import React, { createContext, useState } from "react";

export const RadContext = createContext(undefined);

export const RadProvider = (props) => {
   const [datosContext, setDatosContext] = useState({});

   const [preguntasContext, setPreguntasContext] = useState({});

   const [atencionStatus, setAtencionStatus] = useState(0);

   const [timeStart, setTimeStart] = useState("");

   const [radiografia, setRadiografia] = useState({
      nombre_vr: null,
      descripcion_vr: null,
      nombre_cp: null,
      descripcion_cp: null,
      nombre_hl: null,
      descripcion_hl: null,
      nombre_sn: null,
      descripcion_sn: null,
      nombre_mt: null,
      descripcion_mt: null,
      nombre_sc: null,
      descripcion_sc: null,
      imagen: null,
      nombre_cr: null,
      descripcion_cr: null,
      descripcion_ob: "",
   });
   const [informeData, setInformeData] = useState({
      calidad_rx: null,
      causa: null,
      descripcion: null,
      opacidad_pequena: null,
      opacidad_grande: null,
      forma_tamano: {
         primaria: null,
         secundaria: null,
      },
      zona_afectada: {
         derecha: null,
         izquierda: null,
      },
      neu_pared_toraxica: {
         sitio: null,
         calificacion: null,
      },
      neu_frente: {
         sitio: null,
         calificacion: null,
      },
      neu_diagrama: {
         sitio: null,
         calificacion: null,
      },
      neu_otros_sitios: {
         sitio: null,
         calificacion: null,
      },
      neu_ancho: {
         derecha: null,
         izquierda: null,
      },
      neu_extension: {
         derecha: null,
         izquierda: null,
      },
      eng_perfil: {
         pared_toraxica: null,
         calificacion: null,
      },
      eng_frente: {
         pared_toraxica: null,
         calificacion: null,
      },
      eng_extension: {
         derecha: null,
         izquierda: null,
      },
      eng_ancho: {
         derecha: null,
         izquierda: null,
      },
      neu_obliteracion: null,
      fecha: null,
      nro_placa: null,
      medico_evaluador: null,
      colegiatura: null,
      r_n_e: null,
      simbolos: [
         {
            nombre_simbolo: null,
         },
      ],
   });

   const [radiologiaData, setRadiologiaData] = useState({
      observaciones: null,
      archivo: null,
   });

   const [ToraxOIT, setToraxOIT] = useState({
      observaciones: null,
      imagen: null,
      torax_detalles: [
         {
            rad_partes_id: null,
            descripcion: null,
            opcion: null,
         },
      ],
   });

   const [LumboSacraContext, setLumboSacraContext] = useState({
      sintomas_hallasgos: null,
      observaciones: null,
      recomendaciones: null,
      conclusiones: null,
      fecha: null,
      colegiatura: null,
      resultado: null,
      archivo: null,
   });

   const [dataRayosXToraxOIT, setDataRayosXToraxOIT] = useState({
      //Recibe los datos
   });

   const [RayTorax, setRayTorax] = useState(
      //envia los datos a la api
      {
         //Radiografia
         observaciones: "",
         rad_detalles: [
            {
               rad_partes_id: 1,
               descripcion: null,
               opcion: "1",
            },
            {
               rad_partes_id: 2,
               descripcion: null,
               opcion: "1",
            },
            {
               rad_partes_id: 3,
               descripcion: null,
               opcion: "1",
            },
            {
               rad_partes_id: 4,
               descripcion: null,
               opcion: "1",
            },
            {
               rad_partes_id: 5,
               descripcion: null,
               opcion: "1",
            },
            {
               rad_partes_id: 6,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 7,
               descripcion: null,
               opcion: "1",
            },
         ],
         lectura_oit: {
            imagen: null
         },
         // Informe oit
         informe_oit: {
            fecha: null,
            nro_placa: "",

            // VARIABLE PARA ALMACENAR NO PARA ENVIAR
            objeto_medico: null,
            // -----
            medico_evaluador: null,
            colegiatura: null,
            r_n_e: "",
            comentario: "",
            observaciones: "",
            archivo: null,
         },
         calidad_rx: "2",
         causa: "2",
         descripcion: "otro",
         zona_afectada: {
            derecha: "1",
            izquierda: "1",
         },
         forma_tamano: {
            primaria: "1",
            secundaria: "1",
         },
         opacidad_pequena: "1",
         opacidad_grande: "1",
         neu_pared_toraxica: {
            sitio: "1",
            calificacion: "2",
         },
         neu_frente: {
            sitio: "2",
            calificacion: "2",
         },
         neu_diagrama: {
            sitio: "2",
            calificacion: "1",
         },
         neu_otros_sitios: {
            sitio: "1",
            calificacion: "3",
         },
         neu_extension: {
            derecha: "3",
            izquierda: "1",
         },
         neu_ancho: {
            derecha: "2",
            izquierda: "1",
         },
         neu_obliteracion: "2",
         eng_perfil: {
            pared_toraxica: "3",
            calificacion: "1",
         },
         eng_frente: {
            pared_toraxica: "1",
            calificacion: "2",
         },
         eng_extension: {
            derecha: "3",
            izquierda: "3",
         },
         eng_ancho: {
            derecha: "3",
            izquierda: "1",
         },

         simbolos: [],
         // Rad Columna
         rad_columna: {
            observaciones: "null para mas",
            conclusiones: "null para mas 234",
            archivo: null,
         },
      }
   );

   const [RayXToraxEstandar, setRayXToraxEstandar] = useState(
      {
         //Radiografia
         observaciones: null,
         rad_detalles: [
            {
               rad_partes_id: 1,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 2,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 3,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 4,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 5,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 6,
               descripcion: null,
               opcion: "1"
            },
            {
               rad_partes_id: 7,
               descripcion: null,
               opcion: "1"
            }
         ],
         lectura_oit: {
            primer_dato: null,
            segundo_dato: null,
            tercer_dato: null,
            cuarto_dato: null,
            simbolo: null,
            calidad_rx: null,
            imagen: null
         },
         //Columna
         rad_columna: {
            observaciones: null,
            conclusiones: null,
            archivo: null
         }
      }
   )
   return (
      <RadContext.Provider
         value={{
            datosContext,
            setDatosContext,
            preguntasContext,
            setPreguntasContext,
            atencionStatus,
            setAtencionStatus,
            timeStart,
            setTimeStart,
            informeData,
            setInformeData,
            radiografia,
            setRadiografia,
            radiologiaData,
            setRadiologiaData,
            LumboSacraContext,
            setLumboSacraContext,
            RayTorax,
            setRayTorax,
            ToraxOIT,
            setToraxOIT,
            dataRayosXToraxOIT,
            setDataRayosXToraxOIT,
            RayXToraxEstandar, 
            setRayXToraxEstandar
         }}
      >
         {props.children}
      </RadContext.Provider>
   );
};

import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from "primereact/dialog";
import { Button } from 'primereact/button';

export default function EvaluadoresRadio({ evaluadores, visible, setVisible, id, setid }) {
    const [selectedEvaluador, setSelectedEvaluador] = useState();

    useEffect(() => {
        if (evaluadores && evaluadores.length > 0) {
            evaluadores.forEach((item) => {
                
                if (item.id === id) {
                    setSelectedEvaluador(item);
                }
            });
        }
    }, [evaluadores, id]);



    const onHide = () => {
        setVisible(false);
    }

    const Enviocontext = () => {
        if (selectedEvaluador) {
            setid(selectedEvaluador.id);
        } else {
            setid(null);
        }
    }
    const header = () => {
        return (
            <>
                <i className="pi pi-briefcase icon-create-proveedor" style={{ marginRight: '.5em' }}></i>
                <span>Agregar </span>
            </>
        );
    }

    const footer = () => {
        return (
            <React.Fragment>
                <Button
                    label="Cancelar"
                    icon="pi pi-times"
                    className="p-button-danger"
                    onClick={onHide}
                />
                <Button
                    label="Guardar"
                    icon="pi pi-check"
                    className="p-button-success"
                    onClick={() => {
                        onHide(); Enviocontext();
                    }}
                />
            </React.Fragment>
        );
    }

    return (
        <Dialog visible={visible} header={header} footer={footer} onHide={onHide}>
            <DataTable value={evaluadores} selection={selectedEvaluador} dataKey="id" onSelectionChange={(e) => setSelectedEvaluador(e.value)} tableStyle={{ minWidth: '50rem' }}>
                <Column selectionMode="single" headerStyle={{ width: '3rem' }}></Column>
                <Column field="colegiatura" header="Colegiatura"></Column>
                <Column field="nombres_completos" header="Nombres"></Column>
                <Column field="profesion" header="Profesión"></Column>
                <Column field="direccion" header="Direccion"></Column>
            </DataTable>
        </Dialog>
    );
}

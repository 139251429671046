import React from 'react'
import Logo from '../../../../Images/Icon-ST/Icon-star.svg'
import SkeletonVideo from './Skeleton/SkeletonVideo'
import { ScrollPanel } from 'primereact/scrollpanel';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
// import '../Cards/Skeleton/style.css'

export default function ContactenosB(SendContactenosB) {
  

    const [formConctBreg, setFormConctBreg] = useState({
        numerodecelular: "",
        correoelectronico: "",
        telefono: "",
        telefono02: ""
      });
      const [buttonActive, setButtonActive] = useState(false);
      const [firstClick, setFirstClick] = useState(true);
    
      function OnchangeInputs(e) {
        const { name, value } = e.target;
        setFormConctBreg({ ...formConctBreg, [name]: value });
      }
    
      const SendInfo = () => {
        if (firstClick) {
          setButtonActive(!buttonActive);
          setFirstClick(false);
          return;
        }
        //Enviar datos a la API
        SendContactenosB(formConctBreg);
    
        setFirstClick(true);
        setButtonActive(!buttonActive);
      };
    
      // #region Skeleton
      const [loading, setLoading] = useState(true);
    
      useEffect(() => {
        setTimeout(() => {
          setLoading(false)
        }, 3000);
      }, [])
      // #endregion
    
      return (
        <div className="flex-1 flex bg-white flex-column gap-3" style={{borderRadius: '17px', padding: '25px'}}>
          <div className="flex align-items-start justify-content-between  border-round-lg ">
            <div>
              <img
                width="54px"
                className="border-round-lg bg-indigo-100 p-3"
                src="https://i.ibb.co/hdPWyD0/Group-1920.png"
                alt="Group-1920"
                border="0"
              />
            </div>
    
          </div>
    
          <div className="flex gap-3 flex-column  ">
            <div className="flex gap-3">
              <p className="font-bold white-space-nowrap" style={{ fontSize: '18px' , color: '#5555D8' }}>
                Contáctenos
              </p>
              {/*<p className="text-indigo-500 text-base">Administrador</p>*/}
            </div>
            <p className="text-sm" style={{color: '#7B8794'}}>
              Registre los datos de contacto para brindarle una ayuda a tus
              clientes.
            </p>
          </div>
    
          <div className="flex-1 flex flex-column justify-content-between">
            <div className="flex flex-column gap-3">
              <div className="flex flex-column gap-3  ">
                <div className="flex gap-2 align-items-center ">
                  <i className="pi pi-whatsapp text-base" style={{fontWeight: '500'}}></i>
                  <label htmlFor="numerodecelular" className="text-sm " style={{fontWeight: '500'}}>
                    Número de whatsapp
                  </label>
                </div>
    
                {
                  loading ? <Skeleton className="border-round-lg" height="41px" /> :
                    <InputText
                      onChange={OnchangeInputs}
                      id="numerodecelular"
                      name="numerodecelular"
    
                      type="text"
                      className="p-inputtext-sm"
                      placeholder="938 211 243"
                    />
                }
              </div>
    
              <div className="flex flex-column gap-3 ">
                <div className="flex gap-2 align-items-center ">
                  <i className="pi pi-envelope text-base" style={{fontWeight: '500'}}></i>
                  <label htmlFor="correoelectronico" className="text-sm " style={{fontWeight: '500'}}>
                    Correo Eléctronico
                  </label>
                </div>
                {
                  loading ? <Skeleton className="border-round-lg" height="41px" /> :
                    <InputText
                      onChange={OnchangeInputs}
                      id="correoelectronico"
                      name="correoelectronico"
                      type="text"
                      className="p-inputtext-sm"
                      placeholder="Soporte@bregma.com.pe"
                    />
                }
              </div>
    
              <div className="flex flex-column gap-3  ">
                <div className="flex gap-2 align-items-center ">
                  <i className="pi pi-phone text-base" style={{fontWeight: '500'}}></i>
                  <label htmlFor="telefono" className="text-sm" style={{fontWeight: '500'}}>
                    Teléfono
                  </label>
                </div>
                {
                  loading ? <Skeleton className="border-round-lg" height="41px" /> :
                    <InputText
                      onChange={OnchangeInputs}
                      id="telefono"
                      name="telefono"
                      type="text"
                      className="p-inputtext-sm"
                      placeholder="938 211 243"
                    />
                }
              </div>
    
              <div className="flex flex-column gap-3">
                <div className="flex gap-2 align-items-center ">
                  <i className="pi pi-phone text-base" style={{fontWeight: '500'}}></i>
                  <label htmlFor="telefono02" className="text-sm " style={{fontWeight: '500'}}>
                    Teléfono 02
                  </label>
                </div>
                {
                  loading ? <Skeleton className="border-round-lg" height="41px" /> :
                    <InputText
                      onChange={OnchangeInputs}
                      id="telefono02"
                      name="telefono02"
                      type="text"
                      className="p-inputtext-sm"
                      placeholder="938 211 243"
                    />
                }
              </div>
            </div>
            
          </div>
    
    
        </div>
      );
    }
    
import React, { useState, useRef } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import { useDispatch } from "react-redux";

import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../../AuthUser";
import DialogIngreso from "../Dialogs/DialogEntrada";
import MultiLevelTreeSelectWithAPIClinica from "../../../../../Components/Clinica/ProductoServicio/Servicios/components/SelectTipoServicioClinica";

export default function PageAgregarServiciosB() {
   const dispatch = useDispatch();

   // DropDown
   const [marcados, setMarcados] = useState([{ "id": 1, content: {} }])
   const toast = useRef(null);
   const navigate = useNavigate();
   const { http } = AuthUser()
   const [servicios, setServicios] = useState([])
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [IdSeleccionado, setIdSeleccionado] = useState(null)
   const [PerfilesCreados, setPerfile4sCreados] = useState([
      {
         id: 1,
         NombrePerfil: '',
         PrecioReferencial: 0,
         ItemsSeleccionados: [],
         ItemsSeleccionadosDetalles: []
      }
   ]);
   const [AbrirDialog, setAbrirDialog] = useState(false)

   // const agregarNuevaSeccion = () => {
   //    const ultimoId = PerfilesCreados[PerfilesCreados.length - 1].id;
   //    const nuevaSeccion = {
   //       id: ultimoId + 1,
   //       NombrePerfil: ``,
   //       PrecioReferencial: 0,
   //       ItemsSeleccionados: []
   //    };
   //    setPerfile4sCreados([...PerfilesCreados, nuevaSeccion]);
   // };

   const seleccionarIngreso = (rowData) => {
      return (
         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
               style={{ backgroundColor: Object.keys(marcados[rowData.id - 1 >= 0 ? rowData.id - 1 : 0]["content"]).length === 0 ? "white" : "rgb(235,235,255)" }}
               className="add__container_main_content_body_table_td_btn_service"
               onClick={() => {
                  setAbrirDialog(true);
                  setIdSeleccionado(rowData.id);
               }}
            >
               Seleccionar
            </button>
         </div>
      );
   }

   // const handleChangeNombreTipoPerfil = (event) => {
   //    setNombreTipoPerfil(event.target.value); // Aquí se actualiza el estado
   //    // Otra lógica que necesites...
   // };

   const [NombreServicio, setNombreServicio] = useState("");

   function obtenerIndices(array) {
      const exclusiones = ["areas_medicas", "examenes"]; // Claves a excluir
      const indices = [];
      for (const key in array) {
         if (Object.hasOwnProperty.call(array, key) && !exclusiones.includes(key)) {
            indices.push(key);
         }
      }
      return indices;
   }
   const DataEnviar = ({
      "nombre": NombreServicio,
      "clinica_servicio_id": selectedTipoServicio,
      "perfiles": PerfilesCreados.map(profile => {
         let ItemSelectKey = obtenerIndices(profile.ItemsSeleccionados);
         return {
            "nombre": profile.NombrePerfil,
            "areas_medicas": ItemSelectKey.filter(item => item.startsWith('1')).map(item => (
               {
                  "id": item.slice(2),
                  "parcialmente": 1
               }
            )),
            "capacitaciones": [],
            "examenes": ItemSelectKey.filter(item => item.startsWith('3')).map(item => (
               {
                  "id": item.slice(2),
                  "parcialmente": 1
               }
            ))
         };
      })
   });

   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
      setFaltaTipoServicio(false);
   };

   const [perfiles, setPerfiles] = useState([{
      id: 1,
      nombre: "Nombre(click para cambiar)",
      ingreso: {},
      selectedIngreso: [],
   }
   ])

   // const getServicios = async () => {
   //    dispatch(startLoading());

   //    try {
   //       const response = await http.get('clinica/operaciones/servicio/get');

   //       if (response?.data?.resp || response?.data) {
   //          setServicios(transformData([response.data.resp ? response.data.resp : response.data]))
   //       } else if (response?.data?.error) {
   //          showToast('error', 'Error', response.data.error)
   //       }

   //    } catch (error) {
   //       showToast('error', 'Datos no actualizados', 'Contactar a soporte técnico')

   //    } finally {
   //       dispatch(endLoading());
   //    }
   // }

   // const editarObjetoPorId = (id, nuevoMarcado) => {
   //    setMarcados((prevArrayObjetos) =>
   //       prevArrayObjetos.map((objeto) =>
   //          objeto.id === id
   //             ? {
   //                ...objeto,
   //                content: nuevoMarcado ? { ...nuevoMarcado } : {},
   //             }
   //             : objeto
   //       )
   //    );
   // };

   // const ordenar_datos = (a, b) => {
   //    if (a.id < b.id) {
   //       return -1;
   //    }
   //    if (a.id > b.id) {
   //       return 1;
   //    }
   //    return 0;
   // }

   // const seleccionarServicios = (e, setRowData, tipo, id) => {
   //    editarObjetoPorId(id, e)
   //    let totalRefencial = 0;
   //    var keyNames = Object.keys(e);
   //    let areas_medicas = keyNames
   //       .filter((data) => data.charAt(0) === "1")
   //       .map((item) => {
   //          return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
   //       });
   //    let capacitaciones = keyNames
   //       .filter((data) => data.charAt(0) === "2")
   //       .map((item) => {
   //          return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
   //       });
   //    let examenes = keyNames
   //       .filter((data) => data.charAt(0) === "3")
   //       .map((item) => {
   //          return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
   //       });

   //    examenes = examenes.sort(ordenar_datos)
   //    capacitaciones = capacitaciones.sort(ordenar_datos)
   //    areas_medicas = areas_medicas.sort(ordenar_datos)
   //    const updatedPerfil = {
   //       ...rowData,
   //       clinica_servicio_id: selectedTipoServicio,
   //    };
   //    const updatedPerfiles = perfiles.map(perfil => (perfil.id === rowData.id ? updatedPerfil : perfil));
   //    setRowData(updatedPerfil);
   //    setPerfiles(updatedPerfiles);
   //    setServicio({ ...servicio, clinica_servicio_id: selectedTipoServicio, perfiles: updatedPerfiles });
   //    setPriceReference(totalRefencial)
   //    const areas = {
   //       tipo_perfil_id: tipo,
   //       areas_medicas: areas_medicas,
   //       capacitaciones: capacitaciones,
   //       examenes: examenes,
   //    };
   //    var updatedList = {};
   //    switch (tipo) {
   //       case '1':

   //          setRowData({ ...rowData, selectedIngreso: e });
   //          updatedList = perfiles.map((perfil) => {
   //             if (perfil.id === rowData.id) {
   //                return {
   //                   ...perfil,

   //                   areas_medicas: areas_medicas,
   //                   capacitaciones: capacitaciones,
   //                   examenes: examenes,
   //                }
   //             }
   //             return perfil;
   //          })
   //          break;

   //       default:
   //          break;
   //    }
   //    setPerfiles(updatedList);
   //    setServicio({ ...servicio, perfiles: updatedList })
   // };

   const handleAgregar = () => {
      const newId = Math.max(...perfiles.map((perfil => perfil.id))) + 1
      const newPerfil = {
         id: newId,
         nombre: "Ingresar Nombre",
         selectedIngreso: [],
      }
      setPerfiles([...perfiles, newPerfil])
      const nuevoElemento = { "id": newId, content: {} };
      setMarcados((prevMarcados) => [...prevMarcados, nuevoElemento]);
   };

   // const handleChangeNombre = (event) => {
   //    setNombrePaquete(event.target.value);
   //    validarCampos();
   //    setServicio({ ...servicio, nombre: event.target.value });
   // };

   // const handleKeyDown = (e) => {
   //    if (e.key === "Escape") {
   //       setShowInput(false);
   //    }
   // };

   // useEffect(() => {
   //    getServicios();
   //    document.addEventListener("keydown", handleKeyDown);
   //    return () => {
   //       document.removeEventListener("keydown", handleKeyDown);
   //    };
   // }, []);


   const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;
      rowData[field] = newValue;
      actualizarPerfil(rowData);
   };

   const actualizarPerfil = (perfilActualizado) => {
      setPerfiles(perfiles.map(perfil => perfil.id === perfilActualizado.id ? perfilActualizado : perfil));
   };

   // const cellEditor = (options) => {
   //    return textEditor(options);
   // };

   const textEditor = (options) => {
      return <InputText placeholder="tipo perfil" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{ textAlign: 'center' }} />;
   };

   const headerGroup = (
      <ColumnGroup >
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header="Servicios" field="thisYearSale" />
            <Column header="Precio Referencial" field="lastYearProfit" />

         </Row>
      </ColumnGroup>
   );

   const sendData = async (data) => {
      dispatch(startLoading());
      try {
         const response = await http.post('clinica/paquete/particular/create', data);
         if (response?.data?.resp) {
            showToast('success', 'Creado correctamente', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   }

   // const filtrarPorPrefijo = (items, prefijo) => {
   //    const filteredItems = {};
   //    for (const key in items) {
   //       if (key.startsWith(prefijo)) {
   //          filteredItems[key] = items[key];
   //       }
   //    }
   //    return filteredItems;
   // };

   // const RellenarDatosEnvio = () => {
   //    setDatosEnvio({
   //       "nombre": NombreServicio,
   //       "clinica_servicio_id": selectedTipoServicio,
   //       "perfiles": PerfilesCreados.map(item => ({
   //          "nombre": item.NombrePerfil,
   //          "areas_medicas": Object.entries(item.ItemsSeleccionadosDetalles)
   //             .filter(([key, value]) => key.startsWith('1'))
   //             .reduce((obj, [key, value]) => {
   //                obj[key] = value;
   //                return obj;
   //             }, {}),
   //          //  "capacitaciones": [],
   //          //  "examenes": filtrarPorPrefijo(item.ItemsSeleccionadosDetalles, 3)
   //       }))
   //    });
   // };

   // const CrearServicio = async (data) => {
   //    dispatch(startLoading());

   //    try {
   //       const response = await http.post('clinica/paquete/particular/create', data);

   //       if (response?.data?.resp) {
   //          showToast(
   //             "success",
   //             "Creado exitosa",
   //             "Servicio creado correctamente"
   //          );
   //       } else if (response?.data?.error) {
   //          showToast('error', 'Error', response.data.error)
   //       }

   //    } catch (error) {
   //       showToast('error', 'Error', 'Contactar a soporte técnico')

   //    } finally {
   //       dispatch(endLoading());
   //    }
   // };

   // useEffect(() => {
   //    if (datosEnvio != null) {
   //       CrearServicio(datosEnvio);
   //    }
   // }, [datosEnvio]);

   // const validarCampos = () => {
   //    const camposNombreValido = nombrePaquete.trim() !== '';
   //    // const camposServicioValido = selectedTreeSelectId !== null;
   //    // const camposSeleccionValida = selectedNodeKeys && typeof selectedNodeKeys === 'object' && Object.keys(selectedNodeKeys).length > 0;


   //    const camposSonValidos = camposNombreValido;
   //    // && camposServicioValido && camposSeleccionValida

   //    setNombreValido(camposNombreValido);
   //    // setServicioValido(camposServicioValido);
   //    // setSeleccionValida(camposSeleccionValida);
   //    setCamposValidos(camposSonValidos);
   // };

   // useEffect(() => {
   //    validarCampos();
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [nombrePaquete]);

   const [FaltaNombrePack, setFaltaNombrePack] = useState(false);
   const [FaltaTipoServicio, setFaltaTipoServicio] = useState(false);

   const VerificarFaltas = () => {
      var verifica = true;
      if (NombreServicio === "" || NombreServicio == null) {
         setFaltaNombrePack(true);
         verifica = false;
      }
      if (selectedTipoServicio === "" || selectedTipoServicio == null) {
         setFaltaTipoServicio(true);
         verifica = false;
      }
      return verifica;
   }

   const handleSubmit = () => {
      var veri = VerificarFaltas();
      if (veri) {
         sendData(DataEnviar);
      }
   };

   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Nuevo Servicio - Particular</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white h-auto p-3 pl-5 pr-5 ContenedorWhiteAHP gap-2 flex flex-wrap align-items-start">

               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Nombre:
                  </label>
                  <span className="p-input-icon-right border-round-lg">
                     <i className="pi pi-pencil" />

                     <InputText
                        placeholder="Paquete anual- Primax"
                        className={`add__container_main_content_header_inputs_singular_input ${FaltaNombrePack ? 'active' : ''}`}
                        value={NombreServicio}
                        onChange={(e) => {
                           setNombreServicio(e.target.value); setFaltaNombrePack(false)
                        }}
                     />

                     {/* <InputText
                        placeholder="Paquete anual- Primax"
                        className="add__container_main_content_header_inputs_singular_input"
                        value={NombreServicio} onChange={(e) => setNombreServicio(e.target.value)}
                     /> */}

                  </span>
                  <div className={`divFaltaHeighttTransition ${FaltaNombrePack ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaNombrePack ? 'active' : ''}`}>Se necesita ingresar datos</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Tipo de servicio:
                  </label>
                  <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                     faltt={FaltaTipoServicio}
                  />
                  {/* <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     /> */}
                  <div className={`divFaltaHeighttTransition ${FaltaTipoServicio ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaTipoServicio ? 'active' : ''}`}>Se necesita ingresar un tipo de servicio</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1" style={{ opacity: '0' }}>
                     Buscar:
                  </label>
                  <span className=" p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                     <i className=" px-2 pi pi-search" />
                     <InputText placeholder="Buscar..." className="w-full" />
                  </span>
               </div>

               {/* <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Nombre:
                     </label>
                     <span className="p-input-icon-right">
                        <i className="pi pi-pencil" />
                        <InputText
                           placeholder="Paquete anual- Primax"
                           className="add__container_main_content_header_inputs_singular_input"
                           value={NombreServicio} onChange={(e) => setNombreServicio(e.target.value)}
                        />
                     </span>
                  </div>
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Tipo de servicio:
                     </label>
                     <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     />
                  </div>
               </div>
               <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText placeholder="Buscador" />
                     </span>
                  </div>
               </div> */}
            </div>
            { }
            <div className="add__container_main_content_body">
               <div style={{ width: "75%", margin: "auto" }} className="add__container_main_content_body_container bg-white">
                  <ScrollPanel
                     style={{ width: "100%", maxHeight: '400px', padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid">
                        <DataTable
                           value={PerfilesCreados}
                           headerColumnGroup={headerGroup}
                           editMode="cell"
                           tableStyle={{ maxWidth: '100%' }}
                           size="small">
                           <Column className="columna_j" field="NombrePerfil" header="" style={{ width: '22%', textAlign: "center" }}
                              editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                           <Column body={seleccionarIngreso} header="Servicios" ></Column>
                           <Column
                              field="PrecioReferencial"
                              header="Precio Referencial"
                              body={(rowData) => (
                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <button
                                       className="p-button p-component p-button-text-icon-left p-button-text-icon-align-right p-button-outlined p-button-sm"
                                       style={{ marginLeft: '5px', marginRight: '5px', width: '100px', height: '30px' }}
                                       type="button"
                                    >
                                       <span className="p-button-text p-c" style={{ display: 'flex', alignItems: 'center' }}>
                                          <span style={{ marginRight: '5px' }}>S/.</span>
                                          {rowData.PrecioReferencial}
                                       </span>
                                    </button>
                                 </div>
                              )}
                           />
                        </DataTable>
                     </div>

                     <DialogIngreso
                        Activador={AbrirDialog}
                        setActivador={setAbrirDialog}
                        DataActual={PerfilesCreados}
                        setDataActual={setPerfile4sCreados}
                        IdSelect={IdSeleccionado}
                        DataServicios={servicios}
                     />

                  </ScrollPanel>
               </div>
               <div style={{ width: "75%", margin: "auto", marginTop: "12px" }} className="flex justify-content-between add__container_main_content_footer_two">
                  <button
                     className="add__container_main_content_footer_one_btn"
                     onClick={handleAgregar}

                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
                  <div className="w-auto flex gap-2 ">
                     <button className="add__container_main_content_footer_two_btn ">
                        <Link
                           to="/productosyservicios/servicios/"
                           className="bg-colo-btn"
                           style={{ textDecoration: "none" }}
                        >
                           Cancelar
                        </Link>
                     </button>
                     <button className="button__add_new"
                        onClick={handleSubmit}>Guardar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
} 
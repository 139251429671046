import React from 'react';
import { Dialog } from 'primereact/dialog';
import "./CSSBoton.css"
import { InputText } from 'primereact/inputtext';

const ModalNoAtendido = (props) => {
    return (
        <Dialog
            visible={props.visible}
            header={
                <>
                    <h2 style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "18px", color: '#5555D8' }}>No a sido atendido</h2>
                </>
            }
            style={{ width: 'auto' }}
            modal
            footer={
                <div>
                    <button onClick={props.onNo} className='BotonCancelar312'>Cancelar</button>
                    <button onClick={props.onYes} className='BotonSave321'> Confirmar</button>
                </div>
            }
            onHide={props.onHide}
        >
            <div className='flex flex-column w-full gap-3'>
                <p>Por favor, indique la razón por la cual no se pudo atender al paciente.</p>
                <InputText />
            </div>
        </Dialog >
    );
};

export default ModalNoAtendido;

import React, { useContext, useEffect, useState } from 'react';
import RATabsNav from "./RATabsNav";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import PageTriaje from "../../../Page/Triaje/PageTriaje";
import { TriajeProvider } from "../../../Page/Triaje/TriajeContext";
import { PsicologiaProvider } from "../../../Page/Psicologia/PsicologiaContext";
import PagePsicologia from "../../../Page/Psicologia/PagePsicologia";
import PageEkg from "../../../Page/Ekg/PageEkg";
import { EkgProvider } from "../../../Page/Ekg/EkgContext";
import { OftProvider } from "../../../Page/Oftalmologia/OftContext";
import PageOft from "../../../Page/Oftalmologia/PageOft";
import PageAud from "../../../Page/Audiometria/PageAud";
import { AudProvider } from "../../../Page/Audiometria/AudContext";
import PageRad from '../../../Page/Radiologia/PageRad';

import { RadProvider } from '../../../Page/Radiologia/RadProvider';
import { MGProvider } from '../../../Page/MedicinaGeneral/MGProvider'
import PageMedicinaGeneral from '../../../Page/MedicinaGeneral/PageMedicinaGeneral';
import PageLaboratorio from '../../../Page/Laboratorio/PageLaboratorio';
import { LaboratorioProvider } from '../../../Page/Laboratorio/LaboratorioProvider';
import PageEspi from '../../../Page/Espirometria/PageEspirometria';
import { EspiProvider } from '../../../Page/Espirometria/EspiProvider';

import { OdoProvider } from '../../../Page/Odontologia/OdoProvider';
import PrincipalOdontograma from '../../../Page/Odontologia/PrincipalOdontograma';

const RAAtencionTabNavigator = ({ arraytabNavigate }) => {

   let { pageViewContext } = useContext(RAContext)

   const contentPage = () => {
      switch (pageViewContext) {
         case 0: //TRIAJE
            return (
               <>
                  <TriajeProvider>
                     <PageTriaje />
                  </TriajeProvider>
               </>
            )
         case 1: //PSICOLOGÍA

            return (
               <>
                  <PsicologiaProvider>
                     <PagePsicologia />
                  </PsicologiaProvider>
               </>
            )
         case 2: //EKG
            return (
               <>
                  <EkgProvider>
                     <PageEkg />
                  </EkgProvider>
               </>
            )
         case 3: //OFT
            return (
               <>
                  <OftProvider>
                     <PageOft />
                  </OftProvider>
               </>
            )
         case 4: //AUD
            return (
               <>
                  <AudProvider>
                     <PageAud />
                  </AudProvider>
               </>
            )
         case 5: //  RAD
            return (
               <>
                  <RadProvider>
                     <PageRad />
                  </RadProvider>
               </>
            )
         case 6: //Laboratorio
            return (
               <>
                  <LaboratorioProvider>
                     <PageLaboratorio />
                  </LaboratorioProvider>
               </>
            )
         case 7: //AUD
            return (
               <>
                  <OdoProvider>
                     <PrincipalOdontograma />
                  </OdoProvider>
               </>
            )
         case 8: //Espirometria
            return (
               <>
                  <EspiProvider>
                     <PageEspi />
                  </EspiProvider>
               </>
            )
         case 9: //MEDGEN
            return (
               <>
                  <MGProvider>
                     <PageMedicinaGeneral />
                  </MGProvider>
               </>
            )
         default:
            return (
               <>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <span>404</span>
                  </div>
               </>
            )

      }

   }

   return (
      <>
         <div className="ratn_container">
            <div className='flex flex-row gap-3 align w-full'>
               <RATabsNav array={arraytabNavigate} />
            </div>
            {/* <ModalImprimir
          imprimirVisible={imprimirVisible}
          setImprimirVisible={setImprimirVisible}
        /> */}
            <div className="ratn_content_container">
               {contentPage()}
            </div>
         </div>
      </>
   );
};

export default RAAtencionTabNavigator;
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import ModalPdf from './ModalPdf';
import { InputText } from 'primereact/inputtext';

const ModalTicket = ({ visibleTicket, setVisibleTicket, examenes, generatePDF, pdfUrl, configIp, setConfigIp }) => {
   const [seletExamenes, setSelectExamenes] = useState([]);
   const [show, setShow] = useState(false)



   const onCheckboxChange = (rowData) => {
      const updatedExamenes = seletExamenes.map((examen) =>
         examen.id === rowData.id
            ? { ...examen, selected: !examen.selected }
            : examen
      );
      setSelectExamenes(updatedExamenes);
   };

   const handleGenerate = () => {
      generatePDF(seletExamenes)
      // setShow(true);
      // setVisibleTicket(false)
   }

   const footerDialog = () => {
      return (
         <div className="flex gap-5 justify-content-end pt-4">
            <Button onClick={() => { setVisibleTicket(false); }} label="Cancelar" />
            <Button onClick={() => handleGenerate()} label="Actualizar" />
         </div>
      )
   }

   const handleChangeConfigIp = (e) => {
      setConfigIp({...configIp, [e.target.name] : e.target.value});
   }

   useEffect(() => {
      setSelectExamenes(examenes)
   }, [examenes]);

   return (
      <>
         <Dialog
            visible={visibleTicket}
            onHide={() => setVisibleTicket(false)}
            header={<h1>Exámenes</h1>}
            style={{ width: '850px' }}
            footer={footerDialog}
         >
            <DataTable
               value={seletExamenes}>
               <Column field='nombre' header='Documento' />
               <Column style={{ display: "flex", justifyContent: "center" }} header="Seleccionar" body={(rowData) => (
                  <Checkbox
                     checked={rowData.selected}
                     onChange={() => onCheckboxChange(rowData)}
                  />
               )}
               />
            </DataTable>
            <div className="field grid">
               <label htmlFor="ip" style={{ width: "50%" }}>Dirección IP*</label>
               <InputText
                  id="ip"
                  name='ip'
                  value={configIp.ip}
                  onChange={(e) => handleChangeConfigIp(e)}
                  style={{ width: "100%" }}
                  required
                  autoComplete="off"
               />
            </div>
            <div className="field grid">
               <label htmlFor="port" style={{ width: "50%" }}>Puerto*</label>
               <InputText
                  id="port"
                  name='port'
                  value={configIp.port}
                  onChange={(e) => handleChangeConfigIp(e)}
                  style={{ width: "100%" }}
                  required
                  autoComplete="off"
               />
            </div>
         </Dialog>
         <ModalPdf
            show={show}
            setShow={setShow}
            pdfUrl={pdfUrl}
         />
      </>
   );
}

export default ModalTicket;
import React, { useEffect, useState } from 'react'
import AuthUser from '../../AuthUser';
import RAResultadosTable from '../../Components/RutaAtencion/Resultados/RAResultadosTable';

export default function PageMenuEspirometria() {
   const { http } = AuthUser();
   const [pacientes, setPacientes] = useState([]);
   const getPacientes = () => {
      http
         .get("/atencion/paciente/area/7")
         .then((response) => {
            setPacientes(response.data.data);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      getPacientes();
   }, [])

   return (
      <div>
         <RAResultadosTable
            pacientes={pacientes}
            nombre={"Espirometria"}
         ></RAResultadosTable>
      </div>
   )
}

import React from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { addLocale } from "primereact/api";

export default function InputCalendar(props) {
   const handleDateChange = (e) => {
      if (props.onChange) {
         props.onChange(e);
      }
   };

   addLocale("es", {
      firstDayOfWeek: 0,
      dayNames: [
         "domingo",
         "lunes",
         "martes",
         "miércoles",
         "jueves",
         "viernes",
         "sábado",
      ],
      dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
      dayNamesMin: ["DO", "LU", "MA", "MI", "JU", "VI", "SA"],
      monthNames: [
         "Enero",
         "Febrero",
         "Marzo",
         "Abril",
         "Mayo",
         "Junio",
         "Julio",
         "Agosto",
         "Septiembre",
         "Octubre",
         "Noviembre",
         "Diciembre",
      ],
      monthNamesShort: [
         "ene",
         "feb",
         "mar",
         "abr",
         "may",
         "jun",
         "jul",
         "ago",
         "sep",
         "oct",
         "nov",
         "dic",
      ],
      today: "Hoy",
      clear: "Limpiar",
   });
   const monthNavigatorTemplate = (e) => {
      return (
         <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            style={{ lineHeight: 1 }}
         />
      );
   };
   const yearNavigatorTemplate = (e) => {
      return (
         <Dropdown
            value={e.value}
            options={e.options}
            onChange={(event) => e.onChange(event.originalEvent, event.value)}
            className="ml-2"
            style={{ lineHeight: 1 }}
         />
      );
   };

   return (
      <Calendar
         id={props.id}
         value={props.value}
         onChange={handleDateChange}
         monthNavigator
         yearNavigator
         yearRange="1900:2100"
         locale="es"
         dateFormat="dd/mm/yy"
         monthNavigatorTemplate={monthNavigatorTemplate}
         yearNavigatorTemplate={yearNavigatorTemplate}
         showButtonBar
         showIcon
         inputMode="numeric"
         showOnFocus={true}
         style={props.style}
         inputStyle={props.inputStyle}
         // readonlyInput={true}
         placeholder={props.placeholder ? props.placeholder : "dd/mm/aaaa"}
         disabled={props.disabled ?? false}
         className={props.className ? props.className : ""}
         
      />
   );
}

import React, { createContext, useEffect, useState } from 'react';

export const EspiContext = createContext(undefined)

export const EspiProvider = (props) => {

   const [datosContext, setDatosContext] = useState({
      interpretacion: "",
      observaciones: "",
      esp_equipo: "Winspiro",
      archivo: null,
      datos_detalles: [
         {
            esp_datos_detalles_tipo_id: 1,
            valor_real: 0,
            valor_teorico: 0,
            porcentaje: 0
         },
         {
            esp_datos_detalles_tipo_id: 2,
            valor_real: 0,
            valor_teorico: 0,
            porcentaje: 0
         },
         {
            esp_datos_detalles_tipo_id: 3,
            valor_real: 0,
            valor_teorico: 0,
            porcentaje: 0
         },
         {
            esp_datos_detalles_tipo_id: 4,
            valor_real: 0,
            valor_teorico: 0,
            porcentaje: 0
         }
      ]
   });

   const [preguntasContext, setPreguntasContext] = useState({
      esp_preguntas_1: {
         antecedentes_pulmonares: [
            {
               patologia_id: 283, patologia_nombre: "Niega", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 284, patologia_nombre: "Asma", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 285, patologia_nombre: "Tuberculosis", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 286, patologia_nombre: "EPOC", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 287, patologia_nombre: "EPID", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 288, patologia_nombre: "Bronquitis Crónica", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 289, patologia_nombre: "Neumonía", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 290, patologia_nombre: "Bronquiectasia", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 291, patologia_nombre: "Rinitis Alérgica", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 292, patologia_nombre: "Otros", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 293, patologia_nombre: "Tabaco", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 294, patologia_nombre: "N° Cigarros al día", resultado: 0, descripcion: ""
            },
            {
               patologia_id: 295, patologia_nombre: "N° Años fumados", resultado: 0, descripcion: ""
            },
            {
               patologia_id: 296, patologia_nombre: "Especifica", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 297, patologia_nombre: "Exposición humo de leña", resultado: "no", descripcion: ""
            }
         ],
         sintomas_actuales: [
            {
               patologia_id: 298, patologia_nombre: "Niega", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 299, patologia_nombre: "Tos", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 300, patologia_nombre: "Disnea", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 301, patologia_nombre: "Silbido de Pecho", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 302, patologia_nombre: "Dolor Torácico", resultado: "no", descripcion: ""
            },
            {
               patologia_id: 303, patologia_nombre: "Otros", resultado: "no", descripcion: ""
            }
         ]
      },
      esp_preguntas_2: {
         usuarios_esp: [
            {
               patologia_id: 304,
               patologia_nombre: "¿Tuvo desprendimiento de la retina o una operación (cirugía) de los ojos, tórax o abdomen, en los últimos 3 meses?",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 305,
               patologia_nombre: "¿Ha tenido algún ataque cardiaco o infarto al corazón en los últimos 3 meses?",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 306,
               patologia_nombre: "¿Ha estado hospitalizado(a) por cualquier otro problema cardiaco en los últimos 3 meses?",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 307,
               patologia_nombre: "¿Está utilizando medicamentos para tuberculosis, en este momento?",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 308,
               patologia_nombre: "En caso de ser mujer: ¿Está embarazada actualmente?",
               resultado: "no",
               descripcion: ""
            }],
         profesional_esp: [
            {
               patologia_id: 309,
               patologia_nombre: "Embolia pulmonar",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 310,
               patologia_nombre: "Infarto reciente",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 311,
               patologia_nombre: "Inestabilidad CV",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 312,
               patologia_nombre: "Fiebre, náusea, vómito",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 313,
               patologia_nombre: "Embarazo avanzado",
               resultado: "no",
               descripcion: ""
            },
            {
               patologia_id: 314,
               patologia_nombre: "Embarazo complicado",
               resultado: "no",
               descripcion: ""
            },
         ]

      },
      esp_preguntas_3: [
         {
            patologia_id: 315,
            patologia_nombre: "¿Tuvo una infección respiratoria(resfriado), en las últimas 3 semanas?",
            resultado: "no",
            descripcion: ""
         },
         {
            patologia_id: 316,
            patologia_nombre: "¿Tuvo infección en el oído en las últimas 3 semanas?",
            resultado: "no",
            descripcion: ""
         },
         {
            patologia_id: 317,
            patologia_nombre: "¿Usa aerosoles(sprays inhalados) o nebulizaciones con broncocilatadores, en las últimas 8 horas?",
            resultado: "no",
            descripcion: ""
         },
         {
            patologia_id: 318,
            patologia_nombre: "¿Has usado algún medicamento broncodilatador toma en las últimas 8 horas?",
            resultado: "no",
            descripcion: ""
         },
         {
            patologia_id: 319,
            patologia_nombre: "¿Fumo(cualquier tipo de cigarro), en las últimas dos horas?",
            resultado: "no",
            descripcion: ""
         },
         {
            patologia_id: 320,
            patologia_nombre: "¿Realizo algún ejercicio físico fuerte(como gimnasia, caminada o trotar), en la última hora?",
            resultado: "no",
            descripcion: ""
         },
         {
            patologia_id: 321,
            patologia_nombre: "¿Comió en la última hora?",
            resultado: "no",
            descripcion: ""
         },
      ]
   });

   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState('');

   useEffect(() => {
   }, [])

   return (
      <EspiContext.Provider
         value={{
            datosContext, setDatosContext,
            preguntasContext, setPreguntasContext,
            atencionStatus, setAtencionStatus,
            timeStart, setTimeStart
         }}
      >
         {props.children}
      </EspiContext.Provider>
   );
};
import React, { createContext, useState } from 'react';

export const LaboratorioContext = createContext(undefined)

export const LaboratorioProvider = (props) => {

   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState('');
   const [examenesContext, setExamenesContext] = useState([
      

   ])

   return (
      <LaboratorioContext.Provider
         value={{
            examenesContext, setExamenesContext,
            atencionStatus, setAtencionStatus,
            timeStart, setTimeStart
         }}
      >
         {props.children}
      </LaboratorioContext.Provider>
   );
};
//Replica en versión Skeleton de GCEmpresa
import { Skeleton } from 'primereact/skeleton';

const SS1Customer = () => {

    return (
        <div className="">
            <div className="flex flex-column gap-2 p-3 bg-white border-round-lg">
                <div className="flex flex-column gap-2">
                    <Skeleton height="40px" width="40px" />
                    <Skeleton height="20px" width="200px" borderRadius='0px' />
                </div>

                <div className="flex gap-2 flex-wrap " style={{ marginTop: "19px" }}>
                    <div className="flex-auto flex flex-column gap-2 ventas" style={{ width: '253px' }}>
                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />
                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />
                        <Skeleton height="39px" />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />
                    </div>

                    <div className="flex-auto flex flex-column gap-2 ventas" style={{ width: '253px' }}>
                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />

                        <Skeleton height="20px" borderRadius='0px' />
                        <Skeleton height="39px" />
                        <div className="flex-1 flex flex-column gap-2 justify-content-between">
                            <div className="flex flex-column gap-2">
                                <Skeleton height="20px" borderRadius='0px' />
                                <Skeleton height="207.9px" width="100%" />
                            </div>

                            <div className="flex justify-content-between gap-3" style={{ height: '39px' }}>
                                <Skeleton height="39px" />
                                <Skeleton height="39px" />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-column xl:justify-content-between justify-content-end xl:w-21rem w-full relative">
                        <div className="flex-1 xl:flex lg:hidden md:hidden sm:hidden hidden justify-content-center aling-items-center">
                            <Skeleton width="336px" height="100%" className="absolute top-0 left-0 right-0 bottom-0 m-auto" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SS1Customer;   
import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Header, Footer } from "../../Pure/SeccionesNavegacion";
import { useDispatch } from "react-redux";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";


export default function EliminarModal({ eliminar, close, estado, paquete }) {
    const dispatch = useDispatch();

    const ActivarLoading = () => {
        dispatch(startLoading());
        setTimeout(() => {
            dispatch(endLoading());

        }, 800);
    }
    // BOTONES DE CONFIMACION (ELIMINAR) 
    const handleDeleteConfirmation = (id_paquete) => (
        <div className="flex justify-content-end">
            <Button
                label="Cancelar"
                className="p-button-secondary"
                style={{ background: "white", color: "#344054" }}
                onClick={() => {
                    close(false);
                }}
            />
            <Button
                label="Eliminar"
                className="p-button-danger "
                onClick={() => {
                    close(false);
                    eliminar(id_paquete);
                    ActivarLoading();
                }}
            />
        </div>
    );

    const header = () => {
        return (
            <Header piIcon={'pi pi-trash'} titulo={'Eliminar paquete'} />
        )
    }

    const footer = () => {
        return (
            <Footer
                label={'Eliminar'}
                setVisible={close}
                onClickEnviar={() => {
                    close(false);
                    eliminar(paquete?.paquete?.id);
                    ActivarLoading();
                }}
            />
        )
    }


    return (
        <Dialog
            /*visible={estado}
            style={{ width: "350px", height: "auto" }}
            className="p-fluid"
            header={<p className="text-red-500">Eliminar Paquete</p>}*/
            visible={estado}
            resizable={false}
            style={{ width: "500px", height: "auto" }}
            className="p-fluid eliminarLocal"
            header={header}
            footer={footer}
            onHide={() => close(false)}
        >
            {/*<div className="flex flex-column w-full justify-content-center align-items-center" style={{ marginTop: "20px" }}>
                <p style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "16px" }}>¿Está seguro qué desea eliminar    ?</p>

            </div>*/}
            <div className="flex flex-column gap-3" style={{ fontSize: "17px", fontFamily: 'Montserrat' }}>
                ¿Está seguro qué desea eliminar el paquete?
            </div>
        </Dialog>

    )
}
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";
import { useDispatch } from "react-redux";

//Importación de componentes generales
import AuthUser from "../../../../AuthUser";
import { Footer, Header } from "../../../Pure/SeccionesNavegacion";
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";
import { udpateLogo } from "../../../../Redux/authSlice";

//Estilos e imagen de uso general
import icono from "../../../../Images/Perfil/icon/ActualizarUsuario.svg";
import "../../../../Style/usuario/PERSONA.css";

export default function UpdateEmpresaIMGComponent({ dataShow, visible, setVisible, getEmpresa, entidad, api }) {
   const { http } = AuthUser();
   let toast = useRef(null);
   const dispatch = useDispatch();
   const [foto, setFoto] = useState({ foto: null });
   const [empresa, setEmpresa] = useState(dataShow);

   const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
   const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
   const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

   const HideDialogEdit = () => {
      setVisible(false);
      setEmpresa(dataShow)
   };

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   // Subir imagen
   const [totalSize, setTotalSize] = useState(0);
   const fileUploadRef = useRef(null);

   const headerTemplate = (options) => {
      const { className, chooseButton, uploadButton, cancelButton } = options;
      const value = totalSize / 20000;
      const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

      return (
         <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
            {chooseButton}
            {uploadButton}
            {cancelButton}
            <div className="flex align-items-center gap-3 ml-auto">
               <span>{formatedValue} / 2 MB</span>
               <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
            </div>
         </div>
      );
   };

   const onTemplateSelect = (e) => {
      let _totalSize = totalSize;
      let files = e.files;

      Object.keys(files).forEach((key) => {
         _totalSize += files[key].size || 0;
      });

      setTotalSize(_totalSize);
   };

   const onTemplateUpload = (e) => {
      let _totalSize = 0;

      e.files.forEach((file) => {
         _totalSize += file.size || 0;
      });

      setTotalSize(_totalSize);
   };

   const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      callback();
   };

   const onTemplateClear = () => {
      setTotalSize(0);
   };

   const onRemoveTemplate = () => {
      setEmpresa({ ...empresa, logo: null })
      setFoto({ ...foto, foto: null })
   };

   const itemTemplate = (file, props) => {
      return (
         <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center justify-content-between w-full mb-1">
               <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
               <span className="flex flex-column text-left">
                  {file.name}
                  <small>{new Date().toLocaleDateString()}</small>
                  <div className="mt-2">
                     <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                  </div>
               </span>
               <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
         </div>
      );
   };

   const emptyTemplate = () => {
      return (empresa?.logo ? (
         <div className="flex align-items-center flex-wrap">
            <div className="flex align-items-center justify-content-around w-full">
               <img alt={empresa.logo} role="presentation" src={empresa.logo} width={100} />
               <span className="flex flex-column text-left">
                  {empresa.razon_social}
                  <small>{new Date().toLocaleDateString()}</small>
               </span>
               <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={onRemoveTemplate} />
            </div>
         </div>
      ) : (
         <div className="flex align-items-center flex-column">
            <i className="pi pi-upload" style={{ fontSize: '29px', borderRadius: '50%', color: 'rgba(85, 85, 216, 0.8)' }}></i>
            <span style={{ fontSize: '14px', color: 'rgba(85, 85, 216, 0.5)', fontWeight: '400' }}>
               Arrastre y coloque su logo aquí
            </span>
         </div>
      ))
   };

   const subirFoto = async () => {
      const formDataSend = new FormData();
      formDataSend.append("logo", foto.foto);
      if (foto.foto) {
         formDataSend.append('delete_image', false)
      } else if (!foto.foto) {
         formDataSend.append('delete_image', true)
      }
      dispatch(startLoading());
      try {
         const response = await http.post(`${api}/${dataShow?.id}`, formDataSend);
         if (response?.data?.resp) {
            await Promise.all([
               showToast(
                  "success",
                  "Actualizado correctamente",
                  "Se subió la foto de perfil correctamente"
               ),
               getEmpresa(),
               setVisible(false),
               setEmpresa({ ...empresa, logo: response?.data?.logo || response?.data?.code?.logo || null}),
               dispatch(udpateLogo(response?.data?.logo || response?.data?.code?.logo || null))
            ])
         } else if (response?.data?.error) {
            showToast('error', 'Error al actualizar', response.data.error)
         }
      } catch (error) {
         showToast(
            "error",
            "Error al actualizar logo",
            "Contacte a soporte técnico"
         );
         console.error("Algo salió mal", error);
      } finally {
         dispatch(endLoading());
      }
   }

   const handleChangeImagen = (e) => {
      setFoto({ foto: e?.files[0] });
   }

   useEffect(() => {
      setEmpresa(dataShow)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visible])


   return (
      <div>
         <Toast ref={toast} />
         <Dialog
            header={<Header icono={icono} titulo={`Foto de perfil - ${entidad}`} subtitulo={"Inserte una foto de perfil"} />}
            footer={<Footer type={"button"} onClickCancelar={() => { setVisible(false); setEmpresa(dataShow) }} onClickEnviar={subirFoto} label={"Actualizar"} />}
            visible={visible} style={{ width: '50vw', maxWidth: '446px' }} className="form3-container"
            appendTo={document.getElementById("persona-form-edit")}
            dismissableMask
            onHide={() => HideDialogEdit()}>
            <div style={{ marginTop: '10px' }}>
               <div className="flex flex-column gap-3 mb-4">
                  <div className="body-form flex flex-wrap w-full h-full md:flex-row gap-3">
                     <div className="flex flex-column md:flex-wrap gap-3 flex-1">
                        <div>
                           <span style={{ fontWeight: '500', fontSize: '14px' }}>Agregar una imagen</span>
                        </div>
                        <div>
                           <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                           <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                           <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                           <FileUpload ref={fileUploadRef}
                              id="foto"
                              name="foto[]"
                              accept="image/*"
                              maxFileSize={2000000}
                              auto
                              customUpload
                              uploadHandler={handleChangeImagen}
                              onUpload={onTemplateUpload}
                              onSelect={onTemplateSelect}
                              onError={onTemplateClear}
                              onClear={onTemplateClear}
                              headerTemplate={headerTemplate}
                              itemTemplate={itemTemplate}
                              emptyTemplate={emptyTemplate}
                              chooseOptions={chooseOptions}
                              uploadOptions={uploadOptions}
                              cancelOptions={cancelOptions}
                           />
                        </div>
                        <div className="flex flex-column gap-3">
                           <div style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>
                              <p>Recomendaciones:</p>
                           </div>
                           <div className="flex flex-column gap-1" style={{ fontWeight: "400", fontSize: "14px", color: "#7B8794" }}>
                              <p>*Peso máximo de imagen 2 Mb</p>
                              <p>*Tamaño de 300 x 300px</p>
                              <p>*Imagen en formato .jpg</p>
                              <p>*Formato de buena calidad</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
      </div>
   );
}

import React, { useContext, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import './card1AHP.css';
import { RAContext } from "../../../Page/RutaAtencion/RAContext";

const dropdownOptions = [
   { label: 'Ojo Derecho', value: '1' },
   { label: 'Ojo Izquierdo', value: '2' },
   { label: 'Ambos', value: '3' },
];

function Card1({ index, nombre, checked, onChange, dropdownonchange, value }) {
   let { pageViewContext, stateAtencion } = useContext(RAContext)

   const handleCheckboxChange = (event) => {
      // onChange(event);
      onChange();
   };

   const handleDropdownChange = (event) => {
      dropdownonchange(index, event.value);
   };

   return (
      <div className="Contenedor_Card01">
         <div className="Contenedor_Texto_Card01">
            <Checkbox onChange={handleCheckboxChange} checked={checked} disabled={stateAtencion[pageViewContext].estado === 3} />
            <h1>{nombre}</h1>
         </div>
         <div className="Ocul-cont-coment">
            <Dropdown
               className="d-a w-full"
               options={dropdownOptions}
               disabled={!checked}
               value={value}
               onChange={handleDropdownChange}
               style={{ width: '180px' }}
               defaultValue={dropdownOptions[0].label}
               placeholder={"Seleccionar"}
            />
         </div>
      </div>
   );
}

export default Card1;

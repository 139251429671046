import React, { createContext, useState } from 'react';

export const EkgContext = createContext(undefined)

export const EkgProvider = (props) => {

   const [datosContext, setDatosContext] = useState({
      ritmo: "",
      pr: "",
      frecuencia: "",
      qrs: "",
      eje_electrico: "",
      qt: "",
      conclusiones: "",
      recomendaciones: "",
      medico_evaluador: "",
      colegiatura: "",
      archivo: "",
      nombre_archivo:""
   });
   const [preguntasContext, setPreguntasContext] = useState({});
   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState('');

   return (
      <EkgContext.Provider
         value={{
            datosContext, setDatosContext,
            preguntasContext, setPreguntasContext,
            atencionStatus, setAtencionStatus,
            timeStart, setTimeStart
         }}
      >
         {props.children}
      </EkgContext.Provider>
   );
};
import React, {useState,useRef}from 'react'
import { Rating } from "primereact/rating";
import '../../../../Style/styleDonut.css'


function AceptacionE() {
  const Ndatos = [1, 2, 3];
  const [cliente, setCliente] = useState(1);
  const [tipoCliente, setTipoCliente] = useState("");
     const [visible, setVisible] = useState(false);
     const [value, setValue] = useState(null);
/*Datos estaticos para las tarjetas*/   

  const [datosCorporativo, setDatosCorporativo] = useState([
    {
       id: 1,
       TipoDoc: "RUC",
       NumDoc: 45678901,
       RazonS: "Alicorp",
       CodProforma: "160932AO025",
       Contacto: "contacto",
       Locales: "locales",
       Actividad: "Pendiente",
       Detalles: "detalles",
    },
 ]);
 const [datosClinica, setDatosClinica] = useState([
    {
       id: 1,
       TipoDoc: "RUC",
       NumDoc: 45678901,
       RazonS: "Medical Roma",
       CodProforma: "160932AO025",
       Contacto: "contacto",
       Locales: "locales",
       Actividad: "En espera",
       Detalles: "detalles",
    },
 ]);
 const [datosParticular, setDatosParticular] = useState([
    {
       id: 1,
       TipoDoc: "DNI",
       NumeroDoc: 45678901,
       Nombres: "Jorge Tito",
       APaterno: "Carranzas",
       AMaterno: "Campo",
       CodProforma: "160932AO025",
       Contacto: "contacto",
       Actividad: "Pendiente",
       Detalles: "Detalles",
    },
    {
       id: 2,
       TipoDoc: "DNI",
       NumeroDoc: 45678901,
       Nombres: "Sara",
       APaterno: "Flores",
       AMaterno: "Palma",
       CodProforma: "160932AO025",
       Contacto: "contacto",
       Actividad: "Pendiente",
       Detalles: "Detalles",
    },
 ]);
 const [datos, setDatos] = useState(datosCorporativo);

  let vista;
   if (cliente === 1) {
      vista = (
         <div className="flex flex-column aling-items-centern gap-3">
            <div className="fon1dis grid w-full justify-content-center gap-3 ml-1">
               <div className="primi">
                  <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className="seco">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className="terc">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className="cuart">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className="quint">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className="sext">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>
            </div>

            {/* Estadisticas */}

            <div className=" w-full h-30rem"></div>
         </div>
      );
   } else if (cliente === 2) {
      vista = (
         <div className="flex flex-column aling-items-centern gap-3">
            <div className="fon1dis grid w-full justify-content-center gap-3 ml-1">
               <div className="primi">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='seco' > 
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='terc'>                
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>
               

               <div className='cuart'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
            </div>

               <div className='quint'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='sext'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>
              
            </div>

            {/* Estadisticas */}

            <div className=" w-full h-30rem ">
               
            </div>
         </div>
      );
   } else if (cliente === 3) {
      vista = (
         <div className="flex flex-column aling-items-centern gap-3">
            <div className="fon1dis grid w-full justify-content-center gap-3 ml-1">
               <div className="primi">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='seco' > 
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='terc'>                
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>
               

               <div className='cuart'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
            </div>

               <div className='quint'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='sext'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>
               
            </div>

            {/* Estadisticas */}

            <div className=" w-full h-30rem">
               
            </div>
         </div>
      );
   } else if (cliente === 4) {
      vista = (
         <div className="flex flex-column aling-items-centern gap-3">
           <div className="fon1dis grid w-full justify-content-center gap-3 ml-1">
               <div className="primi">
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='seco' > 
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='terc'>                
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>
               

               <div className='cuart'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
            </div>

               <div className='quint'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

               <div className='sext'>
               <div className="flex flex-column re w-20rem h-20rem card align-items-center">
                     <div className="BotonesGraficaDonut flex flex-row gap-2">
                        <button style={{}}>
                           <i className="pi pi-sync" />
                        </button>
                     </div>

                     <h5
                        className="flex pl-3"
                        style={{
                           fontSize: "20px",
                           paddingTop: "20px",
                           justifyContent: "center",
                        }}
                     >
                        Audiometría
                     </h5>
                     <div
                        style={{
                           display: "flex",
                           height: "150px",
                           justifyContent: "center",
                           alignItems: "center",
                        }}
                     >
                        <p
                           className="flex flex-column  justify-content-center"
                           style={{
                              fontSize: "36px",
                              fontWeight: "bold",
                              alignItems: "center",
                              color: "#5555D8",
                              backgroundColor: "#F6F5FF",
                              borderRadius: "50px 50px 50px 50px",
                              height: "100px",
                              width: "100px",
                           }}
                        >
                           3.0
                        </p>
                     </div>
                     <div
                        className="flex  justify-content-center"
                        style={{
                           bottom: "1px",
                           // paddingTop:'20px',
                           fontSize: "50px",
                        }}
                     >
                        <Rating
                           value={3.5}
                           starTemplate={"50px"}
                           style={{
                              fontSize: "5rem",
                              height: "50px",
                              width: "250px",
                              justifyContent: "center",
                           }}
                           onChange={(e) => setValue(e.value)}
                           cancel={false}
                           readOnly
                        />
                     </div>
                     <div
                        style={{
                           backgroundColor: "#F6F5FF",
                           borderRadius: "20px",
                           width: "80%",
                        }}
                        className=" text-center"
                     >
                        <h6
                           style={{
                              width: "100%",
                              fontSize: "15px",
                              // color: "#FEC33B",
                           }}
                        >
                           Trabajadores Encuestados:
                        </h6>
                        <h7
                           style={{
                              color: "#5555D8",
                              fontWeight: "bold",
                              fontSize: "14px",
                           }}
                        >
                           210
                        </h7>
                     </div>

                     {/* <div className='BotonesGraficaDonut flex  gap-2'>
                           <button>
                              <i className="pi pi-sync" />
                        </button>
                        </div> */}
                  </div>
               </div>

            </div>

            {/* Estadisticas */}

            <div className=" w-full h-30rem ">
               
            </div>
         </div>
      );
   }

  const saltoLTabla = (L1, L2) => {
    return (
       <span
          className="flex text-center"
          dangerouslySetInnerHTML={{ __html: `${L1}<br>${L2}` }}
       />
    );
 };
  return (   
    <div className="flex flex-1 gap-2 h-full">   
        <div
            className="flex flex-column gap-5 bg-white border-round-xl p-3"
            style={{ height: "calc(100vh - 200px)" }}
         >
            <div className="flex gap-2 align-items-center justify-content-center">
               <label htmlFor="" className="font-semibold text-base">
                  Lista de Sedes
               </label>
               {/*           <Button
            label=" Reporte"
            className="pi pi-file border-round-xl surface-0 text-green-500 border-green-500"
          /> */}
            </div>
            <div className="flex flex-column gap-2">
               {/* CLIENTE CORPOTARIVO */}
               <div
                  className={`flex gap-3 py-2 px-3 align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-purple-300 ${
                     cliente === 1 ? "border-purple-100" : ""
                  }`}
                  onClick={() => {
                     setCliente(1);
                     setDatos(datosCorporativo);
                  }}
               >
                  <i className="pi pi-user text-xl bg-purple-100 border-round-lg p-2 text-purple-500" />
                  <div>
                     <label htmlFor="" className="text-purple-500 font-bold">
                        Global
                     </label>
                     <p
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        style={{ width: "150px" }}
                     ></p>
                  </div>
                  {cliente === 1 ? (
                     <i className="pi pi-ellipsis-v text-400 font-semibold" />
                  ) : (
                     <div className="w-1rem"></div>
                  )}
               </div>
               {/* CLIENTE CLINICA */}
               <div
                  className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-blue-300 ${
                     cliente === 2 ? "border-blue-100" : ""
                  }`}
                  onClick={() => {
                     setCliente(2);
                     setDatos(datosClinica);
                  }}
               >
                  <i className="pi pi-user text-xl bg-blue-100 border-round-lg p-2 text-blue-500" />
                  <div>
                     <label htmlFor="" className="text-blue-500 font-bold">
                        Sede San Martin de Porres
                     </label>
                     <p
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        style={{ width: "150px" }}
                     ></p>
                  </div>
                  {cliente === 2 ? (
                     <i className="pi pi-ellipsis-v text-400 font-semibold" />
                  ) : (
                     <div className="w-1rem"></div>
                  )}
               </div>
               {/* CLIENTE PARTICULAR */}
               <div
                  className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-green-300 ${
                     cliente === 3 ? "border-green-100" : ""
                  }`}
                  onClick={() => {
                     setCliente(3);
                     setDatos(datosParticular);
                  }}
               >
                  <i className="pi pi-user text-xl bg-green-100 border-round-lg p-2 text-green-500" />
                  <div>
                     <label htmlFor="" className="text-green-500 font-bold">
                        Sede San Juan de Lurigancho
                     </label>
                     <p
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        style={{ width: "150px" }}
                     ></p>
                  </div>
                  {cliente === 3 ? (
                     <i className="pi pi-ellipsis-v text-400 font-semibold" />
                  ) : (
                     <div className="w-1rem"></div>
                  )}
               </div>
               <div
                  className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-yellow-300 ${
                     cliente === 3 ? "border-green-100" : ""
                  }`}
                  onClick={() => {
                     setCliente(4);
                     setDatos(datosParticular);
                  }}
               >
                  <i className="pi pi-user text-xl bg-yellow-100 border-round-lg p-2 text-yellow-500" />
                  <div>
                     <label htmlFor="" className="text-yellow-500 font-bold">
                        Sede Independencia
                     </label>
                     <p
                        className="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                        style={{ width: "150px" }}
                     ></p>
                  </div>
                  {cliente === 4 ? (
                     <i className="pi pi-ellipsis-v text-400 font-semibold" />
                  ) : (
                     <div className="w-1rem"></div>
                  )}
               </div>
            </div>
            <div className="flex flex-column flex-1 align-items-center justify-content-center">
               <img
                  src="https://cdn.discordapp.com/attachments/1089924612944580719/1143597414264082453/Cerco_E.png"
                 alt="imagencita"
                  style={{ width: "291px", height: "369px" }}
               /> 
            </div>
         </div>
         <div className=" flex flex-column border-round-xl tablaGClientes h-screen w-9 aling-items-centern gap-3">
            {vista}
            {/* dunout */}
         </div>

    </div>
  )
}

export default AceptacionE
import React, { useState, useEffect } from "react";
import Botones from "../../Pure/MenuPrincipal/botones";
import Eclipses from "../../Pure/MenuPrincipal/Eclipses";
import Imagenes from "../../Pure/MenuPrincipal/Imagenes";
import "../../../Style/estilo.css";
import Business from "../../../Images/Pure/MenuPrincipal/Img/Business.png";
import Sombra from "../../../Images/Pure/MenuPrincipal/Img/sombra.png";
import Icon1 from "../../../Images/Pure/MenuPrincipal/img-icons/con1.png";
import Icon2 from "../../../Images/Pure/MenuPrincipal/img-icons/con2.png";
import Icon3 from "../../../Images/Pure/MenuPrincipal/img-icons/con3.png";
import Icon4 from "../../../Images/Pure/MenuPrincipal/img-icons/con4.png";
import { Dialog } from "primereact/dialog";
import { HookPositionDemo } from "../../ModalPaginaEnProceso/ModalDesarrollo";

const RecursosHumanosPrinc = ({ locales, areas, roles, personal, reclutamiento, servicios, accesoProp }) => {

  //Obtener Lista de Accesos

  const listAcceso = accesoProp.map((acc) =>{
    return acc.url
  });

  const { visible, setVisible, show, position, setPosition } = HookPositionDemo();

  const [isResponsive, setIsResponsive] = useState(false); // State to track responsiveness

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsResponsive(true);
    } else {
      setIsResponsive(false);
    }
  };

  // Add event listener for window resize
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ height: "100%" }} className='flex flex-nowrap flex-column w-full'>
      <div className="arriba">
        <span className="tit">Recursos Humanos</span>
        <br />
        <span className="con">En este módulo usted podrá realizar la gestión de recursos humanos de Bregma</span>
      </div>
      <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "70%" }}>
        <Eclipses />
        {!isResponsive && (
          <Imagenes
            src={Business}
            className="logo2"
          />
        )}
        <Imagenes src={Sombra} className="som" />
        <Botones hidden={!listAcceso.includes('locales')} Link="/recursoshumanos/locales" img={Icon1} span="Mis locales" button="button b2" />
        <Botones hidden={!listAcceso.includes('roles')} Link="/recursoshumanos/roles" img={Icon2} span="Roles" button="button b3" />
        <Botones hidden={!listAcceso.includes('personal')} Link="/recursoshumanos/personal" img={Icon3} span="Mi Personal" button="button b4" />
        <Botones hidden={!listAcceso.includes('reclutamiento')} Link="/recursoshumanos/reclutamiento" img={Icon4} span="Reclutamiento" button="button b5" />
        {/* <Botones hidden={!listAcceso.includes('evaluadores')} Link="/recursoshumanos/evaluadores" img={Icon4} span="Lista de evaluadores" button="button b5" /> */}
        <Dialog
          visible={visible}
          position={position}
          onHide={() => setVisible(false)}
          draggable={false}
          resizable={false}
          style={{ width: "400px", textAlign: "center" }}
        >
          <p className="mt-2 pr-2">Este módulo se encuentra aún en desarrollo.</p>
        </Dialog>
      </div>
    </div>
  );
};

export default RecursosHumanosPrinc;

//REACT
import React, { useContext, useState, useEffect, useRef } from "react";
//PRIMEREACT
import { Calendar } from "primereact/calendar";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
//ROUTER DOM
import { useNavigate } from "react-router-dom";
//OTROS
import FileDropzone from "../../../Ventas/FileDropZone/FileDropZone";
import SalesCommon from "../../Services/SalesCommon/SalesCommon";
import SMap from "../../SalesMap/SMap";
import SInputMapAutocomplet from "../../SalesMap/SInputMapAutocomplet";
import { SalesContext } from "../../SalesContext/SalesContext";
import "../../../../Style/Ventas/VentasS1Customer.css";
import InputCalendar from '../../../form/InputCalendar';

const S1Customer = ({ setIdRubro, ubicaciones, tipos_clientes, rubros, showToast, validateForm, postClinic, putClinic }) => {
   //#region VARIABLE DE INSTANCIA
   const { sales, setSales } = useContext(SalesContext);
   const { getRUC, getRUCcopy, getSectors } = SalesCommon();
   const navigate = useNavigate();
   const formRef = useRef(null)
   //#endregion
   

   //#region VARIABLES
   const opcionesDepartamentos = ubicaciones.map((departamento) => ({
      label: departamento.departamento,
      value: departamento,
   }));
   const opcionesProvincias = sales.GCEmpresa.departamento
      ? sales.GCEmpresa.departamento.provincias.map((provincia) => ({
         label: provincia.provincia,
         value: provincia,
      }))
      : [{ label: 'Sin elementos', value: null }];
   const opcionesDistritos = sales.GCEmpresa.provincia
      ? sales.GCEmpresa.provincia.distritos.map((distrito) => ({
         label: distrito.distrito,
         value: distrito,
      }))
      : [{ label: 'Sin elementos', value: null }];
   const [visible, setVisible] = useState(false);
   const headerTemplate = (
      <img
         width="40px"
         height="40px"
         className="p-1 bg-indigo-100 border-round-lg select-none"
         src="https://i.ibb.co/9Y5W8Pn/Icon.png"
         alt=""
         draggable="false"
      />
   );
   //#endregion

   //#region CAPTURA DE DATOS
   // INPUTS SIMPLIFICADOS: NUEMERO_DOCUMENTO, RAZON_SOCIAL,CANTIDAD_TRABAJADORES, ACTIVIDAD
   const OnchangeInput = (e) => {
      const { name, value } = e.target;
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, [name]: value } }));
   }

   const onChangeTipoCliente = (e) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, tipo_cliente: e.value } }));
      // setIdRubro(e.target.value);
   }
   const OnchangeResponsable = (e) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, responsable: e.target.value } }));
   }
   const actualizarProvincias = (departamento) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, departamento: departamento, provincia: null } }));
   };

   const actualizarDistritos = (provincia) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, provincia: provincia } }));
   };

   const handleDistritoID = (e) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, distrito: e.value } }));
   }
   const OnchangeDireccion = (e) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, direccion: e.target.value } }));
   };
   function convertDate(texto) {
      let r = null;
      if (texto) {
         const convert = texto.split("-");
         r = new Date(convert[0] + "/" + convert[1] + "/" + convert[2]);
      }
      return r;
   }
   // const onChangeAniversario = (e) => {
   //    const formattedDate = e.value.toISOString().slice(0, 10).toString();
   //    setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, aniversario: formattedDate } }));
   // };
   const onChangeRubro = (e) => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, rubro: e.value } }));
   };

   const onchangeFileLogo = (f) => {
      if (f) {
         setSales((prevState) => ({
            ...prevState,
            GCEmpresa: {
               ...prevState.GCEmpresa,
               logo: f,
               logoName: f.name,
               logoType: f.type,
               logoSize: f.size,
            },
         }));
      }
   };
   //#endregion

   //#region OTRAS FUNCIONES
   const SearchRuc = () => {
      if (sales.GCEmpresa.numero_documento.length === 11) {
         getRUC(sales.GCEmpresa.numero_documento, setSales, showToast);
      } else {
         showToast("warn", "Advertencia", "Ingrese 11 dígitos por favor");
      }
   }
   const sendClinica = (e) => {
      e.preventDefault();
      const form = {
         tipo_cliente_id: sales.GCEmpresa.tipo_cliente,
         numero_documento: sales.GCEmpresa.numero_documento,
         ruc: sales.GCEmpresa.numero_documento,
         razon_social: sales.GCEmpresa.razon_social,
         responsable: sales.GCEmpresa.responsable,
         nombre_comercial: sales.GCEmpresa.razon_social,
         latitud: sales.GCEmpresa.latitud,
         longitud: sales.GCEmpresa.longitud,
         tipo_documento_id: 2,
         distrito_id: sales.GCEmpresa.distrito.id,
         direccion: sales.GCEmpresa.direccion,
         ubicacion_mapa: sales.GCEmpresa.ubicacion_mapa,
         aniversario: sales.GCEmpresa.aniversario,
         rubro_id: sales.GCEmpresa.rubro,
         cantidad_trabajadores: sales.GCEmpresa.cantidad_trabajadores,
         años_actividad: sales.GCEmpresa.actividad,
         logo: sales.GCEmpresa.logo,
         estado_registro: "I",
      }
      var f = new FormData();
      //   if (validateForm(form)) {
      if (sales.GCEmpresa.clinica_id !== null && sales.GCEmpresa.clinica_id !== undefined) {
         for (const [key, value] of Object.entries(form)) {
            f.append(key, value);
         }
         putClinic(sales.GCEmpresa.contrato_id, f, setSales, showToast);
      } else {
         for (const [key, value] of Object.entries(form)) {
            f.append(key, value);
         }
         postClinic(f, setSales, showToast, navigate);

      }

      //   } else {
      //       showToast("warn", "Advertencia", "Complete el formulario")
      //   }

   };
   //#endregion

   //#region SMAPS AND SINPUTMAPAUTOCOMPLETE
   const [latLng, setLatLng] = useState({
      lat: -12.04804382839035,
      lng: -77.03139639316225
   });
   const [direccion, setDireccion] = useState(sales.GCEmpresa.ubicacion_mapa || "");
   
   const [latLngLima, setLatLngLima] = useState({ lat: -12.0464, lng: -77.0428 });
   //MAPS
   const handleMarkerPositionChange = (newPosition) => {
      setLatLngLima({
         lat: newPosition.lat,
         lng: newPosition.lng
      });
   };

   const handleAddressChange = (newAddress) => {
      setDireccion(newAddress);
   };

   //AUTOCOMPLETE
   const handlePlaceChanged = (formattedAddress, coords) => {
      setDireccion(formattedAddress);
      setLatLng({ lat: coords.lat, lng: coords.lng });
   };

   //SAVE
   const SaveMapLocation = () => {
      setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, ubicacion_mapa: direccion, latitud: latLngLima.lat, longitud: latLngLima.lng,"direccion":direccion} }));
      setVisible(false);
  };
   const CancelMapLocation = () => {
      setDireccion(sales.GCEmpresa.ubicacion_mapa);
      setLatLng({ lat: parseFloat(sales.GCEmpresa.latitud), lng: parseFloat(sales.GCEmpresa.longitud) });
      setVisible(false);
   };
   //#endregion

   const Ver = () => {
   };

   //Agregado 04/09/23
   useEffect(() => {
      setDireccion(sales.GCEmpresa.ubicacion_mapa);
      setLatLngLima({ lat: sales.GCEmpresa.latitud, lng: sales.GCEmpresa.longitud });
   }, []);
   //Agregado 04/09/23
   useEffect(() => {
      setDireccion(sales.GCEmpresa.ubicacion_mapa);
      setLatLngLima({ lat: sales.GCEmpresa.latitud, lng: sales.GCEmpresa.longitud });
   }, []);
   const handleClick = (e) => {
      e.preventDefault(); 
      if (formRef.current) {
         formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
   }
   const [logoUrl, setLogoUrl] = useState("");

   useEffect(() => {
      // Verifica si ya tienes una imagen cargada en sales.GCEmpresa.logo
      if (sales.GCEmpresa.logo) {
         if (typeof sales.GCEmpresa.logo === 'string') {
            // Si el logo ya es una URL, simplemente establece la URL
            setLogoUrl(sales.GCEmpresa.logo);
         } else {
            // Si el logo es un objeto (por ejemplo, un File), lee su contenido
            const reader = new FileReader();
            reader.onload = () => {
               setLogoUrl(reader.result);
            };
            reader.readAsDataURL(sales.GCEmpresa.logo);
         }
      }
   }, [sales.GCEmpresa.logo]);

   const [aniversario, setAniversario] = useState(new Date()); // Estado para almacenar la fecha de aniversario
    const [aniosActividad, setAniosActividad] = useState(0); // Estado para almacenar los años de actividad ingresados manualmente

    // Función para manejar el cambio de la fecha de aniversario
    const onChangeAniversario = (e) => {
        setAniversario(e.value); // Actualiza la fecha de aniversario cuando cambia el calendario
    };

    // Efecto que se ejecuta una vez al año para actualizar los años de actividad
    useEffect(() => {
        const fechaActual = new Date();
        const fechaAniversario = new Date(aniversario);
        const diferenciaAnios = fechaActual.getFullYear() - fechaAniversario.getFullYear();
        setAniosActividad(aniosActividad + diferenciaAnios); // Actualiza los años de actividad sumando la diferencia de años
    }, [aniversario]); // Se activa cuando cambia la fecha de aniversario
   return (
      <>
         <form ref={formRef} onSubmit={(event) => sendClinica(event)} method="POST" encType="multipart/form-data" className="flex flex-column gap-2 p-3 bg-white border-round-lg">
            <div className="flex flex-column gap-2">
               <img onClick={() => Ver()} width="40px" height="40px" className="p-1 bg-indigo-100 border-round-lg" src="https://i.ibb.co/9pL0hHB/Icon.png" alt="" />
               <p className="font-bold text-base text-indigo-500">Datos de la empresa</p>
            </div>

            <div className="flex gap-2 flex-wrap ">
               <div className="flex-auto flex flex-column gap-2 ventas" style={{ width: "253px" }}>
                  <label htmlFor='tipoClienteDropdown' className="font-bold">Tipo de Cliente</label>
                  <Dropdown
                     value={sales.GCEmpresa.tipo_cliente}
                     inputId="tipoClienteDropdown"
                     options={tipos_clientes}
                     onChange={(e) => onChangeTipoCliente(e)}
                     placeholder="Seleccione un tipo de cliente"
                     className="p-inputtext-sm"
                  />

                  <label htmlFor="numero_documento" className="font-bold ">RUC</label>
                  <div className="flex gap-2 justify-content-between">
                     <InputText
                        value={sales.GCEmpresa.numero_documento}
                        onChange={(e) => OnchangeInput(e)}
                        name="numero_documento"
                        type="text"
                        id="numero_documento"
                        placeholder="Escriba el número de RUC"
                        className="flex-1 p-inputtext-sm p-2"
                     />
                     <div onClick={() => SearchRuc()} className="p-button pl-2 pr-2 bg-indigo-500"><i className="pi pi-search text-sm" ></i></div>
                  </div>

                  <label htmlFor="razon_social" className="font-bold ">Razón Social</label>
                  <InputText
                     value={sales.GCEmpresa.razon_social}
                     onChange={(e) => OnchangeInput(e)}
                     name="razon_social"
                     type="text"
                     id="razon_social"
                     placeholder="Bregma S.A.C"
                     className="p-inputtext-sm p-2"
                  />

                  <label htmlFor="responsable" className="font-bold ">Responsable</label>
                  <InputText
                     value={sales.GCEmpresa.responsable}
                     onChange={(e) => OnchangeResponsable(e)}
                     id="responsable"
                     name="responsable"
                     type="text"
                     placeholder="Responsable"
                     className="p-inputtext-sm p-2"
                  />

                  <label htmlFor="ubicacion" className="font-bold ">Ubicación</label>
                  <div className="flex flex-column gap-2">
                     <Dropdown
                        value={sales.GCEmpresa.departamento}
                        onChange={(e) => actualizarProvincias(e.value)}
                        options={opcionesDepartamentos}
                        placeholder="Selecciona un departamento"
                        className="p-inputtext-sm"
                        inputId="ubicacion"
                        filter
                     />
                     <Dropdown
                        value={sales.GCEmpresa.provincia}
                        onChange={(e) => actualizarDistritos(e.value)}
                        options={opcionesProvincias}
                        disabled={!sales.GCEmpresa.departamento}
                        placeholder="Selecciona una provincia"
                        className="p-inputtext-sm"
                        filter
                     />
                     <Dropdown
                        value={sales.GCEmpresa.distrito}
                        onChange={(e) => handleDistritoID(e)}
                        options={opcionesDistritos}
                        disabled={!sales.GCEmpresa.provincia}
                        placeholder="Selecciona un distrito"
                        name="distrito_id" id="distrito_id"
                        className="p-inputtext-sm"
                        filter
                     />
                  </div>

                  <label htmlFor="direccion" className="font-bold ">Dirección</label>
                  <InputText
                     value={sales.GCEmpresa.direccion}
                     onChange={(e) => OnchangeDireccion(e)}
                     id="direccion"
                     name="direccion"
                     type="text"
                     placeholder="Av.Dylan Quintanilla #5"
                     className="p-inputtext-sm p-2"   
                  />

                  <label htmlFor="Ubidelmapa" className="font-bold ">Ubicación del mapa</label>
                  <div className="flex flex-column gap-2">
                     <div className="flex gap-2">
                        <InputText
                           value={sales.GCEmpresa.ubicacion_mapa}
                           onClick={() => setVisible(true)}
                           readOnly
                           name="ubicacion_mapa"
                           id="Ubidelmapa"
                           type="text"
                           placeholder="Ubicación del Local"
                           className="p-inputtext-sm p-2 flex-1"
                        />
                        <div
                           onClick={() => setVisible(true)}
                           className="p-button bg-indigo-100 pt-0 pb-0 pl-1 pr-1">
                           <img src="https://i.ibb.co/9Y5W8Pn/Icon.png" alt="Imagen" />
                        </div>
                     </div>

                     <Dialog
                        header={headerTemplate}
                        visible={visible}
                        onHide={() => CancelMapLocation()}
                        style={{maxWidth:"700px",minWidth:"250px",width:"100%", zIndex: '200', position: "relative" }}
                     >
                        <div className="flex flex-column gap-3">
                           <div className="flex flex-column gap-2">
                              <label className="text-lg font-bold text-indigo-500">Ubicación en el mapa</label>
                              <label className="text-gray-500 text-sm" htmlFor=" ">Ubica tu nuevo local en el mapa para ubicarte de forma mas precisa</label>
                           </div>

                           <SInputMapAutocomplet 
                              restrictions={{ country: ["PE"] }}
                              onPlaceChanged={handlePlaceChanged}
                           >
                              <span className="p-input-icon-left w-full">
                                 <i className="pi pi-search" />
                                 <InputText
                                    value={direccion}
                                    onChange={(e) => { setDireccion(e.target.value) }}
                                    className="w-full" placeholder="Buscar ubicación"
                                 />
                              </span>
                           </SInputMapAutocomplet>

                           <SMap
                              mapContainerStyle={{ width: "100%", height: "400px", border:"2px solid black"}}
                              center={latLngLima}
                              markerPosition={latLngLima}
                              onMarkerPositionChange={handleMarkerPositionChange}
                              onAddressChange={handleAddressChange}
                           />
                        </div>
                        <div className="flex justify-content-center gap-3 pt-4">
                           <Button
                              onClick={() => {CancelMapLocation();}}
                              className="w-9rem  bg-white border-gray-300 text-black-alpha-90" label="Cancelar"
                           />
                           <Button
                              onClick={() => SaveMapLocation()}
                              className="w-9rem " label="Guardar "
                           />
                        </div>
                     </Dialog>
                  </div>
               </div>

               <div className="flex-auto flex flex-column gap-2 ventas" style={{ width: "253px" }}>
                  <label htmlFor="aniversario" className="font-bold">Aniversario</label>
                  <Calendar
                     value={aniversario}
                     onChange={onChangeAniversario}
                     inputId="aniversario"
                     name="aniversario"
                     showIcon
                     showButtonBar
                     placeholder="dd.mm.yy"
                     dateFormat="dd/mm/yy"
                     className="p-inputtext-sm"
                  />


                  <label htmlFor="cantidad_trabajadores" className="font-bold white-space-nowrap"> Cantidad de trabajadores</label>
                  <InputNumber
                     value={sales.GCEmpresa.cantidad_trabajadores}
                     onValueChange={(e) => OnchangeInput(e)}
                     maxLength={4}
                     useGrouping={false}
                     inputId="cantidad_trabajadores"
                     name="cantidad_trabajadores"
                     placeholder="0 trabajadores"
                  />

               <label htmlFor="actividad" className="font-bold">Años de actividad</label>
               <InputText
                  value={aniosActividad}
                  onChange={(e) => setAniosActividad(parseInt(e.target.value) || 0)} // Actualiza los años de actividad cuando cambia el valor del campo
                  id="actividad"
                  name="actividad"
                  type="text"
                  placeholder="0 años"
                  className="p-inputtext-sm p-2"
               />

                  <div className="flex-1 flex flex-column gap-2 justify-content-between">
                     <div className="flex flex-column gap-2">
                        <label className="font-bold flex"> Logo </label>
                        <div style={{ width: "90%", height: "200px", position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}> {/* Establece el ancho y el alto del contenedor */}                              <FileDropzone
                           onFileDrop={onchangeFileLogo}
                           label="Arrastre y coloque su logo aquí"
                           gap="3"
                           style={{ width: "100%", height: "100%" }} // Ajusta el tamaño del FileDropzone al 100% del contenedor
                           nameFile="logo"
                           File={sales.GCEmpresa.logoName}
                           Type={sales.GCEmpresa.logoType}
                           Size={sales.GCEmpresa.logoSize}
                        />
                           {/* Mostrar la imagen cargada */}
                           {logoUrl && (
                              <img
                                 alt="Logo"
                                 src={logoUrl}
                                 style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    maxWidth: "90%",
                                    maxHeight: "90%",
                                    objectFit: "contain",
                                    borderRadius: "30px"
                                 }}
                              />
                           )}
                        </div>
                     </div>


                  </div>

               </div>

               <div className="flex flex-column xl:justify-content-between justify-content-end xl:w-21rem w-full relative">
                  <div className="flex-1 xl:flex lg:hidden md:hidden sm:hidden hidden justify-content-center aling-items-center">
                     <img src="https://i.ibb.co/Hn4z54w/Group-2179.png" alt="Edificio" className="absolute top-0 left-0 right-0 bottom-0 m-auto" width="336px" height="339px"></img>
                  </div>
               </div>
            </div>
         </form>
         <div className="" style={{ width: "96%", height: "1px" }}>
            <div style={{
               display: "grid",
               justifyItems: "end",
               marginTop: "20px",
               paddingRight:"35px"
            }}>
               <Button
                  //disabled={buttonsDisabled} 
                  onClick={handleClick}
                  type="button"
                  className={` ${!sales.GCEmpresa.contrato_id ? "" : "bg-green-500 border-green-500"}`}
                  label={`${!sales.GCEmpresa.contrato_id ? "Guardar" : "Actualizar"}`}
               />
            </div>
         </div>
      </>
   )
}
export default S1Customer;
import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { PickList } from "primereact/picklist";
import { InputText } from "primereact/inputtext";

import { Footer, Header } from "../../../../Pure/SeccionesNavegacion";
import "../../../../../Style/modalAgregar.css"
import carpeta from "../../../../../Images/Icons/Mis_Locales/Areas/carpeta.svg";

const ModalAgregar = ({ setvisibleAgPersonal, visibleAgPersonal, setSource, source, target, setTarget, agregarPersonal, dataAsignar, area, rowData }) => {
   const [busquedaSource, setBusquedaSource] = useState(source)
   const [busquedaTarget, setBusquedaTarget] = useState(target)

   const busquedaPersonal = (tipo, e) => {
      if (e.target.value !== '') {
         //Filtra los locales que contengan el valor del input en su nombre o apellidos
         let datosFiltrados = tipo === "source" ? source.filter(                          //Si tipo es source
            source => source.persona.nombres.toLowerCase().includes(e.target.value.toLowerCase()) || source.persona.apellido_materno.toLowerCase().includes(e.target.value.toLowerCase())
               || source.persona.apellido_paterno.toLowerCase().includes(e.target.value.toLowerCase())
         ) : target.filter(                                                               //Si tipo es target
            target => target.persona.nombres.toLowerCase().includes(e.target.value.toLowerCase()) || target.persona.apellido_materno.toLowerCase().includes(e.target.value.toLowerCase())
               || target.persona.apellido_paterno.toLowerCase().includes(e.target.value.toLowerCase())
         );
         //Actualiza los datos de busqueda con los filtrados
         if (tipo === "source") {
            setBusquedaSource(datosFiltrados)//Si tipo es source, actualiza el estado de busquedaSource
         } else if (tipo === 'target') {
            setBusquedaTarget(datosFiltrados)//Si tipo es target, actualiza el estado de busquedaTarget
         }
      }
      else {
         //Si no hay valor en el input, muestra todo el personal
         setBusquedaSource(source)
         setBusquedaTarget(target)
      }
   }

   const HideDialogAgregar = () => {
      setvisibleAgPersonal(false);
   };

   const header = (<Header icono={carpeta} titulo={'Trabajadores'} subtitulo={"Organiza a tu personal de la mejor forma"} />)

   const paqueteCreateDialogFooter = (
      <Footer
         onClickCancelar={() => {
            HideDialogAgregar();
         }}
         onClickEnviar={() => {
            //Crea un objeto con los datos de la asignación
            let data = {
               bregma_area_id: dataAsignar.bregma_area_id,
               bregma_personales: target,

               clinica_area_id: dataAsignar.clinica_area_id,
               clinica_personales: target,

               empresa_area_id: dataAsignar.empresa_area_id,
               empresa_personales: target
            }
            //Agrega el id del personal a la área
            agregarPersonal(data);
            //Cierra el modal
            setvisibleAgPersonal(false)
         }}
         label={'Guardar'}
      />
   );

   const onChange = (event) => {
      //Actualiza el estado de los datos de la fuente
      setSource(event.source);
      //Actualiza el estado de los datos del destino
      setTarget(event.target);
   };

   const sourceHeader = () => {
      return (
         <div
            className="flex flex-wrap flex-column"
            style={{ padding: "0px", margin: "0px" }}
         >
            <p>Empleados sin asignación</p>
            <div className="w-full">
               <span className="p-input-icon-left w-full">
                  <i className="pi pi-search" />
                  <InputText
                     type="search"
                     onInput={(e) => busquedaPersonal("source", e)} //Filtra los datos del personal por nombre o apellidos
                     placeholder="Buscar nombre de trabajador"
                     className="w-full"
                  />
               </span>
            </div>
         </div>
      );
   };

   const targetHeader = () => {
      return (
         <div
            className="flex flex-wrap flex-column w-full"
            style={{ padding: "0px", margin: "0px" }}
         >
            <p>Empleados asignados a esta área:</p>
            <div className="w-full">
               <span className="p-input-icon-left w-full">
                  <i className="pi pi-search" />
                  <InputText
                     type="search"
                     onInput={(e) => busquedaPersonal("target", e)}
                     placeholder="Buscar nombre de trabajador"
                     className="w-full"
                  />
               </span>
            </div>
         </div>
      );
   };

   const itemTemplate = (item) => {
      return (<p>{item.persona.nombres} {item.persona.apellido_paterno} {item.persona.apellido_materno}</p>);
   };

   useEffect(() => {
      setBusquedaSource(source);
   }, [source]);

   useEffect(() => {
      setBusquedaTarget(target);
   }, [target]);

   return (
      <div className="card flex justify-content-center">
         <Dialog
            visible={visibleAgPersonal}
            style={{ width: '50vw' }}
            className="scrollResponsive"
            header={header}
            footer={paqueteCreateDialogFooter}
            onHide={() => {
               HideDialogAgregar();
            }}
         >
            <div className="flex flex-wrap flex-column gap-2">
               <p className="tituloAgPer02">Área:</p>
               <div className="soporte-tecnico-modal">
                  <p className="tituloAgPer03">
                     {rowData?.data?.nombre}
                  </p>
               </div>
               <div className="card">
                  <PickList
                     source={busquedaSource ? busquedaSource : source}//Lista de personal disponible para asignar
                     target={busquedaTarget ? busquedaTarget : target}//Personal asignado
                     onChange={onChange}//Cambia los datos de la fuente y del destino
                     itemTemplate={itemTemplate}//Muestra los datos del personal
                     breakpoint="4000px"
                     sourceHeader={sourceHeader()}//Encabezado de la fuente
                     targetHeader={targetHeader()}//Encabezado del destino
                     filter="true"
                     sourceStyle={{ height: '8rem' }}
                     targetStyle={{ height: '8rem' }}
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
};
export default ModalAgregar;

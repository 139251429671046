import React, { useEffect, useState } from "react";
import AuthUser from "../../../AuthUser";
import { transformData } from "../../../Services/Operaciones/operaciones";
import "../../../Style/OperServicios.css";
import "../../../Style/icons.css";
import Estadisticas from '../../../Components/Estadisticas/Estadisticas'
import '../../../Style/Botones.css';
import { TabView, TabPanel } from 'primereact/tabview';
import "../../../Style/styleDonut.css";
import GraficoRadarPe from "./EstadisticaRadar";

function EstadiGeneralesE() {

   const { http } = AuthUser();
   // Trayendo datos GET
   const [servicios, setServicios] = useState([]);
   const [categories, setcategories] = useState("");
   const [key, setKey] = useState(0);

   const getServicios = () => {
      http
         .get("bregma/operaciones/servicios/get")
         .then((response) => {
            setServicios(transformData([response.data]));
            setcategories(Object.keys(response.data));
         })
         .catch((e) => {
            console.error(e);
         });
   };

   const [selectedCategory, setSelectedCategory] = useState(false);
   const handleClick = (key, category) => {
      setKey(key);
      setSelectedCategory(true);
   };

   const changue_name_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "Áreas Médicas";
         case "capacitaciones":
            return "Capacitaciones";
         case "examenes":
            return "Examen de ayuda al diagnóstico ";
         case "laboratorios":
            return "Laboratorio Molecular";
         default:
            return "";
      }
   };

   const changue_item_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "i-plus-green";
         case "capacitaciones":
            return "i-person-blue";
         case "examenes":
            return "i-diagnostic-vector-orange";
         case "laboratorios":
            return "i-lab-genomica-purple";
         default:
            return "";
      }
   };

   const changue_bg_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "services__content_section_one_content_item_icon_green";
         case "capacitaciones":
            return "services__content_section_one_content_item_icon_blue";
         case "examenes":
            return "services__content_section_one_content_item_icon_orange";
         case "laboratorios":
            return "services__content_section_one_content_item_icon_purple";
         default:
            return "";
      }
   };
   return (
      <>
         <div className="flex flex-column gap-4 text-sm min-h-full">
            <span className="tit ">Estadísticas Generales </span>
            <span className="con ">En este módulo usted podrá llevar control de los empleados de la empresa y las sedes de la empresa.</span>
            <TabView className="Contenedorestadisticasestadisticasgenerales">
               <TabPanel header="Analisis Individual">
                  <Estadisticas />
               </TabPanel>
               {/* <TabPanel header="Analisis Grupal">
                  <GraficoRadarPe />
               </TabPanel> */}

            </TabView>
         </div>
      </>
   );
}

export default EstadiGeneralesE;

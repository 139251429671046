//Importaciones de componentes generales
import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { Toast } from "primereact/toast";
import { ProgressBar } from "primereact/progressbar";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { FileUpload } from "primereact/fileupload";
import { useDispatch } from "react-redux";

//Componentes generales
import { Footer, Header } from "../../../Pure/SeccionesNavegacion";
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";
import { udpateLogoPerfil } from "../../../../Redux/authSlice"
import AuthUser from "../../../../AuthUser";

import icono from "../../../../Images/Perfil/icon/ActualizarUsuario.svg";

//Importaciones de estilos propios
import "../../../../Style/usuario/PERSONA.css";

// Componente principal para actualizar la información de la persona
export default function UpdatePersonaComponent({ personaUpdate, visible, setVisible, getPersona}) {
   const { http } = AuthUser();
   const toast = useRef(null);
   const dispatch = useDispatch();

   // Manejador de clic para la selección de archivos
   const handleClick = () => {
      if (fileUploadRef.current) {
         fileUploadRef.current.choose();
      }
   };

   const [foto, setFoto] = useState({ foto: null });
   const [datosPersona, setDatosPersona] = useState(personaUpdate);

   // eslint-disable-next-line react-hooks/exhaustive-deps
   useEffect(() => { setDatosPersona(personaUpdate) }, [visible])

   const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined', onClick: handleClick, };
   const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
   const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

   const initialValues = {
      id: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      celular: "",
      correo: "",
      foto: "",
      delete_image: ''
   };

   // Función para mostrar notificaciones
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   // Función para ocultar el cuadro de diálogo
   const HideDialogEdit = () => {
      setVisible(false);
      setDatosPersona(personaUpdate);
   };

   // Configuración de Formik para manejar el formulario
   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         const formDataSend = new FormData();
         formDataSend.append("nombres", data.nombres);
         formDataSend.append("apellido_paterno", data.apellido_paterno);
         formDataSend.append("apellido_materno", data.apellido_materno);
         formDataSend.append("celular", data.celular);
         formDataSend.append("correo", data.correo);
         formDataSend.append("foto", data.foto);
         formDataSend.append('delete_image', false);
         http
            .post(`persona/update/${data.id}`, formDataSend)
            .then((response) => {
               showToast(
                  "success",
                  `Actualizado correctamente`,
                  `Se actualizó correctamente`
               );
               getPersona();
               HideDialogEdit();
            })
            .catch((error) => {
               console.error(error);
            });
      }
   });

   // Función para actualizar los valores del formulario con la información de la persona
   const updatePersonal = () => {
      formik.setValues({
         id: personaUpdate?.id,
         nombres: personaUpdate?.nombres,
         apellido_paterno: personaUpdate?.apellido_paterno,
         apellido_materno: personaUpdate?.apellido_materno,
         celular: personaUpdate?.celular,
         correo: personaUpdate?.correo,
         foto: personaUpdate?.foto,
      });
   };

   // Subir imagen
   const [totalSize, setTotalSize] = useState(0);
   const fileUploadRef = useRef(null);

   const headerTemplate = (options) => {
      const { className, chooseButton, uploadButton, cancelButton } = options;
      const value = totalSize / 20000;
      const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

      return (
         <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
            {chooseButton}
            {uploadButton}
            {cancelButton}
            <div className="flex align-items-center gap-3 ml-auto">
               <span>{formatedValue} / 2 MB</span>
               <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
            </div>
         </div>
      );
   };

   const onTemplateSelect = (e) => {
      let _totalSize = totalSize;
      let files = e.files;

      Object.keys(files).forEach((key) => {
         _totalSize += files[key].size || 0;
      });

      setTotalSize(_totalSize);
   };

   const onTemplateUpload = (e) => {
      let _totalSize = 0;

      e.files.forEach((file) => {
         _totalSize += file.size || 0;
      });

      setTotalSize(_totalSize);
   };

   const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      setFoto({ ...foto, foto: null });
      callback();
   };

   const onRemoveTemplate = () => {
      setDatosPersona(prevState => ({ ...prevState, foto: null }));
      setFoto({ ...foto, foto: null });
   };

   const onTemplateClear = () => {
      setTotalSize(0);
   };


   const itemTemplate = (file, props) => {
      return (
         <div className="flex align-items-center">
            <div className="flex align-items-center gap-3">
               <img alt={file.name} role="presentation" src={file.objectURL} width={100}/>
               <span className="flex flex-column text-left">
                  {file.name}
                  <small>{new Date().toLocaleDateString()}</small>
                  <div className="mt-2">
                     <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                  </div>
               </span>
            </div>
            <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={() => onTemplateRemove(file, props.onRemove)} />
         </div>
      );
   };

   const emptyTemplate = () => {
      return (
         datosPersona?.foto ?
            (<div className="flex align-items-center justify-content-around">
               <div className="flex align-items-center gap-3">
                  <img alt={datosPersona?.foto} role="presentation" src={datosPersona?.foto} width={100} />
                  <span className="flex flex-column text-left">
                     {datosPersona?.nombres}
                     <small>{new Date().toLocaleDateString()}</small>
                  </span>
               </div>
               <Button type="button" icon="pi pi-times" className="p-button-rounded p-button-danger" onClick={onRemoveTemplate} />
            </div>)
            : (<div className="flex align-items-center flex-column">
               <i className="pi pi-upload" style={{ fontSize: '29px', borderRadius: '50%', color: 'rgba(85, 85, 216, 0.8)' }}></i>
               <span style={{ fontSize: '14px', color: 'rgba(85, 85, 216, 0.5)', fontWeight: '400' }}>
                  Arrastre y coloque su logo aquí
               </span>
            </div>)
      );
   };

   // Función para subir la foto
   const subirFoto = async () => {
      const formDataSend = new FormData();
      formDataSend.append("foto", foto.foto);
      if (foto.foto && personaUpdate?.foto) {
         formDataSend.append("delete_image", false);
      } else if (!foto.foto) {
         formDataSend.append("delete_image", true);
      }
      dispatch(startLoading());
      try {
         const response = await http.post(`persona/update/foto/${personaUpdate?.id}`, formDataSend);
         if (response?.data?.resp) {
            await Promise.all([
               setVisible(false),
               getPersona(),
               setDatosPersona(prevState => ({ ...prevState, foto: response?.data?.code?.foto ?? null }))
            ]);
            dispatch(udpateLogoPerfil(response?.data?.code?.foto ?? null))
            showToast(
               "success",
               "Actualizado correctamente",
               "Se subió la foto de perfil correctamente"
            );
         } else if (response?.data?.error) {
            showToast('error', 'Error', response?.data?.error);
         }
      } catch (error) {
         showToast(
            "error",
            "Error al actualizar",
            'Contacte a soporte técnico'
         );
         console.error("algo salio mal", error);
      } finally {
         dispatch(endLoading());
      }
   };

   const paqueteCreateDialogFooter = (
      <Footer onClickCancelar={() => { setVisible(false); setDatosPersona(personaUpdate); }} onClickEnviar={subirFoto} type={'Button'} label={'Actualizar'} />
   );

   // Manejador del cambio de imagen
   const handleChangeImagen = (e) => {
      setFoto({ foto: e?.files[0] });
   };

   // Actualizar información de la persona cuando cambie
   useEffect(() => {
      updatePersonal();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [personaUpdate]);

   return (
      <div>
         <form id="persona-form-edit" className="persona-form-edit" noValidate onSubmit={formik.handleSubmit}></form>
         <Dialog
            header={<Header icono={icono} titulo={"Foto de perfil"} subtitulo={"Inserte una foto de perfil"} />}
            visible={visible} style={{ width: '50vw', maxWidth: '446px' }} className="form3-container"
            appendTo={document.getElementById("persona-form-edit")}
            dismissableMask
            footer={paqueteCreateDialogFooter}
            onHide={() => HideDialogEdit()}>
            <div style={{ marginTop: '10px' }}>
               <div className="flex flex-column gap-3 mb-4">
                  <div className="body-form flex flex-wrap w-full h-full md:flex-row gap-3">
                     {/* Subir foto */}
                     <div className="flex flex-column md:flex-wrap gap-3 flex-1">
                        <div>
                           <span style={{ fontWeight: '500', fontSize: '14px' }}>Agregar una imagen</span>
                        </div>
                        <div>
                           <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                           <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                           <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
                           <FileUpload
                              ref={fileUploadRef}
                              id="foto"
                              name="foto[]"
                              accept="image/*"
                              maxFileSize={2000000}
                              auto
                              customUpload
                              uploadHandler={handleChangeImagen}
                              onUpload={onTemplateUpload}
                              onSelect={onTemplateSelect}
                              onError={onTemplateClear}
                              onClear={onTemplateClear}
                              headerTemplate={headerTemplate}
                              itemTemplate={itemTemplate}
                              emptyTemplate={emptyTemplate}
                              chooseOptions={chooseOptions}
                              uploadOptions={uploadOptions}
                              cancelOptions={cancelOptions}
                           />
                        </div>
                        <div className="flex flex-column gap-3">
                           <div style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>
                              <p>Recomendaciones:</p>
                           </div>
                           <div className="flex flex-column gap-1" style={{ fontWeight: "400", fontSize: "14px", color: "#7B8794" }}>
                              <p>*Peso máximo de imagen 2 Mb</p>
                              <p>*Tamaño de 300 x 300px</p>
                              <p>*Imagen en formato .jpg</p>
                              <p>*Formato de buena calidad</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
         <Toast ref={toast} />
      </div>
   )
}
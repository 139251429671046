import React, { useContext, useState, useEffect } from "react";
import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import { MGContext } from "../../MedicinaGeneral/MGProvider";
import { RAContext } from "../../RutaAtencion/RAContext";
import AuthUser from "../../../AuthUser";
import TriajeAntPerAdd from "../../../Components/Triaje/AntPer/TriajeAntPerAdd";
import TriajeAntPerTable from "../../../Components/Triaje/AntPer/TriajeAntPerTable";
import "../../../Style/Triaje/AntecedentePer/triaje_ant_per.css";
import './PageTriajeAntPer_AndH.css';

const PageTriajeAntPer = () => {

   const { http } = AuthUser();

   const { antPerContext, setAntPerContext } = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);


   const categories = [
      { name: "Tétanos", id: 199 },
      { name: "Gripe", id: 200 },
      { name: "Hepatitis B", id: 201 },
      { name: "COVID 19 ", id: 202 },
      { name: "Fiebre Amarilla ", id: 203 },
   ];
   const [comentarios, setcomentarios] = useState(antPerContext?.inmunizaciones ?? '');

   // const [paciente, setpaciente] = useState();

   // Creado las variables donde se van a almacenar las patologias                           (1)
   const [patologias, setPatologias] = useState([]);
   //Funcion para obtener las patologias desde la base de datos mediante la API
   const getPatologias = () => {
      http
         .get("patologias1/get")
         .then((response) => {
            setPatologias(response.data.resp);
            console.error("data", response.data.resp);
         })
         .catch(() => {
            console.error("Algo mal");
         });
   };

   //Para llamar a la función
   useEffect(() => {
      getPatologias();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [isDictating, setIsDictating] = useState(false);
   const [dictatedText, setDictatedText] = useState('');
   const webkitSpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
   const recognition = new webkitSpeechRecognition();

   const handleDictation = () => {
      if (!isDictating) { // Si no está dictando, inicia el dictado de voz
         if ('webkitSpeechRecognition' in window) {
            const recognition = new webkitSpeechRecognition();

            recognition.lang = 'es-ES'; // Establece el idioma de reconocimiento de voz (puedes cambiarlo según tus necesidades).
            recognition.continuous = false; // Configura el reconocimiento para que se detenga después de una única frase.

            recognition.onresult = (event) => {
               const transcript = event.results[0][0].transcript;
               setDictatedText(transcript); // Actualiza el estado 'dictatedText' con el texto obtenido.
            };

            recognition.onend = () => {
               setIsDictating(false); // Cuando se detiene el reconocimiento, marca que no se está dictando.
            };

            recognition.start(); // Inicia el reconocimiento de voz.
            setIsDictating(true); // Marca que se está dictando.
         } else {
            // El navegador no admite la API de reconocimiento de voz.
            alert('Tu navegador no admite el reconocimiento de voz.');
         }
      } else { // Si está dictando, detiene el dictado de voz
         recognition.stop(); // Detiene el reconocimiento de voz
         setIsDictating(false); // Marca que no se está dictando
      }
   };

   return (
      <>
         <TriajeAntPerAdd
            header="Paciente"
            data={antPerContext?.med_ant_patologia_personal}
            patologias={patologias}
         />

         <Divider />

         <div className="tap_form_title Cuadro_inmunizacion_andh" style={{ display: "flex", gap: '4rem' }}>
            {/* <h3>Inmunizaciones</h3> */}
            <div className="cchekcboxs_andH">
               <TriajeAntPerAdd
                  header="Inmunizaciones"
                  data={antPerContext.med_ant_inmunizaciones_personal}
                  patologias={categories}
               />
            </div>
            <div className="tap_form_textarea comentario_andH_2 flex flex-row w-full" style={{ width: '100%', paddingTop: '7px' }}>
               <div className="flex flex-column w-full">
                  <InputTextarea
                     rows={7}
                     style={{ width: "100%" }}
                     autoResize
                     value={comentarios || dictatedText}
                     onChange={(e) => {
                        setcomentarios(e.target.value); // Actualizar el estado 'comentarios'
                        setAntPerContext(prevState => ({ // Actualizar el estado 'antPerContext' con el nuevo valor de 'comentarios'
                           ...prevState,
                           inmunizaciones: e.target.value
                        }));
                     }}
                     placeholder="Si no está en la lista, escriba aquí"
                     className="Cuadro_Comentario_Anhs_2"
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
               </div>
               <div className="flex flex-column justify-content-end" style={{ marginLeft: "-50px", marginBottom: "5px" }}>
                  <button onClick={handleDictation}
                     style={{ background: "rgb(85, 85, 216)", color: "white", width: "36px", borderRadius: "6px", border: "1px solid rgb(99, 102, 241)", cursor: "pointer" }}
                  >
                     {isDictating ? 'Off' : 'On'}
                  </button>
               </div>
            </div>
         </div>

         <div className="tap_form_title">
            <h3>
               Absentismo por accidente o enfermedades (asociados o no al trabajo)
            </h3>
         </div>

         <TriajeAntPerTable />
      </>
   );
};

export default PageTriajeAntPer;

import React from 'react';
import OftAntOculForm from "../../../Components/Oft/AntecedenteOcular/OftAntOculForm";
import OftAntSistForm from "../../../Components/Oft/AntecedenteSistemico/OftAntSistForm";
import "../../../Style/OfCSS.css";
import { iconOft } from "../../../Services/importIcons";
import { Accordion, AccordionTab } from 'primereact/accordion';

const PageOftAnt = () => {

   return (
      <>
         <div className="oft_ant_titles" style={{ marginBottom: "20px" }}>
            <div>
               <img src={iconOft} alt="Icon" />
            </div>
            <h3>Antecedentes</h3>
         </div>

         <Accordion activeIndex={0} className='Acordioon_Antecerentes'>
            <AccordionTab header="ANTECEDENTES OCULARES">
               <div className="Contenedor_Ofc ">
                  <OftAntOculForm />
               </div>
            </AccordionTab>
            <AccordionTab header="ANTECEDENTES SISTÉMICOS">
               <div className="Contenedor_Ofc1">
                  <OftAntSistForm />
               </div>
            </AccordionTab>
         </Accordion>
      </>
   );
};

export default PageOftAnt;
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import "../../Style/styleDonut.css";

const GraficoBar = ({ labels, datasets, titles = [], card, titlesClassName = [] }, labelshidden) => {
  /*Grafico de Barras*/
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--green-500');
    const textColorSecondary = documentStyle.getPropertyValue(
      '--text-color-secondary'
    );
    const textColorPrimary = documentStyle.getPropertyValue(
      '--text-color-primary'
    );
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    const data = {
      labels,
      datasets:
        datasets?.length === 1
          ? [
            {
              label: datasets[0].label,
              backgroundColor: datasets[0].backgroundColor || [
                'rgba(255, 99, 132, 0.2)',
              ],
              borderColor: datasets[0].borderColor || [
                'rgb(255, 99, 132)',
              ],
              data: datasets[0].data,
              borderWidth: datasets[0].borderWidth === 0 ? 0 : datasets[0].borderWidth || 1
            },
          ]
          : datasets?.map((dataset) => ({
            label: dataset.label,
            backgroundColor: dataset.backgroundColor,
            borderColor: dataset.borderColor,
            data: dataset.data,
            borderWidth: dataset.borderWidth,
          })),
    };

    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          display: labelshidden ? false : true,
          labels: {
            fontColor: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
            font: {
              weight: 800,
            },
          },
          grid: {
            display: false,
            drawBorder: false,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, [datasets, labels]);

  return (
    <div className={card ? 'card' : ''}>
      <p className={`TituloSuperior text-center ${titlesClassName[0] || 'font-bold text-3xl'}`}>{titles[0] || ''}</p>
      <div className="flex align-items-center text-xl">
        <p className={`titulovolteado -rotate-90 ${titlesClassName[2] || 'font-bold'}`}>{titles[2] || ''}</p>
        <Chart
          className={`flex ${titles[2] ? 'w-11' : 'w-full'} `}
          type="bar"
          data={chartData}
          options={chartOptions}
        />
      </div>
      <p className={`tituloinferior text-center ${titlesClassName[1] || 'font-bold text-xl'}`}>{titles[1] || ''}</p>
    </div>
  );
}

export default GraficoBar;
import React, { useState, useEffect, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ModalTicket from './ModalTicket';
import AuthUser from '../../../AuthUser';

const Entregable = ({ data, visibleEntregable, setVisibleEntregable, examenes, generatePDF, pdfUrl, configIp, setConfigIp }) => {
   const [visibleTicket, setVisibleTicket] = useState(false);
   const [docs, setDocs] = useState([])
   const [visiblePdf, setVisiblePdf] = useState(false)

   const [mostrarDialog, setMostrarDialog] = useState(false);
   const ref_file = useRef(null)
   const [namefile, setNamefile] = useState(null);
   const [file, setFile] = useState(null);
   const { http } = AuthUser();
   const [urlPDF, setUrlPDF]= useState(`https://apidev.bregma.com.pe/public/api/documentos/hoja-ruta/${data.id}`);

   const getUrlPDF = () => {
      http
          .get(`documentos/hoja-ruta/${data.id}`)
          .then((response) => {
              setFile(response);
          })
          .catch((error) => {
            console.error("Error al obtener archivos pdf", error)
        });
  }

   const BotonConsent = () => {
      return (
         <Button
            onClick={() => {
               getUrlPDF();
               setMostrarDialog(true);
            }}
            style={{ width: "75px" }}>
            <i className="fa-solid fa-briefcase-medical" style={{ fontSize: "2rem" }}></i>
         </Button>

      )
   }

   const BotonTiquet = () => {
      const handleClick = () => {
         setVisibleTicket(true);
      };
      return (
         <Button
            onClick={handleClick}
            style={{ width: "75px" }}>
            <i className="pi pi-ticket" style={{ fontSize: "2rem" }}></i>

         </Button>
      )
   }

   const renderizarDocumento = (documento) => {
      if (documento === 'botonConsent') {
         return <BotonConsent />;
      }
      if (documento === 'botonTiquet') {
         return <BotonTiquet />;
      }
   }

   const DocConsentimiento = {
      getDocsData() {
         return [
            {
               item: '1',
               nombre: 'Hoja de ruta',
               documento: 'botonConsent'
            },
            {
               item: '2',
               nombre: 'Tiquet de laboratorio',
               documento: 'botonTiquet'
            },
         ]
      }
   }

   useEffect(() => {
      setDocs(DocConsentimiento.getDocsData());
   }, []);

   return (<>
      <Dialog
         visible={visibleEntregable}
         style={{ margin: "20px", width: "850px" }}
         onHide={() => setVisibleEntregable(false)}
         header={<div className="">
            <h1 className='mb-2 titulo_entregable flex justify-content-center align-items-center'>Entregables</h1>
         </div>}>

         <p style={{ color: '#4545bc8a' }}>Cargue los entregables del paciente</p>
         <DataTable
            value={docs}
            tableStyle={{ minWidth: '50rem' }}>
            <Column field="item" align="center" header="Item" />
            <Column field="nombre" header="Documento" style={{ textAlign: 'center' }} />
            <Column
               body={rowData => renderizarDocumento(rowData.documento)}
               field="documento"
               header="Descarga"
               style={{ display: "flex", justifyContent: "center", alignItems: "center" }} />
         </DataTable>

      </Dialog>
      <ModalTicket
         visibleTicket={visibleTicket}
         setVisibleTicket={setVisibleTicket}
         examenes={examenes}
         pdfUrl={pdfUrl}
         generatePDF={generatePDF}
         configIp={configIp}
         setConfigIp={setConfigIp}
      />

      <Dialog
         visible={mostrarDialog}
         onHide={() => setMostrarDialog(false)}
         header="Vista Previa del PDF"
         modal
         style={{ width: '80vw', maxHeight: '80vh', height: "80vh" }}
      >
         <iframe
            src={urlPDF}
            width="100%"
            height="100%"
            title="Vista Previa del PDF"
         />
      </Dialog>
   </>
   )
}

export default Entregable;
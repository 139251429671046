import React, { useContext, useState } from 'react'
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

//Componentes generales
import { GestionContext } from '../../../Page/EmpresaRol/GestionAtencion/services/GestionAtencionContext';
import HojaRutaComponent from './components/HojaRuta';

//Estilos
import "./style/card-clinica.css"
import "./style/side-paciente.css"
import "./style/tabla-admin.css"

import hojaRuta from './images/hoja-ruta.svg';

export default function SidePaciente() {

   const { pacientes } = useContext(GestionContext);
   //prueba
   const [visibleTrazabilidad, setVisibleTrazabilidad] = useState(false); //Nuevo estado para controlar el mensaje emergente trazabilidad
   const [personal, setPersonal] = useState({})

   const handleTrazabiliadad = (item) => {
      setPersonal(item)
      setVisibleTrazabilidad(true); // Mostrar el mensaje emergente trazabilidad
   };


   //button
   const buttonDetails = (rowData) => {
      switch (rowData) {
         case "0":
            return <Tag value="Programado"></Tag>;
         case "1":
            return <Tag severity="warning" value="En atención"></Tag>;
         case "2":
            return <Tag severity="success" value="Atendido"></Tag>;
         case "3":
            return <Tag value="New"></Tag>;
         default:
            return <Tag value="No programado"></Tag>;
      }
   };

   return (

      <div className="flex flex-auto  flex-column" >
         <table className='Tabla_lt' style={{ marginTop: '20px' }}>
            <thead >
               <tr style={{ height: '70px', textAlign: "center" }}>
                  <th className="border-bottom-1 border-top-1 border-gray-300">Lista de trabajadores</th>
                  <th className="border-bottom-1 border-top-1 border-gray-300">Tipo de atención</th>
                  <th className="border-bottom-1 border-top-1 border-gray-300">Hoja de ruta</th>
                  <th className="border-bottom-1 border-top-1 border-gray-300">Estado</th>
                  <th className="border-bottom-1 border-top-1 border-gray-300">Resultado</th>
               </tr>
            </thead>
            <tbody >
               {pacientes?.length > 0 ? pacientes.map((item, index) => (
                  <React.Fragment key={item.id}>
                     <tr className='mt-4' style={{ height: '60px' }}>
                        <td data-label="Lista de trabajadores" style={{ borderLeft: '0px', marginTop: '10px' }}>
                           {/* <Checkbox className='mr-2' /> */}
                           {item.nombres} {item.apellido_paterno} {item.apellido_materno}
                        </td>
                        <td data-label="Tipo de atención">
                           <Tag value={item.paquete_nombre}></Tag>

                        </td>
                        <td data-label="Hoja de ruta">
                           <Button style={{ backgroundColor: "#EBEBFF" }} onClick={() => handleTrazabiliadad(item)}>
                              <img alt='image1' src={hojaRuta}></img>
                           </Button>
                        </td>
                        <td data-label="Estado">{buttonDetails(item?.estado_paciente)}</td>
                        <td data-label="Resultado" className='align-items-center h-4rem ' style={{ cursor: 'pointer' }}>
                           <i className='pi pi-download'></i>Descargar
                        </td>
                     </tr>
                  </React.Fragment>
               )) :
                  <tr >
                     <td colSpan={5} style={{ textAlign: 'center' }}> No hay pacientes asignados</td>
                  </tr>

               }
            </tbody>
         </table>

         <HojaRutaComponent
            personal={personal}
            visible={visibleTrazabilidad}
            setVisible={setVisibleTrazabilidad}
         />
      </div>
   );
}

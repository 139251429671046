import React, { useContext, useEffect, useState } from "react";
import EnfermedadVisual from "./Selects/EnfermedadVisual";
import Vision_colores from "./Selects/VisionColores";
import Reflejos_pupilares from "./Selects/ReflejosPupilares";
import Tonometriacomponent from "./Selects/Desplegables/Tonometria";
import Examen_Externo from "./Selects/Desplegables/Examenesterno";
import Presion_intraucular from "./Selects/PresionIntraucular";
import '../../../Style/oft_agud_visual.css';
import { OftContext } from "../OftContext";
import AuthUser from "../../../AuthUser";
import { Dropdown } from "primereact/dropdown";
import { Fieldset } from "primereact/fieldset";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { RAContext } from "../../RutaAtencion/RAContext";

export default function ParteInferior_Adgudeza_Visual() {
    const { http } = AuthUser();

    let { agudVisualContext, setAgudVisualContext } = useContext(OftContext);
    const [Enfermedad_ocular, setEnfermedad_ocular] = useState(agudVisualContext?.enfermedad_ocular ?? null);
    const [vision_colores, setvision_colores] = useState(agudVisualContext?.vision_colores ?? null);
    const [reflejos_pupilares, setreflejos_pupilares] = useState(agudVisualContext?.reflejos_pupilares ?? null);
    const [presion_intraocular, setpresion_intraocular] = useState(agudVisualContext?.presion_intraocular ?? null);
    const [Ton_OjoDer, setTon_OjoDer] = useState(agudVisualContext?.tonometria?.ojo_derecho ?? null);
    const [Ton_OjoIzq, setTon_OjoIzq] = useState(agudVisualContext?.tonometria?.ojo_izquierdo ?? null);
    const [Ee_OjoDer, setEe_OjoDer] = useState(agudVisualContext?.examen_externo?.ojo_derecho ?? null);
    const [Ee_OjoIzq, setEe_OjoIzq] = useState(agudVisualContext?.examen_externo?.ojo_izquierdo ?? null);
    const [Ee_ExClin, setEe_ExClin] = useState(agudVisualContext?.examen_externo?.examen_clinico ?? null);
    let { pageViewContext, stateAtencion } = useContext(RAContext)

    const optionsVisual = [
        { label: 'Presente', id: 'Presente' },
        { label: 'Ausente', id: 'Ausente' }
    ];

    const optionsColores = [
        { label: 'Normal', id: 'NORMAL' },
        { label: 'Deuteranomalía', id: 'DEUTEROANOMALIA' },
        { label: 'Protanomalía', id: 'PROTANOMALIA' },
        { label: 'Tritanomalía', id: 'TRITANOMALIA' },
        { label: 'Dicromatismo', id: 'DICROMATISMO' },
        { label: 'Monocromatismo', id: 'MONOCROMATISMO' }
    ];

    const optionsReflejos = [
        { label: 'Conservado', id: 'CONSERVADO' },
        { label: 'No Conservado', id: 'NO CONSERVADO' }
    ];

    const optionsIntra = [
        { label: 'Normal', id: 'NORMAL' },
        { label: 'Sospecha de glaucoma', id: 'SOSPECHA DE GLAUCOMA' },
        { label: 'Glaucoma', id: 'GLAUCOMA' }
    ];

    const ojo = [
        { label: 'Normal', id: 'NORMAL' },
        { label: 'Anormal', id: 'ANORMAL' }
    ];
    useEffect(() => {
        setAgudVisualContext({
            ...agudVisualContext,
            [`enfermedad_ocular`]: Enfermedad_ocular,
            [`vision_colores`]: vision_colores,
            [`reflejos_pupilares`]: reflejos_pupilares,
            [`presion_intraocular`]: presion_intraocular,
            [`tonometria`]: {
                [`ojo_derecho`]: Ton_OjoDer,
                [`ojo_izquierdo`]: Ton_OjoIzq
            },
            [`examen_externo`]: {
                [`ojo_derecho`]: Ee_OjoDer,
                [`ojo_izquierdo`]: Ee_OjoIzq,
                [`examen_clinico`]: Ee_ExClin
            }
        })
    }, [Enfermedad_ocular, vision_colores, reflejos_pupilares, presion_intraocular, Ton_OjoDer, Ton_OjoIzq, Ee_OjoDer, Ee_OjoIzq, Ee_ExClin]);

    return (
        <div className="flex flex-wrap gap-3 juamp">
            <div className="flex flex-column flex-1 gap-2" style={{ width: "100%" }}>
                <div className="flex flex-row gap-2 ramp" style={{ alignItems: 'center' }}>
                    <span style={{ width: '100%', textAlign: "center", padding: "5px" }}>Enfermedad ocular:</span>
                    <Dropdown
                        options={optionsVisual}
                        optionValue="id"
                        value={Enfermedad_ocular}
                        onChange={e => setEnfermedad_ocular(e.value)}
                        className="w-full"
                        placeholder="Seleccionar"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>

                <div className="flex flex-row gap-2 ramp" style={{ alignItems: 'center' }}>
                    <span style={{ width: '100%', textAlign: "center", padding: "5px" }}>Visión de colores:</span>
                    <Dropdown
                        options={optionsColores}
                        optionValue="label"
                        value={vision_colores}
                        onChange={e => setvision_colores(e.value)}
                        className="w-full"
                        placeholder="Seleccionar"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>

                <div className="flex flex-row gap-2 ramp" style={{ alignItems: 'center' }}>
                    <span style={{ width: '100%', textAlign: "center", padding: "5px" }}>Reflejos pupilares:</span>
                    <Dropdown
                        options={optionsReflejos}
                        optionValue="label"
                        value={reflejos_pupilares}
                        onChange={e => setreflejos_pupilares(e.value)}
                        className="w-full"
                        placeholder="Seleccionar"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>

                <div className="flex flex-row gap-2 ramp" style={{ alignItems: 'center' }}>
                    <span style={{ width: '100%', textAlign: "center", padding: "5px" }}>Presión intraocular:</span>
                    <Dropdown
                        options={optionsIntra}
                        optionValue="label"
                        value={presion_intraocular}
                        onChange={e => setpresion_intraocular(e.value)}
                        className="w-full"
                        placeholder="Seleccionar"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>

                <>
                    <Fieldset legend="Tonometría" toggleable>
                        <div className='flex flex-wrap gap-2'>
                            <div className='flex flex-row gap-2 flex-1' style={{ alignItems: 'center', display: "flex", flexWrap: "wrap" }}>
                                <span style={{ width: '100%', textAlign: 'left', fontWeight: 'bold', color: 'gray' }}>Ojo Izquierdo</span>
                                <InputNumber className='w-full' style={{ textAlign: 'center' }} placeholder='0' value={Ton_OjoIzq} onChange={(e) => setTon_OjoIzq(e.value)} disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false}
                                    locale="en-US"
                                    maxFractionDigits={2} />
                            </div>
                            <div className='flex flex-row gap-2 flex-1' style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}>
                                <span style={{ width: '100%', textAlign: 'left', fontWeight: 'bold', color: 'gray' }}>Ojo Derecho</span>
                                <InputNumber className='w-full' style={{ textAlign: 'center' }} placeholder='0' value={Ton_OjoDer} onChange={(e) => setTon_OjoDer(e.value)} disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false}
                                    locale="en-US"
                                    maxFractionDigits={2} />
                            </div>
                        </div>
                    </Fieldset>
                </>
            </div>
            <div className="flex flex-column flex-1 gap-2">
                <>
                    <Fieldset legend="Examen Externo" toggleable>
                        <div style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '18px', fontFamily: 'Montserrat', borderBottom: '2px solid gray', padding: '4px' }}>Párpados</div>
                        <div className="flex flex-wrap w-full gap-3">
                            <div className="ojoderecho flex-1" style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ border: '0px solid transparent', fontSize: "17px", textAlign: 'left', fontWeight: 'bold', color: 'gray', margin: '10px 0px' }}>Ojo Izquierdo:</div>
                                <Dropdown
                                    options={ojo}
                                    optionValue="label"
                                    value={Ee_OjoIzq}
                                    onChange={e => setEe_OjoIzq(e.value)}
                                    className="w-full"
                                    placeholder="Seleccionar"
                                    disabled={stateAtencion[pageViewContext].estado === 3}
                                />
                            </div>
                            <div className="ojoderecho flex-1" style={{ display: "flex", flexDirection: "column" }}>
                                <div style={{ border: '0px solid transparent', fontSize: "17px", textAlign: 'left', fontWeight: 'bold', color: 'gray', margin: '10px 0px' }}>Ojo Derecho:</div>
                                <Dropdown
                                    options={ojo}
                                    optionValue="label"
                                    value={Ee_OjoDer}
                                    onChange={e => setEe_OjoDer(e.value)}
                                    className="w-full"
                                    placeholder="Seleccionar"
                                    disabled={stateAtencion[pageViewContext].estado === 3}
                                />
                            </div>
                        </div>
                        <div className="flex flex-column gap-2">
                            <span style={{ textAlign: 'left', fontWeight: 'bold', color: 'gray', fontSize: "17px", margin: '18px 0px 10px 0px' }}>Examen Clínico Ocular Externo</span>
                            <InputTextarea autoResize value={Ee_ExClin} onChange={(e) => setEe_ExClin(e.target.value)} rows={4} disabled={stateAtencion[pageViewContext].estado === 3} />
                        </div>
                    </Fieldset>
                </>
            </div>
        </div>
    )
}


import { Button } from "primereact/button"
import Hospital from '../../../../Images/Hospital2 1.png'


export default function CardClientesGClientes () {
    return(
        <div className="contenedor-locales flex-column shadow-1 border-round-xl p-3 gap-3">
            <div className="contenedor-img-local gap-3">
                <div className="mt-2 p-2 bg-indigo-100 border-circle align-items-center justify-content-center">
                    <img src={Hospital} className="hospital_Imagen pt-1 mx-1"/>
                </div>
                <div className="flex flex-column">
                    <p className="text-cyan-300 font-bold text-right bg-cyan-100 ml-auto px-2 border-round-lg">Habilitado</p>
                    <h2 className="text-indigo-500">Local 01</h2>
                    <p  className="text-500">Los Eucaliptos 438, San Juan de Lurigancho 15412</p>
                </div>
                
            </div>
            <div className="flex flex-column gap-2 px-3">
                <div className="Nomb_local justify-content-between">
                    <label className="font-semibold text-500">Nombres</label>
                    <label>Local A</label>
                </div>
                <div className="Departament justify-content-between">
                    <label className="font-semibold text-500">Departamento</label>
                    <label>Lima</label>
                </div>
                <div className=" provincia justify-content-between">
                    <label className="font-semibold text-500">Provincia</label>
                    <label>Lima</label>
                </div>
                <div className="distrit justify-content-between">
                    <label className="font-semibold text-500">Distrito</label>
                    <label>SJL</label>
                </div>
            </div>
            <div className="button_act pt-3 border-400 border-top-1 justify-content-between">
                <div className="boton_edit_E gap-2 px-1">
                    <Button className="border-none bg-red-100" icon="pi pi-trash text-xl text-red-500" severity="help" aria-label="Favorite" onClick={() => ''}/>
                    <Button className="border-none bg-green-100" icon="pi pi-pencil text-xl text-green-500" severity="help" aria-label="Favorite" onClick={() => ''} />
                </div>
                <div className="flex flex-column justify-content-end">
                    <i className="pi pi-calendar ml-auto text-500" >14.08.2022</i>
                    <label className="ultimaAct font-semibold text-500">Última actualización</label>
                </div>
            </div>
        </div>
    )
}
import React, {
    useContext,
    useState
} from 'react';
import { TabPanel, TabView } from "primereact/tabview";
import { LaboratorioContext } from "./LaboratorioProvider";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconLab } from "../../Services/importIcons";
import { Accordion, AccordionTab } from 'primereact/accordion';
import TablaRH from '../../Components/Laboratorio/TablaRH';
import Hemograma from './Hemograma/Hemograma';
import TablaGlucosa from '../../Components/Laboratorio/Glucosa/glucosa';
import ExamenCompletoDeOrina from '../../Components/Laboratorio/ExamenCompletoDeOrina';
import ViewColesterol from '../../Components/Laboratorio/Colesterol/ViewColesterol';
import ViewTriglicerido from '../../Components/Laboratorio/Trigliceridos/ViewTriglicerido';
import ViewColesterolHDL from '../../Components/Laboratorio/ColesterolHDL/ViewColesterolHDL';
import ViewColesterolLDL from '../../Components/Laboratorio/ColesterolLDL/ViewColesterolLDL';
import ViewColesterolVLDL from '../../Components/Laboratorio/ColesterolVLDL/ViewColesterolVLDL';
import ViewPerfilLipidico from '../../Components/Laboratorio/PerfilLipídico/ViewPerfilLipídico';

import ViewCocaina from '../../Components/Laboratorio/Toxicologia/ExamCocaina/ViewCocaina'
import ViewMarihuana from '../../Components/Laboratorio/Toxicologia/ViewMarihuana/ViewMarihuana';

import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';

const PageLaboratorio = () => {
    const [imprimirVisible, setImprimirVisible] = useState(false)

    const { atencionStatus, setAtencionStatus } = useContext(LaboratorioContext);
    const { setTimeStart } = useContext(LaboratorioContext);

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <>
            <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
                scrollable
            >
                <TabPanel header="Anatomía patológica" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="">

                        </AccordionTab>
                    </Accordion>
                </TabPanel>

                <TabPanel header="Biología molecular" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="Covid-19">
                            
                        </AccordionTab>
                    </Accordion>
                    <AccordionTab header="Coronavirus SARS-COV-2 molecular">
                        <></>
                    </AccordionTab>
                </TabPanel>

                <TabPanel header="Bioquímica">
                    <Accordion multiple className='Acordioon_Antecerentes'>
                        <AccordionTab header="Glucosa">
                            <TablaGlucosa />
                        </AccordionTab>
                        <AccordionTab header="Perfil Lipídico">
                            <ViewPerfilLipidico/>
                        </AccordionTab>
                        <AccordionTab header="Colesterol">
                            <ViewColesterol />
                        </AccordionTab>
                        <AccordionTab header="Trigliceridos">
                            <ViewTriglicerido/>
                        </AccordionTab>
                        <AccordionTab header="Colesterol HDL">
                            <ViewColesterolHDL/>
                        </AccordionTab>
                        <AccordionTab header="Colesterol LDL">
                            <ViewColesterolLDL/>
                        </AccordionTab>
                        <AccordionTab header="Colesterol VLDL">
                            <ViewColesterolVLDL/>
                        </AccordionTab>
                        <AccordionTab header="Urea">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Creatinina">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="TGO - Transaminasa oxalacético (AST)">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="TGP - Transaminasa piruvico (ALT)">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Fosfatasa alcalina">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Proteínas totales y fraccionales">
                            <></>
                        </AccordionTab>

                        <AccordionTab header="TGO - Transaminasa oxalacético (AST)">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="TGP - Transaminasa piruvico (ALT)">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="GGTP  - Gamma glutamil transpeptidasa">
                            <></>
                        </AccordionTab>

                    </Accordion>
                </TabPanel>

                <TabPanel header="Coagulación" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="Grupo y Factor RH">
                            <TablaRH />
                        </AccordionTab>
                    </Accordion>
                </TabPanel>

                <TabPanel header="Hematología" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="Hemograma">
                            <Hemograma />
                        </AccordionTab>
                        <AccordionTab header="Grupo sanguineo y Factor Rh">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Velocidad de sedimentación (VSG)">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Tiempo de Coagulación">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Hemoglobina y hematocrito">
                            <></>
                        </AccordionTab>
                    </Accordion>
                </TabPanel>

                <TabPanel header="Inmunobioquímica" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="Aglutinaciones(tifoidea, brucellas)">
                        </AccordionTab>

                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Hepatitis A HAV IgG ">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="Hepatitis C -  HVC -4ta generación ">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>

                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                        <AccordionTab header="">
                            <></>
                        </AccordionTab>
                    </Accordion>
                </TabPanel>

                <TabPanel header="Microbiología" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="">

                        </AccordionTab>
                    </Accordion>
                    <AccordionTab header="Coprocultivo">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="Parasitologico seriado  x 1">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="Parasitologico seriado x 2">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="Parasitologico seriado x 3">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="Reacción Inflamatoria">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="Thevenon en heces ">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="BK directo x 1">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="BK directo x 2">
                        <></>
                    </AccordionTab>
                    <AccordionTab header="BK seriado x 3">
                        <></>
                    </AccordionTab>
                </TabPanel>

                <TabPanel header="Urianálisis" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="Examen completo de orina">
                            <ExamenCompletoDeOrina />
                        </AccordionTab>
                    </Accordion>
                </TabPanel>

                <TabPanel header="Genética" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="">

                        </AccordionTab>
                    </Accordion>
                </TabPanel>

                <TabPanel header="Toxicología" className='Acordioon_Antecerentes'>
                    <Accordion multiple>
                        <AccordionTab header="Marihuana">
                            <ViewMarihuana/>
                        </AccordionTab>
                        <AccordionTab header="Cocaína">
                            <ViewCocaina/>
                        </AccordionTab>
                    </Accordion>
                </TabPanel>
            </TabView>
            <div style={{ borderRadius: '10px', height: "70px" }} className='flex justify-content-end gap-2 mt-2 bg-white'>
                <button className='p-button p-component btn-2 flex gap-1'>
                    <i className='pi pi-save'> </i>
                    <span>Guardar</span>
                </button>
                <button style={{ color: 'red' }} className='p-button  p-component btn-2 flex gap-1'>
                    <i className='pi pi-times'></i>
                    <span>Cancelar</span>
                </button>
                <button style={{ color: 'black' }} onClick={() => setImprimirVisible(true)} className='p-button p-component btn-2 flex gap-1'>
                    <i className='pi pi-print'></i>
                    <span>Imprimir</span>
                </button>
            </div>
            <ModalImprimir
                imprimirVisible={imprimirVisible}
                setImprimirVisible={setImprimirVisible}
            />

            {/* {
                atencionStatus === 0
                &&
                <RATimeView title="Laboratorio" icon={iconLab} setTimeStart={setTimeStart} setAtencionStatus={setAtencionStatus} />
            } */}
        </>
    );
};

export default PageLaboratorio;
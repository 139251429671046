import React, { useState, useContext, useEffect } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import VoiceButton from "../../../../Components/ButtonVoice/VoiceButton";
import { Dropdown } from 'primereact/dropdown';
import "../estilos/styles.css"
import { MGContext } from '../../MGProvider';
import { RAContext } from "../../../RutaAtencion/RAContext";
import "../../../../Style/Ventas/VentasS1Customer.css";
export default function DialogAgregar({ DiagMed, setDiagMed, data }) {
   // const data_context = useContext(dialogContext)
   let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
   let { diagMedicoContext, setDiagMedicoContext, evaluacionMedicaContext, setEvaluacionMedicaContext } = useContext(MGContext)

   const [visible, setVisible] = useState(false);
   // VARIABLES
   const [CIE, setCIE] = useState(data ? data[0] : null);
   const [TipoDiagnostico, setTipoDiagnostico] = useState({ name: 'Presuntivo', code: '0' });
   const [DiagOcupacional, setDiagOcupacional] = useState({ name: 'Si', code: '0' });
   const [selectedImp, setSelectedImp] = useState({ name: 'Si', code: '0' });
   const [observaciones, setObservaciones] = useState("");
   const [recomendaciones, setRecomendaciones] = useState("")

   const TiposDx = [
      { name: 'Presuntivo', code: '0' },
      { name: 'Definitivo', code: '1' },
      { name: 'Repetitivo', code: '2' }
   ];

   const DxOc = [
      { name: 'Si', code: '0' },
      { name: 'No', code: '1' },
   ];

   const Imp = [
      { name: 'Si', code: '0' },
      { name: 'No', code: '1' },
   ];

   const footerContent = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => { setVisible(false) }} className="p-button-text" />
         <Button label="Guardar" icon="pi pi-check" onClick={() => crearEvaluacion()} autoFocus />
      </div>
   );

   const LimpiarAll = () => {
      setCIE(data ? data[0] : null);
      setTipoDiagnostico({ name: 'Presuntivo', code: '0' });
      setDiagOcupacional({ name: 'Si', code: '0' });
      setSelectedImp({ name: 'Si', code: '0' });
      setObservaciones("");
      setRecomendaciones("");
   }

   const crearEvaluacion = () => {
      let nuevoRegistro = {
         enfermedad_especifica_id: CIE?.id,
         cie: CIE?.codigo,
         tipo_diagnostico: TipoDiagnostico?.code,
         ocupacional: DiagOcupacional?.code,
         importante: selectedImp?.code,
         observacion: observaciones,
         recomendacion: recomendaciones,
      }
      // setDiagMedicoContext([...diagMedicoContext, nuevoRegistro]);
      setEvaluacionMedicaContext(prevState => ({
         ...prevState,
         med_dx_conclusion: {
            ...prevState.med_dx_conclusion,
            med_lista_general_diagnostico: [
               ...prevState.med_dx_conclusion.med_lista_general_diagnostico,
               nuevoRegistro
            ]
         }
      }));

      setVisible(false)
      LimpiarAll()
   }

   return (
      <div className="card flex justify-content-center">
         <Button
            className="mb-3 border-round-sm border-none bg-indigo-100 text-indigo-400"
            icon="pi pi-plus"
            onClick={() => setVisible(true)}
            disabled={stateAtencion[pageViewContext].estado === 3}
            style={{
               background: "#F2ECFF",
               borderRadius: "4px",
               border: "none",
               color: "#8C63E3",
               height: '18px'
            }} />
         <Dialog visible={visible} style={{ width: '60vw' }} onHide={() => setVisible(false)} footer={footerContent}>
            <div className="flex flex-column gap-2">
               <div className="flex flex-row gap-2">
                  <i className="pi pi-pencil" style={{ background: '#6366F1', color: 'white', padding: '5px', borderRadius: '7px' }} />
                  <span style={{ fontSize: '20px', color: '#4545BC', fontWeight: '700' }}>Diagnóstico Médico</span>
               </div>
               <div className="flex flex-row gap-2 w-full justify-content-between" style={{ alignItems: 'end' }}>
                  <span style={{ marginBottom: "15px", width: "30%" }}>Diagnóstico CIE</span>
                  <div className="flex flex-column w-full">
                     <span style={{ marginLeft: '10px', marginBottom: "5px" }}>Código</span>
                     <Dropdown
                        value={CIE}
                        onChange={(e) => { setCIE(e.value) }}
                        options={data} optionLabel="codigo"
                        placeholder="Seleccione una opción"
                        filter
                        style={{ marginLeft: '10px', width: "100%" }} />
                  </div>
                  <div className="flex flex-column w-full">
                     <span style={{ textAlign: "left", marginLeft: '35px', marginBottom: "5px" }}>Denominación</span>
                     <div className="flex flex-row">
                        <Dropdown
                           value={CIE}
                           onChange={(e) => setCIE(e.value)}
                           options={data} optionLabel="enfermedad_especifica"
                           placeholder="Ingrese un denominación"
                           filter
                           style={{ marginLeft: '10px', width: "100%" }}
                           className="p-inputtext-sm"
                           panelClassName="customOptionStyle" />
                     </div>
                  </div>
               </div>

               <div className="flex flex-wrap gap-2 w-full mt-3">
                  <div className="flex flex-row align-items-center">
                     <span className="w-5rem">Tipo de Diagnóstico</span>
                     <div className="">
                        <Dropdown value={TipoDiagnostico} onChange={(e) => setTipoDiagnostico(e.value)} options={TiposDx} optionLabel="name"
                           placeholder="" className="" style={{ height: "47px", marginLeft: "22px", marginRight: "23.5px", width: "156px" }} />
                     </div>
                  </div>
                  <div className="flex flex-row gap-2 " style={{ alignItems: 'center' }}>
                     <span>Diagnóstico ocupacional</span>
                     <div className="">
                        <Dropdown
                           value={DiagOcupacional}
                           onChange={(e) => setDiagOcupacional(e.value)}
                           options={DxOc}
                           optionLabel="name"
                           placeholder="" />
                     </div>
                  </div>
                  <div className="flex flex-row gap-2 " style={{ alignItems: 'center' }}>
                     <span>De importancia</span>
                     <div className="">
                        <Dropdown
                           value={selectedImp}
                           onChange={(e) => setSelectedImp(e.value)}
                           options={Imp}
                           optionLabel="name"
                           placeholder=""
                           className="" />
                     </div>
                  </div>
               </div>
               <Divider />
               <div>
                  <span style={{}}>Observaciones: (Criterio médico)</span>
                  {/* <textarea rows="8" style={{ width: '100%', background: 'transparent', border: '0.5px solid rgba(136, 136, 142, 0.6)' }} /> */}
                  <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                     <VoiceButton
                        value={observaciones}
                        onChange={setObservaciones}
                        placeholder="Comentario" />
                  </div>
               </div>
               <div>
                  <span>Recomendaciones:</span>
                  {/* <textarea rows="8" style={{ width: '100%', background: 'transparent', border: '0.5px solid rgba(136, 136, 142, 0.6)' }} /> */}
                  <div style={{ marginTop: "20px" }}>
                     <VoiceButton
                        value={recomendaciones}
                        onChange={setRecomendaciones}
                        placeholder="Comentario" />
                  </div>
               </div>
            </div>
         </Dialog>
      </div>
   )
}

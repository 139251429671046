import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

import { Footer, Header } from "../../../Pure/SeccionesNavegacion";
import AuthUser from "../../../../AuthUser";
import CreateEmpresa from "../../CreateEmpresa/CreateEmpresa";

import icono from "../../../../Images/Perfil/icon/ActualizarUsuario.svg";
import '../style/ValidacionInputPEr.css'

export default function UpdateEmpresa({
    showToast,
    data,
    setBregma,
    handleSubmitUpdate,
    visible, setVisible,
    entidad
}) {
    const { http } = AuthUser();

    const [departamentos, setDepartamentos] = useState([]);
    const [departamento, setDepartamento] = useState(null);

    const [errors, setErrors] = useState({});

    const [provincia, setProvincia] = useState(null);
    const [distrito, setDistrito] = useState(null);

    // Efecto para obtener todas las ubicaciones al montar el componente
    useEffect(() => {
        getAllUbicaciones();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Función para obtener todas las ubicaciones
    const getAllUbicaciones = () => {
        http
            .get("/ubicacion")
            .then((response) => {
                setDepartamentos(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Función para manejar el envío previo del formulario
    const [vreir, setVreir] = useState(false);

    const handleprevSubmit = () => {
        SubirCambiosPopUp();
    };

    useEffect(() => {
        if (vreir) {
            handleSubmitUpdate(data);
            setVisible(false);
            setVreir(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vreir, data]);

    const [VerifiRazonSo, setVerifiRazonSo] = useState(false);

    useEffect(() => {
        setVerifiRazonSo(false);
    }, [visible]);

    const VerificarFaltass = () => {
        var veri = true;
        if (!RazonSocialLocal) {
            veri = false;
            setVerifiRazonSo(true);
        }
        return veri;
    }

    const SubirCambiosPopUp = () => {
        if (VerificarFaltass()) {
            setBregma((prevState) => ({
                ...prevState,
                razon_social: RazonSocialLocal,
                celular: CelularLocal,
                correo: CorreoLocal,
                direccion: DireccionLocal,
            }));
            setVreir(true);
        }
    };

    // Función para verificar si un campo del formulario es válido
    const isFormFieldValid = (fieldName) => {
        return !!errors[fieldName];
    };

    // Función para obtener el mensaje de error de un campo del formulario
    const getFormErrorMessage = (fieldName) => {
        return isFormFieldValid(fieldName) && <small className="p-error">{errors[fieldName]}</small>;
    };

    // Contenido del pie del diálogo
    const footerContent = (
        <Footer
            onClickCancelar={() => setVisible(false)}
            onClickEnviar={(e) => {
                let hasErrors = false;
                const newErrors = {};

                if (!RazonSocialLocal) {
                    newErrors.razon_social = "Razón social requerida.";
                    hasErrors = true;
                }

                if (!departamento) {
                    newErrors.departamento = "Seleccione una opción";
                    hasErrors = true;
                }

                if (!provincia) {
                    newErrors.provincia = "Seleccione una opción";
                    hasErrors = true;
                }

                if (!distrito) {
                    newErrors.distrito = "Seleccione una opción";
                    hasErrors = true;
                }

                setErrors(newErrors);

                if (hasErrors) {
                    showToast('error', 'Error', 'Complete los campos obligatorios');
                }

                if (!hasErrors) {
                    handleprevSubmit();
                    setErrors({});
                }
            }}
            label={'Actualizar'}
        />
    );

    // Función para ocultar el diálogo
    const onHideDialog = () => {
        setVisible(false);
        setErrors({});
    };

    const [RazonSocialLocal, setRazonSocialLocal] = useState("");
    const [CelularLocal, setCelularLocal] = useState("");
    const [CorreoLocal, setCorreoLocal] = useState("");
    const [DireccionLocal, setDireccionLocal] = useState("");

    useEffect(() => {
        setRazonSocialLocal(data?.razon_social);
        setCelularLocal(data?.celular);
        setCorreoLocal(data?.correo);
        setDireccionLocal(data?.direccion);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    return (
        <Dialog
            header={<Header icono={icono} titulo={`Datos de ${entidad}`} subtitulo={'Complete la siguiente información sobre la empresa'} />}
            footer={footerContent}
            visible={visible} className="form2-container"
            style={{ width: '45vw', minWidth: "200px", maxWidth: "446px" }}
            onHide={onHideDialog}>
            <div className="Contenedor_input_POPUP_Empresa">

                <div className={`Inputss_POPUP_Update_Empresa ${VerifiRazonSo ? 'faltaSelec' : ''}`}>
                    <label htmlFor="integer" className="">(*) Razón Social</label>
                    <InputText
                        id="razon_social"
                        name="razon_social"
                        style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                        defaultValue={RazonSocialLocal}
                        onChange={(e) => { setRazonSocialLocal(e.target.value); setVerifiRazonSo(false) }}
                        className={classNames({ 'p-invalid': isFormFieldValid('razon_social') })}
                    />
                    {getFormErrorMessage('razon_social')}
                </div>
                <div className="Inputss_POPUP_Update_Empresa">
                    <label htmlFor="integer" className="">Celular</label>
                    <InputText id="celularE" keyfilter="num" className="w-full text-area2"
                        style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={CelularLocal}
                        onChange={(e) => { setCelularLocal(e.target.value) }} />
                </div>

                <div className="Inputss_POPUP_Update_Empresa">
                    <label htmlFor="email " className="">Correo</label>
                    <InputText id="email" type="email" keyfilter={/^[a-z0-9._@]+$/} className="w-full text-area2  input-correo"
                        style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                        defaultValue={CorreoLocal}
                        onChange={(e) => { setCorreoLocal(e.target.value) }}
                    />
                </div>

                <CreateEmpresa
                    setBregma={setBregma}
                    data={data}
                    departamentos={departamentos}
                    departamento={departamento}
                    setDepartamento={setDepartamento}
                    provincia={provincia}
                    setProvincia={setProvincia}
                    distrito={distrito}
                    setDistrito={setDistrito}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                    classNames={classNames}
                />

                <div className="Inputss_POPUP_Update_Empresa">
                    <label htmlFor="integer" className="">Dirección</label>
                    <InputText id="direccion" className="w-full text-area2"
                        style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={DireccionLocal}
                        onChange={(e) => { setDireccionLocal(e.target.value) }} />
                </div>
            </div>
        </Dialog>
    );
}

import React, { useEffect, useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import Biologo from './C-opciones/Biologo';
import MCirujano from './C-opciones/MCirujano';
import TecMedico from './C-opciones/TecMedico';
import "../DialogCrearEvaluadores.css";

export default function Laboratorio({ rowData, LabEspecialista, setLabEspecialista, Cbp, Cmp, Ctmp, Rne, setCbp, setCmp, setRne, setCtmp , labEspecialistas }) {

   let componenteAMostrar;
   let labelNuevoInput = "";

   switch (LabEspecialista?.code) {
      case 1:
         componenteAMostrar = <Biologo Cbp={Cbp} setCbp={setCbp} />;
         break;
      case 2:
         componenteAMostrar = <TecMedico Ctmp={Ctmp} setCmp={setCtmp} />;
         break;
      case 3:
         componenteAMostrar = <MCirujano Cmp={Cmp} Rne={Rne} setRne={setRne} setCmp={setCmp} />;
         break;
      default:
         componenteAMostrar = null;
   }
   
   useEffect(() => {
      if (rowData?.especialista_evaluador?.lab_especialista_id) {
         labEspecialistas?.forEach((elemento) => {
            if (rowData?.especialista_evaluador?.lab_especialista_id === elemento.id) {
               setLabEspecialista(elemento.id)
            }
         })
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rowData]);

   const LabEspecialistaChange = (e) => {
      setLabEspecialista(e)
      setCbp(null)
      setCmp(null)
      setRne(null)
      setCtmp(null)

   }

   return (
      <div className="contenedor-crear3 mx-3" style={{ marginTop: "20px" }}>
         <div
            style={{
               marginTop: "-10px",
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between"
            }}
         >
            <label
               htmlFor="especialista"
               style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                  display: "flex",
               }}
            >
               Escoge una:{" "}
            </label>
            <Dropdown
               value={LabEspecialista}
               options={labEspecialistas}
               optionLabel="nombre"
               optionValue='id'
               placeholder=""
               onChange={(e) => LabEspecialistaChange(e.target.value)}
               required
               style={{ width: "400px" }}
            />

         </div>
         <div className="options">
            {LabEspecialista === 1 && (
               <div
                  style={{
                     marginTop: "20px",
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     alignItems: "center",
                  }}
               >
                  <label
                     htmlFor="RNM"
                     style={{
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#4545bc",
                     }}
                  >
                     CBP*:{" "}
                  </label>
                  <InputText
                     placeholder="Código"
                     style={{ width: "400px", marginLeft: "6px" }}
                     keyfilter={/^[a-zA-Z0-9]*$/} // Permitir solo caracteres alfanuméricos
                     value={Cbp ? Cbp.toUpperCase() : ''}
                     onChange={(e) => { setCbp(e.target.value.toUpperCase()) }}
                  />
               </div>
            )}
            {LabEspecialista === 2 && (
               <div
                  style={{
                     marginTop: "20px",
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     alignItems: "center",
                  }}
               >
                  <label
                     htmlFor="CTMP"
                     style={{
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#4545bc",
                     }}
                  >
                     CTMP*:{" "}
                  </label>
                  <InputText
                     placeholder="Código"
                     style={{ width: "400px", marginLeft: "6px" }}
                     keyfilter={/^[a-zA-Z0-9]*$/} // Permitir solo caracteres alfanuméricos
                     value={Ctmp ? Ctmp.toUpperCase() : ''}
                     onChange={(e) => { setCtmp(e.target.value.toUpperCase()) }}
                  />
               </div>
            )}
            {LabEspecialista === 3 && (
               <div
                  style={{
                     marginTop: "20px",
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     alignItems: "center",
                  }}
               >
                  <label
                     htmlFor="CMP"
                     style={{
                        fontFamily: "Montserrat",
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#4545bc",
                     }}
                  >
                     CMP*:{" "}
                  </label>
                  <InputText
                     placeholder="Código"
                     style={{ width: "400px", marginLeft: "6px" }}
                     keyfilter={/^[a-zA-Z0-9]*$/} // Permitir solo caracteres alfanuméricos
                     value={Cmp ? Cmp.toUpperCase() : ''}
                     onChange={(e) => { setCmp(e.target.value.toUpperCase()) }}
                  />
               </div>
            )}
         </div>
         {componenteAMostrar}
      </div>
   );
}

import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import "./main.css";
import { colors } from "@mui/material";
import DialogDetallesLiquidacion from "./DialogDetallesLiquidacion";

function GestionAtencion(props) {
   let colorContentEstado='';
    let colorEstado='';
    if(props.estadoLiquido==='Por liquidar'){colorEstado='#FF6767'; colorContentEstado='#FFECEC'}
    if(props.estadoLiquido==='En proceso'){colorEstado='#E38621'; colorContentEstado='#FFECD7'}
    if(props.estadoLiquido==='Liquidado'){colorEstado='#00A15C'; colorContentEstado='#E5F6EF'}

   const [visibleDetalle, setVisibleDetalle] = useState(false);

   return (
      <div className="">
         <div className="container-header flex justify-content-between sm:align-items-start lg:align-items-center align-items-start mb-5 sm:flex-column lg:flex-row  flex-column gap-3">
            <div className="liquidacion-l justify-content-left ">
               <span className="titulo">Liquidación</span>
               <br />
               <span className="con h-auto ">
                  En este módulo usted podrá generar el costo por atención y la
                  facturación de sus productos y/o servicios.
               </span>
            </div>
            {/* <div className="butons1 justify-content-rigth">
               <button className="activeButton">
                  <span>Liquidación</span>
               </button>

               <button>
                  <span>Facturación</span>
               </button>

               <button>
                  <span>Mis pagos</span>
               </button>
            </div> */}
            <div className='div_botones_AHP flex flex-wrap lg:flex-nowrap justify-content-start '>
              <a href=""><Button className='botones_Facturacion boton_selecccionado' label="Costo por Atención" /></a>
              <a href="../contabilidad/facturacion"><Button className='botones_Facturacion ' label="Facturación" /></a>
              <a href="../contabilidad/pagos"><Button className='botones_Facturacion ' label="Mis pagos" /></a>
            </div>   
         </div>

         <div className="clientes mb-3 flex justify-content-between align-items-star flex-column ">
            <div className="clinicaButtons  flex flex-wrap gap-2">
               <button className="shadow-1">
                  <span>Cliente Clínica</span>
               </button>
               <button className="shadow-1">
                  <span>Cliente Corporativo</span>
               </button>
               <button className="shadow-1">
                  <span>Cliente Consultora</span>
               </button>
               <button className="shadow-1">
                  <span>Cliente Particular</span>
               </button>
            </div>
            <div className="content ">
               <div className=" flex gap-2 mt-4  w-100">
                  <div className="">
                     <button className="btnReport">
                        <i className="pi pi-file"></i>
                        <span>Reporte</span>
                     </button>
                  </div>
                  <span className="p-input-icon-left  ">
                     <i className="pi pi-search"></i>
                     <input
                        style={{
                           fontSize: "14px",



                           fontWeight: "400",
                           color: "#7B879480",
                        }}
                        className="p-inputtext p-component input_search_AHP  py-3 shadow-1 "
                        placeholder="Buscador"
                     ></input>
                  </span>
               </div>
            </div>
            
         </div>

         {/* TABLA */}
         <div className="contenedor-tabla ">
            <ScrollPanel
               style={{ width: "100%", height: "100%" }}
               className="custombar1"
            >
               <table
                  className="Tabla_Servicios_Breegma"
                  style={{ background: "#fff", width: "100%" }}
               >
                  <thead>
                     <tr>
                        {/* <th></th> */}
                        <th style={{ whiteSpace: "wrap" }}>Item.</th>
                        <th style={{ whiteSpace: "wrap" }}>Razón Social</th>
                        <th style={{ whiteSpace: "wrap" }}>RUC</th>
                        <th style={{ whiteSpace: "wrap" }}>Modalidad</th>
                        <th style={{ whiteSpace: "wrap" }}>Estado</th>
                        <th style={{ whiteSpace: "wrap" }}>Días Fac.</th>
                        <th style={{ whiteSpace: "wrap" }}>Total(Incl.IGV)</th>
                        <th style={{ whiteSpace: "wrap" }}>Detalles</th>
                     </tr>
                  </thead>
                  <tbody>
                     {/* {currentPersonal?.map((item) => ( */}
                     {/* key={item.id} */}
                     <tr>
                        <td
                           data-label="Item"
                           style={{ borderLeft: "0px solid transparent" }}
                        >
                           <div className="flex flex-row gap-2 justify-content-center">
                              {/* {actionBodyTemplateAsignarcheck(item)}
                                 {item.id} */}
                           </div>{" "}
                        </td>
                        <td
                           style={{ whiteSpace: "wrap" }}
                           data-label="Razón Social"
                        >
                           {/* <BotonEstado estado="Programado" /> */}
                        </td>
                        <td style={{ whiteSpace: "wrap" }} data-label="RUC">
                           {/* {item.persona.tipo_documento.nombre} */}
                        </td>
                        <td
                           style={{ whiteSpace: "wrap" }}
                           data-label="Modalidad"
                        >
                           {/* {item.persona.numero_documento} */}
                        </td>

                        <td style={{ whiteSpace: "wrap" }} data-label="Estado">
                           {/* <BotonResultadooo resultado="Observado" /> */}
                           <p>{props.estadoLiquido}</p>
                        </td>

                        <td data-label="Días fAC."></td>

                        <td
                           style={{ whiteSpace: "wrap" }}
                           data-label="Total(Incl.IGV)"
                        >
                           {/* {actionBodyTemplateAsignar(item)} */}
                        </td>

                        <td data-label="Detalles">
                           {<DialogDetallesLiquidacion />}
                        </td>
                     </tr>
                     {/* ))} */}
                  </tbody>
               </table>

               <div style={{ display: "flex", justifyContent: "right" }}>
                  <div className="footer-contador">
                     <div
                        className="flex flex-wrap justify-content-evenly gap-5 pagination-component"
                        style={{ flexDirection: "row" }}
                     >
                        <button
                           style={{ cursor: "pointer" }}
                           className="btn-current-next"
                           //  onClick={() => handlePageChange(currentPage - 1)}
                           //  disabled={currentPage === 1}
                        >
                           <i className="pi pi-angle-left"></i>
                        </button>

                        <button
                           style={{ cursor: "pointer" }}
                           className="btn-current-back"
                           //  onClick={() => handlePageChange(currentPage + 1)}
                           //  disabled={currentPage === totalPages}
                        >
                           <i className="pi pi-angle-right"></i>
                        </button>
                     </div>
                  </div>
               </div>
            </ScrollPanel>
         </div>
      </div>
   );
}

export default GestionAtencion;

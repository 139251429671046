import React, { useEffect, useState } from "react";
import 'primeicons/primeicons.css';
import './StylesPresentacion.css';
import DataPorCobrar from '../DataBaseData/DataPorCobrar';
import DataEnProceso from '../DataBaseData/DataEnProceso';
import DataCobrado from '../DataBaseData/DataCobrado';
import './Responsive.css';

function PresentacionGeneral(props){
    let contadorPorCobrar= 3;
    let contadorEnProceso= 2;
    let contadorCobrado= 3;
    const propsParaDataPorCobrar = {nombreComponente: props.estiloComponente};
    const propsParaDataEnProceso = {nombreComponente: props.estiloComponente};
    const propsParaDataCobrado = {nombreComponente: props.estiloComponente};

    const [Visibilidad, Get_Visibilidad] = useState(true);
  const [VisibilidadBotonRespo, Get_VisibilidadBotonRespo] = useState(false);
  const [VisibilidadPageCards01, Get_VisibilidadPageCards01] = useState(false);
  const [VisibilidadPageCards02, Get_VisibilidadPageCards02] = useState(false);
  const [VisibilidadPageCards03, Get_VisibilidadPageCards03] = useState(false);
  const [contado, setContado] = useState(false);
  const [contado02, setContado02] = useState(false);
  const [contado03, setContado03] = useState(false);
 

  function Ver_Responsive_cards() {
    Get_VisibilidadPageCards01(true);
    setContado(true);
  }
  function Ver_Responsive_cards2() {
    Get_VisibilidadPageCards02(true);
    setContado02(true);
  }
  function Ver_Responsive_cards3() {
    Get_VisibilidadPageCards03(true);
    setContado03(true);
  }
  function Limpiar() {
    Get_VisibilidadPageCards01(false);
    Get_VisibilidadPageCards02(false);
    Get_VisibilidadPageCards03(false);
    setContado(false);
    setContado02(false);
    setContado03(false);
  }

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      const breakpoint = 1362;

      if (windowWidth <= breakpoint) {
        Get_Visibilidad(false);
        Get_VisibilidadBotonRespo(true);
        Get_VisibilidadPageCards01(false);
        Get_VisibilidadPageCards02(false);
        Get_VisibilidadPageCards03(false);
        if (contado) {
            Get_VisibilidadPageCards01(true);
            Get_VisibilidadBotonRespo(false);
            Get_VisibilidadPageCards02(false);
            Get_VisibilidadPageCards03(false);
        } else if (contado02){
            Get_VisibilidadPageCards01(false);
            Get_VisibilidadBotonRespo(false);
            Get_VisibilidadPageCards02(true);
            Get_VisibilidadPageCards03(false);
        } else if (contado03){
            Get_VisibilidadPageCards01(false);
            Get_VisibilidadBotonRespo(false);
            Get_VisibilidadPageCards02(false);
            Get_VisibilidadPageCards03(true);
        }else {
            Get_VisibilidadPageCards01(false);
            Get_VisibilidadBotonRespo(true);
            Get_VisibilidadPageCards02(false);
            Get_VisibilidadPageCards03(false);
          }
      } else {
        Get_Visibilidad(true);
        Get_VisibilidadBotonRespo(false);
        Get_VisibilidadPageCards01(false);
        Get_VisibilidadPageCards02(false);
        Get_VisibilidadPageCards03(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [contado, contado02, contado03]);
    return(
        <div style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%', height:'100%'}}>
             {Visibilidad && (<div id='contendor_Bs_AHP'>
                <div className='Contenedor_cuadros'>
                    <div className='titulo_cuadro'>
                        <div className='Textt_Content'>
                            <p className='texto_base_AHP'>Por cobrar</p>
                            <div className='Contador_cuadro' style={{background:'#FFECEC'}}>
                                <p className='texto_base_AHP' style={{color:'#FF6767'}}>{contadorPorCobrar}</p>
                            </div>
                        </div>
                        <i className='pi pi-ellipsis-h'></i>
                    </div>
                    <div className='contenedor_base_cuadro'>
                        <DataPorCobrar {...propsParaDataPorCobrar}/>
                    </div>
                </div>

                <div className='Contenedor_cuadros'>
                    <div className='titulo_cuadro'>
                        <div className='Textt_Content'>
                            <p className='texto_base_AHP'>En proceso</p>
                            <div className='Contador_cuadro' style={{background:'#FFEDD7'}}>
                                <p className='texto_base_AHP' style={{color:'#EF771C'}}>{contadorEnProceso}</p>
                            </div>
                        </div>
                        <i className='pi pi-ellipsis-h'></i>
                        
                    </div>
                    <div className='contenedor_base_cuadro'>
                        <DataEnProceso {...propsParaDataEnProceso} />
                    </div>
                </div>

                <div className='Contenedor_cuadros'>
                    <div className='titulo_cuadro'>

                        <div className='Textt_Content'>
                            <p className='texto_base_AHP'>Cobrado</p>
                            <div className='Contador_cuadro' style={{background:'#C4F4DF'}}>
                                <p className='texto_base_AHP' style={{color:'#00A15C'}}>{contadorCobrado}</p>
                            </div>
                        </div>
                        <i className='pi pi-ellipsis-h'></i>

                    </div>
                    <div className='contenedor_base_cuadro'>
                        <DataCobrado {...propsParaDataCobrado} />
                    </div>
                </div>
            </div>)}
            {VisibilidadBotonRespo && (
                <div className='Respon_contenedor_princi'>
                    <button className="Respon_contenedor_botones" id='boton01' style={{background:'#FF6767',border:'2px solid #FFECEC'}} onClick={() => Ver_Responsive_cards()}>
                        <div className="Respon_titulo_boton" style={{color:'#FFECEC'}}>Por cobrar</div>
                        <div className="Respon_contador_boton" style={{background:'#FFECEC'}}>
                            <p className="Respon_contador_letra_boton">{contadorPorCobrar}</p>
                        </div>
                    </button>
                    <button className="Respon_contenedor_botones" id='boton02' style={{background:'#EF771C',border:'2px solid #FFEDD7'}} onClick={() => Ver_Responsive_cards2()}>
                    <div className="Respon_titulo_boton" style={{color:'#FFEDD7'}}>En Proceso</div>
                        <div className="Respon_contador_boton" style={{background:'#FFEDD7'}}>
                            <p className="Respon_contador_letra_boton">{contadorEnProceso}</p>
                        </div>
                    </button>
                    <button className="Respon_contenedor_botones" id='boton03' style={{background:'#00A15C',border:'2px solid #C4F4DF'}} onClick={() => Ver_Responsive_cards3()}>
                    <div className="Respon_titulo_boton" style={{color:'#C4F4DF'}}>Cobrado</div>
                        <div className="Respon_contador_boton" style={{background:'#C4F4DF'}}>
                            <p className="Respon_contador_letra_boton">{contadorCobrado}</p>
                        </div>
                    </button>
                </div>
            )}
            {VisibilidadPageCards01 && (
                <div style={{width:'100%', height:'500px', display:'flex', flexDirection:'column'}}>
                    <div className='Textt_Content02'>
                        <div className='Textt_Content02' style={{width:'100%'}}>
                            <p className='texto_base_AHP' style={{fontSize:'20px'}}>Por cobrar</p>
                            <div className='Contador_cuadro' style={{background:'#FFECEC', width:'32px', height:'32px'}}>
                                <p className='texto_base_AHP' style={{color:'#FF6767',fontSize:'18px'}}>{contadorPorCobrar}</p>
                            </div>
                        </div>
                        <div className="Boton_regreso" style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100px', height:'auto'}} onClick={() => Limpiar()}>
                            <div className="Contador_cuadro" id="boton01" style={{background:'#FFECEC', width:'50px', height:'50px', border:'2px solid #FF6767'}}>
                                <i className="pi pi-undo" style={{color:'#FF6767',fontSize:'25px'}}></i>
                            </div>
                        </div>
                    </div>
                    <DataPorCobrar {...propsParaDataPorCobrar}/>
                </div>
            )}
            {VisibilidadPageCards02 && (
                <div style={{width:'100%', height:'500px', display:'flex', flexDirection:'column'}}>
                    <div className='Textt_Content02'>
                        <div className='Textt_Content02' style={{width:'100%'}}>
                            <p className='texto_base_AHP' style={{fontSize:'20px'}}>En Proceso</p>
                            <div className='Contador_cuadro' style={{background:'#FFEDD7', width:'32px', height:'32px'}}>
                                <p className='texto_base_AHP' style={{color:'#EF771C',fontSize:'18px'}}>{contadorPorCobrar}</p>
                            </div>
                        </div>
                        <div className="Boton_regreso" style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100px', height:'auto'}} onClick={() => Limpiar()}>
                            <div className="Contador_cuadro" id="boton02" style={{background:'#FFEDD7', width:'50px', height:'50px', border:'2px solid #EF771C'}}>
                                <i className="pi pi-undo" style={{color:'#EF771C',fontSize:'25px'}}></i>
                            </div>
                        </div>
                    </div>
                    <DataEnProceso {...propsParaDataEnProceso} />
                </div>
            )}
            {VisibilidadPageCards03 && (
                <div style={{width:'100%', height:'500px', display:'flex', flexDirection:'column'}}>
                    <div className='Textt_Content02'>
                        <div className='Textt_Content02' style={{width:'100%'}}>
                            <p className='texto_base_AHP' style={{fontSize:'20px'}}>Cobrado</p>
                            <div className='Contador_cuadro' style={{background:'#C4F4DF', width:'32px', height:'32px'}}>
                                <p className='texto_base_AHP' style={{color:'#00A15C',fontSize:'18px'}}>{contadorPorCobrar}</p>
                            </div>
                        </div>
                        <div className="Boton_regreso" style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100px', height:'auto'}} onClick={() => Limpiar()}>
                            <div className="Contador_cuadro" id="boton03" style={{background:'#C4F4DF', width:'50px', height:'50px', border:'2px solid #00A15C'}}>
                                <i className="pi pi-undo" style={{color:'#00A15C',fontSize:'25px'}}></i>
                            </div>
                        </div>
                    </div>
                    <DataCobrado {...propsParaDataCobrado} />
                </div>
            )}
        </div>
    )
}
export default PresentacionGeneral;
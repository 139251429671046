import React, { useContext, useRef, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Toast } from "primereact/toast";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { TabPanel, TabView } from "primereact/tabview";

import PagePsiDatOcup from "./DatosOcupacionales/PagePsiDatOcup";
import PagePsiExpLab from "./ExperienciaLaboral/PagePsiExpLab";
import PageExaMent from "./ExamenMental/PageExaMent";
import PagePsiProCog from "./ProcesoCognitivo/PagePsiProCog";
import PagePsiPruebas from "./PruebasPsicologicas/PagePsiPruebas";
import PagePsiPreguntas from "./Preguntas/PagePsiPreguntas";
import PagePsiDiag from "./Diagnostico/PagePsiDiag";
import PageOtros from "./PageOtros";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';

import AuthUser from "../../AuthUser";
import { RAContext } from "../../Page/RutaAtencion/RAContext";
import { PsicologiaContext } from "./PsicologiaContext";

import { iconPsicologia } from "../../Services/importIcons";
import ModalGuardarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion";
import { useDispatch } from "react-redux";

import { startLoading, endLoading } from "../../Redux/isLoadingSlice";
import BottomsFootComponent from "../../Components/Clinica/AtencionMedica/pure/BottomsFoot";
import ModalCancelarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion";

const PagePsicologia = ({ revision }) => {
   const dispatch = useDispatch();

   const { http } = AuthUser();
   const { idPaciente } = useParams();
   const toast = useRef(null);

   let { datOcupContext, setDatOcupContext,
      expLabContext, setExpLabContext,
      exaMenContext, setExaMenContext,
      proCogContext, setProCogContext,
      pruPsiContext,
      pregContext, setPregContext,
      diagContext, setDiagContext,
      setTimeStart
   } = useContext(PsicologiaContext);

   let { pacienteContext, pageViewContext, stateAtencion, setStateAtencion, psicologiaAreasContext, psicologiaDetalleId, setPsicologiaDetalleId } = useContext(RAContext)

   const [visibleImprimir, setVisibleImprimir] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);
   const [activeIndex, setActiveIndex] = useState(0);
   const [documentos, setDocumentos] = useState([])
   const [idPsicologia, setIdPsicologia] = useState(null)
   const [estado, setEstado] = useState(false)

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };


   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const psicologiaCreate = () => {
      http
         .post(`psicologia/create`, { atencion_id: idPaciente })
         .then((response) => {

            if (response.data.resp) {
               setIdPsicologia(response?.data?.psicologia_id)
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch((error) => {
            console.error(error);
            showToast('error', 'Error', error.message);
         });

   };

   const getPacientePisicologia = async () => {
      await http
         .get(`psicologia/get/${idPaciente}`)
         .then((response) => {
            if (response.data.resp) {
               setIdPsicologia(response?.data?.resp.id);
               fetchGetAllData();
            }
            if (response.data.error) {
               dispatch(endLoading());
            }
         })
         .catch((error) => {
            dispatch(endLoading());
         })
   };

   const getExperiencia = async (id) => {
      await http
         .get(`psicologia/experiencialaboral/get/${id}`)
         .then((response) => {
            if (response?.data?.data) {
               setExpLabContext(response.data.data);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };


   const getDatosOcupacionales = async (id) => {
      try {
         await http
            .get(`psicologia/datosocupacionales/get/${id}`)
            .then((response) => {
               if (response.data.resp) {
                  setDatOcupContext(response.data.resp);
               }
            })
            .catch((error) => {
               console.error(error);
            });
      } catch (error) {
         console.error(error)
      }
   };

   const getDatosExamenMental = async (id) => {
      await http
         .get(`psicologia/psiexamenmental/get/${id}`)
         .then((response) => {
            if (!response.data.error) {
               setExaMenContext(response.data.resp)
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getProcesosCognitivos = async (id) => {
      await http
         .get(`psicologia/procesocognoscitivo/get/${id}`)
         .then((response) => {
            if (!response.data.error) {
               let auxResponse = response.data.resp;
               setProCogContext({
                  lucido_atento: auxResponse.lucido_atento,
                  pensamiento: auxResponse.pensamiento,
                  percepcion: auxResponse.percepcion,
                  psi_memoria_id: auxResponse.memorias,
                  psi_inteligencia_id: auxResponse.psi_inteligencia_id,
                  apetito: auxResponse.apetito,
                  suenno: auxResponse.suenno,
                  personalidad: auxResponse.personalidad,
                  afectividad: auxResponse.afectividad,
                  conducta_sexual: auxResponse.conducta_sexual,
               });
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getPreguntas = async (id) => {
      await http
         .get(`psicologia/psipreguntas/get/${id}`)
         .then((response) => {
            if (!response.data.error) {
               setPregContext(response.data.resp);
            }
         })
         .catch((error) => {
            console.error(error);
         })
   };

   const getDiagnostico = async (id) => {
      await http
         .get(`psicologia/psidiagnosticofinal/get/${id}`)
         .then((response) => {
            if (!response.data.error) {
               setDiagContext(response.data.resp)
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const getDocumentos = async () => {
      await http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            setPsicologiaDetalleId({
               id: psicologiaDetalleId.id,
               estado: 1,
               hora_inicio: psicologiaDetalleId.hora_inicio,
               fecha_inicio: psicologiaDetalleId.fecha_inicio,
               hora_fin: psicologiaDetalleId.hora_fin,
               fecha_fin: psicologiaDetalleId.fecha_fin,
            })
         })
   }

   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            setPsicologiaDetalleId({
               id: psicologiaDetalleId.id,
               estado: 2,
               hora_inicio: psicologiaDetalleId.hora_inicio,
               fecha_inicio: psicologiaDetalleId.fecha_inicio,
               hora_fin: psicologiaDetalleId.hora_fin,
               fecha_fin: psicologiaDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setPsicologiaDetalleId({
                  id: psicologiaDetalleId.id,
                  estado: 2,
                  hora_inicio: psicologiaDetalleId.hora_inicio,
                  fecha_inicio: psicologiaDetalleId.fecha_inicio,
                  hora_fin: psicologiaDetalleId.hora_fin,
                  fecha_fin: psicologiaDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
            showToast('success', 'Exito', "Algo salió mal");
         })
   }

   const datosOcupacionalesCreate = async (idPsicologia) => {
      await http
         .post(
            `psicologia/datosocupacionales/create/${idPsicologia}`,
            datOcupContext
         )
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const experienciaLaboralCreate = async (idPsicologia) => {
      let newData = {
         experiencia_laboral: expLabContext
      };
      await http
         .post(
            `psicologia/experiencialaboral/create/${idPsicologia}`,
            newData
         )
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const examenMentalCreate = async (idPsicologia) => {
      await http
         .post(`psicologia/psiexamenmental/create/${idPsicologia}`, exaMenContext)
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };
   const procesoCognoscitivoCreate = async (idPsicologia) => {
      await http
         .post(
            `psicologia/procesocognoscitivo/create/${idPsicologia}`,
            proCogContext
         )
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };
   const pruebasPsicologicas = async (idPsicologia) => {
      await http
         .post(
            `psicologia/psipruebapsicologica/create/${idPsicologia}`,
            pruPsiContext
         )
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };
   const preguntasCreate = async (idPsicologia) => {
      await http
         .post(`psicologia/psipreguntas/create/${idPsicologia}`, pregContext)
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };
   const diagnosticoCreate = async (idPsicologia) => {
      await http
         .post(`psicologia/psidiagnosticofinal/create/${idPsicologia}`, diagContext)
         .then(() => {
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const fetchGetAllData = async () => {
      try {
         await Promise.all([
            getExperiencia(idPaciente),
            getDatosOcupacionales(idPaciente),
            getDatosExamenMental(idPaciente),
            getProcesosCognitivos(idPaciente),
            getPreguntas(idPaciente),
            getDiagnostico(idPaciente),
         ])
         showToast(
            "success",
            `Atención en Obtenida`,
            `Datos traidos correctamente la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const fetchAllData = async () => {
      try {
         await Promise.all([
            datosOcupacionalesCreate(idPsicologia),
            experienciaLaboralCreate(idPsicologia),
            examenMentalCreate(idPsicologia),
            procesoCognoscitivoCreate(idPsicologia),
            pruebasPsicologicas(idPsicologia),
            preguntasCreate(idPsicologia),
            diagnosticoCreate(idPsicologia),
         ])
         showToast(
            "success",
            `Atención en Psicología guardada`,
            `Se guardó la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const guardarDatos = () => {
      dispatch(startLoading());
      setVisibleGuardar(false)
      fetchAllData();
   }

   const restablecerDatos = () => {
      setVisibleCancelar(false);
      dispatch(startLoading());
      fetchGetAllData();
   }

   const renderTabs = (area, key) => {
      const optionMapping = {
         6: (
            <TabPanel key={key} header="Datos Ocupacionales">
               <PagePsiDatOcup />
            </TabPanel>
         ),
         7: (
            <TabPanel key={key} header="Experiencia laboral">
               <PagePsiExpLab />
            </TabPanel>
         ),
         8: (
            <TabPanel key={key} header="Examen mental">
               <PageExaMent />
            </TabPanel>
         ),
         9: (
            <TabPanel key={key} header="Procesos Cognitivos">
               <PagePsiProCog />
            </TabPanel>
         ),
         10: (
            <TabPanel key={key} header="Pruebas Psicológicas">
               <PagePsiPruebas />
            </TabPanel>
         ),
         11: (
            <TabPanel key={key} header="Preguntas">
               <PagePsiPreguntas />
            </TabPanel>
         ),
         12: (
            <TabPanel key={key} header="Diagnóstico">
               <PagePsiDiag />
            </TabPanel>
         ),
         13: (
            <TabPanel key={key} header="Otros">
               <Accordion multiple>
                  <AccordionTab header="Pruebas">
                     <PageOtros />
                  </AccordionTab>
               </Accordion>
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   useEffect(() => {
      dispatch(startLoading());
      getDocumentos();
      getPacientePisicologia();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               psicologiaAreasContext?.map((area, key) => {
                  return renderTabs(area, key)
               })
            }
         </TabView>
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleImprimir={setVisibleImprimir}
            setVisibleCancelar={setVisibleCancelar}
            revision={revision}
         />
         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Psicología"
            onNo={() => setVisibleGuardar(false)}
            onYes={guardarDatos}
            onHide={() => setVisibleGuardar(false)}
         />

         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}
         />
         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Psicología"
            onNo={() => setVisibleCancelar(false)}
            onYes={restablecerDatos}
            onHide={() => setVisibleCancelar(false)}
         />

         {(psicologiaDetalleId.estado === "0") && (
            <RATimeView
               title="Psicología"
               AreaDetalleId={psicologiaDetalleId?.id}
               icon={iconPsicologia}
               setTimeStart={setTimeStart}
               createAtencion={psicologiaCreate}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               CancellAtencion={CambiarEstado}
               AreaDetalleId={psicologiaDetalleId?.id}
               title={stateAtencion[pageViewContext].nombre}
               finalizarAtencion={finalizarRevision}
               textButton='finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={pacienteContext?.psicologia_fin}
               VerHoraInicio={psicologiaDetalleId?.hora_inicio}
               VerfechaInicio={psicologiaDetalleId?.hora_fin}
               estado={estado}
               setEstado={setEstado}
            />
         )}
         {estado && revision !== 1 && (
            <RATimeView
               CancellAtencion={CambiarEstado}
               AreaDetalleId={psicologiaDetalleId?.id}
               title={stateAtencion[pageViewContext].nombre}
               finalizarAtencion={finalizarAtencion}
               textButton='finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={pacienteContext?.psicologia_fin}
               VerHoraInicio={psicologiaDetalleId?.hora_inicio}
               VerfechaInicio={psicologiaDetalleId?.hora_fin}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PagePsicologia;
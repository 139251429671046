import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputSwitch } from 'primereact/inputswitch';
import NuevoGenerar from './NuevoGenerar';
import NuevoFirmaHuella from './NuevoFirmaHuella';
import Identificacion from './Identificacion';
import AuthUser from '../../../AuthUser';

const Consentimiento = ({ visibleConsent, setVisibleConsent, paciente }) => {
  const { http } = AuthUser();
  const [docs, setDocs] = useState([]);
  const [visiblePdf, setVisiblePdf] = useState(false);
  const [visibleGenerar, setVisibleGenerar] = useState(false);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [url, setUrl] = useState(null);
  const [embarazo, setEmbarazo] = useState(paciente.embarazo === "SI");
  const [radiografia, setRadiografia] = useState(paciente.radiografia === "SI");
  const [actualizarDatos, setActualizarDatos] = useState(false);

 const renderizarDocumento = (rowData) => {
  return (
    <Button
      className='boton_pdf'
      style={{ width: '100px', color: 'red' }}
      onClick={() => {
        setSelectedPdf(rowData.url); // Almacenar la URL del PDF en selectedPdf
        setVisiblePdf(true);
      }}
    >
      <i className="pi pi-file-pdf" style={{ fontSize: '2rem', width: '150px' }}></i>
    </Button>
  );
};

const getDocumentos = (url) => {
  // Define los datos que deseas enviar en la solicitud
  let data = {
    embarazo: embarazo ? "SI" : "NO", // Convertir booleano a string
    radiografia: radiografia ? "SI" : "NO", // Convertir booleano a string
  };
  // Realiza la solicitud GET
  http
    .get(`${url}${paciente.id}`, { params: data }) // Pasamos los datos como parámetros
    .then((response) => {
      setUrl(response.data.url);
      setActualizarDatos(false); // Reiniciamos el estado de actualizar datos
    })
    .catch((error) => {
      console.error('Error al obtener los documentos:', error);
    });
};

useEffect(() => {
  if (selectedPdf) {
    getDocumentos(selectedPdf); // Llamamos a la función getDocumentos cuando selectedPdf cambia
  }
}, [selectedPdf, paciente.id, embarazo, radiografia]);

useEffect(() => {
  if (embarazo !== (paciente.embarazo === "SI") || radiografia !== (paciente.radiografia === "SI")) {
    setActualizarDatos(true);
  }
}, [embarazo, radiografia, paciente]);

  

  const DocConsentimiento = {
    getDocsData() {
      return [
        {
          id: '1',
          nombre: 'Consentimiento de RX (Mujeres)',
          url: 'consentimiento/rx-mujeres/',
          documento: 'botonConsent'
        },
        {
          id: '2',
          nombre: 'Consentimiento de entrega de exámenes médicos',
          url: '/consentimiento/informado/',
          documento: 'botonConsent1'
        },
        {
          id: '3',
          nombre: 'Consentimiento de toma de muestra sanguínea',
          url: '/consentimiento/informado-laboratorio/',
          documento: 'botonConsent2'
        },
        {
          id: '4',
          nombre: 'Consentimiento para pruebas toxicológicas',
          url: '/consentimiento/informado-pruebas-toxicologicas/',
          documento: 'botonConsent3'
        },
        {
          id: '5',
          nombre: 'Consentimiento para uso de huella y firma',
          url: '/consentimiento/uso-de-huella-firma/',
          documento: 'botonConsent4'
        },
        {
          id: '6',
          nombre: 'Consentimiento para menores de edad',
          url: 'consentimiento/menor-edad/',
          documento: 'botonConsent5'
        }
      ];
    }
  };

  const HideDialogConsent = () => {
    setVisibleConsent(false);
  };

  const ConsentFooter = (
    <div className='flex flex-nowrap justify-content-end align-items-center' style={{ height: "70px" }}>
      <Button
        style={{ margin: "10px" }}
        type='button'
        className="delete w-2"
        label="Cancelar"
        onClick={HideDialogConsent} />

      <Button
        style={{ margin: "10px" }}
        type='submit'
        className="aceptar-consent w-2"
        label='Aceptar' />
    </div>
  );

  const showDialogGenerar = (rowData) => {
    setVisibleGenerar(true);
    setSelectedPdf(rowData);
  };

  const generarFirma = (rowData) => {
    return (
      <Button
        onClick={() => {
          showDialogGenerar(rowData);
        }}
        style={{ margin: "10px" }}
        type='button'
        className="delete w-7 flex justify-content-center align-items-center">
        <i className="pi pi-file-o"></i>
      </Button>
    );
  };

  useEffect(() => {
    setDocs(DocConsentimiento.getDocsData());
  }, []);

  return (
    <>
      <Dialog
        visible={visibleConsent}
        style={{ margin: '20px', width: '50%' }}
        onHide={() => setVisibleConsent(false)}
        header={<><div className="flex flex-nowrap" style={{ color: "#6366F1" }}>
          <i className="pi pi-download" style={{ fontSize: "1.5rem", marginRight: "10px" }}></i>
          <h1 className='mb-2 titulo-consentimiento'>Consentimientos informados</h1>
        </div>
          <p className='cred'>En esta sección puede cargar la firma y huella del paciente</p></>}
        footer={ConsentFooter}>
        <Identificacion paciente={paciente} datoPacienteX={paciente.correo} />

        <div className='gap-5 mt-5'>
          <p className='parrafo-anexar'>Anexar huella y firma en los siguientes documentos: </p>

          <DataTable
            value={docs}
            tableStyle={{}}>
            <Column field="id" align="center" header="Item" style={{ whiteSpace: "wrap" }} />
            <Column field="nombre" header="Nombre de Documento" style={{ whiteSpace: "wrap" }} />
            <Column
              body={(e) => generarFirma(e)}
              header="Acción"
              style={{ whiteSpace: "wrap" }} />
            <Column
              body={rowData => renderizarDocumento(rowData)}
              field="documento"
              header="Documento"
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
          </DataTable>
        </div>
        {actualizarDatos && <div style={{ marginTop: "10px", color: "red" }}>Actualizar datos</div>}
      </Dialog>

      <NuevoGenerar
        paciente={paciente}
        visibleGenerar={visibleGenerar}
        pdfFile={selectedPdf}
        setVisibleGenerar={setVisibleGenerar}
        consentimiento={'Consentimiento de RX (Mujeres)'}
        setEmbarazo={setEmbarazo} // Pasamos la función setEmbarazo
        setRadiografia={setRadiografia} // Pasamos la función setRadiografia
        embarazo={embarazo} // Pasamos el estado de embarazo actualizado
        radiografia={radiografia} // Pasamos el estado de radiografía actualizado
      >
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label>Embarazo:</label>
            <InputSwitch checked={embarazo} onChange={(e) => setEmbarazo(e.value)} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label>Radiografía:</label>
            <InputSwitch checked={radiografia} onChange={(e) => setRadiografia(e.value)} />
          </div>
        </div>
      </NuevoGenerar>

      <NuevoFirmaHuella
        visiblePdf={visiblePdf}
        setVisiblePdf={setVisiblePdf}
        pdfFile={selectedPdf}
        paciente={paciente}
        url={url}
        generarFirma={generarFirma}
      />
    </>
  );
}

export default Consentimiento;
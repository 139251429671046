export const contentService = [
   {
      id: 1,
      codeProform: "16U922AUU25",
      status: "En espera",
   },
   {
      id: 2,
      codeProform: "16U922AUU26",
      status: "Aceptado",
   },
   {
      id: 3,
      codeProform: "16U922AUU24",
      status: "Rechazado",
   },
];

import {Dropdown} from "primereact/dropdown";

const CercoTipo = (props) => {

  const tipos = [
    {name: 'Análisis Cuantitativo'},
    {name: 'Análisis Cualitativo'},
  ]

  return (
    <>
    <label className="text-xs">Tipo de Análisis</label>
      <Dropdown
        optionLabel="name"
        value={props.state}
        options={tipos}
        onChange={(e) => props.setState(e.value)}
        placeholder="Análisis cualitativo"
        className="flex p-inputtext-sm"
      />
    </>
  );
};

export default CercoTipo;
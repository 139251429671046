import Bienvenidos from "./Bienvenidos/Bienvenidos";
import ContactenosB from "./ContactenosB/ContactenosB";
import CapacitacionesCoorp from "./CapacitacionesCoorp/Capacitaciones";
// import SoporteTecnicoPrinc  from '../../Components/Soporte/SoporteTecnicoPrinc';
// import soporte from "../../Imagenes/soporte.jpg";

export default function Soporte({FContactenosB}) {
    function SendContactenosB(data) {
        FContactenosB(data);
    }

    

    return (
        <div className='SoporteTec_coorp' style= {{paddingLeft: '1.5rem' , paddingRight: '1rem', paddingBottom: '2rem'}}>
            <div className='STecnicoCorp' style={{ marginLeft: '20px' /*position: 'fixed', marginTop: '-80px' */}}>
                <h1 className='tit'>Soporte técnico</h1>
                <p className='con' style={{marginTop: '15px' , marginBottom: '10px'}}>
                    Edita este contenido para ayudar a tu público </p>
            </div>
            <div className='CuerpoSoporte flex gap-3 flex-wrap'>
                <div className='flex flex-column gap-3 flex-1' style={{ maxWidth: '594px' , paddingRight: '1.3rem' }}>
                    <div style={{paddingBottom: '1rem'}}>
                        <Bienvenidos />
                    </div>
                    <div>
                        <ContactenosB SendContactenosB={SendContactenosB} />
                    </div>
                </div>
                <div className="flex flex-column flex-1" >
                    <CapacitacionesCoorp />
                </div>
                
            </div>

        </div>
    )

    
}
import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import AuthUser from "../../../AuthUser";

const Nuevopopupfirma = ({ visible, setVisible, paciente,Setvariuable ,variable }) => {
  const { http } = AuthUser();
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const startDrawing = (e) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    ctx.beginPath();
    ctx.moveTo(x, y);
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;

    ctx.lineTo(x, y);
    ctx.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
  };

  const savefirma2 = () => {
    const canvas = canvasRef.current;
    const imageDataURL = canvas.toDataURL("image/png");

    canvas.toBlob((blob) => {
      const formdata = new FormData();
      formdata.append("firma", blob, "firma.png");

      http
        .post(`persona/update/firma/${paciente.id}`, formdata)
        .then((response) => {
        })
        .catch((error) => {
          console.error("Error al enviar la imagen al servidor:", error);
        });
    }, "image/png");
  };

  const FooterDialog = (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Button type="button" label="Limpiar" className="boton02" onClick={clearCanvas} style={{ marginRight: "10px" }} />
      <Button label="Cerrar" className="boton02" onClick={() => setVisible(false)} />
      <Button label="Guardar" onClick={()=>{
        savefirma2();
        setVisible(false);
        Setvariuable(variable+1)}} className="p-button-primary" style={{ marginLeft: "10px" }} />
    </div>
  );

  return (
    <Dialog
      visible={visible}
      onHide={() => setVisible(false)}
      footer={FooterDialog}
      header={<><div className="flex flex-nowrap" style={{ color: "#6366F1" }}>
        <i className="pi pi-download" style={{ fontSize: "1.5rem", marginRight: "10px" }}></i>
        <h1 className='mb-2 titulo-consentimiento'>Agregar firma</h1>
      </div>
        <p className='cred'>En esta sección puede cargar la firma del paciente</p></>}
      draggable={false}
      className="custom-dialog p-dialog-maximized"
      style={{ width: "100vw", height: "120vh" }}
    >
      <div className="w-full h-full text-center">

        <canvas
          ref={canvasRef}
          width={1200}
          height={570}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={endDrawing}
          onMouseLeave={endDrawing}
          style={{ border: "1px solid #000", width:'95%', height:'95%' }}
        ></canvas>
      </div>
    </Dialog>
  );
};

export default Nuevopopupfirma;

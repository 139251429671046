import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useDispatch } from "react-redux";

import { startLoading, endLoading } from "../../Redux/isLoadingSlice";
import AuthUser from '../../AuthUser';

import '../../Style/Operaciones/servicios.css'

import "./Style/VerificaFalta.css"

const TableOperacionesClinicaComponent = ({ getServicios, transformedNodes, data, selectedNodeKeys, seleccionarServicios }) => {
   const dispatch = useDispatch();

   const toast = useRef(null);
   const { http } = AuthUser()
   const [visibleMes, setVisibleMes] = useState(false);
   const [visibleAno, setVisibleAno] = useState(false)
   const [rowData, setRowData] = useState({})
   const precio = useRef('')
   const precioAnual = useRef('')
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const footerContent = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisibleMes(false)} className="p-button-text" />
         <Button label="Aceptar" icon="pi pi-check" onClick={() => EnvioUpdateCreate()} autoFocus />
      </div>
   );

   const footerContentAno = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisibleAno(false)} className="p-button-text" />
         <Button label="Aceptar" icon="pi pi-check" onClick={() => handleCreateAno()} autoFocus />
      </div>
   );

   const [VerfiuevoPrecioFalta, setVerfiuevoPrecioFalta] = useState(false);

   useEffect(() => {
      setVerfiuevoPrecioFalta(false);
   }, [visibleMes])
   const Verificar = () => {
      var verif = true;
      var inputPrecio = document.getElementById('NewPrecioInpputMenPP').value;
      if (inputPrecio === "") {
         verif = false;
         setVerfiuevoPrecioFalta(true)
      }
      return verif;
   }

   const EnvioUpdateCreate = () => {
      var veri = Verificar();
      if (veri) {
         handleCreate();
      }
   }

   const handleCreate = async () => {
      let data = {
         tipo: rowData.key.substring(0, 1),
         precio_referencial_mensual_venta: precio.current.value,
      };
      let id = rowData.key.substring(rowData.key.includes('.') ? (rowData.key.lastIndexOf(".") + 1) : (rowData.key.lastIndexOf("-") + 1), rowData.key.length);

      dispatch(startLoading());

      try {
         const response = await http.put(`clinica/operaciones/servicio/preciomensual/update/${id}`, data);

         if (response.data?.resp) {
            showToast(
               "success",
               "Precio Actualizado",
               `Se actualizó el precio mensual a S/.${precio.current.value} correctamente`
            );
            getServicios();
            setVisibleMes(false);
         } else if (response.data?.error) {
            showToast(
               "error",
               "Error al actualizar",
               response.data.error
            );
         }
      } catch (error) {
         showToast(
            "error",
            "Error al actualizar",
            'Contacte a soporte técnico'
         );
      } finally {
         dispatch(endLoading());
      }
   }

   const handleCreateAno = () => {
      let data = {
         tipo: rowData.key.substring(0, 1),
         precio_referencial_anual: precioAnual.current.value,
      };
      let id = rowData.key.substring(rowData.key.includes('.') ? (rowData.key.lastIndexOf(".") + 1) : (rowData.key.lastIndexOf("-") + 1), rowData.key.length);
      http.put(`clinica/operaciones/servicio/precio/udpate/${id}`, data)
         .then((response) => {
            showToast(
               "success",
               "Precio Actualizado",
               `Se actualizó el precio anual a S/.${precioAnual.current.value} correctamente`
            );
            getServicios();
            setVisibleAno(false);
         }).catch((error) => {
            showToast(
               "error",
               "Error al Actualizar",
               `No se pudo actualizar el precio anual`
            );
            console.error("Error al crear o actualizar servicio", error)
         });
   }

   const buttonActionMensual = (rowData) => {
      setVisibleMes(true)
      setRowData(rowData);
   }

   const buttonEditMensual = (rowData) => {
      if (rowData.children && rowData.children.length > 0) return null;
      return (
         <div >
            <Tag style={{ cursor: 'pointer' }} onClick={() => buttonActionMensual(rowData)}>
               <div>S/.{rowData.data.precio_mensual} </div>
            </Tag>
         </div>
      )
   }

   const [globalFilter, setGlobalFilter] = useState('');

   const getHeader = () => {
      return (
         <div className="flex justify-content-end">
            <div className="p-input-icon-left">
               <i className="pi pi-search" style={{}}></i>
               <InputText style={{ textAlign: 'left', marginLeft: "50px" }}
                  type="search"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Buscar área médica" />
            </div>
         </div>
      );
   };

   let header = getHeader();

   return (
      <><Toast ref={toast} />
         <div style={{ width: '100%', overflowY: 'auto' }}>
            <TreeTable
               globalFilter={globalFilter}
               header={header}
               tableStyle={{ width: '100%' }}
               value={transformedNodes}
               className="TablaDeOperacionesServicios ventas contactList"
               emptyMessage={<p className="text-center text-gray-500">
                  Seleccione un paquete para ver su detalle.
               </p>}
               headerStyle={{

               }}
               style={{
                  width: '100%', // Asegura que la tabla ocupe todo el ancho del contenedor
               }}
            >
               <Column
                  header="Precio Referencial Mensual"
                  field="nombre"
                  expander
                  headerStyle={{
                     width: '131px',
                     height: '20px',
                     top: '270px',
                     left: '929px',
                     color: '#248D63',
                     fontFamily: 'Montserrat',
                     fontWeight: '600',
                     fontSize: '14px',
                     lineHeight: '19.6px',

                  }}
                  style={{
                     width: '40px',
                     height: '20px',
                     top: '334px',
                     left: '936px',
                     color: '#393838',
                     fontFamily: 'Montserrat',
                     fontWeight: '600',
                     padding: '40px',
                     fontSize: '14px',
                     lineHeight: '19.6px'
                  }} />

               <Column
                  header=""
                  body={(e) => buttonEditMensual(e)}
                  className="text-center text-green-500"
                  headerStyle={{
                     padding: '5px',
                     whiteSpace: 'normal',
                     fontSize: '15px'
                  }}
               ></Column>

            </TreeTable>

            <Dialog
               key={1}
               header={<>
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#5555D8"
                     }}
                  >Nuevo precio para&nbsp;{rowData?.data?.nombre} </p>
               </>}
               visible={visibleMes}
               style={{ width: '25vw', minWidth: "238px" }}
               onHide={() => setVisibleMes(false)}
               footer={footerContent}
            >


               <div className='NewPrecioMensualAS flex gap-2 align-items-start pt-1 pb-1'>
                  <p className='mt-2'>S/.</p>
                  <div className='flex flex-column gap-2 relative w-full'>
                     <InputText
                        keyfilter={'num'}
                        defaultValue={rowData?.data?.precio_mensual}
                        id='NewPrecioInpputMenPP'
                        className={`w-full flex InputRetraccionNumCuAP ${VerfiuevoPrecioFalta ? 'Select' : ''}`}
                        key={rowData?.key}
                        onChange={() => { setVerfiuevoPrecioFalta(false) }}
                        ref={precio}
                        autoFocus=''>
                     </InputText>
                     <div className={`InputRetraccionNumCuAPDiv ${VerfiuevoPrecioFalta ? 'Select' : ''}`}></div>
                     <p className={`InputRetraccionNumCuAPTextError ${VerfiuevoPrecioFalta ? 'Select' : ''}`}>Necesita ingresar un nuevo precio</p>
                  </div>

               </div>
            </Dialog>
            <Dialog
               key={2}
               header={<>
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#5555D8"
                     }}
                  >Nuevo precio anual</p>
               </>}
               visible={visibleAno}
               style={{ width: '25vw', minWidth: "238px" }}
               onHide={() => setVisibleAno(false)}
               footer={footerContentAno}
            >
               <div className='flex dialogo-center mt-3 flex-row gap-3 align-items-center'>
                  S/.<InputText className='flex w-full' key={rowData?.key} ref={precioAnual} defaultValue={rowData?.data?.precio_anual}></InputText>
               </div>
            </Dialog>
         </div></>
   );
}

export default TableOperacionesClinicaComponent;
import React, { useState, useEffect, useRef, useContext } from "react";
import data from "../../../../Services/dataExamenFisico";
import DialogAgregarr from "./Dialog/DialogAgregar";
import DialogEditar from "./Dialog/DialogEditar";
import DialogEliminar from "./Dialog/DialogEliminar";
import style from '../../../../Style/estilo.css';
import { dialogContext } from "../context/contextdialog";
import { MGContext, MGProvider } from "../../MGProvider";
import { Button } from "primereact/button";
import DialogEliminarGenEvMed from "../Dialog/DialogEliminarGenEvMed";
import { RAContext } from "../../../RutaAtencion/RAContext";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export default function Componentinterconsultas() {
    let { interconsultaContext, setInterconsultaContext, evaluacionMedicaContext, setEvaluacionMedicaContext } = useContext(MGContext)
    let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
    const [DatosInter, setDatosInter] = useState([]);
    const [VisibleEliminar, setVisibleEliminar] = useState(false);
    const [VisibleEditar, setVisibleEditar] = useState(false);
    const [VisibleAgregar, setVisibleAgregar] = useState(false);

    const [InterID, setInterID] = useState();
    const [InterData, setInterData] = useState();

    // MODAL ELIMINAR
    const HandleDelete = (index) => {
        setVisibleEliminar(true);
        setInterID(index);
        setEvaluacionMedicaContext({
            ...evaluacionMedicaContext,
            med_interconsultas: interconsultaContext
        })
    }
    const cities = [
        { name: 'Administración de Hospitales', code: 1 },
        { name: 'Administración de salud', code: 2 },
        { name: 'Anatomia Humana', code: 3 },
        { name: 'Anatomia patolologica', code: 4 },
        { name: 'Anatomia patolologica-patologia clinica', code: 5 },
        { name: 'Anestesia cardiovascular', code: 6 },
        { name: 'Anestesia tesiologica', code: 7 },
        { name: 'Angiologia', code: 8 },
        { name: 'Bioquimica', code: 9 },
        { name: 'Cardiologia', code: 10 },
        { name: 'Cirugia cardiovascular pediatrica', code: 11 },
        { name: 'Cirugia craneomaxilofacial', code: 12 },
        { name: 'Cirugia de cabeza y cuello', code: 13 },
        { name: 'Cirugia de mano', code: 14 },
        { name: 'Cirugia general', code: 15 },
        { name: 'Cirugia general y oncologia', code: 16 },
        { name: 'Cirugia neumologica', code: 17 },
        { name: 'Cirugia pediatria', code: 18 },
        { name: 'Cirugia plastica y reparadora', code: 19 },
        { name: 'Cirugia toraccica y cardiovascular', code: 20 },
        { name: 'Clinica medica', code: 21 },
        { name: 'Dermatologia', code: 22 },
        { name: 'Embriologia', code: 23 },
        { name: 'Endocrinología', code: 24 },
        { name: 'Endocrinología pediátrica y genética', code: 25 },
        { name: 'Enfermedades infecciosas y trópicas', code: 26 },
        { name: 'Epidemiologia', code: 27 },
        { name: 'Farmacologia', code: 28 },
        { name: 'Fisiologia', code: 29 },
        { name: 'Gastroenterologia', code: 30 },
        { name: 'Gastroenterologia pediatrica', code: 31 },
        { name: 'Genetica', code: 32 },
        { name: 'Geriatria', code: 33 },
        { name: 'Ginecologia y obstetricia', code: 34 },
        { name: 'Hematología', code: 35 },
        { name: 'Histologia', code: 36 },
        { name: 'Histopatologia', code: 37 },
        { name: 'Infectologia', code: 38 },
        { name: 'Infectologia pediatrica', code: 39 },
        { name: 'Inmunologia y alergia', code: 40 },
        { name: 'Inmunologia y reumatologia', code: 41 },
        { name: 'Laboratorio clinico', code: 42 },
        { name: 'Laboratorio clinico y anatomia', code: 43 },
        { name: 'Medicina de emergencia y desastres', code: 44 },
        { name: 'Medicina del deporte', code: 45 },
        { name: 'Medicina del trabajo', code: 46 },
        { name: 'Medicina familiar', code: 47 },
        { name: 'Medicina fisica y rehabilitación', code: 48 },
        { name: 'Medicina general', code: 49 },
        { name: 'Medicina general integral', code: 50 },
        { name: 'Medicina general y oncologica', code: 51 },
        { name: 'Medicina integral y gestion en salud', code: 52 },
        { name: 'Medicina intensiva', code: 53 },
        { name: 'Medicina intensiva pediatrica', code: 54 },
        { name: 'Medicina interna', code: 55 },
        { name: 'Medicina legal', code: 56 },
        { name: 'Medico lector oit', code: 57 },
        { name: 'Nefrologia', code: 58 },
        { name: 'Nefrologia pediatrica', code: 59 },
        { name: 'Neonatologia', code: 60 },
        { name: 'Neonatologia pediatrica', code: 61 },
        { name: 'Neurocirugia', code: 62 },
        { name: 'Neurologia', code: 63 },
        { name: 'Neurologia pediatrica', code: 64 },
        { name: 'Nutrición', code: 65 },
        { name: 'Odontologo', code: 66 },
        { name: 'Odontologia', code: 67 },
        { name: 'Oftalmologia', code: 68 },
        { name: 'Oncologia medica', code: 69 },
        { name: 'Oncologia quirurgica', code: 70 },
        { name: 'Ortopedia y traumatologia', code: 71 },
        { name: 'Otorrinolaringologia', code: 72 },
        { name: 'Parasitologia', code: 73 },
        { name: 'Patologia clinica', code: 74 },
        { name: 'Patologia oncologica', code: 75 },
        { name: 'Patologia y laboratorio clinico', code: 76 },
        { name: 'Pediatria', code: 77 },
        { name: 'Proctologia', code: 78 },
        { name: 'Psiquiatria', code: 79 },
        { name: 'Psiquiatria infantil', code: 80 },
        { name: 'Radio Diagnostico', code: 81 },
        { name: 'Radiologia', code: 82 },
        { name: 'Radioterapia', code: 83 },
        { name: 'Reumatologia', code: 84 },
        { name: 'Salud publica', code: 85 },
        { name: 'Servicios otras descapacidades y m?', code: 86 },
        { name: 'Urologia general y oncologica', code: 87 },
        { name: 'Venereologia', code: 88 }
    ];


    //MODAL EDITAR
    const HandleEditar = (item, index) => {
        setVisibleEditar(true)
        setInterData(item);
        setInterID(index);
    }

    const ConfirmarEditar = (data) => {
        let DatosEncontrados = evaluacionMedicaContext.med_interconsultas[InterID];
        if (DatosEncontrados) {
            DatosEncontrados.med_especialidad_id = data?.med_especialidad_id
            DatosEncontrados.fecha = data?.fecha
            DatosEncontrados.fecha_proxima = data?.fecha_proxima
            DatosEncontrados.motivo = data?.motivo
            DatosEncontrados.observaciones = data?.observaciones
            DatosEncontrados.diagnostico = data?.diagnostico
        }
        setInterconsultaContext([...evaluacionMedicaContext.med_interconsultas])
    }

    useEffect(() => {
        setEvaluacionMedicaContext({
            ...evaluacionMedicaContext,
            med_interconsultas: interconsultaContext
        })
    }, [interconsultaContext])
    const eliminarDiagnosticoPorIndice = (indice) => {
        setEvaluacionMedicaContext(prevState => {
            const newState = { ...prevState };

            newState.med_interconsultas.splice(indice, 1);

            return newState;
        });
    };
    return (
        <div>
            <div className="flex flex-row" style={{ justifyContent: 'space-between', marginBottom: '25px' }}>
                <h1>Interconsultas</h1>
                <div className="flex flex-row gap-2 botones_inter">
                    <DialogAgregarr
                        setVisible={setVisibleAgregar}
                        Visible={VisibleAgregar}
                        titulo="Nueva interconsulta"
                    />
                    <Button style={{ height: '10px' }} icon="pi pi-plus" className="p-button-success" onClick={() => setVisibleAgregar(true)} disabled={stateAtencion[pageViewContext].estado === 3} />
                </div>
            </div>
            <DialogEliminarGenEvMed
                VisibleEliminar={VisibleEliminar} s
                setVisibleEliminar={setVisibleEliminar}
                InterID={InterID}
                ContextEliminar={interconsultaContext}
                funcion={eliminarDiagnosticoPorIndice}
            />
            <DialogAgregarr
                setVisible={setVisibleEditar}
                Visible={VisibleEditar}
                titulo="Editar interconsulta"
                InterData={InterData}
                Index={InterID}
                ConfirmarEditar={ConfirmarEditar}
            />

            <div style={{ overflowY: 'auto' }}>
                <table border={0} className="Hemogramatabla">
                    <thead>
                        <tr>
                            <th style={{ borderLeft: '0px' }}>Item</th>
                            <th>Especialidad</th>
                            <th>Fecha</th>
                            <th>Diagnostico</th>
                            <th>Motivo</th>
                            <th>Fecha próxima</th>
                            <th>Observaciones</th>
                            <th>Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {interconsultaContext?.map((item, index) => (
                            <React.Fragment>
                                <tr key={item.id}>
                                    <td style={{ borderLeft: '0px' }}>{index + 1}</td>
                                    <td>
                                        {cities.find(city => city.code === item.med_especialidad_id)?.name || 'Sin especialidad'}
                                    </td>
                                    <td>{item.fecha}</td>
                                    <td>{item.diagnostico}</td>
                                    <td>{item.motivo}</td>
                                    <td>{item.fecha_proxima}</td>
                                    <td>{item.observaciones}</td>
                                    <td>
                                        <React.Fragment>
                                            <Button
                                                // icon={stateAtencion[pageViewContext].estado === 3 ? "pi pi-eye" : "pi pi-pencil"}
                                                icon="pi pi-pencil"
                                                className="editar1"
                                                onClick={() => HandleEditar(item, index)}
                                            />
                                            <Button
                                                icon="pi pi-trash"
                                                className="eliminar1"
                                                onClick={() => HandleDelete(index)}
                                            // disabled={stateAtencion[pageViewContext].estado === 3}
                                            />
                                        </React.Fragment>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}

                    </tbody>
                </table>

            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from 'primereact/scrollpanel';
import { useDispatch } from "react-redux";

//Componentes generales
import AuthUser from "../../../../AuthUser";
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import TablaCoorporativa from "./Tabla";

//Imágenes
import bgcontain from "../../../../Images/Operaciones/Servicios/bg-contain.png";

//Estilos generales
import './Style/ResponsivePageServiciosEmpresa.css';
import "../../../../Style/styless.css";

// Componente de contenido vacío para cuando no se ha seleccionado un servicio
const content_void = (
    <div
        style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: '100%'
        }}
    >
        <p>Seleccione un servicio</p>
    </div>
);

// Hook personalizado para manejar la selección de servicios
export const HookOperaServicios = (data) => {

    const [selectedId, setSelectedId] = React.useState(null);
    const [selectedIndex, setSelectedIndex] = React.useState(null);

    // Función para manejar el clic en un botón de servicio
    const handleButtonClick = (id, index) => {
        setSelectedId(id);
        setSelectedIndex(index);
    };

    const selectedData = data?.[selectedIndex];
    return { selectedId, handleButtonClick, selectedData, selectedIndex };
};

export default function PageServiciosE() {
    const dispatch = useDispatch();
    const { http } = AuthUser();
    const [dataPaquetes, setDataPaquetes] = useState(null);
    const { selectedData, selectedId, handleButtonClick, selectedIndex } = HookOperaServicios(dataPaquetes);

    const [datosBusqueda, setBusqueda] = useState(dataPaquetes);

    // Función para realizar la búsqueda de perfiles en los servicios seleccionados
    const busqueda = (e) => {
        if (e.target.value !== '') {
            setBusqueda(selectedData.perfil_tipos.filter(item => item.perfil.nombre.includes(e.target.value)));
        }
        else if (e.target.value === '') {
            setBusqueda(dataPaquetes.perfil_tipos);
        }
    };

    // Función para generar un color aleatorio para los botones de servicios
    const generarColorAleatorio = () => {
        const rangoMin = 180;
        const rangoMax = 255;
        const generarComponente = () => Math.floor(Math.random() * (rangoMax - rangoMin + 1)) + rangoMin;

        const r = generarComponente();
        const g = generarComponente();
        const b = generarComponente();

        const convertirHex = (componente) => componente.toString(16).padStart(2, '0');

        return `#${convertirHex(r)}${convertirHex(g)}${convertirHex(b)}`;
    };

    const [coloresBotones, setcoloresBotones] = useState({});

    // Función para obtener los paquetes de la empresa
    const GetPaqueteEmpresa = async () => {
        dispatch(startLoading());

        try {
            const response = await http.get('empresa/paquetes/get_paquetes');

            if (response.data?.resp || response.data.data) {
                setDataPaquetes(response.data.data);

                const nuevosColores = {};
                for (let i = 0; i < response.data.data.length; i++) {
                    nuevosColores[i] = generarColorAleatorio();
                }
                setcoloresBotones(nuevosColores);

            } else if (response.data?.error) {
                console.error(response.data.error);
            }
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(endLoading());
        }
    };

    // Efecto para obtener los paquetes de la empresa al montar el componente
    useEffect(() => {
        GetPaqueteEmpresa();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="default__content pr-4 pl-4 contenedorPSPageServiciosHP">
            <div className="Titu_SubTituloSerHP">
                <span>Servicios</span>
                <span>En este módulo usted podrá ver sus servicios adquiridos.</span>
            </div>

            <div className="w-full h-full flex gap-2">
                <div className="ContenedorListaServiciAdPFSP relative h-full border-round-2xl bg-white pr-3 pl-3 pt-4">
                    <div className="subtituloDP w-full flex align-items-start justify-content-start gap-2 mb-2">
                        <i className="pi pi-th-large"></i>
                        <p>Nuestros servicios adquiridos</p>
                    </div>

                    <ScrollPanel className="PaneelScrollServicesGP custombar1 w-full">
                        <div className="flex flex-column gap-3">
                            {dataPaquetes?.map((item, index) => (
                                <button
                                    icon="pi pi-ellipsis-v"
                                    className="border-round-xl bg-white p-2"    
                                    onClick={() => handleButtonClick(item.paquete, index)}
                                >
                                    <div className="w-full flex gap-3">
                                        <div className="ConteICconos"
                                            style={{ backgroundColor: coloresBotones[index] }}>
                                            <i className="pi pi-user"></i>
                                        </div>
                                        <div className="TextTituloService">{item.paquete.nombre}</div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </ScrollPanel>

                    <img src={bgcontain} alt="contain" className="absolute img_ArchiveroSdTablaSele" style={{ width: '110px', objectFit: 'cover', bottom: '20px', left: '105px' }} />
                </div>

                <div className="ContenedorListaServiciAdPFSP flex-1 h-full border-round-2xl bg-white pr-3 pl-3 pt-4 pb-4">
                    <section className="section-two w-full">
                        {selectedId ? (
                            <div className="default-section-node">
                                <div className="w-full flex align-items-center justify-content-between">
                                    <div className="flex align-items-center justify-content-center gap-2 TituloSelectPr">
                                        <div style={{ backgroundColor: coloresBotones[selectedIndex] ? coloresBotones[selectedIndex] : "white" }}>
                                            <i className="pi pi-user"></i>
                                        </div>
                                        <h1 style={{ fontWeight: "600", fontSize: "20px" }}>
                                            {selectedData.paquete.nombre}
                                        </h1>
                                    </div>

                                    <div className="relative">
                                        <i className="pi pi-search absolute" style={{ top: '15px', left: '10px' }}></i>
                                        <InputText className="pl-5" placeholder="Buscar..." onChange={(e) => { busqueda(e) }} />
                                    </div>
                                </div>

                                <TablaCoorporativa dataPerfiles={datosBusqueda ? datosBusqueda : selectedData.perfil_tipos} />
                            </div>
                        ) : (
                            content_void
                        )}
                    </section>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import { Dialog } from "primereact/dialog";

import { Footer, Header } from "../../../Pure/SeccionesNavegacion";

export default function ModalEliminarLocal({ handleDelete, setVisibleDelete, visibleDelete }) {

  const header = (<Header piIcon={'pi pi-trash'} titulo={'Eliminar local'} />)

  const footer = (<Footer
    onClickCancelar={() => { setVisibleDelete(false) }}
    onClickEnviar={() => {
      handleDelete(visibleDelete.id);
      setVisibleDelete(false);
    }}
    label={"Eliminar"} />)

  return (
    <Dialog
      visible={visibleDelete.status}
      style={{ height: "250px", width: "450px" }}
      className="p-fluid eliminarLocal"
      header={header}
      footer={footer}
      onHide={() => {
        setVisibleDelete(false);
      }}
    >
      <div className="flex flex-column gap-3">
        <div className="flex flex-column w-full">
          <p>¿Está seguro qué desea eliminar local?</p>
        </div>
      </div>
    </Dialog>
  )
}
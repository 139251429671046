import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

import "../../../../Style/CSS_tabla.css";

//Componentes únicos
import { ModalCreateRecluta, ModalEditarRecluta, ModalEliminarRecluta } from '../../../../Components/RecursosHumanos/Reclutamiento/Components/index'

//Componentes generales
import AuthUser from "../../../../AuthUser";
import TablaCrud from "../../../../Components/Table/TablaCrud";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import CargarDatosDNI from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarDatosDNI";
import CargarData from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarData";
import ModalTransferir from "../../../../Components/RecursosHumanos/Reclutamiento/ModalTransferir";
import { DialogAsignarServicio, AsignarServicioGrupal } from "../../../../Components/RecursosHumanos/components/index-asignar-servicio";

function PageReclutamientoEmpresa() {
   const dispatch = useDispatch();

   const { http } = AuthUser();

   const toast = useRef(null);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [personal, setPersonal] = useState([]);
   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleTransferir, setvisibleTransferir] = useState(false);
   const [visibleDialogDNI, setVisibleDialogDNI] = useState(false);
   const [visibleAsingar, setVisibleAsingar] = useState(false)
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [roles, setRoles] = useState([]);
   const [visibleAsignarGrupal, setVisibleAsignarGrupal] = useState(false)
   const [selectedPersonal, setSelectedPersonal] = useState([])
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [locales, setLocales] = useState([])
   const [sexos, setSexos] = useState([])
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      rol: "",

   });

   //Función notificaciones
   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   /* INTEGRACIONES GET */

   const getLocales = async (id) => {
      try {
         const response = await http.get(`empresa/local/get/${id}`)
         if (response?.data?.data) {
            setLocales(response?.data?.data)
         }
      } catch (error) {
         showToast('error', 'Error', error)
      };
   };

   const getAllTipoDocumentos = async () => {
      try {
         const response = await http.get("/tipodocumentos/get")
         if (response?.data?.data) {
            setTipoDocumentos(response.data.data);
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      };
   };

   const getRoles = async () => {
      try {
         const response = await http.get("roles/get")
         if (response?.data?.data) {
            setRoles(response?.data?.data);
         }
      } catch (error) {
         showToast("error", 'Error', error)
      };
   };

   const getPersonal = async () => {
      try {
         const response = await http.get("empresa/personal/reclutamiento/get")
         if (response?.data?.data) {
            setPersonal(response.data?.data);
         } else if (response?.data.resp) {
            setPersonal(null);
         }
      } catch (error) {
         showToast("error", 'Error', "Contáctese con soporte técnico")
      };
   };

   const getGenero = async () => {
      try {
         const response = await http.get("sexos/get");
         if (response?.data?.data) {
            setSexos(response.data.data);
         }
      } catch (error) {
         console.error('Error al obtener géneros', error)
         showToast('error', 'Error al obtener géneros', 'Contáctese con soporte técnico.')
      };
   };

   const downloadTemplate = async (id) => {
      try {
         const response = await http.get(`empresa/personal/reclutamiento/download-template`, { responseType: 'blob' })
         if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx'); // o el nombre que desees para el archivo
            document.body.appendChild(link);
            link.click();
            link.remove();
         }
      } catch (error) {
         console.error("Error al descargar plantilla", error);
      };
   };

   const handleObtenerPuesto = async () => {
      try {
         const response = await http.get("/empresa/cargo/get1")
         setData(response.data.data);
      } catch (error) {
         console.error("Error al obtener puestos de trabajo", error);
      }

   };

   //Integraciones post
   const subirArchivo = async (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", data);
      dispatch(startLoading())
      try {
         const response = await http.post("/empresa/personal/reclutamiento/import", formData)
         if (response?.data?.resp) {
            await Promise.all([
               getPersonal(),
               setVisibleDialog(false),
               setIsLoading(false)
            ])

            showToast("success", "Éxito", response?.data?.resp)

         } else if (response?.data?.error) {
            showToast('error', "Error", response.data.error)
         }
      } catch (error) {
         setIsLoading(false)
         setVisibleDialogDNI(false)
         showToast('error', "Error", "Contáctese con soporte técnico")
      } finally {
         dispatch(endLoading());
      };
   };

   const subirArchivoDNI = async (data) => {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", data);
      dispatch(startLoading())
      try {
         const response = await http.post("/empresa/personal/reclutamiento/import/dni", formData)
         if (response?.data?.resp) {
            await Promise.all([
               getPersonal(),
               setVisibleDialogDNI(false),
               setIsLoading(false),
            ])

            showToast("success", "Éxito", "Personal asignado correctamente")

         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error)
         }
      } catch (error) {
         setVisibleDialogDNI(false)
         setIsLoading(false)
         showToast("error", "Fallo al asignar", "Contáctese con soporte técnico");
      } finally {
         dispatch(endLoading());
      };
   };

   const asignarPaqueteGrupal = (data) => {
      dispatch(startLoading())
      http
         .post("empresa/personal/asignar/servicio", data)
         .then((response) => {
            if (response?.data?.resp) {
               showToast("success", "Envío grupal", "Grupo asignado correctamente")
            } else if (response?.data?.error) {
               showToast('error', 'Error', response.data.error)
            }
         })
         .catch((error) => {
            showToast("error", 'Error', "Contáctese con soporte técnico")
         })
         .finally(() => {
            dispatch(endLoading());
         });
   }

   const asignarPaquetes = (data) => {
      dispatch(startLoading())
      http
         .post("empresa/personal/asignar/perfil", data)
         .then((response) => {
            if (response?.data?.resp) {
               showToast("success", "Asignado correctamente", "Se asignó correctamente al personal");
               setVisibleAsingar(false);
            } else if (response?.data?.error) {
               showToast('error', 'Error', response?.data?.error)
            }
         })
         .catch((error) => {
            showToast('error', 'Error', "Contáctese con soporte técnico")
         })
         .finally(() => {
            dispatch(endLoading());
         });
   };

   //Activa DialogAsignarServicio
   const actionBodyAsignar = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-send"
               className="editar1"
               onClick={() => AsignarServicio(rowData)}
            />
         </React.Fragment>
      );
   };

   //Activa ModalEliminarRecluta y ModalEliminarRecluta
   const actionBodyTemplate = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />
         </React.Fragment>
      );
   };

   //Activa ModalTransferir
   const actionBodyTransferir = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-qrcode"
               className="editar1"
               onClick={() => transferir(rowData)}
            />
         </React.Fragment>
      );
   };

   //Envía información del personal y abre el modal
   const AsignarServicio = (rowData) => {
      setVisibleAsingar(true);
      setSelectPersonal(rowData);
   };

   const editPaquete = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleEdit(true);
      setSelectPersonal(rowData)
   };

   const deletePaquete = (rowData) => {
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };

   const transferir = (rowData) => {
      setvisibleTransferir(true);
      setSelectPersonal(rowData);
   };

   const openDialog = () => {
      setVisibleDialog(true)
   }

   const handleItem = (rowData, index) => {
      return <div>{index.rowIndex + 1}</div>
   }

   const fetchAllData = async () => {
      try {
         //Espera la ejecución y respuesta de las funciones
         await Promise.all([
            getAllTipoDocumentos(),
            getPersonal(),
            getRoles(),
            handleObtenerPuesto(),
            getGenero(),
            getLocales(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         //Finaliza la carga
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      //Inicia la carga al montar el componente
      dispatch(startLoading());

      //Prepara los datos
      fetchAllData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div>
         <div className="flex flex-column">
            <h1 className="titulo">Reclutamiento</h1>
            <p className="subtitulo">En este módulo usted puede gestionar los postulantes a bregma</p>
         </div>

         <div className="movil_reclu_corp mt-4">
            <Toast ref={toast} />
            <TablaCrud
               style={{ border: "100px solid black" }}
               selectionMode={null ? 'checkbox' : null}
               selection={selectedPersonal} // Personal seleccionado
               onSelectionChange={(e) => setSelectedPersonal(e.value)} // Maneja el cambio de selección
               isHeader={true} // Muestra la barra de búsqueda en el encabezado
               name="Personal"
               tableNombre={`Lista de Personal`}
               descripcion={`A continuación, se visualiza la lista de personal.`}
               value={personal}// Datos del personal

               //Acción y etiquetas
               onClickLeftButton1={() => { setVisibleCreate(true); }}
               leftLabelButton1={" Crear"}
               onClickLeftButton2={() => setVisibleAsignarGrupal(true)}
               leftLabelButton2={" Asignar Servicio"}
               onClickLeftButton5={() => openDialog()}
               leftLabelButton5={" Cargar datos"}
            >
               <Column selectionMode="multiple"></Column>
               <Column className="c1" header="Item" body={handleItem} style={{ borderLeft: "none" }} ></Column>
               <Column className="c1" field={"persona.tipo_documento.nombre"} header="Tipo_Doc"></Column>
               <Column className="c1" field={"persona.numero_documento"} header="# de Documento"></Column>
               <Column className="c1" style={{ display: 'none' }} field={"persona.nombres"} header="Tipo_Doc"></Column>
               <Column className="c1"
                  body={(rowData) => `${rowData.persona.nombres} ${rowData.persona.apellido_paterno} ${rowData.persona.apellido_materno}`}
                  header="Nombre Completos" >
               </Column>
               <Column className="c1" field={"persona.correo"} header="Correo"></Column>
               <Column className="c1" field={"persona.celular"} header="Celular"></Column>
               <Column className="c1" header="Enviar Servicio" body={actionBodyAsignar}></Column>
               <Column className="c1" field={"persona.tranferir"} header="Transferir" body={actionBodyTransferir} ></Column>
               <Column className="c1" header="Acciones" style={{ maxWidth: "8rem" }} body={actionBodyTemplate}></Column>
            </TablaCrud>
         </div>

         {/* Modal Crear Recluta */}
         <ModalCreateRecluta
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            sexos={sexos}
            getPersonal={getPersonal}
            api="empresa/personal/reclutamiento/create"
            data={data}
         />

         {/* Modal Editar Recluta */}
         <ModalEditarRecluta
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            data={data}
            sexos={sexos}
            api="empresa/personal/reclutamiento/update"
            dispatch={dispatch}
         />

         {/* Modal Eliminar */}
         <ModalEliminarRecluta
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"empresa/personal/reclutamiento/delete"}
         />

         {/* Modal Carga de datos DNI*/}
         <CargarDatosDNI
            visible={visibleDialogDNI}
            setVisible={setVisibleDialogDNI}
            subirArchivo={subirArchivoDNI}
            isLoading={isLoading}
         />

         {/* Modal Carga de Datos */}
         <CargarData
            visible={visibleDialog}
            setVisible={setVisibleDialog}
            subirArchivo={subirArchivo}
            isLoading={isLoading}
            downloadTemplate={downloadTemplate}
         />

         {/* Modal Asignar Servicio */}
         <DialogAsignarServicio
            visibleAsignar={visibleAsingar}
            setVisibleAsignar={setVisibleAsingar}
            showToast={showToast}
            item={selectPersonal}
            data={data}
            asignarPaquetes={asignarPaquetes}
         />

         {/* Modal Asignar servicio */}
         <AsignarServicioGrupal
            visibleAsignar={visibleAsignarGrupal}
            setVisibleAsignar={setVisibleAsignarGrupal}
            asignarPaquetes={asignarPaqueteGrupal}
            locales={locales}
            item={selectedPersonal}
            examenes={selectedPersonal}
         />

         {/* Modal transferir */}
         <ModalTransferir
            setvisibleTransferir={setvisibleTransferir}
            visibleTransferir={visibleTransferir}
            showToast={showToast}
            item={selectPersonal}
            data={data}
            getPersonal={getPersonal}
            api="empresa/personal/reclutamiento/estadoreclutamiento"
         />

         {/* Dialogs sin uso */}
         {/* <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            api={'empresa/personal/enviar_credenciales'}
         /> 
         <ModalCarga
            visibleCarga={visibleCarga}
            setVisibleCarga={setVisibleCarga}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />
         
         <ModalReporte
            visibleReporte={visibleReporte}
            setVisibleReporte={setVisibleReporte}
            personal={personal}
            setPersonal={setPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
         />*/}
      </div>
   );
}

export default PageReclutamientoEmpresa;
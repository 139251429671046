import React, { useContext, useEffect, useState, useRef } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import "../../../../Style/ANTECEDENTE.css";
import ICONO from "../img/ICONORIESGOS.svg";
import { Dropdown } from 'primereact/dropdown';
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from "primereact/inputtext";
import { TriajeContext } from "../../../Triaje/TriajeContext";
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import InputCalendar from "../../../../Components/form/InputCalendar";
import { RAContext } from "../../../RutaAtencion/RAContext";
import VoiceButton from "../../../../Components/ButtonVoice/VoiceButtonTriajeRiesgo";

export default function EDITARAGREGARRIESGOS({ visibleRiesgo, setVisibleRiesgo, mostrarDatosActualizadosRiesgo, DatosEditarRiesgo, showToast }) {
    let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
    /** DATOS DE RIEGO LABORAL EN ANTECEDENTES*/
    const [Riesgo, setRiesgo] = useState(DatosEditarRiesgo?.riesgo ?? null);
    const [Agente, setAgente] = useState([]);
    const [Observacion, setObservacion] = useState(DatosEditarRiesgo?.observacion ?? null);
    const [LaboresExpuestas, setLaboresExpuestas] = useState(DatosEditarRiesgo?.labores_expuestas ?? null);
    const [FuentesRiesgo, setFuentesRiesgo] = useState(DatosEditarRiesgo?.fuentes_riesgo ?? null);
    const [TipoExposicion, setTipoExposicion] = useState(DatosEditarRiesgo?.tipo_exposicion ?? null);
    const [EPP, setEPP] = useState(DatosEditarRiesgo?.equipo_proteccion_personal ?? null);
    const [Hr, setHr] = useState(DatosEditarRiesgo?.hora ?? null);
    const [Dia, setDia] = useState(DatosEditarRiesgo?.dia ?? null);
    const [DetalleEPP, setDetalleEPP] = useState(DatosEditarRiesgo?.detalle_epp ?? null);
    const ObservacionRef = useRef(null);
    const LaboresExpuestasRef = useRef(null);
    const FuentesRiesgoRef = useRef(null);
    const detalleEPPRef = useRef(null);

    const footerContent = (
        <div className="flex flex-wrap flex-row mt-3 mb-3 justify-content-end">
            <Button label="Aceptar" onClick={() => UpdateAntLab()} autoFocus />
            <Button label="Cancelar" onClick={() => setVisibleRiesgo(false)} className="p-button-text" />
        </div>
    );

    const [selectedRiesgo, setSelectedRiesgo] = useState(null);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    useEffect(() => {
        setRiesgo(DatosEditarRiesgo?.riesgo ?? '');
        setObservacion(DatosEditarRiesgo?.observacion ?? '');
        setLaboresExpuestas(DatosEditarRiesgo?.labores_expuestas ?? '');
        setFuentesRiesgo(DatosEditarRiesgo?.fuentes_riesgo ?? '');
        setTipoExposicion(DatosEditarRiesgo?.tipo_exposicion ?? '');
        setEPP(DatosEditarRiesgo?.equipo_proteccion_personal ?? '');
        setHr(DatosEditarRiesgo?.hora ?? '');
        setDia(DatosEditarRiesgo?.dia ?? null);
        setDetalleEPP(DatosEditarRiesgo?.detalle_epp ?? '');

        if (DatosEditarRiesgo?.tipo_exposicion != null) {
            for (const city of citiesExposicion) {
                if (DatosEditarRiesgo?.tipo_exposicion == city.code) {
                    setTipoExposicion(
                        { name: city.name, code: city.code }
                    )
                }
            }
        }
    
        if (DatosEditarRiesgo?.equipo_proteccion_personal != null) {
            for (const city of citiesEPP) {
                if (DatosEditarRiesgo?.equipo_proteccion_personal == city.name) {
                    setEPP(
                        { name: city.name, code: city.code }
                    )
                }
            }
        }

        if (DatosEditarRiesgo?.riesgo_agente && DatosEditarRiesgo?.riesgo_agente != []) {
            const nombresAgentes = DatosEditarRiesgo?.riesgo_agente.map(item => item.agente);
            setAgente(nombresAgentes)
        }

    }, [DatosEditarRiesgo]);

    const citiesExposicion = [
        { name: 'Esporádica', code: '0' },
        { name: 'Permanente', code: '1' },
        { name: 'Ninguno', code: '2' } 
    ];

    const citiesEPP = [
        { name: 'Incompleto', code: '0' },
        { name: 'Completo', code: '1' },
        { name: 'Ninguno', code: '2' }
    ];

    const UpdateAntLab = () => {
        try {
            let datosActualizados = {
                Riesgo,
                Agente,
                Observacion,
                LaboresExpuestas,
                FuentesRiesgo,
                TipoExposicion,
                EPP,
                Hr,
                Dia,
                DetalleEPP,
            };
        
            // Verificar si showToast es una función antes de llamarla
            if (typeof showToast === 'function') {
                showToast(); // Llama a la función showToast
            }
        
            mostrarDatosActualizadosRiesgo(datosActualizados);
        } catch (error) {
            console.error(error); // Imprimir el error en la consola del navegador
        } finally {
            setVisibleRiesgo(false); // Siempre cierra el diálogo, incluso si ocurre un error
        }
    }

    const limpiar = () => {
        setRiesgo(null)
        setAgente([])
        setObservacion(null)
        setLaboresExpuestas(null)
        setFuentesRiesgo(null)
        setTipoExposicion(null)
        setEPP(null)
        setHr(null)
        setDia(null)
        setDetalleEPP(null)
    }

    const riesgosCheckboxes = {
        Biológicas: [
            { label: 'Bacterias', value: '1' },
            { label: 'Parásitos', value: '2' },
            { label: 'Virus', value: '3' },
            { label: 'Hongos', value: '4' },
            { label: 'Ricketsias', value: '5' },
            { label: 'Animales', value: '6' },
            { label: 'Plantas', value: '7' }
        ],
        Ergonómicas: [
            { label: 'Monotonia - Movimiento repetitivos', value: '8' },
            { label: 'Sobrecarga 1', value: '9' },
            { label: 'Posiciones corporales', value: '10' },
            { label: 'Ciclos metabolicos', value: '11' },
            { label: 'Lugaresde trabajo y controles', value: '12' }
        ],
        Físicas: [
            { label: 'Ruido', value: '13' },
            { label: 'Vibración', value: '14' },
            { label: 'Temperaturas y humedades extremas', value: '15' },
            { label: 'Iluminación defectuosa', value: '16' },
            { label: 'Variaciones de presión', value: '17' },
            { label: 'Radiación ionizante', value: '18' },
            { label: 'Radiación no ionizante', value: '19' }
        ],
        Psicosocial: [
            { label: 'Stress', value: '20' },
            { label: 'interacción con personas', value: '21' },
            { label: 'Trabajo monótono', value: '22' },
            { label: 'Carga mental', value: '23' },
        ],
        Químicas: [
            { label: 'Gases', value: '24' },
            { label: 'Vapores', value: '25' },
            { label: 'Polvos orgánicos naturales', value: '26' },
            { label: 'Polvos orgánicos sintéticos', value: '27' },
            { label: 'Polvos inorgánicos', value: '28' },
            { label: 'Humos', value: '29' },
            { label: 'Neblina o rocios', value: '30' },
            { label: 'Diferencia de oxígeno', value: '31' }
        ],
    };

    const handleCheckboxChange = (e, checkboxLabel) => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setAgente([...Agente, checkboxLabel]);
        } else {
            setAgente(Agente.filter((value) => value !== checkboxLabel));
        }
    };

    return (
        <div className="flex justify-content-start" style={{ marginTop: '20px', marginBottom: '10px' }}>
            <Dialog visible={visibleRiesgo} style={{ width: '50vw' }} onHide={() => setVisibleRiesgo(false)} footer={footerContent}>
                <div className="flex flex-column gap-1">
                    <div className="CONTENEDORTITULOCONICONO gap-3">
                        <div className="CONTENEDOR_ICONO">
                            <img src={ICONO} alt="Ícono" />
                        </div>
                        <span>Editar Nuevo Antecedente Riesgo Laboral</span>
                    </div>
                    <div className="CONTENEDORDROPDOWNLIST flex flex-column gap-3">
                        <div className="flex flex-column gap-2">
                            <span>Riesgo:</span>
                            <Dropdown
                                value={Riesgo}
                                onChange={(e) => setRiesgo(e.value)}
                                options={Object.keys(riesgosCheckboxes)}
                                placeholder="Selecciona un riesgo"
                                className="w-full"
                                disabled={stateAtencion[pageViewContext].estado === 3}
                            />
                        </div>
                        <div className="flex-auto">
                            <span className="flex mb-3">Agente:</span>
                            <ScrollPanel style={Riesgo ? { width: '100%', height: '200px' } : { width: '100%', height: '0px' }} className="custombar1">
                                {Riesgo && (
                                    <div className="LISTACHECKBOX flex flex-column gap-3">
                                        {riesgosCheckboxes[Riesgo].map((checkbox) => (
                                            <label key={checkbox.value} htmlFor={checkbox.value} className="flex flex-row gap-3" style={{ alignItems: 'center' }}>
                                                <Checkbox className="CheckboxOPCIONESNUEVOSRIESGO" value={checkbox.value} checked={Agente?.includes(checkbox.label)}
                                                    onChange={(e) => handleCheckboxChange(e, checkbox.label)} disabled={stateAtencion[pageViewContext].estado === 3} />
                                                {checkbox.label}
                                            </label>
                                        ))}
                                    </div>
                                )}
                            </ScrollPanel>
                        </div>
                    </div>
                    <div className="CONTENEDORINPUTTAREAEPP flex flex-column gap-3">
                        <div className="flex flex-column gap-2">
                            <span>Observación</span>
                            <VoiceButton
                                rows="3"
                                cols="50"
                                className="INPUTTAREASTYLE"
                                value={Observacion} onChange={(value) => setObservacion(value)}
                                ref={ObservacionRef}
                            />
                        </div>
                        <div className="flex flex-column gap-2">
                            <span>Labores Expuestas</span>
                            <VoiceButton rows="3" cols="50" 
                            value={LaboresExpuestas} 
                            onChange={(value) => setLaboresExpuestas(value)} 
                            className="INPUTTAREASTYLE" 
                            ref={LaboresExpuestasRef}
                            />
                        </div>
                        <div className="flex flex-column gap-2">
                            <span>Fuentes de Riesgo</span>
                            <VoiceButton rows="3" cols="50" 
                            value={FuentesRiesgo} 
                            onChange={(value) => setFuentesRiesgo(value)} 
                            className="INPUTTAREASTYLE" 
                            ref={FuentesRiesgoRef}
                            />
                        </div>
                        <div className="flex flex-column gap-2">
                            <span>Tipo de Exposición</span>
                            <Dropdown value={TipoExposicion}
                                onChange={(e) => setTipoExposicion(e.value)}
                                options={citiesExposicion}
                                optionLabel="name"
                                placeholder="Selecionar una Opcion"
                                className="w-full"
                                disabled={stateAtencion[pageViewContext].estado === 3} />
                        </div>
                    </div>
                    <div className="CONTENEDOREPPS flex flex-wrap gap-3">
                        <div className="flex flex-column gap-2 w-full flex-1">
                            <span>Equipo Protección Personal (EPP)</span>
                            <Dropdown value={EPP} onChange={(e) => setEPP(e.value)}
                                options={citiesEPP} optionLabel="name"
                                placeholder="Selecionar una Opcion"
                                className="w-full"
                                disabled={stateAtencion[pageViewContext].estado === 3}
                            />
                        </div>
                        <div className="flex flex-row gap-3 w-full flex-1">
                            <div className="flex flex-column gap-2">
                                <span>Hr</span>
                                <InputText
                                    value={Hr}
                                    onChange={(e) => setHr(e.target.value)}
                                    disabled={stateAtencion[pageViewContext].estado === 3}
                                />
                            </div>
                            <div className="flex flex-column" style={{ justifyContent: 'end', paddingBottom: '15px' }}>
                                <span>%</span>
                            </div>
                            <div className="flex flex-column gap-2">
                                <span>Dia</span>
                                <InputText
                                    value={Dia}
                                    onChange={(e) => setDia(e.target.value)}
                                    showIcon
                                    disabled={stateAtencion[pageViewContext].estado === 3}
                                />
                            </div>
                        </div>
                        <div className="flex flex-column w-full gap-2">
                            <span>Detalle EPP</span>
                            <VoiceButton rows="3" cols="50" value={DetalleEPP} onChange={(value) => setDetalleEPP(value)} className="INPUTTAREASTYLE" ref={detalleEPPRef}/>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
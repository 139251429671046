import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";

//Componentes generales
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";

//Componentes únicos
import CardAreas from '../../../../Components/RecursosHumanos/Local/LocalAreas/AreasComponentes/CardAreas'
import CardListasGenerales from '../../../../Components/RecursosHumanos/Local/LocalAreas/AreasComponentes/CardListasGenerales'
import TablaAreas from '../../../../Components/RecursosHumanos/Local/LocalAreas/AreasComponentes/TablaAreas'

const PageMisAreasE = () => {
   const dispatch = useDispatch();
   const { http } = AuthUser();
   const { id: idLocal } = useParams();
   const toast = useRef(null);
   const [areas, setAreas] = useState([]);
   const [personalGeneral, setPersonalGeneral] = useState([]);
   const [NombreGerente, setNombreGerente] = useState(null);
   const [NombreGerenteBD, setNombreGerenteBD] = useState(null);
   const [source, setSource] = useState([]);
   const [target, setTarget] = useState([]);
   const [locales, setLocales] = useState([]);

   //Integraciones get
   const handlePersonalGeneral = async () => {
      try {
         const response = await http.get("empresa/personal/get")
         if (response?.data?.data) {
            setPersonalGeneral(response?.data?.data);
         } else if (response?.data?.error) {
            ShowToast("error", "Error al obtener datos del personal", response.data.error)
         }
      } catch (error) {
         ShowToast("error", "Error al obtener datos del personal", "Contáctese con Soporte Técnico.")
         console.error("Error al obtener datos del personal", error);
      }
   }

   const HandleGetGerente = async () => {
      try {
         const response = await http.get(`/empresa/local/jefe/mostrar/${idLocal}`)
         if (response?.data?.data) {
            setLocales(response.data.data);
            let datos = null;
            if (response.data.data.empresa_personal != null) {
               datos = response.data.data.empresa_personal.persona.apellido_paterno + " " + response.data.data.empresa_personal.persona.apellido_materno + ", " + response.data.data.empresa_personal.persona.nombres;
            }
            setNombreGerenteBD(datos);
         } else if (response?.data?.error) {
            ShowToast("error", "Error al obtener datos del gerente", response.data.error)
         }
      } catch (error) {
         ShowToast("error", "Error al obtener datos del gerente", "Contáctese con Soporte Técnico.")
         console.error(error);
      };
   };

   const obtenerAreasgenerales = async () => {
      try {
         const response = await http.get(`/empresa/local/${idLocal}/areas/get`)
         if (response?.data?.data) {
            //Cambia los datos de las áreas para mostrar en la tabla
            let data = changeDataToTreeTable(response.data.data);
            //Actualiza el estado de las áreas
            setAreas(data);
         } else if (response?.data?.error) {
            ShowToast("error", "Error al obtener datos de las áreas", response.data.error)
         }
      } catch (error) {
         ShowToast("error", "Error al obtener datos de las áreas", "Contáctese con Soporte Técnico.")
         console.error(error);
      };
   };

   //Integraciones post
   const handleCrearGerente = async (data) => {
      dispatch(startLoading());
      try {
         const response = await http.post("empresa/local/jefe/asignar", data)
         if (response?.data?.resp) {
            await Promise.all([
               HandleGetGerente()
            ]);
            ShowToast("success", "Gerente asignado", "Gerente asignado correctamente")
         } else if (response?.data?.error) {
            ShowToast("error", "Error al asignar gerente", response.data.error)
         }
      } catch (error) {
         console.error("Error al asignar gerente: ", error)
         ShowToast("error", "Error al asignar gerente", "Contáctese con Soporte Técnico.")
      } finally {
         dispatch(endLoading());
      }
   };

   const handleagregarAreasgenerales = async (a) => {
      let dataSend = {
         nombre: a.nombre,
         parent_id: a.parent_id,
         empresa_local_id: idLocal,
      }
      dispatch(startLoading());
      try {
         const response = await http.post("empresa/area/create", dataSend)
         if (response?.data?.resp) {
            await Promise.all([
               obtenerAreasgenerales()
            ])
            ShowToast("success", "Área creada", "Área creada correctamente")

         } else if (response?.data?.error) {
            ShowToast("error", "Error al crear área", response.data.error)
         }
      } catch (error) {
         ShowToast("error", "Error al crear área", "Contáctese con Soporte Técnico.")
         console.error(error);
      } finally {
         dispatch(endLoading());
      }
   };

   const handleGetPersonal = async (idArea) => {
      try {
         const response = await http.get(`empresa/personal/area/get/${idArea}`)
         if (response?.data?.data) {
            
            //Filtra los datos del personal por área
            let listTarget = response?.data?.data.filter((item) => item.empresa_area_id === idArea)
            //Filtra los datos del personal sin área
            let listSource = response?.data?.data.filter((item) => item.empresa_area_id === null)
            //Actualiza el estado de los datos de las áreas
            setSource(listSource);
            //Actualiza el estado de los datos del personal por área
            setTarget(listTarget)
         } else if (response?.data?.error) {
            ShowToast("error", "Error al obtener datos del personal", response.data.error)
         }

      } catch (error) {
         ShowToast("error", "Error al obtener datos del personal", "Contáctese con Soporte Técnico.")
         console.error(error);
      };
   }

   const agregarPersonal = (data) => {
      dispatch(startLoading());
      http
         .post(`empresa/personal/asignarareas`, data)
         .then((response) => {
            if (response?.data?.resp) {
               ShowToast("success", "Personal agregado", "Personal agregado correctamente")
            } else if (response?.data?.error) {
               ShowToast("error", "Error al agregar personal", response.data.error)
            }
         })
         .catch((error) => {
            ShowToast("error", "Error al agregar personal", "Contáctese con Soporte Técnico.")
            console.error(error);
         })
         .finally(() => {
            dispatch(endLoading());
         })
   }

   //Integraciones put
   const EditarAreaEmpresa = async (idAreaEmpresa, areaEmpresa) => {
      let dataSend = {
         nombre: areaEmpresa.nombre,
         parent_id: areaEmpresa.parent_id,
         empresa_local_id: idLocal,
      }
      dispatch(startLoading());
      try {
         const response = await http.put(`empresa/area/update/${idAreaEmpresa}`, dataSend)
         if (response?.data?.resp) {
            await Promise.all([
               obtenerAreasgenerales()
            ])
            ShowToast("success", "Área actualizada", "Área actualizada correctamente")

         } else if (response?.data?.error) {
            ShowToast("error", "Error al editar área", response.data.error)
         }
      } catch (error) {
         ShowToast("error", "Error al editar área", "Contáctese con Soporte Técnico.")
         console.error(error);
      } finally {
         dispatch(endLoading());
      }
   }

   //Integraciones delete
   const handleDeleteEmpresa = async (idArea) => {
      dispatch(startLoading());
      try {
         const response = await http.delete(`empresa/area/delete/${idArea}`)
         if (response?.data?.resp) {
            await Promise.all([
               obtenerAreasgenerales()
            ])
         }
         ShowToast("success", "Eliminado correctamente", "Área eliminada correctamente")
      } catch (error) {
         console.error(error);
      } finally {
         dispatch(endLoading());
      }
   };

   //Función para obtener todos los datos al montar el componente
   const fetchAllData = async () => {
      try {
         await Promise.all([
            handlePersonalGeneral(),
            HandleGetGerente(),
            obtenerAreasgenerales(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   //Función para mostrar notificaciones
   const ShowToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail
      })
   }

   //Función para cambiar los datos de las áreas para mostrar en la tabla
   const changeDataToTreeTable = (arreglo) => {
      let data = arreglo.map((area) => {
         let aux = [];
         if (area.children && area.children.length > 0) {
            aux = changeDataToTreeTable(area.children);
         }
         return {
            key: area.id,
            data: {
               id: area.id,
               nombre: area.nombre,
            },
            children: aux,
         };
      });
      return data;
   };

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   return (
      // contenedor principal

      <div className="flex flex-column w-full h-full px-2">
         <div className="flex flex-column h-7rem w-full">
            <h1 className="flex w-full tit">Mis Áreas</h1>
            <div className="flex inline-flex justify-content-between">
               <p className="text-indigo-200 text-sm w-22rem con p-0" style={{ minWidth: "100%" }}>
                  A continuación se visualiza las áreas que usted ha definido en su empresa para el local.
               </p>
               <div>
               </div>
            </div>
         </div>
         <div className="flex flex-wrap w-full">
            <div className="flex  flex-column gap-2 w-3">
               <div className="flex justify-content-center" style={{ minHeight: "0" }}>
                  {/* Muestra datos del local */}
                  <CardAreas
                     locales={locales}>
                  </CardAreas>
               </div>

               <div className="" style={{ minHeight: "57vh" }}>
                  {/* Muestra datos del gerente */}
                  <CardListasGenerales
                     NombreGerente={NombreGerente}
                     setNombreGerente={setNombreGerente}
                     personalGeneral={personalGeneral}
                     handleCrearGerente={handleCrearGerente}
                     NombreGerenteBD={NombreGerenteBD}
                     HandleGetGerente={HandleGetGerente}
                  />
               </div>
            </div>

            <div className="flex w-9 pl-3 pr-2">
               {/* Muestra los datos de las áreas en una tabla */}
               <TablaAreas
                  areas={areas}
                  id={idLocal}
                  handleagregarAreasgenerales={handleagregarAreasgenerales}
                  act={obtenerAreasgenerales}
                  handleGetPersonal={handleGetPersonal}
                  source={source}
                  target={target}
                  setSource={setSource}
                  setTarget={setTarget}
                  agregarPersonal={agregarPersonal}
                  handleEditArea={EditarAreaEmpresa}
                  handleDelete={handleDeleteEmpresa}
               ></TablaAreas>
            </div>
         </div>
         <Toast ref={toast} />
      </div>
   );
};
export default PageMisAreasE;
import React, { useState } from "react";
import { InputText } from 'primereact/inputtext';

const Search = ({ extraFunction }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        extraFunction(event.target.value);
    };

    return (
        <span className="p-input-icon-left flex-1 border-none">
            <i className="pi pi-search" />
            <InputText
                value={searchQuery}
                onChange={handleSearchChange}
                className="p-inputtext-sm w-full border-none border-round-2xl"
                placeholder="Buscar empresa"
            />
        </span>
    );
};

export default Search;
import React, {useContext} from 'react';
import {Sidebar} from "primereact/sidebar";
import {RAContext} from "../../../Page/RutaAtencion/RAContext";
import {Button} from "primereact/button";
import {Avatar} from "primereact/avatar";
import imageAvatar from '../../../Images/usuario.png'
import RAResultadosTimeLine from "./RAResultadosTimeLine";

const RAResultadosOverlay = ({visible, setVisible }) => {

  const {atencionContext} = useContext(RAContext);

  return (
    <>
      <Sidebar
        visible={visible}
        onHide={() => setVisible(!visible)}
        position="right"
        showCloseIcon={false}
        style={{width:'25em'}}
      >
        {/*HEADER*/}
        <div className="rar_hr_header">
          <Button icon="fa-solid fa-briefcase-medical" className="p-button-rounded" />
          <h3>Hoja de Ruta</h3>
        </div>

        {/*USER*/}
        <div className="rar_hr_user">
          <Avatar image={imageAvatar} shape="circle" size="large" />
          <span>{atencionContext?.nombres_completos}</span>
        </div>

        {/*BODY*/}
        <div className="rar_hr_body">
          <RAResultadosTimeLine 
          />
        </div>
      </Sidebar>
    </>
  );
};

export default RAResultadosOverlay;
export const transformData = (arreglo, itemID) => {
   let data = arreglo.map((area) => {
      let aux = [];
      if (area.children && area.children.length > 0) {
         aux = transformData(area.children, itemID);
      }
      return {
         key: itemID + "-" + area.id,
         label: area.nombre,
         children: aux,
      };
   });
   return data;
}

export const changeDataToTreeTable = (arreglo) => {
   var data = [];

   data = arreglo?.map(item => {

      let auxAreas = transformData(item?.empresa_area, item.id)
      let auxData = {
         key: item.id,
         label: item.nombre,
         children: auxAreas
      }
      return auxData;
   })
   return data;
};
import React, { useState, useEffect } from 'react';
import { AutoComplete } from 'primereact/autocomplete';

const SelectFlechita = ({option, setvalue, setvalueId, title, titleStyle, titleClassname, contentClassName, className, style, id, setParameter,value = null, disabled, maxLength=1000}) => {
    // selectedValue contiene el valor seleccionado
    // suggestions contiene las opciones que se pasa por props
    // id es obligatirio si las opciones contienen id o value
    const [selectedValue, setSelectedValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            autoSuggestRef.current.show();
            if (suggestions.length <= 0) {
                setSuggestions(option);
            }
        }
        
    };

    const handleOptionSelect = (e) => {
        // Al seleccionar una opción, actualiza
        setParameter && setParameter(e)
        setvalue && setvalue(e.target.value.label || e.target.value || '')
      //   setvalueId && setvalueId(e.target.value.id || e.target.value.value || null)
        setSelectedValue(e.value);
        setSuggestions(option)
    };

    const autoSuggestRef = React.createRef();

    useEffect(() => {
        setSelectedValue(value === null ? null : value)
        setSuggestions(option);
    },[value])

    return (
        <div className={contentClassName}>
            <label style={titleStyle} className={titleClassname} >{title}</label>
            {
                id ? 
                /* cuando la opciones contiene id o value */
                <AutoComplete
                    inputClassName = 'w-full'
                    className={className}
                    style={style}
                    field="label"
                    value={selectedValue}
                    suggestions={suggestions}
                    completeMethod={(e) => setSuggestions(suggestions?.filter((option) => option.label.toLowerCase().includes(e.query.toLowerCase())) || suggestions)}
                    onChange={(e) => handleOptionSelect(e)}
                    onKeyDown={handleKeyDown}
                    ref={autoSuggestRef}
                    disabled={disabled}
                    dropdown
                    maxLength={maxLength}
                />
                :
                /* cuando la opciones es un arreglo */
                <AutoComplete
                    inputClassName = 'w-full'
                    className={className}
                    style={style}
                    value={selectedValue}
                    suggestions={suggestions}
                    completeMethod={(e) => setSuggestions(suggestions?.filter((option) => option.toLowerCase().includes(e.query.toLowerCase())) || suggestions)}
                    onChange={(e) => handleOptionSelect(e)}
                    onKeyDown={handleKeyDown}
                    ref={autoSuggestRef}
                    disabled={disabled}
                    dropdown
                    maxLength={maxLength}
                />
            }
            
        </div>
    )
}

export default SelectFlechita;

import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';

import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

import note from "../../../../Images/roles/note.svg";

const LocalesModalComponent = ({
   visibleLocales,
   setVisibleLocales,
   rol,
   GetLocales,
   AsignarLocales,
   LocalAsignadoBD
}) => {

   //Variables de estado
   const [SeleccionarLocal, setSeleccionarLocal] = useState(null);
   const [LocalAsignado, setLocalAsignado] = useState({ "local_id": 0 })
   const [NombreLocalSelect, setNombreLocalSelect] = useState("");

   const opciones =
      //Iteración de cada local
      GetLocales?.map((item) => (
         { name: item.nombre, code: item.id }
      ))

   const hideDialogLocales = () => {
      setVisibleLocales(false);
   };

   const ChangeLocal = (e) => {
      //Actualiza valor del local seleccionado
      setSeleccionarLocal(e.value)
      //Envía local como asignado
      setLocalAsignado({ local_id: e.value.code })
   }

   const GuardarCambios = () => {
      //Inserta id del rol y JSON del local
      AsignarLocales(rol.id, LocalAsignado);
      setVisibleLocales(false);
      //Limpia datos de la variable
      setSeleccionarLocal(null);
   }
   
   const AsignarLocal = () => {
      // Recorrer las opciones disponibles y comparar el código del local con el local asignado en la base de datos
      if (opciones) {
         const localAsignado = opciones.find(item => item.code === LocalAsignadoBD);
         if (localAsignado) {
            // Si encuentra una coincidencia, actualiza el estado del local seleccionado y el nombre del local
            setSeleccionarLocal(localAsignado);
            setNombreLocalSelect(localAsignado.name);
         }
      }
   }

   const header = (<Header icono={note} titulo={"Administrar Locales"} subtitulo={`En esta sección usted puede administrar y gestionar los datos de los locales`} />)

   const footer = (<Footer onClickEnviar={GuardarCambios} onClickCancelar={() => { setVisibleLocales(false); setSeleccionarLocal(null); }} label={"Guardar"} />)

   useEffect(() => {
      //Carga el nombre del local almacenado
      AsignarLocal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visibleLocales])

   return (
      <div className="tabla-rol">
         <Dialog
            visible={visibleLocales}
            style={{ width: "500px", height: "auto", borderRadius: "20px" }}
            header={header}
            footer={footer}
            modal
            className="p-fluid tabla-rol"
            onHide={() => { hideDialogLocales() }}
         >
            <div className="field">
               <h3>{rol.nombre}</h3>
               <br></br>
               <p
                  style={{
                     color: "#5555D8",
                     fontSize: "20px",
                     fontWeight: "bold",
                     fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                  }}
               >
                  Seleccionar Locales
               </p>
            </div>
            <div>
               <Dropdown
                  value={SeleccionarLocal}
                  onChange={(e) => ChangeLocal(e)}
                  options={opciones}
                  optionLabel="name"
                  placeholder="Seleccionar Local"
               />
            </div>
            <div className="flex flex-wrap pt-1">
               <p className="flex flex-wrap pt-1 align-items-end">Local Asignado actualmente:
                  <span className="pl-2 font-semibold">{NombreLocalSelect === "" ? " No hay local asignado" : NombreLocalSelect}</span>
               </p>
            </div>
         </Dialog>
      </div>
   );
};

export default LocalesModalComponent;
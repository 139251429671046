import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';

import Logo from "./img/Proforma-icon.svg"

export default function DialogProforma() {

    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Yes" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    const handleFullscreen = () => {
        const editor = document.getElementById('editor');
        editor.requestFullscreen();
    };

    const handleZoomIn = () => {
        const editorContent = document.getElementById('editor-content');
        editorContent.style.transform = 'scale(1.2)';
    };

    const handleZoomOut = () => {
        const editorContent = document.getElementById('editor-content');
        editorContent.style.transform = 'scale(0.8)';
    };

    return (
        <div>
            <Button onClick={() => setVisible(true)} style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
            <i className="pi pi-file-pdf proforma__container_body_table_txt_pdf-corporativo"></i>
            </Button>
            <Dialog visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className="flex" style={{ width: '60px', height: '60px', background: '#EBEBFF', borderRadius: '10px', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={Logo} alt="" style={{ width: '35px', height: '35px' }} />
                </div>
                <div className="flex" style={{ justifyContent: 'space-between' }}>
                    <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                        <span style={{ fontWeight: '700', fontSize: '18px', color: '#5555D8' }}>Proforma #160922A0025 </span>
                    </div>
                    <div>

                    </div>
                </div>
                <div id="editor">
                    <Panel header="Opciones">
                        {/* Opciones del editor aquí */}
                    </Panel>
                    <div className="editor-buttons">
                        <Button label="Zoom in" onClick={handleZoomIn} />
                        <Button label="Zoom out" onClick={handleZoomOut} />
                    </div>
                    <div id="editor-content">
                        {/* Contenido del editor aquí */}
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

import React from "react";
import { Skeleton } from "primereact/skeleton";

const SS2Contact = () => {
  return (
    <div className="flex flex-column gap-3 ml-6 mr-6">
      <div className="flex flex-column bg-white p-3 border-round-lg">
        <div>
          <Skeleton
            width="40px"
            height="40px"
            className="p-1 border-round-lg"
          />
        </div>
        <div className="flex justify-content-between align-items-center gap-2 flex-wrap">
          <div>
            <Skeleton
              width="456.47px"
              height="19px"
              className="font-bold text-base text-indigo-500"
            />
            <Skeleton
              width="456.47px"
              height="18px"
              className="text-gray-500"
            />
          </div>
          <div className="flex-1 flex justify-content-end">
            <span
              className="xl:w-auto lg:w-auto md:w-full sm:w-full w-full p-input-icon-left"
              style={{ minWidth: '168px' }}
            >
              <Skeleton
                width="168px"
                height="47px"
                className="xl:w-auto lg:w-auto md:w-full sm:w-full w-full"
              />
            </span>
          </div>
        </div>
      </div>

      <div
        className="Ventas ventas_grid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(319px, 1fr))',
          gridAutoRows: '242px',
          gridGap: '10px',
        }}
      >
        <div className="flex flex-1 flex-column justify-content-between bg-white border-round-lg">
          <Skeleton height="242px" />
        </div>
        <div className="flex flex-1 flex-column justify-content-between bg-white border-round-lg">
          <Skeleton height="242px" />
        </div>
        <div className="flex flex-1 flex-column justify-content-between bg-white border-round-lg">
          <Skeleton height="242px" />
        </div>
        <div className="flex flex-1 flex-column justify-content-between bg-white border-round-lg">
          <Skeleton height="242px" />
        </div>
        <div className="flex flex-1 flex-column justify-content-between bg-white border-round-lg">
          <Skeleton height="242px" />
        </div>
      </div>
    </div>
    
  );
}
export default SS2Contact;
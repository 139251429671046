import React, { useState, useRef, useEffect } from "react";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";

import AuthUser from "../../../../AuthUser";
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

import "../style/RetraccionesVer.css"
import '../../../../Style/stylePerfil.css';

export default function CardCuentaComponent({ entidadPago, getbregma, entidadesBancarias, showToast }) {
  const dispatch = useDispatch()
  const { http } = AuthUser();
  const [visible, setVisible] = useState(false);
  const [entidadPagoUpdate, setEntidadPagoUpdate] = useState({
    id: 0,
    numero_cuenta: "",
    cci: "",
    entidad_bancaria_id: null,
    entidad_bancaria: null,
  })


  const handleChangeEntidadBancaria = (e) => {
    setEntidadPagoUpdate({ ...entidadPagoUpdate, entidad_bancaria: e.target.value, entidad_bancaria_id: e.value === null ? null : e.value.id })
  }

  useEffect(() => {
    setEntidadPagoUpdate(entidadPago)

  }, [entidadPago])

  const cciRef = useRef('');
  const numeroCuentaRef = useRef('');

  const saveCuentaBancaria = async () => {
    const cuentaBancaria = {
      entidad_bancaria_id: entidadPagoUpdate.entidad_bancaria_id,
      entidad_bancaria: entidadPagoUpdate.entidad_bancaria,
      numero_cuenta: numeroCuentaRef.current.value,
      cci: cciRef.current.value
    }
    dispatch(startLoading());
    try {
      const response = await http.put(`entidad_pago/update/${entidadPago.id}`, cuentaBancaria)
      if (response?.data?.resp) {
        await Promise.all([
          setVisible(false),
          getbregma()
        ])
        showToast(
          'success',
          'Datos actualizados',
          'Cuenta bancaria guardada exitosamente'
        )
      } else if (response?.data?.error) {
        showToast('error', 'Error', response.data.error)
      }
    } catch (error) {
      showToast('error', 'Error', 'Contacte a soporte técnico.')
      console.error(error);
    } finally {
      dispatch(endLoading())
    }
  }
  const toast = useRef(null);

  //delete

  const [btndelete, setBtndelete] = useState(false);

  const handleEliminarEntidad = async () => {
    dispatch(startLoading());
    try {
      const response = await http.delete(`entidad_pago/delete/${entidadPago.id}`)
      if (response?.data?.resp) {
        await Promise.all([
          getbregma(),
          setBtndelete(false)
        ])
        showToast(
          "success",
          "Eliminado correctamente",
          "Cuenta eliminada exitosamente"
        );
      } else if (response?.data?.error) {
        showToast('error', 'Error', response.data.error)
      }
    } catch (error) {
      showToast('error', 'Error', 'Contacte a soporte técnico.')
      console.error("error");
    } finally {
      dispatch(endLoading());
    }
  };


  // Skeleton
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2200);
  }, [])
  const loader = () => {
    return (
      <div>
        <div>
          <Skeleton width="281px" height="226px" borderRadius="16px"></Skeleton>
        </div>
      </div>
    );
  }


  const [NumCuentaEntiFalta, setNumCuentaEntiFalta] = useState(false);
  const [NumCCIEntiFalta, setNumCCIEntiFalta] = useState(false);


  const VerificarFaltas = () => {
    var verifi = true;

    var InputCuenta = document.getElementById('nameNumCuentaEnPP').value
    if (InputCuenta === "") {
      verifi = false;
      setNumCuentaEntiFalta(true);
    }
    var InputCCI = document.getElementById('nameNumCCIEnPP').value
    if (InputCCI === "") {
      verifi = false;
      setNumCCIEntiFalta(true);
    }
    return verifi;
  }
  const EnvioUpdateCuenta = () => {
    var verri = VerificarFaltas();

    if (verri) {
      saveCuentaBancaria();
    }

  }

  useEffect(() => {
    setNumCuentaEntiFalta(false);
    setNumCCIEntiFalta(false);
  }, [visible])

  if (loading) {
    return (loader())
  }

  else {
    return (
      <div className="wrapperdbb p-4 px-3 m-3">
        <div className="carousel-card-cuentas w-full">
          <div className="flex flex-wrap justify-content-between align-items-center gap-2 p-1">

            <div className="flex flex-1">
              <img
                src={entidadPago?.entidad_bancaria?.logo}
                alt="Entidad bancaria"
                style={{ width: '100%', height: '74px', objectFit: 'contain' }} />
            </div>
            <div className="flex flex-row gap-2">
              <Button
                icon='pi pi-trash'
                onClick={() => setBtndelete(true)}
                severity="danger"
                outlined="true"
                className="p-button-danger p-button-text "
                style={{ width: '33px', height: '33px', backgroundColor: '#FFECEC' }}
              />
              <Button
                icon="pi pi-pencil"
                onClick={() => setVisible(true)}
                className="p-button-success p-button-text "
                style={{ width: '33px', height: '33px', backgroundColor: '#BFF1DF' }}
              />
            </div>
            {/* <img src={entidadPago?.entidad_bancaria?.logo} alt="Entidad bancaria" width="150px" style={{ padding: '8px', height: '80px', marginLeft: '-20px', marginTop: '-10px', marginBottom: '-10px', position: "relative" }} />
            <div className="flex flex-row gap-2">
              <Button icon='pi pi-trash' onClick={() => setBtndelete(true)} severity="danger" outlined="true" className="p-button-danger p-button-text " style={{ width: '30px', height: '30px', marginTop: '10px', marginRight: '-5px', backgroundColor: '#FFECEC' }} />
              <Button icon="pi pi-pencil" onClick={() => setVisible(true)} className="p-button-success p-button-text " style={{ width: '30px', height: '30px', marginTop: '10px', marginRight: '-5px', backgroundColor: '#BFF1DF' }} />
            </div> */}
          </div>

          <div>
            <div className="linea">
              <h4 className="tituloCuentas text-base">
                Número de cuenta<br />
              </h4>
              <h6 className="numero text-sm">
                {entidadPago?.numero_cuenta}
              </h6>
            </div>

            <h4 className="tituloCuentas text-base">
              CCI<br />
            </h4>
            <h6 className="numero text-sm">
              {entidadPago?.cci}
            </h6>
          </div>
        </div>

        <div className="card flex justify-content-center">
          <Dialog resizable={false} visible={btndelete} onHide={() => setBtndelete(false)} className="card-buton"
            header={<Header piIcon={'pi pi-trash'} titulo={"Eliminar tarjeta"} />}
            footer={<Footer type={"button"} onClickCancelar={() => setBtndelete(false)} onClickEnviar={() => handleEliminarEntidad()} label={"Eliminar"} />}
            style={{ width: '30vw', minWidth: '500px', maxWidth: "446px" }} >
            <div className="h-full card mb-0">
              <div className="flex justify-content-between h-full flex-column gap-3">
                <div className="flex ">
                  <label htmlFor="num" className="flex justify-content-center" >

                  </label>
                </div>
              </div>
            </div>
          </Dialog>
          <Toast ref={toast} />
        </div>
        <div className="card flex justify-content-center">
          <Dialog
            onHide={() => setVisible(false)}
            visible={visible}
            className="card-buton"
            header={<Header piIcon={'pi pi-pencil'} titulo={"Editar datos de la tarjeta"} />}
            footer={<Footer type={"button"} onClickCancelar={() => setVisible(false)} onClickEnviar={() => EnvioUpdateCuenta()} label={"Guardar"} />}
            style={{ width: '25vw', minWidth: '500px', maxWidth: "446" }}
          >
            <div className="card">
              <div className="flex flex-column gap-3 mb-4">
                <div className="flex-auto">
                  <label htmlFor="num" className="font-bold block mb-2">
                    Entidad Bancaria
                  </label>
                  <Dropdown
                    value={entidadPagoUpdate?.entidad_bancaria}
                    onChange={(e) => handleChangeEntidadBancaria(e)}
                    options={entidadesBancarias}
                    optionLabel="nombre"
                    placeholder="Seleccionar tipo de Tarjeta"
                    className="w-full"
                  />
                </div>
                <div className="flex flex-column relative gap-2">
                  <label htmlFor="integer" className="font-bold block">
                    Numero de Cuenta
                  </label>

                  <InputText id="nameNumCuentaEnPP" ref={numeroCuentaRef}
                    style={{ width: "100%", height: "50px", border: "1px solid #ccc", padding: "5px" }}
                    defaultValue={entidadPago.numero_cuenta}
                    keyfilter="num"
                    onChange={() => { setNumCuentaEntiFalta(false) }}
                    className={`w-full text-area InputRetraccionNumCuAP ${NumCuentaEntiFalta ? 'Select' : ''}`}
                  />

                  <div className={`InputRetraccionNumCuAPDiv ${NumCuentaEntiFalta ? 'Select' : ''}`}></div>
                  <p className={`InputRetraccionNumCuAPTextError ${NumCuentaEntiFalta ? 'Select' : ''}`}>
                    Necesita ingresar un número de cuenta
                  </p>

                </div>


                <div className="flex flex-column relative gap-2">
                  <label htmlFor="email" className="font-bold block">
                    CCI
                  </label>
                  <InputText id="nameNumCCIEnPP" ref={cciRef}
                    style={{ width: "100%", height: "50px", border: "1px solid #ccc", padding: "5px" }}
                    defaultValue={entidadPago.cci}
                    keyfilter="num"
                    onChange={() => { setNumCCIEntiFalta(false) }}
                    className={`w-full text-area InputRetraccionNumCuAP ${NumCCIEntiFalta ? 'Select' : ''}`} />

                  <div className={`InputRetraccionNumCuAPDiv ${NumCCIEntiFalta ? 'Select' : ''}`}></div>
                  <p className={`InputRetraccionNumCuAPTextError ${NumCCIEntiFalta ? 'Select' : ''}`}>
                    Necesita ingresar un número CCI
                  </p>
                </div>
              </div>
            </div>
          </Dialog>
        </div>
        <Toast ref={toast} />
      </div >
    )
  }
}

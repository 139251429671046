import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import "../../../Style/Radiologia/Radiologia.css";
import VoiceRadiologia from "../../ButtonVoice/VoiceRadiologia";
import { RadContext } from "../../../Page/Radiologia/RadProvider";

const OpcRayosXTREstandar = ({ titulo, idRayo }) => {

   const [selectedOption, setSelectedOption] = useState();
   const [value, setValue] = useState("");

   let { RayXToraxEstandar, setRayXToraxEstandar } = useContext(RadContext);

   const Opciones = [
      { name: "Normal", code: "1" },
      { name: "Patológico", code: "2" },
      { name: "No concluyente", code: "3" },
   ];

   useEffect(() => {
      const selectedDetalle = RayXToraxEstandar.rad_detalles[idRayo - 1];

      if (selectedDetalle) {
         setSelectedOption(Opciones.find((opt) => opt.code === selectedDetalle.opcion));
         setValue(selectedDetalle.descripcion);

      }

   }, [idRayo, RayXToraxEstandar]);


   const onChange = (e) => {
      setSelectedOption(e.value);
      const newRayTorax = { ...RayXToraxEstandar };
      newRayTorax.rad_detalles[idRayo - 1] = {
         rad_partes_id: idRayo,
         descripcion: value,
         opcion: e.value.code
      };
      setRayXToraxEstandar(newRayTorax);
   };

   const onChangeValue = (e) => {
      setValue(e);
      const newRayTorax = { ...RayXToraxEstandar };
      const selectedOptionCode = selectedOption ? selectedOption.code : null;
      newRayTorax.rad_detalles[idRayo - 1] = {
         descripcion: e,
         opcion: selectedOptionCode,
      };
      setRayXToraxEstandar(newRayTorax);
   };

   const LmpiarRegistro = (nulo) => {
      if (RayXToraxEstandar.rad_detalles.length > 0) {
         const nuevoRayTorax = { ...RayXToraxEstandar };
         nuevoRayTorax.rad_detalles[idRayo - 1].opcion = nulo;
         setRayXToraxEstandar(nuevoRayTorax);
      }
   };

   const subTitulo = {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: "600",
   };
   return (
      <div className="w-full flex flex-1 flex-wrap flex-column gap-2" >
         <div className="w-full h-auto" style={subTitulo}>{titulo}</div>
         <div className="w-full flex gap-2 CuadroDropInputOpcRayosTorax">
            <div className="w-5 flex h-auto relative CuadroDropInputOpcRayosToraxDrop">
               <Dropdown
                  value={selectedOption}
                  onChange={(e) => onChange(e)}
                  options={Opciones}
                  optionLabel="name"
                  placeholder="Seleccionar"
                  className="desplegable-RadiografiaUwU w-full align-items-center"
                  style={{ height: "48px" }}
               />
               {selectedOption != null ? (
                  <button
                     className="absolute pi pi-times"
                     style={{right:'39px', top:'16px', background:'white', border:'0px solid transparent', color:'#6c757d'}}
                     onClick={() => {
                        setSelectedOption({});
                        LmpiarRegistro(null);
                     }}
                  ></button>
               ) : null}
            </div>


            <div className="w-7 CuadroDropInputOpcRayosToraxInput">
               <VoiceRadiologia
                  value={value}
                  onChange={(e) => onChangeValue(e)}
                  className="flex-1"
               />
            </div>

         </div>


      </div>
   );
};

export default OpcRayosXTREstandar;

import React, { useState, useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown';

export default function CreateEmpresa({
    data,
    setBregma,
    departamentos,
    departamento,
    setDepartamento,
    provincia,
    setProvincia,
    distrito,
    setDistrito,
    isFormFieldValid,
    getFormErrorMessage,
    classNames
}) {
    //const [ubicaciones, setUbicaciones] = useState([]);
    const [provincias, setProvincias] = useState(null);
    const [distritos, setDistritos] = useState(null);

    const handleChangeDepartamento = (e) => {
        setDepartamento(e.target.value);
        setProvincias(e.target.value === null ? null : e.target.value.provincias);
        setProvincia(null);
        setDistritos(null);
        setDistrito(null);
    };

    const handleChangeProvincia = (e) => {
        setProvincia(e.target.value);
        setDistritos(e.target.value === null ? null : e.target.value.distritos);
        setDistrito(null)
    };

    const handleChangeDistrito = (e) => {
        setDistrito(e.target.value);
        setBregma({ ...data, distrito: e.target.value, distrito_id: e.value === null ? null : e.value.id })
    };

    useEffect(() => {
        if (data?.distrito != null && departamentos !== undefined) {
            let _departamento = departamentos?.filter(
                (departamento) => departamento?.departamento === data?.distrito.provincia.departamento.departamento
            );
            setProvincias(_departamento[0]?.provincias);
            setDepartamento(_departamento[0]);
            let _provincia = _departamento[0]?.provincias.filter(
                (provincia) =>
                    provincia.provincia === data?.distrito.provincia.provincia
            );
            setProvincia(_provincia[0]);
            setDistritos(_provincia[0]?.distritos);
            let _distrito = _provincia[0]?.distritos.filter(
                (distrito) => distrito.distrito === data?.distrito.distrito
            );
            setDistrito(_distrito[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [departamentos]);

    return (
        <div style={{ width: '100%' }}>
            <div className="flex flex-column gap-3 mb-2 mt-1">
                <label htmlFor="departamento" className='font-bold block'>(*) Departamento</label>
                <Dropdown
                    id="departamento"
                    options={departamentos}
                    value={departamento}
                    onChange={handleChangeDepartamento}
                    optionLabel="departamento"
                    placeholder="Seleccione un Departamento"
                    className={classNames({ 'p-invalid': isFormFieldValid('departamento') })}
                    filter
                />
                {getFormErrorMessage('departamento')}
            </div>
            <div className="flex flex-column gap-3 mb-2">
                <label htmlFor="provincia" className='font-bold block'>(*) Provincia</label>
                <Dropdown
                    id="provincia"
                    value={provincia}
                    options={provincias}
                    onChange={handleChangeProvincia}
                    optionLabel="provincia"
                    placeholder="Seleccione una Provincia"
                    className={classNames({ 'p-invalid': isFormFieldValid('provincia') })}
                    filter
                />
                {getFormErrorMessage('provincia')}
            </div>
            <div className="flex flex-column gap-3 mb-2">
                <label htmlFor="distrito" className='font-bold block'>(*) Distrito</label>
                <Dropdown
                    id="distrito"
                    name='distrito'
                    value={distrito}
                    options={distritos}
                    onChange={handleChangeDistrito}
                    optionLabel="distrito"
                    placeholder="Seleccione un distrito"
                    className={classNames({ 'p-invalid': isFormFieldValid('distrito') })}
                    filter
                />
                {getFormErrorMessage('distrito')}
            </div>
        </div>
    );
}
import React, { useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { TreeTable } from 'primereact/treetable';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import '../../../../Style/Operaciones/servicios.css'
import AuthUser from '../../../../AuthUser';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { endLoading, startLoading } from '../../../../Redux/isLoadingSlice';
import { useDispatch } from 'react-redux';

const TableOperacionesComponent = ({ getServicios, transformedNodes, selectedNodeKeys, seleccionarServicios }) => {
   const dispatch = useDispatch();
   const toast = useRef(null);
   const { http } = AuthUser()
   const [visibleMes, setVisibleMes] = useState(false);
   const [visibleAno, setVisibleAno] = useState(false)
   const [rowData, setRowData] = useState({})
   const precio = useRef('')
   const precioAnual = useRef('')
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const footerContent = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisibleMes(false)} className="p-button-text" />
         <Button label="Aceptar" icon="pi pi-check" onClick={() => handleCreate()} autoFocus />
      </div>
   );

   const footerContentAno = (
      <div>
         <Button label="Cancelar" icon="pi pi-times" onClick={() => setVisibleAno(false)} className="p-button-text" />
         <Button label="Aceptar" icon="pi pi-check" onClick={() => handleCreateAno()} autoFocus />
      </div>
   );

   const handleCreate = async () => {
      let data = {
         tipo: rowData.key.substring(0, 1),
         precio_referencial_mensual: precio.current.value,
      };
      let id = rowData.key.substring(rowData.key.includes('.') ? (rowData.key.lastIndexOf(".") + 1) : (rowData.key.lastIndexOf("-") + 1), rowData.key.length);
      dispatch(startLoading())
      try {
         const response = await http.put(`bregma/operaciones/servicio/preciomensual/update/${id}`, data)
         if (response?.data?.resp) {
            await Promise.all([
               getServicios(),
               setVisibleMes(false)
            ])
            showToast(
               "success",
               "Precio Actualizado",
               `Se actualizó el precio mensual a S/.${precio.current.value} correctamente`
            );
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast(
            "error",
            "Error al Actualizar",
            `Contacte a soporte técnico`
         );
      } finally {
         dispatch(endLoading())
      }
   }

   const handleCreateAno = async () => {
      let data = {
         tipo: rowData.key.substring(0, 1),
         precio_referencial_anual: precioAnual.current.value,
      };
      let id = rowData.key.substring(rowData.key.includes('.') ? (rowData.key.lastIndexOf(".") + 1) : (rowData.key.lastIndexOf("-") + 1), rowData.key.length);
      dispatch(startLoading())
      try {
         const response = await http.put(`bregma/operaciones/servicio/precioanual/update/${id}`, data)
         if (response?.data?.resp) {
            await Promise.all([
               getServicios(),
               setVisibleAno(false)
            ])
            showToast(
               "success",
               "Precio Actualizado",
               `Se actualizó el precio anual a S/.${precioAnual.current.value} correctamente`
            );
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast(
            "error",
            "Error al Actualizar",
            `No se pudo actualizar el precio anual`
         );
      }finally{
         dispatch(endLoading())
      }
   }


   const buttonActionMensual = (rowData) => {
      setVisibleMes(true)
      setRowData(rowData);
   }

   const buttonActionAnual = (rowData) => {
      setVisibleAno(true)
      setRowData(rowData);
   }

   const buttonEditMensual = (rowData) => {
      if (rowData.children && rowData.children.length > 0) return null;
      return (
         <div >
            <Tag style={{ cursor: 'pointer' }} onClick={() => buttonActionMensual(rowData)}>
               <div>S/.{rowData.data.precio_referencial_mensual} </div>
            </Tag>
         </div>
      )
   }
   const buttonEditAnual = (rowData) => {
      if (rowData.children && rowData.children.length > 0) return null;
      return (
         <div >
            <Tag key={rowData?.key} style={{ cursor: 'pointer' }} onClick={() => buttonActionAnual(rowData)}>
               <div>S/.{rowData.data.precio_referencial_anual} </div>
            </Tag>
         </div>
      )
   }
   const [globalFilter, setGlobalFilter] = useState('');
   const [filterMode, setFilterMode] = useState('lenient');
   const [filterOptions] = useState([
      { label: 'Lenient', value: 'lenient' },
      { label: 'Strict', value: 'strict' }
   ]);
   const getHeader = () => {
      return (
         <div className="flex justify-content-end">
            <div className="p-input-icon-left">
               <i className="pi pi-search" style={{}}></i>
               <InputText style={{ textAlign: 'left', marginLeft: "50px" }}
                  type="search"
                  onInput={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Buscar área médica" />
            </div>
         </div>
      );
   };

   let header = getHeader();

   return (
      <><Toast ref={toast} />
         <div style={{ width: '100%', overflowY: 'auto' }}>
            <TreeTable
               globalFilter={globalFilter}
               header={header}
               filterMode={filterMode}
               tableStyle={{ width: '100%' }}
               value={transformedNodes}
               className="TablaDeOperacionesServicios ventas contactList"
               emptyMessage={<p className="text-center text-gray-500">
                  Seleccione un paquete para ver su detalle.
               </p>}
               style={{
                  width: '100%', // Asegura que la tabla ocupe todo el ancho del contenedor
               }}
            >
               <Column
                  field="nombre"
                  expander
                  style={{
                     color: '#393838',
                     fontFamily: 'Montserrat',
                     fontWeight: '600',
                     padding: '40px',
                     fontSize: '16px',
                     letterSpacing: '1px',
                     height: '100%',
                  }} />

               <Column
                  header="Precio Referencial Mensual"
                  body={(e) => buttonEditMensual(e)}
                  className="text-center text-green-500"
                  headerStyle={{
                     padding: '5px',
                     whiteSpace: 'normal',
                     fontSize: '15px'
                  }}
               ></Column>
               <Column
                  header="Precio Referencial Anual"
                  body={(e) => buttonEditAnual(e)}

                  className="text-center text-green-500"
                  headerStyle={{
                     padding: '5px',
                     whiteSpace: 'normal',
                     fontSize: '15px',
                  }}

               ></Column>

            </TreeTable>

            <Dialog
               key={1}
               header={<>
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#5555D8"
                     }}
                  >Nuevo precio mensual para {rowData?.data?.nombre}</p>
               </>}
               visible={visibleMes}
               style={{ width: '25vw', minWidth: "238px" }}
               onHide={() => setVisibleMes(false)}
               footer={footerContent}
            >


               <div className='flex dialogo-center mt-3 flex-row gap-3 align-items-center'>
                  S/.<InputText defaultValue={rowData?.data?.precio_referencial_mensual} className='flex w-full' key={rowData?.key} ref={precio} ></InputText>
               </div>
            </Dialog>
            <Dialog
               key={2}
               header={<>
                  <p
                     style={{
                        fontWeight: "600",
                        fontSize: "18px",
                        color: "#5555D8"
                     }}
                  >Nuevo precio anual para {rowData?.data?.nombre}</p>
               </>}
               visible={visibleAno}
               style={{ width: '25vw', minWidth: "238px" }}
               onHide={() => setVisibleAno(false)}
               footer={footerContentAno}
            >
               <div className='flex dialogo-center mt-3 flex-row gap-3 align-items-center'>
                  S/.<InputText className='flex w-full' key={rowData?.key} ref={precioAnual} defaultValue={rowData?.data?.precio_referencial_anual}></InputText>
               </div>
            </Dialog>
            {/* <TableInPopUp data={data} /> */}
         </div></>
   );
}

export default TableOperacionesComponent;

import React from 'react';
import { Chart } from "primereact/chart";
import AudAudiometriaGridTable2 from "./AudAudiometriaGridTable2";

const AudAudiometriaChart = React.memo(({ aereaDer, aereaIzq, oseaDer, oseaIzq }) => {
  const chartOptionsIzq = {
    maintainAspectRatio: false,
    aspectRatio: .6,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Oído Izquierdo',
        font: {
          size: 22
        },
        color: '#005d96'
      },
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hz',
          font: {
            size: 17
          }
        },
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        title: {
          display: true,
          text: 'db',
          font: {
            size: 17
          }
        },
        ticks: {
          color: '#495057',
          stepSize: 10
        },
        grid: {
          color: '#ebedef'
        },
        suggestedMin: -10,
        suggestedMax: 120,
        reverse: true,
      }
    }
  };
  const chartOptionsDer = {
    maintainAspectRatio: false,
    aspectRatio: .6,
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Oído Derecho',
        font: {
          size: 22
        },
        color: '#cb1a1a'
      },
      legend: {
        labels: {
          color: '#495057'
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Hz',
          font: {
            size: 17
          }
        },
        ticks: {
          color: '#495057'
        },
        grid: {
          color: '#ebedef'
        }
      },
      y: {
        title: {
          display: true,
          text: 'db',
          font: {
            size: 17
          }
        },
        ticks: {
          color: '#495057',
          stepSize: 10
        },
        grid: {
          color: '#ebedef'
        },
        suggestedMin: -10,
        suggestedMax: 120,
        reverse: true,
      }
    }
  };

  const resultdataIzq = {
    labels: [250, 500, 1000, 2000, 3000, 4000, 6000, 8000],
    datasets: [
      {
        label: 'Vía Aérea',
        data: aereaIzq,
        fill: false,
        borderColor: '#005d96',
        tension: .4,
        pointStyle:'cross',
        pointRadius: 10,
        pointHoverRadius: 15,
        pointRotation:45,
      },
      {
        label: 'Vía Ósea',
        data: oseaIzq,
        fill: false,
        borderColor: '#21252b',
        borderDash: [5, 5],
        tension: .4,
        pointStyle:'rect',
        pointRadius: 10,
        pointHoverRadius: 15,
        // pointRotation:45,
      }
    ]
  };
  const resultdataDer = {
    labels: [250, 500, 1000, 2000, 3000, 4000, 6000, 8000],
    datasets: [
      {
        label: 'Vía Aérea',
        data: aereaDer,
        fill: false,
        borderColor: '#cb1a1a',
        tension: .0,
        backgroundColor: 'rgba(203,26,26,0.1)',
        pointStyle: 'circle',
        pointRadius: 10,
        pointHoverRadius: 15,
      },
      {
        label: 'Vía Ósea',
        data: oseaDer,
        fill: false,
        borderColor: '#21252b',
        borderDash: [5, 5],
        tension: .0,
        pointStyle: 'triangle',
        pointRadius: 10,
        pointHoverRadius: 15,
        
      }
    ]
  };

  return (
    <div className="aud_aud_chart flex justify-content-evenly">

      <div className='w-5'>
        <Chart type="line" data={resultdataIzq} options={chartOptionsIzq} />
        {/*Tabla*/}
        <AudAudiometriaGridTable2 title="Vía Aérea" color="color2" state={aereaIzq} />
        <AudAudiometriaGridTable2 title="Vía Ósea" color="color3" state={oseaIzq} />
      </div>

      <div className='w-5'>
        <Chart type="line" data={resultdataDer} options={chartOptionsDer} />
        <AudAudiometriaGridTable2 title="Vía Aérea" color="color1" state={aereaDer} />
        <AudAudiometriaGridTable2 title="Vía Ósea" color="color3" state={oseaDer} />
        {/*Tabla*/}
      </div>

    </div>
  );
});

export default AudAudiometriaChart;
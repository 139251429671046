import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";

import { Footer, Header } from "../../Pure/SeccionesNavegacion";
import Logo from "../../../Images/GroupSVG.svg";

export default function AsignarServicioGrupal({
   visibleAsignar,
   setVisibleAsignar,
   item,
   asignarPaquetes,
}) {

   const [clinica_id, setClinica_id] = useState()
   const [perfiles, setPerfiles] = useState("")

   //Opciones para el tipo de evaluación
   const tipoEvaluacion = [
      {
         id: 2,
         nombre: "Periódico"
      },
      {
         id: 3,
         nombre: "Retiro"
      }
   ]

   //Mapeo del perfil de cada empleado
   const empleadosMap = () => {
      let aux_nombre = ""
      item?.map((empleado) => {
         aux_nombre = aux_nombre + empleado?.perfil?.perfil?.nombre + ", "
         return true
      })
      setPerfiles(aux_nombre)
   }

   //Valores iniciales del formulario
   const initialValues = {
      clinica_local_id: 0,
      fecha: "",
      perfil_tipo_id: {},
      perfil_id: 0,
      tipo_perfil: 0,
      empresa_personales: [
         {
            empresa_personal_id: 0,
         },
      ],
      clinica_id: 0,
   };

   //Configuración del formulario con formik
   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         let data_send = {
            clinica_local_id: data?.clinica_local_id?.id,
            fecha: data.fecha,
            tipo_evaluacion_id: data?.perfil_tipo_id?.id, //tipo_perfil: Rutina2, Salida3
            empresa_personales: data?.empresa_personales //datos de personal
         }
         if (data.fecha !== "") {
            const fecha = new Date(data.fecha);
            const formattedFecha = fecha.toISOString().split("T")[0];//Formateo de fecha
            data_send.fecha = formattedFecha;//Inclusión a array
         }
         asignarPaquetes(data_send);//Envío de datos a la api
         setVisibleAsignar(false);//Cierra el modal
      },
   });

   const ids = item?.map(empleado => empleado); //Mapeo de los id de los empleados

   //Anidación de los id de cada empleado en una nueva variable dentro de los elementos iniciales del array formik
   const idPorEvaluacion = () => {
      if (formik.values.perfil_tipo_id?.id === 2) {
         formik.setValues({
            ...formik.values,
            empresa_personales: ids?.map(id => ({ empresa_personal_id: id.id, tipo_perfil_id: id.perfil?.perfil_tipo_items[0]?.id }))
         })
      } else if (formik.values.perfil_tipo_id?.id === 3) {
         formik.setValues({
            ...formik.values,
            empresa_personales: ids?.map(id => ({ empresa_personal_id: id.id, tipo_perfil_id: id.perfil?.perfil_tipo_items[1]?.id }))
         })
      }
   }

   //Actualiza valores del formulario
   const updateValues = () => {
      formik.setValues({
         clinica_local_id: 0,
         fecha: "",
         perfil_id: 0,
         perfil_tipo_id: 0,
         empresa_personales: ids?.map(id => ({ empresa_personal_id: id.id, tipo_perfil_id: 0 })),
         clinica_id: 0,
      });
   };

   //Se ejecuta cuando el tipo de perfil cambia
   useEffect(() => {
      idPorEvaluacion();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [formik.values.perfil_tipo_id])

   //Se ejecuta cuando el valor del item cambia
   useEffect(() => {
      updateValues();
      let Locales = item?.[0]?.locales?.map(id => {
         return id;
      });
      setClinica_id(Locales); // Establece los IDs de las clínicas

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [item]);

   //Mapea el perfil de cada empleado
   useEffect(() => {
      empleadosMap();
   })

   //Cabecera del dialog
   const header = (<Header icono={Logo} titulo={'Asignar servicio'} subtitulo={'Elige el tipo de examen corresponiente'} />)

   //Pie de dialog
   const footerContent = (<Footer type={'submit'} onClickCancelar={() => setVisibleAsignar(false)} label={'Enviar'} />);

   return (
      <div className="flex justify-content-center">
         <form
            id="asignar-personal-paquete-masivo"
            className="asignar-personal-paquete-masivo flex flex-column gap-3"
            noValidate
            onSubmit={formik.handleSubmit}
         />
         <Dialog
            appendTo={document.getElementById("asignar-personal-paquete-masivo")}
            visible={visibleAsignar}
            style={{ width: "50vw", maxWidth: "424px" }}
            onHide={() => setVisibleAsignar(false)}
            header={header}
            footer={footerContent}
         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column gap-3 mt-3">
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Perfil:</p>
                  <InputText
                     id="nombre"
                     name="nombre"
                     value={perfiles}
                     className="w-full "
                     disabled
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Tipo de examen:</p>
                  <Dropdown
                     id="perfil_tipo_id"
                     name="perfil_tipo_id"
                     value={formik.values.perfil_tipo_id}
                     onChange={(e) => { formik.setFieldValue('perfil_tipo_id', e.value); }}
                     options={tipoEvaluacion}
                     optionLabel="nombre"
                     placeholder="Selecciona un examen"
                     className="w-full "
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Fecha de Atención:</p>
                  <Calendar
                     value={formik.values.fecha}
                     dateFormat="dd-mm-yy"
                     onChange={(e) => formik.setFieldValue("fecha", e.target.value)}
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Local:</p>
                  <Dropdown
                     id="clinica_local_id"
                     value={formik.values.clinica_local_id}
                     onChange={formik.handleChange}
                     options={clinica_id}
                     optionLabel="nombre"
                     placeholder="Selecciona un local"
                     className="w-full "
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
}
import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { Skeleton } from 'primereact/skeleton';
import { InputText } from "primereact/inputtext";

import AuthUser from "../../../AuthUser";
import CreateEmpresa from "../CreateEmpresa/CreateEmpresa";

import icono from "../../../Images/Perfil/icon/ActualizarUsuario.svg";

import '../../../Style/PagePerfilGeneral/USU-2/EmpresaCoorporativo.css';
import '../../../Style/PagePerfil.css'

export default function ProveedorComponentCoorporativa({ dataShow, data, setBregma, tiposDocumentos, handleSubmitUpdate }) {
    const { http } = AuthUser();
    const [visible, setVisible] = useState(false);
    const [departamentos, setDepartamentos] = useState([])
    const regexCorreoElectronico = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    
    /*CONVERTIR DE MAYUSCULA A MINUSCULA */
    const editText = (text, separador) => {
        if (text) {
            const palabras = text.split(" ")
            for (let i = 0; i < palabras.length; i++) {
                palabras[i] = (palabras[i][0]?.charAt(0).toUpperCase() || '') + palabras[i].substr(1).toLowerCase();
            }
            return (text ? palabras.join(" ") + (separador || '') : '');
        } else {
            return ''
        }
        /*  return (text?text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()+(separador||''):''); */
    }

    const handleChangeCelular = (e) => {
        setBregma({ ...data, celular: e.target.value })
    }

    const handleChangeCorreo = (e) => {
        let input = e.target
        if (input.classList.contains("p-invalid")) {
            input.classList.remove("p-invalid")
        }
        setBregma({ ...data, correo: e.target.value })
    }

    const handleChangeDireccion = (e) => {
        setBregma({ ...data, direccion: e.target.value })
    }

    const handleChangeRazonSocial = (e) => {
        setBregma({ ...data, razon_social: e.target.value })
    }

    const handleChangeNombreComercial = (e) => {
        setBregma({ ...data, nombre_comercial: e.target.value })
    }

    useEffect(() => {

        getAllUbicaciones()
    }, []);

    const getAllUbicaciones = () => {
        http
            .get("/ubicacion")
            .then((response) => {
                setDepartamentos(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const showErrorToast = (message) => {
        toast.current.show({ severity: 'error', summary: 'Error', detail: message });
    };
    const handleprevSubmit = (e) => {
        let input = document.querySelector(".input-correo")

        if (regexCorreoElectronico.test(data?.correo) || data?.correo == "") {
            handleSubmitUpdate(data.id, data)
            setVisible(false)

        } else {
            showErrorToast("Ingresar los campos correctamente")
            if (!input.classList.contains("p-invalid")) {
                input.classList.add("p-invalid")

            }


        }

    }

    const footerContent = (
        <div className="card flex flex-wrap justify-content-center gap-3">
            <Button onClick={(e) => {
                handleprevSubmit(e)
            }} className=" p-button-raised" >
                <span style={{ fontWeight: "500", fontSize: "14px" }}>Actualizar</span>
            </Button>
        </div>
    );

    const toast = useRef(null);

    // Skeleton
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, [])
    const loader = () => {
        return (
            <div className=" flex flex-wrap h-auto w-full">
                <div className="wrapperizquierda flex w-full pt-4 flex-wrap"
                    style={{ marginLeft: '80px', height: "298px" }}
                >
                    <div className="flex-1 info flex-row w-auto ">
                        {/* Datos y botones */}

                        <div style={{ marginTop: '20px' }} className='flex flex-column'>
                            <Skeleton width="423px" height="47px" borderRadius="16px"></Skeleton>
                            <h6 className='flex flex-row w-full subtitulos' style={{ marginTop: '10px' }}><Skeleton width="202px" height="39.9px" borderRadius="16px"></Skeleton></h6>
                            <div className='flex flex-row justify-content-end' style={{ marginTop: "-130px" }}>
                                {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                                <Skeleton width="85.75px" height="30.56px" borderRadius="16px"></Skeleton>
                            </div>
                        </div>

                        {/* Datos del usuario parte inferior */}
                        <div style={{ marginLeft: '60px' }} className="flex flex-wrap justify-content-between pt-5">
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                            <div className="data">
                                <div style={{ marginLeft: '20px', marginTop: '100px' }}>
                                    <Skeleton width="76px" height="66px" borderRadius="16px"></Skeleton>
                                </div>
                                <div style={{ marginLeft: '' }}>
                                    <Skeleton width="125px" height="25px" borderRadius="16px"></Skeleton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    const [InputCelEmpresa, setInputCelEmpresa] = useState(false);
    const [InputCorreoEmpresa, setInputCorreoEmpresa] = useState(false);

    if (loading) {
        return (loader())
    }

    else {
        return (
            <div className="CONTENEODRENTERO_CORPORA">
                <Toast ref={toast} />
                {/* Img de usuario */}
                <div className="CONTENEDOR_GENERAL_CORPORA">
                    <div className='CONTENEDOR_BOTON_EDITAR_CORPORA'  >
                        {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                        <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{ margin: '0' }}>
                            <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                            <p className='TEXTOAGREGARUSUARIO'>Editar</p>

                        </Button>
                    </div>
                    <div className="CONTENEDOR_DATA_GENERAL_CORPORA">
                        {/* Datos y botones */}

                        <div className='CONTENEDOR_UP_AHP_CORPORA'>
                            <div className="CONTENEDOR_DATA_CORPORA_TITU">
                                <label className='TITULO_CONT_UP' htmlFor="">Datos de la Empresa </label>
                                <label className='TITULO_CONT_UP_020' htmlFor="">
                                    {editText(dataShow?.distrito?.provincia?.departamento?.departamento, '-') +
                                        editText(dataShow?.distrito?.provincia?.provincia, '-') +
                                        editText(dataShow?.distrito?.distrito) || 'No hay departamento'}
                                </label>
                            </div>
                            <div className="CONTENEDOR_DATA_CORPORA_TITU">
                                {/* AV. San Borja Norte NRO. 772 URB. San Borja */}
                                <h6 className="SUBTITULO_CONT_DOWN_CORPORA">{dataShow?.razon_social || 'Sin nombre de la Empresa'}</h6>

                                <h6 className="SUBTITULO_CONT_DOWN_CORPORA">
                                    {dataShow?.numero_documento != null ? dataShow.numero_documento || 'No hay número' : 'No hay número'}
                                </h6>
                            </div>
                        </div>

                        {/* Datos del usuario parte inferior */}
                        <div className="CONTENEDOR_DOWN_AHP_CORPORA">
                            <div className="SUB_SECTIONS_ICONO_DATA_CORPORA">
                                <i className="pi pi-home"></i>
                                <h4 className='TITULO_CONT_DOWN_CORPORA'>Razón Social</h4>
                                <p className='SUBTITULO_CONT_DOWN_CORPORA'>
                                    {dataShow?.razon_social || 'No hay razón social'}
                                    {/* administracion@bregma.com.pe */}
                                </p>
                            </div>
                            <div className="SUB_SECTIONS_ICONO_DATA_CORPORA">
                                <i className="pi pi-hashtag"></i>
                                <h4 className='TITULO_CONT_DOWN_CORPORA'>RUC</h4>
                                <p className='SUBTITULO_CONT_DOWN_CORPORA'>
                                    {dataShow?.numero_documento || 'No hay documento'}
                                    {/* 938210243 */}
                                </p>
                            </div>
                            <div className="SUB_SECTIONS_ICONO_DATA_CORPORA">
                                <i className="pi pi-envelope"></i>
                                <h4 className='TITULO_CONT_DOWN_CORPORA'>Correo</h4>
                                <p className='SUBTITULO_CONT_DOWN_CORPORA'>
                                    {dataShow?.celulares != null ? dataShow?.correos[0]?.correo || 'Sin correo' : 'Sin correo'}
                                    {/* 938210243 */}
                                </p>
                            </div>

                        </div>

                    </div>
                    {/* Cuestionario emergente */}


                </div>
                <Dialog

                    header={<>
                        <div className="flex flex-column gap-2">
                            <div className="flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#EBEBFF', borderRadius: '10px', width: '60px', height: '60px' }}>
                                <img src={icono} alt="" style={{ width: '25px', height: '31.25px' }} />
                            </div>
                            <div>
                                <h3 style={{ fontWeight: '700', fontSize: '16px', color: '#5555D8' }}>Datos de la empresa</h3>
                                <p style={{ fontWeight: '500', fontSize: '14px', color: '#7B8794' }}>Complete la siguiente información sobre la empresa</p>
                            </div>
                        </div>
                    </>}
                    footer={footerContent}
                    visible={visible} className="form2-container"
                    style={{ width: '45vw', minWidth: "200px", maxWidth: "446px" }}
                    onHide={() => { setVisible(false); setInputCelEmpresa(false); setInputCorreoEmpresa(false) }}>
                    <div className="Contenedor_input_POPUP_Empresa">

                        <div className="Inputss_POPUP_Update_Empresa">
                            <label htmlFor="integer" className="">Razón Social</label>
                            <InputText id="name" className="w-full text-area2"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={data?.razon_social}
                                onChange={(e) => { handleChangeRazonSocial(e) }} />
                        </div>

                        <div className="Inputss_POPUP_Update_Empresa">
                            <label htmlFor="integer" className="">Celular</label>
                            <InputText id="celularE" keyfilter="num" className="w-full text-area2"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={data?.correo}
                                onChange={(e) => { handleChangeCelular(e) }} />

                        </div>

                        <div className="Inputss_POPUP_Update_Empresa">
                            <label htmlFor="email " className="">Correo</label>
                            <InputText id="email" type="email" keyfilter={/^[a-z0-9._@]+$/} className="w-full text-area2  input-correo"
                                style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                                value={data?.correo}
                                onChange={(e) => { handleChangeCorreo(e) }}
                            />
                        </div>

                        <CreateEmpresa
                            setBregma={setBregma}
                            data={data}
                            departamentos={departamentos}
                        />

                        <div className="Inputss_POPUP_Update_Empresa">
                            <label htmlFor="integer" className="">Dirección</label>
                            <InputText id="direccion" className="w-full text-area2"
                                style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                defaultValue={data?.direccion}
                                onChange={(e) => { handleChangeDireccion(e) }} />
                        </div>

                        {InputCelEmpresa && (
                            <div className="Inputss_POPUP_Update_Empresa">
                                <label htmlFor="celular2Empresa">Celular secundario</label>
                                <InputText id="celular2Empresa" name="celular2Empresa"
                                    style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                    className="w-full text-area2"
                                />
                            </div>)}
                        {InputCorreoEmpresa && (
                            <div className="Inputss_POPUP_Update_Empresa">
                                <label htmlFor="correo2Empresa">Correo secundario</label>
                                <InputText id="correo2Empresa" name="correo2Empresa"
                                    style={{ width: "100%", height: "40px", marginTop: "5px", border: "1px solid #ccc", padding: "5px" }}
                                    className="w-full text-area2"
                                />
                            </div>)}

                    </div>
                    {(InputCelEmpresa === false || InputCorreoEmpresa === false) && (
                        <div className="SectionExtraPOPUPEmpresa">
                            <p className="SectionExtraPOPUPTituEmpresa">Agregar nuevo dato</p>
                            {(InputCelEmpresa === false && InputCorreoEmpresa === false) && (
                                <div className="SectionExtraPOPUPContenButtonEmpresa">
                                    {InputCelEmpresa == false && (
                                        <div onClick={() => setInputCelEmpresa(true)}>Nuevo celular</div>
                                    )}
                                    {InputCorreoEmpresa == false && (
                                        <div onClick={() => setInputCorreoEmpresa(true)}>Nuevo correo</div>
                                    )}
                                </div>
                            )}
                            {(InputCelEmpresa === true || InputCorreoEmpresa === true) && (
                                <div className="SectionExtraPOPUPContenButtonEmpresa" style={{ justifyContent: 'center' }}>
                                    {InputCelEmpresa == false && (
                                        <div onClick={() => setInputCelEmpresa(true)}>Nuevo celular</div>
                                    )}
                                    {InputCorreoEmpresa == false && (
                                        <div onClick={() => setInputCorreoEmpresa(true)}>Nuevo correo</div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </Dialog>
            </div>
        )
    }
}
import React, { useState, useRef, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import SistemaMotor from "./ComponentTableS";
import Coordinacion from "./ComponentTableS2";
import { ScrollPanel } from 'primereact/scrollpanel';
import Upload_Input from "../input_upload/upload_input";
import EnvioArchivoMedGeneral from '../EnvioArchivoMedGeneral'
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";

export default function ComponentSistemaMotor(props) {
    const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);

    const [Archivo, setArchivo] = useState(exploracionDataContext?.motor_cordinacion?.archivo);
    const updateArchivo = (nuevoValor) => {
       setExploracionDataContext((prev) => ({
          ...prev, motor_cordinacion: {
             ...prev.motor_cordinacion,
             archivo: nuevoValor
          },
       }));
    }
    return (
        //Contenedor
        <div className="flex flex-column gap-3" style={{ width: '100%' }}>
            {/* Movilidad */}
            <div>
                <ScrollPanel style={{ width: '100%', height: '470px' }}>
                    <SistemaMotor />
                </ScrollPanel>
            </div>
            <div className="mb-5 mt-5">
                <ScrollPanel style={{ width: '100%', height: '470px' }}>
                    <Coordinacion />
                </ScrollPanel>
            </div>
            <div className="w-full h-auto flex">
                <EnvioArchivoMedGeneral
                    FuncionSetEnvio={setArchivo}
                    FuncionUpData={updateArchivo}
                    ValorSet={Archivo}
                    DataContext={exploracionDataContext.motor_cordinacion.archivo}
                />
            </div>
        </div>
    );
}

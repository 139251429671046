import React, { createContext, useState } from 'react';

export const AudContext = createContext(undefined)

export const AudProvider = (props) => {

   const [agregarContext, setAgregarContext] = useState({});
   const [principalContext, setPrincipalContext] = useState({
      AudCae: {
         oido_derecho: "",
         oido_izquierdo: "",
      },
      AudTimpanos: {
         oido_derecho: "",
         oido_izquierdo: "",
      },
      AudAudicion: {
         oido_derecho: "",
         oido_izquierdo: "",
      },
      AudOtoscopia: {
         oido_derecho: "",
         oido_izquierdo: "",
      },
      AudInterpretacion: {
         oido_derecho: "",
         oido_izquierdo: "",
      },
      observaciones: "",
      metodo_clasificacion: "",
      audiograma:[]

   });

   

   const [audData, setAudData] = useState({});
   const [getAudiometria, setGetAudiometria] = useState([]);

   const [audioId, setAudioId] = useState(null);

   const [audioPrincidat, setAudioPrincidat] = useState(null);

   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState('');



   return (
      <AudContext.Provider
         value={{
            principalContext, setPrincipalContext,
            atencionStatus, setAtencionStatus,
            timeStart, setTimeStart,
            agregarContext, setAgregarContext,
            getAudiometria, setGetAudiometria,
            audioId, setAudioId,
            audData, setAudData,
            audioPrincidat, setAudioPrincidat
         }}
      >
         {props.children}
      </AudContext.Provider>
   );
};
import { TreeTable } from "primereact/treetable";
import MultiLevelTreeSelectWithAPI from "../components/Servicios/Select";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import imgServicio from "../images/NewService.svg";
import { Button } from "primereact/button";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useEffect, useState } from "react";
import AuthUser from "../../../AuthUser";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";


import 'primeicons/primeicons.css';

const ModalEditarPaquete = ({ close, estado, data, servicios, showToast, update, index }) => {
    const { http } = AuthUser();
    const [totalPrecio, setTotalPrecio] = useState(0);
    const [selectedNodeKeys, setSelectedNodeKeys] = useState(null);
    const [nombrePaquete, setNombrePaquete] = useState('');
    const [selectedService, setSelectedService] = useState(null);
    const [selectedTreeSelectId, setSelectedTreeSelectId] = useState(data[0]?.paquete?.bregma_servicio_id);
    useEffect(() => {
        setSelectedTreeSelectId(data[0]?.paquete?.bregma_servicio_id)
    }, [data])
    const [sendData, setSendData] = useState({
        bregma_servicio_id: data[0]?.paquete?.bregma_servicio_id,
        icono: null,
        nombre: data[0]?.paquete?.nombre,
        areas_medicas: [],
        capacitaciones: [],
        examenes: [],
        laboratorios: [],
    });

    const sumPrices = (data, keysToSum) => {

        let precioTotal = {
            totalAnual: 0,
            totalMensual: 0
        };
        if (keysToSum === null) {
            return precioTotal
        }
        let keyNombres = Object.keys(keysToSum) || [];

        data.forEach((item) => {
            if (keyNombres.includes(item.key) && item.children.length === 0) {
                const precioMensual = Number(item.data.precio_referencial_mensual);
                const precioAnual = Number(item.data.precio_referencial_anual);
                precioTotal.totalMensual += isNaN(precioMensual) ? 0 : precioMensual;
                precioTotal.totalAnual += isNaN(precioAnual) ? 0 : precioAnual;
            }

            if (item.children && item.children.length > 0) {
                const childPrices = sumPrices(item.children, keysToSum);
                precioTotal.totalMensual += childPrices.totalMensual;
                precioTotal.totalAnual += childPrices.totalAnual;
            }
        });

        return precioTotal;
    };

    const obtenerPadres = (items, hijo) => {
        let names = hijo ? [] : [];

        items?.forEach(item => {
            if (item.children.length > 0) {
                names.push(item.nombre);
                names.push(...obtenerPadres(item.children, true));
            }
        });

        return names;
    }


    const transformDataChild = (data, parentKey, areasPadre, area) => {
        return data.map((item, index) => {
            const isChild = areasPadre?.includes(item.nombre);

            const node = isChild
                ? {
                    key: !area ? `${parentKey}.${item.id}` : `${parentKey}-${item.id}`,
                    data: {
                        nombre: item.nombre.replace('_', ' '),
                        precio_referencial_anual: 0,
                        precio_referencial_mensual: 0,
                        precio_mensual: 0,
                        precio_anual: 0,
                    },
                    children: [],
                }
                : {
                    key: !area ? `${parentKey}.${item.id}` : `${parentKey}-${item.id}`,
                    data: {
                        nombre: item.nombre,
                        precio_referencial_anual: item.precio_referencial_anual || 0,
                        precio_referencial_mensual: item.precio_referencial_mensual || 0,
                        precio_mensual: item.precio_mensual || 0,
                        precio_anual: item.precio_anual || 0,
                    },
                    children: [],
                };

            if (item.children && item.children.length > 0) {
                node.children = transformDataChild(item.children, !area ? `${parentKey}.${item.id}` : `${parentKey}-${item.id}`, areasPadre);
            }

            return node;
        });
    }


    const transformData = (paquetes) => {
        if (!paquetes || paquetes.length === 0 || !paquetes[0]) {
            return [];
        } else {
            const areas = ['areas_medicas', 'capacitaciones', 'examenes'];

            return areas.map((area, index) => {
                const data = {
                    key: `${area}`,
                    data: {
                        nombre: area.replace('_', ' ').toUpperCase(),
                        description: `This is node ${area}`,
                    },
                    children: [],
                };

                const areaData = paquetes.flatMap((paquete) => paquete[area]);
                let areasPadre = obtenerPadres(areaData)

                const areaNodes = transformDataChild(areaData, `${index + 1}`, areasPadre, true);

                data.children = areaNodes;
                return data;
            });
        }
    };


    const seleccionarServicios = (e) => {
        setSelectedNodeKeys(e);
        let keyNames = Object.keys(e);

        const areas_medicas = keyNames
            .filter((data) => data.charAt(0) === "1")
            .map((item) => {

                return {
                    id: item.slice(2, item.length),
                    parcialmente: e[item]?.partialChecked ? 0 : 1,
                };
            });
        const capacitaciones = keyNames

            .filter((data) => data.charAt(0) === "2")
            .map((item) => {
                return {
                    id: item.slice(2, item.length),
                    parcialmente: e[item]?.partialChecked ? 0 : 1,
                };
            });
        const examenes = keyNames
            .filter((data) => data.charAt(0) === "3")
            .map((item) => {
                return {
                    id: item.slice(2, item.length),
                    parcialmente: e[item]?.partialChecked ? 0 : 1,
                };
            });

        setSendData({
            ...sendData,
            areas_medicas: areas_medicas,
            capacitaciones: capacitaciones,
            examenes: examenes,
        });
    };




    const getKeyData = (data, dataServicios) => {
        const formattedData = {};

        if (!data) {
            return {}
        }

        const processNode = (node, servicios) => {
            const { key, children } = node;

            const matchingServiceNode = servicios.find(servicio => servicio.key === key);

            if (!matchingServiceNode || children.length !== matchingServiceNode.children.length) {
                formattedData[key] = { checked: false, partialChecked: true };
            } else {
                formattedData[key] = { checked: true, partialChecked: false };
            }

            children.forEach(childNode => processNode(childNode, matchingServiceNode.children));
        };

        data.forEach(node => {
            if (node.children.length > 0 || !['areas_medicas', 'capacitaciones', 'examenes'].includes(node.key)) {
                processNode(node, dataServicios);
            }
        });

        return formattedData;
    };


    //ENVIAR DATA
    const editarPaquete = async (data, paquete_id) => {
        dispatch(startLoading());
        try {
            const response = await http.put(`/bregma/paquete/update/${paquete_id}`, data)
            if (response?.data?.resp) {
                await Promise.all([
                    update()
                ])
                showToast('success', 'Actualizado correctamente', response?.data?.resp);
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error);
            }
        } catch (e) {
            console.error(e);
            showToast("error", "Error", "Contáctese con soporte técnico");
        } finally {
            dispatch(endLoading());
        }
    };

    const EnviarData = () => {
        let AlistarDatos = sendData
        AlistarDatos.bregma_servicio_id = selectedTreeSelectId
        AlistarDatos.nombre = nombrePaquete
        editarPaquete(AlistarDatos, data[0]?.paquete?.id)
        close(false)
    };

    useEffect(() => {
        setSelectedNodeKeys(getKeyData(transformData(data), servicios))
        seleccionarServicios(getKeyData(transformData(data), servicios))
        setNombrePaquete(data ? data[0]?.paquete?.nombre : '')
        setSelectedService(data[0]?.paquete?.bregma_servicio_id)
    }, [data]);

    useEffect(() => {
        setTotalPrecio(sumPrices(servicios, selectedNodeKeys))
    }, [selectedNodeKeys]);

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column
                    footer="Costo Total:"
                    footerStyle={{ textAlign: "right", fontFamily: "Montserrat", fontWeight: "600", fontSize: "14px", color: "#4545BC" }}
                />
                <Column
                    footer={`S/ ${totalPrecio.totalMensual}`}
                    footerStyle={{ textAlign: "center", fontFamily: "Montserrat", fontWeight: "700", fontSize: "14px", color: "rgba(86, 86, 86, 0.8)" }}
                />
                <Column
                    footer={`S/ ${totalPrecio.totalAnual}`}
                    footerStyle={{ textAlign: "center", fontFamily: "Montserrat", fontWeight: "700", fontSize: "14px", color: "rgba(86, 86, 86, 0.8)" }}
                />
            </Row>
        </ColumnGroup>
    );

    const dispatch = useDispatch();

    const ActivarLoading = () => {
        dispatch(startLoading());
        setTimeout(() => {
            dispatch(endLoading());

        }, 800);
    }

    const [camposValidos, setCamposValidos] = useState(false);
    const [nombreValido, setNombreValido] = useState(false);
    const [servicioValido, setServicioValido] = useState(false);
    const [seleccionValida, setSeleccionValida] = useState(false);


    const validarCampos = () => {
        const camposNombreValido = nombrePaquete !== '';
        const camposServicioValido = selectedTreeSelectId !== null;
        const camposSeleccionValida = selectedNodeKeys && typeof selectedNodeKeys === 'object' && Object.keys(selectedNodeKeys).length > 0;


        const camposSonValidos = camposNombreValido && camposServicioValido && camposSeleccionValida;

        setNombreValido(camposNombreValido);
        setServicioValido(camposServicioValido);
        setSeleccionValida(camposSeleccionValida);
        setCamposValidos(camposSonValidos);
    };

    useEffect(() => {
        validarCampos();
    }, [nombrePaquete, selectedTreeSelectId, selectedNodeKeys]);

    const [nombreValido2, setNombreValido2] = useState(true);
    const [servicioValido2, setServicioValido2] = useState(true);
    const [seleccionValida2, setSeleccionValida2] = useState(true);

    const InicilizarFaltasValidacion = () => {
        setNombreValido2(true);
        setServicioValido2(true);
        setSeleccionValida2(true);
    }

    const VerificarTodosDatos = () => {
        let veri = true;

        if (!nombreValido) {
            setNombreValido2(false);
            veri = false;
        } else {
            setNombreValido2(true);
        }

        if (!servicioValido) {
            setServicioValido2(false);
            veri = false;
        } else {
            setServicioValido2(true);
        }

        if (!seleccionValida) {
            setSeleccionValida2(false);
            veri = false;
        } else {
            setSeleccionValida2(true);
        }

        return veri;
    };


    const footerContent = (
        <div>
            <Button
                className="p-button-secondary p-button-outlined"
                label="Cancelar"
                onClick={() => { close(false); InicilizarFaltasValidacion() }}
            ></Button>
            <Button onClick={() => {
                if (VerificarTodosDatos()) {
                    EnviarData();
                    ActivarLoading()
                    InicilizarFaltasValidacion();
                }
            }} label="Actualizar"></Button>
        </div>
    );
    const HeaderDialogEditar = (
        <div className="flex gap-2 flex-column pl-2" style={{ alignItems: "start" }}>
            <img src={imgServicio} alt="" />
            <div style={{ fontSize: "18px", fontWeight: "700", color: "#5555D8", fontFamily: 'Montserrat' }}>
                Actualizar servicio
            </div>
        </div>
    )

    useEffect(() => {
        const inputElement = document.querySelector('.ventas_contactList thead tr:nth-child(2) input');
        if (inputElement) {
            inputElement.placeholder = 'Buscar servicio';
        }
    });



    return (
        <>
            <Dialog
                visible={estado}
                onHide={() => { close(false); InicilizarFaltasValidacion() }}
                footer={footerContent}
                header={HeaderDialogEditar}
                style={{ width: "900px", height: "auto" }} footerColumnGroup={footerGroup}>
                <div className="DialogoCrearServicio flex flex-column relative" style={{ gap: '12px' }}>
                    {/* Nombre del paquete con buscador */}
                    <style>
                        {`
                           .FaltaDatos{
                              box-shadow: 0 0 0 1px white, 0 0 0 2px red;
                           }
                           .AlertaFaltaDatos{
                              opacity: 0;
                              color: red;
                              bottom: -22px;
                              left: 6px;
                              transition:.5s;
                              font-size: 14px;
                           }
                           .AlertaFaltaDatos.Activo{
                              opacity: 1;
                           }
                        `}
                    </style>
                    <div className="flex justify-content-between gap-2 p-2">
                        <div className="w-6 relative gap-1 flex flex-column align-items-start justify-content-center">
                            <h6 style={{ fontWeight: "600", fontSize: "18px", color: "#565656" }} >
                                Nombre:
                            </h6>
                            <InputText
                                className={`${!nombreValido2 ? 'FaltaDatos' : ''} w-full`}
                                defaultValue={data ? data[0]?.paquete?.nombre : ''} placeholder="Ingrese un nombre"
                                onChange={(e) => {
                                    setNombrePaquete(e.target.value);
                                    setNombreValido2(true)
                                }} />
                            <span className={`absolute AlertaFaltaDatos ${!nombreValido2 ? 'Activo' : ''}`}>Debe ingresar el nombre del paquete</span>
                        </div>
                        <div className="relative w-6 flex flex-column align-items-start justify-content-end">
                            <MultiLevelTreeSelectWithAPI
                                setSelectedTreeSelectId={setSelectedTreeSelectId}
                                selectedService={selectedService}
                                setSelectedService={setSelectedService}

                                addClassName={`${!servicioValido2 ? 'FaltaDatos' : ''}`}
                                onServiceChange={() => {
                                    validarCampos();
                                    setServicioValido(selectedTreeSelectId !== null);
                                }}
                                addfuncion={setServicioValido2}
                            />
                            <span className={`absolute AlertaFaltaDatos ${!servicioValido2 ? 'Activo' : ''}`}>Debe ingresar el tipo de servicio</span>
                        </div>
                    </div>



                    <style>
                        {`
                            .ventas_contactList tbody tr td:nth-child(1) {
                                max-width: 580px !important;
                                min-width: 580px !important;
                            }
                            .ventas_contactList thead tr:nth-child(2) th{
                                padding: 0px !important;
                            }
                            .ventas_contactList thead tr:nth-child(2) input {
                                position: absolute;
                                top: 6px;
                                width: 38%;
                                right: 229px;
                                display:flex !important;
                            }
                        `}
                    </style>
                    <div className="w-full relative">
                        <i className="pi pi-search absolute" style={{ right: '250px', zIndex: '2', top: '32px', fontSize: '20px' }}></i>
                    </div>
                    <div className="relative">
                        <TreeTable
                            value={servicios.filter(item => item.key != "capacitaciones") || []}
                            selectionMode="checkbox"
                            selectionKeys={selectedNodeKeys}
                            onSelectionChange={(e) => {
                                seleccionarServicios(e.value);
                                setSeleccionValida2(true);
                            }
                            }
                            className={`${!seleccionValida2 ? 'FaltaDatos' : ''} ventas_contactList relative`}
                            emptyMessage={
                                <p className="text-center text-gray-500">
                                    Seleccione un paquete para ver su
                                    detalle.
                                </p>
                            }
                            style={{ height: "100%", borderRadius: "13px", width: '98%', margin: 'auto' }}
                            footerColumnGroup={footerGroup}
                        >
                            <Column
                                filter
                                header={
                                    <>
                                        <p style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "16px", color: "#5B5B5B" }}>Lista de servicios</p>
                                    </>
                                }
                                field="nombre"
                                expander
                            />
                            <Column
                                header={
                                    <div className="flex flex-row justify-content-center relative" style={{ width: '80px', height: '30px' }}>
                                        <div className="absolute flex align-items-center justify-content-center" style={{ height: "48px", width: "105px", background: "#FFECD7", borderRadius: "5px", justifyContent: "center", fontWeight: "600", fontSize: "16px", color: "#EF771C", alignItems: "Center", whiteSpace: 'normal', top: '-10px', lineHeight: '17px' }}>
                                            Precio Mensual
                                        </div>
                                    </div>
                                }
                                // "Precio Mensual"
                                body={(node) => (
                                    <>
                                        {node?.children?.length !== 0 ? (
                                            <span></span>
                                        ) : (
                                            <span className="text-center">
                                                S/{node?.data?.precio_referencial_mensual}
                                            </span>
                                        )}
                                    </>
                                )}
                                className="text-center StyleCOlumn"
                            ></Column>
                            <Column
                                header={
                                    <div className="flex flex-row justify-content-center relative" style={{ width: '80px', height: '30px' }}>
                                        <div className="absolute flex align-items-center justify-content-center" style={{ height: "48px", width: "105px", background: "#E0F5FD", borderRadius: "5px", justifyContent: "center", fontWeight: "600", fontSize: "16px", color: "#6BCDF5", alignItems: "Center", whiteSpace: 'normal', top: '-10px', padding: '0px 10px', lineHeight: '17px' }}>
                                            Precio Anual
                                        </div>
                                    </div>
                                }
                                field="data.pVenta"
                                className="text-center text-green-500"
                                body={(node) => (
                                    <>
                                        {node?.children?.length !== 0 ? (
                                            <span></span>
                                        ) : (
                                            <span className="text-center">
                                                S/{node?.data?.precio_referencial_anual}
                                            </span>
                                        )}
                                    </>
                                )}
                            ></Column>
                        </TreeTable>
                        <span className={`absolute AlertaFaltaDatos ${!seleccionValida2 ? 'Activo' : ''}`} style={{ bottom: '8px', marginLeft: '10px' }}>Debe seleccionar un servicio</span>
                    </div>

                </div>
            </Dialog>

        </>
    )
}

export default ModalEditarPaquete;
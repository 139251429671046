import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

export default function CartaDeControl2() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

        

        const data = {
            labels: ['Paciente1', 'Paciente2', 'Paciente3', 'Paciente4', 'Paciente5', 'Paciente6', 'Paciente7'],
            datasets: [
                {
                    label: "Minutos",
                    backgroundColor: "#5555d8",
                    data: [0, 3, 4, 1, 6, 7, 8, 4, 9, 10, 6, 4, 9, 5, 7, 3],
                    fill: false,
                    borderColor: "#5555d8",
                },
                {
                    label: "Máximo",
                    backgroundColor: "red",
                    data: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10],
                    fill: false,
                    borderColor: "red",
                },
                {
                    label: "Normal",
                    backgroundColor: "green",
                    data: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5,],
                    borderColor: 'green'
                },
                {
                    label: "Mínimo",
                    backgroundColor: "black",
                    data: [0, 0, 0, 0, 0, 0,0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,],
                    borderColor: 'black'
                }
                
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    display: true,
                    position: 'left',
                    align: 'start',
                    labels:{
                        color: 'black',
                        padding: 20
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, []);

    return (
        <div className="card">
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    )
}
        
import React, { useState, useEffect, useRef, useContext } from "react";
import "../../../Style/ExcelTablee.css";
import TablaComponent from "./ComponentTableD";
import TablaComponent2 from "./ComponentTableD2";
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import VoiceInputTextarea from "../../ButtonVoice/VoiceInputTextarea";
import { InputText } from "primereact/inputtext";
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import EnvioArchivoMedGeneral from '../EnvioArchivoMedGeneral'
import CardMedicinaGeneral from '../CardMedicinaGeneral';

export default function ComponentDesviacion(props) {
   const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);

   // const cardComponents = Object.keys(exploracionDataContext.med_eje_anterior).map((key, index) => {
   //    const registro = exploracionDataContext.med_eje_anterior[key];
   //    const numeroPatologia = index + 1;
   //    return (
   //       <CardMedicinaGeneral
   //          key={key}
   //          patologia={`${numeroPatologia}.- ${registro.patologia_id}`}
   //          campo={["med_eje_anterior", key]}
   //          context={exploracionDataContext}
   //          setContext={setExploracionDataContext}
   //       />
   //    );
   // });

   // const cardComponents02 = Object.keys(exploracionDataContext.med_eje_lateral).map((key, index) => {
   //    const registro = exploracionDataContext.med_eje_lateral[key];
   //    const numeroPatologia = index + 1;
   //    return (

   //    );
   // });


   const [Archivo, setArchivo] = useState(exploracionDataContext?.desviacion?.archivo);
   const updateArchivo = (nuevoValor) => {
      setExploracionDataContext((prev) => ({
         ...prev, desviacion: {
            ...prev.desviacion,
            archivo: nuevoValor
         },
      }));
   }
   const updateContextBase = (nuevoValor, propiedad, ind) => {
      setExploracionDataContext((prev) => ({
         ...prev,
         desviacion: {
            ...prev.desviacion,
            eje_anterior: prev.desviacion.eje_anterior.map((item, index) => {
               if (index === ind) {
                  return {
                     ...item,
                     [propiedad]: nuevoValor
                  };
               }
               return item;
            })
         }
      }));
   };
   const updateContextBase02 = (nuevoValor, propiedad, ind) => {
      setExploracionDataContext((prev) => ({
         ...prev,
         desviacion: {
            ...prev.desviacion,
            eje_lateral: prev.desviacion.eje_lateral.map((item, index) => {
               if (index === ind) {
                  return {
                     ...item,
                     [propiedad]: nuevoValor
                  };
               }
               return item;
            })
         }
      }));
   };



   const [Patologias, setPatologias] = useState([
      { id: 49, Nom_Patologia: "Cervical" },
      { id: 50, Nom_Patologia: "Dorsal" },
      { id: 51, Nom_Patologia: "Lumbar" }
   ])
   const OpcionDesviacionEjeAnterior = [
      { name: 'Normal', code: 'N' },
      { name: 'Anormal', code: 'A' },
      { name: 'Disminuida', code: 'D' }
   ];
   const OpcionDesviacionEjeLateral = [
      { name: 'Normal', code: 'N' },
      { name: 'Concavidad Derecha', code: 'C' },
      { name: 'Concavidad Izquierda', code: 'B' }
   ];

   return (
      //Contenedor
      <div className="pt-3" div style={{ width: '100%' }}>
         <div
            className="cardPalpacionContenido"
            id="miContenedor"
         >
            <div className="encabezadoColumn">
               <span style={{ textAlign: "center" }}>
                  COLUMNA VERTEBRAL: DESVIACIÓN DEL EJE ANTERO - POSTERIOR
               </span>
            </div>
            <div className="CuerpoColumn flex gap-3 ml-4 justify-content-center">
               <div className="flex flex-column w-full">
                  <table className="TablaResponsiv">
                     <thead>
                        <tr>
                           <th
                              style={{
                                 fontWeight: "500",
                                 width: "350px",
                                 fontSize: "14px",
                                 textAlign: "center",
                              }}
                           >
                              Patología
                           </th>
                           <th
                              style={{
                                 fontWeight: "500",
                                 fontSize: "14px",
                                 textAlign: "center",
                              }}
                           >
                              Resultado
                           </th>
                           <th
                              style={{
                                 fontWeight: "500",
                                 fontSize: "14px",
                                 textAlign: "center",
                              }}
                           >
                              Observación
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {exploracionDataContext.desviacion.eje_anterior.map((item, index) => {
                           let namePatologia = "";
                           Patologias.forEach((key) => {
                              if (key.id === item.patologia_id) {
                                 namePatologia = key.Nom_Patologia;
                              }
                           });

                           return (
                              <CardMedicinaGeneral
                                 key={index}
                                 context={exploracionDataContext}
                                 tituloSeccion={`${index + 1}.- ${namePatologia}`}
                                 PadreContext={exploracionDataContext?.desviacion?.eje_anterior[index]}
                                 updateContext={updateContextBase}
                                 index={index}
                                 opciones={OpcionDesviacionEjeAnterior}
                              />
                           );
                        })}

                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         {/* segunda tabla */}
         <div
            className="cardPalpacionContenido mt-5 mb-5"
            id="miContenedor"
         >
            <div className="encabezadoColumn">
               <span>DESVIACIÓN DEL EJE LATERAL</span>
            </div>
            <div className="CuerpoColumn flex gap-4 ml-4  ">
               <div className="flex flex-column w-full">
                  <table className="TablaResponsiv">
                     <thead>
                        <tr className="">
                           <th
                              style={{
                                 fontWeight: "500",
                                 fontSize: "14px",
                                 textAlign: "center",
                              }}
                           >
                              Patología
                           </th>
                           <th
                              style={{
                                 fontWeight: "500",
                                 fontSize: "14px",
                                 textAlign: "center",
                              }}
                           >
                              Resultado
                           </th>
                           <th
                              style={{
                                 fontWeight: "500",
                                 fontSize: "14px",
                                 textAlign: "center",
                              }}
                           >
                              Observación
                           </th>
                        </tr>
                     </thead>
                     <tbody>
                        {exploracionDataContext.desviacion.eje_lateral.map((item, index) => {
                           let namePatologia = "";
                           Patologias.forEach((key) => {
                              if (key.id === item.patologia_id) {
                                 namePatologia = key.Nom_Patologia;
                              }
                           });

                           return (
                              <CardMedicinaGeneral
                                 key={index}
                                 context={exploracionDataContext}
                                 tituloSeccion={`${index + 1}.- ${namePatologia}`}
                                 PadreContext={exploracionDataContext?.desviacion?.eje_lateral[index]}
                                 updateContext={updateContextBase02}
                                 index={index}
                                 opciones={OpcionDesviacionEjeLateral}
                              />
                           );
                        })}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
         <div className="flex flex-column w-full gap-5">
            <EnvioArchivoMedGeneral
               FuncionSetEnvio={setArchivo}
               FuncionUpData={updateArchivo}
               ValorSet={Archivo}
               DataContext={exploracionDataContext.desviacion.archivo}
            />
         </div>
      </div>

   );
}

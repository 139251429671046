
import React from 'react';
import { Button } from 'primereact/button';
import "../../../Style/Reclutamiento/CSSCard.css";

export default function BotonPlan(props) {

    function getBackgroundColor(plan) {
        if(plan==='Plan gen familia'){
            return '#BBF6DD';
        } else if (plan === 'Plan gen prime') {
            return '#F6F2FC';
        } else if (plan === 'Plan gen plus') {
            return '#FFECD7';
        } else if (plan === 'Plan gen ultra') {
            return '#ECFAFF';
        }
        else{
            return 'white';
        }
    }

    function getBackgroundColorpunto(plan) {
        if(plan==='Plan gen familia'){
            return '#00A15C';
        } else if (plan === 'Plan gen prime') {
            return '#8C62E3';
        } else if (plan === 'Plan gen plus') {
            return '#FF9F38';
        } else if (plan === 'Plan gen ultra') {
            return '#6BCDF5';
        }
        else{
            return 'white';
        }
    }

    function getColor(plan) {
        if(plan==='Plan gen familia'){
            return '#00A15C';
        } else if (plan === 'Plan gen prime') {
            return '#8C62E3';
        } else if (plan === 'Plan gen plus') {
            return '#FF9F38';
        } else if (plan === 'Plan gen ultra') {
            return '#6BCDF5';
        }
        else{
            return 'white';
        }
    }

    return (
        <div className="flex justify-content-center">
            <button className='BoTONEstaDO flex flex-row justify-content-center align-items-center gap-2' style={{ background: getBackgroundColor(props.plan), borderRadius: '8px', width:'140px' }}>
                <div style={{ borderRadius: '50%', background: getBackgroundColorpunto(props.plan), width: '11px', height: '11px' }} />
                <span style={{ color: getColor(props.plan), fontWeight: '400', fontSize: '14px' }}>{props.plan}</span>
            </button>
        </div>

    )
}

import { Button } from "primereact/button"
import Hospital from '../../../../Images/Hospital2 1.png'
export default function CardClientesGClientes () {

    return(
        <div className=" contenedor-contactos  flex-column shadow-1 border-round-xl gap-3">
            <div className=" gap-3 imagen-nombre">
                <div className="p-2 bg-indigo-100 border-circle align-items-center justify-content-center">
                    <img src={Hospital} className="pt-1 mx-1 hospital-img"/>
                </div>
                <div className="flex flex-column justify-content-center">
                    <h2 className="text-indigo-500">Diana Pérez Diaz</h2>
                    <label>Ventas</label>
                </div>
            </div>
            <div className="flex flex-column gap-2 px-3">
                <label> Contacto 01</label>
                <div className=" telefono">
                    <label className="font-semibold text-500">Teléfono</label>
                    <label>933276473</label>
                </div>
                <div className=" correo">
                    <label className="font-semibold text-500">Correo</label>
                    <label>dianadz@gmail.com</label>
                </div>
            </div>
            <div className=" contenedor-boton-Ultactualiza pt-3 border-400 border-top-1 justify-content-between">
                <div className="buttons gap-2 px-1">
                    <Button className="border-none bg-red-100" icon="pi pi-trash text-xl text-red-500" severity="help" aria-label="Favorite" onClick={() => ''}/>
                    <Button className="border-none bg-green-100" icon="pi pi-pencil text-xl text-green-500" severity="help" aria-label="Favorite" onClick={() => ''} />
                </div>
                <div className="flex flex-column justify-content-end">
                    <i className="pi pi-calendar ml-auto text-500" >14.08.2022</i>
                    <label className="ultimaAct_Contactos font-semibold text-500">Última actualización</label>
                </div>
            </div>
        </div>
    )
}
import React from 'react'; // Importa React
import { Dialog } from 'primereact/dialog'; // Importa el componente Dialog de PrimeReact

//Componentes generales
import AuthUser from '../../../AuthUser'; // Importa el componente AuthUser
import { endLoading, startLoading } from '../../../Redux/isLoadingSlice'; // Importa acciones de Redux para manejar el estado de carga
import { Footer, Header } from '../../Pure/SeccionesNavegacion'; // Importa componentes de pie de página y encabezado

export default function ModalEliminarCargo({
  visibleEliminarCargo, // Propiedad para controlar la visibilidad del modal
  setVisibleEliminarCargo, // Función para cambiar la visibilidad del modal
  showToast, // Función para mostrar notificaciones
  cargoEliminar, // Objeto que contiene la información del cargo a eliminar
  getCargos, // Función para obtener la lista de cargos
  api, // URL de la API para realizar la eliminación
  dispatch // Función para despachar acciones de Redux
}) {
  const { http } = AuthUser(); // Obtiene el objeto http de AuthUser para realizar peticiones

  const hideDialogEliminarCargo = () => {
    setVisibleEliminarCargo(false); // Oculta el modal
  }

  const DeleteCargo = async (id) => {
    dispatch(startLoading()); // Inicia el estado de carga
    try {
      const response = await http.delete(`${api}/${id}`); // Realiza la petición DELETE a la API
      if (response?.data?.resp) { // Si la respuesta es exitosa
        hideDialogEliminarCargo(); // Oculta el modal
        await Promise.all([getCargos()]); // Obtiene la lista de cargos actualizada
        showToast("success", "Eliminado correctamente", "Se eliminó el cargo correctamente"); // Muestra notificación de éxito
      } else if (response?.data?.error) { // Si hay un error en la respuesta
        showToast("error", "Error", response?.data?.error); // Muestra notificación de error
      }
    } finally {
      dispatch(endLoading()); // Finaliza el estado de carga
    }
  }

  const header = () => { return (<Header piIcon={'pi pi-trash'} titulo={'Eliminar Personal'} />) } // Encabezado del modal

  const paqueteDeleteFooter = (id) => (
    <Footer
      onClickCancelar={() => hideDialogEliminarCargo()} // Maneja el clic en el botón de cancelar
      onClickEnviar={() => DeleteCargo(id)} // Maneja el clic en el botón de eliminar
      label={'Eliminar'} // Etiqueta del botón de eliminar
    />
  );

  return (
    <div>
      <form
        id="personal-form-delete"
        className='personal-form-delete'
        noValidate>
        <Dialog
          resizable={false} // Desactiva el redimensionamiento del modal
          visible={visibleEliminarCargo} // Controla la visibilidad del modal
          style={{ width: "450px", margin: "5%" }} // Estilos del modal
          header={header} // Encabezado del modal
          footer={paqueteDeleteFooter(cargoEliminar.id)} // Pie de página del modal
          onHide={() => {
            hideDialogEliminarCargo(); // Oculta el modal al cerrar
          }}>
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p style={{ fontSize: "20px" }} className="m-0">¿Está seguro que desea eliminar personal?</p> {/* Mensaje de confirmación */}
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
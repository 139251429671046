import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { FilterMatchMode } from "primereact/api";

//Estilos generales
import "../../Style/Table/tablaCrud.css";
import "./TablaCrudJ.css";

function TablaCrud({
   key,
   multiple,
   multipler1,
   moder1,
   namer1,
   maxFileSizer1,
   acceptr1,
   leftLabelButton1,
   onClickLeftButton1,
   leftIconButton1,
   leftLabelButton4,
   onClickLeftButton4,
   leftIconButton4,
   leftLabelButton2,
   onClickLeftButton2,
   leftIconButton2,
   leftLabelButton3,
   leftIconButton3,
   onClickLeftButton3,
   leftLabelButton5,
   onClickLeftButton5,
   leftLabelButton6,
   onClickLeftButton6,
   rightLabelButton4,
   rightIconButton4,
   rightLabelButton3,
   rightIconButton3,
   onClickRightButton3,
   rightLabelFileUpload3,
   rightLabelButton2,
   rightIconButton2,
   onClickRightButton2,
   rightLabelFileUpload2,
   rightLabelButton1,
   rightIconButton1,
   onClickRightButton1,
   rightLabelFileUpload1,
   uploadHandlerr1,
   name,
   ref,
   value,
   selection,
   onSelectionChange,
   selectionMode,
   dataKey,
   rows,
   isHeader,
   children
}) {
   const [globalFilterValue, setGlobalFilterValue] = useState("");
   const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
   });
   const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
   };
   const [globalFilter, setGlobalFilter] = useState(null);
   const buttonLeft = (
      <React.Fragment>
         {leftLabelButton1 ? (
            <Button className="btn-1" onClick={onClickLeftButton1}>
               <i
                  className={
                     !leftIconButton1
                        ? "pi pi-plus flex gap-2 icon-style-1"
                        : leftIconButton1
                  }
               />
               <span>{leftLabelButton1}</span>
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}

         {leftLabelButton4 ? (
            <Button className="btn-2" onClick={onClickLeftButton4}>
               <i
                  className={
                     !leftIconButton4
                        ? "pi pi-file-excel flex gap-1 icon-style-2"
                        : leftIconButton4
                  }
               />
               <span>{leftLabelButton4}</span>
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}

         {leftLabelButton2 ? (
            <Button className="left2 flex gap-1" onClick={onClickLeftButton2}>
               <i
                  className={
                     !leftIconButton2 ? "pi pi-upload " : leftIconButton2
                  }
               />
               <span>{leftLabelButton2}</span>
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}

         {leftLabelButton3 ? (
            <Button
               className=" hola btn-primary gap-3"
               onClick={onClickLeftButton3}
            >
               <i
                  className={!leftIconButton3 ? "pi pi-plus" : leftIconButton3}
               />
               <span>{leftLabelButton3}</span>
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}

         {rightLabelButton4 ? (
            <Button className="btn-primary">
               <i
                  className={
                     !rightIconButton4 ? "pi-angle-left" : rightIconButton4
                  }
               />
               {rightLabelButton4}
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}

         {leftLabelButton5 ? (
            <Button className="botondni" onClick={onClickLeftButton5}>
               <i
                  className={!leftIconButton4 ? "pi pi-file" : leftIconButton4}
               />
               <p>{leftLabelButton5}</p>
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}
         {leftLabelButton6 ? (
            <Button className="botondni2" onClick={onClickLeftButton6}>
               <i
                  className={!leftIconButton4 ? "pi pi-file" : leftIconButton4}
               />
               <p>{leftLabelButton6}</p>
            </Button>
         ) : (
            <React.Fragment></React.Fragment>
         )}
      </React.Fragment>
   );
   const buttonRight3 = () => {
      if (rightLabelButton3) {
         return (
            <Button
               label={rightLabelButton3}
               icon={!rightIconButton3 ? "pi pi-file-excel" : rightIconButton3}
               className="btn-import"
               onClick={onClickRightButton3}
            />
         );
      } else if (rightLabelFileUpload3) {
         return (
            <FileUpload
               multiple={multiple}
               mode="basic"
               name="demo[]"
               auto
               url="https://primefaces.org/primereact/showcase/upload.php"
               accept=".xlsx"
               chooseOptions={{
                  label: rightLabelFileUpload3,
                  icon: "pi pi-file-excel",
                  className: "btn-import btn-tablacrud-import",
               }}
            />
         );
      }
   };
   const buttonRight2 = () => {
      if (rightLabelButton2) {
         return (
            <Button
               label={rightLabelButton2}
               icon={!rightIconButton2 ? "pi pi-file-excel" : rightIconButton2}
               className="btn-import"
               onClick={onClickRightButton2}
            />
         );
      } else if (rightLabelFileUpload2) {
         return (
            <FileUpload
               mode="basic"
               name="demo[]"
               auto
               url="https://primefaces.org/primereact/showcase/upload.php"
               accept=".xlsx"
               chooseOptions={{
                  label: rightLabelFileUpload2,
                  icon: "pi pi-file-excel",
                  className: "btn-import btn-tablacrud-import",
               }}
            />
         );
      }
   };
   const buttonRight1 = () => {
      if (rightLabelButton1) {
         return (
            <Button
               label={rightLabelButton1}
               icon={!rightIconButton1 ? "pi pi-file-excel" : rightIconButton1}
               className="btn-import"
               onClick={onClickRightButton1}
            />
         );
      } else if (rightLabelFileUpload1) {
         return (
            <FileUpload
               multiple={multipler1}
               mode={moder1}
               name={namer1}
               maxFileSize={maxFileSizer1}
               accept={acceptr1}
               customUpload
               auto
               uploadHandler={uploadHandlerr1}
               chooseOptions={{
                  label: rightLabelFileUpload1,
                  icon: "pi pi-file-excel",
                  className: "btn-import btn-tablacrud-import",
               }}
            />
         );
      }
   };

   const buttonRight4 = () => {
      if (rightLabelButton4) {
         return (
            <Button
               label={rightLabelButton4}
               icon={!rightIconButton1 ? "pi pi-fi" : rightIconButton1}
               className="btn-import"
               onClick={onClickRightButton1}
            />
         );
      } else if (rightLabelFileUpload1) {
         return (
            <FileUpload
               multiple={multipler1}
               mode={moder1}
               name={namer1}
               maxFileSize={maxFileSizer1}
               accept={acceptr1}
               customUpload
               auto
               uploadHandler={uploadHandlerr1}
               chooseOptions={{
                  label: rightLabelFileUpload1,
                  icon: "pi pi-file-excel",
                  className: "btn-import btn-tablacrud-import",
               }}
            />
         );
      }
   };

   const header_left = (
      <div className="aten-section">
         <div
            className="aten-areas flex flex-wrap align-items-center gap-3"
            style={{ width: "100%" }}
         >
            {buttonLeft}
         </div>

         {buttonRight3()}
         {buttonRight2()}
         {buttonRight1()}
         {buttonRight4()}
      </div>
   );
   const header = (
      <div className="table-header">
         <div className="search flex justify-content-between align-items-center">
            {header_left}
            <span
               className="p-input-icon-left custom-input w-6 bg-transparent" /* Tratar de no modificar esta tabla */
            >
               <i className="px-2 pi pi-search small-icon" />
               <InputText
                  className="textBuscar w-full "
                  type="search"
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  onInput={onGlobalFilterChange ? undefined : (e) => setGlobalFilter(e.target.value)}
                  placeholder="Buscar..."
               />
            </span>
         </div>
      </div>
   );

   return (
      <React.Fragment>
         <div className="datatable-crud-demo">
            <div className="card-table">
               <div className="table-container">
                  <DataTable
                     key={key}
                     filters={filters}
                     className="tablaReclutamiento"
                     emptyMessage={`No hay ${name} por mostrar`}
                     ref={ref}
                     value={value}
                     selection={selection}
                     onSelectionChange={onSelectionChange}
                     dataKey={dataKey}
                     paginator
                     selectionMode={selectionMode}
                     rows={rows ? rows : 10}
                     rowsPerPageOptions={[10, 20, 50]}
                     paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                     currentPageReportTemplate={`Mostrando {first} al {last} de {totalRecords} ${name}`}
                     globalFilter={globalFilter}
                     header={
                        isHeader === false ? (
                           <React.Fragment></React.Fragment>
                        ) : (
                           header
                        )
                     }
                     responsiveLayout="scroll"
                  >
                     {children}
                  </DataTable>
               </div>
            </div>
         </div>
      </React.Fragment>
   );
}

export default TablaCrud;

import React from 'react'

function PageEstadisticaRHB() {
    return (
        <div>
            <div className='arriba'>
                <span className='tit'>Estadistica Recursos Humanos</span><br />
                <span className='con'>Agregar contenido</span>
            </div>
        </div>
    )
}

export default PageEstadisticaRHB
import React, { useState, useRef } from "react";
import { Toast } from 'primereact/toast';
import CuadroNotificacionesInBox from './Cuadro_InBox.jsx';
import VerNotificacion from './VerNotificacion.jsx'
import mensaje from './img/Comments.svg';
/* Responsive todo en PagePerfil1.css */

export default function InboxComponent() {
    const Mensaje = {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '15.94px',
        letterSpacing: '0em',
        textAlign: 'center',
        margin: '0% 10% 0% 10%',
        opacity: "54%",
        color: '#4545BC',
        paddingTop: "10px"
    }
    const [notificaciones, setnotificaciones] = useState([
        {
            id: '1',
            icon: "0",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores amen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '0',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '2',
            icon: "1",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados ene dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '1',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '3',
            icon: "2",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados en su último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '1',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '4',
            icon: "3",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tisu último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '2',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '5',
            icon: "1",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienete a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '1',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '6',
            icon: "2",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados en su último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '1',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '7',
            icon: "3",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados en su último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '2',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '8',
            icon: "1",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados en su último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '1',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '9',
            icon: "2",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados en su último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '1',
            hora: "16:17", fecha: '2024-02-16'
        },
        {
            id: '10',
            icon: "3",
            remitente: "MEDICAL ROMA",
            titular: 'Tienes 4 trabajadores observados',
            contenido: 'Hola Medical Roma, tienes 4 trabajadores que han salido observados en su último examen médico de rutina Para verificar la lista, tienes que dirigirte a la parte inferior de este mensaje donde encontrará un botón que le diga ir a la página.',
            tipo_mensaje: '2',
            hora: "16:17", fecha: '2024-02-16'
        }
    ])
    const [DatosNoti, setDatosNoti] = useState('')
    const [ExistNoti, setExitsNoti] = useState(false);
    const toast = useRef(null);
    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Notificación', detail: 'Eliminación exitosa', life: 3000 });
    }
    const EliminarAllNotificaciones = () => {
        setnotificaciones([]);
        setExitsNoti(false);
    };
    return (
        <div className="CONTENEDOR_INBOX_GENERAL_AHP flex align-items-center justify-content-between">
            <Toast ref={toast} />
            <div className="SUBCONTENEDOR_INBOX_GENERAL_AHP_1 h-full p-3 flex flex-column align-items-center justify-content-between" style={{ width: '49.5%' }}>
                <div className="CONTENEDOR_TITU_NOTI_INBOX_AHP_1 w-full h-auto flex justify-content-start align-items-center">
                    <h2 className="TEXTO_NOTIFICACION mr-3">Notificaciones</h2>
                    <div className="SUBTEXTO_NOTIFICACION_CANT flex align-items-center justify-content-center pt-2 pb-2 pr-3 pl-3 border-round-2xl	w-auto h-auto" style={{ backgroundColor: '#F6F6FF' }}>
                        <i className="pi pi-bell mr-2"></i>
                        <p>Tienes {notificaciones.length} notificaiones</p>
                    </div>
                </div>
                <div className="w-full overflow-auto" style={{ height: '80%' }}>
                    {notificaciones.length !== 0 ? (
                        <CuadroNotificacionesInBox
                            DataNotificaciones={notificaciones}
                            FuncionChange={setDatosNoti}
                            FuncionLimpiar={setExitsNoti}
                        ></CuadroNotificacionesInBox>
                    ) : (
                        <div className="MENSAJE w-full h-full border-round-xl">
                            <img src={mensaje} alt="" />
                            <span style={Mensaje}>Acá aparecerán las notificaciones de manera más detallada, solo dale clic al icono de una flecha de cada notificación.</span>
                        </div>
                    )}
                </div>
                <div className="SUBTEXTO_NOTIFICACION_BORRAR flex align-items-center justify-content-center pt-2 pb-2 pr-5 pl-5 border-round-md"
                    onClick={() => EliminarAllNotificaciones()}
                    style={{ backgroundColor: '#7A7AEE', color: 'white', cursor: 'pointer' }}>
                    <i className="pi pi-trash mr-2"></i>
                    <p className="">Borrar todas las notificaciones</p>
                </div>
            </div>
            <div className="LINEA_DIVISORIA_INBOX_AHP"></div>
            <div className="SUBCONTENEDOR_INBOX_GENERAL_AHP_2 h-full p-3 flex flex-column align-items-center justify-content-center" style={{ width: '49.5%' }}>
                {ExistNoti ? (
                    <VerNotificacion
                        Data={DatosNoti}
                        AllData={notificaciones}
                        setData={setnotificaciones}
                        FuncionLimpiar={setExitsNoti}
                        ViewToast={showSuccess}
                    ></VerNotificacion>
                ) : (
                    <div className="MENSAJE w-full h-full border-round-xl">
                        <img src={mensaje} alt="" />
                        <span style={Mensaje}>Acá aparecerán las notificaciones de manera más detallada, solo dale clic al icono de una flecha de cada notificación.</span>
                    </div>
                )}
            </div>
        </div>

    )
}
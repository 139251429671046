import React, { useState } from "react";
import Table from "../../Table/Table";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import ModalSigVitEliminar from "./ModalsSigVit/ModalSigVitEliminar";
import { Accordion, AccordionTab } from "primereact/accordion";
import ModalSigVitCrear from "./ModalsSigVit/ModalSigVitCrear";
import { Fieldset } from "primereact/fieldset";

const TriajeSigVitTable = () => {
   const [visibleDelete, setVisibleDelete] = useState(false);
   const [IdSignosVitales, setIdSignosVitales] = useState(false);

   const EliminarSignosVitales = (itemIndex) => {
      setVisibleDelete(true);
      setIdSignosVitales(itemIndex);
   };
   const EditarSignosVitales = (rowData, itemIndex) => {
      setIdSignosVitales(itemIndex);
   };

   const actionBodyTemplateboton = (rowData, indexData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => EditarSignosVitales(rowData, indexData.rowIndex)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => { EliminarSignosVitales(indexData.rowIndex); }}
            />
         </React.Fragment>
      );
   };
   return (
      <>
         <div className="desktop-signos-vitales">
            <Fieldset legend="Añadir signos vitales" toggleable>
               <ModalSigVitCrear />
            </Fieldset>
            <Accordion className="pt-5">
               <AccordionTab header="Historial">
                  <Table
                     // data={dataSigVitBD}
                     createButtonEnabled={false}
                     clasesSearch="w-5 flex justify-end align-items-end"
                  >
                     <Column
                        className="text-center"
                        field="item"
                        header="Ítem"
                     ></Column>
                     <Column
                        className="text-center"
                        field="frec_cardiaca"
                        header="Frec. Cardiaca"
                     />
                     <Column
                        className="text-center"
                        field="frec_respiratoria"
                        header="Frec. Respiratoria"
                     />
                     <Column
                        className="text-center"
                        field="p_sistolica"
                        header="P. Sistólica"
                     />
                     <Column
                        className="text-center"
                        field="p_diastolica"
                        header="P. Diastólica"
                     />
                     <Column
                        className="text-center"
                        field="p_media"
                        header="Pres. Media"
                     />
                     <Column
                        className="text-center"
                        field="saturacion"
                        header="Sat. O2"
                     />
                     <Column
                        className="text-center"
                        field="temperatura"
                        header="Temperatura"
                     />
                     <Column
                        className="text-center"
                        field="observaciones"
                        header="Observaciones"
                        style={{ minWidth: "16rem", whiteSpace: "wrap" }}
                     />
                     <Column
                        className="text-center"
                        header="Acción"
                        body={actionBodyTemplateboton}
                        style={{ maxWidth: "8rem" }}
                     />
                  </Table>
               </AccordionTab>
            </Accordion>
         </div>
         <ModalSigVitEliminar
            visibleDelete={visibleDelete}
            setVisibleDelete={setVisibleDelete}
            IdSignosVitales={IdSignosVitales}
         ></ModalSigVitEliminar>
      </>
   );
};

export default TriajeSigVitTable;

import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { RAContext } from '../../../Page/RutaAtencion/RAContext';

const RATimeView = ({ title, AreaDetalleId, icon, finalizarAtencion, iniciarAtencion,
   CancellAtencion, textButton, createAtencion, VerHoraFin, VerHoraInicio, VerfechaInicio,  estado, setEstado }) => {
   const [date, setDate] = useState('');
   const [dateSend, setDateSend] = useState('');
   const [time, setTime] = useState('');
   const [savingTime, setSavingTime] = useState(false);
   let { pageViewContext, stateAtencion } = useContext(RAContext)

   const empezar = () => {
      iniciarAtencion(AreaDetalleId, time, dateSend)
      createAtencion();
   }

   const getDateTime = () => {
      const dt = new Date(Date.now());

      const day = dt.getDate().toString().padStart(2, '0');
      const month = (dt.getMonth() + 1).toString().padStart(2, '0');
      const year = dt.getFullYear();

      const horas = dt.getHours();
      const minutos = dt.getMinutes();

      let hora;

      if (horas > 12) {
         hora = horas - 12;
      } else {
         hora = horas;
      }

      const fecha = `${day}-${month}-${year}`;
      const fechaSend = `${year}-${month}-${day}`;
      const horaFormateada = `${horas}:${minutos}`;

      setDateSend(fechaSend);
      setDate(fecha);
      setTime(horaFormateada);
   };

   useEffect(() => {
      getDateTime();
      const intervalId = setInterval(getDateTime, 10000);

      return () => {
         clearInterval(intervalId);
      };
   }, []);

   return (
      <>
         <section className="paciente_start_attention">
            <div className="shadow_containers">
               <header>
                  <img src={icon} alt="icon_triaje" />
                  <label>{title}</label>
               </header>
               <main className="psa_date">
                  {!estado ?
                     (
                        <div>
                           <div>
                              <label>Fecha y Hora de Inicio: </label>
                              <span className="psa_date_detail">{date}</span>
                           </div>
                           <span>{time}</span>
                        </div>
                     ) :
                     (
                        <div>
                           <div>
                              <label>Fecha y Hora de Inicio: </label>
                              <span className="psa_date_detail">{VerfechaInicio}</span>
                           </div>
                           {/* <span>{formateTime(pacienteContext?.psicologia_inicio)}</span> */}
                           <span>{VerHoraInicio}</span>
                        </div>
                     )
                  }
                  <br /><br />

                  {stateAtencion[pageViewContext].estado === 4 ?
                     (
                        <div>
                           <div>
                              <label>Fecha y Hora de Fín: </label>
                              <span className="psa_date_detail">{date}</span>
                           </div>
                           {/* <span>{pacienteContext?.psicologia_fin}</span> */}
                           <span>{VerHoraFin}</span>
                        </div>
                     ) : (
                        <>
                           <label>Fecha y Hora de Fín: </label>
                           <span>{finalizarAtencion && date}</span>
                           <span>{finalizarAtencion && time}</span>
                        </>
                     )}


               </main>
               {
                  stateAtencion[pageViewContext].estado === 4 ?
                     (
                        <footer className='gap-3'>
                           <Button
                              label='Ver'
                              onClick={() => CancellAtencion(3)}
                           />
                        </footer>
                     ) :
                     (
                        <footer className='gap-3'>

                           {finalizarAtencion && <Button
                              label='Cancelar'
                              // icon="pi pi-arrow-circle-right"
                              className="bg-white w-6"
                              style={{ color: "#6366F1" }}
                              loadingIcon="pi pi-spin pi-spinner"
                              onClick={() => setEstado(!estado)}
                              loading={savingTime}
                           />}
                           <Button
                              label={!savingTime && (textButton || 'Iniciar atención')}
                              // icon="pi pi-arrow-circle-right"
                              className="p-button w-12"
                              loadingIcon="pi pi-spin pi-spinner"
                              onClick={() => estado ? finalizarAtencion(AreaDetalleId, time, dateSend) : empezar()}
                              loading={savingTime}
                           />
                        </footer>
                     )
               }

            </div>
         </section>
      </>
   );
};

export default RATimeView;

import { InputText } from "primereact/inputtext";
import "../../../../Style/PageGestionProformaB.css";
import { contentService } from "../../../../Services/dataGestion2";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';

export default function ClinProforma() {
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(3); 

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState('');

    const bg_state = (btn) => {
        switch (btn) {
            case "En espera":
                return "proforma__container_body_table_state_purple";
            case "Aceptado":
                return "proforma__container_body_table_state_green";
            case "Rechazado":
                return "proforma__container_body_table_state_red";
            default:
                return "";
        }
    };

    const handleEstadoSeleccionado = (estado) => {
        setSelectedStatus(estado);
        setDialogVisible(false);
    };

    const [visible, setVisible] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);

    

    return (
        <div className="proforma__container">
            <div className="add__container_header">
                <h4 className="add__container_header_h4">Gestión de Proformas</h4>
                <p className="add__container_header_p">
                    En esta seccion usted puede ver el historial de las proformas
                </p>
            </div>
            <div className="proforma__container_input_header flex ">
                <span className="p-input-icon-left  bg-transparent">
                    <i className="pi pi-search" />
                    <InputText
                        placeholder="Buscador"
                        className="input_responsive border-0 border-round-3xl shadow-2"
                    />
                </span>
                <div className="flex-1 flex gap-2 justify-content-end">
                    <Button className="bg-green-600" onClick={() => setVisible(true)} style={{ color: "white", borderBlockColor: "green", fontSize: "15px" }}>
                        <span className="p-button-label p-c">+ Crear nuevo</span>
                    </Button>
                </div>
            </div>
            <div className="proforma__container_body bg-white flex flex-column">
                <ScrollPanel
                    style={{ width: "100%", height: "100%" }}
                    className="custombar2"
                >

                    <table className="proforma__container_body_table">
                        <thead>
                            <tr>
                                <th className="text-center">Item</th>
                                <th className="text-center">Código de Proforma</th>
                                <th className="text-center">Razon Social</th>
                                <th className="text-center">Fecha de Creacion</th>
                                <th className="text-center">Estado</th>
                                <th className="text-center">Carga de Archivos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contentService.slice(first, first + rows).map((item) => (
                                <tr key={item.id}>
                                    <td className="text-center proforma__container_body_table_txt_proform">
                                        {item.id}
                                    </td>
                                    <td className="text-center proforma__container_body_table_txt_proform">
                                        {item.codeProform}
                                    </td>
                                    <td className="text-center proforma__container_body_table_txt_proform">
                                        {item.razonS}
                                    </td>
                                    <td className="text-center proforma__container_body_table_txt_proform">
                                        {item.fechaCr}
                                    </td>
                                    <td>
                                        <div className="flex h-full w-full justify-content-center">
                                            <div className={bg_state(item.status)}>
                                                <i className=""></i>
                                                <p>{item.status}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex align-items-center justify-content-center w-full h-full ">
                                            <Button style={{ backgroundColor: 'transparent', borderColor: 'transparent' }} >
                                                <i className="pi pi-upload proforma__container"></i>
                                            </Button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div>
                        <Paginator
                            first={first}
                            rows={rows}
                            totalRecords={contentService.length}
                            rowsPerPageOptions={[3, 6, 9]}
                            onPageChange={onPageChange}
                        />
                    </div>
                </ScrollPanel>
            </div>
            <Dialog visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
            <h4 className="add__container_header_h4">Crear Proformas</h4>
                <p className="add__container_header_p">
                  Aqui podras crear una nueva proforma
                </p>
            </Dialog>

        </div>
    );
}

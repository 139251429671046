import React, { useState } from "react";
import { Button } from "primereact/button";
//type, onChangeType valueButton1 valueButton2 valueDefault labelButton1 labelButton2
const S3ProductType = (props) => {
    /* const [tipo, setTipo] = useState(props.valueDefault || "1");

    const onchangeTipo = (e) => {
        setTipo(e)
    } */

    return (
        <div className="flex gap-2">
            <Button

                onClick={() => props.onChangeType(props.valueButton1)}
                className={`ventas-button-type-price text-sm flex-1 ${props.type === (props.valueButton1 || '1')
                            ? 'bg-indigo-500'
                            : 'text-gray-500 bg-gray-300 border-gray-300'
                            }`}
                label={props.labelButton1 || "1"}
                disabled={props.disabledButton === props.valueButton1}
            ></Button>
            <Button
                onClick={() => props.onChangeType(props.valueButton2)}
                className={`ventas-button-type-price text-sm flex-1 ${props.type === (props.valueButton2 || '2')
                            ? 'bg-indigo-500'
                            : 'text-gray-500 bg-gray-300 border-gray-300'
                            }`}
                label={props.labelButton2 || "2"}
                disabled={props.disabledButton === props.valueButton2}
            ></Button>
        </div>
    )
}
export default S3ProductType;
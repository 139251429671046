
import { Dropdown } from 'primereact/dropdown';
import React, { useState, useContext, useEffect } from 'react';
import "../../../../Style/style6.css";
import { OftContext } from '../../OftContext';
import { RAContext } from '../../../RutaAtencion/RAContext';

export default function LadoIntemedio() {
    let { agudVisualContext, setAgudVisualContext } = useContext(OftContext);
    const [Ae_OjoDer, setAe_OjoDer] = useState(agudVisualContext?.a_e?.ojo_derecho ?? null);
    const [Ae_OjoIzq, setAe_OjoIzq] = useState(agudVisualContext?.a_e?.ojo_izquierdo ?? null);
    let { pageViewContext, stateAtencion } = useContext(RAContext)

    useEffect(() => {
        setAgudVisualContext({
            ...agudVisualContext,
            [`a_e`]: {
                [`ojo_derecho`]: Ae_OjoDer,
                [`ojo_izquierdo`]: Ae_OjoIzq
            },
        })
    }, [Ae_OjoDer, Ae_OjoIzq]);
    
      
    const options = [
        { label: 'No usa', id: '1 ' },
        { label: '20/10', id: '2 ' },
        { label: '20/13', id: '3 ' },
        { label: '20/15', id: '4 ' },
        { label: '20/16', id: '5 ' },
        { label: '20/20', id: '6 ' },
        { label: '20/25', id: '7 ' },
        { label: '20/30', id: '8 ' },
        { label: '20/32', id: '9 ' },
        { label: '20/40', id: '10' },
        { label: '20/50', id: '11' },
        { label: '20/60', id: '12' },
        { label: '20/63', id: '13' },
        { label: '20/70', id: '14' },
        { label: '20/80', id: '15' },
        { label: '20/100', id: '16' },
        { label: '20/125', id: '17' },
        { label: '20/150', id: '18' },
        { label: '20/160', id: '19' },
        { label: '20/200', id: '20' },
        { label: '20/250', id: '21' },
        { label: '20/320', id: '22' },
        { label: '20/400', id: '23' },
        { label: '20/500', id: '24' },
        { label: '20/800', id: '25' },
        { label: 'CD', id: '26' },
        { label: 'PL', id: '27' },
        { label: 'Ausente', id: '28' },
        { label: 'Positivo', id: '29' },
        { label: 'Negativo', id: '30' },
    ];

    return (
        <div className="CONTENEDORAE flex flex-column w-full">
            <div className="ENCABEZADOAE flex flex-column w-full" style={{ padding: '17px' }}>
                <span style={{ fontWeight: 'bold', fontSize: '17px' }}>A.E</span>
            </div>
            <div className="CONTENIDOAEOJODERECHOIZQUIERDO">
                <div className="subcabeza">
                    <div className="SUBENCABEZADOOJOS flex flex-row">
                        <div className="flex flex-row w-full justify-content-center">
                            <span>Ojo Derecho</span>
                        </div>
                        <div className="flex flex-row w-full justify-content-center">
                            <span>Ojo Izquierdo</span>
                        </div>
                    </div>
                </div>
                <div className="DROPDOWNOJODERECHOEIZQUIERDO flex">
                    <div className="flex flex-row w-full sups" style={{ padding: '5px' }}>
                        <span style={{ width: "100%", textAlign: "center", marginBottom: '6px' }} className='ojoder'>Ojo Derecho</span>
                        <Dropdown
                            options={options}
                            optionValue="label"
                            value={Ae_OjoDer}
                            onChange={e => setAe_OjoDer(e.value)}
                            className="w-full"
                            placeholder="Seleccionar"
                            disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                    </div>
                    <div className="flex flex-row w-full sups" style={{ padding: '5px' }}>
                        <span style={{ width: "100%", textAlign: "center", marginBottom: '6px' }} className='ojoder'>Ojo Izquierdo</span>
                        <Dropdown
                            options={options}
                            optionValue="label"
                            value={Ae_OjoIzq}
                            onChange={e => setAe_OjoIzq(e.value)}
                            className="w-full"
                            placeholder="Seleccionar"
                            disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { RadContext } from "./RadProvider";
import VoiceRadiologia from "../../Components/ButtonVoice/VoiceRadiologia";

const CalidadRadiograficaContent = () => {
    let { RayTorax, setRayTorax } =
        useContext(RadContext);

    const cities = [
        { name: "1", code: "1" },
        { name: "2", code: "2" },
        { name: "3", code: "3" },
        { name: "4", code: "4" },
    ];
    const cities6 = [
        { name: "Artefacto ", code: "1" },
        { name: "Blanda", code: "2" },
        { name: "Escapulas", code: "3" },
        { name: "Inspiración insuficiente", code: "4" },
        { name: "Posición Centrado", code: "5" },
        { name: "Sobreexposición", code: "6" },
        { name: "Subexposición", code: "7" },
        { name: "Contraste", code: "8" },
        { name: "Otros", code: "9" },

     ];


    const actualizarPropiedadesBase = (newvalor, propiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: newvalor
        }));
    };
    const ConvertirSelectObjeto = (Array, Respuesta, setvalorRespuesta, propDef) => {
        for (let index = 0; index < Array.length; index++) {
            if (Array[index][propDef] == Respuesta) {
                setvalorRespuesta(Array[index]);
            }
        }
    };


    const [calidad_rx, setcalidad_rx] = useState(null);
    const [calidad_rx_cod, setcalidad_rx_code] = useState(RayTorax.calidad_rx ? RayTorax.calidad_rx : null);
    useEffect(() => {
        ConvertirSelectObjeto(cities, calidad_rx_cod, setcalidad_rx, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(calidad_rx_cod, "calidad_rx");
    }, [calidad_rx_cod])



    const [causa, setcausa] = useState(null);
    const [causa_cod, setcausa_cod] = useState(RayTorax.causa ? RayTorax.causa : null);
    useEffect(() => {
        ConvertirSelectObjeto(cities6, causa_cod, setcausa, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(causa_cod, "causa");
    }, [causa_cod])


    const [observaciones, setobservaciones]= useState(RayTorax.descripcion ? RayTorax.descripcion : null);
    useEffect(() => {
        actualizarPropiedadesBase(observaciones, "descripcion");
    }, [observaciones])

    const subTituloMorado = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        color: "#4545BC",
        width: "120px",
    };
    return (
        <div className="w-full h-auto flex flex-column pb-2 pr-2 pl-2 gap-2">
            <div className="w-full flex" >
                <div style={subTituloMorado}>Calidad Rx:</div>
                <div className="flex-1 flex relative">
                    <Dropdown className="w-full" style={{ height: '48px' }} value={calidad_rx}
                        onChange={(e) => setcalidad_rx_code(e.value.code)}
                        options={cities} optionLabel="name" />
                    {calidad_rx != null ? (
                        <button
                            className="absolute pi pi-times"
                            style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                            onClick={() => {
                                setcalidad_rx_code(null);
                                actualizarPropiedadesBase(null, "calidad_rx"); setcalidad_rx(null)
                            }}></button>
                    ) : null}
                </div>
            </div>
            <div className="w-full flex">
                <div style={subTituloMorado}>Causa:</div>
                <div className="flex-1 flex relative">
                    <Dropdown className="w-full" style={{ height: '48px' }} value={causa}
                        onChange={(e) => setcausa_cod(e.value.code)}
                        options={cities6} optionLabel="name" />
                    {causa != null ? (
                        <button
                            className="absolute pi pi-times"
                            style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                            onClick={() => {
                                setcausa_cod(null);
                                actualizarPropiedadesBase(null, "causa"); setcausa(null)
                            }}></button>
                    ) : null}
                </div>
            </div>
            <div className="w-full flex flex-column gap-1">
                <div style={subTituloMorado}>Observaciones:</div>
                <VoiceRadiologia
                  value={observaciones}
                  onChange={(e) => setobservaciones(e)}
                  className="flex-1"
               />
            </div>
        </div>

    );
};

export default CalidadRadiograficaContent;

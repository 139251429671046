import React, { useState, useContext, useEffect } from "react";
import "../../../Style/ExcelTable2.css";
import "../../../Style/MedicinaGeneral/ExplPalpacion.css"
import CardMedicinaGeneral from "../CardMedicinaGeneral";
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";


export default function SistemaMotor(props) {

    const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);

    const updateContextBase = (nuevoValor, propiedad, ind) => {
        setExploracionDataContext((prev) => ({
            ...prev,
            med_motor_patologias: prev.med_motor_patologias.map((item, index) => {
                if (index === ind) {
                    return {
                        ...item,
                        [propiedad]: nuevoValor
                    };
                }
                return item;
            })
        }));
    };

    const [Patologias, setPatologias] = useState([
        { id: 49, Nom_Patologia: "Cervical" },
        { id: 50, Nom_Patologia: "Dorsal" },
        { id: 51, Nom_Patologia: "Lumbar" },

        { id: 54, Nom_Patologia: "Cervical - Flexión" },
        { id: 55, Nom_Patologia: "Cervical - Extensión" },
        { id: 56, Nom_Patologia: "Cervical - Laterización Derecho" },
        { id: 57, Nom_Patologia: "Cervical - Laterización Izquierdo" },
        { id: 58, Nom_Patologia: "Cervical - Rotación Derecho" },
        { id: 59, Nom_Patologia: "Cervical - Rotación Izquierda" },
        { id: 60, Nom_Patologia: "Cervical - Irradiación" },
        { id: 61, Nom_Patologia: "Lumbar - Flexión" },
        { id: 62, Nom_Patologia: "Lumbar - Extensión" },
        { id: 63, Nom_Patologia: "Lumbar - Laterización Derecho" },
        { id: 64, Nom_Patologia: "Lumbar - Laterización Izquierdo" },
        { id: 65, Nom_Patologia: "Lumbar - Rotación Derecho" },
        { id: 66, Nom_Patologia: "Lumbar - Rotación Izquierda" },
        { id: 67, Nom_Patologia: "Lumbar - Irradiación" },

        { id: 68, Nom_Patologia: "Lasègue Derecho" },
        { id: 69, Nom_Patologia: "Lasègue Izquierdo" },
        { id: 70, Nom_Patologia: "Schover" },

        { id: 71, Nom_Patologia: "Cervical - Apófisis Espinosas Dolor" },
        { id: 72, Nom_Patologia: "Cervical - Contractura Muscular" },
        { id: 73, Nom_Patologia: "Dorsal - Apófisis Espinosas Dolor" },
        { id: 74, Nom_Patologia: "Dorsal - Contractura Muscular" },
        { id: 75, Nom_Patologia: "Lumbar - Apófisis Espinosas Dolor" },
        { id: 76, Nom_Patologia: "Lumbar - Contractura Muscular" },



        { id: 77, Nom_Patologia: 'Hombro - Abducción Derecho' },
        { id: 78, Nom_Patologia: "Hombro - Abducción Izquierdo" },
        { id: 79, Nom_Patologia: "Hombro - Flexión Derecho" },
        { id: 80, Nom_Patologia: "Hombro - Flexión Izquierdo" },

        { id: 81, Nom_Patologia: "Hombro - Extensión Derecho" },
        { id: 82, Nom_Patologia: "Hombro - Extensión Izquierdo" },
        { id: 83, Nom_Patologia: "Hombro - Rotación Externa Derecho" },
        { id: 84, Nom_Patologia: "Hombro - Rotación Externa Izquierdo" },
        { id: 85, Nom_Patologia: "Hombro - Rotación Interna Derecho" },
        { id: 86, Nom_Patologia: "Hombro - Rotación Interna Izquierdo" },
        { id: 87, Nom_Patologia: "Hombro - Irradiación Derecho" },
        { id: 88, Nom_Patologia: "Hombro - Irradiación Izquierdo" },
        { id: 89, Nom_Patologia: "Hombro - ALT. Masa Muscular Derecho" },
        { id: 90, Nom_Patologia: "Hombro - ALT. Masa Muscular Izquierdo" },

        { id: 91, Nom_Patologia: "Codo - Abducción Derecho" },
        { id: 92, Nom_Patologia: "Codo - Abducción Izquierdo" },
        { id: 93, Nom_Patologia: "Codo - Flexión Derecho" },
        { id: 94, Nom_Patologia: "Codo - Flexión Izquierdo" },
        { id: 95, Nom_Patologia: "Codo - Extensión Derecho" },
        { id: 96, Nom_Patologia: "Codo - Extensión Izquierdo" },
        { id: 97, Nom_Patologia: "Codo - Rotación Externa Derecho" },
        { id: 98, Nom_Patologia: "Codo - Rotación Externa Izquierdo" },
        { id: 99, Nom_Patologia: "Codo - Rotación Interna Derecho" },
        { id: 100, Nom_Patologia: "Codo - Rotación Interna Izquierdo" },

        { id: 101, Nom_Patologia: "Codo - Irradiación Derecho" },
        { id: 102, Nom_Patologia: "Codo - Irradiación Izquierdo" },
        { id: 103, Nom_Patologia: "Codo - ALT. Masa Muscular Derecho" },
        { id: 104, Nom_Patologia: "Codo - ALT. Masa Muscular Izquierdo" },
        { id: 105, Nom_Patologia: "Muñeca - Abducción Derecho" },
        { id: 106, Nom_Patologia: "Muñeca - Abducción Izquierdo" },
        { id: 107, Nom_Patologia: "Muñeca - Flexión Derecho" },
        { id: 108, Nom_Patologia: "Muñeca - Flexión Izquierdo" },
        { id: 109, Nom_Patologia: "Muñeca - Extensión Derecho" },
        { id: 110, Nom_Patologia: "Muñeca - Extensión Izquierdo" },

        { id: 111, Nom_Patologia: "Muñeca - Rotación Externa Derecho" },
        { id: 112, Nom_Patologia: "Muñeca - Rotación Externa Izquierdo" },
        { id: 113, Nom_Patologia: "Muñeca - Rotación Interna Derecho" },
        { id: 114, Nom_Patologia: "Muñeca - Rotación Interna Izquierdo" },
        { id: 115, Nom_Patologia: "Muñeca - Irradiación Derecho" },
        { id: 116, Nom_Patologia: "Muñeca - Irradiación Izquierdo" },
        { id: 117, Nom_Patologia: "Muñeca - ALT. Masa Muscular Derecho" },
        { id: 118, Nom_Patologia: "Muñeca - ALT. Masa Muscular Izquierdo" },
        { id: 119, Nom_Patologia: "Cadera - Abducción Derecho" },
        { id: 120, Nom_Patologia: "Cadera - Abducción Izquierdo" },

        { id: 121, Nom_Patologia: "Cadera - Flexión Derecho" },
        { id: 122, Nom_Patologia: "Cadera - Flexión Izquierdo" },
        { id: 123, Nom_Patologia: "Cadera - Extensión Derecho" },
        { id: 124, Nom_Patologia: "Cadera - Extensión Izquierdo" },
        { id: 125, Nom_Patologia: "Cadera - Rotación Externa Derecho" },
        { id: 126, Nom_Patologia: "Cadera - Rotación Externa Izquierdo" },
        { id: 127, Nom_Patologia: "Cadera - Rotación Interna Derecho" },
        { id: 128, Nom_Patologia: "Cadera - Rotación Interna Izquierdo" },
        { id: 129, Nom_Patologia: "Cadera - Irradiación Derecho" },
        { id: 130, Nom_Patologia: "Cadera - Irradiación Izquierdo" },

        { id: 131, Nom_Patologia: "Cadera - ALT. Masa Muscular Derecho" },
        { id: 132, Nom_Patologia: "Cadera - ALT. Masa Muscular Izquierdo" },
        { id: 133, Nom_Patologia: "Rodillo - Abducción Derecho" },
        { id: 134, Nom_Patologia: "Rodillo - Abducción Izquierdo" },
        { id: 135, Nom_Patologia: "Rodillo - Flexión Derecho" },
        { id: 136, Nom_Patologia: "Rodillo - Flexión Izquierdo" },
        { id: 137, Nom_Patologia: "Rodillo - Extensión Derecho" },
        { id: 138, Nom_Patologia: "Rodillo - Extensión Izquierdo" },
        { id: 139, Nom_Patologia: "Rodillo - Rotación Externa Derecho" },
        { id: 140, Nom_Patologia: "Rodillo - Rotación Externa Izquierdo" },

        { id: 141, Nom_Patologia: "Rodillo - Rotación Interna Derecho" },
        { id: 142, Nom_Patologia: "Rodillo - Rotación Interna Izquierdo" },
        { id: 143, Nom_Patologia: "Rodillo - Irradiación Derecho" },
        { id: 144, Nom_Patologia: "Rodillo - Irradiación Izquierdo" },
        { id: 145, Nom_Patologia: "Rodillo - ALT. Masa Muscular Derecho" },
        { id: 146, Nom_Patologia: "Rodillo - ALT. Masa Muscular Izquierdo" },
        { id: 147, Nom_Patologia: "Tobillo - Abducción Derecho" },
        { id: 148, Nom_Patologia: "Tobillo - Abducción Izquierdo" },
        { id: 149, Nom_Patologia: "Tobillo - Flexión Derecho" },
        { id: 150, Nom_Patologia: "Tobillo - Flexión Izquierdo" },

        { id: 151, Nom_Patologia: "Tobillo - Extensión Derecho" },
        { id: 152, Nom_Patologia: "Tobillo - Extensión Izquierdo" },
        { id: 153, Nom_Patologia: "Tobillo - Rotación Externa Derecho" },
        { id: 154, Nom_Patologia: "Tobillo - Rotación Externa Izquierdo" },
        { id: 155, Nom_Patologia: "Tobillo - Rotación Interna Derecho" },
        { id: 156, Nom_Patologia: "Tobillo - Rotación Interna Izquierdo" },
        { id: 157, Nom_Patologia: "Tobillo - Irradiación Derecho" },
        { id: 158, Nom_Patologia: "Tobillo - Irradiación Izquierdo" },
        { id: 159, Nom_Patologia: "Tobillo - ALT. Masa Muscular Derecho" },
        { id: 160, Nom_Patologia: "Tobillo - ALT. Masa Muscular Izquierdo" },




        { id: 161, Nom_Patologia: "Test de Phalen Derecho" },
        { id: 162, Nom_Patologia: "Test de Phalen Izquierdo" },
        { id: 163, Nom_Patologia: "Test de Thinel Derecho" },
        { id: 164, Nom_Patologia: "Test de Thinel Izquierdo" },
        { id: 165, Nom_Patologia: "Pie Derecho" },
        { id: 166, Nom_Patologia: "Pie Izquierdo" },



        { id: 167, Nom_Patologia: "Maniobra de Barre" },
        { id: 168, Nom_Patologia: "Maniobra de Mingazini" },
        { id: 169, Nom_Patologia: "Movimiento Involuntario" },
        { id: 170, Nom_Patologia: "Atrofia Muscular" },
        { id: 171, Nom_Patologia: "Asimitría Muscular" },
        { id: 172, Nom_Patologia: "Hipotonía Muscular" },
        { id: 173, Nom_Patologia: "Hipertonía Muscular" },
        { id: 174, Nom_Patologia: "Fuerza Muscular" },
    ])
    const OpcionesSistemaMotor = [
        { name: 'Normal', code: 'N' },
        { name: 'Anormal', code: 'A' }
    ];

    return (
        //Contenedor
        <div className="cardPalpacionContenido">
            <h2 className="cardPalpacionTitulo">
                SISTEMA MOTOR
            </h2>
            <table className="cardPalpacionTabla pl-4 pr-4 pb-4" >
                <thead>
                    <tr>
                        <th className="cardPalpacionTablaTitulo" style={{ width: "250px", textAlign: 'center' }}> Patología</th>
                        <th className="cardPalpacionTablaTitulo" style={{ textAlign: 'center' }}>Resultado</th>
                        <th className="cardPalpacionTablaTitulo" style={{ textAlign: 'center' }}>Observación</th>
                    </tr>
                </thead>
                <tbody className="pb-2">
                    {/* {cardComponents} */}
                    {exploracionDataContext.med_motor_patologias.map((item, index) => {
                        let namePatologia = "";
                        Patologias.forEach((key) => {
                            if (key.id === item.patologia_id) {
                                namePatologia = key.Nom_Patologia;
                            }
                        });

                        return (
                            <CardMedicinaGeneral
                                key={index}
                                context={exploracionDataContext}
                                tituloSeccion={`${index + 1}.- ${namePatologia}`}
                                PadreContext={exploracionDataContext?.med_motor_patologias[index]}
                                updateContext={updateContextBase}
                                index={index}
                                opciones={OpcionesSistemaMotor}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div >
    );
}



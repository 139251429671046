import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'primereact/treeselect';
import AuthUser from '../../../../AuthUser';

const MultiLevelTreeSelectWithAPI = ({ setSelectedTreeSelectId, setSelectedService, selectedService, addClassName, addfuncion }) => {
    const { http } = AuthUser();
    const [groupedServices, setGroupedServices] = useState([]);

    useEffect(() => {
        getServicios();
    }, []);

    const getServicios = () => {
        http
            .get('/bregma/servicio/get')
            .then(response => {
                const processedData = response.data.data.map(item => ({
                    key: item.id.toString(),
                    label: item.nombre,
                    data: item.nombre,
                    icon: '', // Puedes ajustar esto según tus necesidades
                    children: item.children.map(subItem => ({
                        key: subItem.id.toString(),
                        label: subItem.nombre,
                        data: subItem.nombre,
                        icon: '', // Puedes ajustar esto según tus necesidades
                        children: subItem.children.map(subSubItem => ({
                            key: subSubItem.id.toString(),
                            label: subSubItem.nombre,
                            data: subSubItem.nombre,
                            icon: '' // Puedes ajustar esto según tus necesidades
                        }))
                    }))
                }));
                setGroupedServices(processedData);
            })
            .catch(error => {
                // Mostrar una notificación o mensaje de error
                console.error('Error fetching services:', error);
            });
    };

    const handleNodeSelect = event => {
        setSelectedService(event.value);
        setSelectedTreeSelectId(event.value);
        if (addfuncion) {
            addfuncion(true);
        }
    };
    

    return (
        <TreeSelect
            value={selectedService}
            options={groupedServices}
            selectionMode="single"
            placeholder="Tipo de Servicio"
            onChange={handleNodeSelect}
            className={"w-full flex flex-row align-items-center " + (addClassName ? addClassName : '')}
            style={{transition:'0.3s'}}
        />

    );
};

export default MultiLevelTreeSelectWithAPI;

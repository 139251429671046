import React, { useState, useContext, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import DialogCrearEvaluadores from "./DialogCrearEvaluadores";
import DialogEditarEvaluadores from "./DialogEditarEvaluadores";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { faL } from "@fortawesome/free-solid-svg-icons";
import { InputText } from "primereact/inputtext";
import AuthUser from "../../../../AuthUser";
import { Toast } from "primereact/toast";
import axios from 'axios';
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";


function PageListaEvaluadores() {
   const dispatch = useDispatch();
   const { http } = AuthUser()
   const toast = useRef(null);
   const [visible3, setVisible3] = useState(false);
   // const [visibleEdAr, setvisibleEdAr] = useState(false);
   const [rowData, setRowData] = useState();
   const [DataEnviar, setDataEnviar] = useState();
   const [VisibleCrear, setVisibleCrear] = useState(false);
   const [VisibleEditar, setVisibleEditar] = useState(false);
   const [ListaMedEv, setListaMedEv] = useState([]);
   const [especialistas, setEspecialistas] = useState([]);
   const [tiposEspecialistas, setTiposEspecialistas] = useState([]);
   const [labEspecialistas, setLabEspecialistas] = useState([])

   const [evaluadores, setEvaluadores] = useState([]);

   useEffect(() => {
      // Llamada a la API para obtener los datos de evaluadores
      axios.get('https://apidev.bregma.com.pe/public/api/clinica/recursos_humanos/evaluador/get')
         .then(response => {
            setEvaluadores(response?.data);
         })
         .catch(error => {
            console.error('Error fetching evaluadores:', error);
         });
   }, []);


   const showToast = (type, title, detail) => {
      if (toast.current) {
         toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
         });
      } else {
         console.error("Toast no inicializado");
      }
   };

   // OBTENER DATOS DEL EVALUADOR (LISTAR)
   const getEvaluadores = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get("clinica/recursos_humanos/evaluador/get");

         if (response?.data?.resp || response?.data?.data) {
            setListaMedEv(response.data.data)
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
      // http
      //    .get("clinica/recursos_humanos/evaluador/get")
      //    .then((response) => {
      //       if (response.data.data) {
      //          setListaMedEv(response.data.data)
      //       }
      //    })
      //    .catch((error) => {
      //       console.error(error);
      //    })
   }

   // CREAR DATOS DEL EVALUADOR (LISTAR)
   const createEvaluador = async (data, funcionClean) => {

      dispatch(startLoading());

      try {
         const response = await http.post("clinica/recursos_humanos/evaluador/create", data);

         if (response?.data?.resp) {
            getEvaluadores();
            setVisibleCrear(false)
            funcionClean()
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
      // http
      //    .post("clinica/recursos_humanos/evaluador/create", data)
      //    .then((response) => {
      //       
      //       if (response.data.resp) {
      //          getEvaluadores();
      //          setVisibleCrear(false)
      //          funcionClean()
      //       }
      //    })
      //    .catch((error) => {
      //       console.error(error);
      //    })
   }
   const updateEvaluador = async (id, data, funcionClean) => {

      dispatch(startLoading());

      try {
         const response = await http.post(`clinica/recursos_humanos/evaluador/update/${id}`, data);

         if (response?.data?.resp) {
            getEvaluadores();
            setVisibleEditar(false);
            funcionClean();
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
      // http
      //    .post(`clinica/recursos_humanos/evaluador/update/${id}`, data)
      //    .then((response) => {
      //       
      //       if (response.data.resp) {
      //          getEvaluadores();
      //          setVisibleEditar(false);
      //          funcionClean();
      //       }
      //    })
      //    .catch((error) => {
      //       console.error(error);
      //    });
   };





   const ConfirmarEliminar = async () => {

      dispatch(startLoading());

      try {
         const response = await http.delete(`clinica/recursos_humanos/evaluador/delete/${rowData.id}`);

         if (response?.data?.resp) {
            showToast(
               "success",
               `Eliminado correctamente`,
               response.data.resp
            );
            getEvaluadores();
            setVisible3(false)
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   }

   // CREAR DATOS DEL EVALUADOR (LISTAR)
   const getdatosTipoEspecialistas = async (data) => {

      dispatch(startLoading());

      try {
         const response = await http.get("clinica/recursos_humanos/evaluador/tipo_especialistas");
         if (response?.data?.resp || response?.data?.data) {
            setEspecialistas(response.data.resp ? response.data.resp.especialistas : response.data.data.especialistas)
            setTiposEspecialistas(response.data.resp ? response.data.resp.tipos_especialistas : response.data.data.tipos_especialistas)
            setLabEspecialistas(response.data.resp ? response.data.resp.lab_especialistas : response.data.data.lab_especialistas)

         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
      // http
      //    .get("clinica/recursos_humanos/evaluador/tipo_especialistas")
      //    .then((response) => {
      //       if (response.data.resp) {
      //          setEspecialistas(response.data.resp.especialistas)
      //          setTiposEspecialistas(response.data.resp.tipos_especialistas)
      //          setLabEspecialistas(response.data.resp.lab_especialistas)
      //       }
      //    })
      //    .catch((error) => {
      //       console.error(error);
      //    })
   }

   useEffect(() => {
      getEvaluadores();
      getdatosTipoEspecialistas();
   }, []);

   const options = [
      { name: "Médico Ocupacional", code: 1 },
      { name: "Médico Auditor", code: 2 },
      { name: "Médico Especialista", code: 3 },
      { name: "Odontología", code: 4 },
      { name: "Licenciado de Psicología", code: 5 },
      { name: "Laboratorio", code: 6 },
   ];

   //LISTAR EN LA TABLA
   let especialista = []
   const ListarListaMedEv = ListaMedEv?.map((elemento, indexelemento) => {
      if (elemento.tipo_especialista_id) {
         options.map((profesion, index) => {
            if (elemento.tipo_especialista_id === profesion.code) {
               especialista = profesion.name;
               return especialista;
            }
            return null;
         });
         return { ...elemento, item: indexelemento + 1, especialista: especialista };
      }
      return null;

   });

   //EDITAR EVLAUADOR
   const EditarEvaluador = (rowData) => {
      setVisibleEditar(true);
      setRowData(rowData);
   }
   //ELIMINAR EVALUADOR
   const EliminarEvaluador = (rowData) => {
      setVisible3(true);
      setRowData(rowData);
   }



   const dialogHeaderEliminar = () => {
      return (
         <div>
            <i
               className="font-bold pi pi-trash text-4xl"
               style={{ color: "#5555D8" }}
            >
               <label
                  style={{
                     color: "#5555D8",
                     fontStyle: "normal",
                     fontFamily: "Montserrat",
                     fontSize: "20px"
                  }}
               >
                  Eliminar
               </label>
            </i>
         </div>
      );
   };
   const dialogFooterEliminar = () => {
      return (
         <div className="flex justify-content-between">
            <Button
               type="button"
               label="Cancelar"
               className="delete w-6"
               onClick={() => {
                  setVisible3(false);
               }}
            />
            <Button
               label="Eliminar"
               className="p-button-success w-6"
               style={{
                  color: "#5555D8",
                  backgroundColor: "#EBEBFF",
                  borderColor: "#5555D8",
               }}
               onClick={() => ConfirmarEliminar()}
            />
         </div>
      );
   };


   return (
      <>
         <Dialog
            header={dialogHeaderEliminar}
            footer={dialogFooterEliminar}
            resizable={false}
            visible={visible3}
            onHide={() => setVisible3(false)}
         >
            <div style={{ margin: "25px", textAlign: "center" }}>
               <h3>¿Estás seguro que deseas elimniar evaluador? </h3>
            </div>
         </Dialog>
         <div className="table1 flex w-full flex-wrap tabla-rol flex-row">
            <div className="section-one flex flex-column flex-1">
               <h1 className="tit">Lista de evaluadores</h1>
               <p className="con">Gestiona tus evaluadores</p>
            </div>
         </div>
         <div className="aten-section w-full">
            <div className="aten-areas w-full flex align-items-center justify-content-between mt-5 mb-5">
               <Button
                  label="Crear Evaluador"
                  icon="pi pi-plus"
                  severity="success"
                  onClick={() => setVisibleCrear(true)}
               />
               <InputText
                  placeholder="Ingrese una búsqueda"
                  style={{ width: "400px" }}
               />
               <DialogCrearEvaluadores
                  visible={VisibleCrear}
                  setvisible={setVisibleCrear}
                  DataEnviar={DataEnviar}
                  setDataEnviar={setDataEnviar}
                  GetEvaluador={getEvaluadores}
                  createEvaluador={createEvaluador}
                  titulo="Crear evaluador"
                  tiposEspecialistas={tiposEspecialistas}
                  especialistas={especialistas}
                  labEspecialistas={labEspecialistas}
               />
            </div>
         </div>
         <DataTable
            value={ListarListaMedEv}
            className="tabla-proforma mt-5"
            style={{ border: "1px solid #ccc" }}
         >
            <Column
               field="item"
               header="Item"
               className="item"
               style={{
                  textAlign: "center",
                  width: "5%",
                  fontFamily: "Montserrat",
               }}
            ></Column>
            <Column
               field="nombres"
               header="Nombre"
               className=""
               style={{
                  width: "10%",
                  whiteSpace: "wrap",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  color: "#393838",
                  fontWeight: "500",
               }}
            ></Column>
            <Column
               field="apellidos"
               header="Apellido"
               className="apellido"
               style={{
                  width: "15%",
                  whiteSpace: "wrap",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  color: "#393838",
                  fontWeight: "500",
               }}
            ></Column>
            <Column
               field="especialista"
               header="Profesión"
               className="profesion"
               style={{
                  width: "15%",
                  whiteSpace: "wrap",
                  textAlign: "center",
                  fontFamily: "Montserrat",
                  color: "#393838",
                  fontWeight: "500",
               }}
            ></Column>
            <Column
               field="firma"
               header="Firma"
               body={(rowData) => (
                  <div className="btn-container-firma">
                     <button
                        style={{
                           color: '#2229D1',
                           background: '#9B9FFA',
                           border: '0px solid',
                           padding: '0.75rem 1.25rem',
                           fontSize: '1rem',
                           transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
                           borderRadius: '6px',
                           margin: '25px',
                           textAlign: 'center',
                           width: '150px',
                           fontWeight: 'bold'

                        }}
                     >
                        {rowData.imagen_firma ? 'Agregado' : 'No Agregado'}
                     </button>
                  </div>
               )}
               className=""
               style={{
                  width: '10%',
                  whiteSpace: 'wrap',
                  textAlign: 'center',
                  fontFamily: 'Montserrat',
                  fontSize: '16px',
                  color: '#393838',
                  fontWeight: '500',
               }}
            />
            <Column
               header="Acciones"
               body={(rowData) => (
                  <div className="btn-container">
                     <Button
                        icon="pi pi-pencil text-green-700 text-xl"
                        className=" bg-green-200 border-0"
                        onClick={() => EditarEvaluador(rowData)}
                        style={{ marginLeft: "15px" }}
                     />
                     <Button
                        icon="pi pi-trash text-red-500 text-xl"
                        className="bg-red-100  border-0"
                        onClick={() => EliminarEvaluador(rowData)}
                     />
                  </div>
               )}
               style={{
                  width: "15%",
                  whiteSpace: "wrap",
                  textAlign: "center",
                  fontFamily: "Montserrat",
               }}
            ></Column>
         </DataTable>
         <DialogCrearEvaluadores
            setvisible={setVisibleEditar}
            visible={VisibleEditar}
            DataEnviar={DataEnviar}
            setDataEnviar={setDataEnviar}
            GetEvaluador={getEvaluadores}
            titulo="Editar evaluador"
            updateEvaluador={updateEvaluador}
            rowData={rowData}
            tiposEspecialistas={tiposEspecialistas}
            especialistas={especialistas}
            labEspecialistas={labEspecialistas}
         />
         <Toast ref={toast} />
      </>
   );
}
export default PageListaEvaluadores;
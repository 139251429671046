import AuthUser from "../../../../../AuthUser";
import { useDispatch } from "react-redux";
import { startLoading, endLoading} from '../../../../../Redux/isLoadingSlice'

const SB3Product = () => {
    const { http } = AuthUser();
    const dispatch = useDispatch();

    const postPackage = async (contrato_id, data, showToast, setEstadoEnvio) => {
        dispatch(startLoading())
        await http.post(`bregma/asignar/paquete/${contrato_id}`, data)
        .then(async (response) => {
            if (response.data.resp) {
                await Promise.all([
                    setEstadoEnvio(true)
                ])
                showToast("success", "Completado", response.data.resp);
            } else if (response.data.error) {
                showToast("error", "Error", response.data.error);
            }
        }).catch(async (error) => {
            console.error(error);
        }).finally(async () => {
            dispatch(endLoading())
        });
    };

    return {
        postPackage,
    };
};

export default SB3Product;
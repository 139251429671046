import React, { useContext, useEffect, useState } from 'react';
import { RAContext } from "../../RutaAtencion/RAContext";
import { OftContext } from "../OftContext";
import { Dropdown } from "primereact/dropdown";
import { iconOft } from "../../../Services/importIcons";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import '../../../Style/ref_visual.css'
import './PageRef.css';
import { Input } from '@mui/material';

const PageRef = () => {
   let { refraccion, setRefraccion } = useContext(OftContext);
   let { pageViewContext, stateAtencion } = useContext(RAContext)

   const [LejOjoDerShp, setLejOjoDerShp] = useState(refraccion?.ojo_derecho_refraccion_lejos?.shp ?? null);
   const [LejOjoDerCyl, setLejOjoDerCyl] = useState(refraccion?.ojo_derecho_refraccion_lejos?.cyl ?? null);
   const [LejOjoDerEj, setLejOjoDerEj] = useState(refraccion?.ojo_derecho_refraccion_lejos?.ej ?? null);
   const [MedidaDerLej, setMedidaDerLej] = useState(refraccion?.ojo_derecho_refraccion_lejos?.seleccionar ?? null);

   const [LejOjoIzqShp, setLejOjoIzqShp] = useState(refraccion?.ojo_izquierdo_refraccion_lejos?.shp ?? null);
   const [LejOjoIzqCyl, setLejOjoIzqCyl] = useState(refraccion?.ojo_izquierdo_refraccion_lejos?.cyl ?? null);
   const [LejOjoIzqEj, setLejOjoIzqEj] = useState(refraccion?.ojo_izquierdo_refraccion_lejos?.ej ?? null);
   const [MedidaIzqLej, setMedidaIzqLej] = useState(refraccion?.ojo_izquierdo_refraccion_lejos?.seleccionar ?? null);

   const [LejOjoDip, setLejOjoDip] = useState(refraccion?.dip_lejos ?? null);

   const [CerOjoDerShp, setCerOjoDerShp] = useState(refraccion?.ojo_derecho_refraccion_cerca?.shp ?? null);
   const [CerOjoDerCyl, setCerOjoDerCyl] = useState(refraccion?.ojo_derecho_refraccion_cerca?.cyl ?? null);
   const [CerOjoDerEj, setCerOjoDerEj] = useState(refraccion?.ojo_derecho_refraccion_cerca?.ej ?? null);
   const [MedidaDerCer, setMedidaDerCer] = useState(refraccion?.ojo_derecho_refraccion_cerca?.seleccionar ?? null);

   const [CerOjoIzqShp, setCerOjoIzqShp] = useState(refraccion?.ojo_izquierdo_refraccion_cerca?.shp ?? null);
   const [CerOjoIzqCyl, setCerOjoIzqCyl] = useState(refraccion?.ojo_izquierdo_refraccion_cerca?.cyl ?? null);
   const [CerOjoIzqEj, setCerOjoIzqEj] = useState(refraccion?.ojo_izquierdo_refraccion_cerca?.ej ?? null);
   const [MedidaIzqCer, setMedidaIzqCer] = useState(refraccion?.ojo_izquierdo_refraccion_cerca?.seleccionar ?? null);

   const [CerOjoDip, setCerOjoDip] = useState(refraccion?.dip_cerca ?? null);

   useEffect(() => {
      setRefraccion({
         [`ojo_derecho_refraccion_cerca`]: {
            [`shp`]: CerOjoDerShp,
            [`cyl`]: CerOjoDerCyl,
            [`ej`]: CerOjoDerEj,
            [`seleccionar`]: MedidaDerCer
         },
         [`ojo_izquierdo_refraccion_cerca`]: {
            [`shp`]: CerOjoIzqShp,
            [`cyl`]: CerOjoIzqCyl,
            [`ej`]: CerOjoIzqEj,
            [`seleccionar`]: MedidaIzqCer
         },
         [`dip_cerca`]: CerOjoDip,
         [`ojo_derecho_refraccion_lejos`]: {
            [`shp`]: LejOjoDerShp,
            [`cyl`]: LejOjoDerCyl,
            [`ej`]: LejOjoDerEj,
            [`seleccionar`]: MedidaDerLej
         },
         [`ojo_izquierdo_refraccion_lejos`]: {
            [`shp`]: LejOjoIzqShp,
            [`cyl`]: LejOjoIzqCyl,
            [`ej`]: LejOjoIzqEj,
            [`seleccionar`]: MedidaIzqLej
         },
         [`dip_lejos`]: LejOjoDip

      })
   }, [LejOjoDerShp, LejOjoDerCyl, LejOjoDerEj, MedidaDerLej, LejOjoIzqShp, LejOjoIzqCyl, LejOjoIzqEj, MedidaIzqLej, LejOjoDip, CerOjoDerShp,
      CerOjoDerCyl, CerOjoDerEj, MedidaDerCer, CerOjoIzqShp, CerOjoIzqCyl, CerOjoIzqEj, CerOjoDip, MedidaIzqCer])

   const refraccionoptions = [
      { label: 'No usa', id: 1 },
      { label: '20/10', id: 2 },
      { label: '20/13', id: 3 },
      { label: '20/15', id: 4 },
      { label: '20/16', id: 5 },
      { label: '20/20', id: 6 },
      { label: '20/25', id: 7 },
      { label: '20/30', id: 8 },
      { label: '20/32', id: 9 },
      { label: '20/40', id: 10 },
      { label: '20/50', id: 11 },
      { label: '20/60', id: 12 },
      { label: '20/63', id: 13 },
      { label: '20/70', id: 14 },
      { label: '20/80', id: 15 },
      { label: '20/100', id: 16 },
      { label: '20/125', id: 17 },
      { label: '20/150', id: 18 },
      { label: '20/160', id: 19 },
      { label: '20/200', id: 20 },
      { label: '20/250', id: 21 },
      { label: '20/320', id: 22 },
      { label: '20/400', id: 23 },
      { label: '20/500', id: 24 },
      { label: '20/800', id: 25 },
      { label: 'CD', id: 26 },
      { label: 'PL', id: 27 },
      { label: 'Ausente', id: 28 }
   ];

   const [hovered, setHovered] = useState(false);
   const handleMouseEnter = () => { setHovered(true); };
   const handleMouseLeave = () => { setHovered(false) };

   const [hovered01, setHovered01] = useState(false);
   const handleMouseEnter01 = () => { setHovered01(true); };
   const handleMouseLeave01 = () => { setHovered01(false) };
   const classes = `contenedorLejos ${hovered ? 'ClasesHoverMouse' : ''}`;
   const classes01 = `contenedorLejos ${hovered01 ? 'ClasesHoverMouse02' : ''}`;

   return (
      <div>
         <div className="oft_ant_titles" style={{ marginBottom: "20px" }}>
            <div>
               <img src={iconOft} alt="Icon" />
            </div>
            <h3>Refracciones</h3>
         </div>

         <div className='contenedor_Principal_Refracciones'>
            <div className={classes01} onMouseEnter={handleMouseEnter01} onMouseLeave={handleMouseLeave01}>
               <h1 className='tituloGeneralDE_LEJOS'>De Lejos</h1>
               <div className='subcontenedor_LEEJOS relative'>
                  <div className='contenedorUPDe_Lejos' style={{ marginTop: '-80px' }}>
                     <div className='contenedor_Tipo_OJO_Inputs' style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='TituloSection letraDE_LEJOS'>Ojo Der.</div>
                        <div className='InputsSectionDE_LEJOS'>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Shp</h1>
                              <InputNumber value={LejOjoDerShp} onChange={(e) => setLejOjoDerShp(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Cyl</h1>
                              <InputNumber value={LejOjoDerCyl} onChange={(e) => setLejOjoDerCyl(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Ej</h1>
                              <InputNumber value={LejOjoDerEj} onChange={(e) => setLejOjoDerEj(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                        </div>
                     </div>
                     <div className='DrowdownRetraccionSection'>
                        <Dropdown options={refraccionoptions} optionValue='label' value={MedidaDerLej} onChange={(e) => setMedidaDerLej(e.value)} className="d-r" disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                     </div>
                  </div>
                  <div className='contenedorDOWNDe_Lejos'>
                     <div className='contenedor_Tipo_OJO_Inputs' style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='TituloSection letraDE_LEJOS'>Ojo Izq.</div>
                        <div className='InputsSectionDE_LEJOS'>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Shp</h1>
                              <InputNumber value={LejOjoIzqShp} onChange={(e) => setLejOjoIzqShp(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Cyl</h1>
                              <InputNumber value={LejOjoIzqCyl} onChange={(e) => setLejOjoIzqCyl(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Ej</h1>
                              <InputNumber value={LejOjoIzqEj} onChange={(e) => setLejOjoIzqEj(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                        </div>
                     </div>
                     <div className='DrowdownRetraccionSection'>
                        <Dropdown options={refraccionoptions} optionValue='label' value={MedidaIzqLej} onChange={(e) => setMedidaIzqLej(e.value)} className="d-r" disabled={stateAtencion[pageViewContext].estado === 3} />
                     </div>
                  </div>
                  <div className='contenedor_div_contenedor' style={{ marginTop: '-145px' }}>
                     <h1 className="subtituloRefracciones letraDE_LEJOS">Dip</h1>
                     <InputNumber value={LejOjoDip} onChange={(e) => setLejOjoDip(e.value)} className='inputRetraccion w-3' style={{ marginTop: '-0px' }} disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                  </div>
               </div>
            </div>



            <div className={classes} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
               <h1 className='tituloGeneralDE_LEJOS'>De Cerca</h1>
               <div className='subcontenedor_LEEJOS relative'>
                  <div className='contenedorUPDe_Lejos' style={{ marginTop: '-80px' }}>
                     <div className='contenedor_Tipo_OJO_Inputs' style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='TituloSection letraDE_LEJOS'>Ojo Der.</div>
                        <div className='InputsSectionDE_LEJOS'>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Shp</h1>
                              <InputNumber value={CerOjoDerShp} onChange={(e) => setCerOjoDerShp(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Cyl</h1>
                              <InputNumber value={CerOjoDerCyl} onChange={(e) => setCerOjoDerCyl(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Ej</h1>
                              <InputNumber value={CerOjoDerEj} onChange={(e) => setCerOjoDerEj(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                        </div>
                     </div>
                     <div className='DrowdownRetraccionSection'>
                        <Dropdown options={refraccionoptions} optionValue='label' value={MedidaDerCer} onChange={(e) => setMedidaDerCer(e.value)} className="d-r" disabled={stateAtencion[pageViewContext].estado === 3} />

                     </div>
                  </div>
                  <div className='contenedorDOWNDe_Lejos'>
                     <div className='contenedor_Tipo_OJO_Inputs' style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className='TituloSection letraDE_LEJOS'>Ojo Izq.</div>
                        <div className='InputsSectionDE_LEJOS'>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Shp</h1>
                              <InputNumber value={CerOjoIzqShp} onChange={(e) => setCerOjoIzqShp(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Cyl</h1>
                              <InputNumber value={CerOjoIzqCyl} onChange={(e) => setCerOjoIzqCyl(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                           <div className='flex flex-column align-items-center'>
                              <h1 className="subtituloRefracciones letraDE_LEJOS">Ej</h1>
                              <InputNumber value={CerOjoIzqEj} onChange={(e) => setCerOjoIzqEj(e.value)} className='inputRetraccion w-7' disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                           </div>
                        </div>
                     </div>
                     <div className='DrowdownRetraccionSection'>
                        <Dropdown options={refraccionoptions} optionValue='label' value={MedidaIzqCer} onChange={(e) => setMedidaIzqCer(e.value)} className="d-r" disabled={stateAtencion[pageViewContext].estado === 3} />

                     </div>
                  </div>
                  <div className='contenedor_div_contenedor' style={{ marginTop: '-145px' }} >
                     <h1 className="subtituloRefracciones letraDE_LEJOS">Dip</h1>
                     <InputNumber value={CerOjoDip} onChange={(e) => setCerOjoDip(e.value)} className='inputRetraccion w-3' style={{ marginTop: '-0px' }} disabled={stateAtencion[pageViewContext].estado === 3} useGrouping={false} locale="en-US" maxFractionDigits={2} />
                  </div>
               </div>
            </div>

         </div>

      </div >
   );
};

export default PageRef; 

import React from "react";
import { InputText } from 'primereact/inputtext';
import "../../../../../../Style/StyleTablas.css";
const handleFocus = (event) => {
   // Selecciona todo el contenido del input cuando se enfoca
   event.target.select();
};

export default function ComponentTablaViaAerea({ audiometria, setAudiometria, Detector }) {

   const hanbleInputChangeDerecha = (e) => {
      let auxAudiometria = {
         ...audiometria,
         via_aerea: {
            ...audiometria?.via_aerea,
            derecha: {
               ...audiometria?.via_aerea.derecha,
               [e.target.name]: e.target.value
            }
         }
      }
      setAudiometria(auxAudiometria)
      Detector(existeMayor(auxAudiometria.via_aerea.derecha) || existeMayor(auxAudiometria.via_aerea.derecha_enmascarado) || existeMayor(auxAudiometria.via_aerea.izquierda) || existeMayor(auxAudiometria.via_aerea.izquierda_enmascarado))
   }
   
   
   const hanbleInputChangeDerechaEnmascarado = (e) => {
      let auxAudiometria = {
         ...audiometria,
         via_aerea: {
            ...audiometria?.via_aerea,
            derecha_enmascarado: {
               ...audiometria?.via_aerea.derecha_enmascarado,
               [e.target.name]: e.target.value
            }
         }
      }
      setAudiometria(auxAudiometria)
      Detector(existeMayor(auxAudiometria.via_aerea.derecha) || existeMayor(auxAudiometria.via_aerea.derecha_enmascarado) || existeMayor(auxAudiometria.via_aerea.izquierda) || existeMayor(auxAudiometria.via_aerea.izquierda_enmascarado))
   }

   const hanbleInputChangeIzquierda = (e) => {
      let auxAudiometria = {
         ...audiometria,
         via_aerea: {
            ...audiometria?.via_aerea,
            izquierda: {
               ...audiometria?.via_aerea.izquierda,
               [e.target.name]: e.target.value
            }
         }
      }
      setAudiometria(auxAudiometria)
      Detector(existeMayor(auxAudiometria.via_aerea.derecha) || existeMayor(auxAudiometria.via_aerea.derecha_enmascarado) || existeMayor(auxAudiometria.via_aerea.izquierda) || existeMayor(auxAudiometria.via_aerea.izquierda_enmascarado))
   }
   const hanbleInputChangeIzquierdaEnmascarado = (e) => {
      let auxAudiometria = {
         ...audiometria,
         via_aerea: {
            ...audiometria?.via_aerea,
            izquierda_enmascarado: {
               ...audiometria?.via_aerea.izquierda_enmascarado,
               [e.target.name]: e.target.value
            }
         }
      }
      setAudiometria(auxAudiometria)
      Detector(existeMayor(auxAudiometria.via_aerea.derecha) || existeMayor(auxAudiometria.via_aerea.derecha_enmascarado) || existeMayor(auxAudiometria.via_aerea.izquierda) || existeMayor(auxAudiometria.via_aerea.izquierda_enmascarado))
   }

   const existeMayor = (objeto) => {
      for (const clave in objeto) {
         if (objeto[clave] > 31) {
            return true; // Retorna true si al menos uno es mayor que 31
         }
      }
      return false;
   }

   return (
      <div className="Contenedor-Tabla-ViaAerea" style={{marginTop: "-0.88rem"}}>
         <div className="encabezado-derecho">
            <div className="color-encadezado">
               <span>Oido derecho</span>
            </div>
            <div className="OidoDerechotabla">
               <div className="row table-row">
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}></div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>125</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>250</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>500</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>750</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>1000</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>1500</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>2000</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>3000</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>4000</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>6000</div>
                  <div className="col table-row" style={{ color: '#565656', fontSize: '10px' }}>8000</div>
               </div>
               <div className="row table-row">
                  <div className="col table-row"></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText name="v_500" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha.v_500} onChange={hanbleInputChangeDerecha} onFocus={handleFocus} /></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText name="v_1000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.derecha.v_1000} onChange={hanbleInputChangeDerecha} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText name="v_2000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha.v_2000} onChange={hanbleInputChangeDerecha} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_3000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha.v_3000} onChange={hanbleInputChangeDerecha} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_4000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha.v_4000} onChange={hanbleInputChangeDerecha} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_6000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha.v_6000} onChange={hanbleInputChangeDerecha} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_8000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha.v_8000} onChange={hanbleInputChangeDerecha} onFocus={handleFocus}/></div>
               </div>
               <div className="row table-row">
                  <div className="col table-row">Enmascarado</div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText name="v_500" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_500} onChange={hanbleInputChangeDerechaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText name="v_1000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_1000} onChange={hanbleInputChangeDerechaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col table-row"><InputText name="v_2000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_2000} onChange={hanbleInputChangeDerechaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_3000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_3000} onChange={hanbleInputChangeDerechaEnmascarado}onFocus={handleFocus} /></div>
                  <div className="col table-row"><InputText name="v_4000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_4000} onChange={hanbleInputChangeDerechaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_6000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_6000} onChange={hanbleInputChangeDerechaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col table-row"><InputText name="v_8000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.derecha_enmascarado.v_8000} onChange={hanbleInputChangeDerechaEnmascarado} onFocus={handleFocus}/></div>
               </div>
            </div>
         </div>
         <div className="Encabezado-Izquierdo">
            <div className="Color-EncadezadoIzquierdo">
               <span>Oido Izquierdo</span>
            </div>
            <div className="OidoIzquierdotabla">
               <div className="row">
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}></div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>125</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>250</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>500</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>750</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>1000</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>1500</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>2000</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>3000</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>4000</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>6000</div>
                  <div className="col" style={{ color: '#565656', fontSize: '10px' }}>8000</div>
               </div>
               <div className="row">
                  <div className="col"></div>
                  <div className="col"><InputText disabled style={{ width: '65px',  height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText name="v_500" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.izquierda.v_500} onChange={hanbleInputChangeIzquierda} onFocus={handleFocus}/></div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText name="v_1000" style={{ width: '65px' , height:"20px"}} value={audiometria?.via_aerea.izquierda.v_1000} onChange={hanbleInputChangeIzquierda} onFocus={handleFocus}/></div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText name="v_2000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda.v_2000} onChange={hanbleInputChangeIzquierda} onFocus={handleFocus}/></div>
                  <div className="col"><InputText name="v_3000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda.v_3000} onChange={hanbleInputChangeIzquierda}onFocus={handleFocus} /></div>
                  <div className="col"><InputText name="v_4000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda.v_4000} onChange={hanbleInputChangeIzquierda} onFocus={handleFocus}/></div>
                  <div className="col"><InputText name="v_6000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda.v_6000} onChange={hanbleInputChangeIzquierda} onFocus={handleFocus}/></div>
                  <div className="col"><InputText name="v_8000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda.v_8000} onChange={hanbleInputChangeIzquierda}onFocus={handleFocus} /></div>
               </div>
               <div className="row">
                  <div className="col">Enmascarado</div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText name="v_500" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_500} onChange={hanbleInputChangeIzquierdaEnmascarado}onFocus={handleFocus} /></div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText name="v_1000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_1000} onChange={hanbleInputChangeIzquierdaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col"><InputText disabled style={{ width: '65px', height:"20px", backgroundColor: '#ced4da' }} /></div>
                  <div className="col"><InputText name="v_2000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_2000} onChange={hanbleInputChangeIzquierdaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col"><InputText name="v_3000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_3000} onChange={hanbleInputChangeIzquierdaEnmascarado}onFocus={handleFocus} /></div>
                  <div className="col"><InputText name="v_4000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_4000} onChange={hanbleInputChangeIzquierdaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col"><InputText name="v_6000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_6000} onChange={hanbleInputChangeIzquierdaEnmascarado} onFocus={handleFocus}/></div>
                  <div className="col"><InputText name="v_8000" style={{ width: '65px', height:"20px" }} value={audiometria?.via_aerea.izquierda_enmascarado.v_8000} onChange={hanbleInputChangeIzquierdaEnmascarado} onFocus={handleFocus}/></div>
               </div>
            </div>
         </div>
      </div>
   )
}

import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

//Componentes generales
import TablaCrud from "../../Components/Table/TablaCrud";
import AuthUser from "../../AuthUser";

//Componentes únicos
import DialogPDF from "../../Components/HistoriaClinica/DialogPDF";
import InputCalendar from "../../Components/form/InputCalendar";

function PageHistoriaC() {
   const [checkedFecha, setCheckedFecha] = useState(false);
   const [date, setDate] = useState(null);
   const [date1, setDate1] = useState(null);
   const { http } = AuthUser();
   const [paciente, setPaciente] = useState([]);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [visible, setVisible] = useState(false);
   const [documentos, setDocumentos] = useState([])
   const [editDialogVisible, setEditDialogVisible] = useState(false);

   const generateRandomDates = () => {
      const startTimestamp = new Date(2020, 0, 1).getTime();
      const endTimestamp = new Date().getTime();
      const randomTimestamp =
         startTimestamp + Math.random() * (endTimestamp - startTimestamp);
      const randomDate = new Date(randomTimestamp);
      return randomDate.toLocaleDateString();
   };

   const [selectEstablecimiento, setSelectEstablecimiento] = useState(null);
   const optionEstablecimiento = [
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" },
   ];

   const getPersonalHistorial = () => {
      http
         .get("clinica/gestion_atenciones/historial/get")
         .then((response) => {
            if (response.data.data) {
               setPaciente(response.data.data);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const handleImprimir = (rowData) => {
      setVisible(true)
      setDocumentos(rowData.documentos)
   }

   const BotonPDF = (rowData) => {
      return (
         <Button
            style={{
               backgroundColor: "white",
               color: "green",
               border: "1px solid transparent",
            }}
            onClick={() => handleImprimir(rowData)}
         >
            <i className="pi pi-file-pdf" />
         </Button>
      );
   };

   const redirectToURL = (rowData) => {
      if (rowData && rowData.id) {
         // Almacena los datos de rowData en localStorage
         // localStorage.setItem("rowData", JSON.stringify(rowData));

         // Abre una nueva ventana
         window.open(`/getsiondeatenciones/historiaclinica/${rowData.id}`);
      } else {
         console.error("rowData o rowData.id es undefined");
      }
   };

   const BotonEditar = ({ rowData }) => {
      const openEditDialog = () => {
         setEditDialogVisible(true);
         // Almacena los datos de rowData en localStorage
         // localStorage.setItem("rowData", JSON.stringify(rowData));
      };

      return (
         <Button
            style={{ backgroundColor: "green", color: "white", margin: "auto" }}
            onClick={openEditDialog}
         >
            <i className="pi pi-pencil" style={{ color: "white" }} />
         </Button>
      );
   };

   let rowCounter = 0;

   useEffect(() => {
      getPersonalHistorial();
      generateRandomDates();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="flex flex-nowrap flex-column w-full">
         <div className="arriba mb-6">
            <span className="tit">Historia clínica</span>
            <br />
            <span className="con">
               En este módulo usted podrá ver la historia clínica de sus trabajadores
            </span>
         </div>
         <div className="flex flex-wrap flex-column">
            <div className="flex flex-wrap flex-row gap-4 p-3">
               <div className="flex flex-wrap flex-column gap-2">
                  <div className="flex flex-wrap flex-row gap-3">
                     <Checkbox
                        onChange={(e) => setCheckedFecha(e.checked)}
                        checked={checkedFecha}
                     ></Checkbox>
                     <p>Por fecha</p>
                  </div>
                  <div className="flex flex-wrap flex-row gap-3">
                     <InputCalendar
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        showIcon
                        disabled={!checkedFecha}
                     />
                     <InputCalendar
                        value={date1}
                        onChange={(e) => setDate1(e.value)}
                        showIcon
                        disabled={!checkedFecha}
                     />
                  </div>
               </div>
               <div className="flex flex-wrap flex-column gap-3 flex-1">
                  <div>
                     <p className="">Establecimiento</p>
                  </div>

                  <div className="card flex justify-content-center">
                     <Dropdown
                        value={selectEstablecimiento}
                        onChange={(e) => setSelectEstablecimiento(e.value)}
                        options={optionEstablecimiento}
                        optionLabel="name"
                        placeholder="Seleccione"
                        className="w-full"
                     />
                  </div>
               </div>

               <div className="flex flex-wrap flex-column gap-3 flex-1">
                  <div>
                     <p className="">Servicio</p>
                  </div>

                  <div className="card flex justify-content-center">
                     <Dropdown
                        value={selectEstablecimiento}
                        onChange={(e) => setSelectEstablecimiento(e.value)}
                        options={optionEstablecimiento}
                        optionLabel="name"
                        placeholder="Seleccione"
                        className="w-12"
                     />
                  </div>
               </div>
               <div
                  className="container-setContainers"
                  style={{ display: "flex" }}
               >
                  <div className="flex flex-wrap gap-3 flex-1">
                     <div>
                        <p style={{ marginBottom: "15px" }}>
                           Tipo de evaluaciones
                        </p>
                        <div
                           className="card flex justify-content-center"
                           style={{ width: "350px" }}
                        >
                           <Dropdown
                              value={selectEstablecimiento}
                              onChange={(e) =>
                                 setSelectEstablecimiento(e.value)
                              }
                              options={optionEstablecimiento}
                              optionLabel="name"
                              placeholder="Seleccione"
                              className="w-full"
                           />
                        </div>
                     </div>
                     <div>
                        <div>
                           <p style={{ marginBottom: "15px" }}>Empresa</p>
                           <div
                              className="card flex justify-content-center"
                              style={{ width: "350px" }}
                           >
                              <Dropdown
                                 value={selectEstablecimiento}
                                 onChange={(e) =>
                                    setSelectEstablecimiento(e.value)
                                 }
                                 options={optionEstablecimiento}
                                 optionLabel="name"
                                 placeholder="Seleccione"
                                 className="w-full"
                              />
                           </div>
                        </div>
                     </div>
                     <div
                        className="flex flex-wrap align-items-end"
                        style={{
                           marginBottom: "15px",
                           marginRight: "10px",
                           float: "right",
                        }}
                     >
                        <Button label="Filtrar" style={{ width: "200px" }} />
                     </div>
                  </div>
               </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div>
               <TablaCrud
                  name="Historia Clínica"
                  tableNombre={`Lista de historia clínica`}
                  descripcion={`A continuación, se visualiza la lista de historia clínica.`}
                  onInputSearch={(e) => setGlobalFilter(e.target.value)}
                  valueGlobalFilter={globalFilter}
                  value={paciente}
               >
                  <Column
                     field={"id"}
                     body={() => {
                        rowCounter += 1;
                        return rowCounter;
                     }}
                     header="ID"
                  ></Column>
                  <Column
                     field={"tipo_documento.nombre"}
                     header="Tip_doc"
                  ></Column>
                  <Column
                     field={"numero_documento"}
                     header="Nro. documento"
                  ></Column>
                  <Column
                     // body={(rowData) =>
                     //    `${rowData.persona.apellido_paterno} ${rowData.persona.apellido_materno} ${rowData.persona.nombres}`
                     // }
                     field="nombres_completos"
                     header="Nombres"
                  ></Column>
                  <Column
                     field="fecha"
                     // body={() => generateRandomDates()}
                     header="Fecha"
                  ></Column>
                  <Column
                     // body={() => getRandomProfile()}
                     field={"perfil.nombre"}
                     header="Perfil"
                  ></Column>
                  <Column body="Apto" header="Resultado"></Column>
                  <Column
                     field={""}
                     body={BotonPDF}
                     header="Informe"
                     style={{ textAlign: "center" }}
                  ></Column>
                  <Column
                     field={""}
                     body={(rowData) => <BotonEditar rowData={rowData} />}
                     header="Editar"
                     style={{ textAlign: "center" }}
                  ></Column>
               </TablaCrud>
            </div>
         </div>
         <DialogPDF documentos={documentos} prop01={visible} prop02={setVisible} />
         <Dialog
            visible={editDialogVisible}
            style={{ width: "550px", height: "25vh" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            modal
            className="p-fluid"
            header={
               <span
                  style={{
                     color: "red",
                     fontSize: "2rem",
                     textAlign: "center",
                  }}
               >
                  Editar está bajo responsabilidad de la clínica
               </span>
            }
            onHide={() => setEditDialogVisible(false)}
         >
            <div
               style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1.5rem",
               }}
            >
               <Button
                  label="Continuar"
                  style={{ width: "200px", marginRight: "10px" }}
                  onClick={() => {
                     setEditDialogVisible(false);
                     redirectToURL(JSON.parse(localStorage.getItem("rowData")));
                  }}
               />
               <Button
                  label="Cancelar"
                  style={{ width: "200px" }}
                  onClick={() => setEditDialogVisible(false)}
               />
            </div>
         </Dialog>
      </div>
   );
}

export default PageHistoriaC;

import React from "react";
import AreasGenerales from './AreasGenerales';


const CardListasGenerales = ({ NombreGerente, setNombreGerente, personalGeneral, handleCrearGerente, NombreGerenteBD, HandleGetGerente }) => {
    return (
        <div className="flex flex-column pl-3 pr-3 pt-3 w-full h-full cardlistagenerales relative">
            <div className="flex flex-wrap align-content-start w-full" >
                <p className="flex text-base  lista font-semibold ">Jefe en local</p>
            </div>
            <AreasGenerales
                NombreGerente={NombreGerente}
                setNombreGerente={setNombreGerente}
                personalGeneral={personalGeneral}
                handleCrearGerente={handleCrearGerente}
                NombreGerenteBD={NombreGerenteBD}
                HandleGetGerente={HandleGetGerente}
            />
        </div>
    );
}
export default CardListasGenerales;
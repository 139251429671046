import { useState } from 'react'
import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import LineChart from './LineChart';
import CartaDeControl2 from './CartaDeControl2';
import "./EstadisticaMedica.css";
import { Dropdown } from 'primereact/dropdown';
import InputCalendar from "../../../../Components/form/InputCalendar";
function PageEstadisticaMedica() {

    //Los elementos del tap
    const elementos = [
        { label: 'Atencion1', value: 'Atencion1' },
        { label: 'Atencion2', value: 'Atencion2' }
    ]

    //Seleccion de atencion medica
    const [selectedAtencionMedic, setSelectedAtencionMedic] = useState(null);
    const selectAtencion = [
        { name: 'Triaje', code: 'TR' },
        { name: 'Psicología', code: 'PSI' },
        { name: 'EKG', code: 'EKG' },
        { name: 'Oftamología', code: 'OFT' },
        { name: 'Audiometría', code: 'AUD' },
        { name: 'Radiología', code: 'RDG' }
    ];

    //Seleccion de empresa
    const [selectedCompany, setSelectedCompany] = useState(null);
    const selectCompany = [
        { nameCompany: 'Clínica', code: 'CLI' },
        { nameCompany: 'Corporativo', code: 'COR' },
        { nameCompany: 'Particular', code: 'PAR' }
    ];

    //Rango de fecha
    const [dateStart, setDate] = useState(null);
    const [dateFinally, setDates] = useState(null);


    return (
        <div>
            <div className='arriba'>
                <span className='tit'>Atención Médica</span><br />
            </div>
            <div className="card">
                <TabView>
                    <TabPanel header="Carta de Control">
                        <h2 className='title-atencion'>Carta de Control</h2>
                        <div className='atencion-medica-carta-de-control'>
                            <div className='select-atencion one'>
                                <p className="font-atencion">Atención Médica</p>
                                <Dropdown value={selectedAtencionMedic} onChange={(e) => setSelectedAtencionMedic(e.value)} options={selectAtencion} optionLabel="name"
                                    placeholder="Seleccione la atención médica" className="w-full md:w-18rem" />
                            </div>
                            <div className='select-atencion'>
                                <p className="font-atencion">Empresa</p>
                                <Dropdown value={selectedCompany} onChange={(e) => setSelectedCompany(e.value)} options={selectCompany} optionLabel="nameCompany"
                                    placeholder="Seleccione" className="w-full md:w-18rem" />
                            </div>
                            <div className='select-atencion'>
                                <p className="font-atencion">Fecha de inicio</p>
                                <InputCalendar value={dateStart} onChange={(e) => setDate(e.value)} showIcon placeholder="DD/MM/YYYY" />
                            </div>
                            <div className='select-atencion'>
                                <p className="font-atencion">Fecha final</p>
                                <InputCalendar value={dateFinally} onChange={(e) => setDates(e.value)} showIcon placeholder="DD/MM/YYYY" />
                            </div>
                        </div>
                        <div className='grafico-carta-de-control' style={{ width: "1200px" }}>
                            <CartaDeControl2/>
                        </div>
                    </TabPanel>
                    <TabPanel header="Gráficos">
                        <h2>Gráficos</h2>
                        <div className='atencion-medica-carta-de-control'>
                            <div className='select-atencion one'>
                                <p className="font-atencion">Atención Médica</p>
                                <Dropdown value={selectedAtencionMedic} onChange={(e) => setSelectedAtencionMedic(e.value)} options={selectAtencion} optionLabel="name"
                                    placeholder="Seleccione la atención médica" className="w-full md:w-18rem" />
                            </div>
                            <div className='select-atencion'>
                                <p className="font-atencion">Empresa</p>
                                <Dropdown value={selectedCompany} onChange={(e) => setSelectedCompany(e.value)} options={selectCompany} optionLabel="nameCompany"
                                    placeholder="Seleccione" className="w-full md:w-18rem" />
                            </div>
                            <div className='select-atencion'>
                                <p className="font-atencion">Fecha de inicio</p>
                                <InputCalendar value={dateStart} onChange={(e) => setDate(e.value)} showIcon placeholder="DD/MM/YYYY" />
                            </div>
                            <div className='select-atencion'>
                                <p className="font-atencion">Fecha final</p>
                                <InputCalendar value={dateFinally} onChange={(e) => setDates(e.value)} showIcon placeholder="DD/MM/YYYY" />
                            </div>
                        </div>

                        <div className='flex flex-column gap-6 justify-content-start' style={{width: "1200px"}}>
                            <LineChart/>
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}

export default PageEstadisticaMedica;

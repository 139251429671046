import React, { useState, useEffect, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import "../../Style/Radiologia/estilos.css";
import { Button } from "primereact/button";
import Detalles from "../../Images/Radiologia/iconobotonhoja.svg";
import InputCalendar from "../../Components/form/InputCalendar";
import VoiceButton from "../../Components/ButtonVoice/VoiceButton";
import CustomDropdown from "./CustomDropdown";
import { RadContext } from "./RadProvider";
import Simbolos from "./Simbolos";
import Voicet from "../../Components/ButtonVoice/VoiceRa";
import { Calendar } from 'primereact/calendar';
import AuthUser from "../../AuthUser";
import EvaluadorTableAHP from "../../Components/MedicinaGeneral/examenAltura/pure/EvaluadorTableAHP";
import { Dropdown } from 'primereact/dropdown';
import CalidadRadiograficaContent from './CalidadRadiografica';
import AnormalidadesParenquimatosasContent from './AnormalidadesParenquimatosas';
import AnormalidadesPleuralesContent from './AnormalidadesPleurales';
import EngrosamientoDifusoContent from './EngrosamientoDifuso'
import VoiceRadiologia from "../../Components/ButtonVoice/VoiceRadiologia";
import EnvioArchivoRadiologia from '../MedicinaGeneral/ExamenFisico/EnvioArchivoRadiologia';
import CalendarRad from '../../Components/Radiologia/columna/CalendarRayosX'
import EnvioArchivoRadiologiaCol from '../../Components/Radiologia/columna/EnvioArchivoRadiologiaCol'
import EvaluadoresRadio from '../../Components/Radiologia/Radiologia/VerEvaluadores'


export default function GroupDemo() {
   const [containerStyle] = useState({});
   const [headerStyle] = useState({});
   const [observaciones, setObservaciones] = useState("");

   let { RayTorax, setRayTorax } =
      useContext(RadContext);

   // Calidad Radiografica

   const cities = [
      { name: "1", code: "1" },
      { name: "2", code: "2" },
      { name: "3", code: "3" },
      { name: "4", code: "4" },
   ];

   // Zona Afectada Izq y Der

   const cities2 = [
      { name: "Superior", code: "1" },
      { name: "Medio", code: "2" },
      { name: "Inferior", code: "3" },
   ];
   const cities3 = [
      { name: "Superior", code: "1" },
      { name: "Medio", code: "2" },
      { name: "Inferior", code: "3" },
   ];

   // Opacidad Pequeña

   const cities4 = [
      { name: "0/-", code: "1" },
      { name: "0/0", code: "2" },
      { name: "0/1", code: "3" },
      { name: "1/0", code: "4" },
      { name: "1/1", code: "5" },
      { name: "1/2", code: "6" },
      { name: "2/1", code: "7" },
      { name: "2/2", code: "8" },
      { name: "2/3", code: "9" },
      { name: "3/2", code: "10" },
      { name: "3/3", code: "11" },
      { name: "3/+", code: "12" },
   ];

   // Opacidad Grande

   const cities5 = [
      { name: "A", code: "1" },
      { name: "B", code: "2" },
      { name: "C", code: "2" },
   ];

   // Calidad Radiografica

   const cities6 = [
      { name: "1.Sobreexposición ", code: "1" },
      { name: "2.Subexposición", code: "2" },
      { name: "3.Posición centrado", code: "3" },
      { name: "4.Inspiración insuficiente", code: "4" },
      { name: "5.Escapulas", code: "5" },
      { name: "6.Artefacto", code: "6" },
      { name: "7.Contraste", code: "7" },
      { name: "8.Otros", code: "8" },
   ];

   const cities7 = [
      { name: "p", code: "1" },
      { name: "s", code: "2" },
      { name: "q", code: "3" },
      { name: "t", code: "4" },
      { name: "r", code: "5" },
      { name: "u", code: "6" },
   ];

   const cities8 = [
      { name: "O", code: "1" },
      { name: "D", code: "2" },
      { name: "I", code: "3" },
   ];

   const cities9 = [
      { name: "0", code: "1" },
      { name: "1", code: "2" },
      { name: "2", code: "3" },
      { name: "3", code: "4" },
   ];

   const cities10 = [
      { name: "A", code: "1" },
      { name: "B", code: "2" },
      { name: "C", code: "3" },
   ];

   const [nombreArchivo, setNombreArchivo] = useState("");

   const inputFile = useRef(null);

   const handleArchivoCargado = (event) => {
      const archivoCargado = event.target.files[0];
      if (archivoCargado) {
         setNombreArchivo(archivoCargado.name);
      } else {
         setNombreArchivo('');
      }
   };

   const abrirSelectorArchivos = () => {
      inputFile.current.click();
   };

   const onChange = (newDescripcion) => {
      setRayTorax({ ...RayTorax, descripcion: newDescripcion });
   };

   // const placaChange = (e) => {
   //    setRayTorax({ ...RayTorax.informe_oit, nro_placa: e.target.value });
   // };

   // const medicoChange = (e) => {
   //    setRayTorax({ ...RayTorax.informe_oit, medico_evaluador: e.target.value });
   // };

   // const colegiaturaChange = (e) => {
   //    setRayTorax({ ...RayTorax.informe_oit, colegiatura: e.target.value });
   // };

   // const rneChange = (e) => {
   //    setRayTorax({ ...RayTorax.informe_oit, r_n_e: e.target.value });
   // };

   const formatDate = (inputDate) => {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
   };

   // const fechaChange = (e) => {
   //    const formattedDate = formatDate(e.value);
   //    setRayTorax({ ...RayTorax.informe_oit, fecha: formattedDate });
   // };

   const placaChange = (e) => {
      setRayTorax((prevData) => ({
         ...prevData,
         informe_oit: {
            ...prevData.informe_oit,
            nro_placa: e.target.value,
         },
      }));
   };

   const medicoChange = (e) => {
      setRayTorax((prevData) => ({
         ...prevData,
         informe_oit: {
            ...prevData.informe_oit,
            medico_evaluador: e.target.value,
         },
      }));
   };

   const colegiaturaChange = (e) => {
      setRayTorax((prevData) => ({
         ...prevData,
         informe_oit: {
            ...prevData.informe_oit,
            colegiatura: e.target.value,
         },
      }));
   };

   const rneChange = (e) => {
      setRayTorax((prevData) => ({
         ...prevData,
         informe_oit: {
            ...prevData.informe_oit,
            r_n_e: e.target.value,
         },
      }));
   };

   const fechaChange = (e) => {
      const formattedDate = formatDate(e.value);
      setRayTorax((prevData) => ({
         ...prevData,
         informe_oit: {
            ...prevData.informe_oit,
            fecha: formattedDate,
         },
      }));
   };



   const handleObservacion = (e) => {
      setRayTorax({ ...RayTorax.informe_oit, observaciones: e });
   };

   const handleArchivo = (e) => {
      setRayTorax({ ...RayTorax.informe_oit, archivo: e });
   };

   // useEffect(() => {
   // }, []);
   const subTitulo = {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: "600",
   };
   const subTituloMorado = {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontWeight: "700",
      color: "#4545BC"
   };
   const [date, setDate] = useState();









   // EVALUADORES:
   const [NombreEvaluador, setNombreEvaluador] = useState('');
   const [Colegiatura, setColegiatura] = useState('');


   const [evaluadores, setEvaluadores] = useState({})
   const { http } = AuthUser();
   const [viewPop, setviewPop] = useState(false);
   const getMedicos = () => {
      http
         .get("medicina_general/evaluacion_medica/evaluadores/get")
         .then((response) => {
            setEvaluadores(response.data.evaluadores)
         })
   }
   useEffect(() => {
      getMedicos();
   }, []);
   useEffect(() => {
      if (evaluadores && evaluadores.length > 0) {
         evaluadores.map((item) => {
            
            if (item.id == RayTorax.informe_oit.colegiatura) {
               setNombreEvaluador(item.nombres_completos);
               setColegiatura(item.colegiatura);
            }
         })
      }
   }, [evaluadores, RayTorax]);
   const ActualizarView = () => {
      if (evaluadores && evaluadores.length > 0) {
         if (RayTorax.informe_oit.colegiatura) {
            evaluadores.map((item) => {
               
               if (item.id == RayTorax.informe_oit.colegiatura) {
                  setNombreEvaluador(item.nombres_completos);
                  setColegiatura(item.colegiatura);
               }
            })
         }
         else {
            setNombreEvaluador("");
            setColegiatura("");
         }
      }


   }
   useEffect(() => {
      ActualizarView();
   }, [RayTorax])

   const ActualizarEvaluador = (ideva) => {
      setRayTorax(prevState => ({
         ...prevState,
         informe_oit: {
            ...prevState.informe_oit,
            colegiatura: ideva
         }
      }));
   };











   const actualizarObservaciones = (nuevasObservaciones) => {
      setRayTorax(prevState => ({
         ...prevState,
         informe_oit: {
            ...prevState.informe_oit,
            observaciones: nuevasObservaciones
         }
      }));
   };

   const [TextAreaObserva, setTextAreaObserva] =
      useState(RayTorax.informe_oit.observaciones ? RayTorax.informe_oit.observaciones : null);
   useEffect(() => {
      actualizarObservaciones(TextAreaObserva)
   }, [TextAreaObserva])

















   const [ArchivoBaseFlexibilidad, setArchivoBaseFlexibilidad] = useState(RayTorax.informe_oit.archivo);
   const updateArchivoFlexibilidad = (nuevoValor) => {
      setRayTorax((RayTorax) => ({
         ...RayTorax,
         informe_oit: {
            ...RayTorax.informe_oit,
            archivo: nuevoValor,
         },
      }));
   };
   const Actualizarfecha = (ideva) => {
      setRayTorax((RayTorax) => ({
         ...RayTorax,
         informe_oit: {
            ...RayTorax.informe_oit,
            fecha: ideva,
         },
      }));
   };
   return (
      <div>
         <div className="flex gap-1 flex-column">
            <style>
               {
                  `
                  @media(max-width: 841px){
                     .Cuadro01InformeOITDatosGeneral>div:nth-child(3){
                        min-width: 100% !important;
                        flex-wrap: wrap;
                        padding-top: 0.25rem !important;
                        padding-bottom: 0.25rem !important;
                     }
                  }
                  @media(max-width: 526px){
                     .Cuadro01InformeOITDatosGeneral>div:nth-child(3) .cuadrosubth02{
                        min-width: 100% !important;
                     }
                     .Cuadro01InformeOITDatosGeneral>div:nth-child(3) .cuadrosubth02:nth-child(2){
                        flex-direction: row !important;
                     }
                  }
                  
               `
               }
            </style>
            <div
               className="Cuadro01InformeOITDatosGeneral 
               p-2 flex flex-row flex-wrap gap-1 w-full text align-content-center"
               style={{
                  backgroundColor: "#F6F7FB",
                  borderRadius: "15px"
               }}
            >
               <div className="flex-1 flex flex-column w-2  gap-1"
                  style={{ minWidth: '200px' }}>
                  <label style={subTitulo}>Fecha:</label>
                  <CalendarRad val={RayTorax?.informe_oit?.fecha} update={Actualizarfecha} />

               </div>

               <div className="flex-1 flex flex-column w-2 gap-1"
                  style={{ minWidth: '100px' }}>
                  <label style={subTitulo}>Nr. Placa</label>
                  <InputText
                     type="text"
                     className="p-inputtext-sm"
                     value={RayTorax.informe_oit.nro_placa}
                     onChange={(e) => placaChange(e)}
                     style={{ height: '45px' }}
                  />
               </div>

               <div className="flex-1 flex w-6 gap-1 align-items-between"
                  style={{ minWidth: '400px' }}>
                  <div className="cuadrosubth02 flex-1 flex flex-column gap-1" >
                     <label style={subTitulo}>Médico evaluador:</label>
                     <InputText
                        className="flex flex-1" disabled value={NombreEvaluador} onChange={(e) => setNombreEvaluador(e.target.value)}
                        style={{ height: '45px', backgroundColor: '#DDDDDD' }}
                     />
                  </div>
                  <div className="cuadrosubth02 flex align-items-end">
                     <div className="flex flex-1 flex-column gap-1">
                        <label style={subTitulo}>Colegiatura:</label>

                        <div className="w-full flex">
                           <InputText
                              className="w-7rem" disabled value={Colegiatura} onChange={(e) => setColegiatura(e.target.value)}
                              style={{ height: '45px', backgroundColor: '#DDDDDD' }}
                           />
                        </div>
                     </div>
                     <Button className="ButonEvaluadoresRadiologiaAg ml-1 flex align-center justify-content-center"
                        icon="pi pi-search" severity="success" onClick={() => { setviewPop(true) }}
                        style={{ width: '40px', maxWidth: '40px', height: '45px', backgroundColor: '#BFF1DF', borderColor: '#248D63' }}>
                        <style>
                           {`
                              .ButonEvaluadoresRadiologiaAg .pi-search:before{
                                 color: #248D63 !important;
                              }
                           `}
                        </style>
                     </Button>
                     <EvaluadoresRadio
                            evaluadores={evaluadores}
                            visible={viewPop}
                            setVisible={setviewPop}
                            id={RayTorax?.informe_oit?.colegiatura}
                            setid={ActualizarEvaluador}
                        />
                  </div>

               </div>

               <div className=" w-2 flex flex-1 flex-column gap-1"
                  style={{ minWidth: '140px', width: '140px' }}>
                  <label style={subTitulo}>R.N.E</label>
                  <InputText
                     type="text"
                     className="p-inputtext-sm"
                     value={RayTorax.informe_oit.r_n_e}
                     onChange={rneChange}
                     style={{ height: '45px' }}
                  />
               </div>
            </div>

            <div className="flex flex-wrap gap-3">
               <style>
                  {`
                     @media (max-width:995px){
                        .contenedorCali{
                           width: 100% !important;
                        }
                        .contenedorAnor{
                           width: 100% !important;
                           
                        }
                     }
                  `}
               </style>
               <div
                  className="contenedorCali flex flex-column gap-2"
                  id="miContenedor"
                  style={containerStyle}
               >
                  <div className="encabezadoCali" style={{ ...headerStyle, textAlign: 'center' }}>
                     <span>1. CALIDAD RADIOGRÁFICA</span>
                  </div>

                  <CalidadRadiograficaContent />
               </div>

               <div
                  className="contenedorAnor flex flex-column gap-2  "
                  id="miContenedor"
               >
                  <div className="encabezadoCae" style={{ ...headerStyle, textAlign: 'center' }}>
                     <span>2. ANORMALIDADES PARENQUIMATOSAS</span>
                  </div>
                  <AnormalidadesParenquimatosasContent />
               </div>
            </div>

            <div
               className="contenedorDoble flex flex-column gap-2 w-full flex-1"
               id="miContenedor"
               style={containerStyle}
            >
               <div className="encabezadoDoble" style={{ ...headerStyle, textAlign: 'center' }}>
                  <span>3. ANORMALIDADES PLEURALES</span>
               </div>
               <AnormalidadesPleuralesContent />
            </div>
            <div
               className="contenedorDoble flex flex-column gap-2 w-full flex-1"
               id="miContenedor"
               style={containerStyle}
            >
               <div className="encabezadoDoble" style={{ ...headerStyle, textAlign: 'center' }}>
                  <span>3.2 Engrosamiento Difuso de la pleura</span>
               </div>
               <EngrosamientoDifusoContent />
            </div>

            <Simbolos />

            <div
               className="ObservacionesInformeOPITVoiceButAHP w-full h-auto flex flex-column">
               {/* <div className="CuerpoCae flex gap-3">
                  <div
                     className="flex flex-row gap-2"
                     style={{ alignItems: "center" }}
                  >
                     <span style={{ color: "#363E4B" }}>Observaciones</span>
                  </div>
               </div> */}
               <style>
                  {`
                     .ObservacionesInformeOPITVoiceButAHP textarea{
                        height: 100px ;
                     }
                  `}
               </style>
               <div style={{ ...subTituloMorado, margin: '7px 0px' }}>Observaciones:</div>
               <VoiceRadiologia
                  value={TextAreaObserva}
                  onChange={(e) => setTextAreaObserva(e)} />
               {/* <Voicet valueDefect={RayTorax?.informe_oit?.observaciones} onChange1={(text) => setRayTorax({ ...RayTorax, informe_oit: { ...RayTorax.informe_oit, observaciones: text } })} /> */}
            </div>
            <EnvioArchivoRadiologiaCol
               FuncionSetEnvio={setArchivoBaseFlexibilidad}
               FuncionUpData={updateArchivoFlexibilidad}
               ValorSet={ArchivoBaseFlexibilidad}
               DataContext={RayTorax.informe_oit.archivo}
            />

         </div>
      </div>
   );
}

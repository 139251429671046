import GClientes from "../../../Components/Ventas/GClientes/Components/GClientes";

const PageGestionClientes = () => {
    return (

        <div className="flex flex-column gap-3 text-sm min-h-full">
            <span className="text-2xl text-indigo-500 font-bold">Clientes</span>
            <span className="text-indigo-300">En este módulo usted podrá visualizar a sus clientes.</span>
            <GClientes/>
        </div>
    )
};

export default PageGestionClientes;
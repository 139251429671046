import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../Style/style5.css";
import "../../../Style/Button.css";
import "../../../Style/Laboratorio/EstilosVPH.css";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import AuthUser from "../../../AuthUser";

export default function TablaVPH({ tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
   const { http } = AuthUser();
   const inputFile = useRef(null);

   const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
   // const [labPruebas, setLabPruebas] = useState([])
   const [prueba, setPrueba] = useState({})
   const [nombreArchivo, setNombreArchivo] = useState(null);
   // const [file, setFile] = useState(null);
   const [visibleDialog, setVisibleDialog] = useState(false);

   const handleCreate = () => {
      const formData = new FormData();
      formData.append("examen_id", tipoExamen);
      formData.append("prueba[examen_id]", tipoPrueba);
      formData.append("prueba[estado]", 1);
      formData.append("prueba[perfil]", 1);
      formData.append("prueba[cantidad_parametros]", 0);
      formData.append("prueba[parametros]", []);
      formData.append("archivo", prueba?.archivo ? prueba?.archivo : null);

      http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
         .then(response => {

            showToast("success", "Creado Correctamente", response?.data?.resp)
         })
         .catch(error => {
            console.error(error)
            showToast("Error", "Error de Guardar", error?.data?.resp)
         })
   }

   const abrirSelectorArchivos = () => {
      inputFile.current.click();
   };

   const handleArchivoCargado = (event) => {
      const archivoCargado = event.target.files[0];
      if (archivoCargado) {
         // setFile(archivoCargado)
         setNombreArchivo(archivoCargado.name);
         let temporalPrueba = prueba
         temporalPrueba.archivo = archivoCargado;
         setPrueba({ ...prueba, archivo: archivoCargado });
         setExamenesContext(temporalPrueba)

      } else {
         setNombreArchivo("");
      }
   };

   const handlerdeletefile = () => {
      setNombreArchivo("")
      // setFile(null)
   }
   const abrirDialog = () => {
      setVisibleDialog(true);
   };

   useEffect(() => {

      let auxPruebas = examenesContext.find(examen => {
         return examen.examen_id === tipoExamen
      });
      let auxPrueba = auxPruebas?.pruebas.find((prueba => {
         return prueba.examen_id === tipoPrueba
      }));
      if (auxPrueba?.archivo) {

      }
      // setLabPruebas(auxPruebas);
      setPrueba(auxPrueba);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <div className="pt-3" style={{ width: "90%", margin: "auto" }} >
         <div
            className="flex flex-row gap-2"
            style={{ alignItems: "center" }}
         >
            <input
               type="file"
               ref={inputFile}
               style={{ display: "none" }}
               onChange={handleArchivoCargado}

            />
            <span>Nombre de Archivo</span>
            <InputText
               type="text"
               value={prueba && prueba?.archivo !== null ? (typeof prueba?.archivo === "string" ? prueba?.archivo.substring(prueba?.archivo.lastIndexOf('/') + 1) : nombreArchivo) : ""}
               readOnly
               style={{
                  width: "800px",
                  height: "40px",
               }}
            />
            <Button
               icon="pi pi-upload"
               className="upload"
               onClick={abrirSelectorArchivos}
            />
            <Button icon="pi pi-trash"
               onClick={handlerdeletefile}
               className="trash" />
            <Button
               className="search"
               onClick={abrirDialog}
               icon="pi pi-search"
            >
            </Button>
         </div>
         <div className="flex pt-3 pb-3" style={{ justifyContent: 'end' }}>
            <div className="flex gap-3">
               <Button label="Grabar" onClick={handleCreate} icon="fas fa-save" />
               <Button label="Recuperar" icon="fas fa-undo" />
            </div>
         </div>
         <Dialog
            header="Vista previa del PDF"
            visible={visibleDialog}
            style={{ width: '70vw' }}
            onHide={() => setVisibleDialog(false)}
         >
            <iframe
               src={prueba.archivo && prueba?.archivo !== null ? (typeof prueba?.archivo !== "string" ? URL.createObjectURL(prueba?.archivo) : typeof prueba?.archivo == "string" ? prueba?.archivo : '') : ""}
               width="100%"
               height="500px"
               title="Vista previa del PDF"
            />
         </Dialog>
      </div>
   );
}

import React, { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';


// Transformacion de Hook
export const HookPositionDemo = () => {
    const [visible, setVisible] = useState(false);
    const [position, setPosition] = useState('center');
    const show = (position) => {
        setPosition(position);
        setVisible(true);
    };
    return { visible, setVisible, show,position, setPosition }
}
// Fin del hook

export default function PositionDemo() {
    const { visible, setVisible, show, footerContent } = HookPositionDemo();
    const [position, setPosition] = useState('left');
    



    return (
        <div className="">
                <Dialog header="Holaaaa" visible={visible} position={position} onHide={() => setVisible(false)} footer={footerContent} draggable={false} resizable={false}>
                    <p className="m-0">
                        Hola
                    </p>
                </Dialog>
        </div>
    )
}
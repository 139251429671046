import React, { useState } from "react";
import "../../../Style/MedicinaGeneral/ExplPalpacion.css";
import Articulacion from "./ComponentTableA";
import Test from "./ComponentTableA2";
import { ScrollPanel } from 'primereact/scrollpanel';



export default function ComponentExpl(props) {


    return (
        //Contenedor
        <div style={{ width: '100%', overflowX:"auto"  }}>
            <div className="flex flex-wrap flex-column gap-5">
                <div>
                    <ScrollPanel style={{ width: '100%', height: '470px'}}>
                        <Articulacion />
                    </ScrollPanel>
                </div>
                <div>
                    <Test />
                </div>
            </div>
        </div>
    );
}


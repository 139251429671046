import React, { useState, useEffect, useContext } from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import ComponentDesviacion from '../../../Components/MedicinaGeneral/Desviacion/Desviacion';
import Componentmovilidad from '../../../Components/MedicinaGeneral/Movilidad/Movilidad';
import ComponentExpl from '../../../Components/MedicinaGeneral/Exploracion/EXPL';
import ComponentArticulacion from '../../../Components/MedicinaGeneral/Articulacion/Articulacion';
import ComponentSistemaMotor from '../../../Components/MedicinaGeneral/SistemaMotoryCoordinacion/SistemaMotor';
import ComponentAnamesis from '../../../Components/MedicinaGeneral/Anamesis/Anamesis';
import "../../../Style/MedicinaGeneral/ExplPalpacion.css";
import { MGContext } from '../MGProvider';
import AuthUser from "../../../AuthUser";

export default function Pageexploracion() {
    const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);
    const { http } = AuthUser()
    const [idMedGen, setIdMedGen] = useState(2);


    const DataEnvio = {
        "desviacion": {
            "archivo": null,
            "eje_anterior": [
                {
                    "patologia_id": 1,
                    "resultado": "resultado",
                    "observacion": "observacion"
                },
                {
                    "patologia_id": 1,
                    "resultado": "resultado",
                    "observacion": "observacion"
                }
                ,{
                    "patologia_id": 1,
                    "resultado": "resultado",
                    "observacion": "observacion"
                }
            ],
            "eje_lateral": [
                {
                    "patologia_id": 2,
                    "resultado": "resultado",
                    "observacion": "observacion"
                },
                {
                    "patologia_id": 1,
                    "resultado": "resultado",
                    "observacion": "observacion"
                },
                {
                    "patologia_id": 1,
                    "resultado": "resultado",
                    "observacion": "observacion"
                }
            ]
        },
        "movilidad": {
            "archivo": null,
        },
        "movilidad_patologia": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_exploracion_patologias": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_palpacion_patologias": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_art_movilidad_patologias": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_art_test_patologias": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_motor_patologias": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_test_coordinacion_patologias": [
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            },
            {
                "patologia_id": 1,
                "resultado": "resultado",
                "observaciones": "observaciones"
            }
        ],
        "med_anamnesis": [
            {
                "puesto": "puesto",
                "sedente": "sedente",
                "bipedestacion": "bipedestacion",
                "peso_inf": 30,
                "peso_sub": 50,
                "movimiento_manual_carga": "A",
                "movimiento_repetitivo": "A",
                "postura_forzada": "A",
                "pvd": "A",
                "horas": 8,
                "dominancia": "A",
                "diagnostico": "Diagnóstico",
                "recomendaciones": "Recomendaciones",
                "conclusiones": "Conclusiones"
            }
        ],
        "levanta": "levanta",
        "empuja": "empuja",
        "arrastra": "arrastra"
    }

    const EnvioData = (data) => {
        http.post(`medicina_general/exploracion_clinica/create/${idMedGen}`, data)
            .then((response) => {
                console.error("error", data)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    return (
        <div >
            {/* Jorge Falconi */}
            <Accordion className='Acordeon7u7Bonito'>
                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Desviación</span>
                    </div>
                } >
                    <ComponentDesviacion />
                </AccordionTab>


                {/*Carlos Salinas y Fabrizzio del Aguila */}
                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Movilidad</span>
                    </div>
                } >
                    <Componentmovilidad />
                </AccordionTab>


                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Exploración/Palpación</span>
                    </div>
                } >
                    <ComponentExpl />
                </AccordionTab>

                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Articulación</span>
                    </div>
                } >
                    <ComponentArticulacion />
                </AccordionTab>

                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Sistema Motor y Coordinación</span>
                    </div>
                } >
                    <ComponentSistemaMotor />
                </AccordionTab>

                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Anamnesis-Diagnóstico</span>
                        <span>Anamnesis-Dx</span>
                    </div>
                } >
                    <ComponentAnamesis />
                </AccordionTab>

            </Accordion>


        </div>)
}
import React, { useContext, useRef, useState, useEffect } from 'react';
import AuthUser from "../../AuthUser";
import { OftContext } from "./OftContext";
import { TabPanel, TabView } from "primereact/tabview";
import PageOftAnt from "./Antecedentes/PageOftAnt";
import '../../Style/oft.css';
import PageOftAgud from "./AgudezaVisual/PageOftAgud";
import PageOftTest from "./Test/PageOftTest";
import PageOftMed from "./Medicion/PageOftMed";
import PageOftDiag from "./Diagnostico/PageOftDiag";
import PageRef from "./Refraccion/PageRef";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconOft } from "../../Services/importIcons";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import { useParams } from 'react-router-dom';
import { RAContext } from '../RutaAtencion/RAContext';
import ModalGuardarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion';
import { Toast } from 'primereact/toast';
import ModalCancelarAtencion from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion';
import PageOftTestColor from './TestColores/PageOftTestColor';
import PageOftTestProfundidad from './TestProfundidad/PageOftTestProfundidad';
import Campimetria from './Campimetria/Campimetria';
import { startLoading, endLoading } from "../../Redux/isLoadingSlice";

import BottomsFootComponent from '../../Components/Clinica/AtencionMedica/pure/BottomsFoot';
import { useDispatch } from 'react-redux';

const PageOft = ({ revision }) => {
   const dispatch = useDispatch();

   const { idPaciente } = useParams();
   const { http } = AuthUser();
   const toast = useRef(null);

   let {
      anteContext, setAnteContext,
      agudVisualContext, setAgudVisualContext,
      refraccion, setRefraccion,
      testColorContext, setTestColorContext,
      testProfundidad, setTestProfundidad,
      campimetriaContext, setCampimetriaContext,
      diagContext, setDiagContext,
      mediContext, setMediContext,
      testContext, setTestContext,
      setAtencionStatus,
   } = useContext(OftContext)
   let { oftalmologiaAreasContext, pacienteContext, setPacienteContext, pageViewContext, stateAtencion, setStateAtencion, oftalmologiaDetalleId, setOftalmologiaDetalleId } = useContext(RAContext)
   const { setTimeStart } = useContext(OftContext);

   const [campCon, setCampCon] = useState([])
   const [visibleImprimir, setVisibleImprimir] = useState(false)
   const [VisibleCancelar, setVisibleCancelar] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [oftalmologiaId, setOftalmologiaId] = useState(null);
   const [activeIndex, setActiveIndex] = useState(0);
   const [estado, setEstado] = useState(false)
   const [documentos, setDocumentos] = useState([])



   const handleConfirmNo = () => {
      setVisibleGuardar(false);
   };

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };
   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };


   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            
            setOftalmologiaDetalleId({
               id: oftalmologiaDetalleId.id,
               estado: 1,
               hora_inicio: oftalmologiaDetalleId.hora_inicio,
               fecha_inicio: oftalmologiaDetalleId.fecha_inicio,
               hora_fin: oftalmologiaDetalleId.hora_fin,
               fecha_fin: oftalmologiaDetalleId.fecha_fin,
            })
         })
   }

   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            
            setOftalmologiaDetalleId({
               id: oftalmologiaDetalleId.id,
               estado: 2,
               hora_inicio: oftalmologiaDetalleId.hora_inicio,
               fecha_inicio: oftalmologiaDetalleId.fecha_inicio,
               hora_fin: oftalmologiaDetalleId.hora_fin,
               fecha_fin: oftalmologiaDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setOftalmologiaDetalleId({
                  id: oftalmologiaDetalleId.id,
                  estado: 2,
                  hora_inicio: oftalmologiaDetalleId.hora_inicio,
                  fecha_inicio: oftalmologiaDetalleId.fecha_inicio,
                  hora_fin: oftalmologiaDetalleId.hora_fin,
                  fecha_fin: oftalmologiaDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }


   const oftalmologiaCreate = () => {
      http
         .post('/oftalmologia/create', { atencion_id: idPaciente })
         .then((response) => {

            if (response.data.Error) {
               throw new Error(response.data.Error);
            }
            setOftalmologiaId(response.data.oftalmologia_id)
            setPacienteContext({ ...pacienteContext, oftalmologia_id: response.data.oftalmologia_id })
            showToast('success', 'Exito', response.data.resp);
         })
   };

   const createAntecedentes = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/antecedentes/create/${oftalmologiaId}`, anteContext)
         .then(() => {
            console.log('Antecedentes Oculares guardado');
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Antecedentes Oculares');
         })
   };

   const createAgudez = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/agudeza-visual/create/${oftalmologiaId}`, agudVisualContext)
         .then(() => {
            console.log('Diagnostico de AgudezaVisual guardado', agudVisualContext);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Medición', error)
         })
   };

   const createRefraccion = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/refraccion/create/${oftalmologiaId}`, refraccion)
         .then(() => {
            console.log('Diagnostico de Refraccion guardado', refraccion);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Medición')
         })
   };

   const createTestColor = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/test-colores/create/${oftalmologiaId}`, testColorContext)
         .then(() => {
            console.log('Test guardado', testColorContext);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Test');
         })
   };

   const createTestProfundidad = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/test-profundidad/create/${oftalmologiaId}`, testProfundidad)
         .then(() => {
            console.log('Test guardado', testProfundidad);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Test');
         })
   };

   const createCampimetria = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/campimetria-confrontacion/create/${oftalmologiaId}`, campimetriaContext)
         .then(() => {
            console.log('Test guardado', campimetriaContext);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Test');
         })
   };

   const createMedicion = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/medicion/create/${oftalmologiaId}`, mediContext)
         .then(() => {
            console.log('Medición guardado', mediContext);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Medición');
         })
   };

   const createTest = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/test-rutina/create/${oftalmologiaId}`, testContext)
         .then(() => {
            console.log('Test guardado', testContext);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Test');
         })
   };

   const createDiagnostico = async (oftalmologiaId) => {
      await http
         .post(`oftalmologia/diagnostico/create/${oftalmologiaId}`, diagContext)
         .then(() => {
            console.log('Diagnostico guardado', diagContext);
         })
         .catch((error) => {
            console.error(error);
            console.log('Error en Diagnostico');
         })
   };


   const GetPacienteOftalmo = () => {
      http
         .get(`oftalmologia/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setOftalmologiaId(response?.data?.resp?.id)
               fetchGetAllData();
            }
            if (response.data.error) {
               dispatch(endLoading());
            }
         })
   }

   const fetchAllData = async () => {
      try {
         await Promise.all([
            createAntecedentes(oftalmologiaId),
            createAgudez(oftalmologiaId),
            createRefraccion(oftalmologiaId),
            createTestColor(oftalmologiaId),
            createTestProfundidad(oftalmologiaId),
            createCampimetria(oftalmologiaId),
            createMedicion(oftalmologiaId),
            createTest(oftalmologiaId),
            createDiagnostico(oftalmologiaId),
         ])
         showToast(
            "success",
            `Atención en Psicología guardada`,
            `Se guardó la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const fetchGetAllData = async () => {
      try {
         await Promise.all([
            getAntecedentes(),
            getAgudez(),
            getRefraccion(),
            getTestColor(),
            getTestProfundidad(),
            getCampimetria(),
            getMedicion(),
            getTest(),
            getDiagnostico(),
         ])
         showToast(
            "success",
            `Atención en Obtenida`,
            `Datos traidos correctamente la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const getAntecedentes = async () => {
      await http
         .get(`/oftalmologia/antecedentes/show_pac/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               const antecedente_ocular = response?.data?.resp?.oft_antecedentes_oculares;
               const antecedentes_sistemicos = response?.data?.resp?.oft_antecedentes_sistemicos;
               setAnteContext({
                  ante_cirugia: {
                     tipo_patologia_id: antecedente_ocular?.ante_cirugia?.tipo_patologia_id,
                     afecta_a: antecedente_ocular?.ante_cirugia?.afecta_a,
                     select: antecedente_ocular?.ante_cirugia?.select === "1" ? true : false,
                     comentario: antecedente_ocular?.ante_cirugia?.comentario === null ? "" : antecedente_ocular?.ante_cirugia?.comentario
                  },
                  ante_daltonismo: {
                     tipo_patologia_id: antecedente_ocular?.ante_daltonismo?.tipo_patologia_id,
                     afecta_a: antecedente_ocular?.ante_daltonismo?.afecta_a,
                     select: antecedente_ocular?.ante_daltonismo?.select === "1" ? true : false,
                     comentario: antecedente_ocular?.ante_daltonismo?.comentario === null ? "" : antecedente_ocular?.ante_daltonismo?.comentario
                  },
                  ante_discromatopsia: {
                     verde: antecedente_ocular?.ante_discromatopsia?.verde === "1" ? true : false,
                     rojo: antecedente_ocular?.ante_discromatopsia?.rojo === "1" ? true : false,
                     amarillo: antecedente_ocular?.ante_discromatopsia?.amarillo === "1" ? true : false,
                     select: antecedente_ocular?.ante_discromatopsia?.select === "1" ? true : false,
                     comentario: antecedente_ocular?.ante_discromatopsia?.comentario === null ? "" : antecedente_ocular?.ante_discromatopsia?.comentario,
                  },
                  oft_oculares_otros: {
                     select: antecedente_ocular?.oft_oculares_otros?.select === "1" ? true : false,
                     comentarios: antecedente_ocular?.oft_oculares_otros?.comentarios === null ? "" : antecedente_ocular?.oft_oculares_otros?.comentarios,
                  },
                  oft_antecedentes_oculares: {
                     cirugias: antecedente_ocular?.cirugias
                  },
                  oft_ocular_patologia: antecedente_ocular?.oft_ocular_patologia.length > 0 ? antecedente_ocular?.oft_ocular_patologia?.map(patologia => {
                     return {
                        patologia_id: patologia.patologia_id,
                        nombre: patologia?.patologias?.nombre,
                        select: patologia.select === "1" ? true : false,
                        afecta_a: patologia.afecta_a,
                     }
                  }) : anteContext.oft_ocular_patologia,
                  oft_sistematico_otro: {
                     comentarios: antecedentes_sistemicos?.oft_sistemico_otros?.comentarios,
                     select: antecedentes_sistemicos?.oft_sistemico_otros?.select === 1 ? true : false,
                  },
                  oft_antecedente_sistematico: {
                     conductor: antecedentes_sistemicos?.conductor,
                     correctores: antecedentes_sistemicos?.correctores,
                     tipo: antecedentes_sistemicos?.tipo,
                  },
                  oft_sistematico_patologia: antecedentes_sistemicos?.oft_sistemico_patologia.length > 0 ? antecedentes_sistemicos?.oft_sistemico_patologia?.map(patologia => {
                     return {
                        patologia_id: patologia.patologia_id,
                        nombre: patologia?.nombre,
                        select: patologia.select === 1 ? true : false,
                     }
                  }) : anteContext.oft_sistematico_patologia
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getAgudez = async () => {
      await http
         .get(`oftalmologia/agudeza-visual/show/${idPaciente}`)
         .then((response) => {
            if (response && response.data.resp) {
               setAgudVisualContext({
                  corregido_si: {
                     vision_cerca: {
                        ojo_derecho: response?.data?.resp?.correccion_si?.vision_cerca?.ojo_derecho,
                        ojo_izquierdo: response?.data?.resp?.correccion_si?.vision_cerca?.ojo_izquierdo,
                        binocular: response?.data?.resp?.correccion_si?.vision_cerca?.binocular,
                     },
                     vision_lejos: {
                        ojo_derecho: response?.data?.resp?.correccion_si?.vision_lejos?.ojo_derecho,
                        ojo_izquierdo: response?.data?.resp?.correccion_si?.vision_lejos?.ojo_izquierdo,
                        binocular: response?.data?.resp?.correccion_si?.vision_lejos?.binocular,
                     }
                  },
                  corregido_no: {
                     vision_cerca: {
                        ojo_derecho: response?.data?.resp?.correccion_no?.vision_cerca?.ojo_derecho,
                        ojo_izquierdo: response?.data?.resp?.correccion_no?.vision_cerca?.ojo_izquierdo,
                        binocular: response?.data?.resp?.correccion_no?.vision_cerca?.binocular,
                     },
                     vision_lejos: {
                        ojo_derecho: response?.data?.resp?.correccion_no?.vision_lejos?.ojo_derecho,
                        ojo_izquierdo: response?.data?.resp?.correccion_no?.vision_lejos?.ojo_izquierdo,
                        binocular: response?.data?.resp?.correccion_no?.vision_lejos?.binocular,
                     }
                  },
                  a_e: {
                     ojo_derecho: response?.data?.resp?.a_e?.ojo_derecho,
                     ojo_izquierdo: response?.data?.resp?.a_e?.ojo_izquierdo,
                  },
                  tonometria: {
                     ojo_derecho: response?.data?.resp?.tonometria?.ojo_derecho,
                     ojo_izquierdo: response?.data?.resp?.tonometria?.ojo_izquierdo,
                  },
                  examen_externo: {
                     ojo_derecho: response?.data?.resp?.examen_externo?.ojo_derecho,
                     ojo_izquierdo: response?.data?.resp?.examen_externo?.ojo_izquierdo,
                     examen_clinico: response?.data?.resp?.examen_externo?.examen_clinico,
                  },
                  enfermedad_ocular: response?.data?.resp?.enfermedad_ocular,
                  vision_colores: response?.data?.resp?.vision_colores,
                  reflejos_pupilares: response?.data?.resp?.reflejos_pupilares,
                  presion_intraocular: response?.data?.resp?.presion_intraocular,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getRefraccion = async () => {
      await http
         .get(`oftalmologia/refraccion/show/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setRefraccion({
                  ojo_derecho_refraccion_lejos: {
                     shp: response?.data?.resp?.refraccion_lejos?.ojoderechorefraccion?.shp,
                     cyl: response?.data?.resp?.refraccion_lejos?.ojoderechorefraccion?.cyl,
                     ej: response?.data?.resp?.refraccion_lejos?.ojoderechorefraccion?.ej,
                     seleccionar: response?.data?.resp?.refraccion_lejos?.ojoderechorefraccion?.seleccionar,
                  },
                  ojo_izquierdo_refraccion_lejos: {
                     shp: response?.data?.resp?.refraccion_lejos?.ojoizquierdorefraccion?.shp,
                     cyl: response?.data?.resp?.refraccion_lejos?.ojoizquierdorefraccion?.cyl,
                     ej: response?.data?.resp?.refraccion_lejos?.ojoizquierdorefraccion?.ej,
                     seleccionar: response?.data?.resp?.refraccion_lejos?.ojoizquierdorefraccion?.seleccionar,
                  },
                  dip_lejos: response?.data?.resp?.refraccion_lejos?.dip_lejos,
                  ojo_derecho_refraccion_cerca: {
                     shp: response?.data?.resp?.refraccion_cerca?.ojoderechorefraccion?.shp,
                     cyl: response?.data?.resp?.refraccion_cerca?.ojoderechorefraccion?.cyl,
                     ej: response?.data?.resp?.refraccion_cerca?.ojoderechorefraccion?.ej,
                     seleccionar: response?.data?.resp?.refraccion_cerca?.ojoderechorefraccion?.seleccionar,
                  },
                  ojo_izquierdo_refraccion_cerca: {
                     shp: response?.data?.resp?.refraccion_cerca?.ojoizquierdorefraccion?.shp,
                     cyl: response?.data?.resp?.refraccion_cerca?.ojoizquierdorefraccion?.cyl,
                     ej: response?.data?.resp?.refraccion_cerca?.ojoizquierdorefraccion?.ej,
                     seleccionar: response?.data?.resp?.refraccion_cerca?.ojoizquierdorefraccion?.seleccionar,
                  },
                  dip_cerca: response?.data?.resp?.refraccion_cerca?.dip_cerca,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getTestColor = async () => {
      await http
         .get(`/oftalmologia/test-colores/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setTestColorContext({
                  ojo_derecho: parseInt(response?.data?.resp?.ojo_derecho),
                  ojo_izquierdo: parseInt(response?.data?.resp?.ojo_izquierdo),
                  reconoce_colores: response?.data?.resp?.reconoce_colores,
                  dificultad_color: response?.data?.resp?.dificultad_color,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getTestProfundidad = async () => {
      await http
         .get(`/oftalmologia/test-profundidad/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setTestProfundidad({
                  stereo_fly_test: response?.data?.resp?.stereo_fly_test,
                  circulos: response?.data?.resp?.circulos,
                  porcentaje: response?.data?.resp?.porcentaje,
                  animales: response?.data?.resp?.animales,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getCampimetria = async () => {
      await http
         .get(`/oftalmologia/campimetria-confrontacion/get/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setCampCon(response?.data?.resp)
               setCampimetriaContext({
                  ojo_derecho: response?.data?.resp?.ojo_derecho,
                  ojo_izquierdo: response?.data?.resp?.ojo_izquierdo,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getMedicion = async () => {
      await http
         .get(`/oftalmologia/medicion/show/${idPaciente}`)
         .then((response) => {
            if (response.data.resp) {
               setMediContext({
                  precion_ojo_derecho: response?.data?.resp?.precion_ojo_derecho,
                  precion_ojo_izquierdo: response?.data?.resp?.precion_ojo_izquierdo,
                  fondo_ojo_derecho: response?.data?.resp?.fondo_ojo_derecho,
                  fondo_ojo_izquierdo: response?.data?.resp?.fondo_ojo_izquierdo,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const getTest = async () => {
      await http
         .get(`oftalmologia/test-rutina/get/${idPaciente}`)
         .then((response) => {
            if (response.data.resp) {
               setTestContext({
                  movimientos_oculares: response?.data?.resp?.movimientos_oculares,
                  ojo_izquierdo: {
                     parpados: response?.data?.resp?.ojo_izquierdo?.parpados,
                     conjuntiva: response?.data?.resp?.ojo_izquierdo?.conjuntiva,
                     cornea: response?.data?.resp?.ojo_izquierdo?.cornea,
                     camara_anterior: response?.data?.resp?.ojo_izquierdo?.camara_anterior,
                     iris: response?.data?.resp?.ojo_izquierdo?.iris,
                     cristalino: response?.data?.resp?.ojo_izquierdo?.cristalino,
                     refle_pupilares: response?.data?.resp?.ojo_izquierdo?.refle_pupilares,
                  },
                  ojo_derecho: {
                     parpados: response?.data?.resp?.ojo_derecho?.parpados,
                     conjuntiva: response?.data?.resp?.ojo_derecho?.conjuntiva,
                     cornea: response?.data?.resp?.ojo_derecho?.cornea,
                     camara_anterior: response?.data?.resp?.ojo_derecho?.camara_anterior,
                     iris: response?.data?.resp?.ojo_derecho?.iris,
                     cristalino: response?.data?.resp?.ojo_derecho?.cristalino,
                     refle_pupilares: response?.data?.resp?.ojo_derecho?.refle_pupilares,
                  }
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }



   const getDiagnostico = async () => {
      await http
         .get(`/oftalmologia/diagnostico/show_pac/${idPaciente}`)
         .then((response) => {
            if (response?.data?.resp) {
               setDiagContext({
                  diagnostico: response?.data?.resp?.diagnostico,
                  plan: response?.data?.resp?.plan,
                  conclusion: response?.data?.resp?.conclusion,
               });
            }
         })
         .catch(error => {
            if (error.response) {
               // El servidor respondió con un código de error (404, 500, etc.)
               console.error("Error de respuesta del servidor:", error.response.status);
            } else if (error.request) {
               // La solicitud se realizó, pero no se recibió respuesta
               console.error("No se recibió respuesta del servidor");
            } else {
               // Ocurrió un error antes de enviar la solicitud
               console.error("Error al enviar la solicitud:", error.message);
            }
         });
   }

   const guardarDatos = () => {
      dispatch(startLoading());
      setVisibleGuardar(false);
      fetchAllData();
   };

   const restablecerDatos = () => {
      dispatch(startLoading());
      setVisibleCancelar(false);
      fetchGetAllData();
   }

   const renderTabs = (area) => {
      const optionMapping = {
         50: (
            <TabPanel header="Antecedentes">
               <PageOftAnt />
            </TabPanel>
         ),
         51: (
            <TabPanel header="Agudeza Visual">
               <PageOftAgud />
            </TabPanel>
         ),
         52: (
            <TabPanel header="Refracción">
               <PageRef />
            </TabPanel>
         ),
         53: (
            <TabPanel header="Test Colores">
               <PageOftTestColor />
            </TabPanel>
         ),
         54: (
            <TabPanel header="Test de Profundidad">
               <PageOftTestProfundidad />
            </TabPanel>
         ),
         55: (
            <TabPanel header="Campimetría de Confrontación">
               <Campimetria campCon={campCon} />
            </TabPanel>
         ),
         56: (
            <TabPanel header="Medición">
               <PageOftMed />
            </TabPanel>
         ),
         57: (
            <TabPanel header="Test de Rutina">
               <PageOftTest />
            </TabPanel>
         ),
         58: (
            <TabPanel header="Diagnóstico">
               <PageOftDiag />
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      dispatch(startLoading());
      getDocumentos();
      GetPacienteOftalmo();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               oftalmologiaAreasContext?.map(area => {
                  return renderTabs(area)
               })
            }
         </TabView>
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />
         <ModalGuardarAtencion
            visible={visibleGuardar}
            onHide={() => setVisibleGuardar(false)}
            onYes={guardarDatos}
            onNo={handleConfirmNo}
            Atencion="Oftalmología"
         />
         <ModalCancelarAtencion
            visible={VisibleCancelar}
            Atencion="Oftalmología"
            onNo={() => setVisibleCancelar(false)}
            onYes={restablecerDatos}
            onHide={() => setVisibleCancelar(false)}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}
         />
         {oftalmologiaDetalleId.estado === '0' && (
            <RATimeView
               title="Oftalmología"
               AreaDetalleId={oftalmologiaDetalleId?.id}
               icon={iconOft}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={oftalmologiaCreate}
               textButton='Iniciar atención'
            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Oftalmología"
               AreaDetalleId={oftalmologiaDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={oftalmologiaDetalleId?.hora_final}
               VerHoraInicio={oftalmologiaDetalleId?.hora_inicio}
               VerfechaInicio={oftalmologiaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title="Oftalmología"
               AreaDetalleId={oftalmologiaDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={oftalmologiaDetalleId?.hora_final}
               VerHoraInicio={oftalmologiaDetalleId?.hora_inicio}
               VerfechaInicio={oftalmologiaDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageOft;
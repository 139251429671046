import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { useFormik } from "formik";
import AuthUser from "../../../../../../AuthUser";
import axios from "axios";

export default function ModalEditar({
  visibleEdit,
  setVisibleEdit,
  personal,
  setPersonal,
  showToast,
  tipoDocumentos,
  roles,
  getPersonal,
  api,
  data
}) {
  const { http } = AuthUser();
  const options = ['No es usuario', 'Es usuario'];
  const [value, setValue] = useState(options[0]);
  const [HabRol, setHabRol] = useState(false);

  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "2022-01-01",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    rol: "",
    usuario: "",
    empresa_cargo: "",
    pretension_salarial: "",
  };

  //03
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (datos) => {
      const formDataSend = new FormData();
      for (var key in datos) {
        formDataSend.append(key, datos[key]);
      }
      formDataSend.append("tipo_documento_id", datos?.tipo_documento?.id);
      datos.tipo_documento_id = datos?.tipo_documento?.id;

      formDataSend.append("rol_id", datos?.rol?.id);
      datos.rol_id = datos?.rol?.id;

      formDataSend.append("pretension_salarial", datos?.pretension_salarial);

      formDataSend.append("empresa_cargo_id", datos?.empresa_cargo?.id);
      datos.empresa_cargo_id = datos?.empresa_cargo?.id;

      datos.usuario = formik.values.usuario;

      http.put(`${api}/${datos.id}`, datos)
        .then(() => {
          getPersonal();
          HideDialogEdit();
          showToast(
            "success",
            "Actualizado correctamente",
            "Se creo correctamente el personal"
          );
        })
        .catch((error) => {
          console.error(error);
        });
    },
  });
  const HideDialogEdit = () => {
    setVisibleEdit(false);
  };

  const paqueteCreateDialogFooter = (
    <div className="flex w-full bot">
      <Button
        type="button"
        label="Cancelar"
        className="delete w-full"
        onClick={() => {
          HideDialogEdit();
        }}
      />
      <Button
        type="submit"
        label="Actualizar"
        className="p-button-success w-full"
        style={{
          backgroundColor: "#5555D8",
          borderColor: "#5555D8",
        }}
      />
    </div>
  );


  const updatePersonal = () => {
    formik.setValues({
      id: personal?.id,
      tipo_documento: personal?.persona?.tipo_documento,
      numero_documento: personal?.persona?.numero_documento,
      nombres: personal?.persona?.nombres,
      apellido_paterno: personal?.persona?.apellido_paterno,
      apellido_materno: personal?.persona?.apellido_materno,
      fecha_nacimiento: "",
      distrito_id: 2,
      celular: personal?.persona?.celular,
      correo: personal?.persona?.correo,
      direccion: personal?.persona?.direccion,
      rol: personal?.rol,
      usuario: personal?.user_rol,
      empresa_cargo: personal?.empresa_cargo,
      pretension_salarial: personal?.pretension_salarial || "",
    });

  };

  useEffect(() => {
    updatePersonal();
  }, [personal, roles, data]);

  const getNombreCompleto = (numdocumento, usuario) => {
    if (formik.values.tipo_documento !== "") {
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              nombres: res.data.nombres,
              apellido_paterno: res.data.apellidoPaterno,
              apellido_materno: res.data.apellidoMaterno,
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              rol: formik.values.rol,
              usuario: formik.values.usuario,
              empresa_cargo: formik.values.empresa_cargo,
              pretension_salarial: formik.values.pretension_salarial
            })
            getPersonal();
          })
          .catch((error) => {
            console.error("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      } else if (formik.values.tipo_documento.nombre === "RUC") {
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            getPersonal();
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              rol: formik.values.rol,
              usuario: formik.values.usuario,
              empresa_cargo: formik.values.empresa_cargo,
              pretension_salarial: formik.values.pretension_salarial
            })
          })
          .catch((error) => {
            console.error("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      }
    }
    else {
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }
  
  return (
    <div>
      <form
        id="personal-form-edit"
        className="personal-form-edit"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleEdit}
          style={{
            width: "450px",
            margin: "5%"
          }}
          appendTo={document.getElementById("personal-form-edit")}
          header={
            <>
              <div
                className="flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#EBEBFF",
                  width: "60px",
                  height: "60px",
                  borderradius: "10px",
                }}
              >
                <div className="flex justify-content-center align-items-center">
                  <img
                    src="https://cdn.discordapp.com/attachments/1078050726217011331/1090321659044573235/Group_1920.png"
                    alt="img"
                  />
                </div>
              </div>
              <h1 className="per">Editar Personal</h1>
            </>
          }
          dismissableMask
          className="p-fluid "
          footer={paqueteCreateDialogFooter}
          onHide={() => {
            HideDialogEdit();
          }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className="m-0">En esta seccion usted puede editar los datos para personal</p>
              <p className="per1">Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className="w-full"
                />
              </div>
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <div className="flex flex-1 w-full gap-2">
                <InputText
                  id="numero_documento"
                  name="numero_documento"
                  value={formik.values.numero_documento}
                  onChange={formik.handleChange}
                  required
                />
                <Button
                  type="button"
                  label="Validar"
                  style={{ background: "#07A34F", borderColor: "#07A34F" }}
                  onClick={() => getNombreCompleto(
                    formik.values.numero_documento,
                    formik.values.usuario
                  )}
                />
              </div>
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="name">Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres}
                className="w-full"
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">Apellidos Completos</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno + " " + formik.values.apellido_materno}
                onChange={formik.handleChange}
                className="w-full" />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="">Correo</label>
              <InputText
                id="correo"
                name="correo"
                value={formik.values.correo}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="">Celular</label>
              <InputText
                id="celular"
                name="celular"
                value={formik.values.celular}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>
          <div className="flex flex-column">
            <p>Pretensión salarial</p>
            <InputText
              id="pretension_salarial"
              name="pretension_salarial"
              value={formik.values.pretension_salarial}
              onChange={formik.handleChange}
              className="w-full" />
          </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
import React, { useState, useContext, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import "../../../Style/OftAntOculForm.css";
import Card1 from "./card1";
import { OftContext } from '../../../Page/Oftalmologia/OftContext';
import { RAContext } from '../../../Page/RutaAtencion/RAContext';
import VoiceButtonOft from '../../ButtonVoice/VoiceButtonOft';
import VoiceOFT from '../../ButtonVoice/VoiceButtonOft';

function OftAntOculForm() {

   let { pageViewContext, stateAtencion } = useContext(RAContext)
   const { anteContext, setAnteContext } = useContext(OftContext);

   const [otros, setOtros] = useState(anteContext ? anteContext.oft_oculares_otros.comentarios : null);
   const [comentCirugia, setComentCirugia] = useState(anteContext ? anteContext.ante_cirugia.comentario : null);
   const [comentDaltonismo, setComentDaltonismo] = useState(anteContext ? anteContext.ante_daltonismo.comentario : null);
   const [comentDis, setComentDis] = useState(anteContext ? anteContext.ante_discromatopsia.comentario : null);

   useEffect(() => {
      setAnteContext({
         ...anteContext,
         ante_cirugia: ({
            ...anteContext?.ante_cirugia,
            comentario: comentCirugia,
         }),
         ante_daltonismo: ({
            ...anteContext?.ante_daltonismo,
            comentario: comentDaltonismo,
         }),
         ante_discromatopsia: ({
            ...anteContext?.ante_discromatopsia,
            comentario: comentDis,
         }),
         oft_oculares_otros: ({
            ...anteContext?.oft_oculares_otros,
            comentarios: otros,
         }),
      })
   }, [otros, comentCirugia, comentDaltonismo, comentDis]);

   const handleCheckboxChange = (index) => {
      let oft_ocular_patologia = anteContext?.oft_ocular_patologia?.map((item, i) => {
         if (i === index) {
            return { ...item, select: !item.select };
         }
         return item;
      });

      setAnteContext(prevData => (
         {
            ...prevData,
            oft_ocular_patologia: oft_ocular_patologia
         }
      ));

   };

   const handleDropdownChange = (index, value) => {
      let oft_ocular_patologia = anteContext?.oft_ocular_patologia?.map((item, i) => {
         if (i === index) {
            return { ...item, afecta_a: value };
         }
         return item;
      });
      setAnteContext(prevData => (
         {
            ...prevData,
            oft_ocular_patologia: oft_ocular_patologia
         }
      ));

   };



   const handleDiscromatopsiaChange = (checked) => {
      setAnteContext({
         ...anteContext,
         ante_discromatopsia: ({
            ...anteContext?.ante_discromatopsia,
            select: !anteContext?.ante_discromatopsia?.select,
         }),
      });
   };

   const handleCheckboxValueChange = (value, checked) => {
      setAnteContext({
         ...anteContext,
         ante_discromatopsia: ({
            ...anteContext?.ante_discromatopsia,
            [value]: checked,
         }),
      });
   };

   const handleCheckboxChange18 = () => {
      setAnteContext({
         ...anteContext,
         ante_cirugia: {
            tipo_patologia_id: anteContext?.ante_cirugia?.tipo_patologia_id,
            select: !anteContext?.ante_cirugia?.select,
            afecta_a: anteContext?.ante_cirugia?.afecta_a,
            comentario: anteContext?.ante_cirugia?.comentario,
         },
      });
   };

   const handleDropdownChange18 = (index, e) => {
      setAnteContext({
         ...anteContext,
         ante_cirugia: {
            tipo_patologia_id: anteContext?.ante_cirugia?.tipo_patologia_id,
            select: anteContext?.ante_cirugia?.select,
            afecta_a: e,
            comentario: anteContext?.ante_cirugia?.comentario,
         },
      });
   };

   const handleCheckboxChange19 = () => {
      setAnteContext({
         ...anteContext,
         ante_daltonismo: {
            tipo_patologia_id: anteContext?.ante_daltonismo?.tipo_patologia_id,
            select: !anteContext?.ante_daltonismo?.select,
            afecta_a: anteContext?.ante_daltonismo?.afecta_a,
            comentario: anteContext?.ante_daltonismo?.comentario,
         },
      });
   };

   const handleDropdownChange19 = (index, e) => {
      setAnteContext({
         ...anteContext,
         ante_daltonismo: {
            tipo_patologia_id: anteContext?.ante_daltonismo?.tipo_patologia_id,
            select: anteContext?.ante_daltonismo?.select,
            afecta_a: e,
            comentario: anteContext?.ante_daltonismo?.comentario,
         },
      });
   };
   const handleCheckboxOtros = () => {
      setAnteContext({
         ...anteContext,
         oft_oculares_otros: {
            select: !anteContext?.oft_oculares_otros?.select,
            comentarios: anteContext?.oft_oculares_otros?.comentarios
         },
      });
   };


   return (
      <div className="flex flex-wrap align-items-center w/full AntOcular">
         {anteContext?.oft_ocular_patologia?.map((data, index) => (
            <div className="checkbox-dropdown-container flex w-full flex-1 Evalua-ocular1" key={index}>
               <Card1
                  index={index}
                  nombre={data.nombre}
                  onChange={() => handleCheckboxChange(index)}
                  checked={data.select}
                  value={data.afecta_a}
                  dropdownonchange={handleDropdownChange}
               />
            </div>
         ))}
         <div className="flex flex-column w-full contenedor-AntOcular-part2">
            <div className="checkbox-dropdown-container flex flex-wrap align-items-center cirugias-cont">
               <div className="flex ciru-gias">
                  <Card1
                     nombre={"Cirugías"}
                     onChange={handleCheckboxChange18}
                     checked={anteContext?.ante_cirugia?.select}
                     value={anteContext?.ante_cirugia?.afecta_a}
                     dropdownonchange={handleDropdownChange18}
                  />
               </div>
               <div className="w-full flex-1 cirugias-coment flex flex-row">
                  <VoiceButtonOft
                     value={comentCirugia}
                     onChange={setComentCirugia}
                     disabled={!anteContext?.ante_cirugia?.select || stateAtencion[pageViewContext].estado === 3}
                     placeholder="Comentario..."
                  >
                  </VoiceButtonOft>
               </div>
            </div>
            <div className="checkbox-dropdown-container flex flex-row w-full align-items-center daltonismo-cont">
               <div className="flex dalto-nismo">
                  <Card1
                     nombre={"Daltonismo"}
                     onChange={handleCheckboxChange19}
                     checked={anteContext?.ante_daltonismo?.select}
                     value={anteContext?.ante_daltonismo?.afecta_a}
                     dropdownonchange={handleDropdownChange19}
                  />
               </div>
               <div className="w-full flex-1 daltonismo-coment flex flex-row">

                  <VoiceOFT
                     value={comentDaltonismo}
                     onChange={setComentDaltonismo}
                     disabled={!anteContext?.ante_daltonismo?.select || stateAtencion[pageViewContext].estado === 3}
                     placeholder="Comentario..."
                  >
                  </VoiceOFT>
               </div>
            </div>
         </div>
         <div className="checkbox-dropdown-container flex flex-row w-full align-items-center cont-Discromatop">
            <div className="contenedor-texto checkbox-dropdown-container flex flex-row align-items-center discromat">
               {/* <Checkbox onChange={handleCheckboxChange} checked={discromatismo}></Checkbox> */}
               <Checkbox
                  onChange={(e) => handleDiscromatopsiaChange(e.checked)}
                  checked={anteContext?.ante_discromatopsia?.select}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
               <label htmlFor="ingredient1" className="ml-2">
                  Discromatopsia
               </label>
            </div>
            <div className="flex gap-3 contenedor-colors">
               <div className="flex align-items-center cl-verde">
                  <Checkbox
                     name="verde"
                     onChange={(e) => handleCheckboxValueChange('verde', e.checked)}
                     checked={anteContext?.ante_discromatopsia?.verde}
                     disabled={!anteContext?.ante_discromatopsia?.select || stateAtencion[pageViewContext].estado === 3}
                     className="rounded-checkbox"
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                     Verde
                  </label>
               </div>
               <div className="flex align-items-center cl-rojo">
                  <Checkbox
                     name="rojo"
                     onChange={(e) => handleCheckboxValueChange('rojo', e.checked)}
                     checked={anteContext?.ante_discromatopsia?.rojo}
                     disabled={!anteContext?.ante_discromatopsia?.select || stateAtencion[pageViewContext].estado === 3}
                     className="rounded-checkbox"
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                     Rojo
                  </label>
               </div>
               <div className="flex align-items-center cl-amarillo">
                  <Checkbox
                     name="amarillo"
                     onChange={(e) => handleCheckboxValueChange('amarillo', e.checked)}
                     checked={anteContext?.ante_discromatopsia?.amarillo}
                     disabled={!anteContext?.ante_discromatopsia?.select || stateAtencion[pageViewContext].estado === 3}
                     className="rounded-checkbox"
                  />
                  <label htmlFor="ingredient3" className="ml-2">
                     Amarillo
                  </label>
               </div>
            </div>
            <div className="w-full flex-1 daltonismo-coment flex flex-row" style={{ paddingLeft: "20px" }}>
               <VoiceButtonOft
                  value={comentDis}
                  onChange={setComentDis}
                  disabled={!anteContext?.ante_discromatopsia?.select || stateAtencion[pageViewContext].estado === 3}
                  placeholder="Comentario..."
               >
               </VoiceButtonOft>
            </div>
         </div>
         <div className="contenedor-texto checkbox-dropdown-container flex flex-row align-items-center otros-cont w-full">
            <div className="checkbox-dropdown-container flex flex-row align-items-center gap-3 check-otros">
               <Checkbox onChange={handleCheckboxOtros}
                  checked={anteContext?.oft_oculares_otros?.select}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
               <h1 style={{ fontWeight: "400", fontSize: "14px" }}>Otros</h1>
            </div>
            <div className="flex flex-column w-full">
               <VoiceOFT
                  value={otros}
                  onChange={setOtros}
                  disabled={!anteContext?.oft_oculares_otros?.select || stateAtencion[pageViewContext].estado === 3}
                  placeholder="Otros antecedentes..."
               >
               </VoiceOFT>
            </div>

         </div>
      </div>
   );
}

export default OftAntOculForm; 

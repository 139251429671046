import React, { useState } from 'react';
import { InputText } from "primereact/inputtext";

export default function Psicologia({ Cpsp, setCpsp }) {

   const [show, setShow] = useState();

   const handleClick = (event) => {
      setShow(!show)
   }


   return (
      <div className="options mx-3">
         <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <label
               htmlFor="RNM"
               style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#4545bc"
               }}
            >
               CPsP:{" "}
            </label>
            <InputText
               // keyfilter="int"
               placeholder="Código"
               style={{ width: "400px", marginLeft: "6px" }}
               value={Cpsp.toUpperCase()}
               onChange={(e) => { setCpsp(e.target.value.toUpperCase()) }}
            ></InputText>
         </div>
      </div>
   )
}

import React from 'react';
import RAResultadosTable from "../../../Components/RutaAtencion/Resultados/RAResultadosTable";

import '../../../Style/RutaAtencion/ruta_atencion.css'
import { useParams } from 'react-router-dom';


const PageRAResultados = () => {
  const { tipo } = useParams();
  return (
      <main className="bg-custom-white p-3">
        <RAResultadosTable tipo={tipo} />
      </main>
  );
};

export default PageRAResultados;
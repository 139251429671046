import { Skeleton } from 'primereact/skeleton';

const SkeletonEmpresa = ({pageSize}) => {
    const skeletons = [];

    for (let index = 0; index < pageSize; index++) {
        skeletons.push(
            <Skeleton
                key={index}
                className="h-full flex flex-column gap-2 justify-content-between border-round-lg p-2 shadow-2"
            ></Skeleton>
        );
    }

    return <div className="empresas_grid">{skeletons}</div>;
};

export default SkeletonEmpresa
import React, { useState } from "react";
import "../../../../Style/ProductoyServicios/EstiloTablaServicios.css";
import Check from "../../../../Imagenes/img/CHECKIcon.svg";
import { Button } from "primereact/button";
import { EliminarModal, ModalEditarPaquete } from '../index'
import { ScrollPanel } from 'primereact/scrollpanel';

function TablaServicios({ paquete, sortOrder, toggleSortOrder, eliminar, transformedNodes, showToast, update }) {

   const [visibleEditar, setVisibleEditar] = useState(false);  
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [AccionPaquete, setAccionPaquete] = useState();

   const itemsPerPage = 7; // Número de elementos por página
   const [currentPage, setCurrentPage] = useState(0);
   const pageCount = Math.ceil(paquete.length / itemsPerPage);
   const startIndex = currentPage * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
   const currentItems = paquete.slice(startIndex, endIndex);

   // Ordenar

   const sortedItems = [...currentItems].sort((a, b) => {
      const nameA = a.paquete.nombre?.toLowerCase() || '';
      const nameB = b.paquete.nombre?.toLowerCase() || '';

      if (sortOrder === "asc") {
         return nameA.localeCompare(nameB);
      } else {
         return nameB.localeCompare(nameA);
      }
   });

   //Obtener Fecha 

   function obtenerFechaActual() {
      const fechaActual = new Date();
      return fechaActual;
   }





   const [id_registroTable, setid_registroTable]= useState();
   return (
      <div className="card Content_Tabla_Servicios_Breegma gap-3 flex flex-column justify-content-between " style={{ padding: "10px", marginTop: "20px", minHeight: '550px', overflow: 'auto' }}>

         <ScrollPanel className="custombar1" style={{ width: '100%', height: '100%',overflowX:'hidden' }} >

            <table className="Tabla_Servicios_Breegma w-full">
               <thead>
                  <tr>
                     <th className="px-2">Item</th>
                     <th className="px-4">Paquetes</th>
                     <th className="px-4">Precio Mensual</th>
                     <th className="px-4">Precio Anual</th>
                     {/* <th className="px-4">N° de Clientes</th> */}
                     <th className="px-4">Fecha de creación</th>
                     <th className="px-4">Acción</th>
                  </tr>
               </thead>
               <tbody>
                  {sortedItems.map((item, key) => (
                     <tr key={key}>
                        <td data-label="Item" style={{}}>
                           {key + 1}
                        </td>
                        <td data-label="Paquetes" className="flex justify-content-center text-left" >
                           <label className="white-space-nowrap overflow-hidden text-overflow-ellipsis" style={{}}>
                              {item.paquete.nombre}
                           </label>
                        </td>
                        <td data-label="Precio Mensual" className="">
                           <label className="bg-orange-100 text-orange-500 font-bold text-sm py-1 px-1 border-round-sm">
                              S/ {item.paquete.precio_mensual}
                           </label>
                        </td>
                        <td data-label="Precio Anual" className="">
                           <label className="bg-cyan-100 text-cyan-500 font-bold text-sm py-1 px-1 border-round-sm">
                              S/ {item.paquete.precio_anual}
                           </label>
                        </td>
                        {/* <td data-label="N° de Clientes" className="flex justify-content-center text-left">
                           <div className="bg-purple-100 flex align-items-center border-round-xl">
                              <img src={Check} alt="" className="py-2 px-1" />
                              <label htmlFor="" className="text-purple-500 font-bold text-sm py-1 px-1">
                                 0 trabajadores
                              </label>
                           </div>
                        </td> */}

                        <td data-label="Fecha de creación">
                           <div className="Fecha_de_creación flex flex-row gap-1">
                              <i className="pi pi-calendar"></i>
                              {obtenerFechaActual().toLocaleDateString()}

                           </div>
                        </td>
                        <td data-label="Acción">
                           <div className="Fecha_de_creación flex flex-row gap-1">
                              <Button id={"BotonEditarPaqueteServiciosBregma"+key} onClick={() => {
                                 setVisibleEditar(true);
                                 setAccionPaquete(item);
                                 setid_registroTable(key);
                              }}><i className="pi pi-pencil"></i></Button>
                              <Button onClick={() => {
                                 setVisibleEliminar(true);
                                 setAccionPaquete(item)
                              }}><i className="pi pi-trash" ></i></Button>
                           </div>
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
            {/* Paginación */}
            <div className="flex gap-3 align-items-center justify-content-end">
               <Button
                  style={{ background: "rgba(221, 204, 255, 0.844)", border: "transparent", width: "35px", height: "35px", justifyContent: "center", alignItems: "center" }}
                  label="<"
                  onClick={() => {
                     if (currentPage > 0) {
                        setCurrentPage(prevPage => prevPage - 1);
                     }
                  }}
               />
               {currentPage + 1}
               <Button
                  style={{ background: "rgba(221, 204, 255, 0.844)", border: "transparent", width: "35px", height: "35px", justifyContent: "center", alignItems: "center" }}
                  label=">"
                  onClick={() => {
                     if (currentPage < pageCount - 1) {
                        setCurrentPage(prevPage => prevPage + 1);
                     }
                  }}
               />
            </div>
            <EliminarModal close={setVisibleEliminar} estado={visibleEliminar} eliminar={eliminar} paquete={AccionPaquete} />
            <ModalEditarPaquete update={update} showToast={showToast} servicios={transformedNodes} close={setVisibleEditar} estado={visibleEditar} data={[AccionPaquete]} index={id_registroTable} />
         </ScrollPanel >

      </div>

   );
}

export default TablaServicios;
import React, { useState, useEffect, useContext } from 'react';
import { ProductPruebas } from '../../../Services/ProductPruebas';
import { Dropdown } from 'primereact/dropdown';
import { RAContext } from "../../RutaAtencion/RAContext";
import { MGContext } from '../MGProvider';
// Importa el componente VoiceInputTextarea
import VoiceButton from "../../../Components/ButtonVoice/VoiceButtonMGAltura";

export default function Pruebas() {
   let { stateAtencion, pageViewContext } = useContext(RAContext);

   let { examenAlturaContext, setExamenAlturaContext } = useContext(MGContext);

   const [pruebasEspecificas, setPruebasEspecificas] = useState(examenAlturaContext?.med_prueba?.length > 0 && examenAlturaContext?.med_prueba || ProductPruebas.map((patologia) => {
      return {
         patologia_id: patologia.id,
         resultado: "Normal",
         observacion: ""
      }
   }))

   //Opciones para el dropdown
   const opciones = [
      { label: 'Normal', code: 'N' },
      { label: 'Anormal', code: 'A' },
      { label: 'No realizada', code: 'NR' },

   ];

   // const opciones02 = [
   //    { label: 'No realizada', code: 'NR' },
   //    { label: 'Normal', code: 'N' },
   // ];

   const limitedOptions = [
      { label: 'Normal', code: 'N' },
      { label: 'No realizada', code: 'NR' },

   ];

   const handlePruebasEspecificas = (index, value, atributo) => {
      const updatedPruebasEspecificas = [...pruebasEspecificas];
      updatedPruebasEspecificas[index] = {
         ...updatedPruebasEspecificas[index],
         [atributo]: value
      };
      setPruebasEspecificas(updatedPruebasEspecificas);
   };
   const shouldLimitOptions = (code) => {
      const limitedOptionsCodes = [
         'Sustentación mediante un pie a un ángulo de 15 grados.',
         'Caminar libre sobre recta de 3 m (sin desvío).',
         'Caminar libre ojos vendados 3 m (sin desvío).',
         'Caminar libre ojos vendados punta talón 3m (sin desvío).',
         'Rotar sobre silla y luego verificar equilibrio de pie',
         'ADCADOCOQUINESIA DIRECTA',
         'ADCADOCOQUINESIA CRUZADA',
         'Recibió entrenamiento para trabajo sobre nivel'
      ];
      return limitedOptionsCodes.includes(code);
   };

   useEffect(() => {
      setExamenAlturaContext({
         ...examenAlturaContext,
         med_prueba: pruebasEspecificas
      })
   }, [pruebasEspecificas])

   return (
      <div>
         <table className="excel-table9">
            <thead>
               <tr>
                  <th style={{ width: "30%" }}>Patología</th>
                  <th style={{ width: "20%" }}>Resultado</th>
                  <th style={{ width: "50%" }}>Observación</th>
               </tr>
            </thead>
            <tbody>
               {ProductPruebas.map((product, index) => (
                  <tr key={product.id}>
                     <td
                        style={{
                           overflow: "hidden",
                           fontSize: "15px",
                           wordWrap: "break-word",
                           whiteSpace: "pre-wrap",
                           textAlign: "start"
                        }}
                     >{product.code}</td>
                     <td>
                        <Dropdown
                           value={opciones.filter(opcion => pruebasEspecificas[index]?.resultado === opcion.label)[0]}
                           onChange={(e) => handlePruebasEspecificas(index, e.value.label, 'resultado')}
                           options={(shouldLimitOptions(product.code)) ? limitedOptions : opciones} // Aquí se decide qué opciones mostrar
                           disabled={stateAtencion[pageViewContext].estado === 3}
                           optionLabel="label"
                           placeholder="Normal"
                           className="w-full md:w-8rem"
                        />
                     </td>
                     <td className='w-full'>
                        <VoiceButton
                           value={pruebasEspecificas[index]?.observacion}
                           onChange={(newText) => handlePruebasEspecificas(index, newText, 'observacion')}
                           placeholder="Comentario"
                           disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                     </td>
                  </tr>
               ))}
            </tbody>
         </table>
      </div>
   );
}

import React, { useState, useEffect } from 'react';
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Chica.png';
import Sombra from '../../../Images/Pure/MenuPrincipal/Img/sombra.png';
import icon1 from '../../../Images/Pure/MenuPrincipal/img-icons/opera2.png';
import icon2 from '../../../Images/Pure/MenuPrincipal/img-icons/ser1.png';
import icon3 from '../../../Images/Pure/MenuPrincipal/img-icons/libro.png';
import '../../../Style/Botones.css';

function PageContabilidadB() {
  const [showAdditionalButtons, setShowAdditionalButtons] = useState(false);
  const [showComponents, setShowComponents] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowAdditionalButtons(screenWidth <= 750);
      setShowComponents(screenWidth > 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='flex flex-nowrap flex-column w-full h-full'>
      <div className='arriba'>
        <span className='tit'>Contabilidad</span>
        <br />
        <span className='con'>En este módulo usted podrá generar el costo por atención y la facturación de sus productos y/o servicios.</span>
      </div>
      <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center h-full'>
        {showAdditionalButtons && (
          <>
            {/* <Botones Link='/contabilidad/costoatencion' img={icon1} span='Costo por Atencion' button='button b3' />
            <Botones Link='/contabilidad/facturacion' img={icon2} span='Facturacion' button='button b4' />
            <Botones Link='/contabilidad/mispagos' img={icon3} span='Mis pagos' button='button b5' /> */}
          </>
        )}
        {showComponents && (
          <>
            {/* <Botones Link='/contabilidad/costoatencion' img={icon1} span='Costo por Atencion' button='button b3' />
            <Botones Link='/contabilidad/facturacion' img={icon2} span='Facturacion' button='button b4' />
            <Botones Link='/contabilidad/mispagos' img={icon3} span='Mis pagos' button='button b5' /> */}
            <Imagenes src={Chica} className='logo1' />
            <Imagenes src={Sombra} className='som' />
            <Eclipses />
          </>
        )}
      </div>
    </div>
  );
}

export default PageContabilidadB;
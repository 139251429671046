import React, { useEffect, useRef, useState } from 'react'
import { Toast } from 'primereact/toast';
import { useDispatch } from "react-redux";

//Componente general
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";

//Componente único
import TablaRolComponent from '../../../../Components/RecursosHumanos/Rol/Components/TablaRol';

//Imágenes
import AuthUser from '../../../../AuthUser';
import imgRoles from "../../../../Images/roles/roles.svg";

//Estilo único
import '../../../../Style/estilospagrol.css';

function PageRolesEmpresa() {
    const dispatch = useDispatch();

    const { http } = AuthUser();
    const toast = useRef(null);
    const [key, setkey] = useState(0);
    const [GetLocales, setGetLocales] = useState([]);
    const [roles, setRoles] = useState([]);
    const [Idroles, setIdRoles] = useState({});
    const [accesos, setAccesos] = useState([]);
    const [LocalAsignadoBD, setLocalAsignadoBD] = useState(null);

    //Función para notificar acciones
    const showToast = (type, title, detail) => {
        toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
        });
    }

    //Integraciones get
    const getAllRoles = async () => {
        try {
            const response = await http.get("/roles/get")
            if (response?.data?.data) {
                setRoles(response.data.data);
                //Actualiza el estado de Idroles con el primer rol de la lista
                setIdRoles(response.data.data[0]);
                //Actualiza el estado de key con el valor de key + 1
                setkey(key + 1);
            }
        } catch (error) {
            showToast("error", "Error en la obtención de roles", "Contáctese con Soporte Técnico.")
            console.error("Error en la obtención de roles", error);
        };
    };

    const getAccesos = async () => {
        try {
            const response = await http.get("/accesos/get")
            if (response?.data?.data) {
                setAccesos(response.data.data);
            }
        } catch (error) {
            showToast("error", "Error en la obtención de accesos", "Contáctese con Soporte Técnico.")
            console.error("Error en la obtención de accesos", error);
        };
    };

    const ObtenerLocales = (ruta) => {
        http
            .get(`${ruta}`)
            .then((response) => {
                if (response.data.data) {
                    setGetLocales(response.data.data);
                }
            }).catch((error) => {
                showToast("error", "Error", "Contáctese con Soporte Técnico.")
                console.error("Error al obtener locales", error);
            });
    }

    //API PARA OBTENER LOCAL ASIGNADO AL ROL
    const GetLocalAsignado = async (id) => {
        dispatch(startLoading());
        try {
            const response = await http.get(`/roles/show/${id}`);
            if (response?.data?.resp || response?.data?.data) {
                if (response?.data?.data?.bregma_local != null) {
                    setLocalAsignadoBD(response?.data?.data?.bregma_local?.nombre)
                } else if (response?.data?.resp?.bregma_local != null) {
                    setLocalAsignadoBD(response?.data?.resp?.bregma_local?.nombre)
                }
                else if (response?.data?.data?.clinica_local != null) {
                    setLocalAsignadoBD(response?.data?.data?.clinica_local?.nombre)
                } else if (response?.data?.resp?.clinica_local != null) {
                    setLocalAsignadoBD(response?.data?.resp?.clinica_local?.nombre)
                }
                else if (response?.data?.data?.empresa_local != null) {
                    setLocalAsignadoBD(response?.data?.data?.empresa_local?.nombre)
                } else if (response?.data?.resp?.empresa_local != null) {
                    setLocalAsignadoBD(response?.data?.resp?.empresa_local?.id)
                } else {
                    setLocalAsignadoBD(null)
                }
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            showToast('error', 'Error', 'Contactar a soporte técnico')
            console.error('Error al obtener local asignado', error);
        } finally {
            dispatch(endLoading());
        }
    }

    //Integraciones post
    const asignarRoles = async (data) => {
        dispatch(startLoading());
        try {
            const response = await http.post("roles/asignar", data);
            if (response?.data?.resp) {
                getAllRoles();
                showToast("success", "Acceso Asignado", "Se asignaron correctamente los accesos")
            } else if (response?.data?.error) {
                showToast("error", "Error al asignar rol", response?.data?.error)
            }
        } catch (error) {
            console.error("Error al asignar rol", error);
            showToast('error', 'Error al asignar rol', 'Contactar a soporte técnico')
        } finally {
            dispatch(endLoading());
        }
    }

    const handleCreateRol = async (rol) => {
        dispatch(startLoading());
        try {
            const response = await http.post(`roles/create`, rol)
            if (response?.data?.resp) {
                await Promise.all([
                    getAllRoles()
                ]);
                showToast("success", "Rol creado", "Se ha creado un nuevo rol correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error al crear rol", response?.data?.error)
            }
        } catch (error) {
            console.error("Error al crear rol", error);
            showToast("error", "Error al crear rol", "Contáctese con Soporte Técnico.")
        } finally {
            dispatch(endLoading());
        }
    };

    //Integraciones put
    const handleEditRol = async (rol) => {
        dispatch(startLoading());
        try {
            const response = await http.put(`roles/update/${rol.id}`, rol)
            if (response?.data?.resp) {
                await Promise.all([
                    getAllRoles()
                ]);
                showToast("success", "Rol Editado", "El rol se editó correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error al editar rol", response?.data?.error)
            }
        } catch (error) {
            console.error("Error al editar rol", error);
            showToast("error", "Error al editar rol", "Contáctese con Soporte Técnico.")
        } finally {
            dispatch(endLoading());
        }
    };

    //API PARA ASIGNAR LOCALES SEGÚN ROLES
    const AsignarLocales = async (idrol, data) => {
        dispatch(startLoading());
        try {
            const response = await http.put(`/roles/locales/asignar/${idrol}`, data);
            if (response?.data?.resp || response?.data) {
                getAllRoles();
                showToast('success', 'Local asignado', response?.data?.resp)
            } else if (response?.data?.error) {
                showToast('error', 'Error al asignar local', response.data.error)
            }
        } catch (error) {
            console.error('Error al asignar local', error);
            showToast('error', 'Error al asignar local', 'Contactar a soporte técnico')
        } finally {
            dispatch(endLoading());
        }
    }

    //Integraciones delete
    const handleDeleteRol = async (id) => {
        dispatch(startLoading());
        try {
            const response = await http.delete(`/roles/delete/${id}`)
            if (response?.data?.resp) {
                await Promise.all([
                    getAllRoles()
                ]);
                showToast("success", "Rol eliminado", "El rol se eliminó correctamente", response?.data?.resp)
            } else if (response?.data?.error) {
                showToast("error", "Error al eliminar rol", response?.data?.error)
            }
        } catch (error) {
            console.error("Error al eliminar rol", error);
            showToast("error", "Error al eliminar rol", "Contáctese con soporte técnico.")

        } finally {
            dispatch(endLoading());
        }
    };

    //CONDICIONAL: SEGÚN EL ID DEL ROL SE ASIGNARÁ LA RUTA PARA OBTENER LOS LOCALES DE ESE USUARIO
    const ObtenerLocalesCondicional = () => {
        if (Idroles.bregma_id != null) {
            ObtenerLocales(`/bregma/local/show`);
        }
        else if (Idroles.clinica_id != null) {
            ObtenerLocales(`clinica/recursoshumanos/local/get`);
        } else if (Idroles.empresa_id != null) {
            ObtenerLocales(`/empresa/local/show`);
        }
    }

    //Reúne los get para cargar los datos
    const fetchAllData = async () => {
        try {
            await Promise.all([
                getAllRoles(),
                getAccesos(),
            ]);
        } catch (error) {
            console.error('Error al cargar datos:', error);
        } finally {
            dispatch(endLoading());
        }
    }

    //Monta el componente
    useEffect(() => {
        dispatch(startLoading());
        fetchAllData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="table1 flex w-full flex-wrap  tabla-rol flex-row pl-7 pr-6 LPFS">
            <div className="section-one flex flex-column flex-1 pr-4 w-8">
                <h1 className="tit">Roles</h1>
                <p className="con">Crea roles con acceso a Bregma</p>

                {/* Envío de funciones a la tablaRol mediante props */}
                <TablaRolComponent
                    key={key}
                    roles={roles}
                    rolUpdate={handleEditRol}
                    rolCreate={handleCreateRol}
                    rolDelete={handleDeleteRol}
                    getRoles={getAllRoles}
                    accesos={accesos}
                    asignarRoles={asignarRoles}
                    GetLocales={GetLocales}
                    setGetLocales={setGetLocales}
                    ObtenerLocalesCondicional={ObtenerLocalesCondicional}
                    AsignarLocales={AsignarLocales}
                    LocalAsignadoBD={LocalAsignadoBD}
                    GetLocalAsignado={GetLocalAsignado}
                />
            </div>

            {/* Imagen de roles */}
            <div className="section-two Ppof pl-6 padding-top">
                <img src={imgRoles} alt="roles" />
            </div>
            { }
            { }
            <Toast ref={toast} />
        </div>
    );
}

export default PageRolesEmpresa


import React from 'react'
import '../../../Style/ProductoyServicios/Botones_B.css'
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Doc.png';
import im1 from '../../../Images/Pure/MenuPrincipal/img-icons/mua.png';
import im2 from '../../../Images/Pure/MenuPrincipal/img-icons/pro1.png';
import im3 from '../../../Images/Pure/MenuPrincipal/img-icons/ser1.png';
import im4 from '../../../Images/Pure/MenuPrincipal/img-icons/libro.png';



function PageProductosServiciosB({ accesoProp }) {
   const list_images = (url) => {
      switch (url) {
         case "productos":
            return im2;
         case "servicios":
            return im3;
         case "terminosycondiciones":
            return im4;
         default:
            return im1;
      }
   }

   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className='arriba'>
            <span className='tit'>Productos y Servicios</span><br />
            <span className='con'>En este módulo usted podrá crear los productos y servicios ofertados a sus clientes.</span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
            {
               accesoProp.filter(acceso => acceso.url !== "productos").map((acceso, key) => (
                  <Botones
                     key={key}
                     Link={acceso.url}
                     img={list_images(acceso.url)}
                     span={acceso.label === "Gestión de Proformas" ? "Gestión de Proformas" : acceso.label}
                     button={"button b" + (key + 1)}
                  />
               ))

               /*accesoProp.map((acceso, key) => {
                  return <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b" + (key + 1)}
                     />
                  </>
               })*/
            }
            {/* <Botones
               Link="/productosyservicios/gestiondeproformas"
               img={im1}
               span="Gestión de Proformas Bregma"
               button="button b2"
            />
            <Botones
               Link='/productosyservicios/productos'
               img={im2}
               span="Productos"
               button="button b3"
            />
            <Botones
               Link='/productosyservicios/servicios'
               img={im3}
               span="Servicios"
               button="button b4"
            />
            <Botones
               Link='/productosyservicios/terminosycondiciones'
               img={im4}
               span="Terminos y Condiciones"
               button="button b5"
            /> */}

            <Imagenes
               src={Chica}
               className="logo3"
            />

            <Eclipses />
         </div>
      </div>
   );
}

export default PageProductosServiciosB;
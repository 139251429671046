import React, { useState } from "react";
import "../../../Style/MedicinaGeneral/ExplPalpacion.css"
import ComponentTableE from "./ComponentTableE";
import ComponentTableE2 from "./ComponentTableE2";
export default function ComponentExpl(props) {

    return (
        //Contenedor
        <div style={{ width: '100%', overflowX:"auto" }}>
            <div className="flex flex-wrap flex-column gap-5">
                <div>
                    <ComponentTableE />
                </div>
                <div>
                    <ComponentTableE2 />
                </div>
            </div>
        </div>
    );
}

import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

const DialogArchivoComponent = ({ handleCreate, file, prueba, setFile, nombreArchivo, setNombreArchivo ,viewFile=true}) => {
   const [timestamp, setTimestamp] = useState(Date.now());
   const inputFile = useRef(null);
   //Funcion que hace que que los archivos no se almacenen en cache.
   const handleCreateUrl=()=>{
      const url = file ? URL.createObjectURL(file) : (prueba?.archivo ? prueba.archivo : '');
      const urlWithTimestamp = `${url}${url.includes('?') ? '&' : '?'}nocache=${timestamp}`;
      return urlWithTimestamp

   }

   const [visibleDialog, setVisibleDialog] = useState(false);

   const handleArchivoCargado = (event) => {
      const archivoCargado = event.target.files[0];
      if (archivoCargado) {
         setFile(archivoCargado)
         setNombreArchivo(archivoCargado.name);
      } else {
         setNombreArchivo("");
      }
   };

   const abrirSelectorArchivos = () => {
      inputFile.current.click();
   };

   const handlerdeletefile = () => {
      setNombreArchivo("")
      setFile(null)
   }

   const abrirDialog = () => {
      setVisibleDialog(true);
   };

   return (
      <div className="pt-3" style={{ width: "90%", margin: "auto" }} >
         {viewFile &&( <div
            className="flex flex-row gap-2"
            style={{ alignItems: "center" }}
         >
            <input
               type="file"
               ref={inputFile}
               style={{ display: "none" }}
               onChange={handleArchivoCargado}
            />
            <span>Nombre de Archivo</span>
            <InputText
               type="text"
               value={nombreArchivo}
               readOnly
               style={{
                  width: "800px",
                  height: "40px",
               }}
            />
            <Button
               icon="pi pi-upload"
               className="upload"
               onClick={abrirSelectorArchivos}
            />
            <Button icon="pi pi-trash"
               onClick={handlerdeletefile}
               className="trash" />
            <Button
               className="search"
               onClick={abrirDialog}
               icon="pi pi-search"
            >
            </Button>
         </div>)}
         
         <div className="flex pt-3 pb-3" style={{ justifyContent: 'end' }}>
            <div className="flex gap-3">
               <Button label="Grabar" onClick={handleCreate} icon="fas fa-save" />
               <Button label="Recuperar" icon="fas fa-undo" />
            </div>
         </div>
         <Dialog
            header="Vista previa del PDF"
            visible={visibleDialog}
            style={{ width: '70vw' }}
            onHide={() => setVisibleDialog(false)}
         >
            <iframe
               src={handleCreateUrl()}
               width="100%"
               height="500px"
               title="Vista previa del PDF"
            />
         </Dialog>
      </div>
   );
}

export default DialogArchivoComponent;


import React from 'react';
import "../../../Style/Reclutamiento/CSSCard.css";
import Logo from "../../../Images/Reclutaminento/img/ICONOTORRE.png";
import { Divider } from 'primereact/divider';

export default function CardClinica(props) {
    return (
        <div className='flex flex-column bg-white shadow-1 border-round-lg h-10rem p-2'>
            <div className='flex flex-wrap gap-3 align-items-center'>
                <div className='flex flex-column justify-content-center align-items-center' style={{ width: '62.18px', height: '62.18px', borderRadius: '50%', background: '#EBEBFF' }}>
                    <img src={Logo} alt="" style={{ width: '41.33px' }} />
                </div>
                <div className='flex flex-column gap-1'>
                    <span style={{ fontWeight: '700', fontSize: '16px', color: '#5555D8' }}>
                        Clínica {props.nombreClinica}
                    </span>
                    <span style={{ fontWeight: '400', fontSize: '14px', color: '#7B8794' }}>
                        Empresa de salud
                    </span>
                </div>
            </div>
            <Divider />
            <div className='flex flex-wrap gap-3'>
                <div className='flex flex-column w-full h-2rem justify-content-center align-items-center border-round-lg  font-bold text-base' style={{ background: props.estado === 'Habilitado' ? '#E0F5FD' : '#FFECEC' }}>
                    <span style={{ color: props.estado === 'Habilitado' ? '#64CBF4' : '#E54444' }}>{props.estado}</span>
                </div>
                <div className='flex-1'>
                    <button className='w-full h-2rem  border-round-lg font-bold  text-base border-none' style={{ background: props.estado === 'Habilitado' ? '#F6F2FC' : 'rgba(123, 135, 148, 0.15)' }}>
                        <span style={{ color: props.estado === 'Habilitado' ? '#8C63E3' : '#7B8794' }}>Seleccionar</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

import React, { useContext} from 'react';
import Table from "../../Table/Table";
import { Column } from "primereact/column";
import { Button } from 'primereact/button';
import { PsicologiaContext } from '../../../Page/Psicologia/PsicologiaContext';
import { RAContext } from '../../../Page/RutaAtencion/RAContext';

const PsiExpLabTable = ({ setVisible, setVisibleDelete, setSelectIndice, setVisibleEdit }) => {
  
  const { expLabContext } = useContext(PsicologiaContext)
  let { pageViewContext, stateAtencion } = useContext(RAContext)


  //funcion opara seleccionar indice y visualizar el modal de eliminar
  const deleteExpLab = (rowIndex) => {
    setSelectIndice(rowIndex.rowIndex);
    setVisibleDelete(true);
  };

  //funcion opara seleccionar indice y visualizar el modal de editar
  const editExperiencia = (rowIndex) => {
    setSelectIndice(rowIndex.rowIndex);
    setVisibleEdit(true);
  }

  //cuerpo de item
  const indice = (rowData,rowIndex) => {
    return (
      rowIndex.rowIndex + 1
    )
  }

  //cuerpo de las acciones
  const botones = (rowData, rowIndex) => {
    return (
      <div className='flex gap-3'>
        <Button 
          icon='pi pi-pencil text-xl' 
          className='editar1' 
          onClick={() => editExperiencia(rowIndex)}
          disabled={stateAtencion[pageViewContext].estado === 3} />
        <Button 
          icon='pi pi-trash text-xl' 
          className='eliminar1' 
          onClick={() => deleteExpLab(rowIndex)}
          disabled={stateAtencion[pageViewContext].estado === 3} />
        
      </div>
    )
  }

  return (
    <>
      <div className="desktop-exp-lab">
        <Table
          title="Historial"
          headerEnabled={false}
          createLabel="Añadir Experiencia"
          // onInputSearch={(e) => setGlobalFilter(e.target.value)}
          // valueGlobalFilter={globalFilter}
          // data={clinicas}
          // onClickRefresh={() => getData()}
          data={expLabContext}
          onClickCreate={() => setVisible(true)}
          disabled={stateAtencion[pageViewContext].estado === 3}
        >
          <Column className='text-center' body={indice} header="Ítem" />
          <Column className='text-center' field="fecha_ingreso" header="Fecha ingreso" />
          <Column className='text-center' field="fecha_salida" header="Fecha retiro" />
          <Column className='text-center' field="nombre_empresa" header="Nombre empresa" />
          <Column className='text-center' field="act_empresa" header="Act. empresa" />
          <Column className='text-center' field="puesto" header="Puesto" />
          <Column className='text-center' field="labor_trabajo" header="Labor trabajo" />
          <Column className='text-center' field="causa_retiro" header="Causa de retiro" />
          <Column className='text-center' field="" header="Acción" body={botones} />
        </Table>
      </div>

      <div className="movil-exp-lab">
        <Table
          title="Historial"
          headerEnabled={false}
          createLabel="Añadir Experiencia"
          // onInputSearch={(e) => setGlobalFilter(e.target.value)}
          // valueGlobalFilter={globalFilter}
          // data={clinicas}
          // onClickRefresh={() => getData()}
          data={expLabContext}
          onClickCreate={() => setVisible(true)}
        >
          <Column className='text-center' field="itemIndex" header="Ítem" />
          <Column field="fecha_ingreso" header="Fecha ingreso" />
          <Column field="fecha_salida" header="Fecha salida" />
          <Column field="nombre_empresa" header="Nombre empresa" />
          <Column field="act_empresa" header="Act. Empresa" />
          <Column field="puesto" header="Puesto" />
          <Column field="labor_trabajo" header="Labor trabajo" />
          <Column field="causa_retiro" header="Causa de retiro" />
          <Column className='text-center' field="" header="Acción" body={botones} />
        </Table>
      </div>
    </>
  );
};

export default PsiExpLabTable;
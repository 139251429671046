import React, { useEffect, useState } from 'react'
import RAResultadosTable from '../../Components/RutaAtencion/Resultados/RAResultadosTable';
import AuthUser from '../../AuthUser';

const PageMenuPsicologia = () => {
    const { http } = AuthUser();
    const [pacientes, setPacientes] = useState([]);
    const getPacientes = () => {
       http
          .get("/atencion/paciente/area/2")
          .then((response) => {
             setPacientes(response.data.data);
          })
          .catch((error) => {
             console.error(error);
          });
    };
 
    useEffect(() => {
       getPacientes();
    }, [])
 
    return (
       <div>
          <RAResultadosTable
             pacientes={pacientes}
             nombre={"Psicología"}
          ></RAResultadosTable>
       </div>
    )
}

export default PageMenuPsicologia;
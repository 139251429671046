import React, { useState, useContext } from "react";
import PsiExpLaboralForm from "../../../Components/Psicologia/ExperienciaLaboral/PsiExpLaboralForm";
import ModalPsiExpLabEditar from "../../../Components/Psicologia/ModalsPsiExpLab/ModalPsiExpLabEditar"
import ModalPsiExpLabEliminar from "../../../Components/Psicologia/ModalsPsiExpLab/ModalPsiExpLabEliminar";
import "../../../Style/ExperienciaLaboral/psi_exp_lab.css";
import PsiExpLabTable from "../../../Components/Psicologia/ExperienciaLaboral/PsiExpLabTable";
import { PsicologiaContext } from "../PsicologiaContext";


function PagePsiExpLab() {
    const { expLabContext } = useContext(PsicologiaContext);

    // estados para la visualizacion de modals
    const [visible, setVisible] = useState(false);
    const [visibleEdit, setVisibleEdit] = useState(false);
    const [visibleDelete, setVisibleDelete] = useState(false);

    //estado para seleccionar un indice
    const [selectIndice, setSelectIndice] = useState(null);

    //funcion para eliminar la fila seleccionada de la tabla
    const eliminarExperiencia = () => {
        expLabContext.splice(selectIndice, 1)
        setVisibleDelete(false)
    };
    //funcion para editar la fila seleccionada de la tabla
    const editarExperiencia = (data) => {
        expLabContext.splice(selectIndice, 1, data)
        setVisibleEdit(false)
    };



    return (
        <>
            <PsiExpLabTable
                visible={visible}
                setVisible={setVisible}
                setVisibleDelete={setVisibleDelete}
                setSelectIndice={setSelectIndice}
                setVisibleEdit={setVisibleEdit}	/>

            <PsiExpLaboralForm
                visible={visible}
                setVisible={setVisible} />

            <ModalPsiExpLabEditar
                visibleEdit={visibleEdit}
                setVisibleEdit={setVisibleEdit}
                selectIndice={selectIndice}
                editarExperiencia={editarExperiencia} />

            <ModalPsiExpLabEliminar
                visibleDelete={visibleDelete}
                setVisibleDelete={setVisibleDelete}
                eliminarExperiencia={eliminarExperiencia} />
        </>
    )
}

export default PagePsiExpLab;
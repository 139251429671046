
import React, { useContext, useEffect, useState } from "react";
import Ladoizquierdo from "./LadoIzquierdo";
import LadoDerecho from "./ladoDerecho";


export default function ParteSuperior_Adgudeza_Visual() {

    return (
        <div className="flex flex-wrap w-full gap-3" style={{ display: "flex", flexDirection: "column" }}>
            {/* Sin Corregir */}
            <div className="flex flex-row w-full flex-1">
                <Ladoizquierdo />
            </div>
            {/* Corregido */}
            <div className="flex flex-row w-full flex-1">
                <LadoDerecho />
            </div>
        </div>
    )
}

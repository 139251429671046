import React from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import "../../Style/InputMapAutocompletMapaLocal.css";

//Biblioteca para api Google Maps
const libraries = ["places"];

function InputMapAutocomplet(props) {
  //Carga de la api con la clave de acceso
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCsCf38reBoC31fo0enpAT6cESVohjRSwk",
    //googleMapsApiKey: "AIzaSyC6KKz5ECwpUopPfQEQhuHe9y57FTJgdUk",
    libraries: libraries,
  });

  //Si la api se encuentra cargada, renderizar el componente Autocomplete
  return isLoaded ? (
    <Autocomplete
      restrictions={props.restrictions}//Restricciones de búsqueda
      onPlaceChanged={props.onPlaceChanged}//Evento que se ejecuta cuando se selecciona una ubicación
      onLoad={props.onLoad}//Evento que se ejecuta cuando se carga el componente
    >
      {props.children}
    </Autocomplete>
  ) : (
    <React.Fragment></React.Fragment>//Fragmento vacío para evitar errores
  );
}

export default InputMapAutocomplet;
import React, { useContext, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SalesContext } from "../../SalesContext/SalesContext";
import FileInputButton from "../../../Ventas/FileInputButton/FileInputButton";
import InputCalendar from '../../../form/InputCalendar';
import { Dropdown } from 'primereact/dropdown';

// import AuthUser from "../../../AuthUser";

import axios from "axios";

export default function ContactoEditar({ data, crud, convertDate }) {
   // Estados para almacenar los datos del formulario y la respuesta de la API

   const { sales, setSales } = useContext(SalesContext);

   const formRef = useRef(null)
   const optionsDocument = [
      { name: 'DNI', code: 1 },
      { name: 'Carnet de Extranjeria', code: 3 },
      { name: 'Pasaporte', code: 4 },
   ];


   // Función para manejar el evento de clic en el botón "Consultar"
   const handleSubmit = () => {
      // Realiza la solicitud GET a la API con el número de documento ingresado
      axios
         .get(`https://dniruc.apisperu.com/api/v1/dni/${sales?.GCContacto?.numero_documento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
         .then((res) => {
            // Actualiza los estados con los datos obtenidos de la API
            setSales({
               ...sales,
               GCContacto: {
                  ...sales.GCContacto,
                  nombres: res.data.nombres,
                  apellido_paterno: res.data.apellidoPaterno,
                  apellido_materno: res.data.apellidoMaterno
               }
            });
         })
         .catch((error) => {
            // Manejo de errores: muestra un mensaje de error y limpia los campos
            console.error(error);
         });
      InicilizarFaltas();

   };

   const InicilizarFaltas = () => {
      setVerificarNombre(true);
      setVerificarApellidoP(true);
      setVerificarApellidoM(true);
      setVerificarNumdoc(true);
   }
   //#region VARIABLES DE INSTANCIA
   //#endregion

   //#region VARIABLES
   const [visible, setVisible] = useState(false);
   const [selectedImage, setSelectedImage] = useState(data.persona.foto + '?v=' + Date.now());
   //#endregion
   //#region FUNCIONES
   const replaceContact = () => {
      setSales(prevState => ({
         ...prevState, GCContacto: {
            ...prevState.GCContacto,
            contacto_id: data.id,
            tipo_documento_id: data.persona.tipo_documento_id,
            numero_documento: data.persona.numero_documento,
            nombres: data.persona.nombres || "",
            apellido_paterno: data.persona.apellido_paterno || "",
            apellido_materno: data.persona.apellido_materno || "",
            cargo: data.persona.cargo || "",
            fecha_nacimiento: data.persona.fecha_nacimiento,
            hobbies: data.persona.hobbies || "",
            celular: data.persona.celular,
            telefono: data.persona.telefono || null,
            correo: data.persona.correo || "",
            comentarios: data.comentarios || "",
            foto: data.persona.foto || "https://i.ibb.co/4SHrqm4/user-male.png",
         }
      }));
      // setSelectedImage(data.persona.foto || "https://i.ibb.co/4SHrqm4/user-male.png");
      setVisible(true)
   };
   const onchangeInputs = (e) => {
      const { name, value } = e.target || e;
      setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, [name]: value } }));
   };
   const onchangeFechaNacimiento = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;


         setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, fecha_nacimiento: fechaFormateada } }));

      }
   };

   const handleFileChange = (selectedFiles) => {
      if (selectedFiles.length > 0) {
         const reader = new FileReader();
         reader.onload = () => {
            setSelectedImage(reader.result);
         };
         reader.readAsDataURL(selectedFiles[0]);
      } else {
         setSelectedImage(null);
      }
   }
   const handleDeleteImage = () => {
      setSelectedImage("https://i.ibb.co/4SHrqm4/user-male.png");
      setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, foto: null } }));
   };

   const onchangeFile = (file) => {
      setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, foto: file } }));
   }
   //#endregion

   //#region FUNCIÓN DE ENVÍO DE DATOS
   const sendEdit = async (e) => {
      e.preventDefault();

      const resp = await crud("editar", setVisible);
      if (resp) {
         setVisible(false);
      }
   }
   const handleEnvio = (e) => {
      var verigeneral = VerificarExistencias();
      if (verigeneral) {
         if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
         }
         setVisible(false);
      }

   }

   //#endregion

   const handleChangeTypeDocument = (e) => {
      setSales({ ...sales, GCContacto: { ...sales.GCContacto, tipo_documento_id: e.target.value.code } });
   }

   const [VerificarNumdoc, setVerificarNumdoc] = useState(true);
   const [VerificarNombre, setVerificarNombre] = useState(true);
   const [VerificarApellidoP, setVerificarApellidoP] = useState(true);
   const [VerificarApellidoM, setVerificarApellidoM] = useState(true);

   const VerificarExistencias = () => {
      var VerificacionGeneral = true;
      if (!sales.GCContacto.numero_documento) {
         VerificacionGeneral = false;
         setVerificarNumdoc(false);
      }
      if (!sales.GCContacto.nombres) {
         VerificacionGeneral = false;
         setVerificarNombre(false);
      }
      if (!sales.GCContacto.apellido_paterno) {
         VerificacionGeneral = false;
         setVerificarApellidoP(false);
      }
      if (!sales.GCContacto.apellido_materno) {
         VerificacionGeneral = false;
         setVerificarApellidoM(false);
      }
      return VerificacionGeneral;
   }



   const headerTemplate = () => {
      return (
         <div className="flex justify-content-start gap-2 align-items-center">
            <div className="flex align-items-center justify-content-center" style={{ width: '45px', height: '45px', borderRadius: '10px', backgroundColor: '#EBEBFF' }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 40 40" fill="none">
                  <g clip-path="url(#clip0_19_1288)">
                     <path d="M33.3334 33.333H6.66669" stroke="#5555D8" stroke-width="2.42424" stroke-linecap="round" />
                     <path fill-rule="evenodd" clip-rule="evenodd" d="M24.3096 7.3562C25.6113 6.05445 27.722 6.05445 29.0236 7.3562C30.3255 8.65795 30.3255 10.7685 29.0236 12.0702L15.2182 25.8757L10.1675 26.2123L10.5042 21.1617L24.3096 7.3562Z" stroke="#5555D8" stroke-width="2.42424" stroke-linecap="round" />
                  </g>
                  <defs>
                     <clipPath id="clip0_19_1288">
                        <rect width="40" height="40" fill="white" />
                     </clipPath>
                  </defs>
               </svg>
            </div>
            <p className="text-indigo-600 text-lg font-bold"
               style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '18px' }}>
               Editar Contacto
            </p>
         </div>
      );
   };
   const footerTemplate = () => {
      return (
         <div className="flex justify-content-end mt-5" style={{ gap: '10px' }}>
            <Button
               type="button"
               className="bg-green-500 border-green-500 text-sm p-2 m-0"
               label="Editar"
               style={{ width: '200px' }}
               onClick={(e) => { handleEnvio(e) }}
            />
            <Button
               onClick={() => { setVisible(false); InicilizarFaltas() }}
               type='button'
               style={{ width: '200px' }}
               label="Cancelar"
               className="font-normal bg-white border-gray-500 text-sm p-2 text-gray-800 m-0"
            />
         </div>
      )
   }

   return (
      <>

         <Button
            icon="pi pi-pencil text-green-500"
            className="bg-green-100 border-green-100 crudButon"
            onClick={() => replaceContact()}
         />
         <style>
            {`
               .FaltaInput{
                  transition: .5s;
               }
               .FaltaInput.selectFaltaInput{
                  box-shadow:
                     0 0 0 1px white,
                     0 0 0 2px red;
               }
               .FaltaInputText{
                  color: red;
                  position: absolute;
                  bottom: 4px;
                  left: 10px;
                  opacity: 0;
                  transition: .4s;
               }
               .FaltaInputText.selecttFaltaInput{
                  opacity: 1;
                  bottom: 2px;
               }
            `}
         </style>
         <Dialog
            header={headerTemplate}
            visible={visible}
            style={{ width: '765px', height: 'auto' }}
            onHide={() => { setVisible(false); setSelectedImage(null); InicilizarFaltas() }}
            footer={footerTemplate}
            className="empresas"

         >
            <form onSubmit={(e) => sendEdit(e)} ref={formRef} method="post" id="formulario"
               className="text-sm flex flex-column gap-3 w-full mt-3">
               <div className="flex flex-wrap gap-3 ventas">
                  <div className="flex-1 flex flex-column gap-2">

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="numero_documento">Tipo documento</label>
                        <Dropdown value={optionsDocument[optionsDocument.findIndex(ele => ele.code === sales?.GCContacto?.tipo_documento_id)] ?? optionsDocument[0]}
                           onChange={(e) => { handleChangeTypeDocument(e) }} options={optionsDocument} optionLabel="name"
                           placeholder="Seleccionar tipo documento" className="w-full " name="tipo_documento_id" />
                     </div>
                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="numero_documento">Número de Documento</label>

                        <div className="flex">
                           <InputText
                              value={sales?.GCContacto?.numero_documento}
                              onChange={(e) => { setSales({ ...sales, GCContacto: { ...sales.GCContacto, numero_documento: e.target.value } }); setVerificarNumdoc(true); }}
                              name="numero_documento"
                              id="numero_documento"
                              placeholder="12345678"
                              className={`w-full FaltaInput ${!VerificarNumdoc ? 'selectFaltaInput' : ''}`}
                           />
                           <div style={{ marginLeft: '10px' }}>
                              <Button
                                 style={{ height: '40px', width: '100px', background: "green", borderColor: 'green' }}
                                 label="Validar"
                                 onClick={handleSubmit}
                                 type="button"
                              />
                           </div>
                        </div>
                        <div style={{ height: `${!VerificarNumdoc ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarNumdoc ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su número de documento
                        </span>

                     </div>

                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="nombres">Nombres</label>
                        <InputText
                           value={sales?.GCContacto?.nombres}
                           onChange={(e) => { onchangeInputs(e);; setVerificarNombre(true) }}
                           name="nombres"
                           id="nombres"
                           placeholder="Carlos Nemecio"
                           className={`p-inputtext-sm FaltaInput ${!VerificarNombre ? 'selectFaltaInput' : ''}`}
                        />
                        <div style={{ height: `${!VerificarNombre ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarNombre ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su nombre
                        </span>
                     </div>

                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="apellido_paterno">Apellido Paterno</label>
                        <InputText
                           value={sales?.GCContacto?.apellido_paterno}
                           onChange={(e) => { onchangeInputs(e); setVerificarApellidoP(true) }}
                           name="apellido_paterno"
                           id="apellido_paterno"
                           placeholder="Aquino"
                           className={`p-inputtext-sm FaltaInput ${!VerificarApellidoP ? 'selectFaltaInput' : ''}`}
                        />
                        <div style={{ height: `${!VerificarApellidoP ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarApellidoP ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su apellido paterno
                        </span>
                     </div>


                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="apellido_materno">Apellido Materno</label>
                        <InputText
                           value={sales?.GCContacto?.apellido_materno}
                           onChange={(e) => { onchangeInputs(e);; setVerificarApellidoM(true) }}
                           name="apellido_materno"
                           id="apellido_materno"
                           placeholder="Bellos"
                           className={`p-inputtext-sm FaltaInput ${!VerificarApellidoM ? 'selectFaltaInput' : ''}`}
                        />
                        <div style={{ height: `${!VerificarApellidoM ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarApellidoM ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su apellido materno
                        </span>
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="cargo">Cargo</label>
                        <InputText
                           value={sales.GCContacto.cargo}
                           onChange={(e) => onchangeInputs(e)}
                           name="cargo"
                           id="cargo"
                           placeholder="Soporte"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="celular">Teléfono</label>
                        <InputNumber
                           value={sales.GCContacto.celular === 'null' ? null : sales.GCContacto.celular}
                           onValueChange={(e) => onchangeInputs(e)}
                           useGrouping={false}
                           name="celular"
                           id="celular"
                           placeholder="000 000 000"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="correo">Correo</label>
                        <InputText
                           value={sales.GCContacto.correo}
                           onChange={(e) => onchangeInputs(e)}
                           type="email"
                           name="correo"
                           id="correo"
                           placeholder="example@example.com"
                           className="p-inputtext-sm"
                        />
                     </div>
                  </div>

                  <div className="flex-1 flex flex-column gap-2 justify-content-end">
                     <label className="font-bold" htmlFor="foto" >Foto</label>
                     <div className="flex justify-content-center flex-1">
                        <span className="w-auto flex align-items-center justify-content-center bg-indigo-200 p-2 border-circle m-auto">
                           {true && (
                              <img
                                 className="border-circle"
                                 style={{ objectFit: "cover", width: '231px', aspectRatio: '1/1', height: '231px' }}
                                 src={
                                    selectedImage && selectedImage.includes('null') ?
                                       "https://i.ibb.co/4SHrqm4/user-male.png" :
                                       selectedImage || (sales.GCContacto.foto + '?v=' + Date.now())
                                 }
                                 alt="user-male"
                                 draggable={false}
                              />
                           )}
                        </span>
                     </div>
                     <div className="flex align-items-center justify-content-center ">
                        <FileInputButton
                           onChange={(e) => handleFileChange(e)}
                           file={(e) => onchangeFile(e)}
                           accept="image/*"
                           className="bg-green-200 border-green-200 text-sm font-normal text-green-600"
                           type="button"
                           label="Cambiar imagen"
                           icon="pi pi-camera text-green-600"
                           iconPos="right"
                           inputId="foto"
                        />
                        <Button
                           type="button"
                           style={{ marginLeft: "10px" }}
                           icon="pi pi-trash text-red-500"
                           className="bg-red-100 border-red-100 pl-2"
                           onClick={() => handleDeleteImage()}
                        />
                     </div>

                     <label className="flex align-items-center gap-2 font-bold" htmlFor="comentarios">
                        <p>Comentarios</p>
                        <i className="pi pi-pencil text-sm" />
                     </label>
                     <InputTextarea
                        value={sales.GCContacto.comentarios || ""}
                        onChange={(e) => onchangeInputs(e)}
                        className="ventas-scroll card m-0"
                        id="comentarios"
                        name="comentarios"
                        style={{ resize: 'none', height: "7.6rem" }}
                        placeholder="Ingresa un comentario"
                        maxLength={100}
                     />
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="fecha_nacimiento">Cumpleaños</label>
                        <InputCalendar
                           value={convertDate(sales.GCContacto.fecha_nacimiento)}
                           onChange={(e) => onchangeFechaNacimiento(e)}
                           name="fecha_nacimiento"
                           inputId="fecha_nacimiento"
                           showIcon
                           showButtonBar
                           placeholder="dd/mm/yy" dateFormat="dd/mm/yy"
                           className="p-inputtext-sm ventas text-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="">
                           Hobbies
                        </label>
                        <InputText
                           value={sales.GCContacto.hobbies}
                           onChange={(e) => onchangeInputs(e)}
                           name="hobbies"
                           placeholder="Leer, etc"
                           className="p-inputtext-sm"
                        />

                     </div>
                  </div>
               </div>
            </form>
         </Dialog>
      </>
   );
}
import { InputText } from "primereact/inputtext";
import "../../../../Style/PageGestionProformaB.css";
import { contentService } from "../../../../Services/dataGestion";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useState } from "react";
import { Button } from "primereact/button";

/*Agregado*/
import DialogDetalles from '../../../../Components/ProformaPopUp/DialogDetalles';
export default function PageGestionProformaB() {
   //
   const [dialogVisible, setDialogVisible] = useState(false);
   const [selectedStatus, setSelectedStatus] = useState('');
   const bg_state = (btn) => {
      switch (btn) {
         case "En espera":
            return "proforma__container_body_table_state_purple";
         case "Aceptado":
            return "proforma__container_body_table_state_green";
         case "Rechazado":
            return "proforma__container_body_table_state_red";
         default:
            return "";
      }
   };

   const handleEstadoSeleccionado = (estado) => {
      setSelectedStatus(estado);
      setDialogVisible(false);
    };
   
   
   const [visible, setVisible] = useState(false);

   return (
      <div className="proforma__container">
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Gestión de Proformas</h4>
            <p className="add__container_header_p">
               En este módulo usted podrá ver el historial de proformas..
            </p>
         </div>
         <div className="proforma__container_input_header">
            <span className="p-input-icon-left  bg-transparent">
               <i className="pi pi-search" />
               <InputText
                  placeholder="Buscador"
                  className="input_responsive border-0 border-round-3xl shadow-2"
               />
            </span>
         </div>
         <div className="proforma__container_body bg-white">
            <ScrollPanel
               style={{ width: "100%", height: "100%" }}
               className="custombar2"
            >
               <table className="proforma__container_body_table">
                  <thead>
                     <tr>
                        <th className="text-center">Item</th>
                        <th className="text-center">Código de Proforma</th>
                        <th className="text-center">Proforma</th>
                        <th className="text-center">Encuesta</th>
                        <th className="text-center">Estado</th>
                        <th className="text-center">Envío</th>
                     </tr>
                  </thead>
                  <tbody>
                     {contentService.map((item) => (
                        <tr>
                           <td className="text-center proforma__container_body_table_txt_proform">
                              {item.id}
                           </td>
                           <td className="text-center proforma__container_body_table_txt_proform">
                              {item.codeProform}
                              
                           </td>
                           <td>
                              <div className="flex align-items-center justify-content-center w-full h-full">
                                 <i className="pi pi-file-pdf proforma__container_body_table_txt_pdf"></i>
                              </div>
                           </td>

                           <td>
                              <div>
                                 <Button
                                    style={{ cursor: "pointer", color: "#5555D8" ,backgroundColor:"transparent", border:"transparent"}}
                                    className="w-full h-full gap-2 flex justify-content-center align-items-center"
                                    onClick={() => setVisible(true)}>
                                    <>
                                       {/* <i className="pi pi-info-circle"></i>
                                       <p className="pb-1">Ver detalles</p> */}
                                    </>
                                    <DialogDetalles/>
                                 </Button>
                              </div>
                           </td>

                           <td>
                              <div className="flex h-full w-full justify-content-center">
                                 <div className={bg_state(item.status)}>
                                    <i className=""></i>
                                    <p>{item.status}</p>
                                 </div>
                              </div>
                           </td>

                           <td>
                              <div className="flex  align-items-center justify-content-center w-full h-full ">
                                 {/* <i className="pi pi-send proforma__container_body_table_txt_pdf"></i> */}
                                 
                                 <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                                 onClick={() => setDialogVisible(true)}>
                                 <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
                                    
                                 </Button>
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </ScrollPanel>
         </div>
      </div>
   );
}
import React, { useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import TablaCrud from "../../../Components/Table/TablaCrud";
import { Button } from "primereact/button";
import AuthUser from "../../../AuthUser";
import { classNames } from "primereact/utils";

// COMPONENTES USO UNICO
import {ModalComenClinica, ModalCreateClinica, ModalEditar, ModalEliminar} from '../../../Components/RecursosHumanos/Reclutamiento/Components/Pure/Clinica/index.js'


// COMPONENTES GENERALES:
import ModalCredenciales from "../../../Components/RecursosHumanos/Personal/Components/ModalCredenciales";
import ModalCarga from "../../../Components/RecursosHumanos/Personal/Components/ModalCarga";
import ModalReporte from "../../../Components/RecursosHumanos/Personal/Components/ModalReporte";


import "./Style/Reclutamiento.css";
import "../../../Style/personal.css";
import { Column } from "primereact/column";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function PageReclutamiento() {
  // const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const { http } = AuthUser();

  const toast = useRef(null);
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [personal, setPersonal] = useState([]);
  const [personaleliminar, setPersonaleliminar] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleCredenciales, setVisibleCredenciales] = useState(false);
  const [visibleEliminar, setVisibleEliminar] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleCarga, setVisibleCarga] = useState(false);
  const [visibleReporte, setVisibleReporte] = useState(false);
  const [roles, setRoles] = useState([]);
  const [visibleComentarios, setVisibleComentarios] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [selectPersonal, setSelectPersonal] = useState({
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "2022-01-01",
    distrito_id: 2,
    celular: "",
    correo: "",
    usuario: "",
    rol: "",
  });

  const reject = () => {
    toast.current.show({
      severity: "info",
      summary: "Rechazada",
      detail: "No se realizo ninguna acción",
      life: 3000,
    });
  };
  const showComentariosModal = (rowData) => {
    setSelectedRowData(rowData);
    setVisibleComentarios(true);
  };
  const handleSaveComentarios = (id, nuevosComentarios) => {
    const nuevoPersonal = personal.map((item) =>
      item.id === id ? { ...item, comentarios: nuevosComentarios } : item
    );
    setPersonal(nuevoPersonal);
  };


  const hideComentariosModal = () => {
    setVisibleComentarios(false);
  };
  const determineCvIcon = (cv) => {
    if (cv && cv.endsWith('.pdf')) {
      return 'pi pi-file-pdf';
    }
  };
  const getAllTipoDocumentos = async () => {
    try {
      const response = await http
        .get("/tipodocumentos/get")
      if (response?.data?.data) {
        setTipoDocumentos(response.data.data);
      } else if (response?.data?.error) {
        showToast('error', 'Error', response.data.error)
      }
    } catch (error) {
      showToast('error', 'Error', 'Contáctese con soporte técnico.')
    };
  };

  const getRoles = async () => {
    try {
      const response = await http
        .get("roles/get")
      setRoles(response?.data?.data);
    } catch (error) {
      console.error(error);
    };
  };

  const getPersonal = async () => {
    try {
      const response = await http
        .get("clinica/personal/reclutamiento/get")

      setPersonal(response.data?.data);
    } catch (error) {
      console.error(error);
    };
  };

  const fetchAllData = async () => {
    try {
      await Promise.all([
        getAllTipoDocumentos(),
        getPersonal(),
        getRoles(),
      ]);
    } catch (error) {
      console.error('Error al cargar datos:', error);
    } finally {
      dispatch(endLoading());
    }
  }

  useEffect(() => {
    dispatch(startLoading());
    fetchAllData()
    // getAllTipoDocumentos();
    // getPersonal();
    // getRoles();
  }, []);

  const showToast = (tipo, titulo, detalle) => {
    toast.current.show({
      severity: tipo,
      summary: titulo,
      detail: detalle,
      life: 3000,
    });
  };

  // const hideDialogPaqueteEdit = () => {
  //   setVisibleEdit(false);
  // };
  const renderCvColumn = (rowData) => {
    const cvIcon = determineCvIcon(rowData.cv);

    return (
      <i
        className={classNames("pi", cvIcon)}
        style={{ fontSize: "2rem", cursor: "pointer" }}
      />
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="editar1"
          onClick={() => editPaquete(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="eliminar1"
          onClick={() => deletePaquete(rowData)}
        />
      </React.Fragment>
    );
  };
  const editPaquete = (rowData) => {
    setSelectPersonal(rowData);
    setVisibleEdit(true);
    setSelectPersonal(rowData)
  };

  const deletePaquete = (rowData) => {
    setPersonaleliminar(rowData);
    setVisibleEliminar(true);
  };

  return (
    <div>
      <div className="flex flex-column">
        <h1 className="tit">Reclutamiento </h1>
        <p className="con">Administralo de la mejor manera</p>
      </div>

      <div >
        <Toast ref={toast} />
        <TablaCrud
          name="Personal"
          tableNombre={`Lista de Personal`}
          descripcion={`A continuación, se visualiza la lista de personal.`}
          onInputSearch={(e) => setGlobalFilter(e.target.value)}
          valueGlobalFilter={globalFilter}
          value={personal}
          onClickLeftButton1={() => {
            setVisibleCreate(true);
          }}
          onClickLeftButton2={() => {
            setVisibleCarga(true);
          }}
          onClickLeftButton4={() => {
            setVisibleReporte(true);
          }}
          leftLabelButton1={" Crear"}
          // leftLabelButton4={" Reporte"}
          leftLabelButton2={" Carga de Datos"}
        >
          <Column field={"id"} header="Item" bodyStyle={{ textAlign: 'center' }}></Column>
          <Column header={"Tipo de Doc."} field={"persona.tipo_documento.nombre"} bodyStyle={{ textAlign: 'center' }}></Column>
          <Column header={"N. de Doc."} field={"persona.numero_documento"} bodyStyle={{ textAlign: 'center' }}></Column>
          <Column
            body={(rowData) => `${rowData.persona.nombres} ${rowData.persona.apellido_paterno} ${rowData.persona.apellido_materno}`}
            header="Nombre Completos" bodyStyle={{ textAlign: 'center' }}
          ></Column>
          <Column header={"Cv"} field={"cv"} body={renderCvColumn} bodyStyle={{ textAlign: 'center' }}></Column>
          <Column header={"Pretensión salarial"} field={"pretension_salarial"} bodyStyle={{ textAlign: 'center' }}></Column>
          <Column header={"Comentarios"} body={(rowData) => (<Button icon="pi pi-comment" className="comentarios-icon" onClick={() => showComentariosModal(rowData)} />)} bodyStyle={{ textAlign: 'center', whiteSpace: 'nowrap' }}></Column>
          <Column header={"Editar"} field={actionBodyTemplate} bodyStyle={{ textAlign: 'center' }}></Column>
        </TablaCrud>
      </div>

      <ModalCreateClinica
        visibleCreate={visibleCreate}
        setVisibleCreate={setVisibleCreate}
        personal={selectPersonal}
        setPersonal={setSelectPersonal}
        showToast={showToast}
        tipoDocumentos={tipoDocumentos}
        roles={roles}
        getPersonal={getPersonal}
        api="/clinica/personal/reclutamiento/create"
        toast={toast}
      />

      <ModalEditar
        visibleEdit={visibleEdit}
        setVisibleEdit={setVisibleEdit}
        personal={selectPersonal}
        setPersonal={setSelectPersonal}
        showToast={showToast}
        tipoDocumentos={tipoDocumentos}
        roles={roles}
        getPersonal={getPersonal}
        api="/clinica/personal/reclutamiento/update"
      />
      <ModalCredenciales
        visibleCredenciales={visibleCredenciales}
        setVisibleCredenciales={setVisibleCredenciales}
        personal={personal}
        setPersonal={setPersonal}
        showToast={showToast}
        tipoDocumentos={tipoDocumentos}
        roles={roles}
        api={'empresa/personal/enviar_credenciales'}
      />
      <ModalEliminar
        visibleEliminar={visibleEliminar}
        showToast={showToast}
        setVisibleEliminar={setVisibleEliminar}
        setPersonal={setPersonaleliminar}
        getPersonal={getPersonal}
        api="/clinica/personal/reclutamiento/delete"
        id={personaleliminar.id}
      />
      <ModalCarga
        visibleCarga={visibleCarga}
        setVisibleCarga={setVisibleCarga}
        personal={personal}
        setPersonal={setPersonal}
        showToast={showToast}
        tipoDocumentos={tipoDocumentos}
        roles={roles}
      />
      <ModalReporte
        visibleReporte={visibleReporte}
        setVisibleReporte={setVisibleReporte}
        personal={personal}
        setPersonal={setPersonal}
        showToast={showToast}
        tipoDocumentos={tipoDocumentos}
        roles={roles}
      />
      <ModalComenClinica
        visible={visibleComentarios}
        onHide={hideComentariosModal}
        comentariosInicial={selectedRowData?.comentarios}
        onSave={handleSaveComentarios}
        selectedRowData={selectedRowData}
      />

    </div >
  );
}
import { useState, useRef, useEffect } from "react";
import { Toast } from 'primereact/toast';
import { useDispatch } from "react-redux";

//Componentes generales
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice";
import AuthUser from "../../../AuthUser";

//Componentes únicos
import { EmpresaComponentCoorporativa, PersonaComponentCoorporativo } from "../../../Components/Perfil/Coorporativo";
import PermisosComponent from "../../../Components/Perfil/usuario/components/Permisos";
import Detraccion from "../../../Components/Perfil/usuario/components/Detraccion";
import CrearCcomponent from "../../../Components/Perfil/usuario/components/CrearC";

import './MediaQueryPerfilClinica.css';

const PagePerfil = () => {
   const { http } = AuthUser();
   const toast = useRef();
   const dispatch = useDispatch();
   const [clinica, setClinica] = useState({
      id: 0,
      tipo_documento: null,
      numero_documento: null,
      razon_social: null,
      direccion: null,
      celular: null,
      correo: null,
      distrito: null,
      distrito_id: null
   });
   const [clinicaUpdate, setClinicaUpdate] = useState([]);
   const [persona, setPersona] = useState([]);
   const [personaUpdate, setPersonaUpdate] = useState([]);
   const [entidadPagos, setEntidadPagos] = useState([]);
   const [detracciones, setDetracciones] = useState([]);
   const [entidadPagoElectronico, setEntidadPagoElectronico] = useState([]);
   const [entidadesBancarias, setEntidadesBancarias] = useState([]);

   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   //Integraciones GET
   const getClinica = async () => {
      try {
         const response = await http.get("clinica/show");
         if (response?.data?.resp) {
            setClinica(response?.data?.resp);
            setClinicaUpdate({
               ...clinicaUpdate,
               id: response?.data?.resp?.id,
               tipo_documento: response?.data?.resp?.tipo_documento,
               tipo_documento_id: response?.data?.resp?.tipo_documento_id,
               numero_documento: response?.data?.resp?.numero_documento,
               razon_social: response?.data?.resp?.razon_social,
               direccion: response?.data?.resp?.direccion,
               celular: response?.data?.resp?.celulares[0]?.celular,
               correo: response?.data?.resp?.correos[0]?.correo,
               distrito: response?.data?.resp?.distrito,
               distrito_id: response?.data?.resp?.distrito_id
            });
            localStorage.logo = response.data?.resp?.logo
            setEntidadPagos(response?.data?.resp?.entidad_pagos)
            setDetracciones(response?.data?.resp?.detracciones)
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error);
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
      }
   };

   const getEntidadesBancarias = async () => {
      try {
         const response = await http.get('entidad_bancaria/get');
         if (response?.data?.data || response?.data?.resp) {
            setEntidadesBancarias(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error);
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.');
      }
   }

   const getEntidadesElectronicas = async () => {
      try {
         const response = await http.get('entidad_electronica_pago/get');
         if (response?.data?.data || response?.data?.resp) {
            setEntidadPagoElectronico(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error);
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.');
      }
   }

   const getPersona = async () => {
      try {
         const response = await http.get('persona/show');
         if (response?.data?.resp) {
            setPersona(response?.data?.resp?.persona);
            setPersonaUpdate(response?.data?.resp?.persona);
            dispatch(endLoading());
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error);
            dispatch(endLoading());
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.');
      }
   }


   //Integraciones POST
   const handleSubmitUpdate = (data) => {
      http
         .put(`clinica/updatebylogin`, data)
         .then((response) => {
            getClinica();
         })
         .catch((error) => {
            console.error(error);
         })
   }

   const handleCreateClinica = (entidadPago, limpiarEntidadBancaria, CerrarModal) => {
      http
         .post("entidad_pago/create/clinica", entidadPago)
         .then((response) => {
            if (response?.data?.resp) {
               getClinica();
               CerrarModal();
               limpiarEntidadBancaria();
               showToast('success', 'Nueva entidad bancaria', response.data.resp);
            } else if (response?.data?.error) {
               showToast('error', 'Error', response?.data?.error)
            }
         })
         .catch((error) => {
            CerrarModal();
            console.error("error", error);
            showToast('error', 'Error', 'Contacte con soporte técnico')
         });
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getClinica(),
            getEntidadesBancarias(),
            getEntidadesElectronicas(),
            getPersona()
         ])
      } catch (error) {
         console.error('Error en la carga de datos', error)
      } finally {
         dispatch(endLoading())
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (

      <div className="ContenedorGeneralPerfil flex flex-wrap flex-column gap-3 mt-3 pr-7 pl-6">
         <Toast ref={toast} />
         <div>
            <p className="tit "> Mi Perfil </p>
            <p className="con">Adminí­stralo a tu mejor manera</p>
         </div>
         <div className="gap-3 perfil-res ContenedorGeneralComponente" style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div className=" flex flex-wrap ContenedorComponentePersona">
               <PersonaComponentCoorporativo
                  showToast={showToast}
                  persona={persona}
                  personaUpdate={personaUpdate}
                  setPersonaUpdate={setPersonaUpdate}
                  getPersona={getPersona}
                  institucion={"Perfil"}
               />
            </div>
            <div className=" flex flex-wrap ContenedorComponenteEmpresa">
               <EmpresaComponentCoorporativa
                  showToast={showToast}
                  dataShow={clinica}
                  setDataShow={setClinica}
                  data={clinicaUpdate}
                  setBregma={setClinicaUpdate}
                  getEmpresa={getClinica}
                  handleSubmitUpdate={handleSubmitUpdate}
                  entidad='la Clínica'
                  api={'clinica/update/foto'}
               />
            </div>

         </div>
         <div className="CONEDORINFEERIORPERFIL w-12 flex gap-3">
            <div className="CONEDORINFEERIORPERMISOS flex gap-3" style={{ maxWidth: '100%', margin: 'auto' }}>
               <PermisosComponent namefull={persona?.nombres + " " + (persona?.apellido_paterno ?? "") + " " + (persona?.apellido_materno ?? "")} />
            </div>
            <div className="CONEDORINFEERIORCREAR flex flex-column gap-1">
               <div>
                  <Detraccion
                     detracciones={detracciones}
                     getbregma={getClinica}
                     showToast={showToast}
                  />
               </div>
               <div>
                  <CrearCcomponent
                     getEntidadesElectronicas={getEntidadesElectronicas}
                     entidadPagoElectronico={entidadPagoElectronico}
                     getbregma={getClinica}
                     entidadPagos={entidadPagos}
                     entidadesBancarias={entidadesBancarias}
                     handleCreate={handleCreateClinica}
                     showToast={showToast}
                  />
               </div>
            </div>
         </div>
      </div>
   )
}
export default PagePerfil;
import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { RadContext } from "./RadProvider";

const AnormalidadesPleuralesContent = () => {
    let { RayTorax, setRayTorax } =
        useContext(RadContext);

    const cities = [
        { name: "1", code: "1" },
        { name: "2", code: "2" },
        { name: "3", code: "3" },
        { name: "4", code: "4" },
    ];
    const subTituloMorado = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        color: "#4545BC",
        width: "100%",
        height: '50px',
        backgroundColor: '#F6F7FB',
        padding: '7px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    };

    const subTituloNormal = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        height: '48px',
        display: 'flex',
        color: '#88888E',
        alignItems: 'center',
        justifyContent: 'start',
    };

    const actualizarPropiedadesBase = (newvalor, propiedad, secpropiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: {
                ...prevState[propiedad],
                [secpropiedad]: newvalor
            }
        }));
    };
    const actualizarPropiedadesBaseNivel01 = (newvalor, propiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: newvalor
        }));
    };

    const ConvertirSelectObjeto = (Array, Respuesta, setvalorRespuesta, propDef) => {
        for (let index = 0; index < Array.length; index++) {
            if (Array[index][propDef] == Respuesta) {
                setvalorRespuesta(Array[index]);
            }
        }
    };

    const OpcionesSitioCalcificacion = [
        { name: "0", code: "1" },
        { name: "D", code: "2" },
        { name: "I", code: "3" },
    ];
    const [ParedToraxSitio, setParedToraxSitio] = useState(null);
    const [ParedToraxSitio_cod, setParedToraxSitio_cod] = useState(RayTorax.neu_pared_toraxica.sitio ? RayTorax.neu_pared_toraxica.sitio : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, ParedToraxSitio_cod, setParedToraxSitio, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(ParedToraxSitio_cod, "neu_pared_toraxica", "sitio");
    }, [ParedToraxSitio_cod])

    const [ParedToraxCalcificacion, setParedToraxCalcificacion] = useState(null);
    const [ParedToraxCalcificacion_cod, setParedToraxCalcificacion_cod] =
        useState(RayTorax.neu_pared_toraxica.calificacion ? RayTorax.neu_pared_toraxica.calificacion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, ParedToraxCalcificacion_cod, setParedToraxCalcificacion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(ParedToraxCalcificacion_cod, "neu_pared_toraxica", "calificacion");
    }, [ParedToraxCalcificacion_cod])


    const [DeFrenteSitio, setDeFrenteSitio] = useState(null);
    const [DeFrenteSitio_cod, setDeFrenteSitio_cod] = useState(RayTorax.neu_frente.sitio ? RayTorax.neu_frente.sitio : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, DeFrenteSitio_cod, setDeFrenteSitio, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DeFrenteSitio_cod, "neu_frente", "sitio");
    }, [DeFrenteSitio_cod])

    const [DeFrenteCalcificacion, setDeFrenteCalcificacion] = useState(null);
    const [DeFrenteCalcificacion_cod, setDeFrenteCalcificacion_cod] =
        useState(RayTorax.neu_frente.calificacion ? RayTorax.neu_frente.calificacion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, DeFrenteCalcificacion_cod, setDeFrenteCalcificacion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DeFrenteCalcificacion_cod, "neu_frente", "calificacion");
    }, [DeFrenteCalcificacion_cod])



    const [DiafragmaSitio, setDiafragmaSitio] = useState(null);
    const [DiafragmaSitio_cod, setDiafragmaSitio_cod] = useState(RayTorax.neu_diagrama.sitio ? RayTorax.neu_diagrama.sitio : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, DiafragmaSitio_cod, setDiafragmaSitio, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DiafragmaSitio_cod, "neu_diagrama", "sitio");
    }, [DiafragmaSitio_cod])

    const [DiafragmaCalcificacion, setDiafragmaCalcificacion] = useState(null);
    const [DiafragmaCalcificacion_cod, setDiafragmaCalcificacion_cod] =
        useState(RayTorax.neu_diagrama.calificacion ? RayTorax.neu_diagrama.calificacion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, DiafragmaCalcificacion_cod, setDiafragmaCalcificacion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DiafragmaCalcificacion_cod, "neu_diagrama", "calificacion");
    }, [DiafragmaCalcificacion_cod])



    const [OtrosSitiosSitio, setOtrosSitiosSitio] = useState(null);
    const [OtrosSitiosSitio_cod, setOtrosSitiosSitio_cod] = useState(RayTorax.neu_otros_sitios.sitio ? RayTorax.neu_otros_sitios.sitio : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, OtrosSitiosSitio_cod, setOtrosSitiosSitio, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(OtrosSitiosSitio_cod, "neu_otros_sitios", "sitio");
    }, [OtrosSitiosSitio_cod])

    const [OtrosSitiosCalcificacion, setOtrosSitiosCalcificacion] = useState(null);
    const [OtrosSitiosCalcificacion_cod, setOtrosSitiosCalcificacion_cod] =
        useState(RayTorax.neu_otros_sitios.calificacion ? RayTorax.neu_otros_sitios.calificacion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesSitioCalcificacion, OtrosSitiosCalcificacion_cod, setOtrosSitiosCalcificacion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(OtrosSitiosCalcificacion_cod, "neu_otros_sitios", "calificacion");
    }, [OtrosSitiosCalcificacion_cod])








    const OpcionesExtención = [
        { name: "0", code: "1" },
        { name: "1", code: "2" },
        { name: "2", code: "3" },
        { name: "3", code: "4" },
    ];
    const [ExtenciónDerecha, setExtenciónDerecha] = useState(null);
    const [ExtenciónDerecha_cod, setExtenciónDerecha_cod] = useState(RayTorax.neu_extension.derecha ? RayTorax.neu_extension.derecha : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesExtención, ExtenciónDerecha_cod, setExtenciónDerecha, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(ExtenciónDerecha_cod, "neu_extension", "derecha");
    }, [ExtenciónDerecha_cod])

    const [ExtenciónIzquierda, setExtenciónIzquierda] = useState(null);
    const [ExtenciónIzquierda_cod, setExtenciónIzquierda_cod] =
        useState(RayTorax.neu_extension.izquierda ? RayTorax.neu_extension.izquierda : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesExtención, ExtenciónIzquierda_cod, setExtenciónIzquierda, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(ExtenciónIzquierda_cod, "neu_extension", "izquierda");
    }, [ExtenciónIzquierda_cod])






    const OpcionesAncho = [
        { name: "A", code: "1" },
        { name: "B", code: "2" },
        { name: "C", code: "3" },
    ];
    const [AnchoDerecha, setAnchoDerecha] = useState(null);
    const [AnchoDerecha_cod, setAnchoDerecha_cod] = useState(RayTorax.neu_ancho.derecha ? RayTorax.neu_ancho.derecha : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesAncho, AnchoDerecha_cod, setAnchoDerecha, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(AnchoDerecha_cod, "neu_ancho", "derecha");
    }, [AnchoDerecha_cod])

    const [AnchoIzquierda, setAnchoIzquierda] = useState(null);
    const [AnchoIzquierda_cod, setAnchoIzquierda_cod] =
        useState(RayTorax.neu_ancho.izquierda ? RayTorax.neu_ancho.izquierda : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesAncho, AnchoIzquierda_cod, setAnchoIzquierda, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(AnchoIzquierda_cod, "neu_ancho", "izquierda");
    }, [AnchoIzquierda_cod])






    const OpcionesObliteracion = [
        { name: "0", code: "1" },
        { name: "D", code: "2" },
        { name: "I", code: "3" },
    ];
    const [obliteracion, setobliteracion] = useState(null);
    const [obliteracion_cod, setobliteracion_cod] = useState(RayTorax.neu_obliteracion ? RayTorax.neu_obliteracion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesObliteracion, obliteracion_cod, setobliteracion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBaseNivel01(obliteracion_cod, "neu_obliteracion");
    }, [obliteracion_cod])
    return (
        <div className="AnormalidadesPleuralesAHPRaidologialikjh w-full flex h-auto pl-2 pr-2 pb-2 gap-3">
            <style>
                  {`
                     @media (max-width:1048px){
                        .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart01 > div:nth-child(1){
                           width: 40% !important;
                        }
                        .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart01 > div:nth-child(2){
                            width: 30% !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart01 > div:nth-child(3){
                            width: 30% !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 .textAHRadioplogiaTamañoAgrd .absolute{
                            width: 200px !important;
                         }
                     }
                     @media (max-width:851px){
                        .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart01 {
                           width: 60% !important;
                        }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02{
                            width: 40% !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 .textAHRadioplogiaTamañoAgrd .absolute{
                            width: 150px !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 > div:nth-child(1){
                            width: 33% !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 > div:nth-child(2){
                             width: 33% !important;
                          }
                          .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 > div:nth-child(3){
                             width: 33% !important;
                          }
                     }
                     @media (max-width:769px){
                        .AnormalidadesPleuralesAHPRaidologialikjh {
                            flex-wrap:wrap !important;
                         }
                        .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart01 {
                           width: 100% !important;
                        }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02{
                            width: 100% !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 .textAHRadioplogiaTamañoAgrd .absolute{
                            width: 200px !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 > div:nth-child(1){
                            width: 40% !important;
                         }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 > div:nth-child(2){
                             width: 30% !important;
                          }
                          .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02 > div:nth-child(3){
                             width: 30% !important;
                          }
                     }
                     @media (max-width:445px){
                        .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart01 {
                           width: 100% !important;
                           display: flex !important;
                           flex-direction:row !important;
                        }
                         .AnormalidadesPleuralesAHPRaidologialikjh .Ano9rmalidPleuralesPart02{
                            width: 100% !important;
                            display: flex !important;
                            flex-direction:row !important;

                         }
                     }
                  `}
               </style>
            <div className="Ano9rmalidPleuralesPart01 w-6 flex gap-1" style={{ flexWrap: 'nowrap' }}>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '48%'}}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Placas neurales: </div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Pared Toraxica de Perfil</div>
                    <div className="w-full" style={{ ...subTituloNormal }}>De Frente</div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Diafragma</div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Otros Sitios</div>

                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '26%'}}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Sitio:</div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={ParedToraxSitio}
                            onChange={(e) => setParedToraxSitio_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {ParedToraxSitio != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                onClick={() => {
                                    setParedToraxSitio_cod(null);
                                    actualizarPropiedadesBase(null, "neu_pared_toraxica", "sitio"); setParedToraxSitio(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={DeFrenteSitio}
                            onChange={(e) => setDeFrenteSitio_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {DeFrenteSitio != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDeFrenteSitio_cod(null);
                                    actualizarPropiedadesBase(null, "neu_frente", "sitio"); setDeFrenteSitio(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={DiafragmaSitio}
                            onChange={(e) => setDiafragmaSitio_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {DiafragmaSitio != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDiafragmaSitio_cod(null);
                                    actualizarPropiedadesBase(null, "neu_diagrama", "sitio"); setDiafragmaSitio(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={OtrosSitiosSitio}
                            onChange={(e) => setOtrosSitiosSitio_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {OtrosSitiosSitio != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setOtrosSitiosSitio_cod(null);
                                    actualizarPropiedadesBase(null, "neu_otros_sitios", "sitio"); setOtrosSitiosSitio(null)
                                }}></button>
                        ) : null}
                    </div>

                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '26%'}}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Calcificación:</div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={ParedToraxCalcificacion}
                            onChange={(e) => setParedToraxCalcificacion_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {ParedToraxCalcificacion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setParedToraxCalcificacion_cod(null);
                                    actualizarPropiedadesBase(null, "neu_pared_toraxica", "calificacion");
                                    setParedToraxCalcificacion(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={DeFrenteCalcificacion}
                            onChange={(e) => setDeFrenteCalcificacion_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {DeFrenteCalcificacion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDeFrenteCalcificacion_cod(null);
                                    actualizarPropiedadesBase(null, "neu_frente", "calificacion");
                                    setDeFrenteCalcificacion(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={DiafragmaCalcificacion}
                            onChange={(e) => setDiafragmaCalcificacion_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {DiafragmaCalcificacion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDiafragmaCalcificacion_cod(null);
                                    actualizarPropiedadesBase(null, "neu_diagrama", "calificacion");
                                    setDiafragmaCalcificacion(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={OtrosSitiosCalcificacion}
                            onChange={(e) => setOtrosSitiosCalcificacion_cod(e.value.code)}
                            options={OpcionesSitioCalcificacion} optionLabel="name" />
                        {OtrosSitiosCalcificacion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setOtrosSitiosCalcificacion_cod(null);
                                    actualizarPropiedadesBase(null, "neu_otros_sitios", "calificacion");
                                    setOtrosSitiosCalcificacion(null)
                                }}></button>
                        ) : null}
                    </div>

                </div>
            </div>


            <div className="Ano9rmalidPleuralesPart02 w-6 flex gap-1" style={{ flexWrap: 'nowrap' }}>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '48%'}}>
                    <div className="w-full" style={{ ...subTituloNormal }}></div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Extención</div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Ancho</div>
                    <div className="w-full relative textAHRadioplogiaTamañoAgrd" style={{}}>
                        <p className="absolute" style={{ width: '350px', ...subTituloNormal }}>
                            Obliteración de ángulo costrofrénico
                        </p>
                    </div>

                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '26%'}}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Derecha:</div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={ExtenciónDerecha}
                            onChange={(e) => setExtenciónDerecha_cod(e.value.code)}
                            options={OpcionesExtención} optionLabel="name" />
                        {ExtenciónDerecha != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                onClick={() => {
                                    setExtenciónDerecha_cod(null);
                                    actualizarPropiedadesBase(null, "neu_extension", "derecha"); setExtenciónDerecha(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={AnchoDerecha}
                            onChange={(e) => setAnchoDerecha_cod(e.value.code)}
                            options={OpcionesAncho} optionLabel="name" />
                        {AnchoDerecha != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setAnchoDerecha_cod(null);
                                    actualizarPropiedadesBase(null, "neu_ancho", "derecha"); setAnchoDerecha(null)
                                }}></button>
                        ) : null}
                    </div>


                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '26%'}}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Izquierda:</div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={ExtenciónIzquierda}
                            onChange={(e) => setExtenciónIzquierda_cod(e.value.code)}
                            options={OpcionesExtención} optionLabel="name" />
                        {ExtenciónIzquierda != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setExtenciónIzquierda_cod(null);
                                    actualizarPropiedadesBase(null, "neu_extension", "izquierda");
                                    setExtenciónIzquierda(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={AnchoIzquierda}
                            onChange={(e) => setAnchoIzquierda_cod(e.value.code)}
                            options={OpcionesAncho} optionLabel="name" />
                        {AnchoIzquierda != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setAnchoIzquierda_cod(null);
                                    actualizarPropiedadesBase(null, "neu_ancho", "izquierda");
                                    setAnchoIzquierda(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <Dropdown className="w-full" style={{ height: '48px' }} value={obliteracion}
                            onChange={(e) => setobliteracion_cod(e.value.code)}
                            options={OpcionesObliteracion} optionLabel="name" />
                        {obliteracion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', top: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setobliteracion_cod(null);
                                    actualizarPropiedadesBaseNivel01(null, "neu_obliteracion");
                                    setobliteracion(null)
                                }}></button>
                        ) : null}
                    </div>


                </div>
            </div>
        </div>

    );
};

export default AnormalidadesPleuralesContent;

//Importaciones de componentes generales
import React, { useRef, useEffect, useState } from "react";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";

//Importación de componentes externos
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice";
import AuthUser from "../../../AuthUser";

//Importaciones de componentes propios
import { EmpresaComponentCoorporativa, PersonaComponentCoorporativo } from "../../../Components/Perfil/Coorporativo";
import SectionNotificacionCoorporativo01 from './SeccionNotificacionesCoorp01';

//Importaciones de estilos propios
import "../../../Style/PagePerfil1.css";
import "./MediaQueryPerfilEmpresa.css";

function PagePerfilEmpresa() {
    const { http } = AuthUser();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [empresa, setEmpresa] = useState({
        id: 0,
        tipo_documento_id: null,
        distrito_id: null,
        numero_documento: null,
        razon_social: null,
        direccion: null,
        estado_pago: null,
        latitud: null,
        longitud: null,
        tipo_documento: null,
        distrito: null,
        celulares: null,
        correos: null,
        detracciones: null,
        entidad_pagos: null
    });
    const [empresaUpdate, setEmpresaUpdate] = useState([]);
    const [persona, setPersona] = useState([]);
    const [personaUpdate, setPersonaUpdate] = useState([]);

    //Funcion para mostrar el toast en la pantala principal (Se les envia a los componentes hijos para que ellos mismos puedan mostrar el toast desde su posición)
    const showToast = (type, title, detail) => {
        toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000,
        });
    };

    //Funcion para traer todos los datos de la empresa seleccionada (retorna los datos de la empresa para mostrarlo por la pantalla)
    const getEmpresa = async () => {
        try {
            const response = await http.get('empresa/show')
            if (response?.data) {
                setEmpresa(response?.data);
                setEmpresaUpdate({
                    ...empresaUpdate,
                    id: response.data?.id,
                    tipo_documento: response.data?.tipo_documento,
                    tipo_documento_id: response.data?.tipo_documento_id,
                    numero_documento: response.data?.numero_documento,
                    razon_social: response.data?.razon_social,
                    celular: response.data?.celulares[0]?.celular,
                    correo: response.data?.correos[0]?.correo,
                    distrito: response.data?.distrito,
                    distrito_id: response.data?.distrito_id,
                    direccion: response.data?.direccion,
                });
                localStorage.logo = response.data?.logo
            } else if (response?.data?.error) {
                showToast('error', 'Empresa', response.data.error)
            }
        } catch (error) {
            console.error('Error carga de datos empresa', error)
        }
    };

    //Funcion para traer todos los datos de la persona seleccionada (retorna los datos de la persona para mostrarlo por la pantalla)
    const getPersona = async () => {
        try {
            const response = await http.get('persona/show')
            if (response?.data?.resp?.persona) {
                setPersona(response?.data?.resp?.persona);
                setPersonaUpdate(response?.data?.resp?.persona);
                return response?.data?.resp?.persona;
            } else if (response?.data?.error) {
                showToast('error', 'Persona', response.data.error)
            }
        } catch (error) {
            console.error('Error carga datos persona', error)
        }
    }

    //Funcion para actualizar datos de empresa.
    const handleSubmitUpdate = async (data) => {
        dispatch(startLoading())
        try {
            const response = await http.post(`empresa/update/me/login`, data)
            if (response?.data?.resp) {
                await Promise.all([
                    getEmpresa()
                ])
                showToast(
                    "success",
                    "Datos actualizados correctamente",
                    "Los datos de empresa se actualizaron correctamente"
                )
            } else if (response?.data?.error) {
                showToast('error', 'Actualización de datos - empresa', response.data.error)
            }
        } catch (error) {
            showToast('error', 'Actualización de datos - empresa', 'Contacte a soporte técnico')
            console.error("Error actualización datos - empresa", error);
        } finally {
            dispatch(endLoading())
        }
    }

    // Funcion general que ejecuta las funciones importantes esperando una data de respuesta
    const fetchAllData = async () => {
        try {
            await Promise.all([
                getEmpresa(),
                getPersona()
            ])
        } catch (error) {
            console.error('Error en la carga de datos', error)
        } finally {
            dispatch(endLoading())
        }
    }

    // UseEffect que se ejecutara cada vez que se inicialize el componente, esto ejecuta:
    useEffect(() => {
        //Loading Inicializado
        dispatch(startLoading());
        //Funcion Principal
        fetchAllData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Toast ref={toast} />
            <div className="ContenedorGeneralPerfil flex flex-wrap flex-column mt-3 pr-6 pl-6" style={{ gap: '1.5rem' }}>
                <div>
                    <p className="tit "> Mi Perfil </p>
                    <p className="con" style={{ maxWidth: '333px' }}>Adminístralo a tu mejor manera </p>
                </div>
                <div className="gap-3 perfil-res ContenedorGeneralComponente" style={{ width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className=" flex flex-wrap ContenedorComponentePersona">
                        <PersonaComponentCoorporativo
                            showToast={showToast}
                            persona={persona}
                            personaUpdate={personaUpdate}
                            setPersonaUpdate={setPersonaUpdate}
                            getPersona={getPersona}
                            institucion={"Coorporativo"}
                        />
                    </div>
                    <div className="flex flex-wrap ContenedorComponenteEmpresa">
                        <EmpresaComponentCoorporativa
                            showToast={showToast}
                            dataShow={empresa}
                            setDataShow={setEmpresa}
                            data={empresaUpdate}
                            setBregma={setEmpresaUpdate}
                            getEmpresa={getEmpresa}
                            handleSubmitUpdate={handleSubmitUpdate}
                            entidad='Empresa'
                            api={"empresa/update/foto"}
                        />
                    </div>

                </div>
                <div className=" w-full flex flex-row">
                    <SectionNotificacionCoorporativo01
                        RazonS={empresa.razon_social}
                    >
                    </SectionNotificacionCoorporativo01>
                </div>
            </div>
        </>
    )
}

export default PagePerfilEmpresa
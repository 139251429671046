import React from 'react'
import Logo from '../../../Images/Icon-ST/Icon-check.svg'
import img from '../../../Images/Icon-ST/Doc-Bienvenida-Soporte.png'
import check from '../../../Images/Icon-ST/check-circulo.png'
import "../../../Style/style.css";

export default function Bienvenidos() {
    return (
        <div className='ContenedorCardSoporte flex w-full' style={{ background: 'white', borderRadius: '17px' }}>
            {/* Lado izquierdo */}
            <div className='ConenedorLado_Izquierdo_Bienvenido flex flex-column flex-1 gap-3' style={{ padding: '25px' }}>
                <div className='flex flex-row'>
                    {/* Logo */}
                    <div className='flex flex-column' style={{ background: '#F4ECFF', width: '42px', height: '42.86px', borderRadius: '10px', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={Logo} alt="Check" style={{ width: '20px', height: '20px' }} />
                    </div>
                    {/* Text */}
                    <div className='flex flex-column w-full gap-1'>
                        <h1 className='flex w-full' style={{ color: '#5555D8', fontWeight: '700', fontSize: '25px', justifyContent: 'center' }}>Bienvenido</h1>
                        {/*<h2 className='flex w-full' style={{ color: '#8C62E3', fontWeight: '500', fontSize: '14px', justifyContent: 'center' }}>Administrador Clinica</h2>*/}
                    </div>
                </div>
                <div className='flex'>
                    <span className='flex w-full' style={{ textAlign: 'center', fontWeight: '400', fontSize: '14px', color: '#7B8794' }}>Si cuenta con alguna duda en el uso del servicio, por favor comuníquese con nosotros.</span>
                </div>
                <div className='flex flex-column gap-3'>
                    <span className='flex gap-1' style={{ fontWeight: '300', fontSize: '13.5px', alignItems: 'center' }}>
                        <img src={check} alt="Check" style={{ width: '26px', height: '26px' }} />
                        Preguntanos  a través de nuestros canales.
                    </span>
                    <span className='flex gap-1' style={{ fontWeight: '300', fontSize: '13.5px', alignItems: 'center' }}>
                        <img src={check} alt="Check" style={{ width: '26px', height: '26px' }} />
                        Edita la información de tus contactos.
                    </span>
                    <span className='flex gap-1' style={{ fontWeight: '300', fontSize: '13.5px', alignItems: 'center' }}>
                        <img src={check} alt="Check" style={{ width: '26px', height: '26px' }} />
                        Agrega nuevos tutoriales.
                    </span>
                </div>

            </div>

            {/* lado derecho img */}
            <div className='Contenedor_LadoDerecho_IMG_Bienvenido flex flex-2' style={{ padding: '25px', borderLeft: '0.5px solid rgba(69, 69, 188, 0.6)' }}>
                <img src={img} alt="Doc" style={{ width: '203.36px', height: '263px' }} />
            </div>
        </div>
    )
}

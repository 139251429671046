import React from 'react'

function PageMisPagosE() {
    return (
        <div>
            <div>
                <span className='tit'>Pagos</span><br />
                <span className='con'>Pagina Principal de pagos</span>
            </div>
        </div>
    )
}

export default PageMisPagosE
import React, { useState, useEffect, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import { Dropdown } from 'primereact/dropdown';
import { MGContext } from "../MGProvider";
import { VoiceChat } from "@mui/icons-material";
import { InputTextarea } from "primereact/inputtextarea";

const TamizajeDermatologicoPreguntas = () => {
  const [selectedRating, setSelectedRating] = useState(null);
  //    Tienes que hacer values para las diferentes observaciones si no todo se van a escribir en uno s
  const [value, setValue] = useState("");
  const { tamizajeDermatologico, setTamizajeDermatologico } = useContext(MGContext);
  const Respuestas = [
    { respuesta: 'SI' },
    { respuesta: 'NO' }
  ];
  // VARIABLES DE RESPUESTAS DE LA PREGUNTA:
  const [Rpta01, setRpta01] = useState(tamizajeDermatologico?.tam_preguntas[0] && tamizajeDermatologico?.tam_preguntas[0].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta01);
  const [subRpta01, setsubRpta01] = useState(tamizajeDermatologico?.tam_preguntas[1] && tamizajeDermatologico?.tam_preguntas[1]?.descripcion || tamizajeDermatologico?.Preguntas?.subRpta01);
  const [Rpta02, setRpta02] = useState(tamizajeDermatologico?.tam_preguntas[2] && tamizajeDermatologico?.tam_preguntas[2].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta02);
  const [subRpta02, setsubRpta02] = useState(tamizajeDermatologico?.tam_preguntas[3] && tamizajeDermatologico?.tam_preguntas[3]?.descripcion || tamizajeDermatologico?.Preguntas?.subRpta02);
  const [Rpta03, setRpta03] = useState(tamizajeDermatologico?.tam_preguntas[4] && tamizajeDermatologico?.tam_preguntas[4].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta03);
  const [Rpta04, setRpta04] = useState(tamizajeDermatologico?.tam_preguntas[5] && tamizajeDermatologico?.tam_preguntas[5].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta04);
  const [Rpta05, setRpta05] = useState(tamizajeDermatologico?.tam_preguntas[6] && tamizajeDermatologico?.tam_preguntas[6].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta05);
  const [subRpta05, setsubRpta05] = useState(tamizajeDermatologico?.tam_preguntas[7] && tamizajeDermatologico?.tam_preguntas[7]?.descripcion || tamizajeDermatologico?.Preguntas?.subRpta05);
  const [Rpta06, setRpta06] = useState(tamizajeDermatologico?.tam_preguntas[8] && tamizajeDermatologico?.tam_preguntas[8].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta06);
  const [subRpta06, setsubRpta06] = useState(tamizajeDermatologico?.tam_preguntas[9] && tamizajeDermatologico?.tam_preguntas[9]?.descripcion || tamizajeDermatologico?.Preguntas?.subRpta06);
  const [Rpta07, setRpta07] = useState(tamizajeDermatologico?.tam_preguntas[10] && tamizajeDermatologico?.tam_preguntas[10].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta07);
  const [subRpta07, setsubRpta07] = useState(tamizajeDermatologico?.tam_preguntas[11] && tamizajeDermatologico?.tam_preguntas[11]?.descripcion || tamizajeDermatologico?.Preguntas?.subRpta07);
  const [Rpta08, setRpta08] = useState(tamizajeDermatologico?.tam_preguntas[12] && tamizajeDermatologico?.tam_preguntas[12].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta08);
  const [Rpta09, setRpta09] = useState(tamizajeDermatologico?.tam_preguntas[13] && tamizajeDermatologico?.tam_preguntas[13].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta09);
  const [subRpta09, setsubRpta09] = useState(tamizajeDermatologico?.tam_preguntas[14] && tamizajeDermatologico?.tam_preguntas[14]?.descripcion || tamizajeDermatologico?.Preguntas?.subRpta09);
  const [Rpta10, setRpta10] = useState(tamizajeDermatologico?.tam_preguntas[15] && tamizajeDermatologico?.tam_preguntas[15].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta10);
  const [Rpta11, setRpta11] = useState(tamizajeDermatologico?.tam_preguntas[16] && tamizajeDermatologico?.tam_preguntas[16].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta11);
  const [Rpta12, setRpta12] = useState(tamizajeDermatologico?.tam_preguntas[17] && tamizajeDermatologico?.tam_preguntas[17].respuesta === "SI" ? Respuestas[0] : Respuestas[1] ?? tamizajeDermatologico.Preguntas.Rpta12);

  const SeccionPregunta = ({ varAlmac, setvarAlmac, pregunta, condicion, varAlmac02, setvarAlmac02, pregunta02 }) => (
    <>
      <div className="ContendorPrincipal_TamiDerma w-full h-auto align-items-center justify-content-between flex p-1">
        <div className="ContendorPregunta_TamiDerma w-9 h-3rem relative flex align-items-center justify-content-start" style={{ fontFamily: "Montserrat", fontSize: '15px' }}>{pregunta}</div>
        <div className={"ContendorRespuestas_TamiDerma w-3 h-3rem flex align-items-center justify-content-center relative "}>
          <Dropdown value={varAlmac} onChange={(e) => { setvarAlmac(e.value);  }} options={Respuestas} optionLabel="respuesta"
            className="" style={{ width: '105px' }} />
        </div>
        <style>
          {`
              .ContendorPrincipal_TamiDerma {
                border: 1px solid #5555d8;
                border-radius: 10px;
              }
              .ContendorPregunta_TamiDerma {
  
                left: 7px;
              }
              .DivisorBotonRespuesta {
                width: 10px;
                background-color: #5555d8;
              }
              .ContendorRespuestas_TamiDerma {
  
                right: 7px;
              }
            `}
        </style>
      </div>
      {condicion ? (
        <div className="ContendorPrincipal_TamiDerma w-full h-auto align-items-center justify-content-between flex flex-column p-2">
          <div className="ContendorPregunta_TamiDerma w-full h-auto  flex align-items-center justify-content-start mb-2" style={{ fontFamily: "Montserrat", fontSize: '15px' }}>{pregunta02}</div>
          <div className={"ContendorRespuestas_TamiDerma w-full h-auto flex align-items-center justify-content-end  " + varAlmac02}>
            <VoiceButton
              value={varAlmac02}
              onChange={setvarAlmac02}
              className="w-full h-auto"
            />
          </div>
        </div>
      ) : null}
    </>
  );

  const editarObservaciones = (nuevaObservacion, Propiedad) => {
    setTamizajeDermatologico(prevState => ({
      ...prevState,
      Preguntas: {
        ...prevState.Preguntas,
        [Propiedad]: nuevaObservacion
      }
    }));
  };

  //   const editarTamPreguntas = (index, id, respuesta, descripcion) => {
  //     let aux_preguntas = {
  //       med_tam_derm_preguntas_id: id,
  //       respuesta: respuesta,
  //       descripcion: descripcion
  //     }
  //   setTamizajeDermatologico(prevState => ({
  //     ...prevState,
  //     tam_preguntas: [
  //       ...prevState.tam_preguntas,
  //       aux_preguntas
  //     ]
  //   }));
  // };

  const editarTamPreguntas = (index, id, respuesta, descripcion) => {
    let aux_preguntas = {
      med_tam_derm_preguntas_id: id,
      respuesta: respuesta,
      descripcion: descripcion
    }
    setTamizajeDermatologico(prevState => ({
      ...prevState,
      tam_preguntas: {
        ...prevState.tam_preguntas,
        [index]: aux_preguntas
      }
    }));
  };

  const LimpiarNo = (variablesVeri, ValorAVeri, FinalVar, funcion) => {
    // Verificar que las variables no sean nulas o indefinidas
    if (variablesVeri && ValorAVeri && variablesVeri.respuesta === ValorAVeri.respuesta) {
      // Limpiar FinalVar si la condición se cumple
      // Llamar a la función de actualización del estado para establecer Rpta01 en null
      funcion("");
    }
  }

  // Llamar a LimpiarNo con los parámetros adecuados


  useEffect(() => {
    //PREGUNTA 01
    editarObservaciones(Rpta01, "Rpta01");
    LimpiarNo(Rpta01, { respuesta: 'NO' }, "subRpta01", setsubRpta01)
    if (Rpta01.respuesta === 'SI') {
      editarTamPreguntas(0, 1, Rpta01.respuesta, '')
    } else if (Rpta01.respuesta === 'NO') {
      editarTamPreguntas(0, 1, Rpta01.respuesta, '')
    }

    //PREGUNTA 02
    editarObservaciones(subRpta01, "subRpta01");
    if (subRpta01) {
      editarTamPreguntas(1, 2, '', subRpta01)
    }

    //PREGUNTA 03
    editarObservaciones(Rpta02, "Rpta02");
    if (Rpta02.respuesta === 'SI') {
      editarTamPreguntas(2, 3, Rpta02.respuesta, '')
    } else if (Rpta02.respuesta === 'NO') {
      editarTamPreguntas(2, 3, Rpta02.respuesta, '')
    }

    // //PREGUNTA 04
    LimpiarNo(Rpta02, { respuesta: 'NO' }, "subRpta02", setsubRpta02)
    editarObservaciones(subRpta02, "subRpta02");
    if (subRpta02) {
      editarTamPreguntas(3, 4, Rpta02.respuesta, subRpta02)
    }

    // //PREGUNTA 05
    editarObservaciones(Rpta03, "Rpta03");
    if (Rpta03.respuesta === 'SI') {
      editarTamPreguntas(4, 5, Rpta03.respuesta, '')
    } else if (Rpta03.respuesta === 'NO') {
      editarTamPreguntas(4, 5, Rpta03.respuesta, '')
    }

    // //PREGUNTA 06
    editarObservaciones(Rpta04, "Rpta04");
    if (Rpta04.respuesta === 'SI') {
      editarTamPreguntas(5, 6, Rpta04.respuesta, '')
    }else if (Rpta04.respuesta === 'NO') {
      editarTamPreguntas(5, 6, Rpta04.respuesta, '')
    }

    // //PREGUNTA 07
    editarObservaciones(Rpta05, "Rpta05");
    LimpiarNo(Rpta05, { respuesta: 'NO' }, "subRpta05", setsubRpta05)
    if (Rpta05.respuesta === 'SI') {
      editarTamPreguntas(6, 7, Rpta05.respuesta, '')
    } else if (Rpta05.respuesta === 'NO') {
      editarTamPreguntas(6, 7, Rpta05.respuesta, '')
    }

    // //PREGUNTA 08
    editarObservaciones(subRpta05, "subRpta05");
    if (subRpta05) {
      editarTamPreguntas(7, 8, Rpta05.respuesta, subRpta05)
    }

    //PREGUNTA 09
    editarObservaciones(Rpta06, "Rpta06");
    LimpiarNo(Rpta06, { respuesta: 'NO' }, "subRpta06", setsubRpta06)
    if (Rpta06.respuesta === 'SI') {
      editarTamPreguntas(8, 9, Rpta06.respuesta, '')
    } else if (Rpta06.respuesta === 'NO') {
      editarTamPreguntas(8, 9, Rpta06.respuesta, '')
    }

    //PREGUNTA 10
    editarObservaciones(subRpta06, "subRpta06");
    if (subRpta06) {
      editarTamPreguntas(9, 10, Rpta06.respuesta, subRpta06)
    }

    //PREGUNTA 11
    editarObservaciones(Rpta07, "Rpta07");
    LimpiarNo(Rpta07, { respuesta: 'NO' }, "subRpta07", setsubRpta07)
    if (Rpta07.respuesta === 'SI') {
      editarTamPreguntas(10, 11, Rpta07.respuesta, '')
    } else if (Rpta07.respuesta === 'NO') {
      editarTamPreguntas(10, 11, Rpta07.respuesta, '')
    }

    //PREGUNTA 12
    editarObservaciones(subRpta07, "subRpta07");
    if (subRpta07) {
      editarTamPreguntas(11, 12, Rpta07.respuesta, subRpta07)
    }

    //PREGUNTA 13
    editarObservaciones(Rpta08, "Rpta08");
    if (Rpta08.respuesta === 'SI') {
      editarTamPreguntas(12, 13, Rpta08.respuesta, '')
    } else if (Rpta08.respuesta === 'NO') {
      editarTamPreguntas(12, 13, Rpta08.respuesta, '')
    }

    //PREGUNTA 14
    editarObservaciones(Rpta09, "Rpta09");
    LimpiarNo(Rpta09, { respuesta: 'NO' }, "subRpta09", setsubRpta09)
    if (Rpta09.respuesta === 'SI') {
      editarTamPreguntas(13, 14, Rpta09.respuesta, '')
    } else if (Rpta09.respuesta === 'NO') {
      editarTamPreguntas(13, 14, Rpta09.respuesta, '')
    }

    //PREGUNTA 15
    editarObservaciones(subRpta09, "subRpta09");
    if (subRpta09) {
      editarTamPreguntas(14, 15, Rpta09.respuesta, subRpta09)
    }

    //PREGUNTA 16
    editarObservaciones(Rpta10, "Rpta10");
    if (Rpta10.respuesta === 'SI') {
      editarTamPreguntas(15, 16, Rpta10.respuesta, '')
    } else if (Rpta10.respuesta === 'NO') {
      editarTamPreguntas(15, 16, Rpta10.respuesta, '')
    }

    //PREGUNTA 17
    editarObservaciones(Rpta11, "Rpta11");
    if (Rpta11.respuesta === 'SI') {
      editarTamPreguntas(16, 17, Rpta11.respuesta, '')
    } else if (Rpta11.respuesta === 'NO') {
      editarTamPreguntas(16, 17, Rpta11.respuesta, '')
    }

    //PREGUNTA 18
    editarObservaciones(Rpta12, "Rpta12");
    if (Rpta12.respuesta === 'SI') {
      editarTamPreguntas(17, 18, Rpta12.respuesta, '')
    } else if (Rpta12.respuesta === 'NO') {
      editarTamPreguntas(17, 18, Rpta12.respuesta, '')
    }

  }, [Rpta01, subRpta01, subRpta02, Rpta02, Rpta03, Rpta04, Rpta05, subRpta05, Rpta06, subRpta06, Rpta07, subRpta07, Rpta08, Rpta09, subRpta09, Rpta10, Rpta11, Rpta12]);

  return (
    <div className="w-full h-auto" >
      <div className="w-full h-auto flex flex-column align-items-center justify-content-center gap-1">
        <div className="w-full h-4rem  flex align-items-center justify-content-between gap-2" style={{ borderRadius: '10px' }}>
          <div className="w-9 h-full flex align-items-center justify-content-center font-semibold" style={{ borderRadius: '10px', backgroundColor: 'rgb(235, 240, 255)', color: '#5555d8', fontFamily: "Montserrat" }}>PREGUNTAS</div>
          <div className="w-3 h-full flex align-items-center justify-content-center font-semibold" style={{ borderRadius: '10px', backgroundColor: 'rgb(235, 240, 255)', color: '#5555d8', fontFamily: "Montserrat" }}>RESPUESTAS</div>
        </div>

        {SeccionPregunta({ varAlmac: Rpta01, setvarAlmac: setRpta01, pregunta: "¿Sufre Ud. de enfermedades en la piel?", condicion: (Rpta01.respuesta === 'SI'), varAlmac02: subRpta01, setvarAlmac02: setsubRpta01, pregunta02: "¿Qué diagnóstico tiene?" })}

        {SeccionPregunta({ varAlmac: Rpta02, setvarAlmac: setRpta02, pregunta: "¿Actualmente tiene alguna lesión (ampolla, escamas, rascados) en la piel?", condicion: (Rpta02.respuesta === 'SI'), varAlmac02: subRpta02, setvarAlmac02: setsubRpta02, pregunta02: "¿Donde se localiza?" })}

        {SeccionPregunta({ varAlmac: Rpta03, setvarAlmac: setRpta03, pregunta: "¿Presenta algún cambio de coloración en la piel?", condicion: (false), varAlmac02: subRpta02, setvarAlmac02: setsubRpta02, pregunta02: "" })}

        {SeccionPregunta({ varAlmac: Rpta04, setvarAlmac: setRpta04, pregunta: "¿Estas lesiones se repiten varias veces?", condicion: (false), varAlmac02: subRpta02, setvarAlmac02: setsubRpta02, pregunta02: "" })}

        {SeccionPregunta({ varAlmac: Rpta05, setvarAlmac: setRpta05, pregunta: "¿Ud. tiene enrojecimiento de alguna zona del cuerpo?", condicion: (Rpta05.respuesta === 'SI'), varAlmac02: subRpta05, setvarAlmac02: setsubRpta05, pregunta02: "¿Dónde se localiza?" })}

        {SeccionPregunta({ varAlmac: Rpta06, setvarAlmac: setRpta06, pregunta: "¿Tiene comezón?", condicion: (Rpta06.respuesta === 'SI'), varAlmac02: subRpta06, setvarAlmac02: setsubRpta06, pregunta02: "¿Dónde se localiza?" })}

        {SeccionPregunta({ varAlmac: Rpta07, setvarAlmac: setRpta07, pregunta: "¿Presenta hinchazon en parte de su cuerpo?", condicion: (Rpta07.respuesta === 'SI'), varAlmac02: subRpta07, setvarAlmac02: setsubRpta07, pregunta02: "¿Dónde se localiza?" })}

        {SeccionPregunta({ varAlmac: Rpta08, setvarAlmac: setRpta08, pregunta: "¿Sufre de Rinitis Alérgica o Asma?", condicion: (false), varAlmac02: subRpta07, setvarAlmac02: setsubRpta07, pregunta02: "" })}

        {SeccionPregunta({ varAlmac: Rpta09, setvarAlmac: setRpta09, pregunta: "¿Usa EPP?", condicion: (Rpta09.respuesta === 'SI'), varAlmac02: subRpta09, setvarAlmac02: setsubRpta09, pregunta02: "¿Qué tipo de protección  usa?" })}

        {SeccionPregunta({ varAlmac: Rpta10, setvarAlmac: setRpta10, pregunta: "¿Esta tomando alguna mediación?", condicion: (false), varAlmac02: subRpta09, setvarAlmac02: setsubRpta09, pregunta02: "" })}

        {SeccionPregunta({ varAlmac: Rpta11, setvarAlmac: setRpta11, pregunta: "¿El paciente al examen físico presenta algún lesión sugerente a Dermatopatía?", condicion: (false), varAlmac02: subRpta09, setvarAlmac02: setsubRpta09, pregunta02: "" })}

        {SeccionPregunta({ varAlmac: Rpta12, setvarAlmac: setRpta12, pregunta: "¿El paciente necesita ser evaluado por médico dermatologo para la realización de las siguientes pruebas?", condicion: (false), varAlmac02: subRpta09, setvarAlmac02: setsubRpta09, pregunta02: "" })}
      </div>
    </div>
  );
};
export default TamizajeDermatologicoPreguntas;

import React, { useContext, useEffect } from 'react'
import CuestionarioTable from '../Pure/CuestionarioTable'
import { EspiContext } from '../../../../Page/Espirometria/EspiProvider'

export default function Pregunta1() {
   const { preguntasContext, setPreguntasContext } = useContext(EspiContext)

   const setAntecedentesPulmonares = (data) => {
      setPreguntasContext({
         ...preguntasContext,
         esp_preguntas_1: {
            ...preguntasContext.esp_preguntas_1,
            antecedentes_pulmonares: data
         }
      });
   }

   const setSintomasActuales = (data) => {
      setPreguntasContext({
         ...preguntasContext,
         esp_preguntas_1: {
            ...preguntasContext.esp_preguntas_1,
            sintomas_actuales: data
         }
      });
   }

   return (
      <>
         <div className='card'>
            <CuestionarioTable header={"Antecedentes Pulmonares"} datos={preguntasContext?.esp_preguntas_1?.antecedentes_pulmonares} setDatos={setAntecedentesPulmonares} />
         </div>
         <div className="card">
            <CuestionarioTable header={"Síntomas Actuales"} datos={preguntasContext?.esp_preguntas_1?.sintomas_actuales} setDatos={setSintomasActuales} />
         </div>
      </>
   )
}

import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

export default function DialogEliminarGenEvMed(props) {
   // const [visible, setVisible] = useState(false);

   const DeleteData = () => {
      props.setVisibleEliminar(false)
      props.ContextEliminar.splice(props.DiagMedID, 1)
   } 
   const footerContent = (
      <React.Fragment>
         <div className="flex justify-content-between">
         <Button
            label="Cancelar"
            // icon="pi pi-times"
            onClick={() => props.setVisibleEliminar(false)}
            className="p-button p-component delete w-full"
         />
         <Button
            label="Eliminar"
            // icon="pi pi-check"
            onClick={() => {props.funcion(props.DiagMedID); props.setVisibleEliminar(false)}}
            style={{
               color: "#5555D8",
               backgroundColor: "#EBEBFF",
               borderColor: "#5555D8",
           }}
            className="p-button-success w-full"
         />
      </div>
      </React.Fragment>
      
   );

   return (
      //    <div className="card flex justify-content-center">
      //       <Button
      //          className="mb-3"
      //          icon="pi pi-trash"
      //          style={{
      //             background: "#FFECEC",
      //             border: "none",
      //             borderRadius: "4px",
      //             color: "#FF6767",
      //          }}
      //          onClick={() => setVisible(true)}
      //       />
      <Dialog
         visible={props.VisibleEliminar}
         className="p-fluid"
         style={{ width: "450px", height: "220px" }}
         header={<p style={{ color: "#5555D8", fontSize: "32px" }}>Eliminar</p>}
         onHide={() => props.setVisibleEliminar(false)}
         footer={footerContent}
      >
         <p className="m-0">¿Está seguro que desea eliminar?</p>
      </Dialog>
      // </div>
   );
}

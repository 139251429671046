import React, { useState } from "react";
import { InputText } from "primereact/inputtext";

export default function Ocupacional({ Rnm, Cmp, Rne, setRnm, setCmp, setRne }) {
   const [show, setShow] = useState();

   const handleClick = (event) => {
      setShow(!show);
   };

   return (
      <div className="options mx-3">
         <div
            style={{
               marginTop: "20px",
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <label
               htmlFor="RNM"
               style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#4545bc",
               }}
            >
               RNM*:{" "}
            </label>
            <InputText
               // keyfilter="int"
               placeholder="Código"
               style={{ width: "400px", marginLeft: "6px" }}
               value={Rnm.toUpperCase()}
               onChange={(e) => { setRnm(e.target.value.toUpperCase()) }}
            ></InputText>
         </div>
         <div
            style={{
               marginTop: "20px",
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <label
               htmlFor="RNM"
               style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#4545bc",
               }}
            >
               CMP*:{" "}
            </label>
            <InputText
               // keyfilter="int"
               placeholder="Código"
               style={{ width: "400px" }}
               value={Cmp.toUpperCase()}
               onChange={(e) => { setCmp(e.target.value.toUpperCase()) }}
            ></InputText>
         </div>
         <div
            style={{
               marginTop: "20px",
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <label
               htmlFor="RNM"
               style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#4545bc",
               }}
            >
               RNE:{" "}
            </label>
            <InputText
               // keyfilter="int"
               placeholder="Código"
               style={{ width: "400px", marginLeft: "6px" }}
               value={Rne.toUpperCase()} 
               onChange={(e) => setRne(e.target.value.toUpperCase())}
            ></InputText>
         </div>
      </div>
   );
}

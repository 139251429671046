import { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';

export default function ContactList({contactosSeleccionados, estado}) {
    const [contactos, setContactos] = useState([
        
        { id: 1, nombres: 'Contacto 01', seleccionado: false },
        { id: 2, nombres: 'Contacto 02', seleccionado: false },
        { id: 3, nombres: 'Contacto 03', seleccionado: false },
        { id: 4, nombres: 'Contacto 04', seleccionado: false },
        { id: 5, nombres: 'Contacto 05', seleccionado: false },
        { id: 6, nombres: 'Contacto 06', seleccionado: false },
        { id: 7, nombres: 'Contacto 07', seleccionado: false },
        { id: 8, nombres: 'Contacto 08', seleccionado: false },
        { id: 9, nombres: 'Contacto 09', seleccionado: false },
    ]);
    function handleCheckboxChange(contacto) {
        const nuevosContactos = contactos.map((c) => {
            if (c.id === contacto.id) {
                return { ...c, seleccionado: !c.seleccionado };
            }
            return c;
        });
        setContactos(nuevosContactos);
    }

    /* Buscador */
    const [busqueda, setBusqueda] = useState('');

    function handleBusquedaChange(e) {
        setBusqueda(e.target.value);
    }

    const contactosFiltrados = contactos.filter((contacto) => {
        return (
            contacto.nombres.toLowerCase().includes(busqueda.toLowerCase()) ||
            contacto.seleccionado
        );
    });

    function handleMostrarContactos() {
        const contactosSelect = contactosFiltrados.filter(
            (contacto) => contacto.seleccionado
        );
        contactosSeleccionados(contactosSelect);
        estado(true);
    }

    return (
        <>
            <div className="p-input-icon-left w-full">
                <i className="pi pi-search" />
                <InputText placeholder="Buscar por nombre"
                    value={busqueda}
                    onChange={handleBusquedaChange} className="w-full" />
            </div>

            <div className="flex flex-column gap-2 font-bold h-15rem contactList">
                {contactosFiltrados.map((contacto) => (
                    <div
                        key={contacto.id}
                        className="border-bottom-1 border-gray-300 flex justify-content-between p-2"
                    >
                        <p>{contacto.nombres}</p>
                        <Checkbox
                            checked={contacto.seleccionado}
                            onChange={() => handleCheckboxChange(contacto)}
                        />
                    </div>
                ))}
            </div>

            <div className="flex flex-column justify-content-center align-items-center gap-3">
                <button className="w-7 p-button p-component flex gap-2 p-2 align-items-center justify-content-center border-gray-500 bg-transparent text-gray-500 text-sm">
                    <i className="pi pi-envelope"></i>Correo
                </button>
                <button className="w-7 p-button p-component flex gap-2 p-2 align-items-center justify-content-center border-indigo-500 bg-transparent text-indigo-500 text-sm">
                    <i className="pi pi-whatsapp"></i>WhatsApp
                </button>
            </div>

            <div className="flex-1 flex justify-content-center align-items-end gap-3 pl-6 pr-6">
                <button className="flex-1 p-button p-component flex gap-2 p-2 align-items-center justify-content-center border-gray-500 bg-transparent text-gray-500 text-sm">
                    <p>Cancelar</p>
                </button>
                <button onClick={handleMostrarContactos} className="flex-1 p-button p-component flex gap-2 p-2 align-items-center justify-content-center text-sm">
                    <i className="pi pi-send"></i>Enviar
                </button>
            </div>
        </>
    );
}

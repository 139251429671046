import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import "./CSSBoton.css";
import AuthUser from '../../../../AuthUser';

const ModalAtencionAtendida = (props) => {
    const { http } = AuthUser
    
    return (
        <Dialog
            visible={props.visible}
            header={
                <>
                    <h2 style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "18px", color: '#5555D8' }}>Finalizar</h2>
                </>
            }
            style={{ width: 'auto' }}
            modal
            footer={
                <div>
                    <button onClick={props.onNo} className='BotonCancelar312'>Cancelar</button>
                    <button onClick={props.onYes} className='BotonSave321'> Finalizar</button>
                </div>
            }
            onHide={props.onHide}
        >
            ¿Deseas finalizar la atención?
        </Dialog >
    );
};

export default ModalAtencionAtendida;

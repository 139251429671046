import React, { useContext, useEffect, useState } from 'react';
import InputCalendar from "../../form/InputCalendar";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { PsicologiaContext } from "../../../Page/Psicologia/PsicologiaContext";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';


const PsiExpLaboralForm = ({ visible, setVisible }) => {

   let { expLabContext, setExpLabContext } = useContext(PsicologiaContext)
   //Varibles de estado
   const [experiencia, setexperiencia] = useState([])

   const [fechaIngreso, setFechaIngreso] = useState(null);
   const [fechaSalida, setFechaSalida] = useState(null);
   const [fechaIngresoDate, setFechaIngresoDate] = useState(null);
   const [fechaSalidaDate, setFechaSalidaDate] = useState(null);
   const [nombreEmpresa, setnombreEmpresa] = useState(null);
   const [actEmpresa, setactEmpresa] = useState(null);
   const [puesto, setpuesto] = useState(null);
   const [laborTrabajo, setlaborTrabajo] = useState(null);
   const [laborTrabajoSelect, setLaborTrabajoSelect] = useState(null);
   const [causaRetiro, setcausaRetiro] = useState(null);

   //Opciones del dropdown

   const trabajadorOptions = [
      { label: 'Superficie', id: 1 },
      { label: 'Subsuelo', id: 2 }
   ]


   //UseEffects para que se guaden los datos de experiencia

   useEffect(() => {
      setexperiencia([{
         [`fecha_ingreso`]: fechaIngreso,
         [`fecha_salida`]: fechaSalida,
         [`fecha_ingreso_date`]: fechaIngresoDate,
         [`fecha_salida_date`]: fechaSalidaDate,
         [`nombre_empresa`]: nombreEmpresa,
         [`act_empresa`]: actEmpresa,
         [`puesto`]: puesto,
         [`labor_trabajo`]: laborTrabajo,
         [`labor_trabajo_select`]: laborTrabajoSelect,
         [`causa_retiro`]: causaRetiro,
         [`estado_registro`]: 'A'
      }])

   }, [fechaIngreso, fechaSalida, nombreEmpresa, actEmpresa, puesto, laborTrabajo, laborTrabajoSelect, causaRetiro])

   //Funcion para limpiar datos
   const limpiarDatos = () => {
      setFechaIngreso(null)
      setFechaSalida(null)
      setFechaIngresoDate(null)
      setFechaSalidaDate(null)
      setnombreEmpresa(null)
      setactEmpresa(null)
      setpuesto(null)
      setlaborTrabajo(null)
      setLaborTrabajoSelect(null)
      setcausaRetiro(null)
   };

   //funcion para agregar datos al arreglo
   const handleAgregarDatos = () => {
      const nuevasExperiencias = [...expLabContext, ...experiencia];
      setExpLabContext(nuevasExperiencias);
      limpiarDatos();
      setVisible(false)
      setLaborTrabajoSelect(null)
   };

   //funciones para agregar el campo de fecha a la experiencia
   const handleFechaIngresoChange = (e) => {
      if (e?.value) {
         let Fecha = e.value.toLocaleDateString('es-PE', { year: 'numeric', month: '2-digit', day: '2-digit' });
         const ordenFecha = Fecha.split('/')
         let fecha = ordenFecha[2] + '/' + ordenFecha[1] + '/' + ordenFecha[0];
         setFechaIngreso(fecha);
         setFechaIngresoDate(e.value);
      } else {
         const inputValue = e.target.value;
         setFechaIngreso(inputValue);
      };

   };

   const handleFechaSalidaChange = (e) => {
      if (e?.value) {
         let Fecha = e?.value.toLocaleDateString('es-PE', { year: 'numeric', month: '2-digit', day: '2-digit' });
         const ordenFecha = Fecha.split('/')
         let fecha = ordenFecha[2] + '/' + ordenFecha[1] + '/' + ordenFecha[0];

         setFechaSalida(fecha);
         setFechaSalidaDate(e.value);
      }else {
         const inputValue = e.target.value;
         setFechaSalida(inputValue);
      };
   };

   const handleLaborTrabajoChange = (e) => {
      setlaborTrabajo(e.value.label); // Establece la etiqueta
      setLaborTrabajoSelect(e.value); // Establece el objeto completo
   };

   //Cuerpo del footer del dialog
   const dialogFooter = (
      <div className="flex justify-content-end mt-4 gap-2">
         <Button
            type="button"
            label="Cancelar"
            className="delete"
            onClick={() => {
               setVisible(false);
               limpiarDatos();
            }}
         />
         <Button icon="pi pi-save" label="Guardar" onClick={() => handleAgregarDatos()} />
      </div>
   )

   return (

      <Dialog
         visible={visible}
         style={{ width: '400px', margin: '20px' }}
         onHide={() => setVisible(false)}
         header={<div style={{ marginBottom: "15px" }}>Experiencia Laboral</div>}
         footer={dialogFooter}
      >

         <div className='flex flex-wrap gap-5'>
            <div className="flex flex-wrap justify-content-between gap-3 w-full">
               <div className='flex flex-1 flex-wrap justify-content-between align-items-center gap-2'>
                  <label className='texto w-12 exp-laboral-txt'>Fecha ingreso</label>
                  <InputCalendar className="exp-laboral-calendar" value={experiencia?.fecha_ingreso_date || fechaIngresoDate} onChange={e => handleFechaIngresoChange(e)} />
               </div>
               <div className='flex flex-1 flex-wrap justify-content-between align-items-center gap-2'>
                  <label className='texto w-12 exp-laboral-txt'>Fecha retiro</label>
                  <InputCalendar className="exp-laboral-calendar" value={experiencia?.fecha_salida_date || fechaSalidaDate} onChange={e => handleFechaSalidaChange(e)} />
               </div>
            </div>
            <div className="w-12  flex flex-column gap-1 ">
               <div className="flex">
                  <label className='texto exp-laboral-txt'>Nombre de la empresa</label>
               </div>
               <InputText value={experiencia.nombre_empresa} onChange={e => setnombreEmpresa(e.target.value)} />
            </div>
            <div className="w-12  flex flex-column gap-1">
               <div className="flex">
                  <label className='texto exp-laboral-txt'>Act. Empresa</label>
               </div>
               <InputText onChange={e => setactEmpresa(e.target.value)} value={experiencia.act_empresa} />
            </div>
            <div className="pel_grid_responsive flex flex-wrap flex-row">
               <div className="flex flex-1">
                  <div >
                     <label className='texto exp-laboral-txt'>Puesto</label>
                  </div>
                  <InputText value={experiencia.puesto} onChange={e => setpuesto(e.target.value)} />
               </div>
               <div className="flex flex-1">
                  <div >
                     <label className='texto exp-laboral-txt'>Labor trabajo</label>
                  </div>
                  <Dropdown value={laborTrabajoSelect} onChange={(e) => handleLaborTrabajoChange(e)} options={trabajadorOptions} optionLabel="label" />
               </div>
            </div>
            <div className="w-12 flex flex-column gap-1">
               <div className="flex">
                  <label className='texto exp-laboral-txt'>Causa de retiro</label>
               </div>
               <InputText onChange={e => setcausaRetiro(e.target.value)} value={experiencia.causa_retiro} />
            </div>

         </div>

      </Dialog>
   );
};

export default PsiExpLaboralForm;
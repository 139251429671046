import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import "./estilos.css";

export default function OneSimbolo(props) {
    const [active, setActive] = useState(props.Respuestas.includes(props.Text));
    const agregar = () => {
        props.FuncionAgregar(props.Text);
        setActive(true);
    };
    const eliminar = () => {
        props.FuncionEliminar(props.Text);
        setActive(false);
    };
    const DiseñoButtonOnly={
        borderRadius:'10px',
        backgroundColor: '#EBF0FF',
        border: '4px solid white',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        fontFamily: 'Montserrat',
        fontWeight: '900',
        color: 'rgb(136, 136, 142)',
        transition: '.5s'
    }

    return (
        <div className="ContenOneeSelectflex align-items-center justify-content-center w-auto h-auto">
            <style>
                {`
                    .ContenOneeSelectflex .OnlySimbolo{
                        background-color: #6366F1 !important;
                        color: white !important;
                    }
                    .ContenOneeSelectflex .botonOnly:hover{
                        background-color: #A7A9EA !important;
                        color: white !important;
                    }
                `}
            </style>
            <button
                className={`pl-4 pr-4 pt-1 pb-1 botonOnly BotonOption_${props.Text} ${active ? 'OnlySimbolo' : ''}`}  
                onClick={() => { active ? eliminar() : agregar() }}
                style={{...DiseñoButtonOnly}}>
                {props.Text}
            </button>
        </div>
    );
}

import React, { useState, useEffect, useContext } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ScrollPanel } from 'primereact/scrollpanel';
//componentes
import VoiceButton from "../../../Components/ButtonVoice/VoiceButtonMGAltura";
//Servicios
import { PatologiaAntPer } from '../../../Services/ProductService1';
import { HallazgoAntPer } from '../../../Services/ProductHallazgo1';
//contexto
import { MGContext } from '../MGProvider';
import { RAContext } from "../../RutaAtencion/RAContext";
export default function AntecedentesH() {
   //Contexto

   let { stateAtencion, pageViewContext } = useContext(RAContext);
   let { examenAlturaContext, setExamenAlturaContext } = useContext(MGContext);

   //Estados para guardar los resultados
   const [antPresonales, setAntPresonales] = useState((examenAlturaContext?.med_personal_patologia?.length > 0 && examenAlturaContext?.med_personal_patologia) || PatologiaAntPer.map((patologia) => {
      return {
         patologia_id: patologia.id,
         resultado: "No",
         observacion: ""
      }
   }));



   const [hallazgosPositivos, setHallazgosPositivos] = useState((examenAlturaContext?.med_hallazgo_patologia?.length > 0 && examenAlturaContext?.med_hallazgo_patologia) || HallazgoAntPer.map((patologia) => {
      return {
         patologia_id: patologia.id,
         resultado: "No",
         observacion: ""
      }
   }));

   //Opciones para el dropdown
   const opciones = [
      { name: 'No', code: 1 },
      { name: 'Si', code: 2 },
   ];

   //Funciones para actualizar estados
   const handleAntecedentePersonal = (index, value, atributo) => {
      const updatedAntPresonales = [...antPresonales];
      updatedAntPresonales[index] = {
         ...updatedAntPresonales[index],
         [atributo]: value
      };
      setAntPresonales(updatedAntPresonales);
   };

   const handleHallazgo = (index, value, atributo) => {
      const updatedHallagos = [...hallazgosPositivos];
      updatedHallagos[index] = {
         ...updatedHallagos[index],
         [atributo]: value
      };
      setHallazgosPositivos(updatedHallagos);
   };

   useEffect(() => {
      setExamenAlturaContext({
         ...examenAlturaContext,
         med_personal_patologia: antPresonales,
         med_hallazgo_patologia: hallazgosPositivos
      })
   }, [antPresonales, hallazgosPositivos])

   return (
      <div className="flex flex-wrap gap-3">
         <div className="flex flex-column flex-1" style={{ boxShadow: "0px 4px 15px 0px #EBEBEE80" }}>
            <div className="flex flex-row justify-content-center mt-3">
               <h2
                  className="flex"
                  style={{
                     fontWeight: "600",
                     fontSize: "14px",
                     color: "#7B8794",
                     borderRadius: "13px",
                     background: "#F6F7FB",
                     justifyContent: "center",
                     padding: "10px",
                     width: "261px",
                  }}
               >
                  ANTECEDENTES PERSONALES
               </h2>
            </div>
            <table className="excel-table9">
               <thead>
                  <tr>
                     <th style={{ width: "30%" }}>Patología</th>
                     <th style={{ width: "20%" }}>Resultado</th>
                     <th style={{ width: "50%" }}>Observación</th>
                  </tr>
               </thead>
               <tbody>
                  {PatologiaAntPer.map((patologia, index) => (
                     <tr key={patologia.id}>
                        <td
                           style={{
                              overflow: "hidden",
                              fontSize: "15px",
                              wordWrap: "break-word",
                              whiteSpace: "pre-wrap",
                              textAlign: "start"
                           }}
                        >{patologia.code}</td>
                        <td>
                           <Dropdown
                              value={opciones.filter(opcion => antPresonales[index]?.resultado === opcion.name)[0]}
                              onChange={(e) => handleAntecedentePersonal(index, e.value.name, 'resultado')}
                              options={opciones}
                              optionLabel="name"
                              placeholder={'Si'}
                              disabled={stateAtencion[pageViewContext].estado === 3}
                              className="w-full md:w-8rem"
                           />
                        </td>
                        <td className="w-full">
                           <VoiceButton
                              value={antPresonales[index]?.observacion}
                              onChange={(newText) => handleAntecedentePersonal(index, newText, 'observacion')}
                              placeholder="Comentario"
                              disabled={stateAtencion[pageViewContext].estado === 3}
                           />
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         </div>

         <div className="flex flex-column flex-1" style={{ boxShadow: "0px 4px 15px 0px #EBEBEE80" }}>
            <div className="flex flex-row justify-content-center mt-3">
               <h2
                  className="flex"
                  style={{
                     fontWeight: "600",
                     fontSize: "14px",
                     color: "#7B8794",
                     borderRadius: "13px",
                     background: "#F6F7FB",
                     justifyContent: "center",
                     padding: "10px",
                     width: "356px",
                  }}
               >
                  HALLAZGOS POSITIVOS AL EXTERNO FÍSICO
               </h2>
            </div>
            <ScrollPanel style={{ width: "100%", height: "480px" }} className="custombar1">
               <table className="excel-table9">
                  <thead>
                     <tr>
                        <th style={{ width: "40%" }}>Patología</th>
                        <th style={{ width: "20%" }}>Resultado</th>
                        <th style={{ width: "40%" }}>Observación</th>
                     </tr>
                  </thead>
                  <tbody>
                     {HallazgoAntPer.map((hallazgo, index) => (
                        <tr key={hallazgo.id}>
                           <td
                              style={{
                                 overflow: "hidden",
                                 fontSize: "15px",
                                 wordWrap: "break-word",
                                 whiteSpace: "pre-wrap",
                                 textAlign: "start"
                              }}
                           >
                              <span>{hallazgo.code}</span>
                           </td>
                           <td>
                              <Dropdown
                                 value={opciones.filter(opcion => hallazgosPositivos[index]?.resultado === opcion.name)[0]}
                                 onChange={(e) => handleHallazgo(index, e.value.name, 'resultado')}
                                 options={opciones}
                                 optionLabel="name"
                                 placeholder={'Si'}
                                 disabled={stateAtencion[pageViewContext].estado === 3}
                                 className="w-full md:w-8rem"
                              />
                           </td>
                           <td className="w-full">
                              <VoiceButton
                                 value={hallazgosPositivos[index]?.observacion}
                                 onChange={(newText) => handleHallazgo(index, newText, 'observacion')}
                                 placeholder="Comentario"
                                 disabled={stateAtencion[pageViewContext].estado === 3}
                              />
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </ScrollPanel>
         </div>
      </div>
   );
}

import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import AudAudiometriaChart from "../../../../../Components/Audiometria/Aud/AudAudiometriaChart";

export default function GraficarAudiograma({ data }) {
   const [interpretIzq, setInterpretIzq] = useState(null);
   const [interpretDer, setInterpretDer] = useState(null);
   const [observ, setObserv] = useState("");
   const [visible, setVisible] = useState(false);

   let aereaDer = {
      v250: null,
      v500: data?.audiometria?.via_aerea?.derecha?.v_500,
      v1000: data?.audiometria?.via_aerea?.derecha?.v_1000,
      v2000: data?.audiometria?.via_aerea?.derecha?.v_2000,
      v3000: data?.audiometria?.via_aerea?.derecha?.v_3000,
      v4000: data?.audiometria?.via_aerea?.derecha?.v_4000,
      v6000: data?.audiometria?.via_aerea?.derecha?.v_6000,
      v8000: data?.audiometria?.via_aerea?.derecha?.v_8000,
   };
   let oseaDer = {
      v250: null,
      v500: data?.audiometria?.via_osea?.derecha?.v_500,
      v1000: data?.audiometria?.via_osea?.derecha?.v_1000,
      v2000: data?.audiometria?.via_osea?.derecha?.v_2000,
      v3000: data?.audiometria?.via_osea?.derecha?.v_3000,
      v4000: data?.audiometria?.via_osea?.derecha?.v_4000,
      v6000: data?.audiometria?.via_osea?.derecha?.v_6000,
      v8000: data?.audiometria?.via_osea?.derecha?.v_8000,
   };
   let aereaIzq = {
      v250: null,
      v500: data?.audiometria?.via_aerea?.izquierda?.v_500,
      v1000: data?.audiometria?.via_aerea?.izquierda?.v_1000,
      v2000: data?.audiometria?.via_aerea?.izquierda?.v_2000,
      v3000: data?.audiometria?.via_aerea?.izquierda?.v_3000,
      v4000: data?.audiometria?.via_aerea?.izquierda?.v_4000,
      v6000: data?.audiometria?.via_aerea?.izquierda?.v_6000,
      v8000: data?.audiometria?.via_aerea?.izquierda?.v_8000,
   };
   let oseaIzq = {
      v250: null,
      v500: data?.audiometria?.via_osea?.izquierda?.v_500,
      v1000: data?.audiometria?.via_osea?.izquierda?.v_1000,
      v2000: data?.audiometria?.via_osea?.izquierda?.v_2000,
      v3000: data?.audiometria?.via_osea?.izquierda?.v_3000,
      v4000: data?.audiometria?.via_osea?.izquierda?.v_4000,
      v6000: data?.audiometria?.via_osea?.izquierda?.v_6000,
      v8000: data?.audiometria?.via_osea?.izquierda?.v_8000,
   };

   const optionsInterpretacion = [
      { name: "Normoacusia" },
      { name: "D/C Trauma acústico leve" },
      { name: "D/C Trauma acústico avanzado" },
      { name: "D/C Hipoacusia inducida por ruido leve" },
      { name: "D/C Hipoacusia inducida por ruido moderado" },
      { name: "D/C Hipoacusia inducida por ruido avanzada" },
      { name: "D/C otras alteraciones no debida a ruido" },
      { name: "Hipoacusia conductiva leve" },
      { name: "Hipoacusia conductiva moderada" },
      { name: "Hipoacusia conductiva severa" },
      { name: "Hipoacusia conductiva profunda" },
      { name: "Hipoacusia neurosensorial leve" },
      { name: "Hipoacusia neurosensorial moderada" },
      { name: "Hipoacusia neurosensorial severa" },
      { name: "Hipoacusia neurosensorial profunda" },
      { name: "Hipoacusia mixta leve" },
      { name: "Hipoacusia mixta moderada" },
      { name: "Hipoacusia mixta severa" },
      { name: "Hipoacusia mixta profunda" },
   ];

   const footerContent = (
      <div>
         <Button
            label="Guardar"
            icon="pi pi-check"
            onClick={() => setVisible(false)}
            autoFocus
         />
      </div>
   );

   return (
      <div>
         <Button
            icon="fas fa-chart-line"
            className="p-button-rounded p-button-raised "
            onClick={() => setVisible(true)}
         />
         <Dialog
            visible={visible}
            style={{ width: "80%", height: "80%" }}
            onHide={() => setVisible(false)}
            footer={footerContent}
         >
            <h3 style={{ marginLeft: "4rem" }}>Gráfica</h3>

            <AudAudiometriaChart
               aereaIzq={Object.values(aereaIzq)}
               aereaDer={Object.values(aereaDer)}
               oseaIzq={Object.values(oseaIzq)}
               oseaDer={Object.values(oseaDer)}
            />

            <div
               style={{
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  marginLeft: "4rem",
               }}
            >
               <h4>Interpretación (Impresión Dx)</h4>
            </div>

            <div className="aud_aud_dropdown">
               <div className="aud_aud_dropdown_column_1 w-6 justify-content-evenly flex align-items-center">
                  <label>Oído Izquierdo</label>
                  <Dropdown
                     placeholder="Seleccione una opción"
                     value={interpretIzq}
                     onChange={(e) => setInterpretIzq(e.value)}
                     options={optionsInterpretacion}
                     optionLabel="name"
                     optionValue="name"
                  />
               </div>
               <div className="aud_aud_dropdown_column_2 w-6 justify-content-evenly flex align-items-center">
                  <label>Oído Derecho</label>
                  <Dropdown
                     placeholder="Seleccione una opción"
                     value={interpretDer}
                     onChange={(e) => setInterpretDer(e.value)}
                     options={optionsInterpretacion}
                     optionLabel="name"
                     optionValue="name"
                  />
               </div>
            </div>

            <div className="flex flex-column mt-3">
               <div>
                  <span>Observación</span>
               </div>
               <InputTextarea
                  placeholder="Ingrese una observación"
                  value={observ}
                  onChange={(e) => setObserv(e.target.value)}
                  autoResize
               />
            </div>
         </Dialog>
      </div>
   );
}


import React, { useContext, useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Calendar } from 'primereact/calendar';
import SelectEspecialidad from "../Select/Select";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import VoiceButton from "../../../../../Components/ButtonVoice/VoiceButton";
import InputCalendar from '../../../../../Components/form/InputCalendar';
import { dialogContext } from "../../context/contextdialog";
import { MGContext, MGProvider } from "../../../MGProvider";
import { RAContext } from "../../../../RutaAtencion/RAContext";
import Calendar02 from '../../../../../Components/form/Calendar02';

export default function DialogAgregarr({ setVisible, Visible, titulo, InterData, ConfirmarEditar, Index }) {
    let { interconsultaContext, setInterconsultaContext } = useContext(MGContext)
    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => { setVisible(false) }} className="p-button-text" />
            <Button label="Confirmar" onClick={() => SendData()} autoFocus />
        </div>
    );
    const [editar, seteditar] = useState()

    useEffect(() => {
        if (ConfirmarEditar) {
            seteditar("Editar")
        }
        else {
            seteditar("Crear")
        }
    }, [])


    const ActualizarContex = (valor, propiedad) => {
        setInterconsultaContext(prevState => (
            prevState.map((item, index) => {
                if (index == Index) {
                    return { ...item, [propiedad]: valor };
                } else {
                    return item;
                }
            })
        ));


    }

    const SendData = () => {
        if (ConfirmarEditar) {
            ActualizarContex(fechaRemoto, "fecha");
            ActualizarContex(observacionRemoto, "observaciones");
            ActualizarContex(diagnosticoRemoto, "diagnostico");
            ActualizarContex(motivoRemoto, "motivo");
            ActualizarContex(fechaProxiRemoto, "fecha_proxima");
            ActualizarContex(especialidadRemoto, "med_especialidad_id");
        } else {
            const nuevoId = interconsultaContext.length > 0 ? interconsultaContext[interconsultaContext.length - 1].id + 1 : 1;
            setInterconsultaContext(prevState => (
                [
                    ...prevState,
                    {
                        fecha: fechaRemoto,
                        observaciones: observacionRemoto,
                        diagnostico: diagnosticoRemoto,
                        motivo: motivoRemoto,
                        fecha_proxima: fechaProxiRemoto,
                        med_especialidad_id: especialidadRemoto,
                        codigo: "123",
                        id: nuevoId,
                        med_evaluacion_medica_id: 2
                    }
                ]
            ));
        }
        setVisible(false);
    };
    

    const [observacionRemoto, setobservacionRemoto] = useState(InterData?.observaciones)
    useEffect(() => {
        if (InterData?.observaciones) {
            setobservacionRemoto(InterData?.observaciones)
        }
        else {
            setobservacionRemoto("")
        }
    }, [InterData])

    const [diagnosticoRemoto, setdiagnosticoRemoto] = useState(InterData?.diagnostico)
    useEffect(() => {
        if (InterData?.diagnostico) {
            setdiagnosticoRemoto(InterData?.diagnostico)
        }
        else {
            setdiagnosticoRemoto("")
        }
    }, [InterData])

    const [motivoRemoto, setmotivoRemoto] = useState(InterData?.motivo)
    useEffect(() => {
        if (InterData?.motivo) {
            setmotivoRemoto(InterData?.motivo)
        }
        else {
            setmotivoRemoto("")
        }
    }, [InterData])

    const [fechaRemoto, setfechaRemoto] = useState(InterData?.fecha)
    useEffect(() => {
        if (InterData?.fecha) {
            setfechaRemoto(InterData?.fecha)
        }
        else {
            setfechaRemoto("")
        }
    }, [InterData])

    const [fechaProxiRemoto, setfechaProxiRemoto] = useState(InterData?.fecha_proxima)
    useEffect(() => {
        if (InterData?.fecha_proxima) {
            setfechaProxiRemoto(InterData?.fecha_proxima)
        }
        else {
            setfechaProxiRemoto("")
        }
    }, [InterData])

    const [especialidadRemoto, setespecialidadRemoto] = useState(InterData?.med_especialidad_id)
    useEffect(() => {
        if (InterData?.med_especialidad_id) {
            setespecialidadRemoto(InterData?.med_especialidad_id)
        }
        else {
            setespecialidadRemoto(0)
        }
    }, [InterData])


    return (
        <div className="card flex justify-content-center">
            <Dialog visible={Visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent}>
                <div className="flex flex-column gap-2">
                    <div className="flex flex-row gap-2">
                        <i className="pi pi-plus" style={{ background: '#6366F1', color: 'white', padding: '5px', borderRadius: '7px' }} />
                        <span style={{ fontSize: '20px', color: '#4545BC', fontWeight: '700' }}>{titulo}</span>
                    </div>
                    {/* Especialidad y fecha */}
                    <div className="flex flex-row" style={{ justifyContent: 'space-between', alignItems: 'end' }}>
                        <div>
                            <span>Especialidad</span>
                            <SelectEspecialidad EspInter={especialidadRemoto} setEspInter={setespecialidadRemoto} InterData={InterData} />
                        </div>
                        <div>
                            <Calendar02
                                val={fechaRemoto}
                                update={setfechaRemoto} showIcon />
                        </div>
                    </div>
                    <Divider />
                    <div>
                        <span>Diagnóstico:</span>
                        <div>
                            <VoiceButton
                                value={diagnosticoRemoto}
                                onChange={setdiagnosticoRemoto}
                                placeholder="Comentario" />
                        </div>
                    </div>
                    <div>
                        <span>Motivo:</span>
                        <div>
                            <VoiceButton
                                value={motivoRemoto}
                                onChange={setmotivoRemoto}
                                placeholder="Comentario" />
                        </div>
                    </div>
                    <div className="flex flex-row gap-3" style={{ alignItems: 'end' }}>
                        <div className="flex flex-column">
                            <span>Próximo control</span>
                            <Calendar02
                                val={fechaProxiRemoto}
                                update={setfechaProxiRemoto}
                            />
                        </div>
                        <div className="flex flex-column w-full">
                            <InputText placeholder="Comentario" value={observacionRemoto} onChange={(e) => setobservacionRemoto(e.target.value)} />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

import React, { useContext } from 'react'
import CuestionarioTable from '../Pure/CuestionarioTable'
import { EspiContext } from '../../../../Page/Espirometria/EspiProvider'

export default function Pregunta3() {
   const { preguntasContext, setPreguntasContext } = useContext(EspiContext)

   const setDatos = (data) => {
      setPreguntasContext({
         ...preguntasContext,
         esp_preguntas_3: data
      });
   }

   return (
      <div className='card'>
         <CuestionarioTable
            header={"Pregunta para todos los entrevistados que no tienen criterios de exclusión y por lo tanto deben ser atendidos"}
            datos={preguntasContext.esp_preguntas_3}
            setDatos={setDatos} />
      </div>
   )
}
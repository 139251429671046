import React from "react";
import AreasLocal from '../../Components/RecursosHumanos/Local/LocalAreas/AreasLocal';
import { useParams } from "react-router-dom";

const PageAreasLocales = () => {
   const { id } = useParams()
   return (
      <div>
         <AreasLocal></AreasLocal>
      </div>
   );

}
export default PageAreasLocales;
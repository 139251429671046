import FileDropzone from '../../../FileDropZone/FileDropZone';
import ContactList from './ContactList';
import React, { useContext } from 'react';
import { SendVentasContext } from '../../../SendVentasContext';

export default function NCPCredenciales({ contactos, setContactos,Estado }) {
    
    const { sendVentas, setSendVentas } = useContext(SendVentasContext);
    
    /* IMÁGENES */
    function handleProformaFile(f) {
        setSendVentas({...sendVentas, GCCredenciales: {...sendVentas.GCCredenciales, proformaFile: f.name}})
    }
    function handleActitudFile(f) {
        setSendVentas({...sendVentas, GCCredenciales: {...sendVentas.GCCredenciales, actitudFile: f.name}})
    }
    function handleAdiconalFile(f) {
        setSendVentas({...sendVentas, GCCredenciales: {...sendVentas.GCCredenciales, adicionalFile: f.name}})
    }
    /* CONTACTO */
    function handleContact(c) {
    }
    function HandleEstado(e) {
        Estado(e);
    }

    return (
        <div className="flex flex-column w-full">
            <div className="flex-1 flex flex-wrap justify-content-center gap-3 mr-6 ml-6">
                <div className="flex-1 bg-white border-round-lg flex flex-column gap-2 p-6" style={{minWidth: "357.984px"}}>
                    <div className="flex">
                        <img
                            width="40px"
                            height="40px"
                            className="bg-indigo-100 border-round-lg p-2"
                            src="https://i.ibb.co/gjvBxZS/Icon.png"
                            alt=""
                        />
                    </div>

                    <p className="text-indigo-500 font-bold text-base">
                        Envio de credenciales
                    </p>

                    <p>Proforma</p>
                    <FileDropzone onFileDrop={handleProformaFile} label="Arrastre y coloque su archivo aquí" gap="2" />

                    <p>Valores de Aptitud</p>
                    <FileDropzone onFileDrop={handleActitudFile} label="Arrastre y coloque su archivo aquí" gap="2" />

                    <p>Documentos adicionales</p>
                    <FileDropzone onFileDrop={handleAdiconalFile} label="Arrastre y coloque su archivo aquí" gap="2" />

                    <p>Otros</p>
                    <FileDropzone onFileDrop={handleAdiconalFile} label="Arrastre y coloque su archivo aquí" gap="2" />
                </div>

                <div className="flex-1 bg-white border-round-lg flex flex-column gap-2 p-6">
                    <ContactList contactosSeleccionados={handleContact} contactos={contactos} setContactos={setContactos} estado={HandleEstado} />
                </div>
            </div>
        </div>
    );
}
import React from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { Footer, Header } from "../../../../Pure/SeccionesNavegacion";
import Icon from "../../../../../Images/Icon.png";

export default function ModalEditarArea({ visibleEdAr, setvisibleEdAr, rowData, BotonEditar, handleChangeNombre }) {

  const header = (<Header icono={Icon} titulo={'Editar área'} subtitulo={'En esta sección usted puede editar las areas'}/>)

  const footer = (<Footer onClickCancelar={() => setvisibleEdAr(false)} onClickEnviar={BotonEditar} label={'Actualizar'}/>)

  return (
    <Dialog
      header={header}
      footer={footer}
      style={{ width: "413px"}}
      visible={visibleEdAr}
      onHide={() => setvisibleEdAr(false)}
    >
      <div className="flex flex-column gap-5 ">
        <div className=" flex flex-column gap-2">
          <div className="">
            <p className="text-700">Área</p>
          </div>
          <div className="">
            {/* Actualiza el nombre del área */}
            <InputText
              className="w-full "
              onChange={(e) => handleChangeNombre(e)}
              defaultValue={rowData?.data?.nombre}
            />
          </div>
        </div>
      </div>
    </Dialog>
  )
}
import React, { useState, useEffect, useContext } from "react";
import "../../../Style/ExcelTable.css";
import "../../../Style/MedicinaGeneral/ExplPalpacion.css"
import CardExamenFisico3 from "../CardExamenFisico3";
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";
import CardMedicinaGeneral from "../CardMedicinaGeneral";

export default function Articulacion() {
    const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);

    const handleInputChange = (value) => {
        const patologias = [
            'Cervical - Flexión',
            'Cervical - Extensión',
            'Cervical - Laterización Derecho',
            'Cervical - Laterización Izquierdo',
            'Cervical - Rotación Derecho',
            'Cervical - Rotación Izquierda',
            'Cervical - Irradiación',
            'Lumbar - Flexión',
            'Lumbar - Extensión',
            'Lumbar - Laterización Derecho',
            'Lumbar - Laterización Izquierdo',
            'Lumbar - Rotación Derecho',
            'Lumbar - Rotación Izquierda',
            'Lumbar - Irradiación',

        ];


        const resultados = [select01, select02, select03, select04, select05, select06, select07, select08, select09, select10, select11, select12, select13, select14];
        const observaciones = [input01, input02, input03, input04, input05, input06, input07, input08, input09, input10, input11, input12, input13, input14];

        const nuevaData = patologias.reduce((acc, patologia, index) => {
            acc[`r${index + 1}`] = {
                patologia_id: patologia,
                resultado: resultados[index],
                observacion: observaciones[index]
            };
            return acc;
        }, {});

        setExploracionDataContext((prevData) => ({
            ...prevData,
            med_movilidad_patologia: {
                ...prevData.med_movilidad_patologia,
                ...nuevaData
            },
        }));
    };

    const [select01, setSelect01] = useState(null);
    const [select02, setSelect02] = useState(null);
    const [select03, setSelect03] = useState(null);
    const [select04, setSelect04] = useState(null);
    const [select05, setSelect05] = useState(null);
    const [select06, setSelect06] = useState(null);
    const [select07, setSelect07] = useState(null);
    const [select08, setSelect08] = useState(null);
    const [select09, setSelect09] = useState(null);
    const [select10, setSelect10] = useState(null);
    const [select11, setSelect11] = useState(null);
    const [select12, setSelect12] = useState(null);
    const [select13, setSelect13] = useState(null);
    const [select14, setSelect14] = useState(null);

    const [input01, setInput01] = useState('');
    const [input02, setInput02] = useState('');
    const [input03, setInput03] = useState('');
    const [input04, setInput04] = useState('');
    const [input05, setInput05] = useState('');
    const [input06, setInput06] = useState('');
    const [input07, setInput07] = useState('');
    const [input08, setInput08] = useState('');
    const [input09, setInput09] = useState('');
    const [input10, setInput10] = useState('');
    const [input11, setInput11] = useState('');
    const [input12, setInput12] = useState('');
    const [input13, setInput13] = useState('');
    const [input14, setInput14] = useState('');
    const opciones = [
        { name: 'Normal', code: '0' },
        { name: 'Anormal', code: '1' }
    ];
    const handleSend = (data, campo) => {
        setExploracionDataContext({ ...exploracionDataContext, med_movilidad_patologia: { ...exploracionDataContext.med_movilidad_patologia, [campo]: { ...exploracionDataContext.med_movilidad_patologia[campo], resultado: data } } })

    }
    const handleSendI = (data, campo) => {
        setExploracionDataContext({ ...exploracionDataContext, med_movilidad_patologia: { ...exploracionDataContext.med_movilidad_patologia, [campo]: { ...exploracionDataContext.med_movilidad_patologia[campo], observacion: data } } })
    }


    const patologiasData = [
        { patologia: "Cervical - Flexión", state: [exploracionDataContext?.med_movilidad_patologia?.r1?.resultado, exploracionDataContext?.med_movilidad_patologia?.r1?.observacion], setState: [handleSend, handleSendI], campo: "r1" },
        { patologia: "Cervical - Extensión", state: [exploracionDataContext?.med_movilidad_patologia?.r2?.resultado, exploracionDataContext?.med_movilidad_patologia?.r2?.observacion], setState: [handleSend, handleSendI], campo: "r2" },
        { patologia: "Cervical - Laterización Derecho", state: [exploracionDataContext?.med_movilidad_patologia?.r3?.resultado, exploracionDataContext?.med_movilidad_patologia?.r3?.observacion], setState: [handleSend, handleSendI], campo: "r3" },
        { patologia: "Cervical - Laterización Izquierdo", state: [exploracionDataContext?.med_movilidad_patologia?.r4?.resultado, exploracionDataContext?.med_movilidad_patologia?.r4?.observacion], setState: [handleSend, handleSendI], campo: "r4" },
        { patologia: "Cervical - Rotación Derecho", state: [exploracionDataContext?.med_movilidad_patologia?.r5?.resultado, exploracionDataContext?.med_movilidad_patologia?.r5?.observacion], setState: [handleSend, handleSendI], campo: "r5" },
        { patologia: "Cervical - Rotación Izquierda", state: [exploracionDataContext?.med_movilidad_patologia?.r6?.resultado, exploracionDataContext?.med_movilidad_patologia?.r6?.observacion], setState: [handleSend, handleSendI], campo: "r6" },
        { patologia: "Cervical - Irradiación", state: [exploracionDataContext?.med_movilidad_patologia?.r7?.resultado, exploracionDataContext?.med_movilidad_patologia?.r7?.observacion], setState: [handleSend, handleSendI], campo: "r7" },
        { patologia: "Lumbar - Flexión", state: [exploracionDataContext?.med_movilidad_patologia?.r8?.resultado, exploracionDataContext?.med_movilidad_patologia?.r8?.observacion], setState: [handleSend, handleSendI], campo: "r8" },
        { patologia: "Lumbar - Extensión", state: [exploracionDataContext?.med_movilidad_patologia?.r9?.resultado, exploracionDataContext?.med_movilidad_patologia?.r9?.observacion], setState: [handleSend, handleSendI], campo: "r9" },
        { patologia: "Lumbar - Laterización Derecho", state: [exploracionDataContext?.med_movilidad_patologia?.r10?.resultado, exploracionDataContext?.med_movilidad_patologia?.r10?.observacion], setState: [handleSend, handleSendI], campo: "r10" },
        { patologia: "Lumbar - Laterización Izquierdo", state: [exploracionDataContext?.med_movilidad_patologia?.r11?.resultado, exploracionDataContext?.med_movilidad_patologia?.r11?.observacion], setState: [handleSend, handleSendI], campo: "r11" },
        { patologia: "Lumbar - Rotación Derecho", state: [exploracionDataContext?.med_movilidad_patologia?.r12?.resultado, exploracionDataContext?.med_movilidad_patologia?.r12?.observacion], setState: [handleSend, handleSendI], campo: "r12" },
        { patologia: "Lumbar - Rotación Izquierda", state: [exploracionDataContext?.med_movilidad_patologia?.r13?.resultado, exploracionDataContext?.med_movilidad_patologia?.r13?.observacion], setState: [handleSend, handleSendI], campo: "r13" },
        { patologia: "Lumbar - Irradiación", state: [exploracionDataContext?.med_movilidad_patologia?.r14?.resultado, exploracionDataContext?.med_movilidad_patologia?.r14?.observacion,], setState: [handleSend, handleSendI], campo: "r14" }
    ];


    const components = patologiasData.map(({ patologia, state, setState, campo }, index) => (
        <CardExamenFisico3
            key={index}
            patologia={patologia}
            setSelect01={(e) => {
                setState[0](e.value, campo);
                // handleInputChange(e.value, 'select');
            }}
            select01={state[0]}
            valor={state[1]}
            funcion={(e) => {
                setState[1](e, campo);
                // handleInputChange(e, 'VoiceInputTextarea');
            }}
        />
    ));


    // const cardComponents = Object.keys(exploracionDataContext.med_movilidad_patologia).map((key, index) => {
    //     const registro = exploracionDataContext.med_movilidad_patologia[key];
    //     const numeroPatologia = index + 1;
    //     return (
    //         <CardMedicinaGeneral
    //             key={key}
    //             patologia={`${numeroPatologia}.- ${registro.patologia_id}`}
    //             campo={["med_movilidad_patologia", key]}
    //             context={exploracionDataContext}
    //             setContext={setExploracionDataContext}
    //         />
    //     );
    // });
    const updateContextBase = (nuevoValor, propiedad, ind) => {
        setExploracionDataContext((prev) => ({
            ...prev,
            movilidad_patologia: prev.movilidad_patologia.map((item, index) => {
                if (index === ind) {
                    return {
                        ...item,
                        [propiedad]: nuevoValor
                    };
                }
                return item;
            })
        }));
    };

    const [Patologias, setPatologias] = useState([
        { id: 49, Nom_Patologia: "Cervical" },
        { id: 50, Nom_Patologia: "Dorsal" },
        { id: 51, Nom_Patologia: "Lumbar" },

        { id: 54, Nom_Patologia: "Cervical - Flexión" },
        { id: 55, Nom_Patologia: "Cervical - Extensión" },
        { id: 56, Nom_Patologia: "Cervical - Laterización Derecho" },
        { id: 57, Nom_Patologia: "Cervical - Laterización Izquierdo" },
        { id: 58, Nom_Patologia: "Cervical - Rotación Derecho" },
        { id: 59, Nom_Patologia: "Cervical - Rotación Izquierda" },
        { id: 60, Nom_Patologia: "Cervical - Irradiación" },
        { id: 61, Nom_Patologia: "Lumbar - Flexión" },
        { id: 62, Nom_Patologia: "Lumbar - Extensión" },
        { id: 63, Nom_Patologia: "Lumbar - Laterización Derecho" },
        { id: 64, Nom_Patologia: "Lumbar - Laterización Izquierdo" },
        { id: 65, Nom_Patologia: "Lumbar - Rotación Derecho" },
        { id: 66, Nom_Patologia: "Lumbar - Rotación Izquierda" },
        { id: 67, Nom_Patologia: "Lumbar - Irradiación" }
     ])
     const OpcionMovilidadDolor = [
        { name: 'Normal', code: 'N' },
        { name: 'Leve', code: 'L' },
        { name: 'Moderado', code: 'M' },
        { name: 'Severo', code: 'S' }
     ];
     
    return (
        //Contenedor
        <div className="cardPalpacionContenido">
            <table className="cardPalpacionTabla pl-4 pr-4 pb-4" >
                <thead>
                    <tr>
                        <th className="cardPalpacionTablaTitulo" style={{ width: "350px", textAlign: 'center' }}> Patología</th>
                        <th className="cardPalpacionTablaTitulo" style={{ textAlign: 'center' }}>Resultado</th>
                        <th className="cardPalpacionTablaTitulo" style={{ textAlign: 'center' }}>Observación</th>
                    </tr>
                </thead>
                <tbody className="pb-2">
                    {exploracionDataContext.movilidad_patologia.map((item, index) => {
                        let namePatologia = "";
                        Patologias.forEach((key) => {
                            if (key.id === item.patologia_id) {
                                namePatologia = key.Nom_Patologia;
                            }
                        });

                        return (
                            <CardMedicinaGeneral
                                key={index}
                                context={exploracionDataContext}
                                tituloSeccion={`${index + 1}.- ${namePatologia}`}
                                PadreContext={exploracionDataContext?.movilidad_patologia[index]}
                                updateContext={updateContextBase}
                                index={index}
                                opciones={OpcionMovilidadDolor}
                            />
                        );
                    })}
                    {/* Otro contenido de tu página */}
                    {/* {components} */}

                </tbody>
            </table>
        </div >
    );
}





import React, {useContext,useState,useEffect} from 'react';
import Table from "../../../Components/Table/Table";
import {Column} from "primereact/column";
import { PsicologiaContext } from '../PsicologiaContext';
import { RAContext } from "../../RutaAtencion/RAContext";

const PagePsiPruebas = () => {
  let {pruPsiContext, setPruPsiContext} = useContext(PsicologiaContext)
  let { pageViewContext, stateAtencion  } = useContext(RAContext)
  
  //Lista para mostrar en la tabla
  const listPruebas = [
    { prueba: 'Test rápido de inteligencia', id: 1 },
    { prueba: 'Test de inteligencia Barsit', id: 2 },
    { prueba: 'Test de Audit', id: 3 },
    { prueba: 'Test de la figura humana de Machover', id: 4 },
    { prueba: 'Sub test de laberintos del Wais', id: 5 },
    { prueba: 'Test de  Bender', id: 6 },
    { prueba: 'Inventario de Burnout Maslach', id: 7 },
    { prueba: 'Sub test del Cubo del Wais', id: 8 },
    { prueba: 'Test de Persona bajo la lluvia', id: 9 },
    { prueba: 'Persona con arma', id: 10 },
    { prueba: 'PMA (Verbal)', id: 11 },
    { prueba: 'Escala de Somnolencia de Epworth', id: 12 },
    { prueba: 'Prueba de conductor BC', id: 13 },
    { prueba: 'Cuestionario Riesgos psicosociales laborales', id: 14 },
    { prueba: 'Test de Fobias', id: 15 },
    { prueba: 'Test de Acrofobias de Cohen', id: 16 },
    { prueba: 'Cuestionario de síntomas Subjetivos de fatiga de H. Yoshitake', id: 17 },
    { prueba: 'Test de Raven para adultos', id: 18 },
    { prueba: 'Test de Wonderlic', id: 19 },
    { prueba: 'Test de Liderazgo', id: 20 },
    { prueba: 'Inventario IVAPT', id: 21 },
    { prueba: 'Inventario Clínico Multiaxial de Millon', id: 22 },
    { prueba: 'Inventario de Cociente Emocional de Baron', id: 23 },
    { prueba: 'Escala de agorafobia de Bandelow', id: 24 },
    { prueba: 'Cuestionario de Factores Psicosociales versión media', id: 25 },
    { prueba: 'Cuestionario de personalidad Eysenck', id: 26 },
    { prueba: 'Test de Inteligencia General 2', id: 27 },
    { prueba: 'Cuestionario de Claustrofobia (espacios confinados)', id: 28 },
    { prueba: 'Test de estrés laboral', id: 29 },
    { prueba: 'Test de Ansiedad -Zung', id: 30 },
    { prueba: 'Test de Depresión -Zung', id: 31 },
    { prueba: 'Test de Minimental', id: 32 },
    { prueba: 'Test de Salamanca', id: 33 },
    { prueba: 'Otros', id: 34 },
  ]

  //Variables de estado
  const [selectedPruebas, setSelectedPruebas] = useState(
    listPruebas.filter(prueba => pruPsiContext?.pruebas.some(item => item.psi_prueba_id === prueba.id))
  );

  //useEffect para que agrege las pruebas seleccionadas a una lista y al contexto
  useEffect(() => {
    let lista= selectedPruebas?.map((pruebas)=>{
      return {psi_prueba_id:pruebas.id}
    })
    
    setPruPsiContext({
      [`psicologia_id`]: null,
      [`pruebas`]:lista,
    })
  }, [selectedPruebas])

  
  return (
    <>

      <Table
        title="Pruebas"
        headerEnabled={false}
        createButtonEnabled={false}
        data={listPruebas}
        selection={selectedPruebas}
        onSelectionChange={(e) => stateAtencion[pageViewContext].estado !== 3 && setSelectedPruebas(e.value)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
        <Column field="prueba" header="Psicología" bodyStyle={{paddingLeft:'26px'}}/>
      </Table>

    </>
  );
};

export default PagePsiPruebas;
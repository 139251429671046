import React, { createContext, useState } from "react";

export const OftContext = createContext(undefined);

export const OftProvider = (props) => {

   const [visualCheckboxStates, setVisualCheckboxStates] = useState({
      hta: false,
      dislipidemias: false,
      diabetes: false,
      tiroides: false,
      otros: false,
   });

   const [anteContext, setAnteContext] = useState({
      ante_cirugia: {
         tipo_patologia_id: null,
         afecta_a: null,
         select: false,
         comentario: "",
      },
      ante_daltonismo: {
         tipo_patologia_id: null,
         afecta_a: null,
         select: false,
         comentario: "",
      },
      ante_discromatopsia: {
         verde: false,
         rojo: false,
         amarillo: false,
         select: false,
         comentario: "",
      },
      oft_oculares_otros: {
         select: false,
         comentarios: "",
      },
      oft_antecedentes_oculares: {
         cirugias: "",
      },
      oft_ocular_patologia: [
         { patologia_id: 23, nombre: "Glaucoma", select: false, afecta_a: null },
         { patologia_id: 24, nombre: "Cataratas", select: false, afecta_a: null },
         { patologia_id: 25, nombre: "Miopia", select: false, afecta_a: null },
         { patologia_id: 26, nombre: "Ojo Rojo", select: false, afecta_a: null },
         { patologia_id: 27, nombre: "Nistagmos", select: false, afecta_a: null },
         { patologia_id: 28, nombre: "Ametropia", select: false, afecta_a: null },
         { patologia_id: 29, nombre: "Estrabismo", select: false, afecta_a: null },
         { patologia_id: 30, nombre: "Ptosis", select: false, afecta_a: null },
         { patologia_id: 31, nombre: "Pterigion", select: false, afecta_a: null },
         { patologia_id: 32, nombre: "Hipermetropia", select: false, afecta_a: null },
         { patologia_id: 33, nombre: "Conjuntivitis", select: false, afecta_a: null },
         { patologia_id: 34, nombre: "Dercatocalasia", select: false, afecta_a: null },
         { patologia_id: 35, nombre: "Retinop. Diabetica", select: false, afecta_a: null },
         { patologia_id: 36, nombre: "Astigmatismo", select: false, afecta_a: null },
         { patologia_id: 37, nombre: "Presbicia", select: false, afecta_a: null },
         { patologia_id: 38, nombre: "Blefaritis", select: false, afecta_a: null },
         { patologia_id: 39, nombre: "Ambliopia", select: false, afecta_a: null },
         { patologia_id: 40, nombre: "Chalazion", select: false, afecta_a: null },
      ],
      oft_sistematico_otro: {
         comentarios: "",
         select: false,
      },
      oft_antecedente_sistematico: {
         conductor: null,
         correctores: null,
         tipo: null,
      },
      oft_sistematico_patologia: [
         {
            patologia_id: 16,
            select: false,
            nombre: "HTA",
         },
         {
            patologia_id: 12,
            select: false,
            nombre: "Dislipidemia",
         },
         {
            patologia_id: 11,
            select: false,
            nombre: "Diabetes M.",
         },
         {
            patologia_id: 14,
            select: false,
            nombre: "Enf. Tiroides",
         },
      ]
   });
   const [agudVisualContext, setAgudVisualContext] = useState({
      corregido_si: {
         vision_cerca: {
            ojo_derecho: null,
            ojo_izquierdo: null,
            binocular: null,
         },
         vision_lejos: {
            ojo_derecho: null,
            ojo_izquierdo: null,
            binocular: null,
         },
      },
      corregido_no: {
         vision_cerca: {
            ojo_derecho: null,
            ojo_izquierdo: null,
            binocular: null,
         },
         vision_lejos: {
            ojo_derecho: null,
            ojo_izquierdo: null,
            binocular: null,
         },
      },
      a_e: {
         ojo_derecho: null,
         ojo_izquierdo: null,
      },
      tonometria: {
         ojo_derecho: null,
         ojo_izquierdo: null,
      },
      examen_externo: {
         ojo_derecho: null,
         ojo_izquierdo: null,
         examen_clinico: '',
      },
      enfermedad_ocular: null,
      vision_colores: null,
      reflejos_pupilares: null,
      presion_intraocular: null,
   });
   const [refraccion, setRefraccion] = useState({
      ojo_derecho_refraccion_lejos: {
         shp: null,
         cyl: null,
         ej: null,
         seleccionar: null,
      },
      ojo_izquierdo_refraccion_lejos: {
         shp: null,
         cyl: null,
         ej: null,
         seleccionar: null,
      },
      dip_lejos: null,
      ojo_derecho_refraccion_cerca: {
         shp: null,
         cyl: null,
         ej: null,
         seleccionar: null,
      },
      ojo_izquierdo_refraccion_cerca: {
         shp: null,
         cyl: null,
         ej: null,
         seleccionar: null,
      },
      dip_cerca: null,
   });
   const [testColorContext, setTestColorContext] = useState({
      ojo_derecho: null,
      ojo_izquierdo: null,
      reconoce_colores: null,
      dificultad_color: null,
   });
   const [testProfundidad, setTestProfundidad] = useState({
      stereo_fly_test: null,
      circulos: null,
      porcentaje: null,
      animales: null,
   });
   const [campimetriaContext, setCampimetriaContext] = useState({
      ojo_derecho: null,
      ojo_izquierdo: null,
   });
   const [mediContext, setMediContext] = useState({
      precion_ojo_derecho: "",
      precion_ojo_izquierdo: "",
      fondo_ojo_derecho: "",
      fondo_ojo_izquierdo: "",
   });
   const [testContext, setTestContext] = useState({
      movimientos_oculares: null,
      ojo_izquierdo: {
         parpados: null,
         conjuntiva: null,
         cornea: null,
         camara_anterior: null,
         iris: null,
         cristalino: null,
         refle_pupilares: null,
      },
      ojo_derecho: {
         parpados: null,
         conjuntiva: null,
         cornea: null,
         camara_anterior: null,
         iris: null,
         cristalino: null,
         refle_pupilares: null,
      },
   });

   const [diagContext, setDiagContext] = useState({
      diagnostico: "",
      plan: "",
      conclusion: "",
   });

   const [listPatologias, setListPatologias] = useState([]);

   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState("");

   return (
      <OftContext.Provider
         value={{
            anteContext, setAnteContext,
            agudVisualContext, setAgudVisualContext,
            refraccion, setRefraccion,
            testColorContext, setTestColorContext,
            testProfundidad, setTestProfundidad,
            campimetriaContext, setCampimetriaContext,
            testContext, setTestContext,
            mediContext, setMediContext,
            diagContext, setDiagContext,
            atencionStatus, setAtencionStatus,
            listPatologias,
            timeStart,
            setTimeStart,
            visualCheckboxStates,
            setVisualCheckboxStates,
         }}
      >
         {props?.children}
      </OftContext.Provider>
   );
};
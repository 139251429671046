import React, { useContext, useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import VoiceButton from "../../../../Components/ButtonVoice/VoiceButton";
import { Dropdown } from 'primereact/dropdown';
import "../../../../Style/Ventas/VentasS1Customer.css";
import { MGContext } from "../../MGProvider";

export default function DialogEditar({ DiagMedDataAll, setVisibleEditar, VisibleEditar, DiagMedID, DataDrop }) {
    let { diagMedicoContext, setDiagMedicoContext, setEvaluacionMedicaContext } = useContext(MGContext)
    const [observaciones, setObservaciones] = useState(DiagMedDataAll?.observacion);

    const TiposDx = [
        { name: 'Presuntivo', code: 0 },
        { name: 'Definitivo', code: 1 },
        { name: 'Repetitivo', code: 2 }
    ];
    const DxOc = [
        { name: 'Si', code: 0 },
        { name: 'No', code: 1 },
    ];
    const Imp = [
        { name: 'Si', code: 0 },
        { name: 'No', code: 1 },
    ];

    useEffect(() => {
        if (DiagMedDataAll && DiagMedDataAll.observacion) {
            setObservaciones(DiagMedDataAll.observacion);
        }
    }, [DiagMedDataAll]);

    const [recomendaciones, setRecomendaciones] = useState(DiagMedDataAll?.recomendacion)
    useEffect(() => {
        if (DiagMedDataAll && DiagMedDataAll.recomendacion) {
            setRecomendaciones(DiagMedDataAll.recomendacion);
        }
    }, [DiagMedDataAll]);

    const [DiagCIE, setDiagCIE] = useState()
    useEffect(() => {
        if (DiagMedDataAll && DiagMedDataAll.enfermedad_especifica_id) {
            DataDrop.map((item) => {
                if (item.id == DiagMedDataAll.enfermedad_especifica_id) {
                    setDiagCIE(item)
                }
            })
        }
    }, [DiagMedDataAll]);

    const [TipoDiagnostico, setTipoDiagnostico] = useState()
    useEffect(() => {
        if (DiagMedDataAll && DiagMedDataAll.tipo_diagnostico) {
            TiposDx.map((item) => {
                if (item.code == DiagMedDataAll.tipo_diagnostico) {
                    setTipoDiagnostico(item)
                }
            })
        }
    }, [DiagMedDataAll]);

    const [selectedDxOc, setSelectedDxOc] = useState()
    useEffect(() => {
        if (DiagMedDataAll && DiagMedDataAll.ocupacional) {
            DxOc.map((item) => {
                if (item.code == DiagMedDataAll.ocupacional) {
                    setSelectedDxOc(item)
                }
            })
        }
    }, [DiagMedDataAll]);

    const [selectedImp, setSelectedImp] = useState()
    useEffect(() => {
        if (DiagMedDataAll && DiagMedDataAll.importante) {
            Imp.map((item) => {
                if (item.code == DiagMedDataAll.importante) {
                    setSelectedImp(item)
                }
            })
        }
    }, [DiagMedDataAll]);

    const Actualizarcontext = (value, propiedad, indice) => {
        setEvaluacionMedicaContext(prevState => {
            const newState = { ...prevState };

            newState.med_dx_conclusion.med_lista_general_diagnostico = prevState.med_dx_conclusion.med_lista_general_diagnostico.map((item, index) => {
                if (index === indice) {
                    return {
                        ...item,
                        [propiedad]: value
                    };
                }
                return item;
            });
            return newState;
        });
    }

    const Actualizar = (index) => {
        Actualizarcontext(DiagCIE.codigo, "cie", index);
        Actualizarcontext(TipoDiagnostico.code, "tipo_diagnostico", index);
        Actualizarcontext(selectedDxOc.code, "ocupacional", index);
        Actualizarcontext(selectedImp.code, "importante", index);
        Actualizarcontext(observaciones, "observacion", index);
        Actualizarcontext(recomendaciones, "recomendacion", index);
        setVisibleEditar(false);
    }

    const LimpiarAll = () => {
        setDiagCIE(null);
        setTipoDiagnostico(null);
        setSelectedDxOc(null);
        setSelectedImp(null);
        setObservaciones("");
        setRecomendaciones("");
    }
    useEffect(() => {
        LimpiarAll();
    }, [])


    const footerContent = (
        <div>
            <Button label="Cancelar" icon="pi pi-times" onClick={() => { setVisibleEditar(false) }} className="p-button-text" />
            <Button label="Guardar" icon="pi pi-check" onClick={() => { Actualizar(DiagMedID) }} autoFocus />
        </div>
    );

    return (
        <div className="card flex justify-content-center">
            <Dialog visible={VisibleEditar} style={{ width: '70vw' }} onHide={() => setVisibleEditar(false)} footer={footerContent}>
                <div className="flex flex-column gap-2">
                    <div className="flex flex-row gap-2">
                        <i className="pi pi-pencil" style={{ background: '#6366F1', color: 'white', padding: '5px', borderRadius: '7px' }} />
                        <span style={{ fontSize: '20px', color: '#4545BC', fontWeight: '700' }}>Buscar o Modificar Diagnóstico</span>
                    </div>
                    <div className="flex flex-row gap-2" style={{ alignItems: 'end' }}>
                        <span style={{ width: '100px', marginTop: "30px" }}>Diagnóstico CIE</span>
                        <div className="flex flex-column">
                            <span style={{ marginLeft: '10px', marginBottom: "5px" }}>Código</span>
                            <Dropdown
                                value={DiagCIE}
                                onChange={(e) => setDiagCIE(e.value)}
                                options={DataDrop} optionLabel="codigo"
                                placeholder="Seleccione una opción"
                                filter
                                style={{ marginLeft: '10px', width: "100%" }} />
                        </div>
                        <div className="flex flex-column w-full">
                            <span style={{ textAlign: "left", marginLeft: '35px', marginBottom: "5px" }}>Denominación</span>
                            <div className="flex flex-row">
                                <Dropdown
                                    value={DiagCIE}
                                    onChange={(e) => setDiagCIE(e.value)}
                                    options={DataDrop} optionLabel="enfermedad_especifica"
                                    placeholder="Ingrese un denominación"
                                    filter
                                    style={{ marginLeft: '10px', width: "100%" }}
                                    className="p-inputtext-sm"
                                    panelClassName="customOptionStyle" />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap gap-2 w-full" style={{ justifyContent: 'space-between', marginTop: '10px' }}>
                        <div className="flex flex-row" style={{ alignItems: 'center' }}>
                            <span style={{ width: '80px' }}>Tipo de Diagnóstico</span>
                            <div className="">
                                <Dropdown value={TipoDiagnostico} onChange={(e) => setTipoDiagnostico(e.value)} options={TiposDx} optionLabel="name"
                                    placeholder="" className="" style={{ height: "47px", marginLeft: "22px" }} />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 " style={{ alignItems: 'center' }}>
                            <span>Diagnóstico ocupacional</span>
                            <div className="">
                                <Dropdown
                                    value={selectedDxOc}
                                    onChange={(e) => setSelectedDxOc(e.value)}
                                    options={DxOc}
                                    optionLabel="name"
                                    placeholder=""
                                    className="" />
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 " style={{ alignItems: 'center' }}>
                            <span>De importancia</span>
                            <div className="">
                                <Dropdown
                                    value={selectedImp}
                                    onChange={(e) => setSelectedImp(e.value)}
                                    options={Imp}
                                    optionLabel="name"
                                    placeholder=""
                                    className="" />
                            </div>
                        </div>
                    </div>
                    <Divider />
                    <div>
                        <span style={{}}>Observaciones: (Criterio médico)</span>
                        <div style={{ marginTop: "20px", marginBottom: "10px" }}>
                            <VoiceButton
                                value={observaciones}
                                onChange={setObservaciones}
                                placeholder="Comentario" />
                        </div>
                    </div>
                    <div>
                        <span>Recomendaciones:</span>
                        <div style={{ marginTop: "20px" }}>
                            <VoiceButton
                                value={recomendaciones}
                                onChange={setRecomendaciones}
                                placeholder="Comentario" />
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Column } from "primereact/column";
import { useDispatch } from "react-redux";

//Componentes generales
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";
import { ModalCreate, ModalEditar, ModalEliminar, ModalCredenciales } from '../../../../Components/RecursosHumanos/Personal/Components/index'
import { DialogAsignarServicio, AsignarServicioGrupal } from "../../../../Components/RecursosHumanos/components/index-asignar-servicio";
import CargarData from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarData";
import CargarDatosDNI from "../../../../Components/RecursosHumanos/Reclutamiento/Dialog/CargarDatosDNI";
import TablaCrud from "../../../../Components/Table/TablaCrud";

//Estilos generales
import "../../../../Style/CSS_tabla.css";
import "../../../../Style/Botones.css";

function PagePersonalEmpresa() {
   const dispatch = useDispatch();
   const toast = useRef(null);
   const { http } = AuthUser();
   const [visibleAsignarGrupal, setVisibleAsignarGrupal] = useState(false);
   const [visibleCredenciales, setVisibleCredenciales] = useState(false);
   const [visibleDialogDNI, setVisibleDialogDNI] = useState(false);
   const [visibleEliminar, setVisibleEliminar] = useState(false);
   const [visibleAsignar, setVisibleAsignar] = useState(false);
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [personaleliminar, setPersonaleliminar] = useState([]);
   const [selectedPersonal, setSelectedPersonal] = useState([]);
   const [tipoDocumentos, setTipoDocumentos] = useState([]);
   const [sexos, setSexos] = useState([]);
   const [contratos, setContratos] = useState([]);
   const [personal, setPersonal] = useState([]);
   const [roles, setRoles] = useState([]);
   const [data, setData] = useState([]);
   const [selectPersonal, setSelectPersonal] = useState({
      id: 0,
      tipo_documento: "",
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "2022-01-01",
      distrito_id: 2,
      celular: "",
      correo: "",
      usuario: "",
      rol: "",
   });

   //Función para notificar eventos
   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   //Integraciones get
   const getAllTipoDocumentos = async () => {
      try {
         const response = await http.get("tipodocumentos/get");
         if (response?.data?.data) {
            setTipoDocumentos(response.data.data);
         }
      } catch (error) {
         console.error('Error al obtener documentos', error)
         showToast('error', 'Error al obtener documentos', 'Contáctese con soporte técnico.')
      };
   };

   const getGenero = async () => {
      try {
         const response = await http.get("sexos/get");
         if (response?.data?.data) {
            setSexos(response.data.data);
         }
      } catch (error) {
         console.error('Error al obtener géneros', error)
         showToast('error', 'Error al obtener géneros', 'Contáctese con soporte técnico.')
      };
   };

   const getRoles = async () => {
      try {
         const response = await http.get("roles/get")
         if (response?.data?.data) {
            setRoles(response?.data?.data);
         }
      } catch (error) {
         console.error('Error al obtener roles', error)
         showToast('error', 'Error al obtener roles', "Contáctese con soporte técnico.")
      };
   };

   const getPersonal = async () => {
      try {
         const response = await http.get("empresa/personal/get")
         if (response?.data?.data) {
            setPersonal(response?.data?.data);
         } else if (response?.data?.error) {
            setPersonal(null);
         }
      } catch (error) {
         console.error('Error al obtener datos del personal', error)
         showToast("error", "Error al obtener datos del personal", "Contáctese con soporte técnico.")
      };
   };

   const getContract = async () => {
      try {
         const response = await http.get("tipocontrato/show")
         if (response?.data?.data) {
            setContratos(response?.data.data)
         }
      } catch (error) {
         console.error('Error al obtener contratos', error)
         showToast('error', 'Error al obtener contratos', "Contáctese con soporte técnico")
      };
   }

   const downloadTemplate = async () => {
      try {
         // Realiza una solicitud GET a la URL especificada y espera la respuesta
         const response = await http.get(`empresa/personal/download-template`, { responseType: 'blob' })
         // Verifica si la respuesta contiene datos
         if (response.data) {
            // Crea una URL para el blob de datos recibido
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Crea un elemento de enlace (link) en el documento
            const link = document.createElement('a');
            // Establece la URL del enlace al blob creado
            link.href = url;
            // Establece el atributo de descarga del enlace con el nombre del archivo
            link.setAttribute('download', 'template.xlsx'); // o el nombre que desees para el archivo
            // Añade el enlace al cuerpo del documento
            document.body.appendChild(link);
            // Simula un clic en el enlace para iniciar la descarga
            link.click();
            // Elimina el enlace del documento
            link.remove();
         }
      } catch (error) {
         // Muestra un mensaje de error en la consola si ocurre un error durante la descarga
         console.error("Error al descargar el archivo", error);
      };
   };

   const handleObtenerPuesto = async () => {
      try {
         const response = await http.get("empresa/cargo/get1")
         if (response?.data?.data) {
            setData(response.data.data);
         }
      } catch (error) {
         console.error('Error al obtener puestos de trabajo', error)
         showToast('error', 'Error', "Contáctese con soporte técnico")
      }
   };

   //Integraciones post
   const asignarPaquetes = (data) => {
      dispatch(startLoading());
      http
         .post("empresa/personal/asignar/perfil", data)
         .then((response) => {
            if (response.data.error) {
               showToast('error', 'Error', response.data.error);
            } else if (response?.data?.resp) {
               showToast("success", "Asignado correctamente", "Se asignó correctamente al personal");
            }
         })
         .catch((error) => {
            console.error('Error al asignar paquetes', error)
            showToast('error', 'Error', "Contáctese con soporte técnico");
         })
         .finally(() => {
            dispatch(endLoading());
         })
   };

   const asignarPaqueteGrupal = (data) => {
      dispatch(startLoading());
      http
         .post("empresa/personal/asignar/servicio", data)
         .then((response) => {
            if (response.data.error) {
               showToast('error', 'Error', response.data.error);
            } else if (response?.data?.resp) {
               showToast("success", "Envío grupal", "Grupo asignado correctamente")
            }
         })
         .catch((error) => {
            console.error('Error al asignar paquetes', error)
            showToast('error', 'Error', "Contáctese con soporte técnico");
         })
         .finally(() => {
            dispatch(endLoading());
         })
   }

   const subirArchivoDNI = async (data) => {
      //Muestra un mensaje de carga
      setIsLoading(true);

      //Instancia FormData
      const formData = new FormData();

      //Convierte el objeto file del elemento data a formData
      formData.append("file", data);
      dispatch(startLoading());
      try {
         const response = await http.post("/empresa/personal/import/dni", formData)
         if (response.data.error) {
            showToast('error', 'Error', response.data.error);
         } else if (response?.data?.resp) {
            await Promise.all([
               getPersonal(),
               setVisibleDialogDNI(false)
            ])
            setTipoDocumentos(response.data.data);

            //Culmina el mensaje de carga
            setIsLoading(false);
            showToast("success", "Éxito", "El personal fue asignado.")
         }
      } catch (error) {
         setIsLoading(false);
         console.error('Error al subir archivo', error)
         showToast("error", "Fallo al enviar personal", "Contáctese con soporte técnico")
      } finally {
         dispatch(endLoading());
      }
   };

   const subirArchivo = async (data) => {
      // Muestra un mensaje de carga
      setIsLoading(true);

      // Instancia FormData
      const formData = new FormData();

      // Convierte el objeto file del elemento data a formData
      formData.append("file", data);

      // Inicia la carga
      dispatch(startLoading());

      try {
         // Realiza la solicitud POST para subir el archivo
         const response = await http.post("/empresa/personal/import", formData)

         // Verifica si hay un error en la respuesta
         if (response.data.error) {
            showToast('error', 'Error', response.data.error);
         } else if (response?.data?.resp) {
            // Si la respuesta es exitosa, actualiza los datos del personal
            await Promise.all([getPersonal()])
            setVisibleDialog(false);
            setIsLoading(false);
            showToast("success", "Éxito", response?.data?.resp)
         }
      } catch (error) {
         // Maneja el error y muestra un mensaje de error
         setIsLoading(false);
         showToast("error", "Fallo al enviar personal", "Contáctese con soporte técnico")
         console.error(error)
      } finally {
         // Finaliza la carga
         dispatch(endLoading());
      }
   };

   //Carga los datos al renderizar el componente
   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTipoDocumentos(),
            getGenero(),
            getPersonal(),
            getRoles(),
            handleObtenerPuesto(),
            getContract()
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   //Credenciales
   const actionBodyCredenciales = (rowData) => {
      return (
         <Button
            icon="pi pi-id-card text-2xl"
            className="p-button-text"
            onClick={() => showCredencialesModal(rowData)}
         />
      )
   }

   //Envío de servicios
   const actionBodyTemplateAsignar = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-send"
               className="p-button-text"
               onClick={() => asignarServicio(rowData)}
               style={{ height: "32px" }}
            />
         </React.Fragment>
      );
   };

   //Acciones
   const actionBodyTemplate = (rowData) => {
      return (

         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => editPaquete(rowData)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => deletePaquete(rowData)}
            />

         </React.Fragment>
      );
   };

   const asignarServicio = (rowData) => {
      setSelectPersonal(rowData);//Selecciona la información de cada empleado
      setVisibleAsignar(true);//Abre el modal de referencia
   };

   const editPaquete = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleEdit(true);
   };

   const deletePaquete = (rowData) => {
      setPersonaleliminar(rowData);
      setVisibleEliminar(true);
   };

   const showCredencialesModal = (rowData) => {
      setSelectPersonal(rowData);
      setVisibleCredenciales(true);
   }

   const openDialog = () => {
      setVisibleDialog(true)
   };

   //Datos controlados
   const handleItem = (rowData, index) => {
      return <div>{index.rowIndex + 1}</div>
   }

   const handleContrato = (rowData) => {
      return rowData.tipo_contrato ? rowData.tipo_contrato.nombre : "-"
   }

   //Monta el componente
   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="flex flex-column gap-2">
         <Toast ref={toast} />
         <div className="flex flex-column">
            <h1 className="titulo w-full">Mi Personal</h1>
            <p className="subtitulo" style={{ marginBottom: "10px" }}>Adminístralo de la mejor forma</p>
         </div>

         <div className="content-table-rol flex w-12 justify-content-end table centrar">
            <TablaCrud
               selectionMode={null}
               selection={selectedPersonal}
               onSelectionChange={(e) => setSelectedPersonal(e.value)}
               dataKey="id"
               name={"personal"}
               value={personal}

               //Acción y etiquetas
               leftLabelButton1={"Crear"}
               onClickLeftButton1={() => { setVisibleCreate(true); }}
               leftLabelButton3={"Asignar servicios"}
               onClickLeftButton3={() => { setVisibleAsignarGrupal(true) }}
               leftLabelButton5={"Cargar datos"}
               onClickLeftButton5={() => openDialog()}
            // leftLabelButton6={"Cargar datos DNI"}
            // onClickLeftButton6={() => openDialogDNI()}
            >
               <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>

               <Column
                  className='ccc1'
                  body={handleItem}
                  header={<span>Item</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc1'
                  field="persona.tipo_documento.nombre"
                  header={<span className="header-rol" >Documento</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc2'
                  field="persona.numero_documento"
                  header={<span className="header-accesos">Num. Documento</span>}
                  style={{ minWidth: "60%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               <Column
                  className='ccc4'
                  field="persona.nombres"
                  header={<span className="header-locales">Nombres completos</span>}
                  style={{ minWidth: "60%", whiteSpace: "wrap", textAlign: "center" }}>
               </Column>

               <Column
                  className='ccc1'
                  body={handleContrato}
                  header={<span>Tipo de Contrato</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc1'
                  field="empresa_cargo.nombre"
                  header={<span>Puesto de Trabajo</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Envio Credenciales</span>}
                  body={actionBodyCredenciales}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               {/* <Column
                  className='ccc1'
                  body={handleEvaluacion}
                  header={<span>Último examen médico</span>}
                  style={{ minWidth: "60%", textAlign: "center", fontWeight: "700", fontSize: "12px", whiteSpace: "wrap" }}
               ></Column> */}

               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Envio Servicios</span>}
                  body={actionBodyTemplateAsignar}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>

               <Column
                  className='ccc3'
                  header={<span className="header-acciones">Acciones</span>}
                  body={actionBodyTemplate}
                  style={{ minWidth: "45%", whiteSpace: "wrap", textAlign: "center" }}
               ></Column>
            </TablaCrud>
         </div>

         {/* Modal Create */}
         <ModalCreate
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            personal={selectPersonal}
            setPersonal={setSelectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            sexos={sexos}
            roles={roles}
            getPersonal={getPersonal}
            api={"/empresa/personal/create"}
            data={data}
            contractOptions={contratos}
            user={true}
         />

         {/* Modal Editar */}
         <ModalEditar
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            personal={selectPersonal}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            sexos={sexos}
            roles={roles}
            getPersonal={getPersonal}
            api={"empresa/personal/update"}
            data={data}
            contractOptions={contratos}
            tipo_acceso={true}
         />

         {/* Modal eliminar */}
         <ModalEliminar
            visibleEliminar={visibleEliminar}
            setVisibleEliminar={setVisibleEliminar}
            personal={personal}
            setPersonal={personaleliminar}
            showToast={showToast}
            tipoDocumentos={tipoDocumentos}
            roles={roles}
            getPersonal={getPersonal}
            api={"/empresa/personal/delete"}
         />

         {/* Modal Credenciales */}
         <ModalCredenciales
            visibleCredenciales={visibleCredenciales}
            setVisibleCredenciales={setVisibleCredenciales}
            personal={selectPersonal}
            showToast={showToast}
            idPersonal={selectPersonal?.id}
            api={'empresa/personal/enviar_credenciales'}
         />

         {/* Modal asignar servicios */}
         <DialogAsignarServicio
            visibleAsignar={visibleAsignar}
            setVisibleAsignar={setVisibleAsignar}
            item={selectPersonal}
            asignarPaquetes={asignarPaquetes}
            data={data}
         />

         {/* Modal asignar servicio grupal */}
         <AsignarServicioGrupal
            visibleAsignar={visibleAsignarGrupal}
            setVisibleAsignar={setVisibleAsignarGrupal}
            item={selectedPersonal}
            examenes={selectedPersonal}
            asignarPaquetes={asignarPaqueteGrupal}
         />

         {/* Modal carga de datos DNI */}
         <CargarDatosDNI
            visible={visibleDialogDNI}
            setVisible={setVisibleDialogDNI}
            subirArchivo={subirArchivoDNI}
            isLoading={isLoading}
         />

         {/* Modal de carga de datos */}
         <CargarData
            visible={visibleDialog}
            setVisible={setVisibleDialog}
            subirArchivo={subirArchivo}
            isLoading={isLoading}
            downloadTemplate={downloadTemplate}
         />

      </div>
   );
}

export default PagePersonalEmpresa;
import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Tree } from 'primereact/tree';

import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

import note from "../../../../Images/roles/note.svg";

const AccesosModalComponent = ({
   visibleAccess,
   setVisibleAccess,
   rol,
   asignarRoles,
   accesos,
   listaAccesos,
}) => {

   // Mantén un estado local para las selecciones de cada rol
   const [tempSelectedAccesos, setTempSelectedAccesos] = useState([]);

   const hideDialogAccess = () => {
      setVisibleAccess(false);
   };

   const handleNodeSelect = (e) => {
      // Actualiza el estado temporal de accesos seleccionados con el valor del evento
      setTempSelectedAccesos(e.value);
   };

   const handleAssignRol = () => {
      // Crea un objeto de datos con el ID del rol y los accesos seleccionados temporalmente
      let data = {
         rol_id: rol.id,
         accesos: tempSelectedAccesos,
      };
      // Muestra los datos en la consola para depuración
      // Llama a la función asignarRoles con los datos creados
      asignarRoles(data);
      // Oculta el diálogo de acceso
      hideDialogAccess();
   };

   const handleCancel = () => {
      // Restablece las selecciones locales a las selecciones globales
      setTempSelectedAccesos(listaAccesos);
      //Cierra el modal
      hideDialogAccess();
   };

   const header = (<Header icono={note} titulo={"Administrar Accesos"} subtitulo={`En esta sección usted puede administrar y gestionar los datos de ${rol.nombre}`} />)

   const footer = (<Footer onClickEnviar={handleAssignRol} onClickCancelar={() => handleCancel()} label={"Guardar"} />)

   useEffect(() => {
      if (listaAccesos) {
         //Actualiza con la lista de accesos para insertarlos en el tree
         setTempSelectedAccesos(listaAccesos)
      } else {
         //Si no hay selecciones guardadas, inicializa el estado local vacío
         setTempSelectedAccesos([]);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rol?.id]);

   return (
      <div className="tabla-rol">
         <Dialog
            visible={visibleAccess}
            style={{ width: "620px", height: "70vh", borderRadius: "20px", margin: "25px" }}
            header={header}
            modal
            className="p-fluid tabla-rol"
            onHide={handleCancel}
            footer={footer}
         >
            <div className="field" style={{ marginLeft: '20px', marginRight: '20px' }}>
               <p
                  style={{
                     color: "#5555D8",
                     fontSize: "16px",
                     fontWeight: "bold",
                     fontFamily:
                        "apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol",
                  }}
               >
                  Seleccionar accesos
               </p>
               <br></br>
               <Tree
                  value={accesos} // Valor del árbol de accesos
                  selectionMode="checkbox" // Modo de selección de casilla de verificación
                  selectionKeys={tempSelectedAccesos} // Claves de selección temporales que muestran los accesos seleccionados
                  onSelectionChange={handleNodeSelect} // Función que maneja el cambio de selección de nodos
                  // tristate={true}// Permite el estado triestado en las casillas de verificación
               />
            </div>
         </Dialog>
      </div>
   );
};

export default AccesosModalComponent;
import React, { useState } from "react";
import "../../Style/style5.css";
import "../../Style/Button.css";
import { Button } from "primereact/button";


const PageOtros = () => {
   const [value, setValue] = useState("");
   //funcion Se puede simplificar
   const [isClicked, setIsClicked] = useState(false);

   function handleClick() {
      setIsClicked(true);
   }

   return (
    <div>
      {/*  */}
      <div
        className="TituloHemograma flex flex-row gap-3"
        style={{ marginBottom: "2rem", alignItems: "center" }}
      >
        <span style={{ fontWeight: "700", fontSize: "20px", color: "#5555D8", fontFamily: "Montserrat" }}>
          Examen Psicosensométrico
        </span>
        
      </div>
    </div>
  );
};
export default PageOtros;


import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import ComponentAntecedenteRelacionales from './Antecedentes';
import ComponentSintomasActuales from './Sintomas';

export default function VistasFichaAudiologica({ audFichaAudiologica, setAudFichaAudiologica }) {

   return (
      <div className="">
         <TabView>
            <TabPanel header="Antecedentes Relacionados">
               <ComponentAntecedenteRelacionales audFichaAudiologica={audFichaAudiologica} setAudFichaAudiologica={setAudFichaAudiologica} />
            </TabPanel>
            <TabPanel header="Sintomas Actuales">
               <ComponentSintomasActuales audFichaAudiologica={audFichaAudiologica} setAudFichaAudiologica={setAudFichaAudiologica} />
            </TabPanel>
         </TabView>
      </div>
   )
}

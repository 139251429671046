import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import '../../../Style/EstilosSoporteBregma/ContactenosB.css'
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';


export default function ContactenosB({ SendContactenosB, persona,vistaselected }) {
  
  const sendContacto=(data)=>{
    let url;
    switch(vistaselected){
      case "corporativo":
          url="corporativo"
          break;

      case "clinica":
          url="usuario";
          break;

      case "usuario":
          url="clinica"
          break;

  }
 
  }
  const [formConctBreg, setFormConctBreg] = useState({"corporativo":{
    numerodecelular: "",
    correoelectronico: "",
    telefono: "",
    telefono02: ""
  },"clinica":{
    numerodecelular: "",
    correoelectronico: "",
    telefono: "",
    telefono02: ""
  },"usuario":{
    numerodecelular: "",
    correoelectronico: "",
    telefono: "",
    telefono02: ""
  }});
  const [buttonActive, setButtonActive] = useState(false);
  const [firstClick, setFirstClick] = useState(true);

  function OnchangeInputs(e) {
    const { name, value } = e.target;
    setFormConctBreg((prevForm) => ({
			...prevForm,
			[vistaselected]: {
			  ...prevForm[vistaselected],
			  [name]: value,
			},
		  }));
    
  } 

  const toast = useRef(null);
  const SendInfo = () => {
    

    if (firstClick) {
      setButtonActive(!buttonActive);
      setFirstClick(false);
      return;
    }
    toast.current.show({ severity: 'info', summary: 'Info', detail: 'Se guardo correctamente' });
    //Enviar datos a la API
    SendContactenosB(formConctBreg)
    setFirstClick(true);
    setButtonActive(!buttonActive);
  
      // Aquí puedes agregar cualquier lógica adicional que necesites antes de mostrar la alerta
    /*alert('Se guardó correctamente.');*/
    
  };

  // #region Skeleton
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, [])
  // #endregion
  

  return (
    <div className="Contenedor_principal_Contactenos">
      <div className='Contenedor_iconosAH'>
        <div className='contenedor_icono_princ'>
          <img
              width="54px"
              src="https://i.ibb.co/hdPWyD0/Group-1920.png"
              alt="Group-1920"
              border="0"
            />
        </div>
        <Toast ref={toast} />
        <Button
            style={{backgroundColor:'rgb(207, 225, 252)', border:'0'}}
            onClick={SendInfo}
            icon={`${'pi pi-save text-blue-500'} font-bold text-base p-1`}
          />
      </div>
      <div className='Descripcion_sectionAH'>
        <h2 className='titulo_componente'>Contáctenos Bregma</h2>
        <h4 className='subtitulo_componente'>Administrador</h4>
        {/* <p>{`${persona?.data?.persona?.nombres} ${persona?.data?.persona?.apellido_paterno}`}</p> */}
        <p className='letra_Descripcion_componente'>Registre los datos de contacto para brindarle una ayuda a tus clientes</p>
      </div>
      <div className='Inputs_Dataa'>
        <p className='content_data_input'>
          <i className="pi pi-whatsapp"></i>Número de celular</p>
          <div className='Contenedor_input_skeleton'>
          {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
                <InputText
                  onChange={OnchangeInputs}
                  id="numerodecelular"
                  name="numerodecelular"
                  value={formConctBreg[vistaselected].numerodecelular}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="938 211 243"
                  style={{width:'100%'}}
                />
            }
          </div>
      </div>
      <div className='Inputs_Dataa'>
      <p className='content_data_input'>
          <i className="pi pi-envelope"></i>Correo Eléctronico</p>
          <div className='Contenedor_input_skeleton'>
          {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
              <InputText
                onChange={OnchangeInputs}
                id="correoelectronico"
                name="correoelectronico"
                type="text"
                className="p-inputtext-sm"
                placeholder="Soporte@bregma.com.pe"
                style={{width:'100%'}}
                value={formConctBreg[vistaselected].correoelectronico}
              />
            }
          </div>
      </div>
      <div className='Inputs_Dataa'>
      <p className='content_data_input'>
          <i className="pi pi-phone"></i>Teléfono</p>
          <div className='Contenedor_input_skeleton'>
          {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
              <InputText
                onChange={OnchangeInputs}
                id="telefono"
                name="telefono"
                type="text"
                className="p-inputtext-sm"
                placeholder="938 211 243"
                style={{width:'100%'}}
                value={formConctBreg[vistaselected].telefono}
              />
            }
          </div>
      </div>
      <div className='Inputs_Dataa'>
      <p className='content_data_input'>
          <i className="pi pi-phone"></i>Teléfono 02</p>
          <div className='Contenedor_input_skeleton'>
          {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
              <InputText
                onChange={OnchangeInputs}
                id="telefono02"
                name="telefono02"
                type="text"
                className="p-inputtext-sm"
                placeholder="938 211 243"
                style={{width:'100%'}}
                value={formConctBreg[vistaselected].telefono02}

              />
            }
          </div>
      </div>
      <div className='contenedor_imagen_relleno'>
      <img
            src="https://i.ibb.co/DbDJTbN/Other-12.png"
            alt="Other-12"
            border="0"
            width="116px"
            height="116px"
          />
      </div>
    </div>
  );
}

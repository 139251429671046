import React, { useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import InputCalendar from "../../form/InputCalendar";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

export const tipoOptions = [
  {
    id: 1,
    label: "Accidente",
    value: 1,
  },
  {
    id: 2,
    label: "Enfermedad",
    value: 2,
  },
  {
    id: 3,
    label: "Cirugías",
    value: 3,
  },
  {
    id: 4,
    label: "Otros",
    value: 4,
  },
];

export const asocOptions = [
  {
    id: 1,
    label: "Si",
    value: true,
  },
  {
    id: 2,
    label: "No",
    value: false,
  },
];

// Hooks

// 1.
export const InicFinDisc = () => {
  const [fechaInicio, setFechaInicio] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);
  const [diasIncapacidad, setDiasIncapacidad] = useState(null);

  useEffect(() => {
    if (fechaInicio && fechaFinal) {
      const diffTime =
        new Date(fechaFinal).getTime() - new Date(fechaInicio).getTime();
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDiasIncapacidad(diffDays);
    } else {
      setDiasIncapacidad(null);
    }
  }, [fechaInicio, fechaFinal]);

  return {
    fechaFinal,
    fechaInicio,
    setFechaFinal,
    setFechaInicio,
    diasIncapacidad,
  };
};

// 2.
export const TipoAsocTrab = () => {
  const [tipoAnt, settipoAnt] = useState([]);
  const [asocTrabajo, setasocTrabajo] = useState([]);
  useEffect(() => {
    settipoAnt(tipoAnt);
  }, [tipoAnt]);

  useEffect(() => {
    setasocTrabajo(asocTrabajo);
  }, [asocTrabajo]);
  return {tipoAnt, asocTrabajo, settipoAnt, setasocTrabajo }
};

const TriajeAntPerDialogAdd = ({ visible, setVisible, data, id }) => {
  // hooks
  const {
    fechaFinal,
    fechaInicio,
    setFechaFinal,
    setFechaInicio,
    diasIncapacidad,
  } = InicFinDisc();

  const {tipoAnt, asocTrabajo, settipoAnt, setasocTrabajo } = TipoAsocTrab()
  // fin de hooks

  const toast = useRef(null);

  const [tipo, setTipo] = useState({});
  const [asociado, setAsociado] = useState(null);
  const [descrip, setDescrip] = useState("");
  // const [fechaInicio, setFechaInicio] = useState(null);
  // const [fechaFinal, setFechaFinal] = useState(null);
  // const [diasIncapacidad, setDiasIncapacidad] = useState(null);
  const [menoscabo, setMenoscabo] = useState("");
  
  const asocOptions = [
    {
      id: 1,
      label: "Si",
      value: true,
    },
    {
      id: 2,
      label: "No",
      value: false,
    },
  ];

  const hideDialog = () => {
    setVisible(!visible);
  };

  //ADD
  const handleSave = () => {};

  //EDIT
  const handleEdit = () => {};

  //DELETE
  const handleDelete = () => {};

  const dialogHeader = (
    <div className="mb-4">
      {id === 0 && <span>Crear antecedente</span>}
      {id === 1 && <span>Editar antecedente</span>}
      {id === 2 && <span>Eliminar antecedente</span>}
    </div>
  );

  const dialogFooter = (
    <div className="flex align-items-center justify-content-between">
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-danger p-button-outlined p-button-text"
        onClick={hideDialog}
      />
      {id === 0 && ( //ADD
        <>
          <Button label="Guardar" icon="pi pi-save" onClick={handleSave} />
        </>
      )}

      {id === 1 && ( //EDIT
        <>
          <Button label="Guardar" icon="pi pi-save" onClick={handleEdit} />
        </>
      )}

      {id === 2 && ( //DELETE
        <>
          <Button label="Eliminar" icon="pi pi-check" onClick={handleDelete} />
        </>
      )}
    </div>
  );

  const dialogBody = (
    <div className="flex flex-column gap-3">
      <div>
        <label>Tipo</label>
        <Dropdown
          placeholder="Seleccione una opción"
          options={tipoOptions}
          onChange={(e) => settipoAnt(e.value)}
          value={tipoAnt}
        />
      </div>

      <div>
        <label>Asociado al trabajo</label>
        <Dropdown
          placeholder="Seleccione una opción"
          options={asocOptions}
          onChange={(e) => setasocTrabajo(e.value)}
          value={asocTrabajo}
        />
      </div>

      <div>
        <label>Descripción</label>
        <InputTextarea placeholder="Ingrese una descripción" autoResize />
      </div>

      <div>
        <label>Fecha de inicio</label>
        <InputCalendar
          value={fechaInicio}
          onChange={(e) => setFechaInicio(e.value)}
        />
      </div>

      <div>
        <label>Fecha final</label>
        <InputCalendar
          value={fechaFinal}
          onChange={(e) => setFechaFinal(e.value)}
        />
      </div>

      <div>
        <label>Días de incapacidad</label>
        <InputNumber value={diasIncapacidad} />
      </div>

      <div>
        <label>Menoscabo</label>
        <InputTextarea placeholder="Ingrese una descripción" autoResize />
      </div>
    </div>
  );

  return (
    <>
      <Toast ref={toast}/>
      <Dialog
        visible={visible}
        style={{ width: "600px", height: "80vh" }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        className="p-fluid"
        closable={false}
        draggable={false}
        modal
        onHide={hideDialog}
        header={dialogHeader}
        footer={dialogFooter}
      >
        {dialogBody}
      </Dialog>
    </>
  );
};

export default TriajeAntPerDialogAdd;

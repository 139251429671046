import React, { createContext, useState } from "react";
// import { RAContext } from "../RutaAtencion/RAContext";

export const TriajeContext = createContext({})

export const TriajeProvider = (props) => {

   const [antLabRiesgoContext, setAntLabRiesgoContext] = useState([]);
   const [antLabContext, setAntLabContext] = useState([]);
   const [AntOcupContext, setAntOcupContext] = useState({
      empresa_actual: null,
      puesto_ocupacional: null,
      antecedente_laboral: []
   });

   const [sigVitContext, setSigVitContext] = useState([]);
   const [pesTalContext, setPesTalContext] = useState([]);
   const [listPatologias] = useState([]);
   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState("");

   return (
      <TriajeContext.Provider
         value={{
            AntOcupContext,
            setAntOcupContext,
            sigVitContext,
            setSigVitContext,
            pesTalContext,
            setPesTalContext,
            listPatologias,
            atencionStatus,
            setAtencionStatus,
            timeStart,
            setTimeStart,
            antLabContext,
            setAntLabContext,
            antLabRiesgoContext,
            setAntLabRiesgoContext,
         }}
      >
         {props.children}
      </TriajeContext.Provider>
   );
};

import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React from 'react'
import { InputTextarea } from 'primereact/inputtextarea';
        
export default function CuestionarioTable({ datos, setDatos, header }) {
   const SiNoDropdown = ({ value, onSelectionChange }) => {
      const opciones = [
         { label: 'Sí', value: 'si' },
         { label: 'No', value: 'no' },
      ];

      return (
         <Dropdown
            value={value}
            options={opciones}
            onChange={(e) => onSelectionChange(e.value)}
            placeholder="Seleccione"
         />
      );
   };

   const showData = (rowData, rowIndex) => {
      return Number.isNaN(Number(rowData?.resultado)) ? (
         <SiNoDropdown
            value={rowData.resultado}
            onSelectionChange={(newValue) => onOpcionChange(newValue, rowIndex)}
         />
      ) : (
         <input
            style={{ width: '3rem', height: '2rem', textAlign: 'center' }}
            type='number'
            value={rowData.resultado}
            onChange={(e) => onOpcionNumberChange(e.target.value, rowIndex)}
         />
      )
   }
   const onOpcionNumberChange = (newValue, rowIndex) => {
      const newData = [...datos];
      newData[rowIndex.rowIndex].resultado = parseInt(newValue);
      setDatos(newData);
   };

   const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;
      rowData[field] = newValue;
      setDatos(datos)
   };

   const onOpcionChange = (newValue, rowIndex) => {
      const newData = [...datos];
      newData[rowIndex.rowIndex].resultado = newValue;
      setDatos(newData);
   };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return <InputTextarea autoResize className='w-full' type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
   };
   return (
      <>
         <DataTable header={header} editMode="cell" value={datos} size='small' tableStyle={{ width: '40rem' }} style={{ textAlign: 'center' }} className='TablaCuestionarioEspiro' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]}>
            <Column field='patologia_nombre' header={"Patología"} style={{ width: '55%', whiteSpace: 'pre-line' }} ></Column>
            <Column field='resultado' align={'center'} body={(rowData, rowIndex) => showData(rowData, rowIndex)} header={"Resultado"} style={{ width: '15%' }}></Column>
            <Column field='descripcion' header={"Descripción"} onCellEditComplete={onCellEditComplete} editor={(options) => cellEditor(options)} style={{ width: '30%', whiteSpace: 'pre-line' }}></Column>
         </DataTable>
         <style>
            {`
            .InputTextNEw input{
               max-width:90px
            }
            .TablaCuestionarioEspiro .p-editable-column{
               overflow-wrap: break-word;
               padding: 8px 0px 4px 0px !important;
            }
            .TablaCuestionarioEspiro textarea{
               height: 38px; 
            }
            `}
         </style>
      </>
   )
}

import React, { useState, useRef, useContext, useEffect } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import "../../../Style/Radiologia/Radiologia.css";
import { RadContext } from "../../../Page/Radiologia/RadProvider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
//import OpcRayosX from "../Radiologia/OpcRayosX";
import { Accordion, AccordionTab } from "primereact/accordion";
// import GroupDemo from "../../../Page/Radiologia/RadioContext";
// import Voicet from "../../ButtonVoice/VoiceRa";
import ColumnRT from "./Column";
import OpcRayosXTREstandar from "./OpcRayosXToraxEstandar";
import VoiceRadiologia from "../../../Components/ButtonVoice/VoiceRadiologia";


export default function LecturaOIT_Estandar() {
    let { RayXToraxEstandar, setRayXToraxEstandar } = useContext(RadContext);


    const [selectedCity, setSelectedCity] = useState(null);

    const Respuestas = [
        { name: 'SI', code: '1' },
        { name: 'NO', code: '2' }
    ];

    //    STYLES
    const EstiloTitulo = {
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '21px'
    }
    const EstiloDropNO_SI = {
        Width: '80px',
        maxWidth: '80px',
        minWidth: '80px'
    }
    const EstilosubTitulo = {
        fontFamily: 'Montserrat',
        fontWeight: '700',
        fontSize: '16px',
        color: '#6366F1'
    }

    const ActualizarLecturaOIT = (propiedad, valor) => {
        setRayXToraxEstandar(prevState => ({
            ...prevState,
            lectura_oit: {
                ...prevState.lectura_oit,
                [propiedad]: valor
            }
        }));
    }
    const AgregarObjetoDrop = (opciones, seteo, id) => {
        opciones.map(item => {
            if (item.code == id) {
                seteo(item);
            }
        });
    };

    const [Dato01_code, setDato01_code] = useState(RayXToraxEstandar.lectura_oit.primer_dato ? RayXToraxEstandar.lectura_oit.primer_dato : 0);
    const [Dato01, setDato01] = useState(null);
    useEffect(() => {
        AgregarObjetoDrop(Respuestas, setDato01, Dato01_code);
    }, [RayXToraxEstandar])
    useEffect(() => {
        ActualizarLecturaOIT("primer_dato", Dato01_code);
    }, [Dato01_code])

    const [Dato02_code, setDato02_code] = useState(RayXToraxEstandar.lectura_oit.segundo_dato ? RayXToraxEstandar.lectura_oit.segundo_dato : 0);
    const [Dato02, setDato02] = useState(null);
    useEffect(() => {
        AgregarObjetoDrop(Respuestas, setDato02, Dato02_code);
    }, [RayXToraxEstandar])
    useEffect(() => {
        ActualizarLecturaOIT("segundo_dato", Dato02_code);
    }, [Dato02_code])

    const [Dato03_code, setDato03_code] = useState(RayXToraxEstandar.lectura_oit.tercer_dato ? RayXToraxEstandar.lectura_oit.tercer_dato : 0);
    const [Dato03, setDato03] = useState(null);
    useEffect(() => {
        AgregarObjetoDrop(Respuestas, setDato03, Dato03_code);
    }, [RayXToraxEstandar])
    useEffect(() => {
        ActualizarLecturaOIT("tercer_dato", Dato03_code);
    }, [Dato03_code])

    const [Dato04_code, setDato04_code] = useState(RayXToraxEstandar.lectura_oit.cuarto_dato ? RayXToraxEstandar.lectura_oit.cuarto_dato : 0);
    const [Dato04, setDato04] = useState(null);
    useEffect(() => {
        AgregarObjetoDrop(Respuestas, setDato04, Dato04_code);
    }, [RayXToraxEstandar])
    useEffect(() => {
        ActualizarLecturaOIT("cuarto_dato", Dato04_code);
    }, [Dato04_code])


    const [Dato05_code, setDato05_code] = useState(RayXToraxEstandar.lectura_oit.calidad_rx ? RayXToraxEstandar.lectura_oit.calidad_rx : 0);
    const [Dato05, setDato05] = useState(null);
    useEffect(() => {
        AgregarObjetoDrop(Respuestas, setDato05, Dato05_code);
    }, [RayXToraxEstandar])
    useEffect(() => {
        ActualizarLecturaOIT("calidad_rx", Dato05_code);
    }, [Dato05_code])

    return (
        <div className="flex w-full h-auto flex-column gap-2 align-items-center justify-content-center">
            <style>
                {`
                    .DropEditadoMin{
                        height: 48px !important;
                    }
                    .DropEditadoMin > div:nth-child(4){
                        width: auto !important;
                        padding: 0.6rem !important;
                    }
                    .DropEditadoMin > span{
                        display: flex !important;
                        align-items: center !important;
                        justify-content: start !important;
                        width: auto !important;
                        padding: 0.5rem 0rem 0.5rem 0.9rem !important;
                    }
                `}
            </style>
            <div className="w-full h-auto" style={{ ...EstiloTitulo, padding: '10px' }}>Lectura OIT</div>
            <div className="w-full h-auto flex gap-2 align-items-center justify-content-center">
                <Dropdown value={Dato01} onChange={(e) => setDato01_code(e.value.code)} options={Respuestas} optionLabel="name"
                    className="DropEditadoMin" style={{ ...EstiloDropNO_SI }} />

                <div className="flex align-items-center justify-content-center" style={{ ...EstiloTitulo }}>/</div>

                <Dropdown value={Dato02} onChange={(e) => setDato02_code(e.value.code)} options={Respuestas} optionLabel="name"
                    className="DropEditadoMin" style={{ ...EstiloDropNO_SI }} />

                <Dropdown value={Dato03} onChange={(e) => setDato03_code(e.value.code)} options={Respuestas} optionLabel="name"
                    className="DropEditadoMin" style={{ ...EstiloDropNO_SI }} />

                <Dropdown value={Dato04} onChange={(e) => setDato04_code(e.value.code)} options={Respuestas} optionLabel="name"
                    className="DropEditadoMin" style={{ ...EstiloDropNO_SI }} />
            </div>
            <div className="h-auto flex gap-2 align-items-center justify-content-center" style={{ width: '360px' }}>
                <div className="flex flex-1 flex-column gap-2">
                    <p className="flex align-items-center justify-content-center"
                        style={{ ...EstilosubTitulo, height: '40px' }}>Simbolo: </p>
                    <InputText value={RayXToraxEstandar.lectura_oit.simbolo ? RayXToraxEstandar.lectura_oit.simbolo : ""}
                        onChange={(e) => ActualizarLecturaOIT("simbolo", e.target.value)}
                        style={{ height: '48px' }}
                    />
                </div>
                <div className="flex flex-column gap-2" >
                    <p className="flex align-items-center justify-content-center text-center"
                        style={{ ...EstilosubTitulo, width: '80px', height: '40px' }}>Calidad Rx: </p>
                    <Dropdown value={Dato05} onChange={(e) => setDato05_code(e.value.code)} options={Respuestas} optionLabel="name"
                        className="DropEditadoMin" style={{ ...EstiloDropNO_SI }} />
                </div>
            </div>

        </div>
    );
}
import React from 'react';

import RecepcionComponent from '../../../../Components/Recepcion/RecepcionTablaFiltro/Recepcion';
import { RAProvider } from '../../../RutaAtencion/RAContext';

const PageRecepcion = () => {

    return (
        <div className='flex flex-column gap-4'>
            <RAProvider Titulo="Recepción" Descripcion="Administra a los pacientes">
                <RecepcionComponent />
            </RAProvider>
        </div>
    );
}

export default PageRecepcion;

import React, { useState } from 'react';

const SplitButton = ({ options, menuPosition }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const handleMenuOptionClick = (tab) => {
    setShowMenu(false);
    options.onOptionClick(tab);
  };

  const getMenuStyle = () => {
    if (menuPosition === 'left') {
      return { top: '100%', left: '-190px' };
    } else if (menuPosition === 'right') {
      return { top: '100%', right: '-150px' };
    } else if (menuPosition === 'center') {
      return { top: '100%', left: '50%', transform: 'translateX(-50%)' };
    }
  };

  return (
    <div className="split-button">
      <button className="dots" onClick={handleMenuToggle}>
        <i className={options.icon}></i>
      </button>
      {showMenu && (
        <div className="menu-options" style={getMenuStyle()}>
          {options.menuItems.map((item, index) => (
            <button key={index} onClick={() => handleMenuOptionClick(item.tab)}>
                {item.icon && <i className={item.icon}></i>} {/* Agregamos el icono si está definido */}
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SplitButton;

import React, { useEffect, useState, useCallback } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

export default function PeriodoSeleccion({
  paquetes,
  onchangeChecks,
  sendProducto,
  setSendProducto,
}) {
  /* BUSCADOR Y LISTA DE PAQUETES */

  const [paquetesc, setPaquetesc] = useState(
    paquetes.map((node) => {
      return {
        key: node.key,
        name: node.data.name,
        seleccionado: false,
      };
    })
  );
  const [paquetesFiltrados, setPaquetesFiltrados] = useState(paquetesc);
  const [busqueda, setBusqueda] = useState("");

  const handleChange = useCallback(
    (paquete) => {
      setPaquetesc((prevPaquetesc) =>
        prevPaquetesc.map((c) =>
          c.key === paquete.key
            ? { ...c, seleccionado: !paquete.seleccionado }
            : c
        )
      );

      onchangeChecks(paquete);
    },
    [onchangeChecks]
  );

  const filterPackages = useCallback(() => {
    let filtered = paquetesc.filter((pkg) =>
      pkg.name.toLowerCase().includes(busqueda?.toLowerCase())
    );

    setPaquetesFiltrados(filtered);
  }, [paquetesc, busqueda]);

  // Actualizar paquetes filtrados cuando paquetesc o busqueda cambien
  useEffect(() => {
    filterPackages();
  }, [paquetesc, busqueda, filterPackages]);

  //Inicializar paquetesc filtrados con valores seleccionados de datos
  useEffect(() => {
    setPaquetesFiltrados(paquetesc);
  }, [paquetesc]);

  return (
    <>
      <div className="bg-white flex flex-column p-3 gap-2 border-round-lg">
        <img
          width="40px"
          height="40px"
          className="p-1 bg-indigo-100 border-round-lg"
          src="https://i.ibb.co/8Dc7R86/alt.png"
          alt=""
        />
        <p className="text-indigo-500 font-bold white-space-nowrap">
          Seleccion de paquete
        </p>

        <div className="flex flex-column gap-2">
          <div className="flex justify-content-between gap-2">
            {/* <Button
                            onClick={() => setSendProducto("Anual")}
                            className={`${sendProducto.periodo === "Anual"
                                ? `bg-indigo-500 border-indigo-500`
                                : `text-gray-500 bg-gray-200 border-gray-200`
                                } `}
                            label="Anual"
                        />
                        <Button
                            onClick={() => setSendProducto("Mensual")}
                            className={`${sendProducto.periodo === "Mensual"
                                ? `bg-indigo-500 border-indigo-500`
                                : `text-gray-500 bg-gray-200 border-gray-200`
                                } `}
                            label="Mensual"
                        /> */}
          </div>

          <div className="p-input-icon-left w-full">
            <i className="pi pi-search" />
            <InputText
              placeholder="Buscar Plan"
              className="w-full p-inputtext-sm"
              type="text"
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
            />
          </div>

          <div className=" bg-gray-200 p-2 border-round-lg">
            <div className="flex flex-column gap-1 h-13rem overflow-y-scroll pr-3">
              {
                /* MAPEO DE PAQUETES PARA EL BUSCADOR */
                <div className="border-bottom-1 border-gray-300 p-1">


                  {paquetesFiltrados.map((node) => (
                    <div
                      key={node.key}
                      className="flex justify-content-between  gap-2 mb-2"
                    >
                      <label className="flex align-items-center">
                        {node.name}
                      </label>
                      <Checkbox
                        checked={node.seleccionado}
                        onChange={() => handleChange(node)}
                      />
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>

          <div className="flex flex-column gap-4">

            <p className="text-indigo-500 font-bold white-space-nowrap">
              Pagos por:
            </p>
            <div className="flex justify-content-end pt-4">
              <img
                className="w-4"
                src="https://i.ibb.co/XYpFBR4/Vector.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

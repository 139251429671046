import React, { useContext, useState } from 'react';
import { TabPanel, TabView } from "primereact/tabview";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import {
    iconEspirometria,
} from "../../Services/importIcons";
import { Accordion, AccordionTab } from 'primereact/accordion';
// import { iconEspirometria } from "../../Services/importIcons";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import { EspiContext } from './EspiProvider';
import { AutoComplete } from 'primereact/autocomplete';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import VoiceButton from '../../Components/ButtonVoice/VoiceButton';
// import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
// import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
// import { EspiContext } from './EspiProvider';


const PageEspi = () => {
    const { atencionStatus, setAtencionStatus, setTimeStart } = useContext(EspiContext);
    const [imprimirVisible, setImprimirVisible] = useState(false);
    const [Comentario, setComentario] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);
    const [visible, setVisible] = useState(false);
    const [gridData, setGridData] = useState([
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
        [0, 0, 0],
    ]);

    const columnTitles = ['V. Real', 'V. Teórico', '%'];
    const rowTitles = ['FVC', 'FEV1', 'FEV1/FVC', 'FEF 25-75%'];

    const [vacio, setVacio] = useState(0);
    const [vacio2, setVacio2] = useState(0);

    const [porcentaje, setPorcentaje] = useState(0);
    const [porcentaje2, setPorcentaje2] = useState(0);
    const [porcentaje3, setPorcentaje3] = useState(0);
    const [porcentaje4, setPorcentaje4] = useState(0);


    const search = (event) => {
        let _items = ['Dentro de Límites Normales', 'No aceptables, Múltiples intentos', 'No cumple con criteriosde aceptabilidad ni repetibilidad para la ', 'No cumple con criteriosde aceptabilidad para la interpretación', 'No cumple con criterios de repetibilidad para  la interpretación', 'Patrón  Mixto (obstructivo y restrictivo)', 'Patrón Obstructivo', 'Patrón Obstructivo Grave', 'Patrón Obstructivo Leve', 'Patrón Obstructivo Leve vs Variante fisiológico normal', 'Patrón Obstructivo Moderadamente Grave', 'Patrón Obstructivo Moderado', 'Patrón Obstructivo muy Grave', 'Patrón sugestivo de restricción Pulmonal muy grave'];

        setItems(event.query ? _items : _items);
    }

    const showDialog = () => {
        setVisible(true);
    };

    const hideDialog = () => {
        setVisible(false);
    };

    const handleChange = (value, rowIndex, colIndex) => {
        const updatedGridData = [...gridData];
        updatedGridData[rowIndex][colIndex] = value;
        setGridData(updatedGridData);
    };

    // FEV1 / FVC
    const calcularVacío = () => {
        const vacio = gridData[0][0] / gridData[1][0];
        const updatedGridData = [...gridData];
        updatedGridData[2][0] = vacio;
        setVacio(vacio);
    };
    const calcularVacío2 = () => {
        const vacio2 = gridData[0][1] / gridData[1][1];
        const updatedGridData = [...gridData];
        updatedGridData[2][1] = vacio2;
        setVacio(vacio2);
    };

    // V.REAL * 100 / V.Teorico
    const calcularPorcentaje = () => {
        const porcentaje = (gridData[0][0] * 100) / gridData[0][1];
        const updatedGridData = [...gridData];
        updatedGridData[0][2] = porcentaje;
        setPorcentaje(porcentaje);
    };
    const calcularPorcentaje2 = () => {
        const porcentaje2 = (gridData[1][0] * 100) / gridData[1][1];
        const updatedGridData = [...gridData];
        updatedGridData[1][2] = porcentaje2;
        setPorcentaje(porcentaje2);
    };
    const calcularPorcentaje3 = () => {
        const porcentaje3 = (gridData[2][0] * 100) / gridData[2][1];
        const updatedGridData = [...gridData];
        updatedGridData[2][2] = porcentaje3;
        setPorcentaje(porcentaje3);
    };
    const calcularPorcentaje4 = () => {
        const porcentaje4 = (gridData[3][0] * 100) / gridData[3][1];
        const updatedGridData = [...gridData];
        updatedGridData[3][2] = porcentaje4;
        setPorcentaje(porcentaje4);
    };

    const handleSave = () => {
    };

    const handleRecover = () => {
        const initialGridData = [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
        ];
        setGridData(initialGridData);
        setVacio(0);
        setVacio2(0);
        setPorcentaje(0);
        setPorcentaje2(0);
        setPorcentaje3(0);
        setPorcentaje4(0);
    };

    const actualizarValores = () => {
        calcularVacío();
        calcularVacío2();
        calcularPorcentaje();
        calcularPorcentaje2();
        calcularPorcentaje3();
        calcularPorcentaje4();
    };

    return (
        <>
            <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
                scrollable
            >
                <TabPanel header="Datos">
                    <div className='pb-3'>
                        <Button icon="pi pi-plus" label=" Datos" onClick={showDialog} />
                        <Dialog header="" visible={visible} onHide={hideDialog}>
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {columnTitles.map((title, index) => (
                                                <th key={index}>{title}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {gridData.map((row, rowIndex) => (
                                            <tr key={rowIndex}>
                                                <th>{rowTitles[rowIndex]}</th>
                                                {row.map((cell, colIndex) => (
                                                    <td key={colIndex}>
                                                        <InputNumber
                                                            value={cell}
                                                            onValueChange={(e) =>
                                                                handleChange(e.target.value, rowIndex, colIndex)
                                                                // actualizarValores(e.target.value, rowIndex, colIndex)
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className=' flex align-items-center justify-content-center gap-3 pt-4'>
                                    <button className='flex pr-7 pl-7 p-2 gap-3 border-round-lg' onClick={handleSave} style={{ background: '#07A34F', border: '#07A34F', color: '#FFFFFF', cursor: 'pointer' }}>           
                                    <i className="pi pi-save" style={{margin: '0 0 0 -15px', padding: '0'}} />
                                        <span style={{marginRight: '40px'}}>
                                                Guardar</span>
                                    </button>

                                    <button className='flex pr-7 pl-7 p-2  gap-3 border-round-lg' onClick={handleRecover} style={{ background: '#64CBF4', border: '#64CBF4', color: '#FFFFFF', cursor: 'pointer' }}>           
                                    <i className="pi pi-replay"  style={{margin: '0 0 0 -15px', padding: '0'}} />
                                    <span style={{marginRight: '40px'}} >
                                        Recuperar
                                    </span>
                                        
                                    </button>
                                    
                                    <button className='flex pr-7 pl-7 p-2  gap-3 border-round-lg' onClick={actualizarValores} style={{ background: '#554EF5', border: '#554EF5', color: '#FFFFFF', cursor: 'pointer' }}>          
                                    {/* <i className="pi pi-calculator" /> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calculator" viewBox="0 0 16 16"  style={{margin: '0 0 0 -15px', padding: '0'}}>
                                        <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                                        <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                                    </svg>
                                    <span style={{marginRight: '40px'}} >
                                        Calcular
                                    </span>
                                        
                                    </button>


                                    {/* <button onClick={calcularPorcentaje}>Calcular Porcentaje</button> */}

                                    {/* <div>Vacío: {vacio}</div>
                                    <div>Porcentaje: {porcentaje}%</div> */}
                                </div>
                            </div>
                        </Dialog>
                    </div>
                    <div>
                        <span>Interpretación:</span>
                    </div>
                    <AutoComplete className='pb-3' value={value} suggestions={items} completeMethod={search} onChange={(e) => setValue(e.value)} dropdown style={{ width: '550px' }} />
                    <div>
                        <span>Observaciones:</span>
                    </div>
                    <div className='pb-3'>
                        <VoiceButton
                            value={Comentario}
                            onChange={setComentario}
                            placeholder="Comentario"
                        />
                    </div>
                    <div>
                        <span>Equipo:</span>
                        <AutoComplete className='pb-5' dropdown style={{ width: '300px' }} />
                    </div>
                </TabPanel>

                <TabPanel header="Cuestionario">
                    <Accordion multiple>
                        <AccordionTab header="Pag. 1">

                        </AccordionTab>
                    </Accordion>
                    <Accordion multiple>
                        <AccordionTab header="Pag. 2">

                        </AccordionTab>
                    </Accordion>
                    <Accordion multiple>
                        <AccordionTab header="Pag. 3">

                        </AccordionTab>
                    </Accordion>
                </TabPanel>
            </TabView>
            <div style={{ borderRadius: '10px', height: "70px" }} className='flex justify-content-end gap-2 mt-2 bg-white'>
                <button className='p-button p-component btn-2 flex gap-1'>
                    <i className='pi pi-save'> </i>
                    <span>Guardar</span>
                </button>
                <button style={{ color: 'red' }} className='p-button  p-component btn-2 flex gap-1'>
                    <i className='pi pi-times'></i>
                    <span>Cancelar</span>
                </button>
                <button style={{ color: 'black' }} onClick={() => setImprimirVisible(true)} className='p-button p-component btn-2 flex gap-1'>
                    <i className='pi pi-print'></i>
                    <span>Imprimir</span>
                </button>
            </div>
            <ModalImprimir
                imprimirVisible={imprimirVisible}
                setImprimirVisible={setImprimirVisible}
                
            />

            {/* {
                atencionStatus === 0
                &&
                <RATimeView title="Espirometría" icon={iconEspirometria} setTimeStart={setTimeStart} setAtencionStatus={setAtencionStatus} />
            } */}
        </>
    );
};

export default PageEspi;

import React, { useContext, useEffect } from 'react'
import CuestionarioTable from '../Pure/CuestionarioTable'
import { EspiContext } from '../../../../Page/Espirometria/EspiProvider'

export default function Pregunta2() {
   const { preguntasContext, setPreguntasContext } = useContext(EspiContext)

   const setUsuario = (data) => {
      setPreguntasContext({
         ...preguntasContext,
         esp_preguntas_2: {
            ...preguntasContext.esp_preguntas_2,
            usuarios_esp: data
         }
      });
   }

   const setProfesional = (data) => {
      setPreguntasContext({
         ...preguntasContext,
         esp_preguntas_2: {
            ...preguntasContext.esp_preguntas_2,
            profesional_esp: data
         }
      });
   }

   return (
      <>
         <div className='card'>
            <CuestionarioTable
               header={"Pregunta para todos los usuarios a espirometría"}
               datos={preguntasContext.esp_preguntas_2.usuarios_esp}
               setDatos={setUsuario} />
         </div>
         <div className="card">
            <CuestionarioTable
               header={"Llenar por el profesional de prueba"}
               datos={preguntasContext.esp_preguntas_2.profesional_esp}
               setDatos={setProfesional} />
         </div>
      </>
   )
}

import "../../Style/MedicinaGeneral/ExplPalpacion.css";
import { Dropdown } from 'primereact/dropdown';
import VoiceInputTextarea from "../ButtonVoice/VoiceInputTextarea";

export default function CardExamenFisico3({ patologia, setSelect01, select01,valor, funcion}) {
    const opciones = [
        { name: 'Normal', code: 'N' },
        { name: 'Leve', code: 'LE' },
        { name: 'Moderado', code: 'MO' },
        { name: 'Severo', code: 'SE' },
    ];
    return (
        <tr style={{ padding: "10px 0px 10px 0px" }}>
            <td style={{ padding: "10px 0px 10px 0px", textAlign: "start", fontWeight: "500", fontSize: "14px", color: "#565656" }}>{patologia}</td>
            <td style={{ width: "180px", padding: "10px 0px 10px 0px" }} className="pr-5">
                <Dropdown value={select01} onChange={setSelect01} options={opciones} optionLabel="name" placeholder="Normal" className="w-full" />
            </td>
            <td className="flex flex-row w-full" style={{ padding: "10px 10px 10px 10px" }}>
                <VoiceInputTextarea
                    value={valor}
                    onChange={funcion}
                    placeholder="Comentario"
                    />
            </td>
        </tr>
    );
}

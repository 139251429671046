import '../../../Style/StepsV.css';
import React, { useState, useEffect } from 'react';

export default function StepsV({ model, activeIndex, className }) {
  const [styleProgress, setStyleProgress] = useState({
    width: 0,
    left: 0,
  });
  const [styleCheck, setStyleCheck] = useState({
    width: 0,
    left: 0,
  });

  useEffect(() => {
    let w = activeIndex === model.length - 1 ? 0 : 50 / model.length;

    setStyleProgress({
      width: activeIndex * (100 / model.length) + w,
      left: 100 / model.length / 2,
    });

    setStyleCheck({
      width: activeIndex * (100 / model.length),
      left: 100 / model.length / 2,
    });
  }, [activeIndex]);

  return (
    <div className={`flex-1 flex relative select-none ${className === undefined ? `` : className}`}>
      <div
        className="absolute top-25 right-0 left-0 m-auto border-top-2 border-1 border-gray-300 z-1"
        style={{
          width: `${(model.length - 1) * (100 / model.length)}%`,
        }}
      ></div>
      <div
        className={
          'absolute top-25 border-top-2 border-1 border-blue-300 z-1 transition-all transition-duration-500'
        }
        style={{
          width: `${styleProgress.width}%`,
          left: `${styleProgress.left}%`,
        }}
      ></div>

      <div
        className={
          'absolute top-25 border-top-2 border-1 border-green-300 z-1 transition-all transition-duration-500'
        }
        style={{
          width: `${styleCheck.width}%`,
          left: `${styleCheck.left}%`,
        }}
      ></div>

      {model.map((item, index) => {
        return (
          <div
            key={index}
            className="flex-1 flex flex-column gap-3 align-items-center relative z-2"
          >
            <div
              className={`py-2 w-2rem text-center border-circle text-white StepsV_transitions bg-gray-400 ${activeIndex >= index
                ? item.estado
                  ? `StepsV_check text-transparent`
                  : `StepsV_active`
                : ``
                }`}
            >
              <span>{index + 1}</span>
              <span
                className={item.estado ? `StepsV_shine` : ``}
                style={{ animationDuration: index + 1 }}
              ></span>
            </div>
            <p
              className={` ${activeIndex >= index
                ? item.estado
                  ? `text-green-500 font-bold`
                  : `text-indigo-500 font-bold`
                : ``
                }`}
            >
              {item.label}
            </p>
          </div>
        );
      })}
    </div>
  );
}

import React, { useContext, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import GraficarAudiograma from "./Dialog/Graficar";
import Agregar from "./Dialog/Agregar";
import "../../../../Style/tabla.css";
import { AudContext } from "../../AudContext";
import Editar from "./Dialog/Editar";
import Eliminar from "./Dialog/Eliminar";

const TablaListAudiograma = () => {
   const { principalContext } = useContext(AudContext)

   const [index, setIndex] = useState(0)
   const [rowData, setRowData] = useState({
      audFichaAudiologica: {
         marca: "",
         modelo: "",
         fecha_calibracion: "",
         anos_trabajo: "",
         tiempo_exposicion: "",
         uso_proteccion: "",
         apreciacion_ruido: "",
         aud_fich_antecedentes: [
            {
               patologia_id: 270,
               resultado: "no",
               observaciones: "",
            },
            {
               patologia_id: 271,
               resultado: "no",
               observaciones: "",
            },
            {
               patologia_id: 272,
               resultado: "no",
               observaciones: "",
            },
            {
               patologia_id: 273,
               resultado: "no",
               observaciones: "",
            },
            {
               patologia_id: 274,
               resultado: "no",
               observaciones: "",
            },
            {
               patologia_id: 275,
               resultado: "no",
               observaciones: "",
            },
            {
               patologia_id: 276,
               resultado: "no",
               observaciones: "",
            },
         ],
         aud_fich_sintomas: [
            {
               patologia_id: 277,
               resultado: "no",
               observaciones: "",
            }, {
               patologia_id: 278,
               resultado: "no",
               observaciones: "",
            }, {
               patologia_id: 279,
               resultado: "no",
               observaciones: "",
            }, {
               patologia_id: 280,
               resultado: "no",
               observaciones: "",
            }, {
               patologia_id: 281,
               resultado: "no",
               observaciones: "",
            }, {
               patologia_id: 282,
               resultado: "no",
               observaciones: "",
            }, {
               patologia_id: 283,
               resultado: "no",
               observaciones: "",
            },
         ],
         aud_rinne: {
            oido_derecho: "",
            oido_izquierdo: ""
         },
         aud_weber: {
            oido_derecho: "",
            oido_izquierdo: ""
         },
         aud_schwabach: {
            oido_derecho: "",
            oido_izquierdo: ""
         },
      },
      audiometria: {
         via_aerea: {
            derecha: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
            derecha_enmascarado: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
            izquierda: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
            izquierda_enmascarado: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            }
         },
         via_osea: {
            derecha: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
            derecha_enmascarado: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
            izquierda: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
            izquierda_enmascarado: {
               v_500: 0,
               v_1000: 0,
               v_2000: 0,
               v_3000: 0,
               v_4000: 0,
               v_6000: 0,
               v_8000: 0,
            },
         },
         observaciones: "",
      }
   })
   const [visible, setVisible] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleDelete, setVisibleDelete] = useState(false)

   const handleEdit = (rowData, e) => {
      setRowData(rowData);
      setIndex(e.rowIndex);
      setVisibleEdit(true);
   };
   const handleDelete = (rowData, e) => {
      setRowData(rowData);
      setIndex(e.rowIndex);
      setVisibleDelete(true);
   };

   return (
      <>
         <Button label="Añadir" icon="pi pi-plus" onClick={() => setVisible(true)} />
         <DataTable className="tabla-container" value={principalContext.audiograma} selectionMode="single">
            <Column className="ListaDiagramas" style ={{textAlign: 'Center'}}field="audFichaAudiologica.fecha_calibracion" header="Fecha" />
            <Column className="ListaDiagramas" style ={{textAlign: 'Center'}}field="via_aerea" header="Vía Aérea" />
            <Column className="ListaDiagramas" style ={{textAlign: 'Center'}}field="via_osea" header="Vía Ósea" />
            <Column className="ListaDiagramas" style ={{textAlign: 'Center'}} field="audFichaAudiologica.anos_trabajo" header="Años de Trabajo" />
            <Column className="ListaDiagramas" style ={{textAlign: 'Center'}}field="audFichaAudiologica.tiempo_exposicion" header="Tiempo" />
            <Column className="ListaDiagramas" field="acciones" header="Acciones"
               body={(rowData, e) => (
                  <div className="flex flex-row gap-3">
                     <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-button-raised" onClick={() => handleEdit(rowData, e)} />
                     <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-raised" onClick={() => handleDelete(rowData, e)} />
                     <GraficarAudiograma data={rowData} />
                  </div>
               )}
            />
         </DataTable>
        
            
         <Agregar visible={visible} setVisible={setVisible} />
         <Editar data={rowData} index={index} visible={visibleEdit} setVisible={setVisibleEdit} />
         <Eliminar index={index} visible={visibleDelete} setVisible={setVisibleDelete} />
         

      </>
      
   
   );
   
};

export default TablaListAudiograma;
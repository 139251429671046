import React, {useState} from "react";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
//import { FileUpload } from "primereact/fileupload";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "../../Style/Table/table.css";
function Table(props) {

  const paginatorLeft = (
    <Button
      type="button"
      icon="pi pi-refresh"
      className="p-button-text"
      onClick={props.onClickRefresh}
    />
  );

  const paginatorRight = (
    <Button type="button" icon="" className="p-button-text" />
  );
  const [globalFilter, setGlobalFilter] = useState('');
  const renderHeader1 = () => {
    return (
      <div className="datatable-header">
        <div className="dt-header-title">
          <h4>{props.title}</h4>
        </div>
        {props.createButtonEnabled && (
          <div className="dt-header-btn">
            <Button
              label={props.createLabel}
              icon="pi pi-plus"
              className={`p-button-${props.roundedButtonCreate == null ? "" : "rounded"
                } p-button-raised p-button-${props.colorButtonCreate}`}
              onClick={props.onClickCreate}
              disabled={props.disabled}
            />
          </div>
        )}
        {!props.createSearchEnabled && (
          <div className="dt-header-search">
            <span className="p-input-icon-left">
              <i className="pi pi-search"></i>
              <InputText style={{ textAlign: 'left'}}
                type="search"
                onInput={(e) => setGlobalFilter(e.target.value)}
                placeholder="Buscar área médica" />
            </span>
          </div>
        )}
      </div>

    );
  };
  return (
    <>
      {props.headerEnabled && (
        <div className="p-container-header">
          <div className="p-container-titulo">
            <h1 className="container-titulo-table">{props.tableNombre}</h1>
          </div>
          <div className="container-descripcion">
            <div className="container-descripcion-table">
              <p>{props.descripcion}</p>
            </div>
            {props.importLabel == null ? (
              <></>
            ) : (
              <div className="container-descripcion-button-add">
                <InputText
                  type="file"
                  label={props.importLabel}
                  className={`p-button-${props.roundedButtonImport == null ? "" : "rounded"
                    } p-button-raised p-button-${props.colorButtonImport}`}
                  icon={props.iconImport}
                  onClick={props.onClickImport}
                />
              </div>
            )}
          </div>
        </div>
      )}

      <div className="card-table">
        <div className="titulo-table"></div>
        <DataTable
          globalFilter={globalFilter}
          emptyMessage="No se encontraron resultados."
          dataKey="id"
          value={props.data}
          paginator={props.paginator !== false ? true : false}
          selection={props.selection}
          selectionMode={props.selectionMode}
          onSelectionChange={props.onSelectionChange}
          header={props.header !== false ? renderHeader1 : ''}
          responsiveLayout="scroll"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords}"
          rows={5}
          rowsPerPageOptions={[5, 10, 20, 50]}
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          className={props.className}
        >
          {props.children}
        </DataTable>
      </div>
    </>
  );
}

Table.defaultProps = {
  createButtonEnabled: true,
  headerEnabled: true,
};

export default Table;

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from 'primereact/inputtext';
import CardClientesGClientes from "./CardClientesGClientes";
import CardContactosGClientes from "./CardContactosGClientes";
import '../../../../Style/ModalGClientes.css'
export default function ModalGClientes(props) {

    const encabezado = (
        <i className="pi pi-user text-5xl bg-purple-100 border-round-lg p-2 text-purple-500 h-4rem w-4rem " />
    )

    return (
        <Dialog
            headerStyle={{ borderRadius: '15px 15px 0 0' }}
            contentStyle={{ borderRadius: '0 0 15px 15px' }}
            style={{borderRadius: '15px' }}
            visible={props.visibleModal}
            onHide={() => props.setVisibleModal(false)}
            header={encabezado}
            draggable={false}
            className="dialog-modalCliente"
        >
            <div className="flex flex-column gap-4">
                <div className="flex flex-column gap-2">
                    <h2 className="text-indigo-500">{props.tituloModal} del cliente {props.tipoCliente}</h2>
                    <p className="font-semibold text-500">En esta sección usted podrá encontrar los datos del cliente corporativo</p>
                </div>
                <div className="flex contenedor-buscador-btnAC">
                    <div className="flex-1 flex buscadorCont">
                        <span className="p-input-icon-left " style={{ minWidth: "168px" }}>
                            <i className="pi pi-search text-indigo-500" />
                            <InputText
                                placeholder="Buscador"
                                className="border-round-xl bg-gray-100  xl:w-auto lg:w-auto md:w-full sm:w-full w-full border-none"
                            />
                        </span>
                    </div>
                    <Button className="pi pi-plus bg-white border-round-xl text-indigo-500 align-items-center justify-content-center" style={{minWidth:"120px"}} label=" Agregar contacto"></Button>
                </div>
                <div className="scroll">
                    { props.tituloModal === 'Locales'?
                        <div className="card-clientes">
                            <CardClientesGClientes/>
                            <CardClientesGClientes/>
                            <CardClientesGClientes/>
                        </div>:
                        <div className="card-clientes">
                            <CardContactosGClientes/>
                            <CardContactosGClientes/>
                            <CardContactosGClientes/>
                        </div>
                    }
                </div>
                {props.tituloModal === 'Locales' ?
                    <div className="card-clientes">
                        <CardClientesGClientes />
                        <CardClientesGClientes />
                        <CardClientesGClientes />
                    </div> :
                    <div className="card-clientes">
                        <CardContactosGClientes />
                        <CardContactosGClientes />
                        <CardContactosGClientes />
                    </div>
                }
            </div>


        </Dialog>
    )
}
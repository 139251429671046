import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

//Componentes generales
import TablaCrud from "../../Components/Table/TablaCrud";
import AuthUser from "../../AuthUser";

//Componentes únicos
import DialogPDF from "../../Components/HistoriaClinica/DialogPDF";
import InputCalendar from "../../Components/form/InputCalendar";

function PageProductosServiciosE() {
   const [checkedFecha, setCheckedFecha] = useState(false);
   const [date, setDate] = useState(null);
   const [date1, setDate1] = useState(null);
   const { http } = AuthUser();
   const [personal, setPersonal] = useState([]);
   const [globalFilter, setGlobalFilter] = useState(null);
   const [visible, setVisible] = useState(false);
   const [documentos, setDocumentos] = useState([])

   const generateRandomDates = () => {
      const startTimestamp = new Date(2020, 0, 1).getTime();
      const endTimestamp = new Date().getTime();
      const randomTimestamp =
         startTimestamp + Math.random() * (endTimestamp - startTimestamp);
      const randomDate = new Date(randomTimestamp);
      return randomDate.toLocaleDateString();
   };

   const getPersonalHistorial = () => {
      http
         .get("empresa/gestion/historial/get")
         .then((response) => {
            if (response.data.data) {
               setPersonal(response.data.data);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const handleImprimir = (rowData) => {
      setVisible(true)
      setDocumentos(rowData.documentos)
   }

   const BotonPDF = (rowData) => {
      return (
         <Button
            style={{
               backgroundColor: "white",
               color: "green",
               border: "1px solid transparent ",
            }}
            onClick={() => handleImprimir(rowData)}
         >
            <i className="pi pi-file-pdf" />
         </Button>
      );
   };

   let rowCounter = 0;

   useEffect(() => {
      getPersonalHistorial();
      generateRandomDates();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      rowCounter = 0;
   }, [personal]);

   return (
      <div
         className=" flex flex-nowrap flex-column w-full"
         style={{ height: "100%" }}
      >
         <div className="arriba mb-6">
            <span className="tit">Historia Clínica</span>
            <br />
            <span className="con">
               En este módulo usted podrá ver la historia clínica de sus trabajadores
            </span>
         </div>
         <div className="flex flex-wrap flex-column">
            <div className="flex flex-wrap flex-row gap-4 p-3">
               <div className="flex flex-wrap flex-column gap-2">
                  <div className="flex flex-wrap flex-row gap-3">
                     <Checkbox
                        onChange={(e) => setCheckedFecha(e.checked)}
                        checked={checkedFecha}
                     ></Checkbox>
                     <p>Por fecha</p>
                  </div>
                  <div className="flex flex-wrap flex-row gap-3">
                     <InputCalendar
                        value={date}
                        onChange={(e) => setDate(e.value)}
                        showIcon
                        disabled={!checkedFecha}
                     />
                     <InputCalendar
                        value={date1}
                        onChange={(e) => setDate1(e.value)}
                        showIcon
                        disabled={!checkedFecha}
                     />
                  </div>
               </div>
               <div className="flex flex-wrap align-items-end">
                  <Button label="Filtrar" />
               </div>
            </div>
            <div>
               <TablaCrud
                  name="Historia clinica"
                  tableNombre={`Lista de historia clinica`}
                  descripcion={`A continuación, se visualiza la lista de historia clinica.`}
                  onInputSearch={(e) => setGlobalFilter(e.target.value)}
                  valueGlobalFilter={globalFilter}
                  value={personal}
               >
                  <Column
                     field={"id"}
                     body={() => {
                        rowCounter += 1;
                        return rowCounter;
                     }}
                     header="ID"
                  ></Column>
                  <Column
                     field={"tipo_documento.nombre"}
                     header="Tip_doc"
                  ></Column>
                  <Column
                     field={"numero_documento"}
                     header="Nro. documento"
                  ></Column>
                  <Column
                     // body={(rowData) =>
                     //    `${rowData.persona.apellido_paterno} ${rowData.persona.apellido_materno} ${rowData.persona.nombres}`
                     // }
                     field="nombres_completos"
                     header="Nombres"
                  ></Column>
                  <Column
                     field="fecha"
                     // body={() => generateRandomDates()}
                     header="Fecha"
                  ></Column>
                  <Column
                     // body={() => getRandomProfile()}
                     field={"perfil.nombre"}
                     header="Perfil"
                  ></Column>
                  <Column body="Apto" header="Resultado"></Column>
                  <Column
                     field={""}
                     body={BotonPDF}
                     header="Informe"
                     style={{ textAlign: "center" }}
                  ></Column>
               </TablaCrud>
            </div>
         </div>
         <DialogPDF documentos={documentos} prop01={visible} prop02={setVisible} />
      </div>
   );
}

export default PageProductosServiciosE;

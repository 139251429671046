import { Dialog } from 'primereact/dialog';
import React, { useRef, useState } from 'react';
import { Divider } from 'primereact/divider';
import Logo from "../../../Images/Reclutaminento/img/ICONOASIGNARCLINICA.svg";
import Logo2 from "../../../Images/Reclutaminento/img/ICONODELOCALIZACION.svg";
import { InputText } from 'primereact/inputtext';
import "../../../Style/Reclutamiento/CSSCarrusel.css";
import CardClinica from './CardClinica';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';


const ModalExamenComponent = ({ visibleExamen, setVisibleExamen }) => {

    const [visible, setVisible] = useState(false);

    const [date, setDate] = useState(null);

    const carruselContenedorRef = useRef(null);

    const HideDialog = () => {
        setVisibleExamen(false)
    }

    const scrollCarrusel = (scrollOffset) => {
        carruselContenedorRef.current.scrollBy({
            left: scrollOffset,
            behavior: 'smooth'
        });
    };

    const footerContent = (
        <div>
            <Button
                label="Enviar"
                onClick={() => {
                    setVisibleExamen(false);
                    setVisible(false);
                }}
                autoFocus
                style={{ fontWeight: '500', fontSize: '14px' }}
            />
        </div>
    );


    return (
        <div>
            <Dialog footer={footerContent} visible={visibleExamen} style={{ width: "60vw", borderRadius: '25px', minWidth: '356px' }} appendTo={document.getElementById("persona-form")} header={
                <>
                    <div className="flex justify-content-center align-items-center" style={{ backgroundColor: "#EBEBFF", width: "60px", height: "60px", borderRadius: "10px" }}                    >
                        <div className="flex justify-content-center align-items-center">
                            <img src={Logo} alt="" />
                        </div>
                    </div>
                    <h1 className="per">Asignar Clínica</h1>
                </>
            }
                dismissableMask
                className="p-fluid "
                onHide={() => {
                    HideDialog();
                }}
            >
                <Divider />
                <div className='flex flex-row w-full gap-2 align-items-center' style={{ marginBottom:'10px' }}>
                    <label>Perfil</label>
                    <Dropdown
                        // value={}
                        // options={}
                        optionLabel="Perfil"
                        placeholder="Seleccione una Perfil"
                        // onChange={e => setSuperAreaSelected(e.value)}
                        required
                        className='w-full'
                    />
                </div>
                <div className='flex flex-wrap gap-3 align-items-center justify-content-between'>
                    <div className='flex flex-wrap align-items-center gap-3'>
                        {/* Circulo con icono */}
                        <div className='flex flex-column justify-content-center align-items-center' style={{ width: '42px', height: '42px', background: '#EBEBFF', borderRadius: '50%' }}>
                            <img src={Logo2} alt="" />
                        </div>
                        {/* Direccion */}
                        <div className='flex flex-column gap-1' style={{ width: '250px' }}>
                            <span style={{ fontWeight: '500', fontSize: '14px', color: '#344054' }}>
                                Dirección del reclutado
                            </span>
                            <span style={{ fontWeight: '400', fontSize: '14px', color: '#7B8794' }}>
                                Los Eucaliptos 408, San Juan de Lurigancho 15412
                            </span>
                        </div>
                    </div>
                    <div>
                        <span className="p-input-icon-left" style={{ height: '36px' }}>
                            <i className="pi pi-search" />
                            <InputText placeholder="Buscador" style={{ height: '36px', borderRadius: '13px', background: '#F6F7FB' }} />
                        </span>
                    </div>
                </div>
                <div className='flex flex-column gap-3' style={{ marginTop: '30px' }}>
                    <span style={{ fontWeight: '500', fontSize: '14px' }}>
                        Locales cercanos recomendados
                    </span>
                    <div className='flex flex-row gap-5 justify-content-end' style={{ marginTop: '-40px' }}>
                        <button className="CarruselBoton CarruselBotonIzquierda" onClick={() => scrollCarrusel(-carruselContenedorRef.current.offsetWidth)}>
                            &#8249;
                        </button>
                        <button className="CarruselBoton CarruselBotonDerecha" onClick={() => scrollCarrusel(carruselContenedorRef.current.offsetWidth)}>
                            &#8250;
                        </button>
                    </div>
                    <div className="CarruselModalExamen" ref={carruselContenedorRef}>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="la Luz"
                                estado="Habilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="San Pablo"
                                estado="Habilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="San Juan Bautista"
                                estado="Deshabilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="Ramdom 1"
                                estado="Deshabilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="Ramdom 2"
                                estado="Habilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="Ramdom 3"
                                estado="Deshabilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="Ramdom 4"
                                estado="Habilitado" />
                        </div>
                        <div className="ElementosCarrusel">
                            <CardClinica
                                nombreClinica="Ramdom 5"
                                estado="Deshabilitado" />
                        </div>
                    </div>
                </div>
                <div className='FECHA_ASIGNARCLINICA flex flex-column w-5 gap-3'>
                    <span>Fecha</span>
                    <Calendar value={date} onChange={(e) => setDate(e.value)} showIcon />
                </div>
            </Dialog>
        </div>
    );
}

export default ModalExamenComponent;
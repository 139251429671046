import React, { useContext, useEffect, useState } from 'react';
import { PsicologiaContext } from "../PsicologiaContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { SelectButton } from "primereact/selectbutton";
import '../../../Style/ExamenMental/psi_exa_ment.css'
import { InputTextarea } from 'primereact/inputtextarea';


const PageExaMent = () => {

  let { pageViewContext, stateAtencion } = useContext(RAContext)
  let { exaMenContext, setExaMenContext } = useContext(PsicologiaContext)

  //Variables de estado
  const [presentacion, setPresentacion] = useState(exaMenContext?.presentacion ?? 1)
  const [postura, setPostura] = useState(exaMenContext?.postura ?? 1)
  const [ritmo, setRitmo] = useState(exaMenContext?.ritmo ?? null);
  const [tono, setTono] = useState(exaMenContext?.tono ?? 2);
  const [articulacion, setArticulacion] = useState(exaMenContext?.articulacion ?? 2);
  const [tiempo, setTiempo] = useState(exaMenContext?.tiempo ?? 1);
  const [espacio, setEspacio] = useState(exaMenContext?.espacio ?? 1);
  const [persona, setPersona] = useState(exaMenContext?.persona ?? 1);
  const [coord, setCoord] = useState(exaMenContext?.coordinacion_visomotriz ?? 2);

  //estado para que se visualize el textarea cuando se selecciona el "otros" en ritmo
  const [showInput, setShowInput] = useState(false);

  //estado para guardar el texto del textarea
  const [inputValue, setInputValue] = useState("");

  //END Variables de estado

  //Opciones para los selectButton
  let presentacionOptions = [
    { label: 'Adecuado', id: "1" },
    { label: 'Inadecuado', id: "2" },
  ]
  let posturaOptions = [
    { label: 'Erguida', id: "1" },
    { label: 'Encorvada', id: "2" },
  ]
  let ritmoOptions = [
    { label: 'Lento', value: "1", id: "1" },
    { label: 'Rápido', value: "2", id: "2" },
    { label: 'Fluido', value: "3", id: "3" },
   //  { label: 'Otros', value: 4, id: "4" },
  ];

  let tonoOptions = [
    { label: 'Bajo', id: "1" },
    { label: 'Moderado', id: "2" },
    { label: 'Alto', id: "3" }
  ]
  let articulacionOptions = [
    { label: 'Con dificultad', id: "1" },
    { label: 'Sin dificultad', id: "2" }
  ]
  let orientacionOptions = [
    { label: 'Orientado', id: "1" },
    { label: 'Desorientado', id: "2" }
  ]
  let coordinacionOptions = [
    { label: 'Lento', id: "1" },
    { label: 'Moderado', id: "2" },
    { label: 'Fluido', id: "3" },
    { label: 'No Aplica', id: "4" }
  ]

  //END Opciones para los selectButton


/*NO ES NECESARIO 
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  }; */

  //Funcion para actualizar el estado de ritmo y vizualizar el textArea
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setRitmo(selectedValue);

    if (e.value === 4) {
      setShowInput(true);
    } else {
      setShowInput(false);
    }
  };


  //UseEffect para que se actualize los datos de examen mental

  useEffect(() => {

    setExaMenContext({
      [`presentacion`]: presentacion,
      [`postura`]: postura,
      [`ritmo`]: ritmo,
      [`tono`]: tono,
      [`articulacion`]: articulacion,
      [`tiempo`]: tiempo,
      [`espacio`]: espacio,
      [`persona`]: persona,
      [`coordinacion_visomotriz`]: coord,
    })


  }, [presentacion, postura, ritmo, tono, articulacion, tiempo, espacio, persona, coord])

  return (
    <>
      <div className='contenedor-abuelo'>
        <div>
          <h3 className='observacion'>Observación de Conductas</h3>
        </div>

        <div className='presenpos'>
          <div className="psi_em_form_container">
            <div className="psi_em_form_content">
              <div className="psi_em_form_titles">
                <span>Presentación</span>
              </div>
              <div className="psi_em_form_select">
                <SelectButton
                  optionLabel="label"
                  className='selectbutton'
                  optionValue="id"
                  value={presentacion}
                  options={presentacionOptions}
                  onChange={(e) => setPresentacion(e.value)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
                />
              </div>
            </div>
          </div>


          <div className="psi_em_form_container">
            <div className="psi_em_form_content">
              <div className="psi_em_form_titles">
                <span>Postura</span>
              </div>
              <div className="psi_em_form_select">
                <SelectButton
                  optionLabel="label"
                  className='selectbutton'
                  optionValue="id"
                  value={postura}
                  options={posturaOptions}
                  onChange={(e) => setPostura(e.value)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="psi_em_form_titles_general discurso">
          <span>Discurso</span>
        </div>
        <div className='contenedor-papa'>
          <div className="psi_em_form_content-papa">
            <div className="psi_em_form_titless">
              <span>Ritmo</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbuttonn'
                optionValue="id"
                value={ritmo}
                options={ritmoOptions}
                onChange={handleSelectChange}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />

              {
                showInput && (
                  <InputTextarea
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                    style={{ width: '100%', height: '100px' }}
                    disabled={stateAtencion[pageViewContext].estado === 3}
                  />
                )

              }

            </div>
          </div>



          <div className="psi_em_form_content-papa">
            <div className="psi_em_form_titless">
              <span>Tono</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbuttonn'
                optionValue="id"
                value={tono}
                options={tonoOptions}
                onChange={(e) => setTono(e.value)}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />
            </div>
          </div>
        </div>

        <div className="psi_em_form_container">
          <div className="psi_em_form_content">
            <div className="psi_em_form_titles">
              <span>Articulación</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbutton'
                optionValue="id"
                value={articulacion}
                options={articulacionOptions}
                onChange={(e) => setArticulacion(e.value)}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />
            </div>
          </div>
        </div>

        <div className="psi_em_form_container">
          <div className="psi_em_form_titles_general">
            <span>Orientación</span>
          </div>
          <div className="psi_em_form_content">
            <div className="psi_em_form_titles">
              <span>Tiempo</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbutton'
                optionValue="id"
                value={tiempo}
                options={orientacionOptions}
                onChange={(e) => setTiempo(e.value)}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />
            </div>
          </div>
        </div>

        <div className="psi_em_form_container">
          <div className="psi_em_form_content">
            <div className="psi_em_form_titles">
              <span>Espacio</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbutton'
                optionValue="id"
                value={espacio}
                options={orientacionOptions}
                onChange={(e) => setEspacio(e.value)}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />
            </div>
          </div>
        </div>

        <div className="psi_em_form_container">
          <div className="psi_em_form_content">
            <div className="psi_em_form_titles">
              <span>Persona</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbutton'
                optionValue="id"
                value={persona}
                options={orientacionOptions}
                onChange={(e) => setPersona(e.value)}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />
            </div>
          </div>
        </div>

        <div className="psi_em_form_container">
          <div className="psi_em_form_content-visomotriz">
            <div className="psi_em_form_titless">
              <span style={{ textAlign: "center" }}>Coordinación visomotriz</span>
            </div>
            <div className="psi_em_form_select">
              <SelectButton
                optionLabel="label"
                className='selectbuttonn'
                optionValue="id"
                value={coord}
                options={coordinacionOptions}
                onChange={(e) => setCoord(e.value)}
                disabled={stateAtencion[pageViewContext].estado === 3}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageExaMent;
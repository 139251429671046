import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { classNames } from 'primereact/utils';
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import axios from "axios";

//Componentes generales
import AuthUser from "../../../../AuthUser";
import InputCalendar from "../../../form/InputCalendar";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";

//Estilos
import '../Style/VerificarFaltasCuesti.css'
import { Footer, Header } from "../../../Pure/SeccionesNavegacion";

export default function ModalCreate({
   visibleCreate,
   setVisibleCreate,
   setPersonal,
   showToast,
   tipoDocumentos,
   sexos,
   roles,
   getPersonal,
   api,
   data,
   contractOptions,
   user
}) {

   const dispatch = useDispatch();
   const { http } = AuthUser();

   const [value, setValue] = useState(null);

   //Validaciones
   const [TipoDocumentVerificaci, setTipoDocumentVerificaci] = useState(false);
   const [NumDocumentVerificaci, setNumDocumentVerificaci] = useState(false);
   const [NombrePersonalVerificaci, setNombrePersonalVerificaci] = useState(false);
   const [ApellidoPPersonalVerificaci, setApellidoPPersonalVerificaci] = useState(false);
   const [ApellidoMPersonalVerificaci, setApellidoMPersonalVerificaci] = useState(false);
   const [CorrePersonalVerificaci, setCorrePersonalVerificaci] = useState(false);
   const [EsUsuarioVerificaci, setEsUsuarioVerificaci] = useState(false);
   const [verificacionPuestoTrabajo, setVerificacionPuestoTrabajo] = useState(false);
   const [verificacionRol, setVerificacionRol] = useState(false);

   //Valores iniciales
   const initialValues = {
      id: 0,
      tipo_documento: tipoDocumentos?.[0],
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      distrito_id: "",
      celular: "",
      correo: "",
      direccion: "",
      rol: "",
      empresa_cargo: "",
      usuario: "",
      sexo_id: ""
   };

   let options = [
      { label: 'Es usuario', value: true },
      { label: 'No es usuario', value: false }
   ]

   //Validaciones para determinar si el campo fue ingresado en el formulario
   const VerificarCampos = () => {
      var verifi = true;

      //Tipo de documento
      if (formik.values.tipo_documento === null || formik.values.tipo_documento === "" || formik.values.tipo_documento === undefined) {
         setTipoDocumentVerificaci(true);
         verifi = false;
      }

      //Número de documento
      if (formik.values.numero_documento === null || formik.values.numero_documento === "" || formik.values.numero_documento === undefined) {
         setNumDocumentVerificaci(true);
         verifi = false;
      }

      //Nombres
      if (formik.values.nombres === null || formik.values.nombres === "" || formik.values.nombres === undefined) {
         setNombrePersonalVerificaci(true);
         verifi = false;
      }

      //Apellido paterno
      if (formik.values.apellido_paterno === null || formik.values.apellido_paterno === "" || formik.values.apellido_paterno === undefined) {
         setApellidoPPersonalVerificaci(true);
         verifi = false;
      }

      //Apellido materno
      if (formik.values.apellido_materno === null || formik.values.apellido_materno === "" || formik.values.apellido_materno === undefined) {
         setApellidoMPersonalVerificaci(true);
         verifi = false;
      }

      //Puesto de trabajo
      if (!formik.values.empresa_cargo) {
         setVerificacionPuestoTrabajo(true);
         verifi = false;
      }

      //Tipo de usuario
      if (value === null) {
         setEsUsuarioVerificaci(true);
         verifi = false;
      }

      if (value) {
         if (!formik.values.rol) {
            setVerificacionRol(true)
            verifi = false
         }
      }

      return verifi;
   }

   // Inicializa el hook useFormik con los valores iniciales y la función onSubmit
   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: async (data) => {
         if (VerificarCampos()) {
            const año = formik.values.fecha_nacimiento.getFullYear();
            const mes = ('0' + (formik.values.fecha_nacimiento.getMonth() + 1)).slice(-2); // Se agrega 1 al mes porque los meses van de 0 a 11
            const dia = ('0' + formik.values.fecha_nacimiento.getDate()).slice(-2);

            // Formatear la fecha en el formato deseado (yyyy-dd-mm)
            const fechaFormateada = `${año}-${mes}-${dia}`;

            // Verifica si los campos del formulario son válidos
            const formDataSend = new FormData(); // Crea un nuevo objeto FormData
            for (var key in data) { // Itera sobre cada clave en el objeto data
               formDataSend.append(key, data[key]); // Añade cada par clave-valor a formDataSend
            }
            // Añade campos adicionales a formDataSend con valores específicos
            formDataSend.set("fecha_nacimiento", fechaFormateada);
            formDataSend.append("tipo_documento_id", data?.tipo_documento?.id);
            formDataSend.append("tipo_contrato_id", data?.tipo_contrato?.id);
            formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id);
            formDataSend.append("rol_id", data?.rol?.id);
            dispatch(startLoading()); // Inicia el estado de carga
            try {
               const response = await http.post(api, formDataSend);
               if (response?.data?.resp) {
                  HideDialogCreate(); // Oculta el diálogo de creación
                  await Promise.all([getPersonal()]); // Espera a que se obtenga la lista de personal
                  setPersonal(); // Actualiza el estado del personal
                  showToast("success", "Personal creado", response?.data?.resp);
               } else if (response?.data?.error) {
                  showToast("error", "Error", response?.data?.error);
               }
            } catch (error) {
               showToast("error", "Error", "Contáctese con soporte técnico");
            } finally {
               dispatch(endLoading()); // Finaliza el estado de carga
            }
         }
      }
   });

   // Función que verifica si un campo del formulario es válido
   // Devuelve true si el campo ha sido tocado y tiene errores
   const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

   // Función que obtiene el mensaje de error de un campo del formulario
   // Devuelve un elemento <small> con el mensaje de error si el campo no es válido
   const getFormErrorMessage = (name) => {
      return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
   };

   // Función que oculta el diálogo de creación
   // Resetea el formulario y el valor del estado 'value'
   const HideDialogCreate = () => {
      setVisibleCreate(false); // Oculta el diálogo
      formik.resetForm(); // Resetea el formulario
      setValue(null); // Resetea el valor del estado 'value'
   };

   const getNombreCompleto = (numdocumento, tipoDocumento, tipo_contrato, correo, celular, rol, usuario, empresa_cargo) => {
      if (formik.values.tipo_documento !== "") {
         dispatch(startLoading())
         //Para DNI
         if (formik.values.tipo_documento.nombre === "DNI") {
            axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
               .then(res => {
                  if (res.data.message) {
                     showToast(
                        "error",
                        "Número de documento no encontrado",
                        `No se encontró ningun dato`
                     )
                  } else {
                     //Modifica los valores iniciales con la respuesta obtenida de la api
                     formik.setValues({
                        tipo_documento: tipoDocumento,
                        numero_documento: numdocumento,
                        tipo_contrato: tipo_contrato,
                        nombres: res.data.nombres,
                        apellido_paterno: res.data.apellidoPaterno,
                        apellido_materno: res.data.apellidoMaterno,
                        fecha_nacimiento: "2022-01-01",
                        distrito_id: 2,
                        celular: correo,
                        correo: celular,
                        direccion: "",
                        rol: rol,
                        usuario: usuario,
                        empresa_cargo: empresa_cargo,
                     })
                     //Actualiza el estado de las verificaciones para nombres y apellidos
                     setNombrePersonalVerificaci(false);
                     setApellidoPPersonalVerificaci(false);
                     setApellidoMPersonalVerificaci(false);
                  }
               })
               .catch(() => {
               })
               .finally(() => {
                  dispatch(endLoading());
               })
         } else if (formik.values.tipo_documento.nombre === "RUC") { //Para RUC
            axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
               .then(res => {
                  //Actualiza los valores iniciales con los datos sustraídos de la api
                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
                     apellido_paterno: res.data.razonSocial.split(" ")[0],
                     apellido_materno: res.data.razonSocial.split(" ")[1],
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: celular,
                     correo: correo,
                     direccion: "",
                     rol: rol,
                     usuario: "",
                     empresa_cargo: empresa_cargo,
                     tipo_contrato: tipo_contrato,
                  })
               })
               .catch((error) => {
                  showToast(
                     "error",
                     "Número de documento no encontrado",
                     `No se encontró ningun dato`
                  )
               })
         }
      } else { //Muestra un mensaje de error cuando el tipo de documento no fue elegido
         showToast(
            "error",
            "Tipo de documento",
            `No se eligió un tipo de documento`
         );
      }
   }

   const header = (
      <Header
         piIcon={'pi pi-user'}
         titulo={'Crear Personal'}
         subtitulo={'En esta seccion usted puede registrar los datos del personal para su creación.'} />
   )

   const paqueteCreateDialogFooter = (
      <Footer
         onClickCancelar={() => HideDialogCreate()}
         type={'submit'}
         label={'Crear Usuario'} />
   );

   useEffect(() => {
      setTipoDocumentVerificaci(false);
      setNumDocumentVerificaci(false);
      setNombrePersonalVerificaci(false);
      setApellidoPPersonalVerificaci(false);
      setApellidoMPersonalVerificaci(false);
      setCorrePersonalVerificaci(false);
      setEsUsuarioVerificaci(false);
      setVerificacionPuestoTrabajo(false);
      setVerificacionRol(false);
   }, [visibleCreate])

   useEffect(() => {
      if (value) {
         formik.setFieldValue('usuario', "1")
      } else {
         formik.setFieldValue('usuario', "0")
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [value]);

   return (
      <div>
         <form
            id="persona-form"
            className="persona-form"
            noValidate
            onSubmit={formik.handleSubmit}
         >
            <Dialog
               visible={visibleCreate}
               style={{ width: "450px", margin: "5%" }}
               appendTo={document.getElementById("persona-form")}
               header={header}
               footer={paqueteCreateDialogFooter}
               dismissableMask
               className="p-fluid formularioCreateEmpresaSA"
               onHide={() => HideDialogCreate()}
            >
               <div className="flex flex-column gap-3">
                  <div className="flex flex-column w-full">
                     <p className="per1">(*)Tipo de documento</p>
                     <div className="flex w-full flex-column">
                        <Dropdown
                           id="tipo_documento"
                           name="tipo_documento"
                           value={formik.values.tipo_documento}
                           onChange={(event) => {
                              formik.handleChange(event);
                              setTipoDocumentVerificaci(false);
                           }}
                           options={tipoDocumentos}
                           optionLabel="nombre"
                           placeholder="Selecciona un tipo de documento"
                           //Los estilos de la clase cambian según la disponibilidad de los datos luego de enviar
                           className={classNames('w-full', 'selecttorrBoton2', { 'p-invalid': isFormFieldValid('tipo_documento'), 'selectAS': TipoDocumentVerificaci })}
                        />
                        {/* Muestra un mensaje de error producido por la falta de datos */}
                        {getFormErrorMessage('tipo_documento')}
                     </div>
                  </div>

                  <div className="flex flex-1 w-full flex-column gap-2">
                     <p className="per1">(*)Número de documento</p>
                     <div className="flex flex-1 w-full gap-2">
                        <InputText
                           id="numero_documento"
                           name="numero_documento"
                           value={formik.values.numero_documento}
                           onChange={(event) => {
                              formik.handleChange(event);
                              setNumDocumentVerificaci(false);
                           }}
                           required
                           //Los estilos de la clase cambian según la disponibilidad de los datos luego de enviar
                           className={classNames('w-full', 'selecttorrBoton2', { 'p-invalid': isFormFieldValid('numero_documento'), 'selectAS': NumDocumentVerificaci })}
                        />
                        <Button
                           label="Validar"
                           type="button"
                           style={{ background: "#07A34F", borderColor: "#07A34F" }}
                           onClick={() => getNombreCompleto(
                              formik.values.numero_documento,
                              formik.values.tipo_documento,
                              formik.values.tipo_contrato,
                              formik.values.correo,
                              formik.values.celular,
                              formik.values.rol,
                              formik.values.empresa_cargo,
                              formik.values.usuario,
                           )}//Envía 
                           disabled={formik.values.tipo_documento?.id === 2}
                        />
                     </div>
                     {/* Muestra un mensaje de error producido por la falta de datos */}
                     {getFormErrorMessage('numero_documento')}
                  </div>

                  <div className={`flex flex-column gap-2 iputNombreComplete ${NombrePersonalVerificaci ? 'selectAS' : ''}`}>
                     <label>(*)Nombres Completos
                        <InputText
                           id="nombres"
                           name="nombres"
                           value={formik.values.nombres}
                           //Los estilos de la clase cambian según la disponibilidad de los datos luego de enviar
                           className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                           onChange={(event) => {
                              formik.handleChange(event);
                              setNombrePersonalVerificaci(false);
                           }}
                           required
                           autoComplete="off"
                        />
                        {/* Muestra un mensaje de error producido por la falta de datos */}
                        {getFormErrorMessage('nombres')}
                     </label>
                  </div>

                  <div className={`flex flex-column gap-2 iputApellidoPAComplete ${ApellidoPPersonalVerificaci ? 'selectAS' : ''}`}>
                     <label htmlFor="lastname">(*)Apellidos Paterno</label>
                     <InputText
                        id="apellido_paterno"
                        name="apellido_paterno"
                        value={formik.values.apellido_paterno}
                        onChange={(event) => {
                           formik.handleChange(event);
                           setApellidoPPersonalVerificaci(false);
                        }}
                        //Los estilos de la clase cambian según la disponibilidad de los datos luego de enviar
                        className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })} />
                     {/* Muestra un mensaje de error producido por la falta de datos */}
                     {getFormErrorMessage('apellido_paterno')}
                  </div>

                  <div className={`flex flex-column gap-2 iputApellidoPAComplete ${ApellidoMPersonalVerificaci ? 'selectAS' : ''}`}>
                     <label htmlFor="lastname">(*)Apellidos Materno</label>
                     <InputText
                        id="apellido_materno"
                        name="apellido_materno"
                        value={formik.values.apellido_materno}
                        onChange={(event) => {
                           formik.handleChange(event);
                           setApellidoMPersonalVerificaci(false);
                        }}
                        //Los estilos de la clase cambian según la disponibilidad de los datos luego de enviar
                        className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })} />
                     {/* Muestra un mensaje de error producido por la falta de datos */}
                     {getFormErrorMessage('apellido_materno')}
                  </div>

                  <div className={`flex flex-column gap-2 iputCorreoComplete ${CorrePersonalVerificaci ? 'selectAS' : ''}`}>
                     <label htmlFor="">Correo</label>
                     <InputText
                        id="correo"
                        name="correo"
                        value={formik.values.correo}
                        onChange={(event) => formik.handleChange(event)}
                        className="w-full" />
                  </div>

                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Celular</label>
                     <InputText
                        type="number"
                        id="celular"
                        name="celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>

                  <div className={`flex flex-column gap-2 ${verificacionPuestoTrabajo ? 'selectAS' : ''}`}>
                     <p>(*)Puesto de trabajo</p>
                     <Dropdown
                        id="empresa_cargo"
                        name="empresa_cargo"
                        className={classNames('w-full', 'selecttorrBoton2', { 'p-invalid': isFormFieldValid('empresa_cargo'), 'selectAS': verificacionPuestoTrabajo })}
                        value={formik.values.empresa_cargo}
                        onChange={(event) => { formik.handleChange(event); setVerificacionPuestoTrabajo(false) }}
                        options={data}
                        optionLabel="nombre"
                        placeholder="Selecciona un Puesto de trabajo"
                     />
                     {getFormErrorMessage('empresa_cargo')}
                  </div>

                  <div className="flex w-full flex-column">
                     <p className="per1">Tipo de contrato</p>
                     <Dropdown
                        id="tipo_contrato"
                        name="tipo_contrato"
                        value={formik.values.tipo_contrato}
                        onChange={formik.handleChange}
                        options={contractOptions}
                        optionLabel="nombre"
                        placeholder="Selecciona un tipo de contrato"
                        className="w-full"
                     />
                  </div>

                  {/* No eliminar */}
                  {/* <div className="flex w-full flex-column">
                     <p className="per1">Último examen médico</p>
                     <Calendar
                        id="examen_medico"
                        name="examen_medico"
                        value={formik.values.examen_medico}
                        onChange={formik.handleChange}
                        optionLabel="nombre"
                        placeholder="Seleccionar fecha"
                        className="w-full"
                     />
                  </div> */}

                  <div className="flex justify-content-center align-content-center">
                     <SelectButton
                        className={`flex-1 selecttorrBoton2 ${EsUsuarioVerificaci ? 'selectAS' : ''}`}
                        value={value}
                        onChange={(e) => { setValue(e.value); setEsUsuarioVerificaci(false) }}
                        options={options}
                     ></SelectButton>
                  </div>

                  <div className={`flex flex-column gap-2 ${verificacionRol ? 'selectAS' : ''}`}>
                     {value ? <p>(*)Rol</p> : <p>Rol</p>}
                     <Dropdown
                        id="rol"
                        name="rol"
                        value={formik.values.rol}
                        onChange={(event) => {
                           formik.handleChange(event);
                           setVerificacionRol(false);
                        }}
                        options={roles}
                        optionLabel="nombre"
                        placeholder="Selecciona un Rol"
                        className={value ? classNames('w-full', 'selecttorrBoton2', { 'p-invalid': isFormFieldValid('rol'), 'selectAS': verificacionRol }) : ''}
                        disabled={!value}
                     />
                     {/* Muestra un mensaje de error producido por la falta de datos */}
                     {value ? getFormErrorMessage('rol') : <></>}
                  </div>

                  <div className="flex flex-1 w-full flex-column gap-2">
                     <div className="flex flex-1 w-full gap-2">
                        {user ? <>
                           <div>
                              <p>Sexo</p>
                              <Dropdown
                                 id="sexo_id"
                                 name="sexo_id"
                                 value={formik.values.sexo_id}
                                 onChange={formik.handleChange}
                                 options={sexos}
                                 optionLabel="nombre"
                                 optionValue="id"
                                 placeholder="Selecciona tu sexo"
                                 className={classNames({ 'p-invalid': isFormFieldValid('sexo_id') })}
                              />
                              {/* Muestra un mensaje de error producido por la falta de datos */}
                              {getFormErrorMessage('sexo_id')}
                           </div>

                           <div>
                              <p>Fecha de nacimiento</p>
                              <InputCalendar
                                 id="fecha_nacimiento"
                                 name="fecha_nacimiento"
                                 value={formik.values.fecha_nacimiento}
                                 onChange={formik.handleChange}
                              />
                           </div></> : <></>}
                     </div>
                  </div>
               </div>
            </Dialog>
         </form>
      </div>
   );
}
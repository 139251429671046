import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';

const NuevoFirmaHuella = ({ visiblePdf, setVisiblePdf, url }) => {
   const dialogStyle = {
      width: '80%', // Ancho del popup, puedes ajustarlo según tus necesidades
      minWidth: '400px', // Ancho mínimo del popup
   };

   const PdfConsentimiento = () => {
      return (
         <embed
            src={url}
            style={{ width: "100%", height: "65vh" }}
            title="PDF Viewer"
         ></embed>
      )
   }


   return (
      <Dialog
         visible={visiblePdf}
         onHide={() => setVisiblePdf()}
         header={
            <div className='flex flex-wrap' style={{ color: "#6366F1" }}>
               <i className="pi pi-download" style={{ fontSize: "1.5rem", marginRight: "10px" }}></i>
               <h1 style={{ color: "#6366F1" }}>Descargar Archivo</h1>
            </div>
         }
         style={dialogStyle} // Aplicar estilo al popup para ajustar el tamaño
      >
         <div className="flex flex-column gap-3">
            <p className="cred">
               {" "}
               En esta sección usted puede cargar los datos personales.
            </p>

            {/* Mostrar el visor de PDF */}
            <div className="pdf-viewer">
               <PdfConsentimiento />
            </div>
         </div>
      </Dialog>
   )
}

export default NuevoFirmaHuella;

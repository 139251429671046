import React from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";

//Componentes generales
import AuthUser from "../../../AuthUser";
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice";
import { Footer, Header } from "../../Pure/SeccionesNavegacion";

export default function ModalCreatePuestos({
  visibleCreate,
  setVisibleCreate,
  showToast,
  api,
  tipoRiesgos,
  getCargos,
  dispatch
}) {

  const { http } = AuthUser();

  const initialValues = {
    id: 0,
    nombre: "",
    tipo_riesgo_id: "",

  };

  //Mensajes de error ante ausencia de información en los formularios
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const formik = useFormik({
    initialValues: initialValues,//Raíz
    onSubmit: async (data) => {
      //Array de creación con los datos del cargo
      let data_send = {
        nombre: data.nombre,
        tipo_riesgo_id: data.tipo_riesgo_id.id
      }
      //Inicia la carga
      dispatch(startLoading());
      try {
        const response = await http.post(api, data_send)
        if (response?.data?.resp) {
          await Promise.all([getCargos()])
          HideDialogCreate();
          showToast("success", "Personal creado", "Se creo correctamente el personal");
        } else if (response?.data?.error) {
          showToast("error", "Error", response.data.error)
        }
      } catch (error) {
        console.error("Error de creación", error)
        HideDialogCreate();
        showToast("error", "Error de creación", "Contáctese con Soporte Técnico.")
      } finally {
        //Finaliza la carga
        dispatch(endLoading());
      }
    },
    validate: (data) => {
      let errors = {};
      if (!data.nombre) {
        errors.nombre = "Ingrese el nombre del puesto"
      }
      if (!data.tipo_riesgo_id) {
        errors.tipo_riesgo_id = 'Seleccione el riesgo'
      }
      return errors
    }
  });

  const HideDialogCreate = () => {
    setVisibleCreate(false);//Cierra el pop up
    formik.resetForm();//Resetea el array
  };

  const header = (<Header
    piIcon={"pi pi-briefcase"}
    titulo={'Crear puesto de trabajo'}
    subtitulo={"En esta sección usted puede registrar los puestos de trabajo."} />)

  const paqueteCreateDialogFooter = (<Footer
    type={'submit'}
    label={'Crear'}
    onClickCancelar={() => HideDialogCreate()} />);

  return (
    <div>
      <form
        id="persona-form"
        className="persona-form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleCreate}
          style={{ width: "450px", height: "auto", borderRadius: '25px' }}
          appendTo={document.getElementById("persona-form")}
          header={header}
          footer={paqueteCreateDialogFooter}
          dismissableMask
          className="p-fluid "
          onHide={() => {
            HideDialogCreate();
          }}
        >
          <div>
            <div className="flex flex-column w-full">
              <div style={{ margin: "15px 0px", flexWrap: "wrap" }} className="flex w-full flex-column">
                <p style={{ margin: "0px 0px 8px 0px" }} className="per1">Puesto de Trabajo</p>
                <InputText
                  id="nombre"
                  name="nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  className={classNames({ 'p-invalid': isFormFieldValid('nombre') })}
                />
                {getFormErrorMessage('nombre')}
              </div>
              <div style={{ margin: "15px 0px" }} className="flex w-full flex-column">
                <p>Potencial de Riesgo</p>
                <Dropdown
                  id="tipo_riesgo_id"
                  name="tipo_riesgo_id"
                  value={formik.values.tipo_riesgo_id}
                  onChange={(e) => { formik.setFieldValue("tipo_riesgo_id", e.value) }}//Actualiza campo tipo_riesgo_id
                  options={tipoRiesgos}
                  optionLabel="nombre"//Muestra las opciones según el nombre correspondiente
                  placeholder="Seleccionar Tipo de Riesgo"
                  className={classNames({ 'p-invalid': isFormFieldValid('tipo_riesgo_id') })}
                />
                {getFormErrorMessage('tipo_riesgo_id')}
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
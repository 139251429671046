import React from 'react';
// import "../../../Style/CSSBienvenido.css"
import '../../../Style/EstilosSoporteBregma/BienvenidosAHP.css'
export default function Bienvenidos() {
  return (
    <div className="ContenedorPrincipal_Biene">
      <div className="sub_Contenedor_Bien01">
        <h2 className='titulo_cuadro_bienvenido'>Bienvenidos</h2>
        <h4 className='subtitulo_cuadro_bienvenido'>Administardor bregma</h4>
        <p className='descripcion_cuadro_bienvenido'style={{margin:'8px 0px', textAlign:'center'}}>Si cuenta con alguna duda en el uso del servicio, por favor comuníquese con nosotros.</p>
        <div className='cuadro_icono_bienvenido_check' style={{marginBottom:'8px'}}>
            <img 
              width="22px"
              src="https://i.ibb.co/xsyVHcK/Icon.png"
            />
        </div>
        <div className="contenedor_Recomendacione_bienvenido" style={{marginBottom:'8px'}}>
          <i className="pi pi-check font-bold text-sm text-white bg-indigo-500 p-1 border-3 border-indigo-100 border-circle shadow-1" ></i>
          <p className='descripcion_cuadro_bienvenido'>Preguntanos  a través de nuestros canales.</p>
        </div>
        <div className="contenedor_Recomendacione_bienvenido" style={{marginBottom:'8px'}}>
          <i className="pi pi-check font-bold text-sm text-white bg-indigo-500 p-1 border-3 border-indigo-100 border-circle shadow-1" ></i>
          <p className='descripcion_cuadro_bienvenido'>Edita la información de tus contactos.</p>
        </div>
        <div className="contenedor_Recomendacione_bienvenido">
          <i className="pi pi-check font-bold text-sm text-white bg-indigo-500 p-1 border-3 border-indigo-100 border-circle shadow-1" ></i>
          <p className='descripcion_cuadro_bienvenido'>Agrega nuevos tutoriales.</p>
        </div>
      </div>
      <div className="sub_Contenedor_Bien02 relative">
        <img className='imagen_base_contr instituo'src="https://i.ibb.co/Mh5PmL3/Hospital2-1.png"/>
        <img className='imagen_base_contr doctora absolute' src="https://i.ibb.co/KGM9qxj/0032.png" alt="" />
        <img className='imagen_base_contr maletin absolute' src="https://i.ibb.co/998N0N5/maletin-negocios-3dl-1.png" alt="" />
      </div>
    </div>
  );
}
import React from 'react';

const PageRAHistorial = () => {
  return (


      <header className="flex align-items-center gap-2">
        <i className="pi pi-book"/>
        <h1>Historiales</h1>
      </header>
  );
};

export default PageRAHistorial;
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";

export default function ModalPsiExpLabEliminar({ eliminarExperiencia, setVisibleDelete, visibleDelete }) {

  //Cuerpo de footer del dialog

  const footer = () => (
    <>
      <div className="flex justify-content-between">
        <Button
          label="Cancelar"
          className="p-button p-component delete w-full"
          onClick={() => setVisibleDelete(false)}
        />
        <Button
          label="Eliminar"
          className="p-button-success w-full"
          style={{
            color: "#5555D8",
            backgroundColor: "#EBEBFF",
            borderColor: "#5555D8",
          }}
          onClick={() => eliminarExperiencia()}
        /></div>
    </>
  );

  return (
    <Dialog
      visible={visibleDelete}
      style={{ width: "450px", height: "220px", margin: "20px" }}
      className="p-fluid"
      header={<p style={{ color: "#5555D8", fontSize: "32px", marginBottom: "15px" }}>Eliminar</p>}
      footer={footer}
      onHide={() => setVisibleDelete(false)}
    >
      <p style={{ fontSize: "20px" }}>¿Está seguro que desea eliminar?</p>
    </Dialog>

  )
}
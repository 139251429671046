import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
import InputCalendar from '../../../Components/form/InputCalendar';
import CargarImagen from "../../../Images/atencion/Recepcion/CargarImagen.svg";
import trash from "../../../Images/atencion/Recepcion/trash.svg";
import medical from "../../../Images/atencion/Recepcion/medical.svg";
import persona from "../../../Images/atencion/Recepcion/persona.svg";
import check from "../../../Images/atencion/Recepcion/check.svg";
import cartera from "../../../Images/atencion/Recepcion/cartera.svg";
import camera from "../../../Images/atencion/Recepcion/Camera.svg";
import Webcam from "react-webcam";
import AuthUser from "../../../AuthUser";
// import RAResultadosTable from "../Resultados/RAResultadosTable";

const DatosPaciente = ({ isSaved, setIsSaved }) => {
   const [selectDoc, setselectDoc] = useState(null);
   const { http } = AuthUser();

   const [departamento, setDepartamento] = useState(null);
   const [departamentos, setDepartamentos] = useState([]);
   const [provincia, setProvincia] = useState(null);
   const [provincias, setProvincias] = useState([]);
   const [distrito, setDistrito] = useState(null);
   const [distritos, setDistritos] = useState([]);
   const [selectedDistrict, setSelectedDistrict] = useState(null);

   const docs = [
      { name: "DNI", code: "0" },
      { name: "RUC", code: "1" },
   ];

   const webcamRef = useRef(null);
   const [capturedImage, setCapturedImage] = useState(null);
   const handleCapture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
   };
   const getAllUbicaciones = () => {
      http
         .get("/ubicacion")
         .then((response) => {
            setDepartamentos(response.data.data);
         })
         .catch((error) => {
            console.error(error);
         });
   };
   useEffect(() => {
      getAllUbicaciones();
   }, []);
   const handleChangeDepartamento = (departamentoId) => {
      const selectedDepartamento = departamentos.find(
         (dep) => dep.id === departamentoId
      );
      setProvincias(
         selectedDepartamento ? selectedDepartamento.provincias : []
      );
      setDistritos([]);
      setDepartamento(selectedDepartamento);
   };

   const handleChangeProvincia = (provinciaId) => {
      const selectedProvincia = provincias.find(
         (prov) => prov.id === provinciaId
      );
      setDistritos(selectedProvincia ? selectedProvincia.distritos : []);
      setSelectedDistrict(null);
   };
   const handleChangeDistrito = (distritoId) => {
      const selectedDistrito = distritos.find(
         (dist) => dist.distrito_id === distritoId
      );

      setSelectedDistrict(selectedDistrito);
   };

   const getAllProvincias = () => {
      if (departamento) {
         const departamentoId = departamento.id;
         http
            .get(`/ubicacion/provincias/${departamentoId}`)
            .then((response) => {
               setProvincias(response.data.data);
            })
            .catch((error) => {
               console.error(error);
            });
      }
   };
   useEffect(() => {
      getAllProvincias();
   }, [departamento]);

   const [selectedImages, setSelectedImages] = useState([]);
   const handleImageUpload = (selectedFiles) => {
      const uploadedImages = [];

      for (let i = 0; i < selectedFiles.length; i++) {
         const file = selectedFiles[i];

         if (file.type.startsWith("image/")) {
            const imageUrl = URL.createObjectURL(file);
            uploadedImages.push(imageUrl);
         }
      }

      setSelectedImages(uploadedImages);
   };

   const [selectSexo, setselectSexo] = useState(null);
   const sexo = [
      { name: "Masculino", code: "0" },
      { name: "Femenino", code: "1" },
   ];
   const [inputNumDoc, setinputNumDoc] = useState("");
   const [inputNumCod, setinputNumCod] = useState("");
   const [imputApellidos, setimputApellidos] = useState("");
   const [imputNombres, setimputNombres] = useState("");
   const [imputNumSegSoc, setimputNumSegSoc] = useState("");
   const [imputEdad, setimputEdad] = useState("");
   const [imputRel, setimputRel] = useState("");
   const [date, setDate] = useState(null);

   const [showCamera, setShowCamera] = useState(false);
   const handleCameraToggle = () => {
      if (showCamera) {
         const imageSrc = webcamRef.current.getScreenshot();
         setCapturedImage(imageSrc);
      } else {
         setCapturedImage(null);
      }
      setShowCamera(!showCamera);
   };

   return (
      <div className="flex flex-nowrap flex-row gap-3">
         <div className="flex flex-wrap flex-colum gap-6">
            <div className="flex flex-wrap flex-column gap-3">
               {/* <div>
                  <p className="subtituloR">Datos de Afiliación</p>
               </div> */}

               <div className="contenedorD flex flex-wrap flex-row p-3 gap-3">
                  <div className="flex flex-wrap flex-row gap-3 w-full">
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Tipo de Documento</p>
                        <Dropdown
                           value={selectDoc}
                           onChange={(e) => setselectDoc(e.value)}
                           options={docs}
                           optionLabel="name"
                           className="ingresoR"
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Nro. de Documento</p>
                        <InputText
                           value={inputNumDoc}
                           onChange={(e) => setinputNumDoc(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Nro. de Código</p>
                        <InputText
                           value={inputNumCod}
                           onChange={(e) => setinputNumCod(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-row gap-3 w-full">
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Apellidos Completos</p>
                        <InputText
                           value={imputApellidos}
                           onChange={(e) => setimputApellidos(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Nombres Completos</p>
                        <InputText
                           value={imputNombres}
                           onChange={(e) => setimputNombres(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Nro. de Seguro Social</p>
                        <InputText
                           value={imputNumSegSoc}
                           onChange={(e) => setimputNumSegSoc(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                  </div>
               </div>
            </div>
            <div className="flex flex-wrap flex-column gap-3 w-full">
               <div>
                  <p className="subtituloR">Datos de Nacimiento</p>
               </div>

               <div className="flex flex-wrap flex-column p-3 gap-3">
                  <div className="flex flex-wrap flex-row gap-3">
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Sexo</p>
                        <Dropdown
                           value={selectSexo}
                           onChange={(e) => setselectSexo(e.value)}
                           options={sexo}
                           optionLabel="name"
                           placeholder=""
                           className="ingresoR"
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Fecha de nacimiento</p>
                        {/* <Calendar
                           value={date}
                           onChange={(e) => setDate(e.value)}
                           showIcon
                        /> */}
                        <InputCalendar value={date} onChange={(e) => setDate(e.value)} showIcon placeholder="." />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Edad</p>
                        <InputText
                           value={imputEdad}
                           onChange={(e) => setimputEdad(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Religión</p>
                        <InputText
                           value={imputRel}
                           onChange={(e) => setimputRel(e.target.value)}
                           className="ingresoR"
                           readOnly
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-row gap-3">
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">País</p>
                        <Dropdown
                           value={selectDoc}
                           onChange={(e) => setselectDoc(e.value)}
                           options={docs}
                           optionLabel="name"
                           placeholder=""
                           className="ingresoR"
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Departamento</p>
                        <Dropdown
                           value={departamento}
                           options={departamentos}
                           onChange={(e) => handleChangeDepartamento(e.value)}
                           placeholder={
                              departamento
                                 ? departamento.departamento
                                 : "Seleccionar Departamento"
                           }
                           optionLabel="departamento"
                           optionValue="id"
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-row gap-3">
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Provincia</p>
                        <Dropdown
                           value={provincia}
                           options={provincias}
                           onChange={(e) => {
                              setProvincia(e.value);
                              handleChangeProvincia(e.value);
                           }}
                           placeholder="Seleccionar Provincia"
                           optionLabel="provincia"
                           optionValue="id"
                        />
                     </div>
                     <div className="flex flex-wrap flex-column gap-3 flex-1">
                        <p className="datos">Distrito</p>
                        <Dropdown
                           value={selectedDistrict}
                           options={distritos}
                           onChange={(e) => handleChangeDistrito(e.value)}
                           placeholder={
                              selectedDistrict
                                 ? selectedDistrict.distrito
                                 : "Seleccionar Distrito"
                           }
                           optionLabel="distrito"
                           optionValue="distrito_id"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="flex flex-row flex-nowrap gap-3">
            <div>
               <div className="flex flex-wrap flex-column gap-2 pt-5">
                  <input
                     type="file"
                     id="fileInput"
                     accept="image/*"
                     style={{ display: "none" }}
                     onChange={(e) => handleImageUpload(e.target.files)}
                  />
                  <Button
                     type="button"
                     className="flex flex-nowrap flex-row gap-3 boton003 justify-content-center"
                     style={{
                        border: "1px solid #00A15C",
                        borderRadius: "5px",
                        padding: "5px",
                     }}
                     onClick={() => {
                        const fileInput = document.getElementById("fileInput");
                        if (fileInput) {
                           fileInput.click();
                        }
                     }}
                  >
                     <img src={CargarImagen} alt="" />
                  </Button>

                  <Button
                     type="button"
                     className="flex flex-nowrap flex-row gap-3 boton003 justify-content-center"
                     style={{
                        border: "1px solid #5555D8",
                        borderRadius: "5px",
                        padding: "5px",
                     }}
                     onClick={() => {
                        setShowCamera((prevShowCamera) => !prevShowCamera);
                     }}
                  >
                     <img src={camera} alt={showCamera ? "Cerrar Cámara" : "Abrir Cámara"} />
                  </Button>

                  <Button
                     type="button"
                     className="flex flex-nowrap flex-row gap-3 boton003 justify-content-center"
                     style={{
                        border: "1px solid #FF6767",
                        borderRadius: "5px",
                        padding: "5px",
                     }}
                  >
                     <img src={trash} alt="" />
                  </Button>
               </div>
            </div>
            <div className="flex flex-wrap flex-column gap-4 pt-5">
               <div
                  style={{
                     background: "rgba(96, 96, 96, 0.161)",
                     borderRadius: "17px",
                  }}
                  className="flex flex-wrap justify-content-center p-2 gap-1"
               >
                  <div className="flex flex-wrap justify-content-center">
                     {isSaved &&
                        selectedImages.map((imageUrl, index) => (
                           <img
                              key={index}
                              src={imageUrl}
                              alt={`Imagen ${index}`}
                              width={100}
                              height={100}
                           />
                        ))}
                     {showCamera && (
                        <div className="App">
                           <Webcam
                              ref={webcamRef}
                              style={{
                                 border: "1px solid #00A15C",
                                 borderRadius: "5px",
                                 width: "100px",
                                 height: "100px",
                              }}

                           />
                           <button onClick={handleCapture}><img src={camera} alt="" /></button>
                        </div>
                     )}
                  </div>
               </div>

               <div className="datosGenerales flex flex-wrap flex-column gap-3 pb-3">
                  <div
                     className="flex flex-xolumn flex-wrap gap-4 pl-1 p-2"
                     style={{
                        background: "#E8E8FF",
                        borderRadius: "10px 10px 0px 0px",
                     }}
                  >
                     <img src={check} alt="" />
                     <p className="TextoDatosGeneralesR">Datos Generales</p>
                  </div>
                  <div className="flex flex-xolumn flex-wrap gap-3">
                     <p className="DatosR" >Nelson Días Flores</p>

                     <div className="flex flex-wrap flex-row gap-4 pl-1" >
                        <img src={cartera} alt="" />
                        <p>Puesto ocupacional</p>
                     </div>
                     <p className="DatosR">Conductor</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default DatosPaciente;

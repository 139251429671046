import React, { useContext } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { AudContext } from '../../../AudContext';

export default function Eliminar({ index, visible, setVisible }) {

   const { principalContext, setPrincipalContext } = useContext(AudContext)

   const hideDialog = () => {
      setVisible(false)
   }

   const handleDelete = () => {
      let aux_prev = [...principalContext.audiograma];
      aux_prev.splice(index, 1);
      setPrincipalContext({
         ...principalContext,
         audiograma: aux_prev
      });
      hideDialog();
   }

   const DataDialogFooter = (
      <React.Fragment>
         <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-button-danger"
            onClick={hideDialog}
         />
         <Button
            label="Eliminar"
            icon="pi pi-check"
            className="p-button-success"
            onClick={() => {
               handleDelete();
            }}
         />
      </React.Fragment>
   );


   return (
      <div>
         <Dialog
            visible={visible}
            style={{ width: "450px" }}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            closable={false}
            draggable={false}
            header={
               <>
                  <i className="pi pi-briefcase icon-create-proveedor" style={{ marginRight: '.5em' }}></i>
                  <span>Eliminar Información</span>
               </>
            }
            modal
            className="p-fluid"
            footer={DataDialogFooter}
            onHide={hideDialog}
         >
            <span>¿Está seguro que desea eliminar?</span>
         </Dialog>
      </div>
   )
}

import React, { useRef, useState, } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";

//Componentes generales
import AuthUser from '../../../../AuthUser';
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { Footer, Header } from '../../../Pure/SeccionesNavegacion';

export default function ModalCredenciales({
  visibleCredenciales,
  setVisibleCredenciales,
  personal,
  showToast,
  idPersonal,
  api
}) {
  const dispatch = useDispatch();
  let toastRef = useRef(null);
  const { http } = AuthUser()

  const [correoSeleccionado, setCorreoSeleccionado] = useState(false);

  //Envío de credenciales
  const handleEnviarCredenciales = async () => {
    dispatch(startLoading());
    try {
      const response = await http.post(`${api}/${idPersonal}`);
      if (response?.data?.message) {
        showToast('success', 'Éxito', response?.data?.message)
        setVisibleCredenciales(false)
      } else if (response?.data?.error) {
        showToast('error', 'Error', response.data.error)
      }
    } catch (error) {
      console.error('Error al enviar credenciales', error)
      showToast('error', 'Error', 'Contactar a soporte técnico');
    } finally {
      dispatch(endLoading());
    }
  };

  //Función para ocultar dialog
  const HideDialogCredenciales = () => {
    setVisibleCredenciales(false);
    setCorreoSeleccionado(false)
  };

  //Cabecera y pie de dialog
  const header = (<Header piIcon={'pi pi-id-card'} titulo={"Envío de credenciales"}/>)

  const footer = (<Footer
    onClickCancelar={() => HideDialogCredenciales(false)}
    onClickEnviar={() => {
      if (correoSeleccionado && personal?.persona?.correo) {
        handleEnviarCredenciales();
      } else if (!correoSeleccionado) {
        showToast('error', 'Elija un medio de envío')
      } else {
        showToast('error', 'Error al enviar credenciales', 'Correo no registrado')
      }
    }}
    label={'Enviar'} />)

  return (
    <Dialog
      visible={visibleCredenciales}
      header={header}
      footer={footer}
      style={{ width: '413px', heigth: '403px', borderRadius: '10px' }}
      onHide={() => HideDialogCredenciales()}
    >
      <div className="flex flex-column gap-3">
        <p className="cred">Enviar las credenciales a través de:</p>
        <div className="flex flex-1 w-full flex-column gap-2">
          {/* Botón para seleccionar el envío de credenciales por correo */}
          <Button
            label="Correo"
            className={`email ${correoSeleccionado ? 'bg-primary' : ''}`} // Clase dinámica basada en el estado de selección
            icon="pi pi-envelope"
            onClick={() => setCorreoSeleccionado(!correoSeleccionado)} // Evento de clic para alternar la selección
          />
        </div>
        <div className="flex flex-1 w-full flex-column">
          <Button
            label="Whatsapp"
            className="wsp"
            icon="pi pi-whatsapp"
            disabled
          />
        </div>
        <Toast ref={toastRef} />
      </div>
    </Dialog>
  );
}

import React, { createContext, useState } from 'react';


export const PsicologiaContext = createContext(undefined)

export const PsicologiaProvider = (props) => {

   const [datOcupContext, setDatOcupContext] = useState({
      psi_motivo_evaluacion_id: null,
      motivo_evaluacion: "Evaluación Pre Ocupacional",
      psi_principales_riesgos_id: 2,
      principales_riesgos: "No refiere",
      psi_medidas_seguridad_id: 2,
      medidas_seguridad: "Uso correcto de los EPPS",
      historia_familiar_id: null,
      historia_familiar: "Soltero, vive con sus padres y hermanos",
      accidentes_enfermedades_id: null,
      accidentes_enfermedades: "No refiere",
      habitos_id: null,
      habitos: "Toma bebidas alcohólicas (botellas de cerveza aproximadamente) en compromisos sociales y fuma aproximadamente cigarros en su tiempo libre",
      observaciones_id: null,
      observaciones: "Ninguna",
      otras_observaciones: null,
   });
   const [expLabContext, setExpLabContext] = useState([]);
   const [exaMenContext, setExaMenContext] = useState({
      presentacion: "1",
      postura: "1",
      ritmo: "3",
      tono: "2",
      articulacion: "2",
      tiempo: "1",
      espacio: "1",
      persona: "1",
      coordinacion_visomotriz: "2",
   });
   const [proCogContext, setProCogContext] = useState({
      lucido_atento: 'Selectivo y sostenido',
      pensamiento: 'Racional',
      percepcion: 'Multisensorial',
      psi_memoria_id: [3],
      psi_inteligencia_id: 4,
      apetito: 'Adecuado',
      suenno: 'Ciclo REM adecuado',
      personalidad: 'Tendencia a la extroversión',
      afectividad: 'Tendencia a la estabilidad',
      conducta_sexual: 'Orientado a su rol de género'
   });
   const [pruPsiContext, setPruPsiContext] = useState({
      pruebas: []
   });
   const [pregContext, setPregContext] = useState({
      evaluacion_emocional: "2", 
      evaluacion_organica: "2",
      evaluacion_psicopatologica: "2",
      sano_mentalmente: "1",
      test_fatiga: "1",
      test_somnolenda: "1",
      tipo_estres: "1",
      comentario_ep: '',
      comentario_eo: '',
      comentario_ee: '',
      comentario_sm: '',
      comentario_te: '',
      comentario_ts: '',
      comentario_tf: ''
   });

   /* const [comPregContext, setComPregContext] = useState({
      comentario_ee: '',
      comentario_eo: '',
      comentario_ep: '',
      comentario_sm: '',
      comentario_tf: '',
      comentario_ts: '',
      comentario_te: ''
   }) */

   const [diagContext, setDiagContext] = useState({
      area_cognitiva: '',
      area_emocional: '',
      recomendaciones: '',
      resultado_id: ''
   });

   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState('');

   return (
      <PsicologiaContext.Provider
         value={{
            datOcupContext, setDatOcupContext,
            expLabContext, setExpLabContext,
            exaMenContext, setExaMenContext,
            proCogContext, setProCogContext,
            pruPsiContext, setPruPsiContext,
            pregContext, setPregContext,
            diagContext, setDiagContext,
            atencionStatus, setAtencionStatus,
            timeStart, setTimeStart,
         }}
      >
         {props.children}
      </PsicologiaContext.Provider>
   );
};
import React from 'react';
import ContabilidadComponent from '../../Components/Contabilidad/Contabilidad';
import '../../Style/contabilidad.css'

const PageContabilidad = ({accesoProp}) => {
    return (
        <div className='flex flex-wrap flex-column h-full'>
            <ContabilidadComponent accesoProp={accesoProp} />
        </div>
    );
}

export default PageContabilidad;

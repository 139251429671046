import React , {useState}from "react";
import TablaCrud from "../../Components/Table/TablaCrud";
import { Column } from "primereact/column";
import ModalOdontograma from "./ModalOdontograma";
const TablaOdontograma =()=>{

  const [visibleCrear, setVisibleCrear] = useState(false);


    return(

        <div>
<TablaCrud
        name="Personal"
        tableNombre={`Lista de Personal`}
        descripcion={`A continuación, se visualiza la lista de personal.`}
        onClickLeftButton1={() => {
          setVisibleCrear(true)
        }}
        leftLabelButton1={" Crear"}
      
      >
        <Column field={""} header="Ítem"></Column>
        <Column field={""} header="Fecha"></Column>
        <Column field={""} header="Nro"></Column>
        <Column field={""} header="Ausente"></Column> 
        <Column field={""} header="Prótesis"></Column>
        <Column field={""} header="Est. Bucal"></Column>
        <Column field={""} header="Encías"></Column>
        <Column field={""} header="Oclusión"></Column>
      </TablaCrud>
<ModalOdontograma

setVisibleCrear={setVisibleCrear}
                visibleCrear={visibleCrear}

/>
        </div>
    )
}

export default TablaOdontograma;
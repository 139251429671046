import React, { useState, useContext, useRef, useEffect } from 'react'
import VoiceButton from '../../Components/ButtonVoice/VoiceButton';
import EnvioArchivoMedGeneral from '../../Components/MedicinaGeneral/EnvioArchivoMedGeneral';
import { RadioButton } from 'primereact/radiobutton';
import { RAContext } from '../RutaAtencion/RAContext';
import { MGContext } from './MGProvider';

export const EvaluacionNeurologica = () => {
    let { stateAtencion, pageViewContext } = useContext(RAContext);
    const { EvNeurologica, setEvNeurologica } = useContext(MGContext)
    const [radio1, setRadio1] = useState(EvNeurologica && EvNeurologica.nistagmus_espontaneo ? EvNeurologica.nistagmus_espontaneo : 'Ausente');
    const [radio2, setRadio2] = useState(EvNeurologica && EvNeurologica.nistagmus_posicion ? EvNeurologica.nistagmus_posicion : 'Ausente');
    const [radio3, setRadio3] = useState(EvNeurologica && EvNeurologica.nistagmus_agitacion ? EvNeurologica.nistagmus_agitacion : 'Ausente');
    const [radio4, setRadio4] = useState(EvNeurologica && EvNeurologica.prueba_romberg ? EvNeurologica.prueba_romberg : 'Ausente');
    const [radio5, setRadio5] = useState(EvNeurologica && EvNeurologica.posturografia_dinamica ? EvNeurologica.posturografia_dinamica : 'Normal');
    const [radio6, setRadio6] = useState(EvNeurologica && EvNeurologica.posturografia_estatica ? EvNeurologica.posturografia_estatica : 'Normal');
    const [observaciones, setObservaciones] = useState(EvNeurologica && EvNeurologica.observaciones ? EvNeurologica.observaciones : null);

    const [Archivo, setArchivo] = useState(EvNeurologica.archivo);
    const updateArchivo = (nuevoValor) => {
        setEvNeurologica((prevEvNeurologica) => ({
            ...prevEvNeurologica,
            archivo: nuevoValor
        }));
    }

    useEffect(() => {
        setEvNeurologica({
            ...EvNeurologica,
            nistagmus_espontaneo: radio1,
            nistagmus_posicion: radio2,
            nistagmus_agitacion: radio3,
            prueba_romberg: radio4,
            posturografia_dinamica: radio5,
            posturografia_estatica: radio6,
            observaciones: observaciones
            // archivo: file
        })
    }, [observaciones, radio1, radio2, radio3, radio4, radio5, radio6]);

    return (
        <div>
            <div className=''>
                <div style={{ width: "100%", borderBottom: "3px solid #5CF2F2", marginBottom: "15px" }}>
                    <strong>1. Nistagmus</strong>
                    <div className="flex justify-content-around" style={{ marginTop: "10px" }}>
                        <div className="flex flex-column align-items-flex-end"
                            style={{ width: "auto", paddingLeft: "15%" }}>
                            <p style={{ padding: "10px" }}>Nistagmus Espontáneo</p>
                            <p style={{ padding: "10px" }}>Nistagmus de Posición</p>
                            <p style={{ padding: "10px" }}>Nistagmus de agitación cefálica</p>
                        </div>
                        <div className="flex flex-column" style={{ paddingLeft: "5%" }}>
                            <p style={{ padding: "10px" }}>Presente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="presente"
                                    value="Presente"
                                    onChange={(e) => setRadio1(e.value)}
                                    checked={radio1 === 'Presente'} />
                            </p>
                            <p style={{ padding: "10px" }}>Presente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="presente"
                                    value="Presente"
                                    onChange={(e) => setRadio2(e.value)}
                                    checked={radio2 === 'Presente'} />
                            </p>
                            <p style={{ padding: "10px" }}>Presente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="presente"
                                    value="Presente"
                                    onChange={(e) => setRadio3(e.value)}
                                    checked={radio3 === 'Presente'} />
                            </p>
                        </div>
                        <div className="flex flex-column" style={{ flexGrow: 2, paddingLeft: "5%" }}>
                            <p style={{ padding: "10px" }}>Ausente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="ausente"
                                    value="Ausente"
                                    onChange={(e) => setRadio1(e.value)}
                                    checked={radio1 === 'Ausente'} />
                            </p>
                            <p style={{ padding: "10px" }}>Ausente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="ausente"
                                    value="Ausente"
                                    onChange={(e) => setRadio2(e.value)}
                                    checked={radio2 === 'Ausente'} />
                            </p>
                            <p style={{ padding: "10px" }}>Ausente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="ausente"
                                    value="Ausente"
                                    onChange={(e) => setRadio3(e.value)}
                                    checked={radio3 === 'Ausente'} />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="flex flex-direction-row" style={{ width: "100%", height: "70px", borderBottom: "3px solid #5CF2F2", marginBottom: "15px" }}>
                    <strong>2. Prueba de Romberg</strong>
                    <div className="flex justify-content-center" style={{ paddingRight: "12%", flexGrow: 1 }}>
                        <p style={{ padding: "10px" }}>Presente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <RadioButton inputId=""
                                name="presente"
                                value="Presente"
                                onChange={(e) => setRadio4(e.value)}
                                checked={radio4 === 'Presente'} />
                        </p>
                        <p style={{ marginLeft: "6.5%", padding: "10px" }}>Ausente &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <RadioButton inputId=""
                                name="ausente"
                                value="Ausente"
                                onChange={(e) => setRadio4(e.value)}
                                checked={radio4 === 'Ausente'} />
                        </p>
                    </div>
                </div>

                <div style={{ width: "100%", borderBottom: "3px solid #5CF2F2", marginBottom: "15px" }}>
                    <strong>3. Posturografía</strong>
                    <div className="flex justify-content-around" style={{ marginTop: "10px" }}>
                        <div className="flex flex-column align-items-flex-end"
                            style={{ width: "auto", paddingLeft: "15%", paddingRight: "17%" }}>
                            <p style={{ padding: "10px" }}>Estática</p>
                            <p style={{ padding: "10px" }}>Dinámica</p>
                        </div>
                        <div className="flex flex-column" >
                            <p style={{ padding: "10px" }}>Normal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="normal"
                                    value="Normal"
                                    onChange={(e) => setRadio5(e.value)}
                                    checked={radio5 === 'Normal'} />
                            </p>
                            <p style={{ padding: "10px" }}>Normal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="normal"
                                    value="Normal"
                                    onChange={(e) => setRadio6(e.value)}
                                    checked={radio6 === 'Normal'} />
                            </p>
                        </div>
                        <div className="flex flex-column" style={{ flexGrow: 2, paddingLeft: "5%" }}>
                            <p style={{ padding: "10px" }}>Anormal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="anormal"
                                    value="Anormal"
                                    onChange={(e) => setRadio5(e.value)}
                                    checked={radio5 === 'Anormal'} />
                            </p>
                            <p style={{ padding: "10px" }}>Anormal &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <RadioButton inputId=""
                                    name="anormal"
                                    value="Anormal"
                                    onChange={(e) => setRadio6(e.value)}
                                    checked={radio6 === 'Anormal'} />
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-column w-full gap-5">
                    <div className="flex-1 flex flex-column w-full gap-2">
                        <strong >Observaciones</strong>
                        <VoiceButton
                            value={observaciones}
                            onChange={setObservaciones}
                            placeholder="Ingresa una observación" />
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <EnvioArchivoMedGeneral
                    FuncionSetEnvio={setArchivo}
                    FuncionUpData={updateArchivo}
                    ValorSet={Archivo}
                    DataContext={EvNeurologica.archivo} />
            </div>
        </div>
    )
}

import React from 'react';

const PageRAEstadisticas = () => {
  return (

      <header className="flex align-items-center gap-2">
        <i className="pi pi-chart-bar"/>
        <h1>Estadísticas</h1>
      </header>
  );
};

export default PageRAEstadisticas;
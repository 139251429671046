import React, { useState, useEffect, useContext } from "react";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import img from "../../../src/Images/imagendg1-estees.png";
import img2 from "../../../src/Images/imagendg2-estees.png";
import "./style.css";
// import "../RecursosHumanos/Local/MisLocales";
import AuthUser from "../../AuthUser";
import "../../Style/datosgenerales.css";
import { RecepcionContext } from "./RecepcionTablaFiltro/Components/RecepcionProvider";

function DatosGenerales({
   nombresLocales,
   localSeleccionado,
   onLocalChange,
   selectedRadioButton,
   setSelectedRadioButton,
}) {
   const { http } = AuthUser();

   const {
      datosGenerales,
      setDatosGenerales,
      seleccion,
      setSeleccion,
      seleccionParticular,
      setSeleccionParticular,
      datosGeneralesParticular,
      setDatosGeneralesParticular,
   } = useContext(RecepcionContext);
   const [locales, setLocales] = useState([]);
   const [ingredient, setIngredient] = useState("");
   const { empresa_cargo_id } = useContext(RecepcionContext);
   const [selectEstablecimiento, setSelectEstablecimiento] = useState(null);
   const [selectPuestoTrabajo, setSelectPuestoTrabajo] = useState(null);
   const [OpcionesLocales, setOpcionesLocales] = useState([]);
   const [opcionesLocales, setopcionesLocales] = useState([]);
   const [OpcionesPuestoTrabajo, setOpcionesPuestoTrabajo] = useState([]);
   const [selectTipoE, setselectTipoE] = useState(null);
   const [opcionesprotocolo, setOpcionesProtocolo] = useState([]);
   const [opcionesPerfil, setOpcionesPerfil] = useState([]);


   const GetLocal = () => {
      http
         .get("clinica/recursoshumanos/local/get")
         .then((response) => {
            const localesData = response.data.data;

            const opciones = localesData.map((local) => ({
               label: local.nombre,
               value: local.id,
            }));
            setopcionesLocales(opciones);

            setLocales(localesData);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const GetProtocolo = () => {
      http.get("clinica/paquete/particular/get").then((response) => {
         setOpcionesProtocolo(response?.data?.data);
      });
   };

   useEffect(() => {
      // GetLocales();
      GetLocal();
      GetProtocolo();
   }, []);

   useEffect(() => {
      if (!OpcionesPuestoTrabajo.length && datosGenerales.empresa_cargo_id) {
         const selectedEmpresa = OpcionesLocales.find(
            (emp) => emp.value === datosGenerales.empresa
         );
         if (selectedEmpresa) {
            setOpcionesPuestoTrabajo(selectedEmpresa.cargos || []);
         } else {
            setOpcionesPuestoTrabajo([]);
         }
      }
   }, [
      OpcionesPuestoTrabajo,
      datosGenerales.empresa_cargo_id,
      datosGenerales.empresa,
   ]);

   useEffect(() => {
      if (datosGenerales.empresa_cargo) {
         const selectedCargo = OpcionesPuestoTrabajo.find(
            (cargo) => cargo.value === datosGenerales.empresa_cargo.value
         );

         if (selectedCargo) {
            setSelectPuestoTrabajo(selectedCargo);
            setselectTipoE(null);

            setselectTipoE(selectedCargo.perfil_tipos || []);
         }
      }
   }, [datosGenerales.empresa_cargo, OpcionesPuestoTrabajo]);

   useEffect(() => {
      if (datosGeneralesParticular.protocolo) {
         setOpcionesPerfil(datosGeneralesParticular.protocolo.perfiles);
      }
   }, [datosGeneralesParticular.protocolo]);

   useEffect(() => {
      if (datosGeneralesParticular.protocolo && opcionesPerfil.length) {
         const selectedPerfil = opcionesPerfil.find(
            (perfil) =>
               perfil.value === datosGeneralesParticular.perfil_particular_id
         );

         if (selectedPerfil) {
            setDatosGeneralesParticular({
               ...datosGeneralesParticular,
               perfil_particular: selectedPerfil,
            });
         }
      }
   }, [
      datosGeneralesParticular.protocolo,
      opcionesPerfil,
      datosGeneralesParticular.perfil_particular_id,
   ]);

   return (
      <div>
         <div className="titulodg mb-6">
            <h1 className="">Datos Generales</h1>
         </div>

         {/*Empresa*/}
         <div className="contenedor-datos-generales">
            <div className="ancho-responsive">
               <div className="flex align-items-center justify-content-center mb-3">
                  <RadioButton
                     inputId="ingredient1"
                     name="pizza"
                     value="Cheese"
                     onChange={(e) => {
                        setIngredient(e.value);
                        setSeleccion(true);
                        setSeleccionParticular(false);
                        setSelectedRadioButton(1);
                     }}
                     checked={seleccion}
                  />
                  <label htmlFor="ingredient1" className="ml-2 azul">
                     Empresa
                  </label>
               </div>
               <div>
                  <div className="image-container-2">
                     <div className="subcontainer">
                        <img className="imagen" src={img} alt="" />
                        <div className="ancho-responsive">
                           <h1 className="h1-container">Empresa</h1>
                           <h2 className="h2-container">
                              El paciente proviene <br /> de una compañía o <br />{" "}
                              entidad específica.
                           </h2>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            {/*Particular*/}
            <div className="ancho-responsive">
               <div className="flex align-items-center justify-content-center mb-3">
                  <RadioButton
                     inputId="ingredient2"
                     name="pizza"
                     value="Mushroom"
                     onChange={(e) => {
                        setIngredient(e.value);
                        setSeleccion(false);
                        setSeleccionParticular(true);
                        setSelectedRadioButton(2);
                     }}
                     checked={seleccionParticular}
                  />
                  <label
                     htmlFor="ingredient2"
                     style={{ color: "#7B8794" }}
                     className="ml-2"
                  >
                     Particular
                  </label>
               </div>
               <div className="image-container-2">
                  <div className="subcontainer-2">
                     <img className="imagen" src={img2} alt="" />
                     <div className="ancho-responsive">
                        <h1 className="h1-container-2">Particular</h1>
                        <h2 className="h2-container-2">
                           Disponible <br /> Próximamente
                        </h2>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {
            seleccion && (
               <div
                  className="flex flex-wrap flex-row gap-3 w-full"
                  style={{ marginTop: "30px" }}
               >
                  <div
                     className="flex flex-wrap flex-column gap-3 flex-1"
                     style={{ marginLeft: "20px" }}
                  >
                     <p className="datos">Empresa</p>
                     <div>
                        <Dropdown
                           value={datosGenerales.empresa}
                           onChange={(e) => {
                              setSelectEstablecimiento(e.value);
                              setDatosGenerales({
                                 ...datosGenerales,
                                 empresa: e.value,
                                 empresa_id: e.value,
                              });
                              const selectedEmpresa = OpcionesLocales.find(
                                 (emp) => emp.value === e.value
                              );
                              setOpcionesPuestoTrabajo(
                                 selectedEmpresa.cargos || []
                              );
                           }}
                           options={OpcionesLocales}
                           optionLabel="label"
                           placeholder="Selecciona una empresa"
                           className="w-full"
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                     <p className="datos">Puesto de Trabajo</p>
                     <div>
                        <Dropdown
                           value={datosGenerales.empresa_cargo}
                           onChange={(e) => {
                              setDatosGenerales({
                                 ...datosGenerales,
                                 empresa_cargo_id: e.value.id,
                                 empresa_cargo: e.value,
                              });
                           }}
                           options={OpcionesPuestoTrabajo}
                           optionLabel="nombre"
                           placeholder="Selecciona un puesto de trabajo"
                           className="w-full"
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                     <p className="datos">Tipo de Examen</p>
                     <div>
                        <Dropdown
                           value={datosGenerales.perfil_tipo}
                           onChange={(e) => {
                              const selectedTipo =
                                 selectPuestoTrabajo?.perfil_tipos.find(
                                    (tipo) => tipo.value === e.value
                                 );
                              setDatosGenerales({
                                 ...datosGenerales,
                                 perfil_tipo: e.value,
                                 perfil_tipo_id: e.value.id,
                              });
                              setselectTipoE(e.value);
                           }}
                           options={
                              selectPuestoTrabajo
                                 ? selectPuestoTrabajo.perfil_tipos
                                 : []
                           }
                           optionLabel="nombre"
                           placeholder="Selecciona un tipo de examen"
                           className="w-full"
                           disabled={!selectPuestoTrabajo}
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                     <p className="datos">Locales</p>
                     <div>
                        <Dropdown
                           id="clinica_local_id"
                           options={opcionesLocales}
                           value={datosGenerales.clinica_local_id}
                           onChange={(e) => {
                              setDatosGenerales({
                                 ...datosGenerales,
                                 clinica_local_id: e.value,
                              });
                           }}
                           className="w-full"
                           placeholder="Selecciona un local"
                        />
                     </div>
                  </div>
               </div>
            )
         }
         {
            seleccionParticular && (
               <div
                  className="flex flex-wrap flex-row gap-3 w-full"
                  style={{ marginTop: "30px" }}
               >
                  <div
                     className="flex flex-wrap flex-column gap-3 flex-1"
                  >
                     <p className="datos">Tarifario</p>
                     <div>
                        <Dropdown
                           value={datosGenerales.empresa}
                           onChange={(e) => {
                              setSelectEstablecimiento(e.value);
                              setDatosGenerales({
                                 ...datosGenerales,
                                 empresa: e.value,
                                 empresa_id: e.value,
                              });
                              const selectedEmpresa = OpcionesLocales.find(
                                 (emp) => emp.value === e.value
                              );
                              setOpcionesPuestoTrabajo(
                                 selectedEmpresa.cargos || []
                              );
                           }}
                           options={OpcionesLocales}
                           optionLabel="label"
                           placeholder="Selecciona un servicio"
                           className="w-full"
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                     <p className="datos">Protocolo</p>
                     <div>
                        <Dropdown
                           optionLabel="paquete.nombre"
                           options={opcionesprotocolo}
                           value={datosGeneralesParticular.protocolo}
                           onChange={(e) => {
                              setDatosGeneralesParticular({
                                 ...datosGeneralesParticular,
                                 protocolo: e.value,
                              });
                              setOpcionesPerfil(e.value.perfiles);
                           }}
                           className="w-full"
                           placeholder="Selecciona el protocolo"
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                     <p className="datos">Perfil</p>
                     <div>
                        <Dropdown
                           optionLabel="perfil.nombre"
                           options={opcionesPerfil}
                           value={datosGeneralesParticular.perfil_particular}
                           onChange={(e) => {
                              setDatosGeneralesParticular({
                                 ...datosGeneralesParticular,
                                 perfil_particular_id: e.value.perfil.id,
                                 perfil_particular: e.value,
                              });
                           }}
                           className="w-full"
                           placeholder="Selecciona un perfil"
                        />
                     </div>
                  </div>
                  <div className="flex flex-wrap flex-column gap-3 flex-1">
                     <p className="datos">Locales</p>
                     <div>
                        <Dropdown
                           options={opcionesLocales}
                           value={datosGeneralesParticular.clinica_local_id}
                           onChange={(e) => {
                              setDatosGeneralesParticular({
                                 ...datosGeneralesParticular,
                                 clinica_local_id: e.value,
                              });
                           }}
                           className="w-full"
                           placeholder="Selecciona un local"
                        />
                     </div>
                  </div>
               </div>
            )
         }
      </div >
   );
}

export default DatosGenerales;
import { createContext, useState } from "react";

const salesContext = {
    sales: {},
    setSales: () => { },
};

export const SalesContext = createContext(salesContext);

export function SalesProvider({ children }) {
    //Variable para asignar paquete
    const [sendPaquete, setSendPaquete] = useState(null)


    const [sales, setSales] = useState({
        GCEmpresa: {
            clinica_id: null,
            contrato_id: null,
            tipo_cliente: "",
            numero_documento: "",
            responsable: "", //INPUT
            razon_social: "", //INPUT
            departamento: "",
            provincia: "",
            distrito: "",
            direccion: "",
            ubicacion_mapa: "",
            latitud: -12.0464,
            longitud: -77.0428,
            aniversario: null,
            rubro: "",
            cantidad_trabajadores: "", //INPUT
            actividad: "", //INPUT
            logo: "",
            logoName: "",
            logoType: "",
            logoSize: "",
        },
        GCContacto: {
            contacto_id: "",
            tipo_documento_id: "",            //Sin input pero requerido
            numero_documento: "", //Sin input pero requerido
            nombres: "",
            apellido_paterno: "",
            apellido_materno: "",
            cargo: "",
            fecha_nacimiento: null,
            hobbies: "",
            celular: null,
            telefono: "",
            correo: "",
            comentarios: "",
            foto: "",
            //ESTOS DATOS PUEDEN SER NULOS ES DECIR ENVÍADOS O NO
            // direccion: "Su casa",
            // telefono_emergencia: "1515151515",
            // contacto_emergencia: "1515151515",
            // distrito_id: 1,
            // distrito_domicilio_id: 1,
            // estado_civil_id: 1,
            // religion_id: 1,
            // sexo_id: 1,
            // grado_instruccion_id: 1,
        },
        GCProducto: {
            seleccion: "",
            periodo: "",
            paquetes: [],
            precioReferencial: "",
            precioVenta: "",
        },
        GCCredenciales: {
            proformaFile: "",
            actitudFile: "",
            adicionalFile: "",
        },
        //Clínica Rol
        GVPartDatosPer: {
            //Primer Formulario
            nombresApellidosT: "", //Paciente (titular o menor)
            menorEdadT: false,
            dniT: "", //Paciente (titular o menor)
            fecha_nacimientoT: "", //Paciente (titular o menor)
            fechaT: "", //Paciente (titular o menor)
            //Paciente 2
            nombresApellidosT2: "", //Paciente o Tutor
            menorEdadT2: false, //Paciente o Tutor
            dniT2: "", //Paciente o Tutor
            fecha_nacimientoT2: "", //Paciente o Tutor
        },
        GVPartSeleccionPlan: [],
        GVPartCredenciales: {
            proformaFile: "",
            actitudFile: "",
            adicionalFile: "",
            other: "",
        },
    });

    const updateSales = (newSales) => {
        setSales(newSales);
    };

    const [productType, setProductType] = useState("servicio");
    const [priceType, setPriceType] = useState("precio_mensual");

    const updateProductType = (newType) => {
        setProductType(newType);
    };

    const updatePriceType = (newType) => {
        setPriceType(newType);
    };
    //Controlar checkbox marcados
    const [selectedPackageIndex, setSelectedPackageIndex] = useState(null);

    const [paqueteS3C, setPaqueteS3C] = useState(null);
    const [checkedIndexC, setCheckedIndexC] = useState(null);


    return (
        <SalesContext.Provider
            value={{
                sales, setSales: updateSales,
                sendPaquete, setSendPaquete,
                productType, setProductType,
                priceType, setPriceType,
                selectedPackageIndex, setSelectedPackageIndex,
                paqueteS3C, setPaqueteS3C,
                checkedIndexC, setCheckedIndexC
            }}>
            {children}
        </SalesContext.Provider>
    );
}
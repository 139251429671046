import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { useFormik } from "formik";

import { Footer, Header } from "../../Pure/SeccionesNavegacion";
import Logo from "../../../Images/GroupSVG.svg";

export default function AsignarServicioDialog({
   visibleAsignar,
   setVisibleAsignar,
   item,
   asignarPaquetes,
}) {

   const [tipoExamen, setTipoExamen] = useState()
   const [clinica_id, setClinica_id] = useState()

   //Valores iniciales del formulario
   const initialValues = {
      clinica_local_id: 0,
      fecha: "",
      perfil_tipo_id: {},
      perfil_id: 0,
      empresa_personales: [
         {
            empresa_personal_id: 0,
         },
      ],
      clinica_id: 0,
   };

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: (data) => {
         let data_send = {
            clinica_local_id: data.clinica_local_id?.id,
            fecha: data.fecha,
            perfil_tipo_id: data?.perfil_tipo_id?.id,
            empresa_personales: data.empresa_personales
         }
         if (data.fecha !== "") {
            const fecha = new Date(data.fecha);
            const formattedFecha = fecha.toISOString().split("T")[0];//Formatea la fecha
            data_send.fecha = formattedFecha;//Añade la fecha formateada al array
         }
         asignarPaquetes(data_send);//Envía data seleccionada a la api
         setVisibleAsignar(false);//Cierra el modal
      },
   });

   //Actualización de los valores iniciales
   const updateValues = () => {
      formik.setValues({
         clinica_local_id: 0,
         fecha: "",
         perfil_id: 0,
         perfil_tipo_id: 0,
         empresa_personales: [
            {
               empresa_personal_id: item?.id,
            },
         ],
         clinica_id: 0,
      });
   };

   //Cabecera y pie de dialog
   const header = (<Header icono={Logo} titulo={'Envío de servicios'} subtitulo={'Elige el tipo de examen que corresponda al empleado'} />)

   const footerContent = (<Footer type={'submit'} onClickCancelar={() => setVisibleAsignar(false)} label={'Enviar'}></Footer>)

   useEffect(() => {
      //Actualiza los valores cuando se digita o elige una opción en los input
      updateValues();

      // Establece los tipos de examen
      let Servicio = item?.perfil?.perfil_tipo_items?.map(tipoPerfiles => {
         return tipoPerfiles;
      })
      //Actualiza los tipos de examen con los tipos de perfiles recorridos
      setTipoExamen(Servicio)

      // Establece los IDs de las clínicas
      let Locales = item?.locales?.map(id => {
         return id;
      })
      setClinica_id(Locales)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [item]);// Hook que se ejecuta cuando cambia el item

   return (
      <div className="flex justify-content-center">
         <form
            id="asignar-personal-paquete"
            className="asignar-personal-paquete flex flex-column gap-3"
            noValidate
            onSubmit={formik.handleSubmit}
         />
         <Dialog
            appendTo={document.getElementById("asignar-personal-paquete")}
            visible={visibleAsignar}
            style={{ width: "50vw", maxWidth: "424px" }}
            onHide={() => setVisibleAsignar(false)}
            header={header}
            footer={footerContent}
         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column gap-3 mt-3">
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Perfil:</p>
                  <InputText
                     id="perfil_id"
                     name="perfil_id"
                     value={item?.perfil?.perfil?.nombre}
                     className="w-full "
                     disabled
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Tipo de examen:</p>
                  <Dropdown
                     id="perfil_tipo_id"
                     name="perfil_tipo_id"
                     value={formik.values.perfil_tipo_id}
                     onChange={(e) => { formik.setFieldValue('perfil_tipo_id', e.value) }}
                     options={tipoExamen}
                     optionLabel="nombre"
                     placeholder="Selecciona una examen"
                     className="w-full "
                  />

                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Fecha de Atención:</p>
                  <Calendar
                     value={formik.values.fecha}
                     dateFormat="dd-mm-yy"
                     onChange={(e) => formik.setFieldValue("fecha", e.target.value)}
                  />
                  <p style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>Local:</p>
                  <Dropdown
                     id="clinica_local_id"
                     value={formik.values.clinica_local_id}
                     onChange={formik.handleChange}
                     options={clinica_id}
                     optionLabel="nombre"
                     placeholder="Selecciona un local"
                     className="w-full "
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
}
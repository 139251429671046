import { useState, useRef, useEffect, useContext } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import NCPDatosPersonales from './1NCPDatosPersonales/NCPDatosPersonales';
import NCPSeleccionPlan from './2NCPSeleccionPlan/NCPSeleccionPlan';
import NCPCredenciales from './3NCPCredenciales/NCPCredenciales';
import '../../../../Style/Ventas/Ventas.css'

import StepsV from '../../StepsV/StepsV';

import { SendVentasContext } from '../../SendVentasContext';
import { VentasContext } from '../../VentasContext';
import AuthUser from '../../../../AuthUser';

export default function NClientePart() {
    const { sendVentas } = useContext(SendVentasContext);
    const { setVentas } = useContext(VentasContext);
    const { http } = AuthUser();

    const toast = useRef(null);
    //#region StepsV
    const [items, setItems] = useState([
        {
            label: 'Datos personales',
            estado: false,
        },
        {
            label: 'Selección del plan',
            estado: false,
        },
        {
            label: 'Credenciales',
            estado: false,
        },
    ]);
    const [activeIndex, setActiveIndex] = useState(0);
    const getContent = (index) => {
        switch (index) {
            case 0:
                return <NCPDatosPersonales />;
            case 1:
                return <NCPSeleccionPlan paquetes={paquetes} sendProducto={sendProducto} setSendProducto={setSendProducto} />;
            case 2:
                return <NCPCredenciales />;
            default:
                return null;
        }
    };
    //#region C2
    function DatosC2(dc2) {
        const updatedItems = [...items];
        updatedItems[activeIndex].estado = dc2;
        setItems(updatedItems);
        handleNext();
    }
    //#endregion
    //#region C3
    function DatosC3(dc3) {
        const updatedItems = [...items];
        updatedItems[activeIndex].estado = dc3;
        setItems(updatedItems);
        handleNext();
    }
    //#endregion
    //#region C4
    function DatosC4(dc4) {
        const updatedItems = [...items];
        updatedItems[activeIndex].estado = dc4;
        setItems(updatedItems);
    }
    //#endregion

    function handleBack() {
        const updatedItems = [...items];
        updatedItems[activeIndex].estado = false;
        updatedItems[activeIndex - 1].estado = false;
        setItems(updatedItems);
        setActiveIndex(activeIndex - 1);
    }

    function handleNext() {
        const updatedItems = [...items];
        updatedItems[activeIndex].estado = true;
        setItems(updatedItems);
        setActiveIndex(activeIndex + 1);
    }
    //#endregion

    //#region API General
    //#endregion

    //#region Validación de envios
    const [buttonsDisabled, setButtonsDisabled] = useState(false);

    function validarFormulario(formulario) {
        for (const propiedad in formulario) {
            if (formulario[propiedad] === '') {
                return false;
            }
        }
        return true;
    }
    //#endregion

    //#region GCEmpresa
    const SendFormGCEmpresa = (estado, activeIndex) => {
        if (!estado) return handleNext();

        setButtonsDisabled(estado);
        /*
        if (!validarFormulario(sendVentas.GCEmpresa)) {
            toast.current.show({ severity: 'warn', summary: 'Datos incompletos', detail: 'Rellene el formulario para crear un nuevo cliente, por favor' });
            setTimeout(() => setButtonsDisabled(false), 1500);
            return;
        }*/

        const updatedItems = items.map((item, index) => {
            if (index === activeIndex) return { ...item, estado };
            return item;
        });

        setItems(updatedItems);
        /*const { http } = AuthUser()
        const createEmpresa = (data) => {
            http
                .post('empresa/create', data)
                .then((response) => {
                    
                })
                .catch((error) => {
                    console.error(error);
                })
        }*/
        setTimeout(() => setButtonsDisabled(false), 1500);
    }
    //#endregion

    //#region GCContacto
    const [personas, setPersonas] = useState([
        {
            id: 1,
            nombres: "Eduardo",
            apellido_paterno: "Aquino",
            apellido_materno: "Bellos",
            cargo: "Ventas",
            telefono: "987654321",
            correo: "informe@bregma.com.pe ",
            birthday: new Date('2022-04-24'),
            hobbies: "Leer, etec",
        },
        {
            id: 2,
            nombres: "Christhoper",
            cargo: "Finanzas",
            apellido_paterno: "Aquino",
            apellido_materno: "Bellos",
            cargo: "Marketing",
            telefono: "987654321",
            correo: "informe@bregma.com.pe ",
            birthday: new Date('2022-04-24'),
            hobbies: "Leer, etec",
        },
        {
            id: 3,
            nombres: "Frank",
            cargo: "Marketing",
            apellido_paterno: "Aquino",
            apellido_materno: "Bellos",
            cargo: "Administrador de Bregma",
            telefono: "987654321",
            correo: "informe@bregma.com.pe ",
            birthday: new Date('2022-04-24'),
            hobbies: "Leer, etec",
        },
        {
            id: 4,
            nombres: "Enrique",
            cargo: "Ventas",
            apellido_paterno: "Aquino",
            apellido_materno: "Bellos",
            cargo: "Administrador de Bregma",
            telefono: "987654321",
            correo: "informe@bregma.com.pe ",
            birthday: new Date('2022-04-24'),
            hobbies: "Leer, etec",
        },
        {
            id: 5,
            nombres: "Carlos",
            cargo: "Marketing",
            apellido_paterno: "Aquino",
            apellido_materno: "Bellos",
            cargo: "Administrador de Bregma",
            telefono: "987654321",
            correo: "informe@bregma.com.pe ",
            birthday: new Date('2022-04-24'),
            hobbies: "Leer, etec",
        },
    ]);

    const [formGCContacto, setFormGCContacto] = useState({
        id: "",
        nombres: "",
        apellido_paterno: "",
        apellido_materno: "",
        cargo: "",
        telefono: "",
        correo: "",
        birthday: "",
        hobbies: "",
    });

    function handleCreate(e) {
        const { name } = e.target || e;

        let value;

        if (e.target) {
            value = e.target.value;
        } else if (e.value instanceof Date) {
            value = e.value.toLocaleDateString();
        } else {
            value = e.value;
        }

        setPersonas({ ...personas, [name]: value });
    }

    //#endregion

    //#region GCProducto
    //CREAN SU CONSTANTE QUE SE ENVIARÁ A LA API (SE PUEDEN GUIAR DE GCEMPRESA)
    const [paquetes, setPaquetes] = useState([
        {
            key: "0",
            data: {
                name: "Node 1",
                description: "110.00",
                pVenta: "120.00",
            },
            children: [
                {
                    key: "0-0",
                    data: {
                        name: "Node 1.1",
                        description: "150.00",
                        pVenta: "160.00",
                    },
                    children: [],
                },
            ],
        },
        {
            key: "1",
            data: {
                name: "Node 2",
                description: "120.00",
                pVenta: "130.00",
            },
            children: [
                {
                    key: "0-1",
                    data: {
                        name: "Node 1.2",
                        description: "130.00",
                        pVenta: "140.00",
                    },
                    children: [
                        {
                            key: "0-1-1",
                            data: {
                                name: "Node 1.2.2",
                                description: "230.00",
                                pVenta: "240.00",
                            },
                            children: [],
                        },
                    ],
                },
            ],
        },
        {
            key: "2",
            data: {
                name: "Node 3",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
        {
            key: "3",
            data: {
                name: "Node 4",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
        {
            key: "4",
            data: {
                name: "Node 5",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
        {
            key: "5",
            data: {
                name: "Node 6",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
        {
            key: "6",
            data: {
                name: "Node 7",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
        {
            key: "7",
            data: {
                name: "Node 8",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
        {
            key: "8",
            data: {
                name: "Node 9",
                description: "130.00",
                pVenta: "140.00",
            },
            children: [],
        },
    ]);
    //VARIABLE DE ENVIO
    const [sendProducto, setSendProducto] = useState({
        seleccion: "",
        periodo: "",
        precioReferencial: "",
        precioVenta: "",
    });

    //#endregion

    //#region GCCredenciales
    const [contactos, setContactos] = useState([
        { id: 1, nombres: 'Contacto 01', seleccionado: false },
        { id: 2, nombres: 'Contacto 02', seleccionado: false },
        { id: 3, nombres: 'Contacto 03', seleccionado: false },
        { id: 4, nombres: 'Contacto 04', seleccionado: false },
        { id: 5, nombres: 'Contacto 05', seleccionado: false },
        { id: 6, nombres: 'Contacto 06', seleccionado: false },
        { id: 7, nombres: 'Contacto 07', seleccionado: false },
        { id: 8, nombres: 'Contacto 08', seleccionado: false },
        { id: 9, nombres: 'Contacto 09', seleccionado: false },
    ]);

    //#region API
    const getAllTipoClientes = () => {
        http.get("tipo/cliente/show")
            .then((response) => {
                const tiposDeClientes = response.data;
                setVentas((ventasActuales) => ({
                    ...ventasActuales,
                    GCEmpresa: {
                        ...ventasActuales.GCEmpresa,
                        tipos_de_clientes: tiposDeClientes
                    }
                }));
            })
            .catch((error) => {
                console.error(error);
            });

    };
    function getAllRubro() {
        http.get("rubro/get")
            .then((response) => {
                const tiposDeClientes = response.data.data;
                setVentas((ventasActuales) => ({
                    ...ventasActuales,
                    GCEmpresa: {
                        ...ventasActuales.GCEmpresa,
                        rubros: tiposDeClientes
                    }
                }));
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        getAllTipoClientes();
        getAllRubro();
    }, []); // Solo se carga la primera vez

    //#endregion

    return (
        <div className="text-sm flex flex-column gap-3 pb-3">
            <Toast ref={toast} />
            <div className="flex gap-3 flex-wrap">
                <div className="flex flex-column gap-3">
                    <p className="text-2xl text-indigo-500 font-bold">Nuevo cliente</p>
                    <p className="text-indigo-300">Registra tu cliente potencial.</p>
                </div>
                <StepsV model={items} activeIndex={activeIndex} />
            </div>

            {getContent(activeIndex)}

            <div className="flex justify-content-end gap-3">
                <Button
                    className={`bg-gray-300 border-gray-300 ${activeIndex === 0 ? 'hidden' : ''}`}
                    onClick={handleBack}
                    icon="pi pi-chevron-left text-gray-500"
                />
                <Button
                    className={`bg-gray-300 border-gray-300 ${activeIndex < items.length - 1 ? '' : 'hidden'}`}
                    onClick={handleNext}
                    disabled={buttonsDisabled}
                    icon="pi pi-chevron-right text-gray-500"
                />
            </div>
        </div>

    );
}
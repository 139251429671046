import React, { useState, useRef } from "react";
import "../../Style/Odontologia/Odontograma.css";
import { TabView, TabPanel } from 'primereact/tabview';
import { Dialog } from "primereact/dialog";
import Odontograma from "./Odontograma";
import Detalles from "./Detalles";


const ModalOdontograma = ({setVisibleCrear, visibleCrear}) => {


    const HideDialogCrear = () => {
        setVisibleCrear(false);
    };

    return(

 <div>

    <Dialog   visible={visibleCrear}
                    style={{
                        width: "80%",
                        height: "95%",
                        borderRadius: "25px",
                      }}
                    onHide={() => {
                        HideDialogCrear();
                      }}
                    >
                      <TabView>
                      <TabPanel header="Grafico">
<Odontograma/>
</TabPanel>

<TabPanel header="Detalle">
<Detalles/>
   </TabPanel>
</TabView>
    </Dialog>
    
 </div>
    )
}

export default ModalOdontograma;
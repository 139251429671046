import React, { useContext, useEffect, useState } from 'react';
import { RAContext } from "../../RutaAtencion/RAContext";
import { PsicologiaContext } from "../PsicologiaContext";
import { Divider } from "primereact/divider";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import '../../../Style/ProcesoCognitivo/psi_pro_cog.css'
import SelectFlechita from '../../../Components/Psicologia/ComponentR/SelectFlechita';
//import ButtonVoiceReciclable from '../../../Components/ButtonVoice/ButtonVoiceReciclable';
import ButtonVoiceReciclable from '../../../Components/ButtonVoice/ButtonVoiceReciclable2';

const PagePsiProCog = () => {

  let { proCogContext, setProCogContext} = useContext(PsicologiaContext)
  let { pageViewContext, stateAtencion  } = useContext(RAContext)

  //Variables de estado
  const [lucido, setLucido] = useState(proCogContext?.lucido_atento ?? '');
  const [pensamiento, setPensamiento] = useState(proCogContext?.pensamiento ?? '');
  const [percepcion, setPercepcion] = useState(proCogContext?.percepcion ?? '');
  const [memoria, setMemoria] = useState(proCogContext?.psi_memoria_id ?? [3]);  
  const [inteligencia, setInteligencia] = useState(proCogContext?.psi_inteligencia_id ?? 4);
  const [apetito, setApetito] = useState(proCogContext?.apetito ?? '');
  const [suenio, setSuenio] = useState(proCogContext?.suenno ?? '');
  const [personalidad, setPersonalidad] = useState(proCogContext?.personalidad ?? '');
  const [afectividad, setAfectividad] = useState(proCogContext?.afectividad ?? '');
  const [conduta, setConduta] = useState(proCogContext?.conducta_sexual ?? '');

  //Opciones para el selectFlechita
  let lucidoOptions = [
    { label: 'Selectivo y sostenido', id: 1 },
    { label: 'Selectivo, sostenido y distribuido', id: 2 },
    { label: 'Voluntaria', id: 3 },
    { label: 'Involuntaria', id: 4 },
    { label: 'Dividida', id: 5 }
  ]

  let pensamOptions = [
    { label: 'Racional', id: 1 },
    { label: 'Racional e inductivo', id: 2 },
    { label: 'Racional y deductivo', id: 3 },
    { label: 'Concreto', id: 4 },
    { label: 'Analítico', id: 5 }
  ]

  let percepOptions = [
    { label: 'Multisensorial', id: 1 },
    { label: 'Visual', id: 2 },
    { label: 'Espacial', id: 3 },
    { label: 'Cenestésica', id: 4 },
    { label: 'Kinestésica', id: 5 }
  ]

  let memoriaOptions = [
    { label: 'Corto plazo', value: 1 },
    { label: 'Mediano plazo', value: 2 },
    { label: 'Largo plazo', value: 3 },
  ]

  let inteligenciaOptions = [
    { label: 'Muy superior', value: 1 },
    { label: 'Superior', value: 2 },
    { label: 'Normal brillante', value: 3 },
    { label: 'N. Promedio', value: 4 },
    { label: 'N. Bajo', value: 5 },
    { label: 'Fronterizo', value: 6 },
    { label: 'RM Leve', value: 7 },
    { label: 'RM Moderado', value: 8 },
    { label: 'RM Severo', value: 9 },
    { label: 'RM Profundo', value: 10 },
    { label: 'No aplica', value: 11 },
  ]

  let apetitoOptions = [
    { label: 'Adecuado', id: 1 },
    { label: 'Variable', id: 2 },
    { label: 'Ausente', id: 3 },
    { label: 'Excesivo', id: 4 }
  ]

  let suenioOptions = [
    { label: 'Ciclo REM adecuado', id: 1 },
    { label: 'Variado (hacia el aumento)', id: 2 },
    { label: 'Variado (hacia la disminución)', id: 3 },
    { label: 'Insomnio', id: 4 },
    { label: 'Pesadillas constantes', id: 5 },
    { label: 'Terrores nocturnos', id: 6 },
    { label: 'Sonambulismo', id: 7 },
    { label: 'Bruxismo', id: 8 }
  ]

  let personalOptions = [
    { label: 'Tendencia a la introversión', id: 1 },
    { label: 'Tendencia a la extroversión', id: 2 },
    { label: 'Patrón del alentador', id: 3 },
    { label: 'Patrón del realizador', id: 4 },
    { label: 'Patrón del perfeccionista', id: 5 },
    { label: 'Patrón del creativo', id: 6 },
    { label: 'Patrón del objetivo', id: 7 },
    { label: 'Patrón del persuasivo', id: 8 },
    { label: 'Patrón del promotor', id: 9 },
    { label: 'Patrón del consejero', id: 10 },
    { label: 'Patrón del agente', id: 11 },
    { label: 'Patrón del evaluador', id: 12 },
    { label: 'Patrón del resolutivo', id: 13 },
    { label: 'Patrón profesional', id: 14 },
    { label: 'Patrón del investigador', id: 15 },
    { label: 'Patrón del orientado a resultados', id: 16 },
    { label: 'Patrón del especialista', id: 17 }
  ]

  let afectividadOptions = [
    { label: 'Tendencia a la estabilidad', id: 1 },
    { label: 'Estable', id: 2 },
    { label: 'Tendencia a la inestabilidad', id: 3 },
    { label: 'Inestable', id: 4 }
  ]

  let conductaOptions = [
    { label: 'Orientado a su rol de género', id: 1, className: 'long-option' },
    { label: 'Orientada a su rol de género', id: 2, className: 'long-option' },
    { label: 'Desorientado(a) y no identificado(a) con su género', id: 3, className: 'long-option' }
  ]  

  //END Opciones para el selectFlechita
  
  //UseEffect para que se actualize los datos de procesos cognitivos

  useEffect(() => {
    setProCogContext({
      [`lucido_atento`]: lucido,
      [`pensamiento`]: pensamiento,
      [`percepcion`]: percepcion,
      [`psi_memoria_id`]: memoria,
      [`psi_inteligencia_id`]: inteligencia,
      [`apetito`]: apetito,
      [`suenno`]: suenio,
      [`personalidad`]: personalidad,
      [`afectividad`]: afectividad,
      [`conducta_sexual`]: conduta
    })
  }, [lucido, pensamiento, percepcion, memoria, inteligencia, apetito, suenio, personalidad, afectividad, conduta])

    // Función para manejar el cambio de selección en el checkbox de memoria
    const handleMemoriaChange = (value) => {
      const selectedIndex = memoria.indexOf(value);
      let updatedMemoria = [...memoria];
  
      if (selectedIndex === -1) {
        // Si el valor no está en el array, lo añadimos
        updatedMemoria.push(value);
      } else {
        // Si el valor ya está en el array, lo eliminamos
        updatedMemoria.splice(selectedIndex, 1);
      }
  
      setMemoria(updatedMemoria);

    };

  return (
    <>
    
      <div className="flex w-full relative gap-1 mb-3">
        <SelectFlechita
          contentClassName={'w-12 flex flex-column gap-2'}
          option={lucidoOptions}
          setvalue={setLucido}
          title="Lucido, atento"
          heights={{ height: "37px" }}
          id
          value={lucido}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        ></SelectFlechita>
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3}  className={'mt-5 input-button'} setInput01={setLucido}/>
      </div>
      
      <div className="flex w-full relative mb-3">
        <SelectFlechita
          contentClassName={'w-12 flex flex-column gap-2'}
          option={pensamOptions}
          setvalue={setPensamiento}
          title="Pensamiento"
          heights={{ height: "37px" }}
          id
          value={pensamiento}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3}  className={'mt-5 input-button'} setInput01={setPensamiento}/>

      </div>

      <div className="flex w-full relative gap-1">
        <SelectFlechita
          contentClassName={'w-12 flex flex-column gap-2'}
          option={percepOptions}
          setvalue={setPercepcion}
          title="Percepción"
          heights={{ height: "37px" }}
          id
          value={percepcion}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3} className={'mt-5 input-button'} setInput01={setPercepcion}/>

      </div>


      <Divider />

      <div>
        <span>Memoria</span>
        <div className="psi_pc_checkbox_label">
          {memoriaOptions?.map((item, index) => {
            return (
              <div className="checkbox_memoria" key={index}>
                <Checkbox 
                  inputId={item.label} 
                  value={item.value} 
                  onChange={(e) => handleMemoriaChange(item.value)}
                  //onChange={(e) => setMemoria(e.value);}
                  checked={memoria.includes(item.value)} 
                  //checked={memoria === item.value}
                  disabled={stateAtencion[pageViewContext].estado ===3}
                />
                <label htmlFor={item.label}>{item.label}</label>
              </div>
            );
          })}
        </div>
      </div>

      <div className='intelligence'>
        <span>Inteligencia</span>

        <div className="psi_pc_radiobutton_label">

          {
            inteligenciaOptions?.map((item, index) => {
              return (
                <div className="radiobutton_intelig" key={index}>
                  <RadioButton value={item?.value} 
                    onChange={e => setInteligencia(e.value)} 
                    checked={inteligencia === item?.value} 
                    disabled={stateAtencion[pageViewContext].estado ===3}
                  />
                  <label>{item?.label}</label>
                </div>
              )
            })
          }

        </div>

      </div>

      <Divider />

      <div className="flex w-full relative gap-1 mb-3">
         <SelectFlechita
          className={'w-full'}
          contentClassName={'w-12 flex flex-column gap-2'}
          option={apetitoOptions}
          setvalue={setApetito}
          title="Apetito"
          heights={{ height: "37px" }}
          value={apetito}
          id
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3}  className={'mt-5 input-button'} setInput01={setApetito}/>
      </div>

      <div className="flex w-full relative gap-1 mb-3">
         <SelectFlechita
          contentClassName={'w-full flex flex-column gap-2'}
          option={suenioOptions}
          setvalue={setSuenio}
          title="Sueño"
          heights={{ height: "37px" }}
          id
          value={suenio}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3}  className={'mt-5 input-button'} setInput01={setSuenio}/>
      </div>

      <div className="flex w-full relative gap-1 mb-3">
         <SelectFlechita
          contentClassName={'w-12 flex flex-column gap-2'}
          option={personalOptions}
          setvalue={setPersonalidad}
          title="Personalidad"
          heights={{ height: "37px" }}
          id
          value={personalidad}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3}  className={'mt-5 input-button'} setInput01={setPersonalidad}/>
      </div>

      <div className="flex w-full relative gap-1 mb-3">
         <SelectFlechita
          contentClassName={'w-12 flex flex-column gap-2'}
          option={afectividadOptions}
          setvalue={setAfectividad}
          title="Afectividad"
          heights={{ height: "37px" }}
          id
          value={afectividad}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3}  className={'mt-5 input-button'} setInput01={setAfectividad}/>
      </div>

      <div className="flex w-full relative gap-1 mb-3">
         <SelectFlechita
          contentClassName={'w-12 flex flex-column gap-2'}
          option={conductaOptions}
          setvalue={setConduta}
          title="Conducta Sexual"
          heights={{ height: "37px" }}
          id
          value={conduta}
          titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
          disabled={stateAtencion[pageViewContext].estado ===3}
        />
        <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado ===3} className={'mt-5 input-button'} setInput01={setConduta}/>
      </div>
    </>
  );
};

export default PagePsiProCog;
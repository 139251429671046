import React from 'react';
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import imagen2 from '../../../Images/img2.png';
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Business.png';
import aten1 from '../../../Images/Pure/MenuPrincipal/img-icons/aten1.png'
import { Link } from 'react-router-dom'; // Asegúrate de importar Link si aún no lo has hecho


import 'primeicons/primeicons.css';

const PageGestionAtencion = ({ accesoProp }) => {
   const list_images = (url) => {
      switch (url) {
         case "recepcion":
            return aten1;
         case "liquidacion": // Agrega un caso para la ruta de liquidación
            return aten1; // Reemplaza imagenLiquidacion con la imagen correspondiente
         default:
            return imagen2;
      }
   }

   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className='arriba'>
            <span key="2" className='tit'>Gestión de Atenciones Médicas</span><br />
            <span key="1" className='con'>En este módulo usted podrá realizar la gestión de atenciones médicas para su clínica</span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
            {/* {

               accesoProp.map((acceso, key) => (
                  <Botones
                     key={key}
                     bkey={key}
                     Link={acceso.url}
                     img={list_images(acceso.url)}
                     span={acceso.label === "Historia Clinica" ? "Historia Clínica" : acceso.label}
                     button={"button b" + (key + 1)}
                  />
               ))

            } */}
            {/* PROVICIONAL!!! */}
            <Botones
               bkey={1}
               Link="recepcion"
               img={aten1}
               span="Recepción"
               button="button b1"
            />
            <Botones
               bkey={2}
               Link="auditoria"
               img={imagen2}
               span="Auditoría Clínica"
               button="button b2"
            />

            <Botones
               bkey={4}
               Link="historiaclinica"
               img={imagen2}
               span="Historia Clínica"
               button="button b3"
            />
            <Botones
               bkey={3}
               Link="liquidacion"
               img={imagen2}
               span="Liquidación"
               button="button b4"
            />
            <Imagenes
               src={Chica}
               className="logo2"
            />
            <Eclipses />
         </div>
      </div>
   );
}

export default PageGestionAtencion;
import React, { useContext } from "react";
import { InputText } from "primereact/inputtext";
// Icons
import cigarrette from "../../../Images/Triaje/HabitosNocivos/icons/cigarrette.svg";
import coffee from "../../../Images/Triaje/HabitosNocivos/icons/coffe.svg";
import drugs from "../../../Images/Triaje/HabitosNocivos/icons/drugs.svg";
import liqueur from "../../../Images/Triaje/HabitosNocivos/icons/liqueur.svg";
import "../../../Style/Triaje/HabitosNocivos/style/CSSHab.css";
import { MGContext } from "../../MedicinaGeneral/MGProvider";
import { RAContext } from '../../RutaAtencion/RAContext';
import { Dropdown } from "primereact/dropdown";

const TiposHabito = ({ index, tipo_habito_id, nombre, frecuencia, tiempo, tipo, cantidad, opcion }) => {

   let { habNocContext, setHabNocContext } = useContext(MGContext);
   let { stateAtencion, pageViewContext } = useContext(RAContext);

   const defaultIcons = (icon) => {
      switch (icon) {
         case 1:
            return coffee;
         case 2:
            return drugs;
         case 3:
            return cigarrette;
         case 4:
            return liqueur;
         default:
            return "no hay nada";
      }
   };

   const dataOptions = [
      {
         id: "1",
         nombre: "Nada"
      },
      {
         id: "2",
         nombre: "Poco"
      },
      {
         id: "3",
         nombre: "Regular"
      },
      {
         id: "4",
         nombre: "Excesivo"
      },
      {
         id: "5",
         nombre: "Niega"
      }
   ]

   const onChangeData = (e) => {
      let aux_habitos = [...habNocContext.habitos]
      aux_habitos[index] = {
         ...aux_habitos[index],
         [e.target.name]: e.target.value
      }
      setHabNocContext({
         ...habNocContext,
         habitos: aux_habitos,
      })
   }

   return (
      <div className="col-12 md:col-12 lg:col-6" key={index}>
         <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <div className="card_Hab_Malos" style={{ height: "300px", width: "600px", padding: "25px 15px 15px 15px" }} >
               <div className="flex mb-3 align-items-center gap-3">
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "25px", height: "25px", background: "#EBEBFF", borderRadius: "10px" }}            >
                     <img src={defaultIcons(tipo_habito_id)} alt="icon" />
                  </div>
                  <p style={{ fontSize: "14px", fontWeight: "700", lineHeight: "20px", color: "#5555D8" }} >{nombre}</p>
                  <Dropdown
                     name="opcion"
                     value={opcion}
                     options={dataOptions}
                     style={{ width: "15pxs" }}
                     optionLabel="nombre"
                     optionValue="id"
                     placeholder="Selecciona"
                     onChange={e => onChangeData(e)}
                  />
               </div>
               <div style={{ display: "flex", gap: '1.0rem', marginBottom: "25px" }} >
                  <div className="thn_form_input" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", }} >
                     <span style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "12px", color: "#344054" }}>Frecuencia</span>
                     <InputText
                        name="frecuencia"
                        style={{ width: "80%" }}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                        value={frecuencia}
                        onChange={e => onChangeData(e)}
                     />
                  </div>
                  <div className="thn_form_input" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", }} >
                     <span style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "12px", color: "#344054" }}>Tiempo</span>
                     <InputText
                        name="tiempo"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                        value={tiempo}
                        onChange={e => onChangeData(e)}
                        style={{ width: "80%" }} />
                  </div>
               </div>
               <div style={{ display: "flex", gap: '1.0rem' }}>
                  <div
                     className="thn_form_input"
                     style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                     }}
                  >
                     <span style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "12px", color: "#344054" }}>Tipo</span>
                     <InputText
                        name="tipo"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                        value={tipo}
                        onChange={e => onChangeData(e)}
                        style={{ width: "80%" }} />
                  </div>
                  <div className="thn_form_input" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", }} >
                     <span style={{ fontFamily: "Montserrat", fontWeight: "500", fontSize: "12px", color: "#344054" }}>Cantidad</span>
                     <InputText
                        name="cantidad"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                        value={cantidad}
                        onChange={e => onChangeData(e)}
                        style={{ width: "80%" }} />
                  </div>
               </div>
               <div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default TiposHabito;

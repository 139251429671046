import React, { useState, useEffect } from "react";
import AuthUser from "../../AuthUser";
import RAResultadosTable from "../../Components/RutaAtencion/Resultados/RAResultadosTable";

const PageMenuEKG = () => {
   const { http } = AuthUser();
   const [pacientes, setPacientes] = useState([]);

   const getPacientes = () => {
      http
         .get("/atencion/paciente/area/3")
         .then((response) => {
            setPacientes(response.data.data);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      getPacientes();
   }, [])

   return (
      <div>
         <RAResultadosTable
            pacientes={pacientes}
            nombre={"EKG"}
         ></RAResultadosTable>
      </div>
   )
}

export default PageMenuEKG;
import React from 'react'
import '../../../Style/Botones.css';
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Chica.png';
import Sombra from '../../../Images/Pure/MenuPrincipal/Img/sombra.png';
import im1 from '../../../Images/Pure/MenuPrincipal/img-icons/productos.png';
import im2 from '../../../Images/Pure/MenuPrincipal/img-icons/ven2.png';

function PageEstadisticaB() {
    return (
        <div>
            <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
                <div className='arriba'>
                    <span className='tit'>Estadísticas</span><br />
                    <span className='con'>Agregar contenido</span>
                </div>
                {/* ----Botones duplicados----
                
                <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
                    <Botones
                        Link="/estadisticas/estadisticasps"
                        icons="pi pi-map-marker"
                        span="Productos y servicios"
                        button="button b2"
                    />
                    <Botones
                        Link="/estadisticas/estadisticasventas"
                        icons="pi pi-map-marker"
                        span="Ventas"
                        button="button b3"
                    />
                    <Botones
                        Link="/estadisticas/estadisticasrh"
                        icons="pi pi-map-marker"
                        span="Recursos Humanos"
                        button="button b4"
                    />
                    <Botones
                        Link="/estadisticas/estadisticasconta"
                        icons="pi pi-map-marker"
                        span="Contabilidad"
                        button="button b5"
                    />
                    <Imagenes
                        src={Chica}
                        className="logo1"
                    />
                    <Imagenes
                        src={Sombra}
                        className="som"
                    />
                    <Eclipses />
                </div> */}
            </div>
            <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
                <Botones
                    Link="/estadisticas/estadisticasps"
                    img={im1}
                    span="Productos y Servicios"
                    button="button b2"
                />
                <Botones
                    Link="/estadisticas/estadisticasventas"
                    img={im2}
                    span="Ventas"
                    button="button b3"
                />
                {/* -------Botones sin contenido--------
                
                <Botones
                    Link="/estadisticas/estadisticasrh"
                    icons="pi pi-map-marker"
                    span="Recursos Humanos"
                    button="button b4"
                />
                <Botones
                    Link="/estadisticas/estadisticasconta"
                    icons="pi pi-map-marker"
                    span="Contabilidad"
                    button="button b5"
                />
                <Botones
                    Link="/estadisticas/estadisticamedica"
                    icons="pi pi-map-marker"
                    span="Atención Médica"
                    button="button b6"
                /> */}
                <Imagenes
                    src={Chica}
                    className="logo1"
                />
                <Imagenes
                    src={Sombra}
                    className="som"
                />
                <Eclipses />
            </div>
        </div>
    )
}

export default PageEstadisticaB;
import React, { createContext, useState } from 'react'

const ServicioContext = createContext();

const ServicioProvider = ({ children }) => {
   const [servicio, setServicio] = useState({
      nombre: "",
      clinica_servicio_id: "",
      perfiles: [],
   });
   const [rowData, setRowData] = useState({});
   const [serviciosContext, setServiciosContext] = useState([]);
   const [perfilesContext, setPerfilesContext] = useState(
      [
         {
            id: 1,
            NameTipoPerfil: "Tipo 01",
            Entrada: [
               {
                  ItemsSeleccionados: [],
                  OnlyKeys: [],
                  comentario: ""
               }
            ],
            documentosEntrada: [],
            validacionesEntrada: [],
            PrecioReferencialEntrada: 0,
            Rutina: [
               {
                  ItemsSeleccionados: [],
                  OnlyKeys: [],
                  comentario: ""
               }
            ],
            PrecioReferencialRutina: 0,
            documentosRutina: [],
            validacionesRutina: [],
            Salida: [
               {
                  ItemsSeleccionados: [],
                  OnlyKeys: [],
                  comentario: ""
               }
            ],
            PrecioReferencialSalida: 0,
            documentosSalida: [],
            validacionesSalida: [],
         }
      ]
   );

   const [serviciosValidacionContext, setServiciosValidacionContext] = useState([])

   const agregarElemento = () => {
      const nuevoElemento = {
         id: perfilesContext.length + 1,
         NameTipoPerfil: `Tipo ${perfilesContext.length + 1 < 10 ? '0' + (perfilesContext.length + 1) : perfilesContext.length + 1}`,
         Entrada: [
            {
               ItemsSeleccionados: [],
               OnlyKeys: []
            }
         ],
         PrecioReferencialEntrada: 0,
         documentosEntrada: [],
         validacionesEntrada: [],
         Rutina: [
            {
               ItemsSeleccionados: [],
               OnlyKeys: []
            }
         ],
         PrecioReferencialRutina: 0,
         documentosRutina: [],
         validacionesRutina: [],
         Salida: [
            {
               ItemsSeleccionados: [],
               OnlyKeys: []
            }
         ],
         PrecioReferencialSalida: 0,
         documentosSalida: [],
         validacionesSalida: [],
      };

      setPerfilesContext([...perfilesContext, nuevoElemento]);
   };
   const borrarElemento = (id) => {
      const nuevosPerfiles = perfilesContext.filter(elemento => elemento.id !== id);
      setPerfilesContext(nuevosPerfiles);
   };
   const filtrarDatosSeleccionados = (datos, selectedIds) => {
      return datos.reduce((acc, item) => {
         const newItem = { ...item };
         if (selectedIds.includes(newItem.key) && newItem.children.length === 0) {
            acc.push(newItem);
         }
         if (newItem.children && newItem.children.length > 0) {
            newItem.children = filtrarDatosSeleccionados(newItem.children, selectedIds);
            if (newItem.children.length > 0) {
               acc.push(newItem);
            }
         }
         return acc;
      }, []);
   };

   return (
      <ServicioContext.Provider value={{
         agregarElemento,
         servicio, setServicio,
         rowData, setRowData,
         perfilesContext, setPerfilesContext,
         serviciosContext, setServiciosContext,
         serviciosValidacionContext, setServiciosValidacionContext,
         filtrarDatosSeleccionados, borrarElemento
      }}>{children}</ServicioContext.Provider>
   )
}

export { ServicioProvider, ServicioContext }

import React from 'react'
import Botones from '../../Components/Pure/MenuPrincipal/botones';
import Eclipses from '../../Components/Pure/MenuPrincipal/Eclipses';
import '../../Style/ProductoyServicios/Botones_A.css';
import Imagenes from '../../Components/Pure/MenuPrincipal/Imagenes';
import P_S from '../../Images/Pure/MenuPrincipal/Img/P_S.png'
import im2 from '../../Images/Pure/MenuPrincipal/img-icons/productos.png'
import im3 from '../../Images/Pure/MenuPrincipal/img-icons/servicios.png'
import im4 from '../../Images/Pure/MenuPrincipal/img-icons/terminos.svg'

const PageProductosServicios = ({ accesoProp }) => {

   const listAcceso = accesoProp.map((acc) => {
      return acc.url
   });
   const list_images = (url) => {
      switch (url) {
         case "productos":
            return im2;
         case "servicios":
            return im3;
         case "terminosycondiciones":
            return im4;
         default:
            return P_S;
      }
   }
   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className="arriba">
            <span className='tit'>Productos y Servicios</span><br />
            <span className='con'>Crea productos y servicios para ofrecer a tus clientes.</span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "80%", marginTop: "-5%" }}>
            {
               accesoProp.map((acceso, key) => {
                  return <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b" + (key + 1)}
                     />
                  </>
               })
            }
            <Eclipses />
            <Imagenes
               src={P_S}
               className="logo3"
            />
         </div>
      </div>
   );
}


export default PageProductosServicios;
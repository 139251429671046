import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";

import Maps from "../../../Maps/Maps"
import InputMapAutocomplet from "../../../Maps/InputMapAutocomplet"

export default function MapaLocal({ datosLocal, setDatosLocal, handleChangeDireccion }) {

	const [latLng, setLatLng] = useState({
		lat: parseFloat(datosLocal.latitud) || parseFloat(-11.9756924),
		lng: parseFloat(datosLocal.longitud) || parseFloat(-77.0152073),
	});

	//#region Maps

	//Marcador : Actualiza cuando el usuario realiza doble click en el mapa
	function handleMapDoubleClick(e) {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();
		setLatLng({ lat, lng });
	};

	//Valor cuando es arrastrado
	function handleMapOnDragEnd(e) {
		setLatLng({
			...latLng,
			lat: e?.latLng?.lat(),
			lng: e?.latLng?.lng(),
		});
		setDatosLocal({ ...datosLocal, /* Ubidelmapa: direccion ,  */latitud: e?.latLng?.lat(), longitud: e?.latLng?.lng() });
	}

	//#endregion

	//#region InputMapAutocomplet
	const [autocompleteDirection, setAutocompleteDirection] = useState("");
	const [direccion, setDireccion] = useState("");

	//Actualiza dirección y coordenadas cuando se ejecuta el autocompletado
	function handlePlaceChanged() {
		const place = autocompleteDirection.getPlace();
		//Si el lugar existe, actualiza las coordenadas y la dirección
		if (place && place.geometry && place.geometry.location) {
			//Actualiza las coordenadas
			setLatLng({
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng(),
			});
			//Actualiza la dirección
			setDireccion(place.formatted_address);
			//Actualiza los datos del local
			setDatosLocal({ ...datosLocal, direccion: place.formatted_address, latitud: place.geometry.location.lat(), longitud: place.geometry.location.lng() });
		} else {
			console.error("No se pudo obtener la ubicación del lugar seleccionado");
		}
	}

	//Guarda referencia del autocompletado
	const onLoadAutocomplete = (autocomplete) => {
		setAutocompleteDirection(autocomplete);
	};

	//#endregion
	// Manejador de eventos para la tecla Enter en el buscador
	const handleSearchEnter = (e) => {
		if (e.key === 'Enter') {
			// Realizar búsqueda en el mapa
			handleMapSearch();
		}
	};

	//Búsqueda geográfica basada en la dirección actual
	const handleMapSearch = () => {
		// Obtener la cadena de búsqueda del estado
		const query = datosLocal.direccion;

		// Crear una instancia del geocodificador
		const geocoder = new window.google.maps.Geocoder();

		// Realizar la búsqueda geográfica
		geocoder.geocode({ address: query }, (results, status) => {
			if (status === 'OK') {
				// Si se encuentran resultados, obtener las coordenadas del primer resultado
				const location = results[0].geometry.location;
				const latLng = {
					lat: location.lat(),
					lng: location.lng()
				};

				// Actualizar el estado con las nuevas coordenadas
				setLatLng(latLng);

				// Actualizar los datos del local con la dirección y coordenadas
				setDatosLocal({
					...datosLocal,
					direccion: query,
					latitud: latLng.lat,
					longitud: latLng.lng
				});
			} else {
				console.error('No se encontraron resultados para la búsqueda');
			}
		});
	};


	//Actualiza el estado de direccion
	function HandleDireccion(e) {
		setDireccion(e);
	}

	useEffect(() => {
		setDireccion(direccion)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [datosLocal])


	/*Parte de los estilos resposivos en registro.css*/
	const MapsComponent = () => {

		// Utilizamos useState para manejar el estado del tamaño de la pantalla
		const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1150);

		// Función para actualizar el estado del tamaño de la pantalla cuando cambie
		const updateScreenSize = () => {
			setIsSmallScreen(window.innerWidth < 1200);
		};
		useEffect(() => {
			// Agregamos un listener para el evento 'resize' que llamará a la función updateScreenSize
			window.addEventListener('resize', updateScreenSize);

			// Limpiamos el listener cuando el componente se desmonta para evitar errores de memoria
			return () => {
				window.removeEventListener('resize', updateScreenSize);
			};
		}, []); // El segundo argumento [] asegura que el efecto solo se ejecute una vez al montar el componente

		const Style = {
			borderRadius: '0rem',
			position: 'relative',
			overflow: 'hidden',
			height: isSmallScreen ? '50vh' : '100%'
		};
		return Style;
	};

	/*Objeto que contiene los estilos*/
	const styleObject = MapsComponent();

	return (
		<div className="crear-local-content2 flex flex-column gap-3 pt-2" style={{ width: "57%" }}>

			<InputMapAutocomplet
				restrictions={{ country: ["PE"] }}//Solo accede a Perú
				onPlaceChanged={handlePlaceChanged}//Actualiza el lugar
				onLoad={onLoadAutocomplete}//Se ejecuta al cargar el componente
			>
				<span className="p-input-icon-left w-full">
					<i className="pi pi-search" />
					<InputText className="w-full"
						placeholder="Buscar ubicación"
						value={datosLocal.direccion}
						onChange={(e) => handleChangeDireccion(e)}
						onKeyDown={handleSearchEnter}
					/>
				</span>
			</InputMapAutocomplet>

			<Maps
				className="maps-container"
				center={latLng}//Centro de la cámara
				mapContainerStyle={styleObject}//Estilos del contenedor del mapa
				position={latLng}//Posición del marcador
				onDblClickkMap={handleMapDoubleClick}//Evento que se ejecuta cuando se doble click en el mapa
				draggable={true}//Permite arrastrar el mapa
				onDragEnd={handleMapOnDragEnd}//Evento que se ejecuta cuando se arrastra el mapa
				direccion={HandleDireccion}
			>
			</Maps>
		</div>
	)
}
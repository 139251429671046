import React from 'react'

//Estilos generales
import '../../../Style/Botones.css';

//Componentes generales
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';

//Imágenes únicas
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Business.png';
import con1 from '../../../Images/Pure/MenuPrincipal/img-icons/con1.png';
import con2 from '../../../Images/Pure/MenuPrincipal/img-icons/con2.png';
import con3 from '../../../Images/Pure/MenuPrincipal/img-icons/con3.png';
import con4 from '../../../Images/Pure/MenuPrincipal/img-icons/con4.png';

// Componente principal PageRecursosHumanosE
function PageRecursosHumanosE({ accesoProp }) {
   const list_images = (url) => {
      switch (url) {
         case "locales":
            return con1;
         case "roles":
            return con2;
         case "personal":
            return con3;
         case "reclutamiento":
            return con4;
         case "puestotrabajo":
            return con4;
         default:
            return con4;
      }
   };
   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className='arriba'>
            <span className='tit'>Recursos Humanos</span><br />
            <span className='con'>En este módulo usted podrá realizar la gestión de recursos humanos de Bregma.</span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
            {
               accesoProp
                  .map((acceso, key) => (
                     (
                        <Botones
                           key={key}
                           Link={acceso.url}
                           img={list_images(acceso.url)}
                           span={acceso.label}
                           button={"button b" + (key + 1)}
                        />
                     )
                  ))
            }

            {/* Imagen decorativa */}
            <Imagenes src={Chica} className="logo2" />

            {/* Componente visual adicional */}
            <Eclipses />
         </div>
      </div>
   )
}

export default PageRecursosHumanosE
import React, { useContext, useEffect, useState } from 'react';
import { OftContext } from "../OftContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { Dropdown } from "primereact/dropdown";
import { iconOft } from "../../../Services/importIcons";
import { InputNumber } from "primereact/inputnumber";

import '../../../Style/oft_test.css'
// import './PageOftTest.css';

const PageOftTestProfundidad = () => {
  const stereoFlyTestOptions = [
    { label: 'Anormal', id: "1" },
    { label: '59 MINS', id: "2" },
    { label: 'No percibe', id: "3" },
    { label: 'No corresponde', id: "4" },
  ];

  const circulosOptions = [
    { label: '1-800 sec.', id: "1" },
    { label: '2-400 sec.', id: "2" },
    { label: '3-200 sec.', id: "3" },
    { label: '4-140 sec.', id: "4" },
    { label: '5-100 sec.', id: "5" },
    { label: '6-80 sec.', id: "6" },
    { label: '7-60 sec.', id: "7" },
    { label: '8-50 sec.', id: "8" },
    { label: '9-40 sec.', id: "9" },
    { label: '10-32 sec.', id: "10" },
    { label: '10-25 sec.', id: "11" },
    { label: '10-20 sec.', id: "12" },
    { label: 'No percibe', id: "13" },
    { label: 'No corresponde', id: "14" },
  ];

  const animalsOptions = [
    { label: 'A - 400 Sec. (15%)', id: "1" },
    { label: 'B - 200 Sec. (30%)', id: "2" },
    { label: 'C - 100 Sec. (50%)', id: "3" },
    { label: 'No percibe', id: "4" },
    { label: 'No corresponde', id: "5" },
    { label: 'No realizado', id: "6" },
  ];

  let { testProfundidad, setTestProfundidad } = useContext(OftContext)
  let { pageViewContext, stateAtencion } = useContext(RAContext)

  const [stereo, setStereo] = useState(testProfundidad?.stereo_fly_test ?? null);
  const [circulos, setCirculos] = useState(testProfundidad?.circulos ?? null);
  const [porcentaje, setPorcentaje] = useState(testProfundidad?.porcentaje ?? null);
  const [animales, setAnimales] = useState(testProfundidad?.animales ?? null);


  useEffect(() => {
    setTestProfundidad({
      [`stereo_fly_test`]: stereo,
      [`circulos`]: circulos,
      [`porcentaje`]: porcentaje,
      [`animales`]: animales,

    })
  }, [stereo, circulos, porcentaje, animales])


  return (
    <>
      <div className="oft_ant_titles">
        <div>
          <img src={iconOft} alt="Icon" />
        </div>
        <h3>Test de profundidad</h3>
      </div>

      <div className="oft_test_dropdown_container pt-3">
        <div>
          <label>Stereo Fly Test</label>
          <Dropdown
            showClear
            options={stereoFlyTestOptions}
            optionValue="id"
            value={stereo}
            onChange={e => setStereo(e.value)}
            placeholder="Seleccione una opción"
            disabled={stateAtencion[pageViewContext].estado === 3}
          />
        </div>
        <div>
          <label>%</label>

          <InputNumber
            style={{ width: '100%' }}
            value={porcentaje}
            onChange={e => setPorcentaje(e.value)}
            placeholder="0"
            useGrouping={false}
            locale="en-US"
            maxFractionDigits={2}
            disabled={stateAtencion[pageViewContext].estado === 3}
            onClick={(e) => e.target.select()}
          />
        </div>


        <div>
          <label>Círculos</label>
          <Dropdown
            showClear
            options={circulosOptions}
            optionValue="id"
            value={circulos}
            onChange={e => setCirculos(e.value)}
            placeholder="Seleccione una opción"
            disabled={stateAtencion[pageViewContext].estado === 3}
          />
        </div>
        <div>
          <label>Animales</label>
          <Dropdown
            showClear
            options={animalsOptions}
            optionValue="id"
            value={animales}
            onChange={e => setAnimales(e.value)}
            placeholder="Seleccione una opción"
            disabled={stateAtencion[pageViewContext].estado === 3}
          />
        </div>
      </div>
    </>
  );
};

export default PageOftTestProfundidad;
import AuthUser from "../../../../../AuthUser";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";

const SC3Product = (showToast) => {
   const { http } = AuthUser();
   const dispatch = useDispatch();

   const postPackage = async (contrato_id, data, setEstadoEnvio, getProforma) => {

      dispatch(startLoading());
      try {
         const response = await http.post(`clinica/asignar/paquete/${contrato_id}`, data);
         if (response.data.resp) {
            await Promise.all([
               getProforma(),
               setEstadoEnvio(true)
            ]);
            showToast("success", "Completado", response.data.resp);
         } else if (response.data.error) {
            showToast("error", "Error", "Paquete no asignado");
         }
      } catch (error) {
         console.error('Error in postPackage:', error);
         showToast("error", "Error", "Contactar con soporte técnico");
      } finally {
         dispatch(endLoading());
      }
   };

   const getProforma = async (id) => {
      try {
         const response = await http.get(`clinica/proforma/get/${id}`);
         if (response.data.data) {
            return response.data.data;
         } else {
            showToast("error", "Error", response.data.error);
            //return [];
         }
         //return response.data.resp;
      } catch (error) {
         showToast("error", "Error", "Contactar con soporte técnico");
         //throw error;
      }
   };

   return {
      postPackage,
      getProforma
   };

}

export default SC3Product;
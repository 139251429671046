import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { RadContext } from "./RadProvider";

const EngrosamientoDifusoContent = () => {
    let { RayTorax, setRayTorax } =
        useContext(RadContext);


    const subTituloMorado = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        color: "#4545BC",
        width: "100%",
        height: '50px',
        backgroundColor: '#F6F7FB',
        padding: '7px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    };

    const subTituloNormal = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        height: '48px',
        display: 'flex',
        color: '#88888E',
        alignItems: 'center',
        justifyContent: 'start',
    };
    const subTituloNormalResponsive = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        height: 'auto',
        width: '100%',
        textAlign: 'center',
        display: 'flex',
        color: '#88888E',
        alignItems: 'center',
        justifyContent: 'start',
        diplay: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'none'
    };

    const actualizarPropiedadesBase = (newvalor, propiedad, secpropiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: {
                ...prevState[propiedad],
                [secpropiedad]: newvalor
            }
        }));
    };
    const actualizarPropiedadesBaseNivel01 = (newvalor, propiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: newvalor
        }));
    };

    const ConvertirSelectObjeto = (Array, Respuesta, setvalorRespuesta, propDef) => {
        for (let index = 0; index < Array.length; index++) {
            if (Array[index][propDef] == Respuesta) {
                setvalorRespuesta(Array[index]);
            }
        }
    };

    const OpcionesParedToraxCalcificacion = [
        { name: "0", code: "1" },
        { name: "D", code: "2" },
        { name: "I", code: "3" }
    ];
    const [DePerfilParedTorax, setDePerfilParedTorax] = useState(null);
    const [DePerfilParedTorax_cod, setDePerfilParedTorax_cod] =
        useState(RayTorax.eng_perfil.pared_toraxica ? RayTorax.eng_perfil.pared_toraxica : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesParedToraxCalcificacion, DePerfilParedTorax_cod, setDePerfilParedTorax, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DePerfilParedTorax_cod, "eng_perfil", "pared_toraxica");
    }, [DePerfilParedTorax_cod])

    const [DePerfilCalcificacion, setDePerfilCalcificacion] = useState(null);
    const [DePerfilCalcificacion_cod, setDePerfilCalcificacion_cod] =
        useState(RayTorax.eng_perfil.calificacion ? RayTorax.eng_perfil.calificacion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesParedToraxCalcificacion, DePerfilCalcificacion_cod, setDePerfilCalcificacion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DePerfilCalcificacion_cod, "eng_perfil", "calificacion");
    }, [DePerfilCalcificacion_cod])



    const [DeFrenteParedTorax, setDeFrenteParedTorax] = useState(null);
    const [DeFrenteParedTorax_cod, setDeFrenteParedTorax_cod] =
        useState(RayTorax.eng_frente.pared_toraxica ? RayTorax.eng_frente.pared_toraxica : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesParedToraxCalcificacion, DeFrenteParedTorax_cod, setDeFrenteParedTorax, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DeFrenteParedTorax_cod, "eng_frente", "pared_toraxica");
    }, [DeFrenteParedTorax_cod])

    const [DeFrenteCalcificacion, setDeFrenteCalcificacion] = useState(null);
    const [DeFrenteCalcificacion_cod, setDeFrenteCalcificacion_cod] =
        useState(RayTorax.eng_frente.calificacion ? RayTorax.eng_frente.calificacion : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesParedToraxCalcificacion, DeFrenteCalcificacion_cod, setDeFrenteCalcificacion, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(DeFrenteCalcificacion_cod, "eng_frente", "calificacion");
    }, [DeFrenteCalcificacion_cod])







    const OpcionesExtención = [
        { name: "0", code: "1" },
        { name: "1", code: "2" },
        { name: "2", code: "3" },
        { name: "3", code: "4" },
    ];
    const [ExtenciónDerecha, setExtenciónDerecha] = useState(null);
    const [ExtenciónDerecha_cod, setExtenciónDerecha_cod] = useState(RayTorax.eng_extension.derecha ? RayTorax.eng_extension.derecha : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesExtención, ExtenciónDerecha_cod, setExtenciónDerecha, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(ExtenciónDerecha_cod, "eng_extension", "derecha");
    }, [ExtenciónDerecha_cod])

    const [ExtenciónIzquierda, setExtenciónIzquierda] = useState(null);
    const [ExtenciónIzquierda_cod, setExtenciónIzquierda_cod] =
        useState(RayTorax.eng_extension.izquierda ? RayTorax.eng_extension.izquierda : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesExtención, ExtenciónIzquierda_cod, setExtenciónIzquierda, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(ExtenciónIzquierda_cod, "eng_extension", "izquierda");
    }, [ExtenciónIzquierda_cod])






    const OpcionesAncho = [
        { name: "A", code: "1" },
        { name: "B", code: "2" },
        { name: "C", code: "3" },
    ];
    const [AnchoDerecha, setAnchoDerecha] = useState(null);
    const [AnchoDerecha_cod, setAnchoDerecha_cod] = useState(RayTorax.eng_ancho.derecha ? RayTorax.eng_ancho.derecha : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesAncho, AnchoDerecha_cod, setAnchoDerecha, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(AnchoDerecha_cod, "eng_ancho", "derecha");
    }, [AnchoDerecha_cod])

    const [AnchoIzquierda, setAnchoIzquierda] = useState(null);
    const [AnchoIzquierda_cod, setAnchoIzquierda_cod] =
        useState(RayTorax.eng_ancho.izquierda ? RayTorax.eng_ancho.izquierda : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesAncho, AnchoIzquierda_cod, setAnchoIzquierda, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(AnchoIzquierda_cod, "eng_ancho", "izquierda");
    }, [AnchoIzquierda_cod])


    return (
        <div className="contentEngrosamientoRayosX01 w-full flex h-auto pl-2 pr-2 pb-2 gap-2">
            <style>
                {
                    `
                  @media(max-width: 853px){
                    .contentEngrosamientoRayosX01{
                        flex-direction: column !important;
                    }
                     .contentEngrosamientoRayosX01 >div{
                        width: 100% !important;
                     }
                  }
                  @media(max-width: 437px){
                    .contentEngrosamientoRayosX01 .content01 > div{
                        width: 100% !important;
                    }
                    .contentEngrosamientoRayosX01 .content01 > div:nth-child(1){
                        display: none !important;
                    }
                    .contentEngrosamientoRayosX01 .textResponsive{
                        display: flex !important;
                        padding: 10px !important;
                    }
                  }
               `
                }
            </style>
            <div className="content01 w-6 flex gap-1" style={{ flexWrap: 'nowrap' }}>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '33%' }}>
                    <div className="w-full" style={{ ...subTituloNormal }}> </div>
                    <div className="w-full" style={{ ...subTituloNormal }}>De Perfil</div>
                    <div className="w-full" style={{ ...subTituloNormal }}>De Frente</div>
                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '33%' }}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Pared Toráxica:</div>

                    <div className="w-full flex flex-column relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>De Perfil</div>

                        <Dropdown className="w-full" style={{ height: '48px' }} value={DePerfilParedTorax}
                            onChange={(e) => setDePerfilParedTorax_cod(e.value.code)}
                            options={OpcionesParedToraxCalcificacion} optionLabel="name" />
                        {DePerfilParedTorax != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{ right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                onClick={() => {
                                    setDePerfilParedTorax_cod(null);
                                    actualizarPropiedadesBase(null, "eng_perfil", "pared_toraxica"); setDePerfilParedTorax(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex flex-column relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>De Frente</div>

                        <Dropdown className="w-full" style={{ height: '48px' }} value={DeFrenteParedTorax}
                            onChange={(e) => setDeFrenteParedTorax_cod(e.value.code)}
                            options={OpcionesParedToraxCalcificacion} optionLabel="name" />
                        {DeFrenteParedTorax != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDeFrenteParedTorax_cod(null);
                                    actualizarPropiedadesBase(null, "eng_frente", "pared_toraxica"); setDeFrenteParedTorax(null)
                                }}></button>
                        ) : null}
                    </div>



                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '33%' }}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Calcificación:</div>

                    <div className="w-full flex flex-column relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>De Perfil</div>
                        <Dropdown className="w-full" style={{ height: '48px' }} value={DePerfilCalcificacion}
                            onChange={(e) => setDePerfilCalcificacion_cod(e.value.code)}
                            options={OpcionesParedToraxCalcificacion} optionLabel="name" />
                        {DePerfilCalcificacion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDePerfilCalcificacion_cod(null);
                                    actualizarPropiedadesBase(null, "eng_perfil", "calificacion");
                                    setDePerfilCalcificacion(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex flex-column relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>De Frente</div>
                        <Dropdown className="w-full" style={{ height: '48px' }} value={DeFrenteCalcificacion}
                            onChange={(e) => setDeFrenteCalcificacion_cod(e.value.code)}
                            options={OpcionesParedToraxCalcificacion} optionLabel="name" />
                        {DeFrenteCalcificacion != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setDeFrenteCalcificacion_cod(null);
                                    actualizarPropiedadesBase(null, "eng_frente", "calificacion");
                                    setDeFrenteCalcificacion(null)
                                }}></button>
                        ) : null}
                    </div>

                </div>
            </div>


            <div className=" content01 w-6 flex gap-1" style={{ flexWrap: 'nowrap' }}>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '33%' }}>
                    <div className="w-full" style={{ ...subTituloNormal }}></div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Extención</div>
                    <div className="w-full" style={{ ...subTituloNormal }}>Ancho</div>

                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '33%' }}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Derecha:</div>

                    <div className="w-full flex relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>Extención</div>

                        <Dropdown className="w-full" style={{ height: '48px' }} value={ExtenciónDerecha}
                            onChange={(e) => setExtenciónDerecha_cod(e.value.code)}
                            options={OpcionesExtención} optionLabel="name" />
                        {ExtenciónDerecha != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{ right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                onClick={() => {
                                    setExtenciónDerecha_cod(null);
                                    actualizarPropiedadesBase(null, "eng_extension", "derecha"); setExtenciónDerecha(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>Ancho</div>

                        <Dropdown className="w-full" style={{ height: '48px' }} value={AnchoDerecha}
                            onChange={(e) => setAnchoDerecha_cod(e.value.code)}
                            options={OpcionesAncho} optionLabel="name" />
                        {AnchoDerecha != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setAnchoDerecha_cod(null);
                                    actualizarPropiedadesBase(null, "eng_ancho", "derecha"); setAnchoDerecha(null)
                                }}></button>
                        ) : null}
                    </div>


                </div>
                <div className="flex flex-column gap-1 justify-content-start" style={{ width: '33%' }}>
                    <div className="w-full" style={{ ...subTituloMorado }}>Izquierda:</div>

                    <div className="w-full flex relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>Extención</div>

                        <Dropdown className="w-full" style={{ height: '48px' }} value={ExtenciónIzquierda}
                            onChange={(e) => setExtenciónIzquierda_cod(e.value.code)}
                            options={OpcionesExtención} optionLabel="name" />
                        {ExtenciónIzquierda != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setExtenciónIzquierda_cod(null);
                                    actualizarPropiedadesBase(null, "eng_extension", "izquierda");
                                    setExtenciónIzquierda(null)
                                }}></button>
                        ) : null}
                    </div>

                    <div className="w-full flex relative">
                        <div className="w-full textResponsive" style={{ ...subTituloNormalResponsive }}>Ancho</div>

                        <Dropdown className="w-full relative" style={{ height: '48px' }} value={AnchoIzquierda}
                            onChange={(e) => setAnchoIzquierda_cod(e.value.code)}
                            options={OpcionesAncho} optionLabel="name" />
                        {AnchoIzquierda != null ? (
                            <button
                                className="absolute pi pi-times"
                                style={{
                                    right: '39px', bottom: '16px', background: 'white', border: '0px solid transparent',
                                    color: '#6c757d'
                                }}

                                onClick={() => {
                                    setAnchoIzquierda_cod(null);
                                    actualizarPropiedadesBase(null, "eng_ancho", "izquierda");
                                    setAnchoIzquierda(null)
                                }}></button>
                        ) : null}
                    </div>

                </div>
            </div>
        </div>

    );
};

export default EngrosamientoDifusoContent;

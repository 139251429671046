import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

//Imagen única
import pencil from "../../../../Images/roles/pencil.svg";

//Componente general
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

const CreateRolModal = ({
   visibleCreate,
   setVisibleCreate,
   rolCreate,
   getRoles,
}) => {
   const [rol, setRol] = useState({ id: 0, nombre: "" });
   const [VerifiNombreFalta, setVerifiNombreFalta] = useState(false)

   const handleCreateRol = () => {
      //Envío de datos a la api
      rolCreate(rol);
      //Obtiene roles
      getRoles();
      //Cierra el modal
      setVisibleCreate(false);
      //Define los atributos
      setRol({ id: 0, nombre: "" })
   };

   const VerificarInputCreate = () => {
      var verifi = true;
      var inputNameRol = document.getElementById('NuevoRolNombrePP').value;
      //Corrobora si existen valores nulos
      if (inputNameRol === "" || inputNameRol == null) {
         verifi = false;
         setVerifiNombreFalta(true);
      }
      return verifi;
   }

   const EnvioCreateInputRol = () => {
      //Accede a la función si no existen valores nulos
      if (VerificarInputCreate()) {
         handleCreateRol();
      }
   }
   const hideDialogCreate = () => {
      setVisibleCreate(false);
      //Limpia la variable
      setRol({ id: 0, nombre: "" })
   };

   const handleChangeNombre = (e) => {
      //Actualiza datos del rol
      setRol({ ...rol, nombre: e.target.value });
   };

   //Ejecutable al montar el componente
   useEffect(() => {
      setVerifiNombreFalta(false);
      //Recupera rol y añade el nombre
      setRol({ ...rol, nombre: "" });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visibleCreate])

   return (
      <>
         <Dialog
            visible={visibleCreate}
            style={{ width: "507px", height: "auto" }}
            header={<Header icono={pencil} titulo={"Crear nuevo Rol"} subtitulo={"En esta sección usted puede crear un nuevo rol."} />}
            modal
            className="p-fluid"
            footer={<Footer onClickEnviar={EnvioCreateInputRol} onClickCancelar={() => setVisibleCreate(false)} label={"Crear"} />}
            onHide={() => { hideDialogCreate() }}
            draggable={false}
         >
            <div className="flex flex-column gap-2 relative pt-2">
               <InputText
                  id="NuevoRolNombrePP"
                  placeholder="Colocar rol"
                  type="text"
                  value={rol.nombre}
                  onChange={(e) => { handleChangeNombre(e); setVerifiNombreFalta(false) }}
                  required
                  className={`w-full InputRetraccionNumCuAP ${VerifiNombreFalta ? 'Select' : ''}`}
                  sle="off"
               />
               <div className={`InputRetraccionNumCuAPDiv ${VerifiNombreFalta ? 'Select' : ''}`}></div>
               <p className={`InputRetraccionNumCuAPTextError ${VerifiNombreFalta ? 'Select' : ''}`}>Ingrese el nuevo rol</p>
            </div>
         </Dialog>
      </>
   );
};

export default CreateRolModal;

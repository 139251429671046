import React from 'react';
import '../../../../Style/modales.css'
import { useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

function ModalVerPdf({ show, setShow, pdfUrl, handleAgregarFirmaClick }) {

  const { idPaciente } = useParams();

  const FooterContent = () => {
    return(
      <><Divider/>
      <div style={{ borderRadius: "10px", height: "50px" }} className="flex mt-2 bg-white">
            
            <div className="flex gap-3  w-full aling-items-center" style={{ marginTop: "0px" }}>
              <button
                style={{ color: "green" }}
                className="p-button p-component btn-2 flex gap-1 border-green-300 border-round-lg"
              >
                <i className="pi pi-print"></i>
                <span>Imprimir</span>
              </button>
              <button
                onClick={() => handleAgregarFirmaClick()}
                style={{ color: "#5555D8", marginLeft: "350px" }}
                className="bg-indigo-100 p-button p-component btn-2 flex gap-1 border-indigo-400 border-round-lg"
              >
                <i className="pi pi-pencil"></i>
                <span>Agregar Firma</span>
              </button>
              <button
                style={{ color: "#5555D8" }}
                className="p-button  p-component btn-2 flex gap-1  border-indigo-400 border-round-lg"
              >
                <i className="pi pi-download"></i>
                <span>Guardar</span>
              </button>
            </div>
          </div>
      </>
    )
  }

  const HeaderContent = () => {
    return(
      <>
      <div className='flex align-items-center'>
        <span className="pi pi-file-pdf text-5xl bg-indigo-100 p-2 text-indigo-500 font-bold border-round-lg"></span>
        <h2 className="font-bold text-3xl text-indigo-500 pl-6">Informe del Paciente</h2>
      </div>
      <Divider/>
      </>
    )
  }

  return (
    <Dialog style={{minWidth:'800px'}}
        onHide={() => setShow(false)} 
        visible={show} 
        header={HeaderContent }
        footer={FooterContent} 
        >
            <div>
                <embed src={process.env.REACT_APP_API_URL+"/"+pdfUrl+idPaciente} type='application/pdf' width={'100%'} height={'600px'} className='contedor-pdf'></embed>
            </div>
    </Dialog>
  );
}

export default ModalVerPdf;

import React, { useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const VoiceInputTextarea = ({ nombre, onChange, placeholder, value, disabled }) => {
   const [isListening, setIsListening] = useState(false)

   const startListeningTabaco = () => {
      const recognition = new (window.SpeechRecognition ||
         window.webkitSpeechRecognition)();
      recognition.lang = "es-ES";

      recognition.onstart = () => {
         setIsListening(true);
      };

      recognition.onend = () => {
         setIsListening(false);
      };

      recognition.onresult = (event) => {
         const transcript = event.results[0][0].transcript;
         let comentario = value + transcript;
         onChange(comentario)
      };

      recognition.start();
   };

   return (
      <div className="flex flex-row w-full">
         <div className="flex flex-column w-full">
            <InputTextarea
               name={nombre}
               className="w-full"
               value={value || ''} // Asegurarse de que value no sea undefined
               onChange={onChange}
               placeholder={placeholder}
               style={{ height: '50px' }}
               disabled={disabled}
            />

         </div>
         <div className="flex flex-column justify-content-end" style={{ marginLeft: "-40px", marginBottom: "5px" }}>
            <Button
               style={{ height: "25px", padding: "5px" }}
               label={isListening ? 'Off' : 'On'}
               onClick={startListeningTabaco}
               disabled={isListening}
            />
         </div>
      </div>
   );
};

export default VoiceInputTextarea;

import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import Ocupacional from "./Opciones/Ocupacional";
import Auditor from "./Opciones/Auditos";
import Especialista from "./Opciones/Especialista";
import Odontologia from "./Opciones/Odontologia";
import Psicologia from "./Opciones/Psicologia";
import AuthUser from "../../../../AuthUser";
import "../../../../Style/Botones.css";
import "./DialogCrearEvaluadores.css";
import Laboratorio from "./Opciones/Laboratorio";
import { InputMask } from "primereact/inputmask";

const DialogCrearEvaluadores = ({ visible, setvisible, titulo, createEvaluador, updateEvaluador, GetEvaluador, rowData, tiposEspecialistas, especialistas, labEspecialistas }) => {
   const toast = useRef(null);
   const inputRef = useRef(null);
   const { http } = AuthUser();

   const [selectedImage, setSelectedImage] = useState(null);
   const [showImage, setShowImage] = useState(false);
   const [Apellidos, setApellidos] = useState(null);
   const [Nombres, setNombres] = useState(null);
   const [Direccion, setDireccion] = useState(null);
   const [Telefono, setTelefono] = useState(null);
   const [Email, setEmail] = useState(null);
   const [PostFirma, setPostFirma] = useState(null);
   const [tipoEspecialista, setTipoEspecialista] = useState(null);
   const [Rnm, setRnm] = useState(null);


   const [Cmp, setCmp] = useState(null);
   const [Ctmp, setCtmp] = useState(null);
   const [Cbp, setCbp] = useState(null);


   const [Rne, setRne] = useState(null);
   const [Rna, setRna] = useState(null);
   const [especialistaID, setespecialistaID] = useState(null);
   const [Cpsp, setCpsp] = useState(null);
   const [Cop, setCop] = useState(null);
   const [LabEspecialista, setLabEspecialista] = useState(null);
   const [Foto, setFoto] = useState();

   const handleImageChange = (event) => {
    const file = event?.target.files[0]; // ALMACENA EL EVENTO EN LA VARIABLE FILE

    if (file) {
        const reader = new FileReader(); // CREA UN FileReader ALMACENADO EN READER
        reader.onload = () => {  // CUANDO CARGA EL ARCHIVO READER SE EJECUTARÁ LO DE ADENTRO
            setSelectedImage(reader.result); // SELECTEDIMAGE ALMACENARÁ EL RESULTADO
            setShowImage(true);
            setFoto(file);
        };
        reader.readAsDataURL(file); // CONVERTIR LA IMAGEN EN FORMATO BASE64
    } else if (!selectedImage && rowData?.imagen_firma) {
        // Si no se selecciona ningún archivo y ya hay una imagen en el evaluador, mantener la imagen actual
        setSelectedImage(rowData.imagen_firma);
        setShowImage(true);
        setFoto(null); // Limpiar la imagen actual si se elimina la selección
    }
};




   let componenteAMostrar;

   switch (tipoEspecialista) {
      case 1:
         componenteAMostrar = <Ocupacional Rnm={Rnm} Cmp={Cmp} Rne={Rne} setRnm={setRnm} setCmp={setCmp} setRne={setRne} />;
         break;
      case 2:
         componenteAMostrar = <Auditor Rnm={Rnm} Cmp={Cmp} Rne={Rne} Rna={Rna} setRnm={setRnm} setCmp={setCmp} setRne={setRne} setRna={setRna} />;
         break;
      case 3:
         componenteAMostrar = <Especialista rowData={rowData} Cmp={Cmp} Rne={Rne} especialistaID={especialistaID} setCmp={setCmp} setRne={setRne} setespecialistaID={setespecialistaID} especialistas={especialistas} />;
         break;
      case 4:
         componenteAMostrar = <Odontologia Cop={Cop} setCop={setCop} />;
         break;
      case 5:
         componenteAMostrar = <Psicologia Cpsp={Cpsp} setCpsp={setCpsp} />;
         break;
      case 6:
         componenteAMostrar = <Laboratorio rowData={rowData} LabEspecialista={LabEspecialista} setLabEspecialista={setLabEspecialista} Cbp={Cbp} Cmp={Cmp} Ctmp={Ctmp} Rne={Rne} setCbp={setCbp} setCmp={setCmp} setCtmp={setCtmp} setRne={setRne} labEspecialistas={labEspecialistas} />;
         break;
      default:
         componenteAMostrar = null;
   }
   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const limpiar = () => {
      setApellidos(null)
      setNombres(null)
      setDireccion(null)
      setTelefono(null)
      setEmail(null)
      setPostFirma(null)
      setTipoEspecialista(null)
      setRnm(null)

      setCmp(null)
      setCbp(null)
      setCtmp(null)



      setRne(null)
      setRna(null)
      setespecialistaID(null)
      setCpsp(null)
      setCop(null)
      setLabEspecialista(null)
   }
   

   //EDITAR EVALUADOR
   useEffect(() => {
      if (rowData) {
          setApellidos(rowData?.apellidos);
          setNombres(rowData?.nombres);
          setDireccion(rowData?.direccion);
          setTelefono(rowData?.telefono);
          setEmail(rowData?.email);
          setPostFirma(rowData?.pos_firma);
          setRnm(rowData?.especialista_evaluador?.rnm);
          setCmp(rowData?.especialista_evaluador?.cmp);
          setCbp(rowData?.especialista_evaluador?.cbp);
          setCtmp(rowData?.especialista_evaluador?.ctmp);
          setRne(rowData?.especialista_evaluador?.rne);
          setRna(rowData?.especialista_evaluador?.rna);
          setCpsp(rowData?.especialista_evaluador?.cpsp);
          setCop(rowData?.especialista_evaluador?.cop);
          setSelectedImage(rowData?.imagen_firma || null);
          setFoto(null); // Reiniciar el estado de Foto al editar
      }
  
      if (rowData?.tipo_especialista_id) {
          tiposEspecialistas?.forEach((elemento) => {
              if (rowData?.tipo_especialista_id === elemento.id) {
                  setTipoEspecialista(elemento.id);
              }
          });
      }
  }, [rowData]);
  

   const validateForm = () => {
      if (!Apellidos || !Nombres || !Direccion || !Email || !PostFirma || !tipoEspecialista) {
         showToast("error", "Campos requeridos", "Por favor complete todos los campos obligatorios.");
         return false;
      }
      return true;
   };

   const CrearEvaluador = () => {
      if (!validateForm()) {
          return; // Detener la ejecución si los campos no son válidos
      }
  
      const formData = new FormData();
      formData.append("apellidos", Apellidos);
      formData.append("nombres", Nombres);
      formData.append("direccion", Direccion);
      formData.append("telefono", Telefono);
      formData.append("email", Email);
      formData.append("pos_firma", PostFirma);
      formData.append("tipo_especialista_id", tipoEspecialista);
      formData.append("evaluador_id", null);
      formData.append("rnm", Rnm);
      formData.append("rne", Rne);
      formData.append("rna", Rna);
      formData.append("cbp", Cbp);
      formData.append("ctmp", Ctmp);
      formData.append("cmp", Cmp);
      formData.append("especialista_id", especialistaID);
      formData.append("cpsp", Cpsp);
      formData.append("cop", Cop);
      formData.append("lab_especialista_id", LabEspecialista);
      
      // Si no se selecciona una nueva imagen, conservar la imagen existente
      if (titulo === "Editar evaluador" && !selectedImage) {
          formData.append("imagen_firma", rowData?.imagen_firma);
      } else {
          formData.append("imagen_firma", Foto);
      }
  
      formData.append("estado_registro", "A");
  
      if (titulo === "Crear evaluador") {
          createEvaluador(formData, limpiar);
          showToast(
              "success",
              `Creado correctamente`,
              `Se creó el médico evaluador`
          );
      } else if (titulo === "Editar evaluador") {
          updateEvaluador(rowData.id, formData, limpiar);
          showToast(
              "success",
              `Editado correctamente`,
              `Se editó el médico evaluador`
          );
      }
  }
  

   const TipoEspecialistaChange = (e) => {
      setTipoEspecialista(e)
      setRnm("");
      setRne("");
      setRna("");

      setCmp("");
      setCtmp("");
      setCbp("");
      
      setespecialistaID("");
      setCpsp("");
      setCop("");
      setLabEspecialista("");
   }
   // const HeaderPopUp = (
   //    <div className="w-full h-auto flex">
   //       <div className="flex align-items-center justify-content-center" style={{ backgroundColor: '#EBEBFF', width: '60px', height: '60px', borderRadius: '10px' }}>
   //          <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 29 35" fill="none">
   //             <path d="M20.75 20.75V27M20.75 27V33.25M20.75 27H27M20.75 27L14.5 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
   //             <path d="M14.5 17.625C18.8147 17.625 22.3125 14.1272 22.3125 9.8125C22.3125 5.49778 18.8147 2 14.5 2C10.1853 2 6.6875 5.49778 6.6875 9.8125C6.6875 14.1272 10.1853 17.625 14.5 17.625ZM14.5 17.625C7.59644 17.625 2 21.8223 2 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
   //          </svg>
   //       </div>
   //       <div className="flex flex-column">
   //          <h1 className="tit">{titulo}</h1>
   //          <p className="con">Aquí usted puede agregar un evaluador</p>
   //       </div>
   //    </div>
   // );
   const HeaderPopUp = (
      <div className="w-full h-auto flex align-items-start gap-2">
         <div className="flex align-items-center justify-content-center" style={{ backgroundColor: '#EBEBFF', width: '60px', height: '60px', borderRadius: '10px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="42" viewBox="0 0 29 35" fill="none">
               <path d="M20.75 20.75V27M20.75 27V33.25M20.75 27H27M20.75 27L14.5 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
               <path d="M14.5 17.625C18.8147 17.625 22.3125 14.1272 22.3125 9.8125C22.3125 5.49778 18.8147 2 14.5 2C10.1853 2 6.6875 5.49778 6.6875 9.8125C6.6875 14.1272 10.1853 17.625 14.5 17.625ZM14.5 17.625C7.59644 17.625 2 21.8223 2 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
            </svg>
         </div>
         <div className="flex flex-column gap-1 pt-1">
            <h1 style={{ color: '#5555D8', fontFamily: "Montserrat", fontSize: "24px", fontWeight: "700" }}>{titulo}</h1>
            <p style={{ color: '#7B8794', fontFamily: "Montserrat", fontSize: "17px", fontWeight: "400" }}>Aquí usted puede agregar un evaluador</p>
         </div>
      </div>
   );
   return (
      <div className="contenedor-dialog">
         <Dialog
            header={HeaderPopUp}
            visible={visible}
            onHide={() => { setvisible(false); limpiar() }}
            className="PopUpCrerEditEvaluador"
            style={{ width: "80%", height: "auto" }}
         >
            <style>{`
         .PopUpCrerEditEvaluador .p-dialog-header{
            padding-bottom: 10px !important;
         }
         `}</style>
            <div className="contenedor_principal mx-3 flex mt-5">
               <div className="contenedor-crear w-full flex">
                  <div
                     style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     <label
                        htmlFor="especialista"
                        style={{
                           fontFamily: "Montserrat",
                           fontSize: "16px",
                           fontWeight: "600",
                        }}
                     >
                        Tipo de especialista:{" "}
                     </label>
                     {/* ================================== */}
                     <Dropdown
                        value={tipoEspecialista}
                        options={tiposEspecialistas}
                        optionLabel="nombre"
                        optionValue="id"
                        onChange={(e) => TipoEspecialistaChange(e.target.value)}
                        required
                        className="mx-3 w-full"
                     />
                     {/* ================================== */}

                  </div>

                  {componenteAMostrar}

                  <div className="inputs" style={{ marginTop: "20px" }}>
                     <label
                        htmlFor="apellido"
                        style={{
                           fontFamily: "Montserrat",
                           fontSize: "16px",
                           fontWeight: "600",
                        }}
                     >
                        Apellidos:{""}
                     </label>
                     <InputText
                        placeholder="Ingrese sus apellidos"
                        className="w-full"
                        style={{ margin: "0 18px 0 63px" }}
                        value={Apellidos}
                        onChange={(e) => {
                           const inputValue = e.target.value;
                           const filteredValue = inputValue.replace(/[^a-zA-ZñÑ\s]/g, '').replace(/\b\w/g, (char) => char.toUpperCase());
                           setApellidos(filteredValue);
                        }}
                     ></InputText>
                  </div>
                  <div className="inputs" style={{ marginTop: "20px" }}>
                     <label
                        htmlFor="nombre"
                        style={{
                           fontFamily: "Montserrat",
                           fontSize: "16px",
                           fontWeight: "600",
                        }}
                     >
                        Nombres:{" "}
                     </label>
                     <InputText
                        placeholder="Ingrese su(s) nombre(s)"
                        className="w-full"
                        style={{ margin: "0 18px 0 63px" }}
                        value={Nombres}
                        onChange={(e) => {
                           const inputValue = e.target.value;
                           const filteredValue = inputValue.replace(/[^a-zA-ZñÑ\s]/g, '').replace(/\b\w/g, (char) => char.toUpperCase());
                           setNombres(filteredValue);
                        }}
                     ></InputText>
                  </div>
                  <div className="inputs" style={{ marginTop: "20px" }}>
                     <label
                        htmlFor="direccion"
                        style={{
                           fontFamily: "Montserrat",
                           fontSize: "16px",
                           fontWeight: "600",
                        }}
                        value={Nombres}
                        onChange={(e) => setNombres(e.target.value)}
                     >
                        Dirección:{" "}
                     </label>
                     <InputText
                        placeholder="Ingrese su direccion"
                        className="w-full"
                        style={{ margin: "0 18px 0 63px" }}
                        value={Direccion}
                        onChange={(e) => setDireccion(e.target.value)}
                     ></InputText>
                  </div>
                  <div className="inputs inputTelefonCreatEditEvaluador flex justify-content-between" style={{ marginTop: "20px" }}>
                     <label
                        htmlFor="telefono"
                        style={{
                           fontFamily: "Montserrat",
                           fontSize: "16px",
                           fontWeight: "600",
                        }}
                     >
                        Télefono:{" "}
                     </label>
                     <InputText
                        style={{ margin: '0px 18px 0px 70px' }}
                        className="w-full"
                        value={Telefono}
                        onChange={(e) => {
                           const inputValue = e.target.value;
                           const sanitizedValue = inputValue.replace(/[^0-9+\s]/g, '');
                           setTelefono(sanitizedValue);
                        }}
                        placeholder="(+99) 999-999-999"
                     />
                  </div>
                  <div className="inputs" style={{ marginTop: "20px" }}>
                     <label
                        htmlFor="email"
                        style={{
                           fontFamily: "Montserrat",
                           fontSize: "16px",
                           fontWeight: "600",
                        }}
                     >
                        Email:{" "}
                     </label>
                     <InputText
                        placeholder="Ingrese su correo"
                        className="w-full"
                        type="email"
                        style={{ margin: "0 18px 0 95px" }}
                        value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                     ></InputText>
                  </div>
               </div>
               <div className="firma-contenedor w-full mt-2">
                  <div className="firma-img w-full">
                     <div
                        className="image-container"
                        style={{
                           margin: "5px 0 5px 0",
                           borderRadius: "15px",
                        }}
                     >
                        {selectedImage && showImage && (
                           <img
                              src={selectedImage}
                              alt="Selected"
                              className="selected-image"
                           />
                        )}
                        {!selectedImage && (
                           <div className="select-text flex flex-column">
                              <i className="pi pi-image imd" />
                              Imagen no disponible
                           </div>
                        )}
                     </div>
                  </div>
                  <div className="button-container123">
                     <input
                        id="file-input"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        ref={inputRef}
                        style={{ display: "none" }}
                     />

                     <button
                        onClick={() => inputRef.current.click()}
                        className="select-button w-12 mt-3"
                     >
                        Carga Imagen Firma
                     </button>
                     {selectedImage && (
                        <button
                           onClick={() => setShowImage(!showImage)}
                           className="select-button"
                        >
                           {showImage ? "Ocultar" : "Visualizar"}
                        </button>
                     )}
                  </div>
               </div>
            </div>
            <div className="card flex flex-column justify-content-center mt-5 mx-3">
               <label
                  htmlFor=""
                  style={{
                     color: "rgb(73, 80, 87)",
                     fontWeight: "600",
                     fontSize: "16px",
                  }}
               >
                  Post Firma
               </label>
               <InputTextarea
                  rows={5}
                  cols={30}
                  className="mt-3 textArea"
                  value={PostFirma}
                  onChange={(e) => setPostFirma(e.target.value)}
               />
            </div>

            <div className="contenedor-botones-dialog mt-5 flex justify-content-between">
               <Button
                  className="btncancelar"
                  label="Cancelar"
                  type="button"
                  style={{
                     width: "250px",
                     backgroundColor: "transparent",
                     color: "rgba(85, 85, 216, 1)",
                  }}
                  onClick={() => { setvisible(false); limpiar() }}
               ></Button>
               <Button
                  className="btncrear"
                  label="Confirmar"
                  style={{ width: "250px", marginLeft: "8px" }}
                  onClick={() => CrearEvaluador()}
               ></Button>
            </div>
            <Toast ref={toast} />
         </Dialog>
      </div>
   );
};

export default DialogCrearEvaluadores;
export const HallazgoAntPer =[
      {
        id: 144,
        code: 'NISTAGMUS ESPONTÁNEO',
        resul: '',
        Observación: '',
      },
      {
        id: 145,
        code: 'RECIBIO ENTRENAMIENTO EN PRIMEROS AUXILIOS',
        resul: '',
        Observación: '',
      },
      {
        id: 146,
        code: 'Todas las enfermedades que producen alteración de la consistencia sin importar',
        resul: '',
        Observación: '',
        
      },
      {
        id: 147,
        code: 'Alcoholismo crónico y en general todas aquellas enfermedades que producen',
        resul: '',
        Observación: '',
      },
      /* {
        id: 149,
        code: 'Espontáneo',
        resul: '',
        Observación: '',
      }, */
      {
        id: 148,
        code: 'Alteración de la coordinación presente (dedo nariz)',
        resul: '',
        Observación: '',
      },
      {
        id: 149,
        code: 'Alteración presente del equilibrio',
        resul: '',
        Observación: '',
        
      },
      {
        id: 150,
        code: 'Limitación en fuerza y/o movilidad de extremidades (Mayor a 20g / fuerza cada mano)',
        resul: '',
        Observación: '',
      },
      {
        id: 151,
        code: 'Anormalidad en movimientos oculares',
        resul: '',
        Observación: '',
      },
      {
        id: 152,
        code: 'Anormalidad del lenguaje',
        resul: '',
        Observación: '',
      },
      {
        id: 153,
        code: 'Alguno de los parámetros de la evaluación psicomometrica alterada',
        resul: '',
        Observación: '',
      },
      {
        id: 154,
        code: 'Hipoacusia con compromiso de frecuencia conversacionales con promedio mayor de 40 db uni o',
        resul: '',
        Observación: '',
      },
      {
        id: 155,
        code: 'Movimientos involuntarios y/o asimetria facial',
        resul: '',
        Observación: '',
      },
      {
        id: 156,
        code: 'Puplas no CIRLA',
        resul: '',
        Observación: '',
        
      },
      {
        id: 157,
        code: 'Personas que consumen sustancias estupefacientes o psicotrópicas en niveles que no alteren su capacidad',
        resul: '',
        Observación: '',
        
      },
      {
        id: 158,
        code: 'Sindrome Apnea Obstructiva del sueño sospecha o confirma (Ficha SAS)',
        resul: '',
        Observación: '',
        
      },
      {
        id: 159,
        code: 'Personas que consumen sustancias estupefacientes o psicotrópicas en niveles que no alteren su capacidad',
        resul: '',
        Observación: '',
        
      }, 
      {
        id: 160,
        code: 'RECIBO ENTRENAMIENTO EN PRIMEROS AUXILIOS',
        resul: '',
        Observación: '',
        
      },
      {
        id: 161,
        code: 'Todas aquellas enfermedades que se caractericen por movimientos involuntarios y que interfieran',
        resul: '',
        Observación: '',
        
      },
      {
        id: 162,
        code: 'Perdida recurrente de la conciencia, independiente de su tratamiento, tales como narcolepsia',
        resul: '',
        Observación: '',
        
      },
      {
        id: 163,
        code: 'Alcoholismo crónico y en general todas aquellas enfermedades que producen',
        resul: '',
        Observación: '',
      }, 
      {
        id: 164,
        code: 'Anemia de cualquier grado, según cruterios OMS 2011.',
        resul: '',
        Observación: '',
        
      },
      /* {
        id: 167,
        code: 'Todas las enfermedades que producen alteración de la consistencia sin importar',
        resul: '',
        Observación: '',
        
      }, */
      {
        id: 165,
        code: 'NEUROLOGICO',
        resul: '',
        Observación: '',
        
      },
      {
        id: 166,
        code: 'OSTEOMUSCULAR',
        resul: '',
        Observación: '',
        
      },
      /* {
        id: 170,
        code: 'CARDIOMUSCULAR',
        resul: '',
        Observación: '',
        
      }, */
      {
        id: 167,
        code: 'CARDIOMUSCULAR',
        resul: '',
        Observación: '',
        
      },
      /* {
        id: 172,
        code: 'NEUROLOGICO',
        resul: 'Normal',
        Observación: '',
        
      }, */
      /* {
        id: 173,
        code: 'EXTREMIDADES',
        resul: 'Normal',
        Observación: '',
        
      }, */
      {
        id: 168,
        code: 'EXTREMIDADES',
        resul: 'Normal',
        Observación: '',
        
      },
      /* {
        id: 175,
        code: 'OSTEOMUSCULAR',
        resul: 'Normal',
        Observación: '',
        
      } */
    ]
import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";

export default function LaboratorioTabla({setValue,value,create}) {
    return (
        <div className="flex" style={{ justifyContent: 'space-between' }}>
            <div className="flex gap-3" style={{ alignItems: 'center' }}>
                <span>Observaciones:</span>
                <InputText value={value} onChange={(e) => setValue(0,e.target.value)} />
            </div>
            <div className="flex gap-3">
                <Button label="Grabar" icon="fas fa-save" onClick={create} />
                <Button label="Recuperar" icon="fas fa-undo" />
                {/* <Button label="Hematologo" icon="fas fa-user-md" /> */}
            </div>
        </div>
    );
}
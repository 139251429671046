import React, { useState } from "react";
import { Button } from "primereact/button";
import FirmHuellaConsent from "./FirmaConsent";
// import CamaraConsent from "./CamaraComponent";
import firma from "../../../Images/atencion/Recepcion/firma.svg";
import huella from "../../../Images/atencion/Recepcion/huella.svg";
import "../style.css";
import "../../../Style/ConsentimientoInformado/Identificacion.css"
import HuellaConsent from "./HuellaConsent";

const Identificacion = ({ paciente, datoPacienteX }) => {
   const [activeComponent, setActiveComponent] = useState(null);
   const components = [
      {
         name: "Firma",
         image: firma,
         component: <FirmHuellaConsent titulo="Firma" imagen={firma} Paciente={paciente} />,
      },
      {
         name: "Huella",
         image: huella,
         component: <HuellaConsent titulo="Huella" imagen={huella} paciente={paciente} />,
      },
   ];
   return (
      <div className="flex flex-wrap flex-column gap-5 w-full">

         <div className="w-full flex justify-content-center align-items-center flex-row gap-2">
            {components.map((comp, index) => (
               <Button className="w-6 max-w-6rem boton-color flex justify-content-center" key={index} onClick={() => setActiveComponent(comp.component)}>
                  {comp.name}
               </Button>
            ))}
            {/* <Button className="btn btn-primary" onClick={guardarDatos}>Guardar</Button> */}
         </div>
         {activeComponent}
      </div>
   )

}
export default Identificacion;
import React, { useState, useEffect, useRef, useContext } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import "../../../Style/OftAntSistForm.css";
import { OftContext } from "../../../Page/Oftalmologia/OftContext";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";

const cities = [
   { label: "No", id: "NY" },
   { label: "Si", id: "RM" },
];


const OftAntSistForm = () => {
   const { anteContext, setAnteContext } = useContext(OftContext);
   const { oft_antecedente_sistematico } = anteContext;
   let { pageViewContext, stateAtencion } = useContext(RAContext);

   const handleChangePatologia = (index) => {
      let oft_sistematico_patologia = anteContext?.oft_sistematico_patologia?.map((item, i) => {
         if (i === index) {
            return { ...item, select: !item.select };
         }
         return item;
      });
      setAnteContext({
         ...anteContext,
         oft_sistematico_patologia: oft_sistematico_patologia
      });
   };

   const handleChangeOtros = () => {
      setAnteContext({
         ...anteContext,
         oft_sistematico_otro: {
            comentarios: anteContext?.oft_sistematico_otro?.comentarios,
            select: !anteContext?.oft_sistematico_otro?.select,
         }
      });
   };

   const handleDropdownChangeInput = (comentarios) => {
      setAnteContext({
         ...anteContext,
         oft_sistematico_otro: {
            ...anteContext?.oft_sistematico_otro,
            comentarios: comentarios,
         },
      });
   };
   const [hasContent, setHasContent] = useState(false);


   const handleDropdownChange = (fieldName, value) => {
      setAnteContext({
         ...anteContext,
         oft_antecedente_sistematico: {
            ...oft_antecedente_sistematico,
            [fieldName]: value,
         },
      });
   };

   const [formValues, setFormValues] = useState({
      comentarios: "",
      value: "",
      value1: "",
      checked: false,
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      inputDisabled: true,
      conductor: null,
      correctores: null,
      speechRecognition: null,
      isListening: false,
      inputRef: useRef(null),
   });

   const [voiceInput, setVoiceInput] = useState("");

   const startListening = () => {
      const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
      recognition.lang = "es-ES";

      recognition.onstart = () => {
         setFormValues({ ...formValues, isListening: true });
      };

      recognition.onend = () => {
         setFormValues({ ...formValues, isListening: false });
      };

      recognition.onresult = (event) => {
         const transcript = event.results[0][0].transcript;
         if (typeof handleDropdownChangeInput === "function") {
            let comentario = anteContext?.oft_sistematico_otro?.comentarios + transcript;
            handleDropdownChangeInput(comentario);
            setVoiceInput(transcript);
         }
      };

      recognition.start();
      setFormValues({ ...formValues, speechRecognition: recognition });
   };

   const stopListening = () => {
      if (formValues.speechRecognition) {
         formValues.speechRecognition.stop();
         setFormValues({ ...formValues, isListening: false });
      }
   };

   useEffect(() => {
      // Puedes agregar lógica adicional aquí si es necesario
   }, []);

   return (
      <>
         <div className="contenedor-Ant">
            <div className="flex">
               <div className="flex w-full gap-3 flex-wrap justify-content-between">
                  {anteContext.oft_sistematico_patologia.map((antecedentes, index) => (
                     <div key={index} className="flex cont-checkb">
                        <Checkbox
                           inputId={`hta_${index}`}
                           checked={antecedentes.select}
                           onChange={() => handleChangePatologia(index)}
                           disabled={stateAtencion[pageViewContext].estado === 3}
                        />
                        <label className="label-1">{antecedentes.nombre}</label>
                     </div>
                  ))}
               </div>
            </div>

            <div className="flex flex-row align-items-center grup-2">
               <div className="flex flex-row align-items-center gap-3 cont-checkb-1">
                  <Checkbox
                     className="checkbox-otros"
                     checked={anteContext?.oft_sistematico_otro?.select}
                     onChange={() => handleChangeOtros()}
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
                  <label className="label-2">Otros</label>
               </div>
               <div className="flex w-full cont-coment" style={{ marginTop: "40px" }}>
                  <InputTextarea
                     autoResize
                     className="flex w-full gap-3 coment input-textarea-no-scroll"
                     // value={hasContent ? anteContext?.oft_sistematico_otro.comentarios : "Comentario"}
                     value={anteContext?.oft_sistematico_otro.comentarios}
                     onChange={(e) => {
                        handleDropdownChangeInput(e.target.value);
                        if (e.target.value !== "") {
                           setHasContent(true); // Indicar que el contenido ha sido modificado por el usuario
                        }
                     }}
                     rows={5}
                     cols={30}
                     disabled={!anteContext?.oft_sistematico_otro?.select || stateAtencion[pageViewContext].estado === 3}
                     placeholder="Otros antecedentes..."
                     style={{ height: "86px", paddingRight: "36px" }}
                  />

                  <div
                     className="flex flex-column justify-content-end"
                     style={{ marginLeft: "-40px", marginBottom: "5px" }}
                  >
                     <button
                        className="flex flex-row justify-content-center"
                        onClick={formValues.isListening ? () => { stopListening(); setVoiceInput(""); } : startListening}
                        style={{
                           background: "#5555D8",
                           color: "white",
                           width: "36px",
                           borderRadius: "6px",
                           border: "1px solid #6366F1",
                           cursor: "pointer",
                        }}
                     >
                        {formValues.isListening ? "Off" : "On"}
                     </button>
                  </div>
               </div>
            </div>

            <div className="flex grup-3" style={{ marginBottom: "30px", marginTop: "30px" }}>

               <div className="flex part1">
                  <label className="label-3">Conductor Vehi­culo Empresa</label>
                  <div className="sn1">
                     <Dropdown
                        className="drop"
                        value={oft_antecedente_sistematico.conductor}
                        onChange={(e) => handleDropdownChange("conductor", e.value)}
                        options={cities}
                        optionValue="label"
                        style={{ width: "98px", height: "40px" }}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
               </div>

               <div className="flex part2">
                  <label className="label-3">Usa correctores</label>
                  <div className="sn2">
                     <Dropdown
                        className="drop"
                        value={oft_antecedente_sistematico.correctores}
                        onChange={(e) => handleDropdownChange("correctores", e.value)}
                        options={cities}
                        optionValue="label"
                        style={{ width: "98px", height: "40px", textAlign: "center" }}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
               </div>

               <div className="flex part3">
                  <label className="label-4">Tipo</label>
                  <div className="coment2">
                     <InputText
                        className="text-tipo"
                        value={oft_antecedente_sistematico.tipo}
                        onChange={(e) => handleDropdownChange("tipo", e.target.value)}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default OftAntSistForm;

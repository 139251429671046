import React, { useContext } from 'react';
import Atenc from '../../../Images/Pure/MenuPrincipal/Img/Aten.png'
import aten1 from '../../../Images/Pure/MenuPrincipal/img-icons/aten1.png'
import aten2 from '../../../Images/Pure/MenuPrincipal/img-icons/aten1.png'
import aten3 from '../../../Images/Pure/MenuPrincipal/img-icons/aten3.png'
import aten4 from '../../../Images/Pure/MenuPrincipal/img-icons/aten4.png'
import aten5 from '../../../Images/Pure/MenuPrincipal/img-icons/aten5.png'
import aten6 from '../../../Images/Pure/MenuPrincipal/img-icons/aten6.png'
import aten7 from '../../../Images/Pure/MenuPrincipal/img-icons/aten7.png'
import aten8 from '../../../Images/Pure/MenuPrincipal/img-icons/aten8.png'
import aten9 from '../../../Images/Pure/MenuPrincipal/img-icons/aten9.png'

import '../Atenciones/Recepcion/pagerecepcion.css';
import { Link } from 'react-router-dom';
import { RAContext } from '../../RutaAtencion/RAContext';

function PageAtencionesB({ accesoProp }) {
   // Definir un array de objetos con la información de las áreas médicas
   sessionStorage.setItem('accesos', JSON.stringify(accesoProp));
   const { accesos, setAccesos } = useContext(RAContext)

   setAccesos(accesoProp)

   const list_images = (url) => {
      switch (url) {
         case "triaje":
            return aten2;
         case "psicología":
            return aten3;
         case "ekg":
            return aten4;
         case "oftalmologia":
            return aten5;
         case "audiometria":
            return aten6;
         case "radiologia":
            return aten7;
         case "odontologia":
            return aten7;
         case "espirometria":
            return aten9;
         default:
            return aten1;
      }
   }

   const areasMedicas = [
      {
         nombre: 'Triaje',
         imagen: aten2,
      },
      {
         nombre: 'Oftalmología',
         imagen: aten5,
      },
      {
         nombre: 'EKG',
         imagen: aten4,
      },
      {
         nombre: 'Psicología',
         imagen: aten3,
      },
      {
         nombre: 'Audiometría',
         imagen: aten6,
      },
      {
         nombre: 'Rayos X',
         imagen: aten7,
      },
      {
         nombre: 'Medicina General',
         imagen: aten8,
      },
      {
         nombre: 'Espirometría',
         imagen: aten9,
      },
      {
         nombre: 'Laboratorio',
         imagen: aten1,
      },
   ];

   return (
      <div>
         <div>
            <div className="containerPrincipalAtenciones">
               <img src={Atenc} alt='img' />
               <div className='textContainerAtenciones'>
                  <p className='textpContainerAtenciones'>Áreas Médicas</p>
                  <p className='texth5ContainerAtenciones'>Selecciona tu área indicada</p>
                  <div className='containerAreasMedicas'>
                     {
                        accesoProp.map((acceso, key) => {
                           return <>
                              <div key={key} className='containerAtenciones'>
                              <Link to={acceso.url} className="no-underline containerAtenciones">
                                    <img className='Atenimg' src={list_images(acceso.url)} alt='img' />
                                    <p>{acceso.label}</p>
                                 </Link>
                              </div>
                           </>
                        })
                     }

                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default PageAtencionesB;

import React, { useState, useRef, useContext, useEffect } from "react";
import { Toast } from "primereact/toast";
import { Steps } from "primereact/steps";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import DatosGenerales from "../../DatosGenerales";
import "../Style/Recepcion.css";
import "../../style.css";
import { RecepcionContext } from "./RecepcionProvider";
import AuthUser from "../../../../AuthUser";
import DatosPaciente from "../../DatosPaciente";
import DatosPacienteParticular from "../../DatosPacienteParticular";

const ModalCrear = ({ setVisibleCrear, visibleCrear, showRecepcionComponentToast, refreshTable, getPacientes }) => {
    const { http } = AuthUser();
    const [selectedRadioButton, setSelectedRadioButton] = useState(1);
    const toast = useRef(null)
    const { datosGenerales, setCamposInvalidos, setMostrarError, setDatosGenerales, setEmergencia, setSeleccion, datosGeneralesParticular, setDatosGeneralesParticular } = useContext(RecepcionContext);
    const showToast = (severity, summary, message) => {
        toast.current.show({
            severity: severity,
            summary: summary,
            detail: message
        });
    };
    const handleGuardar = () => {
        if (selectedRadioButton === 2) {
            Guardarconsola();
            setDatosGeneralesParticular({
                tipo_documento_id: null,
                numero_documento: '',
                nombres: '',
                apellido_paterno: '',
                apellido_materno: '',
                sexo_id: null,
                fecha_nacimiento: '',
                distrito_id: null,
                religion: null,
                distrito_domicilio_id: null,
                direccion: '',
                estado_civil_id: null,
                grado_instruccion_id: null,
                correo: '',
                telefono: '',
                celular: '',
                telefono_emergencia: '',
                clinica_local_id: null,
                perfil_particular_id: null,
                empresa_cargo_id: null,
                empresa_id: null,
                apoderado: null
            });

        } else if (selectedRadioButton === 1) {
            postDatosPaciente()
            setDatosGenerales({
                tipo_documento_id: null,
                numero_documento: '',
                nombres: '',
                apellido_paterno: '',
                apellido_materno: '',
                sexo_id: null,
                fecha_nacimiento: '',
                distrito_id: null,
                religion: null,
                distrito_domicilio_id: null,
                direccion: '',
                estado_civil_id: null,
                grado_instruccion_id: null,
                correo: '',
                telefono: '',
                celular: '',
                telefono_emergencia: '',
                clinica_local_id: null,
                perfil_tipo_id: null,
                empresa_cargo_id: null,
                empresa_id: null,
            });

        }
        setEmergencia({
            Codigo: '',
            seguro: '',
            provincia_id: null,
            departamento_id: null,
            departamento: {},
            provincia: {},
            provinciaDom_id: null,
            departamentoDom_id: null,
            departamentoDom: {},
            provinciaDom: {},
            edad: '',
            Registro: '',
        })
        // refreshTable();
        setSeleccion(false);
        setVisibleCrear(false);
        setActiveStep(0)
    };
    useEffect(() => {
        setActiveStep(0);
    }, [setVisibleCrear]);

    const handleCerrar = () => {
        setVisibleCrear(false);
        setDatosGenerales({
            tipo_documento_id: null,
            numero_documento: '',
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            sexo_id: null,
            fecha_nacimiento: '',
            distrito_id: null,
            religion: null,
            distrito_domicilio_id: null,
            direccion: '',
            estado_civil_id: null,
            grado_instruccion_id: null,
            correo: '',
            telefono: '',
            celular: '',
            telefono_emergencia: '',
            clinica_local_id: null,
            perfil_tipo_id: null,
            empresa_cargo_id: null,
            empresa_id: null
        });

        setDatosGeneralesParticular({
            tipo_documento_id: null,
            numero_documento: '',
            nombres: '',
            apellido_paterno: '',
            apellido_materno: '',
            sexo_id: null,
            fecha_nacimiento: '',
            distrito_id: null,
            religion: null,
            distrito_domicilio_id: null,
            direccion: '',
            estado_civil_id: null,
            grado_instruccion_id: null,
            correo: '',
            telefono: '',
            celular: '',
            telefono_emergencia: '',
            clinica_local_id: null,
            perfil_particular_id: null,
            empresa_cargo_id: null,
            empresa_id: null,
            apoderado: null
        });

        setEmergencia({
            Codigo: '',
            seguro: '',
            provincia_id: null,
            departamento_id: null,
            departamento: {},
            provincia: {},
            provinciaDom_id: null,
            departamentoDom_id: null,
            departamentoDom: {},
            provinciaDom: {},
            edad: '',
            Registro: '',
        });

        setSeleccion(false);
        setActiveStep(0)
    };

    const postDatosPaciente = () => {
        const datosEnviar = { ...datosGenerales };

        delete datosEnviar.dristrito;
        delete datosEnviar.distrito_domicilio;
        delete datosEnviar.perfil_tipo;
        delete datosEnviar.empresa_cargo;
        delete datosEnviar.empresa;


        http
            .post('/clinica/empresa/personal/create', datosEnviar)
            .then((response) => {
            })
            .catch((error) => {
                console.error('Error al enviar los datos del paciente:', error);
            });

    };

    const Guardarconsola = () => {
        const datosEnviar = { ...datosGeneralesParticular };

        // Eliminar propiedades no deseadas
        delete datosEnviar.apellidoPaterno;
        delete datosEnviar.perfil_particular;
        delete datosEnviar.tipo_evaluacion_id;
        delete datosEnviar.numeroDocumento;
        delete datosEnviar.selectedTipoDocumento;
        delete datosEnviar.dristrito;
        delete datosEnviar.distrito_domicilio;
        delete datosEnviar.protocolo

        if (datosEnviar.tipo_documento_id && datosEnviar.tipo_documento_id.id) {
            datosEnviar.tipo_documento_id = datosEnviar.tipo_documento_id.id;
        }

        http
            .post('clinica/paciente/particular/create', datosEnviar)
            .then((response) => {
                showRecepcionComponentToast('success', 'Éxito', response.data.resp)
                getPacientes();
            })
            .catch((error) => {
                console.error('Error al enviar los datos del paciente:', error);
                showRecepcionComponentToast('error', 'Error', `Por favor complete todos los campos obligatorios: ${error}`);
            });
    }

    const calcularEdad = (fechaNacimiento) => {
        const today = new Date();
        const birthDate = new Date(fechaNacimiento);
        const difference = today.getTime() - birthDate.getTime();
        const ageDate = new Date(difference);
        const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        return calculatedAge;
    };

    const validarCamposEmpresa = () => {
        const camposObligatorios = [
            'tipo_documento_id',
            'numero_documento',
            'nombres',
            'apellido_paterno',
            'apellido_materno',
            'sexo_id',
            'fecha_nacimiento',
            'distrito_id',
            'dristrito',
            'religion_id',
            'distrito_domicilio_id',
            'distrito_domicilio',
            'direccion',
            'estado_civil_id',
            'grado_instruccion_id',
            'correo',
            'telefono',
            'celular',
            'telefono_emergencia',
            'clinica_local_id',
            // 'perfil_tipo_id',
            // 'perfil_tipo',
            // 'empresa_cargo_id',
            // 'empresa_cargo',
            // 'empresa_id',
            // 'empresa'
        ];


        const camposInvalidos = camposObligatorios.filter(campo => !datosGenerales[campo]);

        setCamposInvalidos(camposInvalidos);

        const camposSonValidos = camposInvalidos.length === 0;
        setMostrarError(!camposSonValidos);
        if (!camposSonValidos) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Por2 favor complete todos los campos obligatorios.' });
        }
        return camposSonValidos;
    };

    const validarCampos = () => {
        const camposObligatorios = [
            'tipo_documento_id',
            'numero_documento',
            'nombres',
            'apellido_paterno',
            'apellido_materno',
            'clinica_local_id',
            'perfil_particular_id',
            'sexo_id',
            'fecha_nacimiento',
            'correo',
            'celular',
            'apoderado'
        ];

        const esMenorDeEdad = calcularEdad(datosGeneralesParticular.fecha_nacimiento) < 18;

        if (!esMenorDeEdad) {
            camposObligatorios.splice(camposObligatorios.indexOf('apoderado'), 1);
        }

        const camposInvalidos = camposObligatorios.filter(campo => !datosGeneralesParticular[campo]);

        setCamposInvalidos(camposInvalidos);

        const camposSonValidos = camposInvalidos.length === 0;
        setMostrarError(!camposSonValidos);
        if (!camposSonValidos) {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Por 3 favor complete todos los campos obligatorios.' });
        }
        return camposSonValidos;
    };

    const [activeStep, setActiveStep] = useState(0);

    const items = [
        {
            label: "Datos Generales",
        },
        {
            label: "Datos del Paciente",
        },
    ];

    const handleStepChange = (index) => {
        if (activeStep !== 0 && activeStep !== 1) {
            return; // No permitir el cambio de paso
        }
        setActiveStep(index);  // Actualiza activeStep con el mismo índice
    };

    const handleRegresar = () => {
        if (activeStep > 0) {
            setActiveStep(activeStep - 1);
        }
    };

    const paqueteCreateDialogFooter = (
        <div className="w-full flex justify-content-end">
            {activeStep === 0 ? ( // Paso 0: Datos Generales
                <Button
                    type="submit"
                    label="Siguiente"
                    className="pt-3 pb-3"
                    style={{
                        backgroundColor: "#5555D8",
                        borderColor: "#5555D8",
                    }}
                    onClick={() => {
                        setActiveStep(activeStep + 1);
                    }}
                />
            ) : ( // Paso 1: Datos del Paciente
                <>

                    <Button
                        type="button"
                        label="Cancelar"
                        className="boton01 pt-3 pb-3"
                        onClick={handleCerrar}
                    />
                    <Button
                        type="button"
                        label="Regresar"
                        className="boton01 pt-3 pb-3"
                        onClick={handleRegresar}
                    />
                    <Button
                        type="submit"
                        label="Guardar"
                        className="pt-3 pb-3"
                        style={{
                            backgroundColor: "#5555D8",
                            borderColor: "#5555D8",
                        }}
                        onClick={() => {

                            if (activeStep === 1) {
                                let camposSonValidos;
                                if (selectedRadioButton === 1) {
                                    camposSonValidos = validarCamposEmpresa();
                                } else if (selectedRadioButton === 2) {
                                    camposSonValidos = validarCampos();
                                }
                                if (camposSonValidos) {
                                    handleGuardar();
                                } else {
                                    showToast('error', 'Error', 'Por favor complete todos los campos obligatorios.', camposSonValidos);
                                }

                            } else {
                                setActiveStep(activeStep + 1);
                            }
                        }}
                    />
                </>
            )}
        </div>
    );

    return (
        <div>
            <Dialog
                visible={visibleCrear}
                style={{ width: "80%" }}
                onHide={() => { handleCerrar(); }}
                footer={paqueteCreateDialogFooter}
                header={
                    <div className="flex flex-row justify-content-center align-items-center gap-3 pl-2">
                        <div
                            className="flex justify-content-center align-items-center"
                            style={{ backgroundColor: "#EBEBFF", width: "50px", height: "50px", borderRadius: "10px" }}
                        >
                            <img
                                src="https://cdn.discordapp.com/attachments/1078050726217011331/1090321659044573235/Group_1920.png"
                                alt=""
                            />
                        </div>
                        <p className="tituloGeneral w-full">Crear atención</p>
                    </div>
                }
            >
                <div className="barra_c mt-2">
                    <Toast ref={toast}></Toast>
                    <Steps
                        model={items}
                        activeIndex={activeStep}
                        onSelect={handleStepChange}
                    />
                </div>
                <Toast ref={toast}></Toast>
                {activeStep === 0 && <DatosGenerales selectedRadioButton={selectedRadioButton} setSelectedRadioButton={setSelectedRadioButton} />}
                {activeStep === 1 && selectedRadioButton === 1 && (
                    <DatosPaciente />
                )}
                {activeStep === 1 && selectedRadioButton === 2 && (
                    <DatosPacienteParticular
                        calcularEdad={calcularEdad} />
                )}
            </Dialog >
        </div>
    );
};

export default ModalCrear;
import React, { useState, useEffect, useContext } from "react";
import "../../../Style/ExcelTablee.css";
import "../../../Style/MedicinaGeneral/ExplPalpacion.css";
import CardExamenFisico from "../CardExamenFisico";
import CardExamenFisico2 from "../CardExamenFisico2";
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";
import VoiceInputTextarea from "../../ButtonVoice/VoiceInputTextarea";
import { Dropdown } from "primereact/dropdown";
import CardMedicinaGeneral from "../CardMedicinaGeneral";

export default function TablaEXPL(props) {
    const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);




    const updateContextBase = (nuevoValor, propiedad, ind) => {
        setExploracionDataContext((prev) => ({
            ...prev,
            med_exploracion_patologias: prev.med_exploracion_patologias.map((item, index) => {
                if (index === ind) {
                    return {
                        ...item,
                        [propiedad]: nuevoValor
                    };
                }
                return item;
            })
        }));
    };

    const [Patologias, setPatologias] = useState([
        { id: 49, Nom_Patologia: "Cervical" },
        { id: 50, Nom_Patologia: "Dorsal" },
        { id: 51, Nom_Patologia: "Lumbar" },

        { id: 54, Nom_Patologia: "Cervical - Flexión" },
        { id: 55, Nom_Patologia: "Cervical - Extensión" },
        { id: 56, Nom_Patologia: "Cervical - Laterización Derecho" },
        { id: 57, Nom_Patologia: "Cervical - Laterización Izquierdo" },
        { id: 58, Nom_Patologia: "Cervical - Rotación Derecho" },
        { id: 59, Nom_Patologia: "Cervical - Rotación Izquierda" },
        { id: 60, Nom_Patologia: "Cervical - Irradiación" },
        { id: 61, Nom_Patologia: "Lumbar - Flexión" },
        { id: 62, Nom_Patologia: "Lumbar - Extensión" },
        { id: 63, Nom_Patologia: "Lumbar - Laterización Derecho" },
        { id: 64, Nom_Patologia: "Lumbar - Laterización Izquierdo" },
        { id: 65, Nom_Patologia: "Lumbar - Rotación Derecho" },
        { id: 66, Nom_Patologia: "Lumbar - Rotación Izquierda" },
        { id: 67, Nom_Patologia: "Lumbar - Irradiación" },

        { id: 68, Nom_Patologia: "Lasègue Derecho" },
        { id: 69, Nom_Patologia: "Lasègue Izquierdo" },
        { id: 70, Nom_Patologia: "Schover" },

    ])
    const OpcionesExploracion = [
        { name: 'Normal', code: 'N' },
        { name: 'Presente', code: 'P' },
        { name: 'No realizada', code: 'R' }
     ];
    return (
        //Contenedor
        <div className="cardPalpacionContenido">
            <h2 className="cardPalpacionTitulo">
                EXPLORACIÓN
            </h2>
            <table className="cardPalpacionTabla pl-4 pr-4 pb-4" >
                <thead>
                    <tr>
                        <th className="cardPalpacionTablaTitulo" style={{ width: "270px", textAlign: 'center' }}> Patología</th>
                        <th className="cardPalpacionTablaTitulo" style={{ textAlign: 'center' }}>Resultado</th>
                        <th className="cardPalpacionTablaTitulo" style={{ textAlign: 'center' }}>Observación</th>
                    </tr>
                </thead>
                <tbody className="pb-3">
                    {exploracionDataContext.med_exploracion_patologias.map((item, index) => {
                        let namePatologia = "";
                        Patologias.forEach((key) => {
                            if (key.id === item.patologia_id) {
                                namePatologia = key.Nom_Patologia;
                            }
                        });

                        return (
                            <CardMedicinaGeneral
                                key={index}
                                context={exploracionDataContext}
                                tituloSeccion={`${index + 1}.- ${namePatologia}`}
                                PadreContext={exploracionDataContext?.med_exploracion_patologias[index]}
                                updateContext={updateContextBase}
                                index={index}
                                opciones={OpcionesExploracion}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div >
    );
}

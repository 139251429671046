//Importaciones de componentes generales
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";

//Componentes generales
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import { updateName } from "../../../../Redux/authSlice";
import AuthUser from "../../../../AuthUser";

//Imágenes
import icono from "../../../../Images/Perfil/icon/ActualizarUsuario.svg";

//Importaciones de estilos propios
import "../../../../Style/usuario/formulario-persona.css";
import '../style/UpdatePersona.css'

// Componente principal para actualizar la información de la persona
export default function UpdatePersonaComponent({ personaUpdate, visible, setVisible, showToast, institucion }) {
   const { http } = AuthUser();
   const toast = useRef(null);
   const dispatch = useDispatch();

   // Valores iniciales del formulario
   const initialValues = {
      id: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      celular: "",
      correo: "",
      foto: "",
   };

   // Función para ocultar el cuadro de diálogo
   const HideDialogEdit = () => {
      setVisible(false);
   };

   // Configuración de Formik para manejar el formulario
   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: async (data) => {
         dispatch(startLoading());
         try {
            const response = await http.post(`persona/update/${personaUpdate?.id}`, data)
            if (response?.data?.resp) {
               HideDialogEdit()
               dispatch(updateName(response?.data?.persona));
               showToast(
                  "success",
                  "Actualizado",
                  "Datos actualizados correctamente"
               );
            } else if (response?.data?.error) {
               showToast('error', 'Error', response.data.error)
            }
         } catch (error) {
            showToast('error', 'Error', 'Contacte a soporte técnico')
            console.error(error)
         } finally {
            dispatch(endLoading())
         }
      },
      validate: (data) => {
         let errors = {}
         // Validación de campos obligatorios
         if (!data.nombres) {
            errors.nombres = "Nombre requerido"
         }
         if (!data.apellido_paterno) {
            errors.apellido_paterno = "Apellido paterno requerido"
         }
         if (!data.apellido_materno) {
            errors.apellido_materno = "Apellido materno requerido"
         }
         return errors
      }
   });

   // Función para verificar si un campo del formulario es válido
   const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

   // Función para obtener el mensaje de error del campo del formulario
   const getFormErrorMessage = (name) => {
      return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
   };

   // Función para actualizar los valores del formulario con la información de la persona
   const updatePersonal = () => {
      formik.setValues({
         id: personaUpdate?.id,
         nombres: personaUpdate?.nombres,
         apellido_paterno: personaUpdate?.apellido_paterno,
         apellido_materno: personaUpdate?.apellido_materno,
         celular: personaUpdate?.celular,
         correo: personaUpdate?.correo,
         foto: personaUpdate?.foto,
      })
   }

   // Efecto para actualizar los valores del formulario al montar el componente
   useEffect(() => {
      updatePersonal()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visible])

   const [InputCel, setInputCel] = useState(false);
   const [InputCorreo, setInputCorreo] = useState(false);

   let celular = personaUpdate?.celular ? personaUpdate?.celular : formik.values?.celular;
   let correo = personaUpdate?.correo ? personaUpdate?.correo : formik.values?.correo;
   let nombres = personaUpdate?.nombres ? personaUpdate?.nombres : formik.values?.nombres;
   let apellidoP = personaUpdate?.apellido_paterno ? personaUpdate?.apellido_paterno : formik.values?.apellido_paterno;
   let apellidoM = personaUpdate?.apellido_materno ? personaUpdate?.apellido_materno : formik.values?.apellido_materno;

   return (
      <div>
         <form id="persona-form-edit" className="persona-form-edit" noValidate onSubmit={formik.handleSubmit}></form>
         <Dialog
            header={<Header icono={icono} titulo={institucion} subtitulo={"Complete la información requerida para el administrador de la empresa."} />}
            visible={visible} className="form-container" style={{ width: '50vw', maxWidth: "446px", height: 'auto' }}
            appendTo={document.getElementById("persona-form-edit")}
            dismissableMask
            footer={<Footer type={"submit"} onClickCancelar={() => setVisible(false)} label={"Actualizar"} />}
            onHide={() => { HideDialogEdit(); setInputCorreo(false); setInputCel(false) }}
         >

            <div className="Contenedor_input_POPUP">
               <div className="Inputss_POPUP_Update">
                  <label htmlFor="nombres">(*) Nombres</label>
                  <InputText
                     id="nombres"
                     name="nombres"
                     defaultValue={nombres}
                     value={formik.values.nombres}
                     onChange={formik.handleChange}
                     style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                     className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                  />
                  {getFormErrorMessage('nombres')}
               </div>

               <div className="Inputss_POPUP_Update">
                  <label htmlFor="apellido_paterno">(*) Apellido Paterno</label>
                  <InputText
                     id="apellido_paterno"
                     name="apellido_paterno"
                     defaultValue={apellidoP}
                     value={formik.values.apellido_paterno}
                     style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                     className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })}
                     onChange={formik.handleChange}
                  />
                  {getFormErrorMessage('apellido_paterno')}
               </div>

               <div className="Inputss_POPUP_Update">
                  <label htmlFor="apellido_materno">(*) Apellido Materno</label>
                  <InputText id="apellido_materno" name="apellido_materno"
                     style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                     defaultValue={apellidoM}
                     value={formik.values.apellido_materno}
                     onChange={formik.handleChange}
                     className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })}
                  />
                  {getFormErrorMessage('apellido_materno')}
               </div>
               <div className="Inputss_POPUP_Update">
                  <label htmlFor="correo">Correo</label>
                  <InputText id="correo" name="correo"
                     style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                     defaultValue={correo}
                     onChange={formik.handleChange}
                     className="w-full text-area2"
                  />
               </div>
               <div className="Inputss_POPUP_Update">
                  <label htmlFor="celular">Celular</label>
                  <InputText id="celular" name="celular"
                     style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                     defaultValue={celular}
                     onChange={formik.handleChange}
                     className="w-full text-area2"
                  />
               </div>

               {InputCel && (
                  <div className="Inputss_POPUP_Update">
                     <label htmlFor="celular2">Celular secundario</label>
                     <InputText id="celular2" name="celular2"
                        style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                        className="w-full text-area2"
                     />
                  </div>)}
               {InputCorreo && (
                  <div className="Inputss_POPUP_Update">
                     <label htmlFor="correo2">Correo secundario</label>
                     <InputText id="correo2" name="correo2"
                        style={{ width: "100%", height: "40px", marginTop: "5px", padding: "5px" }}
                        className="w-full text-area2"
                     />
                  </div>)}
            </div>
         </Dialog>
         <Toast ref={toast} />
      </div>
   )
}

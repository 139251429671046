import React, { useEffect, useState, useRef } from 'react';
import CercoTipo from "../../Components/CercoPrueba/CercoTipo";
import CercoAreas from "../../Components/CercoPrueba/CercoAreas";
import CercoDiag from "../../Components/CercoPrueba/CercoDiag";
import '../../Style/cerco.css'
import CercoChart from "../../Components/CercoPrueba/CercoChart";
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
const CercoPrueba = () => {

  const toast = useRef(null);

  const [tipoAnalisis, setTipoAnalisis] = useState('');

  const [areaN1, setAreaN1] = useState([]);
  const [areaN2, setAreaN2] = useState([]);
  const [areaN3, setAreaN3] = useState([]);

  const [cercoPostRadar, setCercoPostRadar] = useState(null);
  const [cercoPostBar, setCercoPostBar] = useState({});
  const [btnState, setBtnState] = useState(false);

  const [enfermedad, setEnfermedad] = useState([]);
  const [onlyEnfermedad, setOnlyEnfermedad] = useState(null);

  const [codigo3, setCodigo3] = useState([]);
  const [codigo4, setCodigo4] = useState([]);

  const [onlyCodigo3, setOnlyCodigo3] = useState(null);
  const [onlyCodigo4, setOnlyCodigo4] = useState(null);

  const [sexo, setSexo] = useState([]);


  const [rangoEdad, setRangoEdad] = useState([]);
  const [permanencia, setPermanencia] = useState([]);

  const showToast = (tipo, titulo, detalle) => {
    toast.current.show({
      severity: tipo,
      summary: titulo,
      detail: detalle,
      life: 3000,
    });
  };

  const handlePostChartBar = () => {

    let superAreaName = areaN1.length === 0 ? null : areaN1.map(data => data.name);
    let areaName = areaN2.length === 0 ? null : areaN2.map(data => data.name);
    let subAreaName = areaN3.length === 0 ? null : areaN3.map(data => data.name);

    if (onlyEnfermedad === null) {
      if (onlyCodigo3 !== null) {
        if (superAreaName !== null) {
          if (areaName !== null) {
            if (subAreaName !== null) {
              setCercoPostBar(null)
              setBtnState(!btnState)

              const arrayData = Array(subAreaName.length).fill().map(() => Math.floor(100 * Math.random()));
              setCercoPostBar({
                area: subAreaName,
                data: arrayData
              })
            }
            else {
              setCercoPostBar(null)
              setBtnState(!btnState)

              const arrayData = Array(areaName.length).fill().map(() => Math.floor(100 * Math.random()));
              setCercoPostBar({
                area: areaName,
                data: arrayData
              })
            }
          }
          else {
            setCercoPostBar(null)
            setBtnState(!btnState)

            const arrayData = Array(superAreaName.length).fill().map(() => Math.floor(100 * Math.random()));
            setCercoPostBar({
              area: superAreaName,
              data: arrayData
            })
          }
        }
        else {
          showToast(
            'error',
            'Error al generar el gráfico.',
            'Faltan ingresar un área para gráficar.');
        }
      }
      else {
        showToast(
          'error',
          'Error al generar el gráfico.',
          'Faltan ingresar código o enfermedad.');
      }
    } else {
      if (superAreaName !== null) {
        if (areaName !== null) {
          if (subAreaName !== null) {
            setCercoPostBar(null)
            setBtnState(!btnState)

            const arrayData = Array(subAreaName.length).fill().map(() => Math.floor(100 * Math.random()));
            setCercoPostBar({
              area: subAreaName,
              data: arrayData
            })
          }
          else {
            setCercoPostBar(null)
            setBtnState(!btnState)

            const arrayData = Array(areaName.length).fill().map(() => Math.floor(100 * Math.random()));
            setCercoPostBar({
              area: areaName,
              data: arrayData
            })
          }
        }
        else {
          setCercoPostBar(null)
          setBtnState(!btnState)

          const arrayData = Array(superAreaName.length).fill().map(() => Math.floor(100 * Math.random()));
          setCercoPostBar({
            area: superAreaName,
            data: arrayData
          })
        }
      }
      else {
        showToast(
          'error',
          'Error al generar el gráfico.',
          'Faltan ingresar un área para gráficar.');
      }
    }

  }

  const handlePostChartRadar = () => {

    let superAreaName = areaN1.length === 0 ? null : areaN1.map(data => data.nombre);
    let areaName = areaN2.length === 0 ? null : areaN2.map(data => data.nombre);
    let subAreaName = areaN3.length === 0 ? null : areaN3.map(data => data.nombre);

    if (enfermedad.length === 0) {
      if (codigo3.length !== 0) {
        if (superAreaName !== null) {
          if (areaName !== null) {
            if (subAreaName !== null) {
              //SubArea
              setCercoPostRadar(null)
              setBtnState(!btnState)

              let _data = []
              subAreaName.map(data => {
                const arrayData = Array(codigo3.length).fill().map(() => Math.floor(50 * Math.random()));
                _data.push({
                  area: data,
                  data: arrayData
                })
              });
              setCercoPostRadar(_data)
            }
            else {
              //Area
              setCercoPostRadar(null)
              setBtnState(!btnState)

              let _data = []
              areaName.map(data => {
                const arrayData = Array(codigo3.length).fill().map(() => Math.floor(50 * Math.random()));
                _data.push({
                  area: data,
                  data: arrayData
                })
              });
              setCercoPostRadar(_data)
            }
          }
          else {
            setCercoPostRadar(null)
            setBtnState(!btnState)

            let _data = []
            superAreaName.map(data => {
              const arrayData = Array(codigo3.length).fill().map(() => Math.floor(50 * Math.random()));
              _data.push({
                area: data,
                data: arrayData
              })
            })
            setCercoPostRadar(_data)
          }
        }
        else {
          showToast(
            'error',
            'Error al generar el gráfico.',
            'Faltan ingresar un área para gráficar.');
        }
      }
      else {
        showToast(
          'error',
          'Error al generar el gráfico.',
          'Faltan ingresar código o enfermedad.');
      }
    }
    else {
      if (superAreaName !== null) {
        if (areaName !== null) {
          if (subAreaName !== null) {
            setCercoPostRadar(null)
            setBtnState(!btnState)

            let _data = []
            subAreaName.map(data => {
              const arrayData = Array(enfermedad.length).fill().map(() => Math.floor(50 * Math.random()));
              _data.push({
                area: data,
                data: arrayData
              })
            });
            setCercoPostRadar(_data)
          }
          else {
            setCercoPostRadar(null)
            setBtnState(!btnState)

            let _data = []
            areaName.map(data => {
              const arrayData = Array(enfermedad.length).fill().map(() => Math.floor(50 * Math.random()));
              _data.push({
                area: data,
                data: arrayData
              })
            });
            setCercoPostRadar(_data)
          }
        }
        else {
          setCercoPostRadar(null)
          setBtnState(!btnState)

          let _data = []
          superAreaName.map(data => {
            const arrayData = Array(enfermedad.length).fill().map(() => Math.floor(50 * Math.random()));
            _data.push({
              area: data,
              data: arrayData
            })
          })
          setCercoPostRadar(_data)
        }
      }
      else {
        showToast(
          'error',
          'Error al generar el gráfico.',
          'Faltan ingresar un área para gráficar.');
      }
    }
  }



  return (
    <>
      <Toast ref={toast} />
      <div className='flex gap-5 cerco'>
        <div className='flex flex-column gap-2' style={{ width: "75%" }}>
          <div className="flex flex-column gap-2">
            <p className='text-indigo-600 font-bold text-3xl'>Cerco Epidemiológico</p>
            <p className='text-indigo-500 text-xs'>Edita este contenido para ayudar a tu publico</p>
          </div>

          <div className="flex gap-2 mt-4" >
            <div className="flex flex-column gap-2">
              <div className="flex flex-column gap-2 p-3 bg-white border-round-xl" style={{ width: "300px" }}>
                <CercoTipo setState={setTipoAnalisis} state={tipoAnalisis} />
              </div>
              <div className="flex flex-column p-3 bg-white border-round-xl" style={{ width: "300px",height:'34rem' }}>
                <ScrollPanel className="custombar1" style={{ width: '100%', height: '75%' }} >
                  <CercoAreas
                    areaN1={areaN1}
                    setAreaN1={setAreaN1}
                    areaN2={areaN2}
                    setAreaN2={setAreaN2}
                    areaN3={areaN3}
                    setAreaN3={setAreaN3}
                    sexo={sexo}
                    setSexo={setSexo}
                    permanencia={permanencia}
                    setPermanencia={setPermanencia}
                    rangoEdad = {rangoEdad}
                    setRangoEdad = {setRangoEdad}
                    
                  />
                </ScrollPanel>
                <Button
                  label="Limpiar filtros"
                  className='bg-white text-indigo-500 text-sm border-indigo-500 w-6 white-space-nowrap mt-5'
                />
              </div>
            </div>

            <div className='flex flex-1 flex-column gap-2 p-3 bg-white border-round-xl w-full'>
              {tipoAnalisis.name === 'Análisis Cuantitativo'
                ?
                <div className="flex justify-content-end m-3">
                  <Button
                    label="Actualizar"
                    icon="pi pi-spinner"
                    onClick={handlePostChartBar}
                    className='bg-white text-green-500 text-base border-green-500'
                  />
                </div>
                :
                <></>
              }

              {
                tipoAnalisis.name === 'Análisis Cualitativo'
                  ?
                  <>
                    <div className="flex justify-content-end m-3">
                      <Button
                        label="Actualizar"
                        icon="pi pi-spinner"
                        onClick={handlePostChartRadar}
                        className='bg-white text-green-500 text-base border-green-500'

                      />
                    </div>
                    <div className='flex gap-2 justify-content-center'>
                      <div className='flex gray border-round-sm p-1 align-items-center pr-2 pl-2 gap-2'>
                        <img src="https://i.ibb.co/wK8Rp3k/Rectangle-267.png" alt="morado" />
                        <label className='text-xs'>Cobranzas</label>
                      </div>
                      <div className='flex gray border-round-sm p-1 align-items-center pr-2 pl-2 gap-2'>
                        <img src="https://i.ibb.co/J541RG6/Rectangle-267-1.png" alt="azul" />
                        <label className='text-xs'>Ventas</label>
                      </div>
                      <div className='flex gray border-round-sm p-1 align-items-center pr-2 pl-2 gap-2'>
                        <img src="https://i.ibb.co/DRxf1nW/Rectangle-269.png" alt="celeste" />
                        <label className='text-xs'>Reclutamiento</label>
                      </div>
                      <div className='flex gray border-round-sm p-1 align-items-center pr-2 pl-2 gap-2'>
                        <img src="https://i.ibb.co/RTLcjwc/Rectangle-270.png" alt="verde" />
                        <label className='text-xs'>Bienestar Social</label>
                      </div>
                    </div>
                  </>
                  :
                  <></>
              }
              <CercoChart
                tipoAnalisis={tipoAnalisis.name}
                enfermedad={enfermedad}
                onlyEnfermedad={onlyEnfermedad}
                btn={btnState}

                cercoPostRadar={cercoPostRadar}
                cercoPostBar={cercoPostBar}

                codigo3={codigo3}
                codigo4={codigo4}
                onlyCodigo3={onlyCodigo3}
                onlyCodigo4={onlyCodigo4}
              />
            </div>
          </div>

        </div>
        <div className='flex flex-1 flex-column  bg-white border-round-xl' style={{ width: "20%" }}>
          <div className='flex flex-column gap-2 p-3'>
            <p className='text-xs'>Diagnóstico</p>
            <div className='flex flex-column pl-2 '>

              <CercoDiag
                tipoAnalisis={tipoAnalisis}
                enfermedad={enfermedad}
                setEnfermedad={setEnfermedad}
                onlyEnfermedad={onlyEnfermedad}
                setOnlyEnfermedad={setOnlyEnfermedad}

                codigo3={codigo3}
                setCodigo3={setCodigo3}
                codigo4={codigo4}
                setCodigo4={setCodigo4}

                onlyCodigo3={onlyCodigo3}
                setOnlyCodigo3={setOnlyCodigo3}
                onlyCodigo4={onlyCodigo4}
                setOnlyCodigo4={setOnlyCodigo4}
              />
            </div>
          </div>

          {
            tipoAnalisis.name === 'Análisis Cualitativo'
            &&
            <div className='flex align-items-end justify-content-end mt-8 relative' >
              <img src="https://cdn.discordapp.com/attachments/1089924612944580719/1143597414264082453/Cerco_E.png" alt="imagendianogtico" style={{ width: "200px", height: "230px", position: 'absolute', }} />
              <img src="https://i.ibb.co/djJFxmH/Group-1846.png" alt="imagendianogtico" style={{ width: "200px", height: "140px" }} />
            </div>
          }
          {
            tipoAnalisis.name === 'Análisis Cuantitativo'
            &&
            <div className='flex align-items-end justify-content-end relative' style={{ marginTop: "183px" }}>
              <img src="https://cdn.discordapp.com/attachments/1089924612944580719/1143597414264082453/Cerco_E.png" alt="imagendianogtico" style={{ width: "200px", height: "330px", position: 'absolute', }} />
              <img src="https://i.ibb.co/djJFxmH/Group-1846.png" alt="imagendianogtico" style={{ width: "200px", height: "140px" }} />
            </div>
          }
          {
            tipoAnalisis.name !== 'Análisis Cuantitativo' && tipoAnalisis.name !== 'Análisis Cualitativo' &&
            <div className='flex align-items-end justify-content-end relative' style={{ marginTop: "183px" }}>
              <img src="https://cdn.discordapp.com/attachments/1089924612944580719/1143597414264082453/Cerco_E.png" alt="imagendianogtico" style={{ width: "200px", height: "330px", position: 'absolute', }} />
              <img src="https://i.ibb.co/djJFxmH/Group-1846.png" alt="imagendianogtico" style={{ width: "200px", height: "140px" }} />
            </div>
          }
        </div>
      </div>
      { }

    </>


  );
};

export default CercoPrueba;
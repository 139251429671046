import React, { useState, useEffect, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import img1 from "./document.svg";
import "../../Style/estilos3.css";

import MusculoRotacion from "./ExamenFisico/MusculoRotacion";
import MusculoFlexibilidad from "./ExamenFisico/MusculoFlexibilidad";
import {MGContext} from "./MGProvider";

const prepareDataForME=(data)=>{
  const formDataME=new FormData()
  let elemnts_MF=["med_fuerza_abdomen","med_cadera","med_muslo","med_abdomen_lateral"]
  let elemnts_MR=["med_abduccion_hombro","med_abduccion_hombros","med_rotacion_externa","med_rotacion_externa_hombro"]
  Object.keys(data?.med_flexibilidad).forEach(item=>{
     if(elemnts_MF.includes(item)){
        formDataME.append(`med_flexibilidad[${item}][observacion]`,data?.med_flexibilidad?.[item]?.observacion)
        formDataME.append(`med_flexibilidad[${item}][opcion]`,data?.med_flexibilidad?.[item]?.opcion)
     }else{
        formDataME.append(`med_flexibilidad[${item}]`,data?.med_flexibilidad?.[item])
     }
  })
  Object.keys(data?.med_rotacion).forEach(item=>{
     if(elemnts_MR.includes(item)){
        formDataME.append(`med_rotacion[${item}][observacion]`,data?.med_flexibilidad?.[item]?.observacion)
        formDataME.append(`med_rotacion[${item}][opcion]`,data?.med_flexibilidad?.[item]?.opcion)
     }else{
        formDataME.append(`med_rotacion[${item}]`,data?.med_flexibilidad?.[item])
     }
  })
  return formDataME
}
const Esqueleto = () => {
  const [selectedRating, setSelectedRating] = useState(null);
  //    Tienes que hacer values para las diferentes observaciones si no todo se van a escribir en uno s
  const [value, setValue] = useState("");
  const { musculoEsqueleticoContext, setMusculoEsqueleticoContext } = useContext(MGContext);  
  
  return (
    <div className="w-full h-auto">
      <Accordion className="AcordeonDxyConclusión">
        <AccordionTab
          header={
            <>
              <div className="flex flex-row gap-2">
                <img src={img1} alt="" />
                <div>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#393838",
                    }}
                  >
                    Flexibilidad
                  </p>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#393838",
                    }}
                  >
                    Fuerza abdomen, Cadera, Muslo, Abdomen lateral.
                  </span>
                </div>
              </div>
            </>
          }
        >
          <MusculoFlexibilidad />
        </AccordionTab>
        <AccordionTab
          header={
            <>
              <div className="flex flex-row gap-2">
                <img src={img1} alt="" />
                <div>
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "16px",
                      color: "#393838",
                    }}
                  >
                    Rotación
                  </p>
                  <span
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#393838",
                    }}
                  >
                    Abducción de Hombro (0 - 180), Abducción de
                    Hombros (0 - 180), Rotación Externa (0 - 90),
                    Rotación Externa de Hombro interna.
                  </span>
                </div>
              </div>
            </>
          }
        >
          <MusculoRotacion/>
        </AccordionTab>
      </Accordion>
    </div>
  );
};
export default Esqueleto;
export { prepareDataForME};


import React, { useState, useEffect } from "react";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

// COMPONENTES UNICOS
import CreateRolModal from "./CreateRolModal";
import EditRolModalComponent from "./EditRolModal";
import DeleteRolModal from "./DeleteRolModal";
import AccesosModalComponent from "./AccesosModal";
import LocalesModalComponent from "./AccesosLocal";

// COMPONENTES GENERALES
import TablaCrud from "../../../Table/TablaCrud";

//Imágenes
import edit from "../../../../Images/roles/edit.svg";
import trash from "../../../../Images/roles/trash.svg";

// ESTILOS UNICOS
import "../Style/TablaRol.css";
import "../Style/MediaJ.css";

//Estilo general
import "../Style/TablaResponsive.css"

const TablaRolComponent = ({
   roles,
   rolUpdate,
   rolCreate,
   rolDelete,
   getRoles,
   accesos,
   asignarRoles,
   locales,
   GetLocales,
   ObtenerLocalesCondicional,
   AsignarLocales,
}) => {
   const [rol, setRol] = useState({});
   const [visibleCreate, setVisibleCreate] = useState(false);
   const [visibleDelete, setVisibleDelete] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visibleAccess, setVisibleAccess] = useState(false);
   const [visibleLocales, setVisibleLocales] = useState(false);
   const [listaDeAccesos, setListaDeAccesos] = useState();
   const [Id_SelectedLocal, setId_SelectedLocal] = useState(null)

   useEffect(() => {
      // Llama a la función para obtener locales cuando haya un cambio en la lista de roles
      ObtenerLocalesCondicional();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [roles]); // Ejecuta la función cada vez que haya un cambio en la lista de roles

   const OpenAsignarLocales = (rowData) => {
      //Abre el modal para bregma
      if (rowData.bregma_local_id) {
         //Actualiza el estado del local seleccionado
         setId_SelectedLocal(rowData.bregma_local_id);
      }
      //Abre el modal para clínica
      if (rowData.clinica_local_id) {
         //Actualiza el estado del local seleccionado
         setId_SelectedLocal(rowData.clinica_local_id);
      }
      //Abre el modal para empresa
      if (rowData.empresa_local_id) {
         //Actualiza el estado del local seleccionado
         setId_SelectedLocal(rowData.empresa_local_id);
      }
      setRol(rowData);
      setVisibleLocales(true);
   };

   //Botones de administración de accesos, locales y acciones

   const handleAcciones = (rowData) => {
      return (
         <div className="flex">
            <Button
               onClick={() => editRol(rowData)}
               className="p-button-text"
               tooltip="Editar"
            >
               <img src={edit} alt="button-edit" />
            </Button>
            <Button
               onClick={() => deleteRol(rowData)}
               className="p-button-text p-button-danger"
               tooltip="Eliminar"
            >
               <img src={trash} alt="button-delete" />
            </Button>
         </div>
      );
   };

   const handleAccesos = (rowData) => {
      //Verifica si el rol tiene accesos
      const tieneAccesos = Object.keys(rowData?.accesos || {}).length > 0;
      //Cambia el color del botón según la disponibilidad
      const backgroundColor = tieneAccesos ? "#22C55E" : "transparent";

      return (
         <div className="w-11 m-auto btnAccesosPSAP p-1">
            <Button
               onClick={() => {
                  sendAccesos(rowData);
                  //Asigna la lista de accesos
                  setListaDeAccesos(rowData?.accesos);
               }}
               className="flex gap-2 align-items-center justify-content-center m-auto border-transparent"
               tooltip="Administrar Accesos"
               tooltipOptions={{ position: "top" }}
               style={{ backgroundColor: backgroundColor, padding: '14px' }}
            >
               <i className="pi pi-lock" style={{ fontSize: '20px', color: tieneAccesos ? "white" : "#00A15C", }}></i>
               <p
                  style={{
                     fontWeight: "700",
                     fontSize: "12px",
                     color: tieneAccesos ? "#FFF" : "#00A15C",
                  }}
               >
                  Administrar accesos
               </p>
            </Button>
         </div>
      );
   };

   const handleLocales = (rowData) => {
      //Verifica si el rol tiene locales
      const tieneLocales =
         rowData.bregma_local_id ||
         rowData.clinica_local_id ||
         rowData.empresa_local_id;
      const backgroundColor = tieneLocales ? "#22C55E" : "transparent";

      return (
         <div className="w-11 m-auto btnAccesosPSAP p-1">
            <Button
               onClick={() => OpenAsignarLocales(rowData)}//Envía información del local
               className="flex gap-2 align-items-center justify-content-center m-auto border-transparent"
               tooltip="Administrar Locales"
               tooltipOptions={{ position: "top" }}
               style={{ backgroundColor: backgroundColor, padding: '14px' }}
            >
               <i className="pi pi-map-marker" style={{ fontSize: '20px', color: tieneLocales ? "white" : "#00A15C", }}></i>
               <p
                  style={{
                     fontWeight: "700",
                     fontSize: "12px",
                     color: tieneLocales ? "#FFF" : "#00A15C",
                  }}
               >
                  Administrar locales
               </p>
            </Button>
         </div>
      );
   };

   const handleItem = (rowData, rowIndex) => {
      return <div className="item-rol">{rowIndex.rowIndex + 1}</div>;
   };

   const sendAccesos = (rowData) => {
      //Asigna el rol
      setRol(rowData);
      setVisibleAccess(true);
   };

   const editRol = (rowData) => {
      //Asigna el rol
      setRol(rowData);
      setVisibleEdit(true);
   };

   const deleteRol = (rowData) => {
      //Asigna el rol
      setRol(rowData);
      setVisibleDelete(true);
   };

   return (
      <div className="TablaCrudRolesRecursosHumanos content-table-rol flex w-12 justify-content-end table centrar">
         <div className="rol-desktop">
            <TablaCrud
               name={"Roles"}
               value={roles}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={"Crear"}
            >
               <Column
                  className="cccc"
                  field="item"
                  header={<span className="header-item">Item</span>}
                  body={handleItem}
                  style={{ minWidth: "16%" }}
               ></Column>

               <Column
                  className="ccc1"
                  field="nombre"
                  header={<span className="header-rol">Rol</span>}
                  style={{
                     minWidth: "60%",
                     textAlign: "center",
                     fontWeight: "700",
                     fontSize: "12px",
                     whiteSpace: "wrap",
                  }}
               ></Column>

               <Column
                  className="ccc2"
                  header={<span className="header-accesos">Accesos</span>}
                  body={handleAccesos}
                  style={{ minWidth: "60%", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className="ccc4"
                  header={<span className="header-locales">Locales</span>}
                  body={handleLocales}
                  style={{ minWidth: "60%", whiteSpace: "wrap" }}
               ></Column>

               <Column
                  className="ccc3"
                  header={<span className="header-acciones">Acciones</span>}
                  body={handleAcciones}
                  style={{ minWidth: "45%", whiteSpace: "wrap" }}
               ></Column>
            </TablaCrud>
         </div>

         <div className="rol-movil-view">
            <TablaCrud
               name={"Roles"}
               value={roles}
               onClickLeftButton1={() => {
                  setVisibleCreate(true);
               }}
               leftLabelButton1={"Crear"}
            >
               <Column
                  className="ccc2"
                  field="item"
                  header={
                     <span
                        className="header-item"
                        style={{ marginLeft: "5px" }}>
                        Item
                     </span>
                  }
                  style={{ minWidth: "26%" }}
               ></Column>
               <Column
                  className="ccc1"
                  field="nombre"
                  header="Rol"
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
               <Column
                  className="ccc2"
                  header="Accesos"
                  body={handleAccesos}
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
               <Column
                  header={<span className="">Locales</span>}
                  body={handleLocales}
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
               <Column
                  className="ccc1"
                  header="Acciones"
                  body={handleAcciones}
                  style={{ minWidth: "60%", borderLeft: "none" }}
               ></Column>
            </TablaCrud>
         </div>

         {/* Modal de creación */}
         <CreateRolModal
            rol={rol}
            setRol={setRol}
            visibleCreate={visibleCreate}
            setVisibleCreate={setVisibleCreate}
            rolCreate={rolCreate}
            getRoles={getRoles}
         />

         {/* Modal de actualización */}
         <EditRolModalComponent
            rol={rol}
            setRol={setRol}
            visibleEdit={visibleEdit}
            setVisibleEdit={setVisibleEdit}
            rolUpdate={rolUpdate}
            getRoles={getRoles}
         />

         {/* Modal de eliminación */}
         <DeleteRolModal
            rol={rol}
            visibleDelete={visibleDelete}
            setVisibleDelete={setVisibleDelete}
            rolDelete={rolDelete}
            getRoles={getRoles}
         />

         {/* Modal de accesos */}
         <AccesosModalComponent
            visibleAccess={visibleAccess}
            setVisibleAccess={setVisibleAccess}
            rol={rol}
            setRol={setRol}
            accesos={accesos}
            asignarRoles={asignarRoles}
            listaAccesos={listaDeAccesos}
         />

         {/* Modal Locales */}
         <LocalesModalComponent
            visibleLocales={visibleLocales}
            setVisibleLocales={setVisibleLocales}
            rol={rol}
            setRol={setRol}
            locales={locales}
            GetLocales={GetLocales}
            setGetLocales={GetLocales}
            AsignarLocales={AsignarLocales}
            LocalAsignadoBD={Id_SelectedLocal}
         />
      </div>
   );
};

export default TablaRolComponent;

import React, { useState, useEffect } from 'react';
import Eli from '../../../Images/Pure/MenuPrincipal/Img/Eli.png';
import Eli2 from '../../../Images/Pure/MenuPrincipal/Img/eli2.png';
import Eli3 from '../../../Images/Pure/MenuPrincipal/Img/eli3.png';

const Eclipses = () => {
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowAdditionalDiv(screenWidth <= 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 750) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div>  {/*className='elipses-container'*/}
      {!showAdditionalDiv && (
        <>
          <img src={Eli2} className="quarter-circle" alt="" />
          <img src={Eli3} className="fon" alt="" />
          <img src={Eli} className="terce" alt="" />
        </>
      )}
    </div>
  );
};

export default Eclipses;

import { useState } from 'react';
import Soporte from '../../Components/Soporte/Components/Soporte';


export default function PageSoporte() {
    const [formConctBreg, setFormConctBreg] = useState(null);
    const [formTutorialesBreg, setFormTutorialesBreg] = useState(null);

    function SendContactenosB(data) {
        setFormConctBreg(data);
        
    }
    function SendTutorialesB(data) {
        setFormTutorialesBreg(data);
        
    }

    return (
        <div className="flex gap-3 flex-wrap">
            <Soporte FContactenosB={SendContactenosB} FTutorialesB={SendTutorialesB} />
        </div>
    );
}
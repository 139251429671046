import React, { useContext, useState } from "react";
import Table from "../Table/Table";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import "../../Style/EkgDatos.css";
import EkgDatos from "./EkgDatos";
import { EkgContext } from "../../Page/Ekg/EkgContext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Fieldset } from "primereact/fieldset";

const EkgTable = ({url,seturl}) => {

   let { datosContext } = useContext(EkgContext);

   const [visibleEkgEditar, setVisibleEkgEditar] = useState(false);
   const [visibleEkgDelete, setVisibleEkgDelete] = useState({
      status: false,
      id: null,
   });


   const actionBodyTemplateboton = (rowData) => {
      return (
         <React.Fragment>
            <Button
               icon="pi pi-pencil"
               className="editar1"
               onClick={() => {
                  setVisibleEkgEditar(true);
               }}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() =>
                  setVisibleEkgDelete({
                     ...visibleEkgDelete,
                     status: true,
                     id: rowData.id,
                  })
               }
            />
         </React.Fragment>
      );
   };

   return (
      <>
         <Fieldset legend="Añadir Datos" toggleable>
            <EkgDatos url={url} seturl={seturl}/>
         </Fieldset>
         <Accordion className="pt-5">
            <AccordionTab header="Historial">
               <Table
                  headerEnabled={true}
                  value={datosContext}
                  style={{ height: "500px" }}
                  scrollable
               >
                  <Column
                     className="text-center"
                     field="id"
                     header="Ítem"
                  />
                  <Column
                     className="text-center"
                     field="ritmo"
                     header="Ritmo"
                  />
                  <Column
                     className="text-center"
                     field="pr"
                     header="RP"
                  />
                  <Column
                     className="text-center"
                     field="frecuencia"
                     header="Frecuencia"
                  />
                  <Column
                     className="text-center"
                     field="qrs"
                     header="QRS."
                  />
                  <Column
                     className="text-center"
                     field="eje_electrico"
                     header="Eje eléctrico"
                  />
                  <Column
                     className="text-center"
                     field="qt"
                     header="QT"
                  />
                  <Column
                     className="text-center"
                     field="concluciones"
                     header="Conclusiones"
                  />
                  <Column
                     className="text-center"
                     field="recomendaciones"
                     header="Recomendaciones"
                  />
                  <Column
                     className="text-center"
                     field="medico_evaluador"
                     header="Médico evaluador"
                  />
                  <Column
                     className="text-center"
                     field="colegiatura"
                     header="Colegiatura"
                  />
                  <Column
                     className="text-center"
                     header="Acción"
                     body={actionBodyTemplateboton}
                     style={{ maxWidth: "8rem" }}
                  />
               </Table>
            </AccordionTab>
         </Accordion>
      </>
   );
};

export default EkgTable;

import React, { useState, useEffect } from "react";
import { Calendar } from 'primereact/calendar';

export default function CalendarRad(props) {
    const dividirFecha = (fechaString) => {
        if (fechaString && typeof fechaString === 'string') {
            const partes = fechaString.split('-');
            const año = partes[0];
            const mes = partes[1];
            const día = partes[2];
            return { año, mes, día };
        } else {
            // Si fechaString no es una cadena válida, devuelve un objeto vacío
            return { año: '', mes: '', día: '' };
        }
    }
    function convertirFormatoFecha(fechaString) {
        const fechaOriginal = new Date(fechaString);
        
        const año = fechaOriginal.getFullYear().toString();
        const mes = (fechaOriginal.getMonth() + 1).toString().padStart(2, '0');
        const dia = fechaOriginal.getDate().toString().padStart(2, '0');
        
        return `${año}-${mes}-${dia}`;
    }

    useEffect(() => {
        if (props.val) {
            const partesFecha = dividirFecha(props.val);
            const nuevaFecha = new Date(partesFecha.año, partesFecha.mes - 1, partesFecha.día);
            setDate(nuevaFecha);
        }
    }, [props.val]);

    const [date, setDate] = useState();

    const handleChange = (e) => {
        const fechaFormateada = convertirFormatoFecha(e.value);
        setDate(fechaFormateada);
        props.update(fechaFormateada);
    };

    return (
        <div className="w-full flex flex-column">
            <Calendar value={date} onChange={handleChange} showIcon />
        </div>
    )
}

import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import axios from "axios";

//Componentes generales
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";
import InputCalendar from "../../../form/InputCalendar";

//Imagen
import iconEditar from "../../../../Images/Reclutaminento/img/icon_agregarReclutamiento.svg";
import { Footer, Header } from "../../../Pure/SeccionesNavegacion";

export default function ModalEditarRecluta({
  visibleEdit,
  setVisibleEdit,
  personal,
  showToast,
  tipoDocumentos,
  getPersonal,
  api,
  data,
  sexos,
  dispatch
}) {

  const { http } = AuthUser();

  // Valores iniciales del formulario
  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    empresa_cargo: ""
  };

  // Configuración de Formik para manejar el formulario
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: async (datos) => {
      dispatch(startLoading());
      const formDataSend = new FormData();
      for (var key in datos) {
        formDataSend.append(key, datos[key]);
      }
      formDataSend.append("tipo_documento_id", datos?.tipo_documento?.id);
      datos.tipo_documento_id = datos?.tipo_documento?.id;

      formDataSend.append("sexo_id", datos?.sexo?.id);
      datos.sexo_id = datos?.sexo?.id;

      formDataSend.append("empresa_cargo_id", datos?.empresa_cargo?.id);
      datos.empresa_cargo_id = datos?.empresa_cargo?.id;

      try {
        // Envío de datos al servidor
        const response = await http.post(`${api}/${datos.id}`, datos)
        if (response?.data?.resp) {
          await Promise.all([
            getPersonal(),
            HideDialogEdit()
          ])
          showToast("success", "Actualizado correctamente", "Se creo correctamente el personal");
        } else if (response?.data?.error) {
          showToast("error", "Error", response?.data?.error)
        }
      } catch (error) {
        showToast("error", "Error", "Contáctese con soporte técnico.")
        console.error(error);
      } finally {
        dispatch(endLoading());
      }
    },
    validate: (data) => {
      let errors = {};
      if (!data.tipo_documento) {
        errors.tipo_documento = 'Seleccione una opción'
      }
      if (!data.numero_documento) {
        errors.numero_documento = 'Ingrese el número de documento'
      }
      if (!data.nombres) {
        errors.nombres = 'Nombre requerido'
      }
      if (!data.apellido_paterno) {
        errors.apellido_paterno = 'Apellido paterno requerido'
      }
      if (!data.apellido_materno) {
        errors.apellido_materno = 'Apellido materno requerido'
      }
      if (!data.empresa_cargo) {
        errors.empresa_cargo = 'Seleccione una opción'
      }
      return errors
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  // Función para ocultar el diálogo de edición
  const HideDialogEdit = () => {
    setVisibleEdit(false);
  };

  // Encabezado del diálogo
  const HeaderReclutamiento = (<Header
    icono={iconEditar}
    titulo={'Editar postulante'}
    subtitulo={'En esta sección usted puede editar los datos de sus postulantes.'} />)

  // Pie de página del diálogo
  const paqueteCreateDialogFooter = (<Footer
    onClickCancelar={() => HideDialogEdit()}
    label={'Actualizar'}
    type={'submit'} />);

  // Actualiza los valores del formulario con los datos del personal seleccionado
  const updatePersonal = () => {
    formik.setValues({
      id: personal?.id,
      tipo_documento: personal?.persona?.tipo_documento,
      numero_documento: personal?.persona?.numero_documento,
      nombres: personal?.persona?.nombres,
      apellido_paterno: personal?.persona?.apellido_paterno,
      apellido_materno: personal?.persona?.apellido_materno,
      fecha_nacimiento: new Date(personal?.persona?.fecha_nacimiento),
      distrito_id: 2,
      celular: personal?.persona?.celular,
      correo: personal?.persona?.correo,
      sexo: personal?.persona?.sexo,
      direccion: personal?.persona?.direccion,
      empresa_cargo: personal?.empresa_cargo,
    });
  };

  // Obtiene el nombre completo a partir del número de documento
  const getNombreCompleto = (numdocumento) => {
    dispatch(startLoading());
    if (formik.values.tipo_documento !== "") {
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              nombres: res.data.nombres,
              apellido_paterno: res.data.apellidoPaterno,
              apellido_materno: res.data.apellidoMaterno,
              fecha_nacimiento: formik.values.fecha_nacimiento,
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              empresa_cargo: formik.values.empresa_cargo
            })
            getPersonal();
          })
          .catch((error) => {
            console.error("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
          .finally(() => {
            dispatch(endLoading());
          })
      } else if (formik.values.tipo_documento.nombre === "RUC") {
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            getPersonal();
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              fecha_nacimiento: formik.values.fecha_nacimiento,
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              empresa_cargo: formik.values.empresa_cargo
            })
          })
          .catch((error) => {
            console.error("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
          .finally(() => {
            dispatch(endLoading());
          })
      }

    } else {
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }

  useEffect(() => {
    updatePersonal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personal]);

  return (
    <div>
      <form
        id="recluta-form-edit"
        className="recluta-form-edit"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog style={{ width: "450px", margin: "5%" }}
          visible={visibleEdit}
          header={HeaderReclutamiento}
          appendTo={document.getElementById("recluta-form-edit")}
          dismissableMask
          onHide={HideDialogEdit}
          footer={paqueteCreateDialogFooter}>
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className="per1">(*)Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento ?? ''}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className={classNames({ 'p-invalid': isFormFieldValid('tipo_documento') })}
                />
                {getFormErrorMessage('tipo_documento')}
              </div>
            </div>
            <div className="flex flex-1 w-full flex-column gap-2">
              <p className="per1">(*)Número de documento</p>
              <div className="flex flex-1 w-full gap-2">
                <InputText
                  id="numero_documento"
                  name="numero_documento"
                  value={formik.values.numero_documento ?? ''}
                  onChange={formik.handleChange}
                  className={classNames({ 'p-invalid': isFormFieldValid('numero_documento') })}
                  required
                />
                <Button
                  className="w-full"
                  type="button"
                  label="Validar"
                  style={{ background: "#07A34F", borderColor: "#07A34F" }}
                  onClick={() => getNombreCompleto(
                    formik.values.numero_documento,
                  )}
                  disabled={formik.values.tipo_documento?.id === 2}
                />
              </div>
              {getFormErrorMessage('numero_documento')}
            </div>
            <div className="flex flex-column gap-2">
              <label htmlFor="name">(*)Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres ?? ''}
                className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
              {getFormErrorMessage('nombres')}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">(*)Apellidos Paterno</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno ?? ''}
                onChange={formik.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })}
              />
              {getFormErrorMessage('apellido_paterno')}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">(*)Apellidos Materno</label>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={formik.values.apellido_materno ?? ''}
                onChange={formik.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })}
              />
              {getFormErrorMessage('apellido_materno')}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="">Correo</label>
              <InputText
                id="correo"
                name="correo"
                value={formik.values.correo ?? ''}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="">Celular</label>
              <InputText
                id="celular"
                name="celular"
                value={formik.values.celular ?? ''}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column">
              <p>(*)Puesto de Trabajo</p>
              <Dropdown
                id="empresa_cargo"
                name="empresa_cargo"
                value={formik.values.empresa_cargo ?? ''}
                onChange={formik.handleChange}
                options={data}
                optionLabel="nombre"
                placeholder="Selecciona un Puesto de trabajo"
                className={classNames({ 'p-invalid': isFormFieldValid('empresa_cargo') })}
              />
              {getFormErrorMessage('empresa_cargo')}
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <div className="flex flex-1 w-full gap-2">
                <div>
                  <p>Sexo</p>
                  <Dropdown
                    id="sexo"
                    name="sexo"
                    value={formik.values.sexo ?? ''}
                    onChange={formik.handleChange}
                    options={sexos}
                    optionLabel="nombre"
                    placeholder="Selecciona tu sexo"
                  />
                </div>

                <div>
                  <p>Fecha de nacimiento</p>
                  <InputCalendar
                    id="fecha_nacimiento"
                    name="fecha_nacimiento"
                    value={formik.values.fecha_nacimiento ?? ''}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";

export default function S2ContactEdit({ data }) {
   //#region VARIABLES
   const [visible, setVisible] = useState(false);
   //#endregion
   const [selectedImage, setSelectedImage] = useState(data.persona.foto);

   const headerTemplate = () => {
      return (
         <div className="flex justify-content-start gap-2 align-items-center">
            <div className="flex align-items-center justify-content-center" style={{ width: '45px', height: '45px', borderRadius: '10px', backgroundColor: '#EBEBFF' }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 34 38" fill="none">
                  <path d="M5.49996 9.41667H3.58329C2.52475 9.41667 1.66663 10.2748 1.66663 11.3333C1.66663 12.3919 2.52475 13.25 3.58329 13.25H5.49996M5.49996 24.75H3.58329C2.52475 24.75 1.66663 25.6081 1.66663 26.6667C1.66663 27.7252 2.52475 28.5833 3.58329 28.5833H5.49996M32.3333 30.7377C29.7176 26.0238 24.6897 22.8333 18.9166 22.8333C13.1436 22.8333 8.11569 26.0238 5.49996 30.7377M11.6333 36.25H26.2C28.3468 36.25 29.4203 36.25 30.2403 35.8322C30.9615 35.4647 31.548 34.8783 31.9155 34.157C32.3333 33.337 32.3333 32.2635 32.3333 30.1167V7.88333C32.3333 5.73647 32.3333 4.66303 31.9155 3.84304C31.548 3.12175 30.9615 2.53532 30.2403 2.16781C29.4203 1.75 28.3468 1.75 26.2 1.75H11.6333C9.48642 1.75 8.41299 1.75 7.593 2.16781C6.87171 2.53532 6.28528 3.12175 5.91777 3.84304C5.49996 4.66303 5.49996 5.73647 5.49996 7.88333V30.1167C5.49996 32.2635 5.49996 33.337 5.91777 34.157C6.28528 34.8783 6.87171 35.4647 7.593 35.8322C8.41299 36.25 9.48642 36.25 11.6333 36.25ZM24.7386 15.2389C24.7386 18.4544 22.1319 21.0611 18.9164 21.0611C15.7009 21.0611 13.0942 18.4544 13.0942 15.2389C13.0942 12.0234 15.7009 9.41667 18.9164 9.41667C22.1319 9.41667 24.7386 12.0234 24.7386 15.2389Z" stroke="#5555D8" stroke-width="3.13" />
               </svg>
            </div>
            <p className="text-indigo-600 text-lg font-bold" style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '18px' }}>
               Mostrar Contacto
            </p>
         </div>
      );
   };
   return (
      <>
         <Button
            icon="pi pi-eye text-cyan-500"
            className="bg-blue-100 border-blue-100 crudButon"
            onClick={() => setVisible(true)}
         />
         <Dialog
            header={headerTemplate}
            visible={visible}
            style={{ width: "665px", height: "754px" }}
            onHide={() => setVisible(false)}
            className="empresas"
         >
            <div
               className="text-sm flex flex-column gap-3 w-full mt-3"
            >
               <div className="flex flex-wrap gap-3 ventas">
                  <div className="flex-1 flex flex-column gap-3">
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="nombres">
                           Nombres
                        </label>
                        <InputText
                           value={data.persona.nombres}
                           disabled
                           name="nombres"
                           id="nombres"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="apellido_paterno">
                           Apellido Paterno
                        </label>
                        <InputText
                           value={data.persona.apellido_paterno}
                           disabled
                           name="apellido_paterno"
                           id="apellido_paterno"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="apellido_materno">
                           Apellido Materno
                        </label>
                        <InputText
                           value={data.persona.apellido_materno}
                           disabled
                           name="apellido_materno"
                           id="apellido_materno"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="cargo">
                           Cargo
                        </label>
                        <InputText
                           value={data.persona.cargo || ""}
                           disabled
                           name="cargo"
                           id="cargo"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="telefono">
                           Teléfono
                        </label>
                        <InputNumber
                           value={data.persona.telefono || null}
                           disabled
                           useGrouping={false}
                           name="telefono"
                           id="telefono"
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="correo">
                           Correo
                        </label>
                        <InputText
                           value={data.persona.correo || ""}
                           disabled
                           type="email"
                           name="correo"
                           id="correo"
                           className="p-inputtext-sm"
                        />
                     </div>
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="fecha_nacimiento">
                           Cumpleaños
                        </label>
                        <InputText
                           value={data.persona.fecha_nacimiento || null}
                           disabled
                           name="fecha_nacimiento"
                           className="p-inputtext-sm"
                        />
                     </div>
                  </div>

                  <div className="flex-1 flex flex-column gap-3">
                     <label className="font-bold" htmlFor="foto">
                        Foto
                     </label>

                     <div className="flex justify-content-center">
                        <span style={{ width: '218px', height: '218px' }} className="flex align-items-center justify-content-center bg-indigo-200 p-2 border-circle">
                           <img
                              className="border-circle"
                              width="202px"
                              height="202px"
                              style={{ objectFit: "contain" }}
                              src={selectedImage ? selectedImage : 'https://i.ibb.co/4SHrqm4/user-male.png'}
                              alt="user-male"
                              draggable={false}
                           />
                        </span>
                     </div>

                     <div className="flex align-items-center justify-content-center ">

                     </div>

                     <label
                        className="flex align-items-center gap-2 font-bold"
                        htmlFor="comentarios"
                     >
                        <p>Comentarios</p>
                        <i className="pi pi-pencil text-sm" />
                     </label>
                     <InputTextarea
                        value={data.comentarios || ""}
                        disabled
                        className="ventas-scroll"
                        id="comentarios"
                        name="comentarios"
                        style={{ resize: "none", height: "9.5rem" }}
                     />
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="">
                           Hobbies
                        </label>
                        <InputText
                           value={data.persona.hobbies || ""}
                           disabled
                           name="hobbies"
                           placeholder="Ingrese sus hobbies"
                           className="p-inputtext-sm"
                        />
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
      </>
   );
}

import reactSelect from "react-select";
import AuthUser from "../../../../../AuthUser";
import { startLoading, endLoading, } from "../../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

const SC1Customer = () => {
   const { http } = AuthUser();
   const dispatch = useDispatch();

   const getCompany = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get("clinica/contratos/get");

         if (response?.data?.resp || response?.data?.data) {
            const transformedData = response.data.data.map((item) => (
               ({
                  id: item.id,
                  logo: item.empresa.logo || "",
                  empresa: item.empresa.razon_social,
                  nombre: item.empresa.responsable,
                  sevicio_1: 'Áreas médicas',
                  sevicio_2: 'Capacitaciones',
                  estado: item.estado_registro === 'A' ? 1 : 2,
                  empresa_id: item.empresa_id
               })
            ));
            return transformedData
         } else if (response?.data?.error || response?.data?.Error) {
            console.error(response.data.error);
            return [];
         }
         // if (response.data.Error) {
         //     return [];
         // } else {
         //     const transformedData = response.data.data.map((item) => (
         //         ({
         //             id: item.id,
         //             logo: item.empresa.logo || "",
         //             empresa: item.empresa.razon_social,
         //             nombre: item.empresa.responsable,
         //             sevicio_1: 'Áreas médicas',
         //             sevicio_2: 'Capacitaciones',
         //             estado: item.estado_registro === 'A' ? 1 : 2,
         //             empresa_id: item.empresa_id
         //         })
         //     ));
         //     return transformedData
         // }
      } catch (error) {
         throw error;
      } finally {
         dispatch(endLoading());
      }
   };

   const getPackages = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get("clinica/paquete/get");
         if (response.data.data) {
            return response.data.data;
         } else {
            return [];
         }
      } catch (error) {
         throw error;
      } finally {
         dispatch(endLoading());
      }
   };


   const getCompanyID = async (id, ubicaciones, setData) => {
      //FALTA SINPLIFICAR LA CONDICIÓN GENERAL
      try {
         const response = await http.get(`empresa/get/${id}`);

         if (response.data.resp) {
            let departamentoSeleccionado = null
            let provinciaSeleccionada = null
            let distritoSeleccionado = null
            const empresa = response.data.resp ? response.data.resp : response.data.data;

            if (ubicaciones) {
               departamentoSeleccionado = ubicaciones?.find(
                  (departamento) => departamento.id === empresa?.distrito?.provincia?.departamento?.id
               );
               provinciaSeleccionada = departamentoSeleccionado?.provincias.find(
                  (provincia) => provincia.id === empresa?.distrito?.provincia?.id
               );
               distritoSeleccionado = provinciaSeleccionada?.distritos.find(
                  (distrito) => distrito.id === empresa?.distrito?.id
               );
            }
            setData(prevState => (
               {
                  ...prevState,
                  GCEmpresa: {
                     ...prevState.GCEmpresa,
                     contrato_id: response?.data?.resp?.contratos[0]?.id,
                     tipo_cliente: response.data.resp?.contratos[0].tipo_cliente_id,
                     numero_documento: response.data.resp?.numero_documento,
                     razon_social: response.data.resp?.razon_social,
                     responsable: response.data.resp?.responsable || "",
                     nombre_comercial: response.data.resp?.nombre_comercial,
                     latitud: parseFloat(response.data.resp?.latitud) || -12.0464,
                     longitud: parseFloat(response.data.resp?.longitud) || -77.0428,
                     tipo_documento_id: response.data.resp?.tipo_documento.id,
                     departamento: departamentoSeleccionado,

                     provincia: provinciaSeleccionada,

                     distrito: distritoSeleccionado,
                     direccion: response.data.resp?.direccion,
                     ubicacion_mapa: response.data.resp?.ubicacion_mapa || "",
                     aniversario: response.data.resp?.aniversario || null,
                     rubro: response.data.resp?.rubro_id || null,
                     cantidad_trabajadores: response.data.resp?.cantidad_trabajadores || null,
                     actividad: response.data.resp?.años_actividad || null,
                     logo: response.data.resp?.logo || "",
                     estado_registro: response.data.resp?.estado_registro,
                     empresa_id: response.data?.resp?.id

                  }
               }
            ));
            return response.data.resp ? response.data.resp.contratos[0].id : response.data.data.contratos[0].id
         }

         return false;
      } catch (error) {
         throw error;
      } finally {
      }
   };

   const postCompany = async (data, setData, showToast, navigate) => {

      dispatch(startLoading());

      try {
         const response = await http.post("empresa/create", data);

         if (response?.data?.resp) {
            setData(prevState => ({
               ...prevState,
               GCEmpresa: {
                  ...prevState.GCEmpresa,
                  clinica_id: response.data.empresa_id,
                  contrato_id: response.data.contrato_id || null
               }
            }));
            showToast("success", "Completado", response.data.resp);
            navigate(`../ventas/gestiondeventas/empresa/${response.data.empresa_id}/editar`);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'No se creo correctamente', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }

   }

   const putCompany = async (id, data, setData, showToast) => {
      await http.post(`empresa/update/${id}`, data).then((response) => {

         showToast(response.data.resp ? "success" : "error", response.data.resp ? "Completado" : "Error", response.data.resp || response.data.Error)
      }).catch((error) => {
         console.error(error);
      }).finally(() => {
      });
   }

   return {
      getCompany,
      getPackages,

      getCompanyID,
      postCompany,
      putCompany,
   };
}

export default SC1Customer;
import React, { useState, useEffect } from 'react';
import { Checkbox } from 'primereact/checkbox';
import './checkboxGroup.css';

const CheckboxGroup = ({
   data = [],
   defaultChecked = null,
   singleSelecction = false,
   value,
   alwaysOneSelected = false,
   field = ["nombreCompleto"],
   clearChecked = false,
   sendData
}) => {
   
   const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);
   const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

   const toggleCheckbox = (e, id) => {
      if (checkedCheckboxes.includes(id)) {
         if (alwaysOneSelected && selectedCheckboxes.length === 1) {
            return;
         }

         setCheckedCheckboxes(
            checkedCheckboxes.filter((checkboxId) => checkboxId !== id)
         );

         setSelectedCheckboxes((prev) =>
            prev.filter((checkbox) => checkbox.id !== id)
         );
         if (value) {
            value((prev) => prev.filter((checkbox) => checkbox.id !== id));
         }
      } else {
         setCheckedCheckboxes([...checkedCheckboxes, id]);

         setSelectedCheckboxes((prev) => [...prev, e.value]);
         if (value) {
            value((prev) => [...prev, e.value]);
         }
      }
   };

   const singletoggleCheckbox = (e, id) => {
      if (checkedCheckboxes.includes(id)) {
         if (alwaysOneSelected && selectedCheckboxes.length === 1) {
            return;
         }
         setCheckedCheckboxes([]);
         setSelectedCheckboxes((prev) =>
            prev.filter((checkbox) => checkbox.id !== id)
         );
         if (value) {
            value((prev) => prev.filter((checkbox) => checkbox.id !== id));
         }
      } else {
         setCheckedCheckboxes([id]);

         setSelectedCheckboxes([e.value]);
         if (value) {
            value([e.value]);
         }
      }
   };

   useEffect(() => {
      if (defaultChecked) {
         setCheckedCheckboxes([defaultChecked.id]);
         setSelectedCheckboxes([defaultChecked]);
      }

      if (value) {
         value([defaultChecked] || []);
      }
   }, []);

   useEffect(() => {
      if (clearChecked !== false) {
         setCheckedCheckboxes([])
         setSelectedCheckboxes([])
      }
   }, [clearChecked])

   useEffect(() => {
      sendData(checkedCheckboxes)
   }, [checkedCheckboxes])

   return (
      <div className="flex font-bold mt-3 mb-3 justify-content-between flex-wrap ventas-scroll">
         {data.map((d, index) => {
            // const fieldValue = field.reduce((obj, key) => obj && obj[key], d);
            const nombres = d.persona.nombres || '';
            const apellidoPaterno = d.persona.apellido_paterno || '';
            const apellidoMaterno = d.persona.apellido_materno || '';
            const apellidos = `${apellidoPaterno} ${apellidoMaterno}`;
            const cargo = d.persona.cargo || '';

            const nombreCompleto = `${nombres.toLowerCase()} ${apellidoPaterno.toLowerCase()} ${apellidoMaterno.toLowerCase()}`;
            return (
               <div
                  key={index}
                  className="flex flex-wrap justify-content-between pt-3 pb-3 gap-2 contactos"
                  style={{ width: "460px", borderBottom: "1px solid #ced4da", height: "53px" }}
               >
                  <div className="flex" style={{ width: "calc(100% - 30px)", height: "20px" }}>
                     <label style={{ color: "#0C0310", fontFamily: "Montserrat", fontSize: "17px", fontWeight: "500", textTransform: "capitalize" }}>
                        Contacto {index + 1}: {nombreCompleto}
                     </label>
                  </div>

                  <Checkbox
                     value={d}
                     checked={checkedCheckboxes.includes(d.id)}
                     onChange={
                        singleSelecction
                           ? (e) => singletoggleCheckbox(e, d.id)
                           : (e) => toggleCheckbox(e, d.id)
                     }
                     inputId={index}
                     className='checkbox-credenciales'
                  />
               </div>
            );
         })}
      </div>
   );
};

export default CheckboxGroup;
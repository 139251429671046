import React, { useState, useRef } from "react";
import "../../Style/Odontologia/Odontograma.css";
import { BotonDiente } from "../../Components/Odontologia/BotonDiente";
import { BotonMinidiente } from "../../Components/Odontologia/BotonMiniDiente";
import { TabView, TabPanel } from 'primereact/tabview';
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";


const Odontrograma = () => {
   const [selectedColor, setSelectedColor] = useState("");
   const handleColorChange = (event) => {
      setSelectedColor(event.target.value);
   };

   const handleButtonClick = (e) => {
      if (!selectedColor) {
         return alert("Debe elegir un color");
      }
      e.target.style.backgroundColor = selectedColor;
   };

   const NUMERO_DIENTES = 32;
   const [imprimirVisible, setImprimirVisible] = useState(false)
   return (
      <div>
         <TabView>
            <TabPanel header="Odontrograma">
               <div>
                  <div>
                     <div
                        className="opcionesdiente"
                        style={{ marginTop: "40px", marginBottom: "60px" }}
                     >
                        <label
                           className={`color-option red ${selectedColor === "red" ? "selected" : ""
                              }`}
                        >
                           <input
                              className="radio-hidden"
                              type="radio"
                              name="color"
                              value="red"
                              onChange={handleColorChange}
                           />
                           <span className="radio-circle" />
                           Fractura
                        </label>
                        <label
                           className={`color-option blue ${selectedColor === "blue" ? "selected" : ""
                              }`}
                        >
                           <input
                              className="radio-hidden"
                              type="radio"
                              name="color"
                              value="blue"
                              onChange={handleColorChange}
                           />
                           <span className="radio-circle" />
                           Obturación
                        </label>
                        <label
                           className={`color-option green ${selectedColor === "#7F7F7F" ? "selected" : ""
                              }`}
                        >
                           <input
                              className="radio-hidden"
                              type="radio"
                              name="color"
                              value="#7F7F7F"
                              onChange={handleColorChange}
                           />
                           <span className="radio-circle" />
                           Extracción
                        </label>
                        <label
                           className={`color-option yellow ${selectedColor === "#f0ad4e" ? "selected" : ""
                              }`}
                        >
                           <input
                              className="radio-hidden"
                              type="radio"
                              name="color"
                              value="#f0ad4e"
                              onChange={handleColorChange}
                           />
                           <span className="radio-circle" />A Extraer
                        </label>
                        <label
                           className={`color-option white ${selectedColor === "white" ? "selected" : ""
                              }`}
                        >
                           <input
                              className="radio-hidden"
                              type="radio"
                              name="color"
                              value="white"
                              onChange={handleColorChange}
                           />
                           <span className="radio-circle" />
                           Borrar
                        </label>
                     </div>
                     <div className="flex flex-wrap flex-row justify-content-center ">
                        <div style={{ borderRight: "2px solid black" }}>
                           <div className="dientes-contaniner">
                              <BotonDiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={8}
                                 cant={18}

                              />
                           </div>

                           <div className="dientes-contaniner justify-content-end" style={{ borderBottom: "2px solid black" }}>
                              <BotonMinidiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={5}
                                 cant={55}
                              />
                           </div>

                           <div className="dientes-contaniner justify-content-end">
                              <BotonMinidiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={5}
                                 cant={85}
                              />
                           </div>

                           <div className="dientes-contaniner">
                              <BotonDiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={8}
                                 cant={48}
                              />
                           </div>
                        </div>
                        <div>
                           <div className="dientes-contaniner">
                              <BotonDiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={8}
                                 cant={21}
                              />
                           </div>

                           <div className="dientes-contaniner" style={{ borderBottom: "2px solid black" }}>
                              <BotonMinidiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={5}
                                 cant={61}
                              />
                           </div>

                           <div className="dientes-contaniner">
                              <BotonMinidiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={5}
                                 cant={71}
                              />
                           </div>

                           <div className="dientes-contaniner">
                              <BotonDiente
                                 handleButtonClick={handleButtonClick}
                                 cantidad={8}
                                 cant={31}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </TabPanel>

         </TabView>
         <div style={{ borderRadius: '10px', height: "70px" }} className='flex justify-content-end gap-2 mt-2 bg-white'>
            <button className='p-button p-component btn-2 flex gap-1'>
               <i className='pi pi-save'> </i>
               <span>Guardar</span>
            </button>
            <button style={{ color: 'red' }} className='p-button  p-component btn-2 flex gap-1'>
               <i className='pi pi-times'></i>
               <span>Cancelar</span>
            </button>
            <button style={{ color: 'black' }} onClick={() => setImprimirVisible(true)} className='p-button p-component btn-2 flex gap-1'>
               <i className='pi pi-print'></i>
               <span>Imprimir</span>
            </button>
         </div>
         <ModalImprimir
            imprimirVisible={imprimirVisible}
            setImprimirVisible={setImprimirVisible}
         />
      </div>


   );
};

export default Odontrograma;
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import React from 'react';

const LaboratorioListComponent = ({ parametros, handleInputChange, handleInputInicial, handleInputFinal,viewCodigo=true }) => {

   const estiloA = {
      background: 'green',
      color: 'white'
   }
   const estiloB = {
      background: 'red',
      color: 'white'
   }


   return (
      <div>
         <table className='tabla'>
            <thead>
               <tr>
                  <th className="encabezado" style={{ width: '10%', textAlign: "center" }}>Código</th>
                  <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>Prueba</th>
                  <th className="encabezado" style={{ width: '20%', textAlign: "center" }}>Resultado</th>
                  <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>U.M.</th>
                  <th className="encabezado" >Rango</th>
                  <th className="encabezado" style={{ width: '15%', textAlign: "center" }}>Valor Normal</th>
               </tr>
            </thead>
            <tbody>
               {
                  parametros && parametros?.map((parametro, index) => {
                     if(index==16){
                        return null
                     }else{
                        return (
                           <tr key={index}>
                              {viewCodigo&&(<td className="dato">{parametro.codigo}</td>)}
                              <td className="dato">{parametro.prueba}</td>
                              <td className="dato">
                                 <InputText
                                    inputStyle={
                                       parametro.resultado >= parseFloat(parametro.rango_inicial) &&
                                          parametro.resultado <= parseFloat(parametro.rango_final) ?
                                          estiloA : estiloB
                                    }
                                    placeholder="Resultado"
                                    value={parametro.resultado}
                                    onChange={(e) => handleInputChange(index, e.target)}
                                    minFractionDigits={2} maxFractionDigits={5}
                                 />
                              </td>
                              <td className="dato">{parametro.um}</td>
                              <td className="dato">
                                 <InputText className='w-5' style={{ fontSize: '13px' }} value={parametro.rango_inicial}
                                    onChange={(e) => handleInputInicial(index, e.target.value)}
                                 />
                                 <span> - </span>
                                 <InputText className='w-5' style={{ fontSize: '13px' }} value={parametro.rango_final}
                                    onChange={(e) => handleInputFinal(index, e.target.value)} />
                              </td>
                              <td className="dato">{parametro.valor_normal}</td>
                           </tr>
                        )

                     }
                     
                  })
               }
            </tbody>
         </table>
      </div>
   );
}

export default LaboratorioListComponent;

import React, { useContext } from 'react';
import PageTriaje from "../../../../../Page/Triaje/PageTriaje";
import { TriajeProvider } from "../../../../../Page/Triaje/TriajeContext";
import { PsicologiaProvider } from "../../../../../Page/Psicologia/PsicologiaContext";
import PagePsicologia from "../../../../../Page/Psicologia/PagePsicologia";
import PageEkg from "../../../../../Page/Ekg/PageEkg";
import { EkgProvider } from "../../../../../Page/Ekg/EkgContext";
import { OftProvider } from "../../../../../Page/Oftalmologia/OftContext";
import PageOft from "../../../../../Page/Oftalmologia/PageOft";
import PageAud from "../../../../../Page/Audiometria/PageAud";
import { AudProvider } from "../../../../../Page/Audiometria/AudContext";
import PageRad from '../../../../../Page/Radiologia/PageRad';

import { RadProvider } from '../../../../../Page/Radiologia/RadProvider';
import { MGProvider } from '../../../../../Page/MedicinaGeneral/MGProvider'
import PageMedicinaGeneral from '../../../../../Page/MedicinaGeneral/PageMedicinaGeneral';
import PageLaboratorio from '../../../../../Page/Laboratorio/PageLaboratorio';
import { LaboratorioProvider } from '../../../../../Page/Laboratorio/LaboratorioProvider';
import PageEspi from '../../../../../Page/Espirometria/PageEspirometria';
import { EspiProvider } from '../../../../../Page/Espirometria/EspiProvider';

import { OdoProvider } from '../../../../../Page/Odontologia/OdoProvider';
import PrincipalOdontograma from '../../../../../Page/Odontologia/PrincipalOdontograma';
import TabsNav from './TabsNav';
import { RAContext } from '../../../../../Page/RutaAtencion/RAContext';
import { GestionContext } from '../../../../../Page/ClinicaRol/GestionAtencion/services/GestionContext';

const AtencionTabNavigator = ({ arraytabNavigate }) => {

   let { pageViewContext } = useContext(GestionContext)

   const contentPage = () => {
      switch (pageViewContext) {
         case 0: //TRIAJE
            return (
               <>
                  <TriajeProvider>
                     <PageTriaje revision={1} />
                  </TriajeProvider>
               </>
            )
         case 1: //PSICOLOGÍA

            return (
               <>
                  <PsicologiaProvider>
                     <PagePsicologia revision={1}/>
                  </PsicologiaProvider>
               </>
            )
         case 2: //EKG
            return (
               <>
                  <EkgProvider>
                     <PageEkg revision={1}/>
                  </EkgProvider>
               </>
            )
         case 3: //OFT
            return (
               <>
                  <OftProvider>
                     <PageOft revision={1}/>
                  </OftProvider>
               </>
            )
         case 4: //AUD
            return (
               <>
                  <AudProvider>
                     <PageAud revision={1}/>
                  </AudProvider>
               </>
            )
         case 5: //  RAD
            return (
               <>
                  <RadProvider>
                     <PageRad revision={1}/>
                  </RadProvider>
               </>
            )
         case 6: //Laboratorio
            return (
               <>
                  <LaboratorioProvider>
                     <PageLaboratorio revision={1}/>
                  </LaboratorioProvider>
               </>
            )
         case 7: //AUD
            return (
               <>
                  <OdoProvider>
                     <PrincipalOdontograma revision={1}/>
                  </OdoProvider>
               </>
            )
         case 8: //Espirometria
            return (
               <>
                  <EspiProvider>
                     <PageEspi revision={1}/>
                  </EspiProvider>
               </>
            )
         case 9: //MEDGEN
            return (
               <>
                  <MGProvider>
                     <PageMedicinaGeneral revision={1}/>
                  </MGProvider>
               </>
            )
         default:
            return (
               <>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                     <span>404</span>
                  </div>
               </>
            )

      }

   }

   return (
      <>
         <div className="ratn_container">
            <div className='flex flex-row gap-3 align w-full'>
               <TabsNav array={arraytabNavigate} />
            </div>
            {/* <ModalImprimir
          imprimirVisible={imprimirVisible}
          setImprimirVisible={setImprimirVisible}
        /> */}
            <div className="ratn_content_container">
               {contentPage()}
            </div>
         </div>
      </>
   );
};

export default AtencionTabNavigator;
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";

//Componentes generales
import AuthUser from "../../../../../AuthUser";
import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";

//Componentes únicos
import MultiLevelTreeSelectWithAPIClinica from "../../../../../Components/Clinica/ProductoServicio/Servicios/components/SelectTipoServicioClinica";
import { DialogValidaciones } from "../../../../../Components/Clinica/ProductoServicio/Servicios/components/DialogValidaciones";
import { transformData } from "../../../../../Services/ProductosServicios/servicios";
import DialogCoorporativo from '../../../../../Components/Clinica/ProductoServicio/Servicios/components/DialogCorporativo'
import { ServicioContext } from "../../../../../Components/Clinica/ProductoServicio/Providers/ServicioProvider";

//Estilos 
import '../style/disenoTabla.css'
import './Style/PageEditarCoorStyeAP.css'

export default function PageAgregarServiciosCoorporativo() {

   const { http } = AuthUser()
   const toast = useRef(null);

   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { perfilesContext, setPerfilesContext, agregarElemento, serviciosContext, setServiciosContext
      , setServiciosValidacionContext, filtrarDatosSeleccionados, serviciosValidacionContext, borrarElemento } = useContext(ServicioContext);

   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [visibleDetalle, setVisibleDetalle] = useState(false)
   const [documentos, setDocumentos] = useState([]);

   // MIS VARIABLES (Guardan los datos que luego se deben enviar)
   const [NombreServicio, setNombreServicio] = useState();
   const [filtro, setFiltro] = useState(null);


   // FUNCION PARA INCREMENTAR EL ARRAY

   // DATOS Y FUNCIONES DEL POP UP
   const [visibleSelectServicios, setVisibleSelectServicios] = useState(false);
   const [RowDataActual, setRowDataActual] = useState(null);
   const [TipoDialog, setTipoDialog] = useState(0)


   //BODY CON LAS OPCIONES QUE SE MUESTRAN EN ENTRADA/RUTINA/SALIDA
   const OpcionesServicio = (rowData, Tipo, PrecioReferencial) => {
      return (
         <div style={{ gap: '5px' }}>
            {/* SELECCIONAR */}
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <button
                  style={{ backgroundColor: "rgb(235,235,255)", maxWidth: '120px' }}
                  className="ElementoSeleccionarBTNN w-full"
                  onClick={() => {
                     setVisibleSelectServicios(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo)
                  }}
               >
                  Seleccionar
               </button>
            </div>
            {/* PRECIO REFERENCIAL */}
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <p className="ElementoPrecioReferencial">S/. {rowData[PrecioReferencial].toFixed(2)}</p>

            </div>
            {/* VALIDACIONES */}
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <Button className="bg-white border-none"
                  icon="pi pi-check-square text-blue-700 text-xl"
                  onClick={() => {
                     setVisibleDetalle(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo);
                  }}></Button>
            </div>
         </div>
      );
   };

   const sendData = async () => {
      const data = ({
         "nombre": NombreServicio,
         "clinica_servicio_id": selectedTipoServicio,
         "tiempo_venta": 1,
         "perfiles": perfilesContext.map(profile => ({
            "nombre": profile.NameTipoPerfil,
            "entrada": {
               "areas_medicas": Object.entries(profile?.Entrada?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('1')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "capacitaciones": [],
               "examenes": Object.entries(profile?.Entrada?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('3')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "documentos": profile.documentosEntrada,
               "validaciones": profile.validacionesEntrada.length > 0 ? profile.validacionesEntrada : []
            },
            "rutina": {
               "areas_medicas": Object.entries(profile?.Rutina?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('1')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "capacitaciones": [],
               "examenes": Object.entries(profile?.Rutina?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('3')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "documentos": profile.documentosRutina,
               "validaciones": profile.validacionesRutina.length > 0 ? profile.validacionesRutina : []
            },
            "salida": {
               "areas_medicas": Object.entries(profile?.Salida?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('1')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "capacitaciones": [],
               "examenes": Object.entries(profile?.Rutina?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('3')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "documentos": profile.documentosSalida,
               "validaciones": profile.validacionesSalida.length > 0 ? profile.validacionesSalida : []
            }
         }))
      });

      dispatch(startLoading());

      try {
         const response = await http.post('clinica/paquete/create', data);

         if (response?.data?.resp) {
            showToast('success', 'Creado Correctamente', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast(
            "error",
            "Error al actualizar",
            'Contacte a soporte técnico'
         );
      } finally {
         dispatch(endLoading());
      }
   }

   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
      setFaltaTipoServicio(false);
   };

   const getServicios = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get('clinica/operaciones/servicio/get');

         if (response?.data?.resp || response?.data) {
            setServiciosContext(transformData([response.data.resp ? response.data.resp : response.data]))
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Datos no actualizados', 'Contactar a soporte técnico')
      } finally {
         dispatch(endLoading());
      }
   }

   const getDocumentos = async () => {

      dispatch(startLoading());

      try {
         const response = await http.get("documento/pdf/get");

         if (response?.data?.data || response?.data?.resp) {
            setDocumentos(response.data.data)
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   };

   const handleKeyDown = (e) => {

   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getServicios(),
            getDocumentos()
         ])
      } catch (error) {

      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


   const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;
      rowData[field] = newValue
   };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return (<div >
         <InputText
            style={{ maxHeight: '28px' }}
            placeholder="Ingrese un nombre"
            type="text" value={options.value}
            onChange={(e) => options.editorCallback(e.target.value)}

         />;

      </div>)
   };

   const TituloServicioPrecio = (

      <div className="contenedor-tarjetas">
         <div className="flex align-items-center justify-content-around" style={{ width: '100%', height: '100%' }}>
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <p style={{ padding: '0px 0px 0px 8px' }}>Servicios</p>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
               <p style={{ textAlign: 'center', padding: '0px 8px 0px 0px' }}>Precio Referencial</p>
            </div>
            <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
               <p style={{ textAlign: 'center', padding: '0px 8px 0px 0px' }}>Validaciones</p>
            </div>
         </div>
      </div>


   )
   const headerGroup = (
      <ColumnGroup>
         <Row style={{ width: '100%' }}>
            <Column header="" field="lastYearSale" />
            <Column header="Entrada" colSpan={2} />
            <Column header="Rutina" colSpan={2} />
            <Column header="Salida" colSpan={2} />
         </Row>
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header={TituloServicioPrecio} field="thisYearSale" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
         </Row>
      </ColumnGroup>
   );


   const busqueda = (e) => {
      if (e.target.value !== '') {
         let datosFiltrados = perfilesContext.filter(data => data.NameTipoPerfil.toLowerCase().includes(e.target.value.toLowerCase()))
         setFiltro(datosFiltrados)
      } else {
         setFiltro(perfilesContext)
      }
   }

   const [FaltaNombrePack, setFaltaNombrePack] = useState(false);
   const [FaltaTipoServicio, setFaltaTipoServicio] = useState(false);

   const VerificarFaltas = () => {
      var verifica = true;
      if (NombreServicio === "" || NombreServicio === null) {
         setFaltaNombrePack(true);
         verifica = false;
      }
      if (selectedTipoServicio === "" || selectedTipoServicio === null) {
         setFaltaTipoServicio(true);
         verifica = false;
      }
      return verifica;
   }

   const handleSubmit = () => {
      var veri = VerificarFaltas();
      if (veri) {
         sendData()
      }
   }
   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Nuevo Servicio - Coorporativo</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white h-auto p-3 pl-5 pr-5 ContenedorWhiteAHP gap-2 flex flex-wrap align-items-start">


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Nombre:
                  </label>
                  <span className="p-input-icon-right border-round-lg">
                     <i className="pi pi-pencil" />

                     <InputText
                        placeholder="Paquete anual- Primax"
                        className={`add__container_main_content_header_inputs_singular_input ${FaltaNombrePack ? 'active' : ''}`}
                        value={NombreServicio}
                        onChange={(e) => {
                           setNombreServicio(e.target.value); setFaltaNombrePack(false)
                        }}
                     />

                  </span>
                  <div className={`divFaltaHeighttTransition ${FaltaNombrePack ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaNombrePack ? 'active' : ''}`}>Se necesita ingresar datos</p>
               </div>


               <div className="flex flex-1 flex-column relative">

                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Tipo de servicio:
                  </label>

                  <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                     faltt={FaltaTipoServicio}
                  />

                  <div className={`divFaltaHeighttTransition ${FaltaTipoServicio ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaTipoServicio ? 'active' : ''}`}>Se necesita ingresar un tipo de servicio</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1" style={{ opacity: '0' }}>
                     Buscar:
                  </label>
                  <span className=" p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                     <i className=" px-2 pi pi-search" />
                     <InputText placeholder="Buscar..." className="w-full" onChange={(e) => { busqueda(e) }} />
                  </span>
               </div>

            </div>
            { }
            <div className="add__container_main_content_body">
               <div className="add__container_main_content_body_container">
                  <ScrollPanel
                     style={{ width: "100%", padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid" style={{ height: 'auto', backgroundColor: 'transparent' }}>
                        <div className="Cabezera_Tabla_AHP-2">
                           <DataTable
                              className="Cabezera_Tabla_AHP"
                              value={filtro ? filtro : perfilesContext}
                              headerColumnGroup={headerGroup}
                              editMode="cell"
                              tableStyle={{ width: '100%', height: 'auto' }}
                              size="small"
                           >
                              <Column field="NameTipoPerfil" header="Tipo Perfil:" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete} body={(rowData) => (
                                 <>
                                    {rowData.NameTipoPerfil}
                                    <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" onClick={() => borrarElemento(rowData.id)} />
                                 </>
                              )} ></Column>
                              <Column header="Entrada:" body={(rowData) => OpcionesServicio(rowData, 1, "PrecioReferencialEntrada")}></Column>
                              <Column header="Rutina:" body={(rowData) => OpcionesServicio(rowData, 2, "PrecioReferencialRutina")} ></Column>
                              <Column header="Salida:" body={(rowData) => OpcionesServicio(rowData, 3, "PrecioReferencialSalida")}></Column>
                           </DataTable>
                           {/* NO ELIMINAR */}
                           <div className="">
                              <div className="ParteInferiorTablar">
                                 <div></div>
                                 <div></div>
                                 <div></div>
                                 <div></div>
                              </div>
                           </div>
                           {/* NO ELIMINAR */}

                           <DialogCoorporativo
                              visible={visibleSelectServicios}
                              setVisible={setVisibleSelectServicios}
                              rowData={RowDataActual}
                              TipoDialog={TipoDialog}
                              DataGeneral={perfilesContext}
                              setDataGeneral={setPerfilesContext}
                              DataServicios={serviciosContext}
                              documentos={documentos}
                              // DataEnvio={DataEnvio}
                              setServiciosValidacion={setServiciosValidacionContext}
                              filtrarDatosSeleccionados={filtrarDatosSeleccionados}
                           />
                        </div>
                     </div>
                  </ScrollPanel>
               </div>
               <div className="add__container_main_content_footer_one mt-4 md:mt-2 mb-3 md:mb-1">
                  <button
                     className="add__container_main_content_footer_one_btn"
                     onClick={agregarElemento}
                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
               </div>
               <div className="add__container_main_content_footer_two">
                  <button className="add__container_main_content_footer_two_btn ">
                     <Link
                        to="/productosyservicios/servicios/"
                        className="bg-colo-btn"
                        style={{ textDecoration: "none" }}
                     >
                        Cancelar
                     </Link>
                  </button>
                  <button className="button__add_new" onClick={handleSubmit}>Guardar</button>
               </div>
            </div>
         </div>
         <DialogValidaciones
            visible={visibleDetalle}
            setVisible={setVisibleDetalle}
            serviciosValidacionContext={serviciosValidacionContext}
            TipoDialog={TipoDialog}
            rowData={RowDataActual}
            DataGeneral={perfilesContext}
            setDataGeneral={setPerfilesContext}
            filtrarDatosSeleccionados={filtrarDatosSeleccionados}
            DataServicios={serviciosContext}
         />
      </div>
   );
} 
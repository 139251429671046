import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import { OftContext } from "../OftContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { iconOft } from "../../../Services/importIcons";
import "../../../Style/oft.css";
import BVoice from "../../../Components/ButtonVoice/BVoice";

const PageOftDiag = () => {
  let conclusionOptions = [
    { label: 'Normal', id: 1 },
    { label: 'Anormal', id: 2 }
  ];
  let { pageViewContext, stateAtencion } = useContext(RAContext)
  let { diagContext, setDiagContext } = useContext(OftContext);

  const [diagnostico, setDiagnostico] = useState(diagContext?.diagnostico ?? '');
  const [plan, setPlan] = useState(diagContext?.plan ?? '');
  const [conclusion, setConclusion] = useState(diagContext?.conclusion ?? null);
  const [showClearIcon, setShowClearIcon] = useState(false); // Nueva variable de estado

  
  useEffect(() => {
    setDiagContext((prevContext) => ({
      ...prevContext,
      [`diagnostico`]: diagnostico,
      [`plan`]: plan,
      [`conclusion`]: conclusion,
    }));
  }, [diagnostico, plan, conclusion]);

  const handleDiagnosticoChange = (newValue) => {
    if (newValue.length <= 5000) {
      setDiagnostico(newValue);
    }
  };

  const handleConclusionChange = (e) => {
    setConclusion(e.value);
    setShowClearIcon(e.value !== null); // Actualiza el estado de la visibilidad de la "X"
  };

  return (
    <>
      <div className="oft_ant_titles">
        <div>
          <img src={iconOft} alt="Icon" />
        </div>
        <h3>Diagnóstico</h3>
      </div>

      <div className="oft_diag_flex_column">
        <div className='input_micro_label'>
          <label style={{ fontWeight: '650', fontSize: '16px', color: '#6b6b6b' }}>Diagnóstico</label>
        </div>
        <BVoice
  autoResize
  value={diagnostico}
  onChange={setDiagnostico}
  placeholder={"Comentario..."}
  disabled={stateAtencion[pageViewContext].estado === 3}
/>

      </div>

      <div className="oft_diag_flex_column">
        <div className='input_micro_label'>
          <label style={{ fontWeight: '650', fontSize: '16px', color: '#6b6b6b' }}>Plan (Recomendaciones)</label>
        </div>
        <BVoice
          autoResize
          value={plan}
          onChange={setPlan}
          placeholder={"Comentario..."}
          disabled={stateAtencion[pageViewContext].estado === 3}
        />
      </div>

      <div className="oft_diag_flex_column">
        <label style={{ fontWeight: '650', fontSize: '16px', color: '#6b6b6b' }}>Conclusión</label>
        <Dropdown
          options={conclusionOptions}
          placeholder="Seleccione una opción"
          optionValue='label'
          value={conclusion}
          onChange={handleConclusionChange}
          showClear={showClearIcon} // Usamos la variable de estado para controlar la visibilidad de la "X"
          disabled={stateAtencion[pageViewContext].estado === 3}
        />
      </div>
    </>
  );
};

export default PageOftDiag;

import React, { useState } from 'react'
import { Button } from "primereact/button";

//Componentes únicos
import UpdatePersonaComponent from '../usuario/components/UpdatePersona';
import UpdatePersonaIMGComponent from '../usuario/components/UpdatePersonaIMG';

//Estilos únicos
import "../../../Style/PagePerfilGeneral/USU-0/Persona-Coorporativo.css";

//Imágenes
import CorreoIcon from "../../../Images/Iconperfil/Correo.png"
import AvatarMap from "../../../assets/36.png"
import Celular from "../../../Images/Iconperfil/Celular.png"

const PersonaComponentCoorporativo = ({ showToast, persona, personaUpdate, setPersonaUpdate, getPersona, institucion }) => {
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);

    return (
        <div className="BASE_CONTENEDOR_PERSONA_AHPerfil" >
            
            {/* Img de usuario */}
            <div className='POSICIONAMIENTO_IMAGEN' style={{ width: 'auto', height: 'auto' }}>
                <img
                    src={persona?.foto ?? AvatarMap}
                    alt="user"
                    className='IMGDEUSUARIO' />
                <Button icon="pi pi-pencil" onClick={() => setVisible2(true)} className="BOTONEDITARIMGUSARIO flex flex-row" />
            </div>

            <div className="CONTENEDOR_GENERAL">
                <div className='CONTENEDOR_BOTON_EDITAR'  >
                    <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{ margin: '0' }}>
                        <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                        <p className='TEXTOAGREGARUSUARIO'>Editar</p>
                    </Button>
                </div>
                <div className="CONTENEDOR_DATA_GENERAL">

                    {/* Datos y botones */}
                    <div className='w-full' style={{ borderBottom: '0.45px solid #88888E99' }}>
                        <h3 className='TITULO_CONT_UP'>
                            {persona?.nombres} {persona?.apellido_paterno} {persona?.apellido_materno}
                        </h3>
                        <h6 className='SUBTITULO_CONT_DOWN' style={{ border: '0' }}>{institucion}</h6>
                    </div>

                    {/* Datos del usuario parte inferior */}
                    <div className="CONTENEDOR_DOWN_AHP">
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img src={CorreoIcon} alt=''/>
                            <h4 className='TITULO_CONT_DOWN'>Correo</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {persona?.correo === "null" || !persona?.correo ? "No hay correo" : persona?.correo}
                            </p>
                        </div>
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img src={Celular} alt=''/>
                            <h4 className='TITULO_CONT_DOWN'>Celular</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {(persona?.celular === "null" || !persona?.celular) ? "No hay celular" : persona?.celular}
                            </p>
                        </div>

                    </div>

                </div>


            </div>
            {/* Dialog de actualizar Datos de la persona */}
            <UpdatePersonaComponent
                getPersona={getPersona}
                personaUpdate={personaUpdate}
                setVisible={setVisible}
                visible={visible}
                institucion={institucion}
                showToast={showToast}
            />
            {/* Dialog de actualizar la imagen de la persona */}
            <UpdatePersonaIMGComponent
                getPersona={getPersona}
                personaUpdate={personaUpdate}
                setVisible={setVisible2}
                visible={visible2}
            />
        </div>
    )
}

export default PersonaComponentCoorporativo;
let nextId = 1;

function obtenerElementos(item, prefix = '') {
   if (!item) {
      return [];
   }

   const areasMedicas = item.areas_medicas || [];
   const examenes = item.examenes || [];

   const elementosConPrefijo = [
      ...areasMedicas.map(elemento => ({ ...elemento, id: `${prefix}1-${elemento.id}` })),
      ...examenes.map(elemento => ({ ...elemento, id: `${prefix}3-${elemento.id}` }))
   ];

   return elementosConPrefijo;
}

function buscarElementosConChildrenVacios(arr, parentId = null) {
   const elementosConChildrenVacios = [];
   for (let i = 0; i < arr.length; i++) {
      const elemento = arr[i];
      if (elemento.children && Array.isArray(elemento.children)) {
         if (elemento.children.length === 0) {
            const idConPadre = parentId ? `${parentId}.${elemento.id}` : elemento.id;
            elementosConChildrenVacios.push(idConPadre);
         } else {
            const hijosConIds = buscarElementosConChildrenVacios(elemento.children, parentId ? `${parentId}.${elemento.id}` : elemento.id);
            elementosConChildrenVacios.push(...hijosConIds);
         }
      }
   }
   if (parentId) {
      elementosConChildrenVacios.push(parentId);
   }
   return elementosConChildrenVacios;
}

function compararArrays(arr1, arr2, cant, ArrayPadre) {
   const resultado = {};

   arr2.forEach((item) => {
      if (item.startsWith("1")) {
         resultado["areas_medicas"] = { checked: false, partialChecked: true };
      }
      // Verificar si el item comienza con "3"
      else if (item.startsWith("3")) {
         resultado["examenes"] = { checked: false, partialChecked: true };
      }
      const foundExactMatch = arr1.some(obj => obj.key === item);

      if (foundExactMatch) {
         // Si hay coincidencia exacta, establece los valores correspondientes
         resultado[item] = { checked: true, partialChecked: false };
      } else {
         resultado[item] = { checked: false, partialChecked: true };
      }
   });
   const ArrayPadreOrdenado = ordenarElementosPorId(ArrayPadre);
   for (let i = 0; i < ArrayPadreOrdenado.length; i++) {
      let medidor = true;

      ArrayPadreOrdenado[i].hijos.forEach((item) => {
         if (resultado[item] && resultado[item].checked) {
         } else {
            medidor = false;
            // Si algún hijo no está chequeado, no necesitas seguir verificando
            return;
         }
      });

      if (medidor) {
         resultado[ArrayPadreOrdenado[i].id] = { checked: true, partialChecked: false };
      }
   }
   return resultado;
}

const retornarTotal = (array) => {
   let vartotal = 0;
   for (let i = 0; i < array.areas_medicas.length; i++) {
      vartotal += array.areas_medicas[i].precio_referencial_mensual_venta
      if (array.areas_medicas[i].children.length > 0) {
         for (let e = 0; e < array.areas_medicas[i].children.length; e++) {
            vartotal += array.areas_medicas[i].children[e].precio_referencial_mensual_venta
            if (array.areas_medicas[i].children[e].children.length > 0) {
               for (let a = 0; a < array.areas_medicas[i].children[e].children.length; a++) {
                  vartotal += array.areas_medicas[i].children[e].children[a].precio_referencial_mensual_venta
               }
            }
         }
      }
   }
   for (let i = 0; i < array.examenes.length; i++) {
      vartotal += array.examenes[i].precio_referencial_mensual_venta
      if (array.examenes[i].children.length > 0) {
         for (let e = 0; e < array.examenes[i].children.length; e++) {
            vartotal += array.examenes[i].children[e].precio_referencial_mensual_venta
            if (array.examenes[i].children[e].children.length > 0) {
               for (let a = 0; a < array.examenes[i].children[e].children.length; a++) {
                  vartotal += array.examenes[i].children[e].children[a].precio_referencial_mensual_venta
               }
            }
         }
      }
   }
   return vartotal
}

function ordenarElementosPorId(array) {
   const elementosNumericos = [];
   const elementosConLetras = [];

   // Separar los elementos numéricos de los que tienen letras
   array.forEach(elemento => {
      if (/^\d/.test(elemento.id)) {
         elementosNumericos.push(elemento);
      } else {
         elementosConLetras.push(elemento);
      }
   });

   // Ordenar elementos numéricos por la longitud del ID de manera descendente
   elementosNumericos.sort((a, b) => b.id.length - a.id.length);

   // Concatenar elementos numéricos ordenados con elementos con letras
   const resultado = elementosNumericos.concat(elementosConLetras);

   return resultado;
}

export function setPaquete(paquete, ArraySimplificado, ArraySimplificadoPAdres, setPerfilesContext) {
   let perfiles = paquete.perfil_tipos.map(profile => {

      // Incrementar el id y luego usarlo
      const newId = nextId++;

      let Items1 = obtenerElementos(profile.perfil_tipo_items[0]);
      let itemsVacios1 = buscarElementosConChildrenVacios(Items1);
      let ItemsSeleccionados1 = compararArrays(ArraySimplificado, itemsVacios1, ArraySimplificado.length, ArraySimplificadoPAdres);
      let Items2 = obtenerElementos(profile.perfil_tipo_items[1]);
      let itemsVacios2 = buscarElementosConChildrenVacios(Items2);
      let ItemsSeleccionados2 = compararArrays(ArraySimplificado, itemsVacios2, ArraySimplificado.length, ArraySimplificadoPAdres);
      let Items3 = obtenerElementos(profile.perfil_tipo_items[2]);
      let itemsVacios3 = buscarElementosConChildrenVacios(Items3);
      let ItemsSeleccionados3 = compararArrays(ArraySimplificado, itemsVacios3, ArraySimplificado.length, ArraySimplificadoPAdres);


      let Entrada = [{ ItemsSeleccionados: ItemsSeleccionados1, OnlyKeys: itemsVacios1 }];
      let Rutina = [{ ItemsSeleccionados: ItemsSeleccionados2, OnlyKeys: itemsVacios2 }];
      let Salida = [{ ItemsSeleccionados: ItemsSeleccionados3, OnlyKeys: itemsVacios3 }];

      let validacionEntrada = profile.perfil_tipo_items[0].validaciones.map((validacion) => {
         return {
            id: validacion.validacion.id,
            tipo_id: validacion.validacion.tipo_id,
            genero_id: validacion.validacion.genero_id,
            edad: validacion.validacion.edad,
            edad_id: validacion.validacion.edad_id,
            descripcion: validacion.validacion.comentario,
            selectedNodeKeys: Object.fromEntries(validacion.selectedNodeKeys.map(key => [key, true])),
         }
      })

      let validacionRutina = profile.perfil_tipo_items[1].validaciones.map((validacion) => {
         return {
            id: validacion.validacion.id,
            tipo_id: validacion.validacion.tipo_id,
            genero_id: validacion.validacion.genero_id,
            edad: validacion.validacion.edad,
            edad_id: validacion.validacion.edad_id,
            descripcion: validacion.validacion.comentario,
            selectedNodeKeys: Object.fromEntries(validacion.selectedNodeKeys.map(key => [key, true])),
         }
      })

      let validacionSalida = profile.perfil_tipo_items[2].validaciones.map((validacion) => {
         return {
            id: validacion.validacion.id,
            tipo_id: validacion.validacion.tipo_id,
            genero_id: validacion.validacion.genero_id,
            edad: validacion.validacion.edad,
            edad_id: validacion.validacion.edad_id,
            descripcion: validacion.validacion.comentario,
            selectedNodeKeys: Object.fromEntries(validacion.selectedNodeKeys.map(key => [key, true])),
         }
      })

      return {
         id: newId,
         NameTipoPerfil: profile.perfil.nombre,
         Entrada: Entrada,
         documentosEntrada: profile.perfil_tipo_items[0].documentos,
         PrecioReferencialEntrada: retornarTotal(profile.perfil_tipo_items[0]),
         validacionesEntrada: validacionEntrada,
         Rutina: Rutina,
         documentosRutina: profile.perfil_tipo_items[1].documentos,
         PrecioReferencialRutina: retornarTotal(profile.perfil_tipo_items[1]),
         validacionesRutina: validacionRutina,
         Salida: Salida,
         documentosSalida: profile.perfil_tipo_items[2].documentos,
         PrecioReferencialSalida: retornarTotal(profile.perfil_tipo_items[2]),
         validacionesSalida: validacionSalida,
      };

   });
   setPerfilesContext(perfiles)
}

// export function setPaquete(paquete, ArraySimplificado, ArraySimplificadoPAdres, setPerfilesContext) {
//    console.log("paqueteeee", paquete)
//    const newId = nextId++;

//    let perfiles = paquete.perfiles.map((perfil) => {
//       return {
//          id: newId,
//          NameTipoPerfil: perfil?.nombre,
//          Entrada: perfil?.entrada?.areas_medicas,
//          documentosEntrada: perfil?.entrada?.documentos,
//          PrecioReferencialEntrada: 0,
//          validacionesEntrada: perfil?.entrada?.validaciones,
//          Rutina: perfil?.rutina?.areas_medicas,
//          documentosRutina: perfil?.rutina?.documentos,
//          PrecioReferencialRutina: 0,
//          validacionesRutina: perfil?.rutina?.validaciones,
//          Salida: perfil?.salida?.areas_medicas,
//          documentosSalida: perfil?.salida?.documentos,
//          PrecioReferencialSalida: 0,
//          validacionesSalida: perfil?.salida?.validaciones
//       }
//    })

//    console.log(perfiles)
//    setPerfilesContext(perfiles)

// }
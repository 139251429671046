import React, { useContext, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
   iconCANCELADO,
   iconNO_REALIZADO,
   iconPROCESO,
   iconREALIZADO,
} from "../../../Services/importIcons";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import RAResultadosOverlay from "./RAResultadosOverlay";
import { useNavigate } from "react-router-dom";

const RAResultadosTable = ({ pacientes, nombre }) => {

   const { listRutasContext } = useContext(RAContext);
   const { setAtencionContext } = useContext(RAContext);
   const { setPacienteContext, setPageViewContext } = useContext(RAContext);

   const [visibleDialog, setVisibleDialog] = useState(false);
   const [globalFilter, setGlobalFilter] = useState("");

   let navigate = useNavigate();

   //alternativa al switch
   const switchFunction = () => {
      const optionMapping = {
         //[Nombre de area] : [indice de pageViewContext]
         Triaje: 0,
         Psicología: 1,
         EKG: 2,
         Oftalmología: 3,
         Audiometría: 4,
         Radiología: 5,
         Laboratorio: 6,
         Odontología: 7,
         Espirometria: 8,
         MedicinaGeneral: 9,
         default: 0,
      };

      setPageViewContext(optionMapping[nombre] || optionMapping.default);
   };

   const handlePaciente = (rowData) => {
      setAtencionContext(rowData);
      // setPacienteContext(rowData);
      switchFunction();
      navigate(`/rutaatencion/atencion/${rowData.id}`);
   };

   const handleRuta = (rowData) => {
      let data = rowData?.hoja_ruta?.areas_medicas.concat(rowData?.hoja_ruta?.examenes)
      setAtencionContext(data);
      setPacienteContext(rowData);
      setVisibleDialog(true);
   };

   const getStatusService = (status) => {
      status = parseInt(status, 10);
      switch (status) {
         case 0:
            return iconNO_REALIZADO;
         case 1:
            return iconPROCESO;
         case 2:
            return iconREALIZADO;
         case 3:
            return iconCANCELADO;
         case 4:
            return null;
         default:
            return null;
      }
   };

   const bodyhojaRuta = (rowData) => {
      return (
         <div
            className="end_ruta"
            style={{
               display: "flex",
               width: "100%",
               alignItems: "center",
               flexWrap: "wrap",
               justifyContent: "center",
            }}
         >
            <div className="table-icon-link-atenciones-ruta">
               {rowData?.hoja_ruta?.areas_medicas?.map((item, index) => {
                  let hojaRuta = listRutasContext?.findIndex((v) => v?.nombre === item?.nombre);
                  if (hojaRuta !== -1 ) return (
                     <div
                        className="table-icon-link-item-atenciones-ruta"
                        style={{ position: "relative" }}
                        key={index}
                     >
                        <img
                           src={listRutasContext[hojaRuta]?.icon}
                           width={17}
                           height={17}
                           alt="ruta_icons"
                           title={item.nombre}
                        />
                        {
                           <img
                              src={getStatusService(
                                 item.estado_ruta
                              )}
                              width={18}
                              height={18}
                              style={{
                                 position: "absolute",
                                 right: "-12px",
                                 top: "-5px",
                              }}
                              alt="ruta_icons"
                           />
                        }
                     </div>)
                     return <></>
               })}
               {rowData?.hoja_ruta?.examenes?.map((item, index) => {
                  let hojaRuta = listRutasContext?.findIndex((v) => v?.nombre === item?.nombre);
                  return (
                     <div
                        className="table-icon-link-item-atenciones-ruta"
                        style={{ position: "relative" }}
                        key={index}
                        title={item.nombre}
                     >
                        <img
                           src={listRutasContext[hojaRuta]?.icon}
                           width={17}
                           height={17}
                           alt="ruta_icons"
                        />
                        {
                           <img
                              src={getStatusService(
                                 item.estado_ruta
                              )}
                              width={18}
                              height={18}
                              style={{
                                 position: "absolute",
                                 right: "-12px",
                                 top: "-5px",
                              }}
                              alt="ruta_icons"
                           />
                        }
                     </div>)
               })}
               {/* <div className="rar_table_user_action_buttons">
               <Button
                  icon="fa-solid fa-briefcase-medical"
                  className="p-button-rounded"
                  onClick={() => handleRuta(rowData)}
                  title="Detalles"
               />
               </div> */}
            </div>
            <div className="rar_table_user_action_buttons mt-2">
               <Button
                  icon="fa-solid fa-briefcase-medical"
                  className="p-button-rounded"
                  onClick={() => handleRuta(rowData)}
                  title="Detalles"
               />
            </div>
         </div>
      );
   };

   const actionTemplate = (rowData) => {
      return (
         <Button
            icon={"fa-solid fa-folder-open"}
            className="p-button-rounded"
            onClick={() => handlePaciente(rowData)}
            style={{ height: "100%", width: "47px" }}
            title="Atención"
         />
      );
   };

   const paginatorLeft = (
      <Button
         type="button"
         icon="pi pi-refresh"
         className="p-button-text"
         title="Actualizar"
      />
   );

   return (
      <>
         <div
            className="arriba"
            style={{
               fontFamily: "Montserrat",
               fontSize: "25px",
               fontWeight: "500",
               color: "#5555D8",
            }}
         >
            <span className="">{nombre} </span>
            <br />
         </div>
         <div className="flex justify-content-end mb-3">
            <InputText
               placeholder="Ingrese una búsqueda"
               onChange={(e) => setGlobalFilter(e.target.value)}
            />
         </div>
         
         <DataTable
            value={pacientes}
            globalFilter={globalFilter}
            dataKey="id"
            responsiveLayout="stack"
            rows={10}
            rowsPerPageOptions={[10, 20, 50]}
            paginatorLeft={paginatorLeft}
            paginatorRight={<></>}
            tableStyle={{ width: "100%" }}
         >
            <Column
               //field="id"
               header="Ítem"
               align="center"
               body={(rowData, rowIndex) => {
                  return (
                        <div>{rowIndex.rowIndex + 1}</div>
                  );
               }}
               style={{ width: "4%" }}
            />
            <Column
               field="nombres_completos"
               header="Nombres Completos"
               style={{ width: "16%", whiteSpace: "wrap", textAlign: "center" }}
            />
            <Column
               field="persona.tipo_documento.nombre"
               header="Documento"
               align="center"
               style={{ width: "8%", whiteSpace: "wrap" }}
            />
            <Column
               field="numero_documento"
               header="Nº de documento"
               align="center"
               style={{ width: "10%", whiteSpace: "wrap" }}
            />
            <Column
               field="fecha_atencion"
               header="Fecha"
               align="center"
               style={{ width: "8%" }}
            />
            <Column
               body={bodyhojaRuta}
               header="Hoja de Ruta"
               align="center"
               style={{ width: "15%" }}
            />
            <Column
               body={actionTemplate}
               header="Acción"
               align="center"
               style={{ width: "5%" }}
            />
         </DataTable>

         <div className="rar_card-options-table">
            {listRutasContext?.map((btn, index) => (
               <div
                  style={{
                     display: "flex",
                     margin: "0px 10px",
                     alignItems: "center",
                  }}
                  key={index}
               >
                  <img
                     src={btn?.icon}
                     style={{ width: "15px", height: "15px" }}
                     alt="ruta_icons"
                  />
                  <p style={{ marginLeft: "5px" }}>{btn?.nombre}</p>
               </div>
            ))}
         </div>

         <RAResultadosOverlay
            visible={visibleDialog}
            setVisible={setVisibleDialog}
         />
      </>
   );
};

export default RAResultadosTable;

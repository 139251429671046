import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { PersonaComponent, EmpresaComponent, PermisosComponent, Detraccion, CrearCcomponent } from "../../Components/Perfil/usuario/components/index";
import AuthUser from "../../AuthUser";
import "../../Components/Perfil/style/perfil-style.css";
import { startLoading, endLoading, } from "../../Redux/isLoadingSlice";
import { useDispatch} from "react-redux";

const PagePerfil = ({ getAllPersona }) => {

   const dispatch = useDispatch();

   const toast = useRef(null);
   const { http } = AuthUser();
   const [tipoDcoumentos, setTipoDcoumentos] = useState([]);
   const [bregma, setBregma] = useState([]);
   const [bregmaUpdate, setBregmaUpdate] = useState({
      id: null,
      tipo_documento: null,
      numero_documento: null,
      razon_social: null,
      direccion: null,
      celular: null,
      correo: null,
      distrito: null,
      distrito_id: null,
      whatsapp: null,
      sitio_web: null
   })
   const [persona, setPersona] = useState({})
   const [personaUpdate, setPersonaUpdate] = useState({})
   const [entidadPagos, setEntidadPagos] = useState([])
   const [entidadPagoElectronico, setEntidadPagoElectronico] = useState([])
   const [detracciones, setDetracciones] = useState([])
   const [entidadesBancarias, setEntidadesBancarias] = useState([])
   const [dataPersona, setDataPersona] = useState()

   const getBregma = async () => {
      try {
         const response = await http.get("bregma/show");
         if (response?.data?.resp) {
            setBregma(response.data.resp);
            setBregmaUpdate({
               ...bregmaUpdate,
               id: response.data.resp.id,
               tipo_documento: response.data.resp.tipo_documento,
               tipo_documento_id: response.data.resp.tipo_documento_id,
               numero_documento: response.data.resp.numero_documento,
               razon_social: response.data.resp.razon_social,
               direccion: response.data.resp.direccion,
               celular: response.data.resp.celulares?.[0]?.celular,
               correo: response.data.resp.correos?.[0]?.correo,
               distrito: response.data.resp.distrito,
               distrito_id: response.data.resp.distrito_id,
               whatsapp: response.data.resp.whatsapp,
               sitio_web: response.data.resp.sitio_web
            });
            setEntidadPagos(response.data.resp.entidad_pagos);
            setDetracciones(response.data.resp.detracciones);
         } else if (response.data.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         console.error("error perfil", error);
      }
   };

   const getAllTipoDocumentos = async () => {
      try {
         const response = await http.get("/tipodocumentos/get");
         if (response?.data?.data) {
            setTipoDcoumentos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response?.data?.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
         console.error("error", error);
      }
   };

   const getEntidadesBancarias = async () => {
      try {
         const response = await http.get('entidad_bancaria/get');
         if (response?.data?.data) {
            setEntidadesBancarias(response.data.data)
         } else if (response?.data?.error) {
            showToast('error', 'Error', response?.data?.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contáctese con soporte técnico.')
         console.error("error", error);
      }
   }

   const getEntidadesElectronicas = async () => {
      try {
         const response = await http.get('entidad_electronica_pago/get');
         if (response?.data?.data) {
            setEntidadPagoElectronico(response.data.data)
         } else if (response?.data?.error) {
            showToast('Error', 'Error', response?.data?.error)
         }
      } catch (error) {
         showToast('Error', 'Error', 'Contacte a soporte técnico.')
         console.error("error", error);
      }
   }

   const getPersona = async () => {
      try {
         const response = await http.get('persona/show')
         if (response?.data?.resp) {
            setPersona(response?.data?.resp?.persona);
            setPersonaUpdate(response?.data?.resp?.persona);
            setDataPersona(response?.data?.resp)
            // getAllPersona(); //No presenta valores designados
         } else if (response?.data?.error) {
            showToast('error', 'Error', 'Contacte a soporte técnico')
         }
      } catch (error) {
         showToast('error', 'Error', 'Contacte a soporte técnico')
         console.error(error)
      }
   }

   const handleSubmitUpdate = async (id, data) => {
      dispatch(startLoading());
      try {
         const response = await http.put(`bregma/update/${id}`, data)
         if (response?.data?.resp) {
            await Promise.all([
               getBregma()
            ])
            showToast(
               "success",
               "Actualizado correctamente",
               "Datos de empresa actualizados"
            )
         }else if(response?.data?.error){
            showToast('error', 'Error', response?.data?.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contacte a soporte técnico.')
         console.error(error);
      } finally {
         dispatch(endLoading());
      }
   }

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const handleCreate = async (entidadPago, limpiarEntidadBancaria, CerrarModal) => {
      dispatch(startLoading());
      try {
         const response = await http.post("entidad_pago/create-bregma", entidadPago)
         if (response?.data?.resp) {
            await Promise.all([
               getBregma(),
               CerrarModal(),
               limpiarEntidadBancaria()
            ])
            showToast(
               "success",
               "Creación exitosa",
               "Cuenta creada"
            )
         } else if(response?.data?.error){
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         CerrarModal();
         showToast('error', 'Error', 'Contacte a soporte técnico')
         console.error("error", error);
      } finally {
         dispatch(endLoading())
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getBregma(),
            getAllTipoDocumentos(),
            getEntidadesBancarias(),
            getEntidadesElectronicas(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   // Skeleton
   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <>
         <Toast ref={toast} />
         <div className="ContenedorGeneralPerfil flex flex-column gap-3 mt-3 pr-6 pl-6">
            <div>
               <p className="tit "> Mi Perfil </p>
               <p className="con">Adminí­stralo a tu mejor manera </p>
            </div>
            <div className="gap-3 perfil-res ContenedorGeneralComponente" style={{ alignItems: 'center', justifyContent: 'center' }}>

               <div className=" flex flex-wrap ContenedorComponentePersona">
                  <PersonaComponent
                     getAllPersona={getAllPersona}
                     persona={persona}
                     personaUpdate={personaUpdate}
                     setPersonaUpdate={setPersonaUpdate}
                     getPersona={getPersona}
                     showToast={showToast}
                     dataPersona={dataPersona}
                  />
               </div>
               <div className="flex flex-wrap ContenedorComponenteEmpresa ">
                  <EmpresaComponent
                     dataShow={bregma}
                     data={bregmaUpdate}
                     setBregma={setBregmaUpdate}
                     getBregma={getBregma}
                     tiposDocumentos={tipoDcoumentos}
                     handleSubmitUpdate={handleSubmitUpdate}
                     showToast={showToast}
                     toast={toast}
                     dataPersona={dataPersona}
                     api={'bregma/actualizar-logo'}
                  />
               </div>

               {/* {isLoading ? <ViewEmpresa /> : dataPersona?.roles?.[0]?.id === 3 ? (<ViewEmpresa />) : (<>
                  <div className=" flex flex-wrap ContenedorComponentePersona">
                     <PersonaComponent
                        getAllPersona={getAllPersona}
                        persona={persona}
                        personaUpdate={personaUpdate}
                        setPersonaUpdate={setPersonaUpdate}
                        getPersona={getPersona}
                        showToast={showToast}
                        dataPersona={dataPersona}
                     />
                  </div>
                  <div className="flex flex-wrap ContenedorComponenteEmpresa ">
                     <ViewEmpresa />
                  </div>
               </>)} */}

               <div className="CONEDORINFEERIORPERFIL w-12 flex gap-3">
                  <div className="CONEDORINFEERIORPERMISOS w-3 " >

                     <PermisosComponent namefull={persona?.nombres + " " + (persona?.apellido_paterno ?? "") + " " + (persona?.apellido_materno ?? "")} />

                  </div>
                  <div className="CONEDORINFEERIORCREAR flex flex-column gap-3">
                     <div>
                        <Detraccion
                           detracciones={detracciones}
                           getbregma={getBregma}
                           showToast={showToast} />
                     </div>
                     <div>
                        {/* getelectronica */}
                        <CrearCcomponent
                           getEntidadesElectronicas={getEntidadesElectronicas}
                           getbregma={getBregma}
                           entidadPagos={entidadPagos}
                           entidadPagoElectronico={entidadPagoElectronico}
                           entidadesBancarias={entidadesBancarias}
                           handleCreate={handleCreate}
                           showToast={showToast}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
export default PagePerfil;
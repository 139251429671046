export const contentService = [
    {
       id: 1,
       codeProform: "Proforma1",
       razonS:"MEDICAL CARE SERVICES S.A.C",
       fechaCr:"17/04/2023",
       status: "En espera",
    },
    {
       id: 2,
       codeProform: "Proforma2",
       razonS:"MEDICAL CARE SERVICES S.A.C",
       fechaCr:"17/04/2023",
       status: "Aceptado",
    },
    {
       id: 3,
       codeProform: "Proforma3",
       razonS:"MEDICAL CARE SERVICES S.A.C",
       fechaCr:"17/04/2023",
       status: "Rechazado",
    },
    {
      id: 4,
      codeProform: "Proforma4",
      razonS:"MEDICAL CARE SERVICES S.A.C",
      fechaCr:"17/04/2023",
      status: "Rechazado",
   },
 ];
import React, { useEffect, useState, useRef } from "react";
import { ComponentContenedorServicvio } from "../../Components/ProductosServicios/components/index";
import AuthUser from "../../AuthUser";
import { Toast } from 'primereact/toast';
import { startLoading, endLoading, } from "../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function Servicios() {
   const dispatch = useDispatch();

   const { http } = AuthUser();
   const [paquetes, setPaquetes] = useState([]);
   const toast = useRef(null);

   const showToast = (severity, summary, message) => {
      toast.current.show({
         severity: severity,
         summary: summary,
         detail: message
      });
   };

   const getPaquetes = async () => {
      try {
         const response = await http.get("bregma/paquete/get")
         if (response?.data?.data) {
            setPaquetes(response.data.data);
            

         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error);
         }

      } catch (error) {
         console.error(error);
         showToast("error", "Error", "Contáctese con soporte técnico");
      };
   };

   const deletePaquete = async (paquete_id) => {
      dispatch(startLoading());
      try {
         const response = await http.delete(`bregma/paquete/delete/${paquete_id}`)
         if (response?.data?.resp) {
            await Promise.all([
               getPaquetes()
            ])
            showToast('success', 'Eliminado correctamente', response?.data?.resp);

         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error);
         }
      } catch (error) {
         console.error(error);
         showToast("error", "Error", "Contáctese con soporte técnico");
      } finally {
         dispatch(endLoading());
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getPaquetes(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      // getPaquetes();
      dispatch(startLoading());
      fetchAllData()
   }, []);

   return (
      <div>
         <Toast ref={toast} />
         <div>
            <span className="tit">Servicios</span>
            <br />
            <span className="con">Adminístralo a tu mejor manera</span>
         </div>
         <div>
            <ComponentContenedorServicvio paquetes={paquetes} update={getPaquetes} deletePaquete={deletePaquete} showToast={showToast} />
         </div>

      </div>
   );
}

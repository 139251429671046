import { createContext, useState } from "react";

const sendVentasContext = {
    sendVentas: {},
    setSendVentas: () => { },
};

export const SendVentasContext = createContext(sendVentasContext);

export function SendVentasProvider({ children }) {
    const [sendVentas, setSendVentas] = useState({
        GCEmpresa: {
            clinica_id: null,
            contrato_id: null,
            tipo_cliente_id: "",
            ruc: "",
            responsable: "",
            razon_social: "",
            departamento: "",
            provincia: "",
            distrito_id: "",
            direccion: "",
            Ubidelmapa: "",
            latitud: "",
            longitud: "",
            aniversario: "",
            rubro: "",
            cantTrabajadores: "",
            añosActividad: "",
            logo: "",
            logoType: "",
            logoSize: "",
            foto: "",
            loading: true,
        },
        GCContacto: {
            id: "",
            tipo_documento_id: 2,
            numero_documento: "",
            nombres: "",
            apellido_paterno: "",
            apellido_materno: "",
            cargo: "",
            fecha_nacimiento: "",   //birthday: "",
            hobbies: "",
            celular: "",
            telefono: "",
            correo: "",
            direccion: "",
            telefono_emergencia: "",
            contacto_emergencia: "",
            distrito_id: "",
            distrito_domicilio_id: "",
            estado_civil_id: "",
            religion_id: "",
            sexo_id: "",
            grado_instruccion_id: "",
            loading: true,
        },
        GCProducto: {
            seleccion: "",
            periodo: "",
            paquetes:[],
            precioReferencial: "",
            precioVenta: "",
        },
        GCCredenciales: {
            proformaFile: "",
            actitudFile: "",
            adicionalFile: "",
        },
        //Clínica Rol
        GVPartDatosPer: {
            //Primer Formulario
            nombresApellidosT: "", //Paciente (titular o menor)
            menorEdadT: false,
            dniT: "", //Paciente (titular o menor)
            fecha_nacimientoT: "", //Paciente (titular o menor)
            fechaT: "", //Paciente (titular o menor)
            //Paciente 2
            nombresApellidosT2: "", //Paciente o Tutor
            menorEdadT2: false, //Paciente o Tutor
            dniT2: "", //Paciente o Tutor
            fecha_nacimientoT2: "", //Paciente o Tutor
        },
        GVPartSeleccionPlan: [],
        GVPartCredenciales: {
            proformaFile: "",
            actitudFile: "",
            adicionalFile: "",
            other: "",
        },
    });

    const updateSendVentas = (newSendVentas) => {
        setSendVentas(newSendVentas);
    };

    return (
        <SendVentasContext.Provider value={{ sendVentas, setSendVentas: updateSendVentas }}>
            {children}
        </SendVentasContext.Provider>
    );
}
import React, { useState, useEffect } from "react";
import "../../Style/MedicinaGeneral/ExplPalpacion.css";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from "primereact/inputtextarea";
import VoiceInputTextarea from "../ButtonVoice/VoiceInputTextarea";

export default function CardMedicinaGeneral({ context, updateContext, tituloSeccion, PadreContext, index, opciones }) {

    const [dropcode, setDropCode] = useState(PadreContext?.["resultado"]);

    const [dropObject, setDropObject] = useState();


    useEffect(() => {
        const updateObject = opciones.find(item => item.code == dropcode);
        setDropObject(updateObject);
    }, [context]);

    useEffect(() => {
        updateContext(dropcode, "resultado", index)
    }, [dropcode]);

    const StyleSubtitulo = {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: '400',
        color: 'rgb(136, 136, 142)'
    }

    return (
        <tr className="OneCardMedGen">
            <style>
                {`
                    .OneCardMedGen{
                        padding: 5px !important;
                    }
                    .OneCardMedGen > td{
                        padding: 5px !important;
                    }
                    .OneCardMedGen > td:nth-child(1) > div{
                        height: 45px !important;
                    }
                `}
            </style>
            <td style={{ fontSize: "14px", color: "#565656", display: 'table-cell', alignContent: 'start' }}>
                <div className="w-full flex align-items-center justify-content-start"
                    style={{ ...StyleSubtitulo, height: '45px' }} >
                    {tituloSeccion}
                </div>
            </td>

            <td style={{ width: "180px", display: 'table-cell', alignContent: 'start' }} className="">
                <div className="w-full h-full" >
                    <Dropdown
                        value={dropObject}
                        onChange={(e) => setDropCode(e.value.code)}
                        options={opciones} optionLabel="name"
                        className="w-full" />
                </div>
            </td>

            <td className="flex w-full">
                <InputTextarea
                    autoResize
                    value={PadreContext?.["observacion"]}
                    onChange={(e)=>{updateContext(e.target.value, "observacion", index)}}
                    placeholder="Comentario" className="w-full"
                />
            </td>
        </tr>
    );
}

import React from 'react'
import '../../../Style/Botones.css';
import Botones from '../../../Components/Pure/MenuPrincipal/botones';
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes';
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses';
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Chica.png';
import Sombra from '../../../Images/Pure/MenuPrincipal/Img/sombra.png';

function PageContabilidadE() {
    return (
        <div className=' flex flex-nowrap flex-column w-full h-full'>
            <div className='arriba'>
                <span className='tit'>Contabilidad</span><br />
                <span className='con'>En este módulo usted podrá generar el costo por atención y la facturación de sus productos y/o servicios.</span>
            </div>
            <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center h-full'>
                <Botones
                    Link="/contabilidad/pagos"
                    icons="pi pi-map-marker"
                    span="Pagos "
                    button="button b2"
                />
                <Imagenes
                    src={Chica}
                    className="logo1"
                />
                <Imagenes
                    src={Sombra}
                    className="som"
                />
                <Eclipses />
            </div>
        </div>
    )
}

export default PageContabilidadE
import React, { useState } from "react";
import { Button } from "primereact/button";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';

export default function Paquete({
    paquetes,
    selectedNodes,
    sendProducto,
}) {

    //Suma Precio de Venta
    const sumarDescripciones = () => {
        let suma = 0;
        paquetes.forEach((node) => {
            if (selectedNodes.includes(node.key)) {
                suma += parseFloat(node.data.description);
                if (node.children && node.children.length > 0) {
                    suma += sumarDescripcionesRecursivas(node.children);
                }
            }
        });
        return suma.toFixed(2);
    };

    const sumarDescripcionesRecursivas = (children) => {
        let suma = 0;
        children.forEach((node) => {
            suma += parseFloat(node.data.description);
            if (node.children && node.children.length > 0) {
                suma += sumarDescripcionesRecursivas(node.children);
            }
        });
        return suma;
    };
    //Suma Precio Referencial
    const sumarPVenta = () => {
        let suma = 0;
        paquetes.forEach((node) => {
            if (selectedNodes.includes(node.key)) {
                suma += parseFloat(node.data.pVenta);
                if (node.children && node.children.length > 0) {
                    suma += sumarPVentaRecursivas(node.children);
                }
            }
        });
        return suma.toFixed(2)

    };

    const sumarPVentaRecursivas = (children) => {
        let suma = 0;
        children.forEach((node) => {
            suma += parseFloat(node.data.pVenta);
            if (node.children && node.children.length > 0) {
                suma += sumarPVentaRecursivas(node.children);
            }
        });
        return suma;
    };
    // estado para el valor de edición
    const [editingValue, setEditingValue] = useState(null);

    // función para abrir el diálogo de edición
    const openEditDialog = (node) => {
        setEditingValue(node.data.pVenta);
        setEditingNode(node);
        setEditDialogVisible(true);
    };

    // función para actualizar el valor de pVenta
    const updatePVenta = (node, value) => {
        node.data.pVenta = value.toFixed(2).toString();
        setEditDialogVisible(false);
    };
    // estado para el nodo en edición
    const [editingNode, setEditingNode] = useState(null);

    // estado para mostrar el diálogo de edición
    const [editDialogVisible, setEditDialogVisible] = useState(false);




    // función para renderizar el botón de edición
    const editButton = (node) => {
        return (
            <i
                className="pi pi-pencil "
                style={{ cursor: 'pointer' }}
                onClick={() => openEditDialog(node)}
            />
        );
    };
    return (
        //inicio del diseño

        <div className="flex flex-column justify-content-between bg-white border-round-lg p-3 flex-1">
            <div className="flex flex-column gap-2">

                <img
                    width="40px"
                    height="40px"
                    className="p-1 bg-indigo-100 border-round-lg"
                    src="https://i.ibb.co/Pr9nJDV/Icon.png"
                    alt=""
                />

                <div className="flex flex-column gap-2 ">
                    <div className="flex gap-2  ">
                        <p className="text-indigo-500 text-lg font-bold"> Paquete </p>
                        {/* <p className="text-indigo-400 ">{sendProducto.periodo}-MR 2023 </p> */}
                        <p className="text-indigo-400 ">Genoma Completo</p>
                    </div>
                    <p className="text-sm pb-3 border-bottom-1 border-gray-300">
                        Contenido total de pruebas habilitadas
                    </p>
                </div>

                <div >
                    <div className="flex   ml-4 mr-3 gap-6 ">
                        <p className="flex-1 text-indigo-500">Estudios Seleccionados</p>
                        <div className="flex flex-1 justify-content-center  align-items-center">
                            <p className=" mr-4 pr-3 pl-3 text-cyan-300 bg-cyan-100  border-round-lg">
                                Precio referencial
                            </p>
                        </div>
                        <div className="flex flex-1 justify-content-center    align-items-center">
                            <p className="mr-6 pr-3 pl-3 bg-orange-100 text-orange-300 text-center border-round-lg ">
                                Precio de Venta
                            </p>
                        </div>
                    </div>

                    <div className="GCProducto ">
                        <TreeTable
                            value={paquetes.filter((node) => selectedNodes.includes(node.key))}
                            className="ventas"
                            emptyMessage={<p className="text-center text-gray-500">Seleccione un plan para ver su detalle.</p>}
                            style={{ height: "25rem", overflowY: "scroll" }}
                        >
                            <Column field="name" expander></Column>
                            <Column body={(node) => (
                                <>

                                    <span >S/{node.data.description}</span>
                                </>
                            )} className=" text-center"   ></Column>
                            <Column className=" text-center text-green-500"
                                body={(node) => (
                                    <>
                                        {editButton(node)}
                                        <span >&nbsp;S/{node.data.pVenta}</span>
                                    </>
                                )}
                            ></Column>
                        </TreeTable>
                        <Dialog
                            header={`Editar Precio de ${editingNode?.data.name}`}
                            visible={editDialogVisible}
                            onHide={() => setEditDialogVisible(false)}
                        >
                            <div className="p-fluid">
                                <div className="p-field">
                                    <label htmlFor="pVentaInput">Nuevo Precio:</label>
                                    <InputNumber
                                        id="pVentaInput"
                                        value={editingValue}
                                        onValueChange={(e) => setEditingValue(e.value)}
                                        minFractionDigits={2}

                                    />
                                </div>
                            </div>
                            <div className="flex justify-content-center p-dialog-footer p-3">
                                <Button
                                    label="Cancelar"
                                    className="p-button-text text-white bg-indigo-500 border-indigo-500"
                                    onClick={() => setEditDialogVisible(false)}
                                />
                                <Button
                                    label="Guardar"
                                    className="p-button-text text-white bg-indigo-500 border-indigo-500"
                                    onClick={() => updatePVenta(editingNode, editingValue)}
                                />
                            </div>
                        </Dialog>


                    </div>

                </div>


            </div>
            <div className="flex-1 flex flex-column gap-2">
                <div className="flex  justify-content-between mr-5 ml-4 gap-6 bg-orange-100 border-round-md p-2 text-orange-500">

                    <p> Precio de venta total</p>
                    <p>
                        S/{sumarPVenta()}</p>
                    <p className="mr-6">
                        S/{sumarDescripciones()}
                    </p>
                </div>
                <div className="flex p-2 justify-content-end">
                    <Button
                        label="Continuar"
                        className=" overflow-visible bg-white text-indigo-500"
                    />
                </div>
            </div>

        </div>
    );

    
}
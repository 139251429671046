import React from "react";
import "../../../../Style/EstiloTabla.css"
import DialogServicios from "./Buttons/DialogServicios";

export default function TablaCoorporativa({ dataPerfiles }) {

    return (
        <div style={{ margin: '20px 10px 0px 10px' }}>
            <table className="tabla">
                <tbody>
                    {/* TITULOS DE COLUMNAS */}
                    <tr>
                        <th className="encabezado" style={{ fontSize: '16px', border: '0px solid white' }}>Item</th>
                        <th className="encabezado" style={{ fontSize: '16px' }}>Perfiles de trabajadores</th>
                        <th className="encabezado" style={{ fontSize: '16px' }}>Detalles de ingreso</th>
                        <th className="encabezado" style={{ fontSize: '16px' }}>Detalles de periódico</th>
                        <th className="encabezado" style={{ fontSize: '16px' }}>Detalles de retiro</th>
                    </tr>
                    {/* CONTENIDO */}
                    {
                        dataPerfiles.map((perfil, index) => {
                            return (
                                <tr key={index}>
                                    <td className="dato" style={{ border: '0px solid white', fontSize: '16px' }}>{index + 1}</td>
                                    <td className="dato" style={{ fontSize: '16px', textWrap: 'balance' }}>{perfil.perfil.nombre}</td>
                                    <td className="dato">
                                        <div className="flex">
                                            <DialogServicios dataPeriodico={perfil.perfil_tipo_items[0]} nombrePaquete={perfil.perfil.nombre} tipo={"Detalle de ingreso"} />
                                        </div>
                                    </td>
                                    <td className="dato">
                                        <div className="flex">
                                            <DialogServicios dataPeriodico={perfil.perfil_tipo_items[1]} nombrePaquete={perfil.perfil.nombre} tipo={"Detalle de periódico"} />
                                        </div>
                                    </td>
                                    <td className="dato">
                                        <div className="flex">
                                            <DialogServicios dataPeriodico={perfil.perfil_tipo_items[2]} nombrePaquete={perfil.perfil.nombre} tipo={"Detalle de retiro"} />
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
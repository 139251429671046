import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import AuthUser from "../../../AuthUser";
import {
   clearCapturedImage,
   linkOperationTemplate,
   beginOperation,
   onBodyLoad,
} from "../../../Services/main1.js";

const Huellero = ({ titulo, imagen, paciente }) => {
   const { http } = AuthUser();
   const [selectHuella, setSelectHuella] = useState(null);
   const [huella, setHuella] = useState(null);
   const [conectado, setConectado] = useState(false);
   const [mostrarImagen, setMostrarImagen] = useState(true);
   const [modalVisible, setModalVisible] = useState(false);

   const huellero = [
      { name: "Huellero 1", code: "1" },
      { name: "Huellero 2", code: "2" },
      { name: "Huellero 3", code: "3" },
   ];

   function saveHuella(idPaciente) {
      const canvas = document.getElementById("fingerframe");

      canvas.toBlob((blob) => {
         const formdata = new FormData();
         formdata.append("huella", blob, "huella.png");

         http
            .post(`persona/update/huella/${paciente.id}`, formdata)
            .then((response) => {
               // Maneja la respuesta del servidor si es necesario
            })
            .catch((error) => {
               // Maneja el error si ocurre alguno
               console.error("Error al enviar la imagen al servidor:", error);
            });
      }, "image/png");
   }

   

   useEffect(() => {
      setConectado(onBodyLoad());
      setMostrarImagen(true);
   }, []);

   const handleActivar = () => {
      beginOperation("capture", "ansisdk", false);
      setMostrarImagen(false);
      hideModal(); // Cerrar el modal aquí
   };

   const handleQuitarHuella = () => {
      clearCapturedImage();
      setMostrarImagen(true);
   };

   const hideModal = () => {
      setModalVisible(false);
   };

   useEffect(() => {
      const canvas = document.getElementById("fingerframe");
      const ctx = canvas.getContext("2d");

      if (mostrarImagen && imagen) {
         const img = new Image();
         img.src = imagen;
         img.onload = () => {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
         };
      } else {
         ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
   }, [mostrarImagen, imagen]);

   return (
      <div className="flex flex-wrap flex-column w-full gap-3">
         <div className="flex flex-wrap justify-content-center">
            <p
               style={{ background: "#FFFFFF" }}
               className="p-3 text-center w-4"
            >
               {titulo}
            </p>
         </div>

         <div className="contenedorFH gap-4 flex flex-wrap flex-column p-3">
            <div className="flex justify-content-center" id="image">
               <canvas
                  style={{ backgroundColor: "white" }}
                  id="fingerframe"
                  height="460"
                  width="320"
               ></canvas>
            </div>

            <div className="flex flex-wrap flex-row w-full gap-3">
               <div className="flex flex-wrap flex-1">
                  <Button
                     type="button"
                     disabled={conectado}
                     label="Digital"
                     className="boton02 w-full"
                     onClick={() => setModalVisible(true)}
                  />
               </div>
               <div className="flex flex-wrap flex-1">
                  <Button
                     type="button"
                     label="Quitar"
                     className="boton02 w-full"
                     onClick={handleQuitarHuella}
                     disabled={conectado}
                  />
               </div>
               <div className="flex flex-wrap w-5">
                  <Dropdown
                     value={selectHuella}
                     onChange={(e) => setSelectHuella(e.value)}
                     options={huellero}
                     optionLabel="name"
                     placeholder="Huellero"
                     className="flex flex-wrap w-full"
                  />
               </div>
               <div className="flex flex-wrap w-5">
                  <Button
                     className="btn btn-primary"
                     onClick={() => saveHuella(1)}
                  >
                     Guardar
                  </Button>
               </div>
            </div>
         </div>

         <Dialog
            visible={modalVisible}
            onHide={hideModal}
            className="text-center w-3" 
         >
            <div style={{ textAlign: "center", padding: "1rem" }}>
               <p style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  ¿Está seguro de realizar esta acción?
               </p>
            </div>
            <div className="p-d-flex p-flex-column p-ai-center">
               <i className="pi pi-exclamation-triangle p-mr-2 text-red-400 text-7xl" />
               <p className="text-2xl">Si continua activará el huellero</p>
            </div>
            <div className="p-d-flex p-jc-between m-3">
               <Button
                  label="Cerrar"
                  icon="pi pi-times"
                  onClick={hideModal}
                  className="p-button-text pr-6"
               />
               <Button
                  label="Continuar"
                  icon="pi pi-check"
                  onClick={handleActivar}
                  className="p-button-primary"
               />
            </div>
         </Dialog>
      </div>
   );
};

export default Huellero;

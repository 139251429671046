import React, { useState, useRef, useContext, useEffect } from "react";
import EnvioArchivoRadiologiaCol from './EnvioArchivoRadiologiaCol'
import { RadContext } from "../../../Page/Radiologia/RadProvider";
import VoiceRadiologia from "../../../Components/ButtonVoice/VoiceRadiologia";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import AuthUser from "../../../AuthUser";
import EvaluadoresRadio from '../Radiologia/VerEvaluadores'
import CalendarRad from './CalendarRayosX'

// import Detalles from './LumbroSacra'


export default function Column() {
    let { LumboSacraContext, setLumboSacraContext } = useContext(RadContext);

    const actualizarObservaciones = (nuevasObservaciones, propiedad) => {
        setLumboSacraContext(prevState => ({
            ...prevState,
            [propiedad]: nuevasObservaciones
        }));
    };

    const [TextAreaObserva, setTextAreaObserva] = useState(LumboSacraContext?.sintomas_hallasgos);
    useEffect(() => {
        actualizarObservaciones(TextAreaObserva, "sintomas_hallasgos")
    }, [TextAreaObserva])

    const [TextAreaObserva02, setTextAreaObserva02] = useState(LumboSacraContext?.observaciones);
    useEffect(() => {
        actualizarObservaciones(TextAreaObserva02, "observaciones")
    }, [TextAreaObserva02])

    const [TextAreaObserva03, setTextAreaObserva03] = useState(LumboSacraContext?.recomendaciones);
    useEffect(() => {
        actualizarObservaciones(TextAreaObserva03, "recomendaciones")
    }, [TextAreaObserva03])

    const [TextAreaObserva04, setTextAreaObserva04] = useState(LumboSacraContext?.conclusiones);
    useEffect(() => {
        actualizarObservaciones(TextAreaObserva04, "conclusiones")
    }, [TextAreaObserva04])







    const [ArchivoRadiologia, setArchivoRadiologia] = useState(LumboSacraContext?.archivo);
    const updateArchivoRadiologia = (nuevoValor) => {
        setLumboSacraContext((LumbroSacra) => ({
            ...LumbroSacra,
            archivo: nuevoValor
        }));
    };


    const OpcionesResultado = [
        { name: 'Columna Lumbo-sacra radiología', code: '1' },
        { name: 'Descartar contractura muscular', code: '2' },
        { name: 'Espondilolistesis Lumbo - sacra', code: '3' },
        { name: 'Espondiloartrosis Lumbo - sacra', code: '4' }
    ];
    const ActualizarVarObject = (array, seteo, code) => {
        array.map((item) => {
            if (item.code == code) {
                seteo(item)
            }
        })
    }
    const [Resultado_code, setResultado_code] = useState(LumboSacraContext?.resultado)
    const [Resultado, setResultado] = useState()
    useEffect(() => {
        ActualizarVarObject(OpcionesResultado, setResultado, Resultado_code)
    }, [LumboSacraContext])
    useEffect(() => {
        actualizarObservaciones(Resultado_code, "resultado")
    }, [Resultado_code])





    // STYLES
    const subTitulo = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "600",
    };

    // MEDICO EVALUADOR
    const [NombreEvaluador, setNombreEvaluador] = useState('');
    const [Colegiatura, setColegiatura] = useState('');


    const [evaluadores, setEvaluadores] = useState({})
    const { http } = AuthUser();
    const [viewPop, setviewPop] = useState(false);
    const getMedicos = () => {
        http
            .get("medicina_general/evaluacion_medica/evaluadores/get")
            .then((response) => {
                setEvaluadores(response.data.evaluadores)
            })
    }
    useEffect(() => {
        getMedicos();
    }, []);
    useEffect(() => {
        if (evaluadores && evaluadores.length > 0) {
            evaluadores.map((item) => {
                
                if (item.id == LumboSacraContext.colegiatura) {
                    setNombreEvaluador(item.nombres_completos);
                    setColegiatura(item.colegiatura);
                }
            })
        }
    }, [evaluadores, LumboSacraContext]);
    const ActualizarView = () => {
        if (evaluadores && evaluadores.length > 0) {
            if (LumboSacraContext.colegiatura) {
                evaluadores.map((item) => {
                    
                    if (item.id == LumboSacraContext.colegiatura) {
                        setNombreEvaluador(item.nombres_completos);
                        setColegiatura(item.colegiatura);
                    }
                })
            }
            else {
                setNombreEvaluador("");
                setColegiatura("");
            }
        }


    }
    useEffect(() => {
        ActualizarView();
    }, [LumboSacraContext])

    const ActualizarEvaluador = (ideva) => {
        setLumboSacraContext(prevState => ({
            ...prevState,
            colegiatura: ideva
        }));
    };




    const Actualizarfecha = (ideva) => {
        setLumboSacraContext(prevState => ({
            ...prevState,
            fecha: ideva
        }));
    };



    return (
        <div className="w-full flex flex-column gap-3">
            <div className="w-full h-auto flex flex-column gap-2" >
                <h4 className="w-full" style={subTitulo}>Sintomas y Hallazgos:</h4>
                <div className="ObservacionesRadiografiaVoiceButAHP">
                    <style>
                        {`
                            .ObservacionesRadiografiaVoiceButAHP textarea{
                                height: 100px ;
                            }
                        `}
                    </style>
                    <VoiceRadiologia
                        value={TextAreaObserva}
                        onChange={(e) => setTextAreaObserva(e)} />
                </div>
            </div>
            <div className="w-full h-auto flex flex-column gap-2" >
                <h4 className="w-full" style={subTitulo}>Observaciones:</h4>
                <div className="ObservacionesRadiografiaVoiceButAHP">
                    <style>
                        {`
                            .ObservacionesRadiografiaVoiceButAHP textarea{
                                height: 100px ;
                            }
                        `}
                    </style>
                    <VoiceRadiologia
                        value={TextAreaObserva02}
                        onChange={(e) => setTextAreaObserva02(e)} />
                </div>
            </div>
            <div className="w-full h-auto flex flex-column gap-2" >
                <h4 className="w-full" style={subTitulo}>Recomendaciones:</h4>
                <div className="ObservacionesRadiografiaVoiceButAHP">
                    <style>
                        {`
                            .ObservacionesRadiografiaVoiceButAHP textarea{
                                height: 100px ;
                            }
                        `}
                    </style>
                    <VoiceRadiologia
                        value={TextAreaObserva03}
                        onChange={(e) => setTextAreaObserva03(e)} />
                </div>
            </div>
            <div className="w-full h-auto flex flex-column gap-2" >
                <h4 className="w-full" style={subTitulo}>Conclusiones:</h4>
                <div className="ObservacionesRadiografiaVoiceButAHP">
                    <style>
                        {`
                            .ObservacionesRadiografiaVoiceButAHP textarea{
                                height: 100px ;
                            }
                        `}
                    </style>
                    <VoiceRadiologia
                        value={TextAreaObserva04}
                        onChange={(e) => setTextAreaObserva04(e)} />
                </div>
            </div>

            <div className="w-full flex gap-1">
                <div className="w-3 flex flex-column gap-2">
                    <h4 className="w-full" style={subTitulo}>Fecha:</h4>
                    <CalendarRad val={LumboSacraContext?.fecha} update={Actualizarfecha} />
                </div>
                <div className="w-6 flex flex-column gap-2">
                    <h4 className="w-full" style={subTitulo}>Evaluador:</h4>
                    <div className="w-full flex gap-1 EvaluadorLumbro_Sacra">
                        <style>
                            {`
                                .EvaluadorLumbro_Sacra .p-disabled.p-component:disabled{
                                    background-color: #dddddd;
                                }
                            `}
                        </style>
                        <InputText className="flex flex-1" disabled value={NombreEvaluador} onChange={(e) => setNombreEvaluador(e.target.value)} />
                        <InputText className="w-7rem" disabled value={Colegiatura} onChange={(e) => setColegiatura(e.target.value)} />
                        <Button className="ButonEvaluadoresRadiologiaAg" icon="pi pi-search" severity="success" onClick={() => { setviewPop(true) }} aria-label="Search"
                            style={{ width: '40px', maxWidth: '40px', height: '45px', backgroundColor: '#BFF1DF', borderColor: '#248D63' }} />
                        <style>
                            {`
                              .ButonEvaluadoresRadiologiaAg .pi-search:before{
                                 color: #248D63 !important;
                              }
                           `}
                        </style>
                        <EvaluadoresRadio
                            evaluadores={evaluadores}
                            visible={viewPop}
                            setVisible={setviewPop}
                            id={LumboSacraContext?.colegiatura}
                            setid={ActualizarEvaluador}
                        />
                    </div>
                </div>
                <div className="w-3 flex flex-column gap-2">
                    <h4 className="w-full" style={subTitulo}>Resultado:</h4>
                    <Dropdown value={Resultado} onChange={(e) => setResultado_code(e.value.code)} options={OpcionesResultado} optionLabel="name" className="w-full" />
                </div>
            </div>


            <EnvioArchivoRadiologiaCol
                FuncionSetEnvio={setArchivoRadiologia}
                FuncionUpData={updateArchivoRadiologia}
                ValorSet={ArchivoRadiologia}
                DataContext={LumboSacraContext.archivo}
            />
            {/* <Detalles /> */}
        </div>
    )
}


//rows={5} cols={30}
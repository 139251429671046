import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

export default function ComponentAntecedenteRelacionales({ audFichaAudiologica, setAudFichaAudiologica }) {


   const options = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsmilitar = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsruido = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsoido = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsquimicos = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionslaboral = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const optionsototoxico = [
      { label: "Sí", value: "si" },
      { label: "No", value: "no" },
   ];

   const [antecedentes, setAntecedentes] = useState(audFichaAudiologica?.aud_fich_antecedentes)
   const [isListeningTabaco, setIsListeningTabaco] = useState(false);
   const [isListeningMilitar, setIsListeningMilitar] = useState(false);
   const [isListeningRuido, setIsListeningRuido] = useState(false);
   const [isListeningQuimicos, setIsListeningQuimicos] = useState(false);
   const [isListeningLaboral, setIsListeningLaboral] = useState(false);
   const [isListeningOido, setIsListeningOido] = useState(false);
   const [isListeningOtotoxicos, setIsListeningOtotoxicos] = useState(false);

   const handleSelectChange = (index, e) => {
      let antecedentes_aux = antecedentes?.map((antecedente, i) => {
         if (i === index) {
            return {
               patologia_id: antecedente.patologia_id,
               resultado: e.target.value,
               observaciones: "",
            }
         }
         return antecedente;
      })
      setAntecedentes(antecedentes_aux)
      setAudFichaAudiologica({ ...audFichaAudiologica, aud_fich_antecedentes: antecedentes_aux })
   };

   const handleTextareaChange = (index, e) => {
      
      let antecedentes_aux = antecedentes?.map((antecedente, i) => {
         if (i === index) {
            return {
               patologia_id: antecedente.patologia_id,
               resultado: antecedente.resultado,
               observaciones: e,
            }
         }
         return antecedente;
      })
      setAntecedentes(antecedentes_aux)
      setAudFichaAudiologica({ ...audFichaAudiologica, aud_fich_antecedentes: antecedentes_aux })
   };

   const startListeningTabaco = () => {
      if (antecedentes[0]?.resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningTabaco(true);
         };

         recognition.onend = () => {
            setIsListeningTabaco(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[0].observaciones + transcript;
            handleTextareaChange(0, comentario)
         };

         recognition.start();
      }
   };

   const startListeningMilitar = () => {
      if (antecedentes[1].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningMilitar(true);
         };

         recognition.onend = () => {
            setIsListeningMilitar(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[1].observaciones + transcript;
            handleTextareaChange(1, comentario)
         };

         recognition.start();
      }
   };

   const startListeningRuido = () => {
      if (antecedentes[2].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningRuido(true);
         };

         recognition.onend = () => {
            setIsListeningRuido(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[2].observaciones + transcript;
            handleTextareaChange(2, comentario)
         };

         recognition.start();
      }
   };


   const startListeningQuimicos = () => {
      if (antecedentes[3].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningQuimicos(true);
         };

         recognition.onend = () => {
            setIsListeningQuimicos(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[3].observaciones + transcript;
            handleTextareaChange(3, comentario)
         };

         recognition.start();
      }
   };


   const startListeningLaboral = () => {
      if (antecedentes[4].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningLaboral(true);
         };

         recognition.onend = () => {
            setIsListeningLaboral(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[4].observaciones + transcript;
            handleTextareaChange(4, comentario)
         };

         recognition.start();
      }
   };

   const startListeningOido = () => {
      if (antecedentes[5].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningOido(true);
         };

         recognition.onend = () => {
            setIsListeningOido(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[5].observaciones + transcript;
            handleTextareaChange(5, comentario)
         };

         recognition.start();
      }
   };


   const startListeningOtotoxicos = () => {
      if (antecedentes[6].resultado === "si") {
         const recognition = new (window.SpeechRecognition ||
            window.webkitSpeechRecognition)();
         recognition.lang = "es-ES";

         recognition.onstart = () => {
            setIsListeningOtotoxicos(true);
         };

         recognition.onend = () => {
            setIsListeningOtotoxicos(false);
         };

         recognition.onresult = (event) => {
            const transcript = event.results[0][0].transcript;
            let comentario = antecedentes[6].observaciones + transcript;
            handleTextareaChange(6, comentario)
         };

         recognition.start();
      }
   };

   return (
      <div className="flex flex-column w-full gap-3">
         <div className="tabla-responsiva">
            <table className="TablaAntecedenterelacionados">
               <thead>
                  <tr>
                     <th
                        style={{
                           fontWeight: "700",
                           fontSize: "14px",
                           color: "#5555D8",
                        }}
                     >
                        Patología
                     </th>
                     <th
                        style={{
                           fontWeight: "700",
                           fontSize: "14px",
                           color: "#5555D8",
                        }}
                     >
                        Resultado
                     </th>
                     <th
                        style={{
                           fontWeight: "700",
                           fontSize: "14px",
                           color: "#5555D8",
                        }}
                     >
                        Observación
                     </th>
                  </tr>
               </thead>
               <tbody>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Consumo de tabaco
                     </td>
                     <td>
                        <SelectButton
                           name="resultado"
                           value={antecedentes[0]?.resultado}
                           onChange={(e) => handleSelectChange(0, e)}
                           options={options}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-tabaco"
                              name="comentarios-tabaco"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[0].resultado === "no"}
                              value={antecedentes[0].observaciones}
                              onChange={(e) => handleTextareaChange(0, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningTabaco}
                              disabled={isListeningTabaco || antecedentes[0].resultado === "no"}
                           >
                              {isListeningTabaco ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Servicio Militar
                     </td>
                     <td>
                        <SelectButton
                           value={antecedentes[1].resultado}
                           onChange={(e) => handleSelectChange(1, e)}
                           options={optionsmilitar}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-militar"
                              name="comentarios-militar"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[1].resultado === "no"}
                              value={antecedentes[1].observaciones}
                              onChange={(e) => handleTextareaChange(1, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningMilitar}
                              disabled={isListeningMilitar || antecedentes[1].resultado === "no"}
                           >
                              {isListeningMilitar ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Hobbies con exposición al ruido
                     </td>
                     <td>
                        <SelectButton
                           value={antecedentes[2].resultado}
                           onChange={(e) => handleSelectChange(2, e)}
                           options={optionsruido}
                        />
                     </td>
                     <td
                        className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}
                     >
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-ruido"
                              name="comentarios-ruido"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[2].resultado === "no"}
                              value={antecedentes[2].observaciones}
                              onChange={(e) => handleTextareaChange(2, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningRuido}
                              disabled={isListeningRuido || antecedentes[2].resultado === "no"}
                           >
                              {isListeningRuido ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Exposición laboral a químicos
                     </td>
                     <td>
                        <SelectButton
                           value={antecedentes[3].resultado}
                           onChange={(e) => handleSelectChange(3, e)}
                           options={optionsquimicos}
                        />
                     </td>
                     <td className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}>
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-quimicos"
                              name="comentarios-quimicos"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[3].resultado === "no"}
                              value={antecedentes[3].observaciones}
                              onChange={(e) => handleTextareaChange(3, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningQuimicos}
                              disabled={isListeningQuimicos || antecedentes[3].resultado === "no"}
                           >
                              {isListeningQuimicos ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Exposición laboral al ruido
                     </td>
                     <td>
                        <SelectButton
                           value={antecedentes[4].resultado}
                           onChange={(e) => handleSelectChange(4, e)}
                           options={optionslaboral}
                        />
                     </td>
                     <td className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}>
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-laboral"
                              name="comentarios-laboral"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[4].resultado === "no"}
                              value={antecedentes[4].observaciones}
                              onChange={(e) => handleTextareaChange(4, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningLaboral}
                              disabled={isListeningLaboral || antecedentes[4].resultado === "no"}
                           >
                              {isListeningLaboral ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Infección al oído
                     </td>
                     <td>
                        <SelectButton
                           value={antecedentes[5].resultado}
                           onChange={(e) => handleSelectChange(5, e)}
                           options={optionsoido}
                        />
                     </td>
                     <td className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}>
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-oido"
                              name="comentarios-oido"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[5].resultado === "no"}
                              value={antecedentes[5].observaciones}
                              onChange={(e) => handleTextareaChange(5, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningOido}
                              disabled={isListeningOido || antecedentes[5].resultado === "no"}
                           >
                              {isListeningOido ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
                  <tr>
                     <td style={{ fontWeight: "600", fontSize: "14px" }}>
                        Uso de ototóxicos
                     </td>
                     <td>
                        <SelectButton
                           value={antecedentes[6].resultado}
                           onChange={(e) => handleSelectChange(6, e)}
                           options={optionsototoxico}
                        />
                     </td>
                     <td className="flex flex-row w-full"
                        style={{ padding: "10px 0px 10px 0px" }}>
                        <div className="flex flex-column w-full">
                           <textarea
                              id="comentarios-ototoxicos"
                              name="comentarios-ototoxicos"
                              rows="3"
                              cols="100"
                              disabled={antecedentes[6].resultado === "no"}
                              value={antecedentes[6].observaciones}
                              onChange={(e) => handleTextareaChange(6, e.target.value)}
                              style={{ fontFamily: 'Montserrat', padding: "6px", fontSize: "14px" }}
                           ></textarea>
                        </div>
                        <div
                           className="flex flex-column justify-content-end"
                           style={{ marginLeft: "-40px", marginBottom: "5px" }}
                        >
                           <button
                              className="flex flex-row justify-content-center"
                              style={{
                                 background: "#5555D8",
                                 color: "white",
                                 width: "36px",
                                 borderRadius: "6px",
                                 border: "1px solid #6366F1",
                                 cursor: "pointer",
                              }}
                              onClick={startListeningOtotoxicos}
                              disabled={isListeningOtotoxicos || antecedentes[6].resultado === "no"}
                           >
                              {isListeningOtotoxicos ? "Off" : "On"}
                           </button>
                        </div>
                     </td>
                  </tr>
               </tbody>
            </table>
         </div>
      </div>
   );
}

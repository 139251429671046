import React, { useState, useEffect, useRef } from "react";
import LoginInput from "../../Components/Login/LoginInput";
import { Button } from 'primereact/button';
import { Toast } from "primereact/toast";
import LoginService from "../../Services/LoginService";
import { useNavigate } from "react-router-dom";
import { signInUser } from "../../Redux/authSlice";
import { useDispatch } from "react-redux";


function PageLogin() {
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [usuario, setUsuario] = useState({
      username: "",
      password: "",
   });


   const handleChangeUsuario = (e) => {
      setUsuario((prevState) => ({
         ...prevState,
         username: e.target.value,
      }));
   };

   const handleChangePassword = (e) => {
      setUsuario((prevState) => ({
         ...prevState,
         password: e.target.value,
      }));
   };
   
   const [errorMessage, setErrorMessage] = useState("");

   const toast = useRef(null);

   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };


   const handleSubmit = () => {
      dispatch(signInUser(usuario));
      LoginService.login(usuario)
         .then((response) => {
            // handleToast("success", "Usuario correcto", "Bienvenido");
            LoginService.setToken(response.data.token);
            let url = "/" + response?.data?.accesos[0]?.url;
            navigate(url);
         })
         .catch((error) => {
            console.error(error)
            showToast(
               "error",
               "Error al ingresar",
               error.response.data.error
            );
         });
   };


   return (
      <React.Fragment>
         <div className="w-full">

            <Toast ref={toast} />

            <LoginInput
               valueUsuario={usuario.username}
               onChangeValueUsuario={handleChangeUsuario}
               valuePassword={usuario.password}
               onChangeValuePassword={handleChangePassword}
               OnClickIngresar={handleSubmit}
               isLoginDisabled={!usuario.username || !usuario.password}
               error={errorMessage}
            />

         </div>


      </React.Fragment>
   );
}

export default PageLogin;
import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FileUpload } from 'primereact/fileupload';
import { useFormik } from "formik";
import axios from "axios";

//Componentes generales
import AuthUser from "../../../../../../AuthUser";
import { Header, Footer } from '../../../../../Pure/SeccionesNavegacion'

//Estilos únicos
import "../../../style/estilo_boton_pdf.css"

export default function ModalCreateClinica({
   visibleCreate,
   setVisibleCreate,
   setPersonal,
   showToast,
   tipoDocumentos,
   roles,
   getPersonal,
   api
}) {
   const { http } = AuthUser();
   const [DNIexistente, setDNIexistente] = useState([]);

   useEffect(() => {
      GetLocal();
   }, []);

   const GetLocal = () => {
      http
         .get("clinica/personal/reclutamiento/get")
         .then((response) => {
            setDNIexistente(response.data.data.map((usuario) => usuario.persona.numero_documento));
         })
         .catch((error) => {
            console.error(error);
         });
   };


   const formik = useFormik({
      initialValues: {
         id: 0,
         tipo_documento: tipoDocumentos[0],
         numero_documento: "",
         nombres: "",
         apellido_paterno: "",
         apellido_materno: "",
         fecha_nacimiento: "",
         distrito_id: "",
         celular: "",
         correo: "",
         direccion: "",
         rol: "",
         empresa_cargo: "",
         pretension_salarial: "",
      },
      onSubmit: (data) => {
         const formDataSend = new FormData();
         for (var key in data) {
            formDataSend.append(key, data[key]);
         }
         formDataSend.append("tipo_documento_id", data?.tipo_documento?.id);
         formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id);
         formDataSend.append("rol_id", data?.rol?.id);

         http
            .post(api, formDataSend)
            .then((response) => {
               HideDialogCreate();
               getPersonal();
               setPersonal();
               showToast(
                  "success",
                  "Personal creado",
                  "Se creo correctamente el personal"
               );
            })
            .catch((error) => {
               HideDialogCreate();
               showToast(
                  "error",
                  "Error de creación",
                  "Algo salió mal, inténtelo de nuevo"
               );
            });
      },
   });

   const getNombreCompleto = async (
      numdocumento,
      tipoDocumento,
      correo,
      celular,
      rol,
      usuario,
      empresa_cargo
   ) => {
      if (DNIexistente.includes(numdocumento)) {
         showToast(
            "error",
            "Número de documento duplicado",
            "Este número de documento ya existe en la tabla."
         );
         return;
      }

      if (!formik.values.tipo_documento) {
         showToast(
            "error",
            "Tipo de documento",
            "No se eligió un tipo de documento"
         );
         return;
      }

      try {
         let response;
         if (tipoDocumento.nombre === "DNI") {
            response = await axios.get(
               `https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`
            );
         } else if (tipoDocumento.nombre === "RUC") {
            response = await axios.get(
               `https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`
            );
         }

         const data = response.data;

         formik.setValues({
            tipo_documento: tipoDocumento,
            numero_documento: numdocumento,
            nombres: tipoDocumento.nombre === "DNI" ? data.nombres : data.razonSocial.split(" ").slice(2).join(" "),
            apellido_paterno: tipoDocumento.nombre === "DNI" ? data.apellidoPaterno : data.razonSocial.split(" ")[0],
            apellido_materno: tipoDocumento.nombre === "DNI" ? data.apellidoMaterno : data.razonSocial.split(" ")[1],
            fecha_nacimiento: "2022-01-01",
            distrito_id: 2,
            celular: celular,
            correo: correo,
            direccion: "",
            rol: rol,
            usuario: usuario,
            empresa_cargo: empresa_cargo,
         });
      } catch (error) {
         showToast(
            "error",
            "Número de documento no encontrado",
            "No se encontró ningún dato"
         );
      }
   };

   const HideDialogCreate = () => {
      setVisibleCreate(false);
      formik.resetForm();
   };

   const paqueteCreateDialogFooter = (
      <Footer
         type={"submit"}
         onClickCancelar={() => {
            HideDialogCreate();
            GetLocal();
         }}
         label="Crear Usuario" />
   );

   return (
      <div>
         <form
            id="persona-form"
            className="persona-form"
            noValidate
            onSubmit={formik.handleSubmit}
         >
            <Dialog
               visible={visibleCreate}
               style={{
                  width: "450px",
                  margin: "5%",
               }}
               appendTo={document.getElementById("persona-form")}
               header={
                  <>
                     <div
                        className="flex justify-content-center align-items-center"
                        style={{
                           backgroundColor: "#EBEBFF",
                           width: "60px",
                           height: "60px",
                           borderRadius: "10px",
                        }}
                     >
                        <div className="flex justify-content-center align-items-center">
                           <img
                              src="https://cdn.discordapp.com/attachments/1078050726217011331/1090321659044573235/Group_1920.png"
                              alt=""
                           />
                        </div>
                     </div>
                     <h1 className="per">Crear Personal</h1>
                  </>
               }
               dismissableMask
               className="p-fluid "
               footer={paqueteCreateDialogFooter}
               onHide={() => {
                  HideDialogCreate();
               }}
            >
               <div className="flex flex-column gap-3">
                  <div className="flex flex-column w-full">
                     <p className="m-0">
                        En esta sección, usted puede registrar sus datos para crear su personal
                     </p>

                     <p className="per1">Tipo de documento</p>
                     <div className="flex w-full flex-column">
                        <Dropdown
                           id="tipo_documento"
                           name="tipo_documento"
                           value={formik.values.tipo_documento}
                           onChange={formik.handleChange}
                           options={tipoDocumentos}
                           optionLabel="nombre"
                           placeholder="Selecciona un tipo de documento"
                           className="w-full"
                        />
                     </div>
                  </div>

                  <div className="flex flex-1 w-full flex-column gap-2">
                     <div className="flex flex-1 w-full gap-2">
                        <InputText
                           id="numero_documento"
                           name="numero_documento"
                           value={formik.values.numero_documento}
                           onChange={formik.handleChange}
                           required
                        />
                        <Button
                           label="Validar"
                           type="button"
                           style={{ background: "#07A34F", borderColor: "#07A34F" }}
                           onClick={() =>
                              getNombreCompleto(
                                 formik.values.numero_documento,
                                 formik.values.tipo_documento,
                                 formik.values.correo,
                                 formik.values.celular,
                                 formik.values.rol,
                                 formik.values.usuario,
                                 formik.values.empresa_cargo,
                                 formik.values.pretension_salarial
                              )
                           }
                        />
                     </div>
                  </div>

                  <div className="flex flex-column gap-2">
                     <label>Nombres Completos
                        <InputText
                           id="nombres"
                           name="nombres"
                           value={formik.values.nombres}
                           className="w-full"
                           onChange={formik.handleChange}
                           required
                           autoComplete="off"
                        />
                     </label>
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">Apellidos Completos</label>
                     <InputText
                        id="apellido_paterno"
                        name="apellido_paterno"
                        value={formik.values.apellido_paterno + " " + formik.values.apellido_materno}
                        onChange={formik.handleChange}
                        className="w-full"
                     />
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Correo</label>
                     <InputText
                        id="correo"
                        name="correo"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        className="w-full"
                     />
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Celular</label>
                     <InputText
                        id="celular"
                        name="celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        className="w-full"
                     />
                  </div>
                  <div className="flex flex-column">
                     <p>Rol</p>
                     <Dropdown
                        id="rol"
                        name="rol"
                        value={formik.values.rol}
                        onChange={formik.handleChange}
                        options={roles}
                        optionLabel="nombre"
                        placeholder="Selecciona un Rol"
                        className="w-full"
                     />
                  </div>
                  <div className="flex flex-column">
                     <p>Pretensión salarial</p>
                     <InputText
                        id="pretension_salarial"
                        name="pretension_salarial"
                        value={formik.values.pretension_salarial}
                        onChange={formik.handleChange}
                        className="w-full"
                     />
                  </div>
                  <div className="flex flex-column">
                     <label htmlFor="cv">Subir CV</label>
                     <FileUpload
                        className="estilo_boton_pdf"
                        name="cv"
                        mode="basic"
                        accept="application/pdf"
                        chooseLabel="Arrastre y coloque su archivo aquí"
                        customUpload
                        uploadHandler={(e) => formik.setFieldValue('cv', e.files[0])}
                     />
                  </div>
               </div>
            </Dialog>
         </form>
      </div>
   );
}
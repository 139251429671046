import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ScrollPanel } from "primereact/scrollpanel";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

import { Header } from "../../../../../Components/Pure/SeccionesNavegacion";

import Logo from "../../../../../Images/Icon-folder.svg";
import '../../../../../Style/ScrollPanelDemo.css';

export default function DialogServicios({ dataPeriodico, nombrePaquete, tipo }) {

    // Función para transformar los datos recibidos en una estructura adecuada para el TreeTable
    const transformData = (paquetes) => {
        if (!paquetes[0]) {
            return [];
        } else {
            const areas = ['areas_medicas', 'capacitaciones', 'examenes'];

            return areas.map((area, index) => {
                const data = {
                    key: `node-${index}`,
                    data: {
                        nombre: (index === 0 && "Áreas Médicas") || (index === 1 && "Capacitaciones") || (index === 2 && "Exámenes de ayuda al diagnóstico"),
                        description: `This is node ${index}`,
                    },
                    children: [],
                };

                const areaData = paquetes.flatMap((paquete) => paquete[area]);
                const areaNodes = transformDataChild(areaData, `node-${index}`);

                data.children = areaNodes;
                return data;
            });
        }
    };

    // Función recursiva para transformar los datos hijos
    function transformDataChild(data, parentKey) {
        const children = ['Psicología', 'Triaje', 'Examen de Laboratorio', 'Examen Radiológico', 'Laboratorio Molecular', 'Laboratorio Clínico', 'Oftalmología'];
        return data.map((item, index) => {
            const isChild = children.includes(item.nombre);

            const node = isChild
                ? {
                    key: `${parentKey}-${index}`,
                    data: {
                        nombre: item.nombre.replace('_', ' '),
                    },
                    children: [],
                }
                : {
                    key: `${parentKey}-${index}`,
                    data: {
                        nombre: item.nombre,
                    },
                    children: [],
                };

            if (item.children && item.children.length > 0) {
                node.children = transformDataChild(item.children, `${parentKey}-${index}`);
            }

            return node;
        });
    }

    const DateForTreeTable = transformData([dataPeriodico]);
    DateForTreeTable?.splice(1, 1);

    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div
            className="flex flex-column"
            style={{ justifyContent: "center", alignItems: "center" }}
        >
            <Button
                label="Aceptar"
                onClick={() => setVisible(false)}
                autoFocus
                style={{ width: "180px" }}
            />
        </div>
    );

    return (
        <div className="card flex justify-content-center align-items-center mb-0">
            <Button
                className="p-button-text"
                label="Ver detalles"
                icon="pi pi-info-circle"
                onClick={() => setVisible(true)}
            />
            <Dialog
                visible={visible}
                style={{ width: "611px" }}
                onHide={() => setVisible(false)}
                footer={footerContent}
                header={<Header icono={Logo} titulo={tipo} />}
            >
                <div>

                    <div className="flex flex-column gap-3">
                        <div className="p-input-icon-left flex w-full">
                            <h1>{nombrePaquete}</h1>
                        </div>
                        <div className="w-full h-3rem">
                        </div>
                    </div>
                    <div
                        className="card scrollpanel-demo EdittableSearchSSDD"
                        style={{ marginTop: "20px" }}
                    >
                        <div className="flex flex-column md:flex-row gap-5">
                            <div className="flex-auto">
                                <ScrollPanel
                                    style={{ width: "100%", height: "380px" }}
                                    className="custombar1 relative"
                                >
                                    <i className="pi pi-search absolute"></i>
                                    <div
                                        className="middle"
                                        style={{ padding: "10px 10px 10px 10px" }}
                                    >
                                        <TreeTable
                                            value={DateForTreeTable}
                                            className="TreeeTableDKKF"
                                        >
                                            <Column field="nombre" filter filterPlaceholder="Buscador" expander></Column>
                                        </TreeTable>
                                    </div>
                                </ScrollPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

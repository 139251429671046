import React, { useContext, useEffect, useRef, useState } from "react";
import "../../../Style/style5.css";
import "../../../Style/Button.css";
import "../../../Style/Laboratorio/EstilosVPH.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
export default function TablaCovid({ tipoExamen, tipoPrueba, idLaboratorio,indice, showToast }) {
   const { http } = AuthUser();
   const inputFile = useRef(null);
   const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
   const [labPruebas, setLabPruebas] = useState([])
   const [prueba, setPrueba] = useState({})
   const [nombreArchivo, setNombreArchivo] = useState(null);
   const [file, setFile] = useState(null);
   const [visibleDialog, setVisibleDialog] = useState(false);
   const handleCreateUrl = () => {
      const archivo = examenesContext[1]["pruebas"][indice]["archivo"];
    
      if (archivo instanceof File) {
        // Si es un objeto File, creamos una URL para él
        return URL.createObjectURL(archivo);
      } else if (archivo instanceof Blob) {
        // Si es un objeto Blob, convertimos su contenido en una URL de datos
        const reader = new FileReader();
        reader.readAsDataURL(archivo);
        reader.onloadend = () => {
          const dataUrl = reader.result;
          const timestamp = Date.now();
          return `${dataUrl}${dataUrl.includes('?') ? '&' : '?'}nocache=${timestamp}`;
        };
      } else if (typeof archivo === "string" && archivo !== '') {
        // Si es una cadena (URL), la usamos directamente
        const timestamp = Date.now();
        return `${archivo}${archivo.includes('?') ? '&' : '?'}nocache=${timestamp}`;
      }
    
      return '';
    };
  
   const handleCreate = () => {
      const formData = new FormData();
      formData.append("examen_id", tipoExamen);
      formData.append("prueba[examen_id]", tipoPrueba);
      formData.append("prueba[estado]", 1);
      formData.append("prueba[perfil]", 1);
      formData.append("prueba[cantidad_parametros]", 0);
      formData.append("prueba[parametros]", []);
      formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));

      http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
         .then(response => {
            
            showToast("success","Creado Correctamente",response?.data?.resp)
         })
         .catch(error => {
            console.error(error)
            showToast("Error","Error de Guardar",error?.data?.resp)
         })
   }

   const abrirSelectorArchivos = () => {
      inputFile.current.click();
   };

   const handleArchivoCargado = (event) => {
      const archivoCargado = event.target.files[0];
      if (archivoCargado) {
         setFile(archivoCargado)
         setNombreArchivo(archivoCargado.name);
         let temporalPrueba=examenesContext
         temporalPrueba[1]["pruebas"][indice]["archivo"]=archivoCargado;
         setExamenesContext([...examenesContext,])
         
      } else {
         setNombreArchivo("");
      }
   };

   const handlerdeletefile = () => {
      setNombreArchivo("")
      setFile(null)
   }
   const abrirDialog = () => {
      setVisibleDialog(true);
   };

   useEffect(() => {
      
      let auxPruebas = examenesContext.find(examen => {
         return examen.examen_id === tipoExamen
      });
      let auxPrueba = auxPruebas?.pruebas.find((prueba => {
         return prueba.examen_id === tipoPrueba
      }));
      if (auxPrueba?.archivo) {
        
      }
      setLabPruebas(auxPruebas);
      setPrueba(auxPrueba);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return (
      <div className="pt-3" style={{ width: "90%", margin: "auto" }} >

      {/* <div className="encabezadoColumnLab" >
         <span style={{ textAlign: "center", color: "blue" }}>
            RIESGO ALTO
         </span>
      </div>

      <div className="CuerpoColumnLab flex gap-3 ml-4 justify-content-center">
         <div className="flex flex-column w-full">
            <DataTable
               emptyMessage="No hay datos">
               <Column header="Descripción del Exámen" ></Column>
               <Column header="Resultado"></Column>
               <Column header="Unidades"></Column>
               <Column header="Valores de Referencia"></Column>
            </DataTable>
         </div>
      </div>


      <div className="encabezadoColumnLab" >
         <span style={{ textAlign: "center", color: "blue" }}>
            RIESGO MEDIO
         </span>
      </div>

      <div className="CuerpoColumnLab flex gap-3 ml-4 justify-content-center">
         <div className="flex flex-column w-full">
            <DataTable
               emptyMessage="No hay datos">
               <Column header="Descripción del Exámen" ></Column>
               <Column header="Resultado"></Column>
               <Column header="Unidades"></Column>
               <Column header="Valores de Referencia"></Column>
            </DataTable>
         </div>
      </div>


      <div className="encabezadoColumnLab" >
         <span style={{ textAlign: "center", color: "blue" }}>
            RIESGO BAJO
         </span>
      </div>

      <div className="CuerpoColumnLab flex gap-3 ml-4 justify-content-center">
         <div className="flex flex-column w-full">
            <DataTable
               emptyMessage="No hay datos">
               <Column header="Descripción del Exámen" ></Column>
               <Column header="Resultado"></Column>
               <Column header="Unidades"></Column>
               <Column header="Valores de Referencia"></Column>
            </DataTable>
         </div>
      </div> */}
      <div
         className="flex flex-row gap-2"
         style={{ alignItems: "center" }}
      >
         <input
            type="file"
            ref={inputFile}
            style={{ display: "none" }}
            onChange={handleArchivoCargado}
           
         />
         <span>Nombre de Archivo</span>
         <InputText
            type="text"
            value={examenesContext?.length>0 &&(examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"] && typeof examenesContext[1]?.["pruebas"]?.[indice]?.["archivo"] === "string" ? examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"]?.substring(examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"]?.lastIndexOf('/') + 1) : examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"]?.name)}
            readOnly
            style={{
               width: "800px",
               height: "40px",
            }}
         />
         <Button
            icon="pi pi-upload"
            className="upload"
            onClick={abrirSelectorArchivos}
         />
         <Button icon="pi pi-trash"
            onClick={handlerdeletefile}
            className="trash" />
         <Button
            className="search"
            onClick={abrirDialog}
            icon="pi pi-search"
         >
         </Button>
      </div>
      <div className="flex pt-3 pb-3" style={{ justifyContent: 'end' }}>
         <div className="flex gap-3">
            <Button label="Grabar" onClick={handleCreate} icon="fas fa-save" />
            <Button label="Recuperar" icon="fas fa-undo" />
         </div>
      </div>
      <Dialog
         header="Vista previa del PDF"
         visible={visibleDialog}
         style={{ width: '70vw' }}
         onHide={() => setVisibleDialog(false)}
      >
         <iframe
            src={examenesContext?.length> 0 &&(typeof examenesContext?.[1]["pruebas"]?.[indice]?.["archivo"]!=="string"&& examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"]!==null? URL.createObjectURL(examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"]) : typeof examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"]=="string"? examenesContext?.[1]?.["pruebas"]?.[indice]?.["archivo"] : '')}
            //src={handleCreateUrl()}
            width="100%"
            height="500px"
            title="Vista previa del PDF"
         />
      </Dialog>
   </div>
   );
}

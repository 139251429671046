import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { classNames } from 'primereact/utils';
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axios from "axios";

//Componentes generales
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";
import InputCalendar from "../../../form/InputCalendar";

//Imágenes
import iconAgregar from "../../../../Images/Reclutaminento/img/icon_agregarReclutamiento.svg";
import { Footer, Header } from "../../../Pure/SeccionesNavegacion";

export default function ModalCreateRecluta({
  visibleCreate,
  setVisibleCreate,
  showToast,
  tipoDocumentos,
  getPersonal,
  sexos,
  api,
  data,
}) {

  const dispatch = useDispatch();
  const { http } = AuthUser();
  const [value, setValue] = useState("");

  //Valores iniciales
  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    fecha_nacimiento: "",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    rol: "",
    empresa_cargo: "",
    sexo_id: "",
  };

  const formik = useFormik({
    initialValues: initialValues, // Valores iniciales del formulario
    onSubmit: async (data) => {
      dispatch(startLoading()); // Inicia la carga
      let año = formik.values.fecha_nacimiento.getFullYear();
      let mes = ('0' + (formik.values.fecha_nacimiento.getMonth() + 1)).slice(-2); // Se agrega 1 al mes porque los meses van de 0 a 11 
      let dia = ('0' + formik.values.fecha_nacimiento.getDate()).slice(-2);
      // Formatear la fecha en el formato deseado (yyyy-dd-mm)
      const fechaFormateada = `${año}-${mes}-${dia}`;

      const formDataSend = new FormData(); // Crea un nuevo FormData para enviar los datos
      for (var key in data) {
        formDataSend.append(key, data[key]); // Añade cada campo del formulario al FormData
      }
      formDataSend.set("fecha_nacimiento", fechaFormateada);
      formDataSend.append("tipo_documento_id", data?.tipo_documento?.id) // Añade el ID del tipo de documento
      formDataSend.append("rol_id", data?.rol?.id) // Añade el ID del rol
      formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id) // Añade el ID del cargo de la empresa

      try {
        const response = await http.post(api, formDataSend) // Envía los datos a la API
        if (response?.data?.resp) {
          await Promise.all([
            HideDialogCreate(), // Oculta el diálogo de creación
            getPersonal(), // Obtiene la lista actualizada de personal
          ]);
          showToast("success", "Personal creado", "Se creo correctamente el personal"); // Muestra un mensaje de éxito
        } else if (response?.data?.error) {
          showToast("error", "Error", response?.data?.error) // Muestra un mensaje de error controlado
        }
      } catch (error) {
        HideDialogCreate(); // Oculta el diálogo de creación
        showToast("error", "Error", "Contáctese con soporte técnico.") // Muestra un mensaje de error no controlado
      } finally {
        dispatch(endLoading()); // Finaliza la carga
      }
    },
    validate: (data) => {
      let errors = {};
      if (!data.tipo_documento) {
        errors.tipo_documento = "Tipo de documento requerido."; // Valida que el campo tipo_documento no esté vacío
      }
      if (!data.numero_documento) {
        errors.numero_documento = "Número de documento es requerido" // Valida que el campo numero_documento no esté vacío
      }
      if (!data.nombres) {
        errors.nombres = "Nombres requeridos" // Valida que el campo nombres no esté vacío
      }
      if (!data.apellido_paterno) {
        errors.apellido_paterno = "Apellido paterno requerido" // Valida que el campo apellido_paterno no esté vacío
      }
      if (!data.apellido_materno) {
        errors.apellido_materno = "Apellido materno requerido" // Valida que el campo apellido_materno no esté vacío
      }
      if (!data.empresa_cargo) {
        errors.empresa_cargo = "Puesto de trabajo requerido" //Valida que el campo puesto de trabajo no se encuentre vacío
      }
      if (!data.sexo_id) {
        errors.sexo_id = "Sexo es requerido."; // Valida que el campo sexo_id no esté vacío
      }
      return errors; // Retorna los errores encontrados
    }
  });

  // Verifica si un campo del formulario es válido.
  // @param {string} name - El nombre del campo del formulario.
  // @returns {boolean} - Retorna true si el campo ha sido tocado y tiene errores, de lo contrario false.
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  // Obtiene el mensaje de error de un campo del formulario.
  // @param {string} name - El nombre del campo del formulario.
  // @returns {JSX.Element|null} - Retorna un elemento JSX con el mensaje de error si el campo es inválido, de lo contrario null.
  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  // Oculta el diálogo de creación, resetea el formulario y establece el valor a null.
  const HideDialogCreate = () => {
    setVisibleCreate(false);
    formik.resetForm();
    setValue(null);
  };

  const getNombreCompleto = (numdocumento, tipoDocumento, correo, celular, rol, empresa_cargo, fecha_nacimiento) => {
    if (formik.values.tipo_documento !== "") {
      // Verifica si el tipo de documento es DNI
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            if (res.data.message) {
              // Muestra un mensaje de error si no se encuentra el número de documento
              showToast(
                "error",
                "Número de documento no encontrado",
                `No se encontró ningun dato`
              );
            } else {
              // Establece los valores del formulario con los datos obtenidos
              formik.setValues({
                tipo_documento: tipoDocumento,
                numero_documento: numdocumento,
                nombres: res.data.nombres,
                apellido_paterno: res.data.apellidoPaterno,
                apellido_materno: res.data.apellidoMaterno,
                fecha_nacimiento: fecha_nacimiento,
                distrito_id: 2,
                celular: celular,
                correo: correo,
                direccion: "",
                rol: rol,
                empresa_cargo: empresa_cargo,
              })
            }
          })
        // Verifica si el tipo de documento es RUC
      } else if (formik.values.tipo_documento.nombre === "RUC") {
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            // Establece los valores del formulario con los datos obtenidos
            formik.setValues({
              tipo_documento: tipoDocumento,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              fecha_nacimiento: fecha_nacimiento,
              distrito_id: 2,
              celular: celular,
              correo: correo,
              direccion: "",
              rol: rol,
              empresa_cargo: empresa_cargo,
            })
          })
          .catch((error) => {
            // Muestra un mensaje de error si ocurre un problema con la solicitud
            console.error("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      }
    } else {
      // Muestra un mensaje de error si no se ha elegido un tipo de documento
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }

  const header = (<Header icono={iconAgregar} titulo={'Crear postulante'} subtitulo={'En esta seccion usted puede registrar tus datos para crear supersonal'} />)

  const footer = (<Footer
    onClickCancelar={() => {
      HideDialogCreate();
    }}
    type={'submit'}
    label={'Crear postulante'} />)

  useEffect(() => {
    //Actualiza el tipo de usuario y activa la selección del rol cuando es "1"
    if (value === "Si soy usuario") {
      formik.setFieldValue('usuario', "1")
    } else if (value === "No soy usuario") {
      formik.setFieldValue('usuario', "0")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div>
      <form
        id="persona-form"
        className="persona-form"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleCreate}
          style={{ width: "450px", margin: "20px", height: "878px", borderRadius: '25px' }}
          appendTo={document.getElementById("persona-form")}
          header={header}
          footer={footer}
          dismissableMask
          className="p-fluid "
          onHide={() => HideDialogCreate()}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className="per1">(*)Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className={classNames({ 'p-invalid': isFormFieldValid('tipo_documento') })}
                />
                {getFormErrorMessage('tipo_documento')}
              </div>
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <p className="per1">(*)Número de documento</p>
              <div className="flex flex-1 w-full gap-2">
                <InputText
                  id="numero_documento"
                  name="numero_documento"
                  value={formik.values.numero_documento}
                  onChange={formik.handleChange}
                  required
                  className={classNames({ 'p-invalid': isFormFieldValid('numero_documento') })}
                />
                <Button
                  label="Validar"
                  type="button"
                  style={{ background: "#07A34F", borderColor: "#07A34F" }}
                  onClick={() => getNombreCompleto(
                    formik.values.numero_documento,
                    formik.values.tipo_documento,
                    formik.values.correo,
                    formik.values.celular,
                    formik.values.rol,
                    formik.values.empresa_cargo,
                    formik.values.fecha_nacimiento
                  )}
                  disabled={formik.values.tipo_documento?.id === 2}
                />
              </div>
              {getFormErrorMessage('numero_documento')}
            </div>

            <div>
              <label htmlFor="name">(*)Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres}
                className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
              {getFormErrorMessage('nombres')}
            </div>

            <div>
              <label htmlFor="lastname">(*)Apellidos Paterno</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno}
                onChange={formik.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })} />
              {getFormErrorMessage('apellido_paterno')}
            </div>

            <div>
              <label htmlFor="lastname">(*)Apellidos Materno</label>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={formik.values.apellido_materno}
                onChange={formik.handleChange}
                className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })} />
              {getFormErrorMessage('apellido_materno')}
            </div>

            <label htmlFor="">Correo</label>
            <InputText
              id="correo"
              name="correo"
              value={formik.values.correo}
              onChange={formik.handleChange}
              className="w-full" />

            <label htmlFor="">Celular</label>
            <InputText
              id="celular"
              name="celular"
              value={formik.values.celular}
              onChange={formik.handleChange}
              className="w-full" />

            <p>(*)Puesto de trabajo</p>
            <Dropdown
              id="empresa_cargo"
              name="empresa_cargo"
              value={formik.values.empresa_cargo}
              onChange={formik.handleChange}
              options={data}
              optionLabel="nombre"
              placeholder="Selecciona un Puesto de trabajo"
              className={classNames({ 'p-invalid': isFormFieldValid('empresa_cargo') })}
            />
            {getFormErrorMessage('empresa_cargo')}
          </div>
          <div className="flex flex-1 w-full flex-column gap-2">
            <div className="flex flex-1 w-full gap-2">
              <div>
                <p>Sexo</p>
                <Dropdown
                  id="sexo_id"
                  name="sexo_id"
                  value={formik.values.sexo_id}
                  onChange={formik.handleChange}
                  options={sexos}
                  optionLabel="nombre"
                  optionValue="id"
                  placeholder="Selecciona tu sexo"
                  className={classNames({ 'p-invalid': isFormFieldValid('sexo_id') })}
                />
                {getFormErrorMessage('sexo_id')}
              </div>

              <div>
                <p>Fecha de nacimiento</p>
                <InputCalendar
                  id="fecha_nacimiento"
                  name="fecha_nacimiento"
                  value={formik.values.fecha_nacimiento}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
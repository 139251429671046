const dataHemograma = [
    {
        id: 96,
        prueba: 'Boca',
        resultado: 'Normal',
        observaciones: ' Mucosas orales humedas. amigdalas eutroficas, orofaringe no congestiva.',
    },
    {
        id: 97,
        prueba: 'Cuello',
        resultado: 'Normal',
        observaciones: 'Cilíndrico, no deformidades.',
    },
    {
        id: 98,
        prueba: 'Nariz',
        resultado: 'Normal',
        observaciones: 'Fosas permeables',
    },
    {
        id: 99,
        prueba: 'Piel y Faneras',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 100,
        prueba: 'Dentadura',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 101,
        prueba: 'Tórax',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 102,
        prueba: 'Corazón',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 103,
        prueba: 'Otoscopia',
        resultado: 'Normal',
        observaciones: 'OD:Permeable, tímpano íntegro. OI:  Permeable, tímpano íntegro.',
    },
    {
        id: 104,
        prueba: 'Oído',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 105,
        prueba: 'Ectoscopia',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 106,
        prueba: 'Estado Mental',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 107,
        prueba: 'Pulmones',
        resultado: 'Normal',
        observaciones: 'M.V. pasa bien A.C.P.',
    },
    {
        id: 108,
        prueba: 'Cabeza',
        resultado: 'Normal',
        observaciones: 'No deformidades.',
    },
    {
        id: 109,
        prueba: 'Faringe',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 110,
        prueba: 'Aparato respiratorio',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 111,
        prueba: 'Miembros superiores',
        resultado: 'Normal',
        observaciones: 'Rangos articulares conservados',
    },
    {
        id: 112,
        prueba: 'Miembros inferiores',
        resultado: 'Normal',
        observaciones: 'Móviles, rangos articulares conservados',
    },
    {
        id: 113,
        prueba: 'Aparato digestivo',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 114,
        prueba: 'Sistema nervioso',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 115,
        prueba: 'Reflejos osteotendinosos',
        resultado: 'Normal',
        observaciones: 'Presentes, sin alteraciones',
    },
    {
        id: 116,
        prueba: 'Marcha',
        resultado: 'Normal',
        observaciones: 'Eubasica.',
    },
    {
        id: 117,
        prueba: 'Sistema Linfático',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 118,
        prueba: 'Cabello',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 119,
        prueba: 'Colobservacionesna vertebral',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 120,
        prueba: 'Abdomen',
        resultado: 'Normal',
        observaciones: ' B/D RHA presentes.',
    },
    {
        id: 121,
        prueba: 'Tacto rectal',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 122,
        prueba: 'Anillos inguinales',
        resultado: 'Normal',
        observaciones: 'Conservados',
    },
    {
        id: 123,
        prueba: 'Hernia',
        resultado: 'Normal',
        observaciones: 'No',
    },
    {
        id: 124,
        prueba: 'Varices',
        resultado: 'Normal',
        observaciones: 'No',
    },
    {
        id: 125,
        prueba: 'Genitales',
        resultado: 'Normal',
        observaciones: 'Diferido',
    },
    {
        id: 126,
        prueba: 'Ganglios',
        resultado: 'Normal',
        observaciones: 'No se palpan',
    },
    {
        id: 127,
        prueba: 'Neurología',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 128,
        prueba: 'Electrocardiograma',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 129,
        prueba: 'Flujometria',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 130,
        prueba: 'Examen osteomuscular',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 131,
        prueba: 'Estado nutricional',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 132,
        prueba: 'Estado general',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 133,
        prueba: 'Ojos',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 134,
        prueba: 'Aparato cardiovascular',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 135,
        prueba: 'Aparato genito - urinario',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 136,
        prueba: 'Aparato genito locomotor',
        resultado: 'Normal',
        observaciones: '',
    },
    {
        id: 137,
        prueba: 'Lenguaje, atención, memoria, orientación, inteligencia, afectividad',
        resultado: 'Normal',
        observaciones: 'LOTEP',
    },
    {
        id: 138,
        prueba: 'Columna vertebral',
        resultado: 'Normal',
        observaciones: 'No deformidades',
    },
]

export default dataHemograma
import { Button } from 'primereact/button';
import React, { useState } from 'react';

//NO TOCAR SI NO ERES JOEL, SEBASTIAN O DAVE (pedir permiso)
export default function ButtonVoiceReciclable2({setInput01, className, disabled}) {

    const [speechRecognition, setSpeechRecognition] = useState(null);
    const [isListening, setIsListening] = useState(false);
    
    const startListening = () => {
        const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();

        recognition.lang = 'es-ES'; // Configurar el idioma a español de España

        recognition.onstart = () => {
        setIsListening(true);
        };

        recognition.onend = () => {
        setIsListening(false);
        };

        recognition.onaudiostart = () => {
            setIsListening(true);
        };
        
        recognition.onsoundstart = () => {
            setIsListening(true);
        };
        
        recognition.onspeechstart = () => {
            setIsListening(true);
        };

        recognition.onresult = (event) => {
        setIsListening(true);
        const transcript = event.results[0][0].transcript;

        //Actualiza local
        if (typeof setInput01 === 'function') {
            setInput01((prevInput) => prevInput + " " + transcript);
        }

        startListening();
        };

        recognition.start();
        setSpeechRecognition(recognition);
    };

    const stopListening = () => {
        if (speechRecognition) {
        speechRecognition.stop();
        setIsListening(false);
        }
    };

    return (
        <>
            <Button
                disabled={disabled}
                className={className + ' h-2rem p-2'}
                onClick={isListening ? stopListening : startListening}
                style={{
                background: '#5555D8',
                color: 'white',
                width: '36px',
                borderRadius: '6px',
                border: '1px solid #6366F1',
                cursor: 'pointer',
                }}
            >
                {isListening ? 'Off' : 'On'}
            </Button>
        </>
    );
}

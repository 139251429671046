/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import { TabView, TabPanel } from 'primereact/tabview';

//Componentes generales
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice"
import AuthUser from '../../../../AuthUser';

//Componentes únicos
import ComponentServiciosClinica from '../../../ProductosServicios/ServiciosClinica/ServiciosClinica';
import ServicioCLinicaParticular from '../../../ProductosServicios/ServiciosClinica/ServicioCLinicaParticular';

import "../../../../Style/PageServiciosB.css";

const PageServiciosClinica = () => {
   const { http } = AuthUser();
   const dispatch = useDispatch();

   const [paquetes, setPaquetes] = useState([]);
   const [paquetesP, setPaquetesP] = useState([]);

   const toast = useRef(null);
   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const getPaquetes = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get("clinica/paquete/get");
         if (response?.data?.data) {
            setPaquetes(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   };

   const getPaquetesparticular = async () => {

      dispatch(startLoading());

      try {
         const response = await http.get("clinica/paquete/particular/get");

         if (response?.data?.resp || response?.data?.data) {
            setPaquetesP(response.data.data);

         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   };

   const deletePaquete = async (paquete_id) => {
      await http
         .delete(`clinica/paquete/delete/${paquete_id}`)
         .then((response) => {
            if (response?.data?.resp) {
               showToast('success', 'Éxito', response.data.resp);
               getPaquetes();
               getPaquetesparticular();
            } else if (response.data.error) {
               showToast('error', 'Error', response.data.error);
            }
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const fetchGetAllData = async () => {
      try {
         await Promise.all([
            getPaquetesparticular(),
            getPaquetes(),
         ])
         showToast(
            "success",
            `Paquetes obtenidos`,
            `Datos obtenidos correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchGetAllData();
   }, []);

   return (

      <>
         <Toast ref={toast} />
         <div className='add__container_header'>
            <span className='add__container_header_h4'>Servicios</span>
            <span className='add__container_header_p mb-2'>Adminístralo a tu mejor manera</span>

         </div>
         <div>
            <TabView>
               <TabPanel header="Coorporativo">
                  <ComponentServiciosClinica paquetes={paquetes} update={getPaquetes} eliminar={deletePaquete} showToast={showToast} />
               </TabPanel>
               <TabPanel header="Particular">
                  <ServicioCLinicaParticular paquetes={paquetesP} update={getPaquetesparticular} eliminar={deletePaquete} showToast={showToast} />
               </TabPanel>
            </TabView>

         </div>
      </>
   )

}

export default PageServiciosClinica;

import { Button } from "primereact/button";
import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import AuthUser from "../../../AuthUser";

// STYLES GENERALES
import "../../../Style/registro.css";

// COMPONENTES UNICOS

// import ModalCrearLocal from "../../../Components/RecursosHumanos/Local/Components/ModalCrearLocal";
// import ModalEliminarLocal from "../../../Components/RecursosHumanos/Local/Components/ModalEliminarLocal";
// import CardLocal from "../../../Components/RecursosHumanos/Local/Components/CardLocal";

import {ModalCrearLocal,ModalEliminarLocal,CardLocal} from '../../../Components/RecursosHumanos/Local/Components/index'


// COMPONENTES GENERALES
// import DatosGenerales from "../../Recepcion/DatosGenerales";
// const [departamento, setDepartamento] = useState(null);

const MisLocales = () => {
  const dispatch = useDispatch();
  const [nombresLocales, setNombresLocales] = useState([]); {/*Agregado*/ }
  /* // BOTON ACTIVADO Y DESACTIVAADO
    const options = ['On', 'Off']; */
  const [locales, setLocales] = useState([]);
  const [localSeleccionado, setLocalSeleccionado] = useState(null);
  const { http } = AuthUser();
  const toast = useRef(null)
  const [visible1, setVisible1] = useState({
    status: false,
    nombre: "",
  });
  const [departamentos, setDepartamentos] = useState(null);

  const [datosbusqueda, setBusqueda] = useState(locales);
  const [visibleDelete, setVisibleDelete] = useState({
    status: false,
    id: null,
  });


  const [local, setLocal] = useState({
    id: null,
    nombre: "",
    direccion: "",
    latitud: null,
    longitud: null,
    departamento_id: null,
    departamento: null,
    distrito_id: null,
    distrito: null,
    provincia_id: null,
    provincia: null,
  });


  // BUSCAR LOCALES

  const busqueda = (e) => {
    if (e.target.value !== "") {
      let datosFiltrados = locales.filter(
        (locales) =>
          locales.nombre.toLowerCase().includes(e.target.value.toLowerCase()) ||
          locales.departamento.departamento
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          locales.provincia.provincia
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          locales.distrito.distrito
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setBusqueda(datosFiltrados);
    } else {
      setBusqueda(locales);
    }
  };

  const showToast = (tipo, titulo, detalle) => {
    toast.current.show({
      severity: tipo,
      summary: titulo,
      detail: detalle,

    })
  }

  // EDITAR LOCAL
  const handleEditarlocal = (id, datos) => {
    http
      .put(`bregma/local/update/${id}`, datos)
      .then((response) => {
        /*           setVisible(false); */
        getLocales();
        showToast(
          "success",
          "Editado correctamente",
          "El local se editó correctamente"
        )
      })
      .catch((error) => {
        console.error(error);
        showToast(
          "error",
          "Error al editar el local",
          "Hubo un problema al editar el local. Por favor, inténtalo de nuevo."
        );
      });
  };

  // ELIMINAR LOCAL

  const handleDelete = (id) => {
    http
      .delete(`bregma/local/delete/${id}`)
      .then((response) => {
        getLocales();
        showToast(
          "success",
          "Eliminado correctamente",
          "El local se eliminó correctamente"
        )
      })
      .catch((error) => {
        console.error(error);
        showToast(
          "error",
          "Error al eliminar el local",
          "Hubo un problema al eliminar el local. Por favor, inténtalo de nuevo."
        );

      });
  };
  // CREAR LOCAL

  const handleCreate = (datosLocal) => {
    http
      .post("bregma/local/create", datosLocal)
      .then((response) => {
        getLocales();
        setVisible1(false);
        showToast(
          "success",
          `Creado correctamente`,
          `El local se creó correctamente`
        );
      })
      .catch((error) => {
        console.error(error);
        showToast(
          "error",
          "Error al crear el local",
          "Hubo un problema al crear el local. Por favor, inténtalo de nuevo."
        );
      });
  };



  // OBTENER LOCALES
  const getLocales = async () => {
    try {
      const response = await http
        .get("bregma/local/show")
      setLocales(response?.data?.data);
      setBusqueda(response?.data?.data);
    } catch (error) {
      console.error(error);
    };
  };

  {/*Agregado*/ }
  useEffect(() => {
    // Obtiene la lista de locales...
    http.get("bregma/local/show")
      .then((response) => {
        const localesData = response?.data?.data || [];
        setLocales(localesData);

        // Extrae los nombres de los locales y los guarda en nombresLocales
        const nombres = localesData.map((local) => local.nombre);
        setNombresLocales(nombres);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  {/*Agregado*/ }

  // OBTENER LISTA DE  DEPARTAMENTOS
  const getAllUbicaciones = async () => {
    try {
      const response = await http
        .get("/ubicacion")
      setDepartamentos(response.data.data);
    } catch (error) {
      console.error(error);
    };
  };

  const fetchAllData = async () => {
    try {
      await Promise.all([
        getLocales(),
        getAllUbicaciones(),
      ]);
    } catch (error) {
      console.error('Error al cargar datos:', error);
    } finally {
      dispatch(endLoading());
    }
  }

  //   const [url, setUrl] = useState("./areas");
  useEffect(() => {
    // getLocales();
    // getAllUbicaciones();
    dispatch(startLoading());
    fetchAllData()
  }, []);



  return (
    <div className="flex flex-wrap flex-column gap-5 px-6">
      <div className="flex flex-wrap flex-column  ">
        <span className="text-indigo-600 text-3xl font-bold">Mis Locales</span>

        <p className="w-30rem text-indigo-200 text-sm">
          A continuación se visualiza los locales previamente creados para poder
          colocar las áreas que usted a definido en cada uno de sus locales.
        </p>
        {/* <DatosGenerales
          nombresLocales={nombresLocales} // Extrae los nombres de locales
          localSeleccionado={localSeleccionado}
          onLocalChange={(e) => setLocalSeleccionado(e.value)}
        /> */}
      </div>

      <div className=" w-full">
        {/* CARTAS */}

        <ScrollPanel className="scroll">
          <div className=" p-input-icon-left w-full mb-3  flex justify-content-center" style={{ background: "#F6F7FB", boxShadow: "none" }}>
            <span className="p-input-icon-left" style={{ width: "85%", background: "#F6F7FB", boxShadow: "none" }}>
              <i className="px-2 pi pi-search" style={{ color: "#ccd2d8" }} />
              <InputText
                placeholder="Busca tu local"
                style={{ background: "white", width: "100%", height: "52px" }}
                onChange={(e) => busqueda(e)}
              />
            </span>
          </div>

          <div className="px-8  contenedorcard ">

            <button
              className="Effect_Shine  cursor-pointer border-2 border-dashed border-round-2xl flex flex-1 flex-column bg-purple-50 p-3 gap-2 border-round-lg align-items-center 
                              justify-content-center border-indigo-500 hola "
              style={{
                width: "370px",
                height: "361px",
              }}
              onClick={() =>
                setVisible1({
                  ...visible1,
                  status: true,
                  nombre: "Crear local",
                })
              }
            >
              <i className="pi pi-plus-circle text-indigo-600 font-bold text-xl"></i>
              <p className="font-bold text-indigo-600 text-base">
                Crear Nuevo Local
              </p>
              <span className="StepsV_shine"></span>
            </button>

            {
              //MOSTRAR DATOS DE BUSQUEDA
              datosbusqueda.map((localitem, key) => {
                return (
                  <CardLocal
                    key={key}
                    localitem={localitem}
                    local={local}
                    setLocal={setLocal}
                    setVisible1={setVisible1}
                    visible1={visible1}
                    visibleDelete={visibleDelete}
                    setVisibleDelete={setVisibleDelete}
                    provincia={localitem?.provincia?.provincia}
                  />
                );
              })
            }
          </div>
        </ScrollPanel>
      </div>
      <Toast ref={toast} />
      {/*/MODAL CREAR Y EDITAR*/}
      <ModalCrearLocal
        local={local}
        setLocal={setLocal}
        handleCreate={handleCreate}
        setVisible1={setVisible1}
        visible1={visible1}
        departamentos={departamentos}
        handleEditarlocal={handleEditarlocal}
      />
      {/*/MODAL ELIMINAR*/}
      <ModalEliminarLocal
        handleDelete={handleDelete}
        setVisibleDelete={setVisibleDelete}
        visibleDelete={visibleDelete}
      />
    </div>
  );
};
export default MisLocales;

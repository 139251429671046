import { createContext, useState } from "react";

const ventasContext = {
    ventas: {},
    setVentas: () => { },
};

export const VentasContext = createContext(ventasContext);

export function VentasProvider({ children }) {
    const [ventas, setVentas] = useState({
        GCEmpresa: {
            tipos_de_clientes: [],
            ubicaciones: [],
            rubros: []
        },
        GCContacto: {
            contactos: []
        },
        GCProducto: {
            paquetes: []
        },
        GCCredenciales: {
            contactos: []
        }
    });

    const updateVentas = (newVentas) => {
        setVentas(newVentas);
    };

    return (
        <VentasContext.Provider value={{ ventas, setVentas: updateVentas }}>
            {children}
        </VentasContext.Provider>
    );
} 
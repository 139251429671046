import React from "react";
import check from '../../../../assets/check-ultimate.svg'
import bienvenido from "../../../../Images/Iconperfil/Bienvenido.png"
import '../../../../Style/Permisos.css'

export default function PermisosComponent(props) {
  return (
    <div className="CONTENEDOR_BIENVENIDA_AHP flex flex-column align-items-center justify-content-between">
      <div className="CONTENEDOR_BIENEVENIDA_AHP_01 relative w-full h-auto flex flex-column align-items-center justify-content-center">
        <img
          src={bienvenido}
          alt="" style={{ width: 'auto', height: '180px', marginRight: '10px' }}
        />
        <div className="LINEA_DIVISORIA_AHP absolute mt-3"></div>
        <h3 className="SUBTITULO_NEGRITA mt-2">Bienvenido</h3>
        <p className="RAZONSOCIAL_SUBTITULO">{props.razonSocial}</p>
      </div>

      <div className="CONTENEDOR_BIENEVENIDA_AHP_02 w-full h-auto flex flex-column align-items-center justify-content-center p-4">
        <p className="text-center">Las funciones que podrás realizar son las siguientes</p>
        <p><img src={check} alt="check" style={{ height: "26px", width: "26px" }} />Editar los datos de tu perfil</p>
        <p><img src={check} alt="check" style={{ height: '26px', width: '26px' }} />Editar la información de las cuentas bancarias</p>
        <p><img src={check} alt="check" style={{ height: '26px', width: '26px' }} />Agregar nuevas entidades bancarias</p>
      </div>
    </div>
  )
}
// }
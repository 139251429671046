
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../../Services/main1";
import AuthUser from "../../../AuthUser";
import firma from "../../../Images/atencion/Recepcion/firma.svg";
import Nuevopopupfirma from './Nuevopopupfirma';


const FirmaConsent = ({ titulo, imagen, Paciente }) => {
  const { http } = AuthUser();
  const [conectado, setConectado] = useState(false);
  const [mostrarImagen, setMostrarImagen] = useState(true);
  const [mostrarModal, setMostrarModal] = useState(false);


  useEffect(() => {
    setMostrarImagen(true);
    setConectado(false)
  }, []);



  const simularFirma = () => {
    setMostrarModal(false);
  };


  const onHideModal = () => {
    setMostrarModal(false);
    simularFirma();
  };


  const [Firmas, setFirmas] = useState();

  const getDocumentos =() => {
    http
      .get(`/persona/find/${Paciente.numero_documento}`)
      .then((response) => {
        setFirmas(response.data.data.firma)
      })
      .catch((error) => {
        if (error.response && error.response.status === 429) {
          // Manejo de errores específicos, si es necesario
        } else {
          // Manejo de otros errores
        }
      });
  };
  
  const[vari, setvari]= useState(1);
  const [ejecutado, setEjecutado] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!ejecutado) {
        getDocumentos();
      }
    }, 2000);

    return () => clearTimeout(timer);
  }, [vari]);
  

  const [DialogCrearFirma, setDialogCrearFirma] = useState(false);
  return (
    <div className="flex flex-wrap flex-column w-full gap-3">
      <div className="flex flex-wrap justify-content-center">
        <p style={{ background: "#FFFFFF" }} className="p-3 text-center w-4">{titulo}</p>
      </div>
      {/* <button></button>
      {Paciente.id} */}
      <div className="contenedorFH gap-4 flex flex-wrap flex-column p-3">
        {Firmas && (
          <div className="w-full h-auto flex align-items-center justify-content-center">
            <img alt="imagenfirma" className="w-10 m-auto h-auto" src={`${Firmas}`} />
          </div>
        )}
        {!Firmas && (
          <div className="w-full h-auto flex align-items-center justify-content-center">
            <img alt="imagen_firma" className="w-10 m-auto" style={{height:'360px'}} src={firma} />
          </div>
        )}

        <div className="flex flex-wrap flex-row w-full gap-3">
          <div className="flex flex-wrap flex-1">
            <Button
              type="button"
              label="Digital"
              className="boton02" style={{margin:'auto', width:'200px'}}
              onClick={() => setMostrarModal(true)}
              disabled={conectado}
            />
          </div>
          <Nuevopopupfirma
          visible={DialogCrearFirma}
          setVisible={setDialogCrearFirma}
          paciente={Paciente}
          variable={vari}
          Setvariuable={setvari}
          />
          {/* <div className="flex flex-wrap flex-1">
            <Button
              type="button"
              label="Quitar"
              className="boton02 w-full"
              onClick={handleQuitarfirma}
              disabled={conectado}
            />
          </div>
          <div className="flex flex-wrap w-5">
            <Dropdown
              value={selectTablero}
              onChange={(e) => setSelectTablero(e.value)}
              options={optionTablero}
              optionLabel="name"
              placeholder="Tablero 1"
              className="flex flex-wrap w-full"
            />
          </div>
          <div className="flex flex-wrap w-5">
            <Button
              className="btn btn-primary"
              onClick={() => saveFirma(1)}
            >
              Guardar
            </Button>
          </div> */}
        </div>
      </div>

      <Dialog visible={mostrarModal} onHide={onHideModal} className="text-center w-3">
        <div style={{ textAlign: 'center', padding: '1rem' }}>
          <p style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>¿Está seguro de realizar esta acción?</p>
        </div>
        <div className="p-d-flex p-flex-column p-ai-center">
          <i className="pi pi-exclamation-triangle p-mr-2 text-red-400 text-7xl" />
          <p className="text-2xl">Si continúa, activará la firma digital</p>
        </div>
        <div className="p-d-flex p-jc-between m-3">
          <Button label="Cerrar" icon="pi pi-times" onClick={onHideModal} className="p-button-text pr-6" />
          <Button label="Continuar" icon="pi pi-check" onClick={()=>{setDialogCrearFirma(true); onHideModal()}} className="p-button-primary" />
        </div>
      </Dialog>
    </div>
  );
};

export default FirmaConsent;

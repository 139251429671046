import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import axios from "axios";

//Componentes generales
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { Footer, Header } from "../../../Pure/SeccionesNavegacion";
import AuthUser from "../../../../AuthUser";
import InputCalendar from "../../../form/InputCalendar";

export default function ModalEditar({
  visibleEdit,
  setVisibleEdit,
  personal,
  showToast,
  tipoDocumentos,
  roles,
  getPersonal,
  api,
  data,
  contractOptions,
  tipo_acceso,
  sexos
}) {

  const dispatch = useDispatch();
  const { http } = AuthUser();
  const [value, setValue] = useState(true);

  //Opciones para "value" (SelectButton)
  let options = [
    { label: 'Es usuario', value: true },
    { label: 'No es usuario', value: false }
  ]

  //Valores iniciales
  const initialValues = {
    id: 0,
    tipo_documento: "",
    numero_documento: "",
    tipo_contrato: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    sexo: '',
    fecha_nacimiento: "",
    distrito_id: 2,
    celular: "",
    correo: "",
    direccion: "",
    rol: "",
    usuario: "",
    empresa_cargo: ""
  };

  //Obtención de datos existentes
  const updatePersonal = () => {
    formik.setValues({
      id: personal?.id,
      tipo_documento: personal?.persona?.tipo_documento,
      numero_documento: personal?.persona?.numero_documento,
      tipo_contrato: personal?.tipo_contrato,
      nombres: personal?.persona?.nombres,
      apellido_paterno: personal?.persona?.apellido_paterno,
      apellido_materno: personal?.persona?.apellido_materno,
      fecha_nacimiento: new Date(personal?.persona?.fecha_nacimiento),
      sexo: personal?.persona?.sexo,
      distrito_id: 2,
      celular: personal?.persona?.celular,
      correo: personal?.persona?.correo,
      direccion: personal?.persona?.direccion,
      rol: personal?.rol,
      usuario: personal?.user_rol,
      empresa_cargo: personal?.empresa_cargo,
    });
  };

  // Inicializa el hook useFormik con los valores iniciales y la función onSubmit
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (datos) => {
      // Crea un objeto FormData para enviar los datos del formulario
      const formDataSend = new FormData();
      for (var key in datos) {
        formDataSend.append(key, datos[key]);
      }
      // Agrega los IDs de los campos relacionados al FormData
      formDataSend.append("tipo_documento_id", datos?.tipo_documento?.id);
      datos.tipo_documento_id = datos?.tipo_documento?.id;
      datos.sexo_id = datos?.sexo?.id;

      formDataSend.append("tipo_contrato_id", datos?.tipo_contrato?.id);
      datos.tipo_contrato_id = datos?.tipo_contrato?.id;

      formDataSend.append("rol_id", datos?.rol?.id);
      datos.rol_id = datos?.rol?.id;

      formDataSend.append("empresa_cargo_id", datos?.empresa_cargo?.id);
      datos.empresa_cargo_id = datos?.empresa_cargo?.id;

      // Asigna el valor del usuario desde los valores del formik
      datos.usuario = formik.values.usuario;

      // Inicia el estado de carga
      dispatch(startLoading());
      try {
        // Realiza la petición PUT para actualizar los datos del personal
        const response = await http.put(`${api}/${datos.id}`, datos)
        if (response?.data?.resp) {
          // Oculta el diálogo de edición y actualiza la lista de personal
          HideDialogEdit();
          await Promise.all([getPersonal()])
          showToast("success", "Actualizado correctamente", "Se creo correctamente el personal");
        } else if (response?.data?.error) {
          // Muestra un mensaje de error si la respuesta contiene un error
          showToast("error", "Error", response?.data?.error);
        }
      } catch (error) {
        // Muestra un mensaje de error en caso de excepción
        showToast("error", "Error", "Contáctese con soporte técnico");
      } finally {
        // Finaliza el estado de carga
        dispatch(endLoading());
      }
    },
    validate: (data) => {
      let errors = {}
      if (!data.tipo_documento) {
        errors.tipo_documento = "Tipo de documento requerido"
      }
      if (!data.numero_documento) {
        errors.numero_documento = "Número de documento requerido"
      }
      if (data.numero_documento?.length !== 8 || !/^\d+$/.test(data.numero_documento)) {
        errors.numero_documento = 'Ingrese 8 dígitos'
      }
      if (!data.nombres) {
        errors.nombres = "Nombre requerido"
      }
      if (!data.apellido_paterno) {
        errors.apellido_paterno = "Apellido paterno requerido"
      }
      if (!data.apellido_materno) {
        errors.apellido_materno = "Apellido materno requerido"
      }
      if (!data.empresa_cargo) {
        errors.empresa_cargo = "Seleccione una opción"
      }
      if (!data.rol) {
        errors.rol = "Rol requerido"
      }
      return errors;
    }
  });

  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  //Oculta dialog luego de actualizar
  const HideDialogEdit = () => {
    setVisibleEdit(false);
  };

  const header = (
    <Header piIcon={'pi pi-user'}
      titulo={"Editar personal"}
      subtitulo={"En esta seccion usted puede editar los datos para personal"}
    />
  )

  const paqueteCreateDialogFooter = (
    <Footer type={"submit"}
      onClickCancelar={() => {
        HideDialogEdit();
      }}
      label={"Actualizar"} />
  );

  //Validación de número DNI
  const getNombreCompleto = (numdocumento, tipoContrato) => {
    if (formik.values.tipo_documento !== "") {
      //Inicia la carga
      dispatch(startLoading());
      if (formik.values.tipo_documento.nombre === "DNI") {
        axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            if (res.data.message) {
              //Mensaje de error controlado
              showToast(
                "error",
                "Número de documento no encontrado",
                `No se encontró ningún dato`
              );
            } else {
              //Actualiza los input con los datos obtenidos de la api
              formik.setValues({
                id: personal?.id,
                tipo_documento: formik.values.tipo_documento,
                numero_documento: numdocumento,
                tipo_contrato: tipoContrato,
                nombres: res.data.nombres,
                apellido_paterno: res.data.apellidoPaterno,
                apellido_materno: res.data.apellidoMaterno,
                fecha_nacimiento: formik.values.fecha_nacimiento,
                sexo: formik.values.sexo,
                distrito_id: 2,
                celular: formik.values.celular,
                correo: formik.values.correo,
                direccion: formik.values.direccion,
                rol: formik.values.rol,
                usuario: formik.values.usuario,
                empresa_cargo: formik.values.empresa_cargo
              })
            }
          })
          .catch((error) => {
            //Mensaje de error no controlado
            console.error("Error al obtener nombre completo", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningún dato`
            );
          })
          .finally(() => {
            //Finaliza la carga
            dispatch(endLoading());
          })
      } else if (formik.values.tipo_documento.nombre === "RUC") {//Cuando es RUC
        axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
          .then(res => {
            //Setea los datos obtenidos por medio de la api y el número de documento
            formik.setValues({
              id: personal?.id,
              tipo_documento: formik.values.tipo_documento,
              tipo_contrato: tipoContrato,
              numero_documento: numdocumento,
              nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
              apellido_paterno: res.data.razonSocial.split(" ")[0],
              apellido_materno: res.data.razonSocial.split(" ")[1],
              distrito_id: 2,
              celular: formik.values.celular,
              correo: formik.values.correo,
              direccion: formik.values.direccion,
              rol: formik.values.rol,
              usuario: formik.values.usuario,
              empresa_cargo: formik.values.empresa_cargo
            })
          })
          .catch((error) => {
            //Mensaje de error no controlado
            console.error("ALERTA ERROR", error);
            showToast(
              "error",
              "Número de documento no encontrado",
              `No se encontró ningun dato`
            );
          })
      }
    }
    else {
      //Mensaje de error cuando no se ingresa el tipo de documento
      showToast(
        "error",
        "Tipo de documento",
        `No se eligió un tipo de documento`
      );
    }
  }

  useEffect(() => {
    // Actualiza los datos del personal
    updatePersonal();
    // Verifica si el personal tiene un rol asignado
    if (personal?.rol) {
      // Establece el valor del selectButton a "Es usuario"
      setValue(options[0].value);
      // Asigna el valor "1" al campo 'usuario' del formik
      formik.setFieldValue('usuario', "1")
    } else {
      // Establece el valor del selectButton a "No es usuario"
      setValue(options[1].value);
      // Asigna el valor "0" al campo 'usuario' del formik
      formik.setFieldValue('usuario', "0")
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personal, roles, data]);

  return (
    <div>
      <form
        id="personal-form-edit"
        className="personal-form-edit"
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <Dialog
          visible={visibleEdit}
          style={{ width: "450px", margin: "5%" }}
          appendTo={document.getElementById("personal-form-edit")}
          header={header}
          dismissableMask
          className="p-fluid "
          footer={paqueteCreateDialogFooter}
          onHide={() => { HideDialogEdit(); }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p className="per1" style={{ marginTop: '8px' }}>(*)Tipo de documento</p>
              <div className="flex w-full flex-column">
                <Dropdown
                  id="tipo_documento"
                  name="tipo_documento"
                  value={formik.values.tipo_documento}
                  onChange={formik.handleChange}
                  options={tipoDocumentos}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de documento"
                  className={!formik.values.tipo_documento ? classNames({ 'p-invalid': isFormFieldValid('tipo_documento') }) : <></>}
                />
              </div>
              {getFormErrorMessage('tipo_documento')}
            </div>

            <div className="flex flex-1 w-full flex-column gap-2">
              <p className="per1" style={{ marginTop: '8px' }}>(*)Número de documento</p>
              <div className="flex flex-1 w-full gap-2">
                <InputText
                  id="numero_documento"
                  name="numero_documento"
                  value={formik?.values?.numero_documento ?? ''}
                  onChange={formik.handleChange}
                  className={!formik.values.numero_documento ? classNames({ 'p-invalid': isFormFieldValid('numero_documento') }) : <></>}
                  required
                />
                <Button
                  type="button"
                  label="Validar"
                  style={{ background: "#07A34F", borderColor: "#07A34F" }}
                  onClick={() => getNombreCompleto(
                    formik.values.numero_documento,
                    formik.values.usuario,
                    formik.values.tipo_contrato
                  )}
                />
              </div>
              {!formik.values.numero_documento ? getFormErrorMessage('numero_documento') : <></>}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="name">(*)Nombres Completos</label>
              <InputText
                id="nombres"
                name="nombres"
                value={formik.values.nombres ?? ''}
                className={classNames(!formik.values.nombres ? { 'p-invalid': isFormFieldValid('nombres') } : <></>)}
                onChange={formik.handleChange}
                required
                autoComplete="off"
              />
              {!formik.values.nombres ? getFormErrorMessage('nombres') : <></>}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">(*)Apellidos Paterno</label>
              <InputText
                id="apellido_paterno"
                name="apellido_paterno"
                value={formik.values.apellido_paterno ?? ''}
                onChange={formik.handleChange}
                className={classNames(!formik.values.apellido_paterno ? { 'p-invalid': isFormFieldValid('apellido_paterno') } : <></>)}
              />  
              {!formik.values.apellido_paterno ? getFormErrorMessage('apellido_paterno') : <></>}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="lastname">(*)Apellidos Materno</label>
              <InputText
                id="apellido_materno"
                name="apellido_materno"
                value={formik.values.apellido_materno ?? ''}
                onChange={formik.handleChange}
                className={!formik.values.apellido_materno ? classNames({ 'p-invalid': isFormFieldValid('apellido_materno') }) : <></>}
              />
              {!formik.values.apellido_materno ? getFormErrorMessage('apellido_materno') : <></>}
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="">Correo</label>
              <InputText
                id="correo"
                name="correo"
                value={formik.values.correo ?? ''}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            <div className="flex flex-column gap-2">
              <label htmlFor="">Celular</label>
              <InputText
                id="celular"
                name="celular"
                value={formik.values.celular ?? ''}
                onChange={formik.handleChange}
                className="w-full"
              />
            </div>

            {/* Se activa cuando empresa es el tipo de acceso */}
            {!tipo_acceso ? <></> : (<>
              <div className="flex flex-column gap-2">
                <p>(*)Puesto de trabajo</p>
                <Dropdown
                  id="empresa_cargo"
                  name="empresa_cargo"
                  value={formik.values.empresa_cargo ?? ''}
                  onChange={formik.handleChange}
                  options={data}
                  optionLabel="nombre"
                  placeholder="Selecciona un Puesto de trabajo"
                  className={!formik.values.empresa_cargo ? classNames({ 'p-invalid': isFormFieldValid('empresa_cargo') }) : <></>}
                />
                {!formik.values.empresa_cargo ? getFormErrorMessage('empresa_cargo') : <></>}
              </div>

              <div className="flex flex-column gap-2">
                <p>Tipo de contrato</p>
                <Dropdown
                  id="tipo_contrato"
                  name="tipo_contrato"
                  value={formik.values.tipo_contrato}
                  onChange={formik.handleChange}
                  options={contractOptions}
                  optionLabel="nombre"
                  placeholder="Selecciona un tipo de contrato"
                  className="w-full"
                />
              </div>

              {/* No eliminar */}
              {/* <div className="flex flex-column gap-2">
                <p className="per1">Último examen médico</p>
                <Calendar
                  id="examen_medico"
                  name="examen_medico"
                  value={formik.values.examen_medico}
                  onChange={formik.handleChange}
                  optionLabel="nombre"
                  placeholder="Seleccionar fecha"
                  className="w-full"
                />
              </div> */}
            </>)}

            <div className="flex justify-content-center align-content-center">
              <SelectButton
                className="flex-1"
                value={value}
                onChange={(e) => setValue(e.value)}
                options={options}
              ></SelectButton>
            </div>
            {value === true ? <p>(*)Rol</p> : <p>Rol</p>}
            <Dropdown
              id="rol"
              name="rol"
              value={roles?.filter(e => e?.id === formik?.values?.rol?.id)[0]}
              onChange={formik.handleChange}
              options={roles}
              optionLabel="nombre"
              placeholder="Selecciona un Rol"
              className={!formik?.values?.rol ? classNames({ 'p-invalid': isFormFieldValid('rol') }) : <></>}
              disabled={!value}
            />
            {value ? getFormErrorMessage('rol') : <></>}
            {!tipo_acceso ? <></> : (<>
              <div className="flex flex-1 w-full flex-column gap-2">
                <div className="flex flex-1 w-full gap-2">
                  <div>
                    <p>Sexo</p>
                    <Dropdown
                      id="sexo"
                      name="sexo"
                      value={formik.values.sexo}
                      onChange={formik.handleChange}
                      options={sexos}
                      optionLabel="nombre"
                      placeholder="Selecciona tu sexo"
                      className="w-full"
                    />
                  </div>

                  <div>
                    <p>Fecha de nacimiento</p>
                    <InputCalendar
                      id="fecha_nacimiento"
                      name="fecha_nacimiento"
                      value={formik.values.fecha_nacimiento}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div></>)}
          </div>
        </Dialog>
      </form>
    </div>
  );
}
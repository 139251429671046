import React from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";

import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";


export default function ModalEliminar({ eliminar, close, estado, paquete }) {
    const dispatch = useDispatch();
    
    const ActivarLoading = () => {
        dispatch(startLoading());
        setTimeout(() => {
            dispatch(endLoading());

        }, 800);
    }
    const handleDeleteConfirmation = (id_paquete) => (
        <div className="flex justify-content-end">
            <Button
                label="Cancelar"
                className="p-button-secondary"
                style={{ background: "white", color: "#344054" }}
                onClick={() => {
                    close(false);
                }}
            />
            <Button
                label="Eliminar"
                className="p-button-danger "
                onClick={() => {
                    close(false);
                    eliminar(id_paquete);
                    ActivarLoading();
                }}
            />
        </div>
    );


    return (
        <Dialog
            visible={estado}
            resizable={false}
            style={{ width: "500px", height: "auto" }}
            className="p-fluid eliminarLocal"
            header={
                <div className="flex gap-2 flex-column pl-2" style={{ alignItems: "start" }}>
                    <div style={{ backgroundColor: '#EBEBFF', width: '60px', height: '60px', borderRadius: '10px' }} className="flex align-items-center justify-content-center">
                        <i className="pi pi-trash" style={{ color: '#5555D8', fontSize: '34px' }}></i>
                    </div>
                    <div style={{ fontSize: "18px", fontWeight: "700", color: "#5555D8", fontFamily: 'Montserrat' }}>
                        Eliminar paquete
                    </div>
                </div>
            }
            footer={handleDeleteConfirmation(paquete?.paquete?.id)}
            onHide={() => close(false)}
        >
            <div className="flex flex-column gap-3" style={{ fontSize: "17px", fontFamily: 'Montserrat' }}>
                ¿Está seguro qué desea eliminar el paquete?
            </div>
        </Dialog>

    )
}
import React, { useState, useEffect } from 'react';
import { TreeSelect } from 'primereact/treeselect';
import { useDispatch } from "react-redux";

//Importación de componentes generales
import AuthUser from '../../../../../AuthUser';
import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";


const MultiLevelTreeSelectWithAPIClinica = ({ selectedTipoServicio, onTipoServicioSelect, selectedService, setSelectedService, faltt }) => {
    const { http } = AuthUser();

    const [groupedServices, setGroupedServices] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        getServicios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getServicios = async () => {

        dispatch(startLoading());

        try {
            const response = await http.get('/clinica/servicio/get');

            if (response?.data?.data || response?.data?.resp) {
                const processedData = response.data.data.map(item => ({
                    key: item.id.toString(),
                    label: item.nombre,
                    data: item.nombre,
                    icon: '', // Puedes ajustar esto según tus necesidades
                    children: item.children.map(subItem => ({
                        key: subItem.id.toString(),
                        label: subItem.nombre,
                        data: subItem.nombre,
                        icon: '', // Puedes ajustar esto según tus necesidades
                        children: subItem.children.map(subSubItem => ({
                            key: subSubItem.id.toString(),
                            label: subSubItem.nombre,
                            data: subSubItem.nombre,
                            icon: '' // Puedes ajustar esto según tus necesidades
                        }))
                    }))
                }));
                setGroupedServices(processedData);
            } else if (response?.data?.error) {
                console.error(response.data.error);
            }

        } catch (error) {
            console.error('Error fetching services:', error);

        } finally {
            dispatch(endLoading());
        }
    };

    const handleNodeSelect = event => {
        if (onTipoServicioSelect) {
            onTipoServicioSelect(event.value);
        }
    };

    return (
        <div className='w-full'>
            <style>
                {`
                    .InputTrresSelctH.selectt{
                        box-shadow: 0 0 0 2px white, 0 0 0 3px red;
                    }
                `}
            </style>
            <TreeSelect
                value={selectedTipoServicio}
                options={groupedServices}
                selectionMode="single"
                placeholder="Tipo de Servicio"
                onChange={handleNodeSelect}
                style={{ transition: '.4s' }}
                className={`w-full flex flex-row align-items-center InputTrresSelctH ${faltt ? 'selectt' : ''}`}
            />

        </div>
    );
};

export default MultiLevelTreeSelectWithAPIClinica;
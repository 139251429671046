import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import { CardServicioComponent, BrandComponent, TablaServicios } from '../index'
import AuthUser from "../../../../AuthUser";
import { transformData } from "../../../../Services/Operaciones/operaciones";


export default function ComponentContenedorServicvio({ paquetes, update, deletePaquete, showToast }) {
   const { http } = AuthUser();

   const [servicios, setServicios] = useState([]);
   const [transformedNodes, setTransformedNodes] = useState([]);

   const getServicios = async () => {
      try {
         const response = await http.get("bregma/operaciones/servicios/get")
         if (response?.data?.data) {
            setTransformedNodes(transformData([response.data.data]));
            setServicios([response.data.data])
         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error);
         }

      } catch (e) {
         console.error(e);
         showToast("error", "Error", "Contáctese con soporte técnico");
      };
   };

   // Card
   const toggleSortOrderCard = () => {
      setSortOrderCard(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
   };
   const [sortOrderCard, setSortOrderCard] = useState("asc");

   const [sortOrder, setSortOrder] = useState("asc"); // Estado para controlar el orden ("asc" o "desc")
   const toggleSortOrder = () => {
      setSortOrder(prevOrder => (prevOrder === "asc" ? "desc" : "asc"));
   };

   // Ordenar paquetes
   const sortedPaquetes = paquetes
      .slice()
      .sort((a, b) => {
         const nameA = a.paquete?.nombre?.toLowerCase() || "";
         const nameB = b.paquete?.nombre?.toLowerCase() || "";

         if (sortOrder === "asc") {
            return nameA.localeCompare(nameB);
         } else {
            return nameB.localeCompare(nameA);
         }
      });

   // Ordenar tabla
   const sortedTable = sortedPaquetes;

   const createPaquete = async (data) => {
      try {
         const response = await http.post("bregma/paquete/create", data)
         if (response?.data?.resp) {
            // setServicios([response.data]);
            showToast('success', 'Creado correctamente', 'El paquete se creó correctamente');
            update();
         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error);
         }
      } catch (e) {
         console.error(e);
         showToast("error", "Error", "Contáctese con soporte técnico");
      };
   };

   useEffect(() => {
      getServicios();
   }, []);

   const tab1HeaderTemplate = (options) => {
      return (
         <Button
            icon="pi pi pi-list"
            onClick={options.onClick}
            className="px-2 m-2 gap-3 p-button-text flex-wrap"
            style={{
               fontSize: "27px",
               width: "38px",
               height: "38px",
               background: "white",
               borderRadius: "8px",
            }}
         ></Button>
      );
   };

   const tab2HeaderTemplate = (options) => {
      return (
         <Button
            icon="pi pi pi-th-large"
            onClick={options.onClick}
            className="px-2 m-2 gap-3 p-button-text flex-wrap"
            style={{
               fontSize: "27px",
               width: "38px",
               height: "38px",
               background: "white",
               borderRadius: "8px",
            }}
         ></Button>
      );
   };

   return (
      <>
         <div className="px-8" style={{ marginTop: "50px" }}>
            <div className="flex justify-content-start w-full relative">

               <TabView className="Conedor_de_Servicios_vistas w-full">
                  <TabPanel
                     headerTemplate={tab1HeaderTemplate}
                     headerClassName="flex align-items-center"
                  >
                     {/* Tabla */}
                     <TablaServicios transformedNodes={transformedNodes} eliminar={deletePaquete} paquete={paquetes} sortOrder={sortOrder} toggleSortOrder={toggleSortOrder} showToast={showToast} update={update} />
                  </TabPanel>

                  <TabPanel
                     headerTemplate={tab2HeaderTemplate}
                     headerClassName="flex align-items-center"
                  >
                     <div className="grid mt-5">

                        {sortedPaquetes?.map((item, key) => (
                           <CardServicioComponent
                              key={key}
                              transformedNodes={transformedNodes}
                              paquete={item}
                              sortOrder={sortOrder} // Pasar el estado de ordenamiento como prop
                              toggleSortOrder={toggleSortOrder} // Pasar la función de ordenamiento como prop
                              update={update}
                              eliminar={deletePaquete}
                              showToast={showToast}
                           />
                        ))}

                     </div>
                  </TabPanel>
               </TabView>
               <div className="brand-container">
                  <BrandComponent
                     servicios={servicios}
                     transformedNodes={transformedNodes}
                     createPaquete={createPaquete}
                     toggleSortOrder={toggleSortOrder} // Agregar esta prop
                     showToast={showToast}
                     update={update}
                  />

               </div>
            </div>

         </div>
      </>
   );
}
import React, { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { Dialog } from "primereact/dialog";

//Componentes
import { Footer, Header } from "../../../../Pure/SeccionesNavegacion";
import ModalAgregar from "./ModalAgregar";
import ModalEditarArea from "./ModalEditarArea";
import ActionTemplate from "./MenuDesplegable";

//Imágenes
import carpetawhite from "../../../../../Images/Icons/Mis_Locales/Areas/carpeta-white.svg";
import Icon from "../../../../../Images/Icon.png";

const TablaAreas = ({
   areas,
   handleagregarAreasgenerales,
   handleGetPersonal,
   source,
   setSource,
   target,
   setTarget,
   agregarPersonal,
   handleEditArea,
   handleDelete,
}) => {
   const [visible, setVisible] = useState(false);
   const [visible2, setVisible2] = useState(false);
   const [visible3, setVisible3] = useState(false);
   const [visibleEdAr, setvisibleEdAr] = useState(false);
   const [visibleAgPersonal, setvisibleAgPersonal] = useState(false);
   const [rowData, setRowData] = useState();
   const [dataAsignar, setDataAsignar] = useState({});
   const [area, setArea] = useState({
      nombre: "",
      parent_id: null,
   });
   const [showError, setShowError] = useState(false);

   const handleChangeNombre = (e) => {
      //Actualiza el estado del nombre de la área
      setArea({
         ...area,
         nombre: e.target.value,
      });
   };

   const handleChangeNombreSubArea = (e) => {
      //Actualiza el estado del nombre de la sub área
      setArea({
         parent_id: rowData.data.id,
         nombre: e.target.value,
      });
   };

   //Modal de eliminar área
   const dialogHeader = (<Header piIcon={'pi pi-trash'} titulo={'Eliminar área'} />);

   const dialogFooter = (
      <Footer
         onClickCancelar={() => {
            setVisible3(false);
         }}
         onClickEnviar={() => BotonEliminar()}
         label={'Eliminar'} />
   );

   //Modal crear área
   const dialogTitle = (<Header icono={Icon} titulo={'Crear área'} subtitulo={"En esta sección usted podrá registrar las áreas"} />)

   const dialogFooterCreate = (
      <Footer onClickCancelar={() => setVisible(false)}
         onClickEnviar={() => {
            if (area.nombre === '') {
               setShowError(true);
            } else {
               handleagregarAreasgenerales(area);
               setVisible(false);
               setArea({
                  nombre: "",
                  parent_id: null
               });
               setShowError(false);
            }
         }}
         label={'Crear'} />
   );

   const actionTemplate = (rowData) => {
      return (
         <ActionTemplate
            rowData={rowData}
            setVisible2={setVisible2}
            setVisible3={setVisible3}
            setvisibleEdAr={setvisibleEdAr}
            setRowData={setRowData}
         />
      );
   };

   //Modal crear sub área
   const subAreaHeader = (
      <Header
         icono={Icon}
         titulo={'Crear nueva sub área'}
         subtitulo={'En esta sección usted puede registrar las sub áreas que necesite áreas generales'} />
   )

   const subAreaFooter = (
      <Footer
         onClickCancelar={() => {
            setVisible2(false);
            setArea({
               nombre: "",
               parent_id: null,
            });
         }}
         onClickEnviar={() => {
            //Verifica si el nombre de la sub área está vacío
            if (area.nombre === '') {
               //Actualiza el estado de la variable showError
               setShowError(true);
            } else {
               //Agrega la sub área
               handleagregarAreasgenerales(area);
               //Actualiza el estado de la variable visible2
               setVisible2(false);
               //Actualiza el estado del nombre de la sub área
               setArea({
                  nombre: "",
                  parent_id: null,
               });
               //Actualiza el estado de la variable showError
               setShowError(false);
            }
         }}
         label={'Crear'} />
   )

   const header = (
      <div className="flex flex-column">
         <div className="flex align-items-baseline justify-content-between">
            <div className="inline-flex gap-2"></div>
            <div className="inline-flex gap-2">
               <Button
                  label="Agregar nueva área "
                  icon="pi pi-plus text-indigo-400 text-xl hover:text-white"
                  className="bg-white text-indigo-400 hover:bg-primary"
                  onClick={() => setVisible(true)}
               />
               <Dialog
                  style={{ width: "413px", height: "320px" }}
                  visible={visible}
                  onHide={() => setVisible(false)}
                  header={dialogTitle}
                  footer={dialogFooterCreate}
               >
                  <div className="flex flex-column gap-5 ">
                     <div className=" flex flex-column gap-2">
                        <div className="">
                           <p className="text-700">Área</p>
                        </div>
                        <div className="">
                           <InputText
                              defaultValue={area.nombre}
                              className="w-full "
                              onChange={(e) => handleChangeNombre(e)}
                           />
                           {showError && area.nombre === '' && <small className="text-red-500">Ingrese un nombre</small>}
                        </div>
                     </div>
                  </div>
               </Dialog>
               <Dialog
                  header={subAreaHeader}
                  footer={subAreaFooter}
                  style={{ width: "413px" }}
                  visible={visible2}
                  onHide={() => setVisible2(false)}
               >
                  <div className="flex flex-column gap-5 ">
                     <div className=" flex flex-column gap-2">
                        <div>
                           <p className="text-700">
                              Sub Áreas {rowData?.data?.nombre}
                           </p>
                        </div>
                        <div className=" ">
                           <InputText
                              className="w-full"
                              onChange={(e) => handleChangeNombreSubArea(e)}
                           />
                           {showError && area.nombre === '' && <small className="text-red-500">Ingrese un nombre</small>}
                        </div>
                     </div>
                  </div>
               </Dialog>
               {/* Modal eliminar área */}
               <Dialog
                  header={dialogHeader}
                  footer={dialogFooter}
                  resizable={false}
                  visible={visible3}
                  onHide={() => setVisible3(false)}
               >
                  <div>
                     ¿Seguro de que quieres eliminar el área?
                  </div>
               </Dialog>
            </div>
         </div>
      </div>
   );

   //Botón para añadir personal
   const botonagregar = (rowData) => {
      return (
         <Button
            className="btn-personal"
            label={
               <div className="flex align-items-center">
                  <img className="carpeta-white" src={carpetawhite} alt="" />
                  <span className="display-n-m">Agregar personal</span>
               </div>
            }
            onClick={() => {
               //Actualiza el estado de la variable visibleAgPersonal
               setvisibleAgPersonal(true);
               //Actualiza el estado del nombre de la área
               setRowData(rowData);
               //Actualiza el estado de los datos de asignación
               setDataAsignar({
                  bregma_area_id: rowData.data.id,
                  bregma_personales: [],
                  clinica_area_id: rowData.data.id,
                  clinica_personales: [],
                  empresa_area_id: rowData.data.id,
                  empresa_personales: [],
               });
               //Obtiene el personal por área
               handleGetPersonal(rowData.data.id);
            }}
         />
      );
   };

   //Activación de modal para edición del área
   const BotonEditar = () => {
      handleEditArea(rowData?.data?.id, area);
      setvisibleEdAr(false);
   };

   //Activación de modal para eliminación del área
   const BotonEliminar = () => {
      handleDelete(rowData?.data?.id);
      setVisible3(false);
      //Actualiza el estado del nombre de la área
      setArea({
         nombre: "",
         parent_id: null
      });
   };

   return (
      <div className="bg-white border-round-2xl tabla-areas-treetable-responsive">
         <TreeTable
            value={areas}
            header={header}
            emptyMessage="No se encontraron resultados"
            className="w-full bg-white"
         >
            <Column
               headerClassName="w-m"
               field="nombre"
               header="Nombre"
               expander
            />
            <Column
               headerClassName="w-35-m text-align-center"
               field=""
               body={botonagregar}
               header="Agregar"
               bodyStyle={{ textAlign: 'center' }}
               style={{ textAlign: 'center' }}
            />
            <Column
               header="Acciones"
               body={actionTemplate}
               headerClassName="w-15-m text-align-center"
               style={{ textAlign: 'center' }}
            />
         </TreeTable>

         {/* Modal para agregar personal */}
         <ModalAgregar
            Area={area}
            setvisibleAgPersonal={setvisibleAgPersonal}
            visibleAgPersonal={visibleAgPersonal}
            setSource={setSource}
            source={source}
            target={target}
            setTarget={setTarget}
            agregarPersonal={agregarPersonal}
            dataAsignar={dataAsignar}
            rowData={rowData}
         />

         {/* Modal para editar área */}
         <ModalEditarArea
            Area={area}
            visibleEdAr={visibleEdAr}
            setvisibleEdAr={setvisibleEdAr}
            handleEditArea={handleEditArea}
            rowData={rowData}
            BotonEditar={BotonEditar}
            handleChangeNombre={handleChangeNombre}
         />
      </div>
   );
};
export default TablaAreas;
import { createContext, useState } from "react";
import {
   iconAudiometria,
   iconEkg,
   iconLab,
   iconMedicina,
   iconOft,
   iconPsicologia,
   iconRadiologia,
   iconTriaje,
   iconEspirometria,
   iconOdontologia,
} from "../../../../Services/importIcons";

export const GestionContext = createContext(undefined)

export const GestionAtencionProvider = (props) => {

   const [listRutasContext, setListRutasContext] = useState([
      { id: 0, nombre: "Triaje", icon: iconTriaje },
      { id: 1, nombre: "Psicología", icon: iconPsicologia },
      { id: 2, nombre: "EKG", icon: iconEkg },
      { id: 3, nombre: "Oftalmología", icon: iconOft },
      { id: 4, nombre: "Audiometría", icon: iconAudiometria },
      { id: 5, nombre: "Examen Radiológico", icon: iconRadiologia },
      { id: 6, nombre: "Examen de Laboratorio", icon: iconLab },
      { id: 7, nombre: "Odontología", icon: iconOdontologia },
      { id: 8, nombre: "Espirometría", icon: iconEspirometria },
      { id: 9, nombre: "Medicina General", icon: iconMedicina },
   ]);
   const [localesContext, setLocalesContext] = useState([]);
   const [atencionContext, setAtencionContext] = useState(null);
   const [pacientes, setPacientes] = useState([]);
   const [pacienteContext, setPacienteContext] = useState(null);


   return (
      <GestionContext.Provider
         value={{
            localesContext, setLocalesContext,
            pacientes, setPacientes,
            listRutasContext, setListRutasContext,
            atencionContext, setAtencionContext,
            pacienteContext, setPacienteContext,
         }}
      >
         {props.children}
      </GestionContext.Provider>
   );
}

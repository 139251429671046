import React, { useState } from "react";
import "../../../Style/TablaExamen.css";
import { InputTextarea } from 'primereact/inputtextarea';


export default function TablaComponent(props) {
    const [value, setValue] = useState('');

    return (
        //Contenedor
        <div className="flex w-full">
            {/* Columna Vertebral */}
            <div className="flex w-full flex-column">
                <div className="contenedor flex flex-column w-full" >
                    <table className="excel-table tablita">
                        <thead>
                            <tr >
                                <th>{props.column1patologia}</th>
                                <th>{props.column2patologia}</th>
                                <th>{props.column3patologia}</th>
                                <th>{props.column4patologia}</th>
                                <th>{props.column5patologia}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{props.fila1patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[0]?.["resultado"]} onChange={(e) => { props.handleChange(0, e.target.value) }}>
                                        <option value="1">Amarillo</option>
                                        <option value="2">Amarillo Claro</option>
                                        <option value="3">Amarillo Oscuro</option>
                                        <option value="4">Ambar</option>
                                        <option value="5">Anaranjado</option>
                                        <option value="6">Marrón</option>
                                        <option value="7">Pardo</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila2patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[1]?.["resultado"]} onChange={(e) => { props.handleChange(1, e.target.value) }}>
                                        <option value="1">Ligeramente Turbio</option>
                                        <option value="2">Transparente</option>
                                        <option value="3">Turbio</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila3patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[2]?.["resultado"]} onChange={(e) => { props.handleChange(2, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="4">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila4patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[3]?.["resultado"]} onChange={(e) => { props.handleChange(3, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="Positivo+++">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila5patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[4]?.["resultado"]} onChange={(e) => { props.handleChange(4, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="4">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila6patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[5]?.["resultado"]} onChange={(e) => { props.handleChange(5, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="4">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila7patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[6]?.["resultado"]} onChange={(e) => { props.handleChange(6, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="4">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila8patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[7]?.["resultado"]} onChange={(e) => { props.handleChange(7, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="4">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila9patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[8]?.["resultado"]} onChange={(e) => { props.handleChange(8, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila10patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[9]?.["resultado"]} onChange={(e) => { props.handleChange(9, e.target.value) }}>
                                        <option value="1">Negativo</option>
                                        <option value="2">Positivo+</option>
                                        <option value="3">Positivo++</option>
                                        <option value="4">Positivo+++</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila11patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[10]?.["resultado"]} onChange={(e) => { props.handleChange(10, e.target.value) }}>
                                        <option value="1">Abundantes</option>
                                        <option value="2">Escasos</option>
                                        <option value="3">Regular cantidad</option>
                                        <option value="4">No se observan</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila12patologia}</td>
                                <td>
                                    <input type="text" className="excel-mention" style={{ background: 'white' }} value={props?.valueParametros?.[11]?.["resultado"]} onChange={(e) => { props.handleChange(11, e.target.value) }} />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila13patologia}</td>
                                <td>
                                    <select className="excel-dropdown" style={{ background: 'white' }} value={props?.valueParametros?.[12]?.["resultado"]} onChange={(e) => { props.handleChange(12, e.target.value) }}>
                                        <option value="1">Abundantes</option>
                                        <option value="2">Escasos</option>
                                        <option value="3">Regular cantidad</option>
                                        <option value="4">No se observan</option>
                                    </select>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila14patologia}</td>
                                <td>
                                    <input type="text" className="excel-mention" style={{ background: 'white' }} value={props?.valueParametros?.[13]?.["resultado"]} onChange={(e) => { props.handleChange(13, e.target.value) }} />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila15patologia}</td>
                                <td>
                                    <input type="text" className="excel-mention" style={{ background: 'white' }} value={props?.valueParametros?.[14]?.["resultado"]} onChange={(e) => { props.handleChange(14, e.target.value) }} />
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>{props.fila16patologia}</td>
                                <td>
                                    <input type="text" className="excel-mention" style={{ background: 'white' }} value={props?.valueParametros?.[15]?.["resultado"]} onChange={(e) => { props.handleChange(15, e.target.value) }} />
                                </td>
                                <td className="text-center">
                                    <label htmlFor="">g/1</label>
                                </td>
                                <td>
                                    <div className="flex w-full justify-content-around">
                                        <label htmlFor="">1.01</label>
                                        <label htmlFor="">1.04</label>
                                    </div>
                                </td>
                                <td className="text-center">
                                    <label htmlFor="">1.010 - 1.040</label>
                                </td>
                            </tr>
                        </tbody>
                        
                    </table>

                </div>
                <div className="flex flex-column gap-3 w-full" style={{ marginTop: '20px',width:"100%" }}>
                            <div className="flex flex-column gap-3 w-full p-2" >
                                <span>Observaciones:</span>
                                <InputTextarea style={{width:"100%!important "}} value={props?.valueParametros?.[16]?.["observaciones"]} onChange={(e) => { props.handleChange(16, e.target.value, 2) }} rows={3} className="" />
                            </div>
                            <div className="flex flex-row gap-3 p-2" style={{ alignItems: 'center' }} >
                                <p>Conclusión medica</p>
                                <select className="excel-dropdown" style={{ background: 'white', border: '0.6px solid #C3C4E7', width: "130px", height: '32px', borderRadius: '7px' }} value={props?.valueParametros?.[16]?.["conclusion"]}
                                    onChange={(e) => { props.handleChange(16, e.target.value,3) }}>
                                    <option value="1">Anormal</option>
                                    <option value="2">Normal</option>
                                </select>
                            </div>
                        </div>
                {/* <div className="flex flex-column gap-3" style={{ marginTop: '20px' }}>
                    <div className="flex flex-wrap gap-3" style={{ alignItems: 'center' }}>
                        <span>Observaciones:</span>
                        <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} rows={2} className="w-full" />
                    </div>
                    <div className="flex flex-row gap-3" style={{ alignItems: 'center' }}>
                        <p>Conclusión medica</p>
                        <select className="excel-dropdown" style={{ background: 'white', border: '0.6px solid #C3C4E7', width: "130px", height: '32px', borderRadius: '7px' }}>
                            <option value="Negativo">Anormal</option>
                            <option value="Positivo">Normal</option>
                        </select>
                    </div>
                </div> */}
            </div>
        </div>
    );
}


// <tr>
//                                 <td>{props.fila17patologia}</td>
//                                 <td>
//                                     <input type="text" className="excel-mention" style={{ background: 'white' }} />
//                                 </td>
//                                 <td></td>
//                                 <td></td>
//                                 <td></td>
//                             </tr>
//                             <tr>
//                                 <td className="font-bold">{props.fila18patologia}</td>
//                                 <td>
//                                     <select className="excel-dropdown" style={{ background: 'white' }}>
//                                         <option value="Negativo">Anormal</option>
//                                         <option value="Positivo">Normal</option>
//                                     </select>
//                                 </td>
//                                 <td></td>
//                                 <td></td>
//                                 <td></td>
//                             </tr>
import React, { useState, useEffect, useRef } from "react";
import { RadioButton } from 'primereact/radiobutton';
import './MusculoEsqueletico.css'

function MusculoEsqueleticoSelect(props) {
    const Agregar_Clase =(id_componente) =>{
        const elementosConClase = document.querySelectorAll(`.${props.class_C}`);

        elementosConClase.forEach(elemento => {
        elemento.classList.remove('Clase_Seleccion_Mus_Esquel');
        });

        const elemento = document.getElementById(id_componente);
        elemento.classList.add("Clase_Seleccion_Mus_Esquel");
    }
    useEffect(() => {
        if (props.ComandoValue === props.ValueName) {
          Agregar_Clase(props.id_C);
        }
      }, [props.ComandoValue, props.ValueName, props.id_C]);
      
    return (
        <div className="flex flex-column flex-wrap align-items-center justify-content-between Mus_Esquel_Contet_select " 
        style={{ width:'23%'}}
        onClick={() => {
            props.ComandoSet(props.ValueName);
            props.ComandoUpdate(props.ValueName);
            Agregar_Clase(props.id_C);
        }}>
            <RadioButton
                name="pizza"
                value={props.ValueName}
                onChange={(e) => {
                    props.ComandoSet(e.value);
                    props.ComandoUpdate(e.value);
                    Agregar_Clase(props.id_C);
                  }}
                checked={props.ComandoValue === props.ValueName}
                disabled={props.ValorDisabled}                
                />          
            <div className={`w-full flex flex-column align-items-center justify-content-center sub_Mus_Esquel_Contet_select_one ${props.class_C}`} id={props.id_C}>
                <img
                    src={props.imagen}
                    alt="" style={{ width: "100px", height: "130px" }}/>
                <p
                    className="flex justify-content-center"
                    style={{
                        background: "#EBF0FF",
                        borderRadius: "0px 0px 8.56164px 8.56164px",
                        color: "#5555D8",
                        height: "29.11px",
                        width: "100%",
                    }}
                >
                    {props.NameSelect}
                </p>
            </div>
        </div>
    )
}
export default MusculoEsqueleticoSelect;
import React from 'react'
import { Dialog } from 'primereact/dialog'
import { Tag } from 'primereact/tag';

import { Header } from '../../../Pure/SeccionesNavegacion';

import HojaRutaDetalleComponent from './pure/HojaRutaDetalle';

import hojaRuta from '../images/hoja-ruta.svg';

export default function HojaRutaComponent({ personal, visible, setVisible }) {

   const header = (<Header icono={hojaRuta} titulo={'Hoja de Ruta'} subtitulo={'Aquí usted podrá visualizar el proceso de examen del paciente.'} />)

   const buttonDetails = (rowData) => {
      switch (rowData) {
         case "0":
            return <Tag value="Programado"></Tag>;
         case "1":
            return <Tag severity="warning" value="En atención"></Tag>;
         case "2":
            return <Tag severity="success" value="Atendido"></Tag>;
         case "3":
            return <Tag value="New"></Tag>;
         default:
            return <Tag value="No programado"></Tag>;
      }
   };

   return (
      <div>
         <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
         >
            <div>
               <div className='cptraz' style={{ height: '75vh', overflowY: 'hidden' }}>
                  <div className='body-cptraz' style={{ height: '60vh' }}>
                     <div className='cptraz-1'>
                        <div className='user-cptraz flex flex-column align-items-center'>
                           <h2>Detalle de paciente</h2>
                           <div className='user-cptraz-1'>
                              <img alt="Descripción de la imagen" />
                              <div className='user-cptraz-12'>
                                 <h3>{personal.nombres} {personal.apellido_paterno} {personal.apellido_materno}</h3>
                                 <div >
                                    {buttonDetails(personal.estado_paciente)}
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className='datos-cptraz'>
                           <div class='info-cptraz'>
                              <p>DNI: </p>
                              <p>{personal?.numero_documento}</p>
                           </div>
                           <div class='info-cptraz'>
                              <p>Correo:</p>
                              <p>{personal?.correo}</p>
                           </div>
                           {/* <div class='info-cptraz'>
                              <p>Subárea:</p>
                              <p>Sistemas</p>
                           </div>
                           <div class='info-cptraz'>
                              <p>Tipo de examen:</p>
                              <p>EMO - Periódico</p>
                           </div> */}
                        </div>
                     </div>
                     <svg className="svg-medio-cptraz" xmlns="http://www.w3.org/2000/svg" width="2" viewBox="0 0 2 717" fill="none">
                        <path d="M1 0C1 12.2788 1 717 1 717" stroke="#333333" stroke-opacity="1.8" stroke-width="0.7" stroke-dasharray="8 5" />
                     </svg>
                     <div style={{ overflowX: 'hidden', width: '100%' }}>
                        <div className='cptraz-2' style={{ height: '1250px' }}>
                           {
                              personal.paciente_id &&
                              personal.hoja_ruta.map(servicio => {
                                 return (
                                    <HojaRutaDetalleComponent data={servicio} />
                                 )
                              })
                           }

                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </Dialog>
      </div>
   )
}

import React from 'react';
import { Button } from 'primereact/button';
import "../../../Style/Reclutamiento/CSSCard.css";

export default function BotonEstado(props) {

    function getBackgroundColor(estado) {
        if (estado === 'Plan gen plus') {
            return '#FFECD7';
        } else if (estado === 'Plan gen prime') {
            return '#e4c1f5';
        } else if (estado === 'Plan gen ultra') {
            return '#d7effa';
        } else if (estado === 'Plan gen familia') {
            return '#BBF6DD';    
        } else {
            return 'white';
        }
    }

    function getBackgroundColorpunto(estado) {
        if (estado === 'Plan gen plus') {
            return '#FF9F38';
        } else if (estado === 'Plan gen prime') {
            return '#500373';
        } else if (estado === 'Plan gen ultra') {
            return '#38c0ff';
        } else if (estado === 'Plan gen familia') {
            return '#00A15C'; 
        } else {
            return 'white';
        }
    }

    function getColor(estado) {
        if (estado === 'Plan gen plus') {
            return '#FF9F38';
        } else if (estado === 'Plan gen prime') {
            return '#500373';
        } else if (estado === 'Plan gen ultra') {
            return '#38c0ff';
        } else if (estado === 'Plan gen familia') {
            return '#00A15C'; 
        } else {
            return 'white';
        }
    }

    return (
        <div className="flex justify-content-center">
            <button className='BoTONEstaDO flex flex-row justify-content-center align-items-center gap-2' style={{ background: getBackgroundColor(props.estado), borderRadius: '8px',width:"140px" }}>
                <div style={{ borderRadius: '50%', background: getBackgroundColorpunto(props.estado), width: '11px', height: '11px' }} />
                <span style={{ color: getColor(props.estado), fontWeight: '400', fontSize: '14px' }}>{props.estado}</span>
            </button>
        </div>

    )
}
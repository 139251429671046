import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
}from "chart.js"

import React, {useState, useEffect} from "react";
import axios from "axios";
import { AutoFixHigh } from "@mui/icons-material";
import { Chart } from "primereact/chart";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

//datos

const Consulta = [2, 6, 9, 2, 6];
const ConsultaCerrada = [5, 8, 10, 3, 7];
const pacientes = ["Paciente1", "Paciente2", "Paciente3", "Paciente4", "Paciente5"]
const midata = {
    labels: pacientes, //se cambia
    datasets: [ //cada linea del gráfico
        {
            label: "Consulta", //
            data: Consulta, //se cambia
            tension: 0.5,
            fill: true,
            borderColor: "rgba(255, 99, 132)",
            backgroundColor: "rgba(86, 164, 202, 0.5)",
            pointRadius: 5,
            pointBorderColor: "rgba(255, 99, 132)",
            pointBackgroundColor: "rgba(255, 99, 132)",
        },
        {
            label: "ConsultaCerrada",
            data: ConsultaCerrada,
            tension: 0.5,
            fill: true,
            borderColor: "rgba(255, 99, 132)",
            backgroundColor: "rgba(10, 209, 144, 0.5)",
            pointRadius: 5,
            pointBorderColor: "rgba(255, 99, 132)",
            pointBackgroundColor: "rgba(255, 99, 132)",
        },
        
    ],
};
//básicamente son optiones para editar el gráfico
const misoptions = {
    scales: {
        y : {
            min: 1
        },
        x : {
            /*ticks: {color: ""}*/
        }
    },
    plugins: {
        legend: {
            display: true,
        }
    }
}

{/*

const peticionApi=async()=>{
    await axios.get("")
    .then(response=>{
        var respuesta=response.data;
        var auxNombre=[], auxNombre2=[];
        respuesta.map(elemento=>{
            auxNombre.push(elemento."");
            auxNombre2.push(elemento."");
        });
        setNombre(auxNombre);
        setNombre(auxNombre2);
    })
}

useEffect(()=>{
    peticionApi();
},[])

*/ }

export default function LineChart(){
    return <Chart 
                data={midata} 
                options={misoptions}
                >
            </Chart>
}



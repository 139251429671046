import React, { useState, useRef, useContext, useEffect } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import "../../Style/Radiologia/Radiologia.css";
import { RadContext } from "../../Page/Radiologia/RadProvider";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
//import OpcRayosX from "../Radiologia/OpcRayosX";
import { Accordion, AccordionTab } from "primereact/accordion";
import GroupDemo from "../../Page/Radiologia/RadioContext";
// import Voicet from "../../ButtonVoice/VoiceRa";
import ColumnRT from "../../Components/Radiologia/columna/Column";
import OpcRayosXTR from "../../Components/Radiologia/columna/OpcRayosXTorax";
import VoiceRadiologia from "../../Components/ButtonVoice/VoiceRadiologia";


export default function RayosX() {
   let { RayTorax, setRayTorax } = useContext(RadContext);

   const [selectedImage, setSelectedImage] = useState(RayTorax.lectura_oit.imagen ? RayTorax.lectura_oit.imagen : null);
   useEffect(() => {
      setSelectedImage(RayTorax.lectura_oit.imagen ? RayTorax.lectura_oit.imagen : null)
   }, [RayTorax])

   useEffect(() => {
      if (typeof RayTorax.lectura_oit.imagen != "string") {
         var file = RayTorax.lectura_oit.imagen;
         if (file) {
            const reader = new FileReader();

            reader.onload = () => {
               setSelectedImage(reader.result);
               setShowImage(true);
               setimagenFijavisual(reader.result);

            };

            reader.readAsDataURL(file);
         }
      }

   }, []);

   const [showImage, setShowImage] = useState(RayTorax.lectura_oit.imagen ? true : false);
   useEffect(() => {
      setShowImage(RayTorax.lectura_oit.imagen ? true : false)
   }, [RayTorax])

   const inputRef = useRef(null);

   const [imagenFijavisual, setimagenFijavisual] = useState();

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();

         reader.onload = () => {
            setSelectedImage(reader.result);
            setShowImage(true);

            setimagenFijavisual(reader.result);

            setRayTorax({
               ...RayTorax,
               lectura_oit: {
                  ...RayTorax.lectura_oit,
                  imagen: file,
               },
            });
         };

         reader.readAsDataURL(file);
      }
   };

   const handleSelectImage = () => {
      inputRef.current.click();
   };

   const handleToggleImage = () => {
      setShowImage(!showImage);
   };

   const eliminarImge = () => {
      setShowImage(null);
      setSelectedImage(null);
      setRayTorax((RayTorax) => ({
         ...RayTorax,
         lectura_oit: {
            ...RayTorax.lectura_oit,
            imagen: null,
         },
      }));
   };











   const subTitulo = {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: "600",
   };
   const actualizarObservaciones = (nuevasObservaciones) => {
      setRayTorax(prevState => ({
         ...prevState,
         observaciones: nuevasObservaciones
      }));
   };
   return (
      <div className="flex">
         {/* {/ seis contenedores /} */}
         <div style={{ width: "98%" }}>
            <Accordion multiple activeIndex={[0]}>
               <AccordionTab header="Radiografia">
                  <style>
                     {
                        `
                        @media(max-width: 733px){
                           .RadiografiaContentPrincipalAHP{
                              flex-direction: column !important;
                           }
                           .RadiografiaContentPrincipalAHP01{
                              width: 100% !important;
                           }
                           .RadiografiaContentPrincipalAHP02{
                              width: 100% !important;
                           }
                        }
                        @media(max-width: 490px){
                           .CuadroDropInputOpcRayosTorax{
                              flex-direction: column !important;
                           }
                           .CuadroDropInputOpcRayosToraxDrop{
                              width: 100% !important;
                           }
                           .CuadroDropInputOpcRayosToraxInput{
                              width: 100% !important;
                           }
                        }
                        `
                     }
                  </style>
                  <div className="RadiografiaContentPrincipalAHP flex gap-3">
                     <div className="RadiografiaContentPrincipalAHP01 w-6 flex flex-column gap-1">
                        <OpcRayosXTR titulo="Vértices" idRayo={1} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <OpcRayosXTR titulo="Campos pulmonales" idRayo={2} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <OpcRayosXTR titulo="Hilios" idRayo={3} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <OpcRayosXTR titulo="Senos" idRayo={4} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <OpcRayosXTR titulo="Mediastinos" idRayo={5} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <OpcRayosXTR titulo="Silueta cardiovascular" idRayo={6} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <OpcRayosXTR titulo="Conclusión radiográfica" idRayo={7} RayTorax={RayTorax} setRayTorax={setRayTorax} />
                        <div className="w-full h-auto flex flex-column gap-2" >
                           <h4 className="w-full" style={subTitulo}>Observaciones:</h4>
                           <div className="ObservacionesRadiografiaVoiceButAHP">
                              <style>
                                 {`
                                    .ObservacionesRadiografiaVoiceButAHP textarea{
                                       height: 100px ;
                                    }
                                 `}
                              </style>
                              <VoiceRadiologia
                                 value={RayTorax?.observaciones}
                                 onChange={(e) => actualizarObservaciones(e)} />
                              {/* <InputTextarea
                                 id="descripcion_ob"
                                 name="descripcion_ob"
                                 autoResize value={TextAreaObserva} onChange={(e) => setTextAreaObserva(e.target.value)}
                                 className="w-full" style={{height:"96px"}}
                              /> */}
                           </div>
                        </div>
                     </div>
                     {/* {/ imagen /} */}
                     <div className="RadiografiaContentPrincipalAHP02 Imgradio flex-1 w-6 sm:w-1/2">
                        <div className=" flex flex-1 flex-wrap flex-column gap-3">
                           <div className="Contenedor-imagen flex flex-1 flex-wrap flex-column gap-3">
                              <div
                                 className="image-container"
                                 style={{ marginLeft: "0px", marginTop: "0px" }}
                              >
                                 {showImage && selectedImage && (
                                    <div className="w-full p-2" style={{ height: '400px' }}>
                                       <img
                                          style={{ objectFit: 'contain' }}
                                          src={imagenFijavisual ? imagenFijavisual : `${(RayTorax.lectura_oit.imagen)}`}
                                          alt="Selected"
                                          className="selected-image w-full h-full imagenGrayAdoHp"
                                       />
                                       <style>
                                          {`
                                          .imagenGrayAdoHp {
                                             filter: grayscale(100%) !important;
                                          }
                                          `}
                                       </style>
                                    </div>
                                 )}
                                 {!selectedImage && (
                                    <div className="select-text flex flex-column">
                                       <i className="pi pi-image imd" />
                                       Imagen no disponible
                                    </div>
                                 )}
                                 {selectedImage && !showImage && (
                                    <div className="select-text flex flex-column">
                                       <i className="pi pi-eye-slash imd" />
                                       Imagen ocultada
                                    </div>
                                 )}
                              </div>
                           </div>
                           <style>
                              {`
                                 @media(max-width: 403px){
                                    .button-container123{
                                       flex-direction: column !important;
                                    }
                                    .button-container123 button{
                                       width: 100% !important;
                                    }
                                 }
                              `}
                           </style>
                           <div className="button-container123 gap-2">
                              <input
                                 id="file-input"
                                 type="file"
                                 accept="image/*"
                                 onChange={handleImageChange}
                                 ref={inputRef ? inputRef : RayTorax.lectura_oit.imagen}
                                 style={{ display: "none" }}
                              />
                              <Button
                                 className="w-6rem flex align-items-center justify-content-center"
                                 style={{ backgroundColor: '#BFF1DF', borderColor: '#248D63', color: '#248D63' }}
                                 onClick={handleSelectImage}
                              >
                                 Agregar
                              </Button>
                              {selectedImage && (
                                 <Button
                                    className="w-6rem flex align-items-center justify-content-center"
                                    style={{ backgroundColor: '#E0F5FD', borderColor: '#64CBF4', color: '#64CBF4' }}
                                    onClick={handleToggleImage}
                                 >
                                    {showImage ? "Ocultar" : "Visualizar"}
                                 </Button>
                              )}
                              {selectedImage && showImage && (
                                 <Button className="w-4rem flex align-items-center justify-content-center"
                                    style={{ backgroundColor: '#FF6767', borderColor: 'red' }}
                                    onClick={() => { eliminarImge() }}>
                                    <i className="pi pi-trash" style={{ fontSize: '23px' }}></i>
                                 </Button>
                              )}
                              {selectedImage && !showImage && (
                                 <Button className="w-4rem flex align-items-center justify-content-center"
                                    style={{ backgroundColor: '#FF6767', borderColor: 'red' }}
                                    onClick={() => { eliminarImge() }}>
                                    <i className="pi pi-trash" style={{ fontSize: '23px' }}></i>
                                 </Button>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </AccordionTab>
               <AccordionTab header="Informe OIT">
                  <GroupDemo />
               </AccordionTab>
               {/* <AccordionTab header="Columna">
                  <ColumnRT />
               </AccordionTab> */}
            </Accordion>
         </div>
      </div>
   );
}


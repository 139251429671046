import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

const Botones = (props) => {
  const [showAdditionalDiv, setShowAdditionalDiv] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowAdditionalDiv(screenWidth <= 1450);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {

    const screenWidth = window.innerWidth;
    setShowAdditionalDiv(screenWidth <= 1450);
  }, [props.Link]);

  return (
    <div key={props?.bkey} className={showAdditionalDiv ? `justify-content-center text-center align-items-center pb-3 ${props.hidden ? 'hidden' : ''}` : '' }>
      <Link to={props.Link} className="no-underline">
        <button onClick={props.onclick} 
        // style={{ width: showAdditionalDiv ? "70%" : '', border: showAdditionalDiv ? "none" : '' }} className={showAdditionalDiv ? "justify-content-center text-center align-items-center" : ''} >
        className={`${showAdditionalDiv ? "justify-content-center text-center align-items-center btn-modified" : ''}`} style={{border:"none"}} >

          <div>
            {showAdditionalDiv ? (
              <div className="busedosbtn text-start">
                <div ref={props.buttonRef}  style={{display:"flex", justifyContent:"center", alignItems: "center"}}>
                  <img src={props.img} className="seconbutname" alt="" />
                  <span  className="pl-3">{props.span}</span>
                </div>
              </div>
            ) : (
              <div className="buts" >
                <div className={props.button} ref={props.buttonRef}>
                  <img src={props.img} className="icono" alt=""></img>
                  <span>{props.span}</span>
                </div>
              </div>
            )}
          </div>
        </button>
      </Link>

    </div>
  );
};

export default Botones;
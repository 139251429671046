import React, { useState, useEffect } from "react";
import Chica from "../../../Images/Pure/MenuPrincipal/Img/Chica.png";
import Sombra from "../../../Images/Pure/MenuPrincipal/Img/sombra.png";

const Imagenes = (props) => {
  const [showImage, setShowImage] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShowImage(screenWidth > 750);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 750) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      {showImage && (
        <img src={props.src} className={props.className} alt="log" />
      )}
    </div>
  );
};

export default Imagenes;

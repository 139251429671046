import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import SlideEmpresa from './Slide';
import UpdateEmpresaIMGComponent from "./UpdateEmpresaIMGComponent";

//Importación de imágenes
import AvatarMap from "../../../../assets/36.png"
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

import icono from "../../../../Images/Perfil/icon/ActualizarUsuario.svg";

//Importación de estilos únicos
import '../../../../Style/PagePerfilGeneral/USU-0/Empresa.css'

export default function EmpresaComponent(props) {
   const [visible, setVisible] = useState(false);
   const [visibleImage, setVisibleImage] = useState(false);

   /*CONVERTIR DE MAYUSCULA A MINUSCULA */
   const editText = (text, separador) => {
      if (text) {
         const palabras = text.split(" ")
         for (let i = 0; i < palabras.length; i++) {
            palabras[i] = (palabras[i][0]?.charAt(0).toUpperCase() || '') + palabras[i].substr(1).toLowerCase();
         }
         return (text ? palabras.join(" ") + (separador || '') : '');
      } else {
         return ''
      }
      /*  return (text?text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()+(separador||''):''); */
   }

   const handleChangeCelular = (e) => {
      props.setBregma({ ...props.data, celular: e.target.value })
   }

   const handleChangeCorreo = (e) => {
      props.setBregma({ ...props.data, correo: e.target.value })
   }

   const handleChangeDireccion = (e) => {
      props.setBregma({ ...props.data, direccion: e.target.value })
   }

   const handleChangeRazonSocial = (e) => {
      props.setBregma({ ...props.data, razon_social: e.target.value })
   }

   const handleChangeWhatsApp = (e) => {
      props.setBregma({ ...props.data, whatsapp: e.target.value });
   }

   const handleChangeSitioWeb = (e) => {
      props.setBregma({ ...props.data, sitio_web: e.target.value });
   }


   const header = (
      <Header icono={icono} titulo={"Datos de la empresa"} subtitulo={"Complete la siguiente información sobre la empresa"} />
   )

   const footer = (
      <Footer type={"button"}
         onClickCancelar={() => setVisible(false)}
         onClickEnviar={() => {
            props.handleSubmitUpdate(props.data.id, props.data);
            setVisible(false)
         }}
         label={"Actualizar"} />
   )

   return (
      <div className="CONTENEODRENTERO_EMPRE">
         {/* Img de usuario */}
         <div className='POSICIONAMIENTO_IMAGEN' style={{ width: 'auto', height: 'auto' }}>
            <img
               src={props.dataShow?.logo ?? AvatarMap}
               alt="user"
               className='IMGDEUSUARIO' />
            <Button icon="pi pi-pencil" onClick={() => setVisibleImage(true)} className="BOTONEDITARIMGUSARIO flex flex-row" disabled={props.dataPersona?.roles?.[0]?.nombre === 'Administrador Clinica'} />
         </div>
         <div className="CONTENEDOR_GENERAL_EMPRE">
            <div className='CONTENEDOR_BOTON_EDITAR_EMPRE'  >
               {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
               <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{ margin: '0' }} disabled={props.dataPersona?.roles?.[0]?.nombre === 'Administrador Clinica'}>
                  <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                  <p className='TEXTOAGREGARUSUARIO'>Editar</p>
               </Button>
            </div>
            <div className="CONTENEDOR_DATA_GENERAL_EMPRE">
               {/* Datos y botones */}

               <div className='CONTENEDOR_UP_AHP_EMPRE'>
                  <div className="CONTENEDOR_DATA_EMPRE_TITU">
                     <label className='TITULO_CONT_UP' htmlFor="">Datos de la {props.entidad}</label>
                     <label className='TITULO_CONT_UP_020' htmlFor="">
                        {editText(props.dataShow?.distrito?.provincia?.departamento?.departamento, '-') +
                           editText(props.dataShow?.distrito?.provincia?.provincia, '-') +
                           editText(props.dataShow?.distrito?.distrito) || 'No hay departamento'}
                     </label>
                  </div>
                  <div className="CONTENEDOR_DATA_EMPRE_TITU">
                     {/* AV. San Borja Norte NRO. 772 URB. San Borja */}
                     <h6 className="SUBTITULO_CONT_DOWN_EMPRE">{props.dataShow?.direccion || 'Sin dirección'}</h6>

                     <h6 className="SUBTITULO_CONT_DOWN_EMPRE">
                        {props.persona?.correo ?? "No existente"}
                     </h6>
                  </div>
               </div>

               {/* Datos del usuario parte inferior */}
               <div className="CONTENEDOR_DOWN_AHP_EMPRE">
                  <SlideEmpresa
                     dataEx={props.dataShow} />
               </div>

            </div>
            {/* Cuestionario emergente */}
         </div>
         <Dialog
            header={header}
            footer={footer}

            visible={visible} className="form0-container" style={{ width: '45vw', minWidth: "200px", maxWidth: "446px" }} onHide={() => setVisible(false)}>
            <div className="card">
               <div className="flex flex-column gap-3 mb-4" style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}>

                  <div className="flex-auto">
                     <label htmlFor="integer" className="font-bold block mb-2" style={{ marginTop: "10px" }}>
                        Razón Social
                     </label>
                     <InputText id="name" className="w-full text-area2"
                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={props.data?.razon_social}
                        onChange={(e) => { handleChangeRazonSocial(e) }} />
                  </div>
                  <div className="flex-auto">
                     <label htmlFor="integer" className="font-bold block mb-2">
                        Celular
                     </label>
                     <InputText id="celularE" keyfilter="num" className="w-full text-area2"
                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={props.data?.celular}
                        onChange={(e) => { handleChangeCelular(e) }} />
                  </div>
                  {/* <div className="flex-auto">
                                <label htmlFor="idE" className="font-bold block mb-2 w-full">
                                    Numero de Documento
                                </label>
                                <InputText id="idE" keyfilter="text" className="w-full" defaultValue={bregma?.numero_documento} onChange={(e) => { handleChangeNumeroDocumento(e) }} />
                            </div> */}
                  <div className="flex-auto">
                     <label htmlFor="email" className="font-bold block mb-2">
                        Correo
                     </label>
                     <InputText id="email" keyfilter="email" className="w-full text-area2"
                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={props.data?.correo}
                        onChange={(e) => { handleChangeCorreo(e) }} />
                  </div>

                  <div className="flex-auto">
                     <label htmlFor="whatsapp" className="font-bold block mb-2">
                        WhatsApp
                     </label>
                     <InputText id="whatsapp" keyfilter="num" className="w-full text-area2"
                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={props.data?.whatsapp}
                        onChange={(e) => { handleChangeWhatsApp(e) }} />
                  </div>

                  <div className="flex-auto">
                     <label htmlFor="web" className="font-bold block mb-2">
                        Sitio Web
                     </label>
                     <InputText id="web" className="w-full text-area2"
                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={props.data?.sitio_web}
                        onChange={(e) => { handleChangeSitioWeb(e) }} />
                  </div>

                  <div className="flex-auto">
                     <label htmlFor="integer" className="font-bold block mb-2">
                        Dirección
                     </label>
                     <InputText id="direccion" className="w-full text-area2"
                        style={{ width: "100%", height: "50px", marginTop: "10px", border: "1px solid #ccc", padding: "5px" }}
                        defaultValue={props.data?.direccion}
                        onChange={(e) => { handleChangeDireccion(e) }} />
                  </div>
               </div>
            </div>
         </Dialog>

         <UpdateEmpresaIMGComponent
            getEmpresa={props.getBregma}
            dataShow={props.dataShow}
            setDataShow={props.setDataShow}
            setVisible={setVisibleImage}
            visible={visibleImage}
            showToast={props.showToast}
            api={props.api}
         />
      </div>
   )

}
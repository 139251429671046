import React, { useState, useRef, useContext, useEffect } from "react";
import { Button } from "primereact/button";
import "../../../Style/Radiologia/Radiologia.css";
import { RadContext } from "../../../Page/Radiologia/RadProvider";
import { Accordion, AccordionTab } from "primereact/accordion";
import OpcRayosXTREstandar from "./OpcRayosXToraxEstandar";
import VoiceRadiologia from "../../../Components/ButtonVoice/VoiceRadiologia";
import LecturaOIT_Estandar from './LecturaOIT-Estandar';
import EnvioArchivoRadiologiaCol from './EnvioArchivoRadiologiaCol'

export default function Torax() {
   let { RayXToraxEstandar, setRayXToraxEstandar } = useContext(RadContext);

   const subTitulo = {
      fontFamily: "Montserrat",
      fontSize: "15px",
      fontWeight: "600",
   };

   const [selectedImage, setSelectedImage] = useState(RayXToraxEstandar.lectura_oit.imagen ? RayXToraxEstandar.lectura_oit.imagen : null);

   useEffect(() => {
      if (typeof RayXToraxEstandar.lectura_oit.imagen != "string") {
         var file = RayXToraxEstandar.lectura_oit.imagen;
         if (file) {
            const reader = new FileReader();

            reader.onload = () => {
               setSelectedImage(reader.result);
               setShowImage(true);
               setimagenFijavisual(reader.result);

            };

            reader.readAsDataURL(file);
         }
      }

   }, []);

   const [showImage, setShowImage] = useState(RayXToraxEstandar.lectura_oit.imagen ? true : false);

   const inputRef = useRef(null);

   const [imagenFijavisual, setimagenFijavisual] = useState();

   const handleImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
         const reader = new FileReader();

         reader.onload = () => {
            setSelectedImage(reader.result);
            setShowImage(true);

            setimagenFijavisual(reader.result);

            setRayXToraxEstandar({
               ...RayXToraxEstandar,
               lectura_oit: {
                  ...RayXToraxEstandar.lectura_oit,
                  imagen: file,
               },
            });
         };

         reader.readAsDataURL(file);
      }
   };

   const handleSelectImage = () => {
      inputRef.current.click();
   };

   const handleToggleImage = () => {
      setShowImage(!showImage);
   };

   const eliminarImge = () => {
      setShowImage(null);
      setSelectedImage(null);
      setRayXToraxEstandar((RayXToraxEstandar) => ({
         ...RayXToraxEstandar,
         lectura_oit: {
            ...RayXToraxEstandar.lectura_oit,
            imagen: null,
         },
      }));
   };

   const actualizarObservaciones = (nuevasObservaciones) => {
      setRayXToraxEstandar(prevState => ({
         ...prevState,
         observaciones: nuevasObservaciones
      }));
   };

   const actualizarDatosColumna = (propiedad, nuevasObservaciones) => {
      setRayXToraxEstandar(prevState => ({
         ...prevState,
         rad_columna: {
            ...prevState.rad_columna,
            [propiedad]: nuevasObservaciones
         }
      }));
   };

   const [TextAreaObserva, setTextAreaObserva] = useState(RayXToraxEstandar.observaciones ? RayXToraxEstandar.observaciones : null);

   useEffect(() => {
      actualizarObservaciones(TextAreaObserva)
   }, [TextAreaObserva])

   const [TextAreaObserva02, setTextAreaObserva02] = useState(RayXToraxEstandar.rad_columna.observaciones ? RayXToraxEstandar.rad_columna.observaciones : null);

   useEffect(() => {
      actualizarDatosColumna("observaciones", TextAreaObserva02)
   }, [TextAreaObserva02])

   const [TextAreaConclusion, setTextAreaConclusion] = useState(RayXToraxEstandar.rad_columna.conclusiones ? RayXToraxEstandar.rad_columna.conclusiones : null);

   useEffect(() => {
      actualizarDatosColumna("conclusiones", TextAreaConclusion)
   }, [TextAreaConclusion])

   // ARCHIVO
   const [ArchivoRadiologia, setArchivoRadiologia] = useState(RayXToraxEstandar.rad_columna.archivo ? RayXToraxEstandar.rad_columna.archivo : null);

   const updateArchivoRadiologia = (nuevoValor) => {
      setRayXToraxEstandar((RayTorax) => ({
         ...RayTorax,
         rad_columna: {
            ...RayTorax.rad_columna,
            archivo: nuevoValor,
         },
      }));
   };

   return (
      <div className="flex">
         {/* {/ seis contenedores /} */}
         <div style={{ width: "98%" }}>
            <Accordion multiple activeIndex={[0]}>
               <AccordionTab header="Radiografia">
                  <style>
                     {`
                     @media(max-width: 733px){
                        .RadiografiaContentPrincipalAHP{
                           flex-direction: column !important;
                        }
                        .RadiografiaContentPrincipalAHP01{
                           width: 100% !important;
                        }
                        .RadiografiaContentPrincipalAHP02{
                           width: 100% !important;
                        }
                     }
                     @media(max-width: 490px){
                        .CuadroDropInputOpcRayosTorax{
                           flex-direction: column !important;
                        }
                        .CuadroDropInputOpcRayosToraxDrop{
                           width: 100% !important;
                        }
                        .CuadroDropInputOpcRayosToraxInput{
                           width: 100% !important;
                        }
                     }
                     `}
                  </style>
                  <div className="RadiografiaContentPrincipalAHP flex gap-3">
                     <div className="RadiografiaContentPrincipalAHP01 w-6 flex flex-column gap-1">
                        <OpcRayosXTREstandar titulo="Vértices" idRayo={1} />
                        <OpcRayosXTREstandar titulo="Campos pulmonales" idRayo={2} />
                        <OpcRayosXTREstandar titulo="Hilios" idRayo={3} />
                        <OpcRayosXTREstandar titulo="Senos" idRayo={4} />
                        <OpcRayosXTREstandar titulo="Mediastinos" idRayo={5} />
                        <OpcRayosXTREstandar titulo="Silueta cardiovascular" idRayo={6} />
                        <OpcRayosXTREstandar titulo="Conclusión radiográfica" idRayo={7} />
                        <div className="w-full h-auto flex flex-column gap-2" >
                           <h4 className="w-full" style={subTitulo}>Observaciones:</h4>
                           <div className="ObservacionesRadiografiaVoiceButAHP">
                              <style>
                                 {`
                                 .ObservacionesRadiografiaVoiceButAHP textarea{
                                    height: 100px ;
                                 }
                                 `}
                              </style>
                              <VoiceRadiologia
                                 value={TextAreaObserva}
                                 onChange={(e) => setTextAreaObserva(e)} />
                           </div>
                        </div>
                     </div>
                     {/* {/ imagen /} */}
                     <div className="RadiografiaContentPrincipalAHP02 Imgradio flex flex-column flex-1 w-6 gap-3">
                        <LecturaOIT_Estandar />
                        <div className=" flex flex-wrap flex-column gap-3">
                           <div className="Contenedor-imagen flex flex-1 flex-wrap flex-column gap-3">
                              <div
                                 className="image-container"
                                 style={{ marginLeft: "0px", marginTop: "0px" }}
                              >
                                 {showImage && selectedImage && (
                                    <div className="w-full p-2" style={{ height: '400px' }}>
                                       <img
                                          style={{ objectFit: 'contain' }}
                                          src={imagenFijavisual ? imagenFijavisual : `${(RayXToraxEstandar.lectura_oit.imagen).replace("radiologia/torax/", "")}`}
                                          alt="Selected"
                                          className="selected-image w-full h-full imagenGrayAdoHp"
                                       />
                                       <style>
                                          {`
                                          .imagenGrayAdoHp {
                                             filter: grayscale(100%) !important;
                                          }
                                          `}
                                       </style>
                                    </div>
                                 )}
                                 {!selectedImage && (
                                    <div className="select-text flex flex-column">
                                       <i className="pi pi-image imd" />
                                       Imagen no disponible
                                    </div>
                                 )}
                                 {selectedImage && !showImage && (
                                    <div className="select-text flex flex-column">
                                       <i className="pi pi-eye-slash imd" />
                                       Imagen ocultada
                                    </div>
                                 )}
                              </div>
                           </div>
                           <style>
                              {`
                                 @media(max-width: 403px){
                                    .button-container123{
                                       flex-direction: column !important;
                                    }
                                    .button-container123 button{
                                       width: 100% !important;
                                    }
                                 }
                              `}
                           </style>
                           <div className="button-container123 gap-2">
                              <input
                                 id="file-input"
                                 type="file"
                                 accept="image/*"
                                 onChange={handleImageChange}
                                 ref={inputRef ? inputRef : RayXToraxEstandar.lectura_oit.imagen}
                                 style={{ display: "none" }}
                              />
                              <Button
                                 className="w-6rem flex align-items-center justify-content-center"
                                 style={{ backgroundColor: '#BFF1DF', borderColor: '#248D63', color: '#248D63' }}
                                 onClick={handleSelectImage}
                              >
                                 Agregar
                              </Button>
                              {selectedImage && (
                                 <Button
                                    className="w-6rem flex align-items-center justify-content-center"
                                    style={{ backgroundColor: '#E0F5FD', borderColor: '#64CBF4', color: '#64CBF4' }}
                                    onClick={handleToggleImage}
                                 >
                                    {showImage ? "Ocultar" : "Visualizar"}
                                 </Button>
                              )}
                              {selectedImage && showImage && (
                                 <Button className="w-4rem flex align-items-center justify-content-center"
                                    style={{ backgroundColor: '#FF6767', borderColor: 'red' }}
                                    onClick={() => { eliminarImge() }}>
                                    <i className="pi pi-trash" style={{ fontSize: '23px' }}></i>
                                 </Button>
                              )}
                              {selectedImage && !showImage && (
                                 <Button className="w-4rem flex align-items-center justify-content-center"
                                    style={{ backgroundColor: '#FF6767', borderColor: 'red' }}
                                    onClick={() => { eliminarImge() }}>
                                    <i className="pi pi-trash" style={{ fontSize: '23px' }}></i>
                                 </Button>
                              )}
                           </div>
                        </div>
                     </div>
                  </div>
               </AccordionTab>

               {/* <AccordionTab header="Informe OIT">
                  <GroupDemo />
               </AccordionTab> */}
               <AccordionTab header="Columna">
                  <div className="w-full h-auto flex flex-column gap-2" >
                     <div className="w-full h-auto flex flex-column gap-2" >
                        <h4 className="w-full" style={subTitulo}>Observaciones:</h4>
                        <div className="ObservacionesRadiografiaVoiceButAHP">
                           <style>
                              {`
                              .ObservacionesRadiografiaVoiceButAHP textarea{
                                 height: 100px ;
                              }
                              `}
                           </style>
                           <VoiceRadiologia
                              value={TextAreaObserva02}
                              onChange={(e) => setTextAreaObserva02(e)} />
                        </div>
                     </div>

                     <div className="w-full h-auto flex flex-column gap-2" >
                        <h4 className="w-full" style={subTitulo}>Conclusiones:</h4>
                        <div className="ObservacionesRadiografiaVoiceButAHP">
                           <style>
                              {`
                              .ObservacionesRadiografiaVoiceButAHP textarea{
                                 height: 100px ;
                              }
                              `}
                           </style>
                           <VoiceRadiologia
                              value={TextAreaConclusion}
                              onChange={(e) => setTextAreaConclusion(e)} />
                        </div>
                     </div>
                     <EnvioArchivoRadiologiaCol
                        FuncionSetEnvio={setArchivoRadiologia}
                        FuncionUpData={updateArchivoRadiologia}
                        ValorSet={ArchivoRadiologia}
                        DataContext={RayXToraxEstandar.rad_columna.archivo} />
                  </div>
               </AccordionTab>
            </Accordion>
         </div>
      </div>
   );
}

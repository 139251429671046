import React, { useRef, useState } from "react";

import 'primeicons/primeicons.css';
import '../PopDetallesDeAtencion.css';
import './StyleClienteClinicaVR.css';
import '../Chip.css';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";


function ClienteClinicaVR(props){

    let colorContentEstado='';
    let colorEstado='';
    if(props.estadoPago==='Por cobrar'){colorEstado='#FF6767'; colorContentEstado='#FFECEC'}
    if(props.estadoPago==='En proceso'){colorEstado='#E38621'; colorContentEstado='#FFECD7'}
    if(props.estadoPago==='Cobrado'){colorEstado='#00A15C'; colorContentEstado='#E5F6EF'}
    let colorContentMetodo='';
    let colorMetodo='';
    if(props.ModoPago==='Crédito'){colorMetodo='#7A7AEE';colorContentMetodo='#EBEBFF'}
    if(props.ModoPago==='Efectivo'){colorMetodo='#0581AC';colorContentMetodo='#E0F5FD'}

    const estiloBotones = {width: '171px',height: '44px',borderRadius: '7px', border: '1px solid #D0D5DD', display:'flex', alignItems: 'center', justifyContent:'Center',
fontFamily:'Montserrat', fontSize: '14px', fontWeight:'100', color: '#344054', lineHeight: '20px', marginLeft: '10px'};

    const estiloBotonSelect={
        color:'#FFFFFF'
    }
    
    const [visibled, setVisibled] = useState(false);
    const handleClick = () => {
        setVisibled(true)
    };

    const [visibled_second, setVisibled_second] = useState(false);
    const handleClick_Second = () => {
        setVisibled_second(true)
    };

    const footerContent = (
        <div style={{display:'flex', alignItems: 'center', justifyContent:'flex-end'}}>
            <Button label="Cancelar" onClick={() => setVisibled(false)} className="p-button-text" style={estiloBotones}/>
			<Button label="Siguiente" onClick={() => handleClick_Second()} autoFocus style={{...estiloBotones,...estiloBotonSelect}}/>
        </div>
    );
    const BodyyyContent = (
        <div className="Boddy_pop_AHP">
            <div className="Contenedor_data_Pago">
                <div className="contenedores_base_pop_Pago AHP_Contendor_principal_01">
                    <div className="connt_contenData connt_contenData_02">
                    <p className="pop_text_normal_AHP">{props.nombreClinica}</p>
                    <p className="pop_text_titulo_AHP">RUC {props.rucClinica}</p>
                    </div>
                    <div className="connt_contenData connt_contenData_03">
                        <div className='Status_pago'>
                            <div className='contenedorChip' style={{ background:colorContentEstado, paddingLeft:'10px', paddingRight:'10px', height:'21px'}}>
                                <i className='pi pi-circle-fill' style={{ color:colorEstado , fontWeight:500, fontSize:'12px'}}></i>
                                <p className='textChip' style={{ color:colorEstado , fontWeight:500, fontSize:'12px'}}>
                                    {props.estadoPago}
                                </p>
                            </div>
                            <div className='contenedorChip'  style={{ background:colorContentMetodo, paddingLeft:'10px', paddingRight:'10px', height:'21px'}}>
                                <p className='textChip' style={{ color:colorMetodo , fontWeight:500 , fontSize:'12px'}}>
                                    {props.ModoPago} 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="connt_contenData connt_contenData_02">
                        <p className="pop_text_normal_AHP">N° de proforma</p>
                        <p className="pop_text_titulo_AHP">{props.NmProforma}</p>
                    </div>
                </div>
                <div className="contenedores_base_pop_Pago AHP_Contendor_principal_02">
                    <div className="Content_Data_Pago">
                    <p className="pop_text_normal_02_AHP">Total restante</p>
                    <p className="pop_text_titulo_02_AHP">{props.TotalRestante}</p>
                    </div>

                    <div className="Content_Data_Pago_icono">
                        <i className="pi pi-chevron-right"></i>
                    </div>


                    <div className="Content_Data_Pago">
                    <p className="pop_text_normal_02_AHP">Total por cobrar</p>
                    <p className="pop_text_titulo_02_AHP" style={{color:'#00A15C'}}>{props.TotalPorCobrar}</p>
                    </div>
                </div>
            </div>
        </div>
    );
	const headerrrContent =(
		<div id="pr_id_2_header" className="p-dialog-title headder_pop_AHP">
            <div className="flex flex-column justify-content-center align-items-center contenedor_iconn_Dialog" >
                <img src="/static/media/ActualizarUsuario.d1879e486592632169586d651c1c81ee.svg" alt="" style={{ width: '25px', height: '31.25px' }}/>
            </div>
            <h3 className="text_iconn_Dialog">Detalles de atención</h3>
		</div>	
	);

    // CUERPO DEL SEGUNDO POP
    const headerrrContentSecond =(
        <div id="pr_id_2_header" className="p-dialog-title headder_pop_AHP">
            <h3 className="text_iconn_Dialog">Documentos adicionales</h3>
		</div>	
    );
    const footerContentSecond =(
        <div style={{width:'100%',height:'35px'}}>
		</div>	
    );
    const estiloCentroFlex= {
        display:'flex',alignItems:'center', justifyContent:'center'
    }
    const estiloCentroFlexColumn= {
        display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column'
    }
    const tipoLetraTituloDoc01={
        fontFamily: 'Montserrat',fontSize: '14px',fontWeight: '600',lineHeight: '17px',letterSpacing: '0em',color:'#88888E'}
    const tipoLetraTituloDoc02={
        fontFamily: 'Montserrat',fontSize: '12px',fontWeight: '400',lineHeight: '174.63x',letterSpacing: '0em',color:'#88888E', marginTop:'3px'}
    const bodyContentSecond =(
        <div style={{width:'100%',height:'100%', display:'flex',flexDirection:'column', justifyContent:'flex-end', alignItems:'center'}}>
            <div style={{width:'100%', height:'65px', ...estiloCentroFlex, justifyContent:'space-between', padding:'0px 8px', borderTop:'0.75px solid #E9E9E9'}}>
                <div style={{width:'auto', height:'auto',...estiloCentroFlex,alignItems:'self-start'}}>
                    <div style={{width:'auto', height:'100%'}}>
                        <i className="pi pi-file" style={{color:'#88888E', fontSize:'22px', marginRight:'9px'}}></i>
                    </div>
                    <div>
                        <p style={tipoLetraTituloDoc01}>Factura</p>
                        <p style={tipoLetraTituloDoc02}>Pdf01987390198230.pdf</p>
                    </div>
                </div>
                <div className="botonDowloadlArchivo" style={{width:'45px', height:'45px', backgroundColor:'#EBEBFF', borderRadius:'5px',...estiloCentroFlex}}>
                    <i className="pi pi-download" style={{color:'#5555D8', fontSize:'20px'}}></i>
                </div>
            </div>
            <div style={{width:'100%', height:'65px', borderTop:'0.75px solid #E9E9E9', borderBottom:'0.75px solid #E9E9E9', ...estiloCentroFlex, justifyContent:'space-between', padding:'0px 8px'}}>
                <div style={{width:'auto', height:'auto',...estiloCentroFlex,alignItems:'self-start'}}>
                    <div style={{width:'auto', height:'100%'}}>
                        <i className="pi pi-file" style={{color:'#88888E', fontSize:'22px', marginRight:'9px'}}></i>
                    </div>
                    <div>
                        <p style={tipoLetraTituloDoc01}>Comprobante de pago</p>
                        <p style={tipoLetraTituloDoc02}>Pdf01987390198230.pdf</p>
                    </div>
                </div>
                <div className="botonDowloadlArchivo" style={{width:'45px', height:'45px', backgroundColor:'#EBEBFF', borderRadius:'5px',...estiloCentroFlex}}>
                    <i className="pi pi-download" style={{color:'#5555D8', fontSize:'20px'}}></i>
                </div>
            </div>
        </div>
    )

    return (
        
        <div className='Contend_VeriPag_AHP'>
            <div className='Datos_AHP'>
                <p className='Name_clinica letra_normal_AHP'>
                    {props.nombreClinica}
                </p>
                <p className='Ruc_clinica'>
                    {props.rucClinica}
                </p>
                <div className='Status_pago'>
                    <div className='contenedorChip' style={{ background:colorContentEstado, padding:'5px 10px'}}>
                        <i className='pi pi-circle-fill' style={{ color:colorEstado , fontWeight:500, fontSize:'10px'}}></i>
                        <p className='textChip' style={{ color:colorEstado , fontWeight:500, fontSize:'12px'}}>
                            {props.estadoPago}
                        </p>
                    </div>
                    <div className='contenedorChip'  style={{ background:colorContentMetodo, padding:'5px 10px'}}>
                        <p className='textChip' style={{ color:colorMetodo , fontWeight:500 , fontSize:'12px'}}>
                            {props.ModoPago} 
                        </p>
                    </div>
                </div>
            </div>
            <div className='Boton_datos'>
                <div id='contenedor_boton_folder'>
                <button id='fondo_logo_folder' onClick={handleClick}><i className="pi pi-folder-open" />
                </button>
                {visibled && (
                <div className="card flex justify-content-center">
                    <Dialog header={headerrrContent} visible={visibled} style={{ width: '1105px' ,height: '694px' }} onHide={() => setVisibled(false)} footer={footerContent}>
                            {BodyyyContent}
                            {visibled_second && (
                                <Dialog header={headerrrContentSecond} visible={visibled_second} style={{ width: '359px' ,height: '545px' }} onHide={() => setVisibled_second(false)} footer={footerContentSecond}>
                                        {bodyContentSecond}
                                </Dialog>
                            )}
                    </Dialog>
                </div>
                )}
                </div>
            </div>
        </div>
    )
}
export default ClienteClinicaVR;
import { Accordion, AccordionTab } from 'primereact/accordion'
import React from 'react'
import Pregunta1 from '../../../Components/Espirometria/Cuestionario/Pregunta1/Pregunta1'
import Pregunta3 from '../../../Components/Espirometria/Cuestionario/Pregunta3/Pregunta3'
import Pregunta2 from '../../../Components/Espirometria/Cuestionario/Pregunta2/Pregunta2'

export default function PageEspCuestionario() {
   return (
      <>
         <Accordion multiple>
            <AccordionTab header="Pag. 1">
               <Pregunta1 />
            </AccordionTab>
         </Accordion>
         <Accordion multiple>
            <AccordionTab header="Pag. 2">
               <Pregunta2/>
            </AccordionTab>
         </Accordion>
         <Accordion multiple>
            <AccordionTab header="Pag. 3">
               <Pregunta3 />
            </AccordionTab>
         </Accordion>
      </>
   )
}

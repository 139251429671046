import React, { useState, useContext, useEffect } from "react";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import VoiceButton from "../../ButtonVoice/VoiceButton";
import { MGContext } from "../../../Page/MedicinaGeneral/MGProvider";
import { InputNumber } from 'primereact/inputnumber';

export default function ComponentAnamesis() {

   const { exploracionDataContext, setExploracionDataContext } = useContext(MGContext);


   const opciones = [
      { name: "APTO", code: "1" },
      { name: "NO APTO", code: "2" },
      { name: "OBSERVADO", code: "3" },
   ];
   const ActualizarObject = (array, seteo, name) => {
      array.map((item) => {
         if (item.name == name) {
            seteo(item)
         }
      })
   }

   const [dropcode, setdropcode] = useState(exploracionDataContext?.med_anamnesis?.conclusiones);
   const [dropobject, setdropobject] = useState();

   useEffect(() => {
      ActualizarObject(opciones, setdropobject, dropcode)
   }, [exploracionDataContext])
   useEffect(() => {
      ActualizarSubPropiedad(dropcode, "conclusiones")
   }, [dropcode])



   const StyleSubtitulo = {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '400',
      color: 'rgb(136, 136, 142)'
   }
   const StyleSubtitulo02 = {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '800',
      color: '#4545BC'
   }
   const StyleTexto = {
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: '400',
      color: 'black'
   }



   const ActualizarSubPropiedad = (value, propiedadupdate) => {
      setExploracionDataContext((prevData) => ({
         ...prevData,
         "med_anamnesis": {
            ...prevData.med_anamnesis,
            [propiedadupdate]: value
         }
      }));
   };

   const ActualizarPropiedad = (value, propiedad) => {
      setExploracionDataContext(prevData => ({
         ...prevData,
         [propiedad]: value
      }));
   };

   const handleChange01 = (e) => {
      const { checked } = e.target;
      ActualizarPropiedad(checked, "levanta");
   };
   const handleChange02 = (e) => {
      const { checked } = e.target;
      ActualizarPropiedad(checked, "empuja");
   };
   const handleChange03 = (e) => {
      const { checked } = e.target;
      ActualizarPropiedad(checked, "arrastra");
   };

   return (
      <div className="p-1 flex flex-column w-full gap-3">
         <div className="w-full h-auto" style={{ ...StyleSubtitulo }}>
            Anamnesis (Puesto de trabajo actual - No aplica para pre
            ocupacionales)
         </div>
         <div className=" w-full flex gap-2">
            <div className="w-auto flex pt-3 pb-3 pr-4 pl-4 gap-1 align-items-center" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
               <p style={{ ...StyleSubtitulo02 }}>Puesto:</p>
               <div className="flex" style={{ paddingLeft: "20px" }}>
                  <RadioButton inputId="Administrativo" name="Administrativo" value="Administrativo"
                     onChange={(e) => ActualizarSubPropiedad(e.value, "puesto")}
                     checked={exploracionDataContext?.med_anamnesis?.puesto == 'Administrativo'} />
                  <label htmlFor="Administrativo" className="ml-2">Administrativo</label>
               </div>
               <div className="flex" style={{ paddingLeft: "20px" }}>
                  <RadioButton inputId="Operario" name="Operario" value="Operario"
                     onChange={(e) => ActualizarSubPropiedad(e.value, "puesto")}
                     checked={exploracionDataContext?.med_anamnesis?.puesto === 'Operario'} />
                  <label htmlFor="Operario" className="ml-2">Operario</label>
               </div>
            </div>
            <div className="flex-1 flex pt-2 pb-2 pr-4 pl-4 gap-2 align-items-center" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
               <p style={{ ...StyleSubtitulo02, marginRight: '20px' }}>Postura:</p>
               <div className="flex flex-1 align-items-center gap-2 pl-2 pr-2 h-3rem" style={{ borderRadius: '10px', backgroundColor: 'white' }}>
                  <p style={{ ...StyleTexto }}>Sedente: </p>
                  <InputText
                     type="text"
                     className="p-1"
                     placeholder=""
                     style={{ width: "100%", height: "40px" }}
                     value={exploracionDataContext?.med_anamnesis?.sedente}
                     onChange={(e) => {
                        ActualizarSubPropiedad(e.target.value, "sedente")

                     }}
                  />
               </div>
               <div className="flex flex-1 align-items-center gap-2 pl-2 pr-2 h-3rem" style={{ borderRadius: '10px', backgroundColor: 'white' }}>
                  <p style={{ ...StyleTexto }}>Bipedestación: </p>
                  <InputText
                     type="text"
                     className="p-inputtext-sm"
                     placeholder=""
                     style={{ width: "100%", height: "40px" }}
                     value={exploracionDataContext?.med_anamnesis?.bipedestacion}
                     onChange={(e) => {
                        ActualizarSubPropiedad(e.target.value, "bipedestacion")
                     }}
                  />
               </div>
            </div>
         </div>

         <div className="w-full flex gap-2 flex-wrap pt-3 pb-3 pr-4 pl-4 justify-content-between" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
            <div className="h-3rem flex pl-2 pr-2" style={{ backgroundColor: 'white', borderRadius: '10px', width: '49%' }}>
               <div className="flex flex-1 align-items-center" style={{ ...StyleSubtitulo02 }}>
                  ¿Postura forzada?
               </div>
               <div className="flex align-items-center p-2 gap-4">
                  <div className=" flex w-auto gap-1">
                     <RadioButton
                        inputId="postura_forzadaSi"
                        name="postura_forzadaSI"
                        value="Si"
                        onChange={(e) => ActualizarSubPropiedad(e.value, "postura_forzada")}
                        checked={exploracionDataContext?.med_anamnesis?.postura_forzada === "Si"}
                     />
                     <label htmlFor="postura_forzadaSi" style={{ ...StyleTexto }} className="flex align-items-center">
                        Si
                     </label>
                  </div>
                  <div className="flex w-auto gap-1">
                     <RadioButton
                        inputId="postura_forzadaNo"
                        name="postura_forzadaNo"
                        value="No"
                        onChange={(e) => ActualizarSubPropiedad(e.value, "postura_forzada")}
                        checked={exploracionDataContext?.med_anamnesis?.postura_forzada === "No"}
                     />
                     <label htmlFor="postura_forzadaNo" style={{ ...StyleTexto }} className="flex align-items-center">
                        No
                     </label>
                  </div>
               </div>
            </div>
            <div className="h-3rem flex pl-2 pr-2" style={{ backgroundColor: 'white', borderRadius: '10px', width: '49%' }}>
               <div className="flex flex-1 align-items-center" style={{ ...StyleSubtitulo02 }}>
                  ¿Movimiento repetitivo?
               </div>
               <div className="flex align-items-center p-2 gap-4">
                  <div className=" flex w-auto gap-1">
                     <RadioButton
                        inputId="Movimiento_RepetitivoSI"
                        name="Movimiento_RepetitivoSI"
                        value="Si"
                        onChange={(e) => ActualizarSubPropiedad(e.value, "movimiento_repetitivo")}

                        checked={exploracionDataContext?.med_anamnesis?.movimiento_repetitivo === "Si"}
                     />

                     <label htmlFor="Movimiento_RepetitivoSI" style={{ ...StyleTexto }} className="flex align-items-center">
                        Si
                     </label>
                  </div>
                  <div className="flex w-auto gap-1">
                     <RadioButton
                        inputId="Movimiento_RepetitivoNo"
                        name="Movimiento_RepetitivoNo"
                        value="No"
                        onChange={(e) => ActualizarSubPropiedad(e.value, "movimiento_repetitivo")}
                        checked={exploracionDataContext?.med_anamnesis?.movimiento_repetitivo === "No"}
                     />
                     <label htmlFor="Movimiento_RepetitivoNo" style={{ ...StyleTexto }} className="flex align-items-center">
                        No
                     </label>
                  </div>
               </div>
            </div>
            <div className="h-3rem flex pl-2 pr-2" style={{ backgroundColor: 'white', borderRadius: '10px', width: '49%' }}>
               <div className="flex flex-1 align-items-center" style={{ ...StyleSubtitulo02 }}>
                  ¿Movimientos Manual de carga?
               </div>
               <div className="flex align-items-center p-2 gap-4">
                  <div className=" flex w-auto gap-1">
                     <RadioButton
                        inputId="Movimiento_ManualCargaSI"
                        name="Movimiento_ManualCargaSI"
                        value="Si"
                        onChange={(e) => ActualizarSubPropiedad(e.value, "movimiento_manual_carga")}
                        checked={exploracionDataContext?.med_anamnesis?.movimiento_manual_carga === "Si"}
                     />

                     <label htmlFor="Movimiento_ManualCargaSI" style={{ ...StyleTexto }} className="flex align-items-center">
                        Si
                     </label>
                  </div>
                  <div className="flex w-auto gap-1">
                     <RadioButton
                        inputId="Movimiento_ManualCargaNO"
                        name="Movimiento_ManualCargaNO"
                        value="No"
                        onChange={(e) => ActualizarSubPropiedad(e.value, "movimiento_manual_carga")}
                        checked={exploracionDataContext?.med_anamnesis?.movimiento_manual_carga === "No"}
                     />
                     <label htmlFor="Movimiento_ManualCargaNO" style={{ ...StyleTexto }} className="flex align-items-center">
                        No
                     </label>
                  </div>
               </div>
            </div>
         </div>


         <div className=" w-full flex gap-2">
            <div className="flex-1 flex pt-2 pb-2 pr-4 pl-4 gap-2 align-items-center" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
               <p style={{ ...StyleSubtitulo02, marginRight: '20px' }}>Peso:</p>
               <div className="flex flex-1 align-items-center gap-2 pl-2 pr-2 h-3rem" style={{ borderRadius: '10px', backgroundColor: 'white' }}>
                  <p style={{ ...StyleTexto }}>Entre: </p>
                  <InputText
                     type="text"
                     className="p-inputtext-sm"
                     placeholder=""
                     style={{ width: "100%", height: "40px" }}
                     value={exploracionDataContext?.med_anamnesis?.peso_inf}
                     onChange={(e) => ActualizarSubPropiedad(e.target.value, "peso_inf")}

                  />
                  <p className="w-7rem" style={{ ...StyleTexto }}>Kg y </p>
                  <InputText
                     type="text"
                     className="p-inputtext-sm"
                     placeholder=""
                     style={{ width: "100%", height: "40px" }}
                     value={exploracionDataContext?.med_anamnesis?.peso_sub}
                     onChange={(e) => ActualizarSubPropiedad(e.target.value, "peso_sub")}
                  />
                  <p style={{ ...StyleTexto }}>Kg.</p>

               </div>
            </div>
            <div className="flex-1 flex pt-2 pb-2 pr-4 pl-4 gap-2 align-items-center" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
               <p style={{ ...StyleSubtitulo02, marginRight: '20px' }}>Método:</p>
               <div className="flex flex-1 align-items-center gap-2 pl-2 pr-2 h-3rem">
                  <div className="flex align-items-center w-4">
                     <Checkbox
                        onChange={handleChange01}
                        checked={exploracionDataContext.levanta}
                     />
                     <label htmlFor="ingredient1" className="ml-3" style={{ ...StyleTexto }}>
                        Levanta
                     </label>
                  </div>
                  <div className="flex align-items-center w-4"
                     style={{ paddingLeft: "25px" }}
                  >
                     <Checkbox
                        onChange={handleChange02}
                        checked={exploracionDataContext.empuja}
                     />
                     <label htmlFor="ingredient2" className="ml-3" style={{ ...StyleTexto }}>
                        Empuja
                     </label>
                  </div>
                  <div className="flex align-items-center w-4"
                     style={{ paddingLeft: "25px" }}
                  >
                     <Checkbox
                        onChange={handleChange03}
                        checked={exploracionDataContext.arrastra}
                     />
                     <label htmlFor="ingredient3" className="ml-3" style={{ ...StyleTexto }}>
                        Arrastra
                     </label>
                  </div>
               </div>

            </div>
         </div>

         <div className=" w-full flex gap-2">
            <div className="flex-1 flex pt-2 pb-2 pr-4 pl-4 gap-2 align-items-center" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
               <p className="flex flex-1" style={{ ...StyleSubtitulo02, marginRight: '20px' }}>¿Usuario de pantalla de visualización de datos (PVD)?:</p>
               <div className="flex w-auto align-items-center gap-2 pl-2 pr-2 h-3rem" style={{ borderRadius: '10px', backgroundColor: 'white' }}>
                  <div className="w-uato flex"></div>


                  <div className="flex align-items-center p-2 gap-4">
                     <div className=" flex w-auto gap-1">
                        <RadioButton inputId="pvdSI" name="pvdSI" value="Si"
                           onChange={(e) => ActualizarSubPropiedad(e.value, "pvd")}
                           checked={exploracionDataContext?.med_anamnesis?.pvd == 'Si'} />
                        <label htmlFor="pvdSI" className="ml-2">Si</label>

                     </div>
                     <div className="flex w-auto gap-1">

                        <RadioButton inputId="pvdNO" name="pvdNO" value="No"
                           onChange={(e) => ActualizarSubPropiedad(e.value, "pvd")}
                           checked={exploracionDataContext?.med_anamnesis?.pvd == 'No'} />
                        <label htmlFor="pvdNO" className="ml-2">No</label>

                     </div>
                  </div>

                  <div className="w-auto flex align-items-center gap-2">
                     <style>{`
                     .minmaxoptroo input{
                        width:100% !important;
                     }
                        `}</style>
                     <InputNumber
                        inputId="minmaxoptroo"
                        style={{width:'70px'}}
                        className="minmaxoptroo"
                        useGrouping={false}
                        value={exploracionDataContext?.med_anamnesis?.horas}
                        onValueChange={(e) => { ActualizarSubPropiedad(e.target.value, "horas") }} min={0} max={100} />
                     {/* <InputText
                        type="number"
                        className="p-inputtext-sm"
                        placeholder=""
                        style={{ width: "70px" }}
                        value={exploracionDataContext?.med_anamnesis?.horas}
                        disabled={exploracionDataContext?.med_anamnesis?.pvd !== "Si"}

                        onChange={(e) => {
                           ActualizarSubPropiedad(e.target.value, "horas")

                        }}
                     /> */}
                     <p style={{ ...StyleTexto }}>N horas/Día </p>

                  </div>


               </div>
            </div>
            <div className="w-auto flex pt-3 pb-3 pr-4 pl-4 gap-1 align-items-center" style={{ backgroundColor: 'rgb(246, 247, 251)', borderRadius: '10px' }}>
               <p style={{ ...StyleSubtitulo02 }}>Dominancia:</p>
               <div className="flex" style={{ paddingLeft: "20px" }}>

                  <RadioButton inputId="Diestro" name="Diestro" value="Diestro"
                     onChange={(e) => ActualizarSubPropiedad(e.value, "dominancia")}
                     checked={exploracionDataContext?.med_anamnesis?.dominancia == 'Diestro'} />
                  <label htmlFor="Diestro" className="ml-2">Diestro</label>

               </div>
               <div className="flex" style={{ paddingLeft: "20px" }}>
                  <RadioButton inputId="Zurdo" name="Zurdo" value="Zurdo"
                     onChange={(e) => ActualizarSubPropiedad(e.value, "dominancia")}
                     checked={exploracionDataContext?.med_anamnesis?.dominancia == 'Zurdo'} />
                  <label htmlFor="Zurdo" className="ml-2">Zurdo</label>

               </div>
            </div>
         </div>


         <div className="w-full flex gap-2 mt-3">
            <div className="flex flex-1 flex-column gap-2">
               <div style={{ ...StyleSubtitulo02 }}>
                  Diagnósticos:
               </div>
               <div>
                  <VoiceButton
                     value={exploracionDataContext?.med_anamnesis?.diagnostico}
                     onChange={(value) => {
                        ActualizarSubPropiedad(value, "diagnostico")
                     }}

                     placeholder="Comentario"
                  />
               </div>
            </div>
            <div className="flex flex-1 flex-column gap-2">
               <div style={{ ...StyleSubtitulo02 }}>
                  Recomendaciones:
               </div>
               <div>
                  <VoiceButton
                     value={exploracionDataContext?.med_anamnesis?.recomendaciones}
                     onChange={(value) => {
                        ActualizarSubPropiedad(value, "recomendaciones")

                     }}
                     placeholder="Comentario"
                  />
               </div>
            </div>
         </div>


         <div className="w-full flex gap-2 ">
            <div className="w-auto flex flex-column gap-2">
               <div style={{ ...StyleSubtitulo02 }}>
                  Conclusiones:
               </div>
               <div>
                  <Dropdown
                     value={dropobject}
                     onChange={(e) => {
                        setdropcode(e.value.name)
                     }}
                     options={opciones}
                     optionLabel="name"
                     editable
                     className="w-full md:w-14rem"
                     style={{ width: "172px" }}
                  />
               </div>
            </div>
            <div className="flex flex-1 flex-column gap-2">
               <div style={{ ...StyleSubtitulo02, height: '19px' }}>
               </div>
               <div>
                  <InputTextarea
                     type="text"
                     autoResize
                     className="p-inputtext-sm"
                     style={{
                        width: "100%",
                        height: "40px",
                        overflow: "hidden",
                     }}
                     placeholder="Comentario"
                     onChange={(e) => {
                        setExploracionDataContext((prevData) => ({
                           ...prevData,
                           med_anamnesis: {
                              ...prevData.med_anamnesis,
                              conclusiones_detalles: e.target.value
                           }
                        }));
                     }}
                     value={exploracionDataContext?.med_anamnesis?.conclusiones_detalles}
                  />
               </div>
            </div>
         </div>



      </div>
   );
}

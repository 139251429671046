import React, { createContext, useEffect, useState } from "react";

export const RecepcionContext = createContext(undefined);

export const RecepcionProvider = (props) => {
  const [camposInvalidos, setCamposInvalidos] = useState([]);
  const [mostrarError, setMostrarError] = useState(false);
  const [mensajeError, setMensajeError] = useState('');
  const obtenerFechaActual = () => {
    const fechaActual = new Date();
    const dia = fechaActual.getDate();
    const mes = fechaActual.getMonth() + 1;
    const año = fechaActual.getFullYear();

    const fechaFormateada = `${año}-${mes < 10 ? '0' + mes : mes}-${dia < 10 ? '0' + dia : dia}`;

    return fechaFormateada;
  };
  const [datosGenerales, setDatosGenerales] = useState({
    tipo_documento_id: null,
    numero_documento: '',
    nombres: '',
    apellido_paterno: '',
    apellido_materno: '',
    sexo_id: null,
    fecha_nacimiento: '',
    distrito_id: null,
    dristrito: {},
    religion_id: null,
    distrito_domicilio_id: null,
    distrito_domicilio: {},
    direccion: '',
    estado_civil_id: null,
    grado_instruccion_id: null,
    correo: '',
    telefono: 0,
    celular: 0,
    telefono_emergencia: 0,
    clinica_local_id: null,
    fecha: obtenerFechaActual(),
    perfil_tipo_id: null,
    perfil_tipo: {},
    usuario: 0,
    empresa_cargo_id: null,
    empresa_cargo: {},
    empresa_id: null,
    empresa: {}
  });

  const [datosGeneralesParticular, setDatosGeneralesParticular] = useState({
    perfil_particular_id: null,
    tipo_documento_id: null,
    selectedTipoDocumento: null,
    numero_documento: '',
    numeroDocumento: '',
    nombres: "",
    apellido_paterno: "",
    apellidoPaterno: '',
    apellido_materno: "",
    fecha_nacimiento: "",
    protocolo: "",
    clinica_local_id: null,
    tipo_evaluacion_id: null,
    celular: "",
    correo: "",
    direccion: "",
    distrito_id: "",
    distrito_domicilio_id: "",
    estado_civil_id: "",
    religion_id: "",
    sexo_id: "",
    grado_instruccion_id: "",
    fecha_atencion: "",
    fecha: "",
    subtotal: "",
    total: "",
    igv: "",
    perfil_particular: null,
    fecha_emision: obtenerFechaActual(),
    apoderado: {
      tipo_documento: {},
      tipo_documento_id: null,
      numero_documento: null,
      nombres_completos: "",
      apellido_paterno: "",
      apellido_materno: ""
    }
  });

  const [seleccion, setSeleccion] = useState(false);
  const [seleccionParticular, setSeleccionParticular] = useState(false);

  const [emerjencia, setEmergencia] = useState({
    Codigo: '',
    seguro: '',
    provincia_id: null,
    departamento_id: null,
    departamento: {},
    provincia: {},

    provinciaDom_id: null,
    departamentoDom_id: null,
    departamentoDom: {},
    provinciaDom: {},
    edad: '',
    Registro: '',
  });

  const [provincias, setProvincias] = useState([]);

  const [distritos, setDistritos] = useState([]);

  useEffect(() => {
    const departamentoId = emerjencia.departamento_id;

    if (departamentoId && emerjencia.provincias) {
      setDistritos(departamentoId.provincias[0].distritos);
    }
  }, [emerjencia.departamento_id, emerjencia.provincias]);
  const [provinciasD, setProvinciasD] = useState([]);
  const [distritosD, setDistritosD] = useState([]);
  useEffect(() => {
    const departamentoId = emerjencia.departamentoDom_id;

    if (departamentoId && emerjencia.provinciasDom) {
      setDistritosD(departamentoId.provinciasDom[0].distritos);
    }
  }, [emerjencia.departamentoDom_id, emerjencia.provinciasDom]);

    const [perfilSeleccionado, setPerfilSeleccionado] = useState({ objetos: [] });

  return (
    <RecepcionContext.Provider
      value={{
        datosGenerales, setDatosGenerales,
        datosGeneralesParticular, setDatosGeneralesParticular,
        seleccion, setSeleccion,
        seleccionParticular, setSeleccionParticular,
        emerjencia, setEmergencia,
        provincias, setProvincias,
        distritos, setDistritos,
        provinciasD, setProvinciasD,
        distritosD, setDistritosD,
        camposInvalidos, setCamposInvalidos,
        mostrarError, setMostrarError,
        mensajeError, setMensajeError
      }}>
      {props.children}
    </RecepcionContext.Provider>
  );


}

import React from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import CardNotificacion from "./CardNotificacion.jsx";
import "../../../../Style/PagePerfil1.css";
import "../../../../Style/PagePerfilGeneral/USU-3/InboxCoorporativo.css"

/* Responsive todo en PagePerfil1.css */

export default function CuadroNotificacionesInBox(props) {
   return (
      <ScrollPanel style={{ width: '100%', height: '100%', overflowX: 'hidden' }} className="custombar1">
         {(props.DataNotificaciones).map((notificacion, index) => (
            <CardNotificacion
               key={index}
               tiponotificaciones={notificacion.tipo_mensaje}
               titulo={notificacion.remitente}
               descripcion={notificacion.titular}
               iconos={notificacion.icon}
               info={notificacion}
               hallaId={props.FuncionChange}
               FuncionAparecer={props.FuncionLimpiar}
            ></CardNotificacion>
         ))}
      </ScrollPanel>

   )
}
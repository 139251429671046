import React from 'react'

export default function BottomsFootComponent({ setVisibleGuardar, setVisibleCancelar, setVisibleImprimir, setEstado, estado, revision,btnSave=true}) {
   return (
      <div
         style={{
            borderRadius: "10px",
            height: "70px",
            position: "relative",
         }}
         className="flex justify-content-end gap-2 mt-2 bg-white myDiv"
      >
         {btnSave &&( <button
            onClick={() => setVisibleGuardar(true)}
            className="p-button p-component btn-2 flex gap-1"
            
         >
            <i className="pi pi-save"> </i>
            <span>Guardar</span>
         </button>)}
        
         {revision !== 1 && (
            <button
               style={{ color: "red" }}
               className="p-button  p-component btn-2 flex gap-1"
               onClick={() => setVisibleCancelar(true)}
            >
               <i className="pi pi-times"></i>
               <span>Cancelar</span>
            </button>
         )}
         <button
            style={{ color: "#6366F1" }}
            onClick={() => setEstado(!estado)}
            className="p-button p-component btn-2 flex gap-1"
         >
            <i className="pi pi-stop-circle"></i>
            <span>{revision === 1 ? "Finalizar revisión" : "Finalizar atención"}</span>
         </button>
         {revision !== 1 && (
            <button
               style={{ color: "black" }}
               onClick={() => setVisibleImprimir(true)}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-print"></i>
               <span>Imprimir</span>
            </button>
         )}
      </div>
   )
}

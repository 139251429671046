import React, { useState, useRef, useEffect } from "react";
import Webcam from "react-webcam";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import InputCalendar from "../../../form/InputCalendar";
import dataStatic from "../../../../Services/Recepcion/dataModalActualizarDatos";
import AuthUser from "../../../../AuthUser";
import "../../style.css";
import trash from "../../../../Images/atencion/Recepcion/trash.svg";
import camera from "../../../../Images/atencion/Recepcion/Camera.svg";
import CargarImagen from "../../../../Images/atencion/Recepcion/CargarImagen.svg";
import { useDispatch } from "react-redux"
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice"


const ModalActulizarDatos = ({
   show,
   setShow,
   data,
   isSaved,
   setIsSaved,
   getPacientes,
   ubicaiones,
   showToast,
   nombre,
   estado,
   disabled = false,
}) => {
   const { http } = AuthUser();

   //SERVICIO DE DATOS ESTATICOS
   const {
      tipoDoc,
      sexo: optionsSexo,
      pais,
      depar,
      reli,
      provi,
      distri,
      Ec,
      gradoinsti,
   } = dataStatic();


   const dispatch = useDispatch();

   //------------SECCION DE ESTADOS-----------//

   const [selectedImages, setSelectedImages] = useState([]);
   //Estado para elmacenar las opciones de ditritos y provincias
   const [optionDistrito, setOptionDistrito] = useState([]);
   const [optionProvicia, setOptionProvicia] = useState([]);
   const [optionDistritoAdomicilio, setOptionDistritoAdomicilio] = useState([]);
   const [optionProviciaAdomicilio, setOptionProviciaAdomicilio] = useState([]);
   const [gradoInstruccion, setGradoInstruccion] = useState([]);

   const [color, setcolor] = useState(disabled ? "#DDDDDD" : "white");
   //Estado para enviar a la api
   const [dataSend, setDataSend] = useState({});

   //estado para los errores de campos requeridos
   const [dataError, setDataError] = useState({
      /* DATOS DE AFILIACION */
      paciente_id: false,
      tipo_documento_id: false,
      numero_documento: false,
      numero_codigo: false,
      nombres: false,
      apellido_paterno: false,
      apellido_materno: false,
      numero_seguro_social: false,

      /* DATOS DE NACIMIENTO */
      sexo_id: false,
      fecha_nacimiento: false,
      //nacimiento_pais : false,
      nacimiento_departamento: false,
      nacimiento_provincia: false,
      distrito_id: false,
      edad: false,
      religion_id: false,

      /* DATOS DE DOMICILIO */
      //domicilio_pais : false,
      domicilio_departamento: false,
      domicilio_provincia: false,
      distrito_domicilio_id: false,
      direccion: false,
      numero_registro: false,

      /* OTROS DATOS */
      estado_civil_id: false,
      grado_instruccion_id: false,
      correo: false,
      telefono: false,
      celular: false,
      telefono_emergencia: false,
   });

   //------------END SECCION ESTADOS-----------//

   const handleImageUpload = (selectedFiles) => {
      const uploadedImages = [];

      for (let i = 0; i < selectedFiles.length; i++) {
         const file = selectedFiles[i];

         if (file.type.startsWith("image/")) {
            const imageUrl = URL.createObjectURL(file);
            uploadedImages.push(imageUrl);
         }
      }

      setSelectedImages(uploadedImages);
   };

   const [isCapturing, setIsCapturing] = useState(false); // Agregar esta variable de estado
   const [capturedImage, setCapturedImage] = useState(null); // Agregar esta variable de estado

   const webcamRef = useRef(null);
   const [showCamera, setShowCamera] = useState(false);
   const [screenshot, setScreenshot] = useState(null);
   const [isCameraActive, setIsCameraActive] = useState(false);

   const capture = () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setScreenshot(imageSrc);
      setShowCamera(false);
   };
   const handleImageDelete = (index) => {
      // Copia el array de imágenes y elimina la imagen en el índice dado
      const updatedImages = [...selectedImages];
      updatedImages.splice(index, 1);
      setSelectedImages(updatedImages);
   };

   //------------SECCION DE FUNCIONES-----------//

   //funcion para separar apellido y nombres
   /* const separarApellidos = (cadena) => {
        // Dividir la cadena en palabras
        const nombre_completo = cadena.trim().split(/\s+/);
      
        // Verificar si hay al menos 3 palabras
        if (nombre_completo.length < 3) {
          return ['',''];
        }
      
        // Obtener las dos últimas palabras
        const apellido = nombre_completo.slice(-2);
      
        // Obtener el texto sin las dos últimas palabras
        const nombres = nombre_completo.slice(0, -2).join(' ');
      
        return [
            nombres,
            apellido.join(' '),
        ];
      } */

   //Funcion para transformar el date del calendar a string(YY/MM/DD)
   const formatDate = (dateValue) => {
      if (dateValue instanceof Date) {
         const year = dateValue.getFullYear().toString();
         const month = (dateValue.getMonth() + 1).toString().padStart(2, "0");
         const day = dateValue.getDate().toString().padStart(2, "0");
         setDataSend({
            ...dataSend,
            fecha_nacimiento: `${year}/${month}/${day}`,
            edad: obtenerEdad(`${year}/${month}/${day}`),
         });
      }
   };

   //Funcion para transformar un string(YY/MM/DD) a date, soporta "/" y "-"

   const parseYYMMDDDate = (dateString) => {
      if (!dateString) return null;
      let parts = dateString.split("/");

      if (parts.length !== 3) parts = dateString.split("-");

      if (parts.length === 3) {
         const year = parts[0];
         const month = parts[1] - 1;
         const day = parts[2];
         return new Date(year, month, day);
      }
   };

   //Funcion para obtener edad

   const obtenerEdad = (fecha) => {
      if (fecha) {
         let fechaActual = new Date();
         let edad =
            fechaActual.getFullYear() - parseYYMMDDDate(fecha).getFullYear();
         let mesActual = fechaActual.getMonth();
         let mesNacimiento = parseYYMMDDDate(fecha).getMonth();
         if (mesActual < mesNacimiento) {
            edad--;
         } else if (
            mesActual === mesNacimiento &&
            fechaActual.getDate() < parseYYMMDDDate(fecha).getDate()
         ) {
            edad--;
         }
         return edad;
      } else {
         return null;
      }
   };

   //Funcion para seleccionar y filtrar opciones de departamento, provincia y distrito por id

   const handleSelectUbicacion = (tipo, event, adomicilio) => {
      //adomicilio indica si el dato es de adomicilio o nacimiento
      if (tipo === "departamento") {
         if (!adomicilio) {
            //en que caso adomicilio no exista
            setDataSend({
               ...dataSend,
               nacimiento_departamento: event.value.id,
               nacimiento_provincia: null,
               distrito_id: null,
            });
         } else {
            //en que caso adomicilio exista
            setDataSend({
               ...dataSend,
               domicilio_departamento: event.value.id,
               domicilio_provincia: null,
               distrito_domicilio_id: null,
            });
         }
         let departamento = ubicaiones?.filter(
            (departamento) => departamento?.id === event.value.id
         )[0];
         !adomicilio
            ? setOptionProvicia(departamento?.provincias || []) //en que caso adomicilio no exista
            : setOptionProviciaAdomicilio(departamento?.provincias || []); //en que caso adomicilio exista
      } else if (tipo === "provincia") {
         let departamento;
         if (!adomicilio) {
            setDataSend({
               ...dataSend,
               nacimiento_provincia: event.value.id,
               distrito_id: null,
            });
            departamento = ubicaiones?.filter(
               (departamento) =>
                  departamento?.id === dataSend?.nacimiento_departamento
            )[0];
         } else {
            setDataSend({
               ...dataSend,
               domicilio_provincia: event.value.id,
               distrito_domicilio_id: null,
            });
            departamento = ubicaiones?.filter(
               (departamento) =>
                  departamento?.id === dataSend?.domicilio_departamento
            )[0];
         }
         let provincia = departamento?.provincias?.filter(
            (provincia) => provincia?.id === event.value.id
         )[0];
         !adomicilio
            ? setOptionDistrito(provincia?.distritos || [])
            : setOptionDistritoAdomicilio(provincia?.distritos || []);
      } else if (tipo === "distrito") {
         !adomicilio
            ? setDataSend({ ...dataSend, distrito_id: event.value.id })
            : setDataSend({
                 ...dataSend,
                 distrito_domicilio_id: event.value.id,
              });
      }
   };

   //Funcion para filtrar las opciones del dropdown segun id

   const    optionFilter = (optiones, value) => {

      return optiones?.filter((option) => option.id === value)[0] || null;
   };

   //Funcion para validar campos requeridos

   const validationFunction = (objeto) => {
      let dataValidate = dataError;
      let status = true;
      const exeptions = [
         "numero_codigo",
         "numero_seguro_social",
         "numero_registro",
      ];
      for (let clave in objeto) {
         if (objeto.hasOwnProperty(clave)) {
            if (!objeto[clave] && !exeptions.includes(clave)) {
               dataValidate = { ...dataValidate, [clave]: true };
               status = false;
            } else {
               dataValidate = { ...dataValidate, [clave]: false };
            }
         }
      }
      setDataError(dataValidate);
      return status;
   };

   //Funcion para reiniciar los valores de error

   const validationFunctionReset = (objeto) => {
      let dataValidate = objeto;
      for (let clave in objeto) {
         if (objeto.hasOwnProperty(clave)) {
            dataValidate = { ...dataValidate, [clave]: false };
         }
      }
      setDataError(dataValidate);
   };

   //Funcion para actualizar datos del usuario

   const IniciarLoading = () => {
      dispatch(startLoading());
      
      setTimeout(() => {
        dispatch(endLoading());
      }, 1500); // 2000 milisegundos = 2 segundos
    };
    
   const handleActualizarDatos = (datos) => {
      const formData = new FormData();
      formData.append("apellido_paterno", datos.apellido_paterno);
      formData.append("apellido_materno", datos.apellido_materno);
      formData.append("celular", datos.celular);
      formData.append("correo", datos.correo);
      formData.append("direccion", datos.direccion);
      formData.append("distrito_domicilio_id", datos.distrito_domicilio_id);
      formData.append("distrito_id", datos.distrito_id);
      formData.append("domicilo_departamento", datos.domicilo_departamento);
      formData.append("domicilio_provincia", datos.domicilio_provincia);
      formData.append("edad", datos.edad);
      formData.append("estado_civil_id", datos.estado_civil_id);
      formData.append("fecha_nacimiento", datos.fecha_nacimiento);
      formData.append("grado_instruccion_id", datos.grado_instruccion_id);
      formData.append("nacimiento_departamento", datos.nacimiento_departamento);
      formData.append("nacimiento_provincia", datos.nacimiento_provincia);
      formData.append("nombres", datos.nombres);
      formData.append("numero_codigo", datos.numero_codigo);
      formData.append("numero_documento", datos.numero_documento);
      formData.append("numero_registro", datos.numero_registro);
      formData.append("numero_seguro_social", datos.numero_seguro_social);
      formData.append("paciente_id", datos.paciente_id);
      formData.append("religion_id", datos.religion_id);
      formData.append("sexo_id", datos.sexo_id);
      formData.append("telefono", datos.telefono);
      formData.append("telefono_emergencia", datos.telefono_emergencia);
      formData.append("tipo_documento_id", datos.tipo_documento_id);

      // formData.append("archivo", datos.archivo);
      let isValidate = validationFunction(datos);
      if (isValidate) {
         http
            .post(
               `clinica/recepcion/pacientes/update/${datos.paciente_id}`,
               formData
            )
            .then((response) => {
               
               setShow(false);
               showToast("success", "Exito", "Datos actualizados");
               getPacientes();
               IniciarLoading();
            })
            .catch((error) => {
               console.error(error);
            });
      }
   };

   //------------END SECCION FUNCIONES-----------//

   const HeaderContent = () => {
      return (
         <>
            <h2 className="tit pl-3">{nombre ?? "Actualizar Datos"}</h2>
         </>
      );
   };

   const FooterContent = () => {
      return (
         <div className="flex gap-5 justify-content-end pt-4">
            <Button
               onClick={() => {
                  setShow(false);
                  validationFunctionReset(dataError);
               }}
               label="Cancelar"
            />
            <Button
               onClick={() => handleActualizarDatos(dataSend)}
               label="Actualizar"
            />
         </div>
      );
   };

   const getGrados = () => {
      http.get("gradoinstruccion/get").then((response) => {
         if (response.data.data) {
            setGradoInstruccion(response.data.data);
         }
      });
   };

   useEffect(() => {
      if (show) {
         //let nombre_apellido = separarApellidos(data?.nombres_completos || '')
         let departamento_default = null;
         let provincia_defaut = null;
         let departamento_domicilio_default = null;
         let provincia_domicilio_defaut = null;
         if (data?.persona?.distrito_id) {

            departamento_default = ubicaiones?.find((departamento) => {
               return (provincia_defaut = departamento?.provincias?.find(
                  (provincia) => {
                     return provincia?.distritos?.find((distrito) => {
                        return distrito.id === data?.persona?.distrito_id;
                     });
                  }
               ));
            });
            setOptionProvicia(departamento_default?.provincias);
            setOptionDistrito(provincia_defaut?.distritos);
         }

         if (data?.persona?.distrito_domicilio_id) {

            departamento_domicilio_default = ubicaiones?.find(
               (departamento) => {
                  return (provincia_domicilio_defaut =
                     departamento?.provincias?.find((provincia) => {
                        return provincia?.distritos?.find((distrito) => {
                           return distrito.id === data?.persona?.distrito_domicilio_id;
                        });
                     }));
               }
            );
            setOptionProviciaAdomicilio(
               departamento_domicilio_default?.provincias
            );
            setOptionDistritoAdomicilio(provincia_domicilio_defaut?.distritos);
         }

         //calcular edad

         let edad = obtenerEdad(data?.persona?.fecha_nacimiento);

         //actualizar datos para enviar a la api

         setDataSend({
            /* DATOS DE AFILIACION */
            paciente_id: data?.id,
            tipo_documento_id: data?.persona?.tipo_documento_id,
            numero_documento: data?.persona?.numero_documento,
            numero_codigo: null,
            nombres: data?.persona?.nombres,
            apellido_paterno: data?.persona?.apellido_paterno,
            apellido_materno: data?.persona?.apellido_materno,
            numero_seguro_social: null,

            /* DATOS DE NACIMIENTO */
            sexo_id: data?.persona?.sexo_id,
            fecha_nacimiento: data?.persona?.fecha_nacimiento,
            //nacimiento_pais : null,
            nacimiento_departamento:data?.persona?.distrito?.provincia?.departamento?.id,
            nacimiento_provincia: data?.persona?.distrito?.provincia?.id,
            distrito_id: data?.persona?.distrito_id,
            edad: edad,
            religion_id: data?.persona?.religion_id,

            /* DATOS DE DOMICILIO */
            //domicilio_pais : null,
            domicilio_departamento: data?.persona?.distrito_domicilio?.provincia?.departamento?.id,
            domicilio_provincia: data?.persona?.distrito_domicilio?.provincia?.id,
            distrito_domicilio_id: data?.persona?.distrito_domicilio_id,
            direccion: data?.persona?.direccion,
            numero_registro: null,

            /* OTROS DATOS */
            estado_civil_id: data?.persona?.estado_civil_id,
            grado_instruccion_id: data?.persona?.grado_instruccion_id,
            correo: data?.persona.correo,
            telefono: data?.persona?.telefono,
            celular: data?.persona?.celular,
            telefono_emergencia: data?.persona?.telefono_emergencia,
         });
      }
   }, [show, data]);

   return (
      <Dialog
         visible={show}
         onHide={() => {
            setShow(false);
            validationFunctionReset(dataError);
         }}
         header={HeaderContent}
         footer={FooterContent}
         style={{ maxWidth: "1500px" }}
         divider
      >
         <>
            <div
               className="flex flex-wrap flex-colum gap-6 w-full"
               style={{
                  background: "#F6F7FB",
                  borderRadius: "12.8671px",
               }}
            >
               <div
                  className="flex flex-row gap-3 px-4"
                  style={{ marginTop: "20px" }}
               >
                  {/* Datos de Afiliación */}
                  <div className="contenedorD flex flex-wrap flex-row p-3 gap-3">
                     <p className="subtituloR">Datos de Afiliación</p>
                     <div className="flex flex-wrap gap-3 w-full">
                        <div className="flex flex-wrap flex-column flex-1 justify-content-between">
                           <label className="datos">Tipo de Documento</label>
                           <Dropdown
                              value={optionFilter(
                                 tipoDoc,
                                 dataSend?.tipo_documento_id
                              )}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    tipo_documento_id: e.value.id,
                                 })
                              }
                              options={tipoDoc}
                              optionLabel="name"
                              placeholder="Documento"
                              className="flex-1 mt-2 max-h-3rem"
                              style={{ minWidth: "14rem", background: color }}
                              disabled={disabled}
                           />
                           {dataError?.tipo_documento_id &&
                              !dataSend?.tipo_documento_id && (
                                 <span className="text-red-500">
                                    Seleccione tipo de documento
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Nro. de Documento</label>
                           <InputText
                              value={dataSend?.numero_documento}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    numero_documento: e.target.value,
                                 })
                              }
                              style={{ background: color }}
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="Ingresar N° DNI"
                              disabled={disabled}
                           />
                           {dataError?.numero_documento &&
                              !dataSend?.numero_documento && (
                                 <span className="text-red-500">
                                    Ingrese numero de documento
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1 ">
                           <label className="datos">Nro. de Código</label>
                           <InputText
                              value={dataSend?.numero_codigo}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    numero_codigo: e.target.value,
                                 })
                              }
                              style={{ background: color }}
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                        </div>
                     </div>

                     <div className="flex flex-wrap flex-row gap-3 w-full">
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Apellido Paterno</label>
                           <InputText
                              value={dataSend?.apellido_paterno}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    apellido_paterno: e.target.value,
                                 })
                              }
                              style={{ background: color }}
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="Apellido Paterno"
                              disabled={disabled}
                           />
                           {dataError?.apellido_paterno &&
                              !dataSend?.apellido_paterno && (
                                 <span className="text-red-500">
                                    Ingrese apellido paterno
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Apellido Materno</label>
                           <InputText
                              value={dataSend?.apellido_materno}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    apellido_materno: e.target.value,
                                 })
                              }
                              style={{ background: color }}
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="Apellido Materno"
                              disabled={disabled}
                           />
                           {dataError?.apellido_materno &&
                              !dataSend?.apellido_materno && (
                                 <span className="text-red-500">
                                    Ingrese apellido materno
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Nombres Completos</label>
                           <InputText
                              value={dataSend?.nombres}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    nombres: e.target.value,
                                 })
                              }
                              style={{ background: color }}
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="Nombre"
                              disabled={disabled}
                           />
                           {dataError?.nombres && !dataSend?.nombres && (
                              <span className="text-red-500">
                                 Ingrese nombres completos
                              </span>
                           )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">
                              Nro. de Seguro Social
                           </label>
                           <InputText
                              value={dataSend?.numero_seguro_social}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    numero_seguro_social: e.target.value,
                                 })
                              }
                              style={{ background: color }}
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                        </div>
                     </div>
                  </div>

                  {/* Camara */}
                  <div className="flex flex-wrap flex-column gap-4 pt-5">
                     <div
                        style={{
                           background: "#fff",
                           borderRadius: "17px",
                           marginTop: "-1px",
                        }}
                        className="flex flex-wrap justify-content-center p-2 gap-1"
                     >
                        {isSaved &&
                           selectedImages.map((imageUrl, index) => (
                              <div key={index}>
                                 <img
                                    src={imageUrl}
                                    alt=""
                                    style={{
                                       background: "#5555D8",
                                       borderRadius: "17px",
                                       width: "147px",
                                       height: "150px",
                                    }}
                                 />
                              </div>   
                           ))}
                        {showCamera && (
                           <div
                              className="camaraweb"
                              style={{
                                 display: "flex",
                                 justifyContent: "center",
                              }}
                           >
                              <Webcam
                                 ref={webcamRef}
                                 audio={false}
                                 screenshotFormat="image/jpeg"
                                 style={{
                                    border: "1px solid blue",
                                    borderRadius: "5px",
                                    width: "100px",
                                    height: "100px",
                                 }}
                              />
                              <button
                                 style={{
                                    border: "1px solid blue",
                                    borderRadius: "5px",
                                 }}
                                 onClick={capture} // Llama a la función capture
                              >
                                 <img src={camera} alt="" />
                              </button>
                           </div>
                        )}
                        {screenshot && (
                           <div>
                              <img
                                 style={{ borderRadius: "5px" }}
                                 src={screenshot}
                                 alt="Foto Capturada"
                              />
                           </div>
                        )}
                        <div className="flex flex-row justify-content-center gap-3">
                           <input
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                 handleImageUpload(e.target.files)
                              }
                           />
                           <Button
                              type="button"
                              className="flex flex-nowrap flex-row gap-3 boton003 justify-content-center"
                              style={{
                                 border: "1px solid #00A15C",
                                 borderRadius: "5px",
                                 padding: "5px",
                                 marginTop: "5px",
                              }}
                              onClick={() => {
                                 const fileInput =
                                    document.getElementById("fileInput");
                                 if (fileInput) {
                                    fileInput.click();
                                 }
                              }}
                           >
                              <img src={CargarImagen} alt="" />
                           </Button>
                           <Button
                              type="button"
                              className="flex flex-nowrap flex-row gap-3 boton003 justify-content-center"
                              style={{
                                 border: "1px solid #5555D8",
                                 borderRadius: "5px",
                                 padding: "5px",
                                 marginTop: "5px",
                              }}
                              onClick={() => {
                                 setShowCamera(
                                    (prevShowCamera) => !prevShowCamera
                                 );
                              }}
                           >
                              <img
                                 src={camera}
                                 alt={
                                    showCamera
                                       ? "Cerrar Cámara"
                                       : "Abrir Cámara"
                                 }
                              />
                           </Button>
                           <Button
                              type="button"
                              className="flex flex-nowrap flex-row gap-3 boton003 justify-content-center"
                              style={{
                                 border: "1px solid #FF6767",
                                 borderRadius: "5px",
                                 padding: "5px",
                                 marginTop: "5px",
                              }}
                           >
                              <img src={trash} alt="" />
                           </Button>
                        </div>
                     </div>
                  </div>
               </div>

               {/* Datos de Nacimiento */}
               <div className="w-full " style={{ marginTop: "-10px" }}>
                  <div className="mx-4 bg-white border-round-xl flex flex-wrap flex-column p-3 gap-3">
                     <p className="subtituloR">Datos de Nacimiento</p>
                     <div className="flex flex-wrap gap-3 w-full">
                        <div className="flex flex-wrap gap-3 flex-1">
                           <div
                              className="flex flex-wrap flex-column flex-1 "
                              style={{ minWidth: "14rem" }}
                           >
                              <label className="datos">
                                 Fecha de nacimiento
                              </label>
                              <InputCalendar
                                 inputStyle={{ background: color }}
                                 style={{ background: color }}
                                 value={parseYYMMDDDate(
                                    dataSend?.fecha_nacimiento
                                 )}
                                 onChange={(e) => formatDate(e.value)}
                                 showIcon
                                 placeholder="01/05/2023"
                                 className="flex-1 mt-2 max-h-3rem"
                                 disabled={disabled}
                              />
                              {dataError?.fecha_nacimiento &&
                                 !dataSend?.fecha_nacimiento && (
                                    <span className="text-red-500">
                                       Seleccione una fecha
                                    </span>
                                 )}
                           </div>
                           <div className="flex flex-wrap flex-column flex-1">
                              <label className="datos">Edad</label>
                              <InputText
                                 value={dataSend?.edad}
                                 onChange={(e) =>
                                    setDataSend({
                                       ...dataSend,
                                       edad: e.target.value,
                                    })
                                 }
                                 className="flex-1 mt-2 max-h-3rem"
                                 placeholder="Edad"
                                 disabled
                                 style={{
                                    minWidth: "14rem",
                                    background: color,
                                 }}
                              />
                              {dataError?.edad && !dataSend?.edad && (
                                 <span className="text-red-500">
                                    Seleccione una fecha
                                 </span>
                              )}
                           </div>
                        </div>

                        <div className="flex flex-wrap gap-3 flex-1">
                           <div className="flex flex-wrap flex-column flex-1">
                              <label className="datos">Sexo</label>
                              <Dropdown
                                 value={optionFilter(
                                    optionsSexo,
                                    dataSend?.sexo_id
                                 )}
                                 onChange={(e) =>
                                    setDataSend({
                                       ...dataSend,
                                       sexo_id: e.value.id,
                                    })
                                 }
                                 options={optionsSexo}
                                 optionLabel="name"
                                 placeholder="Seleccionar"
                                 className="flex-1 mt-2 max-h-3rem"
                                 style={{
                                    minWidth: "14rem",
                                    background: color,
                                 }}
                                 disabled={disabled}
                              />
                              {dataError?.sexo_id && !dataSend?.sexo_id && (
                                 <span className="text-red-500">
                                    Seleccione un genero
                                 </span>
                              )}
                           </div>
                           <div className="flex flex-wrap flex-column flex-1">
                              <label className="datos">Religión</label>
                              {/* <InputText value={imputRel} onChange={(e) => setimputRel(e.target.value)} className="ingresoR" placeholder="Católico" /> */}
                              <Dropdown
                                 value={optionFilter(
                                    reli,
                                    dataSend?.religion_id
                                 )}
                                 onChange={(e) =>
                                    setDataSend({
                                       ...dataSend,
                                       religion_id: e.value.id,
                                    })
                                 }
                                 options={reli}
                                 optionLabel="name"
                                 style={{
                                    minWidth: "14rem",
                                    background: color,
                                 }}
                                 placeholder="Seleccionar"
                                 className="flex-1 mt-2 max-h-3rem"
                                 disabled={disabled}
                              />
                              {dataError?.religion_id &&
                                 !dataSend?.religion_id && (
                                    <span className="text-red-500">
                                       Seleccione una religión
                                    </span>
                                 )}
                           </div>
                        </div>
                     </div>
                     <div className="flex flex-wrap gap-3">
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Departamento</label>
                           <Dropdown
                              value={optionFilter(
                                 ubicaiones,
                                 dataSend?.nacimiento_departamento
                              )}
                              onChange={(e) =>
                                 handleSelectUbicacion("departamento", e)
                              }
                              options={ubicaiones}
                              optionLabel="departamento"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              style={{ minWidth: "14rem", background: color }}
                              disabled={disabled}
                           />
                           {dataError?.nacimiento_departamento &&
                              !dataSend?.nacimiento_departamento && (
                                 <span className="text-red-500">
                                    Seleccione un departamento
                                 </span>
                              )}
                        </div>
                        <div
                           className="flex flex-wrap flex-column flex-1"
                           style={{ width: "370px" }}
                        >
                           <label className="datos">Provincia</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 optionProvicia,
                                 dataSend?.nacimiento_provincia
                              )}
                              onChange={(e) =>
                                 handleSelectUbicacion("provincia", e)
                              }
                              options={optionProvicia}
                              optionLabel="provincia"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={
                                 !dataSend?.nacimiento_departamento || disabled
                              }
                           />
                           {dataError?.nacimiento_provincia &&
                              !dataSend?.nacimiento_provincia && (
                                 <span className="text-red-500">
                                    Seleccione una provincia
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Distrito</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 optionDistrito,
                                 dataSend?.distrito_id
                              )}
                              onChange={(e) =>
                                 handleSelectUbicacion("distrito", e)
                              }
                              options={optionDistrito}
                              optionLabel="distrito"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={
                                 !dataSend?.nacimiento_provincia || disabled
                              }
                           />
                           {dataError?.distrito_id &&
                              !dataSend?.distrito_id && (
                                 <span className="text-red-500">
                                    Seleccione un distrito
                                 </span>
                              )}
                        </div>
                     </div>
                  </div>
               </div>

               {/* Datos de domicilio */}
               <div
                  className="w-full flex flex-wrap flex-column gap-3"
                  style={{ marginTop: "-7px" }}
               >
                  <div className="mx-4 bg-white border-round-xl flex flex-wrap flex-row p-3 gap-3">
                     <p className="subtituloR">Datos de Domicilio</p>
                     <div className="flex flex-wrap flex-row gap-3 w-full">
                        {/* <div className="flex flex-wrap flex-column flex-1">
                                <label className="datos">País</label>
                                <Dropdown value={optionFilter(pais, dataSend?.domicilio_pais)} onChange={(e) => setDataSend({...dataSend, domicilio_pais: e.value.id})} options={pais} optionLabel="name"
                                    placeholder="Perú" className="flex-1 mt-2 max-h-3rem" />
                                {dataError?.domicilio_pais && !dataSend?.domicilio_pais && <span className="text-red-500">Seleccione un pais</span>}
                            </div> */}
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Departamento</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 ubicaiones,
                                 dataSend?.domicilio_departamento
                              )}
                              onChange={(e) =>
                                 handleSelectUbicacion("departamento", e, true)
                              }
                              options={ubicaiones}
                              optionLabel="departamento"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                           {dataError?.domicilio_departamento &&
                              !dataSend?.domicilio_departamento && (
                                 <span className="text-red-500">
                                    Seleccione un departamento
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Provincia</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 optionProviciaAdomicilio,
                                 dataSend?.domicilio_provincia
                              )}
                              onChange={(e) =>
                                 handleSelectUbicacion("provincia", e, true)
                              }
                              options={optionProviciaAdomicilio}
                              optionLabel="provincia"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={
                                 !dataSend?.domicilio_departamento || disabled
                              }
                           />
                           {dataError?.domicilio_provincia &&
                              !dataSend?.domicilio_provincia && (
                                 <span className="text-red-500">
                                    Seleccione un provincia
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Distrito</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 optionDistritoAdomicilio,
                                 dataSend?.distrito_domicilio_id
                              )}
                              onChange={(e) =>
                                 handleSelectUbicacion("distrito", e, true)
                              }
                              options={optionDistritoAdomicilio}
                              optionLabel="distrito"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={
                                 !dataSend.domicilio_provincia || disabled
                              }
                           />
                           {dataError?.distrito_domicilio_id &&
                              !dataSend?.distrito_domicilio_id && (
                                 <span className="text-red-500">
                                    seleccione un distrito
                                 </span>
                              )}
                        </div>
                     </div>
                     <div className="flex flex-wrap flex-row gap-3 w-full">
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Dirección</label>
                           <InputText
                              style={{ background: color }}
                              value={dataSend?.direccion}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    direccion: e.target.value,
                                 })
                              }
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                           {dataError?.direccion && !dataSend?.direccion && (
                              <span className="text-red-500">
                                 ingrese dirreción
                              </span>
                           )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Nro. de Registro</label>
                           <InputText
                              style={{ background: color }}
                              value={dataSend?.numero_registro}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    numero_registro: e.target.value,
                                 })
                              }
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  className="w-full mb-4 flex flex-wrap flex-column gap-3"
                  style={{ marginTop: "-10px" }}
               >
                  {/* Otros Datos */}
                  <div className="mx-4 bg-white border-round-xl flex flex-wrap flex-row p-3 gap-3">
                     <p className="subtituloR">Otros Datos</p>
                     <div className="flex flex-wrap flex-row gap-3 w-full">
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Estado Civil</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 Ec,
                                 dataSend?.estado_civil_id
                              )}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    estado_civil_id: e.value.id,
                                 })
                              }
                              options={Ec}
                              optionLabel="name"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                           {dataError?.estado_civil_id &&
                              !dataSend?.estado_civil_id && (
                                 <span className="text-red-500">
                                    Seleccione un estado
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Grado de Instrucción</label>
                           <Dropdown
                              style={{ background: color }}
                              value={optionFilter(
                                 gradoinsti,
                                 dataSend?.grado_instruccion_id
                              )}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    grado_instruccion_id: e.value.id,
                                 })
                              }
                              options={gradoinsti}
                              optionLabel="name"
                              placeholder="Seleccionar"
                              className="flex-1 mt-2 max-h-3rem"
                              disabled={disabled}
                           />
                           {dataError?.grado_instruccion_id &&
                              !dataSend?.grado_instruccion_id && (
                                 <span className="text-red-500">
                                    Seleccione un grado
                                 </span>
                              )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">E-mail</label>
                           <InputText
                              style={{ background: color }}
                              value={dataSend?.correo}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    correo: e.target.value,
                                 })
                              }
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="example@mail.com"
                              disabled={disabled}
                           />
                           {dataError?.correo && !dataSend?.correo && (
                              <span className="text-red-500">
                                 Ingrese un correo
                              </span>
                           )}
                        </div>
                     </div>
                     <div className="flex flex-wrap flex-row gap-3 w-full">
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Teléfono Fijo</label>
                           <InputText
                              style={{ background: color }}
                              value={dataSend?.telefono}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    telefono: e.target.value,
                                 })
                              }
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="(01) 0000000"
                              disabled={disabled}
                           />
                           {dataError?.telefono && !dataSend?.telefono && (
                              <span className="text-red-500">
                                 Ingrese un numero telefonico
                              </span>
                           )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">Teléfono Celular</label>
                           <InputText
                              style={{ background: color }}
                              value={dataSend?.celular}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    celular: e.target.value,
                                 })
                              }
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="000000000"
                              disabled={disabled}
                           />
                           {dataError?.celular && !dataSend?.celular && (
                              <span className="text-red-500">
                                 Ingrese un numero de celular
                              </span>
                           )}
                        </div>
                        <div className="flex flex-wrap flex-column flex-1">
                           <label className="datos">
                              Contactenos en caso de Emergencia
                           </label>
                           <InputText
                              style={{ background: color }}
                              value={dataSend?.telefono_emergencia}
                              onChange={(e) =>
                                 setDataSend({
                                    ...dataSend,
                                    telefono_emergencia: e.target.value,
                                 })
                              }
                              className="flex-1 mt-2 max-h-3rem"
                              placeholder="000000000"
                              disabled={disabled}
                           />
                           {dataError?.telefono_emergencia &&
                              !dataSend?.telefono_emergencia && (
                                 <span className="text-red-500">
                                    Rellene el campo
                                 </span>
                              )}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>
      </Dialog>
   );
};
export default ModalActulizarDatos;

import React, { createContext, useState } from "react";

export const MGContext = createContext(undefined);

export const MGProvider = (props) => {
   const [atencionStatus, setAtencionStatus] = useState(0);
   const [timeStart, setTimeStart] = useState("");
   const [idMG, setIdMG] = useState(0);
   const [desabilitarPractica, setDesabilitarPractica] = useState(true);
   const [habNocContext, setHabNocContext] = useState({
      frecuencia_id: "",
      tipo_tiempo_id: "",
      cantidad: "",
      practica: "",
      medicamento: "",
      observaciones: "",
      habito_deporte: [],
      otros: "",
      habitos: [
         {
            tipo_habito_id: 1,
            nombre: "Alcohol",
            frecuencia: "",
            tiempo: "",
            tipo: "",
            cantidad: "",
            opcion: null,
         },
         {
            tipo_habito_id: 2,
            nombre: "Tabaco",
            frecuencia: "",
            tiempo: "",
            tipo: "",
            cantidad: "",
            opcion: null,
         },
         {
            tipo_habito_id: 3,
            nombre: "Droga",
            frecuencia: "",
            tiempo: "",
            tipo: "",
            cantidad: "",
            opcion: null,
         },
         {
            tipo_habito_id: 4,
            nombre: "Café",
            frecuencia: "",
            tiempo: "",
            tipo: "",
            cantidad: "",
            opcion: null,
         },
      ],
   });
   const [antFamContext, setAntFamContext] = useState({

      numero_hijos_vivos: "",
      numero_hijos_fallecidos: "",
      familiar: [
         {
            tipo_familiar_id: 1,
            med_familiar_patologia: [
               {
                  patologia_id: 327,
                  comentario: "",
                  nombre: "Ninguna"
               }
            ],
            comentario: ""
         },
         {
            tipo_familiar_id: 2,
            med_familiar_patologia: [
               {
                  patologia_id: 327,
                  comentario: "",
                  nombre: "Ninguna"
               }
            ],
            comentario: ""
         },
         {
            tipo_familiar_id: 5,
            comentario: "",
            med_familiar_patologia: [
               {
                  patologia_id: 327,
                  comentario: "",
                  nombre: "Ninguna"
               }
            ]
         },
         {
            tipo_familiar_id: 6,
            comentario: "",
            med_familiar_patologia: [
               {
                  patologia_id: 327,
                  comentario: "",
                  nombre: "Ninguna"
               }
            ]
         },
         {
            tipo_familiar_id: 7,
            comentario: "",
            med_familiar_patologia: [
               {
                  patologia_id: 327,
                  comentario: "",
                  nombre: "Ninguna"
               }
            ]
         }
      ],
   });
   const [antPerContext, setAntPerContext] = useState({
      inmunizaciones: "",
      inmunizaciones_id: [],
      med_ant_inmunizaciones_personal: [],
      med_ant_patologia_personal: [{
         patologia_id: 327,
         enfermedad: "Ninguno",
         comentario: ""
      }],
      med_ant_absentismo: [],
   });
   const [antGinContext, setAntGinContext] = useState({
      fur: null,
      fur_date: null,
      rc: null,
      fup: null,
      fup_date: null,
      menarquia: null,
      ultimopap: null,
      ultimopap_date: null,
      estado: null,
      metodos_anticonceptivos: null,
      gestaciones: {
         gestaciones: null,
         abortos: null,
         partos: null,
         p_prematuros: null,
         p_eutacico: null,
         p_distocias: null,
         cesareas: null,
         fecha_cesarea: null,
         fecha_cesarea_date: null,
      },
      mamografia: {
         se_hizo: null,
         fecha: null,
         estado: null,
         resultado: null,
         fecha_date: null,
      },
   });


   // const [exploracionDataContext, setExploracionDataContext] = useState({
   //    med_eje_detalle: {
   //       patologia_id: null,
   //       resultado: null,
   //       observacion: null,
   //    },
   //    med_eje_anterior: {
   //       cervical_id_anterior: {
   //          patologia_id: "1. Cervical",
   //          resultado: {code:"N",name:"Normal"},
   //          observacion: "",
   //       },
   //       dorsal_id_anterior: {
   //          patologia_id: "2. Dorsal",
   //          resultado: {code:"N",name:"Normal"},
   //          observacion: "",
   //       },
   //       lumbar_id_anterior: {
   //          patologia_id: "2. Dorsal",
   //          resultado: {code:"N",name:"Normal"},
   //          observacion: "",
   //       },
   //    },
   //    med_eje_lateral: {
   //       cervical_id_anterior: {
   //          patologia_id: "1. Cervical",
   //          resultado: {code:"N",name:"Normal"},
   //          observacion: "",
   //       },
   //       dorsal_id_anterior: {
   //          patologia_id: "1. Cervical",
   //          resultado: {code:"N",name:"Normal"},
   //          observacion: "",
   //       },
   //    },
   //    med_movilidad: {
   //       archivo: null,
   //    },
   //    med_movilidad_patologia:
   //    {
   //       "r1": {
   //          "patologia_id": "Cervical - Flexión",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Cervical - Extensión",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Cervical - Laterización Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r4": {
   //          "patologia_id": "Cervical - Laterización Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r5": {
   //          "patologia_id": "Cervical - Rotación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r6": {
   //          "patologia_id": "Cervical - Rotación Izquierda",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r7": {
   //          "patologia_id": "Cervical - Irradiación",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r8": {
   //          "patologia_id": "Lumbar - Flexión",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r9": {
   //          "patologia_id": "Lumbar - Extensión",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r10": {
   //          "patologia_id": "Lumbar - Laterización Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r11": {
   //          "patologia_id": "Lumbar - Laterización Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r12": {
   //          "patologia_id": "Lumbar - Rotación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r13": {
   //          "patologia_id": "Lumbar - Rotación Izquierda",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r14": {
   //          "patologia_id": "Lumbar - Irradiación",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    }
   //    ,
   //    med_exploracion_patologica: {
   //       "r1": {
   //          "patologia_id": "Lasègue Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Lasègue Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Schover",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    },
   //    med_palpacion_patologia: {
   //       "r1": {
   //          "patologia_id": "Cervical - Apófisis Espinosas Dolor",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Cervical - Contractura Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Dorsal - Apófisis Espinosas Dolor",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r4": {
   //          "patologia_id": "Dorsal - Contractura Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r5": {
   //          "patologia_id": "Lumbar - Apófisis Espinosas Dolor",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r6": {
   //          "patologia_id": "Lumbar - Contractura Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    },
   //    med_art_movilidad_patologia: {
   //       "r1": {
   //          "patologia_id": "Hombro - Abducción Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Hombro - Abducción Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Hombro - Flexión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r4": {
   //          "patologia_id": "Hombro - Flexión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r5": {
   //          "patologia_id": "Hombro - Extensión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r6": {
   //          "patologia_id": "Hombro - Extensión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r7": {
   //          "patologia_id": "Hombro - Rotación Externa Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r8": {
   //          "patologia_id": "Hombro - Rotación Externa Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r9": {
   //          "patologia_id": "Hombro - Rotación Interna Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r10": {
   //          "patologia_id": "Hombro - Rotación Interna Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r11": {
   //          "patologia_id": "Hombro - Irradiación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r12": {
   //          "patologia_id": "Hombro - Irradiación Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r13": {
   //          "patologia_id": "Hombro - ALT. Masa Muscular Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r14": {
   //          "patologia_id": "Hombro - ALT. Masa Muscular Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r15": {
   //          "patologia_id": "Codo - Abducción Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r16": {
   //          "patologia_id": "Codo - Abducción Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r17": {
   //          "patologia_id": "Codo - Flexión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r18": {
   //          "patologia_id": "Codo - Flexión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r19": {
   //          "patologia_id": "Codo - Extensión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r20": {
   //          "patologia_id": "Codo - Extensión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r21": {
   //          "patologia_id": "Codo - Rotación Externa Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r22": {
   //          "patologia_id": "Codo - Rotación Externa Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r23": {
   //          "patologia_id": "Codo - Rotación Interna Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r24": {
   //          "patologia_id": "Codo - Rotación Interna Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r25": {
   //          "patologia_id": "Codo - Irradiación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r26": {
   //          "patologia_id": "Codo - Irradiación Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r27": {
   //          "patologia_id": "Codo - ALT. Masa Muscular Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r28": {
   //          "patologia_id": "Codo - ALT. Masa Muscular Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r29": {
   //          "patologia_id": "Muñeca - Abducción Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r30": {
   //          "patologia_id": "Muñeca - Abducción Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r31": {
   //          "patologia_id": "Muñeca - Flexión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r32": {
   //          "patologia_id": "Muñeca - Flexión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r33": {
   //          "patologia_id": "Muñeca - Extensión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r34": {
   //          "patologia_id": "Muñeca - Extensión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r35": {
   //          "patologia_id": "Muñeca - Rotación Externa Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r36": {
   //          "patologia_id": "Muñeca - Rotación Externa Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r37": {
   //          "patologia_id": "Muñeca - Rotación Interna Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r38": {
   //          "patologia_id": "Muñeca - Rotación Interna Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r39": {
   //          "patologia_id": "Muñeca - Irradiación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r40": {
   //          "patologia_id": "Muñeca - Irradiación Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r41": {
   //          "patologia_id": "Muñeca - ALT. Masa Muscular Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r42": {
   //          "patologia_id": "Muñeca - ALT. Masa Muscular Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r43": {
   //          "patologia_id": "Cadera - Abducción Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r44": {
   //          "patologia_id": "Cadera - Abducción Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r45": {
   //          "patologia_id": "Cadera - Flexión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r46": {
   //          "patologia_id": "Cadera - Flexión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r47": {
   //          "patologia_id": "Cadera - Extensión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r48": {
   //          "patologia_id": "Cadera - Extensión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r49": {
   //          "patologia_id": "Cadera - Rotación Externa Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r50": {
   //          "patologia_id": "Cadera - Rotación Externa Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r51": {
   //          "patologia_id": "Cadera - Rotación Interna Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r52": {
   //          "patologia_id": "Cadera - Rotación Interna Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r53": {
   //          "patologia_id": "Cadera - Irradiación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r54": {
   //          "patologia_id": "Cadera - Irradiación Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r55": {
   //          "patologia_id": "Cadera - ALT. Masa Muscular Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r56": {
   //          "patologia_id": "Cadera - ALT. Masa Muscular Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r57": {
   //          "patologia_id": "Rodillo - Abducción Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r58": {
   //          "patologia_id": "Rodillo - Abducción Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r59": {
   //          "patologia_id": "Rodillo - Flexión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r60": {
   //          "patologia_id": "Rodillo - Flexión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r61": {
   //          "patologia_id": "Rodillo - Extensión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r62": {
   //          "patologia_id": "Rodillo - Extensión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r63": {
   //          "patologia_id": "Rodillo - Rotación Externa Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r64": {
   //          "patologia_id": "Rodillo - Rotación Externa Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r65": {
   //          "patologia_id": "Rodillo - Rotación Interna Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r66": {
   //          "patologia_id": "Rodillo - Rotación Interna Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r67": {
   //          "patologia_id": "Rodillo - Irradiación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r68": {
   //          "patologia_id": "Rodillo - Irradiación Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r69": {
   //          "patologia_id": "Rodillo - ALT. Masa Muscular Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r70": {
   //          "patologia_id": "Rodillo - ALT. Masa Muscular Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r71": {
   //          "patologia_id": "Tobillo - Abducción Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r72": {
   //          "patologia_id": "Tobillo - Abducción Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r73": {
   //          "patologia_id": "Tobillo - Flexión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r74": {
   //          "patologia_id": "Tobillo - Flexión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r75": {
   //          "patologia_id": "Tobillo - Extensión Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r76": {
   //          "patologia_id": "Tobillo - Extensión Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r77": {
   //          "patologia_id": "Tobillo - Rotación Externa Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r78": {
   //          "patologia_id": "Tobillo - Rotación Externa Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r79": {
   //          "patologia_id": "Tobillo - Rotación Interna Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r80": {
   //          "patologia_id": "Tobillo - Rotación Interna Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r81": {
   //          "patologia_id": "Tobillo - Irradiación Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r82": {
   //          "patologia_id": "Tobillo - Irradiación Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r83": {
   //          "patologia_id": "Tobillo - ALT. Masa Muscular Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r84": {
   //          "patologia_id": "Tobillo - ALT. Masa Muscular Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    },
   //    med_art_test_patologia: {
   //       "r1": {
   //          "patologia_id": "Test de Phalen Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Test de Phalen Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Test de Thinel Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r4": {
   //          "patologia_id": "Test de Thinel Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r5": {
   //          "patologia_id": "Pie Derecho",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r6": {
   //          "patologia_id": "Pie Izquierdo",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    },
   //    med_motor_patologia: {
   //       "r1": {
   //          "patologia_id": "Maniobra de Barre",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Maniobra de Mingazini",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Movimiento Involuntario",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r4": {
   //          "patologia_id": "Atrofia Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r5": {
   //          "patologia_id": "Asimitría Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r6": {
   //          "patologia_id": "Hipotonía Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r7": {
   //          "patologia_id": "Hipertonía Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r8": {
   //          "patologia_id": "Fuerza Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    },
   //    med_test_coordinacion_patologia: {
   //       "r1": {
   //          "patologia_id": "Maniobra de Barre",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r2": {
   //          "patologia_id": "Maniobra de Mingazini",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r3": {
   //          "patologia_id": "Movimiento Involuntario",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r4": {
   //          "patologia_id": "Atrofia Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       },
   //       "r5": {
   //          "patologia_id": "Asimitría Muscular",
   //          "resultado": {code:"N",name:"Normal"},
   //          "observacion": ""
   //       }
   //    },

   //    med_ana_metodo: {
   //       levanta: null,
   //       empuja: null,
   //       arrastra: null,
   //    },
   //    med_anamnesis: {
   //       puesto: null,
   //       sedente: null,
   //       bipedestacion: null,
   //       peso_inf: null,
   //       peso_sub: null,
   //       movimiento_manual_carga: "No",
   //       movimiento_repetitivo: "No",
   //       postura_forzada: "No",
   //       metodo: null,
   //       pvd: "No",
   //       horas: null,
   //       dominancia: "Diestro",
   //       diagnostico: null,
   //       recomendaciones: null,
   //       conclusiones: { "Dropdown": "", "Comentario": "" },
   //    },
   // });
   const [exploracionDataContext, setExploracionDataContext] = useState({
      // DESVIACION
      "desviacion": {
         "archivo": null,
         "eje_anterior": [
            {
               "patologia_id": 49,
               "resultado":  "N",
               "observacion": ""
            },
            {
               "patologia_id": 50,
               "resultado":  "N",
               "observacion": ""
            },
            {
               "patologia_id": 51,
               "resultado":  "N",
               "observacion": ""
            }
         ],
         "eje_lateral": [
            {
               "patologia_id": 50,
               "resultado":  "N",
               "observacion": ""
            },
            {
               "patologia_id": 51,
               "resultado":  "N",
               "observacion": ""
            }
         ]
      },

      // MOVILIDAD
      "movilidad": {
         "archivo": null,
      },
      "movilidad_patologia": [
         {
            "patologia_id": 54,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 55,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 56,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 57,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 58,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 59,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 60,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 61,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 62,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 63,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 64,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 65,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 66,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 67,
            "resultado":  "N",
            "observacion": ""
         }
      ],


      // EXPLORACION PALPACION
      "med_exploracion_patologias": [
         {
            "patologia_id": 68,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 69,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 70,
            "resultado":  "N",
            "observacion": ""
         }

      ],
      "med_palpacion_patologias": [
         {
            "patologia_id": 71,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 72,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 73,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 74,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 75,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 76,
            "resultado":  "N",
            "observacion": ""
         }
      ],

      // ARTICULACION
      "med_art_movilidad_patologias": [
         {
            "patologia_id": 77,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 78,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 79,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 80,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 81,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 82,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 83,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 84,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 85,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 86,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 87,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 88,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 89,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 90,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 91,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 92,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 93,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 94,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 95,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 96,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 97,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 98,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 99,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 100,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 101,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 102,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 103,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 104,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 105,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 106,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 107,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 108,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 109,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 110,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 111,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 112,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 113,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 114,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 115,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 116,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 117,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 118,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 119,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 120,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 121,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 122,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 123,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 124,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 125,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 126,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 127,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 128,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 129,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 130,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 131,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 132,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 133,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 134,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 135,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 136,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 137,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 138,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 139,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 140,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 141,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 142,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 143,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 144,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 145,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 146,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 147,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 148,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 149,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 150,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 151,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 152,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 153,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 154,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 155,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 156,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 157,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 158,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 159,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 160,
            "resultado":  "N",
            "observacion": ""
         }
      ],
      "med_art_test_patologias": [
         {
            "patologia_id": 161,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 162,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 163,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 164,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 165,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 166,
            "resultado":  "N",
            "observacion": ""
         }
      ],

      // SISTEMA MOTOR Y COORDINACION
      "motor_cordinacion": {
         "archivo": null
      },
      "med_motor_patologias": [
         {
            "patologia_id": 167,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 168,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 169,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 170,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 171,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 172,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 173,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 174,
            "resultado":  "N",
            "observacion": ""
         }
      ],
      "med_test_coordinacion_patologias": [
         {
            "patologia_id": 175,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 176,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 177,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 178,
            "resultado":  "N",
            "observacion": ""
         },
         {
            "patologia_id": 179,
            "resultado":  "N",
            "observacion": ""
         }
      ],

      // ANAMNESIS DIAGNOSTICO
      "med_anamnesis":
      {
         "puesto": "",
         "sedente": "",
         "bipedestacion": "",
         "peso_inf": 0,
         "peso_sub": 0,
         "movimiento_manual_carga": "",
         "movimiento_repetitivo": "",
         "postura_forzada": "",
         "pvd": "",
         "horas": 0,
         "dominancia": "",
         "diagnostico": "",
         "recomendaciones": "",
         "conclusiones": "",
         "conclusiones_detalles": ""
      }
      ,
      "levanta": false,
      "empuja": false,
      "arrastra": false
   });
   const [evaluacionMedicaContext, setEvaluacionMedicaContext] = useState({
      med_dx_conclusion: {
         observaciones: "",
         recomendaciones: "",
         restricciones: "",
         fecha_valido: "",
         resultado_id: "",
         medico_auditor: {
            nombre_completo: ""
         },
         medico_evaluador: {
            nombre_completo: ""
         },
         medico_evaluador_id: null,
         medico_auditor_id: null,
         med_lista_general_diagnostico: [
            {
               tipo_diagnostico: null,
               ocupacional: "",
               importante: "",
               observacion: "",
               recomendacion: "",
               enfermedad_especifica_id: 1,
            },
         ],
      },
      med_interconsultas: [
         {
            med_especialidad_id: null,
            codigo: 123,
            fecha: "",
            diagnostico: "",
            motivo: "",
            fecha_proxima: "",
            observaciones: "",
         },
      ],
   });
   const [examenFisicoContext, setExamenFisicoContext] = useState({
      med_examen: {
         anamnesis: "",
      },
      med_examen_detalle: [],
      med_detalle: {
         piezas_faltantes: "",
         piezas_mal_estado: "",
         sintomas_principales: "",
         tratamiento_recibido: "",

         tiempo_enfermedad: "",
         forma_inicio: "",
         curso: "",


         apetito: "",
         sed: "",
         orina: "",
         sueño: "",


         perdida_peso: "",
         obesidad: "",

         observaciones: "",
         archivo: "",
         name_file: "",
         url_image: ""
      },
   });
   const [evaluacionOsteomioarticularContext, setEvaluacionOsteomioarticularContext] = useState({
      observaciones: "",
      archivo: "",
      med_osteomioarticular_detalle: [
         {
            id: "",
            med_osteomioarticular_id: 1,
            // med_osteomioarticular: {},
            // med_casa: {},
            // med_trabajo: {},
            parte_cuerpo: "Nuca",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 2,
            parte_cuerpo: "Hombro Derecho",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 3,
            parte_cuerpo: "Hombro Izquierdo",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 4,
            parte_cuerpo: "Codo Derecho",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 5,
            parte_cuerpo: "Codo Izquierdo",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 6,
            parte_cuerpo: "Mano Izquierda",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 7,
            parte_cuerpo: "Mano Derecha",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 8,
            parte_cuerpo: "Columna alta (Dorso)",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 9,
            parte_cuerpo: "Columna baja (Lumbares)",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 10,
            parte_cuerpo: "Cadera derecha",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 11,
            parte_cuerpo: "Rodilla izquierda",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 12,
            parte_cuerpo: "Tobillo/Pie derecho",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
         {
            id: "",
            med_osteomioarticular_id: 13,
            parte_cuerpo: "Tobillo/Pie izquierdo",
            meses: "NO",
            incapacidad_casa: "NO",
            incapacidad_trabajo: "NO",
         },
      ],
   });
   const [anexo16Context, setAnexo16Context] = useState({
      observaciones: "",
      uso_medicamentos: "",
      aptitud: 1,
      med_anexo_preguntas: [
         {
            number: 1,
            med_preguntas_id: 1,
            pregunta: "Anemia",
            presente: false,
         },
         {
            number: 2,
            med_preguntas_id: 2,
            pregunta: "Cirugía mayor reciente",
            presente: false,
         },
         {
            number: 3,
            med_preguntas_id: 3,
            pregunta: "Desordenes de la coagulación trombosis etc.",
            presente: false,
         },
         {
            number: 4,
            med_preguntas_id: 4,
            pregunta: "Diabetes mellitus",
            presente: false,
         },
         {
            number: 5,
            med_preguntas_id: 5,
            pregunta: "Hipertensión arterial",
            presente: false,
         },
         {
            number: 6,
            med_preguntas_id: 6,
            pregunta: "Embarazo",
            presente: false,
         },
         {
            number: 7,
            med_preguntas_id: 7,
            pregunta: "Problemas neurológicos, epilepsia, vértigo, etc.",
            presente: false,
         },
         {
            number: 8,
            med_preguntas_id: 8,
            pregunta:
               "Infecciones recientes(especialmente, oídos, nariz, garganta)",
            presente: false,
         },
         {
            number: 9,
            med_preguntas_id: 9,
            pregunta: "Obesidad mórbida (imc mayor a 35 m/kg2)",
            presente: false,
         },
         {
            number: 10,
            med_preguntas_id: 10,
            pregunta: "Problemas cardiacos: marcapasos, coronariopatía, etc.",
            presente: false,
         },
         {
            number: 11,
            med_preguntas_id: 11,
            pregunta: "Problemas respiratorios: asma, epoc, etc.",
            presente: false,
         },
         {
            number: 12,
            med_preguntas_id: 12,
            pregunta: "Problemas oftalmológicos: retinopatía, glaucoma etc.",
            presente: false,
         },
         {
            number: 13,
            med_preguntas_id: 13,
            pregunta: "Problemas digestivos: ulceras, péptica, hepatitis, etc.",
            presente: false,
         },
         {
            number: 14,
            med_preguntas_id: 14,
            pregunta: "Apnea del sueño",
            presente: false,
         },
         {
            number: 15,
            med_preguntas_id: 15,
            pregunta: "Otra condición medica importante",
            presente: false,
         },
         {
            number: 16,
            med_preguntas_id: 16,
            pregunta: "Alergias",
            presente: false,
         },
      ],
   });
   const [examenAlturaContext, setExamenAlturaContext] = useState({
      med_prueba: [],
      med_personal_patologia: [],
      med_hallazgo_patologia: [],
      med_conclusion: {
         observaciones: "",
         recomendaciones: "",
         datos_medicos: "",
         nro_colegiatura: "",
         evaluador_id: null,
         med_resultado_id: null,
      },
   });
   const [musculoEsqueleticoContext, setMusculoEsqueleticoContext] = useState({
      med_flexibilidad: {
         nombre: "",
         observacion: "",
         archivo: "",
         file_name: "",
         file_url: "",
         med_fuerza_abdomen: {
            opcion: "",
            observacion: "",
         },
         med_cadera: {
            opcion: "",
            observacion: "",
         },
         med_muslo: {
            opcion: "",
            observacion: "",
         },
         med_abdomen_lateral: {
            opcion: "",
            observacion: "",
         },
      },
      med_rotacion: {
         archivo: "",
         file_name: "",
         file_url: "",
         nombre: "",
         observacion: "",
         med_abduccion_hombro: {
            opcion: "",
            observacion: "",
            dolor: "",
         },
         med_abduccion_hombros: {
            opcion: "",
            observacion: "",
            dolor: "",
         },
         med_rotacion_externa: {
            opcion: "",
            observacion: "",
            dolor: "",
         },
         med_rotacion_externa_hombro: {
            opcion: "",
            observacion: "",
            dolor: "",
         },
      },
   });

   const [habNocFisContext, setHabNocFisContext] = useState([]);
   const [diagMedicoContext, setDiagMedicoContext] = useState([]);
   const [interconsultaContext, setInterconsultaContext] = useState([]);
   const [selectedPatologiasP, setSelectedPatologiasP] = useState([{
      patologia_id: 327,
      comentario: "",
      nombre: "Ninguna"
   }]);
   const [selectedPatologiasM, setSelectedPatologiasM] = useState([{
      patologia_id: 327,
      comentario: "",
      nombre: "Ninguna"
   }]);
   const [selectedPatologiasH, setSelectedPatologiasH] = useState([{
      patologia_id: 327,
      comentario: "",
      nombre: "Ninguna"
   }]);
   const [selectedPatologiasAP, setSelectedPatologiasAP] = useState([{
      patologia_id: 327,
      comentario: "",
      nombre: "Ninguna"
   }]);
   const [selectedPatologiasAM, setSelectedPatologiasAM] = useState([{
      patologia_id: 327,
      comentario: "",
      nombre: "Ninguna"
   }]);

   const [EvOstemio, setEvOstemio] = useState([]);
   const [Recepcion, setRecepcion] = useState({
      documento: "",
      num_documento: "",
      num_codigo: "",
   });
   const [MuscEsqueletico01, setMuscEsqueletico01] = useState({
      med_flexibilidad: {
         nombre: "lsjdfksjdfs",
         observacion: "aldjelkda",
      },
      med_rotacion: {
         nombre: "sldja",
         observacion: "ldjlfdf",
      },
   });
   const [tamizajeDermatologico, setTamizajeDermatologico] = useState({
      tam_detalles: {
         sintomas_hallazgo: "",
         observaciones: "",
         recomendaciones: "",
         conclusiones: "",
         fecha: "",
         evaluador_id: null,
         resultado_id: null,
         archivo: null
      },
      Preguntas:
      {
         Rpta01: { respuesta: 'NO' },
         subRpta01: "",
         Rpta02: { respuesta: 'NO' },
         subRpta02: "",
         Rpta03: { respuesta: 'NO' },
         Rpta04: { respuesta: 'NO' },
         Rpta05: { respuesta: 'NO' },
         subRpta05: "",
         Rpta06: { respuesta: 'NO' },
         subRpta06: "",
         Rpta07: { respuesta: 'NO' },
         subRpta07: "",
         Rpta08: { respuesta: 'NO' },
         Rpta09: { respuesta: 'NO' },
         subRpta09: "",
         Rpta10: { respuesta: 'NO' },
         Rpta11: { respuesta: 'NO' },
         Rpta12: { respuesta: 'NO' },
      },
      tam_preguntas: [
         {
            "med_tam_derm_preguntas_id": 1,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 2,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 3,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 4,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 5,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 6,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 7,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 8,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 9,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 10,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 11,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 12,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 13,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 14,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 15,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 16,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 17,
            "respuesta": "NO",
            "descripcion": ""
         },
         {
            "med_tam_derm_preguntas_id": 18,
            "respuesta": "NO",
            "descripcion": ""
         }
      ]
   })

   /*CREAT NUEVA ATENCIÓN*/
   const [DatosPaciente, setDatosPaciente] = useState({});
   const [FirmaHuella, setFirmaHuella] = useState({});
   const [EvNeurologica, setEvNeurologica] = useState({
      nistagmus_espontaneo: "",
      nistagmus_posicion: "",
      nistagmus_agitacion: "",
      prueba_romberg: "",
      posturografia_dinamica: "",
      posturografia_estatica: "",
      observaciones: "",
      archivo: ""
   });


   const [PruebaEsfuerzo, setPruebaEsfuerzo] = useState({
      sintomas_hallazgos: "",
      observaciones: "",
      recomendaciones: "",
      conclusiones: "",
      fecha: "",
      evaluador_id: "",
      evaluador_nombre: "",
      evaluador_colegiatura: "",
      resultado: "",
      resultado_id: "",
      nombre_archivo: "",
      archivo: "",
   });

   return (
      <MGContext.Provider
         value={{
            // datosContext,
            // setDatosContext,
            // preguntasContext,
            // setPreguntasContext,
            // atencionStatus,
            // setAtencionStatus,}
            PruebaEsfuerzo,
            setPruebaEsfuerzo,
            EvNeurologica,
            setEvNeurologica,
            timeStart,
            setTimeStart,
            habNocContext,
            setHabNocFisContext,
            antFamContext,
            setAntFamContext,
            selectedPatologiasP,
            setSelectedPatologiasP,
            selectedPatologiasM,
            setSelectedPatologiasM,
            selectedPatologiasH,
            setSelectedPatologiasH,
            selectedPatologiasAP,
            setSelectedPatologiasAP,
            selectedPatologiasAM,
            setSelectedPatologiasAM,
            antPerContext,
            setAntPerContext,
            evaluacionMedicaContext,
            setEvaluacionMedicaContext,
            antGinContext,
            setAntGinContext,
            habNocFisContext,
            setHabNocContext,
            setExploracionDataContext,
            setExamenFisicoContext,
            setEvOstemio,
            setAnexo16Context,
            setExamenAlturaContext,
            setMusculoEsqueleticoContext,
            exploracionDataContext,
            examenFisicoContext,
            EvOstemio,
            anexo16Context,
            examenAlturaContext,
            musculoEsqueleticoContext,
            atencionStatus,
            setAtencionStatus,
            MuscEsqueletico01,
            setMuscEsqueletico01,
            Recepcion,
            setRecepcion,
            evaluacionOsteomioarticularContext,
            setEvaluacionOsteomioarticularContext,
            DatosPaciente,
            setDatosPaciente,
            FirmaHuella,
            setFirmaHuella,
            diagMedicoContext,
            setDiagMedicoContext,
            interconsultaContext,
            setInterconsultaContext,
            idMG,
            setIdMG,
            desabilitarPractica,
            setDesabilitarPractica,

            tamizajeDermatologico,
            setTamizajeDermatologico
         }}
      >
         {props.children}
      </MGContext.Provider>
   );
};
import React, { useState, useEffect, useContext, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';
import { useParams } from "react-router-dom";
//ORIGINALS
import S1Customer from "../../../../../Components/Sales/NewClient/S1Customer/S1Customer";
import Contacto from "../../../../../Components/Sales/NewClient/Contacto/Contacto";
//SKELETONS
import SS1Customer from "../../../../../Components/Sales/SkeletonSales/SS1Customer/SS1Customer";
import SS2Contact from "../../../../../Components/Sales/SkeletonSales/SS2Contact/SS2Contact";
//SERVICES
import SalesCommon from "../../../../../Components/Sales/Services/SalesCommon/SalesCommon";
import SC1Customer from "../../../../../Components/Sales/Services/Clinica/SC1Customer/SC1Customer";
import SC2Contact from "../../../../../Components/Sales/Services/Clinica/SC2Contact/SC2Contact";
//OTROS COMPONENTES DE VENTAS
import StepsV from "../../../../../Components/Ventas/StepsV/StepsV";
import { SalesContext } from "../../../../../Components/Sales/SalesContext/SalesContext";



const PageClienteEmpresa = () => {
   const { sales, setSales } = useContext(SalesContext);
   const [idRubro, setIdRubro] = useState(null);
   const { getLocations, getTypesOfCustomers, getSectors } = SalesCommon();
   const { postCompany, putCompany, getPackages, getCompanyID } = SC1Customer();
   const { getContact, getContactID, postContact, putContact, deleteContact } = SC2Contact();

   const { id } = useParams();
   const [items, setItems] = useState([
      {
         label: 'Empresa',
         estado: false,
      },
      {
         label: 'Contacto',
         estado: false,
      },


   ]);
   const [loading, setLoading] = useState(true);
   const [locations, setLocations] = useState(null);
   const [typesOfCustomers, setTypesOfCustomers] = useState(null);
   const [sectors, setSectors] = useState(null);
   const [activeIndex, setActiveIndex] = useState(0);
   const toast = useRef(null);

   const getContent = (index) => {
      switch (index) {
         case 0:
            return !loading ? <S1Customer setIdRubro={setIdRubro} ubicaciones={locations} tipos_clientes={typesOfCustomers} rubros={sectors} showToast={showToast} validateForm={validateForm} postClinic={postCompany} putClinic={putCompany} getID={getCompanyID} /> : <SS1Customer />;
         case 1:
            return !loading ? <Contacto getContact={getContact} getContactID={getContactID} postContact={postContact} putContact={putContact} deleteContact={deleteContact} validateForm={validateForm} showToast={showToast} /> : <SS2Contact />;
         case 2:
            return !loading ? <Contacto getContact={getContact} getContactID={getContactID} postContact={postContact} putContact={putContact} deleteContact={deleteContact} validateForm={validateForm} showToast={showToast} /> : <SS2Contact />;
         default:
            return null;
      }
   };
   const handleBack = () => {
      const updatedItems = [...items];
      updatedItems[activeIndex].estado = false;
      updatedItems[activeIndex - 1].estado = false;
      setItems(updatedItems);
      setActiveIndex(activeIndex - 1);
   };
   const handleNext = () => {
      if (sales?.GCEmpresa?.clinica_id) {
         const updatedItems = [...items];
         updatedItems[activeIndex].estado = true;
         setItems(updatedItems);
         setActiveIndex(activeIndex + 1);
      }
   };
   const validateForm = (formulario) => {
      for (const propiedad in formulario) {
         if (formulario[propiedad] === "") {
            return false;
         }
      }
      return true;
   };
   const showToast = (severity, summary, detail) => {
      toast.current.show({ severity: severity, summary: summary, detail: detail });
   };

   useEffect(() => {
      const fetchDataCopy = async () => {
         const promises = [
            getTypesOfCustomers(),
            getLocations(),
            getSectors(),
            getPackages(),
         ];

         try {
            const results = await Promise.allSettled(promises);

            const valueTipoClientes = results[0].status === "fulfilled" ? results[0].value : null;
            const valueUbicaciones = results[1].status === "fulfilled" ? results[1].value : null;
            const valueRubros = results[2].status === "fulfilled" ? results[2].value : null;

            setTypesOfCustomers(valueTipoClientes);
            setLocations(valueUbicaciones);
            setSectors(valueRubros);
            if (id) {
               setSales(prevState => ({ ...prevState, GCEmpresa: { ...prevState.GCEmpresa, clinica_id: id } }));
               await getCompanyID(id, valueUbicaciones, setSales);
            }
            setLoading(false);
         } catch (error) {
            console.error('Error en la llamada a la API:', error);
         }
      }
      fetchDataCopy();
   }, []);

   useEffect(() => {
      if (idRubro !== null) {
         const fetchDataCopy = async () => {
            const promises01 = [
               getSectors(idRubro),
            ];

            try {
               const results = await Promise.allSettled(promises01);
               const valueRubros = results[0].status === "fulfilled" ? results[0].value : null;
               setSectors(valueRubros);
            } catch (error) {
               console.error('Error', error);
            }
         }
         fetchDataCopy();
      }

   }, [idRubro]);

   const Ver = () => {
      //Lógica para visualización de datos de cliente
   };
   return (
      <div className="text-sm p-2 h-full flex flex-column justify-content-center">
         <Toast style={{ zIndex: "100" }} ref={toast} />
         <div className="flex gap-2 p-2 flex-wrap w-full">
            <div className="flex-1 flex flex-column gap-2">
               <p className="text-2xl text-indigo-500 font-bold" onClick={() => Ver()}>Nuevo cliente</p>
               <p className="flex align-items-center text-indigo-300 white-space-nowrap">Registra tu cliente potencial.</p>
            </div>
            <StepsV model={items} activeIndex={activeIndex} />
         </div>

         <div className="w-full flex flex-column gap-3 h-full p-1 ventas-scroll">
            {getContent(activeIndex)}

            <div className="flex justify-content-end gap-3">
               <Button
                  className={`bg-gray-300 border-gray-300 ${activeIndex === 0 ? 'hidden' : ''}`}
                  onClick={() => handleBack()}
                  icon="pi pi-chevron-left text-gray-500"
               />
               <Button
                  className={`bg-gray-300 border-gray-300 ${activeIndex < items.length - 1 ? '' : 'hidden'}`}
                  onClick={() => handleNext()}
                  icon="pi pi-chevron-right text-gray-500"
               />
            </div>
         </div>
      </div>
   );
}

export default PageClienteEmpresa;
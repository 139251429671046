import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Logo from './img/Detalles-icon.svg';
import CheckboxList from "./CheckBoxDetalles";

export default function DialogDetalles() {
  const [isVisible, setIsVisible] = useState(false);

  const handleCloseDialog = () => {
    setIsVisible(false);
  };

  const footerContent = (
    <div className="flex flex-row gap-2" style={{ justifyContent: 'center' }}>
      <Button label="Cancelar" onClick={handleCloseDialog} className="p-button-outlined p-button-secondary w-full" />
      <Button label="Enviar" icon="pi pi-send" onClick={handleCloseDialog} autoFocus style={{ display: 'flex', flexDirection: 'row-reverse' }} className="gap-2 w-full" />
    </div>
  );

  return (
    <div>
      <Button label="Ver detalles" icon="pi pi-info-circle" onClick={() => setIsVisible(true)}
        style={{ backgroundColor: 'transparent', borderColor: 'transparent', color: ' rgba(85, 85, 216, 1)' }}>
      </Button>
      <Dialog visible={isVisible} style={{ maxWidth: '582px' }} onHide={handleCloseDialog} footer={footerContent}
        header={
          <div className="flex flex-column">
            <div className="flex" style={{ width: '60px', height: '60px', background: '#EBEBFF', borderRadius: '10px', justifyContent: 'center', alignItems: 'center' }}>
              <img src={Logo} alt="" style={{ width: '35px', height: '35px' }} />
            </div>
            <div className="flex" style={{ justifyContent: 'space-between' }}>
              <div className="flex flex-column" style={{ marginTop: '15px', marginBottom: '15px' }}>
                <span style={{ fontWeight: '700', fontSize: '18px', color: '#5555D8' }}>Encuesta </span>
                <span style={{ fontWeight: '400', fontSize: '16px' }}>¿Podría seleccionar la procedencia de las ventas?</span>
              </div>
            </div>
          </div>
        }
      >
        <div>
          <CheckboxList />
        </div>
      </Dialog>
    </div>
  );
}

import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

//Componentes generales
import UpdateEmpresaIMGComponent from "../usuario/components/UpdateEmpresaIMGComponent";
import UpdateEmpresa from '../usuario/components/UpdateEmpresa'

//Imágenes únicas
import RazonsocialIcon from "../../../Images/Iconperfil/Razonsocial.png"
import RucIcon from "../../../Images/Iconperfil/Credit-Card.png"
import WebIcon from "../../../Images/Iconperfil/web.png"
import logoEmpresa from "../../../Images/logos/logoEmpresa.png";

//Estilos
import '../../../Style/PagePerfilGeneral/USU-2/EmpresaCoorporativo.css';
import '../../../Style/PagePerfil.css'

export default function EmpresaComponentCoorporativa({
    showToast,
    dataShow,
    data,
    setBregma,
    handleSubmitUpdate,
    getEmpresa,
    setDataShow,
    entidad,
    api
}) {
    const toast = useRef(null);
    const [visible, setVisible] = useState(false);
    const [visibleImg, setVisibleImg] = useState(false);

    /*CONVERTIR DE MAYUSCULA A MINUSCULA */
    const editText = (text, separador) => {
        if (text) {
            const palabras = text.split(" ")
            for (let i = 0; i < palabras.length; i++) {
                palabras[i] = (palabras[i][0]?.charAt(0).toUpperCase() || '') + palabras[i].substr(1).toLowerCase();
            }
            return (text ? palabras.join(" ") + (separador || '') : '');
        } else {
            return ''
        }
    }

    return (
        <div className="BASE_CONTENEDOR_PERSONA_AHPerfil">
            <Toast ref={toast} />
            {/* Img de empresa */}
            <div className='POSICIONAMIENTO_IMAGEN' style={{ width: 'auto', height: 'auto' }}>
                <img
                    src={dataShow.logo ?? logoEmpresa}
                    //src={AvatarMap}
                    alt="user"
                    className='IMGDEUSUARIO' />
                <Button icon="pi pi-pencil" className="BOTONEDITARIMGUSARIO flex flex-row" onClick={() => setVisibleImg(true)} />
            </div>
            <div className="CONTENEDOR_GENERAL">
                <div className='CONTENEDOR_BOTON_EDITAR'  >
                    {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                    <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{ margin: '0' }}>
                        <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                        <p className='TEXTOAGREGARUSUARIO'>Editar</p>

                    </Button>
                </div>
                <div className="CONTENEDOR_DATA_GENERAL">
                    {/* Datos y botones */}

                    <div className='CONTENEDOR_UP_AHP' style={{ borderBottom: '0.45px solid #88888E99' }}>
                        <div className="CONTENEDOR_DATA_CORPORA_TITU" >
                            <label className='TITULO_CONT_UP' htmlFor="">Datos de la {entidad} </label>
                            <label className='TITULO_CONT_UP_020' htmlFor="">
                                {editText(dataShow?.distrito?.provincia?.departamento?.departamento, '-') +
                                    editText(dataShow?.distrito?.provincia?.provincia, '-') +
                                    editText(dataShow?.distrito?.distrito) || 'No hay departamento'}
                            </label>
                        </div>
                        <div className="CONTENEDOR_DATA_CORPORA_TITU justify-content-end">
                            <h6 className="SUBTITULO_CONT_DOWN">
                                {dataShow?.celulares != null ? dataShow.celulares?.[0]?.celular || 'No hay número' : 'No hay número'}
                            </h6>
                        </div>
                    </div>

                    {/* Datos del usuario parte inferior */}
                    <div className="CONTENEDOR_DOWN_AHP">
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img alt="icono-razon-social" src={RazonsocialIcon} />
                            <h4 className='TITULO_CONT_DOWN'>Razón Social</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {dataShow?.razon_social || 'No hay razón social'}
                                {/* administracion@bregma.com.pe */}
                            </p>
                        </div>
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img alt="icono-ruc" src={RucIcon} />
                            <h4 className='TITULO_CONT_DOWN'>RUC</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {dataShow?.numero_documento || 'No hay documento'}
                                {/* 938210243 */}
                            </p>
                        </div>
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img alt="icono-web" src={WebIcon} />
                            <h4 className='TITULO_CONT_DOWN'>Correo</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {dataShow?.celulares != null ? dataShow?.correos[0]?.correo || 'Sin correo' : 'Sin correo'}
                                {/* 938210243 */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <UpdateEmpresa
                showToast={showToast}
                data={data}
                setBregma={setBregma}
                handleSubmitUpdate={handleSubmitUpdate}
                visible={visible}
                setVisible={setVisible}
                entidad={entidad}
            />
            <UpdateEmpresaIMGComponent
                getEmpresa={getEmpresa}
                dataShow={dataShow}
                setDataShow={setDataShow}
                setVisible={setVisibleImg}
                visible={visibleImg}
                showToast={showToast}
                entidad={entidad}
                api={api}
            />
        </div>
    )
}

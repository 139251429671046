import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  width: '400px',
  height: '400px'
};

const libraries = ["places"];

const SMap = (props) => {
  const { center, markerPosition, onMarkerPositionChange, onAddressChange } = props;

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCsCf38reBoC31fo0enpAT6cESVohjRSwk",
    libraries: libraries,
  });

  const [map, setMap] = React.useState(null);

  const geocodeLatLng = (latLng) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === "OK" && results[0]) {
        const formattedAddress = results[0].formatted_address;
        onAddressChange(formattedAddress);
      }
    });
  };

  const handleDoubleClick = React.useCallback(function callback(event) {
    const newMarkerPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    onMarkerPositionChange(newMarkerPosition);
    map.panTo(event.latLng);
    geocodeLatLng(event.latLng);
  }, [map, onMarkerPositionChange]);

  const handleMarkerDragEnd = React.useCallback(function callback(event) {
    const newMarkerPosition = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    };
    onMarkerPositionChange(newMarkerPosition);
    geocodeLatLng(event.latLng);
  }, [onMarkerPositionChange]);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, [center]);

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={
        props.mapContainerStyle || containerStyle
      }
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        gestureHandling: "cooperative",
      }}
      center={center}
      zoom={18}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onDblClick={handleDoubleClick}
    >
      <Marker
        position={markerPosition}
        draggable
        onDragEnd={handleMarkerDragEnd}
      />
    </GoogleMap>
  ) : <></>;
}

export default SMap;

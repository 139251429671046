// import iconPROCESO from "../Images/svg_icons/ICN_En_proceso.svg";

const iconEkg = '/svg_icons/BG_ICN_EKG.svg'
const iconLab = '/svg_icons/BG_ICN_LABORATORIO.svg'
const iconMedicina = '/svg_icons/BG_ICN_MEDICINA_G.svg'
const iconOft = '/svg_icons/BG_ICN_OFTALMOLOGÍA.svg'
const iconPsicologia = '/svg_icons/BG_ICN_PSICOLOGÍA.svg'
const iconRadiologia = '/svg_icons/BG_ICN_TRIAJE.svg'
const iconTriaje = '/svg_icons/BG_ICN_TRIAJE.svg'
const iconAudiometria = '/svg_icons/BG_ICN_AUDIOMETRÍA.svg'
const iconEspirometria = '/svg_icons/BG_ICN_ESPIROMETRIA.svg'
const iconOdontologia = '/svg_icons/BG_ICN_ODONTOLOGIA.svg'

const iconCANCELADO = '/svg_icons/ICN_Cancelado.svg'
const iconNO_REALIZADO = '/svg_icons/ICN_No_realizado.svg'
const iconPROCESO = '/svg_icons/ICN_En_proceso.svg'
const iconREALIZADO = '/svg_icons/ICN_Realizado.svg'


export {
  iconEkg,
  iconLab,
  iconMedicina,
  iconOft,
  iconPsicologia,
  iconTriaje,
  iconRadiologia,
  iconAudiometria,
  iconEspirometria,
  iconCANCELADO,
  iconPROCESO,
  iconNO_REALIZADO,
  iconREALIZADO,
  iconOdontologia
};

import { Button } from "primereact/button";

export default function SeleccionProductos({ sendProducto, setSendProducto }) {

    return (
        <div className="bg-white flex flex-column p-3 gap-2 border-round-lg">
            <img
                width="40px"
                height="40px"
                className="p-1 bg-indigo-100 border-round-lg"
                src="https://i.ibb.co/6v205wk/Icon.png"
                alt=""
            />
            <p className="text-indigo-500 font-bold ">Seleccion de producto</p>
            <div className="flex justify-content-between">

                <Button
                    onClick={() => setSendProducto('Bien')}
                    className={`${sendProducto.seleccion === "Bien" ? `bg-indigo-500 border-indigo-500` : `text-gray-500 bg-gray-200 border-gray-200`} `}
                    label="Bien"
                />
                <Button
                    onClick={() => setSendProducto('Servicio')}
                    className={`${sendProducto.seleccion === "Servicio" ? `bg-indigo-500 border-indigo-500` : `text-gray-500 bg-gray-200 border-gray-200`} `}
                    label="Servicio"
                />
            </div>
        </div>
    );
}
import { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Carousel } from "primereact/carousel";
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch } from "react-redux";

//Importación de componentes generales
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";

//Importación de componentes únicos
import BilleteraElectronicaComponent from "./BilleteraElectronica";
import CardCuentaComponent from "./CardCuenta";

import RucIcon from "../../../../Images/Iconperfil/Credit-Card.png"

//Importación de estilos
import "../../../../Style/stylePerfil.css";
import "../../../../Style/usuario/TabViewCuentas.css";
import "../style/RetraccionesVer.css"

export default function CrearCcomponent({
   getEntidadesElectronicas,
   entidadPagoElectronico,
   getbregma,
   entidadPagos,
   entidadesBancarias,
   entidadesElectronicas,
   handleCreate,
   showToast
}) {
   const { http } = AuthUser();
   const dispatch = useDispatch();
   const toast = useRef(null);
   const [visible, setVisible] = useState(false);
   const [visible2, setVisible2] = useState(false);
   const [dataTypeEntity, setDataTypeEntity] = useState([]);
   const [entidadPago, setEntidadPago] = useState({
      id: 0,
      numero_cuenta: "",
      cci: "",
      entidad_bancaria_id: null,
      entidad_bancaria: null,
   });

   const [entidadpagoElectronico, setEntidadpagoElectronico] = useState({
      id: 0,
      entidad_electronica_id: null,
      numero_cuenta: "",
      entidad_electronica: null,
   });

   const limpiarEntidadBancaria = () => {
      setEntidadPago({
         id: 0,
         entidad_electronica_id: null,
         numero_cuenta: "",
         entidad_electronica: null,
      })
   }

   const limpiarEntidadElectronica = () => {
      setEntidadpagoElectronico({
         id: 0,
         entidad_electronica_id: null,
         numero_cuenta: "",
         entidad_electronica: null,
      })
   }

   const handleGetTypeEntity = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get("entidad_electronica/get");

         if (response?.data?.data) {
            setDataTypeEntity(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response?.data?.error);
         }

      } catch (error) {
         showToast('error', 'Error', 'Contacte con soporte técnico');

      } finally {
         dispatch(endLoading());
      }
   };

   // Electronica create
   const handleCreateElectronica = async () => {
      dispatch(startLoading());
      try {
         const response = await http.post("entidad_electronica_pago/create", entidadpagoElectronico)
         if (response?.data?.resp) {
            await Promise.all([
               getbregma(),
               setVisible2(false),
               limpiarEntidadElectronica(),
               getEntidadesElectronicas(),
            ])
            showToast(
               "success",
               "Creación exitosa",
               "Billetera electrónica creada"
            )
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         setVisible2(false);
         showToast('error', 'Error', 'Contacte a soporte técnico')
         console.error("error", error);
      } finally {
         dispatch(endLoading())
      }
   };

   const handleChangeNumeroCuentaBancaria = (e) => {
      setEntidadPago({ ...entidadPago, numero_cuenta: e.target.value });
   };

   // Electronica
   const handleChangeNumeroCuentaElectronica = (e) => {
      setEntidadpagoElectronico({
         ...entidadpagoElectronico,
         numero_cuenta: e.target.value,
      });
   };

   const handleChangeCCI = (e) => {
      setEntidadPago({ ...entidadPago, cci: e.target.value });

   };

   const handleChangeEntidadBancaria = (e) => {
      setEntidadPago({
         ...entidadPago,
         entidad_bancaria: e.target.value,
         entidad_bancaria_id: e.value === null ? null : e.value.id,
      });

   };

   const handleChangeEntidadElectronica = (e) => {
      setEntidadpagoElectronico({
         ...entidadpagoElectronico,
         entidad_electronica: e.target.value,
         entidad_electronica_id: e.value === null ? null : e.value.id,
      });
   };

   const billeteras = entidadPagoElectronico?.filter(entidad => entidad.estado_registro === "A")
      .map((entidadPagoElectronico) => (
         <BilleteraElectronicaComponent
            key={entidadPagoElectronico.id} // Asumiendo que cada entidad tiene un id único
            entidadPagoElectronico={entidadPagoElectronico}
            tipo={dataTypeEntity}
            getEntidadesElectronicas={getEntidadesElectronicas}
            entidadesElectronicas={entidadesElectronicas}
            showToast={showToast}
         />
      ));

   const cuentasActivas2 = billeteras?.filter(
      (entidadPagoElectronico) => entidadPagoElectronico !== undefined
   );

   const cuentas = entidadPagos
      ?.filter(entidadPago => entidadPago.estado_registro === "A")
      .map((entidadPago) => (
         <CardCuentaComponent
            key={entidadPago.id} // Asumiendo que cada entidad tiene un id único
            entidadPago={entidadPago}
            getbregma={getbregma}
            entidadesBancarias={entidadesBancarias}
            showToast={showToast}
         />
      ));

   const cuentasActivas = cuentas?.filter(
      (entidadPago) => entidadPago !== undefined
   );

   const [activeTab, setActiveTab] = useState(0);

   const changeTab = (index) => {
      setActiveTab(index);
   };

   const CerrarModal = () => {
      setVisible(false);
   }

   useEffect(() => {
      handleGetTypeEntity()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [NumEntidadBanC, setNumEntidadBanC] = useState(false);
   const [EntidadBanC, setEntidadBanC] = useState(false);
   const [CCIfaltaC, setCCIfaltaC] = useState(false);

   useEffect(() => {
      setEntidadBanC(false);
      setNumEntidadBanC(false);
      setCCIfaltaC(false);

      setEntidadPago({
         ...entidadPago,
         entidad_bancaria: null,
         entidad_bancaria_id: null,
         numero_cuenta: null,
         cci: null,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visible])

   const VerificarFaltas = () => {
      var Verificar = true;
      if (entidadPago.numero_cuenta === "" || entidadPago.numero_cuenta === null) {
         Verificar = false;
         setNumEntidadBanC(true);
      }
      if (entidadPago.entidad_bancaria === "" || entidadPago.entidad_bancaria === null) {
         Verificar = false;
         setEntidadBanC(true);
      }
      if (entidadPago.cci === "" || entidadPago.cci === null) {
         Verificar = false;
         setCCIfaltaC(true);
      }
      return Verificar;
   }

   const EnvioCreateEntidadBancaria = () => {
      var veri = VerificarFaltas();
      if (veri) {
         handleCreate(entidadPago, limpiarEntidadBancaria, CerrarModal);
      }
   }

   const [EntidadElectro, setEntidadElectro] = useState(false);
   const [NumTelefonoFa, setNumTelefonoFa] = useState(false);

   useEffect(() => {
      setEntidadElectro(false);
      setNumTelefonoFa(false);
      setEntidadpagoElectronico({
         ...entidadpagoElectronico,
         entidad_electronica: null,
         entidad_electronica_id: null,
         numero_cuenta: null,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [visible2])

   const VerificarFaltasEntiElectro = () => {
      var Verificar = true;
      if (entidadpagoElectronico.entidad_electronica === "" || entidadpagoElectronico.entidad_electronica === null) {
         Verificar = false;
         setEntidadElectro(true);
      }
      if (entidadpagoElectronico.numero_cuenta === "" || entidadpagoElectronico.numero_cuenta === null) {
         Verificar = false;
         setNumTelefonoFa(true);
      }
      return Verificar;
   }

   const EnvioCreateEntidadElectronica = () => {
      var veri = VerificarFaltasEntiElectro();
      if (veri) {
         handleCreateElectronica()
      }
   }

   return (
      <div>
         <div className="tabview22">
            <Toast ref={toast} />
            <div className="tab-header22 flex fle-row gap-3">
               <button
                  className={`tab-button22 ${activeTab === 0 ? "active" : ""}`}
                  onClick={() => changeTab(0)}
                  style={{ width: "100%" }}
               >
                  Cuentas Bancarias
               </button>
               <button
                  className={`tab-button22 ${activeTab === 1 ? "active" : ""}`}
                  onClick={() => changeTab(1)}
                  style={{ width: "100%" }}
               >
                  Billeteras electrónicas
               </button>
            </div>
            <div className="tab-content22" style={{ maxWidth: "90vw" }}>
               {cuentasActivas?.length > 0 && (
                  <div className={`tab-pane22 ${activeTab === 0 ? "active" : ""}`}>
                     <div className="flex flex-column" style={{ maxWidth: "100%" }}>
                        <div className="flex align-items-center justify-content-center">
                           <Carousel
                              className="CarruselCuentasBancarias_1"
                              circular
                              style={{ maxWidth: "100%" }}
                              value={cuentasActivas}
                              autoplayInterval={3000}
                              numVisible={3}
                              numScroll={1}
                              itemTemplate={(item) => (
                                 <div className="p-d-flex p-jc-center flex">
                                    {item}
                                 </div>
                              )}
                           />
                        </div>
                     </div>
                  </div>
               )}
               {cuentasActivas2.length > 0 && (
                  <div className={`tab-pane22 ${activeTab === 1 ? "active" : ""}`}>
                     <div className="flex flex-column" style={{ maxWidth: "100%" }}>
                        <div className="flex align-items-center justify-content-center" >
                           <Carousel
                              className="CarruselCuentasBancarias_1"
                              circular
                              style={{ maxWidth: "100%" }}
                              value={cuentasActivas2}
                              autoplayInterval={3000}
                              numVisible={3}
                              numScroll={1}
                              itemTemplate={(item) => (
                                 <div className="p-d-flex p-jc-center flex">
                                    {item}
                                 </div>
                              )}
                           />
                        </div>
                     </div>
                  </div>
               )}
               <div className="flex flex-wrap w-full text-sm justify-content-center" style={{ marginTop: "auto" }}>
                  {activeTab === 0 && (
                     <button
                        className="custom-button"
                        onClick={() => setVisible(true)}
                        style={{
                           background: "#FFFFFF",
                           borderRadius: "9px",
                           border: "none",
                           margin: "0 auto",
                           padding: "14px 12px",
                           cursor: "pointer",
                           width: "100%", // Para que ocupe todo el ancho del contenedor padre
                           maxWidth: "100rem", // Opcional: establecer un ancho máximo
                        }}
                     >
                        <span
                           style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "rgba(69, 69, 188, 0.54)",
                           }}
                        >
                           Agregar nueva entidad bancaria
                        </span>
                        <span
                           style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "rgba(69, 69, 188, 0.54)",
                           }}
                        >
                           +
                        </span>
                     </button>
                  )}
                  {activeTab === 1 && (
                     <button
                        className="custom-button"
                        onClick={() => setVisible2(true)}
                        style={{
                           background: "#FFFFFF",
                           borderRadius: "9px",
                           border: "none",
                           margin: "0 auto",
                           padding: "14px 12px",
                           cursor: "pointer",
                           width: "100%", // Para que ocupe todo el ancho del contenedor padre
                           maxWidth: "100rem", // Opcional: establecer un ancho máximo
                        }}
                     >
                        <span
                           style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "rgba(69, 69, 188, 0.54)",
                           }}
                        >
                           Agregar nueva entidad electrónica
                        </span>
                        <span
                           style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "rgba(69, 69, 188, 0.54)",
                           }}
                        >
                           +
                        </span>
                     </button>
                  )}
               </div>
            </div>
         </div>

         <div className="card flex justify-content-center">
            <Dialog
               header={<Header icono={RucIcon} titulo={"Crear nuevo método de pago"} />}
               footer={<Footer type={"button"} onClickCancelar={() => setVisible(false)} onClickEnviar={() => EnvioCreateEntidadBancaria()} label={"Guardar"} />}
               visible={visible}
               className="card-buton"
               style={{ width: "30vw", minWidth: "500px" }}
               onHide={() => setVisible(false)}
            >
               <div className="card">
                  <div className="flex flex-column gap-2 mb-4">
                     <div className="flex flex-column relative gap-2">
                        <label htmlFor="num" className="font-bold block mb-2">
                           Entidad Bancaria
                        </label>
                        <Dropdown
                           value={entidadPago?.entidad_bancaria}
                           onChange={(e) => { handleChangeEntidadBancaria(e); setEntidadBanC(false) }}
                           options={entidadesBancarias}
                           optionLabel="nombre"
                           placeholder="Seleccionar tipo de Tarjeta"
                           className={`w-full InputRetraccionNumCuAP ${EntidadBanC ? 'Select' : ''}`}
                        />
                        <div className={`InputRetraccionNumCuAPDiv ${EntidadBanC ? 'Select' : ''}`}></div>
                        <p className={`InputRetraccionNumCuAPTextError ${EntidadBanC ? 'Select' : ''}`}>Necesita seleccionar una entidad Bancaria</p>
                     </div>
                     <div className="flex flex-column relative gap-2">
                        <label htmlFor="num" className="font-bold block">
                           Número de Cuenta
                        </label>
                        <InputNumber
                           inputId="num"
                           keyfilter="num"
                           value={entidadPago?.numero_cuenta}
                           className={`w-full InputRetraccionNumCuAP ${NumEntidadBanC ? 'Select' : ''}`}
                           onValueChange={(e) => {
                              handleChangeNumeroCuentaBancaria(e); setNumEntidadBanC(false);
                           }}
                           useGrouping={false} />
                        <div className={`InputRetraccionNumCuAPDiv ${NumEntidadBanC ? 'Select' : ''}`}></div>
                        <p className={`InputRetraccionNumCuAPTextError ${NumEntidadBanC ? 'Select' : ''}`}>Necesita ingresar un número Bancario</p>
                     </div>
                     <div className="flex flex-column relative gap-2">
                        <label htmlFor="num" className="font-bold block mb-2">
                           CCI
                        </label>
                        <InputNumber
                           inputId="num"
                           keyfilter="num"
                           value={entidadPago?.cci}
                           className={`w-full InputRetraccionNumCuAP ${CCIfaltaC ? 'Select' : ''}`}
                           onValueChange={(e) => { handleChangeCCI(e); setCCIfaltaC(false) }}
                           useGrouping={false} />
                        <div className={`InputRetraccionNumCuAPDiv ${CCIfaltaC ? 'Select' : ''}`}></div>
                        <p className={`InputRetraccionNumCuAPTextError ${CCIfaltaC ? 'Select' : ''}`}>Necesita ingresar un número de CCI</p>
                     </div>
                  </div>
               </div>
            </Dialog>
            <Dialog
               header={<Header icono={RucIcon} titulo={"Crear nuevo método de pago"} />}
               footer={<Footer type={"button"} onClickCancelar={() => setVisible2(false)} onClickEnviar={() => { EnvioCreateEntidadElectronica() }} label={"Guardar"} />}
               visible={visible2}
               className="card-buton"
               style={{ width: "30vw", minWidth: "500px" }}
               onHide={() => setVisible2(false)}
            >
               <div className="card">
                  <div className="flex flex-column gap-3 mb-4">
                     <div className="flex flex-column relative gap-2">
                        <label htmlFor="num" className="font-bold block mb-2">
                           Entidad Electronica
                        </label>
                        <Dropdown
                           value={entidadpagoElectronico?.entidad_electronica}
                           onChange={(e) => { handleChangeEntidadElectronica(e); setEntidadElectro(false) }
                              //  setDataTypeEntity(e.value)
                           }
                           options={dataTypeEntity}
                           optionLabel="nombre"
                           placeholder="Seleccionar tipo de Pago"
                           className={`w-full InputRetraccionNumCuAP ${EntidadElectro ? 'Select' : ''}`}
                        />
                        <div className={`InputRetraccionNumCuAPDiv ${EntidadElectro ? 'Select' : ''}`}></div>
                        <p className={`InputRetraccionNumCuAPTextError ${EntidadElectro ? 'Select' : ''}`}>Necesita seleccionar una entidad electronica</p>
                     </div>
                     <div className="flex flex-column relative gap-2">
                        <label htmlFor="num" className="font-bold block mb-2">
                           Numero de celular
                        </label>
                        <InputNumber
                           inputId="num"
                           keyfilter="num"
                           value={entidadPagoElectronico?.numero_cuenta}
                           className={`w-full InputRetraccionNumCuAP ${NumTelefonoFa ? 'Select' : ''}`}
                           onValueChange={(e) => { handleChangeNumeroCuentaElectronica(e); setNumTelefonoFa(false) }}
                           useGrouping={false} />
                        <div className={`InputRetraccionNumCuAPDiv ${NumTelefonoFa ? 'Select' : ''}`}></div>
                        <p className={`InputRetraccionNumCuAPTextError ${NumTelefonoFa ? 'Select' : ''}`}>Necesita ingresar un número telefonico</p>
                     </div>
                  </div>
               </div>
            </Dialog>
         </div>
      </div>
   );
}
import React, { useState, useEffect, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import img1 from "./document.svg";
import "../../Style/estilos3.css";

import TamizajeDermatologicoDetalles from "./TamizajeDermatologico/TamizajeDermatologicoDetalles";
import TamizajeDermatologicoPreguntas from "./TamizajeDermatologico/TamizajeDermatologicoPreguntas";
import { MGContext } from "./MGProvider";

const TamizajeDermatologico = () => {
  const [selectedRating, setSelectedRating] = useState(null);
  //    Tienes que hacer values para las diferentes observaciones si no todo se van a escribir en uno s
  const [value, setValue] = useState("");

  return (
    <div className="w-full h-auto">
      <Accordion className="AcordeonDxyConclusión">
        <AccordionTab
          header={
            <div className="flex w-full h-auto gap-2">
              <div className="w-2rem h-2rem"><i className="text-xl pi pi-align-left text-center w-full h-full" style={{ color: '#6366F1', paddingTop: '6px' }}></i></div>
              <div>
                <p
                  style={{ fontFamily: "Montserrat", fontWeight: "600", fontSize: '16px' }}

                >
                  Detalles
                </p>
                <span
                  style={{ fontFamily: "Montserrat", fontWeight: "400", fontSize: '14px' }}
                >
                  Síntomas y hallazgos, Observaciones, Recomendaciones y Conclusiones.
                </span>
              </div>
            </div>
          }
        >
          <TamizajeDermatologicoDetalles />
        </AccordionTab>
        <AccordionTab
          header={
            <div className="flex w-full h-auto gap-2">
              <div className="w-2rem h-2rem"><i className="text-xl pi pi-question text-center w-full h-full" style={{ color: '#6366F1', paddingTop: '6px' }}></i></div>
              <div>
                <p
                  style={{ fontFamily: "Montserrat", fontWeight: "600", fontSize: '16px' }}
                >
                  Preguntas
                </p>
                <span
                  style={{ fontFamily: "Montserrat", fontWeight: "400", fontSize: '14px' }}

                >
                  Enfermedades, Lesiones, Coloraciones, Comezón en la piel
                </span>
              </div>
            </div>
          }
        >
          <TamizajeDermatologicoPreguntas />
        </AccordionTab>
      </Accordion>
    </div>
  );
};
export default TamizajeDermatologico;

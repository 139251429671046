
import React, { useState } from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import PagePublic from "../../../../Services/PagePublic";
import PagePrivate from "../../../../Services/PagePrivate";

const PageSalesMagnamentC = () => {
    const [activeIndex, setActiveIndex] = useState(false);

    const getContent = (index) => {
        switch (index) {
            case false:
                return <PagePublic />;
            case true:
                return <PagePrivate />;
            default:
                return null;
        }
    }

    return (
        <div className="flex flex-column gap-2 text-sm pb-3">
            <div className="flex flex-column gap-3 mb-3">
                <p className="text-2xl text-indigo-500 font-bold">Gestión Venta</p>
                <p className="flex-1 flex align-items-center text-indigo-300">Crea y gestiona tus Leads.</p>
            </div>

            <div className="flex flex-column">
                <div className="flex gap-2 justify-content-between">
                    <div className="flex gap-3">
                        <button
                            className={`boton-ventas-clinica ${activeIndex ? `` : `boton-ventas-clinica-active`}`}
                            onClick={() => setActiveIndex(false)}
                        >
                            Empresa
                        </button>
                        {/* <button
                            className={`boton-ventas-clinica ${activeIndex ? `boton-ventas-clinica-active` : ``}`}
                            onClick={() => setActiveIndex(true)}
                        >
                            Particular
                        </button> */}
                    </div>

                    <div className="flex gap-2 align-items-start justify-content-center">
                        {/* <Button
                            className="p-2 gap-2 text-sm bg-white border-green-700 text-green-600 border-green-700 border-round-lg"
                        >
                            <i className="pi pi-file-excel text-sm" />
                            <span>Reporte</span>
                        </Button> */}
                        <Link to={activeIndex ? "particularnuevocliente" : "empresanuevocliente"} className="no-underline">
                            <Button
                                className="p-2 gap-2 text-sm bg-green-600 border-green-700 h-full border-round-lg"
                            >
                                <i className="pi pi-plus text-sm" />
                                <span>Nuevo Cliente</span>
                            </Button>
                        </Link>
                    </div>
                </div>

                <div className="bg-white p-3 border-round-bottom-xl border-round-right-xl">
                    {getContent(activeIndex)}
                </div>
            </div>

        </div >
    )
}
export default PageSalesMagnamentC;
import React, { useEffect } from "react"; // Importa React y hooks necesarios
import { Dialog } from "primereact/dialog"; // Importa el componente Dialog de PrimeReact
import { Dropdown } from "primereact/dropdown"; // Importa el componente Dropdown de PrimeReact
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";

//Componentes generales
import AuthUser from "../../../AuthUser"; // Importa el componente AuthUser
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice"; // Importa acciones de Redux para manejar el estado de carga
import { Footer, Header } from "../../Pure/SeccionesNavegacion"; // Importa componentes de pie de página y encabezado

export default function ModalEditarCargo({
  visibleEditCargo, // Propiedad para controlar la visibilidad del modal
  setVisibleEditCargo, // Función para cambiar la visibilidad del modal
  showToast, // Función para mostrar notificaciones
  cargo, // Objeto que contiene la información del cargo a editar
  api, // URL de la API para realizar la actualización
  tipoRiesgos, // Lista de tipos de riesgos disponibles
  getCargos, // Función para obtener la lista de cargos
  dispatch
}) {

  const { http } = AuthUser(); // Obtiene el objeto http de AuthUser para realizar peticiones

  const initialValues = {
    id: 0, // ID inicial del cargo
    nombre: "", // Nombre inicial del cargo
    tipo_riesgo_id: "", // ID del tipo de riesgo inicial
  };

  //Mensajes de error ante ausencia de información en los formularios
  const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
  };

  const formik = useFormik({
    initialValues: initialValues, // Valores iniciales del formulario
    onSubmit: async (data) => { // Función que se ejecuta al enviar el formulario
      let data_send = {
        nombre: data.nombre, // Nombre del cargo a enviar
        tipo_riesgo_id: data.tipo_riesgo_id.id // ID del tipo de riesgo a enviar
      }
      dispatch(startLoading()); // Inicia el estado de carga
      try {
        const response = await http.put(`${api}/${data.id}`, data_send) // Realiza la petición PUT a la API
        if (response?.data?.resp) { // Si la respuesta es exitosa
          await Promise.all([
            getCargos() // Obtiene la lista de cargos actualizada
          ])
          HideDialogEdit(); // Oculta el modal
          showToast("success", "Personal actualizado", "Se actualizó el personal correctamente"); // Muestra notificación de éxito
        } else if (response?.data?.error) { // Si hay un error en la respuesta
          showToast("error", "Error al editar", "Algo salió mal, inténtelo de nuevo", response.data.error) // Muestra notificación de error
        }
      } catch (error) { // Si ocurre un error en la petición
        console.error("Error al editar", error) // Muestra el error en la consola
        showToast("error", "Error al editar", "Contáctese con Soporte Técnico.") // Muestra notificación de error
        HideDialogEdit(); // Oculta el modal
      } finally {
        dispatch(endLoading()); // Finaliza el estado de carga
      }
    },
    validate: (data) => {
      let errors = {};
      if (!data.nombre) {
        errors.nombre = "Ingrese el nombre del puesto"
      }
      if (!data.tipo_riesgo_id) {
        errors.tipo_riesgo_id = 'Seleccione el riesgo'
      }
      return errors
    }
  });

  const updateCargo = () => {
    formik.setValues({
      id: cargo?.id, // Establece el ID del cargo
      nombre: cargo?.nombre, // Establece el nombre del cargo
      tipo_riesgo_id: tipoRiesgos.find((r) => r.id === cargo?.tipo_riesgo_id) || null, // Establece el tipo de riesgo del cargo
    });
  };

  useEffect(() => {
    updateCargo(); // Actualiza los valores del formulario cuando cambia el cargo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cargo]);

  const HideDialogEdit = () => {
    setVisibleEditCargo(false); // Oculta el modal
  };

  const header = (<Header piIcon={'pi pi-briefcase'} titulo={'Actualizar puesto de trabajo'} subtitulo={' En esta sección puede actualizar los puestos de trabajo.'} />) // Encabezado del modal

  const paqueteCreateDialogFooter = (<Footer type={'submit'} onClickCancelar={() => HideDialogEdit()} label={'Actualizar'} />); // Pie de página del modal

  return (
    <div>
      <form
        id="persona-form-update"
        className="persona-form-update"
        noValidate
        onSubmit={formik.handleSubmit} // Maneja el envío del formulario con Formik
      >
        <Dialog
          visible={visibleEditCargo} // Controla la visibilidad del modal
          style={{ width: "450px", height: "auto", borderRadius: '25px' }} // Estilos del modal
          appendTo={document.getElementById("persona-form-update")} // Adjunta el modal al formulario
          header={header} // Encabezado del modal
          dismissableMask
          className="p-fluid"
          footer={paqueteCreateDialogFooter} // Pie de página del modal
          onHide={() => HideDialogEdit()}
        >
          <div>
            <div className="flex flex-column w-full">
              <div style={{ margin: "15px 0px", flexWrap: "wrap" }} className="flex w-full flex-column">
                <p style={{ margin: "0px 0px 8px 0px" }} className="per1">Editar Puesto de Trabajo</p>
                {/* Campo de texto para el nombre del cargo */}
                <InputText
                  id="nombre"
                  name="nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                  className={classNames({ 'p-invalid': isFormFieldValid('nombre') })}
                />
                {getFormErrorMessage('nombre')}
              </div>
              <div style={{ margin: "15px 0px" }} className="flex w-full flex-column">
                <p>Potencial de Riesgo</p>
                <Dropdown
                  id="tipo_riesgo_id"
                  name="tipo_riesgo_id"
                  value={formik.values.tipo_riesgo_id}
                  onChange={(e) => { formik.setFieldValue("tipo_riesgo_id", e.value) }} // Maneja el cambio de valor del dropdown
                  options={tipoRiesgos} // Opciones del dropdown
                  optionLabel="nombre" // Etiqueta de opción
                  placeholder="Seleccionar Tipo de Riesgo"
                  className={classNames({ 'p-invalid': isFormFieldValid('tipo_riesgo_id') })}
                />
                {getFormErrorMessage('tipo_riesgo_id')}
              </div>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
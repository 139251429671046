import { Checkbox } from 'primereact/checkbox';
import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useState } from 'react';

export default function DialogDetalles() {

    const [checkboxes, setCheckboxes] = useState([
        { id: 1, label: 'Facebook', checked: false },
        { id: 2, label: 'Instagram', checked: false },
        { id: 3, label: 'TikTok', checked: false },
        { id: 4, label: 'Congresos', checked: false },
        { id: 5, label: 'Equipos de Ventas', checked: false },
        { id: 6, label: 'Campañas', checked: false },
        { id: 7, label: 'Medios', checked: false },
        // { id: 8, label: 'Checkbox', checked: false },
    ]);
    return (
        <div>
            <ScrollPanel style={{ width: '100%', height: '350px' }}>
                {checkboxes.map((checkbox) => (
                    <div key={checkbox.id} style={{ height: '50px', borderBottom: '0.5px solid rgba(136, 136, 142, 0.6)', display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            inputId={`cb-${checkbox.id}`}
                            value={checkbox.id}
                            checked={checkbox.checked}
                            onChange={(e) => {
                                const updatedCheckboxes = checkboxes.map((c) =>
                                    c.id === checkbox.id ? { ...c, checked: e.checked } : c
                                );
                                setCheckboxes(updatedCheckboxes);
                            }}
                        />
                        <label htmlFor={`cb-${checkbox.id}`} style={{ marginLeft: '15px', fontWeight: '500', fontSize: '14px' }}>{checkbox.label}</label>
                    </div>
                ))}
            </ScrollPanel>
        </div>
    )
}



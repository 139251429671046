import React, { useState, useEffect, useRef, useContext } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import EvaluacionMedica from "./EvaluacionMedica";
import Componentinterconsultas from "./Interconsultas/Interconsultas";
import "../../../Style/style8.css";
import img1 from "./EvaluacionM.svg";
export default function PageEvaluacionMedica({EvMedicaData}) {
   //RadioButtons
   const [EvMed, setEvMed] = useState([]);
   const [DiagMed, setDiagMed] = useState([]);
   //Desmarcar un radiobutton si ya esta activado
   const handleOnChange = (e, setState) => {
      setState(e.value);
      if (!e.checked) {
         setState("");
      }
   };


   return (
      <div className="card">
         <Accordion className="AcordeonDxyConclusión">
            <AccordionTab
               header={
                  <>
                     <div className="flex flex-row gap-2">
                        <img src={img1} alt="" />
                        <div>
                           <p
                              style={{
                                 fontWeight: "600",
                                 fontSize: "16px",
                                 color: "#393838",
                              }}
                           >
                              Diagnóstico y conclusión
                           </p>
                        </div>
                     </div>
                  </>
               }
            >
               <EvaluacionMedica EvMedicaData={EvMedicaData} setEvMed={setEvMed} EvMed={EvMed} DiagMed={DiagMed} setDiagMed={setDiagMed} />
            </AccordionTab>
            <AccordionTab header={
               <>
                  <div className="flex flex-row gap-2">
                     <img src={img1} alt="" />
                     <div>
                        <p
                           style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              color: "#393838",
                           }}
                        >
                           Interconsultas
                        </p>
                        <span
                           style={{
                              fontWeight: "400",
                              fontSize: "14px",
                              color: "#393838",
                           }}
                        >
                           Lista de interconsultas
                        </span>
                     </div>
                  </div>
               </>
            }>
               <Componentinterconsultas />
            </AccordionTab>
         </Accordion>
      </div>
   );
}

import React, { useContext, useEffect, useState } from 'react';
import { OftContext } from "../OftContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { iconOft } from "../../../Services/importIcons";
import './PageOftMedAHP.css';

const PageOftMed = () => {
  let { pacienteContext } = useContext(RAContext);
  let { mediContext, setMediContext } = useContext(OftContext);
  let { pageViewContext, stateAtencion } = useContext(RAContext);

  const [presionArtDer, setPresionArtDer] = useState(mediContext?.precion_ojo_derecho ?? '');
  const [presionArtIzq, setPresionArtIzq] = useState(mediContext?.precion_ojo_izquierdo ?? '');
  const [fondoOjoDer, setFondoOjoDer] = useState(mediContext?.fondo_ojo_derecho ?? '');
  const [fondoOjoIzq, setFondoOjoIzq] = useState(mediContext?.fondo_ojo_izquierdo ?? '');
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    setMediContext((prevContext) => ({
      ...prevContext,
      [`precion_ojo_derecho`]: presionArtDer,
      [`precion_ojo_izquierdo`]: presionArtIzq,
      [`fondo_ojo_derecho`]: fondoOjoDer,
      [`fondo_ojo_izquierdo`]: fondoOjoIzq,
    }));
  }, [presionArtDer, presionArtIzq, fondoOjoDer, fondoOjoIzq]);

  const handlePresionArtDerChange = (e) => {
    setPresionArtDer(e.value);
  };

  const handlePresionArtIzqChange = (e) => {
    setPresionArtIzq(e.value);
  };

  const handleFondoOjoDerChange = (e) => {
    setFondoOjoDer(e.target.value);
  };

  const handleFondoOjoIzqChange = (e) => {
    setFondoOjoIzq(e.target.value);
  };

  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => { setHovered(true); };
  const handleMouseLeave = () => { setHovered(false) };

  const [hovered01, setHovered01] = useState(false);
  const handleMouseEnter01 = () => { setHovered01(true); };
  const handleMouseLeave01 = () => { setHovered01(false) };
  const classes = `ContenedorCuadroAgudeVisu ${hovered ? 'ClasesHoverMouse03' : ''}`;
  const classes01 = `ContenedorCuadroAgudeVisu ${hovered01 ? 'ClasesHoverMouse04' : ''}`;

  return (
    <>
      <div className="oft_ant_titles" style={{ marginBottom: "20px" }}>
        <div>
          <img src={iconOft} alt="Icon" />
        </div>
        <h3>Medición</h3>
      </div>
      <div className='w-full flex flex-wrap'>
        <div className={classes01} onMouseEnter={handleMouseEnter01} onMouseLeave={handleMouseLeave01}>
          <h3>Presión intraocular</h3>
          <div>
            <label>Ojo Derecho (mmHg)</label>
            <InputNumber
              style={{ width: '100%' }}
              value={presionArtDer}
              onChange={handlePresionArtDerChange}
              placeholder="0 mmHg"
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              disabled={stateAtencion[pageViewContext].estado === 3}
              onClick={(e) => e.target.select()}
            />
          </div>
          <div>
            <label>Ojo Izquierdo (mmHg)</label>
            <InputNumber
              style={{ width: '100%' }}
              value={presionArtIzq}
              onChange={handlePresionArtIzqChange}
              placeholder="0 mmHg"
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              disabled={stateAtencion[pageViewContext].estado === 3}
              onClick={(e) => e.target.select()}
            />
          </div>
        </div>
        <div className={classes} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} >
          <h3>Fondo de ojo directo</h3>
          <div>
            <label>Ojo Derecho</label>
            <InputText
              style={{ width: '100%' }}
              value={fondoOjoDer}
              onChange={handleFondoOjoDerChange}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div>
            <label>Ojo Izquierdo</label>
            <InputText
              style={{ width: '100%' }}
              value={fondoOjoIzq}
              onChange={handleFondoOjoIzqChange}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageOftMed;

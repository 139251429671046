import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react";
import AuthUser from "../../../../../../AuthUser";

export default function ModalEliminar({
  visibleEliminar,
  showToast,
  setVisibleEliminar,
  setPersonal,
  getPersonal,
  api,
  id  
}) {


  const { http } = AuthUser();
  const HideDialogEliminar = () => {
    setVisibleEliminar(false);
  };

  const DeleteMiPersonal = () => { 
    http
      .delete(`${api}/${id}`)
      .then(() => {
        HideDialogEliminar();
        getPersonal();
        showToast(
          "success",
          "Eliminado correctamente",
          "Se eliminó correctamente el personal"
        );
      })
      .catch((error) => {
        console.error("error", error);
      });
  };



  const paqueteDelateDialogFooter = (
    <div className="flex w-full bot">
      <Button
        type="button"
        label="Cancelar"
        className="delete w-full"
        onClick={() => {
          HideDialogEliminar();
        }}
      />
      <Button
        type=""
        label="Eliminar"
        className="p-button-danger w-full"
        onClick={DeleteMiPersonal}
      />
    </div>
  );

  return (
    <div>
      <form
        id="personal-form-delete"
        className="personal-form-delete"
        noValidate
      >
        <Dialog
          resizable={false}
          visible={visibleEliminar}
          style={{ width: "450px", margin:"5%"}}
          header={
            <>
              <h1 style={{
                fontFamily: 'Montserrat',
                fontStyle: "normal",
                fontWeight: "700",
                fontSize: "18",
                lineHeight: "3",
                color: "#5555D8",
              }}></h1>
              <div className="flex justify-content-left align-items-left">
                <i className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}><i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Personal</i></i>
              </div><div
                className="flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "",
                  width: "30px",
                  height: "30px",
                  borderradius: "10px",
                }}
                onClick={() => {
                  HideDialogEliminar();
                }}
              >
              </div>
            </>
          }
          footer={paqueteDelateDialogFooter}
          onHide={() => {
            HideDialogEliminar();
          }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p style={{ fontSize: "20px" }} className=" m-0" >¿Está seguro que desea eliminar personal?</p>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';

const ModalInformacion = ({ show, setShow, data, paciente }) => {

    return (
        <Sidebar
            visible={show}
            onHide={() => setShow(false)}
            position="right"
            showCloseIcon={false}
            style={{ width: '25em' }}
        >
            {/* Cerrar con X */}
            <Button
                icon="pi pi-times"
                className="p-button-rounded p-button-text"
                style={{ position: 'absolute', top: '10px', right: '10px' }}
                onClick={() => setShow(false)}
            />

            {/* HEADER */}
            <div className="rar_hr_header" style={{ justifyContent: 'center' }}>
                <Button
                    icon="pi pi-info"
                    className="p-button-rounded"
                    tooltip="Informacion"
                    tooltipOptions={{ position: "bottom" }}
                    onClick={() => setShow(false)}
                />
                <h3 style={{ margin: '0', marginRight: '50px' }}>Información</h3>
            </div>


            <div className="rar_hr_body">
                <div style={{ display: 'flex',  marginRight: '50px', justifyContent: 'center', marginTop: '30px' }}>
                    <p style={{ marginTop: "0px", fontWeight: 'bold' }}>{data?.nombres_completos || '......................'}</p>
                </div>
            </div>


            {/* BODY */}
            <div className="rar_hr_body" style={{ marginTop: '55px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="pi pi-building" style={{ marginRight: '0.5rem', color: '#6366F1' }}></i>
                    <p style={{ color: '#6366F1', margin: '0', fontWeight: 'bold' }}>Empresa</p>
                </div>
            </div>

            <div className="rar_hr_body">
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <p style={{ color: '#6366F1', margin: '0' }}>Nombre:</p>
                </div>
                <p style={{ marginTop: "5px" }}>{data?.empresa_personal?.empresa?.nombre_comercial || '..................'}</p>
            </div>

            <div className="rar_hr_body">
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <p style={{ color: '#6366F1', margin: '0' }}>RUC:</p>
                </div>
                <p style={{ marginTop: "5px" }}>{data?.empresa_personal?.empresa?.numero_documento|| '..................'}</p>
            </div>


            <div className="rar_hr_body" style={{ marginTop: '65px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <i className="pi pi-user" style={{ marginRight: '0.5rem', color: '#6366F1' }}></i>
                    <p style={{ color: '#6366F1', margin: '0', fontWeight: 'bold' }}>Perfil</p>
                </div>

            </div>

            <div className="rar_hr_body">
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <p style={{ color: '#6366F1', margin: '0' }}>Paquete:</p>
                </div>
                <p style={{ marginTop: "5px" }}>{data?.hoja_ruta?.con_perfil_tipo?.con_perfil?.nombre || '..................'}</p>
            </div>

            <div className="rar_hr_body">
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                    <p style={{ color: '#6366F1', margin: '0' }}>Tipo:</p>
                </div>
                <p style={{ marginTop: "5px" }}>{data?.hoja_ruta?.con_perfil_tipo?.tipo_perfil?.nombre || '..................'}</p>
            </div>
        </Sidebar>
    );

};

export default ModalInformacion;

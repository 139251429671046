import React from "react";
import { Carousel } from 'primereact/carousel';
import CelularIcon from "../../../../Images/Iconperfil/Celular.png"
import RazonsocialIcon from "../../../../Images/Iconperfil/Razonsocial.png"
import RucIcon from "../../../../Images/Iconperfil/Credit-Card.png"
import WhastAppIcon from "../../../../Images/Iconperfil/WhastApp.png"
import WebIcon from "../../../../Images/Iconperfil/web.png"

export default function SlideEmpresa(props) {
    const responsiveOptions = [
        {
            breakpoint: '1199px',
            numVisible: 1,
            numScroll: 1
        },
        {
            breakpoint: '991px',
            numVisible: 2,
            numScroll: 1
        },
        {
            breakpoint: '767px',
            numVisible: 1,
            numScroll: 1
        }
    ];
  const items = [
    {
      icon: RazonsocialIcon,
      title: 'Razón Social',
      subtitle: props.dataEx?.razon_social || 'No hay razón social'
    },
    {
      icon: RucIcon,
      title: 'RUC',
      subtitle: props.dataEx?.numero_documento || 'No hay documento'
    },
    {
      icon: CelularIcon,
      title: 'Celular',
      subtitle: props.dataEx?.celulares != null ? props.dataEx?.celulares[0]?.celular || 'Sin número' : 'Sin número'
    },
    {
      icon: WhastAppIcon,
      title: 'WhatsApp',
      subtitle: props.dataEx?.whatsapp || 'No hay WhatsApp'
    },
    {
      icon: WebIcon,
      title: 'Sitio Web',
      subtitle: props.dataEx?.sitio_web || 'No hay sitio web'
    }
  ];

  return (
    <Carousel value={items} itemTemplate={itemTemplate} numVisible={3} responsiveOptions={responsiveOptions} numScroll={1} />
  );
}

const itemTemplate = (item) => {
  return (
    <div className="SUB_SECTIONS_ICONO_DATA_EMPRE text-center">
      <img src={item.icon} alt={item.title} />
      <h4 className="TITULO_CONT_DOWN_EMPRE">{item.title}</h4>
      <p className='SUBTITULO_CONT_DOWN_EMPRE'>{item.subtitle}</p>
    </div>
    
  );
};

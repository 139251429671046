import React, { useContext, useRef, useState, useEffect } from "react";
import AuthUser from "../../AuthUser";
import { EkgContext } from "./EkgContext";
import EkgPreguntas from "../../Components/Ekg/EkgPreguntas";
import "../../Style/ekg.css";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconEkg } from "../../Services/importIcons";
import { RAContext } from "../RutaAtencion/RAContext";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../Redux/isLoadingSlice";
import { useParams } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import ModalGuardarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
import BottomsFootComponent from "../../Components/Clinica/AtencionMedica/pure/BottomsFoot";
import ModalCancelarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion";

const PageEkg = ({ revision }) => {
   const dispatch = useDispatch();

   const { http } = AuthUser();
   const { idPaciente } = useParams();
   const toast = useRef(null);

   let { datosContext, setDatosContext, setTimeStart, setAtencionStatus } = useContext(EkgContext);
   let { pageViewContext, stateAtencion, setStateAtencion, ekgDetalleId, setEkgDetalleId, ekgAreasContext } = useContext(RAContext);

   const [visibleImprimir, setVisibleImprimir] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);
   const [activeIndex, setActiveIndex] = useState(0);
   const [idEkg, setIdEkg] = useState(null);
   const [estado, setEstado] = useState(false)
   const [documentos, setDocumentos] = useState([])

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };
   //INICIA LA ATENCIÓN
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            
            setEkgDetalleId({
               id: ekgDetalleId.id,
               estado: 1,
               hora_inicio: ekgDetalleId.hora_inicio,
               fecha_inicio: ekgDetalleId.fecha_inicio,
               hora_fin: ekgDetalleId.hora_fin,
               fecha_fin: ekgDetalleId.fecha_fin,
            })
         })
   }
   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            setEkgDetalleId({
               id: ekgDetalleId.id,
               estado: 2,
               hora_inicio: ekgDetalleId.hora_inicio,
               fecha_inicio: ekgDetalleId.fecha_inicio,
               hora_fin: ekgDetalleId.hora_fin,
               fecha_fin: ekgDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
         })
   }

   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setEkgDetalleId({
                  id: ekgDetalleId.id,
                  estado: 2,
                  hora_inicio: ekgDetalleId.hora_inicio,
                  fecha_inicio: ekgDetalleId.fecha_inicio,
                  hora_fin: ekgDetalleId.hora_fin,
                  fecha_fin: ekgDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }
   //obtener la información del triaje del paciente
   const getPacienteEkg = async () => {
      await http
         .get(`ekg/get/${idPaciente}`)
         .then((response) => {

            //SETEAR EL ID DE TRIAJE
            if (response?.data?.resp) {
               setIdEkg(response?.data?.resp?.id)
               fetchGetAllData();
            }
            if (response.data.error) {
               dispatch(endLoading());
            }
         })
         .catch(err => {
            dispatch(endLoading());
         });
   }

   const createPesoTalla = async (formData) => {
      await http
         .post(`ekg/datosekg/create/${idEkg}`, formData)
         .then(() => {
         })
         .catch((error) => {
            console.error("Error en Datos Ekg", error);
         })
   }


   //GAURDAR CAMBIOS DE TRIAJE
   const fetchAllData = async () => {
      const formData = new FormData();
      formData.append('archivo', datosContext.archivo);
      formData.append('ritmo', datosContext.ritmo);
      formData.append('pr', datosContext.pr);
      formData.append('colegiatura', datosContext.colegiatura);
      formData.append('eje_electrico', datosContext.eje_electrico);
      formData.append('frecuencia', datosContext.frecuencia);
      formData.append('medico_evaluador', datosContext.medico_evaluador);
      formData.append('qrs', datosContext.qrs);
      formData.append('qt', datosContext.qt)
      formData.append('recomendaciones', datosContext.recomendaciones)
      formData.append('conclusiones', datosContext.conclusiones)
      try {
         await Promise.all([
            createPesoTalla(formData),
         ])
         showToast(
            "success",
            `Atención en Psicología guardada`,
            `Se guardó la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const ekgCreate = () => {
      const newData = {
         atencion_id: idPaciente,
      };

      http
         .post("ekg/create", newData)
         .then((response) => {
            if (response && response.data.ekg_id) {
               const EKgId = response.data.ekg_id;
               setIdEkg(EKgId);
               showToast("success", "Exito", "EKG creado con exito");
            } else {
               console.error("La respuesta no contiene EKG_Id");
               showToast("error", "Exito", "No se encontre la EKG");
            }
         })
         .catch((error) => {
            console.error("Error al crear la solicitud de EKG:", error);
            showToast("error", "Error", error.message);
         });
   };



   const [archivoUrl, setArchivoUrl] = useState('');

   useEffect(() => {
      // Verifica si ya tienes un archivo cargado en datosContext.archivo
      if (datosContext.archivo) {
         if (typeof datosContext.archivo === 'string') {
            // Si el archivo ya es una URL, simplemente establece la URL
            setArchivoUrl(datosContext.archivo);
         } else {
            // Si el archivo es un objeto (por ejemplo, un File), lee su contenido
            const reader = new FileReader();
            reader.onload = () => {
               setArchivoUrl(reader.result);
            };
            reader.readAsDataURL(datosContext.archivo);
         }
      }
   }, [datosContext.archivo]);


   const getDatosEkg = async () => {
      await http
         .get(`ekg/datosekg/get/${idPaciente}`)
         .then((response) => {
            if (response.data.resp) {
               const Data = response.data.resp;
               const nombreArchivo = response.data.resp.archivo == null ? "" : response.data.resp.archivo.match(/\/([^/?#]+)$/)[1];
               setDatosContext({
                  ...datosContext,
                  ritmo: Data.ritmo,
                  pr: Data.pr,
                  frecuencia: Data.frecuencia,
                  qrs: Data.qrs,
                  eje_electrico: Data.eje_electrico,
                  qt: Data.qt,
                  conclusiones: Data.conclusiones,
                  recomendaciones: Data.recomendaciones,
                  medico_evaluador: Data.medico_evaluador,
                  colegiatura: Data.colegiatura,
                  archivo: Data.archivo ? Data.archivo.replace("/app", "") : "",
                  nombre_archivo: nombreArchivo
               })

            }

         })
         .catch((error) => {
            console.error(error);
         });
   };

   const fetchGetAllData = async () => {
      try {
         await Promise.all([
            getDatosEkg(idPaciente),
         ])
         showToast(
            "success",
            `Atención en Obtenida`,
            `Datos traidos correctamente la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const guardarDatos = () => {
      dispatch(startLoading());
      setVisibleGuardar(false);
      fetchAllData();
   }

   const restablecerDatos = () => {
      dispatch(startLoading());
      setVisibleCancelar(false);
      fetchGetAllData();
   }

   const renderTabs = (area, key) => {
      const optionMapping = {
         48: (
            <TabPanel key={key} header="Datos">
               <EkgPreguntas url={archivoUrl} seturl={setArchivoUrl} />
            </TabPanel>
         ),

         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      dispatch(startLoading());
      getPacienteEkg()
      getDocumentos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <>
         <Toast ref={toast} />
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            {
               ekgAreasContext?.map((area, key) => {
                  return renderTabs(area, key)
               })
            }
         </TabView>

         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />

         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="EKG"
            onNo={() => setVisibleGuardar(false)}
            onYes={guardarDatos}
            onHide={() => setVisibleGuardar(false)}
         />
         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Triaje"
            onNo={() => setVisibleCancelar(false)}
            onYes={restablecerDatos}
            onHide={() => setVisibleCancelar(false)}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}
         />

         {ekgDetalleId.estado === "0" && (
            <RATimeView
               title="Ekg"
               AreaDetalleId={ekgDetalleId?.id}
               icon={iconEkg}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={ekgCreate}
               textButton='Iniciar atención'
            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title={"Ekg"}
               AreaDetalleId={ekgDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton="finalizar revisión"
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraInicio={ekgDetalleId?.hora_inicio}
               VerfechaInicio={ekgDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}

         {estado && revision !== 1 && (
            <RATimeView
               title={"Ekg"}
               AreaDetalleId={ekgDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton="finalizar atención"
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraInicio={ekgDetalleId?.hora_inicio}
               VerfechaInicio={ekgDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageEkg;

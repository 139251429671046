import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Button } from "primereact/button";

import { Dialog } from 'primereact/dialog';

import "../../../../Style/ProductoyServicios/pageGestionProformasE.css";
import { contentService } from '../../../../Services/dataGestion';
import { ScrollPanel } from "primereact/scrollpanel";
import DialogDetalles from '../../../../Components/ProformaPopUp/DialogDetalles';
import DialogProforma from '../../../../Components/ProformaPopUp/DialogProforma';
import DialogEstadoGestion from '../../../../Components/ProformaPopUp/DialogEstado';

export default function PageGestionPlataformasE() {
   
   
   const [dialogVisible, setDialogVisible] = useState(false);
   const bg_state = (btn) => {
      switch (btn) {
         case "En espera":
            return "proforma__container_body_table-corporativo_state_purple";
         case "Aceptado":
            return "proforma__container_body_table-corporativo_state_green";
         case "Rechazado":
            return "proforma__container_body_table-corporativo_state_red";
         default:
            return "";
      }
   };

   

   return (
      <div className="proforma__container-corporativo">
         <div className="add__container_header-corporativo">
            <h4 className="add__container_header_h4">Gestión de Proformas</h4>
            <p className="add__container_header_p">
               En este módulo usted podrá ver el historial de proformas.
            </p>
         </div>
         <div className="proforma__container_input_header-corporativo">
            <span  className="buscador p-input-icon-left  bg-transparent">
               <i className="px-2 pi pi-search" />
               <InputText
                  placeholder="Buscador"
                  className="input_responsive-corporativo border-0 border-round-3xl shadow-2"
               />
            </span>
         </div>
         <div className="proforma__container_body-corporativo bg-white">
            <ScrollPanel
               style={{ width: "100%", height: "100%" }}
               className="custombar2"
            >
               <table className="proforma__container_body_table-corporativo">
                  <thead>
                     <tr>
                        <th className="text-center">Item</th>
                        <th className="text-center">Código de Proforma</th>
                        <th className="text-center">Proforma</th>
                        <th className="text-center">Encuesta</th>
                        <th className="text-center">Estado</th>
                        <th className="text-center">Envío</th>
                     </tr>
                  </thead>
                  <tbody>
                     {contentService.map((item) => (
                        <tr>
                           <td className="text-center proforma__container_body_table_txt_proform-corporativo">
                              {item.id}
                           </td>
                           <td className="text-center proforma__container_body_table_txt_proform-corporativo">
                              {item.codeProform}
                           </td>
                           <td>
                              <div className="flex align-items-center justify-content-center w-full h-full">
                                 <DialogProforma/>
                              </div>
                           </td>
                           <td>
                              <div
                                 style={{ cursor: "pointer", color: "#5555D8" }}
                                 className="w-full h-full gap-2 flex justify-content-center align-items-center">
                                 <DialogDetalles/>
                                 <>
                                 </>
                              </div>
                           </td>
                           <td>
                              <div className="flex h-full w-full justify-content-center">
                              <Button style={{borderColor: 'transparent'}}
                                 className={bg_state(item.status)}
                                 onClick={() => setDialogVisible(true)}
                              >  
                                 <i className=""></i>
                                 <p>{item.status}</p>
                                                     
                              </Button>
                              </div>
                           </td>

                           <td>
                              <div className="flex  align-items-center justify-content-center w-full h-full ">
                                 <Button style={{backgroundColor: 'transparent', borderColor: 'transparent'}}>
                                 <i className="pi pi-send proforma__container_body_table_txt_pdf-corporativo"></i>
                                 </Button>
                              </div>
                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </ScrollPanel>
         </div>
         {dialogVisible && (
            <DialogEstadoGestion
            dialogVisible={dialogVisible}
            setDialogVisible={setDialogVisible}
            />
         )}
      </div>

   );
}
import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { Footer, Header } from '../../../../Components/Pure/SeccionesNavegacion'
import MapaLocal from "./MapaLocal";

export default function ModalCrearLocal(props) {
   //Agregado
   const [nombreValido, setNombreValido] = useState(false);
   const [distritoValido, setDistritoValido] = useState(false);
   const [direccionValida, setDireccionValida] = useState(false);
   //Datos del local
   const [datosLocal, setDatosLocal] = useState({
      id: null,
      nombre: "",
      direccion: "",
      latitud: null,
      longitud: null,
      departamento_id: null,
      departamento: null,
      distrito_id: null,
      distrito: null,
      provincia_id: null,
      provincia: null,
   });
   const [departamento, setDepartamento] = useState(null);
   const [provincia, setProvincia] = useState(null);
   const [provincias, setProvincias] = useState(null);
   const [distritos, setDistritos] = useState(null);
   const [distrito, setDistrito] = useState(null);
   //Errores
   const [nombreError, setNombreError] = useState(false);
   const [departamentoError, setDepartamentoError] = useState(false);
   const [provinciaError, setProvinciaError] = useState(false);
   const [distritoError, setDistritoError] = useState(false);
   const [direccionError, setDireccionError] = useState(false);

   //Montar si el estado de la visibilidad es verdadero
   useEffect(() => {
      if (!props.visible1.status) {
         //Eliminar datos reciduales al cerrar el pop-up
         setNombreError(false); setDepartamentoError(false); setProvinciaError(false); setDistritoError(false); setDireccionError(false);
         setDepartamento(null); setProvincia(null); setProvincias(null); setDistrito(null); setDistritos(null);
      }
   }, [props.visible1.status]);

   useEffect(() => {
      if (props.visible1.nombre === "Editar local") {
         setDatosLocal({
            ...datosLocal,
            id: props.local.id,
            nombre: props.local.nombre,
            direccion: props.local.direccion,
            latitud: props.local.latitud,
            longitud: props.local.longitud,
            departamento_id: props.local.departamento_id,
            departamento: props.local.departamento,
            distrito_id: props.local.distrito_id,
            distrito: props.local.distrito,
            provincia_id: props.local.provincia_id,
            provincia: props.local.provincia,
         });
         // si la lista de departamentos no es nulo se ejecuta el codigo
         if (props.local.departamento !== null && props.departamentos !== null) {
            // filtrar los datos de la lista de departamentos para obtener solo el departamento del local
            let _departamento = props.departamentos?.filter(
               (departamentos) => departamentos.id === props.local.departamento_id
            );
            // chuntar el departamento
            setDepartamento(_departamento[0]);

            // Chuntar la lista de provincias del departamento
            setProvincias(_departamento[0]?.provincias);

            if (props.local.provincia !== null) {
               // filtrar los datos de la lista de provincias para obtener solo la provincia del local ...
               let _provincia = _departamento[0]?.provincias.filter(
                  (provincias) => provincias.id === props.local.provincia_id
               );

               setProvincia(_provincia[0]);

               setDistritos(_provincia[0]?.distritos);
               if (props.local.distrito !== null) {
                  let _distrito = _provincia[0]?.distritos.filter(
                     (distritos) => distritos.id === props.local.distrito_id
                  );

                  setDistrito(_distrito[0]);
               }
            }
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.visible1]);

   //Función para limpiar campos y resetear validaciones
   const LimpiarDatos = () => {
      setDatosLocal({
         ...datosLocal,
         nombre: "",
         direccion: "",
         latitud: null,
         longitud: null,
         departamento_id: null,
         departamento: null,
         distrito_id: null,
         distrito: null,
         provincia_id: null,
         provincia: null,
      });
      setDepartamento(null);
      setProvincia(null);
      setDistrito(null);
      //Agregado
      setNombreValido(false);
      setDistritoValido(false);
      setDireccionValida(false);
   };

   //Reestablecer campos
   useEffect(() => {
      if (props.visible1.status) {
         setNombreValido(false);
         setDistritoValido(false);
         setDireccionValida(false);
      }
   }, [props.visible1.status]);

   //Cierra el modal y limpia los datos
   const handleChangeOcultar = (e) => {
      LimpiarDatos();
      props.setVisible1({ ...props.visible1, status: false, nombre: "" });
   };

   //Procesa creación y edición de un local
   const handleChangeCrear = (e) => {
      props.handleCreate(datosLocal);
      LimpiarDatos();
      props.setVisible1({ ...props.visible1, status: false, nombre: "" });
   };

   //Edita un local, limpia datos y cierra el modal respectivamente
   const handleChangeEditar = (id, datos) => {
      props.handleEditarlocal(id, datos);
      LimpiarDatos();
      props.setVisible1({ ...props.visible1, status: false, nombre: "" });
   };

   //Actualizan datos cuando no existen cambios en los input
   const handleChangeNombre = (e) => {
      setDatosLocal({ ...datosLocal, nombre: e.target.value });
      setNombreValido(e.target.value.trim() !== "");
   };

   // Actualiza la dirección en datosLocal y valida que no se encuentre vacía
   const handleChangeDireccion = (e) => {
      const direccion = e.target.value;
      setDatosLocal({ ...datosLocal, direccion: direccion });
      setDireccionValida(direccion.trim() !== "");
   };


   const handleChangeDepartamento = (e) => {
      setDepartamento(e.value);
      setProvincias(e.value === null ? null : e.value.provincias);
      setDatosLocal({
         ...datosLocal,
         departamento_id: e.value === null ? null : e.value.id,
         provincia: null,
         provincia_id: null,
         distrito: null,
         distrito_id: null,
      });
      setDistritos(null);

      //Para que no queden datos reciduales
      setProvincia(null);
      setDistrito(null)

      //Por si cambia de departamento no quede activo
      setDistritoValido(null);
   };

   const handleChangeProvincia = (e) => {
      setProvincia(e.value);
      setDatosLocal({
         ...datosLocal,
         provincia_id: e.value === null ? null : e.value.id,
         distrito: null,
         distrito_id: null,
      });
      setDistritos(e.value === null ? null : e.value.distritos);

      //Para que no queden datos reciduales
      setDistrito(null)

      //Por si cambia de departamento no quede activo
      setDistritoValido(null);
   };

   const handleChangeDistrito = (e) => {
      //Actualiza el distrito seleccionado
      setDistrito(e.value);

      //Actualiza el valor del id del distrito en datosLocal
      setDatosLocal({
         ...datosLocal,
         distrito_id: e.value === null ? null : e.value.id,
      });

      //Valida que el distrito no sea nulo
      setDistritoValido(true);
   };

   const header = (<Header icono={"https://i.ibb.co/LR6pJxr/Icon-1.png"} titulo={props.visible1.nombre} subtitulo={"Busca tu negocio en el mapa y crea tu local."} />)

   const footer = (
      <Footer onClickCancelar={() => handleChangeOcultar()}
         onClickEnviar={() => {
            if (props.visible1.nombre === 'Crear local' && nombreValido && distritoValido && direccionValida) {
               handleChangeCrear();
               LimpiarDatos();
               props.setVisible1({ ...props.visible1, status: false, nombre: "" });
            } else if (props.visible1.nombre === 'Editar local' && datosLocal.nombre.trim() !== '' && datosLocal.direccion.trim() !== '' && distrito !== null) {
               handleChangeEditar(props.local.id, datosLocal);
               LimpiarDatos();
               props.setVisible1({ ...props.visible1, status: false, nombre: "" });
            } else {
               //Valida que el nombre, departamento, provincia, distrito y dirección no sean nulos
               setNombreError(datosLocal.nombre.trim() === '');
               setDepartamentoError(departamento === null);
               setProvinciaError(provincia === null);
               setDistritoError(distrito === null);
               setDireccionError(datosLocal.direccion.trim() === '');
            }
         }}
         label={'Guardar'} />
   )

   //Renderizado
   return (
      <Dialog header={header} footer={footer} className="border-circle" visible={props.visible1.status} style={{ borderRadius: '50px', width: '60vw' }} onHide={() => handleChangeOcultar()}>
         <div className="flex flex-wrap gap-5 pt-3 justify-content-center">
            <div className="flex  flex-column gap-2 responsive" style={{ width: "38%" }}>
               <div className="flex flex-column gap-1 pt-3">
                  <p>Nombre</p>
                  <InputText className={`w-full ${nombreError ? 'p-invalid' : ''}`} placeholder="Nombre" value={datosLocal.nombre} onChange={(e) => handleChangeNombre(e)} />
               </div>
               {/* Validación de nombre cuando es inexistente  */}
               {nombreError && <small className="p-error">Ingrese un Nombre</small>}
               {!nombreError && <small className="p-error">&nbsp;</small>}

               <div className="flex flex-column gap-1">
                  <p>Departamento</p>
                  <Dropdown
                     className={`w-full ${departamentoError ? 'p-invalid' : ''}`}
                     value={departamento}
                     id="departamento"
                     options={props.departamentos}
                     onChange={handleChangeDepartamento}
                     optionLabel="departamento"
                     placeholder="Selecciona un departamento"
                     filter
                  />
               </div>

               {/* Validación de departamento cuando es inexistente  */}
               {departamentoError && <small className="p-error">{"Seleccione un Departamento"}</small>}
               {!departamentoError && <small className="p-error">&nbsp;</small>}

               <div className="flex flex-column gap-1">
                  <p>Provincia</p>
                  <Dropdown
                     className={`w-full ${provinciaError ? 'p-invalid' : ''}`}
                     id="departamento"
                     value={provincia}
                     options={provincias}
                     onChange={handleChangeProvincia}
                     optionLabel="provincia"
                     placeholder="Selecciona Provincia"
                     filter
                  />
               </div>

               {/* Validación de provincia cuando es inexistente  */}
               {provinciaError && <small className="p-error">{"Seleccione una provincia"}</small>}
               {!provinciaError && <small className="p-error">&nbsp;</small>}

               <div className="flex flex-column gap-1">
                  <p>Distrito</p>
                  <Dropdown
                     className={`w-full ${distritoError ? 'p-invalid' : ''}`}
                     id="distrito"
                     value={distrito}
                     options={distritos}
                     onChange={handleChangeDistrito}
                     optionLabel="distrito"
                     placeholder="Selecciona Distrito"
                     filter
                  />
               </div>

               {/* Validación de distrito cuando es inexistente  */}
               {distritoError && <small className="p-error">{"Seleccione un Distrito"}</small>}
               {!distritoError && <small className="p-error">&nbsp;</small>}

               <div className="flex flex-column gap-1">
                  <p>Dirección</p>
                  <InputText className={`w-full ${direccionError ? 'p-invalid' : ''}`} placeholder="Dirección"
                     value={datosLocal.direccion} onChange={(e) => handleChangeDireccion(e)}
                  />
               </div>

               {/* Validación de departamento cuando es inexistente  */}
               {direccionError && <small className="p-error">{"Ingrese una Dirección"}</small>}
               {!direccionError && <small className="p-error">&nbsp;</small>}
            </div>
            
            <MapaLocal
               datosLocal={datosLocal}
               setDatosLocal={setDatosLocal}
               handleChangeDireccion={handleChangeDireccion}
            ></MapaLocal>
         </div>
      </Dialog>
   );
}
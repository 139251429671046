import React, { useContext } from "react";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import {
   iconCANCELADO,
   iconNO_REALIZADO,
   iconPROCESO,
   iconREALIZADO,
} from "../../../Services/importIcons";

const RAResultadosTimeLine = () => {
   const { listRutasContext, atencionContext } = useContext(RAContext);

   const getStatusService = (status) => {

      const icons = {
         "-1": iconCANCELADO,
         0: iconNO_REALIZADO,
         1: iconPROCESO,
         2: iconREALIZADO,
         3: iconCANCELADO,
      };
      return icons[status] || null;
   };




   return (
      <>
         <div className="rar_hr_time_line">
            {listRutasContext?.map((item, index) => {
               let hojaRuta = atencionContext?.find((v) => v?.nombre === item?.nombre);
               const formatDate = (dateString) => {
                  const date = new Date(dateString);
                  const day = date.getDate();
                  const month = date.getMonth() + 1;
                  const year = date.getFullYear();
                  const formattedDate = `${day}/${month}/${year}`;

                  return formattedDate;
               };
               const fechaInicioFormateada = formatDate(hojaRuta?.fecha_inicio);
               const fechaFinFormateada = formatDate(hojaRuta?.fecha_fin);

               const validarFecha = (dataString) => {
                  if (dataString === "31/12/1969") {
                     const vacío = "--";
                     return vacío;
                  }
                  else {
                     const fechaValida = dataString;
                     return fechaValida;
                  }
               }

               const fechaFinValida = validarFecha(fechaFinFormateada);
               const fechaInicioValida = validarFecha(fechaInicioFormateada);

               const validarHora = (dataString) => {
                  if (dataString === null) {
                     const vacío = "--";
                     return vacío;
                  }
                  else {
                     const horaValida = dataString;
                     return horaValida;
                  }
               }
               const horaInicio= validarHora(hojaRuta?.hora_inicio);
               const horaFin= validarHora(hojaRuta?.hora_fin);


               if (hojaRuta !== undefined) {
                  return (
                     <React.Fragment key={item.id}>
                        <div className="rar_hr_time_line_timeline">
                           {hojaRuta?.nombre !== "No realizado" &&
                              hojaRuta?.nombre !== "En espera" ? (
                              <>
                                 {hojaRuta?.nombre !== "En proceso" ? (
                                    <div className="rar_hr_time_line_timeline_circle" />
                                 ) : (
                                    <div className="rar_hr_time_line_timeline_circle_active" />
                                 )}

                                 {index + 1 === listRutasContext?.length ? (
                                    <></>
                                 ) : hojaRuta?.nombre !== "En proceso" ? (
                                    <div className="rar_hr_time_line_timeline_lineal" />
                                 ) : (
                                    <div className="rar_hr_time_line_timeline_lineal_disabled" />
                                 )}
                              </>
                           ) : (
                              <>
                                 <div className="rar_hr_time_line_timeline_circle_disabled" />
                                 {index + 1 === listRutasContext?.length ? (
                                    <></>
                                 ) : (
                                    <div className="rar_hr_time_line_timeline_lineal_disabled" />
                                 )}
                              </>
                           )}
                        </div>

                        <div className="rar_hr_time_line_content">
                           {hojaRuta?.nombre !== "En proceso" ? (
                              <div className="rar_hr_time_line_content_ruta">
                                 <img src={item?.icon} alt="icon_ruta" />
                                 <label>{item?.nombre}</label>
                                 <br />
                              </div>
                           ) : (
                              <div className="rar_hr_time_line_content_ruta_active">
                                 <img src={item?.icon} alt="icon_ruta" />
                                 <label>{item?.nombre}</label>
                                 <br />
                              </div>
                           )}

                           <div className="rar_hr_time_line_content_status">
                              <span>{hojaRuta?.nombre}</span>
                              {hojaRuta?.nombre !== "En espera" && (
                                 <img
                                    src={getStatusService(hojaRuta?.estado_ruta)}
                                    alt="icon_status"
                                    style={{ height: "18px" }}
                                 />
                              )}
                              <br />
                           </div>
                           {hojaRuta?.nombre !== "No realizado" &&
                              hojaRuta?.nombre !== "En espera" &&
                              hojaRuta?.nombre !== "No corresponde" && (
                                 <div className="rar_hr_time_line_content_fecha">
                                    <span>Inicio: {horaInicio + " - " + fechaInicioValida}</span>

                                    <br />
                                    <span>Fin: {horaFin + " - " + fechaFinValida}</span>
                                 </div>
                              )}
                        </div>
                     </React.Fragment>
                  );

               }
               return <></>

            })}
         </div>
      </>
   );
};

export default RAResultadosTimeLine;

import React, {createContext, useContext, useEffect, useState} from 'react';
import AuthUser from "../../AuthUser";

export const OdoContext = createContext(undefined)

export const OdoProvider = (props) => {

  const {http} = AuthUser();

  const [datosContext, setDatosContext] = useState({});
  const [preguntasContext, setPreguntasContext] = useState({});

  const [atencionStatus, setAtencionStatus] = useState(0);
  const [timeStart, setTimeStart] = useState('');

  useEffect(() => {
  }, [])

  return (
    <OdoContext.Provider
      value={{
        datosContext, setDatosContext,
        preguntasContext, setPreguntasContext,
        atencionStatus, setAtencionStatus,
        timeStart, setTimeStart
      }}
    >
      {props.children}
    </OdoContext.Provider>
  );
};
import React, { useState } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

export default function NCPDatosPersonales() {
    const [menor , setMenor] =useState(false);
    const [checked, setChecked] = useState(false);

    return (
        <form className="flex flex-column gap-2 p-3 bg-white border-round-lg">
            <div className="flex flex-column gap-2">
                <img
                    width="40px"
                    height="40px"
                    className="p-1 bg-indigo-100 border-round-lg"
                    src="https://i.ibb.co/9pL0hHB/Icon.png"
                    alt=""
                />
            </div>
            <div className="flex gap-2">
                <div className="flex flex-1 flex-column gap-2">
                    <div className='flex gap-2 justify-content-between align-items-center'>
                        <p className="font-bold text-lg text-indigo-500">Datos personales</p>
                        <div className='flex gap-2'>
                            <Button className="flex text-sm text-indigo-500 bg-white" style={{ height: "35px" }} label='Agregar nuevo paciente' icon='pi pi-plus' iconPos='left' />
                        </div>
                    </div>
                    {!checked && (
                        <>
                            <p className="font-bold text-base">Paciente (titular)</p>

                            <div className='flex gap-2 justify-content-between align-items-center'>
                                <label htmlFor="nombresApellidosT" className="text-sm">Nombres y Apellidos</label>
                                <div className='flex gap-2 align-items-center'>
                                    <label htmlFor="menorEdadT" className="text-sm">Paciente Menor de edad</label>
                                    <InputSwitch checked={menor} onChange={(e) => setMenor(e.value)} style={{ height: '21px' }} />
                                </div>
                            </div>
                            <InputText
                                //value={}
                                //onChange={}
                                name="nombresApellidosT"
                                id="nombresApellidosT"
                                type="text"
                                placeholder=""
                                className="p-inputtext-sm p-2"
                            />
                            <div className="flex gap-2 flex-wrap">
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="DNIPaciente" className="text-sm">DNI del paciente</label>
                                    <InputNumber
                                        //onValueChange={OnchangeInputs}
                                        //value={sendVentas.GCEmpresa.añosActividad === 0 ? null : sendVentas.GCEmpresa.añosActividad}
                                        maxLength={7}
                                        useGrouping={false}
                                        inputId="DNIPaciente"
                                        name="DNIPaciente"
                                        placeholder="1234567"
                                        className='flex p-inputtext-sm'
                                    />
                                </div>
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="fecha_nacimientoT" className="text-sm white-space-nowrap">Fecha de nacimiento</label>
                                    <Calendar
                                        //value={new Date(sendVentas.GCEmpresa.aniversario)}
                                        //onChange={handleDateChange}
                                        id='fecha_nacimientoT'
                                        name='fecha_nacimientoT'
                                        showIcon showButtonBar
                                        placeholder="dd.mm.yy" dateFormat="dd/mm/yy"
                                        className="flex p-inputtext-sm"
                                        style={{ width: "100%", height: "42px" }}
                                    />
                                </div>
                            </div>
                            <label htmlFor="DNIPaciente" className="text-sm">Fecha</label>
                            <Calendar
                                //value={new Date(sendVentas.GCEmpresa.aniversario)}
                                //onChange={handleDateChange}
                                id='fecha_nacimientoT'
                                name='fecha_nacimientoT'
                                showIcon showButtonBar
                                placeholder="dd.mm.yy" dateFormat="dd/mm/yy"
                                className="p-inputtext-sm w-19rem"
                                style={{ width: "w-19rem", height: "42px" }}
                            />
                            <p className="font-bold text-base mt-4">Paciente 2</p>
                            <div className='flex gap-2 justify-content-between align-items-center'>
                                <label htmlFor="nombresApellidosT" className="text-sm">Nombres y Apellidos</label>
                                <div className='flex gap-2 align-items-center'>
                                    <label htmlFor="menorEdadT" className="text-sm">Paciente Menor de edad</label>
                                    <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} style={{ height: '21px' }} />
                                </div>
                            </div>
                            <InputText
                                //value={}
                                //onChange={}
                                name="nombresApellidosT"
                                id="nombresApellidosT"
                                type="text"
                                placeholder=""
                                className="p-inputtext-sm p-2"
                            />
                            <div className="flex gap-2">
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="DNIPaciente" className="text-sm">DNI del paciente</label>
                                    <InputNumber
                                        //onValueChange={OnchangeInputs}
                                        //value={sendVentas.GCEmpresa.añosActividad === 0 ? null : sendVentas.GCEmpresa.añosActividad}
                                        maxLength={7}
                                        useGrouping={false}
                                        inputId="DNIPaciente"
                                        name="DNIPaciente"
                                        placeholder="1234567"
                                        className='flex p-inputtext-sm'
                                    />
                                </div>
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="fecha_nacimientoT" className="text-sm white-space-nowrap">Fecha de nacimiento</label>
                                    <Calendar
                                        //value={new Date(sendVentas.GCEmpresa.aniversario)}
                                        //onChange={handleDateChange}
                                        id='fecha_nacimientoT'
                                        name='fecha_nacimientoT'
                                        showIcon showButtonBar
                                        placeholder="dd.mm.yy" dateFormat="dd/mm/yy"
                                        className="flex p-inputtext-sm"
                                        style={{ width: "100%", height: "42px" }}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    {checked && (
                        <>
                            <p className="font-bold text-base">Paciente (Menor de edad)</p>
                            <div className='flex gap-2 justify-content-between align-items-center'>
                                <label htmlFor="nombresApellidosT" className="text-sm">Nombres y Apellidos</label>
                                <div className='flex gap-2 align-items-center'>
                                    <label htmlFor="menorEdadT" className="text-sm">Paciente Menor de edad</label>
                                    <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} style={{ height: '21px' }} />
                                </div>
                            </div>
                            <InputText
                                //value={}
                                //onChange={}
                                name="nombresApellidosT"
                                id="nombresApellidosT"
                                type="text"
                                placeholder=""
                                className="p-inputtext-sm p-2"
                            />
                            <div className="flex gap-2">
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="DNIPaciente" className="text-sm">DNI del paciente</label>
                                    <InputNumber
                                        //onValueChange={OnchangeInputs}
                                        //value={sendVentas.GCEmpresa.añosActividad === 0 ? null : sendVentas.GCEmpresa.añosActividad}
                                        maxLength={7}
                                        useGrouping={false}
                                        inputId="DNIPaciente"
                                        name="DNIPaciente"
                                        placeholder="1234567"
                                        className='flex p-inputtext-sm'
                                    />
                                </div>
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="fecha_nacimientoT" className="text-sm white-space-nowrap">Fecha de nacimiento</label>
                                    <Calendar
                                        //value={new Date(sendVentas.GCEmpresa.aniversario)}
                                        //onChange={handleDateChange}
                                        id='fecha_nacimientoT'
                                        name='fecha_nacimientoT'
                                        showIcon showButtonBar
                                        placeholder="dd.mm.yy" dateFormat="dd/mm/yy"
                                        className="flex p-inputtext-sm"
                                        style={{ width: "100%", height: "42px" }}
                                    />
                                </div>
                            </div>
                            <label htmlFor="DNIPaciente" className="text-sm">Fecha</label>
                            <Calendar
                                //value={new Date(sendVentas.GCEmpresa.aniversario)}
                                //onChange={handleDateChange}
                                id='fecha_nacimientoT'
                                name='fecha_nacimientoT'
                                showIcon showButtonBar
                                placeholder="dd.mm.yy" dateFormat="dd/mm/yy"
                                className="p-inputtext-sm w-19rem"
                                style={{ width: "w-19rem", height: "42px" }}
                            />
                            <p className="font-bold text-base mt-4">Tutor legal (Titular)</p>
                            <div className='flex gap-2 justify-content-between align-items-center'>
                                <label htmlFor="nombresApellidosT" className="text-sm">Nombres y Apellidos</label>
                                    <InputSwitch style={{ height: '21px', visibility: 'hidden' }} disabled/>
                            </div>
                            <InputText
                                //value={}
                                //onChange={}
                                name="nombresApellidosT"
                                id="nombresApellidosT"
                                type="text"
                                placeholder=""
                                className="p-inputtext-sm p-2"
                            />
                            <div className="flex gap-2">
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="dniT2" className="text-sm">DNI del tutor</label>
                                    <InputNumber
                                        //onValueChange={OnchangeInputs}
                                        //value={sendVentas.GCEmpresa.añosActividad === 0 ? null : sendVentas.GCEmpresa.añosActividad}
                                        maxLength={7}
                                        useGrouping={false}
                                        inputId="DNIPaciente"
                                        name="DNIPaciente"
                                        placeholder="1234567"
                                        className='flex p-inputtext-sm'
                                    />
                                </div>
                                <div className="flex flex-column gap-2 flex-1">
                                    <label htmlFor="parentesco" className="text-sm white-space-nowrap">Parentesco</label>
                                    <Dropdown
                                        id="parentesco"
                                        //options={optionsTipoClientes}
                                        //onChange={handleTipoCliente}
                                        //value={sendVentas.GCEmpresa.tipo_cliente_id}
                                        placeholder="Madre"
                                        className="p-inputtext-sm"
                                    />
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="flex flex-1 justify-content-center">
                    <img
                        className="flex m-2"
                        src="https://i.ibb.co/c2b3yt2/Group-3402.png"
                        alt=""
                        style={{ width: "448.82px", height: "422.15px" }}
                    />
                </div>
            </div>
            <div className='flex justify-content-end'>
                <Button label="Continuar" className="flex text-sm" style={{ width: "120px", height: "42px" }} />
            </div>
        </form>
    )
}
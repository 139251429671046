import React, { useEffect, useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const BVoice = ({ onChange, placeholder, value, disabled }) => {
  const [isRecognitionStarted, setIsRecognitionStarted] = useState(false);
  const [recognition, setRecognition] = useState(null);

  useEffect(() => {
    const webkitSpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    const newRecognition = new webkitSpeechRecognition();
    newRecognition.lang = 'es-ES';
    newRecognition.continuous = true;
    newRecognition.interimResults = true;

    newRecognition.onstart = () => {
      setIsRecognitionStarted(true);
    };

    newRecognition.onresult = (event) => {
      let tempTranscript = '';

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        tempTranscript += event.results[i][0].transcript;
      }

      onChange(tempTranscript);
    };

    newRecognition.onend = () => {
      setIsRecognitionStarted(false);
    };

    setRecognition(newRecognition);
  }, [onChange]);

  const toggleRecognition = () => {
    if (isRecognitionStarted) {
      recognition.stop();
    } else {
      recognition.start();
    }
  };

  return (
    <div className="flex flex-row w-full align-items-end">
      <div className="flex w-full">
        <InputTextarea
          className="w-full"
          autoResize
          value={value}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      <div className="flex flex-row justify-content-end" style={{ marginLeft: "-40px", marginBottom: "5px" }}>
        <Button
          style={{ height: "25px", padding: "5px" }}
          label={isRecognitionStarted ? 'Off' : 'On'}
          onClick={toggleRecognition}
        />
      </div>
    </div>
  );
};

export default BVoice;
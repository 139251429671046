import React, { useState, useEffect, useRef } from "react";
import { InputText } from 'primereact/inputtext';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";

//Componentes generales
import AuthUser from "../../../../AuthUser";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { CardLocal, ModalEliminarLocal, ModalCrearLocal } from '../../../../Components/RecursosHumanos/Local/Components/index';

//Estilos únicos
import '../../../../Style/misLocales/misLocales.css'

//Componente principal PageLocales de empresa
function PageMisLocalesE() {
    const dispatch = useDispatch();
    const toast = useRef(null);
    const { http } = AuthUser();
    const [departamentos, setDepartamentos] = useState(null);
    const [locales, setLocales] = useState([])
    const [datosbusqueda, setBusqueda] = useState(locales)
    const [local, setLocal] = useState({
        id: null, nombre: '',
        direccion: '',
        latitud: null,
        longitud: null,
        departamento_id: null,
        departamento: null,
        distrito_id: null,
        distrito: null,
        provincia_id: null,
        provincia: null,
    })
    const [visibleDelete, setVisibleDelete] = useState({
        status: false,
        id: null
    });
    const [visible1, setVisible1] = useState({
        status: false,
        nombre: ''
    });

    //Función para mostrar notificaciones
    const showToast = (type, title, detail) => {
        toast.current.show({ severity: type, summary: title, detail: detail, life: 3000 });
    }

    //Buscar locales basados en el input
    const busqueda = (e) => {
        if (e.target.value !== '') {
            //Filtra los locales que contengan el valor del input en su nombre, departamento, provincia, distrito o dirección
            let datosFiltrados = locales.filter(
                locales => locales.nombre.toLowerCase().includes(e.target.value.toLowerCase()) || locales?.departamento?.departamento.toLowerCase().includes(e.target.value.toLowerCase())
                    || locales?.provincia?.provincia.toLowerCase().includes(e.target.value.toLowerCase()) || locales?.distrito?.distrito.toLowerCase().includes(e.target.value.toLowerCase())
            );
            //Actualiza los datos de busqueda con los filtrados
            setBusqueda(datosFiltrados)
        }
        else {
            //Si no hay valor en el input, muestra todos los locales
            setBusqueda(locales)
        }
    };

    //Integración de apis para editar, eliminar y crear

    const handleEditarlocal = async (id, datos) => {
        //Inicia la carga de la operación
        dispatch(startLoading());
        try {
            const response = await http.put(`empresa/local/update/${id}`, datos)
            if (response?.data?.resp) {
                //Carga datos del local mientras la operación de llamada a la api se ejecuta
                await Promise.all([
                    getLocales()
                ]);
                //Muestra un mensaje de alerta cuando la acción se realiza sin complicaciones
                showToast("success", "Editado correctamente", "El local se editó correctamente")
            } else if (response?.data?.error) {
                //Muestra un mensaje de error cuando la api devuelve un error controlado
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            //Muestra un mensaje de error cuando la api falla y muestra el error en consola
            console.error("Error en la edición del local", error)
            showToast('error', 'Error', 'Contáctese con soporte técnico.')
        } finally {
            //Finaliza la carga de operación
            dispatch(endLoading());
        }
    };

    const handleDelete = async (id) => {
        //Inicia la carga de la operación
        dispatch(startLoading());
        try {
            const response = await http.delete(`empresa/local/delete/${id}`)
            if (response?.data?.resp) {
                //Carga datos del local mientras la operación de llamada a la api se ejecuta
                await Promise.all([
                    getLocales()
                ]);
                //Muestra un mensaje de alerta cuando la acción se realiza sin complicaciones
                showToast("success", "Eliminado correctamente", response?.data?.resp)
            } else if (response?.data?.error) {
                //Muestra un mensaje de error cuando la api devuelve un error controlado
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            //Muestra un mensaje de error cuando la api falla y muestra el error en consola
            console.error("Error en la eliminación del local", error)
            showToast('error', 'Error', 'Contáctese con soporte técnico.')
        } finally {
            //Finaliza la carga de operación
            dispatch(endLoading());
        }
    }

    const handleCreate = async (datosLocal) => {
        dispatch(startLoading());
        try {
            const response = await http.post('empresa/local/create', datosLocal)
            if (response?.data?.resp) {
                await Promise.all([
                    getLocales()
                ])
                setVisible1(false);
                showToast("success", "Se creó correctamente", "El local se creó correctamente")
            } else if (response?.data?.error) {
                showToast("error", "Error en la creación de local", response?.data?.error)
            }
        } catch (error) {
            showToast("error", "Error al crear local", "Contáctese con Soporte Técnico.")
            console.error(error)
        } finally {
            dispatch(endLoading());
        }
    }

    //Funciones para obtener datos de las apis
    const getLocales = async () => {
        try {
            const response = await http.get('empresa/local/show')
            if (response?.data?.data) {
                setLocales(response?.data?.data)
                setBusqueda(response?.data?.data)
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            console.error("error", error)
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
        }

    }

    //Obtener todas las ubicaciones
    const getAllUbicaciones = async () => {
        try {
            const response = await http.get("/ubicacion")
            if (response?.data?.data) {
                setDepartamentos(response?.data?.data);
            } else if (response?.data?.error) {
                showToast("error", "Error", response?.data?.error)
            }

        } catch (error) {
            showToast("error", "Error", "Contáctese con Soporte Técnico.")
        };
    };

    //Carga de datos
    const fetchAllData = async () => {
        try {
            await Promise.all([
                getLocales(),
                getAllUbicaciones(),
            ]);
        } catch (error) {
            console.error('Error al cargar datos:', error);
        } finally {
            dispatch(endLoading());
        }
    }

    //Carga de datos al montar el componente
    useEffect(() => {
        dispatch(startLoading());
        fetchAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Renderizado
    return (
        <div className="flex flex-wrap flex-column gap-5 px-6 MisLocaleEmpresaSD_h">

            <div className="flex flex-wrap flex-column  ">
                <span className="text-indigo-600 text-3xl font-bold">Mis Locales</span>
                <br />
                <p className="w-full text-indigo-200 text-sm">A continuación visualiza los locales previamente creados para poder colocar las áreas que usted a definido en cada unos de sus locales.</p>

            </div>
            <div className=" w-full"  >
                <ScrollPanel className="scroll" >
                    <div className=" p-input-icon-left w-full mb-3  flex justify-content-center " style={{ background: "#F6F7FB", boxShadow: "none" }}>
                        <span className="p-input-icon-left  bg-transparent" style={{ width: '70%', background: "#F6F7FB", boxShadow: "none" }}>
                            <i className=" px-2 pi pi-search" style={{ color: "#ccd2d8" }} />
                            <InputText placeholder="Busca tu local" style={{ background: 'white', width: '100%', height: '43px', top: '251px', left: '774px', borderRadius: '13px' }} onChange={(e) => busqueda(e)} />
                        </span>
                    </div>

                    <div className="px-8 contenedorcard ">
                        <button
                            className="relative overflow-hidden cursor-pointer border-2 border-dashed border-round-2xl flex flex-1 flex-column bg-purple-50 p-3 gap-2 border-round-lg align-items-center 
                            justify-content-center border-indigo-500"

                            style={{ width: "370px", height: "361px", }}
                            onClick={() => setVisible1({ ...visible1, status: true, nombre: 'Crear local' })}
                        >
                            <i className="pi pi-plus-circle text-indigo-600 font-bold text-xl"></i>
                            <p className="font-bold text-indigo-600 text-base">Crear Nuevo Local</p><span className="StepsV_shine"></span>
                        </button>

                        {//Mapeo de datos para renderizar cada local en un card
                            datosbusqueda.map((localitem, key) => {
                                return (
                                    <CardLocal
                                        key={key}
                                        localitem={localitem}//Datos del local actual
                                        local={local}//Detalles del local
                                        setLocal={setLocal}//Actualización de los detalles del local
                                        visible1={visible1}//Visibilidad del componente de edición
                                        setVisible1={setVisible1}
                                        visibleDelete={visibleDelete}//Visibilidad componente de eliminación
                                        setVisibleDelete={setVisibleDelete}
                                        provincia={localitem?.distrito?.provincia?.provincia}//Mostrar provincia del local
                                    />
                                )
                            })
                        }

                    </div>
                </ScrollPanel>
            </div>
            { }
            {/* Modal para crear un nuevo local */}
            <ModalCrearLocal
                local={local}//Detalles del local
                setLocal={setLocal}//Actualización de los detalles del local
                handleCreate={handleCreate}//Función para crear un nuevo local
                setVisible1={setVisible1}
                visible1={visible1}
                departamentos={departamentos}
                handleEditarlocal={handleEditarlocal}//Función para editar un local

            />
            {/* Modal para eliminar un local */}
            <ModalEliminarLocal
                handleDelete={handleDelete}//Función para eliminar un local
                setVisibleDelete={setVisibleDelete}
                visibleDelete={visibleDelete}
            />

            <Toast ref={toast} />
        </div>
    )
}

export default PageMisLocalesE
import { Button } from 'primereact/button';
import '../../../Style/EstilosSoporteBregma/TutorialesVistaAHP.css'

export default function TutorialesVista({vistaselect,setVistaSelected}) {
  function LimpiarContorno (miElemento){
    // var ElementoMorado = document.getElementById('Cont_Morado');
    // ElementoMorado.classList.remove('contorno_selecto'); 
    var ElementoAzul = document.getElementById('Cont_Azul');
    ElementoAzul.classList.remove('contorno_selecto'); 
    var ElementoVerde = document.getElementById('Cont_Verde');
    ElementoVerde.classList.remove('contorno_selecto'); 
    var ElementoAmarillo = document.getElementById('Cont_Amarillo');
    ElementoAmarillo.classList.remove('contorno_selecto'); 
    var miElement = document.getElementById(miElemento);
    miElement.classList.add("contorno_selecto"); 
    setVistaSelected(miElemento)
  }


  return (
    <div className='Contenedor_princip_VistaTuto'>
      <div className="contenedor_data_info">
        <div className='contenedor_logo_vistaTuto'>
          <img className="" alt="" src="https://i.ibb.co/QYRsD1j/Icon.png" />
        </div>
        <h2 className='Letra_titu_vistaTuto'>Tutoriales por vista</h2>
        <p className='Letra_simple_vistaTuto'>Crea nuevos tutoriales en cualquier momento para tu personal de Bregma.</p>
      </div>
      <div className='contendor_de_cubsections_tuto'>
          {/* <div className="contendeor_Section_tutoriales contorno_selecto" id='Cont_Morado' onClick={() => {LimpiarContorno('Cont_Morado')}} style={{borderColor:'#8C62E3'}}>
              <div className="subcontenedorDataIcon">
                <div className="contenedor_iconoTuto_star" style={{background:'#F4ECFF'}}>
                  <i className="pi pi-star  border-round-lg border-teal-00 border-1 p-1 font-bold" style={{color:'#8C62E3'}}></i>
                </div>
                <div className="contenedor_data_tutoVistaBregma">
                  <p className="TextoBregmaTuto" style={{color:'#8C62E3'}}>Bregma</p>
                  <p className="TextousuarioTipoTuto">Administrador</p>
                </div>
              </div>
              <Button className="buton_eliminarTutoVista" icon="pi pi-trash font-bold" style={{color: '#8C62E3', background:'#F4ECFF'}}></Button>
          </div> */}
          <div className="contendeor_Section_tutoriales"  id='Cont_Azul' onClick={() => {LimpiarContorno('Cont_Azul')}} style={{borderColor:'#0581AC'}}>
          <div className="subcontenedorDataIcon">
                <div className="contenedor_iconoTuto_star" style={{background:'#E0F5FD'}}>
                  <i className="pi pi-star  border-round-lg border-teal-00 border-1 p-1 font-bold" style={{color:'#0581AC'}}></i>
                </div>
                <div className="contenedor_data_tutoVistaBregma">
                  <p className="TextoBregmaTuto" style={{color:'#0581AC'}}>Bregma</p>
                  <p className="TextousuarioTipoTuto">Corporativo</p>
                </div>
              </div>
              <Button className="buton_eliminarTutoVista" icon="pi pi-trash font-bold" style={{color: '#0581AC', background:'#E0F5FD'}}></Button>
          </div>
          <div className="contendeor_Section_tutoriales" id='Cont_Verde' onClick={() => {LimpiarContorno('Cont_Verde')}} style={{borderColor:'#00A15C'}}>
          <div className="subcontenedorDataIcon">
                <div className="contenedor_iconoTuto_star" style={{background:'#BFF1DF'}}>
                  <i className="pi pi-star  border-round-lg border-teal-00 border-1 p-1 font-bold" style={{color:'#00A15C'}}></i>
                </div>
                <div className="contenedor_data_tutoVistaBregma">
                  <p className="TextoBregmaTuto" style={{color:'#00A15C'}}>Bregma</p>
                  <p className="TextousuarioTipoTuto">Clínica</p>
                </div>
              </div>
              <Button className="buton_eliminarTutoVista" icon="pi pi-trash font-bold" style={{color: '#00A15C', background:'#BFF1DF'}}></Button>
          </div>
          <div className="contendeor_Section_tutoriales" id='Cont_Amarillo' onClick={() => {LimpiarContorno('Cont_Amarillo')}} style={{borderColor:'#E38621'}}>
          <div className="subcontenedorDataIcon">
                <div className="contenedor_iconoTuto_star" style={{background:'#FEE6BF'}}>
                  <i className="pi pi-star  border-round-lg border-teal-00 border-1 p-1 font-bold" style={{color:'#E38621'}}></i>
                </div>
                <div className="contenedor_data_tutoVistaBregma">
                  <p className="TextoBregmaTuto" style={{color:'#E38621'}}>Bregma</p>
                  <p className="TextousuarioTipoTuto">Usuario final</p>
                </div>
              </div>
              <Button className="buton_eliminarTutoVista" icon="pi pi-trash font-bold" style={{color: '#E38621', background:'#FEE6BF'}}></Button>
          </div>
      </div>
  </div>)}


const convertToFormData = (data, formData = new FormData(), parentKey = '') => {
    for (let key in data) {
        if (data.hasOwnProperty(key)) {
            const value = data[key];
            const newKey = parentKey ? `${parentKey}[${key}]` : key;

            if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    if (item instanceof File) {
                        // Si el elemento es un archivo, lo agregamos directamente al FormData
                        formData.append(`${newKey}[${index}][archivo]`, item);
                    } else if (typeof item === 'object' && item !== null) {
                        // Si es un objeto, llamamos recursivamente a la función
                        convertToFormData(item, formData, `${newKey}[${index}]`);
                    } else {
                        // Si es un valor simple, lo agregamos al FormData
                        formData.append(`${newKey}[${index}]`, item);
                    }
                });
            } else if (typeof value === 'object' && value !== null && !(value instanceof File)) {
                // Si es un objeto (pero no un archivo), llamamos recursivamente a la función
                convertToFormData(value, formData, newKey);
            } else {
                // Si es un valor simple, lo agregamos al FormData
                formData.append(newKey, value);
            }
        }
    }

    return formData;
};
export default convertToFormData
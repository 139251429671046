import { useState } from 'react';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import Logo from "./img/CirculCheck.svg"

export default function DialogEstadoGestion({ dialogVisible, setDialogVisible }) {
    const [visible, setVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [tempOption, setTempOption] = useState(null);

    const [value, setValue] = useState('');

    const handleOptionSelect = (e) => {
        setTempOption(e.value);
    };

    const handleDialogSave = () => {
        setSelectedOption(tempOption);
        setDialogVisible(false);
    };

    const handleDialogCancel = () => {
        setTempOption(selectedOption);
        setDialogVisible(false);
    };

    return (
        <div>


            <Dialog
                // botones guardar cancelar
                visible={dialogVisible}
                style={{ maxWidth: '450px' }}
                onHide={handleDialogCancel}
                footer={
                    <div className='flex' style={{ justifyContent: 'center' }}>
                        <Button label="Cancelar" icon="pi pi-times" onClick={handleDialogCancel} className='p-button-secondary p-button-outlined ' />
                        <Button label="Enviar" icon="pi pi-check" onClick={handleDialogSave} />
                    </div>
                }
                header={
                    <div className="flex align-items-center gap-3">
                        <div className="flex" style={{ width: '60px', height: '60px', background: '#EBEBFF', borderRadius: '10px', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={Logo} alt="" style={{ width: '36px', height: '36px' }} />
                        </div>
                        <div className="flex flex-column" style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <span style={{ fontWeight: '700', fontSize: '18px', color: '#5555D8' }}>Envío</span>
                            <span style={{ fontSize: '14px', fontWeight: '400', color: '#7B8794' }}>¿Usted acepta o rechaza el envío?</span>
                            <span style={{ fontSize: '14px', fontWeight: '400', color: '#7B8794' }}>Marque la opción que desee</span>
                        </div>
                    </div>
                }
            >
                <div style={{ width: "366px" }}>
                    <div className='flex flex-column gap-3' style={{ marginBottom: '15px' }}>
                        {/* Check Aceptado */}
                        <div className='flex flex-row gap-3'>
                            <Checkbox
                                inputId="option1"
                                name="option"
                                value="option1"
                                onChange={handleOptionSelect}
                                checked={tempOption === 'option1' || selectedOption === 'option1'}
                            />
                            <label htmlFor="option1">Aceptado</label>
                        </div>
                        {/* chack rechazado */}
                        <div className='flex flex-row gap-3'>
                            <Checkbox
                                inputId="option2"
                                name="option"
                                value="option2"
                                onChange={handleOptionSelect}
                                checked={tempOption === 'option2' || selectedOption === 'option2'}
                            />
                            <label htmlFor="option2">Rechazado</label>
                        </div>

                    </div>

                    <div style={{ marginBottom: '15px' }}>
                        <span style={{ color: '#7B8794', fontSize: '14px', fontWeight: '400' }}>Finalmente, si tiene un comentario respecto a la decisión escríbala a continuación:  </span>
                    </div>

                    <div>
                        <InputTextarea value={value} onChange={(e) => setValue(e.target.value)} style={{ width: '100%', height: '90px' }} />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import VoiceButton from '../../ButtonVoice/VoiceButton';
import LaboratorioListComponent from "../container/laboratorio_list";
import AuthUser from "../../../AuthUser";
import { LaboratorioContext } from "../../../Page/Laboratorio/LaboratorioProvider";
import DialogArchivoComponent from "../container/dialog_archivo";

export default function ViewPerfilLipidico({tipoExamen, tipoPrueba, idLaboratorio, showToast }) {
    const { http } = AuthUser();

   const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);

   const [prueba, setPrueba] = useState({})
   const [nombreArchivo, setNombreArchivo] = useState(null);
   const [file, setFile] = useState(null);
   const [indexSup, setIndexSup] = useState(0);
   const [indexSub, setIndexSub] = useState(0);
   const [parametros, setParametros] = useState([ { prueba: 'Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
   { prueba: 'Triglicéridos', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
   { prueba: 'HDL Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
   { prueba: 'LDL Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
   { prueba: 'VLDL Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0 , valor_normal: "" },
   {observaciones:"",conclusion:""}

]);

    const [selectedConclusion, setSelectedConclusion] = useState(null);
    const [observaciones, setObservaciones] = useState('');
    const conclusion = [
        { name: 'Normal', code: 'NL' },
        { name: 'Anormal', code: 'AL' },
    ];

    const perfilLipidico = [
        { prueba: 'Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
        { prueba: 'Triglicéridos', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
        { prueba: 'HDL Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
        { prueba: 'LDL Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0, valor_normal: "" },
        { prueba: 'VLDL Colesterol', resultado: 0, um: 0, rango_inicial:0,rango_final:0 , valor_normal: "" }
    ]
    const handleCreate = () => {
        const formData = new FormData();
        formData.append("examen_id", tipoExamen);
        formData.append("prueba[examen_id]", tipoPrueba);
        formData.append("prueba[estado]", 1);
        formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
        formData.append("prueba[cantidad_parametros]", parametros?.length);
        formData.append("prueba[parametros]", JSON.stringify(parametros));
        formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));
  
        http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
           .then(response => {
              
              showToast("success","Creado Correctamente",response?.data?.resp)
           })
           .catch(error => {
              console.error(error)
              showToast("Error","Error de Guardar",error?.data?.resp)
           })
     }

  
     const changeExamenContext = (superior, inferior, auxParametros) => {
        setExamenesContext(examenesContext.map((elementoSup, index) => {
           if (index === superior) {
              let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
                 if (indexAux === inferior) {
                    return {
                       ...prueba,
                       parametros: auxParametros,
                       estado: 1,
                       cantidad_parametros: auxParametros?.length,
                       perfil: auxParametros?.length > 1 ? 2 : 1
                    }
                 }
                 return prueba;
              })
              return { ...elementoSup, pruebas: auxPrueba };
           }
           return elementoSup;
        }))
     }
  
     const handleInputChange = (index, e,name) => {
        const tempTask = [...parametros];
        tempTask[index][name] = e.value;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
     const handleInputInicial = (index, e) => {
        
        const tempTask = [...parametros];
        tempTask[index].rango_inicial = e.target.value;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
     const handleInputFinal = (index, e,name) => {
        const tempTask = [...parametros];
        tempTask[index][name] = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
     const handleValor = (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].valor_normal = e.target.value;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
  
     useEffect(() => {
        
        let sup = 0;
        let inf = 0;
        let auxPruebas = examenesContext.find((examen, index) => {
           if (examen.examen_id === tipoExamen) {
              setIndexSup(index);
              sup = index;
              
           }
           return examen.examen_id === tipoExamen
        });
        let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
           if (prueba.examen_id === tipoPrueba) {
              setIndexSub(index);
              inf = index;
              
           }
           return prueba.examen_id === tipoPrueba
        });
        if (auxPrueba?.archivo) {
           setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
        }
        setPrueba(auxPrueba);
        
        
        let auxParametros = typeof auxPrueba?.parametros === 'string' ?  JSON.parse(auxPrueba?.parametros): auxPrueba?.parametros
       
        if (auxPrueba && auxParametros.length > 0 ) {
            if(auxParametros.length ==5){
                auxParametros.push({observaciones:"",conclusion:""})
            }
            
            setParametros(auxParametros);
        }
        changeExamenContext(sup, inf, auxParametros);
        // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

    return (
        // <div className="flex flex-column w-full h-full" style={{padding: '20px'}}>
        //     <div className="flex flex-column w-full">
        //         <table className="tabla">
        //             <tr>
        //                 <th className="encabezado" style={{ width:"50%" }}>Observaciones</th>
        //                 <th className="encabezado" style={{ width:"50%" }}>Conclusión médica</th>
        //             </tr>
        //             <tr>
        //                 <td className="dato" /*style={{ border: '0px solid white' }}*/>
        //                     <InputTextarea/>
        //                 </td>
        //                 <td className="dato">
        //                     <Dropdown 
        //                     value={selectedConclusion} 
        //                     onChange={(e) => setSelectedConclusion(e.value)} 
        //                     options={conclusion} 
        //                     optionLabel="name" 
        //                     placeholder="Seleccione"/>
        //                 </td>
        //             </tr>
        //         </table>
        //     </div>
        //     <div className="flex" style={{ justifyContent:'space-between' }}>
        //         <div className="flex gap-3" style={{ alignItems:'center' }}>
        //             <span>Diferencial:</span>
        //             <InputNumber placeholder="0" useGrouping={false}/>
        //         </div>
        //         
        //     </div>
        // </div>
        /* <DataTable
            style={{ textAlign: "center" }}
            value={perfilLipidico}>
            <Column field="prueba" header="Prueba"></Column>
            <Column field="resultado" header="Resultado"></Column>
            <Column field="um" header="U.M."></Column>
            <Column field="rango" header="Rango"></Column>
            <Column field="valor_normal" header="Valor Normal"><InputText
                value={observaciones}
                onChange={(e) => setObservaciones(e.value)}
                placeholder='Observaciones' /></Column>
        </DataTable> */
        <>
            <div className='p-5 h-full'>
                <table >
                    <thead>
                        <tr>
                            <th style={{ borderLeft: '0px', width: '20%', textAlign: "center" }}>Prueba</th>
                            <th style={{ width: '20%', textAlign: "center" }}>Resultado</th>
                            <th style={{ width: '20%', textAlign: "center" }}>U.M.</th>
                            <th style={{ width: '25%', textAlign: "center" }}>Rango</th>
                            <th style={{ textAlign: "center" }}>Valor Normal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {perfilLipidico.map((item,index) => (
                            <React.Fragment>
                                <tr key={item.id}>
                                    <td style={{ borderLeft: '0px' }}>{item.prueba}</td>
                                    <td>
                                        <InputText placeholder="Resultado" value={parametros?.[index]?.resultado} onChange={(e)=>{handleInputChange(index,e.target,"resultado")}} />
                                    </td>
                                    <td> mg/dl </td>
                                    <td>
                                        <InputText className='w-4' value={parametros?.[index]?.rango_inicial} onChange={(e)=>{handleInputInicial(index,e)}} />
                                        <span> - </span>
                                        <InputText className='w-4' value={parametros?.[index]?.rango_final} onChange={(e)=>{handleInputFinal(index,e.target.value,"rango_final")}}/>
                                    </td>
                                    <td>
                                        <InputText
                                            placeholder="Valor Normal" value={parametros?.[index].valor_normal} onChange={(e)=>{handleValor(index,e)}}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {/* <div className='flex justify-content-around'> */}
                    <div className='flex flex-column justify-content-around'>
                        <p style={{fontWeight:"bold"}}>Observaciones</p>
                        <InputTextarea
                            value={parametros?.[5]?.observaciones}
                            onChange={(e) => handleInputChange(5,e.target,"observaciones")}
                            style={{width:"100%"}}
                            
                            />
                        
                    </div>
                    <div className='flex flex-block' style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <p style={{ textAlign: "center", fontWeight: "bold", marginTop: "8px" }}>Conclusión Médica</p>
                        <Dropdown
                            value={conclusion[parametros?.[5]?.conclusion=="NL"?0:parametros?.[5]?.conclusion=="AL"?1:null]}
                            onChange={(e) => handleInputFinal(5,e.target.value.code,"conclusion")}
                            options={conclusion}
                            optionLabel="name"
                            placeholder="Seleccione"
                            style={{ marginLeft: "10px", width: "200px" }} />
                    </div>

                    {/*<div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
                        <Button label="Grabar" icon="fas fa-save" />
                        <Button label="Recuperar" icon="fas fa-undo" />
                         <Button label="Hematologo" icon="fas fa-user-md" /> 
                    </div>*/}
                {/* </div> */}
            </div>

            {/*<div className="flex flex-column w-full h-full" style={{ padding: '20px' }}>
    ========================================================================================================================================= */}
{/* ================#03 */}
         {/* <div className="flex flex-column w-full">
            <LaboratorioListComponent
               handleInputChange={handleInputChange}
               handleInputFinal={handleInputFinal}
               handleInputInicial={handleInputInicial}
               parametros={parametros}
            />
         </div>
         <DialogArchivoComponent
            file={file}
            handleCreate={handleCreate}
            nombreArchivo={nombreArchivo}
            prueba={prueba}
            setFile={setFile}
            setNombreArchivo={setNombreArchivo}
         /> */}
        <div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
            <Button label="Grabar" icon="fas fa-save" onClick={handleCreate}/>
            <Button label="Recuperar" icon="fas fa-undo" />
            {/* <Button label="Hematologo" icon="fas fa-user-md" />  */}
        </div>
      {/* </div> */}
        </>
        
    );
}
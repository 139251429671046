import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from 'primereact/dropdown';
import { useParams } from "react-router-dom";

import { Footer, Header } from "../../../../Pure/SeccionesNavegacion";

//Imágenes
import Icon2 from '../../../../../Images/IconAz.png';
import IconGerente from '../../../../../Images/iconGerenteGeneral.svg';


const AreasGenerales = ({ NombreGerente, setNombreGerente, personalGeneral, handleCrearGerente, NombreGerenteBD }) => {
  const { id } = useParams();
  const [GerenteGeneral, setGerenteGeneral] = useState({
    local_id: id,
    personal_id: 0
  })
  const [openColocarNombre, setOpenColocarNombre] = useState(false)
  const options =
    //Realiza un recorrido y muestra los datos del personal general
    personalGeneral?.map((item) => (
      { name: item.persona.apellido_paterno + " " + item.persona.apellido_materno + ", " + item.persona.nombres, code: item.id }
    ))

  const HandleChangeGerente = (e) => {
    //Actualiza estado del nombre del gerente
    setNombreGerente(e.value);
    //Actualiza el id del personal del gerente general
    setGerenteGeneral({ ...GerenteGeneral, personal_id: e.value.code })
  }

  const dialogHeader = (<Header icono={IconGerente} titulo={'Gerente general'} subtitulo={'Coloque al jefe encargado del local'} />)

  const dialogFooter = (
    <Footer onClickCancelar={() => { setOpenColocarNombre(false); }}
      onClickEnviar={() => {
        handleCrearGerente(GerenteGeneral);
        setOpenColocarNombre(false);
      }}
      label={'Guardar'} />
  );

  return (
    <>
      <div onClick={() => { setOpenColocarNombre(true) }} className="flex flex-row align-items-center gap-3 border-round-2xl border-2 border-gray-100 p-2 hover:border-cyan-300 cursor-pointer">
        <img src={Icon2} alt="" icon="text-cyan-300" className=" border-round-xl "
          style={{
            padding: "10px",
            backgroundColor: " #BBEBFF"
          }} />
        <div className="flex-1">
          <p className="text-cyan-300 font-bold text-sm">Gerente General</p>
          <div className="flex flex-row gap-2 justify-content-between">
            <div className='flex flex-row gap-2'>
              <i className="flex align-items-center pi pi-pencil text-cyan-300"></i>
              <p className="text-sm text-cyan-300">{NombreGerenteBD == null ? "Colocar nombre" : NombreGerenteBD}</p>
            </div>
            <div className=" flex flex-row gap-2">
              <i className="pi pi-plus text-400 text-cyan-300 font-semibold" />
              <i className="pi pi-ellipsis-v text-400 font-semibold" />
            </div>
          </div>
        </div>

      </div>
      <Dialog style={{ minWidth: '400px' }}
        header={dialogHeader}
        footer={dialogFooter}
        visible={openColocarNombre}
        onHide={() => {
          setOpenColocarNombre(false);
        }}>
        <div className="flex flex-column px-2 gap-2">
          <label className="font-bold" htmlFor="colocarNombre">Gerente general</label>
          <Dropdown
            value={NombreGerente}
            options={options}
            onChange={(e) => HandleChangeGerente(e)}//Actualiza el gerente general
            optionLabel="name"
            placeholder="Seleccionar Gerente General"
            filter
            className="w-full" />
        </div>
      </Dialog>
    </>
  );
}
export default AreasGenerales;
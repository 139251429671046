import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import { Dropdown } from 'primereact/dropdown';
//import { SettingsSuggestRounded, SingleBed } from "@mui/icons-material";
import { RadioButton } from "primereact/radiobutton";
import "../../../Style/MedicinaGeneral/EF_Detalles.css";
import { MGContext } from "../MGProvider";
import { RAContext } from "../../RutaAtencion/RAContext";
import { Dialog } from 'primereact/dialog';
import EnvioArchivoMedGeneral from "../../../Components/MedicinaGeneral/EnvioArchivoMedGeneral";

function DetallesExamenFisico() {
    const { examenFisicoContext, setExamenFisicoContext } = useContext(MGContext);

    const [Archivo, setArchivo] = useState(examenFisicoContext?.med_detalle?.archivo);
    const updateArchivo = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                archivo: nuevoValor
            },
        }));
    }

    const updatePiezaFaltante = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                piezas_faltantes: nuevoValor
            },
        }));
    }
    const updatePiezaMalEstado = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                piezas_mal_estado: nuevoValor
            },
        }));
    }
    const updateSintomasPrincipales = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                sintomas_principales: nuevoValor
            },
        }));
    }
    const updateTratamientoRecibido = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                tratamiento_recibido: nuevoValor
            },
        }));
    }



    const updateTiempoEnfemedad = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                tiempo_enfermedad: nuevoValor
            },
        }));
    }
    const updateFormaInicio = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                forma_inicio: nuevoValor
            },
        }));
    }
    const updateCurso = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                curso: nuevoValor
            },
        }));
    }
    const updateObservacion = (nuevoValor) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                observaciones: nuevoValor
            },
        }));
    }
    const updatePropiedad = (nuevoValor, prropiedad) => {
        setExamenFisicoContext((prev) => ({
            ...prev, med_detalle: {
                ...prev.med_detalle,
                [prropiedad]: nuevoValor
            },
        }));
    }
    const OhtenerObkjeto = (array, seteo, code) => {
        array.map((item) => {
            if (item.code == code) {
                seteo(item)
            }
        })
    }

    const Apet = [
        { name: 'Normal', code: 'N' },
        { name: 'Aumentado', code: 'A' },
        { name: 'Dismiuido', code: 'D' },
    ];


    const [apetito_code, setapetito_code] = useState(examenFisicoContext?.med_detalle?.apetito)
    const [apetito_obj, setapetito_obj] = useState()
    useEffect(() => {
        updatePropiedad(apetito_code, "apetito")
    }, [apetito_code])
    useEffect(() => {
        OhtenerObkjeto(Apet, setapetito_obj, apetito_code)
    }, [examenFisicoContext])


    const [sed_code, setsed_code] = useState(examenFisicoContext?.med_detalle?.sed)
    const [sed_obj, setsed_obj] = useState()
    useEffect(() => {
        updatePropiedad(sed_code, "sed")
    }, [sed_code])
    useEffect(() => {
        OhtenerObkjeto(Apet, setsed_obj, sed_code)
    }, [examenFisicoContext])


    const [orina_code, setorina_code] = useState(examenFisicoContext?.med_detalle?.orina)
    const [orina_obj, setorina_obj] = useState()
    useEffect(() => {
        updatePropiedad(orina_code, "orina")
    }, [orina_code])
    useEffect(() => {
        OhtenerObkjeto(Apet, setorina_obj, orina_code)
    }, [examenFisicoContext])



    const [sueño_code, setsueño_code] = useState(examenFisicoContext?.med_detalle?.sueño)
    const [sueño_obj, setsueño_obj] = useState()
    useEffect(() => {
        updatePropiedad(sueño_code, "sueño")
    }, [sueño_code])
    useEffect(() => {
        OhtenerObkjeto(Apet, setsueño_obj, sueño_code)
    }, [examenFisicoContext])





    return (
        <div className="flex flex-wrap w-full gap-5">
            <div className="flex flex-wrap gap-5 w-full Piezas">
                <div className="flex-1 flex gap-2 Pfaltantes">
                    <label className="PiezasF" htmlFor="piezasFaltantes">Piezas faltantes</label>
                    <VoiceButton
                        value={examenFisicoContext?.med_detalle?.piezas_faltantes}
                        onChange={updatePiezaFaltante}
                        placeholder="Comentar aquí"
                        disabled={false}
                    // disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>
                <div className="flex-1 flex gap-2 PM_estado">
                    <label className="PiezasME" htmlFor="piezasMalEstado" >Piezas en mal estado</label>
                    <VoiceButton
                        value={examenFisicoContext?.med_detalle?.piezas_mal_estado}
                        onChange={updatePiezaMalEstado}
                        placeholder="Comentar aquí"
                        disabled={false}
                    // disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>
            </div>

            <div className="flex flex-column w-full gap-5">
                <div className="flex-1 flex flex-column w-full gap-2">
                    <label htmlFor="sintomasPrincipales" >Síntomas principales</label>
                    <VoiceButton
                        value={examenFisicoContext?.med_detalle?.sintomas_principales}
                        onChange={updateSintomasPrincipales}
                        placeholder="Comentar aquí"
                        disabled={false}
                    // disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>
                <div className="flex-1 flex flex-column w-full gap-2">
                    <label htmlFor="tratamientoRecibido" >Tratamiento recibido</label>
                    <VoiceButton
                        value={examenFisicoContext?.med_detalle?.tratamiento_recibido}
                        onChange={updateTratamientoRecibido}
                        placeholder="Comentar aquí"
                        disabled={false}
                    // disabled={stateAtencion[pageViewContext].estado === 3}
                    />
                </div>
            </div>

            <div className="flex flex-wrap w-full gap-5">
                <div className="flex flex-wrap w-full gap-2 flex-column">
                    <div className="comentarios-examenF flex-1 flex w-full gap-2 ">
                        <label className="examenF-text" htmlFor="tiempoEnfermedad" style={{ display: 'flex', alignItems: 'center', }}>Tiempo de enfermedad</label>
                        <VoiceButton
                            value={examenFisicoContext?.med_detalle?.tiempo_enfermedad}
                            onChange={updateTiempoEnfemedad}
                            disabled={false}
                            placeholder="Comentar aquí"
                        />                    </div>
                    <div className="comentarios-examenF flex-1 flex w-full gap-2 ">
                        <label className="examenF-text" htmlFor="formaInicio" style={{ display: 'flex', alignItems: 'center', }}>Forma de inicio</label>
                        <VoiceButton
                            value={examenFisicoContext?.med_detalle?.forma_inicio}
                            onChange={updateFormaInicio}
                            placeholder="Comentar aquí"
                            disabled={false}
                        />                    </div>
                    <div className="comentarios-examenF flex-1 flex w-full gap-2 ">
                        <label className="examenF-text" htmlFor="curso" style={{ display: 'flex', alignItems: 'center', }}>Curso</label>
                        <VoiceButton
                            value={examenFisicoContext?.med_detalle?.curso}
                            onChange={updateCurso}
                            placeholder="Comentar aquí"
                            disabled={false}
                        />                    </div>
                </div>

                <div className="flex flex-column w-full gap-5 CONT-ASOS">
                    <div className="flex flex-wrap w-full gap-2 ASOS">
                        <div className="flex gap-2 APETITO">
                            <label className="Apetito" htmlFor="apetito" >Apetito</label>
                            <Dropdown value={apetito_obj} onChange={(e) => setapetito_code(e.value.code)} options={Apet}
                                optionLabel="name" placeholder="Normal" disabled={false} lassName="w-full md:w-14rem"
                            />
                        </div>
                        <div className="flex gap-2  SED">
                            <label className="Sed" htmlFor="sed" >Sed</label>
                            <Dropdown value={sed_obj} onChange={(e) => setsed_code(e.value.code)} options={Apet}
                                optionLabel="name" placeholder="Normal" disabled={false} lassName="w-full md:w-14rem"
                            />
                        </div>
                        <div className="flex gap-2 ORINA">
                            <label className="Orina" htmlFor="orina" >Orina</label>
                            <Dropdown value={orina_obj} onChange={(e) => setorina_code(e.value.code)} options={Apet}
                                optionLabel="name" placeholder="Normal" disabled={false} lassName="w-full md:w-14rem"
                            />
                        </div>
                        <div className="flex gap-2 SUENO">
                            <label htmlFor="sueno" className="Sueno" >Sueño</label>
                            <Dropdown value={sueño_obj} onChange={(e) => setsueño_code(e.value.code)} options={Apet}
                                optionLabel="name" placeholder="Normal" disabled={false} lassName="w-full md:w-14rem"
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-wrap w-full PerdidaP_Obesidad">
                    <div className="flex-1 gap-2 align-items-center w-full PerdidaPeso">
                        <p style={{ paddingRight: '20px' }}>Perdida de peso</p>
                        <div className="cont_PerdP ">
                            <div className="PerdidadP_si">
                                <RadioButton
                                    inputId="Psi"
                                    name="PerdidaPesoS"
                                    value="pesoS"
                                    onChange={(e) => updatePropiedad(e.value, "perdida_peso")}
                                    disabled={false}
                                    checked={examenFisicoContext.med_detalle.perdida_peso == 'pesoS'}
                                />
                                <label htmlFor="Psi" className="" style={{ paddingLeft: '7px' }}>Si</label>
                            </div>

                            <div className="PerdidadP_no">
                                <RadioButton
                                    inputId="Pno"
                                    name="PerdidaPesoN"
                                    value="pesoN"
                                    onChange={(e) => updatePropiedad(e.value, "perdida_peso")}
                                    disabled={false}
                                    checked={examenFisicoContext.med_detalle.perdida_peso == 'pesoN'}
                                />
                                <label htmlFor="Pno" className="" style={{ paddingLeft: '7px' }}>No</label>
                            </div>

                        </div>
                    </div>

                    <div className="flex-1 gap-2 align-items-center w-full Obesidad">
                        <p style={{ paddingRight: '20px' }}>Obesidad</p>
                        <div className="cont_Obe">
                            <div className="Obecidad_si">
                                <RadioButton inputId="Osi" name="ObsidadS"
                                    value="obesidadSi" onChange={(e) => updatePropiedad(e.value, "obesidad")}
                                    checked={examenFisicoContext?.med_detalle?.obesidad == 'obesidadSi'} disabled={false} />
                                <label htmlFor="Osi" className="" style={{ paddingLeft: '7px' }}>Si</label>
                            </div>

                            <div className="Obecidad_No">
                                <RadioButton inputId="Ono" name="ObesidadN"
                                    value="obesidadNo" onChange={(e) => updatePropiedad(e.value, "obesidad")}
                                    checked={examenFisicoContext?.med_detalle?.obesidad === 'obesidadNo'}
                                    disabled={false} />
                                <label htmlFor="Ono" className="" style={{ paddingLeft: '7px' }}>No</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-column w-full gap-5">
                <div className="flex flex-wrap w-full gap-3">
                    <label htmlFor="observaciones" >Observaciones</label>
                    <VoiceButton
                        value={examenFisicoContext?.med_detalle?.observaciones}
                        onChange={updateObservacion}
                        placeholder="Comentar Observaciones"
                        disabled={false}
                    />
                </div>
                <EnvioArchivoMedGeneral
                    FuncionSetEnvio={setArchivo}
                    FuncionUpData={updateArchivo}
                    ValorSet={Archivo}
                    DataContext={examenFisicoContext.med_detalle.archivo}
                />
            </div>
            {/* <Dialog
                visible={mostrarDialog}
                onHide={() => setMostrarDialog(false)}
                header="Vista Previa del PDF"
                modal
                style={{ width: '80vw', maxHeight: '80vh', height: "80vh" }}
            >
                <iframe
                    src={urlPDF}
                    width="100%"
                    height="100%"
                    title="Vista Previa del PDF"

                />
            </Dialog> */}
        </div>

    )
}

export default DetallesExamenFisico;
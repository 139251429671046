import React from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";

//Componentes generales
import AuthUser from "../../../../AuthUser";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

export default function ModalEliminar({
  visibleEliminar,
  showToast,
  setVisibleEliminar,
  setPersonal,
  getPersonal,
  api
}) {

  const dispatch = useDispatch();
  const { http } = AuthUser();

  //Oculta el dialog
  const HideDialogEliminar = () => {
    setVisibleEliminar(false);
  };

  //Integración principal
  const DeleteMiPersonal = async (id) => {
    //Inicia la carga
    dispatch(startLoading())
    try {
      //Llamada a la api
      const response = await http.delete(`${api}/${id}`)
      if (response?.data?.resp) {
        //Espera la ejecución de las funciones
        await Promise.all([
          HideDialogEliminar(),
          getPersonal()
        ]);
        //Muestra un mensaje de confirmación
        showToast("success", "Eliminado correctamente", "Se eliminó correctamente el personal", response?.data?.resp);
      } else if (response?.data?.error) {
        //Muestra un mensaje de error controlado
        showToast("error", "Error", response?.data?.error)
      }
    } catch (error) {
      //Muestra un mensaje de error no controlado
      showToast("error", "Fallo al Eliminar", "No se pudo eliminar correctamente",)
      console.error("Error al eliminar", error)
    }
    finally {
      dispatch(endLoading());
    }
  }

  const header = (
    <Header piIcon={'pi pi-trash'} titulo={'Eliminar personal'} />
  )

  const paqueteDelateDialogFooter = (id) => (
    <Footer label={"Eliminar"}
      onClickEnviar={() => {
        DeleteMiPersonal(id);
      }}
      onClickCancelar={() => {
        HideDialogEliminar();
      }} />
  );

  return (
    <div>
      <form
        id="personal-form-delete"
        className="personal-form-delete"
        noValidate
      >
        <Dialog
          resizable={false}
          visible={visibleEliminar}
          style={{ width: "450px", margin: "5%" }}
          header={header}
          footer={paqueteDelateDialogFooter(setPersonal.id)}
          onHide={() => {
            HideDialogEliminar();
          }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p style={{ fontSize: "20px" }} className=" m-0" >¿Está seguro que desea eliminar personal?</p>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
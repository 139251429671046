import React from 'react';
import { Button } from 'primereact/button';

import '../../Style/secciones-navegacion.css';

function Header({ icono, titulo, subtitulo, piIcon }) {
    return (
        <>
            <div className="flex flex-row gap-3 align-items-center">
                <div className="navegacion-icono">
                    {icono ? (<img src={icono} alt=""/>) : (<i className={`${piIcon} flex align-items-center text-3xl`}></i>)}
                </div>
                <div className='flex flex-column'>
                    <p className='header-titulo'>
                        {titulo}
                    </p>
                    <p className='header-subtitulo'>{subtitulo}</p>
                </div>
            </div>
        </>
    )
}

function Footer({ type, onClickCancelar, onClickEnviar, label, labelAdicional, onClickAdicional, iconoAdicional }) {
    return (
        <div className={`flex w-full gap-3 ${labelAdicional ? `justify-content-between` : `justify-content-end`}`}>
            {labelAdicional ? <Button label={labelAdicional} icon={iconoAdicional} className='btn-verde-general justify-content-between w-4 mt-2' onClick={onClickAdicional} /> : ''}
            <div className={`flex ${labelAdicional ? '' : 'w-full' }`}>
                <Button
                    type="button"
                    label="Cancelar"
                    className="delete w-full mt-2"

                    onClick={onClickCancelar}
                />
                <Button
                    type={type}
                    label={label}
                    className="p-button w-full mt-2"

                    style={{
                        color: "#5555D8",
                        backgroundColor: "#EBEBFF",
                        borderColor: "#5555D8",
                    }}
                    onClick={onClickEnviar}
                />
            </div>
        </div>
    )
}

export { Header, Footer };

import { useParams, useLocation } from "react-router-dom";
import CardAreas from '../../../../Components/RecursosHumanos/Local/LocalAreas/AreasComponentes/CardAreas'
import CardListasGeneralesC from '../../../../Components/RecursosHumanos/Local/LocalAreas/AreasComponentes/CardListasGeneralesC'
import TablaAreas from '../../../../Components/RecursosHumanos/Local/LocalAreas/AreasComponentes/TablaAreas'
import React, { useState, useEffect, useRef } from "react";
import AuthUser from "../../../../AuthUser";
import { Toast } from "primereact/toast"
import CardLocal from '../../../../Components/RecursosHumanos/Local/Components/CardLocal'

const PageAreaslocalClinica = () => {
  const { id } = useParams();
  const location = useLocation();

  const [areas, setAreas] = useState([]);
  const [personal, setPersonal] = useState([])
  const [personalGeneral, setPersonalGeneral] = useState([]);
  const [NombreGerente, setNombreGerente] = useState(null);
  const [NombreGerenteBD, setNombreGerenteBD] = useState(null);
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);
  const toast = useRef(null);
  const localitem = { nombre: 'Nombre del local' };
  const [locales, setLocales] = useState([])

  const { http } = AuthUser();



  const handlePersonalGeneral = () => {
    http
      .get("clinica/personal/get")
      .then((response) => {
        setPersonalGeneral(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
  }

  const handleCrearGerente = (data) => {
    http
      .post("recursoshumanos/clinica/local/jefe/asignar", data)
      .then((response) => {
        GetGerenteClinica()
        ShowToast(
          "success",
          "Gerente asignado",
          "Gerente asignado correctamente"
        )
      })
      .catch((error) => {
        console.error("Error al asignar gerente: ", error)
      }
      );
  };
  const GetGerenteClinica = () => {
    http
      .get(`clinica/recursoshumanos/local/jefe/mostrar/${id}`)
      .then((response) => {
        if (response?.data?.data) {
          setLocales(response.data.data); // Corregido el typo en "response"
          let datos = null;
          if (response?.data?.data?.clinica_personal != null) {
            datos =
              response?.data?.data?.clinica_personal?.persona?.apellido_paterno +
              " " +
              response?.data?.data?.clinica_personal?.persona?.apellido_materno +
              ", " +
              response?.data?.data?.clinica_personal?.persona?.nombres; // Corregido el acceso a "clinica_personal"
          }
          setNombreGerenteBD(datos);// Cambiado el acceso a "NombreGerenteBD" por "datos"
        }
      })
      .catch((error) => { // Corregido el paréntesis en "(error)"
        console.error(error);
      });
  };


  const obtenerAreasgenerales = () => {
    http.get(`/clinica/local/${id}/areas/get`).then((response) => {
      let data = changeDataToTreeTable(response.data.data);
      setAreas(data);
    });
  };

  const ShowToast = (type, title, detail) => {
    toast.current.show({
      severity: type,
      summary: title,
      detail: detail
    })
  }

  const changeDataToTreeTable = (arreglo) => {
    let data = arreglo.map((area) => {
      let aux = [];
      if (area.children && area.children.length > 0) {
        aux = changeDataToTreeTable(area.children);
      }
      return {
        key: area.id,
        data: {
          id: area.id,
          nombre: area.nombre,
        },
        children: aux,
      };
    });

    return data;
  };

  const handleagregarAreasgenerales = (a) => {
    http
      .post(`clinica/areas/create/${id}`, a)
      .then((response) => {
        obtenerAreasgenerales();
        ShowToast(
          "success",
          "Área creada",
          "Área creada correctamente"
        )
      })
      .catch((error) => {
        console.error(error);
      });

  };

  const handleGetPersonal = (idArea) => {
    http
      .get(`clinica/personal/area/get/${idArea}`)
      .then((response) => {
        setPersonal(response?.data?.data)
        let listTarget = response?.data?.data.filter((item) => item.clinica_area_id === idArea)
        let listSource = response?.data?.data.filter((item) => item.clinica_area_id === null)
        setSource(listSource);
        setTarget(listTarget)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const agregarPersonal = (data) => {
    http
      .post(`clinica/personal/asignararea`, data)
      .then((response) => {
        
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const EditarAreaClinica = (idAreaClinica, areaClinica) => {
    http
      .put(`clinica/areas/update/${idAreaClinica}`, areaClinica)
      .then(() => {
        obtenerAreasgenerales()
        ShowToast(
          "success",
          "Área actualizada",
          "Área actualizada correctamente"
        )
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleDeleteClinica = (idArea) => {
    http
      .delete(`clinica/areas/delete/${idArea}`)
      .then((response) => {
        obtenerAreasgenerales();
        ShowToast(
          "success",
          "Eliminado correctamente",
          "Área eliminada correctamente"
        )
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    obtenerAreasgenerales();
    handlePersonalGeneral();
    GetGerenteClinica();
  }, []);

  useEffect(() => {
    obtenerAreasgenerales();
    handlePersonalGeneral();
    GetGerenteClinica();
  }, []);

  return (

    <div className="flex flex-wrap w-full h-full px-2">
      <div className="flex flex-column h-6rem w-full">
        <h1 className="tit w-full">Mis Áreas</h1>
        <div className="flex inline-flex justify-content-between">
          <p className="text-indigo-200 text-sm w-22rem con" style={{ minWidth: '100%' }}>
            A continuacion se visualiza las áreas que usted a definido en su
            empresa para el local.
          </p>
        </div>
      </div>
      <div className="flex flex-wrap w-full " style={{ minHeight: "67.5vh" }}>
        <div className="flex  flex-column gap-2 w-3">
          <div className="" style={{ minHeight: "17vmin" }}>
            <CardAreas locales={locales}></CardAreas>
          </div>

          <div className="" style={{ minHeight: "57vh" }}>
            <CardListasGeneralesC
              personalGeneral={personalGeneral}
              NombreGerente={NombreGerente}
              setNombreGerente={setNombreGerente}
              handleCrearGerente={handleCrearGerente}
              NombreGerenteBD={NombreGerenteBD}
              GetGerenteClinica={GetGerenteClinica} />
          </div>
        </div>

        <div className="flex w-9 pl-3 pr-2">
          { }
          <TablaAreas
            areas={areas}
            handleagregarAreasgenerales={handleagregarAreasgenerales}
            act={obtenerAreasgenerales}
            handleGetPersonal={handleGetPersonal}
            source={source}
            target={target}
            setSource={setSource}
            setTarget={setTarget}
            agregarPersonal={agregarPersonal}
            handleEditArea={EditarAreaClinica}
            handleDelete={handleDeleteClinica}
          ></TablaAreas>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
export default PageAreaslocalClinica;
import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Skeleton } from "primereact/skeleton";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { useDispatch } from "react-redux";

import AuthUser from "../../../../AuthUser";
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";

import "../../../../Style/stylePerfil.css";
import "../style/RetraccionesVer.css"

export default function BilleteraElectronicaComponent({
   entidadPagoElectronico,
   tipo,
   getEntidadesElectronicas,
   showToast
}) {

   const dispatch = useDispatch();
   const { http } = AuthUser();
   const toast01 = useRef(null);
   const [visible2, setVisible2] = useState(false);
   const [btndelete, setBtndelete] = useState(false);
   const [entidadPagoUpdate, setEntidadPagoUpdate] = useState({
      id: 0,
      entidad_electronica_id: null,
      numero_cuenta: "",
      entidad_electronica: null,
   });

   const handleChangeEntidadElectronica = (e) => {
      setEntidadPagoUpdate({
         ...entidadPagoUpdate,
         entidad_electronica: e.target.value,
         entidad_electronica_id: e.target.value,
      });
   };
   const [updated, setUpdated] = useState(false);

   useEffect(() => {
      setEntidadPagoUpdate(entidadPagoElectronico);
      setUpdated(false);
   }, [entidadPagoElectronico]);

   const numeroCuentaRef = useRef("");

   const saveCuentaElectronica = async () => {
      let cuentaelectronica = {
         entidad_electronica_id: entidadPagoUpdate.entidad_electronica_id,
         numero_cuenta: numeroCuentaRef.current.value,
      };
      dispatch(startLoading());
      try {
         const response = await http.put(`entidad_electronica_pago/update/${entidadPagoElectronico.id}`, cuentaelectronica)
         if (response?.data?.resp) {
            await Promise.all([
               setVisible2(false),
               setUpdated(true),
               getEntidadesElectronicas()
            ])
            showToast(
               'success',
               'Datos actualizados',
               'Billetera electrónica guardada exitosamente'
            )
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         setVisible2(false);
         showToast(
            'error',
            'Datos no actualizados',
            'Contactar a soporte técnico'
         )
         console.error(error);
      } finally {
         dispatch(endLoading());
      }

      setVisible2(false);
   };

   const handleEliminarEntidad = async () => {
      dispatch(startLoading());
      try {
         const response = await http.delete(`entidad_electronica_pago/delete/${entidadPagoElectronico.id}`)
         if (response?.data?.resp) {
            await Promise.all([
               setBtndelete(false),
               setUpdated(true),
               getEntidadesElectronicas(),
            ])
            showToast(
               "success",
               "Eliminado correctamente",
               "Billetera electrónica eliminada exitosamente"
            );
         } else if (response.data.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         setBtndelete(false);
         showToast('error', 'Error', "Contacte a soporte técnico.");
         console.error(error);

      } finally {
         dispatch(endLoading());
      }
   };

   // Skeleton
   const [loading, setLoading] = useState(true);
   useEffect(() => {
      setTimeout(() => {
         setLoading(false);
      }, 2200);
   }, []);
   const loader = () => {
      return (
         <div>
            <div>
               <Skeleton
                  width="281px"
                  height="226px"
                  borderRadius="16px"
               ></Skeleton>
            </div>
         </div>
      );
   };

   const [VerifiNumCelular, setVerifiNumCelular] = useState(false);
   const Verificar = () => {
      var verifi = true;
      var inputCelular = document.getElementById('nameNumeroCelSS').value;
      if (inputCelular === "" || inputCelular == null) {
         setVerifiNumCelular(true);
         verifi = false;
      }

      return verifi;
   }
   useEffect(() => {
      setVerifiNumCelular(false);
   }, [visible2])

   const EnvioUpdate = () => {
      var verrifi = Verificar();
      if (verrifi) {
         saveCuentaElectronica();
      }
   }

   if (loading) {
      return loader();
   } else {
      return (
         <div className="wrapperdbb p-1 px-3 m-3">
            <Toast ref={toast01} />
            <div className="flex w-full flex-column justify-content-center align-items-center">
               <div className="flex w-full flex-wrap justify-content-between align-items-center gap-2 p-1">
                  <div className="flex flex-column flex-1 justify-content-center align-items-center " style={{ height: '120px', width: '120px' }}>
                     <img
                        src={entidadPagoElectronico?.entidad_electronica?.logo}
                        alt="Entidad electronic"
                        style={{
                           objectFit: 'contain',
                           // padding: "20px",
                           height: "100%",
                           width: "100%"
                           // marginLeft: "-14px",
                           // marginTop: "-10px",
                        }}
                     />
                  </div>
                  <div className="flex flex-row gap-2">
                     <Button
                        icon="pi pi-trash"
                        onClick={() => setBtndelete(true)}
                        severity="danger"
                        outlined="true"
                        className="p-button-danger p-button-text "
                        style={{
                           width: "33px",
                           height: "33px",
                           // marginTop: "10px",
                           // marginRight: "-5px",
                           backgroundColor: "#FFECEC",
                        }}
                     />
                     <Button
                        icon="pi pi-pencil"
                        onClick={() => setVisible2(true)}
                        className="p-button-success p-button-text "
                        style={{
                           width: "33px",
                           height: "33px",
                           // marginTop: "10px",
                           // marginRight: "-5px",
                           backgroundColor: "#BFF1DF",
                        }}
                     />
                  </div>

               </div>
               <div>
                  <div>
                     <div
                        className="linea"
                        style={{ borderBottom: "1px solid transparent" }}
                     >
                        <h4 className="tituloCuentas text-base">
                           Número de celular
                           <br />
                        </h4>
                        <h6 className="numero text-sm">
                           {entidadPagoElectronico?.numero_cuenta}
                        </h6>
                     </div>
                  </div>
               </div>
            </div>


            <div className="card flex justify-content-center">
               <Dialog
                  visible={btndelete}
                  onHide={() => setBtndelete(false)}
                  className="card-buton"
                  footer={<Footer type={"button"} onClickCancelar={() => setBtndelete(false)} onClickEnviar={() => handleEliminarEntidad()} label={"Eliminar"} />}
                  header={<Header piIcon={'pi pi-trash'} titulo={"Eliminar billetera electrónica"} />}
                  style={{ width: "30vw", minWidth: "500px", maxWidth: "446px", }}
               >
                  <div className="h-full card mb-0">
                     <div className="flex justify-content-between h-full flex-column gap-3">
                        <div className="flex ">
                           <label htmlFor="num" className="flex justify-content-center" ></label>
                        </div>
                     </div>
                  </div>
               </Dialog>
            </div>
            <div className="card flex justify-content-center">
               <Dialog
                  onHide={() => setVisible2(false)}
                  visible={visible2}
                  className="card-buton"
                  header={<Header piIcon={'pi pi-pencil'} titulo={"Editar datos de la billetera"} />}
                  footer={<Footer type={"button"} onClickCancelar={() => setVisible2(false)} onClickEnviar={() => EnvioUpdate()} label={"Guardar"} />}
                  style={{ width: "25vw", minWidth: "500px", maxWidth: "446px" }}
               >
                  <div className="card">
                     <div className="flex flex-column gap-3 mb-4">
                        <div className="flex-auto">
                           <label
                              htmlFor="num"
                              className="font-bold block mb-2"
                           >
                              Entidad Bancaria
                           </label>
                           <Dropdown
                              value={entidadPagoUpdate?.entidad_electronica_id}
                              onChange={(e) =>
                                 handleChangeEntidadElectronica(e)
                              }
                              options={tipo}
                              optionValue="id"
                              optionLabel="nombre"
                              placeholder="Seleccionar tipo de Tarjeta"
                              className="w-full"
                           />
                        </div>
                        <div className="flex flex-column relative gap-2">
                           <label htmlFor="integer" className="font-bold block">
                              Número de Celular
                           </label>
                           <InputText
                              id="nameNumeroCelSS"
                              style={{ width: "100%", height: "50px", border: "1px solid #ccc", padding: "5px" }}
                              ref={numeroCuentaRef}
                              defaultValue={
                                 entidadPagoElectronico.numero_cuenta
                              }
                              onChange={() => { setVerifiNumCelular(false) }}
                              keyfilter="num"
                              className={`w-full text-area InputRetraccionNumCuAP ${VerifiNumCelular ? 'Select' : ''}`}
                           />
                           <div className={`InputRetraccionNumCuAPDiv ${VerifiNumCelular ? 'Select' : ''}`}></div>
                           <p className={`InputRetraccionNumCuAPTextError ${VerifiNumCelular ? 'Select' : ''}`}>Necesita ingresar un número de celular</p>
                        </div>
                     </div>
                  </div>
               </Dialog>
            </div>

         </div>
      );
   }
}
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import TextTrimmer from "../../TextTrimmer/TextTrimmer";
import "../../../../Style/Empresas/Empresas.css";
import { useNavigate } from "react-router-dom";
import AuthUser from "../../../../AuthUser";
import logoDefault from "../../../../Images/logos/empresa-default.png"

export default function Empresas({ data, pageSize, btnEdit, btnDelete }) {
   /* #region PAGINACION */
   let navigate = useNavigate();

   const [currentPage, setCurrentPage] = useState(1);

   const lastIndex = currentPage * pageSize;
   const firstIndex = lastIndex - pageSize;
   const currentData = data.slice(firstIndex, lastIndex);

   const nextPage = () => {
      if (currentPage < Math.ceil(data.length / pageSize)) {
         setCurrentPage(currentPage + 1);
      }
   };

   const prevPage = () => {
      if (currentPage > 1) {
         setCurrentPage(currentPage - 1);
      }
   };
   /* #endregion */

   const [clinicas, setClinicas] = useState([]);
   const [dataIds, setDataIds] = useState([]);

   const { http } = AuthUser();

   const getClinicaId = () => {
      http
         .get("/bregma/contratos/get")
         .then((response) => {
            if (response.data && response.data.data) {
               const clinicaIds = response.data.data.map(
                  (item) => item.clinica_id
               );
               setClinicas(clinicaIds);
            }
         })
         .catch((error) => {
            console.error("Error al obtener contratos", error);
         });
   };

   const mapData = () =>{
      const ids = data.map(obj => obj.empresa_id);
      setDataIds(ids)
   }
   

   useEffect(() => {
      getClinicaId();
      mapData();
   }, []);

   return (
      <div className="flex flex-column gap-4 mb-2">
         <div className="empresas_grid-prueba">
            {currentData.map((e, index) => {
               const clinicaId = clinicas[index];
               const dataId = dataIds[index]
               return (
                  <div
                     key={index}
                     className="h-full flex flex-column gap-2 justify-content-between bg-white border-round-3xl shadow-2"
                     style={{ padding: "21px" }}

                  >
                     <div className="flex gap-2">
                        <img
                           width="46px"
                           height="46px"
                           className="border-circle h-full"
                           src={e.logo?e.logo:logoDefault}
                           alt=""
                        />

                        <div className="flex-1 flex flex-column">
                           <TextTrimmer
                              className="text-indigo-500 font-bold"
                              text={e.empresa}
                              maxLength={15}
                           />
                           <TextTrimmer
                              className="text-indigo-500"
                              text={e.nombre}
                              maxLength={15}
                           />
                        </div>

                        <>
                           {e.estado === 1 ? (
                              <i
                                 className="pi pi-check bg-green-300 border-1 border-green-500 border-green-500 flex justify-content-center align-items-center border-circle text-white"
                                 style={{
                                    height: "1.2rem",
                                    width: "1.2rem",
                                    fontSize: ".5rem",
                                 }}
                              ></i>
                           ) : e.estado === 2 ? (
                              <span
                                 className="bg-yellow-300 border-1 border-yellow-500 flex justify-content-center align-items-center border-circle text-white"
                                 style={{
                                    height: "1.2rem",
                                    width: "1.2rem",
                                    fontSize: ".5rem",
                                 }}
                              >
                                 !
                              </span>
                           ) : (
                              <i
                                 className="pi pi-times bg-red-300 border-1 border-red-500 flex justify-content-center align-items-center border-circle text-white"
                                 style={{
                                    height: "1.2rem",
                                    width: "1.2rem",
                                    fontSize: ".5rem",
                                 }}
                              />
                           )}
                        </>
                     </div>

                     {/* <div className="flex-1 flex flex-column justify-content-center gap-2 w-9">
                        <div className="flex align-items-center gap-3">
                           
                           <p className="flex p-1 border-round-xl flex-1 align-items-center justify-content-center" style={{ fontSize:"13px", backgroundColor: "#BFF1DF", color: "#00A15C" }}>
                              {e.sevicio_1}
                           </p>
                        
                           <p className="flex bg-gray-200 p-1 border-round-xl flex-1 align-items-center justify-content-center" style={{ fontSize:"13px"}}>
                              {e.sevicio_2}
                           </p>
                        </div>
                     </div>

                     <div className="flex gap-3 justify-content-between" style={{ paddingBottom: "calc(12px - 0.5rem)"}}>
                        <div className="flex flex-column gap-1 justify-content-start text-left align-items-start w-6">
                              <div className="flex gap-1">
                                 <i className="pi pi-calendar" style={{ color: "#7B8794" }}/>
                                 <p style={{ color: "#7B8794" }}>15.09.22</p>
                              </div>
                              <p className="white-space-nowrap" style={{ color: "#7B8794" }}>
                                 Fecha de Creación
                              </p>
                              
                        </div>

                        <div className="flex flex-column gap-1 justify-content-end text-right align-items-end w-6">
                              <div className="flex gap-1">
                                 <i className="pi pi-calendar" style={{ color: "#7B8794" }} />
                                 <p style={{ color: "#7B8794" }}>15.09.22</p>
                              </div>
                              <p className="white-space-nowrap" style={{ color: "#7B8794" }}>
                                 Última actualización
                              </p>
                              
                        </div>
                     </div> */}
                     

                     {/* <span className="border-top-1" style={{ borderColor: "#E9E9E9" }}></span> */}

                     <div className="flex gap-2 justify-content-between">
                        <div
                           style={{ display: "flex", justifyContent: "flex-end", paddingTop: "calc(15px - 0.5rem)"}}
                        >
                           <Button
                              className="pi pi-plus flex gap-3 font-empresa"
                              label="Proforma"
                              style={{
                                 fontSize: "15px",
                                 color: "#8C63E3",
                                 backgroundColor : "#F3EDFF",
                                 borderColor: "#F3EDFF"
                              }}
                              onClick={() => {
                                 if (!clinicaId || clinicaId === '') {
                                    navigate(`/ventas/gestiondeventas/empresa/${dataId}/credenciales`);
                                 } else {
                                    navigate(`/ventas/gestiondeventas/empresa/${clinicaId}/credenciales`);
                                 }
                              }}
                           />
                        </div>

                        <div className="flex gap-2" style={{ paddingTop: "calc(15px - 0.5rem)" }}>
                           {btnDelete(e) || ""}
                           {btnEdit(e) || ""}
                        </div>
                        



                     </div>
                  </div>
               );
            })}
         </div>
         <span className="border-top-3 border-gray-300 w-9 m-auto"></span>
         {data.length > 0 && (
            <div className="flex justify-content-end gap-3">
               <Button
                  className={`bg-gray-300 border-gray-300 ${
                     currentPage === 1 ? "hidden" : ""
                  }`}
                  onClick={() => prevPage()}
                  icon="pi pi-chevron-left text-gray-500"
               />
               <Button
                  className={`bg-gray-300 border-gray-300 ${
                     currentPage === Math.ceil(data.length / pageSize)
                        ? "hidden"
                        : ""
                  }`}
                  onClick={() => nextPage()}
                  icon="pi pi-chevron-right text-gray-500"
               />
            </div>
         )}
      </div>
   );
}

import React, { useState, useEffect, useContext, useRef } from "react";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate, useParams } from "react-router-dom";
import DialogIngreso from "../Dialogs/DialogEntrada";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import MultiLevelTreeSelectWithAPIClinica from "../../../../../Components/Clinica/ProductoServicio/Servicios/components/SelectTipoServicioClinica";
import { Toast } from 'primereact/toast';
import { ServicioContext } from "../../../../../Components/Clinica/ProductoServicio/Providers/ServicioProvider";

import { transformData } from "../../../../../Services/ProductosServicios/servicios";
import AuthUser from "../../../../../AuthUser";
import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";



export default function PageEditarServiciosCampana() {
   const dispatch = useDispatch();

   const [paquete, setPaquetes] = useState(null)
   const [habilitado, sethabilitado] = useState(true)

   const { id } = useParams()
   useEffect(() => {
      getPaquetes();
   }, []);

   const getPaquetes = async() => {

      dispatch(startLoading());

      try {
         const response = await http.get('clinica/paquete/particular/get');

         if (response?.data?.data) {
            setPaquetes(response.data.data.filter(paquete => {

               return paquete.paquete.id == id
            })[0]);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   }
   const [tipo, setTipo] = useState(0)
   const [marcados, setMarcados] = useState([{ "id": 1, content: {} }])
   const toast = useRef(null);
   const navigate = useNavigate();
   const { http } = AuthUser()
   const [servicios, setServicios] = useState([])
   const { servicio, setServicio, rowData, setRowData } = useContext(ServicioContext)
   const [showInput, setShowInput] = useState(false);
   const [priceReference, setPriceReference] = useState(0)


   const SelectTipóOnly = paquete && paquete.paquete ? paquete.paquete.clinica_servicio_id : null;

   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   useEffect(() => {
      setSelectedTipoServicio(SelectTipóOnly);
   }, [SelectTipóOnly]);

   const [nombrePaquete, setNombrePaquete] = useState("")
   const [nombreValido, setNombreValido] = useState("")
   const [camposValidos, setCamposValidos] = useState("")


   const [IdSeleccionado, setIdSeleccionado] = useState(null)
   const [PerfilesCreados, setPerfile4sCreados] = useState([
      {
         id: 1,
         NombrePerfil: 'Tipo Perfil 1',
         PrecioReferencial: 0,
         ItemsSeleccionados: [],
         ItemsSeleccionadosDetalles: []
      }
   ]);
   const [AbrirDialog, setAbrirDialog] = useState(false)
   const agregarNuevaSeccion = () => {
      const ultimoId = PerfilesCreados[PerfilesCreados.length - 1].id;

      const nuevaSeccion = {
         id: ultimoId + 1,
         NombrePerfil: `Tipo Perfil ${ultimoId + 1}`,
         PrecioReferencial: 0,
         ItemsSeleccionados: []
      };

      setPerfile4sCreados([...PerfilesCreados, nuevaSeccion]);
   };

   const actualizarPrecioReferencial = (perfilId, nuevoPrecio, setPerfilesCreados) => {
      setPerfilesCreados(prevPerfiles => {
         return prevPerfiles.map(perfil => {
            if (perfil.id === perfilId) {
               return {
                  ...perfil,
                  PrecioReferencial: nuevoPrecio
               };
            }
            return perfil;
         });
      });
   };
   const actualizarItemnsSelect = (perfilId, nuevoValor, setPerfilesCreados) => {
      setPerfilesCreados(prevPerfiles => {
         return prevPerfiles.map(perfil => {
            if (perfil.id === perfilId) {
               return {
                  ...perfil,
                  ItemsSeleccionados: nuevoValor
               };
            }
            return perfil;
         });
      });
   };
   const [verdad, setverdad] = useState(true)
   useEffect(() => {
      if (verdad) {
         if (paquete && paquete.perfiles) {
            setTimeout(() => {
               for (let i = 0; i < PerfilesCreados.length; i++) {
                  actualizarPrecioReferencial(i + 1, retornarTotal(paquete.perfiles[i]), setPerfile4sCreados);
                  actualizarItemnsSelect(i + 1, RetornarSelect(paquete.perfiles[i]), setPerfile4sCreados)
               }
               setverdad(false)
            }, 500); // 10000 milisegundos = 10 segundos
         }
      } else {
         sethabilitado(false);
      }
   }, [paquete, setPerfile4sCreados, PerfilesCreados]);


   const RetornarSelect = (array) => {
      let elementos = obtenerElementos(array);
      let elementosFiltrados = buscarElementosConChildrenVacios(elementos);
      let ItemsSeleccionados = compararArrays(ArraySimplificado, elementosFiltrados);
      return ItemsSeleccionados;
   }


   const seleccionarIngreso = (rowData) => {
      return (
         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button
               disabled={habilitado}
               style={{ backgroundColor: Object.keys(marcados[rowData.id - 1 >= 0 ? rowData.id - 1 : 0]["content"]).length == 0 ? "white" : "rgb(235,235,255)" }}
               className="add__container_main_content_body_table_td_btn_service"
               onClick={() => {
                  setAbrirDialog(true);
                  setIdSeleccionado(rowData.id);
               }}
            >
               Seleccionar
            </button>
         </div>
      );
   }
   const NombreServicioOnly = paquete && paquete.paquete ? paquete.paquete.nombre : null;

   const [NombreServicio, setNombreServicio] = useState("");
   useEffect(() => {
      setNombreServicio(NombreServicioOnly);
   }, [NombreServicioOnly]);


   function obtenerIndices(array) {
      const exclusiones = ["areas_medicas", "examenes"]; // Claves a excluir
      const indices = [];
      for (const key in array) {
         if (Object.hasOwnProperty.call(array, key) && !exclusiones.includes(key)) {
            indices.push(key);
         }
      }
      return indices;
   }
   const DataEnviar = ({
      "nombre": NombreServicio,
      "clinica_servicio_id": selectedTipoServicio,
      "perfiles": PerfilesCreados.map(profile => {
         let ItemSelectKey = obtenerIndices(profile.ItemsSeleccionados);
         return {
            "nombre": profile.NombrePerfil,
            "areas_medicas": ItemSelectKey.filter(item => item.startsWith('1')).map(item => (
               {
                  "id": item.slice(2),
                  "parcialmente": 1
               }
            )),
            "capacitaciones": [],
            "examenes": ItemSelectKey.filter(item => item.startsWith('3')).map(item => (
               {
                  "id": item.slice(2),
                  "parcialmente": 1
               }
            ))
         };
      })
   });

   const ArraySimplificado = obtenerElementosConChildrenVacios(servicios);
   const totalValores = ArraySimplificado.length;

   let DataAActualizar;
   let nextId = 1; // Declarar nextId fuera del map

   try {
      DataAActualizar = paquete.perfiles.map(profile => {

         const currentId = nextId; // Almacenar el valor actual de nextId
         nextId++; // Incrementar nextId para el próximo elemento
         return {
            id: currentId,
            NombrePerfil: profile.perfil.nombre,
            PrecioReferencial: 0,
            ItemsSeleccionados: "",
            ItemsSeleccionadosDetalles: []
         };
      });
   } catch (error) {
      console.error("An error occurred while processing the data:", error);
   }


   const retornarTotal = (array) => {
      let vartotal = 0;
      for (let i = 0; i < array.areas_medicas.length; i++) {
         vartotal += array.areas_medicas[i].precio_referencial_mensual_venta
         if (array.areas_medicas[i].children != []) {
            for (let e = 0; e < array.areas_medicas[i].children.length; e++) {
               vartotal += array.areas_medicas[i].children[e].precio_referencial_mensual_venta
               if (array.areas_medicas[i].children[e].children != []) {
                  for (let a = 0; a < array.areas_medicas[i].children[e].children.length; a++) {
                     vartotal += array.areas_medicas[i].children[e].children[a].precio_referencial_mensual_venta
                  }
               }
            }
         }
      }
      for (let i = 0; i < array.examenes.length; i++) {
         vartotal += array.examenes[i].precio_referencial_mensual_venta
         if (array.examenes[i].children != []) {
            for (let e = 0; e < array.examenes[i].children.length; e++) {
               vartotal += array.examenes[i].children[e].precio_referencial_mensual_venta
               if (array.examenes[i].children[e].children != []) {
                  for (let a = 0; a < array.examenes[i].children[e].children.length; a++) {
                     vartotal += array.examenes[i].children[e].children[a].precio_referencial_mensual_venta
                  }
               }
            }
         }
      }
      return vartotal
   }

   const [dataApplied, setDataApplied] = useState(false);

   useEffect(() => {
      if (DataAActualizar && !dataApplied) {
         const newPerfiles = DataAActualizar;
         setPerfile4sCreados(newPerfiles);
         setDataApplied(true);
      }
   }, [DataAActualizar, dataApplied, PerfilesCreados]);


   function obtenerElementosConChildrenVacios(array) {
      const elementosConChildrenVacios = [];

      function buscarElementosConChildrenVacios(arr) {
         for (let i = 0; i < arr.length; i++) {
            const elemento = arr[i];
            if (elemento.children && Array.isArray(elemento.children) && elemento.children.length === 0) {
               elementosConChildrenVacios.push(elemento);
            } else if (elemento.children && Array.isArray(elemento.children) && elemento.children.length > 0) {
               buscarElementosConChildrenVacios(elemento.children);
            }
         }
      }

      buscarElementosConChildrenVacios(array);

      return elementosConChildrenVacios;
   }
   function obtenerElementos(item, prefix = '') {
      if (!item) {
         return [];
      }

      const areasMedicas = item.areas_medicas || [];
      const examenes = item.examenes || [];

      const elementosConPrefijo = [
         ...areasMedicas.map(elemento => ({ ...elemento, id: `${prefix}1-${elemento.id}` })),
         ...examenes.map(elemento => ({ ...elemento, id: `${prefix}3-${elemento.id}` }))
      ];

      return elementosConPrefijo;
   }
   function buscarElementosConChildrenVacios(arr, parentId = null) {
      const elementosConChildrenVacios = [];
      for (let i = 0; i < arr.length; i++) {
         const elemento = arr[i];
         if (elemento.children && Array.isArray(elemento.children)) {
            if (elemento.children.length === 0) {
               const idConPadre = parentId ? `${parentId}.${elemento.id}` : elemento.id;
               elementosConChildrenVacios.push(idConPadre);
            } else {
               const hijosConIds = buscarElementosConChildrenVacios(elemento.children, parentId ? `${parentId}.${elemento.id}` : elemento.id);
               elementosConChildrenVacios.push(...hijosConIds);
            }
         }
      }
      if (parentId) {
         elementosConChildrenVacios.push(parentId);
      }
      return elementosConChildrenVacios;
   }
   function compararArrays(arr1, arr2) {
      const resultado = {};

      // if (arr1.length > 98) {
      arr2.forEach((item) => {
         const foundExactMatch = arr1.some(obj => obj.key === item);

         if (foundExactMatch) {
            // Si hay coincidencia exacta, establece los valores correspondientes
            resultado[item] = { checked: true, partialChecked: false };
         } else {
            resultado[item] = { checked: false, partialChecked: true };
         }

         if (item.startsWith('1')) {
            resultado['areas_medicas'] = { checked: false, partialChecked: true };
         }
         if (item.startsWith('3')) {
            resultado['examenes'] = { checked: false, partialChecked: true };
         }
      });

      return resultado;
   }

   function sumarPrecioReferencial(array1, selectedNodeKeys) {
      let total = 0;

      // Iterar sobre las claves del objeto selectedNodeKeys
      for (let key in selectedNodeKeys) {
         // Verificar si la clave existe en el array1
         const matchingItem = array1.find(item => item && item.key === key);
         if (matchingItem) {
            total += parseFloat(matchingItem.data.precio_referencial_mensual_venta);
         }
      }

      return total;
   }

   const tipoServicioId = "";
   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
      setFaltaTipoServicio(false);
   };

   const [perfiles, setPerfiles] = useState([{
      id: 1,
      nombre: "Nombre(click para cambiar)",
      ingreso: {},
      selectedIngreso: [],
   }
   ])

   // Transformar data

   const getServicios = async () => {

      dispatch(startLoading());

      try {
         const response = await http.get('clinica/operaciones/servicio/get');

         if (response?.data?.resp || response?.data) {
            setServicios(transformData([response.data.resp ? response.data.resp : response.data]))
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Datos no actualizados', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   }

   const editarObjetoPorId = (id, nuevoMarcado) => {
      setMarcados((prevArrayObjetos) =>
         prevArrayObjetos.map((objeto) =>
            objeto.id === id
               ? {
                  ...objeto,
                  content: nuevoMarcado ? { ...nuevoMarcado } : {},
               }
               : objeto
         )
      );
   };
   const ordenar_datos = (a, b) => {
      if (a.id < b.id) {
         return -1;
      }
      if (a.id > b.id) {
         return 1;
      }
      return 0;
   }

   const seleccionarServicios = (e, setRowData, tipo, id) => {
      editarObjetoPorId(id, e)
      let totalRefencial = 0;
      var keyNames = Object.keys(e);
      let areas_medicas = keyNames
         .filter((data) => data.charAt(0) === "1")
         .map((item) => {
            return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
         });
      let capacitaciones = keyNames
         .filter((data) => data.charAt(0) === "2")
         .map((item) => {
            return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
         });
      let examenes = keyNames
         .filter((data) => data.charAt(0) === "3")
         .map((item) => {
            return { id: (item.slice(0, item.length)).replace("-", "."), parcialmente: e[item]?.partialChecked ? 1 : 0, checked: e[item]?.checked ? 1 : 0 };
         });

      examenes = examenes.sort(ordenar_datos)
      capacitaciones = capacitaciones.sort(ordenar_datos)
      areas_medicas = areas_medicas.sort(ordenar_datos)
      const updatedPerfil = {
         ...rowData,
         clinica_servicio_id: selectedTipoServicio,
      };
      const updatedPerfiles = perfiles.map(perfil => (perfil.id === rowData.id ? updatedPerfil : perfil));
      setRowData(updatedPerfil);
      setPerfiles(updatedPerfiles);
      setServicio({ ...servicio, clinica_servicio_id: selectedTipoServicio, perfiles: updatedPerfiles });
      setPriceReference(totalRefencial)
      const areas = {
         tipo_perfil_id: tipo,
         areas_medicas: areas_medicas,
         capacitaciones: capacitaciones,
         examenes: examenes,
      };
      var updatedList = {};
      switch (tipo) {
         case '1':

            setRowData({ ...rowData, selectedIngreso: e });
            updatedList = perfiles.map((perfil) => {
               if (perfil.id === rowData.id) {
                  return {
                     ...perfil,

                     areas_medicas: areas_medicas,
                     capacitaciones: capacitaciones,
                     examenes: examenes,
                  }
               }
               return perfil;
            })
            break;

         default:
            break;
      }
      setPerfiles(updatedList);
      setServicio({ ...servicio, perfiles: updatedList })
   };
   const handleAgregar = () => {
      const newId = Math.max(...perfiles.map((perfil => perfil.id))) + 1
      const newPerfil = {
         id: newId,
         nombre: "Ingresar Nombre",
         selectedIngreso: [],
      }
      setPerfiles([...perfiles, newPerfil])
      const nuevoElemento = { "id": newId, content: {} };
      setMarcados((prevMarcados) => [...prevMarcados, nuevoElemento]);
   };

   const handleChangeNombre = (event) => {
      setNombrePaquete(event.target.value);
      validarCampos();
      setServicio({ ...servicio, nombre: event.target.value });
   };

   const handleKeyDown = (e) => {
      if (e.key === "Escape") {
         setShowInput(false);
      }
   };

   useEffect(() => {
      getServicios();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
   }, []);


   const onCellEditComplete = (e) => {
      let { rowData, newValue, field, originalEvent: event } = e;
      rowData[field] = newValue;
      actualizarPerfil(rowData);
   };

   const actualizarPerfil = (perfilActualizado) => {
      setPerfiles(perfiles.map(perfil => perfil.id === perfilActualizado.id ? perfilActualizado : perfil));
   };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return <InputText placeholder="tipo perfil" type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} style={{ textAlign: 'center' }} />;
   };



   const headerGroup = (
      <ColumnGroup >
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header="Servicios" field="thisYearSale" />
            <Column header="Precio Referencial" field="lastYearProfit" />
         </Row>
      </ColumnGroup>
   );

   const sendData = async (data) => {
      dispatch(startLoading());

      try {
         const response = await http.put('clinica/paquete/particular/update/' + id, data);
         if (response?.data?.resp || response?.data) {
            showToast('success', 'Success', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }
      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   }

   const filtrarPorPrefijo = (items, prefijo) => {

      const filteredItems = {};
      for (const key in items) {
         if (key.startsWith(prefijo)) {
            filteredItems[key] = items[key];
         }
      }

      return filteredItems;
   };


   const [datosEnvio, setDatosEnvio] = useState(null);

   const RellenarDatosEnvio = () => {
      setDatosEnvio({
         "nombre": NombreServicio,
         "clinica_servicio_id": selectedTipoServicio,
         "perfiles": PerfilesCreados.map(item => ({
            "nombre": item.NombrePerfil,
            "areas_medicas": Object.entries(item.ItemsSeleccionadosDetalles)
               .filter(([key, value]) => key.startsWith('1'))
               .reduce((obj, [key, value]) => {
                  obj[key] = value;
                  return obj;
               }, {}),
         }))
      });
   };


   const CrearServicio = async (data) => {

      dispatch(startLoading());
      try {
         const response = await http.post('clinica/paquete/particular/create', data);

         if (response?.data?.resp) {
            showToast(
               "success",
               "Creado exitosa",
               "Servicio creado correctamente"
            );
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   };

   useEffect(() => {
      if (datosEnvio != null) {
         CrearServicio(datosEnvio);
      }
   }, [datosEnvio]);


   const validarCampos = () => {
      const camposNombreValido = nombrePaquete.trim() !== '';

      const camposSonValidos = camposNombreValido;

      setNombreValido(camposNombreValido);
      // setServicioValido(camposServicioValido);
      setCamposValidos(camposSonValidos);
   };

   useEffect(() => {
      validarCampos();
   }, [nombrePaquete]);




   const [FaltaNombrePack, setFaltaNombrePack] = useState(false);
   const [FaltaTipoServicio, setFaltaTipoServicio] = useState(false);

   const VerificarFaltas = () => {
      var verifica = true;
      if (NombreServicio == "") {
         setFaltaNombrePack(true);
         verifica = false;
      }
      if (selectedTipoServicio == "" || selectedTipoServicio == null) {
         setFaltaTipoServicio(true);
         verifica = false;
      }
      return verifica;
   }
   const handleSubmit = () => {
      var veri = VerificarFaltas();
      if (veri) {
         sendData(DataEnviar)
      }
   };


   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Editar Servicio - Particular</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white h-auto p-3 pl-5 pr-5 ContenedorWhiteAHP gap-2 flex flex-wrap align-items-start">


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Nombre:
                  </label>
                  <span className="p-input-icon-right border-round-lg">
                     <i className="pi pi-pencil" />

                     <InputText
                        placeholder="Paquete anual- Primax"
                        className={`add__container_main_content_header_inputs_singular_input ${FaltaNombrePack ? 'active' : ''}`}
                        value={NombreServicio}
                        onChange={(e) => {
                           setNombreServicio(e.target.value); setFaltaNombrePack(false)
                        }}
                     />


                     {/* <InputText
                        placeholder="Paquete anual- Primax"
                        className="add__container_main_content_header_inputs_singular_input"
                        value={NombreServicio} onChange={(e) => setNombreServicio(e.target.value)}
                     /> */}
                  </span>
                  <div className={`divFaltaHeighttTransition ${FaltaNombrePack ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaNombrePack ? 'active' : ''}`}>Se necesita ingresar datos</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Tipo de servicio:
                  </label>
                  <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                     faltt={FaltaTipoServicio}
                  />

                  {/* <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                  /> */}
                  <div className={`divFaltaHeighttTransition ${FaltaTipoServicio ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaTipoServicio ? 'active' : ''}`}>Se necesita ingresar un tipo de servicio</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1" style={{ opacity: '0' }}>
                     Buscar:
                  </label>
                  <span className=" p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                     <i className=" px-2 pi pi-search" />
                     <InputText placeholder="Buscar..." className="w-full" />
                  </span>
               </div>



               {/* <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Nombre:
                     </label>
                     <span className="p-input-icon-right">
                        <i className="pi pi-pencil" />
                        <InputText
                           placeholder="Paquete anual- Primax"
                           className="add__container_main_content_header_inputs_singular_input"
                           value={NombreServicio} onChange={(e) => setNombreServicio(e.target.value)}
                        />
                     </span>
                  </div>
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Tipo de servicio:
                     </label>
                     <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     />
                  </div>
               </div>
               <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText placeholder="Buscador" />
                     </span>
                  </div>
               </div> */}
            </div>
            { }
            <div className="add__container_main_content_body">
               <div style={{ width: "75%", margin: "auto" }} className="add__container_main_content_body_container bg-white">
                  <ScrollPanel
                     style={{ width: "100%", maxHeight: '400px', padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid">
                        <DataTable
                           value={PerfilesCreados}
                           headerColumnGroup={headerGroup}
                           editMode="cell"
                           tableStyle={{ maxWidth: '100%' }}
                           size="small">
                           <Column className="columna_j" field="NombrePerfil" header="" style={{ width: '22%', textAlign: "center" }}
                              editor={(options) => textEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                           <Column body={seleccionarIngreso} header="Servicios" ></Column>
                           <Column
                              field="PrecioReferencial"
                              header="Precio Referencial"
                              body={(rowData) => (
                                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <button
                                       className="p-button p-component p-button-text-icon-left p-button-text-icon-align-right p-button-outlined p-button-sm"
                                       style={{ marginLeft: '5px', marginRight: '5px', width: '100px', height: '30px' }}
                                       type="button"
                                    >
                                       <span className="p-button-text p-c" style={{ display: 'flex', alignItems: 'center' }}>
                                          <span style={{ marginRight: '5px' }}>S/.</span>
                                          {rowData.PrecioReferencial}
                                       </span>
                                    </button>
                                 </div>
                              )}
                           />
                        </DataTable>
                     </div>

                     <DialogIngreso
                        Activador={AbrirDialog}
                        setActivador={setAbrirDialog}
                        DataActual={PerfilesCreados}
                        setDataActual={setPerfile4sCreados}
                        IdSelect={IdSeleccionado}
                        DataServicios={servicios}
                     />

                  </ScrollPanel>
               </div>
               <div style={{ width: "75%", margin: "auto", marginTop: "12px" }} className="flex justify-content-between add__container_main_content_footer_two">
                  <button
                     disabled={habilitado}
                     className=" add__container_main_content_footer_one_btn"
                     onClick={agregarNuevaSeccion}
                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
                  <div className="w-auto flex gap-2 ">
                     <button className="add__container_main_content_footer_two_btn ">
                        <Link
                           to="/productosyservicios/servicios/"
                           className="bg-colo-btn"
                           style={{ textDecoration: "none" }}
                        >
                           Cancelar
                        </Link>
                     </button>
                     <button className="button__add_new"
                        onClick={handleSubmit}>Guardar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
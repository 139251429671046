import React, { useContext, useEffect, useState } from 'react';
import { OftContext } from "../OftContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { iconOft } from "../../../Services/importIcons";
import '../../../Style/oft_test.css'
import './PageOftTest.css';

const PageOftTest = () => {


  let { testContext, setTestContext } = useContext(OftContext)
  let { pageViewContext, stateAtencion } = useContext(RAContext)

  const [movimiento, setMovimiento] = useState(testContext?.movimientos_oculares ?? 'Normal');

  const [parpadosDer, setParpadosDer] = useState(testContext?.ojo_derecho?.parpados ?? 'Normal');
  const [parpadosIzq, setParpadosIzq] = useState(testContext?.ojo_izquierdo?.parpados ?? 'Normal');
  const [conjuntivaDer, setConjuntivaDer] = useState(testContext?.ojo_derecho?.conjuntiva ?? 'Normal');
  const [conjuntivaIzq, setConjuntivaIzq] = useState(testContext?.ojo_izquierdo?.conjuntiva ?? 'Normal');
  const [corneaDer, setCorneaDer] = useState(testContext?.ojo_derecho?.cornea ?? 'Normal');
  const [corneaIzq, setCorneaIzq] = useState(testContext?.ojo_izquierdo?.cornea ?? 'Normal');
  const [camaraDer, setCamaraDer] = useState(testContext?.ojo_derecho?.camara_anterior ?? 'Normal');
  const [camaraIzq, setCamaraIzq] = useState(testContext?.ojo_izquierdo?.camara_anterior ?? 'Normal');
  const [irisDer, setIrisDer] = useState(testContext?.ojo_derecho?.iris ?? 'Normal');
  const [irisIzq, setIrisIzq] = useState(testContext?.ojo_izquierdo?.iris ?? 'Normal');
  const [cristalinoDer, setCristalinoDer] = useState(testContext?.ojo_derecho?.cristalino ?? 'Normal');
  const [cristalinoIzq, setCristalinoIzq] = useState(testContext?.ojo_izquierdo?.cristalino ?? 'Normal');
  const [refleDer, setRefleDer] = useState(testContext?.ojo_derecho?.refle_pupilares ?? 'Normal');
  const [refleIzq, setRefleIzq] = useState(testContext?.ojo_izquierdo?.refle_pupilares ?? 'Normal');

  useEffect(() => {
    setTestContext({
      [`movimientos_oculares`]: movimiento,
      [`ojo_izquierdo`]: {
        [`parpados`]: parpadosIzq,
        [`conjuntiva`]: conjuntivaIzq,
        [`cornea`]: corneaIzq,
        [`camara_anterior`]: camaraIzq,
        [`iris`]: irisIzq,
        [`cristalino`]: cristalinoIzq,
        [`refle_pupilares`]: refleIzq
      },
      [`ojo_derecho`]: {
        [`parpados`]: parpadosDer,
        [`conjuntiva`]: conjuntivaDer,
        [`cornea`]: corneaDer,
        [`camara_anterior`]: camaraDer,
        [`iris`]: irisDer,
        [`cristalino`]: cristalinoDer,
        [`refle_pupilares`]: refleDer
      },
    })
  }, [movimiento, parpadosDer, parpadosIzq,
    conjuntivaDer, conjuntivaIzq, corneaDer, corneaIzq, camaraDer, camaraIzq, irisDer, irisIzq, cristalinoDer, cristalinoIzq, refleDer, refleIzq])

  const TestOft = (props) => {
    return (
      <div className='movil-oft-test' style={{ minWidth: "150px" }}>
        <div className="oft_test_ctable_container">
          <div className="oft_test_ctable_header1" style={{ backgroundColor: '#EBF0FF' }}>
            <span className='span_oft_test'>{props.cabecera}</span>
          </div>
          <div className="oft_test_ctable_content1">
            <span>Ojo Derecho</span>
          </div>
          <div className="oft_test_ctable_content2">
            <InputText value={props.value} onChange={props.value2} placeholder='Normal' disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
          <div className="oft_test_ctable_content1" >
            <span>Ojo Izquierdo</span>
          </div>
          <div className="oft_test_ctable_content2">
            <InputText value={props.value3} onChange={props.value4} placeholder='Normal' disabled={stateAtencion[pageViewContext].estado === 3} />
          </div>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className="oft_ant_titles">
        <div>
          <img src={iconOft} alt="Icon" />
        </div>
        <h3>Test de Rutina</h3>
      </div>

      <div className="oft_test_textarea">
        <div className='input_micro_label'>
          <h3>Movimientos oculares y tropías</h3>
        </div>

        <InputTextarea
          value={movimiento}
          onChange={e => setMovimiento(e.target.value)}
          autoResize
          placeholder="Ingrese una descripción"
          disabled={stateAtencion[pageViewContext].estado === 3}
        />
      </div>

      <h3>Examen Segmentado</h3>
      <div className='desktop-oft-test'>
        <div className="oft_test_ctable_container2">

          <div className="oft_test_ctable_header1" style={{ backgroundColor: '#EBF0FF' }}>
            <span>Examen Segmentado</span>
          </div>

          <div className="oft_test_ctable_header2_main">
            <span>Segmento</span>
          </div>
          <div className="oft_test_ctable_header2">
            <span>Ojo Derecho</span>
          </div>
          <div className="oft_test_ctable_header2">
            <span>Ojo Izquierdo</span>
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Párpados</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={parpadosDer}
              onChange={e => setParpadosDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={parpadosIzq}
              onChange={e => setParpadosIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Conjuntiva</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={conjuntivaDer}
              onChange={e => setConjuntivaDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={conjuntivaIzq}
              onChange={e => setConjuntivaIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Córnea</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={corneaDer}
              onChange={e => setCorneaDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={corneaIzq}
              onChange={e => setCorneaIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Cámara anterior</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={camaraDer}
              onChange={e => setCamaraDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={camaraIzq}
              onChange={e => setCamaraIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Iris</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={irisDer}
              onChange={e => setIrisDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={irisIzq}
              onChange={e => setIrisIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Cristalino</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={cristalinoDer}
              onChange={e => setCristalinoDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={cristalinoIzq}
              onChange={e => setCristalinoIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="oft_test_ctable_body_main">
            <span>Refle. Pupilares</span>
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={refleDer}
              onChange={e => setRefleDer(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="oft_test_ctable_body">
            <InputText
              value={refleIzq}
              onChange={e => setRefleIzq(e.target.value)}
              placeholder="Seleccione"
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
        </div>
      </div>
      <TestOft
        cabecera="Párpados"
        value={parpadosDer}
        value2={e => setParpadosDer(e.target.value)}
        value3={parpadosIzq}
        value4={e => setParpadosIzq(e.target.value)} />

      <TestOft
        cabecera="Conjuntiva"
        value={conjuntivaDer}
        value2={e => setConjuntivaDer(e.target.value)}
        value3={conjuntivaIzq}
        value4={e => setConjuntivaIzq(e.target.value)} />

      <TestOft
        cabecera="Córnea"
        value={corneaDer}
        value2={e => setCorneaDer(e.target.value)}
        value3={corneaIzq}
        value4={e => setCorneaIzq(e.target.value)} />

      <TestOft
        cabecera="Cámara anterior"
        value={camaraDer}
        value2={e => setCamaraDer(e.target.value)}
        value3={camaraIzq}
        value4={e => setCamaraIzq(e.target.value)} />

      <TestOft
        cabecera="Iris"
        value={irisDer}
        value2={e => setIrisDer(e.target.value)}
        value3={irisIzq}
        value4={e => setIrisIzq(e.target.value)} />

      <TestOft
        cabecera="Cristalino"
        value={cristalinoDer}
        value2={e => setCristalinoDer(e.target.value)}
        value3={cristalinoIzq}
        value4={e => setCristalinoIzq(e.target.value)} />

      <TestOft
        cabecera="Refle. Pupilares"
        value={refleDer}
        value2={e => setRefleDer(e.target.value)}
        value3={refleIzq}
        value4={e => setRefleIzq(e.target.value)} />
    </>
  );
};

export default PageOftTest;
import React from 'react'
import '../../../Style/Botones.css'
import Botones from '../../../Components/Pure/MenuPrincipal/botones'
import Eclipses from '../../../Components/Pure/MenuPrincipal/Eclipses'
import Imagenes from '../../../Components/Pure/MenuPrincipal/Imagenes'
import con1 from '../../../Images/Pure/MenuPrincipal/img-icons/con1.png'
import con2 from '../../../Images/Pure/MenuPrincipal/img-icons/con2.png'
import Chica from '../../../Images/Pure/MenuPrincipal/Img/Chica.png'

function PageEstadisticasEm() {
  return (
    <div className=' flex flex-nowrap flex-column w-full' style={{height:"100%"}}>
        <div className='arriba'>
            <span className='tit'>Estadísticas</span><br/>
            <span className='con'>En este módulo usted podrá tomar control sobre los datos de la empresa.</span>
        </div>
        <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{height:"100%"}} >
            <Botones
            Link="/estadisticas/cercoepidemiologico"
            img={con1}
            span="Cerco Epidemiológico"
            button="button b1"
            />
            <Botones
            Link="/estadisticas/estadisticasgenerales"
            img={con2}
            span="Estadísticas Generales"
            button="button b3"
            />
            <Botones
            Link="/estadisticas/encuestaAceptacion"
            img={con2}
            span="Encuesta Aceptacion"
            button="button b5"
            />
            <Eclipses/>
            <Imagenes
            src={Chica}
            className="logo1"
            />

        </div>
    </div>
  )
}

export default PageEstadisticasEm

import Botones from "../../Components/Pure/MenuPrincipal/botones";
import Imagenes from "../../Components/Pure/MenuPrincipal/Imagenes";
import Eclipses from "../../Components/Pure/MenuPrincipal/Eclipses";
import Icon1 from "../../Images/Pure/MenuPrincipal/img-icons/ven1.png";
import Icon2 from "../../Images/Pure/MenuPrincipal/img-icons/ven2.png";
import Productos from "../../Images/Pure/MenuPrincipal/Img/Operaciones.png";

const PageSales = ({ accesoProp }) => {

   const listAcceso = accesoProp.map((acc) => {
      return acc.url
   });

   const list_images = (url) => {
      switch (url) {
         case "gestiondeventas":
            return Icon1;
         default:
            return Icon2;
      }
   }

   return (
      <div className=' flex flex-nowrap flex-column w-full' style={{ height: "100%" }}>
         <div className="arriba">
            <span className="tit">Ventas</span><br />
            <span className="con">
               En este módulo usted podrá crear proformas, clientes y asignación de módulos.
            </span>
         </div>
         <div className='gap-3 w-full flex flex-nowrap flex-column justify-content-center' style={{ height: "100%" }}>
            {
               accesoProp.map((acceso, key) => {
                  return <>
                     <Botones
                        key={key}
                        Link={acceso.url}
                        img={list_images(acceso.url)}
                        span={acceso.label}
                        button={"button b" + (key + 1)}
                     />
                  </>
               })
            }
            <Eclipses />
            <Imagenes src={Productos} className="logo2" />

         </div>
      </div>
   );
}

export default PageSales;
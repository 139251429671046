export const ProductPruebas =[
        {
          id: 170,
          code: 'Tímpanos',
          name: "",
          description: '',
        },
        {
          id: 171,
          code: 'Audición',
          name: "",
          description: '',
        },
        {
          id: 172,
          code: 'Sustentación mediante un pie a un ángulo de 15 grados.',
          name: "",
          description: '',
        },
        {
          id: 173,
          code: 'Caminar libre sobre recta de 3 m (sin desvío).',
          name: "",
          description: '',
        },
        {
          id: 174,
          code: 'Caminar libre ojos vendados 3 m (sin desvío).',
          name: "",
          description: '',
        },
        {
          id: 175,
          code: 'Caminar libre ojos vendados punta talón 3m (sin desvío).',
          name: "",
          description: '',
        },
        {
          id: 176,
          code: 'Rotar sobre silla y luego verificar equilibrio de pie',
          name: "",
          description: '',
        },
        {
          id: 177,
          code: 'ADCADOCOQUINESIA DIRECTA',
          name: "",
          description: '',
        },
        {
          id: 178,
          code: 'ADCADOCOQUINESIA CRUZADA',
          name: "",
          description: '',
        },
        {
          id: 179,
          code: 'Recibió entrenamiento para trabajo sobre nivel',
          name: "",
          description: '',
        },
        {
          id: 180,
          code: 'DIK HALLPIKE',
          name: "",
          description: '',
        },
        {
          id: 181,
          code: 'BARANY',
          name: "",
          description: '',
        },
        {
          id: 182,
          code: 'Vestíbulo ocular',
          name: "",
          description: '',
        },
        {
          id: 183,
          code: 'ROMBERG',
          name: "",
          description: '',
        },
        {
          id: 184,
          code: 'UNTBERGER',
          name: "",
          description: '',
        },
        {
          id: 185,
          code: 'BARRE',
          name: "",
          description: '',
        },
        {
          id: 186,
          code: 'BABISKY-WEILL',
          name: "",
          description: '',
        },

        
  
      ]
  
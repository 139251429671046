import React, { useEffect, useRef, useState } from 'react';
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Dropdown } from "primereact/dropdown";
import '../../Style/component_select_textarea.css'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const SelectTextArea = ({ options, title, setState, state, name = null, setId, id, disabled }) => {
   const {
      interimTranscript,
      resetTranscript,
   } = useSpeechRecognition();

   const [visible] = useState(false);
   const menu = useRef(null);
   const [setIsListening] = useState(false);
   const [transcribedText, setTranscribedText] = useState(''); // Estado para almacenar el texto transrito

   const [setterOptions, setSetterOptions] = useState(options);

   const changeData = (item) => {
      setId(item?.id);
   }

   useEffect(() => {
      if (setterOptions) {
         let _data = setterOptions?.map((item) => ({
            ...item,
            command: () => {
               changeData(item)
            }
         }))
         setSetterOptions(_data)
      }
   }, [])

   useEffect(() => {
      if (id !== null) {
         let _item = options.find(d => d?.id === id)
         setState(_item?.label)
      }
   }, [id])

   const handleVisibleDropdown = (e) => {
      menu.current.toggle(e)
   }

   const handleChangeDropdown = (e) => {
      setState(e);
   }

   const handleInputChange = (e) => {
      setState(e.target.value);
   }

   const handleResult = () => {
      if (interimTranscript) {
         setTranscribedText(prevText => prevText + interimTranscript); // Concatenar el texto transrito
         resetTranscript();
      }
   };

   const handleStartListening = () => {
      resetTranscript();
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
   };

   const handleStopListening = () => {
      handleResult();
      SpeechRecognition.stopListening();
      setIsListening(false);
   };

   return (
      <div className="select_text_area_container">
         <div className="select_text_area-input">
            <label>{title}</label>
         </div>
         {visible && (
            <div>
               <Dropdown
                  disabled={disabled}
                  options={options}
                  optionValue="label"
                  optionLabel="label"
                  onChange={e => {
                     handleChangeDropdown(e);
                     handleVisibleDropdown();
                  }}
                  style={{ width: '100%', marginBottom: '.3rem' }}
                  placeholder="Seleccione una opción"
               />
            </div>
         )}
         <div className="p-inputgroup select_text_area">
            <InputTextarea
               disabled={disabled}
               rows={2}
               cols={30}
               contentEditable
               autoResize
               value={state + transcribedText}
               suppressContentEditableWarning
               placeholder="Escriba o seleccione una opción"
               onChange={handleInputChange}
            />
            <Button
               disabled={disabled}
               icon="pi pi-chevron-down"
               onClick={e => handleVisibleDropdown(e)}
               tooltip="Click para mostrar/ocultar opciones"
               tooltipOptions={{ position: 'bottom' }}
            />
         </div>
         <Menu model={setterOptions} popup ref={menu} className="select_text_area_menu" />

      </div>
   );
};

export default SelectTextArea;
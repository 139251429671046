import React, { useState } from 'react'
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { FileUpload } from 'primereact/fileupload'
import { Tag } from 'primereact/tag';

import { Footer, Header } from '../../../Pure/SeccionesNavegacion';

// Componente para cargar datos de DNI mediante un archivo Excel.
export default function CargarDatosDNI({ visible, setVisible, subirArchivo, isLoading, downloadTemplate }) {

   // Estado para el tamaño total del archivo
   const [totalSize, setTotalSize] = useState(0);
   // Estado para el archivo seleccionado
   const [file, setFile] = useState(null);
   
   // Limpia el archivo seleccionado y reinicia el tamaño total.
   const onTemplateClear = () => {
      setTotalSize(0);
      setFile(null);
   };

   // Plantilla para el encabezado del componente de carga de archivos.
   const headerTemplate = (options) => {
      const { className, chooseButton } = options;
      return (
         <div
            className={className}
            style={{
               backgroundColor: "transparent",
               display: "flex",
               alignItems: "center",
            }}
         >
            {chooseButton}
         </div>
      );
   };

   // Plantilla para cada ítem de archivo cargado.
   const itemTemplate = (file, props) => {
      return (
         <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <div style={{ display: "flex", alignItems: "center", width: "65%" }}>
               <i className="pi pi-file-excel" />
               <span
                  style={{
                     display: "flex",
                     flexDirection: "column",
                     textAlign: "left",
                     marginLeft: "1rem",
                  }}
               >
                  {file.name}
                  <small>{new Date().toLocaleDateString()}</small>
               </span>
            </div>
            <div style={{ width: "35%" }}>
               <Tag
                  value={props.formatSize}
                  severity="warning"
                  style={{ padding: "1rem .5rem" }}
               />
               <Button
                  type="button"
                  icon="pi pi-times"
                  style={{ width: "48px", margin: "0 1rem" }}
                  className="p-button-rounded p-button-danger"
                  onClick={() => onTemplateRemove(file, props.onRemove)}
               />
            </div>
            {isLoading &&
               <div className='montserrat'>Enviando personal, espere...</div>}
         </div>
      );
   };

   // Elimina un archivo de la lista y actualiza el tamaño total.
   const onTemplateRemove = (file, callback) => {
      setTotalSize(totalSize - file.size);
      setFile(null);
      callback();
   };

   // Plantilla para cuando no hay archivos cargados.
   const emptyTemplate = () => {
      return (
         <div
            style={{
               display: "flex",
               alignItems: "center",
               flexDirection: "column",
            }}
         >
            <i
               className="pi pi-file-excel"
               style={{
                  fontSize: "2em",
                  borderRadius: "50%",
                  backgroundColor: "var(--surface-b)",
                  color: "var(--surface-d)",
                  marginTop: "1rem",
                  padding: "1rem",
               }}
            ></i>
            <span
               style={{
                  fontSize: "1.0em",
                  color: "var(--text-color-secondary)",
                  margin: "1rem 2rem",
               }}
            >
               Arrastre y suelte el archivo aquí.
            </span>
         </div>
      );
   };

   // Opciones para el botón de selección de archivo
   const chooseOptions = {
      icon: "pi pi-file-excel",
      iconOnly: false,
      label: "Seleccionar un archivo",
      className: "custom-choose-btn p-button-rounded p-button-outlined",
   };

   // Maneja el cambio de archivo.
   const onChangeFile = (e) => {
      setFile(e?.files[0]);
   };

   // Envía el archivo cargado.
   const sendData = () => {
      subirArchivo(file);
   }

   // Encabezado del diálogo
   const header = (
      <Header piIcon={'pi pi-file'}
         titulo={'Cargar archivo DNI'}
         subtitulo={'Recuerde que esta opción solo funciona cuando el tipo de documento es DNI, no para carné de extranjería o pasaporte.'} />
   )

   // Contenido del pie del diálogo
   const footerContent = (
      <Footer onClickAdicional={downloadTemplate} onClickCancelar={() => setVisible(false)} onClickEnviar={() => sendData()} label={'Aceptar'} labelAdicional={'Descargar plantilla'} iconoAdicional={'pi pi-download'} />
   );

   // Renderizado del componente
   return (
      <>
         <Dialog
            visible={visible}
            onHide={() => setVisible(false)}
            header={header}
            footer={footerContent}
         >
            <FileUpload
               name="excel"
               accept=".xlsx"
               maxFileSize="1000000"
               customUpload
               auto
               uploadHandler={(e) => onChangeFile(e)}
               onError={onTemplateClear}
               onClear={onTemplateClear}
               headerTemplate={headerTemplate}
               itemTemplate={itemTemplate}
               emptyTemplate={emptyTemplate}
               chooseOptions={chooseOptions}
            />
         </Dialog>
      </>
   )
}

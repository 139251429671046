import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history } from "../Services/history";
import axios from "../http-common"

const initialState = () => {
   return {
      first_name: localStorage.getItem("first_name"),
      last_name: localStorage.getItem("last_name"),
      logo: localStorage.getItem("logo"),
      logoPerfil: localStorage.getItem("logoPerfil"),
      accesos: JSON.parse(localStorage.getItem("accesos")),
      tipo: null,
      tipo_acceso: JSON.parse(localStorage.getItem("tipo_acceso")),
      token: JSON.parse(localStorage.getItem("token")),
      finishWithErrors: false,
      errorMessage: "Fallas al solicitar"
   }
}

export const signInUser = createAsyncThunk('signInUser', async (body) => {
   return axios.http.post("/login", body);
})

const authSlice = createSlice({
   name: 'login',
   initialState: initialState(),
   reducers: {
      addToken: (state, action) => {
      },
      logout: (state, action) => {
         state.token = null;
         state.first_name = null;
         state.last_name = null;
         state.logo = null;
         state.logoPerfil = null;
         state.accesos = null;
         state.tipo = null;
         state.tipo_acceso = null;
         localStorage.removeItem("accesos");
         localStorage.removeItem("first_name");
         localStorage.removeItem("last_name");
         localStorage.removeItem("token");
         localStorage.removeItem("tipo_acceso");
         localStorage.removeItem("logo");
         localStorage.removeItem("logoPerfil");
         history.navigate('/login');
      },
      udpateLogo: (state, data) => {
         state.logo = data.payload;
         localStorage.removeItem("logo");
         localStorage.setItem('logo', data.payload);
      },
      updateName: (state, data) => {
         state.first_name = data.payload.nombres;
         state.last_name = data.payload.apellido_paterno;
         localStorage.removeItem("first_name");
         localStorage.removeItem("last_name");
         localStorage.setItem('first_name', data.payload.nombres);
         localStorage.setItem('last_name', data.payload.apellido_paterno);
      },
      udpateLogoPerfil: (state, data) => {
         state.logoPerfil = data.payload;
         localStorage.removeItem("logoPerfil");
         localStorage.setItem('logoPerfil', data.payload);
      },
   },
   extraReducers: {
      [signInUser.pending]: (state, action) => {
         state.loading = true;
      },
      [signInUser.fulfilled]: (state, { payload: { data } }) => {
         localStorage.setItem("token", JSON.stringify(data.token));
         localStorage.setItem("tipo_acceso", JSON.stringify(data.roles[0].tipo_acceso));
         localStorage.setItem("accesos", JSON.stringify(data.accesos));
         localStorage.setItem("first_name", data.persona.nombres);
         localStorage.setItem("last_name", data.persona.apellido_paterno);
         localStorage.setItem("logo", data.url_foto);
         localStorage.setItem("logoPerfil", data.persona.foto);
         state.token = data.token;
         state.accesos = data.accesos;
         state.tipo_acceso = data.roles[0].tipo_acceso;
         state.first_name = data.persona.nombres;
         state.last_name = data.persona.apellido_paterno;
         state.logo = data.url_foto;
         state.logoPerfil = data.persona.foto;
         const { from } = history?.location?.state || { from: { pathname: data.accesos[0]?.url } };
         history.navigate(from);
      },
      [signInUser.rejected]: (state, action) => {
         state.loading = true;
      },
   }
})

export const { addToken, logout, udpateLogo, updateName, udpateLogoPerfil } = authSlice.actions;

export default authSlice.reducer
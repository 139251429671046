import React, { useContext, useRef, useState } from 'react';
import AuthUser from "../../AuthUser";
import { OftContext } from "./OftContext";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import PageOftAnt from "./Antecedentes/PageOftAnt";
import '../../Style/oft.css';
import PageOftAgud from "./AgudezaVisual/PageOftAgud";
import PageOftTest from "./Test/PageOftTest";
import PageOftMed from "./Medicion/PageOftMed";
import PageOftDiag from "./Diagnostico/PageOftDiag";
import PageRef from "./Refraccion/PageRef";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconOft } from "../../Services/importIcons";
import ModalImprimir from '../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir';
import { useParams } from 'react-router-dom';
import { RAContext } from '../RutaAtencion/RAContext';

const PageOft = () => {

  const [imprimirVisible, setImprimirVisible] = useState(false)
  let { anteContext } = useContext(OftContext)
  let { agudVisualContext } = useContext(OftContext)
  let { testContext } = useContext(OftContext)
  let { mediContext } = useContext(OftContext)
  let { diagContext } = useContext(OftContext)
  let { refraccion } = useContext(OftContext)
  let { pacienteContext, pageViewContext, stateAtencion, setStateAtencion } = useContext(RAContext)

  const { idPaciente } = useParams();

  const { atencionStatus, setAtencionStatus } = useContext(OftContext);
  const { setTimeStart } = useContext(OftContext);

  const { http } = AuthUser();

  const [activeIndex, setActiveIndex] = useState(0);

  const [savingStatus, setSavingStatus] = useState(false);
  const toast = useRef(null);

  const showToast = (type, title, detail) => {
    toast.current.show({
      severity: type,
      summary: title,
      detail: detail,
      life: 3000,
    });
  };

  const handleSubmit1 = () => {

  };

  const oftalmologiaCreate = () => {
    const newData = {
      paciente_id: 1
      // idPaciente
    };

    http
      .post('/oftalmologia/create', newData)
      .then(() => {
      })
      .catch((error) => {
        console.error("Error al crear la solicitud de oftalmología:", error);
      });
  };

  const handleSubmit = () => {
    setSavingStatus(true)

    const allFetching = [
      // http
      //   .post('oftalmologia/oft_antecedente/create/1', anteContext)
      //   .then(() => {

      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
      // ,
      // http
      //   .post('oftalmologia/test/create', testContext)
      //   .then(() => {
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
      // ,
      // http
      //   .post('oftalmologia/medicion/create/1', mediContext)
      //   .then(() => { 
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
      // ,
      http
        .post('oftalmologiaDiagnostico/create/1', diagContext)
        .then(() => {
        })
        .catch((error) => {
          console.error(error);
        })
      // ,
      // http
      //   .post('oftalmologia/agudezavisual/create', agudVisualContext)
      //   .then(() => {
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
      // ,
      // http
      //   .post('oftalmologia/refraccion/create/1', refraccion)
      //   .then(() => {
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   })
    ]

    Promise.allSettled(allFetching)
      .then((results) => {
        results.forEach();
        showToast(
          "success",
          `Atención en Oftalmología guardada`,
          `Se guardó la atención correctamente`
        );
        setSavingStatus(false);
      })
      .catch(() => {
        setSavingStatus(false);
      })
  }

  const tabSubmitHeader = (options) => {
    return (
      <>
        <Button
          icon={savingStatus ? `pi pi-spin pi-spinner` : `pi pi-save`}
          label={savingStatus ? 'Guardando ...' : options.titleElement}
          className="p-button-sm"
        />
      </>
    );
  };

  return (
    <>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
        scrollable
      >
        <TabPanel header="Antecedentes">
          <PageOftAnt />
        </TabPanel>
        <TabPanel header="Agudeza Visual">
          <PageOftAgud />
        </TabPanel>
        <TabPanel header="Refracción">
          <PageRef />
        </TabPanel>
        <TabPanel header="Test">
          <PageOftTest />
        </TabPanel>
        <TabPanel header="Medición">
          <PageOftMed />
        </TabPanel>
        <TabPanel header="Diagnóstico">
          <PageOftDiag />
        </TabPanel>
      </TabView>
      <div style={{ borderRadius: '10px', height: "70px" }} className='flex justify-content-end gap-2 mt-2 bg-white'>
        <button className='p-button p-component btn-2 flex gap-1' onClick={handleSubmit}>
          <i className='pi pi-save'> </i>
          <span>Guardar</span>
        </button>
        <button style={{ color: 'red' }} className='p-button  p-component btn-2 flex gap-1'>
          <i className='pi pi-times'></i>
          <span>Cancelar</span>
        </button>
        <button style={{ color: 'black' }} onClick={() => setImprimirVisible(true)} className='p-button p-component btn-2 flex gap-1'>
          <i className='pi pi-print'></i>
          <span>Imprimir</span>
        </button>
      </div>
      <ModalImprimir
        imprimirVisible={imprimirVisible}
        setImprimirVisible={setImprimirVisible}
      />


      {/* {
        atencionStatus === 0
        &&
        <RATimeView title="Oftalmología" icon={iconOft} setTimeStart={setTimeStart} setAtencionStatus={setAtencionStatus} />
      } */}
      {/* {(stateAtencion[pageViewContext].estado === 0 && atencionStatus === 0) && (
        <RATimeView
          title="Oftalmología"
          icon={iconOft}
          setTimeStart={setTimeStart}
          setAtencionStatus={setAtencionStatus}
          createAtencion={oftalmologiaCreate}
        />
      )} */}
    </>
  );
};

export default PageOft;
import React, { useState,useContext } from "react";
import { InputText } from "primereact/inputtext";
import TablaOdontograma from "./TablaOdontrograma";
import "../../Style/Principal.css";
import VoiceButton from "../../Components/ButtonVoice/VoiceButton";
import { OdoContext } from "./OdoProvider"
import { RAContext } from "../RutaAtencion/RAContext";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import { iconOdontologia } from "../../Services/importIcons";


const PrincipalOdontograma = () => {
   const [value, setValue] = useState("");
   const [Comentario, setComentario] = useState("");
   let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext); //
   const {setTimeStart}=useContext(OdoContext)
   const {setAtencionStatus}=useContext(OdoContext)

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
        prevStates.map((estado, index) =>
          index === pageViewContext ? { ...estado, estado: st } : estado
        )
      );
    };

   return (
      <div className="flex flex-column h-full">
         <div style={{height:'calc(100% - 155px)'}}>
            <div style={{backgroundColor:'white', borderRadius:'15px', height:'100%', overflowY:'scroll'}}>
               <div
                  className="flex flex-wrap gap-3 flex-1"
                  style={{ padding: "20px", width: "100%" }}
               >
                  <div
                     className="flex flex-column gap-1 flex-1"
                     style={{ width: "100%" }}
                  >
                     <p>Estado Bucal</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                     />
                  </div>

                  <div
                     className="flex flex-column gap-1 flex-1"
                     style={{ width: "100%" }}
                  >
                     <p>Dentadura</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                     />
                  </div>

                  <div
                     className="flex flex-column gap-1 flex-1"
                     style={{ width: "100%" }}
                  >
                     <p>Encías</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                     />
                  </div>

                  <div
                     className="flex flex-column gap-1 flex-1"
                     style={{ width: "100%" }}
                  >
                     <p>Oclusiones</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                     />
                  </div>
               </div>

               <div className="flex gap-8" style={{ padding: "20px" }}>
                  <div className="flex gap-3 ">
                     <p className="flex justify-content-center align-items-center">
                        Extraer
                     </p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ width: "70px", heigth: "40px" }}
                     />
                  </div>
                  <div className="flex gap-3">
                     <p className="flex justify-content-center align-items-center">
                        Dientes lesión cariosa
                     </p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ width: "70px", heigth: "40px" }}
                     />
                  </div>
                  <div className="flex gap-3">
                     <p className="flex justify-content-center align-items-center">
                        Superficies lesión cariosa
                     </p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ width: "70px", heigth: "40px" }}
                     />
                  </div>
                  <div className="flex  gap-3">
                     <p className="flex justify-content-center align-items-center">
                        Ausente
                     </p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ width: "70px", heigth: "40px" }}
                     />
                  </div>
               </div>
               <div className="flex gap-3 flex-column w-full" style={{ padding: "20px" }}>
                  <div className="flex gap-3 flex-column w-full">
                     <p>Observaciones</p>
                     <VoiceButton
                        value={Comentario}
                        onChange={setComentario}
                        placeholder="Comentario"
                     />
                  </div>
               </div>

               <div>
                  <TablaOdontograma />
               </div>
            </div>
         </div>
         <div
            style={{ borderRadius: "10px", height: "70px" }}
            className="flex justify-content-end gap-2 mt-2 bg-white"
         >
            <button
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-save"> </i>
               <span>Guardar</span>
            </button>
            <button
               style={{ color: "red" }}
               className="p-button  p-component btn-2 flex gap-1"
            >
               <i className="pi pi-times"></i>
               <span>Cancelar</span>
            </button>
            <button
               style={{ color: "black" }}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-print"></i>
               <span>Imprimir</span>
            </button>
         </div>

         {stateAtencion[pageViewContext].estado === 0 && (
            <RATimeView
               title="Odontologia"
               icon={iconOdontologia}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               startAtencion={CambiarEstado}
            />
         )}

         {stateAtencion[pageViewContext].estado === 2 && (
            <RATimeView
               title={stateAtencion[pageViewContext].nombre}
               finallyAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
            />
         )}

      </div>
   );
};

export default PrincipalOdontograma;
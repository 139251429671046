import { useState, useEffect } from 'react'
import AuthUser from "../../../../../AuthUser";
import { startLoading, endLoading, } from "../../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function DataTermsConditions(UrlApi, showToast) {
   const { http } = AuthUser();
   const dispatch = useDispatch();

   const [terms, setTerms] = useState([]);

   const changeDataToTreeTable = (arreglo) => {
      let data = arreglo.map((area) => {
         let aux = [];
         if (area.children && area.children.length > 0) {
            aux = changeDataToTreeTable(area.children);
         }
         return {
            key: area.path,
            data: {
               id: area.id,
               nombre: area.nombre,
               descripcion: area.descripcion,
            },
            children: aux,
         };
      });

      return data;
   };

   const getAllTerms = async () => {
      try {
         const response = await http.get(UrlApi)
         if (response?.data?.data) {
            let data = changeDataToTreeTable(response.data.data);
            setTerms(data);

         } else if (response?.data?.error) {
            showToast("error", "Error", response?.data?.error);
         }

      } catch (error) {
         console.error(error);
         showToast("error", "Error", "Contáctese con soporte técnico");
      }
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getAllTerms(),
         ]);
      } catch (error) {
         console.error('Error al cargar datos:', error);
      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData()
   }, []);

   return { terms, getAllTerms }
}
import React, { useContext, useState, useEffect } from "react";
import { RAContext } from "../../../Page/RutaAtencion/RAContext";
import { Skeleton } from "primereact/skeleton";
import "../../../Style/Botones.css";
import "../../../Style/Atencion/CSSDatosPaciente.css";
import ModalCrear from "./ModalCrearAtencion";
import { Button } from "primereact/button";
import ModalAtencionAtendida from "./Modals-Atencion/ModalAtencionAtendida";
import { useNavigate } from "react-router-dom";
import ModalNoAtendido from "./Modals-Atencion/ModalNoAtendido";
import AuthUser from "../../../AuthUser";
import ModalActulizarDatos from "../../Recepcion/RecepcionTablaFiltro/Components/ModalActualizarDatos";

const RAAtencionFormView = () => {
   const {
      pacienteContext,
      pageViewContext,
      stateAtencion,
      setStateAtencion,
      atencionContext,
   } = useContext(RAContext);

   const { http } = AuthUser();

   

   const [ubicaiones, setUbicaciones] = useState(null);
   const [originalPacientes, setOriginalPacientes] = useState([]);
   const [pacientes, setPacientes] = useState([]);
   const [key, setKey] = useState(0);

   const calcularEdad = (fechaNacimiento) => {
      if (fechaNacimiento === null) {
         return NaN;
      }
      const fechaActual = new Date();
      const nacimiento = new Date(fechaNacimiento);
      let edad = fechaActual.getFullYear() - nacimiento.getFullYear();
      const diferenciaMeses = fechaActual.getMonth() - nacimiento.getMonth();

      if (
         diferenciaMeses < 0 ||
         (diferenciaMeses === 0 && fechaActual.getDate() < nacimiento.getDate())
      ) {
         edad--;
      }

      return edad;
   };

   const [visibleCrear, setVisibleCrear] = useState(false);
   const [checked, setChecked] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);
   const actualizarEstado = () => {
      let actualizacion = stateAtencion.map((area, index) => {
         if (pageViewContext === index) {
            return { estado: 2, titulo: area.titulo, icon: area.icon };
         } else {
            return area;
         }
      });
      setStateAtencion(actualizacion);
      // setModalVisible(true);
   };
   const hideModal = () => {
      setModalVisible(false);
   };
   const navigate = useNavigate();
   const handleSaveConfirmation = () => {
      setModalVisible(false);
      navigate(-1);
   };
   const [showNoAtendidoModal, setShowNoAtendidoModal] = useState(false);
   const handleNoAtendidoClick = () => {
      setShowNoAtendidoModal(true);
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };

   const getPacientes = () => {
      http
         .get("clinica/recepcion/pacientes")
         .then((response) => {
            setPacientes(response.data.data);
            setOriginalPacientes(response.data.data);
            setKey(key + 1);
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   const getAllUbicaciones = () => {
      http
         .get("/ubicacion")
         .then((response) => {
            setUbicaciones(response.data.data);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      getPacientes();
      getAllUbicaciones();
   }, []);

   return (
      <div className="Contenedor_DATOS_DEl_PaciENTE">
         <div className="cardIMG">
            <div className="card-image"></div>
            <div className="flex flex-column w-full align-items-center gap-3">
               <div className="flex gap-3" style={{ marginTop: "80px" }}>
                  {/* <div className='flex justify-content-evenly' style={{ background: '#BBF6DD', borderRadius: '50px', padding: '5px', fontWeight: '700', fontSize: '12px', color: '#00A15C', width: '113px' }}>
              <input onClick={() => CambiarEstado(2)} type="radio" id="opcion1" name="opciones" value="opcion1" checked={stateAtencion[pageViewContext].estado === 3} disabled={stateAtencion[pageViewContext].estado === 3}/>
              <label for="opcion1">Atendido</label>
            </div> */}
                  <div
                     className="flex justify-content-evenly"
                     style={{
                        background: "#BBF6DD",
                        borderRadius: "50px",
                        padding: "5px",
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#00A15C",
                        width: "113px",
                     }}
                  >
                     <input
                        onClick={actualizarEstado}
                        type="radio"
                        id="opcion1"
                        name="opciones"
                        value="opcion1"
                        defaultChecked={
                           stateAtencion[pageViewContext].estado === 3
                        }
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                     <label htmlFor="opcion1">Atendido</label>
                  </div>
                  {/* <div className='flex justify-content-evenly' style={{ background: '#FFECEC', borderRadius: '50px', padding: '5px', fontWeight: '700', fontSize: '12px', color: '#FF6767', width: '113px' }}>
              <input type="radio" id="opcion2" name="opciones" value="opcion2" disabled={stateAtencion[pageViewContext].estado === 3} />
              <label for="opcion2">No atendido</label>
            </div> */}
                  <div
                     className="flex justify-content-evenly"
                     style={{
                        background: "#FFECEC",
                        borderRadius: "50px",
                        padding: "5px",
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#FF6767",
                        width: "113px",
                     }}
                  >
                     <input
                        onClick={handleNoAtendidoClick}
                        type="radio"
                        id="opcion2"
                        name="opciones"
                        value="opcion2"
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                     <label htmlFor="opcion2">No atendido</label>
                  </div>
               </div>

               <div className="flex flex-row w-full justify-content-center">
                  <span
                     style={{
                        color: "#5555D8",
                        fontWeight: "700",
                        fontSize: "18px",
                        marginTop: "20px",
                     }}
                  >
                     Datos del paciente
                  </span>
               </div>

               <div
                  className="flex gap-3"
                  style={{ marginTop: "12px", fontFamily: "Montserrat" }}
               >
                  <div
                     className="flex justify-content-evenly"
                     style={{
                        padding: "3px",
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#363E4B",
                        width: "113px",
                     }}
                  >
                     <label htmlFor="opcion1">Código</label>
                  </div>
                  <div
                     className="flex justify-content-evenly"
                     style={{
                        padding: "3px",
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#363E4B",
                        width: "113px",
                     }}
                  >
                     <label htmlFor="opcion2">N° de atención</label>
                  </div>
               </div>

               <div className="flex gap-3" style={{ marginTop: "0px" }}>
                  <div
                     className="flex justify-content-evenly"
                     style={{
                        padding: "0px",
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#363E4B",
                        width: "100px",
                        paddingRight: "30px",
                     }}
                  >
                     {pacienteContext === null ? (
                        <div className="pfv_form_input_skeleton">
                           <Skeleton
                              className="pfv_form_input_skeleton"
                              height="21px"
                              width="88.95px"
                           ></Skeleton>
                        </div>
                     ) : (
                        <div className="pfv_form_input_value">
                           <span>{pacienteContext?.codigo}</span>
                        </div>
                     )}
                  </div>
                  <div
                     className="flex justify-content-evenly"
                     style={{
                        padding: "0px",
                        fontWeight: "700",
                        fontSize: "12px",
                        color: "#363E4B",
                        width: "100px",
                     }}
                  >
                     {pacienteContext === null ? (
                        <div className="pfv_form_input_skeleton">
                           <Skeleton
                              className="pfv_form_input_skeleton"
                              height="21px"
                              width="88.95px"
                           ></Skeleton>
                        </div>
                     ) : (
                        <div className="pfv_form_input_value">
                           <span>{pacienteContext?.atencion_id}</span>
                        </div>
                     )}
                  </div>
               </div>

               {/* <div className='flex gap-3' style={{ marginTop: '80px' }}>
            <div className="ConedorDatosDelPacienteInputs pfv_form_input">
              <div className='flex justify-content-evenly'>
                <img src={MIChi} alt="" />
                <label>Código</label>
              </div>
              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton">
                    <Skeleton className="pfv_form_input_skeleton" height="21px" width="90px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.persona_id}</span>
                  </div>
              }

              <div className='flex justify-content-evenly'>
                <img src={MIChi} alt="" />
                <label>N° de atención</label>
              </div>
              {
                pacienteContext === null
                  ?
                  <div className="pfv_form_input_skeleton">
                    <Skeleton className="pfv_form_input_skeleton" height="21px" width="90px"></Skeleton>
                  </div>
                  :
                  <div className="pfv_form_input_value">
                    <span>{pacienteContext?.num_atencion}</span>
                  </div>
              }
            </div>
          </div> */}

               <div className="flex flex-column gap-1 contenedor-nom-ape2 w-11 border-round-lg p-3">
                  <span
                     className="pl-3"
                     style={{
                        color: "#5555D8",
                        fontWeight: "500",
                        fontSize: "13px",
                        fontFamily: "Montserrat",
                     }}
                  >
                     Nombres y apellidos
                  </span>
                  {pacienteContext === null ? (
                     <Skeleton className="" height="21px"></Skeleton>
                  ) : (
                     <span
                        className="text-left px-4"
                        style={{ color: "#6f6f6f" }}
                     >
                        {pacienteContext?.nombres ?? ""}{" "}
                        {pacienteContext?.apellido_paterno ?? ""}{" "}
                        {pacienteContext?.apellido_materno ?? ""}
                     </span>
                  )}
               </div>

               {/* <div className="ConedorDatosDelPacienteInputs pfv_form_input">
            <div className="pfv_form_input_name">
              <img src={T3rcero} alt="" />
              <label>Ocupación</label>
            </div>

            {
              pacienteContext === null
                ?
                <div className="pfv_form_input_skeleton">
                  <Skeleton className="pfv_form_input_skeleton" height="21px"></Skeleton>
                </div>
                :
                <div className="pfv_form_input_value">
                  <span>{pacienteContext?.ocupacion}</span>
                </div>
            }
          </div> */}

               <div className="div-datos-paciente2">
                  <div className="ConedorDatosDelPacienteInputs pfv_form_input">
                     <div className="pfv_form_input_name1">
                        <img
                           className="iconos"
                           src="https://cdn.discordapp.com/attachments/1078045652904325191/1151248640157954148/image.png"
                           alt=""
                        />
                        <label>Edad</label>
                     </div>

                     {pacienteContext === null ? (
                        <div className="pfv_form_input_skeleton1">
                           <Skeleton
                              className="pfv_form_input_skeleton"
                              height="21px"
                           ></Skeleton>
                        </div>
                     ) : (
                        <div className="pfv_form_input_value">
                           <span>
                              {Number.isNaN(
                                 calcularEdad(pacienteContext?.fecha_nacimiento)
                              )
                                 ? "N/A"
                                 : calcularEdad(
                                      pacienteContext?.fecha_nacimiento
                                   )}
                           </span>
                        </div>
                     )}
                  </div>

                  <div className="ConedorDatosDelPacienteInputs pfv_form_input">
                     <div className="pfv_form_input_name1">
                        <img
                           className="iconos"
                           src="https://cdn.discordapp.com/attachments/1078045652904325191/1151249537885806744/image.png"
                           alt=""
                        />
                        <label>Plan de Atención</label>
                     </div>

                     {pacienteContext === null ? (
                        <div className="pfv_form_input_skeleton1">
                           <Skeleton
                              className="pfv_form_input_skeleton"
                              height="21px"
                           ></Skeleton>
                        </div>
                     ) : (
                        <div className="pfv_form_input_value">
                           <span>{pacienteContext?.plan_atencion}</span>
                        </div>
                     )}
                  </div>

                  <div className="ConedorDatosDelPacienteInputs pfv_form_input w-full">
                     <div className="pfv_form_input_name1">
                        <img
                           className="iconos"
                           src="https://cdn.discordapp.com/attachments/1078045652904325191/1151249916698566756/image.png"
                           alt=""
                        />
                        <label>Fecha de Evaluación</label>
                     </div>

                     {pacienteContext === null ? (
                        <div className="pfv_form_input_skeleton1">
                           <Skeleton
                              className="pfv_form_input_skeleton"
                              height="21px"
                           ></Skeleton>
                        </div>
                     ) : (
                        <div className="pfv_form_input_value">
                           <span>{pacienteContext?.fecha}</span>
                        </div>
                     )}
                  </div>

                  <div className="ConedorDatosDelPacienteInputs pfv_form_input">
                     <div className="pfv_form_input_name1">
                        <img
                           className="iconos"
                           src="https://cdn.discordapp.com/attachments/1078045652904325191/1151250083241807912/image.png"
                           alt=""
                        />
                        <label>Tipo de Evaluación</label>
                     </div>

                     {pacienteContext === null ? (
                        <div className="pfv_form_input_skeleton1">
                           <Skeleton
                              className="pfv_form_input_skeleton"
                              height="21px"
                           ></Skeleton>
                        </div>
                     ) : (
                        <div className="pfv_form_input_value">
                           <span>{pacienteContext?.tipo_evaluacion}</span>
                        </div>
                     )}
                  </div>
                  {/* <div className='contebot'>
              <button className="boton">Ver más</button>
            </div> */}
               </div>
            </div>
         </div>
         <div className="flex" style={{ marginTop: "30px", width: "100%" }}>
            <Button
               label="Ver más"
               style={{
                  height: "32px",
                  width: "100%",
                  borderRadius: "0px 0px 20px 20px ",
               }}
               onClick={() => setVisibleCrear(true)}
            />
         </div>

         <ModalActulizarDatos
            show={visibleCrear}
            setShow={setVisibleCrear}
            data={atencionContext}
            getPacientes={getPacientes}
            ubicaiones={ubicaiones}
            nombre="Datos generales del paciente"
            estado={1}
            disabled={true}
         />
         {/*  <ModalCrear
            setVisibleCrear={setVisibleCrear}
            visibleCrear={visibleCrear}
            checked={checked}
            setChecked={setChecked}
         /> */}
         <ModalAtencionAtendida
            visible={modalVisible}
            Atencion={stateAtencion[pageViewContext].titulo}
            onHide={hideModal}
            onYes={handleSaveConfirmation}
            onNo={hideModal}
         />
         <ModalNoAtendido
            visible={showNoAtendidoModal}
            onHide={() => setShowNoAtendidoModal(false)}
            onYes={() => setShowNoAtendidoModal(false)}
            onNo={() => setShowNoAtendidoModal(false)}
         />
      </div>
   );
};

export default RAAtencionFormView;

import React, { useContext, useEffect, useState } from "react";
import { AudContext } from "../AudContext";
import { Dropdown } from "primereact/dropdown";
import TablaListAudiograma from "./Tabla/TablaListAudiogramas";
import VoiceInputTextarea from "../../../Components/ButtonVoice/VoiceButton";
import "../../../Style/aud_principal.css";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";

const PageAudPrincipal = () => {
   let { principalContext, setPrincipalContext } = useContext(AudContext);

   const optionsCae = [
      { name: "Permeable", id: "1" },
      { name: "Cerumen Parcial", id: "2" },
      { name: "Tapón de cerumen", id: "3" },
      { name: "Cuerpo extraño", id: "4" },
      { name: "Agenesia de conducto auditivo", id: "5" },
   ];

   const optionsTimpanos = [
      { name: "Integro", id: "1" },
      { name: "Perforado", id: "2" },
      { name: "No visible", id: "3" },
   ];

   const optionsAudicion = [
      { name: "Normal", id: "1" },
      { name: "Alterado", id: "2" },
   ];

   const optionsOtoscopia = [
      { name: "Permeable, tímpano íntegro", id: "1" },
      { name: "Cerumen parcial", id: "2" },
      { name: "Tapón de cerumen", id: "3" },
      { name: "Cuerpo extraño", id: "4" },
      { name: "Agnesia de conducto auditivo", id: "5" },
      { name: "Permeable, tímpano perforado", id: "6" },
   ];

   const optionsInterpretacion = [
      { name: "Normoacusia", id: "1" },
      { name: "D/C Trauma acústico leve", id: "2" },
      { name: "D/C Trauma acústico avanzado", id: "3" },
      { name: "D/C Hipoacusia inducida por ruido leve", id: "4" },
      { name: "D/C Hipoacusia inducida por ruido moderado", id: "5" },
      { name: "D/C Hipoacusia inducida por ruido avanzada", id: "6" },
      { name: "D/C otras alteraciones no debida a ruido", id: "7" },
      { name: "Hipoacusia conductiva leve", id: "8" },
      { name: "Hipoacusia conductiva moderada", id: "9" },
      { name: "Hipoacusia conductiva severa", id: "10" },
      { name: "Hipoacusia conductiva profunda", id: "11" },
      { name: "Hipoacusia neurosensorial leve", id: "12" },
      { name: "Hipoacusia neurosensorial moderada", id: "13" },
      { name: "Hipoacusia neurosensorial severa", id: "14" },
      { name: "Hipoacusia neurosensorial profunda", id: "15" },
      { name: "Hipoacusia mixta leve", id: "16" },
      { name: "Hipoacusia mixta moderada", id: "17" },
      { name: "Hipoacusia mixta severa", id: "18" },
      { name: "Hipoacusia mixta profunda", id: "19" },
   ];
   const [caeDer, setCaeDer] = useState(
      principalContext?.AudCae?.oido_derecho ?? null
   );
   const [caeIzq, setCaeIzq] = useState(
      principalContext?.AudCae?.oido_izquierdo ?? null
   );
   const [timpanosDer, setTimpanosDer] = useState(
      principalContext?.AudTimpanos?.oido_derecho ?? null
   );
   const [timpanosIzq, setTimpanosIzq] = useState(
      principalContext?.AudTimpanos?.oido_izquierdo ?? null
   );
   const [audicionDer, setAudicionDer] = useState(
      principalContext?.AudAudicion?.oido_derecho ?? null
   );
   const [audicionIzq, setAudicionIzq] = useState(
      principalContext?.AudAudicion?.oido_izquierdo ?? null
   );
   const [otoscopiaDer, setOtoscopiaDer] = useState(
      principalContext?.AudOtoscopia?.oido_derecho ?? null
   );
   const [otoscopiaIzq, setOtoscopiaIzq] = useState(
      principalContext?.AudOtoscopia?.oido_izquierdo ?? null
   );
   const [interpretDer, setInterpretDer] = useState(
      principalContext?.AudInterpretacion?.oido_derecho ?? null
   );
   const [interpretIzq, setInterpretIzq] = useState(
      principalContext?.AudInterpretacion?.oido_izquierdo ?? null
   );
   const [metodoClasif, setMetodoClasif] = useState(
      principalContext?.metodo_clasificacion ?? null
   );
   const [observaciones, setObservaciones] = useState(
      principalContext?.observaciones ?? null
   );

   useEffect(() => {
      if (principalContext?.AudCae?.oido_derecho) {
         setCaeDer(principalContext?.AudCae?.oido_derecho);
      }
      if (principalContext?.AudCae?.oido_izquierdo) {
         setCaeIzq(principalContext?.AudCae?.oido_izquierdo);
      }
      if (principalContext?.AudTimpanos?.oido_derecho) {
         setTimpanosDer(principalContext?.AudTimpanos?.oido_derecho);
      }
      if (principalContext?.AudTimpanos?.oido_izquierdo) {
         setTimpanosIzq(principalContext?.AudTimpanos?.oido_izquierdo);
      }
      if (principalContext?.AudAudicion?.oido_derecho) {
         setAudicionDer(principalContext?.AudAudicion?.oido_derecho);
      }
      if (principalContext?.AudAudicion?.oido_izquierdo) {
         setAudicionIzq(principalContext?.AudAudicion?.oido_izquierdo);
      }
      if (principalContext?.AudOtoscopia?.oido_derecho) {
         setOtoscopiaDer(principalContext?.AudOtoscopia?.oido_derecho);
      }
      if (principalContext?.AudOtoscopia?.oido_izquierdo) {
         setOtoscopiaIzq(principalContext?.AudOtoscopia?.oido_izquierdo);
      }
      if (principalContext?.AudInterpretacion?.oido_derecho) {
         setInterpretDer(principalContext?.AudInterpretacion?.oido_derecho);
      }
      if (principalContext?.AudInterpretacion?.oido_izquierdo) {
         setInterpretIzq(principalContext?.AudInterpretacion?.oido_izquierdo);
      }
      if (principalContext?.metodo_clasificacion) {
         setMetodoClasif(principalContext?.metodo_clasificacion);
      }
      if (principalContext?.observaciones) {
         setObservaciones(principalContext?.observaciones);
      }
   }, [
      principalContext?.AudCae?.oido_derecho,
      principalContext?.AudCae?.oido_izquierdo,
      principalContext?.AudTimpanos?.oido_derecho,
      principalContext?.AudTimpanos?.oido_izquierdo,
      principalContext?.AudAudicion?.oido_derecho,
      principalContext?.AudAudicion?.oido_izquierdo,
      principalContext?.AudOtoscopia?.oido_derecho,
      principalContext?.AudOtoscopia?.oido_izquierdo,
      principalContext?.AudInterpretacion?.oido_derecho,
      principalContext?.AudInterpretacion?.oido_izquierdo,
      principalContext?.metodo_clasificacion,
      principalContext?.observaciones,
   ]);

   useEffect(() => {
      setPrincipalContext({
         AudCae: {
            oido_derecho: caeDer,
            oido_izquierdo: caeIzq,
         },
         AudTimpanos: {
            oido_derecho: timpanosDer,
            oido_izquierdo: timpanosIzq,
         },
         AudAudicion: {
            oido_derecho: audicionDer,
            oido_izquierdo: audicionIzq,
         },
         AudOtoscopia: {
            oido_derecho: otoscopiaDer,
            oido_izquierdo: otoscopiaIzq,
         },
         AudInterpretacion: {
            oido_derecho: interpretDer,
            oido_izquierdo: interpretIzq,
         },
         observaciones: observaciones,
         metodo_clasificacion: metodoClasif,
         audiograma: principalContext.audiograma,
      });

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      caeDer,
      caeIzq,
      timpanosDer,
      timpanosIzq,
      audicionDer,
      audicionIzq,
      otoscopiaDer,
      otoscopiaIzq,
      interpretDer,
      interpretIzq,
      metodoClasif,
      observaciones,
   ]);

   const FlexCenter = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
   };

   return (
      <div className="flex flex-column gap-3">
         <div className="flex">
            <div
               className="flex"
               style={{
                  height: "30px",
                  width: "30px",
                  backgroundColor: "#eaeafd",
                  borderRadius: "50%",
                  ...FlexCenter,
                  marginRight: "10px",
               }}
            >
               <img
                  src="/svg_icons/BG_ICN_AUDIOMETRÍA.svg"
                  alt="Icon"
                  style={{ width: "18px", height: "auto" }}
               />
            </div>
            <h3>Principal</h3>
         </div>
         <div className="contenedor_general_Cuadros_Pri">
            {/* hiroshi */}
            <div
               className="contenedorCae cuadros_individuales"
               id="miContenedor"
            >
               <div className="encabezadoCae">
                  <span>CAE</span>
               </div>
               <div className="CuerpoCae flex flex-column gap-3">
                  <div
                     className="flex flex-row gap-2"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.D.</span>
                     <Dropdown
                        value={caeDer}
                        onChange={(e) => setCaeDer(e.value)}
                        placeholder="Seleccione una opción"
                        options={optionsCae}
                        optionLabel="name"
                        optionValue="id"
                        className="w-full"
                     />
                  </div>
                  <div
                     className="flex flex-row gap-3"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.I.</span>
                     <Dropdown
                        value={caeIzq}
                        onChange={(e) => setCaeIzq(e.value)}
                        placeholder="Seleccione una opción"
                        options={optionsCae}
                        optionLabel="name"
                        optionValue="id"
                        className="w-full"
                     />
                  </div>
               </div>
            </div>
            <div
               className="contenedorCae cuadros_individuales"
               id="miContenedortimpano"
            >
               <div className="encabezadoCae">
                  <span>Tímpanos</span>
               </div>
               <div className="CuerpoCae flex flex-column gap-3">
                  <div
                     className="flex flex-row gap-2"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.D.</span>
                     <Dropdown
                        value={timpanosDer}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsTimpanos}
                        onChange={(e) => setTimpanosDer(e.value)}
                        className="w-full"
                     />
                  </div>
                  <div
                     className="flex flex-row gap-3"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.I.</span>
                     <Dropdown
                        value={timpanosIzq}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsTimpanos}
                        onChange={(e) => setTimpanosIzq(e.value)}
                        className="w-full"
                     />
                  </div>
               </div>
            </div>
            <div
               className="contenedorCae cuadros_individuales"
               id="miContenedoraudicion"
            >
               <div className="encabezadoCae">
                  <span>Audición</span>
               </div>
               <div className="CuerpoCae flex flex-column gap-3">
                  <div
                     className="flex flex-row gap-2"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.D.</span>
                     <Dropdown
                        value={audicionDer}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsAudicion}
                        onChange={(e) => setAudicionDer(e.value)}
                        className="w-full"
                     />
                  </div>
                  <div
                     className="flex flex-row gap-3"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.I.</span>
                     <Dropdown
                        value={audicionIzq}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsAudicion}
                        onChange={(e) => setAudicionIzq(e.value)}
                        className="w-full"
                     />
                  </div>
               </div>
            </div>
            <div
               className="contenedorCae cuadros_individuales"
               id="miContenedorotoscopia"
            >
               <div className="encabezadoCae">
                  <span>Otoscopia</span>
               </div>
               <div className="CuerpoCae flex flex-column gap-3">
                  <div
                     className="flex flex-row gap-2"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.D.</span>
                     <Dropdown
                        value={otoscopiaDer}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsOtoscopia}
                        onChange={(e) => setOtoscopiaDer(e.value)}
                        className="w-full"
                     />
                  </div>
                  <div
                     className="flex flex-row gap-3"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.I.</span>
                     <Dropdown
                        value={otoscopiaIzq}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsOtoscopia}
                        onChange={(e) => setOtoscopiaIzq(e.value)}
                        className="w-full"
                     />
                  </div>
               </div>
            </div>
            <div
               className="contenedorCae cuadros_individuales"
               id="miContenedorinteerpretacion"
            >
               <div className="encabezadoCae">
                  <span>Interpretación (Impresión dx)</span>
               </div>
               <div className="CuerpoCae flex flex-column gap-3">
                  <div
                     className="flex flex-row gap-2"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.D.</span>
                     <Dropdown
                        value={interpretDer}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsInterpretacion}
                        onChange={(e) => setInterpretDer(e.value)}
                        className="w-full"
                        disabled

                        // Aquí se establece la opción disabled para desactivar el Dropdown
                     />
                  </div>
                  <div
                     className="flex flex-row gap-3"
                     style={{ alignItems: "center" }}
                  >
                     <span> O.I.</span>
                     <Dropdown
                        value={interpretIzq}
                        placeholder="Seleccione una opción"
                        optionLabel="name"
                        optionValue="id"
                        options={optionsInterpretacion}
                        onChange={(e) => setInterpretIzq(e.value)}
                        className="w-full"
                        disabled
                        // Aquí se establece la opción disabled para desactivar el Dropdown
                     />
                  </div>
               </div>
            </div>

            <div className="cuadros_individuales cuadros_individuales02">
               <div className="flex-row" style={{ marginBottom: "10px" }}>
                  <label className="subtituloaudiometria">
                     Observaciones / Recomendaciones
                  </label>
               </div>

               <VoiceButton
                  value={observaciones}
                  onChange={setObservaciones}
                  placeholder="Ingrese una observación"
               />

               <div className="flex-row" style={{ margin: "10px 0px" }}>
                  <label className="subtituloaudiometria">
                     Método de clasificación audiométrica
                  </label>
               </div>
               <VoiceButton
                  value={metodoClasif}
                  onChange={setMetodoClasif}
                  placeholder="Ingrese el método de clasificación"
               />
            </div>
         </div>

         <h3>Lista de audiogramas</h3>
         {/* tabla no funcional por el momento */}
         <TablaListAudiograma />
      </div>
   );
};

export default PageAudPrincipal;

import React from "react";
import { Dialog } from "primereact/dialog";

import { Footer, Header } from "../../../Pure/SeccionesNavegacion";
import { useDispatch } from "react-redux";
import AuthUser from "../../../../AuthUser";
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";

export default function ModalEliminarRecluta({
  visibleEliminar,
  showToast,
  setVisibleEliminar,
  setPersonal,
  getPersonal,
  api
}) {
  const dispatch = useDispatch();
  const { http } = AuthUser();
  const HideDialogEliminar = () => {
    setVisibleEliminar(false);
  };

  const DeleteMiPersonal = async (id) => {
    dispatch(startLoading())
    try {
      const response = await http.delete(`${api}/${id}`)
      if (response.data.resp) {
        await Promise.all([
          HideDialogEliminar(),
          getPersonal()
        ])
        showToast(
          "success",
          "Eliminado correctamente",
          "Se eliminó correctamente el personal"
        );
      } else if (response?.data?.error) {
        showToast('error', 'Error al eliminar recluta', response?.data?.error)
      }
    } catch (error) {
      showToast(
        "error",
        "El recluta no ha sido eliminado",
        "Contacte a soporte técnico"
      );
      console.error('Error al eliminar recluta', error)
    } finally {
      dispatch(endLoading())
    }
  }

  const header = (<Header titulo={'Eliminar Personal'} piIcon={'pi pi-trash'} />)

  const footer = (<Footer
    label={'Eliminar'}
    onClickEnviar={() => {
      DeleteMiPersonal(setPersonal.id);
    }}
    onClickCancelar={() => {
      HideDialogEliminar();
    }} />)

  return (
    <div>
      <form
        id="personal-form-delete"
        className="personal-form-delete"
        noValidate
      >
        <Dialog
          resizable={false}
          visible={visibleEliminar}
          style={{ width: "450px", margin: "5%" }}
          //appendTo={document.getElementById("personal-form-delete")}
          header={header}
          footer={footer}
          onHide={() => {
            HideDialogEliminar();
          }}
        >
          <div className="flex flex-column gap-3">
            <div className="flex flex-column w-full">
              <p style={{ fontSize: "20px" }} className=" m-0" >¿Está seguro que desea eliminar personal?</p>
            </div>
          </div>
        </Dialog>
      </form>
    </div>
  );
}
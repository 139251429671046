import React from 'react';
import '../../../Style/oft_agud_visual.css'
import ParteInferiorAdgudezaVisual from './ParteinferiorAgudeza';
import ParteSuperiorAdgudezaVisual from './ParteSuperior/ParteSuperior';
import LadoIntemedio from './ParteSuperior/Intermedio';
import { iconOft } from "../../../Services/importIcons";

const PageOftAgud = () => {

   return (
      <div className='flex flex-column gap-3'>
         <div className="oft_ant_titles" style={{ marginBottom: "20px" }}>
            <div>
               <img src={iconOft} alt="Icon" />
            </div>
            <h3>Agudeza Visual</h3>
         </div>
         <ParteSuperiorAdgudezaVisual />
         <LadoIntemedio />
         <ParteInferiorAdgudezaVisual />
      </div>
   );
};

export default PageOftAgud;
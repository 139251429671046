
import React from 'react';
import { Button } from 'primereact/button';
import "../../../Style/Reclutamiento/CSSCard.css";

export default function BotonDescargar() {
    return (
        <div className="flex justify-content-center">
            <Button label="Descargar" icon="pi pi-download" className='BOTON_DEScARGAR p-button-text' style={{ height: '32px' }} />
        </div>
    )
}

import React, { useState, useRef } from 'react';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from "primereact/button";
import DatosPaciente from "./DatosPaciente";
import "../../../Style/RutaAtencionAtencion/style.css";



const ModalCrear = ({ setVisibleCrear, visibleCrear }) => {
    const [isSaved, setIsSaved] = useState(false);
    const handleGuardar = () => {
        setIsSaved(true)
        setVisibleCrear(false);
    }

    const [activeIndex, setActiveIndex] = useState(1);
    const [activeStep, setActiveStep] = useState(0);


    const toast = useRef(null);
    const items = [
        // {
        //     label: 'Datos Generales',
        // },
        // {
        //     label: 'Datos del Paciente',
        // },
        // {
        //     label: 'Firma y Huella',
        // },
    ];


    const HideDialogCrear = () => {
        setVisibleCrear(false);
    };


    const handleStepChange = (index) => {
        setActiveIndex(index);
        setActiveStep(index);
    };

    const paqueteCreateDialogFooter = (
        <div className="flex w-full flex-wrap justify-content-end ">
            <div className="flex w-2 flex-wrap flex-column gap-2 pt-3">
                <Button
                    type="submit"
                    label="Guardar"
                    className=" w-12 pt-3 pb-3"
                    style={{
                        backgroundColor: "#5555D8",
                        borderColor: "#5555D8"
                    }}
                    onClick={handleGuardar}
                />
                {/* <Button
                    type="button"
                    label="Siguiente"
                    className="boton01 w-12 pt-3 pb-3"
                    onClick={() => {
                    }}
                /> */}
            </div>
        </div>

    )
    return (
        <div>
            <form>
                <Dialog
                    visible={visibleCrear}
                    style={{
                        width: "80%",
                        height: "95%",
                        borderRadius: '25px'
                    }}
                    onHide={() => {
                        HideDialogCrear();
                    }}
                    footer={paqueteCreateDialogFooter}
                    header={
                        
                            <div className="flex flex-wrap flex-column gap-3 pl-2" style={{height: "50px"}}>
                                <div
                                    className="flex justify-content-center align-items-center"
                                    style={{
                                        backgroundColor: "#EBEBFF",
                                        width: "300px",
                                        height: "50px",
                                        borderRadius: "10px",
                                    }}
                                >
                                    <img
                                        src="https://cdn.discordapp.com/attachments/1078050726217011331/1090321659044573235/Group_1920.png"
                                        alt=""
                                    />
                                    <h1 className="tituloGeneral w-full" style={{marginLeft: '30px', paddingTop: '125px'}}>Datos del Paciente</h1>
                                </div>
                                <div className="barra_c">
                                    
                                    <Toast ref={toast}></Toast>
                                    <Steps
                                        model={items}
                                        activeIndex={activeIndex}
                                        onSelect={(e) => handleStepChange(e.index)}
                                        readOnly={false}
                                    />
                                </div>

                            </div>
                        
                    }>
                    {/* Renderiza hacia los componentes */}
                    {/* {activeStep === 0 && <DatosGenerales />} */}
                    {activeStep === 0 && <DatosPaciente />}
                    {/* {activeStep === 2 && <FirmaHuella />} */}

                    {/* <div>
                        <TabView>
                            <TabPanel header="Datos del paciente">
                                <DatosPaciente
                                isSaved={isSaved}
                                setIsSaved={setIsSaved}
                                />
                                
                                <DatosPaciente/>
                            </TabPanel>

                            <TabPanel header="Firma y huella">
                                <FirmaHuella
                                />
                            </TabPanel>
                        </TabView>
                    </div> */}
                </Dialog>
            </form>
        </div>
    )
}

export default ModalCrear;
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

//Componente general
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

//Imágenes
import pencil from "../../../../Images/roles/pencil.svg";

const EditRolModalComponent = ({
   rol,
   setRol,
   rolUpdate,
   visibleEdit,
   setVisibleEdit,
   getRoles,
}) => {
   //Variables de estado
   const [FaltaNombreEditar, setFaltaNombreEditar] = useState(false);

   //Funciones
   const hideDialogEdit = () => {
      setVisibleEdit(false);
   };

   const handleEditRol = () => {
      //Envía datos a la api de actualización
      rolUpdate(rol);
      //Obtiene los roles actualizados
      getRoles();
      //Cierra el modal
      setVisibleEdit(false);
   };

   const VerificarInputEditar = () => {
      // Inicializa la variable de verificación como verdadera
      var verifi = true;

      // Obtiene el valor del campo de entrada con el id 'edit-rol-nombre'
      var inputEditarName = document.getElementById('edit-rol-nombre').value;

      // Verifica si el campo de entrada está vacío o es nulo
      if (inputEditarName === "" || inputEditarName == null) {
         // Si está vacío o es nulo, establece la variable de verificación como falsa
         verifi = false;
         // Actualiza el estado para indicar que falta el nombre
         setFaltaNombreEditar(true);
      }

      // Retorna el resultado de la verificación
      return verifi;
   }

   const EnvioUpdateeInputRol = () => {
      //Valida la función y envía el JSON
      if (VerificarInputEditar()) {
         handleEditRol();
      }
   }

   useEffect(() => {
      //Cierra el modal cuando visibleEdit se ejecuta
      setFaltaNombreEditar(false);
   }, [visibleEdit])

   return (
      <div className="tabla-rol">
         <Dialog
            className="tabla-rol"
            draggable={false}
            style={{ width: "507px", height: "auto" }}
            visible={visibleEdit}
            onHide={hideDialogEdit}
            header={<Header icono={pencil} titulo={"Editar Rol"} subtitulo={"En esta sección usted puede edita el rol."} />}
            footer={<Footer onClickEnviar={EnvioUpdateeInputRol} onClickCancelar={() => hideDialogEdit()} label={"Guardar"} />}
         >
            <div className="flex flex-column gap-2 relative pt-2">
               <InputText
                  id="edit-rol-nombre" // Identificador único del campo de entrada
                  name="edit-rol-nombre" // Nombre del campo de entrada
                  defaultValue={rol?.nombre} // Valor por defecto del campo de entrada, que es el nombre del rol actual
                  onChange={(e) => { setRol({ ...rol, nombre: e.target.value }); setFaltaNombreEditar(false)}} // Maneja el cambio de valor del campo de entrada
                  placeholder="Nuevo Rol" // Texto de marcador de posición que se muestra cuando el campo está vacío
                  className={`w-full InputRetraccionNumCuAP ${FaltaNombreEditar ? 'Select' : ''}`} // Clase CSS para el estilo del campo de entrada
               />
               <div className={`InputRetraccionNumCuAPDiv ${FaltaNombreEditar ? 'Select' : ''}`}></div>
               <p className={`InputRetraccionNumCuAPTextError ${FaltaNombreEditar ? 'Select' : ''}`}>Ingrese el nuevo rol</p>
            </div>
         </Dialog>
      </div>
   );
};

export default EditRolModalComponent;

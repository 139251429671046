import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import React, { useState,useContext,useEffect} from "react";
import { Button } from "primereact/button";
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import AuthUser from '../../../AuthUser';
import { LaboratorioContext } from '../../../Page/Laboratorio/LaboratorioProvider';

export default function ViewPerfilLipidico({tipoExamen, tipoPrueba, idLaboratorio, showToast}) {
    const { http } = AuthUser();
    const { examenesContext, setExamenesContext } = useContext(LaboratorioContext);
    const [prueba, setPrueba] = useState({})
    const [nombreArchivo, setNombreArchivo] = useState(null);
    const [file, setFile] = useState(null);
    const [indexSup, setIndexSup] = useState(0);
    const [indexSub, setIndexSub] = useState(0);
    const [selectedConclusion, setSelectedConclusion] = useState(null);
    const [observaciones, setObservaciones] = useState('');
    const conclusion = [
        { name: 'Normal', code: 'NL' },
        { name: 'Anormal', code: 'AL' },
    ];

    const estiloA = {
        background: 'green',
        color: 'white'
    }
    const estiloB = {
        background: 'red',
        color: 'white'
    }

    const [parametros, setParametros] = useState
        ([
            {
                id: 1,
                prueba: 'Billirubina total',
                resultado: 0,
                um: 'mg/dl',
                rango_inicial: 0.3,
                rango_final: 1.2,
                valorNormal: '0.3 - 1.2 mg/dl'
            },
            {
                id: 2,
                prueba: 'Billirubina directa',
                resultado: 0,
                um: 'mg/dl',
                rango_inicial: 0.1,
                rango_final: 1.4,
                valorNormal: '0.1 - 1.4 mg/dl',
            },
            {
                id: 3,
                prueba: 'Billirubina indirecta',
                resultado: 0,
                um: 'mg/dl',
                rango_inicial: 0.2,
                rango_final: 0.8,
                valorNormal: '0.2 - 0.8 mg/dl',
            },
            {
                id: 4,
                prueba: 'Proteinas totales',
                resultado: 0,
                um: 'g/dl',
                rango_inicial: 6,
                rango_final: 8,
                valorNormal: '6.0 - 8.0 g/dl',
            },
            {
                id: 5,
                prueba: 'Albumina',
                resultado: 0,
                um: 'g/dl',
                rango_inicial: 3.5,
                rango_final: 5.5,
                valorNormal: '3.5 - 5.5 g/dl',
            },
            {
                id: 6,
                prueba: 'Globulina',
                resultado: 0,
                um: 'g/dl',
                rango_inicial: 0.5,
                rango_final: 3,
                valorNormal: '0.5 - 3.0 g/dl',
            },
            {
                id: 7,
                prueba: 'Fosfatasa alcalina',
                resultado: 0,
                um: 'Ul/L',
                rango_inicial: 30,
                rango_final: 250,
                valorNormal: '30 - 250 U/L Adulto',
            },
            {
                id: 8,
                prueba: 'TGO',
                resultado: 0,
                um: 'U/L',
                rango_inicial: 0,
                rango_final: 45,
                valorNormal: '0 - 45 U/L',
            },
            {
                id: 9,
                prueba: 'TGP',
                resultado: 0,
                um: 'U/L',
                rango_inicial: 0,
                rango_final: 45,
                valorNormal: '0 - 45 U/L',
            },
            {
                observaciones:"",
                seleccion:""
            }
        ]);

    const [inputValue, setInputValue] = useState('');

    const handleCreate = () => {
        const formData = new FormData();
        formData.append("examen_id", tipoExamen);
        formData.append("prueba[examen_id]", tipoPrueba);
        formData.append("prueba[estado]", 1);
        formData.append("prueba[perfil]", parametros?.length > 1 ? 2 : 1);
        formData.append("prueba[cantidad_parametros]", parametros?.length);
        formData.append("prueba[parametros]", JSON.stringify(parametros));
        formData.append("archivo", file ? file : (nombreArchivo ? prueba?.archivo : null));
  
        http.post(`laboratorio/prueba/create/${idLaboratorio}`, formData)
           .then(response => {
              
              showToast("success","Creado Correctamente",response?.data?.resp)
           })
           .catch(error => {
              console.error(error)
              showToast("Error","Error de Guardar",error?.data?.resp)
           })
     }

  
     const changeExamenContext = (superior, inferior, auxParametros) => {
        setExamenesContext(examenesContext.map((elementoSup, index) => {
           if (index === superior) {
              let auxPrueba = elementoSup.pruebas.map((prueba, indexAux) => {
                 if (indexAux === inferior) {
                    return {
                       ...prueba,
                       parametros: auxParametros,
                       estado: 1,
                       cantidad_parametros: auxParametros?.length,
                       perfil: auxParametros?.length > 1 ? 2 : 1
                    }
                 }
                 return prueba;
              })
              return { ...elementoSup, pruebas: auxPrueba };
           }
           return elementoSup;
        }))
     }


useEffect(() => {
    
    let sup = 0;
    let inf = 0;
    let auxPruebas = examenesContext.find((examen, index) => {
       if (examen.examen_id === tipoExamen) {
          setIndexSup(index);
          sup = index;
          
       }
       return examen.examen_id === tipoExamen
    });
    let auxPrueba = auxPruebas?.pruebas.find((prueba, index) => {
       if (prueba.examen_id === tipoPrueba) {
          setIndexSub(index);
          inf = index;
          
       }
       return prueba.examen_id === tipoPrueba
    });
    if (auxPrueba?.archivo) {
       setNombreArchivo(auxPrueba.archivo.substring(auxPrueba.archivo.lastIndexOf('/') + 1))
    }
    setPrueba(auxPrueba);
    
    
    let auxParametros = typeof auxPrueba?.parametros === 'string' ?  JSON.parse(auxPrueba?.parametros): auxPrueba?.parametros
   
    if (auxPrueba && auxParametros?.length > 0 ) {
        
        setParametros(auxParametros);
    }
    changeExamenContext(sup, inf, auxParametros);
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, []);
   
    const handleInputChange = (index, e) => {
        setInputValue(e.value);
        const tempTask = [...parametros];
        tempTask[index].resultado = e.value;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleInputInicial = (index, e) => {
        setInputValue(e.value);
        const tempTask = [...parametros];
        tempTask[index].rango_inicial = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleInputFinal = (index, e) => {
        setInputValue(e.value);

        const tempTask = [...parametros];
        tempTask[index].rango_final = e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
    };
    const handleObservaciones = (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].observaciones= e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
     const handleSelec= (index, e) => {
        const tempTask = [...parametros];
        tempTask[index].seleccion= e;
        setParametros(tempTask);
        changeExamenContext(indexSup, indexSub, tempTask);
     };
    return (
        <>
            <div className='p-5 h-full'>
                <table >
                    <thead>
                        <tr>
                            <th style={{ borderLeft: '0px', width: '20%', textAlign: "center" }}>Prueba</th>
                            <th style={{ width: '20%', textAlign: "center" }}>Resultado</th>
                            <th style={{ width: '20%', textAlign: "center" }}>U.M.</th>
                            <th style={{ width: '25%', textAlign: "center" }}>Rango</th>
                            <th style={{ textAlign: "center" }}>Valor Normal</th>
                        </tr>
                    </thead>
                        {
                        parametros.map((parametro, index) => {
                            if(index==9){
                                return null
                                
                            }else{
                                return (
                                    < tr>
                                        <td style={{ borderLeft: '0px' }}>{parametro.prueba}</td>
                                        <td><InputText
                                                inputStyle={
                                                    parametro.resultado >= parametro.rango_inicial &&
                                                        parametro.resultado <= parametro.rango_final ?
                                                        estiloA : estiloB
                                                }
                                                placeholder="Resultado"
                                                value={parametro.resultado}
                                                onChange={(e) => handleInputChange(index, e.target)}
                                                minFractionDigits={2} maxFractionDigits={5}
                                            /></td>
                                        <td>{parametro.um}</td>
                                        <td className="dato">
                                            <InputText className='w-3' value={parametro.rango_inicial} 
                                            onChange={(e) => handleInputInicial(index, e.target.value)}/>
                                            <span> - </span>
                                            <InputText className='w-3' value={parametro.rango_final} 
                                            onChange={(e) => handleInputFinal(index, e.target.value)}/>
                                        </td>
                                        <td>{parametro.valorNormal}</td>
                                    </tr>
                                    )

                            }
                           
                                })
                            }            
                    </table>
                    <div className='flex flex-column justify-content-around'>
                        <p style={{fontWeight:"bold"}}>Observaciones</p>
                        <InputTextarea
                            value={examenesContext?.[2]?.pruebas?.[9]?.parametros?.[9]?.observaciones}
                            onChange={(e) => handleObservaciones(9,e.target.value)}
                            placeholder='Observaciones' 
                            style={{width:"100%"}}/>
                    </div>
                    <div className='flex flex-block' style={{ marginTop: "10px", marginBottom: "10px" }}>
                        <p style={{ textAlign: "center", fontWeight: "bold", marginTop: "8px" }}>Conclusión Médica</p>
                        <Dropdown
                        value={conclusion[examenesContext?.[2]?.pruebas?.[9]?.parametros?.[9]?.seleccion=="NL"?0:1]}
                        onChange={(e) => handleSelec(9,e.value.code)}
                        options={conclusion}
                        optionLabel="name"
                        placeholder="Seleccione"
                        style={{ marginLeft: "10px", width: "200px" }} />
                    </div>
            
            <div className="flex flex-row gap-3 mt-2" style={{ justifyContent: 'end' }}>
                <Button label="Grabar" icon="fas fa-save" onClick={handleCreate}/>
                <Button label="Recuperar" icon="fas fa-undo" />
                {/* <Button label="Hematologo" icon="fas fa-user-md" /> */}
            </div>
        </div >
        </>
    );
}
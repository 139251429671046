import React, { useContext, useRef, useState, useEffect } from "react";
import { Toast } from "primereact/toast";
import { TabPanel, TabView } from "primereact/tabview";
import { TriajeContext } from "./TriajeContext";
import "../../Style/Triaje/triaje.css";
import { iconTriaje } from "../../Services/importIcons";
import PageTriajeSigVit from "./SignosVitales/PageTriajeSigVit";
import PageTriajePesTal from "./PesoTalla/PageTriajePesTal";
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
import ANTECEDENTEOCUPACIONAL from "../MedicinaGeneral/AntecedednteOcupacionales/Antececdente";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
import AuthUser from "../../AuthUser";
import { useParams } from "react-router-dom";
import { RAContext } from "../RutaAtencion/RAContext";
import ModalGuardarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalGuardarAtencion"
import ModalCancelarAtencion from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalCancelarAtencion";
import BottomsFootComponent from "../../Components/Clinica/AtencionMedica/pure/BottomsFoot";
import { startLoading, endLoading } from "../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

const PageTriaje = ({ revision }) => {
   const dispatch = useDispatch();

   const { http } = AuthUser();
   const { idPaciente } = useParams();
   const toast = useRef(null);

   let { sigVitContext, pesTalContext, setSigVitContext, AntOcupContext, setAntLabContext, setAtencionStatus, setTimeStart } = useContext(TriajeContext);
   let { stateAtencion, setStateAtencion, pageViewContext, triajeAreasContext, triajeDetalleId, setTriajeDetalleId } = useContext(RAContext); // 1. Importar RAContext

   const [visibleImprimir, setVisibleImprimir] = useState(false);
   const [visibleGuardar, setVisibleGuardar] = useState(false);
   const [visibleCancelar, setVisibleCancelar] = useState(false);
   const [documentos, setDocumentos] = useState([]);

   const [PesTall, setPesTall] = useState(null);
   const [Antocup, setAntocup] = useState(null);
   const [activeIndex, setActiveIndex] = useState(0);
   const [idTriaje, setIdTriaje] = useState(null);
   const [estado, setEstado] = useState(false);


   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };

   const CambiarEstado = (st) => {
      setStateAtencion((prevStates) =>
         prevStates.map((estado, index) =>
            index === pageViewContext ? { ...estado, estado: st } : estado
         )
      );
   };

   //INICIAR ATENCION
   const iniciarAtencion = (id, hora_inicio, fecha_inicio) => {
      let datasend = {
         hora_inicio: hora_inicio,
         fecha_inicio: fecha_inicio,
      }
      http.put(`clinica/hoja-ruta-detalle/iniciar/${id}`, datasend)
         .then(response => {
            
            setTriajeDetalleId({
               id: triajeDetalleId.id,
               estado: 1,
               hora_inicio: triajeDetalleId.hora_inicio,
               fecha_inicio: triajeDetalleId.fecha_inicio,
               hora_fin: triajeDetalleId.hora_fin,
               fecha_fin: triajeDetalleId.fecha_fin,
            })
         })
   }

   //FINALIZA LA ATENCIÓN
   const finalizarAtencion = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/finalizar/${id}`, datasend)
         .then(response => {
            
            setTriajeDetalleId({
               id: triajeDetalleId.id,
               estado: 2,
               hora_inicio: triajeDetalleId.hora_inicio,
               fecha_inicio: triajeDetalleId.fecha_inicio,
               hora_fin: triajeDetalleId.hora_fin,
               fecha_fin: triajeDetalleId.fecha_fin,
            })
            setEstado(!estado);
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
         })
   }
   const finalizarRevision = (id, hora_fin, fecha_fin) => {
      let datasend = {
         hora_fin: hora_fin,
         fecha_fin: fecha_fin,
      }
      http.put(`clinica/hoja-ruta-detalle/revision/finalizar/${id}`, datasend)
         .then(response => {
            if (response.data.resp) {
               setTriajeDetalleId({
                  id: triajeDetalleId.id,
                  estado: 2,
                  hora_inicio: triajeDetalleId.hora_inicio,
                  fecha_inicio: triajeDetalleId.fecha_inicio,
                  hora_fin: triajeDetalleId.hora_fin,
                  fecha_fin: triajeDetalleId.fecha_fin,
               })
               setEstado(!estado);
               showToast('success', 'Exito', response.data.resp);
            }
         })
         .catch(err => {
            console.error(err);
            setEstado(!estado);
            showToast('error', 'Error', "Algo salió mal");
         })
   }

   //CREAR EL ID DE TRIAJE
   const triajeCreate = async () => {
      await http
         .post("triaje/create", { atencion_id: idPaciente })
         .then((response) => {
            //SETEAR EL ID_TRIAJE
            setIdTriaje(response.data.triaje_id);
            showToast('success', 'Éxito', response.data.resp);

         }).catch((error) => {
            console.error(error);
            showToast('error', 'Error', error.message);
         });
   }

   //obtener la información del triaje del paciente
   const getPacienteTriaje = () => {
      http
         .get(`triaje/get/${idPaciente}`)
         .then((response) => {
            //SETEAR EL ID DE TRIAJE
            if (response?.data?.resp) {
               setIdTriaje(response?.data?.resp?.id);
               fetchGetAllData();
            }
            if (response.data.error) {
               dispatch(endLoading());
            }
         })
         .catch(err => {
            dispatch(endLoading());
            console.error("No hay triaje", err);
         });
   }

   // Funciones de crear
   const createPesoTalla =  async(data) => {
      await http
         .post(`triaje/pesotalla/create/${idTriaje}`, data)
         .then((response) => {
         })
         .catch(error => {
            console.error(error)
         })
   }
   const createSignosVitales = async(data) => {
      await http
         .post(`triaje/signosvitales/create/${idTriaje}`, data)
         .then((response) => {
         })
         .catch(error => {
            console.error(error)
         })
   }
   const createAntecedentesOcupacionales = async(data) => {
      await http
         .post(`triaje/antecedenteocupacional/create/${idTriaje}`, data)
         .then((response) => {
         })
         .catch(error => {
            console.error(error)
         })
   }

   //GUARDAR CAMBIOS DE TRIAJE
   const fetchAllData = async () => {
      try {
         await Promise.all([
            createPesoTalla(pesTalContext),
            createSignosVitales(sigVitContext),
            createAntecedentesOcupacionales(AntOcupContext),
         ])
         showToast(
            "success",
            `Atención en Psicología guardada`,
            `Se guardó la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }
   //Funciones de obtener
   const getAntOcup = async (id) => {
      await http
         .get(`triaje/antecedenteocupacional/get/${id}`)
         .then((response) => {
            console.error("response?.data?.antecedente_laboral", response)
            //SI LA RESPUESTA ES QUE NO HAY REGISTRO, NO SE SETEA NADA A ANTLABCONTEXT
            if (response?.data?.resp) {
               setAntocup(response?.data?.resp)
               setAntLabContext(response?.data?.resp?.antecedente_laboral)
            }
         }).catch((error) => {
            console.error("error al traer antecedenteocupacional", error)
         });
   }
   const getPestall = async (id) => {
      await http
         .get(`triaje/pesotalla/get/${id}`)
         .then((response) => {
            if (response?.data?.resp) {
               setPesTall(response?.data?.resp)
            }
         })
         .catch(err => console.error(err));
   }
   const getSigVit = async (id) => {
      await http
         .get(`triaje/signosvitales/get/${id}`)
         .then((response) => {
            if (response.data.resp) {
               setSigVitContext({
                  frec_cardiaca: response?.data?.resp?.frec_cardiaca,
                  frec_respiratoria: response?.data?.resp?.frec_respiratoria,
                  p_diastolica: response?.data?.resp?.p_diastolica,
                  p_media: response?.data?.resp?.p_media,
                  p_sistolica: response?.data?.resp?.p_sistolica,
                  saturacion: response?.data?.resp?.saturacion,
                  temperatura: response?.data?.resp?.temperatura,
                  observaciones: response?.data?.resp?.observaciones ?? ""
               });
            }
         })

         .catch(err => console.error(err));
   }

   const fetchGetAllData = async () => {
      try {
         await Promise.all([
            getSigVit(idPaciente),
            getPestall(idPaciente),
            getAntOcup(idPaciente),
         ])
         showToast(
            "success",
            `Atención en Obtenida`,
            `Datos traidos correctamente la atención correctamente`
         );
      } catch (error) {
         console.error(error)
      } finally {
         dispatch(endLoading());
      }
   }

   const guardarDatos = () => {
      dispatch(startLoading());
      setVisibleGuardar(false);
      fetchAllData();
   }

   const restablecerDatos = () => {
      dispatch(startLoading());
      setVisibleCancelar(false);
      fetchGetAllData();
   }

   const renderTabs = (area, key) => {
      const optionMapping = {
         2: (
            <TabPanel key={key} header="Peso y Talla">
               <PageTriajePesTal setPesTall={setPesTall} PesTall={PesTall} />
            </TabPanel>
         ),
         3: (
            <TabPanel key={key} header="Antecedentes Ocupacionales">
               <ANTECEDENTEOCUPACIONAL showToast={showToast} setAntocup={setAntocup} Antocup={Antocup} />
            </TabPanel>
         ),
         4: (
            <TabPanel key={key} header="Signos Vitales">
               <PageTriajeSigVit />
            </TabPanel>
         ),
         default: (
            <></>
         )
      };
      return optionMapping[area] || optionMapping?.default;
   };

   const getDocumentos = () => {
      http
         .get("documento/pdf/get")
         .then((response) => {
            setDocumentos(response.data.data)
         })
         .catch((error) => {
            console.error(error);
         });
   };

   useEffect(() => {
      dispatch(startLoading());
      getPacienteTriaje();
      getDocumentos();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   return (
      <>
         <Toast ref={toast} />
         {
            <TabView
               activeIndex={activeIndex}
               onTabChange={(e) => setActiveIndex(e.index)}
               scrollable
            >
               {
                  triajeAreasContext?.map((area, key) => {
                     return renderTabs(area, key)
                  })
               }
            </TabView>
         }
         <BottomsFootComponent
            estado={estado}
            setEstado={setEstado}
            setVisibleGuardar={setVisibleGuardar}
            setVisibleCancelar={setVisibleCancelar}
            setVisibleImprimir={setVisibleImprimir}
            revision={revision}
         />
         <ModalGuardarAtencion
            visible={visibleGuardar}
            Atencion="Triaje"
            onNo={() => setVisibleGuardar(false)}
            onYes={guardarDatos}
            onHide={() => setVisibleGuardar(false)}
         />
         <ModalCancelarAtencion
            visible={visibleCancelar}
            Atencion="Triaje"
            onNo={() => setVisibleCancelar(false)}
            onYes={restablecerDatos}
            onHide={() => setVisibleCancelar(false)}
         />
         <ModalImprimir
            imprimirVisible={visibleImprimir}
            setImprimirVisible={setVisibleImprimir}
            documentos={documentos}
            showToast={showToast}
         />

         {triajeDetalleId.estado === '0' && (
            <RATimeView
               title="Triaje"
               AreaDetalleId={triajeDetalleId?.id}
               icon={iconTriaje}
               setTimeStart={setTimeStart}
               setAtencionStatus={setAtencionStatus}
               iniciarAtencion={iniciarAtencion}
               startAtencion={CambiarEstado}
               createAtencion={triajeCreate}
               textButton='Iniciar atención'

            />
         )}

         {estado && revision === 1 && (
            <RATimeView
               title="Triaje"
               AreaDetalleId={triajeDetalleId?.id}
               finalizarAtencion={finalizarRevision}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar revisión'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={triajeDetalleId?.hora_final}
               VerHoraInicio={triajeDetalleId?.hora_inicio}
               VerfechaInicio={triajeDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
         {estado && revision !== 1 && (
            <RATimeView
               title="Triaje"
               AreaDetalleId={triajeDetalleId?.id}
               finalizarAtencion={finalizarAtencion}
               CancellAtencion={CambiarEstado}
               textButton='Finalizar atención'
               icon={stateAtencion[pageViewContext].icon}
               setTimeStart={setTimeStart}
               VerHoraFin={triajeDetalleId?.hora_final}
               VerHoraInicio={triajeDetalleId?.hora_inicio}
               VerfechaInicio={triajeDetalleId?.fecha_inicio}
               estado={estado}
               setEstado={setEstado}
            />
         )}
      </>
   );
};

export default PageTriaje;

import AuthUser from "../../../../../AuthUser";

const SB2Contact = () => {
   const { http } = AuthUser();
   const convert_form = (data) => {
      let contacto = new FormData()
      Object.keys(data)?.forEach((item) => {
         if (item === 'foto') {
            if (data[item] == null) {
               contacto.append("delete_image", "true")
            } else {
               contacto.append("delete_image", "false")
            }
         }
         if (item === 'celular') {
            if (data[item] == null) {
               contacto.append("celular", "null")
            } else {
               contacto.append("celular", data[item])
            }
         } else {
            contacto.append([item].toString(), data[item])

         }

      })
      return contacto

   }
   const getContactID = async (contact_id, setData, showToast) => {
      await http.get(`clinica/contacto/getcon/${contact_id}`).then((response) => {
         setData(response.data.data);
      }).catch((error) => {
         setData(error.response.data.resp);
      })
   };

   const getContact = async (clinic_id, showToast) => {
      try {
         const response = await http.get(`clinica/contacto/getcon/${clinic_id}`);
         if (response.data.data) {
            return response.data.data;
         } else {
            showToast && showToast("warn", "Advertencia", response.data.resp);
            return [];
         }
      } catch (error) {
         throw error;
      }
   };



   const postContact = async (clinic_id, formData, showToast) => {
      let contact = convert_form(formData)
      await http.post(`clinica/contacto/create/${clinic_id}`, contact).then(async (response) => {
         if (response.data.error) {
            showToast("error", "Error", response.data.error);
         } else if (response.data.resp.includes('Error')) {
            showToast("error", "Error", response.data.resp.substring(0, 32));
         }
         else {
            showToast("success", "Completado", response.data.resp);
         }

      }).catch(async (error) => {
         showToast("error", "Error", "Contactar con soporte técnico");
      }).finally(async () => {
      });
   };

   const putContact = async (clinic_id, contact_id, formData, showToast) => {
      let contact = convert_form(formData)
      await http.post(`clinica/contacto/update/${contact_id}/${clinic_id}`, contact).then(async (response) => {
         if (response.data.error) {
            showToast("error", "Error", response.data.error);
         } else if (response.data.resp.includes('Error')) {
            showToast("error", "Error", response.data.resp.substring(0, 32));
         }
         else {
            showToast("success", "Completado", response.data.resp);
         }

      }).catch(async (error) => {
         showToast("error", "Error", "Contactar con soporte técnico");
      }).finally(async () => {
      });
   };

   const deleteContact = async (clinic_id, contact_id, setContact, setContactCopy, showToast) => {
      await http.delete(`clinica/contacto/destroy/${contact_id}`).then(async (response) => {
         if (response.data.error) {
            showToast("error", "Error", response.data.error);
         } else {
            showToast("success", "Completado", response.data.resp);
         }

      }).catch(async (error) => {
         showToast("error", "Error", "Contactar con soporte técnico");
      }).finally(async () => {
         await getContact(clinic_id, setContact, showToast, setContactCopy);
      });
   };

   return {
      getContact,
      getContactID,
      postContact,
      putContact,
      deleteContact
   };
};

export default SB2Contact;
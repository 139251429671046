
import React, { useContext, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Fieldset } from 'primereact/fieldset';
import ComponentTablaViaAerea from "./tabla/TablaViaAerea";
import ComponentTablaViaOsea from "./tabla/TablaViaOsea";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from 'primereact/dropdown';
import "../../../../../Style/StyleTabla.css";
import VistasFichaAudiologica from "./Vistas/VistasFichaAudiologica";
import "../../../../../Style/StyleCardAgregar.css"
import VoiceButton from '../../../../../Components/ButtonVoice/VoiceButton';
import { AudContext } from "../../../AudContext";

export default function Agregar({ visible, setVisible }) {

   const { principalContext, setPrincipalContext } = useContext(AudContext)

   const initialAudFichaAudiologia = {
      marca: "",
      modelo: "",
      fecha_calibracion: "",
      anos_trabajo: "",
      tiempo_exposicion: "",
      uso_proteccion: "",
      apreciacion_ruido: "",
      aud_fich_antecedentes: [
         {
            patologia_id: 270,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 271,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 272,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 273,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 274,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 275,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 276,
            resultado: "no",
            observaciones: "",
         },
      ],
      aud_fich_sintomas: [
         {
            patologia_id: 277,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 278,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 279,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 280,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 281,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 282,
            resultado: "no",
            observaciones: "",
         },
         {
            patologia_id: 283,
            resultado: "no",
            observaciones: "",
         },
      ],
      aud_rinne: {
         oido_derecho: "",
         oido_izquierdo: ""
      },
      aud_weber: {
         oido_derecho: "",
         oido_izquierdo: ""
      },
      aud_schwabach: {
         oido_derecho: "",
         oido_izquierdo: ""
      },
   }

   const initialAudiometria = {
      via_aerea: {
         derecha: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
         derecha_enmascarado: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
         izquierda: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
         izquierda_enmascarado: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         }
      },
      via_osea: {
         derecha: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
         derecha_enmascarado: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
         izquierda: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
         izquierda_enmascarado: {
            v_500: 0,
            v_1000: 0,
            v_2000: 0,
            v_3000: 0,
            v_4000: 0,
            v_6000: 0,
            v_8000: 0,
         },
      },
      observacion: "",
   }
   const ruido = [
      { name: 'Ruido muy intenso', code: 'RI' },
      { name: 'Ruido moderado', code: 'RM' },
      { name: 'Ruido no molesto', code: 'RNM' },
      { name: 'Ninguno', code: 'N' }
   ];

   const [audFichaAudiologica, setAudFichaAudiologica] = useState(initialAudFichaAudiologia);
   const [audiometria, setAudiometria] = useState(initialAudiometria);
   const [date, setDate] = useState(null);
   const [DesicionDesabilitar, setDesicionDesabilitar] = useState(false);

   const handleAgregar = () => {
      let aux = principalContext.audiograma.concat({
         audFichaAudiologica,
         audiometria
      });
      setPrincipalContext({
         ...principalContext,
         audiograma: aux
      });
      setVisible(false);
      cleanForm();
   }

   const footerContent = (
      <div>
         <Button label="Cancelar" onClick={() => setVisible(false)} className="p-button-text" />
         <Button label="Guardar" onClick={handleAgregar} autoFocus />
      </div>
   );
   const Detector = (mensaje) => {
      setDesicionDesabilitar(mensaje); // Assuming you want to update DesicionDesabilitar

   };

   const handleChangeFecha = (e) => {
      setDate(e.value);
      setAudFichaAudiologica({ ...audFichaAudiologica, fecha_calibracion: e.value ? e.value.toLocaleDateString() : "" });
   }


   const handleChangeData = (e) => {
      
      setAudFichaAudiologica({ ...audFichaAudiologica, [e.target.name]: e.target.value })
   }

   const handleChangeRinne = (e) => {
      setAudFichaAudiologica({
         ...audFichaAudiologica,
         aud_rinne: { ...audFichaAudiologica.aud_rinne, [e.target.name]: e.target.value }
      })
   }

   const handleChangeWeber = (e) => {
      setAudFichaAudiologica({
         ...audFichaAudiologica,
         aud_weber: { ...audFichaAudiologica.aud_weber, [e.target.name]: e.target.value }
      })
   }

   const handleChangeShwabach = (e) => {
      setAudFichaAudiologica({
         ...audFichaAudiologica,
         aud_schwabach: { ...audFichaAudiologica.aud_schwabach, [e.target.name]: e.target.value }
      })
   }

   const setChangeObservacion = (e) => {
      setAudiometria({
         ...audiometria,
         observacion: e
      })
   }

   const cleanForm = () => {
      setAudFichaAudiologica(initialAudFichaAudiologia);
      setAudiometria(initialAudiometria);
   }

   return (
      <div>
         <Dialog header="Audiometría" visible={visible} style={{ width: '90%', maxWidth: "1300px" }} onHide={() => setVisible(false)} footer={footerContent}>
            <div className="card" style={{ marginTop: '20px', marginBottom: "1px" }}>
               <Accordion activeIndex={2} className='Acordeon7u7Bonito'>
                  <AccordionTab header="Ficha Audiológica">
                     <div className="Contenedor-Ficha-Adiologica flex flex-column gap-3">
                        {/* Parte superior */}
                        <div className="flex flex-row gap-3">
                           {/* marca modelo fecha calibracion */}
                           <div className="flex flex-column gap-3 w-full">
                              {/* marca */}
                              <div className="flex flex-row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                 <span className="Subtitulos-Audiometria">Marca:</span>
                                 <InputText id='marca' name='marca' value={audFichaAudiologica.marca} onChange={handleChangeData} className="flex flex-column" style={{ width: '80%' }} />
                              </div>
                              {/* Modelo */}
                              <div className="flex flex-row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                 <span className="Subtitulos-Audiometria">Modelo:</span>
                                 <InputText id='modelo' name='modelo' value={audFichaAudiologica.modelo} onChange={handleChangeData} className="flex flex-column" style={{ width: '80%' }} />
                              </div>
                              {/* Fecha calibracion */}
                              <div className="flex flex-row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                 <span className="Subtitulos-Audiometria">Fecha de calibración</span>
                                 <Calendar name='fecha_calibracion' value={date} onChange={(e) => handleChangeFecha(e)} showIcon dateFormat="dd-mm-yy" className="flex flex-row" style={{ width: '89%' }} />
                              </div>
                           </div>
                           {/* Años de trabajo hasta ruido */}
                           <div className="flex flex-column gap-3 w-full">
                              <div className="flex flex-row gap-3">
                                 <div className="flex flex-row w-full" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span className="Subtitulos-Audiometria">Años de trabajo:</span>
                                    <InputText id='anos_trabajo' name='anos_trabajo' value={audFichaAudiologica.anos_trabajo} onChange={handleChangeData} className="flex flex-column" style={{ width: '60%' }} />
                                 </div>
                                 <div className="flex flex-row w-full" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span className="Subtitulos-Audiometria">Tiempos de exposición:</span>
                                    <InputText id='tiempo_exposicion' name='tiempo_exposicion' value={audFichaAudiologica.tiempo_exposicion} onChange={handleChangeData} className="flex flex-column" style={{ width: '80%' }} />
                                 </div>

                              </div>
                              {/* Modelo */}
                              <div className="flex flex-row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                 <span className="Subtitulos-Audiometria">Uso de protecciones:</span>
                                 <div className="flex flex-row gap-3">
                                    <div className="flex align-items-center">
                                       <RadioButton inputId="protecciones1" name="uso_proteccion" value="tapones" onChange={handleChangeData} checked={audFichaAudiologica.uso_proteccion === 'tapones'} />
                                       <label htmlFor="protecciones1" className="ml-2">Tapones</label>
                                    </div>
                                    <div className="flex align-items-center">
                                       <RadioButton inputId="protecciones2" name="uso_proteccion" value="orejeras" onChange={handleChangeData} checked={audFichaAudiologica.uso_proteccion === 'orejeras'} />
                                       <label htmlFor="protecciones2" className="ml-2">Orejeras</label>
                                    </div>
                                    <div className="flex align-items-center">
                                       <RadioButton inputId="protecciones3" name="uso_proteccion" value="ambos" onChange={handleChangeData} checked={audFichaAudiologica.uso_proteccion === 'ambos'} />
                                       <label htmlFor="protecciones3" className="ml-2">Ambos</label>
                                    </div>
                                    <div className="flex align-items-center">
                                       <RadioButton inputId="protecciones4" name="uso_proteccion" value="ninguno" onChange={handleChangeData} checked={audFichaAudiologica.uso_proteccion === 'ninguno'} />
                                       <label htmlFor="protecciones4" className="ml-2">Ninguno</label>
                                    </div>
                                 </div>
                              </div>
                              {/* Fecha calibracion */}
                              <div className="flex flex-row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                 <span className="Subtitulos-Audiometria">Apreciación de ruido:</span>
                                 <Dropdown name='apreciacion_ruido' value={audFichaAudiologica.apreciacion_ruido} onChange={handleChangeData} options={ruido} optionLabel="name" optionValue='name'
                                    placeholder="Seleccionar una opción" className="" style={{ width: "100%" }} />
                              </div>
                           </div>

                        </div>
                        <div>
                           <VistasFichaAudiologica
                              audFichaAudiologica={audFichaAudiologica}
                              setAudFichaAudiologica={setAudFichaAudiologica}
                           />
                        </div>
                        <div className="flex flex-row gap-3">
                           <div className='contenedorRinne flex flex-column gap-2 w-full flex-1' id='miContenedor-Rinne'>
                              <div className='encabezadoRinne'>
                                 <span>Rinne</span>
                              </div>
                              <div className='CuerpoCae flex flex-column gap-3'>
                                 <div className='flex flex-row gap-3'>
                                    <div className='flex flex-row gap-2' style={{ alignItems: 'center' }}>
                                       <span> O.D.</span>
                                       <InputText name='oido_derecho' value={audFichaAudiologica?.aud_rinne?.oido_derecho} onChange={handleChangeRinne} className='w-full' />
                                    </div>
                                    <div className='flex flex-row gap-3' style={{ alignItems: 'center' }}>
                                       <span> O.I.</span>
                                       <InputText name='oido_izquierdo' value={audFichaAudiologica?.aud_rinne?.oido_izquierdo} onChange={handleChangeRinne} className='w-full' />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className='contenedorWeber flex flex-column gap-2 w-full flex-1' id='miContenedor-Weber'>
                              <div className='encabezadoWeber'>
                                 <span>Weber</span>
                              </div>
                              <div className='CuerpoCae flex flex-column gap-3'>
                                 <div className='flex flex-row gap-3'>
                                    <div className='flex flex-row gap-2' style={{ alignItems: 'center' }}>
                                       <span> O.D.</span>
                                       <InputText name='oido_derecho' value={audFichaAudiologica?.aud_weber?.oido_derecho} onChange={handleChangeWeber} className='w-full' />
                                    </div>
                                    <div className='flex flex-row gap-3' style={{ alignItems: 'center' }}>
                                       <span> O.I.</span>
                                       <InputText name='oido_izquierdo' value={audFichaAudiologica?.aud_weber?.oido_izquierdo} onChange={handleChangeWeber} className='w-full' />
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className='contenedorSchwabach flex flex-column gap-2 w-full flex-1' id='miContenedor-Schwabach'>
                              <div className='encabezadoSchwabach'>
                                 <span>Schwabach</span>
                              </div>
                              <div className='CuerpoCae flex flex-column gap-3'>
                                 <div className='flex flex-row gap-3'>
                                    <div className='flex flex-row gap-2' style={{ alignItems: 'center' }}>
                                       <span> O.D.</span>
                                       <InputText name='oido_derecho' value={audFichaAudiologica?.aud_schwabach?.oido_derecho} onChange={handleChangeShwabach} className='w-full' />
                                    </div>
                                    <div className='flex flex-row gap-3' style={{ alignItems: 'center' }}>
                                       <span> O.I.</span>
                                       <InputText name='oido_izquierdo' value={audFichaAudiologica?.aud_schwabach?.oido_izquierdo} onChange={handleChangeShwabach} className='w-full' />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </AccordionTab>
                  <AccordionTab header="Audiometria">
                     <div className="flex flex-column gap-3">
                        {/* parte superior */}
                        {/* <div className="flex flex-row" style={{ justifyContent: 'space-between', alignItems: 'end' }}>
                           <div className="flex flex-row gap-3">
                              <div className="flex flex-column gap-2">
                                 <span className="Subtitulos-Audiometria">Tipo</span>
                                 <div className="card flex justify-content-center">
                                    <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                       placeholder="Seguimiento" className="w-full md:w-14rem" />
                                 </div>
                              </div>
                              <div className="flex flex-column gap-2">
                                 <span className="Subtitulos-Audiometria">Equipo</span>
                                 <div className="card flex justify-content-center">
                                    <Dropdown value={selectedCity1} onChange={(e) => setSelectedCity1(e.value)} options={cities1} optionLabel="name"
                                       placeholder="..." className="w-full md:w-14rem" />
                                 </div>
                              </div>
                           </div>
                           <div className="flex flex-row gap-2">
                              <Button icon="fas fa-save" label="Grabar" className="Boton-grabar" />
                              <Button icon="fas fa-undo" label="Recuperar" className="Boton-recuperar" />
                           </div>
                        </div> */}
                        {/* Via Aérea */}
                        <div className="flex flex-column gap-3" style={{ marginTop: "-10px" }}>
                           <Fieldset legend="Via Aérea" toggleable>
                              <div>
                                 <ComponentTablaViaAerea audiometria={audiometria} setAudiometria={setAudiometria} Detector={Detector} />
                              </div>
                           </Fieldset>
                           {DesicionDesabilitar && (
                              <Fieldset legend="Via ósea" toggleable collapsed={true}>
                                 <div>
                                    <ComponentTablaViaOsea audiometria={audiometria} setAudiometria={setAudiometria} />
                                 </div>
                              </Fieldset>
                           )}
                           <div className="flex flex-column gap-2">
                              <span>Observación:</span>
                              <VoiceButton
                                 value={audiometria.observacion}
                                 onChange={setChangeObservacion}
                                 placeholder="Observaciones"
                              />
                           </div>
                        </div>
                     </div>
                  </AccordionTab>
               </Accordion>
            </div>
         </Dialog>
      </div>
   )
}

import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';


export default function ModalReporte({
    visibleReporte,
    setVisibleReporte,
    personal,
    setPersonal,
    showToast,
    tipoDocumentos,
    roles
}) {
  const [visible, setVisible] = useState(false);
  const HideDialogReporte = () => {
    setVisibleReporte(false);
};
  return (
    
      <Dialog
      visible={visibleReporte}
        header={
          <>
            <div className="flex justify-content-center align-items-center"
              style={{
                backgroundColor: '#FFFFF',
                width: '60px',
                height: '60px',
                borderRadius: '10px'
              }}
            >
              
                <img
                  src="https://cdn.discordapp.com/attachments/752706720048087181/1095484976482500669/File-new.png"
                  alt=""
                  style={{
                    width: '40px',
                    height: '40px',
                    Top: '169px',
                    left: '794 px'
                  }}
                />

            </div>
            <h1 className="per2">Descargar Reporte de Personal</h1>
          </>
        }
        style={{ width: '413px',
                heigth: '403px',
              borderRadius: '10px'}}
        onHide={() => HideDialogReporte()}
      >
      <br />
    </Dialog>
  );
}


import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import React, { useEffect, useState } from "react";
import { classNames } from 'primereact/utils';
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";
import { useFormik } from "formik";
import AuthUser from "../../../../AuthUser";
import axios from "axios";
import { Calendar } from 'primereact/calendar';
import { Data } from "@react-google-maps/api";
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";
export default function ModalCreatePersonal({
   visibleCreate,
   setVisibleCreate,
   setPersonal,
   showToast,
   tipoDocumentos,
   roles,
   getPersonal,
   api,
   data,
   contractOptions,
   sexos,
   userClinica
}) {
   const { http } = AuthUser();
   const dispatch = useDispatch();
   const [selectedContract, setSelectedContract] = useState(null)
   const [sexo, setSexo] = useState(null);
   const [fechaNacimiento, setFechaNacimiento] = useState(null);

   let tipo_acceso = localStorage.getItem("tipo_acceso")

   // const options = [
   //   {name: 'Es usuario', value:'0'},
   //   {name: 'No es usuario', value:'1'},
   // ];
   // const options = ['No es usuario', 'Es usuario'];
   let options = [
      { label: 'Es usuario', value: true },
      { label: 'No es usuario', value: false }
   ]
   const [value, setValue] = useState(true);

   const initialValues = {
      id: 0,
      tipo_documento: tipoDocumentos[0],
      numero_documento: "",
      tipo_contrato: "",
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      fecha_nacimiento: "",
      distrito_id: "",
      celular: "",
      correo: "",
      direccion: "",
      rol: "",
      empresa_cargo: "",
      usuario: 1,
      sexo_id: ""
   };

   const formik = useFormik({
      initialValues: initialValues,
      onSubmit: async (data) => {
         const dataSend = { ...data }
         dataSend.tipo_documento_id = data?.tipo_documento?.id;
         dataSend.rol_id = data?.rol?.id;
         dataSend.empresa_cargo_id = data?.empresa_cargo?.id;
         dataSend.tipo_contrato_id = selectedContract?.id

         dispatch(startLoading());

         try {
            const response = await http.post(api, dataSend);

            if (response?.data?.resp) {
               HideDialogCreate();
               getPersonal();
               setPersonal();
               showToast(
                  "success",
                  "Personal creado",
                  "Se creó correctamente el personal"
               );
            } else if (response?.data?.error) {
               showToast("error", "Error", response?.data?.error);
            }

         } catch (error) {
            showToast('error', 'Error', 'Contactar a soporte técnico')

         } finally {
            dispatch(endLoading());
         }
         // http
         //    .post(api, dataSend)
         //    .then((response) => {
         //       
         //       if (response?.data?.resp) {
         //          HideDialogCreate();
         //          getPersonal();
         //          setPersonal();
         //          showToast(
         //             "success",
         //             "Personal creado",
         //             "Se creó correctamente el personal"
         //          );
         //       } else if (response?.data?.error) {
         //          showToast("error", "Error", response?.data?.error);
         //       }
         //    })
         //    .catch((error) => {
         //       HideDialogCreate();
         //       showToast(
         //          "error",
         //          "Error de creación",
         //          "Algo salió mal, inténtelo de nuevo"
         //       );
         //    })
      },
      validate: (data) => {
         let errors = {};
         if (!data.numero_documento) {
            errors.numero_documento = "Número de documento es requerido"
         }
         if (!data.tipo_documento) {
            errors.tipo_documento = "Tipo de documento requerido.";
         }
         if (!data.apellido_paterno) {
            errors.apellido_paterno = "Apellido paterno requerido"
         }
         if (!data.apellido_materno) {
            errors.apellido_materno = "Apellido materno requerido"
         }
         if (!data.nombres) {
            errors.nombres = "Nombres requeridos"
         }
         if (!tipo_acceso) {
            if (!data.fecha_nacimiento) {
               errors.fecha_nacimiento = 'Fecha de nacimiento requerida'
            }
            if (!data.sexo_id) {
               errors.sexo_id = "Sexo es requerido.";
            }
         }

         return errors;
      }
   });

   const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
   const getFormErrorMessage = (name) => {
      return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
   };

   const HideDialogCreate = () => {
      setVisibleCreate(false);
      formik.resetForm();
      setValue(true);
      setSelectedContract("")
      setSexo(null);
      setFechaNacimiento("");
   };

   const header = (
      <Header
         piIcon={'pi pi-user'}
         titulo={"Crear personal"}
         subtitulo={"En esta sección puedes registrar los datos para la creación del personal"} />
   )

   const paqueteCreateDialogFooter = (
      <Footer
         type={"submit"}
         onClickCancelar={() => {
            HideDialogCreate();
         }}
         label="Crear Usuario" />
   );

   const getNombreCompleto = async (numdocumento, tipoDocumento, tipoContrato, correo, celular, rol, usuario, empresa_cargo) => {
      if (formik.values?.tipo_documento !== "") {
         if (formik.values?.tipo_documento?.nombre === "DNI") {
            dispatch(startLoading())
            try {
               const response = await axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
               if (response?.data?.success === true) {
                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     tipo_contrato: tipoContrato,
                     nombres: response.data.nombres,
                     apellido_paterno: response.data.apellidoPaterno,
                     apellido_materno: response.data.apellidoMaterno,
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: correo,
                     correo: celular,
                     direccion: "",
                     rol: rol,
                     usuario: usuario,
                     empresa_cargo: empresa_cargo,
                  })
               } else if (response?.data?.message) {
                  showToast(
                     "error",
                     'Número de documento desconocido',
                     response.data.message
                  );
               }
            } catch (error) {
               console.error("Error", error)
               showToast('error', 'Error', 'Ingrese un número de Documento Válido')
            } finally {
               dispatch(endLoading())
            }
         } else if (formik.values.tipo_documento?.nombre === "RUC") {
            try {
               const response = await axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
               if (response?.data?.success) {
                  formik.setValues({
                     tipo_documento: tipoDocumento,
                     numero_documento: numdocumento,
                     tipo_contrato: tipoContrato,
                     nombres: response.data.razonSocial.split(" ").slice(2, (response.data.razonSocial.length + 1)).join(" "),
                     apellido_paterno: response.data.razonSocial.split(" ")[0],
                     apellido_materno: response.data.razonSocial.split(" ")[1],
                     fecha_nacimiento: "2022-01-01",
                     distrito_id: 2,
                     celular: celular,
                     correo: correo,
                     direccion: "",
                     rol: rol,
                     usuario: "",
                     empresa_cargo: empresa_cargo,
                  })
               } else if (response?.data?.message) {
                  showToast('error', 'Número RUC incorrecto', response?.data?.message)
               }
            } catch (error) {
               console.error(error)
               showToast('error', 'Error', 'Ingrese un número de Documento Válido')
            } finally {
               dispatch(endLoading())
            }
         } else {
            showToast(
               "error",
               "Tipo de documento",
               `No se eligió un tipo de documento`
            );
         }
      }
   }

   useEffect(() => {
      if (value) {
         formik.setFieldValue('usuario', "1")
      } else {
         formik.setFieldValue('usuario', "0")
      }
   }, [value]);

   const tipoDocumentosFiltrados = tipoDocumentos.filter(doc => doc.nombre !== 'Carné de extranjería');

   return (
      <div>
         <form
            id="persona-form"
            className="persona-form"
            noValidate
            onSubmit={formik.handleSubmit}
         >
            <Dialog
               visible={visibleCreate}
               style={{
                  width: "450px",
                  margin: "5%"
               }}
               appendTo={document.getElementById("persona-form")}
               header={header}
               dismissableMask
               className="p-fluid "
               footer={paqueteCreateDialogFooter}
               onHide={() => {
                  HideDialogCreate();
               }}
            >
               <div className="flex flex-column gap-3">
                  <div className="flex flex-column w-full">
                     <p className="per1">(*) Tipo de documento</p>
                     <div className="flex w-full flex-column">
                        <Dropdown
                           id="tipo_documento"
                           name="tipo_documento"
                           value={formik.values.tipo_documento}
                           onChange={formik.handleChange}
                           options={tipoDocumentosFiltrados}
                           optionLabel="nombre"
                           placeholder="Selecciona un tipo de documento"
                           className={classNames({ 'p-invalid': isFormFieldValid('tipo_documento') })}
                        />
                        {getFormErrorMessage('tipo_documento')}
                     </div>
                  </div>

                  <div className="flex flex-column w-full">
                     <label htmlFor="numero_documento">(*) Número de Documento</label>
                     <div className="flex flex-1 w-full gap-2">
                        <InputText
                           id="numero_documento"
                           name="numero_documento"
                           value={formik.values.numero_documento}
                           onChange={formik.handleChange}
                           required
                           style={{ width: "400px" }}
                           className={classNames({ 'p-invalid': isFormFieldValid('numero_documento') })}
                        />
                        {formik.values.tipo_documento?.nombre !== 'Carnet de extranjería' && formik.values.tipo_documento?.nombre !== 'Pasaporte' && (
                           <Button
                              label="Validar"
                              type="button"
                              style={{ background: "#07A34F", borderColor: "#07A34F" }}
                              onClick={() => {
                                 getNombreCompleto(
                                    formik.values.numero_documento,
                                    formik.values.tipo_documento,
                                    selectedContract,
                                    formik.values.correo,
                                    formik.values.celular,
                                    formik.values.rol,
                                    formik.values.usuario,
                                    formik.values.empresa_cargo,
                                 )
                              }}
                           />
                        )}
                     </div>
                     {getFormErrorMessage('numero_documento')}
                  </div>


                  <div className="flex flex-column gap-2">
                     <label>(*) Nombres Completos
                        <InputText
                           id="nombres"
                           name="nombres"
                           value={formik.values.nombres}
                           className={classNames({ 'p-invalid': isFormFieldValid('nombres') })}
                           onChange={formik.handleChange}
                           required
                           autoComplete="off"
                        />
                     </label>
                     {getFormErrorMessage('nombres')}
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">(*) Apellidos Paterno</label>
                     <InputText
                        id="apellido_paterno"
                        name="apellido_paterno"
                        value={formik.values.apellido_paterno}
                        onChange={formik.handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValid('apellido_paterno') })} />
                  </div>
                  {getFormErrorMessage('apellido_paterno')}

                  <div className="flex flex-column gap-2">
                     <label htmlFor="lastname">(*) Apellidos Materno</label>
                     <InputText
                        id="apellido_materno"
                        name="apellido_materno"
                        value={formik.values.apellido_materno}
                        onChange={formik.handleChange}
                        className={classNames({ 'p-invalid': isFormFieldValid('apellido_materno') })} />
                     {getFormErrorMessage('apellido_materno')}
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Correo</label>
                     <InputText
                        id="correo"
                        name="correo"
                        value={formik.values.correo}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>
                  <div className="flex flex-column gap-2">
                     <label htmlFor="">Celular</label>
                     <InputText
                        type="number"
                        id="celular"
                        name="celular"
                        value={formik.values.celular}
                        onChange={formik.handleChange}
                        className="w-full" />
                  </div>
                  {/* <p className="per1">Tipo de contrato</p>
                  <div className="flex w-full flex-column">
                     <Dropdown
                        value={selectedContract}
                        onChange={(e) => handleChangeContract(e)}
                        options={contractOptions}
                        optionLabel="nombre"
                        placeholder="Selecciona un tipo de contrato"
                        className="w-full"
                     />
                  </div> */}

                  <div className="flex justify-content-center align-content-center">
                     <SelectButton
                        className="flex-1"
                        value={value}
                        onChange={(e) => (setValue(e.value))}
                        options={options}
                     ></SelectButton>
                  </div>

                  <div className="flex flex-column">
                     <p>Rol</p>
                     <Dropdown
                        id="rol"
                        name="rol"
                        value={formik.values.rol}
                        onChange={formik.handleChange}
                        options={roles}
                        optionLabel="nombre"
                        placeholder="Selecciona un Rol"
                        className="w-full"
                        disabled={!value}
                     />
                  </div>
                  {tipo_acceso ?
                     <></> : (<div className="flex flex-1 w-full flex-column gap-2">
                        <div className="flex flex-1 w-full gap-2">
                           <div>
                              <p>Sexo</p>
                              <Dropdown
                                 id="sexo_id"
                                 name="sexo_id"
                                 value={formik.values.sexo_id}
                                 onChange={formik.handleChange}
                                 options={sexos}
                                 placeholder="Seleccionar sexo"
                                 optionValue="id"
                                 optionLabel="nombre"
                                 className={classNames({ 'p-invalid': isFormFieldValid('sexo_id') })}
                              />
                              {getFormErrorMessage('sexo_id')}
                           </div>

                           <div>
                              <p>Fecha de nacimiento</p>
                              <Calendar id="fecha_nacimiento"
                                 name="fecha_nacimiento"
                                 value={formik.values.fecha_nacimiento}
                                 className={classNames({ 'p-invalid': isFormFieldValid('fecha_nacimiento') })}
                                 onChange={formik.handleChange} />
                              {getFormErrorMessage('fecha_nacimiento')}
                           </div>
                        </div>
                     </div>)}
               </div>
            </Dialog>
         </form>
      </div>
   );
}

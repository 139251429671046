import React, { useContext, useEffect } from 'react';
import { InputNumber } from "primereact/inputnumber";
import { TriajeContext } from '../../../../Page/Triaje/TriajeContext';
import VoiceButton from '../../../ButtonVoice/VoiceButtonTriaje';
import { RAContext } from '../../../../Page/RutaAtencion/RAContext';

const ModalSigVitCrear = () => {
   let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
   //llamar contexto
   let { sigVitContext, setSigVitContext } = useContext(TriajeContext)

   const changesData = (e) => {
      setSigVitContext({ ...sigVitContext, [e.target.name]: e.target.value })
   }

   const changesObs = (e) => {
      setSigVitContext({ ...sigVitContext, observaciones: e })
   }

   useEffect(() => {
      if (sigVitContext?.p_diastolica !== null && sigVitContext?.p_sistolica !== null) {
         let media = (sigVitContext?.p_sistolica + (sigVitContext.p_diastolica + sigVitContext.p_diastolica)) / 3
         setSigVitContext({ ...sigVitContext, p_media: media })
      } else {
         setSigVitContext({ ...sigVitContext, p_media: null })
      }
   }, [sigVitContext?.p_sistolica, sigVitContext?.p_diastolica])

   const estilo = {
      background: '#DDDDDD',
   }

   return (
      <>
         <div className="tsv_form1_container">
            <div className="tsv_form1_input">
               <label>Frec. Cardiaca (min)</label>
               <InputNumber
                  id='frec_cardiaca'
                  name='frec_cardiaca'
                  value={sigVitContext?.frec_cardiaca}
                  placeholder="0 min"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
            <div className="tsv_form1_input">
               <label>Frec. Respiratoria (min)</label>
               <InputNumber
                  id='frec_respiratoria'
                  name='frec_respiratoria'
                  value={sigVitContext?.frec_respiratoria}
                  placeholder="0 min"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
            <div className="tsv_form1_input">
               <label>P. Sistólica (mmHg)</label>
               <InputNumber
                  id='p_sistolica'
                  name='p_sistolica'
                  value={sigVitContext?.p_sistolica}
                  placeholder="0 mmHg"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
            <div className="tsv_form1_input">
               <label>P. Diastólica (mmHg)</label>
               <InputNumber
                  id='p_diastolica'
                  name='p_diastolica'
                  value={sigVitContext?.p_diastolica}
                  placeholder="0 mmHg"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
            <div className="tsv_form1_input">
               <label>Pres. Media (mmHg)</label>
               <InputNumber
                  inputStyle={estilo}
                  id='p_media'
                  name='p_media'
                  value={sigVitContext?.p_media}
                  placeholder="0 mmHg"
                  mode="decimal"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={true}
               />
            </div>
            <div className="tsv_form1_input">
               <label>Sat. O2 (%)</label>
               <InputNumber
                  id='saturacion'
                  name='saturacion'
                  value={sigVitContext?.saturacion}
                  placeholder="0 %"
                  mode="decimal"
                  max={100}
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
            <div className="tsv_form1_input">
               <label>Temperatura (°C)</label>
               <InputNumber
                  id='temperatura'
                  name='temperatura'
                  value={sigVitContext?.temperatura}
                  placeholder="0 °C"
                  useGrouping={false}
                  locale="en-US"
                  maxFractionDigits={2}
                  onValueChange={e => changesData(e)}
                  disabled={stateAtencion[pageViewContext].estado === 3}
               />
            </div>
         </div>
         <div className="tsv_form1_container2">
            <div className='input_micro_label'>
               <label>Observaciones</label>
            </div>
            <VoiceButton
               value={sigVitContext?.observaciones}
               onChange={changesObs}
               placeholder="Observaciones"
               disabled={stateAtencion[pageViewContext].estado === 3}
            />
         </div>
         {/* </Dialog> */}
      </>
   );
};
export default ModalSigVitCrear
import React, { useContext, useState, useEffect } from "react";
import TriajePesTalTable from "../../../Components/Triaje/PesoTalla/TriajePesTalTable";
import "../../../Style/Triaje/PesoTalla/triaje_pes_tal.css";
import CrearPesoTalla from "../../../Components/Triaje/PesoTalla/ModalsPesTal/CrearPesoTalla";
import { Column } from "primereact/column";
import Table from "../../../Components/Table/Table";
import { Accordion, AccordionTab } from "primereact/accordion";

const PageTriajePesTal = ({setPesTall, PesTall}) => {
  const [visibleDialog, setVisibleDialog] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [dataPesoTalla, setDataPesoTalla] = useState();
  const [editDatos, setEditDatos] = useState(null);
  let newDataArray = null;

  // let idBD = 0;
  // let datoPesoTallaBD = PesTall.map((pesotalla) => {
  //   idBD = idBD + 1;
  //   let imc =
  //     pesotalla.peso / ((pesotalla.talla / 100) * (pesotalla.talla / 100));
  //   let icc = pesotalla.cintura / pesotalla.cadera;

  //   return { ...pesotalla, IMC: imc.toFixed(2), ICC: icc.toFixed(2), item: idBD };
  // });


  return (
    <>

      <CrearPesoTalla
      setPesTall ={setPesTall}
      PesTall={PesTall}
/>
      {/* <TriajePesTalTable
        dataPesoTalla={dataPesoTalla}
        setActiveIndex={setActiveIndex}
        setVisibleDialog={setVisibleDialog}
        visibleDialog={visibleDialog}
        newDataArray={newDataArray}
        PesTall={PesTall}
        ultimoElemento={ultimoElemento}
      /> */}

      <Accordion className="pt-5">
        <AccordionTab header="Historial">
          <Table
            // title="Historial"
            // data={datoPesoTallaBD}
            onClickCreate={() => setVisibleDialog(true)}
            createButtonEnabled={false}
            clasesSearch="w-5 flex justify-end align-items-end"
          >
            <Column className="text-center" field="item" header="Ítem" />
            <Column
              className="text-center"
              field="talla"
              header="Talla (cm)"
            />
            <Column
              className="text-center"
              field="peso"
              header="Peso (kg)"
            />
            <Column
              className="text-center"
              field="IMC"
              header="IMC (Kg/m2)"
            />
            <Column
              className="text-center"
              field="cintura"
              header="Cintura (cm)"
            />
            <Column
              className="text-center"
              field="cadera"
              header="Cadera (cm)"
            />
            <Column
              className="text-center"
              field="ICC"
              header="Cintura/Cadera"
            />
            <Column
              className="text-center"
              field="max_inspiracion"
              header="max_inspiración"
            />
            <Column
              className="text-center"
              field="expiracion_forzada"
              header="expiración_forzada"
            />
            <Column
              className="text-center"
              field="perimetro_cuello"
              header="perímetro_cuello"
            />
            <Column
              className="text-center"
              field="observaciones"
              header="Observaciones"
              style={{ maxWidth: "16rem", whiteSpace: "wrap" }}
            />
            {/* <Column
                  className="text-center"
                  header="Acción"
                  body={actionBodyTemplateboton}
                  style={{ maxWidth: "8rem" }}
               /> */}
          </Table>
        </AccordionTab>
      </Accordion>
    </>
  );
};

export default PageTriajePesTal;
import AuthUser from "../../../../../AuthUser";
import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

const SB1Customer = () => {
    const { http } = AuthUser();
    const dispatch = useDispatch();
    const getClinic = async () => {
        try {
            const response = await http.get("bregma/contratos/get");
            if (response.data.error) {
                return [];
            } else {
                const transformedData = response.data.data.map((item) => ({
                    id: item.clinica_id,
                    logo: item.clinica.logo,
                    empresa: item.clinica.razon_social,
                    nombre: item.clinica.responsable,
                    sevicio_1: 'Áreas médicas',
                    sevicio_2: 'Capacitaciones',
                    estado: item.estado_registro === 'A' ? 1 : 2,
                }));
                return transformedData
            }
        } catch (error) {
            throw error;
        } finally {
        }
    };

    const deleteClinic = async (id, getClinic, showToast) => {
        dispatch(startLoading());
        try {
            const response = await http.delete(`clinica/delete/${id}`);
            if (response.data.resp) {
                await Promise.all([getClinic()])
                showToast("success", "Eliminado correctamente", response.data.resp);
                //return response.data.data;
            } else if (response?.data?.error) {
                showToast("error", "Error", response.data.error);
                //return [];
            }
        } catch (error) {
            //throw error;
            showToast("error", "Error", "Contacte a soporte técnico");
        } finally {
            dispatch(endLoading())
        }
    }

    const getClinicID = async (id, ubicaciones, setData) => {
        //FALTA SINPLIFICAR LA CONDICIÓN GENERAL
        try {
            const response = await http.get(`clinica/get/${id}`);
            if (Object.keys(response.data).length > 0) {
                const empresa = response.data.resp? response.data.resp: response.data;

                const departamentoSeleccionado = ubicaciones.find(
                    (departamento) => departamento.id === empresa?.distrito?.provincia?.departamento?.id
                );
                const provinciaSeleccionada = departamentoSeleccionado?.provincias.find(
                    (provincia) => provincia.id === empresa?.distrito?.provincia?.id
                );
                const distritoSeleccionado = provinciaSeleccionada?.distritos.find(
                    (distrito) => distrito.id === empresa?.distrito?.id
                );
                setData(prevState => (
                    {
                        ...prevState,
                        GCEmpresa: {
                            ...prevState.GCEmpresa,
                            contrato_id: response.data.resp? response.data.resp.contrato[0].id : response.data.contrato[0].id,
                            tipo_cliente: response.data.resp? response.data.resp.contrato[0].tipo_cliente_id : response.data.contrato[0].tipo_cliente_id,
                            numero_documento: response.data.resp? response.data.resp.numero_documento : response.data.numero_documento,
                            razon_social: response.data.resp? response.data.resp.razon_social: response.data.razon_social,
                            responsable: response.data.resp? response.data.resp.responsable || "" : response.data.responsable,
                            nombre_comercial: response.data.resp? response.data.resp.nombre_comercial: response.data.nombre_comercial,
                            latitud: parseFloat(response.data.resp? response.data.resp.latitud: response.data.latitud) || -16.389571123416584,
                            longitud: parseFloat(response.data.resp? response.data.resp.longitud: response.data.longitud) || -71.55339135054068,
                            tipo_documento_id: response.data.resp? response.data.resp.tipo_documento.id: response.data.tipo_documento.id,
                            departamento: departamentoSeleccionado,
                            provincia: provinciaSeleccionada,
                            distrito: distritoSeleccionado,
                            direccion: response.data.resp? response.data.resp.direccion: response.data.direccion,
                            ubicacion_mapa: response.data.resp? response.data.resp.ubicacion_mapa: response.data.ubicacion_mapa,
                            aniversario: response.data.resp? response.data.resp.aniversario: response.data.aniversario,
                            rubro: response.data.resp? response.data.resp.rubro_id: response.data.rubro_id,
                            cantidad_trabajadores: response.data.resp? response.data.resp.cantidad_trabajadores: response.data.cantidad_trabajadores,
                            actividad: response.data.resp? response.data.resp.años_actividad: response.data.años_actividad,
                            logo: response.data.resp? response.data.resp.logo: response.data.logo,
                            estado_registro: response.data.resp? response.data.resp.estado_registro: response.data.estado_registro,

                        }
                    }
                ));
            }
            return false;
        } catch (error) {
            throw error;
        } finally {
        }
    };

    const getPackages = async () => {
        try {
            const response = await http.get("bregma/paquete/get");
            if (response.data.data) {
                return response.data.data;
            } else {
                return [];
            }
        } catch (error) {
            throw error;
        } finally {
        }
    };


    const postClinic = async (data, setData, showToast, navigate) => {
        dispatch(startLoading())
        try {
            const response = await http.post("clinica/create", data)
            if (response.data.resp) {
                setData(prevState => ({
                    ...prevState,
                    GCEmpresa: {
                        ...prevState.GCEmpresa,
                        clinica_id: response.data.clinica_id || null,
                        contrato_id: response.data.contrato_id || null,
                        delete_image: false
                    }
                }));
                showToast("success", "Creado correctamente", response.data.resp);
                navigate(`../ventas/gestiondeventas/empresa/${response.data.clinica_id}/editar`);
            } else if (response?.data?.error) {
                showToast("error", "Error", response.data.error);
            }
        }
        catch (error) {
            showToast("error", "Error", "Contactar con soporte técnico");
            console.error("Error", error)
        } finally {
            dispatch(endLoading())
        }
    }

    const putClinic = async (contrato_id, data, setData, showToast) => {
        await http.post(`clinica/update/${contrato_id}`, data).then((response) => {
            //showToast(response.data.resp ? "success" : "error", response.data.resp ? "Completado" : "Error", response.data.resp || response.data.error)
            if (response.data.resp) {
                showToast("success", "Actualizado correctamente", response.data.resp);
            } else {
                showToast("error", "Error", response.data.error);
            }
        }).catch((error) => {
            showToast("error", "Error", "Contactar con soporte técnico");
        }).finally(() => {
        });
    }

    return {
        getClinic,
        getClinicID,
        postClinic,
        putClinic,
        deleteClinic,

        getPackages,
    }
}

export default SB1Customer;
import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { RadContext } from "./RadProvider";
import "./estilos.css";
import AuthUser from "../../AuthUser";
import OneSimbolo from './OneSimbolo';

export default function Simbolos() {
   const { RayTorax, setRayTorax } = useContext(RadContext);

   const etiquetas = [
      "aa", "at", "ax", "bu", "ca", "cg", "cn", "co", "cp", "cv", "di", "ef",
      "em", "es", "fr", "hi", "ho", "id", "ih", "kl", "me", "pa", "pb", "pi",
      "px", "ra", "rp", "tb", "oc",
   ];
   const [rpta, setRpta] = useState(RayTorax.simbolos)
   const AgregarSimbolo = (Simbolo) => {
      setRayTorax(prevState => {
         return {
            ...prevState,
            simbolos: [...prevState.simbolos, Simbolo]
         };
      });
   }
   const EliminarSimbolo = (Simbolo) => {
      setRayTorax(prevState => {
         return {
            ...prevState,
            simbolos: prevState.simbolos.filter(item => item !== Simbolo)
         };
      });
   }

   return (
      <div className="contenedorSim flex flex-column w-full flex-1">
         <div className="encabezadoSim">
            <span>4. SÍMBOLO</span>
         </div>
         <div className="flex flex-column w-full">
            <div className="flex flex-wrap p-2 gap-2 w-full m-auto flex align-items-center justify-content-center"
               style={{ maxWidth: '1400px' }}>
               {etiquetas.map((item) => {
                  return (
                     <OneSimbolo
                        Text={item}
                        Respuestas={rpta}
                        FuncionAgregar={AgregarSimbolo}
                        FuncionEliminar={EliminarSimbolo}
                     />
                  );
               })}
            </div>

         </div>
      </div>
   );
}

import React, { useContext, useEffect, useState } from 'react';
import AuthUser from "../../../../AuthUser";
import { TriajeContext } from "../../../../Page/Triaje/TriajeContext";
import { InputNumber } from "primereact/inputnumber";
import { isNumber } from "chart.js/helpers";
import { Divider } from "primereact/divider";
import { Button } from 'primereact/button';
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from 'primereact/dialog';
import VoiceButton from '../../../ButtonVoice/VoiceButtonTriaje';
import { Fieldset } from 'primereact/fieldset';
import { RAContext } from '../../../../Page/RutaAtencion/RAContext';

const CrearPesoTalla = ({ setPesTall, PesTall }) => {

  let { pesTalContext, setPesTalContext } = useContext(TriajeContext);
  let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
  const { http } = AuthUser();
  const [pesoTalla, setPesoTalla] = useState([])
  // const [pesoId, setPesoId] = useState(pesTalContext?.id ?? null);
  const [talla, setTalla] = useState(pesTalContext?.talla ?? null);
  const [peso, setPeso] = useState(pesTalContext?.peso ?? null);
  const [cintura, setCintura] = useState(pesTalContext?.cintura ?? null);
  const [cadera, setCadera] = useState(pesTalContext?.cadera ?? null);
  const [maxInsp, setMaxInsp] = useState(pesTalContext?.max_inspiracion ?? null);
  const [expForc, setExpForc] = useState(pesTalContext?.expiracion_forzada ?? null);
  const [perCuello, setPerCuello] = useState(pesTalContext?.perimetro_cuello ?? null);
  const [observ, setObserv] = useState(pesTalContext?.observaciones ?? null);
  const [imc, setImc] = useState(0);
  const [icc, setIcc] = useState(0);

  useEffect(() => {
    setPesTalContext({
      // [`triaje_id`]: null,
      [`talla`]: talla,
      [`peso`]: peso,
      [`cintura`]: cintura,
      [`cadera`]: cadera,
      [`max_inspiracion`]: maxInsp,
      [`expiracion_forzada`]: expForc,
      [`perimetro_cuello`]: perCuello,
      [`observaciones`]: observ
    })

  }, [talla, peso,
    cintura, cadera,
    maxInsp, expForc,
    perCuello, observ])

  useEffect(() => {
    if (PesTall != null) {
      setTalla(PesTall?.talla)
      setPeso(PesTall?.peso)
      setCintura(PesTall?.cintura)
      setCadera(PesTall?.cadera)
      setMaxInsp(PesTall?.max_inspiracion)
      setExpForc(PesTall?.expiracion_forzada)
      setPerCuello(PesTall?.perimetro_cuello)
      setObserv(PesTall?.observaciones ?? "")
      setPesTall(null)
    }

  }, [PesTall])

  const imcStatus = () => {

    if (imc === 0 || imc == null) {
      return (
        <></>
      )
    } else if (imc < 18.5) {
      return (
        <div className="status_imc_1">
          <span>Bajo Peso</span>
        </div>
      )
    } else if (imc >= 18.5 && imc <= 24.9) {
      return (
        <div className="status_imc_2">
          <span>Normal</span>
        </div>
      )
    } else if (imc >= 25.0 && imc <= 29.9) {
      return (
        <div className="status_imc_3">
          <span>Sobrepeso</span>
        </div>
      )
    } else if (imc >= 30.0 && imc <= 34.9) {
      return (
        <div className="status_imc_4">
          <span>Obesidad I</span>
        </div>
      )
    } else if (imc >= 35.0 && imc <= 39.9) {
      return (
        <div className="status_imc_5">
          <span>Obesidad II</span>
        </div>
      )
    } else if (imc > 39.9) {
      return (
        <div className="status_imc_6">
          <span>Obesidad III</span>
        </div>
      )
    }
  }

  const iccStatus = () => {
    //30-39
    if (icc === 0 || icc == null) {
      return (
        <></>
      )
    }
    else if (icc < 0.84) {
      return (
        <div className="status_imc_1">
          <span>Riesgo Bajo</span>
        </div>
      )
    }
    else if (icc >= 0.84 && icc <= 0.91) {
      return (
        <div className="status_imc_2">
          <span>Riesgo Moderado</span>
        </div>
      )
    }
    else if (icc >= 0.92 && icc <= 0.96) {
      return (
        <div className="status_imc_3">
          <span>Riesgo Alto</span>
        </div>
      )
    }
    else if (icc > 0.96) {
      return (
        <div className="status_imc_4">
          <span>Riesgo Muy Alto</span>
        </div>
      )
    }
  }

  useEffect(() => {
    imcStatus()
  }, [imc])


  useEffect(() => {
    if (talla !== 0 && peso !== 0) {
      setImc(peso / ((talla / 100) * (talla / 100)))
    }
  }, [talla, peso]);


  useEffect(() => {
    if (cintura !== 0 && cadera !== 0)
      setIcc(cintura / cadera)
  }, [cintura, cadera])

  return (
    <Fieldset legend="Añadir peso y talla" toggleable>
      <div className="tpt_form_container">
        <h3>Medición Corporal</h3>
        <div className="tpt_form_section_input">
          <div className="tpt_form_input">
            <label>Peso (kg)</label>
            <InputNumber
              min={0.00}
              placeholder="0 Kg"
              value={peso}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={(e) => setPeso(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="tpt_form_input_icon_1">
            <i className="pi pi-percentage"></i>
          </div>

          <div className="tpt_form_input">
            <label>Talla (cm)</label>
            <InputNumber
              min={0.00}
              placeholder="0 cm"
              value={talla}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={e => setTalla(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="tpt_form_input_icon_2">
            <i className="pi pi-pause"></i>
          </div>

          <div className="tpt_form_input">
            <label>IMC</label>
            <InputNumber
              suffix=" kg/m2"
              min={0.00}
              placeholder="Ingrese peso y talla"
              value={isNumber(imc) && imc !== 0 ? imc : null}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              disabled
            />

            <div className="tpt_form_input_status">
              {
                peso > 0 && talla > 0 ?
                  imcStatus()
                  :
                  <></>
              }
            </div>

          </div>
        </div>

      </div>

      <div className="tpt_form_container">
        <h3>Perímetro de Cintura y Cadera</h3>
        <div className="tpt_form_section_input">
          <div className="tpt_form_input">
            <label>Cintura (cm)</label>
            <InputNumber
              min={0.00}
              placeholder="0 cm"
              value={cintura}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={e => setCintura(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />

          </div>

          <div className="tpt_form_input_icon_1">
            <i className="pi pi-percentage"></i>
          </div>

          <div className="tpt_form_input">
            <label>Cadera (cm)</label>
            <InputNumber
              min={0.00}
              placeholder="0 cm"
              value={cadera}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={e => setCadera(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>

          <div className="tpt_form_input_icon_2">
            <i className="pi pi-pause"></i>
          </div>

          <div className="tpt_form_input">
            <label>ICC</label>
            <InputNumber
              min={0.00}
              placeholder="Ingrese cintura y cadera"
              value={isNumber(icc) && icc !== 0 ? icc : null}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              disabled
            />

            <div className="tpt_form_input_status">
              {
                cintura > 0 && cadera > 0 ?
                  iccStatus()
                  :
                  <></>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="tpt_form_container">
        <h3>Perímetro Torácico</h3>
        <div className="tpt_form_section_input_3">
          <div className="tpt_form_input">
            <label>Máx. Inspiración (cm)</label>
            <InputNumber
              min={0.00}
              placeholder="0 cm"
              value={maxInsp}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={e => setMaxInsp(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="tpt_form_input">
            <label>Expiración Forzada (cm)</label>
            <InputNumber
              min={0.00}
              placeholder="0 cm"
              value={expForc}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={e => setExpForc(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
          <div className="tpt_form_input">
            <label>Perímetro Cuello (cm)</label>
            <InputNumber
              min={0.00}
              placeholder="0 cm"
              value={perCuello}
              useGrouping={false}
              locale="en-US"
              maxFractionDigits={2}
              onValueChange={e => setPerCuello(e.value)}
              disabled={stateAtencion[pageViewContext].estado === 3}
            />
          </div>
        </div>
      </div>


      <Divider />

      <div className='input_micro_label'>

        <div className="tsv_form1_container2">
          <div className='input_micro_label'>
            <label>Observaciones</label>
          </div>
          <VoiceButton
            value={observ}
            onChange={setObserv}
            placeholder="Observaciones"
            disabled={stateAtencion[pageViewContext].estado === 3}
          />
        </div>
      </div>

    </Fieldset>
  );
};

export default CrearPesoTalla;
import React, { useState, useEffect, useContext } from "react";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { MGContext } from "../MGProvider";
import { InputText } from 'primereact/inputtext';
import AuthUser from "../../../AuthUser";
import EvaluadorTableAHP from "../../../Components/MedicinaGeneral/examenAltura/pure/EvaluadorTableAHP";
import EnvioArchivoMedGeneral from '../../../Components/MedicinaGeneral/EnvioArchivoMedGeneral';

const TamizajeDermatologicoDetalles = () => {
  const { tamizajeDermatologico, setTamizajeDermatologico } = useContext(MGContext);

  // VARIABLES DE LOS TEXT-AREA
  const [inputSistHalla, setinputSistHalla] = useState(tamizajeDermatologico?.tam_detalles.sintomas_hallazgo);
  const [inputObserva, setinputObserva] = useState(tamizajeDermatologico?.tam_detalles.observaciones);
  const [inputRecomend, setinputRecomend] = useState(tamizajeDermatologico?.tam_detalles.recomendaciones);
  const [inputConclus, setinputConclus] = useState(tamizajeDermatologico?.tam_detalles.conclusiones);
  const Resultados = [
    { nombre: 'Normal', id: '1' },
    { nombre: 'Anormal', id: '2' },
    { nombre: 'No se evaluó', id: '3' }
  ];

  // VARIABLES EXTRAS INPUTS
  const [inputFecha, setinputFecha] = useState(new Date(tamizajeDermatologico?.tam_detalles.fecha));
  const [selectResultado, setselectResultado] = useState(tamizajeDermatologico?.tam_detalles?.resultado?.nombre === 'Apto' ? Resultados[0] : tamizajeDermatologico?.tam_detalles?.resultado?.nombre === 'No apto' ? Resultados[1] : Resultados[2]);

  // MEDICOS EVALUADORES
  const { http } = AuthUser();
  const [selectEvaluador, setselectEvaluador] = useState(tamizajeDermatologico?.tam_detalles.evaluador?.nombres);
  const [evaluadores, setEvaluadores] = useState({})
  const [visibleEvaluador, setVisibleEvaluador] = useState(false);
  const [ColegiaturaEv, setColegiaturaEv] = useState(tamizajeDermatologico?.tam_detalles?.evaluador?.colegiatura ? tamizajeDermatologico?.tam_detalles?.evaluador?.colegiatura : selectEvaluador ? selectEvaluador.colegiatura : null);
  const [NombreMedicoEvaluador, setNombreMedicoEvaluador] = useState(tamizajeDermatologico?.tam_detalles?.evaluador?.nombres ? tamizajeDermatologico?.tam_detalles?.evaluador?.nombres : selectEvaluador ? selectEvaluador.nombres_completos : null);

  // FUNCIONES DE EDITAR USE-CONTEXT
  const editarObservaciones = (nuevaObservacion, Propiedad) => {
    setTamizajeDermatologico(prevState => ({
      ...prevState,
      tam_detalles: {
        ...prevState.tam_detalles,
        [Propiedad]: nuevaObservacion
      }
    }));
  };

  const getMedicos = () => {
    http
      .get("medicina_general/evaluacion_medica/evaluadores/get")
      .then((response) => {
        setEvaluadores(response.data.evaluadores)
      })
  }

  useEffect(() => {
    editarObservaciones(inputSistHalla, "sintomas_hallazgo");
    editarObservaciones(inputObserva, "observaciones");
    editarObservaciones(inputRecomend, "recomendaciones");
    editarObservaciones(inputConclus, "conclusiones");
    const fecha = inputFecha.toLocaleDateString('en-CA');
    editarObservaciones(fecha, "fecha");

  }, [inputSistHalla, inputObserva, inputRecomend, inputConclus, inputFecha]);

  useEffect(() => {
    editarObservaciones(selectEvaluador?.id || tamizajeDermatologico?.tam_detalles?.evaluador?.id, 'evaluador_id');
    editarObservaciones(selectResultado?.id, 'resultado_id');
  }, [selectEvaluador, selectResultado]);


  useEffect(() => {
    if (selectEvaluador != null) {
      setNombreMedicoEvaluador(selectEvaluador.nombres_completos);
      setColegiaturaEv(selectEvaluador.colegiatura);
    }
    else {
      setNombreMedicoEvaluador("");
      setColegiaturaEv("");
    }

  }, [selectEvaluador]);

  useEffect(() => {
    getMedicos();
  }, []);

  const [Archivo, setArchivo] = useState(tamizajeDermatologico?.tam_detalles?.archivo);
  const updateArchivo = (nuevoValor) => {
    setTamizajeDermatologico((tamizajeDermatologico) => ({
      ...tamizajeDermatologico,
      tam_detalles: {
        ...tamizajeDermatologico.tam_detalles,
        archivo: nuevoValor
      },
    }));
  }

  return (
    <div className="w-full h-auto flex flex-column gap-3 TamizajeDerma_AHP">
      <style>
        {`
            .TamizajeDerma_AHP .subtitulos{
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 18px;
              color: #4545bc;
              padding-bottom: 4px;
            }
          `}
      </style>
      <div className="w-full h-auto flex flex-column align-items-center justify-content-center">
        <p className="w-full subtitulos">Síntomas y hallazgos:</p>
        <VoiceButton
          value={inputSistHalla}
          onChange={setinputSistHalla}
          placeholder="Comentario"
          className="w-full"
        />
      </div>
      <div className="w-full h-auto flex flex-column align-items-center justify-content-center">
        <p className="w-full subtitulos">Observaciones:</p>
        <VoiceButton
          value={inputObserva}
          onChange={setinputObserva}
          placeholder="Comentario"
          className="w-full"
        />
      </div>
      <div className="w-full h-auto flex flex-column align-items-center justify-content-center">
        <p className="w-full subtitulos">Recomendaciones:</p>
        <VoiceButton
          value={inputRecomend}
          onChange={setinputRecomend}
          placeholder="Comentario"
          className="w-full"
        />
      </div>
      <div className="w-full h-auto flex flex-column align-items-center justify-content-center">
        <p className="w-full subtitulos">Conclusiones:</p>
        <VoiceButton
          value={inputConclus}
          onChange={setinputConclus}
          placeholder="Comentario"
          className="w-full"
        />
      </div>
      <div className="w-full h-auto flex align-items-center justify-content-center gap-3">
        <div className="w-2 h-auto flex flex-column align-items-start justify-content-center">
          <p className="w-full subtitulos">Fecha:</p>
          <Calendar placeholder="dd/mm/yy" value={inputFecha} onChange={(e) => setinputFecha(e.value)} className="w-full" />
        </div>
        <div className="w-6 h-auto flex flex-column align-items-start justify-content-center">
          <div className="w-full flex align-items-end justify-content-between gap-2">
            <div className="w-9 flex flex-column align-items-start justify-content-center">
              <p className="w-full subtitulos">Evaluador:</p>
              <InputText className="w-full" value={NombreMedicoEvaluador} onChange={(e) => setNombreMedicoEvaluador(e.target.value)} disabled />
            </div>
            <div className="w-3 flex flex-column align-items-start justify-content-center">
              <p className="w-full subtitulos">Colegiatura:</p>
              <InputText className="w-full" value={ColegiaturaEv} onChange={(e) => setColegiaturaEv(e.target.value)} disabled />
            </div>
            <div className="flex flex-column align-items-start justify-content-end">
              <Button style={{ backgroundColor: 'rgb(237, 237, 255)' }} icon="pi pi-search botonBuscarEvaluadorAHP" onClick={() => { setVisibleEvaluador(true) }}></Button>
            </div>
          </div>
        </div>
        <div className="w-4 h-auto flex flex-column align-items-start justify-content-center">
          <p className="w-full subtitulos">Resultado:</p>
          <Dropdown value={selectResultado} onChange={(e) => setselectResultado(e.value)} options={Resultados} optionLabel="nombre"
            placeholder="Resultado" className="w-full" />
        </div>
      </div>

      <EnvioArchivoMedGeneral
        FuncionSetEnvio={setArchivo}
        FuncionUpData={updateArchivo}
        ValorSet={Archivo}
        DataContext={tamizajeDermatologico?.tam_detalles?.archivo} />

      <EvaluadorTableAHP
        medico={"Evaluador"}
        evaluadores={evaluadores}
        visible={visibleEvaluador}
        setVisible={setVisibleEvaluador}
        evaluadorSelect={selectEvaluador}
        setEvaluador={setselectEvaluador} />
    </div>
  );
};
export default TamizajeDermatologicoDetalles;

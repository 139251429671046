import React, { useState } from "react";
import { Dropdown } from 'primereact/dropdown';
import "../../Style/EstiloTablaa.css"
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputNumber } from 'primereact/inputnumber';

export default function TablaRH() {

    const [selectedGrupoSanguineo, setSelectedGrupoSanguineo] = useState(null);
    const gruposanguineo = [
        { name: 'A', code: 'A' },
        { name: 'AB', code: 'AB' },
        { name: 'B', code: 'B' },
        { name: 'O', code: 'O' }
    ];

    const [selectedFactorRH, setSelectedFactorRH] = useState(null);
    const factorrh = [
        { name: 'Negativo', code: 'N' },
        { name: 'Positivo', code: 'P' }
    ];

    return (
        <div className="flex flex-column w-full h-full">
            <div className="flex flex-column w-full" style={{ margin: '20px 10px 0px 10px' }}>
                <table className="tabla">
                    <tr>
                        <th className="encabezado" style={{ border: '0px solid white' }}>Prueba</th>
                        <th className="encabezado">Resultado</th>
                        <th className="encabezado">U.M</th>
                        <th className="encabezado">Rango</th>
                        <th className="encabezado">Valor Normal</th>
                    </tr>
                    <tr>
                        <td className="dato" style={{ border: '0px solid white' }}>61</td>
                        <td className="dato">Grupo Sanguineo</td>
                        <td className="dato" style={{ padding: '8px 8px 8px 8px' }}>
                            <Dropdown value={selectedGrupoSanguineo} onChange={(e) => setSelectedGrupoSanguineo(e.value)} options={gruposanguineo} optionLabel="name"
                                placeholder="" className="w-full md:w-10rem" />
                        </td>
                        <td className="dato"></td>
                        <td className="dato"></td>

                    </tr>
                    <tr>
                        <td className="dato" style={{ border: '0px solid white' }}>62</td>
                        <td className="dato">Factor RH</td>
                        <td className="dato">
                            <Dropdown value={selectedFactorRH} onChange={(e) => setSelectedFactorRH(e.value)} options={factorrh} optionLabel="name"
                                placeholder="" className="w-full md:w-10rem" />
                        </td>
                        <td className="dato"></td>
                        <td className="dato"></td>
                    </tr>
                    <tr>
                        <td className="dato" style={{ border: '0px solid white' }}></td>
                        <td className="dato"></td>
                        <td className="dato" style={{ padding: '8px 8px 8px 8px' }}></td>
                        <td className="dato"></td>
                        <td className="dato"></td>
                    </tr>
                </table>
            </div>
            <div className="flex flex-row w-full h-full gap-3" style={{ justifyContent: 'space-between' }}>
                <div className="flex flex-row gap-3" style={{ alignItems: 'center' }}>
                    <span>Diferencial:</span>
                    <InputNumber placeholder="0" useGrouping={false}/>
                </div>
                <div className="flex flex-row gap-3">
                    <Button label="Grabar" icon="fas fa-save" />
                    <Button label="Recuperar" icon="fas fa-undo" />
                    <Button label="Hematólogo" icon="fas fa-user-md" />
                </div>

            </div>
        </div>
    )
}
import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import "../../../Style/styleDonut.css";
import { Dialog } from "primereact/dialog";
import img1 from "./img/gestion de cliente.svg";

export default function GraficaDona({ porcentaje, Vigente, trabajadores }) {
   const [chartData, setChartData] = useState({});
   const [chartOptions, setChartOptions] = useState({});
   const [visible, setVisible] = useState(false);
   const [containerHovered, setContainerHovered] = useState(false);

   useEffect(() => {
      let backgroundColor, hoverBackgroundColor;

      // Set colors based on "Vigente" prop
      if (Vigente === "VIGENTE") {
         backgroundColor = ["#71F260", "#e9ecef"];
         hoverBackgroundColor = ["#71F260", "#e9ecef"];
      } else if (Vigente === "PENDIENTES") {
         backgroundColor = ["#6098FF", "#e9ecef"];
         hoverBackgroundColor = ["#6098FF", "#e9ecef"];
      } else if (Vigente === "VENCIDOS A LA FECHA DE HOY") {
         backgroundColor = ["#EF4444", "#e9ecef"];
         hoverBackgroundColor = ["#EF4444", "#e9ecef"];
      }

      const data = {
         labels: ["Completado", "Pendiente"],
         datasets: [
            {
               data: [porcentaje, 100 - porcentaje],
               backgroundColor: backgroundColor,
               hoverBackgroundColor: hoverBackgroundColor,
               borderColor: "transparent",
            },
         ],
      };
      const options = {
         cutout: "70%",
         plugins: {
            legend: {
               display: false,
            },
            datalabels: {
               formatter: (value, ctx) => {
                  return value + "%";
               },
               color: "#000",
               font: {
                  weight: "bold",
               },
            },
         },
      };

      setChartData(data);
      setChartOptions(options);
   }, [porcentaje, Vigente]);

   const handleContainerHover = () => {
      setContainerHovered(true);
   };

   const handleContainerLeave = () => {
      setContainerHovered(false);
   };

   const containerClass = `ContenedorR2 flex justify-content-center flex-1 ${Vigente.toLowerCase()}`;

   return (
      <div
         className={containerClass}
         onMouseEnter={handleContainerHover}
         onMouseLeave={handleContainerLeave}
         style={{ padding: '0px' }}
      >
         <div className="ContenedorR2 flex flex-column gap-1 flex-1">
            <div className="flex flex-row justify-content-between">
               <h6>Trabajadores: {trabajadores}</h6>
               <div className="BotonesGraficaDonut flex flex-row gap-2">
                  <button>
                     <i className="pi pi-sync" />
                  </button>
                  <button onClick={() => setVisible(true)}>
                     <i className="pi pi-window-maximize" />
                  </button>
                  <Dialog header={
                     <>
                        <div className="flex flex-column gap-2">
                           <div className="flex flex-column justify-content-center align-items-center" style={{ background: "#EBEBFF", borderRadius: "10px", width: "60px", height: '60px' }}>
                              <img src={img1} style={{ width: "43px", height: "43px" }} />
                           </div>
                           <h3 style={{ color: "#5555D8", fontSize: "16px" }}>EMO'S {Vigente}</h3>
                        </div>
                     </>
                  } visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                  </Dialog>
               </div>
            </div>
            <h5>EMO'S {Vigente}</h5>
            <div className="flex flex-row w-full justify-content-center">
               <div className="ContenedorR flex justify-content-center flex-row" style={{ width: "240px" }}>
                  <Chart
                     type="doughnut"
                     data={chartData}
                     options={chartOptions}
                     className="DonutM w-full"
                  />
                  <span className="porcentaje-overlay">{porcentaje}%</span>
               </div>
            </div>
         </div>
      </div>
   );
}

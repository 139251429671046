import Bienvenidos from "../Bienvenidos/Bienvenidos";
import TutorialesVista from "../TutorialesVista/TutorialesVista";
import ContactenosB from "../ContactenosB/ContactenosB";
import TutorialesB from "../TutorialesB/TutorialesB";
import '../../../Style/EstilosSoporteBregma/Responssive_SoporteAH.css'
import { useEffect, useState } from "react";


// import SoporteTecnicoPrinc  from '../../Components/Soporte/SoporteTecnicoPrinc';
// import soporte from "../../Images/soporte.jpg";

export default function Soporte({FContactenosB, FTutorialesB}) {
    let campos = {
		"clinica": [{ "label": "perfil" }, { "label": "operaciones" }, { "label": "Productos" }, { "label": "ventas" }],
		"corporativo": [{ "label": "perfil" }, { "label": "operaciones" }, ]
	}
    let formfortuto={
		"corporativo": {
			perfil: '',
			productos_servicios: '',
			operaciones: '',
			ventas: '',
			recursos_humanos: '',
			contabilidad: '',
			estadistica: '',
			soporte_tecnico: '',
		}, "clinica": {
			perfil: '',
			productos_servicios: '',
			operaciones: '',
			ventas: '',
			recursos_humanos: '',
			contabilidad: '',
			estadistica: '',
			soporte_tecnico: '',
		}, "usuario": {
			perfil: '',
			productos_servicios: '',
			operaciones: '',
			ventas: '',
			recursos_humanos: '',
			contabilidad: '',
			estadistica: '',
			soporte_tecnico: '',
		}
	}

    const getTutorials=()=>{
        let form={};
        Object.keys(campos).map((element)=>
        {
            if(form.hasOwnProperty(element)==false){
                form[element]={}
            }
            campos[element].map((elmt)=>
            {
                form[element][elmt.label]=""
            })
        })
        return form
    }
    useEffect(()=>
    {
       let values= getTutorials()
       setFormTutorialesBreg(values)
    },[])
    
    const [formTutorialesBreg, setFormTutorialesBreg] = useState();
    function SendContactenosB(data) {
        FContactenosB(data);
    }

    function SendTutorialesB(data) {
        FTutorialesB(data);
    }
    const Estilo ={
        width:'100%',height:'100%'
    }
    const hadleVistaSelected=(elemento)=>{
        let itemSelected;
        switch(elemento){
            case "Cont_Azul":
                itemSelected="corporativo"
                break;

            case "Cont_Verde":
                itemSelected="clinica";
                break;

            case "Cont_Amarillo":
                itemSelected="usuario"
                break;

        }
        setVistaSelected(itemSelected)
        
    }
    const [vistaselected,setVistaSelected]=useState("corporativo")
    return (
        <div className='contenedorBases'>
            <div className="titulo_subtitulo_Soporte_AHP">
                <h2 id="titulo_principal_soporte_AH">Soporte técnico</h2>
                <h5 id="Subtitulo_principal_soporte_AH">Edita este contenido para ayudar a tu público.</h5>
            </div>
            <div className="subcontenedor_data">
                <div className="contenedor_Bienvenidos_tuto">
                    <div className="subContentenedorBienevenido" >
                        {/*  @Waldo */}
                        <Bienvenidos />
                    </div>

                    <div className="subContentenedortutotrialVista">
                        {/* @Isaac Stevens */}
                        <TutorialesVista  vistaselected={vistaselected} setVistaSelected={hadleVistaSelected}/>
                    </div>
                </div>

                <div className="contenedor_contactenos">
                    {/* @David Ruiz Gozales  y @Johan Mandujano */}
                    <ContactenosB SendContactenosB={SendContactenosB}  vistaselected={vistaselected} />
                </div>

                <div className="contenedor_tutoriales">
                    {/* @eduardo roman  y @Marcos Vargas Mayta */}
                    <TutorialesB SendTutorialesB={SendTutorialesB} vistaselected={vistaselected} formTutorialesBreg={formTutorialesBreg}setVistaSelected={hadleVistaSelected} TutorialItems={campos} setFormTutorialesBreg={setFormTutorialesBreg}/>
                </div>
            </div>
        </div>
    );
}

import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState, useContext } from 'react';
import { OftContext } from "../../OftContext";
import '../../../../Style/style6.css';
import { RAContext } from "../../../RutaAtencion/RAContext";

export default function LadoDerecho() {
    let { agudVisualContext, setAgudVisualContext } = useContext(OftContext);
    let { pageViewContext, stateAtencion } = useContext(RAContext)

    const [CorrSi_VisCer_OjoDer, setCorrSi_VisCer_OjoDer] = useState(agudVisualContext?.corregido_si?.vision_cerca?.ojo_derecho ?? "20/20");
    const [CorrSi_VisCer_OjoIzq, setCorrSi_VisCer_OjoIzq] = useState(agudVisualContext?.corregido_si?.vision_cerca?.ojo_izquierdo ?? "20/20");
    const [CorrSi_VisCer_OjoBin, setCorrSi_VisCer_OjoBin] = useState(agudVisualContext?.corregido_si?.vision_cerca?.binocular ?? null);

    const [CorrSi_VisLej_OjoDer, setCorrSi_VisLej_OjoDer] = useState(agudVisualContext?.corregido_si?.vision_lejos?.ojo_derecho ?? "20/20");
    const [CorrSi_VisLej_OjoIzq, setCorrSi_VisLej_OjoIzq] = useState(agudVisualContext?.corregido_si?.vision_lejos?.ojo_izquierdo ?? "20/20");
    const [CorrSi_VisLej_OjoBin, setCorrSi_VisLej_OjoBin] = useState(agudVisualContext?.corregido_si?.vision_lejos?.binocular ?? null);
    const [dataLoaded, setDataLoaded] = useState(false);

    useEffect(() => {
        setAgudVisualContext({
            ...agudVisualContext,
            [`corregido_si`]: {
                [`vision_cerca`]: {
                    [`ojo_derecho`]: CorrSi_VisCer_OjoDer,
                    [`ojo_izquierdo`]: CorrSi_VisCer_OjoIzq,
                    [`binocular`]: CorrSi_VisCer_OjoBin
                },
                [`vision_lejos`]: {
                    [`ojo_derecho`]: CorrSi_VisLej_OjoDer,
                    [`ojo_izquierdo`]: CorrSi_VisLej_OjoIzq,
                    [`binocular`]: CorrSi_VisLej_OjoBin
                }
            }
        })
    }, [CorrSi_VisCer_OjoDer, CorrSi_VisCer_OjoIzq, CorrSi_VisCer_OjoBin, CorrSi_VisLej_OjoDer, CorrSi_VisLej_OjoIzq, CorrSi_VisLej_OjoBin]);
    
    const optionsCerca = [
        { label: 'No usa', id: '1' },
        { label: '20/10', id: '2' },
        { label: '20/13', id: '3' },
        { label: '20/15', id: '4' },
        { label: '20/16', id: '5' },
        { label: '20/20', id: '6' },
        { label: '20/25', id: '7' },
        { label: '20/30', id: '8' },
        { label: '20/32', id: '9' },
        { label: '20/40', id: '10' },
        { label: '20/50', id: '11' },
        { label: '20/60', id: '12' },
        { label: '20/63', id: '13' },
        { label: '20/70', id: '14' },
        { label: '20/80', id: '15' },
        { label: '20/100', id: '16' },
        { label: '20/125', id: '17' },
        { label: '20/150', id: '18' },
        { label: '20/160', id: '19' },
        { label: '20/200', id: '20' },
        { label: '20/250', id: '21' },
        { label: '20/320', id: '22' },
        { label: '20/400', id: '23' },
        { label: '20/500', id: '24' },
        { label: '20/800', id: '25' },
        { label: 'Ausente', id: '26' }
    ];
    const optionsLejos = [
        { label: 'No usa', id: '1' },
        { label: '20/10', id: '2' },
        { label: '20/13', id: '3' },
        { label: '20/15', id: '4' },
        { label: '20/16', id: '5' },
        { label: '20/20', id: '6' },
        { label: '20/25', id: '7' },
        { label: '20/30', id: '8' },
        { label: '20/32', id: '9' },
        { label: '20/40', id: '10' },
        { label: '20/50', id: '11' },
        { label: '20/60', id: '12' },
        { label: '20/63', id: '13' },
        { label: '20/70', id: '14' },
        { label: '20/80', id: '15' },
        { label: '20/100', id: '16' },
        { label: '20/125', id: '17' },
        { label: '20/150', id: '18' },
        { label: '20/160', id: '19' },
        { label: '20/200', id: '20' },
        { label: '20/250', id: '21' },
        { label: '20/320', id: '22' },
        { label: '20/400', id: '23' },
        { label: '20/500', id: '24' },
        { label: '20/800', id: '25' },
        { label: 'CD', id: '26' },
        { label: 'PL', id: '27' },
        { label: 'Ausente', id: '28' }
    ];

    const optionsCeca = [
        { label: 'No usa', id: '1' },
        { label: '0.50', id: '2' },
        { label: '0.75', id: '3' },
        { label: '1.00', id: '4' },
        { label: '1.25', id: '5' },
        { label: '1.50', id: '6' },
        { label: '1.75', id: '7' },
        { label: '2.00', id: '8' }
    ];

    const optionsLejosB = [
        { label: 'No usa', id: '1' },
        { label: '20/10', id: '2' },
        { label: '20/13', id: '3' },
        { label: '20/15', id: '4' },
        { label: '20/16', id: '5' },
        { label: '20/20', id: '6' },
        { label: '20/25', id: '7' },
        { label: '20/30', id: '8' },
        { label: '20/32', id: '9' },
        { label: '20/40', id: '10' },
        { label: '20/50', id: '11' },
        { label: '20/60', id: '12' },
        { label: '20/63', id: '13' },
        { label: '20/70', id: '14' },
        { label: '20/80', id: '15' },
        { label: '20/100', id: '16' },
        { label: '20/125', id: '17' },
        { label: '20/150', id: '18' },
        { label: '20/160', id: '19' },
        { label: '20/200', id: '20' },
        { label: '20/250', id: '21' },
        { label: '20/320', id: '22' },
        { label: '20/400', id: '23' },
        { label: '20/500', id: '24' },
        { label: '20/800', id: '25' },
        { label: 'CD', id: '26' },
        { label: 'MM', id: '27' },
        { label: 'PL', id: '28' },
        { label: 'Ausente', id: '29' }
    ];

    const [containerStyleinteerpretacion, setContainerStyleotinteerpretacion] = useState({});
    const [headerStyleotinteerpretacion, setHeaderStyleinteerpretacion] = useState({});

    useEffect(() => {
        const contenedor = document.getElementById('miContenedorinteerpretacion');
        const encabezado = contenedor.querySelector('.encabezadoCaeinteerpretacion');

        contenedor.addEventListener('mouseover', function () {
            setContainerStyleotinteerpretacion({ border: '1px solid #7A7AEE', borderRadius: '12px' });
            setHeaderStyleinteerpretacion({ backgroundColor: '#7A7AEE', color: 'white' });
        });

        contenedor.addEventListener('mouseout', function () {
            setContainerStyleotinteerpretacion({});
            setHeaderStyleinteerpretacion({});
        });
    }, []);

    return (
        <div className="flex flex-row w-full">
            {/* Sin Corregir */}
            <div className="contenedorParteSuperiorAgudezaVisual solid-border flex-1" >
                <div className='contenedorCae flex flex-column gap-2 w-full flex-1' id='miContenedorinteerpretacion' style={containerStyleinteerpretacion}>
                    <div className='encabezadoCae' style={{ ...headerStyleotinteerpretacion, padding: '25px' }}>
                        <span style={{ fontWeight: 'bold', fontSize: '17px' }}>Corregida</span>
                    </div>
                    <div className="desaparecer">
                        <div className="flex flex-row " style={{ background: '#F3F6FF', height: '26px', alignItems: 'center', marginTop: '-10px' }}>
                            <span className="flex flex-row w-full" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center' }}>Ojo Derecho</span>
                            <span className="flex flex-row w-full" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center' }}>Ojo Izquierda</span>
                            <span className="flex flex-row w-full" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center' }}>Binocular</span>
                        </div>
                    </div>
                    <div className='CuerpoCae flex flex-column gap-3'>
                        <div className="flex flex-column">
                            <div className="desapier">
                                <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center' }}>Visión de Cerca</span>
                            </div>
                            <div className="flex flex-row ramp-padre">
                                <div className="flex flex-row w-full ramp" style={{ padding: '5px' }}>
                                    <div className="tits">
                                        <span className="flex flex-row w-full" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "11px", background: '#F3F6FF' }}>Ojo Derecho</span>
                                        <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', width: "100%", padding: "7px" }}>Visión de Cerca</span>
                                    </div>
                                    <Dropdown
                                        options={optionsCerca}
                                        optionValue="label"
                                        value={CorrSi_VisCer_OjoDer}
                                        onChange={e => setCorrSi_VisCer_OjoDer(e.value)}
                                        className="w-full"
                                        placeholder="Seleccionar"
                                        disabled={stateAtencion[pageViewContext].estado === 3}
                                    />
                                    <div className="aparicion">
                                        <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "10px" }}>Visión de Lejos</span>
                                        <Dropdown
                                            options={optionsLejos}
                                            optionValue="label"
                                            value={CorrSi_VisLej_OjoDer}
                                            onChange={e => setCorrSi_VisLej_OjoDer(e.value)}
                                            className="w-full"
                                            placeholder="Seleccionar"
                                            disabled={stateAtencion[pageViewContext].estado === 3}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full ramp" style={{ padding: '5px' }}>
                                    <div className="tits">
                                        <span className="flex flex-row w-full" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "11px", background: '#F3F6FF' }}>Ojo Izquierda</span>
                                        <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', width: "100%", padding: "7px" }}>Visión de Cerca</span>
                                    </div>
                                    <Dropdown
                                        options={optionsCerca}
                                        optionValue="label"
                                        value={CorrSi_VisCer_OjoIzq}
                                        onChange={e => setCorrSi_VisCer_OjoIzq(e.value)}
                                        className="w-full"
                                        placeholder="Seleccionar"
                                        disabled={stateAtencion[pageViewContext].estado === 3}
                                    />
                                    <div className="aparicion">
                                        <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "10px" }}>Visión de Lejos</span>
                                        <Dropdown
                                            options={optionsLejos}
                                            optionValue="label"
                                            value={CorrSi_VisLej_OjoIzq}
                                            onChange={e => setCorrSi_VisLej_OjoIzq(e.value)}
                                            className="w-full"
                                            placeholder="Seleccionar"
                                            disabled={stateAtencion[pageViewContext].estado === 3}
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full ramp" style={{ padding: '5px' }}>
                                    <div className="tits">
                                        <span className="flex flex-row w-full" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "11px", background: '#F3F6FF' }}>Binocular</span>
                                        <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "7px", width: "100%" }}>Visión de Cerca</span>
                                    </div>
                                    <Dropdown
                                        options={optionsCeca}
                                        optionValue="label"
                                        value={CorrSi_VisCer_OjoBin}
                                        onChange={e => setCorrSi_VisCer_OjoBin(e.value)}
                                        className="w-full"
                                        placeholder="Seleccionar"
                                        disabled={stateAtencion[pageViewContext].estado === 3}
                                    />
                                    <div className="aparicion">
                                        <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center', padding: "10px" }}>Visión de Lejos</span>
                                        <Dropdown
                                            options={optionsLejosB}
                                            optionValue="label"
                                            value={CorrSi_VisLej_OjoBin}
                                            onChange={e => setCorrSi_VisLej_OjoBin(e.value)}
                                            className="w-full"
                                            placeholder="Seleccionar"
                                            disabled={stateAtencion[pageViewContext].estado === 3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sape">
                            <div className="flex flex-column">
                                <span className="flex flex-row" style={{ fontWeight: '500', fontSize: '14px', color: '#565656', justifyContent: 'center' }}>Visión de Lejos</span>
                                <div className="flex flex-row">
                                    <div className="flex flex-row w-full" style={{ padding: '5px' }}>
                                        <Dropdown
                                            options={optionsLejos}
                                            optionValue="label"
                                            value={CorrSi_VisLej_OjoDer}
                                            onChange={e => setCorrSi_VisLej_OjoDer(e.value)}
                                            className="w-full"
                                            placeholder="Seleccionar"
                                            disabled={stateAtencion[pageViewContext].estado === 3}
                                        />
                                    </div>
                                    <div className="flex flex-row w-full" style={{ padding: '5px' }}>
                                        <Dropdown
                                            options={optionsLejos}
                                            optionValue="label"
                                            value={CorrSi_VisLej_OjoIzq}
                                            onChange={e => setCorrSi_VisLej_OjoIzq(e.value)}
                                            className="w-full"
                                            placeholder="Seleccionar"
                                            disabled={stateAtencion[pageViewContext].estado === 3}
                                        />
                                    </div>
                                    <div className="flex flex-row w-full" style={{ padding: '5px' }}>
                                        <Dropdown
                                            options={optionsLejosB}
                                            optionValue="label"
                                            value={CorrSi_VisLej_OjoBin}
                                            onChange={e => setCorrSi_VisLej_OjoBin(e.value)}
                                            className="w-full"
                                            placeholder="Seleccionar"
                                            disabled={stateAtencion[pageViewContext].estado === 3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

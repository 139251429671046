import React, { useEffect, useState, useRef } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const VoiceButton = ({ onChange, placeholder, value, disabled }) => {
   const [isRecognitionStarted, setIsRecognitionStarted] = useState(false);
   const [recognition, setRecognition] = useState(null);
   const [isListening, setIsListening] = useState(false);
   const [isRecording, setIsRecording] = useState(false);
   const textareaRef = useRef(null);

   useEffect(() => {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      const newRecognition = new SpeechRecognition();
      newRecognition.lang = 'es-ES';
      newRecognition.continuous = true;
      newRecognition.interimResults = true; 

      newRecognition.onstart = () => {
         setIsRecognitionStarted(true);
         setIsListening(true);
      };

      newRecognition.onresult = (event) => {
         let tempTranscript = '';
       
         for (let i = 0; i < event.results.length; ++i) {
           tempTranscript += event.results[i][0].transcript;
         }
       
         if (tempTranscript.trim() !== '') {
           let palabras = tempTranscript.split(/\s+/); // Separar cada palabra por espacios en blanco
       
           // Optimizar cada palabra
           palabras = palabras.map(palabra => palabra.trim().toLowerCase());
       
           // Eliminar símbolos de puntuación no deseados y reconstruir el texto
           let textoCompleto = value + ' ' + palabras.join(' ');
           textoCompleto = textoCompleto.replace(/[^a-zA-ZáéíóúüñÁÉÍÓÚÜÑ0-9\s]/g, ''); // Eliminar caracteres no deseados
       
           // Coincidencia de patrones para detectar datos personales
           const telefonoRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g; // Números de teléfono
           const emailRegex = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/g; // Direcciones de correo electrónico
           const fechaRegex = /\b\d{1,2}\/\d{1,2}\/\d{4}\b/g; // Fechas en formato dd/mm/yyyy
           const edadRegex = /\b(\d{1,2})\s*(?:años|años\sde\sedad)\b/g; // Edades
       
           // Reemplazar datos sensibles con etiquetas
           textoCompleto = textoCompleto.replace(telefonoRegex, '<TELÉFONO>');
           textoCompleto = textoCompleto.replace(emailRegex, '<EMAIL>');
           textoCompleto = textoCompleto.replace(fechaRegex, '<FECHA>');
           textoCompleto = textoCompleto.replace(edadRegex, (match, edad) => {
             return match;
           });
       
           // Verificar si el último carácter es un punto
           const ultimoCaracter = textoCompleto.charAt(textoCompleto.length - 1);
           let nuevoTexto = textoCompleto;
       
           if (ultimoCaracter === '.') {
             // Si el último carácter es un punto, verificar si es punto seguido o punto final
             if (textoCompleto.endsWith('. ')) {
               // Punto seguido
               nuevoTexto = textoCompleto + ' '; // Agregar espacio después del punto seguido
             } else {
               // Punto final
               nuevoTexto = textoCompleto + '\n'; // Agregar salto de línea después del punto final
             }
           }
       
           onChange(nuevoTexto.trim()); // Se utiliza trim() para eliminar espacios en blanco adicionales al inicio y al final
           // Verificar si textareaRef.current no es null antes de acceder a su propiedad scrollHeight
           if (textareaRef.current && textareaRef.current.inputEl) {
             textareaRef.current.inputEl.scrollTop = textareaRef.current.inputEl.scrollHeight;
           }
         }
       };
       
      newRecognition.onend = () => {
         setIsRecognitionStarted(false);
         setIsListening(false);
      };

      setRecognition(newRecognition);
   }, [onChange, value]);

   useEffect(() => {
      return () => {
         if (recognition !== null) {
            recognition.stop();
         }
      };
   }, [recognition]);

   const toggleRecording = () => {
      setIsRecording(prevState => !prevState);
   };

   useEffect(() => {
      if (isRecording && recognition !== null) {
         recognition.start();
      } else if (recognition !== null) {
         recognition.stop();
      }
   }, [isRecording, recognition]);

   return (
      <div className="flex flex-row w-full">
         <div className="flex flex-column w-full">
            <InputTextarea
               ref={textareaRef}
               autoResize
               value={value}
               onChange={(e) => onChange(e.target.value)}
               placeholder={placeholder}
               style={{ height: '131px' }}
               disabled={disabled}
            />
         </div>
         <div className="flex flex-column justify-content-end" style={{ marginLeft: "-40px", marginBottom: "5px" }}>
            <Button
               style={{ height: "25px", padding: "5px" }}
               label={isRecording ? 'Off' : 'On'}
               onClick={toggleRecording}
            />
         </div>
      </div>
   );
};

export default VoiceButton;

export const BotonMinidiente = ({handleButtonClick,cantidad=1, cant}) =>{
    return(
        Array.from({ length: Number(cantidad) }).map((key,index) => 
            <div className="ajustaradia">
                            <div>
                <label className="flex justify-content-center numerom">{index + cant}</label>    
            </div>
            <div className="flex diente" key={index}>
            <div className="flex align-items-center">
              <button  className="miniboton1 " onClick={handleButtonClick} ></button>
            </div>
            <div className="flex flex-column">
              <button className="miniboton2" onClick={handleButtonClick} ></button>
              <div className="flex align-items-center justify-center">
                <button className="miniboton3" onClick={handleButtonClick}></button>
              </div>
              <button className="miniboton4 " onClick={handleButtonClick}></button>
            </div>
            <div className="flex align-items-center">
              <button  className="miniboton5" onClick={handleButtonClick}></button>
            </div>
          
          </div>
            </div>
        )
    )
} 
import React from "react";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";

//Componente general
import { Header, Footer } from "../../../Pure/SeccionesNavegacion";

const DeleteRolModal = ({
   rol,
   rolDelete,
   visibleDelete,
   setVisibleDelete,
   getRoles,
}) => {

   const handleDeleteRol = (id) => {
      //Envía el id del rol a eliminar
      rolDelete(rol.id);
      //Actualiza la lista de elementos
      getRoles();
      setVisibleDelete(false);
   };

   return (
      <div>
         <Dialog
            visible={visibleDelete}
            resizable={false}
            style={{ width: "500px", height: "auto" }}
            className="p-fluid eliminarLocal"
            header={<Header piIcon={"pi pi-trash"} titulo={"Eliminar Rol"} />}
            footer={<Footer onClickEnviar={() => handleDeleteRol(rol?.id)} onClickCancelar={() => { setVisibleDelete(false); }} label={"Eliminar"} />}
            onHide={() => { setVisibleDelete(false); }}
         >
            <div className="w-full h-auto">
               ¿Esta seguro de querer eliminar el Rol?
            </div>
         </Dialog>
      </div>
   );
};

export default DeleteRolModal;
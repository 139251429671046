import React from 'react'
import AceptacionE from './AceptacionE'

function PageAceptacionesE() {
  return (
    <div className=' flex flex-nowrap flex-column w-full' style={{height:"100%"}}>
        <div className='arriba' style={{paddingBottom:"15px"}}>
            <span className='tit'>Reportes de Aceptación</span><br/>
            <span className='con'>En este módulo usted podrá visualizar los resultados de las encuestas realizadas por las areas.</span>
        </div>
        <AceptacionE/>
    </div>
  )
}

export default PageAceptacionesE
import React from 'react';
import "../../../Style/StyleTablas.css";

const AudAudiometriaGridTable2 = ({ title, color, state }) => {
   return (
      <div className="aud_aud_grid_table_2">
         {/*TITLES*/}
         <div className={`aud_aud_grid_table_2_title ${color}`}>
            <label>{title}</label>
         </div>
         {/*SUBTITLES*/}
         <div className='OidoDerechotabla'>
            <div className='row'>
               <div className="col"><span>kHz</span></div>
               <div className="col"><span>250</span></div>
               <div className="col"><span>500</span></div>
               <div className="col"><span>1000</span></div>
               <div className="col"><span>2000</span></div>
               <div className="col"><span>3000</span></div>
               <div className="col"><span>4000</span></div>
               <div className="col"><span>6000</span></div>
               <div className="col"><span>8000</span></div>
            </div>
            {/*BODY*/}
            <div className='row'>
               <div className="col"><span>db</span></div>
               {
                  state && Array.isArray(state) ?
                  state.map((item, index) => {
                        return (
                           <div className="col" key={index}>
                              <span>{item === null ? '' : item}</span>
                           </div>
                        )
                     })
                     :
                     <>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                        <div className="col"><span></span></div>
                     </>
               }
            </div>
         </div>
      </div>
   );
};

export default AudAudiometriaGridTable2;
import React, { useContext, useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";

// Componentes Generales
import AuthUser from "../../../AuthUser.js";
import { RAContext } from "../../../Page/RutaAtencion/RAContext.jsx";
import Consentimiento from "../Consentimiento_Informado/DialogConsentimiento.jsx";

// COMPONETES EXTERNOS
import Entregable from "../Entregables/DialogEntregable.jsx";
import MultiLevelTreeSelectWithAPIClinica from "../../Clinica/ProductoServicio/Servicios/components/SelectTipoServicioClinica.jsx";
import RAResultadosOverlay from "../../RutaAtencion/Resultados/RAResultadosOverlay.jsx";
import ModalInformacion from "../../RutaAtencion/Resultados/ModalInformacio.jsx";

// ESTILOS
import "./Style/Recepcion.css";

// COMPONENTES
import { RecepcionProvider } from "./Components/RecepcionProvider.jsx";
import ModalEmpezarAtencion from "./Components/ModalEmpezarAtencion.jsx";
import ModalFinalizar from "./Components/ModalFinalizar.jsx";
import ModalCrear from "./Components/ModalCrear.jsx";
import ModalActulizarDatos from "./Components/ModalActualizarDatos.jsx";
import CodigoBarrasDialog from "./Components/CodigoBarras.jsx";

import {
   iconCANCELADO,
   iconNO_REALIZADO,
   iconPROCESO,
   iconREALIZADO,
} from "../../../Services/importIcons.js";


const RecepcionComponent = () => {

   const { http } = AuthUser();
   const toast = useRef(null);
   const showToast = (severity, summary, detail) => {
      toast.current.show({ severity: severity, summary: summary, detail: detail });
   };

   const [visibleActulizarDatos, setVisibleActulizarDatos] = useState(false);
   const [pacientes, setPacientes] = useState([]);

   const [paciente, setPaciente] = useState({});
   const [locales, setLocales] = useState([]);
   const [visibleAtencion, setVisibleAtencion] = useState(false);
   const [visibleFinalizar, setVisibleFinalizar] = useState(false);
   const [visibleConsent, setVisibleConsent] = useState(false);
   const [visibleInfo, setVisibleInfo] = useState(false);
   const [visibleEntregable, setVisibleEntregable] = useState(false);
   const [visibleCrear, setVisibleCrear] = useState(false);
   const [checked, setChecked] = useState(false);
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [originalPacientes, setOriginalPacientes] = useState([]);
   const [selectEstablecimiento, setselectEstablecimiento] = useState(null);
   const [selectEvaluaciones, setSelecEvaluaciones] = useState(null);
   const [key, setKey] = useState(0);
   const [ubicaiones, setUbicaciones] = useState(null);
   const [examenes, setExamenes] = useState([]);
   const [configIp, setConfigIp] = useState({
      ip: "192.168.1.1",
      port: "9100"
   })
   const limpiarCalendarios = () => {
      setFechaInicio(null);
      setFechaFin(null);
      setFechaInicioSeleccionada(false);
      setFechaFinSeleccionada(false);
      setFechaValida(true); // limpia los calendarios
      getPacientes();// Actualiza la lista de pacientes
      setselectEstablecimiento(null); // Limpia la selección de establecimiento
      setSelecEvaluaciones(null); // Limpia la selección de evaluaciones
      setSelectedTipoServicio(null); // Limpia la selección de servicios

   };



   const getStatusService = (status) => {
      status = parseInt(status, 10);
      switch (status) {
         case 0:
            return iconNO_REALIZADO;
         case 1:
            return iconPROCESO;
         case 2:
            return iconREALIZADO;
         case 3:
            return iconCANCELADO;
         case 4:
            return null;
         default:
            return null;
      }
   };

   const optionTipoEvaluaciones = [
      { nombre: "Ingreso", id: 1 },
      { nombre: "Periódico", id: 2 },
      { nombre: "Retiro", id: 3 },
   ];
   const [fechaInicio, setFechaInicio] = useState(null);
   const [fechaFin, setFechaFin] = useState(null);

   const handleTipoServicioSelect = (tipoServicioId) => {
      setSelectedTipoServicio(tipoServicioId);
   };

   //Datos importantes para Mostar mensaje de Fecha requerida.
   const [fechaInicioSeleccionada, setFechaInicioSeleccionada] = useState(false);
   const [fechaFinSeleccionada, setFechaFinSeleccionada] = useState(false);

   const [fechaValida, setFechaValida] = useState(true);

   // =================================================================================================================
   //#region filter

   const [globalFilterValue, setGlobalFilterValue] = useState("");


   // const [filtroSeleccionado, setFiltroSeleccionado] = useState(false);

   const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
   });

   const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
   };

   //filtro BOTON

   const handleFiltrar = () => {

      // Verifica si ambas fechas están vacías
      let fechasVacias = (!fechaInicio && !fechaFin);

      // Verifica si solo una de las fechas está vacía y si no se han seleccionado opciones de establecimiento o evaluaciones
      if (((!fechaInicio && fechaFin) || (fechaInicio && !fechaFin)) && (!selectEstablecimiento || !selectEvaluaciones || !selectedTipoServicio)) {
         // Si solo una de las fechas está vacía y no se han seleccionado opciones de establecimiento o evaluaciones
         setFechaValida(false); // Establecer fecha válida como false para mostrar el mensaje
         return;
      }

      setFechaValida(true); // Establece fecha válida como true si no se cumple ninguna de las condiciones anteriores



      let filteredPacientes = originalPacientes;

      if (selectEstablecimiento) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente.clinica_local_id === selectEstablecimiento.id
         );
      }

      if (selectEvaluaciones) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente?.hoja_ruta?.con_perfil_tipo?.tipo_perfil_id ===
               selectEvaluaciones.id
         );
      }

      if (selectedTipoServicio) {
         filteredPacientes = filteredPacientes.filter(
            (paciente) =>
               paciente?.hoja_ruta?.tipo_servicio_id === selectedTipoServicio.id
         );
      }

      if (fechaInicio && fechaFin) {
         const fechaInicioSinHora =
            new Date(
               fechaInicio.getFullYear(),
               fechaInicio.getMonth(),
               fechaInicio.getDate()
            ).getTime() - 100000000;
         const fechaFinSinHora = new Date(
            fechaFin.getFullYear(),
            fechaFin.getMonth(),
            fechaFin.getDate()
         ).getTime();

         filteredPacientes = filteredPacientes.filter((paciente) => {
            const fechaPaciente = new Date(paciente.fecha).getTime();
            return (
               fechaPaciente >= fechaInicioSinHora &&
               fechaPaciente <= fechaFinSinHora
            );
         });
      }
      setPacientes(filteredPacientes);
   };

   //#endregion filter

   //#region apis

   const getPacientes = () => {
      http
         .get("clinica/recepcion/atenciones/get")
         .then((response) => {
            setPacientes(response.data.data);
            setOriginalPacientes(response.data.data);
            setKey(key + 1);
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   const empezarAtencion = () => {
      http
         .put(`clinica/recepcion/atenciones/empezar/${paciente.id}`)
         .then((response) => {
            getPacientes();
            showToast("success", "Atención Iniciada", "La atención ha iniciado correctamente.");
         })
         .catch((err) => {
            console.error(err);
         });
   };

   const GetLocales = () => {
      http
         .get("clinica/recursoshumanos/local/get")
         .then((response) => {
            setLocales(response.data.data); //Asignar o almacenar datos en "locales"
         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   // obtener departamentos, distritos y provincias
   const getAllUbicaciones = () => {
      http
         .get("/ubicacion")
         .then((response) => {
            setUbicaciones(response.data.data);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const generatePDF = async (data) => {
      let examenes = data
         ?.filter((examen) => examen.selected)
         .map((examen) => ({
            hoja_ruta_detalle_id: examen.id,
         }));
      let data_send = {
         examenes: examenes,
      };
      http
         .post(`/clinica/paciente/codigo-barra/${paciente.id}`, data_send)
         .then((response) => {
            response?.data?.resp.map((resp) => {
               printTicket(resp)
               return true;
            }
            )
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const generateBarCodes = (data) => {
      http
         .post("clinica/paciente/particular/generar/codigo-barras", data)
         .then((response) => {
            response?.data?.zpl.map((resp) => {
               printTicket(resp);
               return true;
            })

         })
         .catch((err) => {
            console.error(err.resp);
         });
   };

   const printTicket = async (codigoZPL) => {
      // Abrir una ventana de impresión
      var printWindow = window.open('', '', 'width=200,height=100')
      printWindow.document.write(codigoZPL)
      printWindow.focus()
      // Imprimir
      printWindow.print()
      // Cerrar Ventana
      printWindow.close()
   }

   const handleAtencion = (rowData) => {
      setPaciente(rowData);
      setVisibleAtencion(true);
   };
   const handleFinalizar = (rowData) => {
      setPaciente(rowData);
      setVisibleFinalizar(true);
   };

   const terminarAtencion = (rowData) => {
      http
         .put(`/clinica/recepcion/pacientes/finalizar_atencion/${paciente.id}`)
         .then((response) => {
            getPacientes();
            showToast("success", "Atención Finalizada", "La atención ha sido marcada como finalizada correctamente.");
         })
         .catch((err) => {
            console.error(err);
            showToast("error", "Error", "Hubo un error al intentar finalizar la atención.");
         });
   };


   const actionState = (rowData) => {
      let text = "";
      switch (rowData.estado_atencion) {
         case "0":
            text = "En espera";
            break;
         case "1":
            text = "En atención";
            break;
         case "2":
            text = "Atendido";
            break;
         default:
            text = "No atendido";
      }
      return <Tag value={text} style={{ fontSize: '12px' }} severity={getEstado(rowData)}></Tag>;
   };


   const handleEntregable = (rowData) => {
      setPaciente(rowData);
      setExamenes(rowData?.examenes);
      setVisibleEntregable(true);
   };

   const getEstado = (paciente) => {
      switch (paciente.estado_atencion) {
         case "0":
            return "warning";

         case "1":
            return null;

         case "2":
            return "success";

         default:
            return "danger";
      }
   };

   //Recorrido locales
   const OpcionesLocales = locales?.map((item) =>
      //Seleccionar datos requeridos
      ({ nombre: item.nombre, id: item.id })
   );

   const [DialogCodBarras, setDialogCodBarras] = useState(false);
   const [IdTemporal, SetIdTemporal] = useState(null);
   const acciones = (rowData) => {
      return (
         <div className="flex flex-wrap justify-content-center contenedorbutttonsRecepcionBregAhsp" style={{ gap: '2px' }}>
            <style>
               {`
               .contenedorbutttonsRecepcionBregAhsp button span{
                  font-size: 13px;
               }
               `}
            </style>
            {/*Información*/}
            <Button
               icon="pi pi-info"
               className="border-circle"
               style={{ height: "30px", width: "32px" }}
               tooltip=" Informacion"
               tooltipOptions={{ position: "bottom" }}
               onClick={() => {
                  setPaciente(rowData);
                  setVisibleInfo(true); // Muestra RAInformacion al hacer clic en el botón
               }}
            />

            {/*Editar*/}
            <Button
               icon={"pi pi-pencil"}
               className="border-circle"
               style={{ height: "30px", width: "32px" }}
               tooltip="Actualizar Datos"
               tooltipOptions={{ position: "bottom" }}
               onClick={() => {
                  setVisibleActulizarDatos(true);
                  setPaciente(rowData);
               }}
            />
            {/*Consentimiento*/}
            <Button
               icon={"pi pi-plus"}
               className="border-circle"
               style={{ height: "30px", width: "32px" }}
               tooltip="Ver Consentimientos"
               tooltipOptions={{ position: "bottom" }}
               onClick={() => {
                  setVisibleConsent(true);
                  setPaciente(rowData);
               }}
            />
            {/*Entregable*/}
            <Button
               icon={"pi pi-book"}
               className="border-circle"
               style={{ height: "30px", width: "32px" }}
               tooltip="Ver Entregables"
               tooltipOptions={{ position: "bottom" }}
               onClick={() => handleEntregable(rowData)}
            />

            {/*Atención*/}
            <Button
               icon={"pi pi-send"}
               className="p-button-rounded "
               onClick={() => handleAtencion(rowData)}
               style={{ height: "30px", width: "32px" }}
               tooltip="Iniciar Atención"
               tooltipOptions={{ position: "bottom" }}
            />
            {/*Cambiar estado a terminado*/}
            <Button
               icon={"pi pi-check-circle"}
               className="p-button-rounded "
               onClick={() => handleFinalizar(rowData)}
               style={{ height: "30px", width: "32px" }}
               tooltip="Terminar Atención"
               tooltipOptions={{ position: "bottom" }}
            />
         </div>
      );
   };

   useEffect(() => {
      GetLocales();
      getAllUbicaciones();
      getPacientes();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   //datos principales de UPDATE

   const { listRutasContext } = useContext(RAContext);
   const { setAtencionContext } = useContext(RAContext);
   const { setPacienteContext, setPageViewContext } = useContext(RAContext);

   const [visibleDialog, setVisibleDialog] = useState(false);

   const handleRuta = (rowData) => {
      let data = rowData?.hoja_ruta?.areas_medicas.concat(rowData?.hoja_ruta?.examenes)
      setAtencionContext(data);
      setPacienteContext(rowData);
      setVisibleDialog(true);
      setPaciente(rowData);
   };

   const bodyhojaRuta = (rowData) => {
      return (
         <div
            className="end_ruta"
            style={{
               display: "flex",
               width: "100%",
               alignItems: "center",
               flexWrap: "wrap",
               justifyContent: "center",
            }}
         >
            {rowData?.estado_atencion == 0 ? (
               <>
                  <p style={{ fontSize: "14px" }}>No ha iniciado atención</p>
               </>
            ) :
               (
                  <>
                     <div className="table-icon-link-atenciones-ruta gap-3 pt-2 pb-2">
                        {rowData?.hoja_ruta?.areas_medicas?.map((item, index) => {
                           let hojaRuta = listRutasContext?.findIndex((v) => v?.nombre === item?.nombre);
                           if (hojaRuta !== -1) {
                              return (
                                 <div
                                    className="table-icon-link-item-atenciones-ruta m-0"
                                    style={{ position: "relative" }}
                                    key={index}
                                 >
                                    <img
                                       src={listRutasContext[hojaRuta]?.icon}
                                       width={14}
                                       height={14}
                                       alt="ruta_icons"
                                       title={item.nombre}
                                    />
                                    <img
                                       src={getStatusService(item.estado_ruta)}
                                       width={14}
                                       height={14}
                                       style={{
                                          position: "absolute",
                                          right: "-9px",
                                          top: "-3px",
                                       }}
                                       alt="ruta_icons"
                                    />
                                 </div>
                              );
                           }
                           return null;
                        })}
                        {rowData?.hoja_ruta?.examenes?.map((item, index) => {
                           let hojaRuta = listRutasContext?.findIndex((v) => v?.nombre === item?.nombre);
                           return (
                              <div
                                 className="table-icon-link-item-atenciones-ruta m-0"
                                 style={{ position: "relative" }}
                                 key={index}
                                 title={item.nombre}
                              >
                                 <img
                                    src={listRutasContext[hojaRuta]?.icon}
                                    width={14}
                                    height={14}
                                    alt="ruta_icons"
                                 />
                                 <img
                                    src={getStatusService(item.estado_ruta)}
                                    width={14}
                                    height={14}
                                    style={{
                                       position: "absolute",
                                       right: "-9px",
                                       top: "-3px",
                                    }}
                                    alt="ruta_icons"
                                 />
                              </div>
                           );
                        })}
                     </div>
                     <div className="ContendorbtnHojRutaRecpcBregaP rar_table_user_action_buttons mt-2">
                        <style>
                           {`
                           .ContendorbtnHojRutaRecpcBregaP button span{
                              font-size: 15px;
                           }
                           `}
                        </style>
                        <Button
                           icon="fa-solid fa-briefcase-medical"
                           className="p-button-rounded"
                           onClick={() => handleRuta(rowData)}
                           style={{ width: '30px', height: '30px' }}
                           title="Detalles"
                        />
                     </div>
                  </>
               )}
         </div>

      );
   };
   return (
      <div className="flex flex-wrap flex-column gap-6">
         <Toast ref={toast} />
         <div className="w-full p-4 bg-white border-round-2xl flex flex-column gap-3">
            <div className="w-full flex justify-content-between gap-2 relative">
               <InputText
                  placeholder="Ingresa busqueda"
                  className="w-full pl-6"
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  style={{ height: '40px', maxWidth: '700px', fontSize: '14px' }}
               />
               <i className="pi pi-search absolute" style={{ left: '12px', top: '12px' }} />
               <div className="SectionSearchFiltrosAhpRRecepcions flex justify-content-end flex-row gap-2">
                  <Button
                     className="nuevo"
                     icon="pi pi-plus"
                     label="Crear"
                     onClick={() => setVisibleCrear(true)}
                     style={{ fontSize: "16px", width: "105px", height: "40px", display: "flex", gap: "5px" }}
                  />
                  <Button
                     className=""
                     icon="pi pi-print"
                     label=""
                     onClick={() => setDialogCodBarras(true)}
                     style={{ fontSize: "18px", width: "55px", height: "40px" }}
                  />
               </div>
            </div>
            <div className="ContenedorInputsFiltroAHPRecepcionsdo w-full flex flex-wrap gap-2">
               <style>
                  {`
                  .SectionSearchFiltrosAhpRRecepcions button{
                     display: flex !important;
                     align-items:center;
                  }
                  .SectionSearchFiltrosAhpRRecepcions button span{
                     font-size: 14px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo{
                     justify-content:space-between
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div{
                     display:flex;
                     flex-direction:column;
                     gap: 6px;
                     width: 16.2%;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(2)>div>span{
                     font-size: 14px;
                     padding: 9px 12px !important;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(4)>div>span{
                     font-size: 14px;
                     padding: 9px 12px !important;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(3)>div{
                     height: 40px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(3) div{
                     font-size: 14px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(5) input{
                     font-size: 14px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(6) input{
                     font-size: 14px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(3)>div>div{
                     height: 100% !important;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div p{
                     font-size: 14px;
                     font-weight: 600;
                     color: #393838;
                     padding: 6px 0px 2px 0px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(2){
                     font-size: 14px;
                  }
                  .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(1){
                     font-size: 14px;
                  }
                  @media(max-width:1730px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 24.5%
                     }
                     .ContenedorInputsFiltroAHPRecepcionsdo{
                        justify-content:start
                     }
                  }
                  @media(max-width:1518px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 24.3%
                     }
                  }
                  @media(max-width:1256px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 32.6%
                     }
                     .ContenedorInputsFiltroAHPRecepcionsdo{
                        justify-content:space-between
                     }
                  }
                  @media(max-width:877px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 49.3%
                     }
                  }
                  @media(max-width:653px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 49.1%
                     }
                  }
                  @media(max-width:611px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(2){
                        display: none;
                     }
                     .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(1){
                        margin: auto !important;
                     }
                  }
                  @media(max-width:526px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 48.8%
                     }
                  }
                  @media(max-width:436px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div{
                        width: 100%
                     }
                     .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(2){
                        display: flex;
                        align-items:center;
                        justify-content:center;
                     }
                     .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(1){
                        margin: auto !important;
                     }
                  }
                  @media(max-width:365px){
                     .ContenedorInputsFiltroAHPRecepcionsdo>div:nth-child(7) button span:nth-child(2){
                        display: none;
                     }
                  }
                  `}
               </style>
               <div className="">
                  <p>Establecimiento</p>
                  <Dropdown
                     value={selectEstablecimiento}
                     onChange={(e) => setselectEstablecimiento(e.value)}
                     options={OpcionesLocales}
                     optionLabel="nombre"
                     placeholder="Seleccione"
                     className="w-full"
                     style={{ height: '40px'}}
                     showClear
                  />
               </div>
               <div className="">
                  <p>Servicio</p>
                  <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                  />
               </div>
               <div className="">
                  <p>Tipo de evaluadores</p>
                  <Dropdown
                     value={selectEvaluaciones}
                     onChange={(e) => setSelecEvaluaciones(e.value)}
                     options={optionTipoEvaluaciones}
                     optionLabel="nombre"
                     placeholder="Seleccione"
                     className="w-full"
                     style={{ height: '40px' }}
                     showClear
                  />
               </div>
               <div className="">
                  <p>Fecha Inicial:</p>
                  <Calendar
                     value={fechaInicio}
                     onChange={(e) => { setFechaInicio(e.value); setFechaInicioSeleccionada(true); }}
                     dateFormat="dd/mm/yy"
                     placeholder="dd/mm/aaaa"
                     showIcon
                     style={{ height: '40px' }}
                     className={`${!fechaValida && !fechaInicio ? "p-invalid" : ""} w-full`}
                  />
               </div>
               <div className="">
                  <p>Fecha Final:</p>
                  <Calendar
                     value={fechaFin}
                     onChange={(e) => { setFechaFin(e.value); setFechaFinSeleccionada(true); }}
                     dateFormat="dd/mm/yy"
                     placeholder="dd/mm/aaaa"
                     showIcon
                     style={{ height: '40px' }}
                     className={`${!fechaValida && !fechaFin ? "p-invalid" : ""} w-full`}
                  />
               </div>
               <div className="flex flex-row align-items-end gap-2">
                  <Button
                     onClick={handleFiltrar}
                     label="Filtrar"
                     className="w-6"
                     icon="pi pi-filter"
                     style={{ height: '40px' }}
                  />
                  <Button
                     onClick={limpiarCalendarios}
                     label="Limpiar"
                     className="w-6"
                     icon="pi pi-trash"
                     style={{ height: '40px' }}
                  />
               </div>
            </div>

         </div>
         {/* <div className="flex flex-wrap flex-row gap-2 w-12 contenedorB" style={{ border: '1px solid red' }}>
            <div className="w-full flex flex-wrap relative h-auto" style={{ border: '1px solid green' }}>
               <InputText
                  placeholder="Ingresa busqueda"
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  style={{ height: '30px' }}
               />
               <i className="pi pi-search absolute" />
            </div>
            <div className="w-full flex flex-wrap" style={{ border: '1px solid green' }}></div>

            <div className="flex flex-wrap flex-column gap-3 flex-1">
               <div>
                  <p className="nombre" style={{ marginLeft: "-65px", fontSize: '14px', fontWeight: 'bold' }}>
                     Establecimiento
                  </p>
               </div>
               <div className="card flex justify-content-center">
                  <Dropdown
                     value={selectEstablecimiento}
                     onChange={(e) => setselectEstablecimiento(e.value)}
                     options={OpcionesLocales}
                     optionLabel="nombre"
                     placeholder="Seleccione"
                     className="w-full"
                     style={{ height: "90%", width: "100%" }}
                     showClear
                  />
               </div>
            </div>

            <div className="flex flex-wrap flex-column gap-3 flex-1">
               <p className="nombre" style={{ marginLeft: "-65px", fontSize: '14px', fontWeight: 'bold' }}>
                  Servicio
               </p>
               <MultiLevelTreeSelectWithAPIClinica
                  selectedTipoServicio={selectedTipoServicio}
                  onTipoServicioSelect={handleTipoServicioSelect}
               />
            </div>

            <div className="flex flex-wrap flex-column gap-3 flex-1">
               <div>
                  <p
                     className="nombre"
                     style={{ marginLeft: "-65px", whiteSpace: "nowrap", fontSize: '14px', fontWeight: 'bold' }}
                  >
                     Tipos de evaluaciones
                  </p>
               </div>
               <div className="card flex justify-content-center">
                  <Dropdown
                     value={selectEvaluaciones}
                     onChange={(e) => setSelecEvaluaciones(e.value)}
                     options={optionTipoEvaluaciones}
                     optionLabel="nombre"
                     placeholder="Seleccione"
                     className="w-12"
                     style={{ height: "90%", width: "100%" }}
                     showClear
                  />
               </div>
            </div>
            <div className="flex flex-wrap flex-column gap-3 mt-5">
               <Button
                  onClick={handleFiltrar}
                  label="Filtrar"
                  style={{ fontSize: "16px", height: "40px", display: "flex", gap: "5px" }}
               />

            </div>

            <div
               className="flex flex-wrap flex-column gap-3 mt-5"
            >
               <Button
                  className="nuevo"
                  icon="pi pi-plus"
                  label="Crear"
                  onClick={() => setVisibleCrear(true)}
                  style={{ fontSize: "16px", width: "105px", height: "40px", display: "flex", gap: "5px" }}
               />
            </div>

            <div
               className="flex flex-wrap flex-column gap-3 mt-5"
            >
               <Button
                  className=""
                  icon="pi pi-print"
                  label=""
                  onClick={() => setDialogCodBarras(true)}
                  style={{ fontSize: "18px", width: "60px", height: "40px" }}
               />
            </div>
         </div> */}

         {/* <div className="p-field" style={{ marginTop: "-155px", marginLeft: "32px" }}>
            <div className="flex flex-wrap flex gap-2">
               <div className="flex flex-column gap-1">
                  <p className="p-mb-1 mb-2" style={{ fontSize: '14px', fontWeight: 'bold' }}>Fecha Inicial</p>

                  <Calendar
                     style={{ width: "314px", height: '40px' }}
                     value={fechaInicio}
                     onChange={(e) => { setFechaInicio(e.value); console.log("FechaInicio", fechaInicio); setFechaInicioSeleccionada(true); }}
                     dateFormat="dd/mm/yy"
                     placeholder="dd/mm/aaaa"
                     showIcon
                     className={!fechaValida && !fechaInicio ? "p-invalid" : ""}
                  />

                  {!fechaValida && !fechaInicio && <small className="p-error">Fecha requerida</small>}

               </div>
               <div className="flex flex-column gap-1 " >
                  <p className="p-mb-1 " style={{ fontSize: '14px', fontWeight: 'bold' }}>Fecha Final</p>

                  <Calendar
                     style={{ width: "314px", marginTop: "9px", height: '40px' }}
                     value={fechaFin}
                     onChange={(e) => { setFechaFin(e.value); setFechaFinSeleccionada(true); }}
                     dateFormat="dd/mm/yy"
                     placeholder="dd/mm/aaaa"
                     showIcon
                     className={!fechaValida && !fechaFin ? "p-invalid" : ""}
                  />

                  {!fechaValida && !fechaFin && <small className="p-error">Fecha requerida</small>}

               </div>
               <div className="flex flex-wrap flex-column gap-3 mt-5">

                  <Button
                     onClick={limpiarCalendarios}
                     label="Limpiar"
                     style={{ height: '40px' }}
                  />

               </div>
            </div>
         </div> */}

         <DataTable
            className="TablaRecepcionCSSNew"
            paginator
            value={pacientes}
            key={key}
            filters={filters}
            responsiveLayout="stack"
            rows={5}
            rowsPerPageOptions={[5, 20, 50]}
            tableStyle={{ width: "100%" }}
            style={{ width: "100%", overflowX: "scroll" }}
         >
            <Column
               //field="id"
               header="Ítem"
               align="center"
               body={(rowData, rowIndex) => {
                  return (
                     <div>{rowIndex.rowIndex + 1}</div>
                  );
               }}
               style={{ whiteSpace: "wrap", width: "3.3rem", fontSize: '14px', lineHeight: '16px' }}
            />
            <Column
               field="persona.tipo_documento.nombre"
               header="Tipo de Documento"
               align="center"
               style={{ whiteSpace: "wrap", width: "4.5rem", fontSize: '14px', lineHeight: '16px' }}
            />
            <Column
               field="persona.numero_documento"
               header="Nro. de Documento"
               align="center"
               style={{ whiteSpace: "wrap", width: "6rem", fontSize: '14px', lineHeight: '16px' }}
            />
            <Column
               field="persona.nombres_completos"
               header="Nombres Completos"
               style={{ whiteSpace: "wrap", width: "9rem", fontSize: '14px', lineHeight: '16px' }}
            />
            <Column
               field="fecha_atencion"
               header="Fecha de atención"
               align="center"
               style={{ whiteSpace: "wrap", width: "6rem", fontSize: '14px', lineHeight: '14px' }}
            />

            <Column
               body={bodyhojaRuta}
               header="Hoja de Ruta"
               align="center"
               className="pt-2 pb-2"
               style={{ width: "15%", fontSize: '14px' }}
            />

            <Column
               body={actionState}
               header="Estado"
               align="center"
               className="pl-0 pr-0"
               style={{ whiteSpace: "wrap", width: "4.7rem", fontSize: '14px' }}
            />

            <Column
               body={acciones}
               header="Acciones"
               align="center"
               className="pl-2 pr-2"
               style={{ whiteSpace: "wrap", width: "7.7rem", fontSize: '14px' }}
            />

         </DataTable>

         <ModalEmpezarAtencion
            visible={visibleAtencion}
            setVisible={setVisibleAtencion}
            empezarAtencion={empezarAtencion}
            estadoPaciente={paciente}
         />
         <ModalFinalizar
            visible={visibleFinalizar}
            setVisible={setVisibleFinalizar}
            terminarAtencion={terminarAtencion}
            getPacientes={getPacientes}
            estadoPaciente={paciente}
         />
         <RecepcionProvider>
            <ModalCrear
               setVisibleCrear={setVisibleCrear}
               visibleCrear={visibleCrear}
               checked={checked}
               setChecked={setChecked}
               showRecepcionComponentToast={showToast}
               getPacientes={getPacientes}
            />
         </RecepcionProvider>
         <CodigoBarrasDialog
            generateBarCodes={generateBarCodes}
            visible={DialogCodBarras}
            setVisible={setDialogCodBarras}
         ></CodigoBarrasDialog>
         <Consentimiento
            paciente={paciente}
            visibleConsent={visibleConsent}
            setVisibleConsent={setVisibleConsent}
         />
         <Entregable
            data={paciente}
            examenes={examenes}
            generatePDF={generatePDF}
            visibleEntregable={visibleEntregable}
            setVisibleEntregable={setVisibleEntregable}
            configIp={configIp}
            setConfigIp={setConfigIp}
         />
         <ModalActulizarDatos
            showToast={showToast}
            show={visibleActulizarDatos}
            setShow={setVisibleActulizarDatos}
            data={paciente}
            seData={setPaciente}
            getPacientes={getPacientes}
            ubicaiones={ubicaiones}
         />
         <ModalInformacion
            visible={visibleInfo}
            show={visibleInfo}
            setShow={setVisibleInfo}
            data={paciente}
            seData={setPaciente}
         />
         <RAResultadosOverlay
            visible={visibleDialog}
            setVisible={setVisibleDialog}
         />
      </div>
   );
};



export default RecepcionComponent;
import React, { useState, useEffect, useRef, useContext } from "react";
import { Accordion, AccordionTab } from 'primereact/accordion';
import DetallesExamenFisico from "./Detalles";
import ExamenFisico from "./ExamenFisico";

export default function PageExamenFisico() {
    //RadioButtons
    const [pePeso, setPePeso] = useState('');
    const [obes, setObes] = useState('');

    
    //Desmarcar un radiobutton si ya esta activado
    const handleOnChange = (e, setState) => {
        setState(e.value);
        if (!e.checked) { 
            setState('');
        }
    } 

    useEffect(() => {
    });

    return (
        <div className="card">
            <Accordion className='Acordeon7u7Bonito'>
                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Examen</span>
                    </div>
                } >
                    <ExamenFisico />
                </AccordionTab>
                <AccordionTab header={
                    <div className='flex flex-row gap-2 align-items-center'>
                        <img src="/static/media/EvaluacionM.8afe1930cd2383fdc20d708121791799.svg" />
                        <span>Detalles</span>
                    </div>
                } >
                    <DetallesExamenFisico />
                </AccordionTab>
            </Accordion>
            
        </div>
    )
}
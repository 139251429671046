import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ScrollPanel } from "primereact/scrollpanel";
import { InputText } from "primereact/inputtext";
import { DataTable } from 'primereact/datatable';
import { Column } from "primereact/column";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Button } from "primereact/button";
import { Toast } from 'primereact/toast';

import { startLoading, endLoading } from "../../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

import DialogCoorporativo from '../../../../../Components/Clinica/ProductoServicio/Servicios/components/DialogCorporativo';
import { DialogValidaciones } from "../../../../../Components/Clinica/ProductoServicio/Servicios/components/DialogValidaciones";
import MultiLevelTreeSelectWithAPIClinica from "../../../../../Components/Clinica/ProductoServicio/Servicios/components/SelectTipoServicioClinica";
import { transformData } from "../../../../../Services/ProductosServicios/servicios";
import { ServicioContext } from "../../../../../Components/Clinica/ProductoServicio/Providers/ServicioProvider";
import AuthUser from "../../../../../AuthUser";

import '../style/disenoTabla.css'
import './Style/PageEditarCoorStyeAP.css'
import { setPaquete } from "../services/funciones";

export default function PageEditarServicioCoorporativo() {

   // DropDown
   const toast = useRef(null);
   const { http } = AuthUser();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const { perfilesContext, setServiciosContext, setPerfilesContext, agregarElemento, serviciosValidacionContext, filtrarDatosSeleccionados, serviciosContext, setServiciosValidacionContext } = useContext(ServicioContext);

   const { id: idServicio } = useParams()

   const [servicios, setServicios] = useState([]);
   const [selectedTipoServicio, setSelectedTipoServicio] = useState(null);
   const [visibleDetalle, setVisibleDetalle] = useState(false)
   const [documentos, setDocumentos] = useState([]);
   const [NombreServicio, setNombreServicio] = useState('');
   // DATOS Y FUNCIONES DEL POP UP
   const [VerDialog, setVerDialog] = useState(false);
   const [RowDataActual, setRowDataActual] = useState(null);
   const [TipoDialog, setTipoDialog] = useState(0)
   const [filtro, setFiltro] = useState(null)
   const ArraySimplificado = obtenerElementosConChildrenVacios(servicios);
   const ArraySimplificadoPAdres = obtenerElementosConChildrenLlenos(servicios);

   // Llamar a la función para quitar la clase "Disableddd" de los elementos


   const OpcionesServicio = (rowData, Tipo, PrecioReferencial) => {
      console.log("rowData", rowData)
      return (
         <div style={{ gap: '5px' }}>
            {/* SELECCIONAR */}
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <button
                  className="ElementoSeleccionarBTNN Disableddd"
                  onClick={() => {
                     setVerDialog(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo)
                  }}
               >
                  Seleccionar
               </button>
            </div>
            {/* PRECIO REFERENCIAL */}
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <p className="ElementoPrecioReferencial"> S/. {perfilesContext !== undefined ? rowData[PrecioReferencial].toFixed(2) : "No hay"}</p>
            </div>
            {/* VALIDACIONES */}
            <div className="w-6 h-auto flex align-items-center justify-content-center">
               <Button
                  className="bg-white border-none"
                  icon="pi pi-check-square text-blue-700 text-xl"
                  onClick={() => {
                     setVisibleDetalle(true);
                     setRowDataActual(rowData);
                     setTipoDialog(Tipo);
                  }}></Button>
            </div>
         </div>
      );
   };

   const sendData = async () => {
      const data = ({
         "nombre": NombreServicio,
         "clinica_servicio_id": selectedTipoServicio,
         "tiempo_venta": 1,
         "perfiles": perfilesContext.map(profile => ({
            "nombre": profile.NameTipoPerfil,
            "entrada": {
               "areas_medicas": Object.entries(profile?.Entrada?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('1')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "capacitaciones": [],
               "examenes": Object.entries(profile?.Entrada?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('3')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "documentos": profile.documentosEntrada,
               "validaciones": profile.validacionesEntrada.length > 0 ? profile.validacionesEntrada : []
            },
            "rutina": {
               "areas_medicas": Object.entries(profile?.Rutina?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('1')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "capacitaciones": [],
               "examenes": Object.entries(profile?.Rutina?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('3')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "documentos": profile.documentosRutina,
               "validaciones": profile.validacionesRutina.length > 0 ? profile.validacionesRutina : []
            },
            "salida": {
               "areas_medicas": Object.entries(profile?.Salida?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('1')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "capacitaciones": [],
               "examenes": Object.entries(profile?.Salida?.[0]?.ItemsSeleccionados)?.filter(([key, item]) => key.startsWith('3')).map((item) => (
                  {
                     "id": item[0].slice(2), // Elimina los primeros dos caracteres
                     "parcialmente": 1,
                     ...item[1]
                  }
               )),
               "documentos": profile.documentosSalida,
               "validaciones": profile.validacionesSalida.length > 0 ? profile.validacionesSalida : []
            }
         }))
      });


      dispatch(startLoading());

      try {
         const response = await http.put('clinica/paquete/update/' + idServicio, data);

         if (response?.data?.resp) {
            showToast('success', 'Success', 'El paquete fue creado correctamente')
            navigate('/productosyservicios/servicios')
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }


      // http
      //    .put('clinica/paquete/update/' + idServicio, data)
      //    .then((response) => {
      //       showToast('success', 'Success', 'El paquete fue creado correctamente')
      //       navigate('/productosyservicios/servicios')
      //    })
      //    .catch((error) => {
      //       console.error("algo salio mal", error);
      //       showToast('error', 'Error', 'El paquete no pudo ser creado correctamente')
      //    })
      //    .finally(() => {
      //       dispatch(endLoading());
      //    })
   }

   // DropDown
   const showToast = (severity, summary, message) => {
      toast.current.show({ severity: severity, summary: summary, detail: message, life: 4000 });
   };

   const handleTipoServicioSelect = tipoServicioId => {
      setSelectedTipoServicio(tipoServicioId);
      setFaltaTipoServicio(false);
   };


   function obtenerElementosConChildrenVacios(array) {
      const elementosConChildrenVacios = [];

      function buscarElementosConChildrenVacios(arr) {
         for (let i = 0; i < arr.length; i++) {
            const elemento = arr[i];
            if (elemento.children && Array.isArray(elemento.children) && elemento.children.length === 0) {
               elementosConChildrenVacios.push(elemento);
            } else if (elemento.children && Array.isArray(elemento.children) && elemento.children.length > 0) {
               buscarElementosConChildrenVacios(elemento.children);
            }
         }
      }

      buscarElementosConChildrenVacios(array);

      return elementosConChildrenVacios;
   }



   function obtenerElementosConChildrenLlenos(array) {
      const elementosConChildrenLlenos = [];

      function buscarElementosConChildrenLlenos(arr) {
         for (let i = 0; i < arr.length; i++) {
            const elemento = arr[i];
            if (elemento.children && Array.isArray(elemento.children) && elemento.children.length !== 0) {
               const hijos = elemento.children.map(child => child.key); // Obtener los Ids de los hijos
               elementosConChildrenLlenos.push({ id: elemento.key, cant: elemento.children.length, hijos: hijos });
               buscarElementosConChildrenLlenos(elemento.children);
            }
         }
      }
      buscarElementosConChildrenLlenos(array);
      return elementosConChildrenLlenos;
   }


   const handleKeyDown = (e) => {
      if (e.key === "Escape") {

      }
   };

   const onCellEditComplete = (e) => {
      let { rowData, newValue, field } = e;
      rowData[field] = newValue
   };

   const cellEditor = (options) => {
      return textEditor(options);
   };
   const textEditor = (options) => {
      return <InputText
         style={{ maxHeight: '28px' }}
         placeholder="Ingrese un nombre"
         type="text" value={options.value}
         onChange={(e) => options.editorCallback(e.target.value)}
      />;
   };

   const TituloServicioPrecio = (
      <div className="flex align-items-center justify-content-around" style={{ width: '100%', height: '100%' }}>
         <div className="w-6 h-auto flex align-items-center justify-content-center">
            <p className="text-center mb-0">Servicios</p>
         </div>
         <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
            <p className="text-center mb-0">Precio Referencial</p>
         </div>
         <div className="w-6 h-auto flex align-items-center justify-content-center" style={{ whiteSpace: 'normal', textAlign: 'center' }}>
            <p className="text-center mb-0">Validaciones</p>
         </div>
      </div>

   )
   const headerGroup = (
      <ColumnGroup>
         <Row style={{ width: '100%' }}>
            <Column header="" field="lastYearSale" />
            <Column header="Entrada" colSpan={2} />
            <Column header="Rutina" colSpan={2} />
            <Column header="Salida" colSpan={2} />
         </Row>
         <Row>
            <Column header="Tipo de Perfil" headerStyle={{ width: '22%' }} field="lastYearSale" />
            <Column header={TituloServicioPrecio} field="thisYearSale" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
            <Column header={TituloServicioPrecio} field="lastYearProfit" />
         </Row>
      </ColumnGroup>
   );


   //Buscador
   const busqueda = (e) => {
      if (e.target.value !== '') {
         let datosFiltrados = perfilesContext.filter(data => data.NameTipoPerfil.toLowerCase().includes(e.target.value.toLowerCase()))
         setFiltro(datosFiltrados)
      } else {
         setFiltro(perfilesContext)
      }
   }
   // Transformar data
   const getServicios = async () => {

      dispatch(startLoading());
      try {
         const response = await http.get('clinica/operaciones/servicio/get');

         if (response?.data?.resp || response?.data) {
            setServicios(transformData([response.data]))
            setServiciosContext(transformData([response.data.resp ? response.data.resp : response.data]))
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Datos no actualizados', 'Contactar a soporte técnico');

      } finally {
         dispatch(endLoading());
      }
   }

   const getPaquete = async () => {

      dispatch(startLoading());

      try {
         const response = await http.get(`clinica/paquete/get/${idServicio}`);

         if (response.data.resp) {

            let _paquete = response.data.resp
            setNombreServicio(_paquete?.nombre)
            setPaquete(_paquete, ArraySimplificado, ArraySimplificadoPAdres, setPerfilesContext);
            setSelectedTipoServicio(_paquete?.clinica_servicio_id);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   }

   const getDocumentos = async () => {
      dispatch(startLoading());

      try {
         const response = await http.get("documento/pdf/get");

         if (response?.data?.data || response?.data?.resp) {
            setDocumentos(response.data.data);
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         showToast('error', 'Error', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
      // await http
      //    .get("documento/pdf/get")
      //    .then((response) => {
      //       if (response?.data?.data || response?.data?.resp) {
      //          setDocumentos(response.data.data);
      //       } else if (response?.data?.error) {
      //          showToast('error', 'Error', response.data.error)
      //       }
      //    })
      //    .catch((error) => {
      //       console.error(error);
      //       showToast('error', 'Datos no actualizados', 'Contactar a soporte técnico');
      //    });
   };

   const fetchAllData = async () => {
      try {
         await Promise.all([
            getPaquete(),
            getDocumentos(),
            getServicios(),
         ])
      } catch (error) {

      } finally {
         dispatch(endLoading());
      }
   }

   useEffect(() => {
      dispatch(startLoading());
      fetchAllData();
      document.addEventListener("keydown", handleKeyDown);
      return () => {
         document.removeEventListener("keydown", handleKeyDown);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [FaltaNombrePack, setFaltaNombrePack] = useState(false);
   const [FaltaTipoServicio, setFaltaTipoServicio] = useState(false);

   const VerificarFaltas = () => {
      var verifica = true;
      if (NombreServicio == "") {
         setFaltaNombrePack(true);
         verifica = false;
      }
      if (selectedTipoServicio == "" || selectedTipoServicio == null) {
         setFaltaTipoServicio(true);
         verifica = false;
      }
      return verifica;
   }
   const handleSubmit = () => {
      var veri = VerificarFaltas();
      if (veri) {
         sendData()
      }
   }

   return (
      <div className="add__container">
         <Toast ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Editar Servicio - Coorporativo</h4>
            <p className="add__container_header_p">
               Adminístralo a tu mejor manera
            </p>
         </div>
         <div className="add__container_main">
            <div className="add__container_main_content_header bg-white h-auto p-3 pl-5 pr-5 ContenedorWhiteAHP gap-2 flex flex-wrap align-items-start">

               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Nombre:
                  </label>
                  <span className="p-input-icon-right border-round-lg">
                     <i className="pi pi-pencil" />

                     <InputText
                        placeholder="Paquete anual- Primax"
                        className={`add__container_main_content_header_inputs_singular_input ${FaltaNombrePack ? 'active' : ''}`}
                        value={NombreServicio}
                        onChange={(e) => {
                           setNombreServicio(e.target.value); setFaltaNombrePack(false)
                        }}
                     />
                  </span>
                  <div className={`divFaltaHeighttTransition ${FaltaNombrePack ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaNombrePack ? 'active' : ''}`}>Se necesita ingresar datos</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1">
                     Tipo de servicio:
                  </label>
                  <MultiLevelTreeSelectWithAPIClinica
                     selectedTipoServicio={selectedTipoServicio}
                     onTipoServicioSelect={handleTipoServicioSelect}
                     faltt={FaltaTipoServicio}
                  />
                  <div className={`divFaltaHeighttTransition ${FaltaTipoServicio ? 'active' : ''}`}></div>
                  <p className={`divFaltaHeighttTransitionWord ${FaltaTipoServicio ? 'active' : ''}`}>Se necesita ingresar un tipo de servicio</p>
               </div>


               <div className="flex flex-1 flex-column relative">
                  <label className="add__container_main_content_header_inputs_label mb-1" style={{ opacity: '0' }}>
                     Buscar:
                  </label>
                  <span className=" p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                     <i className=" px-2 pi pi-search" />
                     <InputText placeholder="Buscar..." className="w-full" onChange={(e) => { busqueda(e) }} />
                  </span>
               </div>



               {/* <div className="add__container_main_content_header_group">
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Nombre:
                     </label>
                     <span className="p-input-icon-right border-round-lg" style={{border:'1px solid red'}}>
                        <i className="pi pi-pencil" />

                        <InputText
                           placeholder="Paquete anual- Primax"
                           className="add__container_main_content_header_inputs_singular_input"
                           value={NombreServicio}
                           onChange={(e) => setNombreServicio(e.target.value)}
                        />
                     </span>
                  </div>
                  <div className="add__container_main_content_header_inputs">
                     <label className="add__container_main_content_header_inputs_label">
                        Tipo de servicio:
                     </label>
                     <MultiLevelTreeSelectWithAPIClinica
                        selectedTipoServicio={selectedTipoServicio}
                        onTipoServicioSelect={handleTipoServicioSelect}
                     />
                  </div>
               </div>
               <div className="add__container_main_content_header_group">
                  <div className="contenedor-buscador flex flex-row w-full gap-3" style={{ justifyContent: 'space-between', marginTop: '25px' }}>
                     <span className=" p-input-icon-left flex bg-transparent" style={{ alignItems: 'center' }}>
                        <i className=" px-2 pi pi-search" />
                        <InputText placeholder="Buscar..." style={{ paddingRight: '300px', width: "420px" }} onChange={(e) => { busqueda(e) }} />
                     </span>
                  </div>
               </div> */}
            </div>
            { }
            <div className="add__container_main_content_body">
               <div className="add__container_main_content_body_container">
                  <ScrollPanel
                     style={{ width: "100%", padding: '1rem', alignItems: "center" }}
                     className="custombar2 p-scrollpanel-bar-table"
                  >
                     <div className="card p-fluid" style={{ height: 'auto', backgroundColor: 'transparent' }}>
                        <DataTable
                           className="Cabezera_Tabla_AHP TablaDisabled"
                           value={filtro ? filtro : perfilesContext}
                           headerColumnGroup={headerGroup}
                           editMode="cell"
                           tableStyle={{ width: '100%', height: 'auto' }}
                           size="small"
                        >
                           <Column field="NameTipoPerfil" header="Tipo Perfil:" editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                           <Column header="Entrada:" body={(rowData) => OpcionesServicio(rowData, 1, "PrecioReferencialEntrada")}></Column>
                           <Column header="Rutina:" body={(rowData) => OpcionesServicio(rowData, 2, "PrecioReferencialRutina")} ></Column>
                           <Column header="Salida:" body={(rowData) => OpcionesServicio(rowData, 3, "PrecioReferencialSalida")}></Column>
                        </DataTable>
                        <div className="ParteInferiorTablar">
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                        </div>

                        <DialogCoorporativo
                           visible={VerDialog}
                           setVisible={setVerDialog}
                           rowData={RowDataActual}
                           TipoDialog={TipoDialog}
                           DataGeneral={perfilesContext}
                           setDataGeneral={setPerfilesContext}
                           DataServicios={serviciosContext}
                           documentos={documentos}
                           setServiciosValidacion={setServiciosValidacionContext}
                           filtrarDatosSeleccionados={filtrarDatosSeleccionados}
                        />
                     </div>
                  </ScrollPanel>
               </div>
               <div className="add__container_main_content_footer_one mt-4 md:mt-2 mb-3 md:mb-1">
                  <button
                     className="add__container_main_content_footer_one_btn BotonAgregar BotonAgregarDesabihilitado"
                     style={{ width: '220px' }}
                     onClick={agregarElemento}
                  >
                     <i className="pi pi-plus bg-colo-btn"></i>
                     <p className="bg-colo-btn">Agregar nuevo perfil</p>
                  </button>
               </div>
               <div className="add__container_main_content_footer_two">
                  <button className="add__container_main_content_footer_two_btn ">
                     <Link
                        to="/productosyservicios/servicios/"
                        className="bg-colo-btn"
                        style={{ textDecoration: "none" }}
                     >
                        Cancelar
                     </Link>
                  </button>
                  <button className="button__add_new" onClick={() => { handleSubmit() }}>
                     Actualizar
                  </button>
               </div>
            </div>
         </div>
         <DialogValidaciones
            visible={visibleDetalle}
            setVisible={setVisibleDetalle}
            serviciosValidacionContext={serviciosValidacionContext}
            TipoDialog={TipoDialog}
            rowData={RowDataActual}
            DataGeneral={perfilesContext}
            setDataGeneral={setPerfilesContext}
            filtrarDatosSeleccionados={filtrarDatosSeleccionados}
            DataServicios={serviciosContext}
         />
      </div>
   );
} 
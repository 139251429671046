import React, { useRef, useState } from "react";
import { InputSwitch } from 'primereact/inputswitch';
import 'primeicons/primeicons.css';
import '../PopDetallesDeAtencion.css';
import './StyleClienteCorporativoVR.css';
import '../Chip.css';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";


function ClienteCorporativoVR(props){

    let colorContentEstado='';
    let colorEstado='';
    if(props.estadoPago==='Por cobrar'){colorEstado='#FF6767'; colorContentEstado='#FFECEC'}
    if(props.estadoPago==='En proceso'){colorEstado='#E38621'; colorContentEstado='#FFECD7'}
    if(props.estadoPago==='Cobrado'){colorEstado='#00A15C'; colorContentEstado='#E5F6EF'}
    let colorContentMetodo='';
    let colorMetodo='';
    if(props.ModoPago==='Crédito'){colorMetodo='#7A7AEE';colorContentMetodo='#EBEBFF'}
    if(props.ModoPago==='Efectivo'){colorMetodo='#0581AC';colorContentMetodo='#E0F5FD'}

    const estiloBotones = {width: '171px',height: '44px',borderRadius: '7px', border: '1px solid #D0D5DD', display:'flex', alignItems: 'center', justifyContent:'Center',
fontFamily:'Montserrat', fontSize: '14px', fontWeight:'100', color: '#344054', lineHeight: '20px', marginLeft: '10px'};

    const estiloBotonSelect={
        color:'#FFFFFF'
    }
    const [visibled, setVisibled] = useState(false);
    const handleClick = () => {
        setVisibled(true)
    };
    const [visibled_second, setVisibled_second] = useState(false);
    const handleClick_Second = () => {
        setVisibled_second(true)
    };

    const footerContent = (
        <div style={{display:'flex', alignItems: 'center', justifyContent:'flex-end'}}>
            <Button label="Cancelar" onClick={() => setVisibled(false)} className="p-button-text" style={estiloBotones}/>
			<Button label="Siguiente" onClick={() => handleClick_Second()} autoFocus style={{...estiloBotones,...estiloBotonSelect}}/>
        </div>
    );
    const BodyyyContent = (
        <div className="Boddy_pop_AHP">
            <div className="Contenedor_data_Pago">
                <div className="contenedores_base_pop_Pago AHP_Contendor_principal_01">
                    <div className="connt_contenData connt_contenData_02">
                    <p className="pop_text_normal_AHP">{props.nombreClinica}</p>
                    <p className="pop_text_titulo_AHP">RUC {props.rucClinica}</p>
                    </div>
                    <div className="connt_contenData connt_contenData_03">
                        <div className='Status_pago'>
                            <div className='contenedorChip contenedorChip02' style={{ background:colorContentEstado, paddingLeft:'10px', paddingRight:'10px', height:'21px'}}>
                                <i className='pi pi-circle-fill' style={{ color:colorEstado , fontWeight:500, fontSize:'12px'}}></i>
                                <p className='textChip' style={{ color:colorEstado , fontWeight:500, fontSize:'12px'}}>
                                    {props.estadoPago}
                                </p>
                            </div>
                            <div className='contenedorChip contenedorChip02'  style={{ background:colorContentMetodo, paddingLeft:'10px', paddingRight:'10px', height:'21px'}}>
                                <p className='textChip' style={{ color:colorMetodo , fontWeight:500 , fontSize:'12px'}}>
                                    {props.ModoPago} 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="connt_contenData connt_contenData_02">
                        <p className="pop_text_normal_AHP">N° de proforma</p>
                        <p className="pop_text_titulo_AHP">{props.NmProforma}</p>
                    </div>
                </div>
                <div className="contenedores_base_pop_Pago AHP_Contendor_principal_02">
                    <div className="Content_Data_Pago">
                    <p className="pop_text_normal_02_AHP">Total restante</p>
                    <p className="pop_text_titulo_02_AHP">{props.TotalRestante}</p>
                    </div>

                    <div className="Content_Data_Pago_icono">
                        <i className="pi pi-chevron-right"></i>
                    </div>


                    <div className="Content_Data_Pago">
                    <p className="pop_text_normal_02_AHP">Total por cobrar</p>
                    <p className="pop_text_titulo_02_AHP" style={{color:'#00A15C'}}>{props.TotalPorCobrar}</p>
                    </div>
                </div>
            </div>
        </div>
    );

	const headerrrContent =(
		<div id="pr_id_2_header" className="p-dialog-title headder_pop_AHP">
            <div className="flex flex-column justify-content-center align-items-center contenedor_iconn_Dialog" >
                <img src="/static/media/ActualizarUsuario.d1879e486592632169586d651c1c81ee.svg" alt="" style={{ width: '25px', height: '31.25px' }}/>
            </div>
            <h3 className="text_iconn_Dialog">Detalles de atención</h3>
		</div>	
	);

 
    const estiloFlexCentro={display:'flex',justifyContent:'center', alignItems:'center'};
    const estiloFlexCentroColum={display:'flex',flexDirection:'column',justifyContent:'center', alignItems:'center'};
    const letraGordita={fontFamily: 'Montserrat',fontSize: '12px',fontWeight: '800',lineHeight: '15px',letterSpacing: '0px',textAlign: 'center', color:'#88888E', marginLeft:'6px'}
    // VALORES PARA EL FUNCIONAMIENTO DEL InputSwitch
    const [checked, setChecked] = useState(true);

    // CUERPO DEL SEGUNDO POP
    const headerrrContentSecond =(
        <div id="pr_id_2_header" className="p-dialog-title headder_pop_AHP">
            <h3 className="text_iconn_Dialog">Documentos adicionales</h3>
        </div>	
    );
    const footerContentSecond =(
        <div style={{width:'100%',height:'35px'}}>
        </div>	
    );
    const estiloCentroFlex= {
        display:'flex',alignItems:'center', justifyContent:'center'
    }
    const estiloCentroFlexColumn= {
        display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column'
    }
    const tipoLetraTituloDoc01={
        fontFamily: 'Montserrat',fontSize: '14px',fontWeight: '600',lineHeight: '17px',letterSpacing: '0em',color:'#88888E'}
    const tipoLetraTituloDoc02={
        fontFamily: 'Montserrat',fontSize: '12px',fontWeight: '400',lineHeight: '174.63x',letterSpacing: '0em',color:'#88888E', marginTop:'3px'}
    const bodyContentSecond =(
        <div style={{width:'100%',height:'100%', display:'flex',flexDirection:'column', justifyContent:'flex-end', alignItems:'center'}}>
            <div style={{width:'100%', height:'65px', ...estiloCentroFlex, justifyContent:'space-between', padding:'0px 8px', borderTop:'0.75px solid #E9E9E9'}}>
                <div style={{width:'auto', height:'auto',...estiloCentroFlex,alignItems:'self-start'}}>
                    <div style={{width:'auto', height:'100%'}}>
                        <i className="pi pi-file" style={{color:'#88888E', fontSize:'22px', marginRight:'9px'}}></i>
                    </div>
                    <div>
                        <p style={tipoLetraTituloDoc01}>Factura</p>
                        <p style={tipoLetraTituloDoc02}>Pdf01987390198230.pdf</p>
                    </div>
                </div>
                <div className="botonDowloadlArchivo" style={{width:'45px', height:'45px', backgroundColor:'#EBEBFF', borderRadius:'5px',...estiloCentroFlex}}>
                    <i className="pi pi-download" style={{color:'#5555D8', fontSize:'20px'}}></i>
                </div>
            </div>
            <div style={{width:'100%', height:'65px', borderTop:'0.75px solid #E9E9E9', borderBottom:'0.75px solid #E9E9E9', ...estiloCentroFlex, justifyContent:'space-between', padding:'0px 8px'}}>
                <div style={{width:'auto', height:'auto',...estiloCentroFlex,alignItems:'self-start'}}>
                    <div style={{width:'auto', height:'100%'}}>
                        <i className="pi pi-file" style={{color:'#88888E', fontSize:'22px', marginRight:'9px'}}></i>
                    </div>
                    <div>
                        <p style={tipoLetraTituloDoc01}>Comprobante de pago</p>
                        <p style={tipoLetraTituloDoc02}>Pdf01987390198230.pdf</p>
                    </div>
                </div>
                <div className="botonDowloadlArchivo" style={{width:'45px', height:'45px', backgroundColor:'#EBEBFF', borderRadius:'5px',...estiloCentroFlex}}>
                    <i className="pi pi-download" style={{color:'#5555D8', fontSize:'20px'}}></i>
                </div>
            </div>
        </div>
    )

    return (
        
        <div className='Contenedor_card_coorporativo' style={{width: '360px',height:'260px',backgroundColor:'white', borderRadius:'10px',marginBottom:'35px'}}>
            <div className="sub_contenedor_card" style={{...estiloFlexCentroColum}}>
                <div className="sub_sub_contenedor_data" style={{alignItems:'flex-start',...estiloFlexCentro, justifyContent:'space-between'}}>
                    <div id='contenedor_proforma' style={{...estiloFlexCentroColum, alignItems:'start'}}>
                        <p className="tipo_letra_01">Número de proforma</p>
                        <p className="tipo_letra_02">{props.NmProforma}</p>
                    </div>
                    <div id='contendor_activar_info' style={{...estiloFlexCentro, alignItems:'self-start',}}>
                        <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                        <button id="boton_info_coorporativo" style={{borderRadius:'50%',width:'17px',height:'17px',backgroundColor:'#B5B5B5',border:'0', ...estiloFlexCentro}}>
                            <i style={{color:'white',fontSize:'12px'}} className="pi pi-info"></i>
                        </button>
                    </div>
                </div>
                <div className="sub_sub_contenedor_data" style={{...estiloFlexCentro, justifyContent:'flex-start'}}>
                    <p className="tipo_letra_03" style={{margin:'12px 0 12px 0'}}>{props.nombreClinica}</p>
                </div>
                <div className="sub_sub_contenedor_data" style={{...estiloFlexCentro, justifyContent:'flex-start',marginBottom:'8px'}}>
                    <div className='contenedorChip'  style={{ background:'white', border:'1px solid #D9D9D9',width:'120px', height:'18px'}}>
                        <p className='textChip tipo_letra_RUC' style={{ fontWeight:500, fontSize:'11px'}}>RUC {props.rucClinica}</p>
                    </div> 
                    <div className='contenedorChip' style={{ background:colorContentEstado, width:'auto', height:'18px', paddingLeft:'10px', paddingRight:'10px'}}>
                        <i className='pi pi-circle-fill' style={{ color:colorEstado , fontWeight:500, fontSize:'6px'}}></i>
                        <p className='textChip' style={{ color:colorEstado , fontWeight:500, fontSize:'11px'}}>{props.estadoPago}</p>
                    </div>
                    <div className='contenedorChip'  style={{ background:colorContentMetodo, width:'60px', height:'18px'}}>
                        <p className='textChip' style={{ color:colorMetodo , fontWeight:500 , fontSize:'11px'}}>{props.ModoPago}</p>
                    </div>                
                </div>
            </div>
            <div className="sub_contenedor_card" id='contenedor_icono_archivosSS' style={{...estiloFlexCentro}}>
                <div className="iconos_con_archivos">
                    <i className="pi pi-file" style={{color:'rgb(51, 191, 255)'}}></i>
                    <p style={letraGordita}>{props.archivoCheck}</p>
                </div>
                <div className="iconos_con_archivos">
                    <i className="pi pi-file-pdf" style={{color:'rgb(85, 85, 216)'}}></i>
                    <p style={letraGordita}>{props.archivoEnlace}</p>
                </div>
                <div className="iconos_con_archivos">
                    <i className="pi pi-th-large" style={{color:'rgb(207, 207, 207)'}}></i>
                    <p style={letraGordita}>{props.archivoDividido}</p>
                </div>
                <div className="iconos_con_archivos">
                    <i className="pi pi-clock" style={{color:'rgb(255, 103, 103)'}}></i>
                    <p style={letraGordita}>{props.tiempoRetrasado}</p>
                </div>
            </div>
            <div id="sepadador_contendor" style={{width:'349.02px', borderTop:'0.75px solid #E9E9E9'}}></div>
            <div className="sub_contenedor_card tercer_contenedor_card" style={{...estiloFlexCentro, justifyContent:'space-between', marginTop:'15px'}}>
                <div className="booton_dettales_Pago" style={{...estiloFlexCentro}}>
                    <div className="data_pago_coorporativo">
                        <p className="tipo_letra_04">Total restante</p>
                        <p className="tipo_letra_05" style={{marginTop:'3px'}}>{props.TotalRestante}</p>
                    </div>
                    <i className="pi pi-chevron-right" style={{color:'#565656', margin:'12px'}}></i>
                    <div className="data_pago_coorporativo">
                        <p className="tipo_letra_04">Total por cobrar</p>
                        <p className="tipo_letra_05" style={{color:'#00A15C',marginTop:'3px'}}>{props.TotalPorCobrar}</p>
                    </div>
                </div>
                <div className="booton_dettales_Pago">
                        <button id='fondo_logo_folderCH' style={{width:'35px', height:'35px', borderRadius:'5px'}}onClick={handleClick}>
                            <i className="pi pi-briefcase" style={{color:'#FFA000'}}/>
                        </button>
                        {visibled && (
                        <div className="card flex justify-content-center">
                            <Dialog header={headerrrContent} visible={visibled} style={{ width: '1105px' ,height: '694px' }} onHide={() => setVisibled(false)} footer={footerContent}>
                                    {BodyyyContent}
                                    {visibled_second && (
                                        <Dialog header={headerrrContentSecond} visible={visibled_second} style={{ width: '359px' ,height: '545px' }} onHide={() => setVisibled_second(false)} footer={footerContentSecond}>
                                                {bodyContentSecond}
                                        </Dialog>
                                    )}
                            </Dialog>
                        </div>
                        )}
                </div>
            </div>
        </div>
    )
}
export default ClienteCorporativoVR;
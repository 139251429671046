import React, { useEffect, useState } from "react";
import { AutoComplete } from 'primereact/autocomplete';

export default function SelectEspecialidad({ EspInter, setEspInter, InterData }) {
    const [value, setValue] = useState('');
    const [items, setItems] = useState([]);

    const cities = [
        { name: 'Administración de Hospitales', code: 1 },
        { name: 'Administración de salud', code: 2 },
        { name: 'Anatomia Humana', code: 3 },
        { name: 'Anatomia patolologica', code: 4 },
        { name: 'Anatomia patolologica-patologia clinica', code: 5 },
        { name: 'Anestesia cardiovascular', code: 6 },
        { name: 'Anestesia tesiologica', code: 7 },
        { name: 'Angiologia', code: 8 },
        { name: 'Bioquimica', code: 9 },
        { name: 'Cardiologia', code: 10 },
        { name: 'Cirugia cardiovascular pediatrica', code: 11 },
        { name: 'Cirugia craneomaxilofacial', code: 12 },
        { name: 'Cirugia de cabeza y cuello', code: 13 },
        { name: 'Cirugia de mano', code: 14 },
        { name: 'Cirugia general', code: 15 },
        { name: 'Cirugia general y oncologia', code: 16 },
        { name: 'Cirugia neumologica', code: 17 },
        { name: 'Cirugia pediatria', code: 18 },
        { name: 'Cirugia plastica y reparadora', code: 19 },
        { name: 'Cirugia toraccica y cardiovascular', code: 20 },
        { name: 'Clinica medica', code: 21 },
        { name: 'Dermatologia', code: 22 },
        { name: 'Embriologia', code: 23 },
        { name: 'Endocrinología', code: 24 },
        { name: 'Endocrinología pediátrica y genética', code: 25 },
        { name: 'Enfermedades infecciosas y trópicas', code: 26 },
        { name: 'Epidemiologia', code: 27 },
        { name: 'Farmacologia', code: 28 },
        { name: 'Fisiologia', code: 29 },
        { name: 'Gastroenterologia', code: 30 },
        { name: 'Gastroenterologia pediatrica', code: 31 },
        { name: 'Genetica', code: 32 },
        { name: 'Geriatria', code: 33 },
        { name: 'Ginecologia y obstetricia', code: 34 },
        { name: 'Hematología', code: 35 },
        { name: 'Histologia', code: 36 },
        { name: 'Histopatologia', code: 37 },
        { name: 'Infectologia', code: 38 },
        { name: 'Infectologia pediatrica', code: 39 },
        { name: 'Inmunologia y alergia', code: 40 },
        { name: 'Inmunologia y reumatologia', code: 41 },
        { name: 'Laboratorio clinico', code: 42 },
        { name: 'Laboratorio clinico y anatomia', code: 43 },
        { name: 'Medicina de emergencia y desastres', code: 44 },
        { name: 'Medicina del deporte', code: 45 },
        { name: 'Medicina del trabajo', code: 46 },
        { name: 'Medicina familiar', code: 47 },
        { name: 'Medicina fisica y rehabilitación', code: 48 },
        { name: 'Medicina general', code: 49 },
        { name: 'Medicina general integral', code: 50 },
        { name: 'Medicina general y oncologica', code: 51 },
        { name: 'Medicina integral y gestion en salud', code: 52 },
        { name: 'Medicina intensiva', code: 53 },
        { name: 'Medicina intensiva pediatrica', code: 54 },
        { name: 'Medicina interna', code: 55 },
        { name: 'Medicina legal', code: 56 },
        { name: 'Medico lector oit', code: 57 },
        { name: 'Nefrologia', code: 58 },
        { name: 'Nefrologia pediatrica', code: 59 },
        { name: 'Neonatologia', code: 60 },
        { name: 'Neonatologia pediatrica', code: 61 },
        { name: 'Neurocirugia', code: 62 },
        { name: 'Neurologia', code: 63 },
        { name: 'Neurologia pediatrica', code: 64 },
        { name: 'Nutrición', code: 65 },
        { name: 'Odontologo', code: 66 },
        { name: 'Odontologia', code: 67 },
        { name: 'Oftalmologia', code: 68 },
        { name: 'Oncologia medica', code: 69 },
        { name: 'Oncologia quirurgica', code: 70 },
        { name: 'Ortopedia y traumatologia', code: 71 },
        { name: 'Otorrinolaringologia', code: 72 },
        { name: 'Parasitologia', code: 73 },
        { name: 'Patologia clinica', code: 74 },
        { name: 'Patologia oncologica', code: 75 },
        { name: 'Patologia y laboratorio clinico', code: 76 },
        { name: 'Pediatria', code: 77 },
        { name: 'Proctologia', code: 78 },
        { name: 'Psiquiatria', code: 79 },
        { name: 'Psiquiatria infantil', code: 80 },
        { name: 'Radio Diagnostico', code: 81 },
        { name: 'Radiologia', code: 82 },
        { name: 'Radioterapia', code: 83 },
        { name: 'Reumatologia', code: 84 },
        { name: 'Salud publica', code: 85 },
        { name: 'Servicios otras descapacidades y m?', code: 86 },
        { name: 'Urologia general y oncologica', code: 87 },
        { name: 'Venereologia', code: 88 }
    ];

    const search = (event) => {
        let filteredCities = cities.filter(city => city.name.toLowerCase().startsWith(event.query.toLowerCase()));
        setItems(filteredCities);
    }
    useEffect(() => {
        cities.map((item) => {
            if (item.code == EspInter) {
                setValue(item.name)
            }
        })
    }, [])


    return (
        <div className="" style={{ marginTop: '10px' }}>
            <AutoComplete value={value} suggestions={items} completeMethod={search} field="name"
                valueField="name" onChange={(e) => {setValue(e.value);;setEspInter(e.value.code)}}
                placeholder="Elija una especialidad"
                dropdown />
        </div>
    )
}

import React, { useRef, useState } from "react";
import "./Facturacion.css"
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import PresentacionGeneral from './PresentacionCards/PresentacionGeneral.jsx';
import 'primeicons/primeicons.css';

function Facturacion() {
  function Selector_pagina(miElemento){
    var boton01= document.getElementById('Cliente--clinica-AHP');
    boton01.classList.remove('p-button-outlined');

    var boton02= document.getElementById('Cliente--corporativo-AHP');
    boton02.classList.remove('p-button-outlined');

    var boton03= document.getElementById('Cliente--consultora-AHP');
    boton03.classList.remove('p-button-outlined');

    var boton04= document.getElementById('Cliente--particular-AHP');
    boton04.classList.remove('p-button-outlined');

    var miElement = document.getElementById(miElemento);
    miElement.classList.add("p-button-outlined"); 
  }
  const [visibled_B01, setVisibled_b01] = useState(true);
  const [visibled_B02, setVisibled_b02] = useState(false);
  const [visibled_B03, setVisibled_b03] = useState(false);
  const [visibled_B04, setVisibled_b04] = useState(false);

  const Reiniciador = () => {setVisibled_b01(false);setVisibled_b02(false);setVisibled_b03(false);setVisibled_b04(false);};
  const handleClick01 = () => {setVisibled_b01(true)};
  const handleClick02 = () => {setVisibled_b02(true)};
  const handleClick03 = () => {setVisibled_b03(true)};
  const handleClick04 = () => {setVisibled_b04(true)};

  return (
    <div>
      <div className='contenedor_nav_AHP flex flex-wrap align-items-start '>
        <div className='div_facturacion_AHP h-auto w-100'>
          <span className='tit'>Facturación</span><br />
          <span className='con'>En este módulo usted podrá generar el costo por atención y la facturación de sus productos y/o servicios.</span>
        </div>
        <div className='div_botones_AHP flex lg:flex-nowrap flex-wrap '>
          <a href="../contabilidad/costoatencion"><Button className='botones_Facturacion' label="Costo por Atención" /></a>
          <a href=""><Button className='botones_Facturacion boton_selecccionado' label="Facturación" /></a>
          <a href="../contabilidad/pagos"><Button className='botones_Facturacion' label="Mis pagos" /></a>
        </div>
      </div>
      <div className='contenedor_body_AHP flex-wrap '>
        <div className='Botones_clientes_tipo_AHP lg:flex-nowrap flex-wrap justify-content-start'>
          <Button label="Cliente clínica" id='Cliente--clinica-AHP' className="p-button-outlined botones_Facturacion "
            onClick={() => { Selector_pagina('Cliente--clinica-AHP'); Reiniciador(); handleClick01() }} />
          <Button label="Cliente corporativo" id='Cliente--corporativo-AHP' className="botones_Facturacion"
            onClick={() => { Selector_pagina('Cliente--corporativo-AHP'); Reiniciador(); handleClick02() }} />
          <Button label="Cliente consultora" id='Cliente--consultora-AHP' className=" botones_Facturacion"
            onClick={() => { Selector_pagina('Cliente--consultora-AHP'); Reiniciador(); handleClick03() }} />
          <Button label="Cliente particular" id='Cliente--particular-AHP' className="botones_Facturacion"
            onClick={() => { Selector_pagina('Cliente--particular-AHP'); Reiniciador(); handleClick04() }} />
        </div>
        <div className='Botones_clientes_search_AHP w-100 flex-wrap justify-content-start  '>
          <Button className='botones_Facturacion botonn_reporte_AHP' label="Reporte" icon='pi pi-file' />
          <span className="p-input-icon-left ">
            <i className="pi pi-search " />
            <InputText className='' placeholder="Buscador"  />
          </span>
        </div>
      </div>
      <div className='Contend_botones'>
        {visibled_B01 && (<PresentacionGeneral estiloComponente='ClienteClinicaVR' />)}
        {visibled_B02 && (<PresentacionGeneral estiloComponente='ClienteCorporativoVR' />)}
        {visibled_B03 && (<div></div>)}
        {visibled_B04 && (<div></div>)}
      </div>

    </div>
  )
}

export default Facturacion
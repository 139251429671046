/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext";
import TablaServicios from "../pure/TablaServicios";

export function DialogValidaciones({ visible, setVisible, TipoDialog, rowData, DataGeneral, setDataGeneral, filtrarDatosSeleccionados, DataServicios }) {

   const [tipoDialog, setTipoDialog] = useState("");
   const [selectedNodeId, setSelectedNodeId] = useState(null);
   const [tipoValidacion, setTipoValidacion] = useState("")

   const [indice, setIndice] = useState(0);
   const [serviciosValidacion, setServiciosValidacion] = useState([]);
   const [visibleServicios, setVisibleServicios] = useState(false)
   //Opciones TIPO
   const optionsTipo = [
      { name: "Género", id: "0" },
      { name: "Edad", id: "1" }
   ]

   const optionsGenero = [
      { name: "Hombre", id: "0" },
      { name: "Mujer", id: "1" },
   ]

   const optionsEdad = [
      { name: "Mayores que", id: "0" },
      { name: "Menores que", id: "1" },
   ]

   const [validaciones, setValidaciones] = useState([
      {
         id: 0,
         selectedNodeKeys: [],
         tipo_id: undefined,
         genero_id: undefined,
         edad_id: undefined,
         edad: "",
         descripcion: "Dato estático"
      }
   ])

   //Manejo de tipo edad
   const handleDropdownChange = (e, index) => {
      const value = e.value;
      const nuevosDatos = [...validaciones];
      nuevosDatos[index].tipo_id = value;
      setValidaciones(nuevosDatos);
   };

   const handleDropdownChangeGenero = (e, index) => {
      const nuevosDatos = [...validaciones];
      nuevosDatos[index].genero_id = e.value;
      setValidaciones(nuevosDatos);
   };

   const handleDropdownChangeEdad = (e, index) => {
      const nuevosDatos = [...validaciones];
      nuevosDatos[index].edad_id = e.value;
      setValidaciones(nuevosDatos);
   };

   const handleAreasMedicasButon = (e, index) => {
      setVisibleServicios(true);
      let aux = rowData[tipoDialog][0].OnlyKeys;
      setServiciosValidacion(filtrarDatosSeleccionados(DataServicios, aux))
   };

   const handleChangeServiciosValidacion = (data) => {
      const nuevosDatos = [...validaciones];
      nuevosDatos[indice].selectedNodeKeys = data;
      setValidaciones(nuevosDatos);
   }

   const handleDescripcionChange = (e, index) => {
      const value = e.target.value;
      const nuevosDatos = [...validaciones];
      nuevosDatos[index].edad = value;
      setValidaciones(nuevosDatos);
   };


   const agregarElemento = () => {
      const nuevoElemento = {
         id: validaciones.length > 0 ? validaciones[validaciones.length - 1].id + 1 : 0,
         selectedNodeKeys: [],
         tipo_id: undefined,
         genero_id: undefined,
         edad_id: undefined,
         edad: "",
         descripcion: "Dato estático"
      };
      setValidaciones([...validaciones, nuevoElemento]);
   };
   const handleEliminar = (rowData) => {
      const nuevosDatos = validaciones.filter(item => {
         return item.id !== rowData.id
      });
      setValidaciones(nuevosDatos);
   };

   const rowDataBoton = (e, index) => {
      return (
         <Button className="bg-white border-none"
            icon="pi pi-check-square text-blue-700 text-xl"
            onClick={() => {
               setIndice(index.rowIndex);
               handleAreasMedicasButon(e, indice);
            }} />
      )
   };

   const rowDataTable = (rowData, index) => {
      return (
         <Dropdown
            value={rowData.tipo_id}
            options={optionsTipo}
            onChange={(e) => handleDropdownChange(e, index.rowIndex)}
            optionLabel="name"
            optionValue="id"
            showClear
            placeholder="Seleccione"
         />
      );
   };

   const rowDataDetalle = (rowData, index) => {

      return rowData.tipo_id === undefined ? (<div className="flex justify-content-around">
         <span>Seleccione un tipo de validación</span>
      </div>) :
         (rowData.tipo_id === "1" ?
            (<div className="flex justify-content-around">
               <Dropdown
                  value={rowData.edad_id}
                  options={optionsEdad}
                  onChange={(e) => handleDropdownChangeEdad(e, index.rowIndex)}
                  optionLabel="name"
                  optionValue="id"
                  showClear
                  placeholder="Seleccione"
                  disabled={rowData.tipo_id === "0" || rowData.tipo_id === undefined}
               />
               <InputText
                  value={rowData.edad} // Utiliza la descripción asociada con la fila
                  style={{ width: "40%" }}
                  onChange={(e) => handleDescripcionChange(e, index.rowIndex)}
                  disabled={rowData.tipo_id === "0" || rowData.tipo_id === undefined}

               />
            </div>) :
            (<div className="flex justify-content-around">
               <Dropdown
                  value={rowData.genero_id}
                  options={optionsGenero}
                  onChange={(e) => handleDropdownChangeGenero(e, index.rowIndex)}
                  optionLabel="name"
                  optionValue="id"
                  showClear
                  placeholder="Seleccione"
                  disabled={rowData.tipo_id === "1" || rowData.tipo_id === undefined}
               />
            </div>)
         );
   };




   const BotonEliminar = (rowData) => {
      return (
         <Button
            style={{ backgroundColor: "#FFECEC", color: "#FF6767", border: "0px solid transparent" }}
            onClick={() => handleEliminar(rowData)}
         >
            <i className="pi pi-trash" />
         </Button>
      );
   };

   const handleCancelar = () => {
      setVisible(false);
      setValidaciones([
         {
            id: 0,
            selectedNodeKeys: [],
            tipo_id: undefined,
            genero_id: undefined,
            edad_id: undefined,
            edad: "",
            descripcion: "Dato estático"
         }
      ]);
   };

   const GuardarValidaciones = (validaciones) => {
      const nuevosPerfiles = [...DataGeneral];

      const perfilEncontrado = nuevosPerfiles.find(perfil => perfil.id === selectedNodeId);

      if (perfilEncontrado.hasOwnProperty(tipoValidacion)) {
         perfilEncontrado[tipoValidacion] = validaciones;
      }

      setDataGeneral(nuevosPerfiles);
      setVisible(false)
      setValidaciones([
         {
            id: 0,
            selectedNodeKeys: [],
            tipo_id: undefined,
            genero_id: undefined,
            edad_id: undefined,
            edad: "",
            descripcion: "Dato estático"
         }
      ]);
   }

   const footerContent = (
      <div className="flex justify-content-between">
         <Button label="Agregar" icon="pi pi-plus bg-colo-btn" onClick={agregarElemento} style={{ background: "transparent", color: "#5555D8" }} />
         <div>
            <Button label="Cancelar" icon="pi pi-times" onClick={handleCancelar} className="p-button-text" />
            <Button label="Guardar" icon="pi pi-check"
               onClick={() => GuardarValidaciones(validaciones)}
               autoFocus />
         </div>
      </div>
   );



   useEffect(() => {
      if (TipoDialog) {
         switch (TipoDialog) {
            case 1:
               setTipoDialog("Entrada");
               setTipoValidacion("validacionesEntrada")
               break;
            case 2:
               setTipoDialog("Rutina");
               setTipoValidacion("validacionesRutina")
               break;
            case 3:
               setTipoDialog("Salida");
               setTipoValidacion("validacionesSalida")
               break;
            default:
               setTipoDialog("");
         }
      }
      if (rowData) {
         setSelectedNodeId(rowData.id);
      }
      if (TipoDialog && rowData[tipoValidacion]?.length > 0) {
         setValidaciones(rowData[tipoValidacion]);
      } else {
         setValidaciones([
            {
               id: 0,
               selectedNodeKeys: [],
               tipo_id: undefined,
               genero_id: undefined,
               edad_id: undefined,
               edad: "",
               descripcion: "Dato estático"
            }
         ]);
      }
   }, [TipoDialog, rowData, selectedNodeId, tipoValidacion, visible]);

   return (
      <div>
         <Dialog visible={visible} onHide={() => setVisible(false)} header={
            <div className="flex" style={{ alignItems: 'center' }}>
               <div className="flex" style={{ width: '60px', height: '60px', borderRadius: '10px', justifyContent: 'center', alignItems: 'center', background: '#EBEBFF' }}>
                  <i className="pi pi-book" style={{ fontSize: "32px", color: "#4545BC" }}></i>
               </div>
               <div className="flex flex-column gap-2" style={{ marginLeft: '10px' }} >
                  <span className="tit text-xl">Detalle de {tipoDialog}</span>
               </div>
            </div>}
            footer={footerContent}
         >
            <DataTable value={validaciones}>
               <Column className="flex align-items-center justify-content-center"
                  body={(e, rowData) => rowDataBoton(e, rowData)}
                  header="Seleccionar">
               </Column>
               <Column header="Nombre" body={""}></Column>
               <Column className="text-center"
                  body={(e, rowData) => rowDataTable(e, rowData)}
                  header="Tipo">
               </Column>
               <Column className="text-center"
                  body={(e, rowData) => rowDataDetalle(e, rowData)}
                  header="Detalle">
               </Column>
               {/* <Column className="text-center"
                        body={(e, rowData) => rowDataDesc(e, rowData)}
                        header="Descripción">
                    </Column> */}
               <Column className="text-center w-2" header="Eliminar" body={BotonEliminar}></Column>
            </DataTable>
         </Dialog>

         <TablaServicios
            visible={visibleServicios}
            setVisible={setVisibleServicios}
            data={serviciosValidacion}
            validaciones={validaciones[indice]?.selectedNodeKeys}
            changeValidaciones={handleChangeServiciosValidacion}
         />
      </div>
   )
}
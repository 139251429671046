import React, { useState } from 'react';
import SplitButton from './SplitButton.jsx';

const NotificacionNueva = (props) => {
  const [activeTab, setActiveTab] = useState('todo');
  const [isRead, setIsRead] = useState(false); // Estado para controlar si el mensaje ha sido leído

  const handleMenuOptionClick = (tab) => {
    setActiveTab(tab);
  };

  const handleMarkAsRead = () => {
    setIsRead(true);
  };

  const menuOptions = [
    {
      label: 'Marcar como leído',
      tab: 'option1',
      icon: 'pi pi-check',
      action: handleMarkAsRead // Agregamos una acción para marcar como leído
    },
    // Resto de opciones del menú
    {
             label: 'Destacar',
                  tab: 'option2',
                  icon: 'pi pi-star'
                 },
             {
                  label: 'Avisar más tarde',
        tab: 'option3',
                  icon: 'pi pi-clock'
                  
                },
              ];

  const splitButtonOptions = {
    icon: 'pi pi-ellipsis-h',
    menuItems: menuOptions,
    onOptionClick: handleMenuOptionClick,
  };

  return (
    <div className="flex justify-content-between mensanotigene">
      <div className="inbox">
        <div className="inbox-image flex flex-column justify-content-center align-items-center">
          <img
            className="flex"
            src="https://cdn.discordapp.com/attachments/1078050726217011331/1106273345030332536/Personalidad-gatos.png"
            style={{ width: '30px' }}
          />
        </div>
        <div className="inbox-message flex flex-column gap-3">
          <h4>Hoy</h4>
          <p className="inbox-mensa" style={{ marginTop: '-10px' }}>
            ¡Hey! Jorge Ttito ya tiene que hacer su exámen medico ocupacional en 2 semanas.
          </p>
          <p className="inboxtiempo">Hace un momento</p>
        </div>
      </div>

      <div className="flex align-items-center">
        <div>{!isRead && <div className="purple-dot" />}</div> {/* Punto morado que se muestra si el mensaje no ha sido leído */}
        <div>
          <SplitButton options={splitButtonOptions} menuPosition="left" />
        </div>
      </div>
    </div>
  );
};

export default NotificacionNueva;

import React, { useState, useContext, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { RadContext } from "./RadProvider";

const AnormalidadesParenquimatosasContent = () => {
    let { RayTorax, setRayTorax } =
        useContext(RadContext);

    const cities = [
        { name: "1", code: "1" },
        { name: "2", code: "2" },
        { name: "3", code: "3" },
        { name: "4", code: "4" },
    ];
    const subTituloMorado = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        color: "#4545BC",
        width: "100%",
        height: '50px',
        backgroundColor: '#F6F7FB',
        padding: '7px',
        borderRadius: '10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    };

    const subTituloNormal = {
        fontFamily: "Montserrat",
        fontSize: "15px",
        fontWeight: "700",
        width: "100px",
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
    };

    const actualizarPropiedadesBase = (newvalor, propiedad, secpropiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: {
                ...prevState[propiedad],
                [secpropiedad]: newvalor
            }
        }));
    };
    const actualizarPropiedadesBaseNivel01 = (newvalor, propiedad) => {
        setRayTorax(prevState => ({
            ...prevState,
            [propiedad]: newvalor
        }));
    };

    const ConvertirSelectObjeto = (Array, Respuesta, setvalorRespuesta, propDef) => {
        for (let index = 0; index < Array.length; index++) {
            if (Array[index][propDef] == Respuesta) {
                setvalorRespuesta(Array[index]);
            }
        }
    };

    const OpcionesZonaAfectada = [
        { name: "Superior", code: "1" },
        { name: "Medio", code: "2" },
        { name: "Inferior", code: "3" },
    ];
    const [derecha, setderecha] = useState(null);
    const [derecha_cod, setderecha_cod] = useState(RayTorax.zona_afectada.derecha ? RayTorax.zona_afectada.derecha : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesZonaAfectada, derecha_cod, setderecha, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(derecha_cod, "zona_afectada", "derecha");
    }, [derecha_cod])

    const [izquierda, setizquierda] = useState(null);
    const [izquierda_cod, setizquierda_cod] = useState(RayTorax.zona_afectada.izquierda ? RayTorax.zona_afectada.izquierda : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesZonaAfectada, izquierda_cod, setizquierda, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(izquierda_cod, "zona_afectada", "izquierda");
    }, [izquierda_cod])


    const OpcionesOpacidadesPeque = [
        { name: "0/-", code: "1" },
        { name: "0/0", code: "2" },
        { name: "0/1", code: "3" },
        { name: "1/0", code: "4" },
        { name: "1/1", code: "5" },
        { name: "1/2", code: "6" },
        { name: "2/1", code: "7" },
        { name: "2/2", code: "8" },
        { name: "2/3", code: "9" },
        { name: "3/2", code: "10" },
        { name: "3/3", code: "11" },
        { name: "3/+", code: "12" },
    ];

    const [opacidad_pequena, setopacidad_pequena] = useState(null);
    const [opacidad_pequena_cod, setcausa_cod] = useState(RayTorax.opacidad_pequena ? RayTorax.opacidad_pequena : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpcionesOpacidadesPeque, opacidad_pequena_cod, setopacidad_pequena, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBaseNivel01(opacidad_pequena_cod, "opacidad_pequena");
    }, [opacidad_pequena_cod])



    const formaTamañoOpciones = [
        { name: "p", code: "1" },
        { name: "s", code: "2" },
        { name: "q", code: "3" },
        { name: "t", code: "4" },
        { name: "r", code: "5" },
        { name: "u", code: "6" },
    ];


    const [formaTamaño, setformaTamaño] = useState(null);
    const [formaTamaño_cod, setformaTamaño_cod] = useState(RayTorax.forma_tamano.primaria ? RayTorax.forma_tamano.primaria : null);
    useEffect(() => {
        ConvertirSelectObjeto(formaTamañoOpciones, formaTamaño_cod, setformaTamaño, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(formaTamaño_cod, "forma_tamano", "primaria");
    }, [formaTamaño_cod])

    const [formaTamañoSec, setformaTamañoSec] = useState(null);
    const [formaTamañoSec_cod, setformaTamañoSec_cod] = useState(RayTorax.forma_tamano.secundaria ? RayTorax.forma_tamano.secundaria : null);
    useEffect(() => {
        ConvertirSelectObjeto(formaTamañoOpciones, formaTamañoSec_cod, setformaTamañoSec, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBase(formaTamañoSec_cod, "forma_tamano", "secundaria");
    }, [formaTamañoSec_cod])









    const OpacidadesGrandesOpciones = [
        { name: "0", code: "1" },
        { name: "A", code: "2" },
        { name: "B", code: "3" },
        { name: "C", code: "4" },
    ];

    const [opacidad_Grande, setopacidad_Grande] = useState(null);
    const [opacidad_Grande_cod, setopacidad_Grande_cod] = useState(RayTorax.opacidad_grande ? RayTorax.opacidad_grande : null);
    useEffect(() => {
        ConvertirSelectObjeto(OpacidadesGrandesOpciones, opacidad_Grande_cod, setopacidad_Grande, "code");
    }, [RayTorax])
    useEffect(() => {
        actualizarPropiedadesBaseNivel01(opacidad_Grande_cod, "opacidad_grande");
    }, [opacidad_Grande_cod])



    return (
        <div className="contendorAnromalidadesPArenquimatosasaHPRadiologia 
        w-full h-auto flex pb-2 pr-2 pl-2 gap-2">
            <style>
                {`
                     @media (max-width:640px){
                        .contendorAnromalidadesPArenquimatosasaHPRadiologia{
                            flex-direction: column !important;
                        }
                        .contendorAnromalidadesPArenquimatosasaHPRadiologia > div{
                            width: 100% !important;
                        }
                        .contendorAnromalidadesPArenquimatosasaHPRadiologia > div:nth-child(2)
                        > div:nth-child(2){
                            gap: 13px !important;
                        }
                     }
                     @media (max-width:536px){
                        .contendorAnromalidadesPArenquimatosasaHPRadiologia > div:nth-child(3)
                        > div:nth-child(1){
                            flex-direction:column !important;
                        }
                        .contendorAnromalidadesPArenquimatosasaHPRadiologia > div:nth-child(3)
                        > div:nth-child(1) >div{
                            width: 100% !important;
                        }
                     }
                     @media (max-width:436px){
                        .contendorAnromalidadesPArenquimatosasaHPRadiologia > div:nth-child(3)
                        > div:nth-child(2) > div > div:nth-child(2) > div{
                            width: 100% !important;
                        }
                     }
                  `}
            </style>

            <div className="flex flex-column"
                style={{ width: '40%' }}>
                <div style={{ ...subTituloMorado, marginBottom: '10px' }}>Zonas afectadas: </div>
                <div className=" w-full flex-1 flex flex-column align-items-center justify-content-between">
                    <div className="w-full flex">
                        <div style={subTituloNormal}>Derecha:</div>
                        <div className="flex-1 flex relative">
                            <Dropdown className="w-full" style={{ height: '48px', minWidth: '0px' }} value={derecha}
                                onChange={(e) => setderecha_cod(e.value.code)}
                                options={OpcionesZonaAfectada} optionLabel="name" />
                            {derecha != null ? (
                                <button
                                    className="absolute pi pi-times"
                                    style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                    onClick={() => {
                                        setderecha_cod(null);
                                        actualizarPropiedadesBase(null, "zona_afectada", "derecha"); setderecha(null)
                                    }}></button>
                            ) : null}
                        </div>
                    </div>

                    <div className="w-full flex">
                        <div style={subTituloNormal}>Izquierda:</div>
                        <div className="flex-1 flex relative">
                            <Dropdown className="w-full" style={{ height: '48px', minWidth: '0px' }} value={izquierda}
                                onChange={(e) => setizquierda_cod(e.value.code)}
                                options={OpcionesZonaAfectada} optionLabel="name" />
                            {izquierda != null ? (
                                <button
                                    className="absolute pi pi-times"
                                    style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                    onClick={() => {
                                        setizquierda_cod(null);
                                        actualizarPropiedadesBase(null, "zona_afectada", "izquierda"); setizquierda(null)
                                    }}></button>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className="h-auto flex flex-column gap-2"
                style={{ width: '60%'}}>
                <div className="w-full h-6 flex flex-1 gap-2">
                    <div className="w-6 h-auto flex flex-column">
                        <div style={{ ...subTituloMorado, marginBottom: '10px' }}>Opacidades pequeñas: </div>
                        <div className="flex-1 flex relative">
                            <Dropdown className="w-full" style={{ height: '48px', minWidth: '0px' }} value={opacidad_pequena}
                                onChange={(e) => setcausa_cod(e.value.code)}
                                options={OpcionesOpacidadesPeque} optionLabel="name" />
                            {opacidad_pequena != null ? (
                                <button
                                    className="absolute pi pi-times"
                                    style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                    onClick={() => {
                                        setcausa_cod(null);
                                        actualizarPropiedadesBaseNivel01(null, "opacidad_pequena"); setopacidad_pequena(null)
                                    }}></button>
                            ) : null}
                        </div>
                    </div>
                    <div className="w-6 flex-1 flex flex-column"
                        style={{ width: '30%' }}>
                        <div style={{ ...subTituloMorado, marginBottom: '10px' }}>Opacidades Grandes: </div>
                        <div className="flex-1 flex relative">
                            <Dropdown className="w-full" style={{ height: '48px', minWidth: '0px' }} value={opacidad_Grande}
                                onChange={(e) => setopacidad_Grande_cod(e.value.code)}
                                options={OpacidadesGrandesOpciones} optionLabel="name" />
                            {opacidad_Grande != null ? (
                                <button
                                    className="absolute pi pi-times"
                                    style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                    onClick={() => {
                                        setopacidad_Grande_cod(null);
                                        actualizarPropiedadesBaseNivel01(null, "opacidad_grande"); setopacidad_Grande(null)
                                    }}></button>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="w-full h-6 flex-1" >
                    <div className="w-full h-auto flex flex-column">
                        <div style={{ ...subTituloMorado, marginBottom: '10px' }}>Forma y tamaño: </div>
                        <div className="w-full h-auto flex gap-2">
                            <div className="flex-1 flex relative w-6">
                                <Dropdown className="w-full" style={{ height: '48px', minWidth: '0px' }} value={formaTamaño}
                                    onChange={(e) => setformaTamaño_cod(e.value.code)}
                                    options={formaTamañoOpciones} optionLabel="name" />
                                {formaTamaño != null ? (
                                    <button
                                        className="absolute pi pi-times"
                                        style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                        onClick={() => {
                                            setformaTamaño_cod(null);
                                            actualizarPropiedadesBase(null, "forma_tamano", "primaria"); setformaTamaño(null)
                                        }}></button>
                                ) : null}
                            </div>
                            <div className="flex-1 flex relative w-6">
                                <Dropdown className="w-full" style={{ height: '48px', minWidth: '0px' }} value={formaTamañoSec}
                                    onChange={(e) => setformaTamañoSec_cod(e.value.code)}
                                    options={formaTamañoOpciones} optionLabel="name" />
                                {formaTamañoSec != null ? (
                                    <button
                                        className="absolute pi pi-times"
                                        style={{ right: '39px', top: '16px', background: 'white', border: '0px solid transparent', color: '#6c757d' }}

                                        onClick={() => {
                                            setformaTamañoSec_cod(null);
                                            actualizarPropiedadesBase(null, "zona_afectada", "secundaria"); setformaTamañoSec(null)
                                        }}></button>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AnormalidadesParenquimatosasContent;

export const BotonDiente = ({handleButtonClick,cantidad=1,cant}) =>{
    return(
        Array.from({ length: Number(cantidad) }).map((key, index) => 
            <div className="ajustaradia">
                            <div>
                <label className="flex justify-content-center numerod">{index + cant}</label>    
            </div>
            <div className="flex diente" key={index}>
            <div className="flex align-items-center">
              <button  className="button1 " onClick={handleButtonClick} ></button>
            </div>
            <div className="flex flex-column">
              <button className="button2" onClick={handleButtonClick} ></button>
              <div className="flex align-items-center justify-center">
                <button className="button3" onClick={handleButtonClick}></button>
              </div>
              <button className="button4 " onClick={handleButtonClick}></button>
            </div>
            <div className="flex align-items-center">
              <button  className="button5" onClick={handleButtonClick}></button>
            </div>
          
          </div>
            </div>
        )
    )
}
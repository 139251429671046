import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from 'primereact/toast';

//Componentes generales
import AuthUser from "../../../AuthUser";
import { endLoading, startLoading } from "../../../Redux/isLoadingSlice";
import { Footer, Header } from "../../Pure/SeccionesNavegacion";

export default function ModalPerfilEMO({
   visiblePerfilEmo,
   setVisiblePerfilEmo,
   paquetes,
   cargo,
   showToast,
   getCargos,
   dispatch
}) {
   const { http } = AuthUser();
   const toast = useRef(null);

   const [paquete, setPaquete] = useState(null);
   const [perfiles, setPerfiles] = useState([]);
   const [perfil, setPerfil] = useState(null);

   //Setea el valor de cada estado y oculta el modal
   const HidePerfilEmo = () => {
      setPaquete({});
      setPerfiles([]);
      setPerfil({});
      setVisiblePerfilEmo(false);
   };

   const asignarPerfil = () => {
      //Notifica al usuario si el paquete existe o no
      if (!paquete || Object.keys(paquete).length === 0) {
         showToast('error', 'Falta completar', 'Por favor, seleccione un paquete.');
      } else if (!perfil || Object.keys(perfil).length === 0) {
         showToast('error', 'Falta completar', 'Por favor, seleccione un perfil.');
      } else {
         let data_send = {
            con_perfil_id: perfil?.id
         };
         dispatch(startLoading());
         http
            .put(`empresa/cargo/con_perfil/${cargo?.id}`, data_send)
            .then((response) => {
               if (response?.data?.resp) {
                  showToast('success', 'Asignación exitosa', 'Se asignó el perfil correctamente');
                  getCargos();
                  HidePerfilEmo();
               } else if (response?.data?.error) {
                  showToast("error", 'Error de asignación', 'Hubo un error al asignar el perfil', response?.data?.error)
               }
            })
            .catch((error) => {
               console.error("Error al asignar perfil", error);
               showToast("error", "Error al asignar perfil", "Contáctese con Soporte Técnico.")
            })
            .finally(() => {
               dispatch(endLoading());
            })
      }
   };

   //Actualiza estados del paquete y perfil
   const handleChangePaquete = (e) => {
      setPaquete(e.value);
      setPerfiles(e.value.perfil);
   };

   //Actualiza el tipo de perfil
   const handleChangePerfil = (e) => {
      setPerfil(e.value);
   };

   const header = (<Header
      piIcon={'pi pi-user'}
      titulo={'Perfil EMO'}
      subtitulo={'Elige el tipo de perfil que corresponda'} />)

   const paqueteFooter = (<Footer
      type={"button"}
      onClickCancelar={() => HidePerfilEmo()}
      onClickEnviar={asignarPerfil}
      label={'Asignar'} />);

   useEffect(() => {
      //Busca id del paquete
      const paqueteEncontrado = paquetes.find(elemento => elemento.paquete.id === cargo?.con_perfil?.con_clinica_paquete?.id);

      if (paqueteEncontrado) {
         // Si se encuentra el paquete, obtener los perfiles relacionados
         const perfilesDelPaquete = paqueteEncontrado.perfil;

         // Buscar el perfil con el ID deseado
         const perfilEncontrado = perfilesDelPaquete.find(perfil => perfil.id === cargo?.con_perfil?.id);

         //Si el perfil hallado coincide con el id del perfil del paquete actualiza los estados
         if (perfilEncontrado) {
            setPaquete(paqueteEncontrado);
            setPerfiles(perfilesDelPaquete);
            setPerfil(perfilEncontrado);
         }
      } else {
         //Si no coinciden setea como cadenas y array vacíos
         setPaquete(null);
         setPerfiles([]);
         setPerfil(null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cargo?.con_perfil?.con_clinica_paquete?.id, cargo?.con_perfil?.id, visiblePerfilEmo]);


   return (
      <div className="flex justify-content-center">
         <Toast ref={toast} />
         <form
            id="asignar-personal-paquete-form"
            className="asignar-personal-paquete-form flex flex-column gap-3"
            noValidate
         />
         <Dialog
            appendTo={document.getElementById("asignar-personal-paquete-form")}
            visible={visiblePerfilEmo}
            style={{ width: "50vw", maxWidth: "424px" }}
            onHide={() => HidePerfilEmo(false)}
            header={header}
            footer={paqueteFooter}
         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column gap-3">
                  <p
                     style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}
                  >
                     Paquete:
                  </p>
                  <Dropdown
                     id="perfil_id"
                     name="perfil_id"
                     value={/*paquetes[0].*/paquete/*.nombre*/}
                     options={paquetes}
                     optionLabel="paquete.nombre"
                     placeholder="Selecciona una opción"
                     className="w-full "
                     onChange={(e) => handleChangePaquete(e)}
                  />
                  <p
                     style={{ fontWeight: "500", fontSize: "14px", color: "#344054" }}
                  >
                     Tipo de Perfil:
                  </p>
                  <Dropdown
                     id="perfil_tipo_id"
                     name="perfil_tipo_id"
                     options={perfiles}
                     value={perfil}
                     optionLabel="nombre"
                     placeholder="Selecciona una opción"
                     className="w-full "
                     onChange={(e) => handleChangePerfil(e)}
                  />
               </div>
            </div>
         </Dialog>
      </div>
   );
}
import React, { useState } from 'react'
import { Button } from "primereact/button";

//Componentes únicos
import UpdatePersonaComponent from './UpdatePersona';
import UpdatePersonaIMGComponent from './UpdatePersonaIMG';

import "../../../../Style/PagePerfilGeneral/USU-0/Persona-Coorporativo.css";

//Importación de imágenes
import CorreoIcon from "../../../../Images/Iconperfil/Correo.png"
import Celular from "../../../../Images/Iconperfil/Celular.png"
import AvatarMap from "../../../../assets/36.png"

const PersonaComponent = ({ persona, personaUpdate, getAllPersona, getPersona, showToast, dataPersona }) => {
    const [visible, setVisible] = useState(false);
    const [visible2, setVisible2] = useState(false);

    return (
        <div className="BASE_CONTENEDOR_PERSONA_AHPerfil">
            {/* Img de usuario */}
            <div className='POSICIONAMIENTO_IMAGEN' style={{ width: 'auto', height: 'auto' }}>
                <img
                    src={`${persona?.foto ?? AvatarMap}?t=${new Date().getTime()}`}
                    alt="user"
                    className='IMGDEUSUARIO' />
                <Button icon="pi pi-pencil" onClick={() => setVisible2(true)} className="BOTONEDITARIMGUSARIO flex flex-row" />
            </div>

            <div className="CONTENEDOR_GENERAL">
                <div className='CONTENEDOR_BOTON_EDITAR'  >
                    {/* <Button label="Agregar" icon="pi pi-plus" className="p-button-outlined flex flex-row" style={{ marginLeft: '', fontSize: '12px', padding: '10px', width: '90px', height: '30px', fontWeight: '400' }} /> */}
                    <Button onClick={() => setVisible(true)} className="BOTONAGREGARDATOSUSUARIO p-button-outlined flex" style={{ margin: '0' }}>
                        <i className='ICONODEAGREGAAR pi pi-pencil' style={{ fontSize: "10px", marginRight: "5px" }}></i>
                        <p className='TEXTOAGREGARUSUARIO'>Editar</p>

                    </Button>
                </div>
                <div className="CONTENEDOR_DATA_GENERAL">
                    {/* Datos y botones */}
                    <div className='CONTENEDOR_UP_AHP' style={{ borderBottom: '0.45px solid #88888E99' }}>
                        <h3 className='TITULO_CONT_UP'>
                            {persona?.nombres} {persona?.apellido_paterno} {persona?.apellido_materno}
                            {/* Julio Valentino Aquino Perez */}
                        </h3>
                        <h6 className='SUBTITULO_CONT_DOWN' style={{ border: '0' }}>{dataPersona?.roles?.[0]?.nombre ?? dataPersona?.razon_social}</h6>
                    </div>

                    {/* Datos del usuario parte inferior */}
                    <div className="CONTENEDOR_DOWN_AHP">
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img alt='correo-icon' src={CorreoIcon} />
                            <h4 className='TITULO_CONT_DOWN'>Correo</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {persona?.correo === "null" || !persona?.correo ? "No hay correo" : persona?.correo}
                                {/* administracion@bregma.com.pe */}
                            </p>
                        </div>
                        <div className="SUB_SECTIONS_ICONO_DATA">
                            <img alt='celular-icon' src={Celular} />
                            <h4 className='TITULO_CONT_DOWN'>Celular</h4>
                            <p className='SUBTITULO_CONT_DOWN'>
                                {(persona?.celular === "null" || !persona?.celular) ? "No hay célular" : persona?.celular}
                                {/* 938210243 */}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            <UpdatePersonaComponent
                getAllPersona={getAllPersona}
                getPersona={getPersona}
                personaUpdate={personaUpdate}
                setVisible={setVisible}
                visible={visible}
                institucion={"Administrador"}
                showToast={showToast}
            />

            <UpdatePersonaIMGComponent
                getPersona={getPersona}
                personaUpdate={personaUpdate}
                setVisible={setVisible2}
                visible={visible2}
                institucion={"Coorporativo"}
                showToast={showToast}
            />
        </div>
    )
}

export default PersonaComponent;
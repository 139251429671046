import React from "react";
import AreasGeneralesC from './AreasGeneralesC';


const CardListasGenerales = ({ NombreGerente, setNombreGerente, personalGeneral, handleCrearGerente, NombreGerenteBD, GetGerenteClinica
    // GerenteGeneral, setGerenteGeneral 
    }) => {
    return (
        <div className="flex flex-column pl-3 pr-3 pt-3 w-full h-full cardlistagenerales relative">
            <div className="flex flex-wrap  align-content-start w-full" >
                <p className="flex text-base  lista font-semibold ">Jefe en local</p>

            </div>
            <AreasGeneralesC
                NombreGerente={NombreGerente}
                setNombreGerente={setNombreGerente}
                personalGeneral={personalGeneral}
                handleCrearGerente = {handleCrearGerente} 
                NombreGerenteBD = {NombreGerenteBD}
                GetGerenteClinica = {GetGerenteClinica}
                // GerenteGeneral = {GerenteGeneral}
                // setGerenteGeneral = {setGerenteGeneral}
                />

        </div>
    );
}
export default CardListasGenerales;
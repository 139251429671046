import React from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";

//Componentes generales
import AuthUser from "../../../AuthUser";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";

//Imagen única
import iconAdvertencia from "../../../Images/Reclutaminento/img/icon-advertencia.png";
import { Footer, Header } from "../../Pure/SeccionesNavegacion";

export default function ModalTransferir({
    setvisibleTransferir,
    visibleTransferir,
    showToast,
    item,
    api,
    getPersonal
}) {

    const dispatch = useDispatch();
    const { http } = AuthUser();

    const HideDialogEdit = async () => {
        //Inicia la carga
        dispatch(startLoading());
        try {
            //Llama a la api
            const response = await http.put(`${api}/${item.id}`)
            if (response?.data?.[0]) {
                //Si existe un índice inmerso en la data de la respuesta espera la actualización de los valores y el personal
                await Promise.all([
                    setvisibleTransferir(false),
                    getPersonal()
                ])
                //Muestra un mensaje de confirmación
                showToast("success", "Se transfirió correctamente", "Se creó correctamente el personal");
            } else if (response?.data?.error) {
                //Muestra un mensaje de error controlado
                showToast("error", "Error", response?.data?.error)
            }
        } catch (error) {
            //Muestra un mensaje de error no controlado
            console.error("Error al transferir", error);
            showToast("error", "Error al transferir", "Contáctese con soporte técnico.")
        } finally {
            //Finaliza la carga
            dispatch(endLoading());
        }
    };

    const HeaderTransferir = (<Header icono={iconAdvertencia} titulo={'Transferir'} />);

    const paqueteCreateDialogFooter = (<Footer
        onClickCancelar={() => setvisibleTransferir(false)}
        onClickEnviar={() => HideDialogEdit()}
        label={'Aceptar'} />
    );

    return (
        <div>
            <Dialog
                visible={visibleTransferir}
                style={{ width: "450px" }}
                dismissableMask
                className="p-fluid "
                header={HeaderTransferir}
                footer={paqueteCreateDialogFooter}
                onHide={() => setvisibleTransferir()}
            >
                <>
                    <div className="Body_Advertencia">
                        <h1 className="per" style={{ color: '#363e4b' }}> ¿Está seguro de transferir este postulante a personal? </h1>
                        <p style={{ marginTop: '8px', marginBottom: '8px' }}> Recuerde que al hacerlo no hay forma de retroceder la acción </p>
                    </div>
                </>
            </Dialog>
        </div>
    );
}
import React, { useState, useEffect, useRef } from "react";
import { Skeleton } from "primereact/skeleton";
import { Avatar } from "primereact/avatar";
import { AvatarGroup } from "primereact/avatargroup";
import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { ModalEditarPaquete, ModalEliminar } from '../index'

export default function CardServicioComponent({ sortOrder, toggleSortOrder, transformedNodes, paquete, update, eliminar, showToast }) {
   const [visibleEditar, setVisibleEditar] = useState(false);
   const [AccionPaquete, setAccionPaquete] = useState();
   const [loading, setLoading] = useState(true);
   const [eliminarPaquete, setEliminarPaquete] = useState(false)
   const loader = () => {
      return (
         <div className="col-12 md:col-6 lg:col-3">
            <Skeleton
               width="100%"
               height="187px"
               borderRadius="16px"
            ></Skeleton>
         </div>
      );
   };


   const editdelete = useRef(null);

   const itemseditDelete = [
      {
         label: 'Editar',
         icon: 'pi pi-pencil',
         command: () => {
            setAccionPaquete(paquete)
            setVisibleEditar(true)
            //logica para Editar
         },
      },
      {
         label: 'Eliminar',
         icon: 'pi pi-trash',
         command: () => {
            //logica para Eliminar
            setEliminarPaquete(true)
         },
      },
   ];
   useEffect(() => {
      setTimeout(() => {
         setLoading(false);
      }, 900);
   }, []);

   if (loading) {
      return loader();
   } else {

      const monthlyPrice = parseFloat(paquete.paquete?.precio_mensual || 0).toFixed(2);
      const annualPrice = parseFloat(paquete.paquete?.precio_anual || 0).toFixed(2);

      return (
         <div
            className="col-12 md:col-6 lg:col-3"
            style={{ minWidth: "345px" }}
         >
            <div
               className="flex flex-column gap-2"
               style={{
                  // width: "345px",
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0px 2px 3px rgb(211, 208, 208)",
                  // 
               }}
            >
               <div className="flex flex-row justify-content-between">
                  <h4 style={{ fontWeight: "600", height: "20px" }}>
                     {paquete.paquete?.nombre}
                  </h4>

                  <Button
                     label=". . ."
                     onClick={(e) => editdelete.current.toggle(e)}
                     className="p-button-text flex flex-row"
                     style={{
                        fontSize: "12px",
                        padding: "10px",
                        width: "40px",
                        height: "1px",
                        fontWeight: "400",
                     }}
                  // onClick={togglePopup}
                  />

                  <Menu
                     model={itemseditDelete}
                     popup
                     ref={editdelete}
                     style={{ zIndex: 10, marginTop: '.8rem' }}
                  />

                  {/* <TableInPopUp data={data} /> */}
               </div>
               <div
                  className="flex flex-column gap-2"
                  style={{ marginTop: "10px" }}
               >
                  {/* Precio Semanal */}
                  <div className="flex gap-2" style={{ alignItems: "center" }}>
                     <h6
                        className="w-full"
                        style={{
                           fontSize: "14px",
                           fontWeight: "400",
                           color: "#7B8794",
                        }}
                     >
                        Mensual
                     </h6>
                     <div
                        className="flex w-full"
                        style={{
                           background: "#FCEBD8",
                           height: "28px",
                           alignItems: "center",
                           borderRadius: "4px",
                           justifyContent: "end",
                        }}
                     >
                        <h6
                           style={{
                              color: "#EE812F",
                              padding: "10px",
                              fontSize: "14px",
                           }}
                        >
                           S/{monthlyPrice}
                        </h6>
                     </div>
                  </div>
                  {/* Precio Semestral */}
                  <div className="flex gap-2" style={{ alignItems: "center" }}>
                     <h6
                        className="w-full"
                        style={{
                           fontSize: "14px",
                           fontWeight: "400",
                           color: "#7B8794",
                        }}
                     >
                        Anual
                     </h6>
                     <div
                        className="flex w-full"
                        style={{
                           background: "#E0F5FD",
                           height: "28px",
                           alignItems: "center",
                           borderRadius: "4px",
                           justifyContent: "end",
                        }}
                     >
                        <h6
                           style={{
                              color: "#64CBF4",
                              padding: "10px",
                              fontSize: "14px",
                           }}
                        >
                           S/{annualPrice}
                        </h6>
                     </div>
                  </div>
               </div>
               <div
                  className="flex justify-content-between flex-row"
                  style={{ marginTop: "10px" }}
               >
                  {/* fecha de creacion */}
                  <div className="flex flex-column">
                     <div className="flex">
                        <h6
                           style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#7B8794",
                           }}
                        >
                           Fecha de creación
                        </h6>
                     </div>
                     <div className="flex gap-2">
                        <i
                           className="pi pi-calendar"
                           style={{ width: "12px", height: "12px" }}
                        ></i>
                        <h5
                           style={{
                              fontWeight: "400",
                              color: "#7B8794",
                              height: "15px",
                           }}
                        >
                           14/0/2022
                        </h5>
                     </div>
                  </div>

                  <div className="flex flex-column">
                     <div
                        className="card flex justify-content-center"
                        style={{ height: "31px", marginTop: "5px" }}
                     >
                        <AvatarGroup>
                           <Avatar
                              image="https://primefaces.org/cdn/primereact/images/avatar/amyelsner.png"
                              size="large"
                              shape="circle"
                              style={{
                                 height: "31px",
                                 width: "31px",
                                 margin: "5px",
                              }}
                           />
                           <Avatar
                              image="https://primefaces.org/cdn/primereact/images/avatar/asiyajavayant.png"
                              size="large"
                              shape="circle"
                              style={{ height: "31px", width: "31px" }}
                           />
                           <Avatar
                              icon=""
                              label=""
                              shape="circle"
                              size="large"
                              style={{
                                 backgroundColor: "#D9D9D9",
                                 color: "#ffffff",
                                 height: "31px",
                                 width: "31px",
                              }}
                           />
                        </AvatarGroup>
                     </div>
                  </div>
               </div>
            </div>
            <ModalEliminar estado={eliminarPaquete} close={setEliminarPaquete} eliminar={eliminar} paquete={paquete} />
            <ModalEditarPaquete update={update} showToast={showToast} servicios={transformedNodes} close={setVisibleEditar} estado={visibleEditar} data={[AccionPaquete]} />
         </div>
      );
   }
}

const changeDataToTreeTable = (arreglo, item) => {
   let data = arreglo.map((servicio, key) => {
      let aux = [];
      if (servicio.children && servicio.children.length > 0) {
         aux = changeDataToTreeTable(servicio.children, item);
         // data = {...data, children: aux}
      }
      return {
         key: item + "-" + servicio.id,
         data: {
            nombre: servicio.nombre,
            [item === 1 ? "area_medica_id" : (item === 3 ? "examen_id" : "capacitacion_id")]: item === 1 ? servicio?.area_medica_id : (item === 3 ? servicio?.examen_id : "1"),
            precio_mensual: servicio.precio_mensual?.toString(),
            precio_anual: servicio.precio_anual?.toString(),
            precio_referencial_mensual: servicio.precio_referencial_mensual_venta?.toString(),
            precio_referencial_anual: servicio.precio_referencial_mensual_venta?.toString(),
            id: item === 1 ? (servicio?.perfil_area_id) : (item === 2 ? servicio?.perfil_capacitacion_id : servicio.perfil_examen_id)
         },
         children: aux,
      };
   });
   return data;
};

export const transformData = (nodes) => {
   var areaMedicaChildren = [];
   var capacitacionChildren = [];
   var examenChildren = [];
   nodes.forEach((item) => {


      if (item.areas_medicas && item.areas_medicas.length > 0) {
         let data = changeDataToTreeTable(item?.areas_medicas, 1);
         areaMedicaChildren = data;
      }

      if (item.capacitaciones && item.capacitaciones.length > 0) {
         let data = changeDataToTreeTable(item?.capacitaciones, 2);
         capacitacionChildren = data;
      }

      if (item.examenes && item.examenes.length > 0) {
         let data = changeDataToTreeTable(item?.examenes, 3);
         examenChildren = data;
      }
   });

   const areaMedicaNode = {
      key: "areas_medicas",
      data: {
         nombre: (
            <span className="d-flex align-items-center">
               <i className="p-2 pi pi-book bg-green-100 border-radius text-green-500 border-round-lg" />{" "}
               <strong>Áreas Médicas</strong>
            </span>
         ),
         precio_mensual: "Precio Mensual",
         precio_anual: "Precio Anual",
      },
      children: areaMedicaChildren,
   };

   const capacitacionNode = {
      key: "capacitaciones",
      data: {
         nombre: (
            <span className="d-flex align-items-center">
               <i className="p-2 pi pi-book bg-orange-100 border-radius text-orange-500 border-round-lg" />{" "}
               <strong>Capacitaciones</strong>
            </span>
         ),
         precio_mensual: "",
         precio_anual: "",
      },
      children: capacitacionChildren,
   };

   const examenNode = {
      key: "examenes",
      data: {
         nombre: (
            <span className="">
               <i className="p-2 pi pi-book bg-blue-100 border-radius text-blue-500 border-round-lg" />{" "}
               <strong>Examen de ayuda al diagnóstico</strong>
            </span>
         ),
         precio_mensual: "",
         precio_anual: "",
      },
      children: examenChildren,
   };
   return [areaMedicaNode, capacitacionNode, examenNode];
};
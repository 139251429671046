import React, { useContext, useEffect, useState } from 'react';
import { OftContext } from "../OftContext";
import { RAContext } from "../../RutaAtencion/RAContext";
import { InputText } from "primereact/inputtext";
import { iconOft } from "../../../Services/importIcons";
import '../../../Style/oft_test.css'
import { Dropdown } from 'primereact/dropdown';

const Campimetria = () => {

   let { campimetriaContext, setCampimetriaContext } = useContext(OftContext)
   let { pageViewContext, stateAtencion } = useContext(RAContext)

   const options = [
      { label: 'Normal', code: 1 },
      { label: 'Anormal', code: 2 }
   ]

   const [ojoIzq, setOjoIzq] = useState(campimetriaContext?.ojo_izquierdo ?? null);
   const [ojoDer, setOjoDer] = useState(campimetriaContext?.ojo_derecho ?? null)


   useEffect(() => {
      setCampimetriaContext({
         ...campimetriaContext,
         ojo_derecho: ojoDer,
         ojo_izquierdo: ojoIzq
      })
   }, [ojoIzq, ojoDer])

   useEffect(() => {
      setOjoIzq(campimetriaContext?.ojo_izquierdo)
      setOjoDer(campimetriaContext?.ojo_derecho)
   }, [campimetriaContext])

   return (
      <>
         <div className="oft_ant_titles">
            <div>
               <img src={iconOft} alt="Icon" />
            </div>
            <h3>Campimetría de Confrontación</h3>
         </div>
         <div className='desktop-oft-test'>
            <div className="oft_test_ctable_container">

               {/*SUB-HEADERS*/}
               <div className="oft_test_ctable_header2">
                  <span>Ojo Derecho</span>
               </div>
               <div className="oft_test_ctable_header2">
                  <span>Ojo Izquierdo</span>
               </div>
               {/*BODY*/}
               <div className="oft_test_ctable_body derecho">
                  {ojoDer === 1 && <span></span>}
                  <Dropdown showClear value={ojoDer == 1 ? options[0] : ojoDer == 2 ? options[1] : null} onChange={(e) => setOjoDer(e.target?.value?.code)} options={options} disabled={stateAtencion[pageViewContext].estado === 3} optionLabel="label" className="w-11" placeholder='Seleccionar' />
               </div>
               <div className="oft_test_ctable_body">
                  {ojoIzq === 1 && <span></span>}
                  <Dropdown showClear value={ojoIzq == 1 ? options[0] : ojoIzq == 2 ? options[1] : null} onChange={(e) => setOjoIzq(e.target?.value?.code)} options={options} optionLabel="label" className="w-11" placeholder='Seleccionar' disabled={stateAtencion[pageViewContext].estado === 3} />
               </div>

            </div>
         </div>

         <div className='movil-oft-test' style={{ minWidth: "150px" }}>
            <div className="oft_test_ctable_container">
               <div className="oft_test_ctable_content1">
                  <span>Ojo Derecho</span>
               </div>
               <div className="oft_test_ctable_content2">
                  <Dropdown value={ojoDer} options={options} onChange={e => setOjoDer(e.value)} placeholder='Seleccionar' disabled={stateAtencion[pageViewContext].estado === 3} />
               </div>
               <div className="oft_test_ctable_content1" >
                  <span>Ojo Izquierdo</span>
               </div>
               <div className="oft_test_ctable_content2">
                  <Dropdown value={ojoIzq} options={options} onChange={e => setOjoIzq(e.value)} placeholder='Seleccionar' disabled={stateAtencion[pageViewContext].estado === 3} />
               </div>
            </div>
         </div>

      </>
   );
};

export default Campimetria;
import React, { useContext, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import TablaOdontograma from "./TablaOdontrograma";
import "../../Style/Principal.css";
import { ScrollPanel } from "primereact/scrollpanel";
import VoiceButton from "../../Components/ButtonVoice/VoiceButton";



const PrincipalOdontograma = () => {
   const [value, setValue] = useState("");
   const [Comentario, setComentario] = useState("");
   


   return (
      <ScrollPanel style={{ width: "100%", height: "100%" }}>
         <div style={{backgroundColor:'white', borderRadius:'15px'}}>
            <div
               className="flex flex-wrap gap-3 flex-1"
               style={{ padding: "20px", width: "100%" }}
            >
               <div
                  className="flex flex-column gap-1 flex-1"
                  style={{ width: "100%" }}
               >
                  <p>Estado Bucal</p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                  />
               </div>

               <div
                  className="flex flex-column gap-1 flex-1"
                  style={{ width: "100%" }}
               >
                  <p>Dentadura</p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                  />
               </div>

               <div
                  className="flex flex-column gap-1 flex-1"
                  style={{ width: "100%" }}
               >
                  <p>Encías</p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                  />
               </div>

               <div
                  className="flex flex-column gap-1 flex-1"
                  style={{ width: "100%" }}
               >
                  <p>Oclusiones</p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                  />
               </div>
            </div>

            <div className="flex gap-8" style={{ padding: "20px" }}>
               <div className="flex gap-3 ">
                  <p className="flex justify-content-center align-items-center">
                     Extraer
                  </p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                     style={{ width: "70px", heigth: "40px" }}
                  />
               </div>
               <div className="flex gap-3">
                  <p className="flex justify-content-center align-items-center">
                     Dientes lesión cariosa
                  </p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                     style={{ width: "70px", heigth: "40px" }}
                  />
               </div>
               <div className="flex gap-3">
                  <p className="flex justify-content-center align-items-center">
                     Superficies lesión cariosa
                  </p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                     style={{ width: "70px", heigth: "40px" }}
                  />
               </div>
               <div className="flex  gap-3">
                  <p className="flex justify-content-center align-items-center">
                     Ausente
                  </p>
                  <InputText
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                     style={{ width: "70px", heigth: "40px" }}
                  />
               </div>
            </div>
            <div className="flex gap-3 flex-column w-full" style={{ padding: "20px" }}>
               <div className="flex gap-3 flex-column w-full">
                  <p>Observaciones</p>
                  <VoiceButton
                     value={Comentario}
                     onChange={setComentario}
                     placeholder="Comentario"
                  />
               </div>
            </div>

            <div>
               <TablaOdontograma />

               
            </div>

            <div></div>
         </div>
      </ScrollPanel>
   );
};

export default PrincipalOdontograma;

import React, { useContext, useRef, useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import AuthUser from "../../AuthUser";
import { MGContext } from "./MGProvider";
import { TriajeContext } from '../Triaje/TriajeContext';
import RATimeView from "../../Components/RutaAtencion/Atencion/RATimeView";
// import { iconEkg, iconLab, iconMedicina } from "../../Services/importIcons";
import { iconMedicina } from "../../Services/importIcons";
import PageExamenFisico from "./ExamenFisico/PageExamenFisico";
import PageEvaluacionMedica from "./EvalucionMedica/PageEvaluacionMedica";
import Osteomioarticular from "./EvaluaciónOsteomioarticular/EvaluaciónOsteomioarticular";
import ModalImprimir from "../../Components/RutaAtencion/Atencion/Modals-Atencion/ModalImprimir";
import Anexo16 from "./Anexo16/Anexo16";
import PageAltura from "./ExamendeAltura/PageAltura";
import Esqueleto from "./MusculoEsqueletico";
import Pageexploracion from "./Exploracionclinica/pageexploracion"; 
import PageTriajeHabNoc from "../Triaje/HabitosNocivos/PageTriajeHabNoc";
import PageTriajeAntFam from "../Triaje/AntecedenteFam/PageTriajeAntFam";
import PageTriajeAntPer from "../Triaje/AntecedentePer/PageTriajeAntPer";
import PageTriajeAntGin from "../Triaje/AntecedenteGin/PageTriajeAntGin";

const PageMedicinaGeneral = () => {
   const [imprimirVisible, setImprimirVisible] = useState(false);

  //  let { datosContext } = useContext(MGContext);
  // let { atencionStatus, setAtencionStatus } = useContext(MGContext);
  // let { setTimeStart } = useContext(MGContext);

  const { atencionStatus, setAtencionStatus } = useContext(MGContext, TriajeContext);
  const { setTimeStart } = useContext(MGContext, TriajeContext);

   const [activeIndex, setActiveIndex] = useState(0);

   const toast = useRef(null);

   const showToast = (type, title, detail) => {
      toast.current.show({
         severity: type,
         summary: title,
         detail: detail,
         life: 3000,
      });
   };
   return (
      <>
         <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => setActiveIndex(e.index)}
            scrollable
         >
            <TabPanel header="Hábitos">
               <PageTriajeHabNoc />
            </TabPanel>
            <TabPanel header="Antecedente Familiar">
               <PageTriajeAntFam />
            </TabPanel>
            <TabPanel header="Antecedente Personal">
               <PageTriajeAntPer/>
            </TabPanel>
            <TabPanel header="Antecedente Ginecológico">
               <PageTriajeAntGin />
            </TabPanel> 
            <TabPanel header="Exploración Clinica">
               <Pageexploracion />
            </TabPanel>
            <TabPanel header="Evaluación Médica">
               <PageEvaluacionMedica />
            </TabPanel>
            <TabPanel header="Examen Físico">
               <PageExamenFisico />
            </TabPanel>
            <TabPanel header="Evaluación Osteomioarticular">
               <Osteomioarticular />
            </TabPanel>
            <TabPanel header="Anexo 16">
               <Anexo16 />
            </TabPanel>
            <TabPanel header="Examen de Altura">
               <PageAltura />
            </TabPanel>
            <TabPanel header="Músculo Esquelético">
               <Esqueleto />
            </TabPanel>
            <TabPanel header="">
               <Esqueleto />
            </TabPanel>
         </TabView>
         <div
            style={{
               borderRadius: "10px",
               height: "70px",
               flexDirection: "inherit",
            }}
            className="flex justify-content-end gap-2 mt-2 bg-white"
         >
            <button className="p-button p-component btn-2 flex gap-1">
               <i className="pi pi-save"> </i>
               <span>Guardar</span>
            </button>
            <button
               style={{ color: "red" }}
               className="p-button  p-component btn-2 flex gap-1"
            >
               <i className="pi pi-times"></i>
               <span>Cancelar</span>
            </button>
            <button
               style={{ color: "black" }}
               onClick={() => setImprimirVisible(true)}
               className="p-button p-component btn-2 flex gap-1"
            >
               <i className="pi pi-print"></i>
               <span>Imprimir</span>
            </button>
         </div>
         <ModalImprimir
            imprimirVisible={imprimirVisible}
            setImprimirVisible={setImprimirVisible}
         />

         {/* {
            atencionStatus === 0 
            && 
            <RATimeView title="Medicina General" icon={iconMedicina} setTimeStart={setTimeStart} setAtencionStatus={setAtencionStatus} />
         } */}
      </>
   );
};

export default PageMedicinaGeneral;
import { Button } from "primereact/button";


export default function CapacitacionesCoorp() {

	return (
		<div className="flex-1 flex bg-white pt-3 pb-3 pr-5 pl-5 flex-column gap-3" style={{borderRadius: '17px'}}>
			<div className="flex flex-column gap-3">
				<div className="flex align-items-start justify-content-between  border-round-lg pb-1" style={{marginTop: '10px'}}>
				    <div>
					   <Button className="border-indigo-100 p-2" 
					      style={{borderRadius: '15px', width: '60px' , height: '60px', background: '#EBEBFF', display: 'flex' , justifyContent: 'center'}}>
					      <i className="pi pi-star text-indigo-600 p-1 font-bold border-round-lg border-indigo-600" 
					        style={{border: 'solid', marginRight: '0', width: '34px', height: '34px', 
							    display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px'}}></i>
                       </Button>
  
					</div>
				</div>
			</div>

			<div className="flex flex-column gap-3">
				<div>
					<div className="flex gap-3">
						<p className="font-bold white-space-nowrap"
                            style={{ fontSize: '18px', color: '#5555D8' }}>
							Capacitaciones
						</p>
						{/*<p className="text-indigo-500">Administrador</p>*/}
					</div>
				</div>
				<div className="text-sm" style={{color: '#7B8794'}}>
					Encuentre todas las capacitaciones disponibles para el personal de su empresa.
				</div>

        <div>
          <span style={{ fontWeight: '600', fontSize: '14px' }} >¿Cómo asigno capacitaciones a mis clientes?</span>
		  <div style={{marginTop: '30px', marginBottom: '20px' , display: 'flex' , justifyContent: 'center'}}>
		    <iframe width="333" height="151" style={{borderRadius: '17px'}}
		      src="https://www.youtube.com/embed/4ZmzGj6gQhw?si=7Cpsjt2RwtxYenhK" 
			  title="YouTube video player" 
			  frameborder="0" 
			  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
			  allowfullscreen>
			</iframe>
		  </div>
		  
        </div>

        <div>
          <span style={{ fontWeight: '600', fontSize: '14px' }} >¿Cómo asigno capacitaciones a mis trabajadores</span>
		  <div style={{marginTop: '30px', marginBottom: '20px' , display: 'flex' , justifyContent: 'center'}}>
		    <iframe width="333" height="151" style={{borderRadius: '17px'}}
		      src="https://www.youtube.com/embed/4ZmzGj6gQhw?si=7Cpsjt2RwtxYenhK" 
			  title="YouTube video player" 
			  frameborder="0" 
			  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
			  allowfullscreen>
			</iframe>
		  </div>
        </div>

        <div>
          <span style={{ fontWeight: '600', fontSize: '14px' }} >¿Cómo asigno una nueva capacitación?</span>
		  <div style={{marginTop: '30px' , marginBottom: '20px' , display: 'flex' , justifyContent: 'center'}}>
		    <iframe width="333" height="151" style={{borderRadius: '17px'}}
		      src="https://www.youtube.com/embed/4ZmzGj6gQhw?si=7Cpsjt2RwtxYenhK" 
			  title="YouTube video player" 
			  frameborder="0" 
			  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
			  allowfullscreen>			
			</iframe>
		  </div>
        </div>

		</div>
			
		</div>
	);
}

export const PatologiaAntPer =
[
        {
          id: 138,
          code: 'T.E.C',
          resul: '',
          observacion: '',
        },
        {
          id: 139,
          code: 'Convulsiones',
          resul: '',
          observacion: ''
        },
        {
          id: 140,
          code: 'Mareos',
          resul: '',
          observacion: '',
        },
        {
          id: 141,
          code: 'Acrofobia',
          resul: '',
          observacion: '',
        },
        {
          id: 142,
          code: 'Epilepsia',
          resul: '',
          observacion: '',
        },
        {
          id: 143,
          code: 'Antecedentes alcohol y drogas',
          resul: '',
          observacion: '',
        },
  ];

const dataStatic = () => {
    const tipoDoc = [
      { name: 'DNI', id: 1 },
      { name: 'PASAPORTE', id: 2 },
      { name: 'EXTRANJERíA', id: 3 }
    ];
  
    const sexo = [
      { name: 'Masculino', id: 1 },
      { name: 'Femenino', id: 2 },
    ];

    const pais = [
        { name: 'Perú', id: 1 },
        { name: 'Argentina', id: 2 }
    ]

    const depar = [
        { name: 'Lima', id: 1 },
        { name: 'Ancash', id: 2 }
    ]

    const reli = [
        { name: 'Católico', id: 1 },
        { name: 'Adventista', id: 2 },
        { name: 'Musulmán', id: 3 },
        { name: 'Judio', id: 4 },
        { name: 'Evangélico', id: 5 },
        { name: 'Cristiano', id: 6 },
        { name: 'Luterano', id: 7 },
        { name: 'Otros', id: 8 }
    ]
    const provi = [
        { name: 'Lima', id: 1 },
        { name: 'Cajamarca', id: 2 }
    ]
    const distri = [
        { name: 'San juan de Lurigancho', id: 185  },
        { name: 'Los Olivos', id: 184 }
    ]
    const Ec = [
        { name: 'Soltero(a)', id: 1 },
        { name: 'Casado(a)', id: 2 },
        { name: 'Divorciado(a)', id: 3 },
        { name: 'Viudo(a)', id: 4 }

    ]
    const gradoinsti = [
        { name: 'Primaria', id: 1 },
        { name: 'Primaria Incompleta', id: 2 },
        { name: 'Secundaria', id: 3 },
        { name: 'Secundaria Incompleta', id: 4 },
        { name: 'Estudios Universitarios', id: 5 },
        { name: 'Estudios Universitarios Incompletos', id: 6 },
        { name: 'Ninguno', id: 7 },
        { name: 'Analfabeto', id: 8 },
        { name: 'Hasta 3RO de primaria', id: 9 },
        { name: 'Más 3RO de primaria', id: 10 },
        { name: 'Técnico', id: 11 },
        { name: 'Técnico Incompleto', id: 12 },
        { name: 'Doctorado completo', id: 13 },
        { name: 'Doctorado incompleto', id: 14 },
        { name: 'Especialización completa', id: 15 },
        { name: 'Especialización incompleta', id: 16 },
        { name: 'Maestría completa', id: 17 },
        { name: 'Maestría incompleta', id: 18 }
    ]
  
    return { tipoDoc, sexo, pais, depar, reli, provi, distri, Ec, gradoinsti };
  };
  
  export default dataStatic;
import React from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";

//Librería para Google Maps
const libraries = ["places"];

function Maps(props) {
  //Carga de la api de Google Maps con la clave de acceso
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCsCf38reBoC31fo0enpAT6cESVohjRSwk",
    libraries: libraries,
  });

  //Centro de la cámara
  const center = {
    lat: parseFloat(-16.389571123416584),
    lng: parseFloat(-71.55339135054068),
  };

  //Posición del marcador
  const position = {
    lat: props.center.lat,
    lng: props.center.lng,
  };

  //Función para obtener la dirección de una coordenada
  const geocodeLatLng = (latLng) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ latLng }, (results, status) => {
      if (status === "OK" && props.direccion) {
        props.direccion(results[0].formatted_address);
      }
    });
  };

  // Función para actualizar el prop onDblClick
  const handleDblClick = React.useCallback((event) => {
    geocodeLatLng(event.latLng);
    if (props.onMarkerDragEnd) {
      props.onMarkerDragEnd(event);
    }
    props.onDblClickkMap(event);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  // Función para actualizar el prop onDragEnd
  const handleDragEnd = React.useCallback((event) => {
    geocodeLatLng(event.latLng);
    if (props.onMarkerDragEnd) {
      props.onMarkerDragEnd(event);
    }
    props.onDragEnd(event);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <React.Fragment>
      {/* Si la api no se encuentra cargada, renderizar un fragmento vacío */}
      {!isLoaded ? (
        <React.Fragment></React.Fragment>
      ) : (
        //Si la api se encuentra cargada, renderizar el mapa
        <GoogleMap
          center={props.center ? position : center}
          zoom={15}
          mapContainerStyle={
            !props.mapContainerStyle
              ? { width: "100%", height: "100%" }
              : props.mapContainerStyle
          }
          onLoad={props.onLoad}//Evento que se ejecuta cuando se carga el mapa
          options={{
            streetViewControl: false,//Desactivar el Street View
            mapTypeControl: false,//Desactivar el tipo de mapa
            zoomControl: false,//Desactivar el control de zoom
            gestureHandling: "cooperative",//Permitir que el usuario maneje el mapa
          }}
          onDblClick={handleDblClick} // Actualiza el prop onDblClick
        >
          {/* Marcador en la posición especificada */}
          <Marker
            key="100asds"
            position={props.position}
            draggable={!props.draggable ? true : props.draggable}
            onDragEnd={handleDragEnd} // Actualiza el prop onDragEnd
            title={props.title}
            label={props.label}
          />

          {props.directionsResponse && (
            //Renderiza las direcciones si hay una respuesta
            <DirectionsRenderer directions={props.directionsResponse} />
          )}
        </GoogleMap>
      )}
    </React.Fragment>
  );
}

export default React.memo(Maps);
import React from 'react'
import '../../../Style/Botones.css';
import CardBienvenidoSoporte from './Cards/CardBienvenidoSoporte';
import CardContactenos from './Cards/CardConteactenos';
import CardCapacitaciones from './Cards/CardCapacitaciones';

function PageSoporteTecnicoB() {
    return (
        <div className='SoporteTec' style= {{paddingLeft: '1.5rem' , paddingRight: '1rem', paddingBottom: '2rem'}}>
            <div className='STecnico' style={{ marginLeft: '20px' /*position: 'fixed', marginTop: '-80px' */}}>
                <h1 className='tit'>Soporte técnico</h1>
                <p className='con' style={{marginTop: '15px' , marginBottom: '10px'}}>
                    Edita este contenido para ayudar a tu público </p>
            </div>
            <div className='CuerpoSoporte flex gap-3 flex-wrap'>
                <div className='flex flex-column gap-3 flex-1' style={{ maxWidth: '594px' , paddingRight: '1.3rem' }}>
                    <div style={{paddingBottom: '1rem'}}>
                        <CardBienvenidoSoporte />
                    </div>
                    <div>
                        <CardContactenos />
                    </div>
                </div>
                <div className="flex flex-column flex-1" >
                    <CardCapacitaciones />
                </div>
                
            </div>

        </div>
    )
}

export default PageSoporteTecnicoB
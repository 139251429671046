import "../../../Style/style7.css";
import React, { useRef, useState, useContext, useEffect } from "react";
import PrimeraColumnaElComponente from "./PrimeraColumna";
import SegundaColumnaElComponente from "./SegundaColumna";
import TerceraColumnaElComponente from "./TerceraColumna";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import iconoboton from "../../../Images/iconoarchivoconlupa.svg";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import { RAContext } from '../../../Page/RutaAtencion/RAContext';
import { MGContext } from "../MGProvider";
import AuthUser from "../../../AuthUser";
import { Dialog } from 'primereact/dialog';
import EnvioArchivoMedGeneral from "../../../Components/MedicinaGeneral/EnvioArchivoMedGeneral";

export default function Osteomioarticular() {
   let { stateAtencion, pageViewContext } = useContext(RAContext);
   //Uso de useContext para obtener array de evaluación Osteomioarticular
   const { evaluacionOsteomioarticularContext, setEvaluacionOsteomioarticularContext } = useContext(MGContext);

   const ref_file = useRef(null)
   const [file, setFile] = useState(null)
   const [filePath, setFilePath] = useState('')
   const [visibleDialog, setVisibleDialog] = useState(false);
   const [defaultObservations, setDefaultObservations] = useState("Osteomuscular conservado");

   const handlerchangefile = (event) => {
      const archivo = event.target.files[0]
      setFile(archivo)
      setFilePath(archivo.name)
      changeArchivo(archivo.name);
      setEvaluacionOsteomioarticularContext({ ...evaluacionOsteomioarticularContext, archivo: archivo })
   }

   const handlersubir = () => {
      const formData = new FormData();
      formData.append("archivo", file)
      ref_file.current.click();
   }
   const abrirDialog = () => {
      setVisibleDialog(true);
   };

   const handlerdeletefile = () => {
      setFile(null)
      changeArchivo("");
   }
   const Data01Columna = (mensaje) => {
   }
   const Data02Columna = (mensaje01) => {
   }
   const Data03Columna = (mensaje02) => {
   }

   const changeObservacion = (e) => {
      setEvaluacionOsteomioarticularContext({ ...evaluacionOsteomioarticularContext, observaciones: e })
   }
   const changeArchivo = (e) => {
      setEvaluacionOsteomioarticularContext({ ...evaluacionOsteomioarticularContext, archivo: e })
   }


   useEffect(() => {
      // Establecer el texto predeterminado al iniciar el componente, solo si no hay observaciones establecidas previamente
      if (!evaluacionOsteomioarticularContext.observaciones) {
         setEvaluacionOsteomioarticularContext({ ...evaluacionOsteomioarticularContext, observaciones: defaultObservations });
      }
   }, []);

   // ARCHIVO:
   const [Archivo, setArchivo] = useState(evaluacionOsteomioarticularContext?.archivo);
   const updateArchivo = (nuevoValor) => {
      setEvaluacionOsteomioarticularContext((prev) => ({
         ...prev, archivo: nuevoValor
      }));
   }

   return (
      <div className="flex flex-column gap-4 ">
         <div className="flex ">
            <table className='TablaOsteomi'>
               <tbody className="flex flex-column gap-5 BodyOsteomioarticular" >
                  <tr className="flex ptr-1">Nuca</tr>
                  <tr className="flex ptr-1HD">Hombro derecho</tr>
                  <tr className="flex ptr-1HI">Hombro izquierdo</tr>
                  <tr className="flex ptr-1CD">Codo derecho</tr>
                  <tr className="flex ptr-1">Codo izquierdo</tr>
                  <tr className="flex ptr-1">Mano izquierda</tr>
                  <tr className="flex ptr-1">Mano derecha</tr>
                  <tr className="flex ptr-01">Columna alta (Dorso)</tr>
                  <tr className="flex ptr-2">Columna baja (Lumbares)</tr>
                  <tr className="flex ptr-3">Cadera derecha</tr>
                  <tr className="flex ptr-4">Rodilla izquierda</tr>
                  <tr className="flex ptr-5">Tobillo/Pie derecho</tr>
                  <tr className="flex ptr-6">Tobillo/Pie izquierdo</tr>
               </tbody>
            </table>
            <div className="flex flex-row " style={{ width: '80%' }}>
               <PrimeraColumnaElComponente Data01Columna={Data01Columna} />
               <SegundaColumnaElComponente Data02Columna={Data02Columna} />
               <TerceraColumnaElComponente Data03Columna={Data03Columna} />
            </div>
         </div>
         <div className="ParteInferior-Observaciones gap-3">
            <span>Observaciones</span>
            <VoiceButton
               value={evaluacionOsteomioarticularContext?.observaciones}
               onChange={(e) => changeObservacion(e)}
               // placeholder="Observaciones"
               disabled={stateAtencion[pageViewContext].estado === 3}
            />
         </div>
         <div className="ContenedorDelNombreDelArchivo w-full gap-3">
            <EnvioArchivoMedGeneral
               FuncionSetEnvio={setArchivo}
               FuncionUpData={updateArchivo}
               ValorSet={Archivo}
               DataContext={evaluacionOsteomioarticularContext.archivo}
            />
            {/* <div className="NombreArchivo">
               <span style={{ width: '143px' }}>Nombre de Archivo</span>
            </div>
            <div className="flex flex-column w-full">
               <InputText className="InputDelContenedorArchivo"
                  value={filePath || (evaluacionOsteomioarticularContext?.archivo ? evaluacionOsteomioarticularContext?.archivo?.substring(evaluacionOsteomioarticularContext?.archivo?.lastIndexOf('/') + 1) : '')}
                  onChange={(e) => changeArchivo(e.target.value)}
                  placeholder="Nombre del Archivo" disabled={stateAtencion[pageViewContext].estado === 3} />
            </div>
            <div className="flex flex-row gap-2">
               <Button icon="pi pi-upload" className="p-button-success p-button-outlined " onClick={handlersubir} disabled={stateAtencion[pageViewContext].estado === 3} style={{ background: 'white', width: '30px', height: '30px' }} />
               <Button icon="pi pi-trash" className="p-button-danger p-button-outlined " onClick={handlerdeletefile} disabled={stateAtencion[pageViewContext].estado === 3} style={{ background: 'white', width: '30px', height: '30px' }} />
               <Button className=" p-button-outlined " disabled={stateAtencion[pageViewContext].estado === 3} style={{ background: 'white', width: '20px', height: '30px', padding: "10px 15px 15px 15px" }} onClick={abrirDialog}>
                  <img alt="img_imprimir" src={iconoboton} style={{ position: 'relative', marginLeft: '-11px', marginTop: '6px' }} />
               </Button>
            </div>
            <input type="file" style={{ display: "none" }} onChange={handlerchangefile} ref={ref_file} />

            <Dialog
               header="Vista previa del PDF"
               visible={visibleDialog}
               style={{ width: '70vw' }}
               onHide={() => setVisibleDialog(false)}
            >
               <iframe
                  src={file ? URL.createObjectURL(file) : ''}
                  width="100%"
                  height="500px"
                  title="Vista previa del PDF"
               />
            </Dialog> */}
         </div>
         <Dialog
            header="Vista previa del PDF"
            visible={visibleDialog}
            style={{ width: '70vw' }}
            onHide={() => setVisibleDialog(false)}
         >
            <iframe
               src={file ? URL.createObjectURL(file) : ""}
               width="100%"
               height="500px"
               title="Vista previa del PDF"
            />
         </Dialog>
      </div>
   )
}
import React from 'react';
import '../../Style/MantenimientoCorporativo.css';

import { Button } from 'primereact/button';

export default function PageMantenimientoCorporativo() {
  return (
    <div>
      <div className="contenendor">
        <div className="izquierda flex-1 flex flex-column gap-3">
          <p className="text-2xl text-indigo-300 font-bold">
            Area Mantenimiento
          </p>
          <p className="text-indigo-400" style={{ fontSize: '15px' }}>
            Esta área no se encuentra disponible por el momento, seguimos
            trabajando para darles una buena experiencia.
          </p>
          <div className="flex flex-column justify-content-center align-items-center">
            <Button
              label="Volver"
              outlined
              style={{ backgroundColor: "transparent", color: "gray", border: "1px solid gray", borderRadius: ".8rem", padding: "10px 20px" }}
            />
          </div>
        </div>

        <div className="derecha">
          <img
            className="principal-img"
            src="https://i.ibb.co/Wz5HZcs/Principal.png"
            alt="derecha"
          />
        </div>
      </div>

      <div className="fondo">
        <img
          className="fondo-img"
          src="https://i.ibb.co/rHqfbxz/fondo.png"
          alt="fondo"
        />
      </div>
    </div>
  );
}
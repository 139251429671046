import React, { useEffect, useState } from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';

const VoiceButton = ({ onChange, placeholder, value, disabled }) => {
   const [isRecognitionStarted, setIsRecognitionStarted] = useState(false);
   const [recognition, setRecognition] = useState(null);
   const [isListening, setIsListening] = useState(false);

   useEffect(() => {
      const webkitSpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
      const newRecognition = new webkitSpeechRecognition();
      newRecognition.lang = 'es-ES';
      newRecognition.continuous = true;
      newRecognition.interimResults = true;

      newRecognition.onstart = () => {
         setIsRecognitionStarted(true);
         setIsListening(true);  // Actualizar el estado de isListening
      };

      newRecognition.onresult = (event) => {
         let tempTranscript = '';

         for (let i = 0; i < event.results.length; ++i) {
            tempTranscript += event.results[i][0].transcript;
         }

         let textoCompleto = value + tempTranscript;
         textoCompleto = textoCompleto.replace("coma", ', ');
         onChange(textoCompleto);
      };

      newRecognition.onend = () => {
         setIsRecognitionStarted(false);
         setIsListening(false);  // Actualizar el estado de isListening
      };

      setRecognition(newRecognition);
   }, [onChange, value]);

   useEffect(() => {
      // Limpieza cuando el componente se desmonta
      return () => {
         if (recognition) {
            recognition.stop();
         }
      };
   }, [recognition]);

   const toggleRecognition = () => {
      if (isRecognitionStarted) {
         recognition.stop();
      } else {
         recognition.start();
      }
   };

   return (
      <div className="flex flex-row w-full">
         <div className="flex flex-column w-full">
            <InputTextarea
               autoResize
               value={value}
               onChange={(e) => onChange(e.target.value)}
               placeholder={placeholder}
               style={{ height: '131px' }}
               disabled={disabled}
            />
         </div>
         <div className="flex flex-column justify-content-end" style={{ marginLeft: "-40px", marginBottom: "5px" }}>
            <Button
               style={{ height: "25px", padding: "5px" }}
               label={isListening ? 'Off' : 'On'}
               onClick={toggleRecognition}
            />
         </div>
      </div>
   );
};

export default VoiceButton;

import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

export default function Especialista({ Cmp, Rne, especialistaID, setCmp, setRne, setespecialistaID, rowData, especialistas }) {

   useEffect(() => {
      if (rowData) {
         if (rowData?.especialista_evaluador?.especialista_id) {
            especialistas.forEach((elemento) => {
               if (rowData?.especialista_evaluador?.especialista_id === elemento.id) {
                  setespecialistaID(elemento.id)
               }
            })
         }

      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [rowData]);


   const especialistaIDChange = (e) => {
      setespecialistaID(e);
      setRne("")
      setCmp("")
   }


   return (
      <div className="contenedor-crear2 mx-3 mt-3 mb-3">
         <div
            style={{
               marginTop: "10px",
               display: "flex",
               alignItems: "center",
            }}
         >
            <label
               htmlFor="especialista"
               style={{
                  fontFamily: "Montserrat",
                  fontSize: "16px",
                  fontWeight: "600",
               }}
            >
               Escoge una:{" "}
            </label>
            <Dropdown
               value={especialistaID}
               options={especialistas}
               optionLabel="nombre"
               optionValue="id"
               placeholder=""
               onChange={(e) => especialistaIDChange(e.target.value)}
               required
               className="w-full"
               style={{ marginLeft: "20px" }}
            />
         </div>
         <div className="options">
            <div
               style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
               }}
            >
               <label
                  htmlFor="RNM"
                  style={{
                     fontFamily: "Montserrat",
                     fontSize: "16px",
                     fontWeight: "600",
                     color: "#4545bc",
                  }}
               >
                  CMP*:{" "}
               </label>
               <InputText
                  // keyfilter="int"
                  placeholder="Código"
                  style={{ width: "400px", marginLeft: "6px" }}
                  value={Cmp.toUpperCase()}
                  onChange={(e) => { setCmp(e.target.value.toUpperCase()) }}
               ></InputText>
            </div>
            <div
               style={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
               }}
            >
               <label
                  htmlFor="RNM"
                  style={{
                     fontFamily: "Montserrat",
                     fontSize: "16px",
                     fontWeight: "600",
                     color: "#4545bc",
                  }}
               >
                  RNE*:{" "}
               </label>
               <InputText
                  // keyfilter="int"
                  placeholder="Código"
                  style={{ width: "400px", marginLeft: "6px" }}
                  value={Rne.toUpperCase()}
                  onChange={(e) => { setRne(e.target.value.toUpperCase()) }}
               ></InputText>
            </div>
         </div>
      </div>
   );
}

import React, { useEffect, useState, useRef } from "react";
import bgcontain from "../../../../Images/Operaciones/Servicios/bg-contain.png";
import AuthUser from "../../../../AuthUser";
import TableOperacionesClinicaComponent from "../../../../Components/Operaciones/TableOperacionesClinica";
import { transformData } from "../../../../Services/Operaciones/operaciones";
import { endLoading, startLoading } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";
import { Toast } from 'primereact/toast';

export default function PageOperServiciosB() {
   const { http } = AuthUser();
   // Trayendo datos GET
   const [servicios, setServicios] = useState([]);
   const [categories, setcategories] = useState("");
   const [key, setKey] = useState(0);
   const [selectedCategory, setSelectedCategory] = useState(null);
   const dispatch = useDispatch();
   const toast = useRef();


   const showToast = (tipo, titulo, detalle) => {
      toast.current.show({
         severity: tipo,
         summary: titulo,
         detail: detalle,
         life: 3000,
      });
   };

   const getServicios = async () => {

      dispatch(startLoading());

      try {
         const response = await http.get("clinica/operaciones/servicio/get");

         if (response?.data?.resp || response?.data) {
            setServicios(transformData([response.data.resp ? response?.data?.resp : response?.data]));
            setcategories(Object.keys(response.data.resp ? response?.data?.resp : response?.data));
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error);
         }

      } catch (error) {
         showToast('error', 'Error', 'Contacte con soporte técnico');

      } finally {
         dispatch(endLoading());
      }
   };

   const handleClick = (key, category) => {
      setKey(key);
      setSelectedCategory(changue_name_category(category));
   };


   const changue_name_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "Áreas Médicas";

         case "examenes":
            return "Examen de ayuda al diagnóstico ";
         case "laboratorios":
            return "Laboratorio Molecular";
         default:
            return "";
      }
   };

   const changue_item_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "i-plus-green";
         case "capacitaciones":
            return "";
         case "examenes":
            return "i-diagnostic-vector-orange";
         case "laboratorios":
            return "i-lab-genomica-purple";
         default:
            return "";
      }
   };

   const changue_bg_category = (cat) => {
      switch (cat) {
         case "areas_medicas":
            return "services--content-section-one-content-item-icon-green";
         case "capacitaciones":
            return "";
         case "examenes":
            return "services--content-section-one-content-item-icon-orange";
         case "laboratorios":
            return "services--content-section-one-content-item-icon-purple";
         default:
            return "";
      }
   };

   useEffect(() => {
      getServicios();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   return (
      <div className="services-content">
         <Toast ref={toast} />
         <section className="services--content-section-one">
            <div className="services--content-section-one-header">
               <h3 className="services--content-section-one-header-title">
                  Servicios
               </h3>
               <p className="services--content-section-one-header-paragraph">
                  En este módulo usted podrá operar sus servicios.
               </p>
            </div>

            <section className="services--content-section-one-body">
               <div className="services--content-section-one-body-section">
                  <h3 className="services--content-section-one-title">
                     Nuestros servicios
                  </h3>
                  <style>
                     {`
            .clasedejosue div:nth-child(2) {
                        display: none;
            }
            `}
                  </style>
                  <div className="services--content-section-one-content clasedejosue">
                     {categories &&
                        categories?.map((category, key) => (
                           <div
                              className="services--content-section-one-content-item"
                              key={category}
                              onClick={() => handleClick(key, category)}
                           >
                              <div className={changue_bg_category(category)}>
                                 <i
                                    className={changue_item_category(category)}
                                 ></i>
                              </div>
                              <p className="services--content-section-one-content-item-paragraph">
                                 {changue_name_category(category)}
                              </p>
                              <div>
                                 <i
                                    className="pi pi-ellipsis-v"
                                    style={{
                                       color: "#B6B6B6",
                                       fontWeight: "600",
                                       cursor: "pointer",
                                    }}
                                 ></i>
                              </div>
                           </div>
                        ))}
                  </div>
               </div>

               {/* IMAGEN */}
               <div className="services--content-section-one-image">
                  <img src={bgcontain} alt="contain" height={100} width={110} />
                  <p className="services--content-section-one-image-text">
                     Lista de servicios
                  </p>
               </div>
            </section>
         </section>

         <section className="services--content-section-two">
            {selectedCategory ? (
               <>
                  <div className="services--content-section-two-header">
                     <div className={changue_bg_category(selectedCategory)}>
                        <i
                           className={changue_bg_category(selectedCategory)}
                        ></i>
                     </div>
                     <h2>{selectedCategory}</h2>
                  </div>

                  <div className="services--content-section-two-span">
                     <p>
                        Servicios /
                        <span>{selectedCategory}</span>
                     </p>
                  </div>

                  <div className="table-responsive">
                     <TableOperacionesClinicaComponent
                        getServicios={getServicios}
                        data={servicios}
                        transformedNodes={servicios[key]?.children}
                     ></TableOperacionesClinicaComponent>
                  </div>
               </>
            ) : (
               <div className="flex justify-content-center align-items-center w-full h-full">
                  Seleccione un servicio
               </div>
            )}
         </section>
      </div>
   );
}
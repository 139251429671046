import { createContext, useState } from "react";
import {
   iconAudiometria,
   iconEkg,
   iconLab,
   iconMedicina,
   iconOft,
   iconPsicologia,
   iconRadiologia,
   iconTriaje,
   iconEspirometria,
   iconOdontologia,
} from "../../../../Services/importIcons";

export const GestionContext = createContext(undefined)

export const GestionProvider = (props) => {

   const [listRutasContext, setListRutasContext] = useState([
      { id: 0, nombre: "Triaje", icon: iconTriaje },
      { id: 1, nombre: "Psicología", icon: iconPsicologia },
      { id: 2, nombre: "EKG", icon: iconEkg },
      { id: 3, nombre: "Oftalmología", icon: iconOft },
      { id: 4, nombre: "Audiometría", icon: iconAudiometria },
      { id: 5, nombre: "Examen Radiológico", icon: iconRadiologia },
      { id: 6, nombre: "Examen de Laboratorio", icon: iconLab },
      { id: 7, nombre: "Odontología", icon: iconOdontologia },
      { id: 8, nombre: "Espirometría", icon: iconEspirometria },
      { id: 9, nombre: "Medicina General", icon: iconMedicina },
   ]);
   const [localesContext, setLocalesContext] = useState([]);
   const [atencionContext, setAtencionContext] = useState(null);
   const [pacientes, setPacientes] = useState([]);
   const [pacienteContext, setPacienteContext] = useState(null);
   const [pageViewContext, setPageViewContext] = useState(0);

   const [triajeAreasContext, setTriajeAreasContext] = useState([]);
   const [triajeDetalleId, setTriajeDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });
   const [psicologiaAreasContext, setPsicologiaAreasContext] = useState([]);
   const [psicologiaDetalleId, setPsicologiaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });
   const [ekgAreasContext, setEkgAreasContext] = useState([]);
   const [ekgDetalleId, setEkgDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });
   const [oftalmologiaAreasContext, setOftalmologiaAreasContext] = useState([]);
   const [oftalmologiaDetalleId, setOftalmologiaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });

   const [radiologiaAreasContext, setRadiologiaAreasContext] = useState([]);
   const [radiologiaDetalleId, setRadiologiaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });

   const [audiometriaAreasContext, setAudiometriaAreasContext] = useState([]);
   const [audiometriaDetalleId, setAudiometriaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   });

   const [espirometriaAreasContext, setEspirometriaAreasContext] = useState([]);
   const [espirometriaDetalleId, setEspirometriaDetalleId] = useState({
      id: 0,
      estado: '0',
      hora_inicio: null,
      fecha_inicio: null,
      hora_fin: null,
      fecha_fin: null,
   })

   const [medicinaGeneralAreasContext, setMedicinaGeneralAreasContext] = useState([]);
   const [medicinaGeneralDetalleId, setMedicinaGeneralDetalleId] = useState(
      {
         id: 0,
         estado: '0',
         hora_inicio: null,
         fecha_inicio: null,
         hora_fin: null,
         fecha_fin: null,
      }
   )
   const [laboratorioAreasContext, setLaboratorioAreasContext] = useState([]);
   const [laboratorioDetalleId, setLaboratorioDetalleId] = useState(
      {
         id: 0,
         estado: '0',
         hora_inicio: null,
         fecha_inicio: null,
         hora_fin: null,
         fecha_fin: null,
      }
   )

   return (
      <GestionContext.Provider
         value={{
            localesContext, setLocalesContext,
            pacientes, setPacientes,
            listRutasContext, setListRutasContext,
            atencionContext, setAtencionContext,
            pacienteContext, setPacienteContext,
            pageViewContext, setPageViewContext,
            triajeAreasContext, setTriajeAreasContext,
            triajeDetalleId, setTriajeDetalleId,
            psicologiaAreasContext, setPsicologiaAreasContext,
            psicologiaDetalleId, setPsicologiaDetalleId,
            ekgAreasContext, setEkgAreasContext,
            ekgDetalleId, setEkgDetalleId,
            oftalmologiaAreasContext, setOftalmologiaAreasContext,
            oftalmologiaDetalleId, setOftalmologiaDetalleId,
            audiometriaAreasContext, setAudiometriaAreasContext,
            audiometriaDetalleId, setAudiometriaDetalleId,
            radiologiaAreasContext, setRadiologiaAreasContext,
            radiologiaDetalleId, setRadiologiaDetalleId,
            espirometriaAreasContext, setEspirometriaAreasContext,
            espirometriaDetalleId, setEspirometriaDetalleId,
            medicinaGeneralAreasContext, setMedicinaGeneralAreasContext,
            medicinaGeneralDetalleId, setMedicinaGeneralDetalleId,
            laboratorioAreasContext, setLaboratorioAreasContext,
            laboratorioDetalleId, setLaboratorioDetalleId,
         }}
      >
         {props.children}
      </GestionContext.Provider>
   );
}

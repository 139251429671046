import react, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";

const Detalles = () => {
   const [value, setValue] = useState("");
   const [checked, setChecked] = useState(false);

   const [selectedCity, setSelectedCity] = useState(null);
   const cities = [
      { name: "New York", code: "NY" },
      { name: "Rome", code: "RM" },
      { name: "London", code: "LDN" },
      { name: "Istanbul", code: "IST" },
      { name: "Paris", code: "PRS" },
   ];

   return (
      <div>
         {/* parte inferior */}
         <div className="flex gap-3">
            <div>
               <div className="flex gap-5">
                  <div
                     className="flex flex-column text-center "
                     style={{
                        width: "120px",
                        justifyContent: "center",
                        alignItems: "center",
                     }}
                  >
                     <p>Indicados para extracción</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{
                           width: "70px",
                           heigth: "40px",
                           alignItems: "center",
                        }}
                     />
                  </div>
                  <div
                     className="flex flex-column text-center "
                     style={{
                        width: "80px",
                        justifyContent: "center",
                        alignItems: "center",
                     }}
                  >
                     <p>Lesión de caries</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ width: "70px", heigth: "40px" }}
                     />
                  </div>
                  <div
                     className="flex flex-column text-center"
                     style={{
                        width: "120px",
                        justifyContent: "center",
                        alignItems: "center",
                     }}
                  >
                     <p>Superficies con lesión caries</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{ width: "70px", heigth: "40px" }}
                     />
                  </div>
                  <div className="flex flex-column  text-center">
                     <p>Ausentes</p>
                     <InputText
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        style={{
                           width: "70px",
                           heigth: "40px",
                           marginTop: "20px",
                        }}
                     />
                  </div>
               </div>

               <div className="flex gap-5">
                  <div className="flex flex-column">
                     <p>Estado Bucal</p>
                     <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                     />
                  </div>
                  <div className="flex flex-column">
                     <p>Dentadura</p>
                     <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                     />
                  </div>
                  <div className="flex flex-column">
                     <p>Oclusión</p>
                     <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                     />
                  </div>
                  <div className="flex flex-column">
                     <p>Encias</p>
                     <Dropdown
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.value)}
                        options={cities}
                        optionLabel="name"
                        placeholder="Select a City"
                        className="w-full md:w-14rem"
                     />
                  </div>
               </div>

               <div>
                  <p>Observaciones</p>
                  <InputTextarea
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                     rows={8}
                     cols={128}
                  />
               </div>
            </div>
            <div>
               <div className="flex-column gap-3">
                  <p>Antecedentes patológicos </p>
                  <InputTextarea
                     value={value}
                     onChange={(e) => setValue(e.target.value)}
                     rows={5}
                     cols={30}
                  />
               </div>

               <div
                  className="contenedorTrata flex flex-column gap-2"
                  id="miContenedor"
               >
                  <div className="encabezadoTrata">
                     <span>Tratamiento/Recomendación</span>
                  </div>
                  <div className="CuerpoTrata flex gap-3">
                     <div className="flex flex-column gap-3">
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                             <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Curaciones</span>
                           </div>
                           <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                           </div>
                           <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <InputText
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              style={{ width: "60px", height: "40px" }}
                           />
                           </div>
                        </div>
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Exodoncias</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                           <InputText
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              style={{ width: "60px", height: "40px" }}
                           />
                        </div>
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Endodoncias</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                           <InputText
                              value={value}
                              onChange={(e) => setValue(e.target.value)}
                              style={{ width: "60px", height: "40px" }}
                           />
                        </div>
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Prótesis</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                           
                        </div>
                     </div>
                     <div className="flex flex-column gap-3">
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Profilaxis</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                          
                        </div>
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Destartraje</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                          
                        </div>
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span>Ortodoncia</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                        
                        </div>
                        <div
                           className="flex flex-row gap-2"
                           style={{ alignItems: "center" }}
                        >
                           <span> Control cada 6 meses</span>
                           <Checkbox
                              onChange={(e) => setChecked(e.checked)}
                              checked={checked}
                           ></Checkbox>
                          
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Detalles;

import React, { useContext } from 'react';
import { RAContext } from "../../../Page/RutaAtencion/RAContext";

const RATabsNav = ({ array }) => {
  const {
    setPageViewContext,
    pageViewContext,
    listRutasContext,
  } = useContext(RAContext);

  const activeTab = (e, item) => {
    
    let i

    let element = document.getElementsByClassName('ratn_tabs_item')

    for (i = 0; i < element.length; i++) {
      element[i].className = element[i].className.replace(" tab_active", "");
    }
    e.currentTarget.className += " tab_active";
    setPageViewContext(item?.id)
  };

  const scrollLeft = () => {
    const container = document.querySelector(".ratn_tabs_container");
    container.scrollLeft -= 125; // Ajusta la cantidad de desplazamiento deseada
  };
  
  const scrollRight = () => {
    const container = document.querySelector(".ratn_tabs_container");
    container.scrollLeft += 125; // Ajusta la cantidad de desplazamiento deseada
  };

  return (
    <div className="contenedor_general">
      <button className="scroll-button left-button botonL" onClick={scrollLeft}>
      {"<"}
      </button>
      <div className="ratn_tabs_container mt-2">
      {
          array?.map((item, index) => {

            let icon = listRutasContext?.find(i => i?.id === item?.id)

            return(
              <div
                key={index}
                className={`ratn_tabs_item ${index===pageViewContext?'tab_active':''}`}
                onClick={e => activeTab(e, item)}>
                <div>
                  <img src={icon?.icon} alt="icon_tab" style={{height:'13px'}}/>
                  <span>{item?.nombre}</span>
                </div>
              </div>
            )
          })
      }
      </div>
      <button className="scroll-button right-button botonR" onClick={scrollRight}>
        {">"}
      </button>
    </div>
  );
};

export default RATabsNav;
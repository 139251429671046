import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Chart } from "primereact/chart";
import { InputSwitch } from "primereact/inputswitch";
import "../../../../Style/Proforma.css"

export default function GClientes() {
  const [visibleModal, setVisibleModal] = useState(false);
  const [tipoCliente, setTipoCliente] = useState("");
  const [tituloModal, setTituloModal] = useState("");
  const [cliente, setCliente] = useState(1);
  const [checked, setChecked] = useState(true);
  const [checked1, setChecked1] = useState(true);

  const [datosCorporativo, setDatosCorporativo] = useState([
    {
      id: 1,
      TipoDoc: "RUC",
      NumDoc: 45678901,
      RazonS: "Alicorp",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Locales: "locales",
      Actividad: "Pendiente",
      Detalles: "detalles",
    },
  ]);
  const [datosClinica, setDatosClinica] = useState([
    {
      id: 1,
      TipoDoc: "RUC",
      NumDoc: 45678901,
      RazonS: "Medical Roma",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Locales: "locales",
      Actividad: "En espera",
      Detalles: "detalles",
    },
  ]);
  const [datosParticular, setDatosParticular] = useState([
    {
      id: 1,
      TipoDoc: "DNI",
      NumeroDoc: 45678901,
      Nombres: "Jorge Tito",
      APaterno: "Carranzas",
      AMaterno: "Campo",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Actividad: "Pendiente",
      Detalles: "Detalles",
    },
    {
      id: 2,
      TipoDoc: "DNI",
      NumeroDoc: 45678901,
      Nombres: "Sara",
      APaterno: "Flores",
      AMaterno: "Palma",
      CodProforma: "160932AO025",
      Contacto: "contacto",
      Actividad: "Pendiente",
      Detalles: "Detalles",
    },
  ]);
  const [datos, setDatos] = useState(datosCorporativo);
  const goBack = () => {
    window.history.back();
  };

  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [chartData1, setChartData1] = useState({});
  const [chartOptions1, setChartOptions1] = useState({});

  useEffect(() => {
    const data = {
      labels: ['16-05', '28-05', '06-07', '03-09'],
      datasets: [
        {
          label: 'Sales',
          data: [8000, 10000, 19500,],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)'
          ],
          borderColor: [
            'rgb(255, 159, 64)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)'
          ],
          borderWidth: 1
        }
      ]
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  useEffect(() => {
    const data = {
      labels: ['Ventas'],
      datasets: [
        {
          label: 'Sales',
          data: [110],
          backgroundColor: [
            'rgba(255, 159, 64, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(153, 102, 255, 0.2)'
          ],
          borderColor: [
            'rgb(255, 159, 64)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)'
          ],
          borderWidth: 1
        }
      ]
    };
    const options = {
      scales: {
        y: {
          beginAtZero: true
        }
      }
    };

    setChartData1(data);
    setChartOptions1(options);
  }, []);

  return (
    <div className="flex flex-1 gap-3 h-full ">
      <div className="flex flex-column gap-3">
        <div className="flex flex-column">
          <h1 className="tit">Proforma</h1>
          <p className="con">
            En este módulo usted podrá visualizar las proformas.
          </p>
        </div>
        <div className="flex flex-column gap-3">
          <button
            onClick={goBack}
            style={{
              background: "#FFFFFF",
              border: "1px solid #5555D8",
              color: "#9697D8",
              borderRadius: "7px",
              width: "183px",
              height: "33px",
            }}
            className="pi pi-angle-left flex justify-content-center align-items-center"
          >
            Ir a ventana anterior
          </button>
        </div>
        <div
          style={{ background: "white", padding: "10px" }}
          className="flex flex-row gap-3"
        >
          <div
            style={{ background: "#EBEBFF", width: "63px", height: "62px;" }}
            className="flex flex-column justify-content-center align-items-center"
          >
            <img src="https://cdn.discordapp.com/attachments/1078050726217011331/1111052768078221363/Maleta1_1.png" />
          </div>
          <div>
            <p className="verti font-bold">Alicord</p>
            <p className="subtip">RUC 2045678901</p>
            <p className="subtip pi pi-calendar pl-1">
              Fecha de creación: 14/02/2023</p>
          </div>
        </div>
        <div className="flex flex-column gap-5 bg-white border-round-xl p-3 min-h-full">
          <div className="flex gap-2 align-items-center justify-content-between">
            <label htmlFor="" className="font-semibold text-base">
              Lista de proformas
            </label>
            <Button
              label=" Actualizar"
              className="pi pi-sync border-round-xl surface-0 text-green-500 border-green-500"
            />
          </div>
          <div className="flex flex-column gap-2">
            {/* CLIENTE CORPOTARIVO */}
            <div
              className={`flex gap-3 py-2 px-3 align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-yellow-100 ${cliente === 1 ? "border-yellow-300" : ""
                }`}
              onClick={() => {
                setCliente(1);
                setDatos(datosCorporativo);
              }}
            >
              <i className="pi pi-folder-open  text-xl bg-yellow-100 border-round-lg p-2 text-yellow-500" />
              <div>
                <label htmlFor="" className="textoo font-bold">
                  Proforma #160944A0025
                </label>
                <p
                  className="textaba"
                  style={{ width: "180px" }}
                >
                  Capacitaciones
                </p>
              </div>
              {cliente === 1 ? (
                <i className="pi pi-ellipsis-v text-400 font-semibold" />
              ) : (
                <div className="w-1rem"></div>
              )}
            </div>
            {/* CLIENTE CLINICA */}
            <div
              className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-yellow-100 ${cliente === 2 ? "border-yellow-300" : ""
                }`}
              onClick={() => {
                setCliente(2);
                setDatos(datosClinica);
              }}
            >
              <i className="pi pi-folder-open text-xl bg-yellow-100 border-round-lg p-2 text-yellow-500" />
              <div>
                <label htmlFor="" className="textoo font-bold">
                  Proforma #162588466001
                </label>
                <p
                  className="white-space-nowrap overflow-hidden textaba"
                  style={{ width: "180px" }}
                >
                  Exámenes médicos ocupacionales
                </p>
              </div>
              {cliente === 2 ? (
                <i className="pi pi-ellipsis-v text-400 font-semibold" />
              ) : (
                <div className="w-1rem"></div>
              )}
            </div>
            {/* CLIENTE PARTICULAR */}
            <div
              className={`flex gap-3 py-2 px-3  align-items-center justify-content-between border-round-xl shadow-1 border-2 border-transparent hover:border-yellow-100 ${cliente === 3 ? "border-yellow-300" : ""
                }`}
              onClick={() => {
                setCliente(3);
                setDatos(datosParticular);
              }}
            >
              <i className="pi pi-folder-open text-xl bg-yellow-100 border-round-lg p-2 text-yellow-500" />
              <div>
                <label htmlFor="" className="textoo font-bold">
                  Proforma #162588466001
                </label>
                <p
                  className="white-space-nowrap overflow-hidden textaba"
                  style={{ width: "180px" }}
                >
                  Vigilancia médico ocupacionales
                </p>
              </div>
              {cliente === 3 ? (
                <i className="pi pi-ellipsis-v text-400 font-semibold" />
              ) : (
                <div className="w-1rem"></div>
              )}
            </div>
          </div>
          <div className="flex flex-column flex-1 align-items-center justify-content-center">
            <img
              src="https://i.ibb.co/LdKxCVF/buzon-joel.png"
              alt="imagencita"
            />
            <label>Lista de proformas</label>
          </div>
        </div>
      </div>
      <div className="border-round-xl tablaGClientes w-9">
        {/* buscador */}
        <div className="flex-1 flex justify-content-end">
          <span
            className="xl:w-auto lg:w-auto md:w-full sm:w-full w-full p-input-icon-left"
            style={{ minWidth: "168px" }}
          >
            <i className="pi pi-search text-indigo-500" />
            <InputText
              placeholder="Buscador"
              className="border-round-xl xl:w-auto lg:w-auto md:w-full sm:w-full w-full"
            />
          </span>
        </div>
        {/* tabla */}
        {cliente !== 3 ? (
          <div>
            <div className="flex flex-row mb-3">
              <i className="pi pi-folder-open text-xl bg-yellow-100 border-round-lg p-2 text-yellow-500" />
              <div className="flex flex-column">
                <p className="textoo font-bold">Proforma #160944A0025</p>
                <p className="textaba">Capacitaciones</p>
              </div>
            </div>
            <div className="mb-3 textoo font-bold" >
              <span>Detalle de la solicitud</span>
            </div>
            <div className="flex flex-wrap justify-content-between">
              <div className="flex flex-column gap-3">
                <p className="textin font-bold">Solicitud</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
                <p className="textin font-bold">Nombre:</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
              </div>
              <div className="flex flex-column gap-3">
                <p className="textin font-bold">Código de proforma</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
                <p className="textin font-bold">Proforma aceptada</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
              </div>
              <div className="flex flex-column gap-3">
                <p className="textin font-bold">Cantidad solicitada</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
                <p className="textin font-bold">Modalidad</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
              </div>
              <div className="flex flex-column gap-3 mb-3">
                <p className="textin font-bold">Periodo de facturación</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
                <p className="textin font-bold">Días de crédito</p>
                <InputText
                  style={{
                    width: "190px",
                    height: "40px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
              </div>
            </div>
            <div className="mb-3">
              <span className="textoo font-bold">Linea de tiempo del consumo del servicio</span>
            </div>
            <div className="flex flex-column mb-4"
              style={{
                width: "1200px",
                height: "420px",
                background: "#EBEFFD",
                borderRadius: "14px",
              }}
            >
              <div className="flex flex-row align-items-center gap-3 w-full" style={{ width: "642px", height: "322px", padding: "15px" }}>  <InputText
                style={{
                  width: "239px",
                  height: "33px",
                }}
                type="text"
                className="p-inputtext-sm"
                placeholder=""
              />  <InputText
                  style={{
                    width: "239px",
                    height: "33px",
                  }}
                  type="text"
                  className="p-inputtext-sm"
                  placeholder=""
                />
                <button
                  style={{
                    background: "#FFFFFF",
                    border: "1px solid #5555D8",
                    color: "#9697D8",
                    borderRadius: "6px",
                    width: "114px",
                    height: "33px",
                    marginLeft: "auto",

                  }}
                  className="pi pi-file-excel "

                >
                  Reporte
                </button>
              </div>


              <div className="flex flex-wrap w-full h-full gap-3" style={{ padding: "20px" }}>
                <div className="flex flex-row align-items-center h-full pl-5" style={{ background: "#FFFFFF" }}>
                  <div className="rotar">
                    <p className="verti font-bold"> Monto (S/.)</p>
                  </div>
                  <div>
                    <Chart type="bar" data={chartData} options={chartOptions} style={{ height: "322px", width: "642px" }} />
                  </div>
                </div>
                <div className="flex flex-row align-items-center h-full pl-5" style={{ background: "#FFFFFF" }}>
                  <div className="rotar">
                    <p className="verti font-bold"> Porcentaje (%)</p>
                  </div>
                  <div >
                    <Chart type="bar" data={chartData1} options={chartOptions1} style={{ height: "322px", width: "300px" }} className="venta" />
                  </div>
                </div>

              </div>
            </div>
            <div className="mb-3">
              <span className="textoo font-bold">Inicio de la solicitud</span>
            </div>
            <div className="flex flex-row align-items-center gap-3"
              style={{
                width: "1200px",
                height: "75px",
                background: "#EBEFFD",
                borderRadius: "14px",
              }}
            >
              <div className="flex flex-row align-content-center gap-3 ml-3">
                <p>Fecha de inicio de la facturación</p>

                <div className="flex flex-row align-content-center gap-3 infer">
                  <p>Finalizar servicio</p>
                  <InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} /></div>
              </div>

              <div className="flex flex-row align-content-center gap-3">
                <p>Iniciar servicio</p>
                <InputSwitch checked={checked1} onChange={(e) => setChecked1(e.value)} /></div>
            </div>
          </div>
        ) : (
          <table></table>
        )}
      </div>
    </div>
  );
}

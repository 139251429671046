import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";

//Importación de componentes generales
import { Header, Footer } from '../../../Pure/SeccionesNavegacion';
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";

//Importación de estilos
import "../../../../Style/stylePerfil.css";
import "../style/RetraccionesVer.css";

import img1 from "../../../../Images/Perfil/icon/ESTADISTICAS.svg";

export default function DetraccionComponent({ detracciones, getbregma, showToast }) {
  const dispatch = useDispatch();
  //declaracion de VARIABLES
  const { http } = AuthUser();
  const toast = useRef(null);
  const [visible, setVisible] = useState(false);
  const [detraccion, setDetraccion] = useState(null);
  //end declaracion

  // consultas API
  const handleDestroy = () => {
    http
      .delete(`detraccion/destroy/${detraccion.id}`)
      .then((response) => {
      })
      .catch((error) => {
        console.error("error", error);
        showToast('error', 'Error', 'Ha ocurrido un error al eliminar.')
      })
  }

  const handleCreate = async () => {
    dispatch(startLoading());
    try {
      const response = await http.post("detraccion/create", detraccion)
      if (response?.data?.resp) {
        await Promise.all([
          setVisible(false),
          getbregma()
        ]);
        showToast(
          "success",
          "Guardado correctamente",
          "Se guardó la cuenta de detracción"
        );
      } else if (response?.data?.error) {
        showToast('error', 'Error', response.data.error)
      }
    } catch (error) {
      showToast('error', 'Error', 'Contacte a soporte técnico')
      console.error(error);
    } finally {
      dispatch(endLoading());
    }
  }

  //end consultas API

  //FUNCIONES
  const [FaltaRetrac, setFaltaRetrac] = useState(false);
  const VerificarDate = () => {
    var verifi = true;
    if (detraccion.numero_cuenta === "" || detraccion.numero_cuenta === null) {
      verifi = false;
      setFaltaRetrac(true)
    }
    return verifi;
  }

  const handleEdit = () => {
    var veri = VerificarDate();
    if (veri) {
      if (detracciones) {
        /*crear detraccion */
        handleCreate()
      } else {
        /*eliminar y luego crear detraccion */
        handleDestroy();
        handleCreate()
      };
    }
  };

  const handleCerrarModalEditar = () => {
    setVisible(false)
    setDetraccion({ ...detraccion, numero_cuenta: detracciones?.[0]?.numero_cuenta });
    setFaltaRetrac(false);
  }

  const handleChangeNumeroCuenta = (e) => {
    setDetraccion({ ...detraccion, numero_cuenta: e.target.value });
    setFaltaRetrac(false);
  };

  useEffect(() => {
    setFaltaRetrac(false);
  }, [visible])

  //end funciones

  useEffect(() => {
    setDetraccion({
      id: detracciones?.[0]?.id,
      numero_cuenta: detracciones?.[0]?.numero_cuenta
    })
  }, [detracciones]);

  const EstiloLetraSub = {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '17px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: "#4545BC"
  };

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '10px', marginBottom: "10px", display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0' }}>
      <div className="flex flex-wrap align-items-center justify-content-around p-3 w-full">
        <h3 className="flex flex-wrap titulos text-base" style={{ color: "#4545BC" }} >Cuenta de Detracciones</h3>
        <div className="" style={{ width: 'auto', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <h6 style={{ ...EstiloLetraSub, marginRight: '20px' }}>{detracciones?.[0]?.numero_cuenta}</h6>
          <Button label="" icon="pi pi-pencil"
            className="p-button-text p-button-success"
            style={{ height: "40px", width: "40px", marginRight: '5px', background: "#BFF1DF" }}
            onClick={() => setVisible(true)} />
        </div>
      </div>

      <div>
        {/*MODAL DETRACCION */}
        <Dialog
          header={<Header icono={img1} titulo={"Detracción"} />}
          visible={visible} style={{ width: "30vw", minWidth: "300px" }}
          footer={<Footer type={"button"} onClickCancelar={() => setVisible(false)} onClickEnviar={handleEdit} label={"Guardar"} />}
          onHide={() => handleCerrarModalEditar()}>
          <div className="h-auto flex flex-column">

            <div className="flex flex-column gap-3">
              <div className="flex flex-column gap-2 relative pt-1 pb-1">
                <label htmlFor="num" className="font-bold block">
                  Número de Cuenta
                </label>
                <InputText id="num" keyfilter="num" className={`w-full InputRetraccionNumCuAP ${FaltaRetrac ? 'Select' : ''}`}
                  value={detraccion?.numero_cuenta || ""} onChange={handleChangeNumeroCuenta} placeholder="Escribe aquí para actualizar el número de cuenta" />
                <div className={`InputRetraccionNumCuAPDiv ${FaltaRetrac ? 'Select' : ''}`}></div>
                <p className={`InputRetraccionNumCuAPTextError ${FaltaRetrac ? 'Select' : ''}`}>Necesita ingresar un número</p>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
      <Toast ref={toast} />
    </div>
  );
}
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { Toast } from 'primereact/toast';
import React, { useRef } from 'react';

export default function ContactenosB({ SendContactenosB }) {
  const [formConctBreg, setFormConctBreg] = useState({
    numerodecelular: "",
    correoelectronico: "",
    telefono: "",
    telefono02: ""
  });
  const [buttonActive, setButtonActive] = useState(false);
  const [firstClick, setFirstClick] = useState(true);

  function OnchangeInputs(e) {
    const { name, value } = e.target;
    setFormConctBreg({ ...formConctBreg, [name]: value });
  }

  const toast = useRef(null);
  const SendInfo = () => {
    

    if (firstClick) {
      setButtonActive(!buttonActive);
      setFirstClick(false);
      return;
    }
    toast.current.show({ severity: 'info', summary: 'Info', detail: 'Se guardo correctamente' });
    //Enviar datos a la API
    SendContactenosB(formConctBreg);

    setFirstClick(true);
    setButtonActive(!buttonActive);
  
      // Aquí puedes agregar cualquier lógica adicional que necesites antes de mostrar la alerta
    //alert('Se guardó correctamente.');
    
  };

  // #region Skeleton
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3000);
  }, [])
  // #endregion
  

  return (
    <div className="flex-1 flex bg-white border-round-lg pt-3 pb-3 pr-5 pl-5 flex-column gap-3">
      <div className="flex align-items-start justify-content-between  border-round-lg ">
        <div>
          <img
            width="54px"
            className="border-round-lg bg-indigo-100 p-3"
            src="https://i.ibb.co/hdPWyD0/Group-1920.png"
            alt="Group-1920"
            border="0"
          />
        </div>

        {/* <div className="flex gap-3 ">
          <Toast ref={toast} />
          <Button
            onClick={SendInfo}
            className={'bg-blue-100 border-blue-100'}
            icon={`${'pi pi-save text-blue-500'} font-bold text-base p-1`}
          />
        </div>*/}
      </div>

      <div className="flex gap-3 flex-column  ">
        <div className="flex gap-3">
          <p className="text-indigo-500 font-bold text-base white-space-nowrap">
            Contáctenos
          </p>
          {/*<p className="text-indigo-500 text-base">Administrador</p>*/}
        </div>
        <p className="text-sm">
          Registre los datos de contacto para brindarle una ayuda a tus
          clientes.
        </p>
      </div>

      <div className="flex-1 flex flex-column justify-content-between">
        <div className="flex flex-column gap-3">
          <div className="flex flex-column gap-3  ">
            <div className="flex gap-2 align-items-center ">
              <i className="pi pi-whatsapp font-bold text-base"></i>
              <label htmlFor="numerodecelular" className="text-sm font-bold ">
                Número de celular
              </label>
            </div>

            {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
                <InputText
                  onChange={OnchangeInputs}
                  id="numerodecelular"
                  name="numerodecelular"

                  type="text"
                  className="p-inputtext-sm"
                  placeholder="938 211 243"
                />
            }
          </div>

          <div className="flex flex-column gap-3 ">
            <div className="flex gap-2 align-items-center ">
              <i className="pi pi-envelope font-bold text-base"></i>
              <label htmlFor="correoelectronico" className="text-sm font-bold">
                Correo Eléctronico
              </label>
            </div>
            {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
                <InputText
                  onChange={OnchangeInputs}
                  id="correoelectronico"
                  name="correoelectronico"
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="Soporte@bregma.com.pe"
                />
            }
          </div>

          <div className="flex flex-column gap-3  ">
            <div className="flex gap-2 align-items-center ">
              <i className="pi pi-phone font-bold text-base"></i>
              <label htmlFor="telefono" className="text-sm font-bold">
                Teléfono
              </label>
            </div>
            {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
                <InputText
                  onChange={OnchangeInputs}
                  id="telefono"
                  name="telefono"
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="938 211 243"
                />
            }
          </div>

          <div className="flex flex-column gap-3">
            <div className="flex gap-2 align-items-center ">
              <i className="pi pi-phone font-bold text-base"></i>
              <label htmlFor="telefono02" className="text-sm font-bold">
                Teléfono 02
              </label>
            </div>
            {
              loading ? <Skeleton className="border-round-lg" height="41px" /> :
                <InputText
                  onChange={OnchangeInputs}
                  id="telefono02"
                  name="telefono02"
                  type="text"
                  className="p-inputtext-sm"
                  placeholder="938 211 243"
                />
            }
          </div>
        </div>
        
      </div>


    </div>
  );
}

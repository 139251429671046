import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "../../../Style/Liquidacion.css";
import { InputText } from "primereact/inputtext";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";

export default function DialogDetallesLiquidacion({
   getServicios,
   transformedNodes,
}) {
   const [visible, setVisible] = useState(false);

   const [activeTab, setActiveTab] = useState(0);

   const changeTab = (index) => {
      setActiveTab(index);
   };

   const [selectedButton, setSelectedButton] = useState(null);
   const [selectedButton2, setSelectedButton2] = useState(null);
   const [selectedButton3, setSelectedButton3] = useState(null);

   const handleButtonClick = (buttonNumber) => {
      setSelectedButton(buttonNumber);
      setActiveTab(1); // Cambia a la pestaña de Servicio al hacer clic en "Siguiente"
   };   

   const handleButtonClick2 = (buttonNumber) => {
      setSelectedButton2(buttonNumber);
   };
   const handleButtonClick3 = (buttonNumber) => {
      setSelectedButton3(buttonNumber);
   };

   return (
      <div className="flex justify-content-center">
         <Button
            className="flex flex-row gap-2 align-items-center p-button-text"
            onClick={() => setVisible(true)}
            style={{
               height: "32px",
               fontWeight: "450",
               fontSize: "16px",
               color: "#00a15c",
               padding: 0,
            }}
         >
            <i className="pi pi-info-circle" />
            <span>Ver detalles</span>
         </Button>
         <Dialog
            visible={visible}
            style={{
               width: "909px",
               borderRadius: "25px",
            }}
            header={
               <>
                  <div className="flex flex-row">
                     <div
                        className="flex justify-content-center align-items-center"
                        style={{
                           background: "#EBEBFF",
                           width: "60px",
                           height: "60px",
                           borderRadius: "10px",
                        }}
                     ></div>
                  </div>
                  <div className="flex flex-column w-full gap-1">
                     <span
                        style={{
                           fontWeight: "700",
                           fontSize: "18px",
                           color: "#5555D8",
                        }}
                     >
                        Detalles de atención
                     </span>
                     <span
                        style={{
                           fontWeight: "500",
                           fontSize: "14px",
                           color: "#7B8794",
                        }}
                     >
                        En esta sección usted puede ver los detalles de
                        atencion.
                     </span>
                  </div>
               </>
            }
            onHide={() => setVisible(false)}
         >
            <div className="flex flex-column gap-3">
               <div className="tab-container">
                  <div className="tab-header flex flex-wrap justify-content-start gap-3">
                     <button
                        className={`tab-button ${
                           activeTab === 0 ? "active" : ""
                        }`}
                        onClick={() => changeTab(0)}
                     >
                        Detalles
                     </button>
                     <button
                        className={`tab-button ${
                           activeTab === 1 ? "active" : ""
                        }`}
                        onClick={() => changeTab(1)}
                     >
                        Servicio
                     </button>
                  </div>
                  <div
                     style={{
                        borderBottom: "0.5px solid rgba(69, 69, 188, 0.6)",
                        marginTop: "-10px",
                     }}
                  />
                  <div className="tab-content">
                     <div
                        className={`tab-panel ${
                           activeTab === 0 ? "active" : ""
                        }`}
                     >
                        <div className="flex flex-column gap-3">
                           <div className="flex flex-wrap gap-3">
                              <div className="ConetenedorCapacitaciones flex flex-column flex-1 gap-3">
                                 <div className="deta1">
                                    <div className="rB">
                                       <div
                                          className="flex flex-column gap-1 flex-1"
                                          style={{
                                             height: "69px",
                                             width: "331px",
                                          }}
                                       >
                                          <span
                                             style={{
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                                fontWeight: "500",
                                                color: "#344054",
                                             }}
                                          >
                                             Razón Social
                                          </span>
                                          <InputText
                                             style={{
                                                height: "41px",
                                                width: "331px",
                                             }}
                                          />
                                       </div>
                                       <div className="ss gap-1 flex-1">
                                          <span
                                             style={{
                                                fontSize: "14px",
                                                lineHeight: "20px",
                                                fontWeight: "500",
                                                color: "#344054",
                                             }}
                                          >
                                             Seleccionar servicio
                                          </span>
                                          <span className="p-input-icon-left">
                                             <i class="pi pi-search"></i>
                                             <input
                                                style={{
                                                   fontSize: "14px",
                                                   lineHeight: "24px",
                                                   fontWeight: "400",
                                                   color: "#667085",
                                                }}
                                                class="p-inputtext p-component input_search_AHP"
                                                placeholder="Buscador"
                                             ></input>
                                          </span>
                                       </div>
                                    </div>

                                    <div className="rc-cuadro">
                                       <div className="rca">
                                          <div className="rc-detalle">
                                             <div className="flex flex-wrap flex-column gap-1 flex-1">
                                                <span
                                                   style={{
                                                      fontSize: "14px",
                                                      lineHeight: "20px",
                                                      fontWeight: "500",
                                                      color: "#344054",
                                                   }}
                                                >
                                                   RUC
                                                </span>
                                                <InputText
                                                   style={{
                                                      height: "41px",
                                                      width: "154px",
                                                   }}
                                                />
                                             </div>

                                             <div className="flex flex-wrap flex-column gap-1 flex-1">
                                                <span
                                                   style={{
                                                      fontSize: "14px",
                                                      lineHeight: "20px",
                                                      fontWeight: "500",
                                                      color: "#344054",
                                                   }}
                                                >
                                                   Condiciones de pago
                                                </span>
                                                <InputText
                                                   style={{
                                                      height: "40px",
                                                      width: "165px",
                                                   }}
                                                />
                                             </div>
                                          </div>
                                          <div
                                             className="flex flex-column gap-1 flex-1"
                                             style={{
                                                height: "66px",
                                                width: "331px",
                                             }}
                                          >
                                             <span
                                                style={{
                                                   fontSize: "14px",
                                                   lineHeight: "20px",
                                                   fontWeight: "500",
                                                   color: "#344054",
                                                }}
                                             >
                                                Asesor de ventas
                                             </span>
                                             <InputText
                                                style={{
                                                   height: "41px",
                                                   width: "331px",
                                                }}
                                             />
                                          </div>
                                       </div>
                                       <div className="SErvicios-AD">
                                       <TreeTable
                                                tableStyle={{ minWidth: '22rem' }}
                                                value={transformedNodes}
                                                className="TablaDeOperacionesServicios ventas contactList"
                                                emptyMessage={<p className="text-center text-gray-500">
                                                    
                                                </p>}
                                                style={{
                                                    width: '100%', // Asegura que la tabla ocupe todo el ancho del contenedor
                                                }}
                                            >
                                                <Column
                                                    field="nombre"
                                                    expander
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                        alignItems: 'center',
                                                        padding: '40px',
                                                        fontSize: '11px'
                                                    }} />

                                                <Column
                                                    
                                                    className="text-center text-green-500"
                                                    headerStyle={{
                                                        padding: '5px',
                                                        whiteSpace: 'normal',
                                                        fontSize: '15px'
                                                    }}
                                                >Hola</Column>
                                                <Column
                                                   

                                                    className="text-center text-green-500"
                                                    headerStyle={{
                                                        padding: '5px',
                                                        whiteSpace: 'normal',
                                                        fontSize: '15px',
                                                    }}

                                                >HOLA</Column>

                                            </TreeTable>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="botones-deta gap-3 justify-content-end">
                                    <button 
                                       className= "button-ser-deta " 
                                       onClick={() => setVisible(false)}>
                                        <p
                                        style={{
                                          fontFamily: "'Montserrat",
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          color: "#344054", 
                                          lineHeight: "20px"  
                                       }}
                                        >
                                          Cancelar</p>
                                    </button>
                                    <button className="buton-ser-det " onClick={() => handleButtonClick(3)}>
                                        <p
                                          style={{
                                          fontFamily: "'Montserrat",
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          color: "#FFFFFF", 
                                          lineHeight: "17.7px"  
                                       }}
                                        >
                                          Siguiente</p>
                                    </button>
                                 </div>
                              </div>
                              {/* <div className="ConetenedorCapacitaciones flex flex-column flex-1 gap-3">
                                            <div className="flex flex-column gap-1 flex-1">
                                                <span>Estado</span>
                                                <div className="flex flex-row">       
                                                cvreh 
                                                    <div className="button-container flex flex-row gap-3">
                                                        <button
                                                            className={`buttonN ${selectedButton === 1 ? "selected" : ""
                                                                }`}
                                                            onClick={() => handleButtonClick(1)}
                                                        >
                                                            Culminado
                                                        </button>
                                                        <button
                                                            className={`buttonN ${selectedButton === 2 ? "selected" : ""
                                                                }`}
                                                            onClick={() => handleButtonClick(2)}
                                                        >
                                                            No culminado
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <TreeTable
                                                tableStyle={{ minWidth: '22rem' }}
                                                value={transformedNodes}
                                                className="TablaDeOperacionesServicios ventas contactList"
                                                emptyMessage={<p className="text-center text-gray-500">
                                                    Seleccione un paquete para ver su detalle.
                                                </p>}
                                                style={{
                                                    width: '100%', // Asegura que la tabla ocupe todo el ancho del contenedor
                                                }}
                                            >
                                                <Column
                                                    field="nombre"
                                                    expander
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                        alignItems: 'center',
                                                        padding: '40px',
                                                        fontSize: '11px'
                                                    }} />

                                                <Column
                                                    
                                                    className="text-center text-green-500"
                                                    headerStyle={{
                                                        padding: '5px',
                                                        whiteSpace: 'normal',
                                                        fontSize: '15px'
                                                    }}
                                                >Hola</Column>
                                                <Column
                                                   

                                                    className="text-center text-green-500"
                                                    headerStyle={{
                                                        padding: '5px',
                                                        whiteSpace: 'normal',
                                                        fontSize: '15px',
                                                    }}

                                                >HOLA</Column>

                                            </TreeTable>
                                        </div> */}
                           </div>
                        </div>
                     </div>

                     <div
                        className={`tab-panel ${
                           activeTab === 1 ? "active" : ""
                        }`}
                     >
                        <div className="flex flex-column gap-3">
                           <div className="flex flex-column gap-2">
                              <h1>Examen Médico Ocupacional</h1>
                              <span>
                                 Recuerde que según la
                                 ley___________________________ se indica que
                                 todos sus trabajadores tienen que realizar 1
                                 examen médico ocupacional.
                              </span>
                           </div>
                           <div
                              className="flex flex-column gap-2"
                              style={{ marginTop: "30px" }}
                           >
                              <span
                                 style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#344054",
                                 }}
                              >
                                 Fecha de asignación del servicio
                              </span>
                              <InputText
                                 style={{ width: "50%", height: "40px" }}
                              />
                           </div>
                           <div className="flex flex-column gap-2">
                              <span
                                 style={{
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    color: "#344054",
                                 }}
                              >
                                 Detalles del exámen
                              </span>
                              <Button
                                 className="p-button-text flex justify-content-star gap-2"
                                 style={{
                                    background: "#F6F2FC",
                                    width: "50%",
                                    height: "42px",
                                    padding: "5px",
                                 }}
                              >
                                 <p
                                    style={{
                                       fontWeight: "400",
                                       fontSize: "14px",
                                       color: "#7A7AEE",
                                    }}
                                 >
                                    Descargar PDF
                                 </p>
                              </Button>
                           </div>
                        </div>
                     </div>
                     <div
                        className={`tab-panel ${
                           activeTab === 2 ? "active" : ""
                        }`}
                     >
                        <div className="flex flex-wrap gap-3">
                           <div className="ContenedorInformeyCredenciales flex flex-column flex-1 gap-3">
                              <span>Información</span>
                              <div className="ContenedorForm flex flex-column gap-2">
                                 <label htmlFor="email">Correo</label>
                                 <InputText style={{ height: "40px" }} />
                              </div>
                              <div className="ContenedorForm flex flex-column gap-2">
                                 <label htmlFor="usuario">Usuario</label>
                                 <div className="button-container flex flex-row gap-3">
                                    <button
                                       className={`buttonN ${
                                          selectedButton3 === 1
                                             ? "selected"
                                             : ""
                                       }`}
                                       onClick={() => handleButtonClick3(1)}
                                    >
                                       Si es usuario
                                    </button>
                                    <button
                                       className={`buttonN ${
                                          selectedButton3 === 2
                                             ? "selected"
                                             : ""
                                       }`}
                                       onClick={() => handleButtonClick3(2)}
                                    >
                                       No es usuario
                                    </button>
                                 </div>
                              </div>
                              <div className="ContenedorForm flex flex-column gap-2">
                                 <label htmlFor="phone">Celular</label>
                                 <InputText style={{ height: "40px" }} />
                              </div>
                              <div className="ContenedorForm flex flex-column gap-2">
                                 <label htmlFor="rol">Rol</label>
                                 <InputText style={{ height: "40px" }} />
                              </div>
                           </div>
                           <div className="ContenedorInformeyCredenciales flex flex-column flex-1 gap-3">
                              <span>Envio de Credenciales</span>
                              <div>
                                 <p
                                    style={{
                                       fontWeight: "400",
                                       fontSize: "14px",
                                       color: "#7B8794",
                                    }}
                                 >
                                    Enviar las credenciales por:
                                 </p>
                              </div>
                              <div className="flex flex-column gap-3">
                                 <Button className="p-button-outlined flex justify-content-center gap-2">
                                    <p
                                       style={{
                                          fontWeight: "400",
                                          fontSize: "14px",
                                       }}
                                    >
                                       Correo
                                    </p>
                                 </Button>
                                 <Button className="p-button-outlined flex justify-content-center gap-2">
                                    <p
                                       style={{
                                          fontWeight: "400",
                                          fontSize: "14px",
                                       }}
                                    >
                                       Whatsapp
                                    </p>
                                 </Button>
                              </div>
                              <div
                                 className="flex flex-column gap-3"
                                 style={{ padding: "0px 20%" }}
                              >
                                 <Button
                                    onClick={() => setVisible(false)}
                                    className="p-button-text flex w-full justify-content-center"
                                    style={{ border: "1px solid #D0D5DD" }}
                                 >
                                    <p
                                       style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          color: "#393838",
                                       }}
                                    >
                                       Cancelar
                                    </p>
                                 </Button>
                                 <Button
                                    onClick={() => setVisible(false)}
                                    autoFocus
                                    className="flex w-full justify-content-center"
                                 >
                                    <p
                                       style={{
                                          fontWeight: "500",
                                          fontSize: "14px",
                                          color: "#fff",
                                       }}
                                    >
                                       Enviar
                                    </p>
                                 </Button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
      </div>
   );
}

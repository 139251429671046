import React, { useContext, useState, useEffect, useRef } from "react";
import { PsicologiaContext } from "../PsicologiaContext";

import '../../../Style/DatosOcupacionales/psi_dat_ocup.css';
import SelectFlechita from "../../../Components/Psicologia/ComponentR/SelectFlechita";
import { RAContext } from "../../RutaAtencion/RAContext";
//import ButtonVoiceReciclable from "../../../Components/ButtonVoice/ButtonVoiceReciclable";
import ButtonVoiceReciclable from "../../../Components/ButtonVoice/ButtonVoiceReciclable2";
import VoiceButton from "../../../Components/ButtonVoice/VoiceButton";
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
        

const PagePsiDatOcup = () => {
   let { datOcupContext, setDatOcupContext } = useContext(PsicologiaContext);
   let { pageViewContext, stateAtencion } = useContext(RAContext)
   const [motivo, setMotivo] = useState(datOcupContext?.motivo_evaluacion ?? null);
   const [principales, setPrincipales] = useState(datOcupContext?.principales_riesgos ?? null);
   const [medidas, setMedidas] = useState(datOcupContext?.medidas_seguridad ?? null);
   const [historia, setHistoria] = useState(datOcupContext?.historia_familiar ?? null);
   const [accidente, setAccidente] = useState(datOcupContext?.accidentes_enfermedades ?? null);
   const [habito, setHabito] = useState(datOcupContext?.habitos ?? null);
   const [observacion, setObservacion] = useState(datOcupContext?.observaciones ?? null);

   const [selects, setSelects] = useState(null);
   const [textoIngresado, setTextoIngresado] = useState(false);
   const [textoSeleccion, setTextoSeleccion] = useState(null)

   const textareaRef = useRef(null);

   const handleM = (event) => {
      setDatOcupContext({
         ...datOcupContext,
         psi_motivo_evaluacion_id: event.value.id,
         motivo_evaluacion: event.value.label || event.value
      });
   };

   const handleR = (event) => {
      setDatOcupContext({
         ...datOcupContext,
         psi_principales_riesgos_id: event.value.id,
         principales_riesgos: event.value.label || event.value
      });
   };

   const handleMe = (event) => {
      setDatOcupContext({
         ...datOcupContext,
         psi_medidas_seguridad_id: event.value.id,
         medidas_seguridad: event.value.label || event.value
      });
   };

   //Se utilizan cuando se selecciona un autocomplete
   const handleH = (event) => {
      setDatOcupContext({ ...datOcupContext, historia_familiar_id: event.value.id, historia_familiar: event.value.label || event.value });
   };
   const handleA = (event) => {
      setDatOcupContext({ ...datOcupContext, accidentes_enfermedades_id: event.value.id, accidentes_enfermedades: event.value.label || event.value });
   };
   const handleHa = (event) => {
      setDatOcupContext({ ...datOcupContext, habitos_id: event.value.id, habitos: event.value.label || event.value });
   };

   //Multiselect
   const handleP = (event) => {
      setDatOcupContext({ ...datOcupContext,  observaciones: event});
   };

   const motivos_options = [
      { label: "Evaluación Pre Ocupacional", id: 1 },
      { label: "Evaluación Anual", id: 2 },
      { label: "Evaluación de Retiro", id: 3 },
   ];

   const riesgos_options = [
      { label: "No refiere", id: 1 },
      { label: "Exposición a caída a desnivel, ruidos", id: 2 },
      { label: "Choque, malas maniobras", id: 3 },
      { label: "Caída de altura, atrapamientos", id: 4 },
      { label: "Accidentes de tránsito", id: 5 },
      { label: "Mutilaciones, caídas, pérdida de la vida", id: 6 },
      { label: "Quemaduras, fracturas, corte", id: 7 },
      { label: "No estar concentrado en el trabajo", id: 8 },
      { label: "Mala postura, tensión psicológica, estar triste o preocupado", id: 9, },
      { label: "No dormir sus horas necesarias", id: 10 },
      { label: "No realizar procedimientos de seguridad del área", id: 11 },
      { label: "Ingerir bebidas alcohólicas", id: 12 },
   ];


   const historias_options = [
      { label: "Soltero, vive con sus padres y hermanos", id: 1 },
      { label: "Soltera, vive solo", id: 2 },
      { label: "Casado, vive con su esposa e hijos de", id: 3 },
      { label: "Casada, vive con su esposo e hijos de", id: 4 },
      { label: "Vive con su conviviente", id: 5 },
      { label: "Vive con su conviviente e hijo", id: 6 },
      { label: "Vive con sus familiares más cercenos", id: 7 },
      { label: "Vive con sus abuelos", id: 8 },
      { label: "Vive con sus padres", id: 9 },
      { label: "Vive con sus hermanos", id: 10 }
   ];

   const medidas_options = [
      { label: "No refiere", id: 1 },
      { label: "Uso correcto de los EPPS", id: 2 },
      { label: "Estar atento y concentrado en el trabajo a realizar", id: 3 },
      { label: "Seguir procedimientos", id: 4 },
      { label: "Manejo defensivo, realizar check list", id: 5 },
      { label: "Recibir charlas de inducción y seguridad", id: 6 },
      { label: "Revisar el área de seguridad", id: 7 },
   ];

   const accidentes_options = [
      { label: "No refiere", id: 1 },
      { label: "Problemas de salud relacionados con una intervención quirúrgica", id: 2 },
      { label: "Descanso médico por", id: 3 },
      { label: "Accidente incapacitante, refiere", id: 4 },
      { label: "Lesiones y/o fracturadas ocurridas en vida cotidiana", id: 5 },
      { label: "Dificultades psicológicas que no fueron atendidas por especialista", id: 6 },
      { label: "Problemas de salud crónicos con tratamiento médico", id: 7 },
      { label: "Problemas de salud crónicos sin tratamiento médico", id: 8 },
      { label: "Lesiones y/o fracturadas ocurridas en trabajos anteriores", id: 9 },
   ];

   const habitos_options = [
      { label: "No refiere", id: 1 },
      { label: "Toma bebidas alcohólicas (botellas de cerveza aproximadamente) en compromisos sociales y fuma aproximadamente cigarros en su tiempo libre", id: 2 },
   ];

   const pruebas_options = [
      { label: "Ninguna", id: 1 },
      { label: "No evidencia miedo o temor para realizar trabajos en altura (Test de Acrofobia)", id: 2 },
      { label: "No evidencia miedo o temor para realizar trabajos en espacios confinados", id: 3 },
      { label: "No se evidencia indicadores de somnolencia y fatiga en el área laboral", id: 4 },
      { label: "Nivel bajo de estrés laboral según OIT-OMS", id: 5 },
      { label: "Nivel bajo de fobia específica", id: 6 },
      { label: "No se evidencia signos de demencia. (Test Minimental)", id: 7 },
   ];
   
   const Multiselect = (e) => {
      
      if (!textoIngresado) { // Permitir la selección solo si no se ha ingresado texto
         setSelects(e);
         const Texto= e.map(objeto => `- ${objeto.label}.`).join('\n');

         setTextoSeleccion(Texto)
         setObservacion(Texto);
      }
   }

   const IngresoTexto = (e) => {
      setObservacion(e);
      setTextoIngresado(true);
   }

   const IngresoAudio = (e) => {
      setObservacion(e);
      setTextoIngresado(true);
   }

      useEffect(() => {
         setHistoria(datOcupContext?.historia_familiar ?? null);
         setAccidente(datOcupContext?.accidentes_enfermedades ?? null);
         setHabito(datOcupContext?.habitos ?? null);
         setObservacion(datOcupContext?.observaciones ?? null);
      }, [datOcupContext]);

      useEffect(() => {
         if (datOcupContext.historia_familiar !== historia ||
            datOcupContext.accidentes_enfermedades !== accidente ||
            datOcupContext.habitos !== habito) {
               setDatOcupContext(prevState => ({
                  ...prevState,
                  historia_familiar: historia,
                  accidentes_enfermedades: accidente,
                  habitos: habito
               }));
         }
         if(datOcupContext.observaciones !== observacion){
            handleP(observacion)
         }
      }, [historia, accidente, habito, observacion ]);

      useEffect(() => {
         if (textareaRef.current) {
            const textarea = textareaRef.current;
            textarea.style.height = 'auto'; // Restaurar la altura predeterminada
            textarea.style.height = `${textarea.scrollHeight}px`; // Establecer la altura en función del contenido
         }
      }, [observacion, datOcupContext?.observaciones]);


      useEffect(() => {
         if(textoSeleccion !== observacion && textoIngresado && selects && selects.length !== 0) {
            //Evaluar si las opciones marcadas se encuentran dentro del texto
            const seleccionados = selects.map(option => `- ${option.label}.`);
            const faltantes = seleccionados.filter(label => !observacion.includes(label));
      
            // Si hay etiquetas seleccionadas que no están en el texto de observación, eliminarlas
            if (faltantes.length > 0) {
               const faltantesNuevos = faltantes.map(label => label.slice(2, -1));
               const selectsNuevos = selects.filter(option => !faltantesNuevos.includes(option.label));
               setSelects(selectsNuevos);
            }
         }

         if(selects == null && observacion){
            // Obtener las opciones que estan en el texto pero que no están seleccionadas actualmente
            const opciones = pruebas_options.map(objeto => `- ${objeto.label}.`)
            const encontrados = opciones.filter(label => observacion.includes(label));

            // Agregar las opciones no seleccionadas a selects
            if (encontrados.length > 0) {
               const encontradosNuevos = encontrados.map(label => label.slice(2, -1));
               const objetosCoincidentes = pruebas_options.filter(option => encontradosNuevos.includes(option.label));
               setSelects(objetosCoincidentes);
               setTextoIngresado(true);
               //const Texto = objetosCoincidentes.map(objeto => `- ${objeto.label}.`).join('\n');
               //setTextoSeleccion(Texto)
            }
         }

         if(textoSeleccion == observacion && textoIngresado) { setTextoIngresado(false);}
         if(!observacion) { setTextoIngresado(false);}

      }, [observacion, textoSeleccion])



   return (
      <>
         <h3 className="text-center mb-5">Datos Ocupacionales</h3>
         <div className="flex flex-wrap w-12 gap-5">
            <div className="flex flex-wrap w-12 gap-4">
               <div className="select-responsive">
                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                     option={motivos_options}
                     setvalue={setMotivo}
                     setParameter={handleM}
                     value={datOcupContext?.motivo_evaluacion}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                     title='Motivo de Evaluación'
                  />
               </div>
               <div className="select-responsive">
                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                     option={riesgos_options}
                     setvalue={setPrincipales}
                     setParameter={handleR}
                     value={datOcupContext?.principales_riesgos}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                     title='Principales riesgos'
                  />
               </div>
               
            </div>
            <div className="flex flex-wrap w-12 gap-4">
            
               <div className="flex flex-wrap flex-1 gap-2">
                  <label className="w-12 l-do">Medidas de seguridad</label>
                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     option={medidas_options}
                     setvalue={setMedidas}
                     setParameter={handleMe}
                     value={datOcupContext?.medidas_seguridad}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                  />
               </div>

               <div className="flex w-full relative gap-1">
                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                     option={historias_options}
                     setvalue={setHistoria}
                     title='Historia familiar'
                     setParameter={handleH}
                     value={historia}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                     maxLength={5000}
                  />
                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button'} setInput01={setHistoria}/>
               </div>

               <div className="flex w-full relative gap-1">
                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                     option={accidentes_options}
                     setvalue={setAccidente}
                     title='Accidentes y enfermedades'
                     setParameter={handleA}
                     value={accidente}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                     maxLength={5000}
                  />
                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button'} setInput01={setAccidente}/>
               </div>

               <div className="flex w-full relative gap-1">
                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                     option={habitos_options}
                     setvalue={setHabito}
                     title='Habitos'
                     setParameter={handleHa}
                     value={habito}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                     maxLength={5000}
                  />
                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button'} setInput01={setHabito}/>
                  {/*                   <label htmlFor="motivo_evaluacion" className="w-12 l-do">Habitos</label>
                  <Dropdown
                     className="w-12"
                     value={datOcupContext?.habitos}
                     options={habitos_options}
                     onChange={handleHa}
                     placeholder="Selecciona un motivo"
                     id={habitoId}
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  /> */}
               </div>
               <div className="flex w-full relative gap-1">
                  <div className="flex flex-column w-full">
                     <label style={{color: '#4C4CC9', fontWeight: '600'}} className={'w-full'} >Otras Observaciones</label>
                     <MultiSelect 
                        value={selects} 
                        onChange={(e) => {Multiselect(e.target.value); }}
                        optionLabel="label"
                        options={pruebas_options} 
                        placeholder="Selecciona" 
                        className="w-full mb-2" 
                        maxSelectedLabels={0}
                        disabled={stateAtencion[pageViewContext].estado === 3}
                     />
                     <InputTextarea
                     autoResize
                     value={observacion /*datOcupContext?.observaciones*/}
                     onChange={(e) => {IngresoTexto(e.target.value);}}
                     placeholder={"Observación"}
                     style={{ height: '131px' }}
                     ref={textareaRef}
                     maxLength={5000}
                     className="w-full"
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />

                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button-2'} setInput01={IngresoAudio} />
                  

                  </div>
               </div>

               <div className="flex w-full relative gap-1">
                  
                  {/* 
                  <VoiceButton
                     value={observacion /*datOcupContext?.observaciones}
                     onChange={setObservacion}
                     placeholder="Observaciones"
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />

                  <SelectFlechita
                     contentClassName='flex flex-column w-full'
                     className={'w-full'}
                     titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                     option={pruebas_options}
                     setvalue={setObservacion}
                     title='Otras observaciones'
                     setParameter={handleP}
                     value={datOcupContext?.observaciones}
                     id
                     disabled={stateAtencion[pageViewContext].estado === 3}
                     filtered={false}
                     maxLength={5000}
                  />  */}
               </div>
            </div>
            {/* <div className="flex flex-wrap w-12 gap-2">
               <label htmlFor="motivo_evaluacion" className="flex flex-wrap w-12 l-do">Otras observaciones</label>
               <Dropdown
                  className="w-12"
                  value={datOcupContext?.otras_observaciones}
                  options={pruebas_options}
                  onChange={handleP}
                  placeholder="Selecciona un motivo"
                  disabled={stateAtencion[pageViewContext].estado === 3}
                  editable
               />
            </div> */}
         </div>
      </>
   );
};

export default PagePsiDatOcup;

//REACT
import React, { useContext, useState, useRef } from "react";

//PRIMEREACT
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
//OTHERS
import { SalesContext } from "../../SalesContext/SalesContext";
import FileInputButton from "../../../Ventas/FileInputButton/FileInputButton";
import InputCalendar from '../../../form/InputCalendar';
import axios from "axios";
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';

import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";

export default function ContactoCreate({ crud, convertDate, ResetContacts, visibleCreate }) {
   // Estados para almacenar los datos del formulario y la respuesta de la API
   const [numDocumento, setNumDocumento] = useState('');

   // Función para manejar el evento de clic en el botón "Consultar"
   const handleSubmit = () => {
      // Realiza la solicitud GET a la API con el número de documento ingresado
      axios
         .get(`https://dniruc.apisperu.com/api/v1/dni/${numDocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)
         .then((res) => {
            // Actualiza los estados con los datos obtenidos de la API
            setSales(prevState => ({
               ...prevState,
               GCContacto: {
                  ...prevState.GCContacto,
                  numero_documento: numDocumento,
                  nombres: res.data.nombres,
                  apellido_paterno: res.data.apellidoPaterno,
                  apellido_materno: res.data.apellidoMaterno,
               }
            }));

         })
         .catch((error) => {
            // Manejo de errores: muestra un mensaje de error y limpia los campos
            console.error(error);

         });
      InicilizarFaltas();
   };

   const InicilizarFaltas = () => {
      setVerificarNombre(true);
      setVerificarApellidoP(true);
      setVerificarApellidoM(true);
      setVerificarNumdoc(true);
   }

   //#region VARIABLES DE INSTANCIA
   const { sales, setSales } = useContext(SalesContext);
   //#endregion

   //#region VARIABLES
   const [visible, setVisible] = useState(false);
   const [selectedImage, setSelectedImage] = useState('https://i.ibb.co/4SHrqm4/user-male.png');
   //#endregion
   const toast = useRef(null);
   const showToast = (type, title, detail) => {
      if (toast.current) {
         toast.current.show({
            severity: type,
            summary: title,
            detail: detail,
            life: 3000
         });
      } else {
         console.error("Toast no inicializado");
      }
   };

   //#region FUNCIONES
   const onchangeInputs = (e) => {
      const { name, value } = e.target;
      setSales(prevState => ({
         ...prevState,
         GCContacto: {
            ...prevState.GCContacto,
            [name]: value
         }
      }));
   };
   const onchangeFechaNacimiento = (e) => {
      if (e?.value) {
         let fecha = e?.value;
         const anio = fecha.getFullYear();
         const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
         const dia = fecha.getDate().toString().padStart(2, '0');
         const fechaFormateada = `${anio}-${mes}-${dia}`;


         setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, fecha_nacimiento: fechaFormateada } }));

      }

   };
   const handleFileChange = (selectedFiles) => {
      if (selectedFiles.length > 0) {
         const reader = new FileReader();
         reader.onload = () => {
            setSelectedImage(reader.result);
         };
         reader.readAsDataURL(selectedFiles[0]);
      } else {
         setSelectedImage(null);
      }
   }
   const onchangeFile = (file) => {
      setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, foto: file } }));
   }
   //#endregion

   //#region FUNCIÓN DE ENVÍO DE DATOS
   const dispatch = useDispatch();

   const sendCreate = async (e) => {
      // dispatch(startLoading())
      e.preventDefault();
      var ver = VerificarExistencias();
      if (ver) {
         const resp = await crud("crear");
         if (resp) {
            setVisible(false);
            ResetContacts();
            setSelectedImage('https://i.ibb.co/4SHrqm4/user-male.png');
            // dispatch(endLoading());
         }
      } else {
         showToast(
            "error",
            "Aviso",
            `Llenar todos los campos`
         );
      }
      dispatch(endLoading());
   };

   //#endregion

   const headerTemplate = () => {
      return (
         <div className="flex justify-content-start gap-2 align-items-center">
            <div className="flex align-items-center justify-content-center" style={{ width: '45px', height: '45px', borderRadius: '10px', backgroundColor: '#EBEBFF' }}>
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 29 35" fill="none">
                  <path d="M20.75 20.75V27M20.75 27V33.25M20.75 27H27M20.75 27L14.5 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
                  <path d="M14.5 17.625C18.8147 17.625 22.3125 14.1272 22.3125 9.8125C22.3125 5.49778 18.8147 2 14.5 2C10.1853 2 6.6875 5.49778 6.6875 9.8125C6.6875 14.1272 10.1853 17.625 14.5 17.625ZM14.5 17.625C7.59644 17.625 2 21.8223 2 27" stroke="#5555D8" stroke-width="3.125" stroke-linecap="round" />
               </svg>
            </div>
            <p className="text-indigo-600 text-lg font-bold" style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '18px' }}>
               Crear Nuevo Contacto
            </p>
         </div>
      );
   };
   const footerTemplate = () => {
      return (
         <div className="flex justify-content-end gap-2 mt-5">
            <Button
               onClick={() => {
                  setVisible(false);
                  ResetContacts();
                  InicilizarFaltas();
               }}
               type='button'
               label="Cancelar"
               style={{ width: '200px' }}
               className="font-normal bg-white border-gray-500 text-sm p-2 text-gray-800"
            />
         </div>
      )
   }

   const Ops = [
      { name: 'DNI', code: 1 },
      { name: 'Carnet de Extranjeria', code: 3 },
      { name: 'Pasaporte', code: 4 }
   ];

   const [VerificarNumdoc, setVerificarNumdoc] = useState(true);
   const [VerificarNombre, setVerificarNombre] = useState(true);
   const [VerificarApellidoP, setVerificarApellidoP] = useState(true);
   const [VerificarApellidoM, setVerificarApellidoM] = useState(true);

   const VerificarExistencias = () => {
      var VerificacionGeneral = true;
      if (!sales.GCContacto.numero_documento) {
         VerificacionGeneral = false;
         setVerificarNumdoc(false);
      }
      if (!sales.GCContacto.nombres) {
         VerificacionGeneral = false;
         setVerificarNombre(false);
      }
      if (!sales.GCContacto.apellido_paterno) {
         VerificacionGeneral = false;
         setVerificarApellidoP(false);
      }
      if (!sales.GCContacto.apellido_materno) {
         VerificacionGeneral = false;
         setVerificarApellidoM(false);
      }
      return VerificacionGeneral;
   }


   return (
      <>
         <Toast ref={toast} />
         <button
            onClick={() => {
               setVisible(true);
               ResetContacts();
               setSelectedImage('https://i.ibb.co/4SHrqm4/user-male.png');
            }}
            style={{ height: "auto", width: "320px" }}
            //Effect_Shine
            className={`cursor-pointer border-2 border-dashed border-round-lg flex flex-column bg-white p-3 gap-2 border-round-lg align-items-center justify-content-center border-indigo-500 ${visible ? `ventas_create_active` : `ventas_create`}`}>
            <i className="pi pi-plus-circle text-indigo-600 font-bold text-xl"></i>
            <p className="font-bold text-indigo-600 text-base">
               Agregar Nuevo Contacto
            </p>
         </button>
         <style>
            {`
               .FaltaInput{
                  transition: .5s;
               }
               .FaltaInput.selectFaltaInput{
                  box-shadow:
                     0 0 0 1px white,
                     0 0 0 2px red;
               }
               .FaltaInputText{
                  color: red;
                  position: absolute;
                  bottom: 4px;
                  left: 10px;
                  opacity: 0;
                  transition: .4s;
               }
               .FaltaInputText.selecttFaltaInput{
                  opacity: 1;
                  bottom: 2px;
               }
            `}
         </style>

         <Dialog
            header={headerTemplate}
            visible={visible}
            style={{ width: '765px', height: 'auto' }}
            onHide={() => { setVisible(false); InicilizarFaltas() }}
            className="empresas"
            footer={footerTemplate}
         >

            <form onSubmit={sendCreate}
               className="text-sm flex flex-column gap-3 w-full mt-3">
               <div className="flex flex-wrap gap-3 ventas">
                  <div className="flex-1 flex flex-column gap-2">
                     <div className="flex flex-column gap-2 w-100">
                        <label className="font-bold" htmlFor="nombres">Tipos Documento</label>
                        <Dropdown
                           value={Ops[Ops.findIndex(ele => ele.code === sales?.GCContacto?.tipo_documento_id)]}
                           onChange={(e) => {
                              setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, "tipo_documento_id": e.value.code } }));
                           }}
                           options={Ops}
                           optionLabel="name"
                           placeholder="Tipo de Documento"
                           className="w-full"
                           name="tipo_documento_id"
                        />
                     </div>



                     <div className="flex flex-column gap-2 w-full relative">
                        <label className="font-bold" htmlFor="numero_documento">Número de Documento</label>
                        <div className="flex">

                           <InputText
                              value={sales?.GCContacto?.numero_documento}
                              onChange={(e) => {
                                 setSales(prevState => ({ ...prevState, GCContacto: { ...prevState.GCContacto, "numero_documento": e.target.value } })); setNumDocumento(e.target.value);
                                 setVerificarNumdoc(true);
                              }}
                              name="numero_documento"
                              id="numero_documento"
                              placeholder="000000000"
                              className={`w-full FaltaInput ${!VerificarNumdoc ? 'selectFaltaInput' : ''}`}

                           />
                           <div style={{ marginLeft: '10px' }}>
                              <Button
                                 label="Validar"
                                 style={{ height: '100%', width: '100px', background: "green", borderColor: 'green' }}
                                 onClick={handleSubmit}
                                 type="button"
                                 disabled={sales?.GCContacto?.tipo_documento_id !== 1 || !sales?.GCContacto?.numero_documento || (sales?.GCContacto?.numero_documento && sales?.GCContacto?.numero_documento.length !== 8)} />

                           </div>
                        </div>
                        <div style={{ height: `${!VerificarNumdoc ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarNumdoc ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su número de documento
                        </span>
                     </div>

                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="nombres">Nombres</label>
                        <InputText
                           value={sales.GCContacto.nombres}
                           onChange={(e) => { onchangeInputs(e); setVerificarNombre(true) }}
                           name="nombres"
                           id="nombres"
                           placeholder='Escribir nombre completo...'
                           className={`p-inputtext-sm FaltaInput ${!VerificarNombre ? 'selectFaltaInput' : ''}`}
                           //disabled={numDocumento_disa}
                           style={{ userSelect: 'none' }}
                        />
                        <div style={{ height: `${!VerificarNombre ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarNombre ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su nombre
                        </span>
                     </div>

                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="apellido_paterno">Apellido Paterno</label>
                        <InputText
                           value={sales.GCContacto.apellido_paterno}
                           onChange={(e) => { onchangeInputs(e); setVerificarApellidoP(true) }}
                           name="apellido_paterno"
                           id="apellido_paterno"
                           placeholder='Escribir Apellido paterno...'
                           className={`p-inputtext-sm FaltaInput ${!VerificarApellidoP ? 'selectFaltaInput' : ''}`}
                           //disabled={numDocumento_disa}
                           style={{ userSelect: 'none' }}
                        />
                        <div style={{ height: `${!VerificarApellidoP ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarApellidoP ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su apellido paterno
                        </span>
                     </div>
                     <div className="flex flex-column gap-2 relative">
                        <label className="font-bold" htmlFor="apellido_materno">Apellido Materno</label>
                        <InputText
                           value={sales.GCContacto.apellido_materno}
                           onChange={(e) => { onchangeInputs(e); setVerificarApellidoM(true) }}
                           placeholder='Escribir Apellido materno...'
                           name="apellido_materno"
                           id="apellido_materno"
                           className={`p-inputtext-sm FaltaInput ${!VerificarApellidoM ? 'selectFaltaInput' : ''}`}
                           //disabled={numDocumento_disa}
                           style={{ userSelect: 'none' }}
                        />
                        <div style={{ height: `${!VerificarApellidoM ? '15px' : '0px'}`, transition: '.5s' }}>
                        </div>
                        <span className={`FaltaInputText ${!VerificarApellidoM ? 'selecttFaltaInput' : ''}`}>
                           Ingrese su apellido materno
                        </span>
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="cargo">Cargo</label>
                        <InputText
                           value={sales.GCContacto.cargo}
                           onChange={(e) => onchangeInputs(e)}
                           name="cargo"
                           id="cargo"
                           placeholder="Escribir cargo..."
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="celular">Celular</label>
                        <InputNumber
                           value={sales.GCContacto.celular}
                           onValueChange={(e) => onchangeInputs(e)}
                           useGrouping={false}
                           name="celular"
                           id="celular"
                           placeholder="Ingrese su celular..."
                           className="p-inputtext-sm"
                        />
                     </div>

                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="correo">Correo</label>
                        <InputText
                           value={sales.GCContacto.correo}
                           onChange={(e) => onchangeInputs(e)}
                           type="email"
                           name="correo"
                           id="correo"
                           placeholder="Ingrese su correo..."
                           className="p-inputtext-sm"
                        />
                     </div>
                  </div>


                  <div className="flex-1 flex flex-column gap-2 justify-content-end">
                     <label className="font-bold" htmlFor="foto" >Foto</label>
                     <div className="flex justify-content-center flex-1">
                        <span className="w-auto flex align-items-center justify-content-center bg-indigo-200 p-2 border-circle m-auto">
                           {selectedImage && (
                              <img
                                 className="border-circle"
                                 style={{ objectFit: "cover", width: '231px', aspectRatio: '1/1', height: '231px' }}
                                 src={selectedImage} alt="user-male"
                                 draggable={false}
                              />
                           )}
                        </span>
                     </div>

                     <div className="flex align-items-center justify-content-center ">
                        <FileInputButton
                           onChange={(e) => handleFileChange(e)}
                           file={(e) => onchangeFile(e)}
                           accept="image/*"
                           className="bg-green-200 border-green-200 text-sm font-normal text-green-600"
                           type="button"
                           label="Cambiar imagen"
                           icon="pi pi-camera text-green-600"
                           iconPos="right"
                           inputId="foto"
                        />
                     </div>

                     <label className="flex align-items-center gap-2 font-bold " htmlFor="comentarios">
                        <p>Comentarios</p>
                        <i className="pi pi-pencil text-sm" />
                     </label>
                     <InputTextarea
                        value={sales.GCContacto.comentario}
                        onChange={(e) => onchangeInputs(e)}
                        className="ventas-scroll"
                        id="comentarios"
                        name="comentarios"
                        style={{ resize: 'none', height: "7.6rem" }}
                        placeholder="Ingresa un comentario"
                        maxLength={100}
                     />
                     <div className="flex flex-column gap-2 ">
                        <label className="font-bold" htmlFor="fecha_nacimiento">Cumpleaños</label>
                        <InputCalendar
                           value={convertDate(sales.GCContacto.fecha_nacimiento)}
                           onChange={(e) => onchangeFechaNacimiento(e)}
                           name="fecha_nacimiento"
                           id="fecha_nacimiento"
                           inputId="fecha_nacimiento"
                           showIcon
                           showButtonBar
                           placeholder="dd/mm/yy" dateFormat="dd/mm/yy"
                           className="p-inputtext-sm ventas text-sm"
                        />
                     </div>
                     <div className="flex flex-column gap-2">
                        <label className="font-bold" htmlFor="correo">Hobbies</label>
                        <InputText
                           value={sales.GCContacto.hobbies}
                           onChange={(e) => onchangeInputs(e)}
                           name="hobbies"
                           id="hobbies"
                           placeholder="leer,etc"
                           className="p-inputtext-sm"
                        />
                     </div>
                  </div>
               </div>
               <Button
                  type="submit"
                  className="flex-1 text-sm p-2 absolute"
                  style={{ bottom: '24px', right: '240px', width: '200px' }}
                  label="Agregar"
               />
            </form>
         </Dialog>
      </>
   );
}
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { Tree } from 'primereact/tree';
import { Dialog } from 'primereact/dialog';

//Imágenes
import Hospital2 from "../../../../Images/Hospital2 1.png";
import Bienes from "../../../../Images/IconB.png";

//Componente CardLocal
export default function CardLocal({ local, localitem, setLocal, setVisibleDelete, visibleDelete, visible1, setVisible1, provincia }) {
  const [visible, setVisible] = useState(false);

  //Manejo de la edición de un local
  const handleChangeEditar = (e) => {
    setLocal({
      local,
      id: localitem.id,
      nombre: localitem.nombre,
      direccion: localitem.direccion,
      latitud: localitem.latitud,
      longitud: localitem.longitud,
      departamento_id: localitem.departamento_id,
      departamento: localitem.departamento,
      distrito_id: localitem.distrito_id,
      distrito: localitem.distrito,
      provincia_id: localitem.provincia_id,
      provincia: localitem.provincia,
    })
    setVisible1({ ...visible1, status: true, nombre: 'Editar local' })
  };

  // -----BIENESOFERTADOS-----
  //Servicio de obtención de datos para treetable
  const NodeService = {
    getTreeNodesData() {
      return [
        {
          key: '0',
          label: 'Documents',
          data: 'Documents Folder',
          icon: 'pi pi-fw pi-inbox',
          children: [
            {
              key: '0-0',
              label: 'Work',
              data: 'Work Folder',
              icon: 'pi pi-fw pi-cog',
              children: [
                {
                  key: '0-0-0',
                  label: 'Expenses.doc',
                  icon: 'pi pi-fw pi-file',
                  data: 'Expenses Document',
                },
                {
                  key: '0-0-1',
                  label: 'Resume.doc',
                  icon: 'pi pi-fw pi-file',
                  data: 'Resume Document',
                },
              ],
            },
            {
              key: '0-1',
              label: 'Home',
              data: 'Home Folder',
              icon: 'pi pi-fw pi-home',
              children: [
                {
                  key: '0-1-0',
                  label: 'Invoices.txt',
                  icon: 'pi pi-fw pi-file',
                  data: 'Invoices for this month',
                },
              ],
            },
          ],
        },
        {
          key: '1',
          label: 'Events',
          data: 'Events Folder',
          icon: 'pi pi-fw pi-calendar',
          children: [
            {
              key: '1-0',
              label: 'Meeting',
              icon: 'pi pi-fw pi-calendar-plus',
              data: 'Meeting',
            },
            {
              key: '1-1',
              label: 'Product Launch',
              icon: 'pi pi-fw pi-calendar-plus',
              data: 'Product Launch',
            },
            {
              key: '1-2',
              label: 'Report Review',
              icon: 'pi pi-fw pi-calendar-plus',
              data: 'Report Review',
            },
          ],
        },
        {
          key: '2',
          label: 'Movies',
          data: 'Movies Folder',
          icon: 'pi pi-fw pi-star-fill',
          children: [
            {
              key: '2-0',
              icon: 'pi pi-fw pi-star-fill',
              label: 'Al Pacino',
              data: 'Pacino Movies',
              children: [
                {
                  key: '2-0-0',
                  label: 'Scarface',
                  icon: 'pi pi-fw pi-video',
                  data: 'Scarface Movie',
                },
                {
                  key: '2-0-1',
                  label: 'Serpico',
                  icon: 'pi pi-fw pi-video',
                  data: 'Serpico Movie',
                },
              ],
            },
            {
              key: '2-1',
              label: 'Robert De Niro',
              icon: 'pi pi-fw pi-star-fill',
              data: 'De Niro Movies',
              children: [
                {
                  key: '2-1-0',
                  label: 'Goodfellas',
                  icon: 'pi pi-fw pi-video',
                  data: 'Goodfellas Movie',
                },
                {
                  key: '2-1-1',
                  label: 'Untouchables',
                  icon: 'pi pi-fw pi-video',
                  data: 'Untouchables Movie',
                },
              ],
            },
          ],
        },
      ];
    },

    getTreeNodes() {
      return Promise.resolve(this.getTreeNodesData());
    },
  };

  //Estados para nodos del árbol y selección de los mismos
  const [nodes, setNodes] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState(null);

  //Estado para cargar nodos al montar el componente
  useEffect(() => {
    NodeService.getTreeNodes().then((data) => setNodes(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Header del dialogo para bienesofertados
  const header = (
    <div className="icon-bienes" style={{ marginTop: "2rem", marginLeft: "3rem" }}>
      <img src={Bienes} alt="icon_map " className="w-7" style={{ marginTop: "0.5rem", marginLeft: "0.8rem" }} />
    </div>
  )

  /*Parte de los estilos resposivos en registro.css*/
  // Estado para mantener el ancho de la ventana
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Definir la constante con la condición
  const styleObject = screenWidth < 560 ? "" : "Gestionar Área";

  // Función para actualizar el estado del ancho de la ventana cuando cambia
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  // Suscribirse al evento de cambio de tamaño de la ventana
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div className=" bg-white border-round-xl pl-4 pr-4 pt-4   flex flex-column gap-1 shadow-1" style={{ width: "380px", height: "361px" }}>
      <div className="flex w-full justify-content-end px-1 ">
        <div className="flex absolute align-items-center bg-teal-100 gap-2  px-2 border-round-2xl -mt-2">
          <p className="text-cyan-300 text-sm font-medium"><i className="pi pi-circle-fill text-xs pr-1" />Habilitado </p>
        </div>
      </div>

      <div className="flex gap-3 flex-column pt-2">
        <div className="flex gap-3 px-3">
          <img src={Hospital2} alt="" className="border-circle bg-indigo-100" style={{ padding: "0.45rem" }} />
          <div className=" flex  flex-column my-1">
            <p className="local ">{localitem?.nombre}</p>
            <p className="eucap">{localitem?.direccion}</p>
          </div>
        </div>

        <div className="flex flex-column  px-5 gap-2 " style={{ minHeight: "8rem" }}>
          <div className="flex justify-content-between">
            <p>Departamento</p> <p className="valueCard">{localitem?.departamento?.departamento}</p>
          </div>
          <div className="flex justify-content-between">
            <p>Provincia</p> <p>{provincia}</p></div>
          <div className="flex justify-content-between">
            <p>Distrito</p> <p className="valueCard">{localitem?.distrito?.distrito}</p></div>
        </div>

        <div style={{ width: "90%", marginLeft: "5%" }}
          className="flex px-1 border-200 border-1">
        </div>

        <div className="flex flex-row justify-content-between   px-3  ">
          <div className="flex flex-row gap-1">
            <Button style={{ height: "2.8rem", width: '2.8rem' }}
              className="eliminar"
              icon="pi pi-trash text-2xl"
              severity="help"
              aria-label="Favorite"
              onClick={() => setVisibleDelete({ visibleDelete, status: true, id: localitem.id })}
            />
            <Button style={{ height: "2.8rem", width: '2.8rem' }}
              className="editar"
              icon="pi pi-pencil text-2xl"
              severity="help"
              aria-label="Favorite"
              onClick={() => handleChangeEditar(localitem)} />

            {/* {No se emplea de momento} */}
            <Dialog header={header} visible={visible} style={{ width: '30vw' }} onHide={() => setVisible(false)}>
              <div>
                <div className="pt-3 pl-4 ">
                  <h1 >Bienes ofertados</h1>
                  <p> En esta sección usted definirá que productos o servicios se ofertarán en este local.</p>
                  <br />
                  <Tree value={nodes}
                    filter filterMode="lenient"
                    filterPlaceholder="Todos"
                    selectionMode="checkbox"
                    selectionKeys={selectedKeys} onSelectionChange={(e) => setSelectedKeys(e.value)}
                    className="" />
                </div>
                <div className="pt-4 flex justify-content-center gap-3">
                  <Button className="w-4 bg-white text-700" label="Cancelar" />
                  <Button className="w-4" label="Crear" />

                </div>
              </div>
            </Dialog>
            {/*{No se emplea}*/}

          </div>
          <div className="flex" >
            {/* Referenciar ruta para el área de cada local */}
            <Link to={{
              pathname: `/recursoshumanos/local/${localitem.id}/areas`,
              state: { local: localitem }
            }}
              className="no-underline">
              <Button style={{ height: "2.8rem" }} className="crearareas align-items-center font-semibold text-base" icon="pi pi-plus pr-2 text-xl " severity="help" aria-label="Favorite">
                {styleObject}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
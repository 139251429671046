import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";

//Componentes generales
import { startLoading, endLoading, } from "../../../../Redux/isLoadingSlice";
import AuthUser from "../../../../AuthUser";
import TablaCrud from "../../../../Components/Table/TablaCrud";

//Componentes únicos
import { ModalCreatePuesto, ModalEditCargo, ModalEliminarCargo, ModalPerfilEMO } from '../../../../Components/RecursosHumanos/PuestosTrabajo/index';

//Estilos generales
import "../../../../Style/personal.css";
import "../../../../Style/pageReclutamientoCorp.css";

//Estilos únicos
import "../../../../Style/PotencialRiesgo.css";
import "../../../../Style/PagePuestodeTrabajo.css";


function PagePuestoTrabajo() {
  const dispatch = useDispatch();
  const { http } = AuthUser();
  const toast = useRef(null);

  const [globalFilter, setGlobalFilter] = useState(null);
  const [cargoEliminar, setCargoEliminar] = useState([]);
  const [visibleEditCargo, setVisibleEditCargo] = useState(false);
  const [visibleCreatePuesto, setVisibleCreatePuesto] = useState(false);
  const [visibleEliminarCargo, setVisibleEliminarCargo] = useState(false);
  const [visiblePerfilEmo, setVisiblePerfilEmo] = useState(false);
  const [cargos, setCargos] = useState([])
  const [tipoRiesgos, setTipoRiesgos] = useState([])
  const [paquetes, setPaquetes] = useState([]);
  const [selectCargos, setSelectCargos] = useState({
    name: "",
    tipo_riesgo_id: "",
  });

  //Función notificaciones
  const showToast = (tipo, titulo, detalle) => {
    toast.current.show({
      severity: tipo,
      summary: titulo,
      detail: detalle,
      life: 3000,
    });
  };

  //Integraciones get
  const getCargos = async () => {
    try {
      const response = await http.get("empresa/cargo/get")
      if (response?.data?.data) {
        setCargos(response?.data?.data);
      }
    } catch (error) {
      console.error('Erro al obtener cargos, error')
      showToast('error', 'Error al obtener cargos', "Contáctese con soporte técnico")
    };
  }

  const getTipoRiesgos = async () => {
    try {
      const response = await http.get("tipo/riesgo/get")
      if (response?.data?.data) {
        setTipoRiesgos(response?.data?.data);
      }
    } catch (error) {
      console.error('Error al obtener tipos de riesgo', error)
      showToast("error", "Error al obtener tipos de riesgo", "Contáctese con Soporte Técnico.")
    };
  };

  const getPaquetes = async () => {
    try {
      const response = await http.get("empresa/cargo/get_paquete")
      if (response?.data?.data) {
        setPaquetes(response?.data?.data);
      }
    }
    catch (error) {
      console.error('Error al obtener paquetes', error)
      showToast("error", 'Error al obtener paquetes', "Contáctese con soporte técnico")
    };
  };

  //Funcionalidades
  const handleItem = (e, rowData) => {
    //Número de item
    return <div>{rowData.rowIndex + 1}</div>
  }

  const perfilEMO = (rowData) => {
    //Valida si existe un perfil, determinando el label
    const buttonText = rowData?.con_perfil ? 'Ver perfil' : 'Elegir perfil';
    return (
      <div className="boton_edicion_j">
        <React.Fragment>
          <Button
            className={`${rowData?.con_perfil ? 'perfil_emo' : 'null-perfil perfil_emo2'}`}
            onClick={() => { setSelectCargos(rowData); setVisiblePerfilEmo(true) }}//Envía datos del cargo
          >
            {buttonText}
          </Button>
        </React.Fragment>
      </div>
    );
  };

  //Acciones CRUD
  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="editar1"
          onClick={() => editPaquete(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="eliminar1"
          onClick={() => deletePaquete(rowData)}
        />
      </React.Fragment>
    );
  };

  const editPaquete = (rowData) => {
    setSelectCargos(rowData);//Datos del cargo
    setVisibleEditCargo(true);
  };

  const deletePaquete = (rowData) => {
    setCargoEliminar(rowData);//Datos del cargo
    setVisibleEliminarCargo(true);
  };

  //Condicionales estilos del potencial de riesgo
  const actionRiesgo = (rowData) => {
    let porcentaje
    let colorgeneral = "";
    if (rowData.tipo_riesgo_id === 1) {
      colorgeneral = 'red';
      porcentaje = '100%';
    }
    else if (rowData.tipo_riesgo_id === 2) {
      colorgeneral = "rgb(221, 179, 129)";
      porcentaje = '50%';
    }
    else if (rowData.tipo_riesgo_id === 3) {
      colorgeneral = "green";
      porcentaje = '25%';
    }
    else {
      colorgeneral = "black";
      porcentaje = '0%';
    }
    return (
      <div className="contenedorgeneral_j">
        <p style={{ color: colorgeneral }} className="contenido_componente">{rowData.tipo_riesgo?.nombre}</p>
        <div className="contenedor_porcentaje_j">
          <div style={{ backgroundColor: colorgeneral, width: porcentaje }} className="grafico_porcentaje_j">
          </div>
        </div>
      </div>
    );
  };

  //Espera la carga de datos
  const fetchAllData = async () => {
    try {
      await Promise.all([
        getCargos(),
        getTipoRiesgos(),
        getPaquetes(),
      ]);
    } catch (error) {
      console.error('Error al cargar datos', error)
      showToast("error", 'Error al cargar datos', "Contáctese con soporte técnico")
    } finally {
      //Finaliza la carga de información
      dispatch(endLoading());
    }
  }

  //Monta el componente e inicia la carga de información
  useEffect(() => {
    dispatch(startLoading());
    fetchAllData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="flex flex-column mb-4">
        <h1 className="titulo">Puesto de Trabajo</h1>
        <p className="subtitulo">En este módulo usted podrá gestionar los puestos de trabajo de sus trabajadores</p>
      </div>
      <div className="contenedor-corporativo">
        <Toast ref={toast} />
        <TablaCrud
          name="Personal"
          tableNombre={`Lista de Personal`}
          descripcion={`A continuación, se visualiza la lista de personal.`}
          onInputSearch={(e) => setGlobalFilter(e.target.value)}
          valueGlobalFilter={globalFilter}
          value={cargos}
          leftLabelButton1={" Crear"}
          onClickLeftButton1={() => setVisibleCreatePuesto(true)}
        >
          <Column className="jtabla_titulo" body={handleItem} header="Item" style={{ borderLeft: "none" }}></Column>
          <Column className="jtabla_titulo" style={{ minWidth: "16vW" }} header="Puesto de Trabajo" field="nombre"></Column>
          <Column className="jtabla_titulo" header="Perfil Emo" body={perfilEMO}></Column>
          <Column className="jtabla_titulo" header="Potencial de riesgo" body={actionRiesgo}></Column>
          <Column className="jtabla_titulo j_padding" header="Acción" body={actionBodyTemplate} ></Column>
        </TablaCrud>
      </div>
      {/* Leyendas en la parte inferior */}
      <div className="leyendas" style={{ position: 'fixed', bottom: 0, left: 230, right: 0, padding: '10px', background: '#fff' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <span style={{ color: 'red', marginRight: '10px' }}>Riesgo Alto - 6 Meses </span>
            <span style={{ color: 'rgb(221, 179, 129)', marginRight: '10px' }}>Riesgo Medio - 8 Meses </span>
            <span style={{ color: 'green' }}>Riesgo Bajo - 1 Año </span>
          </div>
        </div>
      </div>

      {/* Modal crear cargos */}
      <ModalCreatePuesto
        visibleCreate={visibleCreatePuesto}
        setVisibleCreate={setVisibleCreatePuesto}
        showToast={showToast}
        tipoRiesgos={tipoRiesgos}
        getCargos={getCargos}
        api="empresa/cargo/create"
        dispatch={dispatch}
      />

      {/* Modal actualzar cargo */}
      <ModalEditCargo
        visibleEditCargo={visibleEditCargo}
        setVisibleEditCargo={setVisibleEditCargo}
        showToast={showToast}
        tipoRiesgos={tipoRiesgos}
        getCargos={getCargos}
        cargo={selectCargos}
        api="empresa/cargo/update"
        dispatch={dispatch}
      />

      {/* Modal eliminar */}
      <ModalEliminarCargo
        visibleEliminarCargo={visibleEliminarCargo}
        setVisibleEliminarCargo={setVisibleEliminarCargo}
        showToast={showToast}
        getCargos={getCargos}
        cargoEliminar={cargoEliminar}
        api="empresa/cargo/destroy"
        dispatch={dispatch}
      />

      {/* Modal PerfilEMO */}
      <ModalPerfilEMO
        paquetes={paquetes}
        visiblePerfilEmo={visiblePerfilEmo}
        setVisiblePerfilEmo={setVisiblePerfilEmo}
        showToast={showToast}
        cargo={selectCargos}
        header="Perfil EMO"
        getCargos={getCargos}
        dispatch={dispatch} />

      {/* Dialogs sin utilizar */}
      {/* <ModalReporte
        visibleReporte={visibleReporte}
        setVisibleReporte={setVisibleReporte}
        showToast={showToast}
      /> */}
    </div>

  );
}

export default PagePuestoTrabajo
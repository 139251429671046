import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import "../../../../Style/ANTECEDENTE.css";
import { TriajeContext } from "../../../Triaje/TriajeContext";
import DialogEliminarGeneral from "./DialogEliminarGeneral";
import EDITARAGREGARRIESGOS from "./DialogEditarAntLabRiesgos";
import { RAContext } from "../../../RutaAtencion/RAContext";

export function TablaRiesgo(props) {
   let { antLabRiesgoContext, setAntLabRiesgoContext } = useContext(TriajeContext);
   let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
   const [selectedAntecedente, setSelectedAntecedente] = useState(null);
   const [VisibleEliminarAntLabRiesgo, setVisibleEliminarAntLabRiesgo] =useState(false);
   const [visibleRiesgo, setVisibleRiesgo] = useState(false);
   const [DataId, setDataId] = useState(null);
   const [DatosEditarRiesgo, setDatosEditarRiesgo] = useState(null);

   const showToast = (severity, summary, detail) => {
      // Lógica para mostrar un mensaje de tostada
   };

   const EliminarAntLabRiesgos = (item) => {
      setVisibleEliminarAntLabRiesgo(true);
      setDataId(item);
   
      // Verificar si props.showToast es una función antes de llamarla
      if (typeof props.showToast === 'function') {
          props.showToast(
              "info",
              "Eliminando...",
              "Eliminando el riesgo de antecedente laboral"
          );
      } else {
          console.error("props.showToast is not a function");
      }
   };
   

   const EditarAntLabRiesgo = (rowData, item) => {
      setVisibleRiesgo(true);
      setDatosEditarRiesgo(rowData)
      setDataId(item);
   };
   const mostrarDatosActualizadosRiesgo = (datosActualizados) => {
      const PocisionEditarAntLabRiesgo = antLabRiesgoContext[DataId];
      if (PocisionEditarAntLabRiesgo) {
         PocisionEditarAntLabRiesgo.riesgo = datosActualizados?.Riesgo ?? null;
         PocisionEditarAntLabRiesgo.agente = datosActualizados?.Agente ?? null;
         PocisionEditarAntLabRiesgo.observacion = datosActualizados?.Observacion ?? null;
         PocisionEditarAntLabRiesgo.labores_expuestas = datosActualizados?.LaboresExpuestas ?? null;
         PocisionEditarAntLabRiesgo.fuentes_riesgo = datosActualizados?.FuentesRiesgo ?? null;
         PocisionEditarAntLabRiesgo.tipo_exposicion = datosActualizados?.TipoExposicion?.code ?? null;
         PocisionEditarAntLabRiesgo.equipo_proteccion_personal = datosActualizados?.EPP?.name ?? null;
         PocisionEditarAntLabRiesgo.hora = datosActualizados?.Hr ?? null;
         PocisionEditarAntLabRiesgo.dia = datosActualizados?.Dia ?? null;
         PocisionEditarAntLabRiesgo.detalle_epp = datosActualizados?.DetalleEPP ?? null;
         if(PocisionEditarAntLabRiesgo?.riesgo_agente){
            PocisionEditarAntLabRiesgo.riesgo_agente = datosActualizados?.Agente.map(item => ({ agente: item })) ?? [];
         }

         props.showToast(
            "success",
            "Editado correctamente",
            "Se editó el riesgo de antecedente laboral"
        )
         // else if(PocisionEditarAntLabRiesgo?.tri_riesgo_agente){
         //    PocisionEditarAntLabRiesgo.tri_riesgo_agente = datosActualizados?.Agente.map(item => ({ agente: item })) ?? [];
         // }
         

      }
   
      setAntLabRiesgoContext([...antLabRiesgoContext]);
      // setAntLabContext([...antLabRiesgoContext, {antecedentes_riesgo: antLabRiesgoContext}]);
      setVisibleRiesgo(false);
   };

   const actionTemplate = (rowData, indexData) => {
      return (
         <React.Fragment>
            <Button
                icon={stateAtencion[pageViewContext].estado === 3 ? "pi pi-eye" : "pi pi-pencil"}
               className="editar1"
               onClick={() => EditarAntLabRiesgo(rowData, indexData.rowIndex)}
            />
            <Button
               icon="pi pi-trash"
               className="eliminar1"
               onClick={() => EliminarAntLabRiesgos(indexData.rowIndex)}
               disabled={stateAtencion[pageViewContext].estado === 3}
            />
         </React.Fragment>
      );
   };

   const ListadoAgentes = (rowData) => {
      return rowData.riesgo_agente.map((elemento, index) => {
         return (
            <p key={index}>
               - {elemento.agente}
            </p>
         );
      });
   };

   const ObservacionTexto = (rowData) => {
      let texto = rowData.observacion
      return (
         <div style={{ width: "250px" }}>
            <p>{texto}</p>
         </div>
      )
   }

   const TipoExplosicion = (rowData) => {
      const cities = [
         { name: 'Esporádica', code: '0' },
         { name: 'Permanente', code: '1' },
         { name: 'Ninguno', code: '2' }
     ];
      
      for (let tipoExpo of cities){
         if (tipoExpo.code == rowData.tipo_exposicion){
            return tipoExpo.name
         }
      }

   }
   return (
      <div className="CONTENEDOR_TABLAANTECEDENTE">
         <DataTable
            value={antLabRiesgoContext}
            selectionMode="single"
            selection={selectedAntecedente}
            onSelectionChange={(e) => setSelectedAntecedente(e.value)}
            dataKey="id"
            rowHover
         >
            <Column field="riesgo" header="Riesgo" sortable />
            <Column field="agente" header="Agente" sortable />
            <Column field="observacion" header="Observación" sortable />
            <Column
               field="labores_expuestas"
               header="Lobores Expuestas"
               sortable
            />
            <Column
               field="fuentes_riesgo"
               header="Fuentes de Riesgo"
               sortable
            />
            <Column
               field= {TipoExplosicion}
               header="Tipo de Exposición"
               sortable
            />
            <Column field="equipo_proteccion_personal" header="EPP" sortable />
            <Column field="hora" header="Hora" sortable />
            <Column field="dia" header="Día" sortable />
            <Column field="detalle_epp" header="Detalle EPP" sortable />
            <Column
               header="Acción"
               body={actionTemplate}
               style={{ textAlign: "center", width: "6em" }}
            />
         </DataTable>

         <DialogEliminarGeneral
            setVisibleEliminar={setVisibleEliminarAntLabRiesgo}
            VisibleEliminar={VisibleEliminarAntLabRiesgo}
            ContextEliminar={antLabRiesgoContext}
            DataId={DataId}
            showToast={showToast}
            texto = "el riesgo de antecedente laboral"

         />
         <EDITARAGREGARRIESGOS
            visibleRiesgo={visibleRiesgo}
            setVisibleRiesgo={setVisibleRiesgo}
            mostrarDatosActualizadosRiesgo={mostrarDatosActualizadosRiesgo}
            DatosEditar={props.DatosEditar}
            DataId={DataId}
            DatosEditarRiesgo={DatosEditarRiesgo}
            showToast={showToast} // Asegúrate de pasar showToast como pro
            
         />
      </div>
   );
}

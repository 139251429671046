import React, { useState, useRef } from 'react';
import {Button} from 'primereact/button'

export default function FileInputButton({ inputId, accept, multiple = false, onChange, file, type="button", style, className, label, icon, iconPos }) {
    const fileInputRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);

    function handleButtonClick() {
        fileInputRef.current.click();
    }

    function handleFileChange(event) {
        const files = event.target.files;
        setSelectedFiles(Array.from(files));
        onChange(Array.from(files));
        file(event.target.files[0])
    }

    return (
        <>
            <input
                ref={fileInputRef}
                id={inputId}
                type="file"
                accept={accept}
                multiple={multiple}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <Button onClick={()=>handleButtonClick()} label={label} icon={icon} iconPos={iconPos} type={type} style={style} className={className}  />
        </>
    );
}
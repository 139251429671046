import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";

const { webkitSpeechRecognition } = window;

const VoiceRadiologia = ({ onChange, placeholder, value }) => {
   const [recognition, setRecognition] = useState(null);

   const startRecognition = () => {
      const recognition = new webkitSpeechRecognition();
      recognition.lang = window.navigator.language;
      recognition.continuous = true;
      recognition.interimResults = true;

      recognition.onresult = (event) => {
         let tempTranscript = "";

         for (let i = event.resultIndex; i < event.results.length; ++i) {
            tempTranscript += event.results[i][0].transcript;
         }

         onChange((value ? value + " " : "") + tempTranscript);
      };

      recognition.onend = () => {
         setRecognition(null);
      };

      recognition.start();
      setRecognition(recognition);
   };

   const stopRecognition = () => {
      if (recognition) {
         recognition.stop();
      }
   };

   return (
      <div className="flex flex-row w-full relative">
         <div className="flex flex-col w-full flex-1">
            <style>
               {`
                  .Inputkjbjgksjigpsaoi{
                     width: 100%;
                     height: 50px;
                  }
               `}
            </style>
            <InputTextarea
               className="Inputkjbjgksjigpsaoi"
               autoResize
               value={value}
               onChange={(e) => onChange(e.target.value)}
               placeholder={placeholder}
            />
         </div>
         <div
            className="flex justify-content-end absolute"
            style={{ bottom: '5px', right: '5px' }}
         >
            {recognition == null && (
               <Button
                  style={{ height: "25px", width: '40px', padding: "5px" }}
                  label={"On"}
                  onClick={startRecognition}
               />
            )}
            {recognition !== null && (
               <Button
                  style={{ height: "25px", width: '40px', padding: "5px" }}
                  label={"Off"}
                  onClick={stopRecognition}
               />
            )}

         </div>
      </div>
   );
};

export default VoiceRadiologia;

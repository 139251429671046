import React, { useState, useRef, useEffect, useContext } from "react";
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import InputCalendar from '../../Components/form/InputCalendar';
import "./style.css";
import AuthUser from "../../AuthUser";
import { RecepcionContext } from "./RecepcionTablaFiltro/Components/RecepcionProvider";
import { useFormik } from "formik";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";

const DatosPaciente = ({ isSaved, setIsSaved }) => {
    const { http } = AuthUser();
    const { camposInvalidos, datosGenerales, setDatosGenerales, emerjencia, setEmergencia, distritos, setDistritos, provincias, setProvincias, provinciasD, setProvinciasD, distritosD, setDistritosD } = useContext(RecepcionContext);
    const toast = useRef(null);
    const [sexos, setSexos] = useState([]);
    const [gradoInstruccion, setGradoInstruccion] = useState([]);
    const [estadoCivil, setEstadoCivil] = useState([]);
    const [religiones, setReligiones] = useState([]);
    const [tiposDocumentos, setTiposDocumentos] = useState([]);

    useEffect(() => {
        getsex();
        getGrado();
        getEstadoCivil();
        getReligiones();
        getAllUbicaciones();
        obtenerTiposDocumentos();
    }, []);

    const showToast = (tipo, titulo, detalle) => {
        toast.current.show({
            severity: tipo,
            summary: titulo,
            detail: detalle,
            life: 3000,
        });
    };

    const obtenerTiposDocumentos = () => {
        http
            .get("tipodocumentos/get")
            .then((response) => {
                if (response?.data?.data) {
                    setTiposDocumentos(response.data.data);
                } else if (response?.data?.error) {
                    showToast('error', 'Error', response.data.error)
                }
            })
            .catch((error) => {
                showToast('error', 'Error', 'Contáctese con soporte técnico.')
            });
    };

    const getsex = () => {
        http
            .get("sexos/get")
            .then((response) => {
                setSexos(response.data.data);
            })
            .catch((error) => {
                console.error("Error al obtener los sexos:", error);
            });
    };

    const getGrado = () => {
        http
            .get("gradoinstruccion/get")
            .then((response) => {
                if (response.data.data) {
                    setGradoInstruccion(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Error al obtener los datos de graduación:", error);
            });
    };

    const getEstadoCivil = () => {
        http
            .get("estadocivil/get")
            .then((response) => {
                if (response.data.data) {
                    setEstadoCivil(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Error al obtener los datos de estado civil:", error);
            });
    };

    const getReligiones = () => {
        http
            .get("religiones/get")
            .then((response) => {
                if (response.data.data) {
                    setReligiones(response.data.data);
                }
            })
            .catch((error) => {
                console.error("Error al obtener los datos de religiones:", error);
            });
    };

    //PARA LUGAR DE NACIMIENTO
    const [departamentos, setDepartamentos] = useState([]);
    const [distrito, setDistrito] = useState(null);
    const [provincia, setProvincia] = useState(null);
    const [departamento, setDepartamento] = useState(null);
    //PARA DOMICILIO
    const [distritoD, setDistritoD] = useState(null);
    const [provinciaD, setProvinciaD] = useState(null);
    const [departamentoD, setDepartamentoD] = useState(null);

    const getAllUbicaciones = () => {
        http
            .get("/ubicacion")
            .then((response) => {
                setDepartamentos(response.data.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    // Manejador para departamento
    const handleChangeDepartamento = (e) => {
        const departamentoSeleccionado = e.value;

        setDatosGenerales((prevDatosGenerales) => ({
            ...prevDatosGenerales,
            distrito_id: null,
            // dristrito: {},
        }));
        setEmergencia((prevEmergencia) => ({
            ...prevEmergencia,
            departamento_id: departamentoSeleccionado.id,
            departamento: departamentoSeleccionado,
            provincia_id: null,
            provincia: {},
        }));

        setProvincias(e.target.value === null ? null : e.target.value.provincias);
        setDistritos(null);
    };

    // Manejador para provincia
    const handleChangeProvincia = (e) => {
        const provinciaSeleccionada = e.target.value;

        setEmergencia((prevEmergencia) => ({
            ...prevEmergencia,
            provincia_id: provinciaSeleccionada.id,
            provincia: provinciaSeleccionada,
        }));

        setDistritos(e.target.value === null ? null : e.target.value.distritos);
    };

    // Manejador para distrito
    const handleChangeDistrito = (e) => {
        const distritoSeleccionado = e.target.value;

        setDatosGenerales((prevDatosGenerales) => ({
            ...prevDatosGenerales,
            distrito_id: distritoSeleccionado.id,
            dristrito: distritoSeleccionado,
        }));

        setDistrito(distritoSeleccionado);
    };

    const handleChangeDepartamentoDomicilio = (e) => {
        const departamentoSeleccionado = e.value;

        setDatosGenerales((prevDatosGenerales) => ({
            ...prevDatosGenerales,
            distrito_domicilio_id: null,
            distrito_domicilio: {},
        }));
        setEmergencia((prevEmergencia) => ({
            ...prevEmergencia,
            departamentoDom_id: departamentoSeleccionado.id,
            departamentoDom: departamentoSeleccionado,
            provinciaDom_id: null,
            provinciaDom: {},
        }));

        setProvinciasD(e.target.value === null ? null : e.target.value.provincias);
        setDistritosD(null);
    };

    const handleChangeProvinciaDomicilio = (e) => {
        const provinciaSeleccionada = e.target.value;

        setEmergencia((prevEmergencia) => ({
            ...prevEmergencia,
            provinciaDom_id: provinciaSeleccionada.id,
            provinciaDom: provinciaSeleccionada,
        }));

        setDistritosD(e.target.value === null ? null : e.target.value.distritos);
    };

    const handleChangeDistritoDomicilio = (e) => {
        const distritoSeleccionado = e.target.value;

        setDatosGenerales((prevDatosGenerales) => ({
            ...prevDatosGenerales,
            distrito_domicilio_id: distritoSeleccionado.id,
            distrito_domicilio: distritoSeleccionado,
        }));

        setDistritoD(distritoSeleccionado);
    };
    const calcularEdad = (fechaNacimiento) => {
        const today = new Date();
        const birthDate = new Date(fechaNacimiento);
        const difference = today.getTime() - birthDate.getTime();
        const ageDate = new Date(difference);
        const calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970);
        return calculatedAge;
    };

    const initialValues = {
        id: 0,
        tipo_documento: tiposDocumentos[0],
        numero_documento: "",
        nombres: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        distrito_id: "",
        celular: "",
        correo: "",
        direccion: "",
        rol: "",
        empresa_cargo: "",
        usuario: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (data) => {
            const formDataSend = new FormData();
            for (var key in data) {
                formDataSend.append(key, data[key]);
            }
            formDataSend.append("tipo_documento_id", datosGenerales.tipo_documento?.id)
            formDataSend.append("numero_documento", datosGenerales.numero_documento)

            formDataSend.append("empresa_cargo_id", data?.empresa_cargo?.id)

            formDataSend.append("rol_id", data?.rol?.id)
        },

    });


    const getNombreCompleto = (numdocumento, tipoDocumento, correo, celular, rol, usuario, empresa_cargo) => {
        if (formik.values.tipo_documento !== "") {
            if (formik.values.tipo_documento.nombre === "DNI") {
                axios.get(`https://dniruc.apisperu.com/api/v1/dni/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)

                    .then(res => {
                        formik.setValues({
                            tipo_documento: tipoDocumento,
                            numero_documento: numdocumento,
                            nombres: res.data.nombres,
                            apellido_paterno: res.data.apellidoPaterno,
                            apellido_materno: res.data.apellidoMaterno,
                            fecha_nacimiento: "2022-01-01",
                            distrito_id: 2,
                            celular: celular,
                            correo: correo,
                            direccion: "",
                            rol: rol,
                            usuario: usuario,
                            empresa_cargo: empresa_cargo,

                        })
                        setDatosGenerales({
                            ...datosGenerales,
                            apellido_paterno: formik.values.apellido_paterno,
                            apellido_materno: formik.values.apellido_materno,
                            nombres: formik.values.nombres,
                        });
                    })
                    .catch((error) => {
                        showToast(
                            "error",
                            "Número de documento no encontrado",
                            `No se encontró ningun dato`
                        );
                    })
            } else if (formik.values.tipo_documento.nombre === "RUC") {
                axios.get(`https://dniruc.apisperu.com/api/v1/ruc/${numdocumento}?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImplcGFjb3Q4MDBAYnJpdHRlZC5jb20ifQ.rG5rX3-Hn2I55vhFEqRM3sbUfa-mhW1gSxaCLZBsbV4`)

                    .then(res => {

                        formik.setValues({
                            tipo_documento: tipoDocumento,
                            numero_documento: numdocumento,
                            nombres: res.data.razonSocial.split(" ").slice(2, (res.data.razonSocial.length + 1)).join(" "),
                            apellido_paterno: res.data.razonSocial.split(" ")[0],
                            apellido_materno: res.data.razonSocial.split(" ")[1],
                            fecha_nacimiento: "2022-01-01",
                            distrito_id: 2,
                            celular: celular,
                            correo: correo,
                            direccion: "",
                            rol: rol,
                            usuario: "",
                            empresa_cargo: empresa_cargo,

                        })


                    })
                    .catch((error) => {
                        showToast(
                            "error",
                            "Número de documento no encontrado",
                            `No se encontró ningun dato`
                        );
                    })
            }


        } else {
            showToast(
                "error",
                "Tipo de documento",
                `No se eligió un tipo de documento`
            );
        }
    }

    const gradoinsti = [
        { nombre: 'Primaria', id: 1 },
        { nombre: 'Primaria Incompleta', id: 2 },
        { nombre: 'Secundaria', id: 3 },
        { nombre: 'Secundaria Incompleta', id: 4 },
        { nombre: 'Estudios Universitarios', id: 5 },
        { nombre: 'Estudios Universitarios Incompletos', id: 6 },
        { nombre: 'Ninguno', id: 7 },
        { nombre: 'Analfabeto', id: 8 },
        { nombre: 'Hasta 3RO de primaria', id: 9 },
        { nombre: 'Más 3RO de primaria', id: 10 },
        { nombre: 'Técnico', id: 11 },
        { nombre: 'Técnico Incompleto', id: 12 },
        { nombre: 'Doctorado completo', id: 13 },
        { nombre: 'Doctorado incompleto', id: 14 },
        { nombre: 'Especialización completa', id: 15 },
        { nombre: 'Especialización incompleta', id: 16 },
        { nombre: 'Maestría completa', id: 17 },
        { nombre: 'Maestría incompleta', id: 18 }
    ]

    return (
        <div className="flex flex-nowrap flex-row gap-3 " style={{ paddingLeft: "5px", paddingRight: "5px", overflow: "hidden", background: "#F6F7FB" }}>
            <form
                id="persona-form"
                className="persona-form"
                noValidate
                onSubmit={formik.handleSubmit}
            ></form>
            <div className="flex flex-wrap flex-colum gap-6" style={{ background: "#F6F7FB", borderRadius: "12.8671px" }} s>
                <div className="flex flex-wrap flex-column gap-3 w-full" style={{ marginTop: "20px" }}>
                    <div className="contenedorD2 flex flex-wrap flex-row p-3 gap-3 w-full">
                        <p className="subtituloR">Datos de Afiliación</p>
                        <div className="flex flex-wrap flex-row gap-3 w-full">
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Tipo de Documento</p>

                                <Dropdown
                                    id="tipo_documento"
                                    name="tipo_documento"
                                    value={datosGenerales.tipo_documento_id}
                                    onChange={(e) => {
                                        setDatosGenerales((prevDatos) => ({
                                            ...prevDatos,
                                            tipo_documento_id: e.value,
                                        }));

                                        formik.handleChange({
                                            target: {
                                                name: "tipo_documento",
                                                value: e.value,
                                            },
                                        });
                                    }}
                                    options={tiposDocumentos}
                                    optionLabel="nombre"
                                    placeholder="Selecciona un tipo de documento"
                                    className="w-full"
                                />
                                {camposInvalidos.includes('tipo_documento_id') && !datosGenerales.tipo_documento_id && (
                                    <p className="mensaje-error">¡Falta rellenar este campo!</p>
                                )}
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Nro. de Documento</p>

                                <InputText
                                    id="numero_documento"
                                    name="numero_documento"
                                    onChange={(e) => { setDatosGenerales({ ...datosGenerales, numero_documento: e.target.value }); formik.handleChange(e) }}
                                    value={datosGenerales.numero_documento}
                                    required
                                />
                                {camposInvalidos.includes('numero_documento') && datosGenerales.numero_documento === '' && (
                                    <p className="mensaje-error">¡Falta rellenar este campo!</p>
                                )}
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 justify-content-end">
                                <p className="datos"></p>
                                <Button
                                    label="Validar"
                                    type="button"
                                    style={{ background: "#07A34F", borderColor: "#07A34F" }}
                                    onClick={() => getNombreCompleto(
                                        formik.values.numero_documento,
                                        formik.values.tipo_documento,
                                        formik.values.correo,
                                        formik.values.celular,
                                        formik.values.rol,
                                        formik.values.usuario,
                                    )}
                                />
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Nro. de Código</p>
                                <InputText
                                    value={emerjencia.Codigo} keyfilter="pint"
                                    onChange={(e) => {
                                        const value = e.target.value.replace(/\D/g, '');
                                        setEmergencia({ ...emerjencia, Codigo: value });
                                    }}
                                    className="ingresoR"
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row gap-3 w-full">
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Apellido Paterno</p>
                                <InputText
                                    id="apellido_paterno"
                                    name="apellido_paterno"
                                    value={formik.values.apellido_paterno}
                                    onChange={(e) => { setDatosGenerales({ ...datosGenerales, apellido_paterno: e.target.value }); formik.handleChange(e); }}
                                    className="w-full" />
                                {camposInvalidos.includes('apellido_paterno') && datosGenerales.apellido_paterno === '' && (
                                    <p className="mensaje-error">¡Falta rellenar este campo!</p>
                                )}

                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Apellido Materno</p>
                                <InputText
                                    id="apellido_materno"
                                    name="apellido_materno"
                                    value={formik.values.apellido_materno}
                                    onChange={(e) => { setDatosGenerales({ ...datosGenerales, apellido_materno: e.target.value }); formik.handleChange(e); }}
                                    className="w-full" />
                                {camposInvalidos.includes('apellido_materno') && datosGenerales.apellido_materno === '' && (
                                    <p className="mensaje-error">¡Falta rellenar este campo!</p>
                                )}
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Nombres Completos</p>
                                <InputText
                                    id="nombres"
                                    name="nombres"
                                    value={formik.values.nombres}
                                    className="w-full"
                                    onChange={(e) => {
                                        setDatosGenerales({ ...datosGenerales, nombres: e.target.value });
                                        formik.handleChange(e);
                                    }}
                                    required
                                    autoComplete="off"
                                />
                                {camposInvalidos.includes('nombres') && datosGenerales.nombres === '' && (
                                    <p className="mensaje-error">¡Falta rellenar este campo!</p>
                                )}
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Nro. de Seguro Social</p>
                                <InputText value={emerjencia.seguro} keyfilter="pint" onChange={(e) => { const value = e.target.value.replace(/\D/g, ''); setEmergencia({ ...emerjencia, seguro: value }); }} className="ingresoR" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Datos de Nacimiento */}
                <div className="contenedor-nac" style={{ marginTop: "-10px" }}>
                    <div className="contenedorD2 flex flex-column flex-row p-3 gap-3 w-full">
                        <p className="subtituloR">Datos de Nacimiento</p>
                        <div className="flex flex-wrap gap-3 w-full">
                            <div className="flex flex-column gap-3 flex-1" style={{ minWidth: "160px" }}>
                                <p className="datos">Sexo</p>
                                <Dropdown
                                    value={datosGenerales.sexo_id}
                                    options={sexos.map((sexo) => ({ label: sexo.nombre, value: sexo.id }))}
                                    onChange={(e) => setDatosGenerales({ ...datosGenerales, sexo_id: e.value })}
                                    className={
                                        (camposInvalidos.includes('sexo_id') && datosGenerales.sexo_id === null)
                                            ? "ingresoR campo-invalido"
                                            : "ingresoR"
                                    }
                                    placeholder="Selecciona un sexo"
                                />
                                {camposInvalidos.includes('sexo_id') && datosGenerales.sexo_id === null && (
                                    <p className="mensaje-error">¡Falta seleccionar un sexo!</p>
                                )}
                            </div>
                            <div className="flex flex-column gap-3 flex-1 " style={{ minWidth: "160px" }}>
                                <p className="datos">Fecha de nacimiento</p>
                                <InputCalendar
                                    value={datosGenerales.fecha_nacimiento}
                                    onChange={(e) => {
                                        setDatosGenerales({ ...datosGenerales, fecha_nacimiento: e.value });

                                        // Calcular la edad en base a la fecha de nacimiento seleccionada
                                        const calculatedAge = calcularEdad(e.value);

                                        // Llenar automáticamente el campo de la edad
                                        setEmergencia({ ...emerjencia, edad: calculatedAge });
                                    }}
                                    showIcon
                                    className={
                                        (camposInvalidos.includes('fecha_nacimiento') && datosGenerales.fecha_nacimiento === '')
                                            ? "campo-invalido"
                                            : ""
                                    }
                                />
                                {camposInvalidos.includes('fecha_nacimiento') && datosGenerales.fecha_nacimiento === '' && (
                                    <p className="mensaje-error">¡Falta seleccionar la fecha de nacimiento!</p>
                                )}
                            </div>
                            <div className="flex flex-column gap-3 flex-1" style={{ minWidth: "160px" }}>
                                <p className="datos">Religión</p>
                                <Dropdown
                                    id="religion_id"
                                    value={datosGenerales.religion_id}
                                    onChange={(e) => setDatosGenerales({ ...datosGenerales, religion_id: e.value })}
                                    options={religiones.map(religion => ({ label: religion.descripcion, value: religion.id }))}
                                    placeholder="Selecciona una religión"
                                    optionLabel="label"
                                    optionValue="value"
                                    className={camposInvalidos.includes('religion') && datosGenerales.religion === null ? "dropdown-styles campo-invalido" : "dropdown-styles"}
                                />
                                {camposInvalidos.includes('religion') && datosGenerales.religion === null && (
                                    <p className="mensaje-error">¡Falta seleccionar una religión!</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row gap-3">
                            <div className="flex flex-wrap flex-column gap-3 flex-1" style={{ width: "470px" }}>
                                <p className="datos">Edad</p>
                                <InputText
                                    value={emerjencia.edad}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        const isNumeric = /^\d+$/;
                                        if (value === '' || (isNumeric.test(value) && parseInt(value) <= 100)) {
                                            setEmergencia({ ...emerjencia, edad: value });
                                        }
                                    }}
                                    className={
                                        isSaved && (
                                            emerjencia.edad === '' || !(/^\d+$/.test(emerjencia.edad) && parseInt(emerjencia.edad) <= 100)
                                        )
                                            ? "ingresoR campo-invalido"
                                            : "ingresoR"
                                    }
                                />
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Departamento</p>
                                <Dropdown
                                    id="departamento"
                                    className="dropdown-pais"
                                    options={departamentos}
                                    value={emerjencia.departamento_id ? emerjencia.departamento : emerjencia.departamento_id}
                                    onChange={handleChangeDepartamento}
                                    optionLabel="departamento"
                                    placeholder="Seleccione un Departamento"
                                    filter
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row gap-3">
                            <div className="flex flex-wrap flex-column gap-3 flex-1" style={{ width: "370px" }}>
                                <p className="datos">Provincia</p>
                                <Dropdown
                                    id="provincia"
                                    className="ingresoR"
                                    value={emerjencia.provincia_id ? emerjencia.provincia : emerjencia.provincia_id}
                                    options={provincias}
                                    onChange={handleChangeProvincia}
                                    optionLabel="provincia"
                                    placeholder="Seleccine una Provincia"
                                    filter
                                />

                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Distrito</p>
                                <Dropdown
                                    id="distrito"
                                    className={
                                        isSaved && !datosGenerales.distrito_id ? "ingresoR campo-invalido" : "ingresoR"
                                    }
                                    value={datosGenerales.distrito_id ? datosGenerales.dristrito : datosGenerales.distrito_id}
                                    options={distritos}
                                    onChange={handleChangeDistrito}
                                    optionLabel="distrito"
                                    placeholder="Selecciona distrito"
                                    filter
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Datos de domicilio */}
                <div className="flex w-full flex-wrap flex-column gap-3" style={{ marginTop: "-7px" }}>
                    <div className="contenedorD2 w-full flex flex-wrap flex-row p-3 gap-3">
                        <p className="subtituloR">Datos de Domicilio</p>
                        <div className="flex flex-wrap flex-row gap-3 w-full">

                            <div className="flex flex-column gap-3 flex-1">
                                <p className="datos">Departamento</p>
                                <Dropdown
                                    id="departamentoD"
                                    className="dropdown-pais"
                                    value={emerjencia.departamentoDom}
                                    options={departamentos}
                                    onChange={handleChangeDepartamentoDomicilio}
                                    optionLabel="departamento"
                                    placeholder="Seleccione un Departamento"
                                    filter
                                />
                            </div>
                            <div className="flex flex-column gap-3 flex-1">
                                <p className="datos">Provincia</p>
                                <Dropdown
                                    id="provinciaD"
                                    className="ingresoR"
                                    value={emerjencia.provinciaDom}
                                    options={provinciasD}
                                    onChange={handleChangeProvinciaDomicilio}
                                    optionLabel="provincia"
                                    placeholder="Seleccione una Provincia"
                                    filter
                                />
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row gap-3 w-full">
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Distrito</p>
                                <Dropdown
                                    id="distritoD"
                                    className={
                                        isSaved && !datosGenerales.distrito_domicilio_id ? "ingresoR campo-invalido" : "ingresoR"
                                    }
                                    value={datosGenerales.distrito_domicilio}
                                    options={distritosD}
                                    onChange={handleChangeDistritoDomicilio}
                                    optionLabel="distrito"
                                    placeholder="Seleccione un Distrito"
                                    filter
                                />
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Dirección</p>
                                <InputText
                                    id="direccion"
                                    name="direccion"
                                    value={formik.values.direccion}
                                    onChange={(e) => { setDatosGenerales({ ...datosGenerales, direccion: e.target.value }); formik.handleChange(e) }}
                                    className="w-full" />
                                {camposInvalidos.includes('direccion') && !datosGenerales.direccion && (
                                    <p className="mensaje-error">¡Falta rellenar este campo!</p>
                                )}

                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1 w-full">
                                <p className="datos">Nro. de Registro</p>
                                <InputText value={emerjencia.Registro} onChange={(e) => { const { value } = e.target; if (/^\d*$/.test(value) || value === '') { setEmergencia({ ...emerjencia, Registro: value }); } }} className="nregist w-full" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap flex-column gap-3 w-full" style={{ marginTop: "-10px" }}>
                    <div className="contenedorD4 flex flex-wrap flex-row p-3 gap-3">
                        <p className="subtituloR">Otros Datos</p>
                        <div className="flex flex-wrap flex-row gap-3 w-full">
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Estado Civil</p>
                                <Dropdown value={datosGenerales.estado_civil_id} onChange={(e) => setDatosGenerales({ ...datosGenerales, estado_civil_id: e.value })} options={estadoCivil} placeholder="Selecciona un estado civil" optionLabel="nombre" optionValue="id" className="dropdown-styles" />
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1" >
                                <p className="datos">Grado de Institución</p>
                                <Dropdown value={datosGenerales.grado_instruccion_id} onChange={(e) => setDatosGenerales({ ...datosGenerales, grado_instruccion_id: e.value })} options={gradoinsti} placeholder="Selecciona un grado de instrucción" optionLabel="nombre" optionValue="id" className="dropdown-styles" />
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1" >
                                <p className="datos">E-mail</p>
                                <InputText
                                    id="correo"
                                    value={datosGenerales.correo}
                                    onChange={(e) => setDatosGenerales({ ...datosGenerales, correo: e.target.value })}
                                    placeholder="example@example.com"
                                />
                                {camposInvalidos.includes('correo') && !datosGenerales.correo && (
                                    <p className="mensaje-error">¡Falta rellenar el campo de correo electrónico!</p>
                                )}
                            </div>
                        </div>
                        <div className="flex flex-wrap flex-row gap-3 w-full">
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Teléfono Fijo</p>
                                <InputText
                                    value={datosGenerales.telefono}
                                    onChange={(e) => {
                                        const pattern = /^[0-9]*$/;
                                        if (pattern.test(e.target.value)) {
                                            setDatosGenerales({ ...datosGenerales, telefono: e.target.value });
                                        }
                                    }}
                                    className={camposInvalidos.includes('telefono') && !datosGenerales.telefono ? "dropdown-styles campo-invalido" : "dropdown-styles"}
                                    placeholder="(01) 999999999"
                                />
                                {camposInvalidos.includes('telefono') && !datosGenerales.telefono && (
                                    <p className="mensaje-error">¡Falta rellenar el campo de teléfono!</p>
                                )}
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Teléfono Celular</p>
                                <InputText
                                    value={datosGenerales.celular}
                                    onChange={(e) => {
                                        const pattern = /^[0-9]*$/;
                                        if (pattern.test(e.target.value)) {
                                            setDatosGenerales({ ...datosGenerales, celular: e.target.value });
                                        }
                                    }}
                                    className={camposInvalidos.includes('celular') && !datosGenerales.celular ? "dropdown-styles campo-invalido" : "dropdown-styles"}
                                    placeholder="999999999"
                                />
                                {camposInvalidos.includes('telefono') && !datosGenerales.celular && (
                                    <p className="mensaje-error">¡Falta rellenar el campo de teléfono!</p>
                                )}
                            </div>
                            <div className="flex flex-wrap flex-column gap-3 flex-1">
                                <p className="datos">Contactenos en caso de Emergencia</p>
                                <InputText
                                    value={datosGenerales.telefono_emergencia}
                                    onChange={(e) => {
                                        const pattern = /^[0-9]*$/;
                                        if (pattern.test(e.target.value)) {
                                            setDatosGenerales({ ...datosGenerales, telefono_emergencia: e.target.value });
                                        }
                                    }}
                                    className={camposInvalidos.includes('telefono_emergencia') && !datosGenerales.telefono_emergencia ? "dropdown-styles campo-invalido" : "dropdown-styles"}
                                    placeholder="999999999"
                                />
                                {camposInvalidos.includes('telefono_emergencia') && !datosGenerales.telefono_emergencia && (
                                    <p className="mensaje-error">¡Falta rellenar el campo de teléfono de emergencia!</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default DatosPaciente;

import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
// import "./CSSBoton.css"

const ModalCancelarAtencion = (props) => {
    return (
        <Dialog
            visible={props.visible}
            header={
                <>
                    <h2 style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "18px", color: '#5555D8', paddingBottom: "30px" }}>Borrar cambios - {props.Atencion}</h2>
                </>
            }
            style={{ width: 'auto' }}

            // footer={
            //     <div>
            //         <Button onClick={props.onNo} className='BotonCancelar312 bg-white' style={{ color: "#6366F1" }}>Cancelar</Button>
            //         <Button onClick={props.onYes} className='BotonSave321'> Aceptar</Button>
            //     </div>
            // }

            footer={
                <div>
                    <button onClick={props.onNo} className='BotonCancelar312'>Cerrar</button>
                    <button onClick={props.onYes} className='BotonSave321'> Aceptar</button>
                </div>
            }
            onHide={props.onHide}
        >
            <p className="flex pb-4">
                ¿Está seguro de eliminar cambios? <br />
                La información se restablecerá a la última versión guardada
            </p>

        </Dialog>
    );
};

export default ModalCancelarAtencion;
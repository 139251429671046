import { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';
import "../../../Style/FileDropZone.css"

export default function FileDropzone({ onFileDrop, style, accept, label, className, File, Type, Size, nameFile, idFile }) {
    const [file, setFile] = useState(null);
    const toast = useRef(null);
    const fileInput = useRef(null);

    function handleDrop(e) {
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        onFileDrop(droppedFile);
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleFileSelect(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        onFileDrop(selectedFile);
    }

    function openFileSelector() {
        fileInput.current.click();
    }

    function formatFileSize(size) {
        if (size < 1024) {
            return size + ' bytes';
        } else if (size < 1024 * 1024) {
            return (size / 1024).toFixed(2) + ' KB';
        } else if (size < 1024 * 1024 * 1024) {
            return (size / (1024 * 1024)).toFixed(2) + ' MB';
        } else {
            return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }
    }

    return (
        <div
            className={`filedropzone ${className || ""}`}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onClick={openFileSelector}
            style={style}
        >
            <Toast ref={toast} />
            <input
                ref={fileInput}
                type="file"
                accept={accept}
                style={{ display: 'none' }}
                onChange={handleFileSelect}
                name={nameFile}
                id={idFile}
            />
            <div className="flex align-items-center gap-2 text-center">
                <p>{label}</p>
                <div className="flex align-items-center">
                    <img width="17px" height="14.92px" src="https://i.ibb.co/xG0v5Gy/Icon.png" alt="" />
                </div>
            </div>
            {/* <div className="flex flex-column gap-1 align-items-center justify-content-center text-center">
                {file || File ? (
                    <>
                        <p>Nombre: {File || file.name}</p>
                        <p>Tipo: {Type || file.type}</p>
                        <p>Tamaño: {formatFileSize(Size || file.size)}</p>
                    </>
                ) : (
                    <>
                        <p className="font-bold">Recomendaciones</p>
                        <p>*Tamaño de 500 x 500px</p>
                        <p>*Alta calidad</p>
                        <p>*Formato .png o .svg</p>
                    </>
                )}
            </div> */}
        </div>
    );
}

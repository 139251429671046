import React from 'react';
import { Dialog } from 'primereact/dialog';
import "./CSSBoton.css"

const ModalGuardarAtencion = (props) => {
    return (
        <>
            <Dialog
                visible={props.visible}
                header={
                    <>
                        <h2 style={{ fontFamily: "Montserrat", fontWeight: "700", fontSize: "18px", color: '#5555D8' }}>Guardar - {props.Atencion}</h2>
                    </>
                }
                style={{ width: 'auto' }}
                modal
                footer={
                    <div>
                        <button onClick={props.onNo} className='BotonCancelar312'>Cancelar</button>
                        <button onClick={props.onYes} className='BotonSave321'> Guardar</button>
                    </div>
                }
                onHide={props.onHide}
            >
                ¿Estás seguro de que quieres guardar {props.Atencion}?
            </Dialog>
        </>
    );
};

export default ModalGuardarAtencion;

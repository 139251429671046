import React from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { saveAs } from 'file-saver';

export default function DialogPDF({ prop01, prop02, documentos }) {

   // Función para descargar el archivo PDF directamente
   const handleDownloadDirect = (ruta, nombre) => {
      const originalText = nombre;
      const textWithUnderscores = originalText.replace(/\s+/g, '_');
      saveAs("Escala de Somnolencia Epworth1.pdf", textWithUnderscores + '.pdf');
   };

   const footerContent = (
      <div>
         <Button label="Cancelar" onClick={() => prop02(false)} className="p-button-text" style={{ border: "1px solid #4545bc" }} />
      </div>
   );

   return (
      <Dialog header="Informe del paciente" visible={prop01} style={{ width: '50vw' }} onHide={() => prop02(false)} footer={footerContent}>
         <table className="tabla">
            <thead>
               <tr>
                  <th className="encabezado">PDF</th>
                  <th className="encabezado text-center">Visualizar</th>
                  <th className="encabezado text-center">Descargar</th>
               </tr>
            </thead>
            <tbody>
               {documentos.map(item => {
                  return (
                     <tr key={item.id}>
                        <td>{item.nombre}</td>
                        <td className="justify-content-center text-center"><a href={item.ruta} target="_blank" rel="noopener noreferrer"><i className="pi pi-eye"></i></a></td>
                        <td className="justify-content-center text-center"><Button onClick={() => handleDownloadDirect(item.ruta, item.nombre)} className="p-button-text" ><i className="pi pi-download" /></Button></td>
                     </tr>
                  );
               })}
            </tbody>
         </table>
      </Dialog>
   );
}

import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

const SpinnerComponent = () => {
   return (
      <div className="card flex justify-content-center justify-aligne-center" style={{
         position: 'fixed',
         top: '0',
         left: '0',
         width: '100%',
         height: '100%',
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         backgroundColor: 'rgba(0, 0, 0, 0.5)', // Fondo semitransparente
         zIndex: '9999', // Asegura que esté por encima de todo
      }}>
         <ProgressSpinner />
      </div>
   );
}

export default SpinnerComponent;

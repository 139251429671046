import React from 'react';
import TriajeSigVitTable from "../../../Components/Triaje/SignosVitales/TriajeSigVitTable";
import '../../../Style/Triaje/SignosVitales/triaje_sig_vit.css'

const PageTriajeSigVit = () => {

  return (
    <>
      <TriajeSigVitTable />
    </>
  );
};

export default PageTriajeSigVit;
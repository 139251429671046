import { InputText } from "primereact/inputtext";
import "../../../../Style/PageGestionProformaB.css"
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useState, useRef, useEffect, useContext } from "react";
import { Button } from "primereact/button";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { Tag } from "primereact/tag";
import { FilterMatchMode } from "primereact/api";
//ORIGINALS
import S3ProductC from "../../../Sales/NewClientC/S3ProductC/S3ProductC";
//SERVICES
import SC1Customer from "../../../../Components/Sales/Services/Clinica/SC1Customer/SC1Customer";
import SC3Product from "../../../Sales/Services/Clinica/SC3Product/SC3Product";
import { useParams } from "react-router-dom";
import { SalesContext } from "../../../Sales/SalesContext/SalesContext";
import { Toast } from 'primereact/toast';
import { useDispatch } from "react-redux";
import { startLoading, endLoading } from "../../../../Redux/isLoadingSlice";
import { Dialog } from "primereact/dialog";
// import AuthUser from "../../../AuthUser";
import SB2Contact from "../../../Sales/Services/Bregma/SB2Contact/SB2Contact";
import { Checkbox } from "primereact/checkbox";

const GestionProforma = () => {
   const { id } = useParams();
   const toast = useRef(null);
   // const { http } = AuthUser();

   const dispatch = useDispatch();

   const { sales, setSales } = useContext(SalesContext);
   const [packages, setPackages] = useState(null);
   const [proforma, setProforma] = useState();
   // const [paqueteDocumentos, setPaqueteDocumentos] = useState(null);

   const showToast = (severity, summary, detail) => {
      toast.current.show({ severity: severity, summary: summary, detail: detail });
   };

   const { getPackages, getCompanyID } = SC1Customer();
   const { postPackage, getProforma } = SC3Product(showToast);

   const [globalFilterValue, setGlobalFilterValue] = useState("");

   const [filters, setFilters] = useState({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
   });

   const onGlobalFilterChange = (e) => {
      const value = e.target.value;
      let _filters = { ...filters };
      _filters["global"].value = value;
      setFilters(_filters);
      setGlobalFilterValue(value);
   };

   const handleGetProforma = async () => {
      const dataProforma = await getProforma(id)
      setProforma(dataProforma)
   }

   const bg_state = (btn) => {
      switch (btn) {
         case "En espera":
            return "proforma__container_body_table_state_purple";
         case "Aceptado":
            return "proforma__container_body_table_state_green";
         case "Rechazado":
            return "proforma__container_body_table_state_red";
         default:
            return "";
      }
   };

   const [visible, setVisible] = useState(false);
   const [tipoCredencial, setTipoCredencial] = useState(null);

   useEffect(() => {
      const fetchDataCopy = async () => {
         const promises = [
            getPackages(),
            handleGetProforma()
         ];
         try {
            const results = await Promise.allSettled(promises);

            const valuePaquetes = results[0].status === "fulfilled" ? results[0].value : null;
            setPackages(valuePaquetes);

            if (id) {
               await getCompanyID(id, null, setSales)
            }

         } catch (error) {
            console.error('Error en la llamada a la API:', error);
         } finally {
            dispatch(endLoading())
         }
      }
      dispatch(startLoading())
      fetchDataCopy();
   }, []);

   const [visible1, setVisible1] = useState(false);

   const { getContact } = SB2Contact();
   const [contactos, setContactos] = useState([]);

   useEffect(() => {
      const obtenerContactos = async () => {
         try {
            const contactosObtenidos = await getContact(2); // Pasa el ID de la clínica
            setContactos(contactosObtenidos);
         } catch (error) {
            console.error('Error al obtener los contactos:', error);
         }
      };

      obtenerContactos();
   }, []);

   const [searchTerm1, setSearchTerm1] = useState('');

   const filteredContactos = contactos.filter(contacto =>
      contacto.persona.nombres && contacto.persona.nombres.toLowerCase().includes(searchTerm1.toLowerCase())
   );

   const [selectedContacts, setSelectedContacts] = useState([]);
   const handleCheckboxChange = (contacto) => {
      if (selectedContacts.includes(contacto)) {
         setSelectedContacts(selectedContacts.filter(c => c !== contacto));
      } else {
         setSelectedContacts([...selectedContacts, contacto]);
      }
   };

   const handleCorreoButtonClick = () => {
      // Filtrar los contactos que tienen correo
      const contactosConCorreo = contactos.filter(contacto => contacto.persona.correo);
      // Establecer los contactos filtrados como seleccionados
      setSelectedContacts(contactosConCorreo);
   };

   const itemFunction = (rowData, rowIndex) => {
      return (
         <p>{rowIndex.rowIndex + 1}</p>
      )
   }

   const cargaArchivo = () => {
      return (
         <div className="flex align-items-center justify-content-center w-full h-full ">
            <Button onClick={() => { setVisible1(true) }} style={{ backgroundColor: "rgba(227, 227, 255, 0.54)", color: "rgb(85, 85, 216)", borderRadius: "25px", border: "rgba(227, 227, 255, 0.54)" }}  >
               <i className="pi pi-upload proforma__container" ></i>
            </Button>
         </div>
      )
   }


   return (
      <div className="proforma__container">
         <Toast style={{ zIndex: "100" }} ref={toast} />
         <div className="add__container_header">
            <h4 className="add__container_header_h4">Gestión de Proformas</h4>
            <p className="add__container_header_p">
               En esta seccion usted puede ver el historial de las proformas
            </p>
         </div>
         <div className="proforma__container_input_header flex ">
            <span className="p-input-icon-left  bg-transparent">
               <i className="pi pi-search" />
               <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Buscador"
                  className="input_responsive border-0 border-round-3xl shadow-2"
               />
            </span>
            <div className="flex gap-2 justify-content-end">
               <Button
                  label="Crear Nuevo"
                  icon="pi pi-plus"
                  className="p-button-success" style={{width:'10rem', borderRadius:'12px'}}
                  onClick={() => setVisible(true)}
               />
            </div>
         </div>
         <div className="proforma__container_body bg-white flex flex-column">
            <ScrollPanel
               style={{ width: "100%", height: "100%" }}
               className="custombar2"
            >
               <DataTable
                  className="tablaProforma"
                  emptyMessage="No se encontraron resultados"
                  responsiveLayout="stack"
                  paginator
                  rows={5}
                  value={proforma}
                  filters={filters}
               >
                  <Column header="Item" body={itemFunction} bodyStyle={{ textAlign: 'center' }} />
                  <Column field="nombre" header="Código de Proforma" bodyStyle={{ textAlign: 'center' }} />
                  <Column field="razon_social" header="Razon Social" bodyStyle={{ textAlign: 'center' }} />
                  <Column header="Estado" body={(rowData) => (
                     <div className={bg_state(rowData.estado)}>
                        <Tag>{rowData.estado != null ? rowData.estado : "En espera"}</Tag>
                     </div>
                  )} />
                  <Column header="Carga de Archivos" body={cargaArchivo} />
               </DataTable>

            </ScrollPanel>
         </div>

         <S3ProductC
            visible={visible} //manejo del toast
            setVisible={setVisible}
            packages={packages} //Paquetes
            postPackages={postPackage}
            showToast={showToast}
            getProforma={handleGetProforma}
         // paqueteDocumentos={paqueteDocumentos} 
         />

         <Dialog
            visible={visible1}
            onHide={() => setVisible1(false)}
            style={{ width: '30vw', maxWidth: "30vw" }}

         >
            <div className="flex-1 flex flex-block justify-content-center gap-4 mr-6 ml-6 mt-4" >

               <div className="flex-1 bg-white border-round-2xl flex flex-column gap-2 p-5" style={{ maxWidth: "515px", height: "600px" }}>
                  <div
                     className="flex-1 bg-white border-round-lg flex flex-column gap-2"
                     style={{ minWidth: "360px" }}
                  >
                     <div className="flex gap-3">
                        <img
                           width="40px"
                           height="40px"
                           className="bg-indigo-100 border-round-lg p-2"
                           src="https://i.ibb.co/gjvBxZS/Icon.png"
                           alt=""
                        />

                        <div className="flex justify-content-between align-items-center mb-3">
                           <p
                              className="font-bold text-base"
                              style={{ color: "#565656 !important" }}
                           >
                              Contactos
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className="p-input-icon-left w-full justify-content-between">

                     <div className="p-input-icon-left" style={{ width: "100%" }}>
                        <i className="pi pi-search" />
                        <InputText
                           value={searchTerm1}
                           onChange={(e) => setSearchTerm1(e.target.value)}
                           placeholder="Buscar por nombre"
                           className="w-full"
                        />
                     </div>
                  </div>

                  <div style={{ minHeight: "316px" }} className="ventas-scroll">
                     {filteredContactos.map(contacto => (
                        <div key={contacto.id}>
                           <Checkbox checked={selectedContacts.includes(contacto)} onChange={() => handleCheckboxChange(contacto)} />
                           {contacto.persona.nombres}
                        </div>
                     ))}
                  </div>


                  <div className="flex flex-column gap-3 align-items-center mt-4 h-full justify-content-between">
                     <div className="flex justify-content-center text-center gap-3">

                        <Button
                           style={{ width: "170px" }}
                           className={`pi pi-envelope flex justify-content-center font-light gap-2 ${tipoCredencial === 1
                              ? "bg-indigo-100 text-indigo-400 border-indigo-200"
                              : "bg-white text-gray-500 border-gray-500"
                              }`}
                           onClick={handleCorreoButtonClick} // Llamar a la función al hacer clic en el botón "Correo"
                        >
                           Correo
                        </Button>

                        <Button
                           style={{ width: "170px" }}
                           className={`pi pi-whatsapp flex justify-content-center font-light gap-2 ${tipoCredencial === 2
                              ? "bg-indigo-100 text-indigo-400 border-indigo-200"
                              : "bg-white text-gray-500 border-gray-500"
                              }`}

                        >
                           Whatsapp
                        </Button>

                     </div>

                     <div className="flex justify-content-center text-center gap-3">
                        <Button
                           style={{ width: "156px", height: "38px" }}
                           className="flex justify-content-center bg-white font-semibold text-gray-900 border-gray-500"
                           onClick={() => setVisible1(false)}

                        >
                           Cancelar
                        </Button>
                        <Button
                           className="pi pi-send flex gap-2"

                        >
                           Enviar
                        </Button>
                     </div>

                  </div>
               </div>
            </div>

         </Dialog>
      </div>
   )
}

export default GestionProforma
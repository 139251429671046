import React from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useState } from "react";
import "../../Style/Loader/spinner.css"
const Spinner = ({show,setVisibles}) => {
  return (
          <Dialog className="spin"  style={{ width: '50vw',backgroundColor:"transparent!important"}} visible={show} onHide={() => setVisibles(false)}>
              <div className="spinners p-5 flex  justify-content-center ">
                    <div class="spinner"></div>
              </div>

          </Dialog>        
 
  )
}
export default Spinner
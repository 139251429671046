import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";

const ModalComenClinica = ({ visible, onHide, comentariosInicial, onSave, selectedRowData }) => {
    const [comentarios, setComentarios] = useState(comentariosInicial || "");
    const [pretensionSalarial, setPretensionSalarial] = useState("");
  
    useEffect(() => {
      setPretensionSalarial(selectedRowData?.pretension_salarial || "");
      setComentarios(comentariosInicial || "");
    }, [selectedRowData, comentariosInicial]);
  
    const handleGuardarComentarios = () => {
      onSave(selectedRowData?.id, comentarios, pretensionSalarial);
      onHide();
    };
  
    const handleCancelar = () => {
      setComentarios(comentariosInicial)
      onHide();
    };
  
    return (
      <Dialog
        visible={visible}
        onHide={onHide}
        modal
        style={{
          width: "385px",
          margin: "5%",
        }}
        header={
          <>
            <div
              className="flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#EBEBFF",
                width: "60px",
                height: "60px",
                borderRadius: "10px",
              }}
            >
              <div className="flex justify-content-center align-items-center">
                <img
                  src="https://cdn.discordapp.com/attachments/1078050726217011331/1090321659044573235/Group_1920.png"
                  alt=""
                />
              </div>
            </div>
            <h1 className="per">Comentarios</h1>
          </>
        }
      >
        <div className="flex flex-column gap-3">
          <div className="flex flex-column w-full">
            <p className="m-0">
              En esta sección, usted podrá dejar los comentarios del proceso de selección de{" "}
              <span style={{ fontWeight: "bold", color: "#333" }}>
                {selectedRowData?.persona?.nombres || ""} {selectedRowData?.persona?.apellido_paterno || ""}{" "}
                {selectedRowData?.persona?.apellido_materno || ""}
              </span>
              .
            </p>
          </div>
        </div>
        <div className="flex flex-column w-full mb-1" style={{ marginBottom: "1rem" }}>
          <label htmlFor="pretensionSalarial">Pretensión Salarial</label>
          <InputText
            id="pretensionSalarial"
            value={pretensionSalarial}
            onChange={(e) => setPretensionSalarial(e.target.value)}
            className="p-inputtext w-full"
          />
        </div>
        <div className="w-full mb-1" style={{ marginBottom: "1rem", maxHeight: "200px", overflowY: "auto" }}>
          <InputTextarea
            value={comentarios}
            onChange={(e) => setComentarios(e.target.value)}
            rows={5}
            cols={30}
            className="p-inputtext w-full"
          />
        </div>
        <div className="flex w-full bot">
          <Button
            label="Cancelar"
            icon="pi pi-times"
            onClick={handleCancelar}
            className="delete w-full"
            style={{ marginRight: "0.5rem" }}
          />
          <Button
            label="Comentar"
            onClick={handleGuardarComentarios}
            className="p-button w-full"
            style={{ width: "150px" }}
            />
        </div>
      </Dialog>
    );
  };
  
  export default ModalComenClinica;
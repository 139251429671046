import React, { useEffect, useState, useContext } from "react";
import "../../../Style/style7.css";
import { Dropdown } from 'primereact/dropdown';
import { MGContext } from "../MGProvider";
import { RAContext } from "../../RutaAtencion/RAContext";

export default function PrimeraColumnaElComponente({ obtenerEvaluacion, nuevaObservacion }) {
    let { stateAtencion, setStateAtencion, pageViewContext } = useContext(RAContext);
    const { evaluacionOsteomioarticularContext, setEvaluacionOsteomioarticularContext } = useContext(MGContext);

    const respuestas = ["SI", "NO" ];

    const onChange = (index, e) => {
        
            const updatedDetalle = [...evaluacionOsteomioarticularContext.med_osteomioarticular_detalle];
            updatedDetalle[index].incapacidad_casa = e;
            setEvaluacionOsteomioarticularContext({
                ...evaluacionOsteomioarticularContext,
                med_osteomioarticular_detalle: updatedDetalle
            });
        
    }

    return (
        <div className="Contenedor-PrimeraColumna-Cusestionario" style={{ width: '33%' }}>
            <div className="EncabezadoDeLaPrimeraColumna">
                <span>Usted ha tenido en los últimos 12 meses problemas (dolor, curvaturas, etc) a nivel de:</span>
            </div>
            <div className="CuerpoConLasOpciones gap-3">
                {evaluacionOsteomioarticularContext && evaluacionOsteomioarticularContext.med_osteomioarticular_detalle
                    ? evaluacionOsteomioarticularContext.med_osteomioarticular_detalle.map((dato, index) => (
                        <div key={index} className="SubtituloDePreguntas flex flex-row" style={{ justifyContent: 'center' }}>
                            <Dropdown
                                value={dato.incapacidad_casa || ""}
                                onChange={(e) => onChange(index, e.value)}
                                options={respuestas}
                                // optionLabel="name"
                                placeholder="NO"
                                disabled={stateAtencion[pageViewContext].estado === 3}
                                className="w-full md:w-6rem"
                                style={{ height: '40px' }}
                            />
                        </div>))
                    : ""}
            </div>
        </div>
    );
}
import React from 'react'

function PageSoporteTecnicoE() {
    return (
        <div>
            <div>
                <span className='tit'>Soporte Tecnico</span><br />
                <span className='con'>Si cuenta con alguna duda en el uso del servicio, por favor, comuniquese con nosotros:</span>
            </div>
        </div>
    )
}

export default PageSoporteTecnicoE
import React from 'react'

function PageEstadisticaPSB() {
    return (
        <div>
            <div className='arriba'>
                <span className='tit'>Estadísticas Productos y Servicios</span><br />
                <span className='con'>Agregar Contenido</span>
            </div>
        </div>
    )
}

export default PageEstadisticaPSB;
import React, { useContext, useEffect, useState } from "react";
import { RAContext } from "../../RutaAtencion/RAContext";
import { PsicologiaContext } from "../PsicologiaContext";
// import SelectTextArea from "../../../Components/form/SelectTextArea";
import { Dropdown } from "primereact/dropdown";
import "../../../Style/Diagnostico/psi_diag.css";
// import Record from "../../../Components/Record/Record"
import SelectFlechita from "../../../Components/Psicologia/ComponentR/SelectFlechita";
import ButtonVoiceReciclable from "../../../Components/ButtonVoice/ButtonVoiceReciclable2";

import SpeechRecognitionComponent from "./SpeechRecognitionComponentarea_cognitiva";
import SpeechRecognitionComponent2 from "./SpeechRecognitionComponentarea_emocional";
import SpeechRecognitionComponent3 from "./SpeechRecognitionComponentRecomendaciones";

const PagePsiDiag = () => {
   let { diagContext, setDiagContext } = useContext(PsicologiaContext);
   let { pacienteContext, pageViewContext, stateAtencion } = useContext(RAContext);

   const [areacog, setAreacog] = useState(diagContext?.area_cognitiva ?? null);
   const [areaemo, setAreaemo] = useState(diagContext?.area_emocional ?? null);
   const [recomendaciones, setRecomendaciones] = useState(diagContext?.recomendaciones ?? null);

   const [result, setResult] = useState(
      diagContext?.resultado_id ?? null
   );

   const handleAC = (event) => {
      setDiagContext({ ...diagContext, area_cognitiva: event.value.label || event.value });
   };
   const handleAE = (event) => {
      setDiagContext({ ...diagContext, area_emocional: event.value.label || event.value });
   };
   const handleR = (event) => {
      setDiagContext({ ...diagContext, recomendaciones: event.value.label || event.value });
   };

   const cogOptions = [
      { label: "Se ubica en la categoría Normal promedio", value: 1, id: 1 },
      { label: "Se encuentra en la categoría inferior al promedio", value: 2, id: 2 },
      { label: "Se ubica en la categoría promedio bajo", value: 3, id: 3 },
      { label: "Se encuentra en la categoría Superior al promedio", value: 4, id: 4},
   ];

   const emoOptions = [
      { label: "Se aprecia colaborador durante la entrevista",value: 1,id: 1,},
      { label: "Se aprecia colaboradora durante la entrevista",value: 2,id: 2,},
      { label: "Se percibe amable en el trato con los demás", value: 3, id: 3 },
      { label: "Se aprecia cordial en la entrevista", value: 4, id: 4 },
      { label: "Se aprecia cordial y colaborador en la entrevista",value: 5,id: 5,},
      { label: "Se aprecia cordial y colaborador en la entrevista. Su autoestima es adecuada",value: 6,id: 6,},
      { label: "Se aprecia cordial y colaborador en la entrevista. Su personalidad tiende a la introversión. Ante situaciones adversas",value: 7,id: 7},
      { label: "Se aprecia cordial y colaborador en la entrevista. Su personalidad tiende a la introversión. Posee recursos internos",value: 8,id: 8},
      { label: "Se aprecia reservada en la entrevista", value: 9, id: 9 },
      { label: "Se aprecia reservado en la entrevista", value: 10, id: 10 },
   ];

   const recomendOptions = [
      { label: "Se le recomienda examen psicológico anualmente",value: 1,id: 1,},
      { label: "Reforzar inducción y capacitación en el área de seguridad laboral",value: 2,id: 2},
      { label: "Se sugiere orientación/consejería psicológica",value: 3,id: 3},
      { label: "Practicar ejercicios de relajación y respiración para mejorar el manejo de la ansiedad", value: 4,id: 4},
   ];

   let resultOptions = [
      { label: "Apto", value: 1, id: 1 },
      { label: "Apto con observación", value: 2, id: 2 },
      { label: "Apto con Recomendación", value: 3, id: 3 },
      { label: "Apto con Restricciones", value: 4, id: 4 },
      { label: "No Apto", value: 5, id: 5 },
      { label: "No Apto Temporal", value: 6, id: 6 },
   ];

   useEffect(() => {
      setDiagContext(prevState => ({
         ...prevState,
         area_cognitiva: areacog,
         area_emocional: areaemo,
         recomendaciones: recomendaciones
      }));
   }, [areacog,  areaemo, recomendaciones]);


   useEffect(() => {
      try {
         
         setDiagContext({
            ...diagContext,
            [`resultado_id`]: result,
         });
         
      } catch (error) {
         console.error("Error al actualizar el contexto:", error);
      }
   }, [result, pacienteContext, setDiagContext]);

   useEffect(()=>{
      let filterDiagnostico= resultOptions?.filter(result=>result?.id===pacienteContext?.resultado_psicologia_id)?.[0];
      setDiagContext({...diagContext,resultado:filterDiagnostico  || null})
   },[])

   


   return (
      <>
         <h3 className="text-center mb-4">Diagnóstico Final</h3>
         <div className="flex flex-wrap w-12 gap-5">
            <div className="flex flex-wrap w-12 gap-4">
               <div className="flex w-full relative gap-1">
                  <SelectFlechita
                              contentClassName='flex flex-column w-full'
                              className={'w-full'}
                              titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                              option={cogOptions}
                              setvalue={setAreacog}
                              title='Área Cognitiva'
                              setParameter={handleAC}
                              value={diagContext?.area_cognitiva}
                              id
                              disabled={stateAtencion[pageViewContext].estado === 3}
                              filtered={false}
                              maxLength={5000}
                  />
                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button'} setInput01={setAreacog} />
               </div>

               <div className="flex w-full relative gap-1">
                  <SelectFlechita
                              contentClassName='flex flex-column w-full'
                              className={'w-full'}
                              titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                              option={emoOptions}
                              setvalue={setAreaemo}
                              title='Área Emocional'
                              setParameter={handleAE}
                              value={diagContext?.area_emocional}
                              id
                              disabled={stateAtencion[pageViewContext].estado === 3}
                              filtered={false}
                              maxLength={5000}
                  />
                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button'} setInput01={setAreaemo} />
               </div>

               <div className="flex w-full relative gap-1">
                  <SelectFlechita
                              contentClassName='flex flex-column w-full'
                              className={'w-full'}
                              titleStyle={{ color: '#4C4CC9', fontWeight: '600' }}
                              option={recomendOptions}
                              setvalue={setRecomendaciones}
                              title='Recomendaciones'
                              setParameter={handleR}
                              value={diagContext?.recomendaciones}
                              id
                              disabled={stateAtencion[pageViewContext].estado === 3}
                              filtered={false}
                              maxLength={5000}
                  />
                  <ButtonVoiceReciclable disabled={stateAtencion[pageViewContext].estado === 3} className={'mt-5 input-button'} setInput01={setRecomendaciones} />
               </div>

               <div className=" flex w-full relative gap-1 psi_diag_label_dropdown">
                  <label>Resultado</label>
                  <Dropdown
                     placeholder="Seleccione una opción"
                     options={resultOptions}
                     value={result}
                     onChange={(e) => setResult(e.value)}
                     disabled={stateAtencion[pageViewContext].estado === 3}
                  />
               </div>
            </div>
         </div>


         {/* <div className="psi_diag_label_textarea">
            <SpeechRecognitionComponent disabled={stateAtencion[pageViewContext].estado === 3}/>
         </div> 

         <div className="psi_diag_label_textarea">
            <SpeechRecognitionComponent2 disabled={stateAtencion[pageViewContext].estado === 3} />
         </div>

         <div className="psi_diag_label_textarea">
            <SpeechRecognitionComponent3 disabled={stateAtencion[pageViewContext].estado === 3} />
         </div> */}

         
      </>
   );
};

export default PagePsiDiag;
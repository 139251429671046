import React from 'react';

const AtencionContainer = ({ tabs, form }) => {
  return (
    <>
      <div className="paciente_form_container">
        <div className="paciente_form_tabs ">
          {tabs}
        </div>
        <div className="paciente_form_form">
          {form}
        </div>

      </div>
    </>
  );
};

export default AtencionContainer;
import React from "react";
import PermisosComponent from "../../../Components/Perfil/usuario/components/Permisos02";
import InboxComponent from './Inbox/Inbox'
import "../../../Style/PagePerfil1.css";

function SectionNotificacionCoorporativo01(props) {
    return (
        <div className="SECTION_GENERAL_NOTIFICACION_COORPO_AHP w-full h-auto flex align-items-center justify-content-between">
            <PermisosComponent razonSocial={props.RazonS}/>
            <InboxComponent />
        </div>
    );
};
export default SectionNotificacionCoorporativo01
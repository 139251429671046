import React from 'react';
import ClienteClinicaVR from '../ClienteClinica/ClienteClinicaVR.jsx';
import ClienteCorporativoVR from '../ClienteCoorporativo/ClienteCorporativoVR.jsx';
import './ResponsiveContenedor.css';

function DataEnProceso(props) {
  let ComponenteDinamico = null;
  
  if (props.nombreComponente === 'ClienteClinicaVR') {
    ComponenteDinamico = ClienteClinicaVR;
  }
  if (props.nombreComponente === 'ClienteCorporativoVR'){
    ComponenteDinamico = ClienteCorporativoVR;
  }
  return (
    <div  style={{width:'100%'}}>
      {/* Renderiza el componente dinámico con las props proporcionadas */}
      {ComponenteDinamico && (
        <div className='Contentt_Card_Verificador'> 
        <ComponenteDinamico
            nombreClinica='Medic Ser S.A.C.'
            rucClinica='20501781291'
            estadoPago='En proceso'
            ModoPago='Efectivo'
            NmProforma='1909A22025'
            TotalRestante='S/1446.90'
            TotalPorCobrar='S/50.00'
            archivoCheck='1'
            archivoEnlace='2'
            archivoDividido='0'
            tiempoRetrasado='-2'
        />
        <ComponenteDinamico
            nombreClinica='Medic Ser S.A.C.'
            rucClinica='20501781291'
            estadoPago='En proceso'
            ModoPago='Efectivo'
            NmProforma='1909A22025'
            TotalRestante='S/1446.90'
            TotalPorCobrar='S/50.00'
            archivoCheck='1'
            archivoEnlace='2'
            archivoDividido='0'
            tiempoRetrasado='-2'
        />
        </div>
      )}
    </div>
  );
}

export default DataEnProceso;

import React, { useState, useEffect, useRef } from "react";
import medicTermConditions from "../../../assets/OperIcons/termConditions/medic-term-conditions.png";
import "../../../Style/TermConditions.css";
import "primeicons/primeicons.css";
import AuthUser from "../../../AuthUser";
import { DataTermsCondition } from "../../../api";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { startLoading, endLoading, } from "../../../Redux/isLoadingSlice";
import { useDispatch } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";


export default function TermConditions() {
   // Data Get
   const { terms, getAllTerms } = DataTermsCondition('clinica/servicio/get');
   const { http } = AuthUser();
   const toast = useRef(null);
   // Variables
   const dispatch = useDispatch();


   // Visible Dialog
   const [visibleDelete, setVisibleDelete] = useState(false);
   const [visibleEdit, setVisibleEdit] = useState(false);
   const [visible, setVisible] = useState(false);
   const [visibleDescription, setVisibleDescription] = useState(false);
   const [visibleSubTermino, setVisibleSubTermino] = useState(false);

   // Datos de terminos

   const [areaNombre, setAreaNombre] = useState("");
   const [descripcion, setDescripcion] = useState("");
   const [selectedTerm, setSelectedTerm] = useState(null);
   const [windowWidth, setWindowWidth] = useState(window.innerWidth);

   //Validar los campos
   // const isNombreValido = areaNombre.trim() !== "";

   /*const handleCrearArea = () => {
      if (isNombreValido) {
         // Realiza la lógica para crear el área
         // Puedes cerrar el cuadro de diálogo si es necesario
         setVisible(false);
      }
   };*/

   // End Variables

   // Footer/Header de dialog

   const [DescripcionFalta, setDescripcionFalta] = useState(false)
   const VerificarDescripcionFalta = () => {
      var verifi = true;
      var inputDescripcionFa = document.getElementById('myTextareaDescripcion').value;
      if (inputDescripcionFa == "" || inputDescripcionFa == null) {
         verifi = false;
         setDescripcionFalta(true)
      }
      return verifi;
   }
   useEffect(() => {
      setDescripcionFalta(false)
   }, [visibleDescription])
   const footerDescription = (
      <div className="flex w-full align-items-center justify-content-end butons-des">
         <Button
            type="button"
            label="Cancelar"
            className="delete w-full"
            onClick={() => {
               setVisibleDescription(false);
            }}
         />
         <button
            style={{ margin: "3%" }}
            className="dialogs_butons_default_primary"
            onChange={(e) => setDescripcion(e.target.value)}
            onClick={() => {
               if (VerificarDescripcionFalta()) {
                  setVisibleDescription(false);
                  handleDescriptionCreate(selectedTerm?.data?.id);
               }

            }}
         >
            Guardar
         </button>
      </div>
   );

   const headerDescription = (
      <div>
         <div className="dialogs_header_around_icon">
            <i className="i-icon-header-dialog-blue"></i>
         </div>
         <h4 className="dialogs_header_subtitle mt-2">
            Descripción de Términos y Condiciones
         </h4>
      </div>
   );

   //Editar
   const [EditarInputNewNombre, setEditarInputNewNombre] = useState(false)
   const VerificarEditarNombreSEF = () => {
      var verifi = true;
      var inputEditarNom = document.getElementById('myInputTextNewNombreEdit').value;
      if (inputEditarNom == "" || inputEditarNom == null) {
         verifi = false;
         setEditarInputNewNombre(true)
      }
      return verifi;
   }

   useEffect(() => {
      setEditarInputNewNombre(false);
   }, [visibleEdit])



   const footerEditar = (
      <div>
         <Button
            label="Cancelar"
            onClick={() => setVisibleEdit(false)}
            className="p-button-text"
         />
         <Button
            label="Guardar"
            onClick={() => {
               if (VerificarEditarNombreSEF()) {
                  setVisibleEdit(false)
                  //handleCrearArea()
                  handleEditArea(selectedTerm?.data?.id)
               }

            }}
            autoFocus
         //disabled={!isNombreValido}
         />
      </div>
   );

   const headerEditar = (
      <>
         <p className="font-bold text-lg" style={{ color: "#5555D8" }}>
            Editar
         </p>
         <span className="font-normal text-sm" style={{ color: "#7B8794", }}
         >
            Aquí podras editar el nombre de la área seleccionada
         </span>
      </>
   );

   //Eliminar

   const footerEliminar = (
      <div className=" flex flex-row gap-2 justify-content-end">
         <Button label="Cancelar" />
         <Button onClick={() => {
            handleDelete(selectedTerm.data.id);
            setVisibleDelete(false);
         }} label="Eliminar" />
      </div>
   );

   //crear area
   const [VerifNombreProSerCre, setVerifNombreProSerCre] = useState(false)
   const VerificarFaltaCrear = () => {
      var verifi = true;
      var inputNomCRe = document.getElementById('InputNombProducServicesPP').value;
      if (inputNomCRe == "" || inputNomCRe == null) {
         verifi = false;
         setVerifNombreProSerCre(true)
      }
      return verifi;
   }

   useEffect(() => {
      setVerifNombreProSerCre(false);
   }, [visible])
   const footerCrearArea = (
      <div className=" flex justify-content-evenly gap-2 butons-area">
         <Button
            label="Cancelar"
            className="dialogs_butons_default_secondary flex-1"
            onClick={() => setVisible(false)}
         />
         <Button
            label="Crear"
            className="flex-1"
            onClick={() => {
               if (VerificarFaltaCrear()) {
                  setVisible(false);
                  //handleCrearArea()
                  handleSubmit()
               }

            }}
         //disabled={!isNombreValido}
         />
      </div>
   );

   const headerCrearArea = (
      <div>
         <div>
            <p className="font-bold text-xl py-2" style={{ color: "#5555D8", }}>
               Nombre del producto o servicio
            </p>
         </div>
         <div className="">
            <p className="font-normal text-sm" style={{ color: "#7B8794", }}>
               En esta sección usted puede crear los términos y condiciones para los productos o servicios que ofrece
            </p>
         </div>
      </div>
   );

   //crear sub area

   const headerSubTermino = (
      <>
         <div>
            <p className="font-bold text-lg" style={{ color: "#5555D8", }}>
               Crear
            </p>
         </div>
         <div className="w-full">
            <p className="font-normal text-sm" style={{ color: "#7B8794", }}>
               En esta sección usted puede registrar las sub
               áreas que necesite áreas generales.
            </p>
         </div>
      </>
   );

   const [InputCreateSubNivelMN, setInputCreateSubNivelMN] = useState(false);

   useEffect(()=>{
      setInputCreateSubNivelMN(false);
   },[visibleSubTermino])

   const VerificarCrearSubnivel = () => {
      var verifi = true;
      var inputCreateNewSubNivel = document.getElementById('myInputTextNewNombreSubnivelCreate').value;
      if (inputCreateNewSubNivel == "" || inputCreateNewSubNivel == null) {
         verifi = false;
         setInputCreateSubNivelMN(true)
      }
      return verifi;
   }
   const footerSubTermino = (
      <div className=" flex w-full align-items-center justify-content-end butons-area">
         <Button
            style={{ margin: "3%" }}
            label="Cancelar"
            className="dialogs_butons_default_secondary"
            onClick={() => {
               setVisibleSubTermino(false);
            }}
         />
         <Button
            style={{ margin: "3%" }}
            label="Crear"
            className="dialogs_butons_default_primary"
            onClick={() => {
               if (VerificarCrearSubnivel()) {
                  //agregarPersonal();
                  //handleCrearArea()
                  handleCreateHijo(selectedTerm?.data?.id)
                  setVisibleSubTermino(false);
               }

            }}
         //disabled= {!isNombreValido}
         />
      </div>
   );

   //end Footer/Header de dialog

   //Acciones

   //Editar

   const showToast = (severity, summary, message) => {
      toast.current.show({
         severity: severity,
         summary: summary,
         detail: message,
         life: 3000
      });
   };

   const handleEditArea = async (id) => {
      dispatch(startLoading());
      const data = {
         nombre: areaNombre,
         descripcion: descripcion?.[id]?.descripcion
      };

      try {
         const response = await http.put(`/clinica/servicio/update/${id}`, data);

         if (response?.data?.resp) {
            getAllTerms();
            showToast(
               "success", "Editado correctamente", "Se editó el área correctamente"
            )
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         console.error(error);
         showToast('error', 'No se edito correctamente', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   };

   //Eliminar

   const handleDelete = async (id) => {
      dispatch(startLoading());

      try {
         const response = await http.delete(`/clinica/servicio/delete/${id}`);

         if (response?.data?.resp) {
            getAllTerms();
            showToast(
               "success",
               "Eliminado correctamente",
               "Se eliminó el área correctamente"
            );
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         console.error(error);
         showToast('error', 'No se eliminó correctamente', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }
   };



   //Crear Area
   const handleSubmit = async () => {

      dispatch(startLoading());

      const data = {
         nombre: areaNombre,
         parent_id: terms.id
      };

      // Verificar si ya existe un área con el mismo nombre
      const areaExistente = terms.find((area) => area.data.nombre === areaNombre);

      if (areaExistente) {
         dispatch(startLoading());
         setTimeout(() => {
            dispatch(endLoading());
         }, 1000);
         showToast(
            "error",
            "Nombre duplicado",
            "Ya existe un área con el mismo nombre. Por favor, elija un nombre único."
         );
         return;
      }
      try {

         const response = await http.post('clinica/servicio/create', data);

         if (response?.data?.resp) {
            getAllTerms();
            showToast(
               "success",
               "Área creada con éxito",
               "La nueva área se ha creado exitosamente."
            );
         } else if (response?.data?.error) {
            showToast(
               "error",
               "Error",
               response.data.error
            );
         }

      } catch (error) {
         showToast('error', 'Error al crear un Área', 'Contactar a soporte técnico')

      } finally {
         dispatch(endLoading());
      }

   };

   //Agregar descripción

   const verifyinput = (campo) => {
      if ((campo ?? false) && (campo.trim() !== '')) {
         return true
      } else {
         return false
      }
   }
   const handleDescriptionCreate = async (id) => {
      const data = {
         nombre: descripcion?.[id]?.nombre.trim(),
         descripcion: descripcion?.[id]?.descripcion.trim()
      };

      if (verifyinput(data.nombre) && verifyinput(data.descripcion)) {

         // http
         //    .put(`/clinica/servicio/update/${id}`, data)
         //    .then((response) => {
         //       if (response?.data?.resp) {

         //          showToast(
         //             "success",
         //             "Descripción actualizada correctamente",
         //             "Se actualizó la descripción correctamente."
         //          );

         //          getAllTerms();
         //       } else if (response?.data?.error) {
         //          showToast('error', 'Error', response.data.error)
         //       }

         //    })
         //    .catch((error) => {
         //       console.error(error);
         //       showToast(
         //          "error",
         //          "Error al actualizar la descripción",
         //          "Contactar a soporte técnico."
         //       );
         //    })




         dispatch(startLoading());

         try {
            const response = await http.put(`/clinica/servicio/update/${id}`, data);

            if (response?.data?.resp) {

               showToast(
                  "success",
                  "Descripción actualizada correctamente",
                  "Se actualizó la descripción correctamente."
               );

               getAllTerms();
            } else if (response?.data?.error) {
               showToast('error', 'Error', response.data.error)
            }

         } catch (error) {
            showToast(
               "error",
               "Error al actualizar la descripción",
               "Contactar a soporte técnico."
            );
         } finally {
            dispatch(endLoading());
         }



      } else {
         showToast(
            "info",
            "Verificar el campo descripcion",
            "Completar correctamente el campo no dejar vacio"
         )
         getAllTerms()
      }
   };

   // Esta funcion esta encargada de manejar el cambio en input de Descripcion de termino de condicion
   const handlechangeconditionterms = (e, id) => {
      setDescripcion(prevState => {
         const newDescripcion = { ...prevState };
         newDescripcion[id] = {
            ...newDescripcion[id],
            descripcion: e.target.value
         };
         return newDescripcion;
      });
   };

   useEffect(() => {
      const handleResize = () => {
         setWindowWidth(window.innerWidth);
      };

      window.addEventListener("resize", handleResize);

      return () => {
         window.removeEventListener("resize", handleResize);
      };
   }, []);

   //Hook encaragdo de setear los valores devueltos por la api en descripcion de terminos y condiciones se ejecutara cuando cambie el valor de terms o al cargar al inicio.
   useEffect(() => {
      let listTerminos = {}
      terms.map(item => {
         listTerminos[item.data.id] = { "nombre": item.data.nombre, "descripcion": item.data.descripcion }
         if (item?.children) {
            item.children.map(it => {

               listTerminos[it.data.id] = { "nombre": it.data.nombre, "descripcion": it.data.descripcion }
            })
         }


      })
      setDescripcion(listTerminos)
   }, [terms])

   //Crear Sub Area

   const handleCreateHijo = async (idArea) => {
      if (!areaNombre.trim()) {
         showToast(
            "error",
            "Falta información",
            "Por favor, ingrese un nombre para el área."
         );
         return;
      }

      // Verificar si ya existe un área con el mismo nombre
      const areaExistente = terms.find((area) => area.data.nombre === areaNombre);

      if (areaExistente) {
         showToast(
            "error",
            "Nombre duplicado",
            "Ya existe un área con el mismo nombre. Por favor, elija un nombre único."
         );
         return;
      }

      const data = {
         nombre: areaNombre,
         parent_id: idArea || null,
      };




      // http
      //    .post(`/clinica/servicio/create`, data)
      //    .then((response) => {
      //       if (response?.data?.resp) {
      //          getAllTerms();
      //          showToast(
      //             "success",
      //             "Área creada con éxito",
      //             "La nueva área se ha creado exitosamente."
      //          );
      //       } else if (response?.data?.error) {
      //          showToast('error', 'Error', response.data.error)
      //       }

      //    })
      //    .catch((error) => {
      //       console.error(error);
      //       showToast(
      //          "error",
      //          "Error al crear un Área",
      //          'Contactar a soporte técnico'
      //       );
      //    });






      dispatch(startLoading());

      try {
         const response = await http.post(`/clinica/servicio/create`, data);

         if (response?.data?.resp) {
            getAllTerms();
            showToast(
               "success",
               "Área creada con éxito",
               "La nueva área se ha creado exitosamente."
            );
         } else if (response?.data?.error) {
            showToast('error', 'Error', response.data.error)
         }

      } catch (error) {
         console.error(error);
         showToast(
            "error",
            "Error al crear un Área",
            'Contactar a soporte técnico'
         );
      } finally {
         dispatch(endLoading());
      }
   };

   //End Acciones


   //Botones

   const actionTemplate = (rowData) => {
      return (
         <div className="busetresbtn">
            <Button className="busecuatrobtn border-0"
               label="Agregar"
               type="button"
               icon="pi pi-plus"
               severity="success"
               onClick={() => {
                  setVisibleSubTermino(true);
                  setAreaNombre('');
                  setSelectedTerm(rowData);
               }}

            ></Button>
            <Button
               icon="pi pi-trash text-red-500 text-xl"
               label="Eliminar"
               className="busecuatrobtn bg-red-100  border-0"
               onClick={() => {
                  setVisibleDelete(true);
                  setSelectedTerm(rowData);
                  
               }}
            />

            <Button className="busecuatrobtn bg-green-100 border-0"
               label="Editar"
               icon="pi pi-pencil text-green-700 text-xl"
               onClick={() => {
                  setVisibleEdit(true)
                  setSelectedTerm(rowData);
               }}
            />

            <Button className="busecuatrobtn bg-purple-100 border-0"
               label="Descripción"
               type="button"
               onClick={() => {
                  setVisibleDescription(true)
                  setSelectedTerm(rowData);
               }}
               icon="pi pi-book text-indigo-400 text-xl"
            ></Button>
         </div>
      );
   }

   const TresPuntos = (rowData) => {
      const [mostrarOpciones, setMostrarOpciones] = useState(false);
      const toggleOpciones = () => {
         setMostrarOpciones((prevState) => !prevState);

      };

      const isMobile = window.innerWidth < 670;

      if (isMobile) {
         return (
            <div className="tres-puntos-container" style={{ position: "relative", width: "100%", textAlign: "center" }}>
               <span onClick={toggleOpciones}>
                  <Button style={{ backgroundColor: "#f8f9fa", color: "#000", border: "1px solid #dee2e6" }}
                     icon="pi pi-ellipsis-v"
                  />
               </span>
               {mostrarOpciones && (
                  <div className="opciones-flotantes">
                     {actionTemplate(rowData)}
                  </div>
               )}
            </div>
         );
      } else {
         return (
            <div>
               {actionTemplate(rowData)}
            </div>
         )
      }
   };


   const header = (
      <div className="flex align-items-baseline justify-content-end gap-2">
         <Button
            label="Agregar"
            style={{ marginTop: "5px" }}
            icon="pi pi-plus text-indigo-400 text-xl"
            className="bg-white text-indigo-400 buton-area"
            onClick={() => setVisible(true)}
         />
      </div>
   );


   return (
      <div className="term__context">

         <section className="term__context_section_one">
            <h1 className="term__context_section_title">
               Términos y Condiciones
            </h1>
            <p className="term__context_section_paragrph">
               En este módulo usted podrá colocar los términos y condiciones de
               los productos y servicios a Bregma y ofertados a sus clientes.
               Por defecto están colocados nuestros términos y condiciones, sin
               embargo usted puede editar los mismos.
            </p>
            <img
               src={medicTermConditions}
               alt="medic"
               className="term__context_section_img"
            />
         </section>
         <section className="term__context_section_two">
            <div className="term__context_section_two_header">
               <div className="term__context_section_two_header_one">
                  <div className="term__context_section_logo">
                     <i className="i-term-conditions"></i>
                  </div>
                  <h5 className="term__context_section_subtitle">
                     Productos y Servicios
                  </h5>
               </div>
            </div>


            <div className="term__context_section_two_body">
               <TreeTable
                  value={terms}
                  header={header}
                  className="w-full bg-white "
               >
                  <Column field="nombre" header="Nombre" expander />
                  <Column
                     style={{ textAlign: "center", margin: "auto" }}
                     header="Acciones"
                     body={TresPuntos}
                     headerClassName="w-15rem"
                  />
               </TreeTable>

            </div>

         </section>
         <Toast ref={toast} />
         {/* Dialog Acciones */}

         {/* Dialog Descricion */}
         <Dialog
            style={{ width: "400px", margin: "5%" }}
            visible={visibleDescription}
            header={headerDescription}
            footer={footerDescription}
            onHide={() => setVisibleDescription(false)}
         >
            <div className="w-full flex flex-column gap-2 relative pt-1 pb-1">
               <InputTextarea
                  autoResize
                  id="myTextareaDescripcion"
                  className={`text-area-des InputRetraccionNumCuAP ${DescripcionFalta ? 'Select' : ''}`}
                  placeholder="Descripción..."
                  style={{ width: "100%", height: "50px", border: "1px solid #ccc" }}
                  value={descripcion?.[selectedTerm?.data?.id]?.descripcion}
                  onChange={(e) => { handlechangeconditionterms(e, selectedTerm?.data?.id); setDescripcionFalta(false) }}
               />
               <div className={`InputRetraccionNumCuAPDiv ${DescripcionFalta ? 'Select' : ''}`}></div>
               <p className={`InputRetraccionNumCuAPTextError ${DescripcionFalta ? 'Select' : ''}`}>Necesita ingresar una descripción</p>
            </div>


         </Dialog>

         {/* Dialog Crear area */}

         <Dialog
            style={{ width: "413px", height: "" }}
            visible={visible}
            header={headerCrearArea}
            footer={footerCrearArea}
            onHide={() => setVisible(false)}
         >
            <div className="flex flex-column relative gap-2">
               <p className="text-700">Área</p>
               <InputText
                  id="InputNombProducServicesPP"
                  //value={areaNombre}
                  onChange={(e) => { setAreaNombre(e.target.value); setVerifNombreProSerCre(false) }}
                  className={`w-full InputRetraccionNumCuAP ${VerifNombreProSerCre ? 'Select' : ''}`}
               />
               <div className={`InputRetraccionNumCuAPDiv ${VerifNombreProSerCre ? 'Select' : ''}`}></div>
               <p className={`InputRetraccionNumCuAPTextError ${VerifNombreProSerCre ? 'Select' : ''}`}>Necesita ingresar un nombre</p>
            </div>
         </Dialog>

         {/* Dialog Crear sub area */}

         <Dialog
            style={{ width: "413px", height: "auto" }}
            visible={visibleSubTermino}
            onHide={() => setVisibleSubTermino(false)}
            header={headerSubTermino}
            footer={footerSubTermino}
         >
            <div className="w-full flex flex-column gap-2 relative pt-1 pb-1">
               <InputText
                  value={areaNombre}
                  id="myInputTextNewNombreSubnivelCreate"
                  style={{ width: "100%", height: "50px", marginTop: "20px", border: "1px solid #ccc" }}
                  className={`w-full InputRetraccionNumCuAP ${InputCreateSubNivelMN ? 'Select' : ''}`}
                  onChange={(e) => {setAreaNombre(e.target.value); setInputCreateSubNivelMN(false)}}
               />
               <div className={`InputRetraccionNumCuAPDiv ${InputCreateSubNivelMN ? 'Select' : ''}`}></div>
               <p className={`InputRetraccionNumCuAPTextError ${InputCreateSubNivelMN ? 'Select' : ''}`}>Necesita ingresar un nombre</p>
            </div>


            {/* <div className="flex flex-column gap-5" style={{ margin: "5%" }}>
               <div className=" flex flex-column gap-2">
                  <InputText
                     value={areaNombre}
                     style={{ width: "100%", height: "50px", marginTop: "20px", border: "1px solid #ccc" }}
                     className="w-full"
                     onChange={(e) => setAreaNombre(e.target.value)}
                  />
               </div>

            </div> */}
         </Dialog>

         {/* Dialog Aliminar */}


         <Dialog

            visible={visibleDelete}
            resizable={false}
            style={{ width: "500px", height: "240px" }}
            className="p-fluid eliminarLocal"
            header={<p className="pi pi-trash" style={{ color: "#5555D8", fontSize: '2rem', fontWeight: "700" }}> <i style={{ fontFamily: 'Montserrat', color: "#5555D8", fontStyle: "normal" }}> Eliminar Producto</i></p>}

            footer={footerEliminar}

            onHide={() => {
               setVisibleDelete(false);
            }}

         >
            <div className="flex flex-column gap-3">
               <div className="flex flex-column w-full">
                  <p style={{ fontSize: "20px" }} className=" m-0 pt-5">¿Está seguro qué desea eliminar?</p>
               </div>
            </div>
         </Dialog>

         {/* Dialog Editar */}

         <Dialog
            visible={visibleEdit}
            onHide={() => setVisibleEdit(false)}
            footer={footerEditar}
            header={headerEditar}
         >

            <div className="w-full flex flex-column gap-2 relative pt-1 pb-1">
               <InputText //value={areaNombre}
                  defaultValue={selectedTerm?.data?.nombre}
                  id="myInputTextNewNombreEdit"
                  onChange={(e) => { setAreaNombre(e.target.value); setEditarInputNewNombre(false) }}
                  className={`text-area-des InputRetraccionNumCuAP ${EditarInputNewNombre ? 'Select' : ''}`}
                  style={{ width: "100%" }} />
               <div className={`InputRetraccionNumCuAPDiv ${EditarInputNewNombre ? 'Select' : ''}`}></div>
               <p className={`InputRetraccionNumCuAPTextError ${EditarInputNewNombre ? 'Select' : ''}`}>Necesita ingresar un nuevo nombre</p>
            </div>
         </Dialog>
      </div>
   );
}